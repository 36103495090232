import React from 'react';
import Checkbox, { CheckboxProps } from 'expo-checkbox';
import { Text } from '../typography';
import { Pressable, PressableProps, View, ViewProps } from 'react-native';

export interface LabeledCheckboxProps extends PressableProps {
  label: string;
  checked: boolean;
  onPress: () => void;
}

export const LabeledCheckbox = ({ label, checked, onPress, ...rest }: LabeledCheckboxProps) => {
  return (
    <Pressable
      style={{ flexDirection: 'row', paddingTop: 5, paddingBottom: 5, alignItems: 'center' }}
      {...rest}
      onPress={onPress}>
      <Checkbox value={checked} onValueChange={onPress} />
      <Text>{label}</Text>
    </Pressable>
  );
};

export interface CheckboxGroupProps extends ViewProps {
  fields: string[][];
  value: string | null;
  onChange: (value: string) => void;
}

export const CheckboxGroup = ({ fields, value, onChange, ...rest }: CheckboxGroupProps) => {
  const isChecked = (name: string) => (JSON.parse(value ?? '[]') as string[]).includes(name);

  const toggleCheckbox = (name: string) => {
    const values = JSON.parse(value ?? '[]') as string[];
    if (values.includes(name)) {
      onChange(JSON.stringify(values.filter((v) => v !== name)));
    } else {
      onChange(JSON.stringify([...values, name]));
    }
  };
  return (
    <View style={{ flexDirection: 'column', justifyContent: 'space-between' }} {...rest}>
      {fields.map((field, index) => (
        <LabeledCheckbox
          key={index}
          label={field[1]}
          checked={isChecked(field[0])}
          onPress={() => toggleCheckbox(field[0])}
        />
      ))}
    </View>
  );
};
