import React from 'react';
import { View, ViewProps } from 'react-native';
import * as Colors from '../../design/colors';
import { Baseorder, useMyOrdersListQuery } from '../../generated/graphql-urql-reacthooks';
import { Text } from '../../design/typography';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import HomescreenList from '../home/HomescreenList';
import ScrollableList from '../../design/generics/list/ScrollableList';
import IconTextListItem from '../../design/generics/list/IconTextListItem';
import { SplitTitleButtonHeader } from '../../design/generics/headers/SplitTitleButtonHeader';

const MyOrdersList = (props: ViewProps) => {
  const [result, reexecuteQuery] = useMyOrdersListQuery({ requestPolicy: 'network-only' });
  const { data, error } = result;
  const patients = data ? data.baseorder : [];

  if (error) {
    return (
      <View>
        <Text>{error.message}</Text>
      </View>
    );
  }

  const heading = (item: Baseorder) => {
    if (item.patient) {
      return `${item.patient.firstName ?? ''} ${item.patient.lastName ?? ''}`;
    }
    return '';
  };

  const subheading = (item: Baseorder) => {
    if (item.patient && item.patient.dateOfBirth) {
      return item.patient.dateOfBirth.toLocaleDateString();
    }
    return '';
  };

  return (
    <HomescreenList<OrderType>
      title={'My Orders'}
      buttonText={'All Orders'}
      buttonStyle={'bg-orders'}
      itemStyle={'bg-orders-light'}
      items={patients}
      icon={(item) => (
        <AntDesign
          style={{ flex: 0, marginLeft: 20, marginRight: 20 }}
          name={'checkcircleo'}
          size={40}
          color="green"
        />
      )}
      headingText={heading}
      subheadingText={subheading}
      getKey={(item) => item.id ?? 0}
      to={(item) => ({ screen: 'Patient', params: { id: item.id } })}
    />
  );
};

export default MyOrdersList;
