import React from 'react';
import { View, ViewProps } from 'react-native';
import {
  ClinicalNoteFieldValueType,
  OrderOrderableFieldValueType,
  ThreadFieldValueType,
} from '../../generated/graphql-urql-reacthooks';
import { Text } from '../../design/typography';

interface FieldValueDisplayProps extends ViewProps {
  fieldValue: OrderOrderableFieldValueType | ClinicalNoteFieldValueType;
}

const FieldValueDisplay = ({ fieldValue, ...props }: FieldValueDisplayProps): JSX.Element => {
  const field = fieldValue.field;
  let replacementValue = JSON.parse(fieldValue.value ?? '') as string | Array<string>;
  replacementValue =
    replacementValue instanceof Array ? replacementValue.join(', ') : replacementValue;
  return (
    <View {...props}>
      <Text>{field?.insertText?.replace('{+value+}', replacementValue)}</Text>
    </View>
  );
};

export default FieldValueDisplay;
