import { AllOrdersType } from '../../generated/graphql-urql-reacthooks';
import { ScrollView, SectionList, View } from 'react-native';
import React from 'react';
import { HeaderText, Text } from '../../design/typography';
import { OrdersRow } from './OrdersRow';
import { ColorFamilyType } from '../../design/colors';
import { BackgroundView } from '../../design/view';
import { TitleList } from '../../design/generics/list/TitleList';

export interface OrdersSectionType {
  title: string;
  data: AllOrdersType[];
  colorFamily: ColorFamilyType;
}

const OrdersSectionList = ({ ordersSections }: { ordersSections: OrdersSectionType[] }) => {
  return (
    <SectionList
      sections={ordersSections}
      renderItem={({ item, section }) => (
        <OrdersRow order={item} style={{ backgroundColor: section.colorFamily.light }} />
      )}
      stickySectionHeadersEnabled={true}
      renderSectionHeader={({ section: { title, colorFamily } }) => (
        <BackgroundView>
          <HeaderText style={{ color: colorFamily.main }}>{title}</HeaderText>
        </BackgroundView>
      )}
    />
  );
};

export default OrdersSectionList;
