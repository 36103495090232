import { View } from 'react-native';
import { Text } from '../../typography';
import {
  ViewPressable,
  ViewPressablePropsToOptional,
  ViewPressableToOptional,
} from '../ViewPressable';
import React from 'react';

export interface IconTextListItemProps extends ViewPressablePropsToOptional {
  icon?: JSX.Element | null;
  headingText: string;
  subheadText?: string;
}

const IconTextListItem = ({
  icon,
  headingText,
  subheadText,
  to,
  children,
  ...rest
}: IconTextListItemProps) => {
  return (
    <ViewPressableToOptional
      to={to}
      className={`align-center m-2 flex flex-row justify-start py-4`}
      {...rest}>
      {icon && <View className={'mx-1 flex-shrink-0 flex-grow-0'}>{icon}</View>}

      <View className={'flex-1 flex-col items-center align-middle'}>
        <Text
          adjustsFontSizeToFit={true}
          numberOfLines={1}
          minimumFontScale={0.5}
          selectable={false}
          className={'mr-1'}>
          {headingText}
        </Text>
        <Text
          adjustsFontSizeToFit={true}
          numberOfLines={1}
          minimumFontScale={0.5}
          selectable={false}>
          {subheadText}
        </Text>
      </View>
    </ViewPressableToOptional>
  );
};

export default IconTextListItem;
