import { Text } from 'react-native';
import { StyledButton } from '../../design/buttons';
import MyPatientsList from '../patient/MyPatientsList';
import MyOrdersList from '../orders/MyOrdersList';
import { BackgroundView } from '../../design/view';

export const HomeScreen = () => {
  return (
    <BackgroundView className="flex-1">
      <Text>Home</Text>
      <MyPatientsList />
      <MyOrdersList />
    </BackgroundView>
  );
};
