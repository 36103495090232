import { useFonts } from 'expo-font'

export function Font({ children }) {
  const [ready] = useFonts({
    'Poppins-Black': require('./assets/font/Poppins/Poppins-Black.ttf'),
    'Poppins-BlackItalic': require('./assets/font/Poppins/Poppins-BlackItalic.ttf'),
    'Poppins-Bold': require('./assets/font/Poppins/Poppins-Bold.ttf'),
    'Poppins-BoldItalic': require('./assets/font/Poppins/Poppins-BoldItalic.ttf'),
    'Poppins-ExtraBold': require('./assets/font/Poppins/Poppins-ExtraBold.ttf'),
    'Poppins-ExtraBoldItalic': require('./assets/font/Poppins/Poppins-ExtraBoldItalic.ttf'),
    'Poppins-ExtraLight': require('./assets/font/Poppins/Poppins-ExtraLight.ttf'),
    'Poppins-ExtraLightItalic': require('./assets/font/Poppins/Poppins-ExtraLightItalic.ttf'),
    'Poppins-Italic': require('./assets/font/Poppins/Poppins-Italic.ttf'),
    'Poppins-Light': require('./assets/font/Poppins/Poppins-Light.ttf'),
    'Poppins-LightItalic': require('./assets/font/Poppins/Poppins-LightItalic.ttf'),
    'Poppins-Medium': require('./assets/font/Poppins/Poppins-Medium.ttf'),
    'Poppins-MediumItalic': require('./assets/font/Poppins/Poppins-MediumItalic.ttf'),
    'Poppins-Regular': require('./assets/font/Poppins/Poppins-Regular.ttf'),
    'Poppins-SemiBold': require('./assets/font/Poppins/Poppins-SemiBold.ttf'),
    'Poppins-SemiBoldItalic': require('./assets/font/Poppins/Poppins-SemiBoldItalic.ttf'),
    'Poppins-Thin': require('./assets/font/Poppins/Poppins-Thin.ttf'),
    'Poppins-ThinItalic': require('./assets/font/Poppins/Poppins-ThinItalic.ttf'),
  })

  if (!ready) {
    return null
  }

  return <>{children}</>
}
