import React from 'react';
import {
  OrderableType,
  OrderOrderableFieldType,
  useOrderableDetailsQuery,
} from '../../../generated/graphql-urql-reacthooks';
import { View, ViewProps } from 'react-native';
import { HeaderText, Text } from '../../../design/typography';
import { CenterViewSpinner } from '../../../design/view';
import FieldValue, { FieldValueType } from '../../field/FieldValue';
import produce from 'immer';

interface OrderableOrderEntryProps extends ViewProps {
  orderable: OrderableType;
  newOrderFieldValues: FieldValueType[];
  updateNewOrderFieldValues: (newOrderData: FieldValueType[]) => void;
}

const OrderableOrderEntry = ({
  orderable,
  newOrderFieldValues,
  updateNewOrderFieldValues,
  ...props
}: OrderableOrderEntryProps): JSX.Element => {
  const [results] = useOrderableDetailsQuery({ variables: { id: orderable?.id } });
  const { data, error, fetching } = results;
  const orderabledata = data?.orderable as OrderableType;
  const setFieldValue = (field: OrderOrderableFieldType, value: string) => {
    const index = newOrderFieldValues?.findIndex((f) => f.field.id === field.id);
    if (index >= 0) {
      updateNewOrderFieldValues(
        produce(newOrderFieldValues, (draft) => {
          draft[index].value = value;
        })
      );
    } else {
      updateNewOrderFieldValues(
        produce(newOrderFieldValues, (draft) => {
          draft.push({ field, value });
        })
      );
    }
  };

  const fieldValueSetter = (field: OrderOrderableFieldType) => (value: string) => {
    setFieldValue(field, value);
  };

  const getFieldValue = (field: OrderOrderableFieldType) =>
    newOrderFieldValues?.find((f) => f.field.id === field.id)?.value ?? null;

  if (fetching) {
    return <CenterViewSpinner />;
  }

  if (orderabledata) {
    return (
      <View {...props}>
        <HeaderText>{orderabledata.title}</HeaderText>
        {orderabledata?.fieldsOrderable?.map((field) => (
          <FieldValue
            key={field.id}
            field={field}
            value={getFieldValue(field)}
            onChange={fieldValueSetter(field)}
          />
        ))}
      </View>
    );
  }

  return <Text>Error</Text>;
};

export default OrderableOrderEntry;
