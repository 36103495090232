import React from 'react';
import { Provider } from './app/provider';
import { Font } from './Fonts';
import Main from './app/main';

export default function App() {
  return (
    <Provider>
      <Font>
        <Main />
      </Font>
    </Provider>
  );
}
