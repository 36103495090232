import {
  useGetPatientHistoryQuery,
  useGetPatientQuery,
} from '../../generated/graphql-urql-reacthooks';
import React from 'react';
import { getAge, getGenderText } from './patientutils';
import { View, ViewProps } from 'react-native';
import { HeaderText } from '../../design/typography';
import { BackgroundView, CenterViewSpinner } from '../../design/view';

const textColor = 'lightText';
const background = 'darkBlue.400';

interface PatientHeaderProps extends ViewProps {
  patientId: number;
}

const PatientHeader = ({ patientId, ...rest }: PatientHeaderProps): JSX.Element => {
  //unused patientId
  const [result, reexecuteQuery] = useGetPatientQuery({ variables: { id: 177, details: false } });
  const { data, fetching } = result;
  const patient = data.patientByPk ?? null;
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const agetext = patient
    ? getAge(new Date(patient.dateOfBirth?.toISOString() ?? '')) +
      getGenderText(patient?.gender ?? 1)
    : null;

  return (
    <BackgroundView {...rest}>
      {fetching && <CenterViewSpinner />}
      {!fetching && patient && (
        <HeaderText>
          {patient.firstName} {patient.lastName}({agetext})
        </HeaderText>
      )}
    </BackgroundView>
  );
};

export default PatientHeader;
