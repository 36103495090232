import {AllOrdersType} from "../../generated/types_only";


export const isOrderCancelled = (order: AllOrdersType) : boolean => {
  return order.cancelled ?? false;
}

export const isOrderApproved = (order: AllOrdersType) : boolean => {
  return order.approved ?? false;
}

export const isOrderExpired = (order: AllOrdersType): boolean => {
  return order.expired ?? false;
}

export const isOrderHistorical = (order: AllOrdersType): boolean => {
  return (order && order.approved && !isOrderCancelled(order) && isOrderExpired(order))??false;
}

//used as an internal check to verify order is not null, cancelled, or expired
const orderPossible = (order: AllOrdersType): boolean => {
  return (order && !isOrderCancelled(order) && !isOrderExpired(order));
}
//Actual Sections
export const isOrderActive = (order: AllOrdersType): boolean => {
	return (orderPossible(order) && order.approved)??false;
};
export const isOrderOpen = (order: AllOrdersType): boolean => {
  return (orderPossible(order) && !order.approved)??false;
}
