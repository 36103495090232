import { ViewProps, Text } from 'react-native';
import React, { ReactElement } from 'react';
import * as Colors from '../../design/colors';
import ScrollableList from '../../design/generics/list/ScrollableList';
import IconTextListItem from '../../design/generics/list/IconTextListItem';
import { SplitTitleButtonHeader } from '../../design/generics/headers/SplitTitleButtonHeader';

interface HomescreenListProps<T> extends ViewProps {
  title: string;
  buttonText: string;
  buttonStyle: string;
  itemStyle: string;
  items: T[];
  headingText: (item: T) => string;
  subheadingText: (item: T) => string;
  icon: (item: T) => ReactElement;
  getKey: (item: T) => string | number;
  to: (item: T) => { screen: string; params?: any };
}

const HomescreenList = <T extends object>({
  title,
  buttonText,
  buttonStyle,
  items,
  itemStyle,
  headingText,
  subheadingText,
  icon,
  to,
  getKey,
  ...rest
}: HomescreenListProps<T>) => {
  const render = (item: T) => {
    return (
      <IconTextListItem
        to={to(item)}
        icon={icon(item)}
        headingText={headingText(item)}
        subheadText={subheadingText(item)}
        key={getKey(item)}
        className={'m-1 ' + itemStyle}
      />
    );
  };

  return (
    <ScrollableList<T>
      renderItem={render}
      items={items}
      header={SplitTitleButtonHeader({
        text: title,
        textColor: Colors.orders.main,
        buttonText: buttonText,
        buttonStyle: buttonStyle,
      })}
    />
  );
};

export default HomescreenList;
