import React from 'react';
import { View, ViewProps } from 'react-native';
import { Text } from '../../typography';
import { StyledButton } from '../../buttons';

interface SplitTitleButtonHeaderProps extends ViewProps {
  text: string;
  textColor?: string;
  buttonText: string;
  buttonStyle?: string;
  buttonPressedColor?: string;
}

export const SplitTitleButtonHeader = ({
  text,
  textColor,
  buttonText,
  buttonStyle,
  buttonPressedColor,
  ...rest
}: SplitTitleButtonHeaderProps) => {
  return (
    <View className={'flex-row items-center justify-between'} {...rest}>
      <Text className={'text-2xl'}>{text}</Text>
      <StyledButton text={buttonText} className={`min-w-[60] ${buttonStyle}`}></StyledButton>
    </View>
  );
};
