import { PatientType } from '../../../generated/graphql-urql-reacthooks';
import React from 'react';
import { ViewProps, View } from 'react-native';
import { Text } from '../../../design/typography';

interface PatientDemoLineProps extends ViewProps {
  title: string;
  value?: string | null | number;
}

const PatientDemoLine = (props: PatientDemoLineProps): JSX.Element => {
  return (
    <View
      {...props}
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderColor: 'black',
        width: '96%',
        justifyContent: 'space-between',
      }}>
      <Text>{props.title}</Text>
      <Text>{props.value}</Text>
    </View>
  );
};

export default PatientDemoLine;
