import React, { useMemo, useState } from 'react';
import PatientHeader from '../../patient/PatientHeader';
import FrequencyEntry from '../../orders/FrequencyEntry';
import { OrderableType } from '../../../generated/graphql-urql-reacthooks';
import {
  DateInputStepperFormStep,
  HeaderedStepperFormStep,
  TextDisplayStepperFormStep,
} from '../../../design/generics/StepperForm/GenericSteps';
import OrderableOrderEntryApproval, {
  OrderableOrderEntryApprovalDataType,
} from './OrderableOrderEntryApproval';
import { StepperForm, StepperFormStepType } from '../../../design/generics/StepperForm/StepperForm';
import DisciplineSelector from './DisciplineSelector';
import { SafeAreaView } from 'react-native';
import { BackgroundView } from '../../../design/view';

const SimpleFrequencyOrderRequestScreen = (props) => {
  console.log(props.route.params);
  const patientId = props.route.params.patientId;
  const [startDate, setStartDate] = React.useState(new Date());
  const [timesAWeek, setTimesAWeek] = React.useState(0);
  const [weeks, setWeeks] = React.useState(0);
  const [discipline, setDiscipline] = React.useState(null as OrderableType | null);
  const [orderableEntryApprovalData, setOrderableEntryApprovalData] = useState({
    existingOrder: null,
    newOrder: [],
    orderRejected: false,
  } as OrderableOrderEntryApprovalDataType);

  const getSteps = (): StepperFormStepType[] => {
    return [
      {
        title: 'Discipline',
        component: <DisciplineSelector setDiscipline={setDiscipline} discipline={discipline} />,
        error: () => {
          return discipline === null ? 'Please select a discipline' : null;
        },
      },
      {
        title: 'Details',
        component: (
          <OrderableOrderEntryApproval
            entryApproval={orderableEntryApprovalData}
            updateEntryApproval={setOrderableEntryApprovalData}
            orderable={discipline as OrderableType}
            patientId={177}
          />
        ),
        error: () => {
          return null;
        },
      },

      DateInputStepperFormStep({ title: 'Start Date', value: startDate, onChange: setStartDate }),
      HeaderedStepperFormStep({
        title: 'Frequency',
        children: (
          <FrequencyEntry
            style={{ alignSelf: 'center' }}
            setTimesAWeek={setTimesAWeek}
            setWeeks={setWeeks}
            weeks={weeks}
            timesAWeek={timesAWeek}
          />
        ),
      }),

      TextDisplayStepperFormStep({
        title: 'Confirm',
        value:
          `Order Request For ${discipline?.title as string}\n` +
          `Start Date: ${startDate.toLocaleDateString()}\n` +
          `${timesAWeek} times a week for ${weeks} weeks`,
        onChange: () => {},
        header: 'Confirm Order Request',
      }),
    ];
  };

  return (
    <BackgroundView className={'flex-1'}>
      <PatientHeader patientId={patientId} style={{ flexGrow: 0 }} />
      <StepperForm
        style={{ flexGrow: 1 }}
        steps={getSteps()}
        onFinish={() => {
          console.log('onFinish');
        }}
      />
    </BackgroundView>
  );
};
export default SimpleFrequencyOrderRequestScreen;
