import { Platform, ViewProps, Text, View } from 'react-native';
import React, { useState } from 'react';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { GenericInputProps } from './Inputs';

export const InlineDateInput = ({ value, onChange, ...rest }: GenericInputProps<Date>) => {
  if (Platform.OS === 'web') {
    return (
      <View>
        <Text>Date here</Text>
      </View>
    );
  } else if (Platform.OS === 'ios') {
    return <RNDateTimePicker value={value} mode={'date'} display={'inline'} {...rest} />;
  } else if (Platform.OS == 'android') {
    return <RNDateTimePicker value={value} mode={'date'} {...rest} />;
  } else {
    return (
      <View>
        <Text>Date here</Text>
      </View>
    );
  }

  return <View {...rest}></View>;
};
