import React, { ReactElement } from 'react';
import { View, ViewProps } from 'react-native';
import {
  AllOrdersType,
  OrderableOrderType,
  OrderType,
} from '../../generated/graphql-urql-reacthooks';
import { Text } from '../../design/typography';
import { Fontisto } from '@expo/vector-icons';
import IconTextListItem, {
  IconTextListItemProps,
} from '../../design/generics/list/IconTextListItem';

interface OrdersRowPropType extends Omit<IconTextListItemProps, 'headingText' | 'subheadingText'> {
  order: AllOrdersType;
}

export const OrdersRow = ({ order, ...rest }: OrdersRowPropType) => {
  let heading = '';
  const subheading = order.dateEffective?.toString();
  let icon = <Fontisto name={'doctor'} size={20} />;
  if (order.__typename === 'OrderableOrderType' && order.orderable) {
    heading = (order.orderable.title as string) + ' Order';
  } else if (order.__typename === 'MedicationOrderType') {
    heading = 'Medication Order';
    icon = <Fontisto name={'pills'} size={20} />;
  } else if (order.__typename === 'FreeTextOrderType') {
    heading = 'Free Text Order';
  }
  return (
    <IconTextListItem
      icon={icon}
      headingText={heading}
      subheadText={subheading}
      className={'rounded-2xl border-2'}
      {...rest}
    />
  );
};
