import { SafeArea } from './safearea/index';
import AuthProvider from './auth/firebase';
import StylesProvider from './styles';
import URQLProvider from './urql';
import { NavigationProvider } from './navigation';

export const Provider = ({ children }) => {
  return (
    <SafeArea>
      <AuthProvider>
        <URQLProvider>
          <NavigationProvider>
            <StylesProvider>{children}</StylesProvider>
          </NavigationProvider>
        </URQLProvider>
      </AuthProvider>
    </SafeArea>
  );
};
