import React from 'react';
import { HeaderText } from '../../../design/typography';
import { BackgroundView } from '../../../design/view';

const DetailedFrequencyOrderRequestScreen = (props) => {
  const patientId = props.route.params.patientId;

  return (
    <BackgroundView>
      <HeaderText>Detailed Frequency Order</HeaderText>
    </BackgroundView>
  );
};

export default DetailedFrequencyOrderRequestScreen;
