import React, { useState } from 'react';
import { View, Text, ScrollView, ScrollViewProps } from 'react-native';
import {
  Orderable,
  OrderablesForCategoryQuery,
  useOrderablesForCategoryQuery,
} from '../../../generated/graphql-urql-reacthooks';
import { CenterViewSpinner } from '../../../design/view';
import SelectListItem from '../../../design/generics/list/SelectListItem';

interface DisciplineSelectorProps extends ScrollViewProps {
  setDiscipline: (NewDiscipline: Orderable | null) => void;
  discipline: Orderable | null;
}

const DisciplineSelector = ({
  setDiscipline,
  discipline,
}: DisciplineSelectorProps): JSX.Element => {
  const [homeHealthOrderableResults] = useOrderablesForCategoryQuery({
    variables: { category: 2 },
  });
  const {
    data: homeHealthOrderableData,
    error: homeHealthOrderableError,
    fetching: homeHealthOrderableFetching,
  } = homeHealthOrderableResults;
  const homeHealthOrderables = homeHealthOrderableData
    ? homeHealthOrderableData.orderable
    : ([] as Orderable[]);
  console.log(homeHealthOrderables);
  const updateDiscipline = (newDiscipline) => {
    if (discipline?.id == newDiscipline?.id) {
      setDiscipline(null);
    } else {
      setDiscipline(newDiscipline);
    }
  };

  if (homeHealthOrderableFetching) {
    return <CenterViewSpinner />;
  }

  return (
    <ScrollView bounces={false}>
      {homeHealthOrderables?.map(
        (homeHealthOrderable) =>
          homeHealthOrderable && (
            <SelectListItem
              key={homeHealthOrderable.id}
              onPress={() => updateDiscipline(homeHealthOrderable)}
              text={homeHealthOrderable.title ?? ''}
              selected={discipline ? homeHealthOrderable.id == discipline.id : false}
            />
          )
      )}
    </ScrollView>
  );
};

export default DisciplineSelector;
