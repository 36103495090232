import * as RN from 'react-native';
import React from 'react';
import { Pressable, PressableProps, TextProps, Text } from 'react-native';
import { styled } from 'nativewind';

interface StyledButtonProps extends PressableProps {
  text: string;
  textProps?: TextProps;
}

const InternalButton = ({ text, textProps, ...rest }: StyledButtonProps) => {
  return (
    <Pressable {...rest}>
      <Text className={'font-normal text-white'} {...textProps}>
        {text}
      </Text>
    </Pressable>
  );
};

export const StyledButton = styled(
  InternalButton,
  '`justify-center rounded-2xl py-2 px-4 align-middle`'
);
