
//palette
export const palette = {
	//REFERENCE #095476
	primary: {
		50: "#def0ff",
		100: "#b4d7fb",
		200: "#89c1f6",
		300: "#5daef1",
		400: "#369dec",
		500: "#228ad3",
		600: "#1570a4",
		700: "#095476",
		800: "#002d49",
		900: "#000e1d",
	},

	secondary: {
		50: "#ffe5e1",
		100: "#febdb5",
		200: "#f79788",
		300: "#f2735a",
		400: "#ed532c",
		500: "#d34012",
		600: "#a5260d",
		700: "#761308",
		800: "#490402",
		900: "#1f0005",
	},

	tertiary: {
		50: "#e7f9eb",
		100: "#c8e9ce",
		200: "#a6d9af",
		300: "#83c990",
		400: "#62ba71",
		500: "#48a057",
		600: "#377d43",
		700: "#26592f",
		800: "#15351b",
		900: "#011403",
	},

	quaternary: {
		50: "#fff7dc",
		100: "#fce6b2",
		200: "#f7d585",
		300: "#f3c557",
		400: "#efb429",
		500: "#d69b10",
		600: "#a67808",
		700: "#785604",
		800: "#483400",
		900: "#1c1000",
	},
	pentanary: {
		50: "#fdedf7",
		100: "#e4d0df",
		200: "#cfb1c7",
		300: "#b993b2",
		400: "#a4749d",
		500: "#8b5b85",
		600: "#6d4766",
		700: "#4e3147",
		800: "#301d2a",
		900: "#150711",
	},
	gray: {
		50: "#f9f9fa",
		100: "#eceeef",
		200: "#dee1e3",
		300: "#cfd3d7",
		400: "#bfc5c9",
		500: "#adb4b9",
		600: "#98a1a8",
		700: "#7f8b93",
		800: "#606e79",
		900: "#374047",
	}
};


//Defaults
export const background = "#FFFFFF";
export const primaryColor = palette.primary[600];
export const textColor = "#000000";


//Color Families
export const blue = {
	main: "#1655BB",
	middle: "#6184BD",
	light: "#99AFD2",
};

export const green = {
	main: "#34BA8A",
	light: "#C6DDD5",
};

export const orange = {
	main: "#FF9900",
	light: "#F6DAAF"
};

export const gray = {
	main: "#707070",
	middle: "#BCBCBC",
	light: "#E7EAEE",
	black: "#000000",
	white: "#FFFFFF"
};

//Application Families
export const patient = blue;
export const orders = green;
export const requests = orange;
export const history = gray;

//Type
export interface ColorFamilyType{
  main: string;
  middle?: string;
  light: string;
  mid?: string;
  dark?: string;
  black?: string;
  white?: string;
}
