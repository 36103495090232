module.exports = {
  "__schema": {
    "queryType": {
      "name": "query_root"
    },
    "mutationType": {
      "name": "mutation_root"
    },
    "subscriptionType": {
      "name": "subscription_root"
    },
    "types": [
      {
        "kind": "SCALAR",
        "name": "Boolean"
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "BooleanComparisonExp",
        "inputFields": [
          {
            "name": "_eq",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_gt",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_gte",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_in",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_isNull",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_lt",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_lte",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_neq",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_nin",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "DateComparisonExp",
        "inputFields": [
          {
            "name": "_eq",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "_gt",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "_gte",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "_in",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "date",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_isNull",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_lt",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "_lte",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "_neq",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "_nin",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "date",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "SCALAR",
        "name": "Float"
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "Float8ComparisonExp",
        "inputFields": [
          {
            "name": "_eq",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            }
          },
          {
            "name": "_gt",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            }
          },
          {
            "name": "_gte",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            }
          },
          {
            "name": "_in",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "float8",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_isNull",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_lt",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            }
          },
          {
            "name": "_lte",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            }
          },
          {
            "name": "_neq",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            }
          },
          {
            "name": "_nin",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "float8",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "SCALAR",
        "name": "Int"
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "IntComparisonExp",
        "inputFields": [
          {
            "name": "_eq",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "_gt",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "_gte",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "_in",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_isNull",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_lt",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "_lte",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "_neq",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "_nin",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "IntervalComparisonExp",
        "inputFields": [
          {
            "name": "_eq",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "_gt",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "_gte",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "_in",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "interval",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_isNull",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_lt",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "_lte",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "_neq",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "_nin",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "interval",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "JsonbCastExp",
        "inputFields": [
          {
            "name": "String",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "JsonbComparisonExp",
        "inputFields": [
          {
            "name": "_cast",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbCastExp",
              "ofType": null
            }
          },
          {
            "name": "_containedIn",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "_contains",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "_eq",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "_gt",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "_gte",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "_hasKey",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_hasKeysAll",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_hasKeysAny",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_in",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "jsonb",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_isNull",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_lt",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "_lte",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "_neq",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "_nin",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "jsonb",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "NumericComparisonExp",
        "inputFields": [
          {
            "name": "_eq",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "_gt",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "_gte",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "_in",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "numeric",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_isNull",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_lt",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "_lte",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "_neq",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "_nin",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "numeric",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "SmallintComparisonExp",
        "inputFields": [
          {
            "name": "_eq",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "_gt",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "_gte",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "_in",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "smallint",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_isNull",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_lt",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "_lte",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "_neq",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "_nin",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "smallint",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "SCALAR",
        "name": "String"
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "StringComparisonExp",
        "inputFields": [
          {
            "name": "_eq",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_gt",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_gte",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_ilike",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_in",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_iregex",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_isNull",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_like",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_lt",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_lte",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_neq",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_nilike",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_nin",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_niregex",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_nlike",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_nregex",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_nsimilar",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_regex",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "_similar",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "TimeComparisonExp",
        "inputFields": [
          {
            "name": "_eq",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "_gt",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "_gte",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "_in",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "time",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_isNull",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_lt",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "_lte",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "_neq",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "_nin",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "time",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "TimestamptzComparisonExp",
        "inputFields": [
          {
            "name": "_eq",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "_gt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "_gte",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "_in",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "timestamptz",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_isNull",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_lt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "_lte",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "_neq",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "_nin",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "timestamptz",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "SCALAR",
        "name": "_varchar"
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "_varcharComparisonExp",
        "inputFields": [
          {
            "name": "_eq",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "_gt",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "_gte",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "_in",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "_varchar",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_isNull",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "_lt",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "_lte",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "_neq",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "_nin",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "_varchar",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "address",
        "fields": [
          {
            "name": "city",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "line1",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "line2",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "addressAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "addressAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "address",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "addressAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "addressAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "addressMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "addressMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "addressStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "addressStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "addressStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "addressSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "addressVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "addressVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "addressVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "addressAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "addressBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "line1",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "line2",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "addressIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "addressInsertInput",
        "inputFields": [
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "line1",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "line2",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "addressMaxFields",
        "fields": [
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "line1",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "line2",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "addressMinFields",
        "fields": [
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "line1",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "line2",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "addressMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "address",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "addressObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "addressInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "addressOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "addressOrderBy",
        "inputFields": [
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "line1",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "line2",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "addressPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "addressSetInput",
        "inputFields": [
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "line1",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "line2",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "addressStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "addressStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "addressStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "addressSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "addressUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "addressBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "addressVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "addressVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "addressVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "address_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "address_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "address_streamCursorValueInput",
        "inputFields": [
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "line1",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "line2",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agency",
        "fields": [
          {
            "name": "agencyLocations",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyLocation",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyLocationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyLocationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyLocationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyLocationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyServices",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyServices",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyServicesOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyServicesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyServicesAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyServicesOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyconnections",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyconnection",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyconnectionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyconnectionsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyconnectionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyconnectionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyjoinrequests",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyjoinrequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyjoinrequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyjoinrequestsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyjoinrequestAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyjoinrequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "category",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "destinedBaseOrders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseorder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "destinedBaseOrdersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "intakeSubmissions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntake",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "intakeSubmissionsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntakeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "payers",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyPayers",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyPayersOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "payersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyPayersAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyPayersOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "suppliesOrderables",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplier",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "suppliesOrderablesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableSupplierAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "agencyAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agency",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "agencyAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "agencyMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "agencyMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "agencyStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "agencyStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "agencyStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "agencySumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "agencyVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "agencyVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "agencyVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyAvgFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "agencyLocations",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "agencyServices",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServicesBoolExp",
              "ofType": null
            }
          },
          {
            "name": "agencyconnections",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnectionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "agencyjoinrequests",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestBoolExp",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "destinedBaseOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "intakeSubmissions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "payers",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayersBoolExp",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "suppliesOrderables",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyIncInput",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyInsertInput",
        "inputFields": [
          {
            "name": "agencyLocations",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocationArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "agencyServices",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServicesArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "agencyconnections",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnectionArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "agencyjoinrequests",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "destinedBaseOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "intakeSubmissions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "payers",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayersArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "suppliesOrderables",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocation",
        "fields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "agency",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agency",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "mobile",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralsToLocation",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referral",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referralsToLocationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "referralAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyLocation",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocationAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocation_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocation_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocation_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocation_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocation_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocation_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocation_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocation_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocation_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocation_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocationArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyLocationInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationAvgFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocationBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "address",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "mobile",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "referralsToLocation",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralBoolExp",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocationIncInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocationInsertInput",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "mobile",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "referralsToLocation",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationMaxFields",
        "fields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationMinFields",
        "fields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyLocation",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocationObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyLocationInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocationOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocationBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocationOrderBy",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "mobile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralsToLocationAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocationPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocationSetInput",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "mobile",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationStddevFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationStddev_popFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationStddev_sampFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationSumFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocationUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocationIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocationSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyLocationBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationVar_popFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationVar_sampFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyLocationVarianceFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocation_avg_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocation_max_order_by",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocation_min_order_by",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocation_stddev_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocation_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocation_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocation_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyLocation_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocation_streamCursorValueInput",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "mobile",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocation_sum_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocation_var_pop_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocation_var_samp_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyLocation_variance_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radius",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyMaxFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyMinFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agency",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyOrderBy",
        "inputFields": [
          {
            "name": "agencyLocationsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocationAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "agencyServicesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServicesAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "agencyconnectionsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnectionAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "agencyjoinrequestsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinedBaseOrdersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakeSubmissionsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayersAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "suppliesOrderablesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayers",
        "fields": [
          {
            "name": "agency",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agency",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "payer",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "payer",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyPayers",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayersAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayers_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayers_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayers_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayers_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayers_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayers_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayers_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayers_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayers_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayers_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayersArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyPayersInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayersOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersAvgFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayersBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayersBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "payer",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayersIncInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayersInsertInput",
        "inputFields": [
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "payer",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersMaxFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersMinFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyPayers",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayersOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayersBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayersOrderBy",
        "inputFields": [
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payer",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayersPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayersSetInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersStddevFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersStddev_popFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersStddev_sampFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersSumFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayersUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayersIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayersSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyPayersBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersVar_popFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersVar_sampFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyPayersVarianceFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayers_avg_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayers_max_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayers_min_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayers_stddev_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayers_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayers_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayers_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyPayers_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayers_streamCursorValueInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayers_sum_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayers_var_pop_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayers_var_samp_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPayers_variance_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyServices",
        "fields": [
          {
            "name": "agency",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agency",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "serviceType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "serviceType",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyServices",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServicesAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServices_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServices_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServices_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServices_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServices_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServices_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServices_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServices_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServices_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServices_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServicesArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyServicesInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServicesOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesAvgFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServicesBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServicesBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "serviceType",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "serviceTypeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServicesIncInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServicesInsertInput",
        "inputFields": [
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "serviceType",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "serviceTypeObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesMaxFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesMinFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyServices",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServicesOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServicesBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServicesOrderBy",
        "inputFields": [
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "serviceType",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "serviceTypeOrderBy",
              "ofType": null
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServicesPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServicesSetInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesStddevFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesStddev_popFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesStddev_sampFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesSumFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServicesUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServicesIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServicesSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyServicesBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesVar_popFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesVar_sampFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyServicesVarianceFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServices_avg_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServices_max_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServices_min_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServices_stddev_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServices_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServices_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServices_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyServices_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServices_streamCursorValueInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServices_sum_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServices_var_pop_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServices_var_samp_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyServices_variance_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "servicetypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencySetInput",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyStddevFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyStddev_popFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyStddev_sampFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencySumFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencySetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyVar_popFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyVar_sampFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyVarianceFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agency_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agency_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agency_streamCursorValueInput",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notificationMode",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnection",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "agency",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyconnection",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnectionAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnection_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnection_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnection_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnection_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnection_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnection_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnection_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnection_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnection_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnection_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnectionArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyconnectionInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnectionOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionAvgFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnectionBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnectionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnectionIncInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnectionInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionMaxFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionMinFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyconnection",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnectionOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnectionBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnectionOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnectionPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnectionSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionStddevFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionStddev_popFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionStddev_sampFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionSumFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnectionUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnectionIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnectionSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyconnectionBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionVar_popFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionVar_sampFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyconnectionVarianceFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnection_avg_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnection_max_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnection_min_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnection_stddev_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnection_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnection_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnection_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyconnection_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnection_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnection_sum_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnection_var_pop_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnection_var_samp_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyconnection_variance_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequest",
        "fields": [
          {
            "name": "agency",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agency",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "approvalStaffMember",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaff",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approved",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "dateApproved",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateSubmitted",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "externalstaff",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalstaff",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyjoinrequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequestAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequest_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequest_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequest_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequest_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequest_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequest_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequest_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequest_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequest_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequest_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequestArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyjoinrequestInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestAvgFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequestBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "approvalStaffMember",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffBoolExp",
              "ofType": null
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "approved",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateApproved",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateSubmitted",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequestIncInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequestInsertInput",
        "inputFields": [
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalStaffMember",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approved",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "dateApproved",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestMaxFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateApproved",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestMinFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateApproved",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyjoinrequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequestOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequestOrderBy",
        "inputFields": [
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalStaffMember",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffOrderBy",
              "ofType": null
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approved",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateApproved",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequestPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequestSetInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approved",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "dateApproved",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestStddevFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestStddev_popFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestStddev_sampFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestSumFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequestUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyjoinrequestBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestVar_popFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestVar_sampFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "agencyjoinrequestVarianceFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequest_avg_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequest_max_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateApproved",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequest_min_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateApproved",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequest_stddev_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequest_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequest_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequest_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "agencyjoinrequest_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequest_streamCursorValueInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approved",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "dateApproved",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequest_sum_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequest_var_pop_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequest_var_samp_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "agencyjoinrequest_variance_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "allergy",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "allergyCategory",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "enteredProvider",
            "type": {
              "kind": "OBJECT",
              "name": "provider",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "publicDbId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "reaction",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "allergyAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "allergyAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "allergy",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "allergyAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "allergyAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "allergyMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "allergyMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "allergyStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "allergyStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "allergyStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "allergySumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "allergyVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "allergyVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "allergyVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergy_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergy_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergy_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergy_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergy_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergy_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergy_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergy_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergy_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergy_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyAppendInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "allergyInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergyOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "allergyAvgFields",
        "fields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "allergyCategory",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "enteredProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "publicDbId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "reaction",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyDeleteAtPathInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyDeleteElemInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyDeleteKeyInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyIncInput",
        "inputFields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "enteredProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "publicDbId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "reaction",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "allergyMaxFields",
        "fields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "publicDbId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "reaction",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "allergyMinFields",
        "fields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "publicDbId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "reaction",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "allergyMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "allergy",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergyBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteredProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "publicDbId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "reaction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyPrependInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergySetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "publicDbId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "reaction",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "allergyStddevFields",
        "fields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "allergyStddev_popFields",
        "fields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "allergyStddev_sampFields",
        "fields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "allergySumFields",
        "fields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergyUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergyAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergyDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergyDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergyDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergyIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergyPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergySetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "allergyBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "allergyVar_popFields",
        "fields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "allergyVar_sampFields",
        "fields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "allergyVarianceFields",
        "fields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergy_avg_order_by",
        "inputFields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergy_max_order_by",
        "inputFields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "publicDbId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "reaction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergy_min_order_by",
        "inputFields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "publicDbId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "reaction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergy_stddev_order_by",
        "inputFields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergy_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergy_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergy_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "allergy_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergy_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "publicDbId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "reaction",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergy_sum_order_by",
        "inputFields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergy_var_pop_order_by",
        "inputFields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergy_var_samp_order_by",
        "inputFields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "allergy_variance_order_by",
        "inputFields": [
          {
            "name": "allergyCategory",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "appointment",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "appointmentDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedStatusAction",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusAction",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "baseorders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseorder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseordersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretime",
            "type": {
              "kind": "OBJECT",
              "name": "caretime",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "childAppointments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "appointment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "childAppointmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "appointmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "claim",
            "type": {
              "kind": "OBJECT",
              "name": "claim",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "classification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnote",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "maxDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "minDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "newVisit",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "parentAppointment",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "proceduresRendered",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureRendered",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRenderedOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "proceduresRenderedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureRenderedAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRenderedOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "provider",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "provider",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "rebillsResultedHere",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "visitRebill",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "visitRebillOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "rebillsResultedHereAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "visitRebillAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "visitRebillOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "schedulingActions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "schedulingAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "schedulingActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "schedulingActionsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "schedulingActionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "schedulingActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequest",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "tentativeDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeIn",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeOut",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanAssessments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentPlanAssessmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "virtualVisit",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "visitRebills",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "visitRebill",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "visitRebillOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "visitRebillsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "visitRebillAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "visitRebillOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "vitals",
            "type": {
              "kind": "OBJECT",
              "name": "vitals",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "appointmentAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "appointment",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "appointmentAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointment_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointment_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointment_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointment_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointment_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointment_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointment_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointment_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointment_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointment_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentAppendInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "appointmentInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "appointmentAvgFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "appointmentDatetime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "associatedStatusAction",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "baseorders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "caretime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "childAppointments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "claim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimBoolExp",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteBoolExp",
              "ofType": null
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "maxDueDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "minDueDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "newVisit",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "parentAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "proceduresRendered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedBoolExp",
              "ofType": null
            }
          },
          {
            "name": "provider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "rebillsResultedHere",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillBoolExp",
              "ofType": null
            }
          },
          {
            "name": "schedulingActions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingActionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestBoolExp",
              "ofType": null
            }
          },
          {
            "name": "tentativeDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "timeIn",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimeComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "timeOut",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimeComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanAssessments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "virtualVisit",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "visitRebills",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillBoolExp",
              "ofType": null
            }
          },
          {
            "name": "vitals",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "vitalsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentDeleteAtPathInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentDeleteElemInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentDeleteKeyInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentIncInput",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "appointmentDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "associatedStatusAction",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "baseorders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "caretime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "childAppointments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "claim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "maxDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "minDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "newVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "parentAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "proceduresRendered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "provider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rebillsResultedHere",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "schedulingActions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingActionArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "tentativeDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "timeIn",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "timeOut",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanAssessments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "virtualVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "visitRebills",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "vitals",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "vitalsObjRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "appointmentMaxFields",
        "fields": [
          {
            "name": "appointmentDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "maxDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "minDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "tentativeDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "appointmentMinFields",
        "fields": [
          {
            "name": "appointmentDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "maxDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "minDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "tentativeDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "appointmentMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "appointment",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "appointmentInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "appointmentDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedStatusAction",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionOrderBy",
              "ofType": null
            }
          },
          {
            "name": "baseordersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "caretime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeOrderBy",
              "ofType": null
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "childAppointmentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "claim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimOrderBy",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteOrderBy",
              "ofType": null
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "maxDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "minDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "newVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "proceduresRenderedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "provider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rebillsResultedHereAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "schedulingActionsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingActionAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestOrderBy",
              "ofType": null
            }
          },
          {
            "name": "tentativeDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "timeIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "timeOut",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanAssessmentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "virtualVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitRebillsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "vitals",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "vitalsOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentPrependInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "appointmentDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "maxDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "minDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "newVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "tentativeDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "timeIn",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "timeOut",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "virtualVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "appointmentStddevFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "appointmentStddev_popFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "appointmentStddev_sampFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "appointmentSumFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointmentUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "appointmentBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "appointmentVar_popFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "appointmentVar_sampFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "appointmentVarianceFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointment_avg_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointment_max_order_by",
        "inputFields": [
          {
            "name": "appointmentDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "maxDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "minDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "tentativeDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointment_min_order_by",
        "inputFields": [
          {
            "name": "appointmentDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "maxDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "minDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "tentativeDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointment_stddev_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointment_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointment_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointment_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "appointment_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointment_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "appointmentDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "maxDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "minDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "newVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "tentativeDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "timeIn",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "timeOut",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "virtualVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointment_sum_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointment_var_pop_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointment_var_samp_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "appointment_variance_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMaxDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followUpMinDays",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "outsideTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typePreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessment",
        "fields": [
          {
            "name": "assessmentItems",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentItemsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentItemAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "associatedIcdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalNoteTemplates",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalNoteTemplatesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplateAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroup",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "renderedAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "renderedAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "renderedAssessmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "renderedAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "renderedAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentPlanTemplates",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanTemplateAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentPlanTemplatesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanTemplateAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessment_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessment_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessment_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessment_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessment_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessment_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessment_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessment_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessment_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessment_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "assessmentItems",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemBoolExp",
              "ofType": null
            }
          },
          {
            "name": "associatedIcdCodes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "_varcharComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteTemplates",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanTemplates",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessmentBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentInsertInput",
        "inputFields": [
          {
            "name": "assessmentItems",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "associatedIcdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteTemplates",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanTemplates",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessmentArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItem",
        "fields": [
          {
            "name": "assessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessment",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "fields",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItemValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "fieldsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentItemValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "insertText",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItem",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItem_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItem_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItem_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItem_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItem_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItem_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItem_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItem_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItem_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItem_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemAppendInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentItemInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemAvgFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "assessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "fields",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValueBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemDeleteAtPathInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemDeleteElemInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemDeleteKeyInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemIncInput",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemInsertInput",
        "inputFields": [
          {
            "name": "assessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "fields",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValueArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemMaxFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemMinFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItem",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "assessmentItemInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemOrderBy",
        "inputFields": [
          {
            "name": "assessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValueAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemPrependInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemSetInput",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemStddevFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemStddev_popFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemStddev_sampFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemSumFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "assessmentItemBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValue",
        "fields": [
          {
            "name": "assessmentItemField",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentItem",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "renderedAssessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "renderedAssessment",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "show",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItemValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValueAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValue_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValue_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValue_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValue_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValue_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValue_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValue_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValue_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValue_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValue_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValueArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentItemValueInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValueOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueAvgFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValueBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValueBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "assessmentItemField",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemBoolExp",
              "ofType": null
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValueIncInput",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValueInsertInput",
        "inputFields": [
          {
            "name": "assessmentItemField",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueMaxFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueMinFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItemValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValueOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValueBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValueOrderBy",
        "inputFields": [
          {
            "name": "assessmentItemField",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemOrderBy",
              "ofType": null
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValuePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValueSetInput",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueStddevFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueStddev_popFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueStddev_sampFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueSumFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValueUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValueIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValueSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "assessmentItemValueBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueVar_popFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueVar_sampFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemValueVarianceFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValue_avg_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValue_max_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValue_min_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValue_stddev_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValue_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValue_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValue_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "assessmentItemValue_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValue_streamCursorValueInput",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValue_sum_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValue_var_pop_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValue_var_samp_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItemValue_variance_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemVar_popFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemVar_sampFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentItemVarianceFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItem_avg_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItem_max_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItem_min_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItem_stddev_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItem_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItem_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItem_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "assessmentItem_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItem_streamCursorValueInput",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItem_sum_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItem_var_pop_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItem_var_samp_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentItem_variance_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "assessmentInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentOrderBy",
        "inputFields": [
          {
            "name": "assessmentItemsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "associatedIcdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalNoteTemplatesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOrderBy",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanTemplatesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessmentAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentSetInput",
        "inputFields": [
          {
            "name": "associatedIcdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessmentUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "assessmentBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "assessmentVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "assessmentVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessment_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessment_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessment_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessment_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessment_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessment_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessment_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "assessment_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessment_streamCursorValueInput",
        "inputFields": [
          {
            "name": "associatedIcdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessment_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessment_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessment_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "assessment_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrder",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "dependantOrder",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseProtocolOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderAvgFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseProtocolOrderBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseProtocolOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntervalComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "parentOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseProtocolOrderIncInput",
        "inputFields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseProtocolOrderInsertInput",
        "inputFields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "parentOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderObjRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderMaxFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderMinFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseProtocolOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseProtocolOrderObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "baseProtocolOrderInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseProtocolOrderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseProtocolOrderOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseProtocolOrderBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseProtocolOrderOrderBy",
        "inputFields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseProtocolOrderPkColumnsInput",
        "inputFields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseProtocolOrderSetInput",
        "inputFields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderStddevFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderStddev_popFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderStddev_sampFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderSumFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseProtocolOrderUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseProtocolOrderIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseProtocolOrderSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "baseProtocolOrderBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderVar_popFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderVar_sampFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseProtocolOrderVarianceFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseProtocolOrder_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "baseProtocolOrder_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseProtocolOrder_streamCursorValueInput",
        "inputFields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "baseorder",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalAction",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "OBJECT",
                  "name": "orderaction",
                  "ofType": null
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderactionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "approved",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "archivalApproved",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "archivalData",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "archivalType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "associatedAppointment",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "calculatedEffectiveDate",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "cancelled",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "childOrders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "dependantOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "dependantOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "childOrdersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "dependantOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "dependantOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dependantOrder",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrder",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationAgency",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expirationDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expired",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "freetextOrder",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrder",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "historicalOrder",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrder",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "icdCodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "_varchar",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "medicationOrder",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrder",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderActions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderaction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderactionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderActionsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderactionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderactionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderQualifications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderQualificationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableOrderQualificationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderableOrder",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrder",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThread",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "validTime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "interval",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseorderAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseorder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseorderAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorder_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorder_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorder_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorder_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorder_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorder_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorder_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorder_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorder_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorder_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderAppendInput",
        "inputFields": [
          {
            "name": "archivalData",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "baseorderInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "baseorderAvgFields",
        "fields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "approvalAction",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "approved",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "archivalApproved",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "archivalData",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "archivalType",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "associatedAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "calculatedEffectiveDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "cancelled",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "childOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dependantOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "destinationAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "expirationDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "expired",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "freetextOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "freeTextOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "historicalOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "icdCodes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "_varcharComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "medicationOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderActions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderQualifications",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualificationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderableOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          },
          {
            "name": "validTime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntervalComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderDeleteAtPathInput",
        "inputFields": [
          {
            "name": "archivalData",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderDeleteElemInput",
        "inputFields": [
          {
            "name": "archivalData",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderDeleteKeyInput",
        "inputFields": [
          {
            "name": "archivalData",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderIncInput",
        "inputFields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderInsertInput",
        "inputFields": [
          {
            "name": "archivalApproved",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "archivalData",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "associatedAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "childOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dependantOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "destinationAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "freetextOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "freeTextOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "historicalOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "icdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicationOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderActions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderQualifications",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualificationArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderableOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "validTime",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "baseorderMaxFields",
        "fields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseorderMinFields",
        "fields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseorderMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseorder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "baseorderInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalActionAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "approved",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "archivalApproved",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "archivalData",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "calculatedEffectiveDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "cancelled",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "childOrdersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dependantOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "destinationAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expirationDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expired",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "freetextOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "freeTextOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "historicalOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "icdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderActionsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderQualificationsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualificationAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadOrderBy",
              "ofType": null
            }
          },
          {
            "name": "validTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderPrependInput",
        "inputFields": [
          {
            "name": "archivalData",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderSetInput",
        "inputFields": [
          {
            "name": "archivalApproved",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "archivalData",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "icdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "validTime",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "baseorderStddevFields",
        "fields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseorderStddev_popFields",
        "fields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseorderStddev_sampFields",
        "fields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseorderSumFields",
        "fields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorderUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "baseorderBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "baseorderVar_popFields",
        "fields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseorderVar_sampFields",
        "fields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "baseorderVarianceFields",
        "fields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorder_avg_order_by",
        "inputFields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorder_max_order_by",
        "inputFields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorder_min_order_by",
        "inputFields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorder_stddev_order_by",
        "inputFields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorder_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorder_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorder_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "baseorder_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorder_streamCursorValueInput",
        "inputFields": [
          {
            "name": "archivalApproved",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "archivalData",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "icdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "validTime",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorder_sum_order_by",
        "inputFields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorder_var_pop_order_by",
        "inputFields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorder_var_samp_order_by",
        "inputFields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "baseorder_variance_order_by",
        "inputFields": [
          {
            "name": "archivalType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "historicalOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "polymorphicCtypeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "caretime",
        "fields": [
          {
            "name": "appointments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "appointment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "appointmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "appointmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "claim",
            "type": {
              "kind": "OBJECT",
              "name": "claim",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "duration",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "message",
            "type": {
              "kind": "OBJECT",
              "name": "message",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientCommunications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientCommunication",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientCommunicationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientCommunicationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientCommunicationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientCommunicationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problems",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretimeProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problemsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeProblemsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "resultedFromRebills",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "visitRebill",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "visitRebillOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "resultedFromRebillsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "visitRebillAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "visitRebillOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThread",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "user",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretime",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretime_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretime_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretime_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretime_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretime_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretime_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretime_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretime_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretime_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretime_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "caretimeInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "caretimeAvgFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "appointments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "claim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimBoolExp",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "message",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageBoolExp",
              "ofType": null
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientCommunications",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunicationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "problems",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblemsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "resultedFromRebills",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          },
          {
            "name": "user",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeIncInput",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeInsertInput",
        "inputFields": [
          {
            "name": "appointments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "claim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "message",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientCommunications",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunicationArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "problems",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblemsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "resultedFromRebills",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "user",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "caretimeMaxFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeMinFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretime",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "caretimeInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeOrderBy",
        "inputFields": [
          {
            "name": "appointmentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "claim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimOrderBy",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "message",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageOrderBy",
              "ofType": null
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientCommunicationsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunicationAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblemsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "resultedFromRebillsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadOrderBy",
              "ofType": null
            }
          },
          {
            "name": "user",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblems",
        "fields": [
          {
            "name": "caretime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretime",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "problem",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "problem",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretimeProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblemsAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblems_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblems_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblems_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblems_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblems_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblems_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblems_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblems_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblems_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblems_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblemsArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "caretimeProblemsInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblemsOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsAvgFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblemsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblemsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "caretime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "problem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemBoolExp",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblemsIncInput",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblemsInsertInput",
        "inputFields": [
          {
            "name": "caretime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "problem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsMaxFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsMinFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretimeProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblemsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblemsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblemsOrderBy",
        "inputFields": [
          {
            "name": "caretime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeOrderBy",
              "ofType": null
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemOrderBy",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblemsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblemsSetInput",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsStddevFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsStddev_popFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsStddev_sampFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsSumFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblemsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblemsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblemsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "caretimeProblemsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsVar_popFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsVar_sampFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeProblemsVarianceFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblems_avg_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblems_max_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblems_min_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblems_stddev_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblems_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblems_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblems_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "caretimeProblems_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblems_streamCursorValueInput",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblems_sum_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblems_var_pop_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblems_var_samp_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeProblems_variance_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeSetInput",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "caretimeStddevFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeStddev_popFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeStddev_sampFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeSumFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretimeUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "caretimeBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "caretimeVar_popFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeVar_sampFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "caretimeVarianceFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretime_avg_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretime_max_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretime_min_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretime_stddev_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretime_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretime_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretime_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "caretime_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretime_streamCursorValueInput",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretime_sum_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretime_var_pop_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretime_var_samp_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "caretime_variance_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "claim",
        "fields": [
          {
            "name": "appointments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "appointment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "appointmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "appointmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "billingProvider",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "provider",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "billingProviderId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretime",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "cpoClaim",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaim",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateofservice",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "icdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "lineitems",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "lineitem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "lineitemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "lineitemsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "lineitemAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "lineitemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "wellnessIcd",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "claimAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "claimAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "claim",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "claimAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "claimAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "claimMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "claimMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "claimStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "claimStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "claimStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "claimSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "claimVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "claimVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "claimVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claimAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claim_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claim_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claim_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claim_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claim_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claim_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claim_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claim_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claim_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claim_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claimArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "claimInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "claimAvgFields",
        "fields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claimBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "appointments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "billingProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "billingProviderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "cpoClaim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "cpoClaimBoolExp",
              "ofType": null
            }
          },
          {
            "name": "dateofservice",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "icdCodes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "_varcharComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "lineitems",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "wellnessIcd",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claimIncInput",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claimInsertInput",
        "inputFields": [
          {
            "name": "appointments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "billingProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "cpoClaim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "cpoClaimObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "dateofservice",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "icdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "lineitems",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "wellnessIcd",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "claimMaxFields",
        "fields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateofservice",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "wellnessIcd",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "claimMinFields",
        "fields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateofservice",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "wellnessIcd",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "claimMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "claim",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claimObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "claimInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claimOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claimOrderBy",
        "inputFields": [
          {
            "name": "appointmentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "billingProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "caretimesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "cpoClaim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "cpoClaimOrderBy",
              "ofType": null
            }
          },
          {
            "name": "dateofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "icdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lineitemsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "wellnessIcd",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claimPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claimSetInput",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "dateofservice",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "icdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "wellnessIcd",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "claimStddevFields",
        "fields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "claimStddev_popFields",
        "fields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "claimStddev_sampFields",
        "fields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "claimSumFields",
        "fields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claimUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "claimBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "claimVar_popFields",
        "fields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "claimVar_sampFields",
        "fields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "claimVarianceFields",
        "fields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claim_avg_order_by",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claim_max_order_by",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "wellnessIcd",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claim_min_order_by",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "wellnessIcd",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claim_stddev_order_by",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claim_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claim_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claim_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "claim_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claim_streamCursorValueInput",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "dateofservice",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "icdCodes",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "wellnessIcd",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claim_sum_order_by",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claim_var_pop_order_by",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claim_var_samp_order_by",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "claim_variance_order_by",
        "inputFields": [
          {
            "name": "billingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "placeOfService",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferrals",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnote",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "referral",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "referral",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "sent",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalNoteFutureReferrals",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferralsAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferrals_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferrals_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferrals_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferrals_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferrals_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferrals_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferrals_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferrals_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferrals_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferrals_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferralsArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalNoteFutureReferralsInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferralsOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsAvgFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferralsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferralsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "referral",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralBoolExp",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "sent",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferralsIncInput",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferralsInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referral",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "sent",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsMaxFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsMinFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalNoteFutureReferrals",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferralsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferralsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferralsOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referral",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralOrderBy",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "sent",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferralsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferralsSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "sent",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsStddevFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsStddev_popFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsStddev_sampFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsSumFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferralsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferralsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferralsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalNoteFutureReferralsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsVar_popFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsVar_sampFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalNoteFutureReferralsVarianceFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferrals_avg_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferrals_max_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferrals_min_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferrals_stddev_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferrals_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferrals_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferrals_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalNoteFutureReferrals_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferrals_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "sent",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferrals_sum_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferrals_var_pop_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferrals_var_samp_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalNoteFutureReferrals_variance_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnote",
        "fields": [
          {
            "name": "appointment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "appointment",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "clinicalnoteFieldValues",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldValuesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteFieldValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentsClinicalNote",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "document",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentsClinicalNoteAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "futureReferrals",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalNoteFutureReferrals",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalNoteFutureReferralsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "futureReferralsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalNoteFutureReferralsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalNoteFutureReferralsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "locked",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "lockedDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pdfDocument",
            "type": {
              "kind": "OBJECT",
              "name": "document",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemPlans",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problemPlan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemPlanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problemPlansAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "problemPlanAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemPlanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoffs",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signoff",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoffsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "signoffAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnote",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteAvgFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteFieldValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValueBoolExp",
              "ofType": null
            }
          },
          {
            "name": "documentsClinicalNote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "futureReferrals",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferralsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "locked",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "lockedDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "pdfDocument",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "problemPlans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlanBoolExp",
              "ofType": null
            }
          },
          {
            "name": "signoffs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteField",
        "fields": [
          {
            "name": "clinicalnoteTemplate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplate",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "fieldvalues",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "fieldvaluesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteFieldValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "insertText",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "templateId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteField",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteField_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteField_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteField_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteField_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteField_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteField_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteField_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteField_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteField_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteField_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldAppendInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteFieldInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "clinicalnoteTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateBoolExp",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "fieldvalues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValueBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldDeleteAtPathInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldDeleteElemInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldDeleteKeyInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldInsertInput",
        "inputFields": [
          {
            "name": "clinicalnoteTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "fieldvalues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValueArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteField",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnoteFieldInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldOrderBy",
        "inputFields": [
          {
            "name": "clinicalnoteTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldvaluesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValueAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldPrependInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldSetInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnoteFieldBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValue",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnote",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "clinicalnoteField",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteField",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "show",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValueAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValue_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValue_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValue_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValue_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValue_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValue_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValue_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValue_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValue_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValue_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValueArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteFieldValueInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValueOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueAvgFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValueBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValueBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteBoolExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteField",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldBoolExp",
              "ofType": null
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValueIncInput",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValueInsertInput",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteField",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueMaxFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueMinFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValueOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValueBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValueOrderBy",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteOrderBy",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteField",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldOrderBy",
              "ofType": null
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValuePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValueSetInput",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueStddevFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueStddev_popFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueStddev_sampFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueSumFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValueUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValueIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValueSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnoteFieldValueBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueVar_popFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueVar_sampFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldValueVarianceFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValue_avg_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValue_max_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValue_min_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValue_stddev_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValue_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValue_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValue_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnoteFieldValue_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValue_streamCursorValueInput",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValue_sum_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValue_var_pop_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValue_var_samp_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteFieldValue_variance_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteFieldVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteField_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteField_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteField_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteField_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteField_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteField_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteField_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnoteField_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteField_streamCursorValueInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteField_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteField_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteField_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteField_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInTemplate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "templateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteIncInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteInsertInput",
        "inputFields": [
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteFieldValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValueArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "documentsClinicalNote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "futureReferrals",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferralsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "locked",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "lockedDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "pdfDocument",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "problemPlans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlanArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "signoffs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteMaxFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lockedDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteMinFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lockedDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnote",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnoteInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteOrderBy",
        "inputFields": [
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnoteFieldValuesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldValueAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "documentsClinicalNoteAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "futureReferralsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferralsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "locked",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lockedDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "pdfDocument",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemPlansAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlanAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "signoffsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnotePkColumnsInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteSetInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "locked",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "lockedDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteStddevFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteStddev_popFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteStddev_sampFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteSumFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplate",
        "fields": [
          {
            "name": "assessment",
            "type": {
              "kind": "OBJECT",
              "name": "assessment",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalnoteFields",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteField",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteFieldAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroup",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedure",
            "type": {
              "kind": "OBJECT",
              "name": "procedure",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerClassifications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplateProviderClassifications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateProviderClassificationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerClassificationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplateProviderClassificationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateProviderClassificationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "showInPhysical",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "showInVirtual",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplate_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplate_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplate_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplate_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplate_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplate_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplate_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplate_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplate_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplate_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteTemplateInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateAvgFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "assessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteFields",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "procedure",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureBoolExp",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "providerClassifications",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "showInPhysical",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "showInVirtual",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateIncInput",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateInsertInput",
        "inputFields": [
          {
            "name": "assessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteFields",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "procedure",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerClassifications",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "showInPhysical",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "showInVirtual",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateMaxFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateMinFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnoteTemplateInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateOrderBy",
        "inputFields": [
          {
            "name": "assessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnoteFieldsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteFieldAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOrderBy",
              "ofType": null
            }
          },
          {
            "name": "procedure",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureOrderBy",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerClassificationsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "showInPhysical",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "showInVirtual",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplatePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications",
        "fields": [
          {
            "name": "clinicalnoteTemplate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplate",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "providerClassification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerClassification",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplateProviderClassifications",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteTemplateProviderClassificationsInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsAvgFields",
        "fields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "clinicalnoteTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateBoolExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "providerClassification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerClassificationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsIncInput",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsInsertInput",
        "inputFields": [
          {
            "name": "clinicalnoteTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerClassification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerClassificationObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsMaxFields",
        "fields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsMinFields",
        "fields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplateProviderClassifications",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsOrderBy",
        "inputFields": [
          {
            "name": "clinicalnoteTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerClassification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerClassificationOrderBy",
              "ofType": null
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsSetInput",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsStddevFields",
        "fields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsStddev_popFields",
        "fields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsStddev_sampFields",
        "fields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsSumFields",
        "fields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsVar_popFields",
        "fields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsVar_sampFields",
        "fields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateProviderClassificationsVarianceFields",
        "fields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications_avg_order_by",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications_max_order_by",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications_min_order_by",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications_stddev_order_by",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnoteTemplateProviderClassifications_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications_streamCursorValueInput",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications_sum_order_by",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications_var_pop_order_by",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications_var_samp_order_by",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateProviderClassifications_variance_order_by",
        "inputFields": [
          {
            "name": "clinicalnotetemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerclassificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateSetInput",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "showInPhysical",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "showInVirtual",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateStddevFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateStddev_popFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateStddev_sampFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateSumFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplateUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnoteTemplateBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateVar_popFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateVar_sampFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteTemplateVarianceFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplate_avg_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplate_max_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplate_min_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplate_stddev_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplate_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplate_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplate_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnoteTemplate_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplate_streamCursorValueInput",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "showInPhysical",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "showInVirtual",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplate_sum_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplate_var_pop_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplate_var_samp_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteTemplate_variance_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderInNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnoteUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnoteBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteVar_popFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteVar_sampFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "clinicalnoteVarianceFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnote_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "clinicalnote_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "clinicalnote_streamCursorValueInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "locked",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "lockedDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "pdfId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codeset",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddon",
        "fields": [
          {
            "name": "codeset",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "codeset",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "codesetId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "hcpcs",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codesetAddon",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddonAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddon_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddon_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddon_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddon_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddon_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddon_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddon_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddon_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddon_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddon_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddonArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetAddonInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddonOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonAvgFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddonBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddonBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "codeset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBoolExp",
              "ofType": null
            }
          },
          {
            "name": "codesetId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddonIncInput",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddonInsertInput",
        "inputFields": [
          {
            "name": "codeset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonMaxFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonMinFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codesetAddon",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddonOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddonBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddonOrderBy",
        "inputFields": [
          {
            "name": "codeset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetOrderBy",
              "ofType": null
            }
          },
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsOrderBy",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddonPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddonSetInput",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonStddevFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonStddev_popFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonStddev_sampFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonSumFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddonUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddonIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddonSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "codesetAddonBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonVar_popFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonVar_sampFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetAddonVarianceFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddon_avg_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddon_max_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddon_min_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddon_stddev_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddon_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddon_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddon_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "codesetAddon_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddon_streamCursorValueInput",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddon_sum_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddon_var_pop_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddon_var_samp_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetAddon_variance_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codesetAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codeset",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "codesetMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "codesetMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "codesetStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "codesetStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "codesetStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "codesetSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "codesetVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "codesetVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "codesetVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetBase",
        "fields": [
          {
            "name": "codeset",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "codeset",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "codesetId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "hcpcs",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codesetBase",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBaseAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBase_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBase_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBase_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBase_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBase_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBase_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBase_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBase_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBase_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBase_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBaseArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetBaseInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBaseOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseAvgFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBaseBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBaseBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "codeset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBoolExp",
              "ofType": null
            }
          },
          {
            "name": "codesetId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBaseIncInput",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBaseInsertInput",
        "inputFields": [
          {
            "name": "codeset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseMaxFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseMinFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codesetBase",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBaseOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBaseBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBaseOrderBy",
        "inputFields": [
          {
            "name": "codeset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetOrderBy",
              "ofType": null
            }
          },
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsOrderBy",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBasePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBaseSetInput",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseStddevFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseStddev_popFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseStddev_sampFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseSumFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBaseUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBaseIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBaseSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "codesetBaseBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseVar_popFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseVar_sampFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetBaseVarianceFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBase_avg_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBase_max_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBase_min_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBase_stddev_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBase_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBase_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBase_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "codesetBase_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBase_streamCursorValueInput",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBase_sum_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBase_var_pop_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBase_var_samp_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBase_variance_order_by",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetInsertInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codesetMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codeset",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "codesetInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetOrderBy",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetSetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codesetStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codesetUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "codesetBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "codesetVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "codesetVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codeset_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "codeset_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "codeset_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "consent",
        "fields": [
          {
            "name": "consentType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "practicegroup",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "requiredPhysicalVisit",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "requiredVirtualVisit",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "signedConsents",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signedConsent",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signedConsentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signedConsentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "signedConsentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signedConsentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "consentAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "consentAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "consent",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "consentAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "consentAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "consentMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "consentMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "consentStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "consentStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "consentStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "consentSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "consentVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "consentVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "consentVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consentAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consent_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consent_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consent_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consent_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consent_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consent_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consent_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consent_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consent_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consent_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consentArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "consentInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "consentAvgFields",
        "fields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consentBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "consentType",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "requiredPhysicalVisit",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "requiredVirtualVisit",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "signedConsents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consentIncInput",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consentInsertInput",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "requiredPhysicalVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "requiredVirtualVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "signedConsents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "consentMaxFields",
        "fields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "consentMinFields",
        "fields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "consentMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "consent",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consentObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "consentInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consentOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consentBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consentOrderBy",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOrderBy",
              "ofType": null
            }
          },
          {
            "name": "requiredPhysicalVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requiredVirtualVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedConsentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consentPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consentSetInput",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "requiredPhysicalVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "requiredVirtualVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "consentStddevFields",
        "fields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "consentStddev_popFields",
        "fields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "consentStddev_sampFields",
        "fields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "consentSumFields",
        "fields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consentUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consentIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consentSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "consentBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "consentVar_popFields",
        "fields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "consentVar_sampFields",
        "fields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "consentVarianceFields",
        "fields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consent_avg_order_by",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consent_max_order_by",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consent_min_order_by",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consent_stddev_order_by",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consent_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consent_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consent_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "consent_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consent_streamCursorValueInput",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "requiredPhysicalVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "requiredVirtualVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consent_sum_order_by",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consent_var_pop_order_by",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consent_var_samp_order_by",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "consent_variance_order_by",
        "inputFields": [
          {
            "name": "consentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaim",
        "fields": [
          {
            "name": "claim",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "claim",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "claimPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "date",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "externalSignedOrders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalSignedOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalSignedOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalSignedOrdersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalSignedOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalSignedOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "startDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "date",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "cpoClaim",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimAvgFields",
        "fields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "cpoClaimBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "cpoClaimBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "claim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimBoolExp",
              "ofType": null
            }
          },
          {
            "name": "claimPtrId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalSignedOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "cpoClaimIncInput",
        "inputFields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "cpoClaimInsertInput",
        "inputFields": [
          {
            "name": "claim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "externalSignedOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimMaxFields",
        "fields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimMinFields",
        "fields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "cpoClaim",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "cpoClaimObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "cpoClaimInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "cpoClaimOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "cpoClaimOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "cpoClaimBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "cpoClaimOrderBy",
        "inputFields": [
          {
            "name": "claim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimOrderBy",
              "ofType": null
            }
          },
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalSignedOrdersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "cpoClaimPkColumnsInput",
        "inputFields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "cpoClaimSetInput",
        "inputFields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimStddevFields",
        "fields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimStddev_popFields",
        "fields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimStddev_sampFields",
        "fields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimSumFields",
        "fields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "cpoClaimUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "cpoClaimIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "cpoClaimSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "cpoClaimBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimVar_popFields",
        "fields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimVar_sampFields",
        "fields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "cpoClaimVarianceFields",
        "fields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "cpoClaim_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "cpoClaim_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "cpoClaim_streamCursorValueInput",
        "inputFields": [
          {
            "name": "claimPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "SCALAR",
        "name": "date"
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrder",
        "fields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "parentOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "dependantOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrderAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrder_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrder_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrder_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrder_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrder_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrder_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrder_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrder_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrder_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrder_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrderArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "dependantOrderInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderAvgFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrderBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "parentOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrderIncInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrderInsertInput",
        "inputFields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "parentOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderMaxFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderMinFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "dependantOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrderObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "dependantOrderInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrderOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrderOrderBy",
        "inputFields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrderPkColumnsInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrderSetInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderStddevFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderStddev_popFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderStddev_sampFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderSumFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrderUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "dependantOrderBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderVar_popFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderVar_sampFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "dependantOrderVarianceFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrder_avg_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrder_max_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrder_min_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrder_stddev_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrder_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrder_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrder_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "dependantOrder_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrder_streamCursorValueInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrder_sum_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrder_var_pop_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrder_var_samp_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "dependantOrder_variance_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentOrderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviation",
        "fields": [
          {
            "name": "abbreviation",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "substitution",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "diagnosisAbbreviation",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "diagnosisAbbreviationBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "diagnosisAbbreviationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "abbreviation",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "substitution",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "diagnosisAbbreviationIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "diagnosisAbbreviationInsertInput",
        "inputFields": [
          {
            "name": "abbreviation",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "substitution",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationMaxFields",
        "fields": [
          {
            "name": "abbreviation",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "substitution",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationMinFields",
        "fields": [
          {
            "name": "abbreviation",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "substitution",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "diagnosisAbbreviation",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "diagnosisAbbreviationOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "diagnosisAbbreviationBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "diagnosisAbbreviationOrderBy",
        "inputFields": [
          {
            "name": "abbreviation",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "substitution",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "diagnosisAbbreviationPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "diagnosisAbbreviationSetInput",
        "inputFields": [
          {
            "name": "abbreviation",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "substitution",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "diagnosisAbbreviationUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "diagnosisAbbreviationIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "diagnosisAbbreviationSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "diagnosisAbbreviationBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "diagnosisAbbreviationVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "diagnosisAbbreviation_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "diagnosisAbbreviation_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "diagnosisAbbreviation_streamCursorValueInput",
        "inputFields": [
          {
            "name": "abbreviation",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "substitution",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "document",
        "fields": [
          {
            "name": "archived",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "category",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnote",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contentType",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "location",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "message",
            "type": {
              "kind": "OBJECT",
              "name": "message",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "order",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrder",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pendingReviews",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "documentPendingReview",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReviewOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "pendingReviewsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentPendingReviewAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReviewOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketthread",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThread",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "documentAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "document",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "documentAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "documentMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "documentMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "documentStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "documentStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "documentStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "documentSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "documentVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "documentVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "documentVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "document_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "document_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "document_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "document_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "document_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "document_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "document_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "document_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "document_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "document_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentAppendInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "documentInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "documentAvgFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "archived",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteBoolExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "contentType",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "location",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "message",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageBoolExp",
              "ofType": null
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "order",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "pendingReviews",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ticketthread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentDeleteAtPathInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentDeleteElemInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentDeleteKeyInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentIncInput",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentInsertInput",
        "inputFields": [
          {
            "name": "archived",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "contentType",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "location",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "message",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "order",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "pendingReviews",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ticketthread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "documentMaxFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contentType",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "location",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentMinFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contentType",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "location",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "document",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "documentInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentOrderBy",
        "inputFields": [
          {
            "name": "archived",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteOrderBy",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "location",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "message",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageOrderBy",
              "ofType": null
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "order",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "pendingReviewsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ticketthread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReview",
        "fields": [
          {
            "name": "document",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "document",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "documentUpdated",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "filename",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderGuess",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrder",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientGuess",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "practicegroup",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "read",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "requiresFurtherAttention",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "scanId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "scanResults",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userAssigned",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "documentPendingReview",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReview_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReview_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReview_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReview_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReview_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReview_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReview_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReview_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReview_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReview_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewAppendInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "scanResults",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "documentPendingReviewInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewAvgFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "documentUpdated",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "filename",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderGuess",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patientGuess",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "read",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "requiresFurtherAttention",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "scanId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "scanResults",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "userAssigned",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewDeleteAtPathInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "scanResults",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewDeleteElemInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "scanResults",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewDeleteKeyInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "scanResults",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewIncInput",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewInsertInput",
        "inputFields": [
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "documentUpdated",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "filename",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderGuess",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientGuess",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "read",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "requiresFurtherAttention",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "scanId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "scanResults",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "userAssigned",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewMaxFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentUpdated",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "filename",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "scanId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewMinFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentUpdated",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "filename",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "scanId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "documentPendingReview",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewOrderBy",
        "inputFields": [
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentUpdated",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "filename",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderGuess",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientGuess",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOrderBy",
              "ofType": null
            }
          },
          {
            "name": "read",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requiresFurtherAttention",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "scanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "scanResults",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userAssigned",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewPrependInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "scanResults",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewSetInput",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "documentUpdated",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "filename",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "read",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "requiresFurtherAttention",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "scanId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "scanResults",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewStddevFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewStddev_popFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewStddev_sampFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewSumFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReviewUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "documentPendingReviewBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewVar_popFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewVar_sampFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentPendingReviewVarianceFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReview_avg_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReview_max_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentUpdated",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "filename",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "scanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReview_min_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentUpdated",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "filename",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "scanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReview_stddev_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReview_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReview_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReview_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "documentPendingReview_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReview_streamCursorValueInput",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "documentUpdated",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "filename",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "read",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "requiresFurtherAttention",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "scanId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "scanResults",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReview_sum_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReview_var_pop_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReview_var_samp_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPendingReview_variance_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientGuessId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeGuess",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userAssignedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentPrependInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentSetInput",
        "inputFields": [
          {
            "name": "archived",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "contentType",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "location",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "documentStddevFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentStddev_popFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentStddev_sampFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentSumFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "documentUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "documentBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "documentVar_popFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentVar_sampFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "documentVarianceFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "document_avg_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "document_max_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "location",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "document_min_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contentType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "location",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "document_stddev_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "document_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "document_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "document_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "document_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "document_streamCursorValueInput",
        "inputFields": [
          {
            "name": "archived",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "contentType",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "location",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "document_sum_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "document_var_pop_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "document_var_samp_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "document_variance_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messageId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketthreadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrder",
        "fields": [
          {
            "name": "agencyname",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "cpoClaim",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaim",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderdate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalSignedOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrderAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrder_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrder_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrder_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrder_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrder_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrder_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrder_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrder_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrder_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrder_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrderArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalSignedOrderInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderAvgFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrderBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "agencyname",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "cpoClaim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "cpoClaimBoolExp",
              "ofType": null
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderdate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrderIncInput",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrderInsertInput",
        "inputFields": [
          {
            "name": "agencyname",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "cpoClaim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "cpoClaimObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderdate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderMaxFields",
        "fields": [
          {
            "name": "agencyname",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderdate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderMinFields",
        "fields": [
          {
            "name": "agencyname",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderdate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalSignedOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrderOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrderBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrderOrderBy",
        "inputFields": [
          {
            "name": "agencyname",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "cpoClaim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "cpoClaimOrderBy",
              "ofType": null
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderdate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrderPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrderSetInput",
        "inputFields": [
          {
            "name": "agencyname",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderdate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderStddevFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderStddev_popFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderStddev_sampFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderSumFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrderUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrderIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrderSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "externalSignedOrderBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderVar_popFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderVar_sampFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalSignedOrderVarianceFields",
        "fields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrder_avg_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrder_max_order_by",
        "inputFields": [
          {
            "name": "agencyname",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderdate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrder_min_order_by",
        "inputFields": [
          {
            "name": "agencyname",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderdate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrder_stddev_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrder_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrder_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrder_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "externalSignedOrder_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrder_streamCursorValueInput",
        "inputFields": [
          {
            "name": "agencyname",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderdate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrder_sum_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrder_var_pop_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrder_var_samp_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalSignedOrder_variance_order_by",
        "inputFields": [
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatients",
        "fields": [
          {
            "name": "externalstaff",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalstaff",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "externalstaffId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalStaffMyPatients",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatientsAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatients_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatients_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatients_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatients_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatients_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatients_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatients_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatients_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatients_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatients_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatientsArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalStaffMyPatientsInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatientsOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsAvgFields",
        "fields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatientsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatientsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "externalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffBoolExp",
              "ofType": null
            }
          },
          {
            "name": "externalstaffId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatientsIncInput",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatientsInsertInput",
        "inputFields": [
          {
            "name": "externalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsMaxFields",
        "fields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsMinFields",
        "fields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalStaffMyPatients",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatientsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatientsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatientsOrderBy",
        "inputFields": [
          {
            "name": "externalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffOrderBy",
              "ofType": null
            }
          },
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatientsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatientsSetInput",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsStddevFields",
        "fields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsStddev_popFields",
        "fields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsStddev_sampFields",
        "fields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsSumFields",
        "fields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatientsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatientsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatientsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "externalStaffMyPatientsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsVar_popFields",
        "fields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsVar_sampFields",
        "fields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalStaffMyPatientsVarianceFields",
        "fields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatients_avg_order_by",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatients_max_order_by",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatients_min_order_by",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatients_stddev_order_by",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatients_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatients_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatients_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "externalStaffMyPatients_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatients_streamCursorValueInput",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatients_sum_order_by",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatients_var_pop_order_by",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatients_var_samp_order_by",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalStaffMyPatients_variance_order_by",
        "inputFields": [
          {
            "name": "externalstaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalstaff",
        "fields": [
          {
            "name": "agencyJoinRequests",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyjoinrequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyjoinrequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyJoinRequestsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyjoinrequestAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyjoinrequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyJoinRequestsApproved",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyjoinrequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyjoinrequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyJoinRequestsApprovedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyjoinrequestAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyjoinrequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "isClinician",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "isManager",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "myPatients",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalStaffMyPatients",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalStaffMyPatientsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "myPatientsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalStaffMyPatientsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalStaffMyPatientsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "user",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "user",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalstaff",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffAvgFields",
        "fields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalstaffBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "agencyJoinRequests",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestBoolExp",
              "ofType": null
            }
          },
          {
            "name": "agencyJoinRequestsApproved",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestBoolExp",
              "ofType": null
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "isClinician",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "isManager",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "myPatients",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatientsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "user",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalstaffIncInput",
        "inputFields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalstaffInsertInput",
        "inputFields": [
          {
            "name": "agencyJoinRequests",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "agencyJoinRequestsApproved",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "isClinician",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isManager",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "myPatients",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatientsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "user",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffMaxFields",
        "fields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffMinFields",
        "fields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalstaff",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalstaffObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "externalstaffInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalstaffOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalstaffOrderBy",
        "inputFields": [
          {
            "name": "agencyJoinRequestsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "agencyJoinRequestsApprovedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyjoinrequestAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "isClinician",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "isManager",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "myPatientsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatientsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "user",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalstaffPkColumnsInput",
        "inputFields": [
          {
            "name": "userId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalstaffSetInput",
        "inputFields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "isClinician",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isManager",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffStddevFields",
        "fields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffStddev_popFields",
        "fields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffStddev_sampFields",
        "fields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffSumFields",
        "fields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalstaffUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "externalstaffBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffVar_popFields",
        "fields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffVar_sampFields",
        "fields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "externalstaffVarianceFields",
        "fields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalstaff_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "externalstaff_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "externalstaff_streamCursorValueInput",
        "inputFields": [
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "isClinician",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isManager",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "fax",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "documents",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "faxDocuments",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxDocumentsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "faxDocumentsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxDocumentsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "faxNumber",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referral",
            "type": {
              "kind": "OBJECT",
              "name": "referral",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "faxAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "fax",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "faxAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "faxMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "faxMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "faxStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "faxStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "faxStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "faxSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "faxVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "faxVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "faxVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "fax_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "fax_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "fax_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "fax_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "fax_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "fax_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "fax_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "fax_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "fax_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "fax_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "faxInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "faxAvgFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "documents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocumentsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "faxName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "faxNumber",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "referral",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralBoolExp",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "faxDocuments",
        "fields": [
          {
            "name": "document",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "document",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "fax",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "fax",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "faxId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "faxDocuments",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocumentsAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocuments_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocuments_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocuments_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocuments_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocuments_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocuments_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocuments_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocuments_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocuments_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocuments_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocumentsArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "faxDocumentsInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocumentsOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsAvgFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocumentsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocumentsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxBoolExp",
              "ofType": null
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocumentsIncInput",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocumentsInsertInput",
        "inputFields": [
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsMaxFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsMinFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "faxDocuments",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocumentsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocumentsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocumentsOrderBy",
        "inputFields": [
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxOrderBy",
              "ofType": null
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocumentsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocumentsSetInput",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsStddevFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsStddev_popFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsStddev_sampFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsSumFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocumentsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocumentsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocumentsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "faxDocumentsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsVar_popFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsVar_sampFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxDocumentsVarianceFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocuments_avg_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocuments_max_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocuments_min_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocuments_stddev_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocuments_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocuments_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocuments_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "faxDocuments_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocuments_streamCursorValueInput",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocuments_sum_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocuments_var_pop_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocuments_var_samp_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxDocuments_variance_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxIncInput",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxInsertInput",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "documents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocumentsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "faxName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "faxNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referral",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "faxMaxFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxMinFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "faxNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "fax",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "faxInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxOrderBy",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxDocumentsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxNumber",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referral",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralOrderBy",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxSetInput",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "faxName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "faxNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "faxStddevFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxStddev_popFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxStddev_sampFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxSumFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "faxUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "faxBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "faxVar_popFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxVar_sampFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "faxVarianceFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "fax_avg_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "fax_max_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxNumber",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "fax_min_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxNumber",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "fax_stddev_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "fax_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "fax_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "fax_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "fax_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "fax_streamCursorValueInput",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "faxName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "faxNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "fax_sum_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "fax_var_pop_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "fax_var_samp_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "fax_variance_order_by",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "SCALAR",
        "name": "float8"
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrder",
        "fields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "freeTextOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderAvgFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "freeTextOrderBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "freeTextOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderText",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "freeTextOrderIncInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "freeTextOrderInsertInput",
        "inputFields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderMaxFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderMinFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "freeTextOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "freeTextOrderObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "freeTextOrderInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "freeTextOrderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "freeTextOrderOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "freeTextOrderBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "freeTextOrderOrderBy",
        "inputFields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "freeTextOrderPkColumnsInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "freeTextOrderSetInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderStddevFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderStddev_popFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderStddev_sampFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderSumFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "freeTextOrderUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "freeTextOrderIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "freeTextOrderSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "freeTextOrderBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderVar_popFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderVar_sampFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "freeTextOrderVarianceFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "freeTextOrder_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "freeTextOrder_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "freeTextOrder_streamCursorValueInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrder",
        "fields": [
          {
            "name": "dependantOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "dependantOrder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "date",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "occurances",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "prn",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "frequencyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderAvgFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyOrderBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "frequencyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "dependantOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "occurances",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyOrderIncInput",
        "inputFields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyOrderInsertInput",
        "inputFields": [
          {
            "name": "dependantOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderMaxFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderMinFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "frequencyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyOrderOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "frequencyOrderBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyOrderOrderBy",
        "inputFields": [
          {
            "name": "dependantOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "dependantOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyOrderPkColumnsInput",
        "inputFields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyOrderSetInput",
        "inputFields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderStddevFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderStddev_popFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderStddev_sampFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderSumFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyOrderUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "frequencyOrderIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "frequencyOrderSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "frequencyOrderBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderVar_popFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderVar_sampFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyOrderVarianceFields",
        "fields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyOrder_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "frequencyOrder_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyOrder_streamCursorValueInput",
        "inputFields": [
          {
            "name": "dependantorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "occurances",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocol",
        "fields": [
          {
            "name": "baseProtocolOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseProtocolOrder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "prn",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "units",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "frequencyProtocol",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolAvgFields",
        "fields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyProtocolBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "frequencyProtocolBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "baseProtocolOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseProtocolOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "units",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyProtocolIncInput",
        "inputFields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyProtocolInsertInput",
        "inputFields": [
          {
            "name": "baseProtocolOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseProtocolOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolMaxFields",
        "fields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolMinFields",
        "fields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "frequencyProtocol",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyProtocolOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "frequencyProtocolBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyProtocolOrderBy",
        "inputFields": [
          {
            "name": "baseProtocolOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseProtocolOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyProtocolPkColumnsInput",
        "inputFields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyProtocolSetInput",
        "inputFields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolStddevFields",
        "fields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolStddev_popFields",
        "fields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolStddev_sampFields",
        "fields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolSumFields",
        "fields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyProtocolUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "frequencyProtocolIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "frequencyProtocolSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "frequencyProtocolBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolVar_popFields",
        "fields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolVar_sampFields",
        "fields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "frequencyProtocolVarianceFields",
        "fields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyProtocol_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "frequencyProtocol_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "frequencyProtocol_streamCursorValueInput",
        "inputFields": [
          {
            "name": "baseprotocolorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "units",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "hcpcs",
        "fields": [
          {
            "name": "addonCodesets",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codesetAddon",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetAddonOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "addonCodesetsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "codesetAddonAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetAddonOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "allowedTelemedicine",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "baseCodesets",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codesetBase",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetBaseOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseCodesetsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "codesetBaseAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetBaseOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "billingPos",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "hcpcsCode",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "isAddon",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "isCpt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "isEm",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "isExtension",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "isWellness",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "lineitems",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "lineitem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "lineitemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "lineitemsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "lineitemAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "lineitemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "mayBeBilateral",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "newVisitOnly",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientPresent",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "procedures",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureHCPCS",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureHCPCSOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "proceduresAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureHCPCSAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureHCPCSOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "numeric",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitPos",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "numeric",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "hcpcs",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsAvgFields",
        "fields": [
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "hcpcsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "addonCodesets",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddonBoolExp",
              "ofType": null
            }
          },
          {
            "name": "allowedTelemedicine",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "baseCodesets",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBaseBoolExp",
              "ofType": null
            }
          },
          {
            "name": "billingPos",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "hcpcsCode",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "isAddon",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "isCpt",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "isEm",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "isExtension",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "isWellness",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "lineitems",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemBoolExp",
              "ofType": null
            }
          },
          {
            "name": "mayBeBilateral",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "newVisitOnly",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patientPresent",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "procedures",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCSBoolExp",
              "ofType": null
            }
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "visitPos",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "hcpcsIncInput",
        "inputFields": [
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "hcpcsInsertInput",
        "inputFields": [
          {
            "name": "addonCodesets",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddonArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "allowedTelemedicine",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "baseCodesets",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBaseArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "billingPos",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "hcpcsCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "isAddon",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isCpt",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isEm",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isExtension",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isWellness",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "lineitems",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "mayBeBilateral",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "newVisitOnly",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "patientPresent",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "procedures",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCSArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "visitPos",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsMaxFields",
        "fields": [
          {
            "name": "billingPos",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitPos",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsMinFields",
        "fields": [
          {
            "name": "billingPos",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitPos",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "hcpcs",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "hcpcsObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "hcpcsInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "hcpcsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "hcpcsOrderBy",
        "inputFields": [
          {
            "name": "addonCodesetsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetAddonAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "allowedTelemedicine",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "baseCodesetsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBaseAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "billingPos",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsCode",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "isAddon",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "isCpt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "isEm",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "isExtension",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "isWellness",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lineitemsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "mayBeBilateral",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "newVisitOnly",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientPresent",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "proceduresAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCSAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitPos",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "hcpcsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "hcpcsSetInput",
        "inputFields": [
          {
            "name": "allowedTelemedicine",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "billingPos",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "hcpcsCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "isAddon",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isCpt",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isEm",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isExtension",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isWellness",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "mayBeBilateral",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "newVisitOnly",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "patientPresent",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "visitPos",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsStddevFields",
        "fields": [
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsStddev_popFields",
        "fields": [
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsStddev_sampFields",
        "fields": [
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsSumFields",
        "fields": [
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "hcpcsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "hcpcsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsVar_popFields",
        "fields": [
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsVar_sampFields",
        "fields": [
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "hcpcsVarianceFields",
        "fields": [
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "hcpcs_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "hcpcs_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "hcpcs_streamCursorValueInput",
        "inputFields": [
          {
            "name": "allowedTelemedicine",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "billingPos",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "hcpcsCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "isAddon",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isCpt",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isEm",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isExtension",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isWellness",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "mayBeBilateral",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "newVisitOnly",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "patientPresent",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "referencePrice",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "timeMinimum",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "visitPos",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "workRvu",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplan",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "eligibilityChecks",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientEligibilityCheck",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientEligibilityCheckOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "eligibilityChecksAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientEligibilityCheckAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientEligibilityCheckOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "payer",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "payer",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "subscriberDob",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberFirstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberGroupNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberIdNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberLastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "insuranceplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplanAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplan_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplan_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplan_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplan_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplan_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplan_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplan_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplan_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplan_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplan_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplanArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "insuranceplanInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplanBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "eligibilityChecks",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "payer",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "subscriberDob",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "subscriberFirstName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "subscriberGroupNumber",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "subscriberIdNumber",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "subscriberLastName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplanIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplanInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "eligibilityChecks",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "payer",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "subscriberDob",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "subscriberFirstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "subscriberGroupNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "subscriberIdNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "subscriberLastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberDob",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberFirstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberGroupNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberIdNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberLastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberDob",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberFirstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberGroupNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberIdNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subscriberLastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "insuranceplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplanObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "insuranceplanInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplanOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplanOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "eligibilityChecksAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payer",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberDob",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberFirstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberGroupNumber",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberIdNumber",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberLastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplanPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplanSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "subscriberDob",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "subscriberFirstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "subscriberGroupNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "subscriberIdNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "subscriberLastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplanUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "insuranceplanBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "insuranceplanVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplan_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplan_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberDob",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberFirstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberGroupNumber",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberIdNumber",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberLastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplan_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberDob",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberFirstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberGroupNumber",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberIdNumber",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscriberLastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplan_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplan_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplan_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplan_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "insuranceplan_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplan_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "subscriberDob",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "subscriberFirstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "subscriberGroupNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "subscriberIdNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "subscriberLastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplan_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplan_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplan_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "insuranceplan_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "integration",
        "fields": [
          {
            "name": "accessToken",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clientId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clientSecret",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultProvider",
            "type": {
              "kind": "OBJECT",
              "name": "provider",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "officesExams",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "practicegroup",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "redirectUrl",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refreshToken",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timestamp",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "integrationAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "integrationAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "integration",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "integrationAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "integrationAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "integrationMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "integrationMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "integrationStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "integrationStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "integrationStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "integrationSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "integrationVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "integrationVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "integrationVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integration_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integration_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integration_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integration_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integration_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integration_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integration_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integration_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integration_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integration_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationAppendInput",
        "inputFields": [
          {
            "name": "officesExams",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "integrationInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "integrationAvgFields",
        "fields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "accessToken",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clientSecret",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "defaultProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "officesExams",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "redirectUrl",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "refreshToken",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "timestamp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationDeleteAtPathInput",
        "inputFields": [
          {
            "name": "officesExams",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationDeleteElemInput",
        "inputFields": [
          {
            "name": "officesExams",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationDeleteKeyInput",
        "inputFields": [
          {
            "name": "officesExams",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationIncInput",
        "inputFields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationInsertInput",
        "inputFields": [
          {
            "name": "accessToken",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "clientId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "clientSecret",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "officesExams",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "redirectUrl",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "refreshToken",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "timestamp",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "integrationMaxFields",
        "fields": [
          {
            "name": "accessToken",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clientId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clientSecret",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "redirectUrl",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refreshToken",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timestamp",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "integrationMinFields",
        "fields": [
          {
            "name": "accessToken",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clientId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clientSecret",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "redirectUrl",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refreshToken",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timestamp",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "integrationMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "integration",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationOrderBy",
        "inputFields": [
          {
            "name": "accessToken",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clientSecret",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "officesExams",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOrderBy",
              "ofType": null
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "redirectUrl",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refreshToken",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "timestamp",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationPrependInput",
        "inputFields": [
          {
            "name": "officesExams",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationSetInput",
        "inputFields": [
          {
            "name": "accessToken",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "clientId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "clientSecret",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "officesExams",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "redirectUrl",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "refreshToken",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "timestamp",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "integrationStddevFields",
        "fields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "integrationStddev_popFields",
        "fields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "integrationStddev_sampFields",
        "fields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "integrationSumFields",
        "fields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integrationUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "integrationBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "integrationVar_popFields",
        "fields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "integrationVar_sampFields",
        "fields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "integrationVarianceFields",
        "fields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integration_avg_order_by",
        "inputFields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integration_max_order_by",
        "inputFields": [
          {
            "name": "accessToken",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clientSecret",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "redirectUrl",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refreshToken",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "timestamp",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integration_min_order_by",
        "inputFields": [
          {
            "name": "accessToken",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clientSecret",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "redirectUrl",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refreshToken",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "timestamp",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integration_stddev_order_by",
        "inputFields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integration_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integration_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integration_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "integration_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integration_streamCursorValueInput",
        "inputFields": [
          {
            "name": "accessToken",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "clientId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "clientSecret",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "officesExams",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "redirectUrl",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "refreshToken",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "timestamp",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integration_sum_order_by",
        "inputFields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integration_var_pop_order_by",
        "inputFields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integration_var_samp_order_by",
        "inputFields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "integration_variance_order_by",
        "inputFields": [
          {
            "name": "clonedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultDoctorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPhysicalVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultVirtualVisitProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedNoteProfile",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "internalstaff",
        "fields": [
          {
            "name": "isSupervisor",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "practicegroup",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "role",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "user",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "user",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "internalstaff",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaffAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaff_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaff_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaff_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaff_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaff_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaff_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaff_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaff_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaff_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaff_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaffArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "internalstaffInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffAvgFields",
        "fields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaffBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "isSupervisor",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "user",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaffIncInput",
        "inputFields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaffInsertInput",
        "inputFields": [
          {
            "name": "isSupervisor",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "user",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffMaxFields",
        "fields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffMinFields",
        "fields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "internalstaff",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaffObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "internalstaffInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaffOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaffOrderBy",
        "inputFields": [
          {
            "name": "isSupervisor",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOrderBy",
              "ofType": null
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "user",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaffPkColumnsInput",
        "inputFields": [
          {
            "name": "userId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaffSetInput",
        "inputFields": [
          {
            "name": "isSupervisor",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffStddevFields",
        "fields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffStddev_popFields",
        "fields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffStddev_sampFields",
        "fields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffSumFields",
        "fields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaffUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "internalstaffBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffVar_popFields",
        "fields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffVar_sampFields",
        "fields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "internalstaffVarianceFields",
        "fields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaff_avg_order_by",
        "inputFields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaff_max_order_by",
        "inputFields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaff_min_order_by",
        "inputFields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaff_stddev_order_by",
        "inputFields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaff_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaff_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaff_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "internalstaff_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaff_streamCursorValueInput",
        "inputFields": [
          {
            "name": "isSupervisor",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaff_sum_order_by",
        "inputFields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaff_var_pop_order_by",
        "inputFields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaff_var_samp_order_by",
        "inputFields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "internalstaff_variance_order_by",
        "inputFields": [
          {
            "name": "practiceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "role",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "SCALAR",
        "name": "interval"
      },
      {
        "kind": "SCALAR",
        "name": "jsonb"
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrder",
        "fields": [
          {
            "name": "approvalAppointment",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalProvider",
            "type": {
              "kind": "OBJECT",
              "name": "provider",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisor",
            "type": {
              "kind": "OBJECT",
              "name": "provider",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorUser",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approved",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateExpires",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateFinalized",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateRequested",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationAgency",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "finalizingUser",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "html",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "newBaseOrder",
            "type": {
              "kind": "OBJECT",
              "name": "baseorder",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderable",
            "type": {
              "kind": "OBJECT",
              "name": "orderable",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestingUser",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisorApprovalDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticket",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThread",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanOrder",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrder",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrder_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrder_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrder_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrder_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrder_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrder_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrder_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrder_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrder_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrder_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderAppendInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderAvgFields",
        "fields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "approvalAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "approvalProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisor",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "approved",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateExpires",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateFinalized",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateRequested",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "destinationAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "finalizingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "html",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "newBaseOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderText",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "requestingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "supervisorApprovalDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ticket",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrderBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderDeleteAtPathInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderDeleteElemInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderDeleteKeyInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequency",
        "fields": [
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "prn",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "date",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencyAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderFrequency",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencyAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencySumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencyAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderFrequencyBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencyBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderFrequencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencyBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "duration",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntervalComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderFrequencyIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderFrequencyInsertInput",
        "inputFields": [
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencyMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencyMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencyMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderFrequency",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderFrequencyOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderFrequencyBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderFrequencyOrderBy",
        "inputFields": [
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderFrequencyPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderFrequencySetInput",
        "inputFields": [
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencyStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencyStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencyStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencySumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderFrequencyUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderFrequencyIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderFrequencySetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyOrderFrequencyBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencyVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencyVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderFrequencyVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderFrequency_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyOrderFrequency_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderFrequency_streamCursorValueInput",
        "inputFields": [
          {
            "name": "duration",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "prn",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderIncInput",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderInsertInput",
        "inputFields": [
          {
            "name": "approvalAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisor",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approved",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateExpires",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateFinalized",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateRequested",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "destinationAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "finalizingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "html",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "newBaseOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "requestingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "supervisorApprovalDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "ticket",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrderObjRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMaxFields",
        "fields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateExpires",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateFinalized",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateRequested",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "html",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisorApprovalDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedications",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "medication",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "medication",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "order",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderMedications",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderMedicationsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderMedicationsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "medication",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "order",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderMedicationsIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderMedicationsInsertInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medication",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "order",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderMedications",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderMedicationsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderMedicationsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderMedicationsOrderBy",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medication",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderBy",
              "ofType": null
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "order",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderMedicationsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderMedicationsSetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderMedicationsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderMedicationsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderMedicationsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyOrderMedicationsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMedicationsVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderMedications_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyOrderMedications_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderMedications_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMinFields",
        "fields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateExpires",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateFinalized",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateRequested",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "html",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisorApprovalDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyOrderInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderBy",
        "inputFields": [
          {
            "name": "approvalAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisor",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approved",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateExpires",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateFinalized",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateRequested",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "finalizingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "html",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "newBaseOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "supervisorApprovalDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticket",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrderOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValue",
        "fields": [
          {
            "name": "field",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderOrderableField",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableOrder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "show",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderOrderableFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValueAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValue_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValue_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValue_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValue_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValue_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValue_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValue_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValue_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValue_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValue_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValueArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderOrderableFieldValueInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValueOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueAvgFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValueBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValueBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "field",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldBoolExp",
              "ofType": null
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValueIncInput",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValueInsertInput",
        "inputFields": [
          {
            "name": "field",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueMaxFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueMinFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderOrderableFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValueOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValueBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValueOrderBy",
        "inputFields": [
          {
            "name": "field",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldOrderBy",
              "ofType": null
            }
          },
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValuePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValueSetInput",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueStddevFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueStddev_popFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueStddev_sampFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueSumFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValueUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValueIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValueSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyOrderOrderableFieldValueBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueVar_popFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueVar_sampFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderOrderableFieldValueVarianceFields",
        "fields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValue_avg_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValue_max_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValue_min_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValue_stddev_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValue_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValue_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValue_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyOrderOrderableFieldValue_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValue_streamCursorValueInput",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "show",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValue_sum_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValue_var_pop_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValue_var_samp_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderOrderableFieldValue_variance_order_by",
        "inputFields": [
          {
            "name": "fieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderPrependInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualification",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "qualification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableQualification",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualificationAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualification_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualification_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualification_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualification_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualification_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualification_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualification_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualification_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualification_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualification_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualificationArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderQualificationInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualificationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualificationBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualificationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "qualification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualificationIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualificationInsertInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "qualification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualificationOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualificationBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualificationOrderBy",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationOrderBy",
              "ofType": null
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualificationPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualificationSetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualificationUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualificationIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualificationSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyOrderQualificationBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderQualificationVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualification_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualification_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualification_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualification_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualification_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualification_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualification_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyOrderQualification_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualification_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualification_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualification_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualification_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderQualification_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderSetInput",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approved",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateExpires",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateFinalized",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateRequested",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "html",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "supervisorApprovalDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderStddevFields",
        "fields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderStddev_popFields",
        "fields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderStddev_sampFields",
        "fields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderSumFields",
        "fields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrderUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyOrderBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderVar_popFields",
        "fields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderVar_sampFields",
        "fields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyOrderVarianceFields",
        "fields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrder_avg_order_by",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrder_max_order_by",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateExpires",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateFinalized",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateRequested",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "html",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisorApprovalDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrder_min_order_by",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateExpires",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateFinalized",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateRequested",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "html",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisorApprovalDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrder_stddev_order_by",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrder_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrder_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrder_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyOrder_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrder_streamCursorValueInput",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "approved",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "dateEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateExpires",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateFinalized",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateRequested",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "html",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "supervisorApprovalDate",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrder_sum_order_by",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrder_var_pop_order_by",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrder_var_samp_order_by",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyOrder_variance_order_by",
        "inputFields": [
          {
            "name": "approvalAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "approvalSupervisorUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "discipline",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "finalizedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrder",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplan",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyTreatmentplanOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrderAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrder_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrder_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrder_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrder_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrder_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrder_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrder_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrder_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrder_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrder_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrderArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyTreatmentplanOrderInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrderBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrderIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrderInsertInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanObjRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyTreatmentplanOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrderObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyTreatmentplanOrderInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrderOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrderBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrderOrderBy",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrderPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrderSetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrderUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrderIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrderSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyTreatmentplanOrderBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "legacyTreatmentplanOrderVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrder_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrder_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrder_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrder_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrder_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrder_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrder_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "legacyTreatmentplanOrder_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrder_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrder_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrder_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrder_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "legacyTreatmentplanOrder_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "lineitem",
        "fields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claim",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "claim",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "deniedFlag",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "hcpcs",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insuranceStatus",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "modifiers",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "postedDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "lineitemAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "lineitem",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "lineitemAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitem_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitem_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitem_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitem_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitem_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitem_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitem_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitem_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitem_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitem_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemAppendInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "lineitemInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "lineitemAvgFields",
        "fields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "balanceIns",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "billingStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "claim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimBoolExp",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "deniedFlag",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "insuranceStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "modifiers",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "_varcharComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "postedDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemDeleteAtPathInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemDeleteElemInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemDeleteKeyInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemIncInput",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemInsertInput",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "billingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "claim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "deniedFlag",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "insuranceStatus",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "modifiers",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "postedDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "lineitemMaxFields",
        "fields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insuranceStatus",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "postedDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "lineitemMinFields",
        "fields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insuranceStatus",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "postedDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "lineitemMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "lineitem",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemOrderBy",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claim",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimOrderBy",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "deniedFlag",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsOrderBy",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insuranceStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "modifiers",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "postedDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemPrependInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemSetInput",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "billingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "deniedFlag",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "insuranceStatus",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "modifiers",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "postedDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "lineitemStddevFields",
        "fields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "lineitemStddev_popFields",
        "fields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "lineitemStddev_sampFields",
        "fields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "lineitemSumFields",
        "fields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitemUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "lineitemSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "lineitemBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "lineitemVar_popFields",
        "fields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "lineitemVar_sampFields",
        "fields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "lineitemVarianceFields",
        "fields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitem_avg_order_by",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitem_max_order_by",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insuranceStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "postedDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitem_min_order_by",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insuranceStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "postedDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitem_stddev_order_by",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitem_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitem_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitem_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "lineitem_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitem_streamCursorValueInput",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "billingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "deniedFlag",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "insuranceStatus",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "modifiers",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "postedDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitem_sum_order_by",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitem_var_pop_order_by",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitem_var_samp_order_by",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "lineitem_variance_order_by",
        "inputFields": [
          {
            "name": "balanceIns",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "balancePt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins1Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins2Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ins3Paid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientPaid",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medication",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "dateStartedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateStoppedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drugName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "indication",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ndc",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ndcExpiration",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ordersForMedication",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medicationorderMedications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationorderMedicationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersForMedicationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationorderMedicationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationorderMedicationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "pharmacyNote",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "route",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rxnorm",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sig",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "strength",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "medicationAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medication",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "medicationAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "medicationMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "medicationMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "medicationStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "medicationStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "medicationStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "medicationSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "medicationVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "medicationVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "medicationVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medication_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medication_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medication_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medication_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medication_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medication_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medication_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medication_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medication_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medication_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationAppendInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationAvgFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateStartedTaking",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateStoppedTaking",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "drugName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "indication",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ndc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ndcExpiration",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ordersForMedication",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedicationsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "pharmacyNote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "Float8ComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "route",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "rxnorm",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "sig",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "strength",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationDeleteAtPathInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationDeleteElemInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationDeleteKeyInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationIncInput",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "dateStartedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "dateStoppedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "drugName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "indication",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "ndc",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "ndcExpiration",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "ordersForMedication",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedicationsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "pharmacyNote",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "route",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "rxnorm",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "sig",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "strength",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationMaxFields",
        "fields": [
          {
            "name": "dateStartedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateStoppedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drugName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "indication",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ndc",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ndcExpiration",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pharmacyNote",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "route",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rxnorm",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sig",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "strength",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationMinFields",
        "fields": [
          {
            "name": "dateStartedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateStoppedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drugName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "indication",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ndc",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ndcExpiration",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pharmacyNote",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "route",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rxnorm",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sig",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "strength",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medication",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "medicationInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrder",
        "fields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "medicationOrderSpecifics",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medicationorderMedications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationorderMedicationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationOrderSpecificsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationorderMedicationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationorderMedicationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medicationOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderAvgFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOrderBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "medicationOrderSpecifics",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedicationsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateStartedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateStoppedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drugName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "indication",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ndc",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ndcExpiration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ordersForMedicationAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedicationsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "pharmacyNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "route",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rxnorm",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "sig",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "strength",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOrderIncInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOrderInsertInput",
        "inputFields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicationOrderSpecifics",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedicationsArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderMaxFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderMinFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medicationOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOrderObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "medicationOrderInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOrderOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOrderOrderBy",
        "inputFields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationOrderSpecificsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedicationsAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOrderPkColumnsInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOrderSetInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderStddevFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderStddev_popFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderStddev_sampFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderSumFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOrderUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "medicationOrderBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderVar_popFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderVar_sampFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationOrderVarianceFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOrder_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "medicationOrder_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationOrder_streamCursorValueInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationPrependInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "dateStartedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "dateStoppedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "drugName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "indication",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "ndc",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "ndcExpiration",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "pharmacyNote",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "route",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "rxnorm",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "sig",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "strength",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationStddevFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationStddev_popFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationStddev_sampFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationSumFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "medicationBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationVar_popFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationVar_sampFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationVarianceFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medication_avg_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medication_max_order_by",
        "inputFields": [
          {
            "name": "dateStartedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateStoppedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drugName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "indication",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ndc",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ndcExpiration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "pharmacyNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "route",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rxnorm",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "sig",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "strength",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medication_min_order_by",
        "inputFields": [
          {
            "name": "dateStartedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateStoppedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drugName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "indication",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ndc",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ndcExpiration",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "pharmacyNote",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "route",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rxnorm",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "sig",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "strength",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medication_stddev_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medication_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medication_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medication_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "medication_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medication_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "dateStartedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "dateStoppedTaking",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "drugName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "indication",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "ndc",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "ndcExpiration",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "pharmacyNote",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "float8",
              "ofType": null
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "route",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "rxnorm",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "sig",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "strength",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medication_sum_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medication_var_pop_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medication_var_samp_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medication_variance_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refills",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedications",
        "fields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationOrder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "medication",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "medication",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medicationorderMedications",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedicationsAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedications_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedications_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedications_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedications_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedications_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedications_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedications_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedications_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedications_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedications_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedicationsArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationorderMedicationsInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedicationsOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedicationsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedicationsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "medication",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedicationsIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedicationsInsertInput",
        "inputFields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medication",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medicationorderMedications",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedicationsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedicationsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedicationsOrderBy",
        "inputFields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medication",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationOrderBy",
              "ofType": null
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedicationsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedicationsSetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedicationsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedicationsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationorderMedicationsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "medicationorderMedicationsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "medicationorderMedicationsVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedications_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedications_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedications_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedications_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedications_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedications_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedications_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "medicationorderMedications_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedications_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedications_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedications_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedications_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "medicationorderMedications_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationorderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "message",
        "fields": [
          {
            "name": "caretimes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretime",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "datetimeSubmitted",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "documents",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "document",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntake",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntake",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceStaffOnly",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequest",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThread",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "user",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "user",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "messageAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "messageAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "message",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "messageAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "messageAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "messageMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "messageMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "messageStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "messageStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "messageStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "messageSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "messageVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "messageVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "messageVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "messageAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "message_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "message_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "message_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "message_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "message_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "message_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "message_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "message_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "message_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "message_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "messageArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "messageInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "messageAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "messageBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "datetimeSubmitted",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "documents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patientIntake",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "practiceStaffOnly",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestBoolExp",
              "ofType": null
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          },
          {
            "name": "user",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "messageIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "messageInsertInput",
        "inputFields": [
          {
            "name": "caretimes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "datetimeSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "documents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientIntake",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "practiceStaffOnly",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "user",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "messageMaxFields",
        "fields": [
          {
            "name": "datetimeSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "messageMinFields",
        "fields": [
          {
            "name": "datetimeSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "messageMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "message",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "messageObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "messageInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "messageOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "messageOrderBy",
        "inputFields": [
          {
            "name": "caretimesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "datetimeSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntake",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeOrderBy",
              "ofType": null
            }
          },
          {
            "name": "practiceStaffOnly",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestOrderBy",
              "ofType": null
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadOrderBy",
              "ofType": null
            }
          },
          {
            "name": "user",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "messagePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "messageSetInput",
        "inputFields": [
          {
            "name": "datetimeSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceStaffOnly",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "messageStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "messageStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "messageStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "messageSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "messageUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "messageBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "messageVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "messageVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "messageVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "message_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "message_max_order_by",
        "inputFields": [
          {
            "name": "datetimeSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "message_min_order_by",
        "inputFields": [
          {
            "name": "datetimeSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "message_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "message_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "message_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "message_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "message_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "message_streamCursorValueInput",
        "inputFields": [
          {
            "name": "datetimeSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceStaffOnly",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "message_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "message_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "message_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "message_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "mutation_root",
        "fields": [
          {
            "name": "deleteAddress",
            "type": {
              "kind": "OBJECT",
              "name": "addressMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "addressBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAddressByPk",
            "type": {
              "kind": "OBJECT",
              "name": "address",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAgency",
            "type": {
              "kind": "OBJECT",
              "name": "agencyMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAgencyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAgencyLocation",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyLocationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAgencyLocationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocation",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAgencyPayers",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyPayersBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAgencyPayersByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayers",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAgencyServices",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyServicesBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAgencyServicesByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServices",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAgencyconnection",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyconnectionBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAgencyconnectionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnection",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAgencyjoinrequest",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyjoinrequestBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAgencyjoinrequestByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequest",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAllergy",
            "type": {
              "kind": "OBJECT",
              "name": "allergyMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "allergyBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAllergyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "allergy",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAppointment",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "appointmentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAppointmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "assessment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAssessmentItem",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentItemBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAssessmentItemByPk",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItem",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAssessmentItemValue",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentItemValueBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteAssessmentItemValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValue",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteBaseProtocolOrder",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "baseProtocolOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteBaseProtocolOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "dependantorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteBaseorder",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "baseorderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteBaseorderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "baseorder",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteCaretime",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "caretimeBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteCaretimeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "caretime",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteCaretimeProblems",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "caretimeProblemsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteCaretimeProblemsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblems",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClaim",
            "type": {
              "kind": "OBJECT",
              "name": "claimMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "claimBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClaimByPk",
            "type": {
              "kind": "OBJECT",
              "name": "claim",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClinicalNoteFutureReferrals",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalNoteFutureReferralsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClinicalNoteFutureReferralsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferrals",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClinicalnote",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClinicalnoteByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnote",
              "ofType": null
            },
            "args": [
              {
                "name": "appointmentId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClinicalnoteField",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteFieldBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClinicalnoteFieldByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteField",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClinicalnoteFieldValue",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteFieldValueBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClinicalnoteFieldValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClinicalnoteTemplate",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteTemplateBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClinicalnoteTemplateByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplate",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClinicalnoteTemplateProviderClassifications",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteClinicalnoteTemplateProviderClassificationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteCodeset",
            "type": {
              "kind": "OBJECT",
              "name": "codesetMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteCodesetAddon",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetAddonBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteCodesetAddonByPk",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddon",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteCodesetBase",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetBaseBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteCodesetBaseByPk",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBase",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteCodesetByPk",
            "type": {
              "kind": "OBJECT",
              "name": "codeset",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteConsent",
            "type": {
              "kind": "OBJECT",
              "name": "consentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "consentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteConsentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "consent",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteCpoClaim",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "cpoClaimBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteCpoClaimByPk",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaim",
              "ofType": null
            },
            "args": [
              {
                "name": "claimPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteDependantOrder",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "dependantOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteDependantOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "baseorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteDiagnosisAbbreviation",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "diagnosisAbbreviationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteDiagnosisAbbreviationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviation",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteDocument",
            "type": {
              "kind": "OBJECT",
              "name": "documentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "documentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteDocumentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "document",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteDocumentPendingReview",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "documentPendingReviewBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteDocumentPendingReviewByPk",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReview",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteExternalSignedOrder",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalSignedOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteExternalSignedOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteExternalStaffMyPatients",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalStaffMyPatientsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteExternalStaffMyPatientsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatients",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteExternalstaff",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalstaffBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteExternalstaffByPk",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaff",
              "ofType": null
            },
            "args": [
              {
                "name": "userId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteFax",
            "type": {
              "kind": "OBJECT",
              "name": "faxMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "faxBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteFaxByPk",
            "type": {
              "kind": "OBJECT",
              "name": "fax",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteFaxDocuments",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "faxDocumentsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteFaxDocumentsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocuments",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteFreeTextOrder",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "freeTextOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteFreeTextOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "baseorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteFrequencyOrder",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "frequencyOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteFrequencyOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "dependantorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteFrequencyProtocol",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "frequencyProtocolBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteFrequencyProtocolByPk",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocol",
              "ofType": null
            },
            "args": [
              {
                "name": "baseprotocolorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteHcpcs",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "hcpcsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteHcpcsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcs",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteInsuranceplan",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "insuranceplanBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteInsuranceplanByPk",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplan",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteIntegration",
            "type": {
              "kind": "OBJECT",
              "name": "integrationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "integrationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteIntegrationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "integration",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteInternalstaff",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "internalstaffBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteInternalstaffByPk",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaff",
              "ofType": null
            },
            "args": [
              {
                "name": "userId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLegacyOrder",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLegacyOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLegacyOrderFrequency",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderFrequencyBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLegacyOrderFrequencyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequency",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLegacyOrderMedications",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderMedicationsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLegacyOrderMedicationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedications",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLegacyOrderOrderableFieldValue",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderOrderableFieldValueBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLegacyOrderOrderableFieldValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLegacyOrderQualification",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderQualificationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLegacyOrderQualificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLegacyTreatmentplanOrder",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyTreatmentplanOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLegacyTreatmentplanOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLineitem",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "lineitemBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteLineitemByPk",
            "type": {
              "kind": "OBJECT",
              "name": "lineitem",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteMedication",
            "type": {
              "kind": "OBJECT",
              "name": "medicationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteMedicationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "medication",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteMedicationOrder",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteMedicationOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "baseorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteMedicationorderMedications",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationorderMedicationsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteMedicationorderMedicationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedications",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteMessage",
            "type": {
              "kind": "OBJECT",
              "name": "messageMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "messageBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteMessageByPk",
            "type": {
              "kind": "OBJECT",
              "name": "message",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderOrderableField",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderOrderableFieldBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderOrderableFieldByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableField",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderOrderableLegacy",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderOrderableLegacyBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderOrderableLegacyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacy",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderable",
            "type": {
              "kind": "OBJECT",
              "name": "orderableMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderable",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableCategory",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableCategoryBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableCategoryByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategory",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableOrder",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "baseorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableOrderQualification",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableOrderQualificationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableOrderQualificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableQualification",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableQualificationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableQualificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableSupplier",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableSupplierBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableSupplierByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplier",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableSupplierPayerConnections",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableSupplierPayerConnectionsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderableSupplierPayerConnectionsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnections",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderaction",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderactionBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteOrderactionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderaction",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatient",
            "type": {
              "kind": "OBJECT",
              "name": "patientMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientAlias",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientAliasBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientAliasByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientAlias",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientCommunication",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientCommunicationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientCommunicationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunication",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientContactPerson",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientContactPersonBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientContactPersonByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPerson",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientEligibilityCheck",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientEligibilityCheckBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientEligibilityCheckByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheck",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientIntake",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientIntakeBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientIntakeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntake",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientIntakeRecordsLocations",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientIntakeRecordsLocationsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientIntakeRecordsLocationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocations",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientPhone",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientPhoneBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientPhoneByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhone",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientPhoto",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientPhotoBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientPhotoByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoto",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientStatus",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientStatusAction",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusActionBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientStatusActionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusAction",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientStatusByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatus",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientStatusSubsequentStatuses",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusSubsequentStatusesBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePatientStatusSubsequentStatusesByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatuses",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePayer",
            "type": {
              "kind": "OBJECT",
              "name": "payerMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "payerBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePayerByPk",
            "type": {
              "kind": "OBJECT",
              "name": "payer",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePracticegroup",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "practicegroupBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deletePracticegroupByPk",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroup",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProblem",
            "type": {
              "kind": "OBJECT",
              "name": "problemMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "problemBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProblemByPk",
            "type": {
              "kind": "OBJECT",
              "name": "problem",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProblemPlan",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "problemPlanBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProblemPlanByPk",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlan",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProcedure",
            "type": {
              "kind": "OBJECT",
              "name": "procedureMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProcedureByPk",
            "type": {
              "kind": "OBJECT",
              "name": "procedure",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProcedureHCPCS",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureHCPCSBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProcedureHCPCSByPk",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCS",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProcedureRendered",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureRenderedBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProcedureRenderedByPk",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRendered",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProvider",
            "type": {
              "kind": "OBJECT",
              "name": "providerMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProviderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "provider",
              "ofType": null
            },
            "args": [
              {
                "name": "internalStaffId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProviderClassification",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerClassificationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProviderClassificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassification",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProviderSchedule",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerScheduleBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteProviderScheduleByPk",
            "type": {
              "kind": "OBJECT",
              "name": "providerSchedule",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteRecordsRequestDestination",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "recordsRequestDestinationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteRecordsRequestDestinationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestination",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteReferral",
            "type": {
              "kind": "OBJECT",
              "name": "referralMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "referralBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteReferralAttachments",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "referralAttachmentsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteReferralAttachmentsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachments",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteReferralByPk",
            "type": {
              "kind": "OBJECT",
              "name": "referral",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteRenderedAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "renderedAssessmentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteRenderedAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteSchedulingAction",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "schedulingActionBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteSchedulingActionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingAction",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteServiceType",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "serviceTypeBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteServiceTypeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "serviceType",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteSignedConsent",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signedConsentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteSignedConsentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsent",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteSignoff",
            "type": {
              "kind": "OBJECT",
              "name": "signoffMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signoffBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteSignoffByPk",
            "type": {
              "kind": "OBJECT",
              "name": "signoff",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteSignoffType",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signoffTypeBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteSignoffTypeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "signoffType",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTelemedAppointmentRequest",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "telemedAppointmentRequestBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTelemedAppointmentRequestAccessCode",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "telemedAppointmentRequestAccessCodeBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTelemedAppointmentRequestAccessCodeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCode",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTelemedAppointmentRequestByPk",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequest",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteThreadCategory",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadCategoryBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteThreadCategoryByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategory",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteThreadCategoryStatus",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadCategoryStatusBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteThreadCategoryStatusByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatus",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteThreadField",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadFieldBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteThreadFieldByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadField",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteThreadFieldValue",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadFieldValueBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteThreadFieldValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTicketSubscription",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "ticketSubscriptionBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTicketSubscriptionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscription",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTicketThread",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "ticketThreadBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTicketThreadByPk",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThread",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTreatmentplan",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTreatmentplanAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanAssessmentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTreatmentplanAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTreatmentplanByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplan",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTreatmentplanNote",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanNoteBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTreatmentplanNoteByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNote",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTreatmentplanProblems",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanProblemsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTreatmentplanProblemsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblems",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTreatmentplanTemplate",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanTemplateBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTreatmentplanTemplateAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanTemplateAssessmentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTreatmentplanTemplateAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteTreatmentplanTemplateByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplate",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteUser",
            "type": {
              "kind": "OBJECT",
              "name": "userMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "userBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteUserByPk",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteUserGroups",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "userGroupsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteUserGroupsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "userGroups",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteUserPermissions",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "userPermissionsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteUserPermissionsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissions",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteVisitRebill",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "visitRebillBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteVisitRebillByPk",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebill",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteVitals",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "vitalsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "deleteVitalsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "vitals",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "insertAddress",
            "type": {
              "kind": "OBJECT",
              "name": "addressMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "addressInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAddressOne",
            "type": {
              "kind": "OBJECT",
              "name": "address",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "addressInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAgency",
            "type": {
              "kind": "OBJECT",
              "name": "agencyMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "agencyInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAgencyLocation",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "agencyLocationInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAgencyLocationOne",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocation",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyLocationInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAgencyOne",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAgencyPayers",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "agencyPayersInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAgencyPayersOne",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayers",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyPayersInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAgencyServices",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "agencyServicesInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAgencyServicesOne",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServices",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyServicesInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAgencyconnection",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "agencyconnectionInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAgencyconnectionOne",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnection",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyconnectionInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAgencyjoinrequest",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "agencyjoinrequestInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAgencyjoinrequestOne",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequest",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyjoinrequestInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAllergy",
            "type": {
              "kind": "OBJECT",
              "name": "allergyMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "allergyInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAllergyOne",
            "type": {
              "kind": "OBJECT",
              "name": "allergy",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "allergyInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAppointment",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "appointmentInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAppointmentOne",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "appointmentInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "assessmentInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAssessmentItem",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "assessmentItemInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAssessmentItemOne",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItem",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentItemInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAssessmentItemValue",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "assessmentItemValueInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAssessmentItemValueOne",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValue",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentItemValueInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertAssessmentOne",
            "type": {
              "kind": "OBJECT",
              "name": "assessment",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertBaseProtocolOrder",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "baseProtocolOrderInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertBaseProtocolOrderOne",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "baseProtocolOrderInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertBaseorder",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "baseorderInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertBaseorderOne",
            "type": {
              "kind": "OBJECT",
              "name": "baseorder",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "baseorderInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertCaretime",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "caretimeInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertCaretimeOne",
            "type": {
              "kind": "OBJECT",
              "name": "caretime",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "caretimeInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertCaretimeProblems",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "caretimeProblemsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertCaretimeProblemsOne",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblems",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "caretimeProblemsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClaim",
            "type": {
              "kind": "OBJECT",
              "name": "claimMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "claimInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClaimOne",
            "type": {
              "kind": "OBJECT",
              "name": "claim",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "claimInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClinicalNoteFutureReferrals",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "clinicalNoteFutureReferralsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClinicalNoteFutureReferralsOne",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferrals",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalNoteFutureReferralsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClinicalnote",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "clinicalnoteInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClinicalnoteField",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "clinicalnoteFieldInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClinicalnoteFieldOne",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteField",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteFieldInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClinicalnoteFieldValue",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "clinicalnoteFieldValueInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClinicalnoteFieldValueOne",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteFieldValueInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClinicalnoteOne",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnote",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClinicalnoteTemplate",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "clinicalnoteTemplateInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClinicalnoteTemplateOne",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplate",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteTemplateInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClinicalnoteTemplateProviderClassifications",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "clinicalnoteTemplateProviderClassificationsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertClinicalnoteTemplateProviderClassificationsOne",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteTemplateProviderClassificationsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertCodeset",
            "type": {
              "kind": "OBJECT",
              "name": "codesetMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "codesetInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertCodesetAddon",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "codesetAddonInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertCodesetAddonOne",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddon",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetAddonInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertCodesetBase",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "codesetBaseInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertCodesetBaseOne",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBase",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetBaseInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertCodesetOne",
            "type": {
              "kind": "OBJECT",
              "name": "codeset",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertConsent",
            "type": {
              "kind": "OBJECT",
              "name": "consentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "consentInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertConsentOne",
            "type": {
              "kind": "OBJECT",
              "name": "consent",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "consentInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertCpoClaim",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "cpoClaimInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertCpoClaimOne",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaim",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "cpoClaimInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertDependantOrder",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "dependantOrderInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertDependantOrderOne",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "dependantOrderInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertDiagnosisAbbreviation",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "diagnosisAbbreviationInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertDiagnosisAbbreviationOne",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviation",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "diagnosisAbbreviationInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertDocument",
            "type": {
              "kind": "OBJECT",
              "name": "documentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "documentInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertDocumentOne",
            "type": {
              "kind": "OBJECT",
              "name": "document",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "documentInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertDocumentPendingReview",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "documentPendingReviewInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertDocumentPendingReviewOne",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReview",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "documentPendingReviewInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertExternalSignedOrder",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "externalSignedOrderInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertExternalSignedOrderOne",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalSignedOrderInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertExternalStaffMyPatients",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "externalStaffMyPatientsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertExternalStaffMyPatientsOne",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatients",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalStaffMyPatientsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertExternalstaff",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "externalstaffInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertExternalstaffOne",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaff",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalstaffInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertFax",
            "type": {
              "kind": "OBJECT",
              "name": "faxMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "faxInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertFaxDocuments",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "faxDocumentsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertFaxDocumentsOne",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocuments",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "faxDocumentsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertFaxOne",
            "type": {
              "kind": "OBJECT",
              "name": "fax",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "faxInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertFreeTextOrder",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "freeTextOrderInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertFreeTextOrderOne",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "freeTextOrderInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertFrequencyOrder",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "frequencyOrderInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertFrequencyOrderOne",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "frequencyOrderInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertFrequencyProtocol",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "frequencyProtocolInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertFrequencyProtocolOne",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocol",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "frequencyProtocolInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertHcpcs",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "hcpcsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertHcpcsOne",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcs",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "hcpcsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertInsuranceplan",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "insuranceplanInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertInsuranceplanOne",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplan",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "insuranceplanInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertIntegration",
            "type": {
              "kind": "OBJECT",
              "name": "integrationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "integrationInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertIntegrationOne",
            "type": {
              "kind": "OBJECT",
              "name": "integration",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "integrationInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertInternalstaff",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "internalstaffInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertInternalstaffOne",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaff",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "internalstaffInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLegacyOrder",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "legacyOrderInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLegacyOrderFrequency",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "legacyOrderFrequencyInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencyOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLegacyOrderFrequencyOne",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequency",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderFrequencyInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencyOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLegacyOrderMedications",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "legacyOrderMedicationsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLegacyOrderMedicationsOne",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedications",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderMedicationsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLegacyOrderOne",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLegacyOrderOrderableFieldValue",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "legacyOrderOrderableFieldValueInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLegacyOrderOrderableFieldValueOne",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderOrderableFieldValueInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLegacyOrderQualification",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "legacyOrderQualificationInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLegacyOrderQualificationOne",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderQualificationInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLegacyTreatmentplanOrder",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "legacyTreatmentplanOrderInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLegacyTreatmentplanOrderOne",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyTreatmentplanOrderInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLineitem",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "lineitemInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertLineitemOne",
            "type": {
              "kind": "OBJECT",
              "name": "lineitem",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "lineitemInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertMedication",
            "type": {
              "kind": "OBJECT",
              "name": "medicationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "medicationInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertMedicationOne",
            "type": {
              "kind": "OBJECT",
              "name": "medication",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertMedicationOrder",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "medicationOrderInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertMedicationOrderOne",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationOrderInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertMedicationorderMedications",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "medicationorderMedicationsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertMedicationorderMedicationsOne",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedications",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationorderMedicationsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertMessage",
            "type": {
              "kind": "OBJECT",
              "name": "messageMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "messageInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertMessageOne",
            "type": {
              "kind": "OBJECT",
              "name": "message",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "messageInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderOrderableField",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderOrderableFieldInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderOrderableFieldOne",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableField",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderOrderableFieldInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderOrderableLegacy",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderOrderableLegacyInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderOrderableLegacyOne",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacy",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderOrderableLegacyInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderable",
            "type": {
              "kind": "OBJECT",
              "name": "orderableMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableCategory",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableCategoryInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategoryOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableCategoryOne",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategory",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableCategoryInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategoryOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableOne",
            "type": {
              "kind": "OBJECT",
              "name": "orderable",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableOrder",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableOrderInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableOrderOne",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableOrderInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableOrderQualification",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableOrderQualificationInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableOrderQualificationOne",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableOrderQualificationInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableQualification",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableQualificationInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableQualificationOne",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableQualificationInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableSupplier",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableSupplierInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableSupplierOne",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplier",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableSupplierInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableSupplierPayerConnections",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableSupplierPayerConnectionsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderableSupplierPayerConnectionsOne",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnections",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableSupplierPayerConnectionsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderaction",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderactionInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertOrderactionOne",
            "type": {
              "kind": "OBJECT",
              "name": "orderaction",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderactionInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatient",
            "type": {
              "kind": "OBJECT",
              "name": "patientMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientAlias",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientAliasInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientAliasOne",
            "type": {
              "kind": "OBJECT",
              "name": "patientAlias",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientAliasInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientCommunication",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientCommunicationInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientCommunicationOne",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunication",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientCommunicationInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientContactPerson",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientContactPersonInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientContactPersonOne",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPerson",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientContactPersonInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientEligibilityCheck",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientEligibilityCheckInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientEligibilityCheckOne",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheck",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientEligibilityCheckInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientIntake",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientIntakeInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientIntakeOne",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntake",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientIntakeInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientIntakeRecordsLocations",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientIntakeRecordsLocationsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientIntakeRecordsLocationsOne",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocations",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientIntakeRecordsLocationsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientOne",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientPhone",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientPhoneInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientPhoneOne",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhone",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientPhoneInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientPhoto",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientPhotoInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientPhotoOne",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoto",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientPhotoInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientStatus",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientStatusInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientStatusAction",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientStatusActionInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientStatusActionOne",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusAction",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusActionInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientStatusOne",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatus",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientStatusSubsequentStatuses",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientStatusSubsequentStatusesInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPatientStatusSubsequentStatusesOne",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatuses",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusSubsequentStatusesInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPayer",
            "type": {
              "kind": "OBJECT",
              "name": "payerMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "payerInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPayerOne",
            "type": {
              "kind": "OBJECT",
              "name": "payer",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "payerInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPracticegroup",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "practicegroupInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertPracticegroupOne",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroup",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "practicegroupInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProblem",
            "type": {
              "kind": "OBJECT",
              "name": "problemMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "problemInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProblemOne",
            "type": {
              "kind": "OBJECT",
              "name": "problem",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "problemInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProblemPlan",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "problemPlanInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProblemPlanOne",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlan",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "problemPlanInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProcedure",
            "type": {
              "kind": "OBJECT",
              "name": "procedureMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "procedureInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProcedureHCPCS",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "procedureHCPCSInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProcedureHCPCSOne",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCS",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureHCPCSInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProcedureOne",
            "type": {
              "kind": "OBJECT",
              "name": "procedure",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProcedureRendered",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "procedureRenderedInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProcedureRenderedOne",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRendered",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureRenderedInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProvider",
            "type": {
              "kind": "OBJECT",
              "name": "providerMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "providerInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProviderClassification",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "providerClassificationInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProviderClassificationOne",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassification",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerClassificationInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProviderOne",
            "type": {
              "kind": "OBJECT",
              "name": "provider",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProviderSchedule",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "providerScheduleInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertProviderScheduleOne",
            "type": {
              "kind": "OBJECT",
              "name": "providerSchedule",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerScheduleInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertRecordsRequestDestination",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "recordsRequestDestinationInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertRecordsRequestDestinationOne",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestination",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "recordsRequestDestinationInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertReferral",
            "type": {
              "kind": "OBJECT",
              "name": "referralMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "referralInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertReferralAttachments",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "referralAttachmentsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertReferralAttachmentsOne",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachments",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "referralAttachmentsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertReferralOne",
            "type": {
              "kind": "OBJECT",
              "name": "referral",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "referralInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertRenderedAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "renderedAssessmentInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertRenderedAssessmentOne",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "renderedAssessmentInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertSchedulingAction",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "schedulingActionInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertSchedulingActionOne",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingAction",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "schedulingActionInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertServiceType",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "serviceTypeInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertServiceTypeOne",
            "type": {
              "kind": "OBJECT",
              "name": "serviceType",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "serviceTypeInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertSignedConsent",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "signedConsentInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertSignedConsentOne",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsent",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signedConsentInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertSignoff",
            "type": {
              "kind": "OBJECT",
              "name": "signoffMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "signoffInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertSignoffOne",
            "type": {
              "kind": "OBJECT",
              "name": "signoff",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signoffInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertSignoffType",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "signoffTypeInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertSignoffTypeOne",
            "type": {
              "kind": "OBJECT",
              "name": "signoffType",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signoffTypeInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTelemedAppointmentRequest",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "telemedAppointmentRequestInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTelemedAppointmentRequestAccessCode",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "telemedAppointmentRequestAccessCodeInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTelemedAppointmentRequestAccessCodeOne",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCode",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "telemedAppointmentRequestAccessCodeInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTelemedAppointmentRequestOne",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequest",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "telemedAppointmentRequestInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertThreadCategory",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "threadCategoryInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertThreadCategoryOne",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategory",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadCategoryInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertThreadCategoryStatus",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "threadCategoryStatusInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertThreadCategoryStatusOne",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatus",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadCategoryStatusInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertThreadField",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "threadFieldInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertThreadFieldOne",
            "type": {
              "kind": "OBJECT",
              "name": "threadField",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadFieldInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertThreadFieldValue",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "threadFieldValueInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertThreadFieldValueOne",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadFieldValueInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTicketSubscription",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "ticketSubscriptionInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTicketSubscriptionOne",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscription",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "ticketSubscriptionInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTicketThread",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "ticketThreadInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTicketThreadOne",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThread",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "ticketThreadInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTreatmentplan",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "treatmentplanInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTreatmentplanAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "treatmentplanAssessmentInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTreatmentplanAssessmentOne",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanAssessmentInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTreatmentplanNote",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "treatmentplanNoteInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTreatmentplanNoteOne",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNote",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanNoteInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTreatmentplanOne",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplan",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTreatmentplanProblems",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "treatmentplanProblemsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTreatmentplanProblemsOne",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblems",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanProblemsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTreatmentplanTemplate",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "treatmentplanTemplateInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTreatmentplanTemplateAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "treatmentplanTemplateAssessmentInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTreatmentplanTemplateAssessmentOne",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanTemplateAssessmentInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertTreatmentplanTemplateOne",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplate",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanTemplateInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertUser",
            "type": {
              "kind": "OBJECT",
              "name": "userMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "userInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertUserGroups",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "userGroupsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertUserGroupsOne",
            "type": {
              "kind": "OBJECT",
              "name": "userGroups",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "userGroupsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertUserOne",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "userInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertUserPermissions",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "userPermissionsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertUserPermissionsOne",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissions",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "userPermissionsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertVisitRebill",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "visitRebillInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertVisitRebillOne",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebill",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "visitRebillInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertVitals",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "objects",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "vitalsInsertInput",
                        "ofType": null
                      }
                    }
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insertVitalsOne",
            "type": {
              "kind": "OBJECT",
              "name": "vitals",
              "ofType": null
            },
            "args": [
              {
                "name": "object",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "vitalsInsertInput",
                    "ofType": null
                  }
                }
              },
              {
                "name": "onConflict",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsOnConflict",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "updateAddress",
            "type": {
              "kind": "OBJECT",
              "name": "addressMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "addressBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAddressByPk",
            "type": {
              "kind": "OBJECT",
              "name": "address",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "addressPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAddressMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "addressMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "addressUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgency",
            "type": {
              "kind": "OBJECT",
              "name": "agencyMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencySetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencySetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyLocation",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyLocationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyLocationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocation",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyLocationPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyLocationMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyLocationMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "agencyLocationUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "agencyUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyPayers",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayersMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyPayersBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyPayersByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayers",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyPayersPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyPayersMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyPayersMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "agencyPayersUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyServices",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServicesMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyServicesBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyServicesByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServices",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyServicesPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyServicesMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyServicesMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "agencyServicesUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyconnection",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnectionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyconnectionBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyconnectionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnection",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyconnectionPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyconnectionMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyconnectionMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "agencyconnectionUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyjoinrequest",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequestMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyjoinrequestBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyjoinrequestByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequest",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "agencyjoinrequestPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAgencyjoinrequestMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyjoinrequestMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "agencyjoinrequestUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateAllergy",
            "type": {
              "kind": "OBJECT",
              "name": "allergyMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergySetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "allergyBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAllergyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "allergy",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergySetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "allergyPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAllergyMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "allergyMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "allergyUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateAppointment",
            "type": {
              "kind": "OBJECT",
              "name": "appointmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "appointmentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAppointmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "appointmentPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAppointmentMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "appointmentMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "appointmentUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "assessment",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAssessmentItem",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentItemBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAssessmentItemByPk",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItem",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentItemPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAssessmentItemMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentItemMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "assessmentItemUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateAssessmentItemValue",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValueMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentItemValueBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAssessmentItemValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValue",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "assessmentItemValuePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateAssessmentItemValueMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentItemValueMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "assessmentItemValueUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateAssessmentMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "assessmentUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateBaseProtocolOrder",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "baseProtocolOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateBaseProtocolOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "baseProtocolOrderPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateBaseProtocolOrderMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseProtocolOrderMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "baseProtocolOrderUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateBaseorder",
            "type": {
              "kind": "OBJECT",
              "name": "baseorderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "baseorderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateBaseorderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "baseorder",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "baseorderPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateBaseorderMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorderMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "baseorderUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateCaretime",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "caretimeBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateCaretimeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "caretime",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "caretimePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateCaretimeMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "caretimeUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateCaretimeProblems",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblemsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "caretimeProblemsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateCaretimeProblemsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblems",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "caretimeProblemsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateCaretimeProblemsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeProblemsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "caretimeProblemsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateClaim",
            "type": {
              "kind": "OBJECT",
              "name": "claimMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "claimBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClaimByPk",
            "type": {
              "kind": "OBJECT",
              "name": "claim",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "claimPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClaimMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "claimMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "claimUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalNoteFutureReferrals",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferralsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalNoteFutureReferralsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalNoteFutureReferralsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferrals",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalNoteFutureReferralsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalNoteFutureReferralsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalNoteFutureReferralsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "clinicalNoteFutureReferralsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnote",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnote",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnotePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteField",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteFieldBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteFieldByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteField",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteFieldPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteFieldMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteFieldMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "clinicalnoteFieldUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteFieldValue",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValueMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteFieldValueBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteFieldValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteFieldValuePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteFieldValueMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteFieldValueMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "clinicalnoteFieldValueUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "clinicalnoteUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteTemplate",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteTemplateBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteTemplateByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplate",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteTemplatePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteTemplateMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplateMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "clinicalnoteTemplateUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteTemplateProviderClassifications",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteTemplateProviderClassificationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "clinicalnoteTemplateProviderClassificationsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateClinicalnoteTemplateProviderClassificationsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplateProviderClassificationsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "clinicalnoteTemplateProviderClassificationsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateCodeset",
            "type": {
              "kind": "OBJECT",
              "name": "codesetMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateCodesetAddon",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddonMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetAddonBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateCodesetAddonByPk",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddon",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetAddonPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateCodesetAddonMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "codesetAddonMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "codesetAddonUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateCodesetBase",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBaseMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetBaseBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateCodesetBaseByPk",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBase",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetBasePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateCodesetBaseMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "codesetBaseMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "codesetBaseUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateCodesetByPk",
            "type": {
              "kind": "OBJECT",
              "name": "codeset",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "codesetPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateCodesetMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "codesetMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "codesetUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateConsent",
            "type": {
              "kind": "OBJECT",
              "name": "consentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "consentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateConsentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "consent",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "consentPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateConsentMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "consentMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "consentUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateCpoClaim",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaimMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "cpoClaimBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateCpoClaimByPk",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaim",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "cpoClaimPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateCpoClaimMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "cpoClaimMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "cpoClaimUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateDependantOrder",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "dependantOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateDependantOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "dependantOrderPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateDependantOrderMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "dependantOrderMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "dependantOrderUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateDiagnosisAbbreviation",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "diagnosisAbbreviationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateDiagnosisAbbreviationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviation",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "diagnosisAbbreviationPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateDiagnosisAbbreviationMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "diagnosisAbbreviationMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "diagnosisAbbreviationUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateDocument",
            "type": {
              "kind": "OBJECT",
              "name": "documentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "documentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateDocumentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "document",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "documentPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateDocumentMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "documentUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateDocumentPendingReview",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReviewMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "documentPendingReviewBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateDocumentPendingReviewByPk",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReview",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "documentPendingReviewPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateDocumentPendingReviewMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentPendingReviewMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "documentPendingReviewUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateExternalSignedOrder",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalSignedOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateExternalSignedOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalSignedOrderPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateExternalSignedOrderMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalSignedOrderMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "externalSignedOrderUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateExternalStaffMyPatients",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatientsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalStaffMyPatientsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateExternalStaffMyPatientsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatients",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalStaffMyPatientsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateExternalStaffMyPatientsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalStaffMyPatientsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "externalStaffMyPatientsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateExternalstaff",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaffMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalstaffBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateExternalstaffByPk",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaff",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "externalstaffPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateExternalstaffMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalstaffMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "externalstaffUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateFax",
            "type": {
              "kind": "OBJECT",
              "name": "faxMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "faxBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateFaxByPk",
            "type": {
              "kind": "OBJECT",
              "name": "fax",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "faxPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateFaxDocuments",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocumentsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "faxDocumentsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateFaxDocumentsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocuments",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "faxDocumentsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateFaxDocumentsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "faxDocumentsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "faxDocumentsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateFaxMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "faxMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "faxUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateFreeTextOrder",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "freeTextOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateFreeTextOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "freeTextOrderPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateFreeTextOrderMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "freeTextOrderMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "freeTextOrderUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateFrequencyOrder",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "frequencyOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateFrequencyOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "frequencyOrderPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateFrequencyOrderMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "frequencyOrderMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "frequencyOrderUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateFrequencyProtocol",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocolMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "frequencyProtocolBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateFrequencyProtocolByPk",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocol",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "frequencyProtocolPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateFrequencyProtocolMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "frequencyProtocolMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "frequencyProtocolUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateHcpcs",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "hcpcsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateHcpcsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcs",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "hcpcsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateHcpcsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "hcpcsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "hcpcsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateInsuranceplan",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplanMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "insuranceplanBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateInsuranceplanByPk",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplan",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "insuranceplanPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateInsuranceplanMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "insuranceplanMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "insuranceplanUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateIntegration",
            "type": {
              "kind": "OBJECT",
              "name": "integrationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "integrationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateIntegrationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "integration",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "integrationPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateIntegrationMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "integrationMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "integrationUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateInternalstaff",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaffMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "internalstaffBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateInternalstaffByPk",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaff",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "internalstaffPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateInternalstaffMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "internalstaffMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "internalstaffUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrder",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderFrequency",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequencyMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencyIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencySetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderFrequencyBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderFrequencyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequency",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencyIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencySetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderFrequencyPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderFrequencyMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderFrequencyMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "legacyOrderFrequencyUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "legacyOrderUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderMedications",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedicationsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderMedicationsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderMedicationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedications",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderMedicationsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderMedicationsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderMedicationsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "legacyOrderMedicationsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderOrderableFieldValue",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValueMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderOrderableFieldValueBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderOrderableFieldValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderOrderableFieldValuePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderOrderableFieldValueMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderOrderableFieldValueMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "legacyOrderOrderableFieldValueUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderQualification",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualificationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderQualificationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderQualificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyOrderQualificationPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyOrderQualificationMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderQualificationMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "legacyOrderQualificationUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyTreatmentplanOrder",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyTreatmentplanOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyTreatmentplanOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "legacyTreatmentplanOrderPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLegacyTreatmentplanOrderMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyTreatmentplanOrderMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "legacyTreatmentplanOrderUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateLineitem",
            "type": {
              "kind": "OBJECT",
              "name": "lineitemMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "lineitemBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLineitemByPk",
            "type": {
              "kind": "OBJECT",
              "name": "lineitem",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "lineitemPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateLineitemMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "lineitemMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "lineitemUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateMedication",
            "type": {
              "kind": "OBJECT",
              "name": "medicationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateMedicationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "medication",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateMedicationMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "medicationUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateMedicationOrder",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateMedicationOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationOrderPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateMedicationOrderMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationOrderMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "medicationOrderUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateMedicationorderMedications",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedicationsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationorderMedicationsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateMedicationorderMedicationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedications",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "medicationorderMedicationsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateMedicationorderMedicationsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationorderMedicationsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "medicationorderMedicationsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateMessage",
            "type": {
              "kind": "OBJECT",
              "name": "messageMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "messageBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateMessageByPk",
            "type": {
              "kind": "OBJECT",
              "name": "message",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "messagePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateMessageMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "messageMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "messageUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderOrderableField",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderOrderableFieldBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderOrderableFieldByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableField",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderOrderableFieldPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderOrderableFieldMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderOrderableFieldMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderOrderableFieldUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderOrderableLegacy",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacySetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderOrderableLegacyBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderOrderableLegacyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacy",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacySetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderOrderableLegacyPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderOrderableLegacyMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderOrderableLegacyMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderOrderableLegacyUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderable",
            "type": {
              "kind": "OBJECT",
              "name": "orderableMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderable",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderablePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableCategory",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategoryIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategorySetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableCategoryBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableCategoryByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategory",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategoryIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategorySetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableCategoryPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableCategoryMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableCategoryMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableCategoryUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableOrder",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableOrderBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableOrderPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableOrderMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableOrderMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableOrderUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableOrderQualification",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableOrderQualificationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableOrderQualificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableOrderQualificationPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableOrderQualificationMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableOrderQualificationMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableOrderQualificationUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableQualification",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableQualificationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableQualificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableQualificationPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableQualificationMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableQualificationMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableQualificationUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableSupplier",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableSupplierBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableSupplierByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplier",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableSupplierPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableSupplierMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableSupplierMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableSupplierUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableSupplierPayerConnections",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableSupplierPayerConnectionsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableSupplierPayerConnectionsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnections",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableSupplierPayerConnectionsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderableSupplierPayerConnectionsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableSupplierPayerConnectionsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderableSupplierPayerConnectionsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderaction",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderactionBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderactionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderaction",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderactionPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateOrderactionMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderactionMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "orderactionUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatient",
            "type": {
              "kind": "OBJECT",
              "name": "patientMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientAlias",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientAliasBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientAliasByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientAlias",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientAliasPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientAliasMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientAliasMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientAliasUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientCommunication",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientCommunicationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientCommunicationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunication",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientCommunicationPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientCommunicationMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientCommunicationMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientCommunicationUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientContactPerson",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientContactPersonBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientContactPersonByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPerson",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientContactPersonPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientContactPersonMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientContactPersonMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientContactPersonUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientEligibilityCheck",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientEligibilityCheckBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientEligibilityCheckByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheck",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientEligibilityCheckPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientEligibilityCheckMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientEligibilityCheckMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientEligibilityCheckUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientIntake",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientIntakeBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientIntakeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntake",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientIntakePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientIntakeMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntakeMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientIntakeUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientIntakeRecordsLocations",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientIntakeRecordsLocationsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientIntakeRecordsLocationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocations",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientIntakeRecordsLocationsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientIntakeRecordsLocationsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntakeRecordsLocationsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientIntakeRecordsLocationsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientPhone",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientPhoneBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientPhoneByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhone",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientPhonePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientPhoneMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientPhoneMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientPhoneUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientPhoto",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientPhotoBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientPhotoByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoto",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientPhotoPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientPhotoMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientPhotoMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientPhotoUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientStatus",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientStatusAction",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusActionBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientStatusActionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusAction",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusActionPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientStatusActionMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatusActionMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientStatusActionUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientStatusByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatus",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientStatusMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatusMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientStatusUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientStatusSubsequentStatuses",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusSubsequentStatusesBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientStatusSubsequentStatusesByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatuses",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusSubsequentStatusesPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePatientStatusSubsequentStatusesMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatusSubsequentStatusesMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "patientStatusSubsequentStatusesUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePayer",
            "type": {
              "kind": "OBJECT",
              "name": "payerMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "payerBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePayerByPk",
            "type": {
              "kind": "OBJECT",
              "name": "payer",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "payerPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePayerMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "payerMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "payerUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updatePracticegroup",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "practicegroupBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePracticegroupByPk",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroup",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "practicegroupPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updatePracticegroupMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "practicegroupMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "practicegroupUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateProblem",
            "type": {
              "kind": "OBJECT",
              "name": "problemMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "problemBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProblemByPk",
            "type": {
              "kind": "OBJECT",
              "name": "problem",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "problemPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProblemMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "problemMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "problemUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateProblemPlan",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "problemPlanBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProblemPlanByPk",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlan",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "problemPlanPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProblemPlanMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "problemPlanMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "problemPlanUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateProcedure",
            "type": {
              "kind": "OBJECT",
              "name": "procedureMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProcedureByPk",
            "type": {
              "kind": "OBJECT",
              "name": "procedure",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedurePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProcedureHCPCS",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureHCPCSBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProcedureHCPCSByPk",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCS",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureHCPCSPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProcedureHCPCSMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureHCPCSMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "procedureHCPCSUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateProcedureMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "procedureUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateProcedureRendered",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureRenderedBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProcedureRenderedByPk",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRendered",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureRenderedPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProcedureRenderedMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureRenderedMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "procedureRenderedUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateProvider",
            "type": {
              "kind": "OBJECT",
              "name": "providerMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProviderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "provider",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProviderClassification",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerClassificationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProviderClassificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassification",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerClassificationPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProviderClassificationMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerClassificationMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "providerClassificationUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateProviderMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "providerUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateProviderSchedule",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerScheduleBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProviderScheduleByPk",
            "type": {
              "kind": "OBJECT",
              "name": "providerSchedule",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerSchedulePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateProviderScheduleMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerScheduleMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "providerScheduleUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateRecordsRequestDestination",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "recordsRequestDestinationBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateRecordsRequestDestinationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestination",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "recordsRequestDestinationPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateRecordsRequestDestinationMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "recordsRequestDestinationMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "recordsRequestDestinationUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateReferral",
            "type": {
              "kind": "OBJECT",
              "name": "referralMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "referralBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateReferralAttachments",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "referralAttachmentsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateReferralAttachmentsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachments",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "referralAttachmentsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateReferralAttachmentsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "referralAttachmentsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "referralAttachmentsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateReferralByPk",
            "type": {
              "kind": "OBJECT",
              "name": "referral",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "referralPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateReferralMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "referralMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "referralUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateRenderedAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "renderedAssessmentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateRenderedAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "renderedAssessmentPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateRenderedAssessmentMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "renderedAssessmentMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "renderedAssessmentUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateSchedulingAction",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "schedulingActionBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateSchedulingActionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingAction",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "schedulingActionPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateSchedulingActionMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "schedulingActionMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "schedulingActionUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateServiceType",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "serviceTypeBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateServiceTypeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "serviceType",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "serviceTypePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateServiceTypeMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "serviceTypeMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "serviceTypeUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateSignedConsent",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signedConsentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateSignedConsentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsent",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signedConsentPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateSignedConsentMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "signedConsentMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "signedConsentUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateSignoff",
            "type": {
              "kind": "OBJECT",
              "name": "signoffMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signoffBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateSignoffByPk",
            "type": {
              "kind": "OBJECT",
              "name": "signoff",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signoffPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateSignoffMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "signoffMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "signoffUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateSignoffType",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signoffTypeBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateSignoffTypeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "signoffType",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signoffTypePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateSignoffTypeMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "signoffTypeMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "signoffTypeUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateTelemedAppointmentRequest",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "telemedAppointmentRequestBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTelemedAppointmentRequestAccessCode",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "telemedAppointmentRequestAccessCodeBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTelemedAppointmentRequestAccessCodeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCode",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "telemedAppointmentRequestAccessCodePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTelemedAppointmentRequestAccessCodeMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "telemedAppointmentRequestAccessCodeMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "telemedAppointmentRequestAccessCodeUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateTelemedAppointmentRequestByPk",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequest",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "telemedAppointmentRequestPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTelemedAppointmentRequestMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "telemedAppointmentRequestMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "telemedAppointmentRequestUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateThreadCategory",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategorySetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadCategoryBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateThreadCategoryByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategory",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategorySetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadCategoryPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateThreadCategoryMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadCategoryMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "threadCategoryUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateThreadCategoryStatus",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadCategoryStatusBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateThreadCategoryStatusByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatus",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadCategoryStatusPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateThreadCategoryStatusMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadCategoryStatusMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "threadCategoryStatusUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateThreadField",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadFieldBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateThreadFieldByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadField",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadFieldPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateThreadFieldMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadFieldMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "threadFieldUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateThreadFieldValue",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadFieldValueBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateThreadFieldValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadFieldValuePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateThreadFieldValueMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadFieldValueMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "threadFieldValueUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateTicketSubscription",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "ticketSubscriptionBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTicketSubscriptionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscription",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "ticketSubscriptionPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTicketSubscriptionMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketSubscriptionMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "ticketSubscriptionUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateTicketThread",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "ticketThreadBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTicketThreadByPk",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThread",
              "ofType": null
            },
            "args": [
              {
                "name": "_append",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadAppendInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteAtPath",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadDeleteAtPathInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteElem",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadDeleteElemInput",
                  "ofType": null
                }
              },
              {
                "name": "_deleteKey",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadDeleteKeyInput",
                  "ofType": null
                }
              },
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_prepend",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadPrependInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "ticketThreadPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTicketThreadMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketThreadMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "ticketThreadUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplan",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanAssessmentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanAssessmentPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanAssessmentMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanAssessmentMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "treatmentplanAssessmentUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplan",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "treatmentplanUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanNote",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanNoteBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanNoteByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNote",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanNotePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanNoteMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanNoteMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "treatmentplanNoteUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanProblems",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanProblemsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanProblemsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblems",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanProblemsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanProblemsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanProblemsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "treatmentplanProblemsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanTemplate",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanTemplateBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanTemplateAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanTemplateAssessmentBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanTemplateAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanTemplateAssessmentPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanTemplateAssessmentMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanTemplateAssessmentMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "treatmentplanTemplateAssessmentUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanTemplateByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplate",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanTemplatePkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateTreatmentplanTemplateMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanTemplateMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "treatmentplanTemplateUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateUser",
            "type": {
              "kind": "OBJECT",
              "name": "userMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "userBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateUserByPk",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "userPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateUserGroups",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "userGroupsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateUserGroupsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "userGroups",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "userGroupsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateUserGroupsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "userGroupsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "userGroupsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateUserMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "userMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "userUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateUserPermissions",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "userPermissionsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateUserPermissionsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissions",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "userPermissionsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateUserPermissionsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "userPermissionsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "userPermissionsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateVisitRebill",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "visitRebillBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateVisitRebillByPk",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebill",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "visitRebillPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateVisitRebillMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "visitRebillMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "visitRebillUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateVitals",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsMutationResponse",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "vitalsBoolExp",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateVitalsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "vitals",
              "ofType": null
            },
            "args": [
              {
                "name": "_inc",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsIncInput",
                  "ofType": null
                }
              },
              {
                "name": "_set",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsSetInput",
                  "ofType": null
                }
              },
              {
                "name": "pk_columns",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "vitalsPkColumnsInput",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "updateVitalsMany",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "OBJECT",
                "name": "vitalsMutationResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "updates",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "INPUT_OBJECT",
                        "name": "vitalsUpdates",
                        "ofType": null
                      }
                    }
                  }
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "SCALAR",
        "name": "numeric"
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableField",
        "fields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "fieldValues",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderOrderableFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderableFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "fieldValuesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderOrderableFieldValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderableFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "insertText",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderable",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderable",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientQualification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "required",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderOrderableField",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableFieldVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableField_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableField_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableField_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableField_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableField_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableField_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableField_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableField_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableField_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableField_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldAppendInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderOrderableFieldInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "fieldValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValueBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patientQualification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "required",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldDeleteAtPathInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldDeleteElemInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldDeleteKeyInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "fieldValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValueArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientQualification",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "required",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderOrderableField",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderOrderableFieldInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldOrderBy",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldValuesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValueAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientQualification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "required",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldPrependInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldSetInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientQualification",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "required",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableFieldUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderOrderableFieldBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableFieldVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableField_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableField_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableField_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableField_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableField_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableField_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableField_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderOrderableField_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableField_streamCursorValueInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insertText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientQualification",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "required",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableField_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableField_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableField_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableField_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderWithinGroup",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacy",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderable",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderable",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacyAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderOrderableLegacy",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacyAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacySumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacyVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacyAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacy_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacy_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacy_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacy_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacy_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacy_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacy_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacy_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacy_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacy_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacyArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderOrderableLegacyInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacyOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacyAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacyBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacyIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacyInsertInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacyMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacyMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacyMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderOrderableLegacy",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacyOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacyBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacyOrderBy",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacyPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacySetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacyStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacyStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacyStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacySumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacyUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacyIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacySetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderOrderableLegacyBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacyVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacyVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderOrderableLegacyVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacy_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacy_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacy_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacy_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacy_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacy_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacy_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderOrderableLegacy_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacy_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacy_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacy_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacy_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderOrderableLegacy_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "quantity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderable",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableCategory",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "children",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderable",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "childrenAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "description",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "legacyOrders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrdersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderableFields",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderOrderableField",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderableFieldsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderOrderableFieldAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderables",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderOrderableLegacy",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableLegacyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderablesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderOrderableLegacyAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableLegacyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableOrders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableOrdersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableQualifications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableQualificationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableQualificationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "parent",
            "type": {
              "kind": "OBJECT",
              "name": "orderable",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "suppliers",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplier",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "suppliersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableSupplierAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "validIcd10",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "_varchar",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "orderableAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderable",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "orderableAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "orderableMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "orderableMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "orderableStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "orderableVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderable_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderable_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderable_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderable_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderable_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderable_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderable_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderable_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderable_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderable_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableAvgFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableCategoryBoolExp",
              "ofType": null
            }
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "children",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableBoolExp",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "legacyOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderOrderableFields",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderOrderables",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderableOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderableQualifications",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "parent",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableBoolExp",
              "ofType": null
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "suppliers",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierBoolExp",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "validIcd10",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "_varcharComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategory",
        "fields": [
          {
            "name": "classification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderablesInCategory",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderable",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderablesInCategoryAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategoryAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableCategory",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategoryAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategorySumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategoryVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategoryAvgFields",
        "fields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableCategoryBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategoryBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableCategoryBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategoryBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderablesInCategory",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableBoolExp",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableCategoryIncInput",
        "inputFields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableCategoryInsertInput",
        "inputFields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderablesInCategory",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategoryMaxFields",
        "fields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategoryMinFields",
        "fields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategoryMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableCategory",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableCategoryObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableCategoryInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableCategoryOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableCategoryOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableCategoryBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableCategoryOrderBy",
        "inputFields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderablesInCategoryAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableCategoryPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableCategorySetInput",
        "inputFields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategoryStddevFields",
        "fields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategoryStddev_popFields",
        "fields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategoryStddev_sampFields",
        "fields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategorySumFields",
        "fields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableCategoryUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableCategoryIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableCategorySetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableCategoryBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategoryVar_popFields",
        "fields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategoryVar_sampFields",
        "fields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableCategoryVarianceFields",
        "fields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableCategory_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableCategory_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableCategory_streamCursorValueInput",
        "inputFields": [
          {
            "name": "classification",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableIncInput",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableInsertInput",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableCategoryObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "children",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "legacyOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderOrderableFields",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderOrderables",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacyArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderableOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderableQualifications",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "parent",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "suppliers",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "validIcd10",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableMaxFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableMinFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderable",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrder",
        "fields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "fieldValues",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderOrderableFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderableFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "fieldValuesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderOrderableFieldValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderableFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderable",
            "type": {
              "kind": "OBJECT",
              "name": "orderable",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrder_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrder_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrder_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrder_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrder_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrder_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrder_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrder_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrder_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrder_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableOrderInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderAvgFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "fieldValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValueBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderBy",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableCategoryOrderBy",
              "ofType": null
            }
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "childrenAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "legacyOrdersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderOrderableFieldsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableFieldAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderOrderablesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderOrderableLegacyAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderableOrdersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderableQualificationsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "parent",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderBy",
              "ofType": null
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "suppliersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "validIcd10",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderIncInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderInsertInput",
        "inputFields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "fieldValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValueArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderMaxFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderMinFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableOrderInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderOrderBy",
        "inputFields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldValuesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderOrderableFieldValueAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderPkColumnsInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualification",
        "fields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderableQualification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableQualification",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualificationVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualificationAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualification_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualification_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualification_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualification_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualification_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualification_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualification_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualification_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualification_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualification_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualificationArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableOrderQualificationInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualificationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualificationBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualificationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderableQualification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualificationIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualificationInsertInput",
        "inputFields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderableQualification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualificationOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualificationBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualificationOrderBy",
        "inputFields": [
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableQualification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationOrderBy",
              "ofType": null
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualificationPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualificationSetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualificationUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualificationIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualificationSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableOrderQualificationBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderQualificationVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualification_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualification_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualification_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualification_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualification_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualification_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualification_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableOrderQualification_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualification_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualification_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualification_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualification_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderQualification_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "qualificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderSetInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderStddevFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderStddev_popFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderStddev_sampFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderSumFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrderUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableOrderBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderVar_popFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderVar_sampFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableOrderVarianceFields",
        "fields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrder_avg_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrder_max_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrder_min_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrder_stddev_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrder_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrder_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrder_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableOrder_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrder_streamCursorValueInput",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrder_sum_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrder_var_pop_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrder_var_samp_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableOrder_variance_order_by",
        "inputFields": [
          {
            "name": "baseorderPtrId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderablePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualification",
        "fields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "noteText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderQualificationValues",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderQualificationValuesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableOrderQualificationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderQualifications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderQualificationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderQualificationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderable",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderable",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "required",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualificationVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualification_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualification_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualification_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualification_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualification_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualification_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualification_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualification_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualification_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualification_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationAppendInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableQualificationInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "data",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "noteText",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderQualificationValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualificationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderQualifications",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualificationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "required",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationDeleteAtPathInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationDeleteElemInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationDeleteKeyInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "noteText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderQualificationValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualificationArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderQualifications",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualificationArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "required",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "noteText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "noteText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableQualificationInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationOrderBy",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "noteText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderQualificationValuesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderQualificationAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderQualificationsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderQualificationAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "required",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationPrependInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationSetInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "noteText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "required",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualificationUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableQualificationSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableQualificationBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableQualificationVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualification_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualification_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "noteText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualification_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "noteText",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualification_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualification_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualification_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualification_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableQualification_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualification_streamCursorValueInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "noteText",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "required",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualification_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualification_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualification_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableQualification_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSetInput",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "validIcd10",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableStddevFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableStddev_popFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableStddev_sampFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSumFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplier",
        "fields": [
          {
            "name": "acceptedPayers",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplierPayerConnections",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierPayerConnectionsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "acceptedPayersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableSupplierPayerConnectionsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierPayerConnectionsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agency",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderable",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderable",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplier",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplier_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplier_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplier_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplier_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplier_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplier_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplier_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplier_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplier_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplier_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableSupplierInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierAvgFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "acceptedPayers",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnectionsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierIncInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierInsertInput",
        "inputFields": [
          {
            "name": "acceptedPayers",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnectionsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierMaxFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierMinFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplier",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableSupplierInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierOrderBy",
        "inputFields": [
          {
            "name": "acceptedPayersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnectionsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "agency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnections",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "payer",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "payer",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "supplier",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableSupplier",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplierPayerConnections",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnectionsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnectionsAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnections_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnections_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnections_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnections_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnections_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnections_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnections_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnections_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnections_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnections_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnectionsArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderableSupplierPayerConnectionsInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnectionsOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnectionsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnectionsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "payer",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "supplier",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnectionsIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnectionsInsertInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "payer",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "supplier",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierObjRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplierPayerConnections",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnectionsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnectionsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnectionsOrderBy",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payer",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supplier",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnectionsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnectionsSetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnectionsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnectionsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnectionsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableSupplierPayerConnectionsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierPayerConnectionsVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnections_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnections_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnections_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnections_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnections_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnections_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnections_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableSupplierPayerConnections_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnections_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnections_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnections_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnections_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPayerConnections_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderablesupplierId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "payerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierSetInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierStddevFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierStddev_popFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierStddev_sampFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierSumFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplierUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableSupplierBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierVar_popFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierVar_sampFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableSupplierVarianceFields",
        "fields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplier_avg_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplier_max_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplier_min_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplier_stddev_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplier_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplier_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplier_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableSupplier_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplier_streamCursorValueInput",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplier_sum_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplier_var_pop_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplier_var_samp_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableSupplier_variance_order_by",
        "inputFields": [
          {
            "name": "agencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderableUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderableBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderableVar_popFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableVar_sampFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderableVarianceFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderable_avg_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderable_max_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderable_min_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderable_stddev_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderable_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderable_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderable_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderable_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderable_streamCursorValueInput",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "validIcd10",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderable_sum_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderable_var_pop_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderable_var_samp_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderable_variance_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderaction",
        "fields": [
          {
            "name": "action",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "baseorder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorder",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "enteringUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "user",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "responsibleProvider",
            "type": {
              "kind": "OBJECT",
              "name": "provider",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderactionAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderaction",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderactionAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "orderactionVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderactionAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderaction_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderaction_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderaction_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderaction_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderaction_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderaction_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderaction_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderaction_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderaction_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderaction_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderactionArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "orderactionInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderactionAvgFields",
        "fields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderactionBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "action",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "enteringUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "responsibleProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderactionIncInput",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderactionInsertInput",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "enteringUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "responsibleProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderactionMaxFields",
        "fields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderactionMinFields",
        "fields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderactionMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderaction",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderactionOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderactionOrderBy",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "baseorder",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderOrderBy",
              "ofType": null
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "responsibleProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderactionPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderactionSetInput",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderactionStddevFields",
        "fields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderactionStddev_popFields",
        "fields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderactionStddev_sampFields",
        "fields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderactionSumFields",
        "fields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderactionUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderactionBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "orderactionVar_popFields",
        "fields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderactionVar_sampFields",
        "fields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "orderactionVarianceFields",
        "fields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderaction_avg_order_by",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderaction_max_order_by",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderaction_min_order_by",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderaction_stddev_order_by",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderaction_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderaction_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderaction_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "orderaction_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderaction_streamCursorValueInput",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderaction_sum_order_by",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderaction_var_pop_order_by",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderaction_var_samp_order_by",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "orderaction_variance_order_by",
        "inputFields": [
          {
            "name": "action",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "responsibleProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patient",
        "fields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "admittedStatus",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "agencyconnections",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyconnection",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyconnectionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyconnectionsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyconnectionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyconnectionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "allergies",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "allergy",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "allergyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "allergiesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "allergyAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "allergyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "appointments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "appointment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "appointmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "appointmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseorders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseorder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseordersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretime",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "claims",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "claim",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "claimOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "claimsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "claimAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "claimOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "contactPersons",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientContactPerson",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientContactPersonOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "contactPersonsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientContactPersonAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientContactPersonOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documents",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "document",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentsPendingReviewGuesses",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "documentPendingReview",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReviewOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentsPendingReviewGuessesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentPendingReviewAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReviewOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "emergencyContactName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "emergencyContactPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalSignedOrders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalSignedOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalSignedOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalSignedOrdersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalSignedOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalSignedOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalStaffClaimingMyPatient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalStaffMyPatients",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalStaffMyPatientsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalStaffClaimingMyPatientAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalStaffMyPatientsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalStaffMyPatientsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "faxes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "fax",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "faxesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "faxAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "firstName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "followupMax",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followupMin",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "gender",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "homeAddress",
            "type": {
              "kind": "OBJECT",
              "name": "address",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "insurancePlans",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "insuranceplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "insuranceplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insurancePlansAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "insuranceplanAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "insuranceplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "intakes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntake",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "intakesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntakeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "lastName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medication",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "middleName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nickName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "orders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientAliases",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientAlias",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientAliasOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientAliasesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientAliasAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientAliasOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "phones",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientPhone",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhoneOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "phonesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientPhoneAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhoneOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroup",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problems",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problemsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "problemAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referrals",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referral",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referralsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "referralAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "renderedAssessments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "renderedAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "renderedAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "renderedAssessmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "renderedAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "renderedAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "secondaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signedConsents",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signedConsent",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signedConsentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signedConsentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "signedConsentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signedConsentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusActions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "statusActionsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatusActionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequests",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "telemedAppointmentRequestAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketthreads",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketThread",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketthreadsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketThreadAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplans",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplansAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "user_can_access",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "patientAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patient",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "patientAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "patientMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "patientMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "patientStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "patientSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "patientVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patient_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patient_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patient_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patient_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patient_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patient_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patient_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patient_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patient_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patient_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientAlias",
        "fields": [
          {
            "name": "alias",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientAlias",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "patientAliasVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAliasAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAlias_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAlias_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAlias_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAlias_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAlias_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAlias_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAlias_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAlias_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAlias_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAlias_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAliasArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientAliasInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAliasOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAliasBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAliasBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "alias",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAliasIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAliasInsertInput",
        "inputFields": [
          {
            "name": "alias",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasMaxFields",
        "fields": [
          {
            "name": "alias",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasMinFields",
        "fields": [
          {
            "name": "alias",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientAlias",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAliasOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAliasBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAliasOrderBy",
        "inputFields": [
          {
            "name": "alias",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAliasPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAliasSetInput",
        "inputFields": [
          {
            "name": "alias",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAliasUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAliasIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAliasSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientAliasBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientAliasVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAlias_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAlias_max_order_by",
        "inputFields": [
          {
            "name": "alias",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAlias_min_order_by",
        "inputFields": [
          {
            "name": "alias",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAlias_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAlias_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAlias_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAlias_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientAlias_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAlias_streamCursorValueInput",
        "inputFields": [
          {
            "name": "alias",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAlias_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAlias_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAlias_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAlias_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientAppendInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientAvgFields",
        "fields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "address",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "admittedStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "agencyconnections",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnectionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "allergies",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "appointments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "baseorders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "claims",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimBoolExp",
              "ofType": null
            }
          },
          {
            "name": "contactPersons",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPersonBoolExp",
              "ofType": null
            }
          },
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "documents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "documentsPendingReviewGuesses",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewBoolExp",
              "ofType": null
            }
          },
          {
            "name": "email",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "emergencyContactName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "emergencyContactPhone",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalSignedOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "externalStaffClaimingMyPatient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatientsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "faxes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxBoolExp",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "followupMax",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "followupMin",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "homeAddress",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressBoolExp",
              "ofType": null
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "insurancePlans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanBoolExp",
              "ofType": null
            }
          },
          {
            "name": "intakes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "medications",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "middleName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "nickName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientAliases",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAliasBoolExp",
              "ofType": null
            }
          },
          {
            "name": "phones",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhoneBoolExp",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "primaryPhone",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "problems",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemBoolExp",
              "ofType": null
            }
          },
          {
            "name": "referrals",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralBoolExp",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "secondaryPhone",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "signedConsents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "statusActions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequests",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ticketthreads",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentplans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanBoolExp",
              "ofType": null
            }
          },
          {
            "name": "user_can_access",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunication",
        "fields": [
          {
            "name": "caretime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretime",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientCommunication",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunicationVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunicationAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunication_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunication_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunication_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunication_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunication_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunication_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunication_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunication_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunication_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunication_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunicationArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientCommunicationInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunicationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationAvgFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunicationBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunicationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "caretime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunicationIncInput",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunicationInsertInput",
        "inputFields": [
          {
            "name": "caretime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationMaxFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationMinFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientCommunication",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunicationOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunicationBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunicationOrderBy",
        "inputFields": [
          {
            "name": "caretime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeOrderBy",
              "ofType": null
            }
          },
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunicationPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunicationSetInput",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationStddevFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationStddev_popFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationStddev_sampFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationSumFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunicationUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunicationIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientCommunicationSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientCommunicationBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationVar_popFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationVar_sampFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientCommunicationVarianceFields",
        "fields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunication_avg_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunication_max_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunication_min_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunication_stddev_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunication_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunication_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunication_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientCommunication_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunication_streamCursorValueInput",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datesigned",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunication_sum_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunication_var_pop_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunication_var_samp_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientCommunication_variance_order_by",
        "inputFields": [
          {
            "name": "caretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPerson",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "emergencyContact",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "phones",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientPhone",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhoneOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "phonesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientPhoneAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhoneOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "rank",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "relationship",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "scheduling",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientContactPerson",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPersonVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPersonAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPerson_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPerson_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPerson_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPerson_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPerson_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPerson_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPerson_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPerson_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPerson_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPerson_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPersonArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientContactPersonInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPersonOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPersonBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPersonBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "emergencyContact",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "phones",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhoneBoolExp",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "relationship",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "scheduling",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPersonIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPersonInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "emergencyContact",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "phones",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhoneArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "relationship",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "scheduling",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonMaxFields",
        "fields": [
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "relationship",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonMinFields",
        "fields": [
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "relationship",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientContactPerson",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPersonObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientContactPersonInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPersonOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPersonOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPersonBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPersonOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "emergencyContact",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "phonesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhoneAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "relationship",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "scheduling",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPersonPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPersonSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "emergencyContact",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "relationship",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "scheduling",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPersonUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPersonIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPersonSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientContactPersonBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientContactPersonVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPerson_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPerson_max_order_by",
        "inputFields": [
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "relationship",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPerson_min_order_by",
        "inputFields": [
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "relationship",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPerson_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPerson_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPerson_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPerson_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientContactPerson_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPerson_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "emergencyContact",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "relationship",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "scheduling",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPerson_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPerson_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPerson_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientContactPerson_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientDeleteAtPathInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientDeleteElemInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientDeleteKeyInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheck",
        "fields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endDos",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "date",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "insuranceplan",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "insuranceplan",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "startDos",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "date",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientEligibilityCheck",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheckVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheck_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheck_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheck_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheck_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheck_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheck_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheck_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheck_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheck_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheck_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckAppendInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientEligibilityCheckInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckAvgFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "endDos",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "insuranceplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanBoolExp",
              "ofType": null
            }
          },
          {
            "name": "startDos",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckDeleteAtPathInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckDeleteElemInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckDeleteKeyInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckIncInput",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckInsertInput",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "endDos",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insuranceplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "startDos",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckMaxFields",
        "fields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endDos",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startDos",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckMinFields",
        "fields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endDos",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startDos",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientEligibilityCheck",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckOrderBy",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "endDos",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insuranceplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanOrderBy",
              "ofType": null
            }
          },
          {
            "name": "startDos",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckPrependInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckSetInput",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "endDos",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "startDos",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckStddevFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckStddev_popFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckStddev_sampFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckSumFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheckUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientEligibilityCheckSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientEligibilityCheckBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckVar_popFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckVar_sampFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientEligibilityCheckVarianceFields",
        "fields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheck_avg_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheck_max_order_by",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "endDos",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startDos",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheck_min_order_by",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "endDos",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startDos",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheck_stddev_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheck_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheck_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheck_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientEligibilityCheck_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheck_streamCursorValueInput",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "endDos",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "startDos",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheck_sum_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheck_var_pop_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheck_var_samp_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientEligibilityCheck_variance_order_by",
        "inputFields": [
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIncInput",
        "inputFields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientInsertInput",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "agencyconnections",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnectionArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "allergies",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergyArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "appointments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "baseorders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "claims",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "contactPersons",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPersonArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "documents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "documentsPendingReviewGuesses",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "emergencyContactName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "emergencyContactPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "externalSignedOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "externalStaffClaimingMyPatient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatientsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "faxes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "followupMax",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "followupMin",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "homeAddress",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insurancePlans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "intakes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "medications",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "middleName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "nickName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientAliases",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAliasArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "phones",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhoneArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "primaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "problems",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "referrals",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "secondaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "signedConsents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "statusActions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequests",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ticketthreads",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "treatmentplans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientIntake",
        "fields": [
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "insuranceId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insuranceName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastFourSocial",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "messages",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "message",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "messageOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "messagesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "messageAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "messageOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patient",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "practicegroup",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "recordsLocations",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntakeRecordsLocations",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeRecordsLocationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "recordsLocationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntakeRecordsLocationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeRecordsLocationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "submittedAgency",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agency",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "submittedUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "user",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "telemedAppointmentRequests",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "telemedAppointmentRequestAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "user_can_access",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntake",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntake_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntake_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntake_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntake_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntake_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntake_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntake_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntake_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntake_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntake_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientIntakeInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "insuranceId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "insuranceName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "lastFourSocial",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "messages",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "recordsLocations",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocationsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "submittedAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "submittedUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequests",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestBoolExp",
              "ofType": null
            }
          },
          {
            "name": "user_can_access",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeInsertInput",
        "inputFields": [
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insuranceId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "insuranceName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "lastFourSocial",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "messages",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "recordsLocations",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocationsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "submittedAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "submittedUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequests",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeMaxFields",
        "fields": [
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insuranceId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insuranceName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastFourSocial",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeMinFields",
        "fields": [
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insuranceId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "insuranceName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastFourSocial",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntake",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientIntakeInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeOrderBy",
        "inputFields": [
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insuranceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insuranceName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastFourSocial",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messagesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOrderBy",
              "ofType": null
            }
          },
          {
            "name": "recordsLocationsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocationsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedAppointmentRequestsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "user_can_access",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocations",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "intake",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntake",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "recordsLocation",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "recordsRequestDestination",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntakeRecordsLocations",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocationsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocationsAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocations_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocations_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocations_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocations_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocations_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocations_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocations_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocations_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocations_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocations_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocationsArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientIntakeRecordsLocationsInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocationsOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocationsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocationsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "intake",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "recordsLocation",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestinationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocationsIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocationsInsertInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "intake",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "recordsLocation",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestinationObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntakeRecordsLocations",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocationsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocationsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocationsOrderBy",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intake",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "recordsLocation",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestinationOrderBy",
              "ofType": null
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocationsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocationsSetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocationsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocationsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocationsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientIntakeRecordsLocationsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeRecordsLocationsVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocations_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocations_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocations_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocations_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocations_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocations_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocations_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientIntakeRecordsLocations_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocations_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocations_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocations_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocations_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeRecordsLocations_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientintakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "recordsrequestdestinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeSetInput",
        "inputFields": [
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insuranceId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "insuranceName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "lastFourSocial",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntakeUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientIntakeBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientIntakeVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntake_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntake_max_order_by",
        "inputFields": [
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insuranceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insuranceName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastFourSocial",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntake_min_order_by",
        "inputFields": [
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insuranceId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insuranceName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastFourSocial",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntake_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntake_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntake_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntake_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientIntake_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntake_streamCursorValueInput",
        "inputFields": [
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insuranceId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "insuranceName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "lastFourSocial",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntake_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntake_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntake_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientIntake_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedAgencyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "submittedUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientMaxFields",
        "fields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "emergencyContactName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "emergencyContactPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followupMax",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followupMin",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "middleName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nickName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "secondaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientMinFields",
        "fields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "emergencyContactName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "emergencyContactPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followupMax",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "followupMin",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "middleName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nickName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "secondaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patient",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientOrderBy",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "agencyconnectionsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyconnectionAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "allergiesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "allergyAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "appointmentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "baseordersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "caretimesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "claimsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "contactPersonsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPersonAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "documentsPendingReviewGuessesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "emergencyContactName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "emergencyContactPhone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalSignedOrdersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalSignedOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "externalStaffClaimingMyPatientAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalStaffMyPatientsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "faxesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followupMax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followupMin",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "homeAddress",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressOrderBy",
              "ofType": null
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlansAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "intakesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicationsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "medicationAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "middleName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "nickName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ordersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientAliasesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAliasAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "phonesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhoneAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOrderBy",
              "ofType": null
            }
          },
          {
            "name": "primaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "referralsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "secondaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signedConsentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusActionsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequestsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ticketthreadsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "treatmentplansAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "user_can_access",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientPhone",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contactPerson",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPerson",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "leaveVoicemail",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "notifyPhysical",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "notifyVirtual",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "number",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "supportsSms",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "typeDescription",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientPhone",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoneVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhoneAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhone_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhone_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhone_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhone_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhone_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhone_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhone_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhone_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhone_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhone_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhoneArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientPhoneInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhoneOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneAvgFields",
        "fields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhoneBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhoneBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "contactPerson",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPersonBoolExp",
              "ofType": null
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "leaveVoicemail",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notifyPhysical",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notifyVirtual",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "number",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "supportsSms",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "typeDescription",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhoneIncInput",
        "inputFields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhoneInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "contactPerson",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPersonObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "leaveVoicemail",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notifyPhysical",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "notifyVirtual",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "number",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "supportsSms",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "typeDescription",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneMaxFields",
        "fields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "number",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeDescription",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneMinFields",
        "fields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "number",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeDescription",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientPhone",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhoneOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhoneBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhoneOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contactPerson",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientContactPersonOrderBy",
              "ofType": null
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "leaveVoicemail",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notifyPhysical",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notifyVirtual",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "number",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supportsSms",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeDescription",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhonePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhoneSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "leaveVoicemail",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notifyPhysical",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "notifyVirtual",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "number",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "supportsSms",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "typeDescription",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneStddevFields",
        "fields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneStddev_popFields",
        "fields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneStddev_sampFields",
        "fields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneSumFields",
        "fields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhoneUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhoneIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhoneSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientPhoneBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneVar_popFields",
        "fields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneVar_sampFields",
        "fields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoneVarianceFields",
        "fields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhone_avg_order_by",
        "inputFields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhone_max_order_by",
        "inputFields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "number",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeDescription",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhone_min_order_by",
        "inputFields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "number",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeDescription",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhone_stddev_order_by",
        "inputFields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhone_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhone_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhone_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientPhone_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhone_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "leaveVoicemail",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notifyPhysical",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "notifyVirtual",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "number",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "supportsSms",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "typeDescription",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhone_sum_order_by",
        "inputFields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhone_var_pop_order_by",
        "inputFields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhone_var_samp_order_by",
        "inputFields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhone_variance_order_by",
        "inputFields": [
          {
            "name": "appointmentNotificationPreference",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "contactPersonId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rank",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientPhoto",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "document",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "document",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientPhoto",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhotoVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoAvgFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhotoBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhotoBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhotoIncInput",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhotoInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoMaxFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoMinFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientPhoto",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhotoOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhotoBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhotoOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhotoPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhotoSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoStddevFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoStddev_popFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoStddev_sampFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoSumFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhotoUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhotoIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPhotoSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientPhotoBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoVar_popFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoVar_sampFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientPhotoVarianceFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhoto_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientPhoto_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPhoto_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientPrependInput",
        "inputFields": [
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientSetInput",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "emergencyContactName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "emergencyContactPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "followupMax",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "followupMin",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "middleName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "nickName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "primaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "secondaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatus",
        "fields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientStatusActions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusActionsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatusActionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "requiresProviderAuthorization",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "statusOnExpires",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatus",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "underCare",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusAction",
        "fields": [
          {
            "name": "associatedAppointment",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "authorizingProvider",
            "type": {
              "kind": "OBJECT",
              "name": "provider",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetimeEffective",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "datetimeEnd",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "enteringUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "user",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatus",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusActionVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusActionAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusAction_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusAction_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusAction_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusAction_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusAction_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusAction_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusAction_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusAction_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusAction_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusAction_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusActionArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "patientStatusActionInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionAvgFields",
        "fields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusActionBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "associatedAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "authorizingProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "datetimeEffective",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "datetimeEnd",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "enteringUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusBoolExp",
              "ofType": null
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusActionIncInput",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusActionInsertInput",
        "inputFields": [
          {
            "name": "associatedAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "authorizingProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetimeEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "datetimeEnd",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "enteringUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionMaxFields",
        "fields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetimeEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetimeEnd",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionMinFields",
        "fields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetimeEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetimeEnd",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusActionObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientStatusActionInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusActionOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusActionOrderBy",
        "inputFields": [
          {
            "name": "associatedAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "authorizingProvider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetimeEffective",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetimeEnd",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusOrderBy",
              "ofType": null
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusActionPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusActionSetInput",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetimeEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "datetimeEnd",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionStddevFields",
        "fields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionStddev_popFields",
        "fields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionStddev_sampFields",
        "fields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionSumFields",
        "fields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusActionUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientStatusActionBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionVar_popFields",
        "fields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionVar_sampFields",
        "fields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusActionVarianceFields",
        "fields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusAction_avg_order_by",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusAction_max_order_by",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetimeEffective",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetimeEnd",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusAction_min_order_by",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetimeEffective",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetimeEnd",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusAction_stddev_order_by",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusAction_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusAction_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusAction_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientStatusAction_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusAction_streamCursorValueInput",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetimeEffective",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "datetimeEnd",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "datetimeEntered",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusAction_sum_order_by",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusAction_var_pop_order_by",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusAction_var_samp_order_by",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusAction_variance_order_by",
        "inputFields": [
          {
            "name": "associatedAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "authorizingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatus",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusAvgFields",
        "fields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patientStatusActions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "requiresProviderAuthorization",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "statusOnExpires",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusBoolExp",
              "ofType": null
            }
          },
          {
            "name": "underCare",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusIncInput",
        "inputFields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusInsertInput",
        "inputFields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientStatusActions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "requiresProviderAuthorization",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "statusOnExpires",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "underCare",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusMaxFields",
        "fields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusMinFields",
        "fields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatus",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientStatusInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusOrderBy",
        "inputFields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientStatusActionsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "requiresProviderAuthorization",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusOnExpires",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusOrderBy",
              "ofType": null
            }
          },
          {
            "name": "underCare",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusSetInput",
        "inputFields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "requiresProviderAuthorization",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "underCare",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusStddevFields",
        "fields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusStddev_popFields",
        "fields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusStddev_sampFields",
        "fields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatuses",
        "fields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "fromStatus",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatus",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "toStatus",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatus",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusSubsequentStatuses",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatusesVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesAvgFields",
        "fields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusSubsequentStatusesBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusSubsequentStatusesBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "fromStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "toStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusSubsequentStatusesIncInput",
        "inputFields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusSubsequentStatusesInsertInput",
        "inputFields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "fromStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "toStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusObjRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesMaxFields",
        "fields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesMinFields",
        "fields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusSubsequentStatuses",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusSubsequentStatusesOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusSubsequentStatusesBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusSubsequentStatusesOrderBy",
        "inputFields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fromStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "toStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusSubsequentStatusesPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusSubsequentStatusesSetInput",
        "inputFields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesStddevFields",
        "fields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesStddev_popFields",
        "fields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesStddev_sampFields",
        "fields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesSumFields",
        "fields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusSubsequentStatusesUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusSubsequentStatusesIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusSubsequentStatusesSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientStatusSubsequentStatusesBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesVar_popFields",
        "fields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesVar_sampFields",
        "fields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSubsequentStatusesVarianceFields",
        "fields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusSubsequentStatuses_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientStatusSubsequentStatuses_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusSubsequentStatuses_streamCursorValueInput",
        "inputFields": [
          {
            "name": "fromPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "toPatientstatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusSumFields",
        "fields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatusUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientStatusBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusVar_popFields",
        "fields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusVar_sampFields",
        "fields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStatusVarianceFields",
        "fields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatus_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientStatus_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientStatus_streamCursorValueInput",
        "inputFields": [
          {
            "name": "defaultDaysInEffect",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "onExpiresId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "requiresProviderAuthorization",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "underCare",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientStddevFields",
        "fields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStddev_popFields",
        "fields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientStddev_sampFields",
        "fields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientSumFields",
        "fields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patientUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patientBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "patientVar_popFields",
        "fields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientVar_sampFields",
        "fields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "patientVarianceFields",
        "fields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patient_avg_order_by",
        "inputFields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patient_max_order_by",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "emergencyContactName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "emergencyContactPhone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followupMax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followupMin",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "middleName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "nickName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "primaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "secondaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patient_min_order_by",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "emergencyContactName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "emergencyContactPhone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followupMax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "followupMin",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "middleName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "nickName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "primaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "secondaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patient_stddev_order_by",
        "inputFields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patient_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patient_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patient_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "patient_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patient_streamCursorValueInput",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "dateOfBirth",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "emergencyContactName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "emergencyContactPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalJson",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "followupMax",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "followupMin",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "middleName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "nickName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "primaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "secondaryPhone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patient_sum_order_by",
        "inputFields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patient_var_pop_order_by",
        "inputFields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patient_var_samp_order_by",
        "inputFields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "patient_variance_order_by",
        "inputFields": [
          {
            "name": "admittedStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "gender",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "homeAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "latitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "longitude",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "payer",
        "fields": [
          {
            "name": "agencies",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyPayers",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyPayersOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agenciesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyPayersAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyPayersOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "insurancePlans",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "insuranceplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "insuranceplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insurancePlansAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "insuranceplanAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "insuranceplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderableSuppliers",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplierPayerConnections",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierPayerConnectionsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableSuppliersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableSupplierPayerConnectionsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierPayerConnectionsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "payerAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "payerAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "payer",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "payerAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "payerAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "payerMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "payerMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "payerStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "payerStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "payerStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "payerSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "payerVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "payerVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "payerVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "payerAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "payerBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "agencies",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayersBoolExp",
              "ofType": null
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "drchronoName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "insurancePlans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanBoolExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderableSuppliers",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnectionsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "payerIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "payerInsertInput",
        "inputFields": [
          {
            "name": "agencies",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayersArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "drchronoName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "insurancePlans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderableSuppliers",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnectionsArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "payerMaxFields",
        "fields": [
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "payerMinFields",
        "fields": [
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "drchronoName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "payerMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "payer",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "payerObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "payerInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "payerOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "payerOrderBy",
        "inputFields": [
          {
            "name": "agenciesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyPayersAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "drchronoName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "insurancePlansAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "insuranceplanAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderableSuppliersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderableSupplierPayerConnectionsAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "payerPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "payerSetInput",
        "inputFields": [
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "drchronoName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "payerStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "payerStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "payerStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "payerSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "payerUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "payerSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "payerBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "payerVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "payerVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "payerVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "payer_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "payer_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "payer_streamCursorValueInput",
        "inputFields": [
          {
            "name": "drchronoId",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "drchronoName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "practicegroup",
        "fields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "assessments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "clinicalNoteTemplate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalNoteTemplateAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplateAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "consents",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "consent",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "consentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "consentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "consentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "consentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "defaultLabAgency",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "defaultRadiologyAgency",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentsPendingReview",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "documentPendingReview",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReviewOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentsPendingReviewAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentPendingReviewAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReviewOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "imageUrl",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "integrations",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "integration",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "integrationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "integrationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "integrationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "integrationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "internalStaff",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "internalstaff",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "internalstaffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "internalStaffAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "internalstaffAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "internalstaffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "labAppend",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicalDirector",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientIntakes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntake",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientIntakesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntakeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patients",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patient",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radiologyAppend",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "recordsRequestDestinations",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "recordsRequestDestination",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "recordsRequestDestinationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "recordsRequestDestinationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "recordsRequestDestinationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "recordsRequestDestinationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadCategories",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategory",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadCategoriesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadCategoryAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketThreads",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketThread",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketThreadsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketThreadAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "practicegroup",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroupVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroupAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroup_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroup_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroup_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroup_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroup_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroup_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroup_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroup_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroup_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroup_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroupArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "practicegroupInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupAvgFields",
        "fields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroupBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "address",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "assessments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateBoolExp",
              "ofType": null
            }
          },
          {
            "name": "consents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "defaultLabAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "documentsPendingReview",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewBoolExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "imageUrl",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "integrations",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "internalStaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffBoolExp",
              "ofType": null
            }
          },
          {
            "name": "labAppend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "medicalDirector",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patientIntakes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patients",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "radiologyAppend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "recordsRequestDestinations",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestinationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "threadCategories",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ticketThreads",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroupIncInput",
        "inputFields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroupInsertInput",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "assessments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "consents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "defaultLabAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "documentsPendingReview",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "imageUrl",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "integrations",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "internalStaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "labAppend",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "medicalDirector",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientIntakes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patients",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "radiologyAppend",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "recordsRequestDestinations",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestinationArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "threadCategories",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ticketThreads",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupMaxFields",
        "fields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "imageUrl",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "labAppend",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radiologyAppend",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupMinFields",
        "fields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "imageUrl",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "labAppend",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "radiologyAppend",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "practicegroup",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroupObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "practicegroupInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroupOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroupOrderBy",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "assessmentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalNoteTemplateAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "consentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "defaultLabAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentsPendingReviewAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "imageUrl",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "integrationsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "integrationAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "internalStaffAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "labAppend",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicalDirector",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntakesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radiologyAppend",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "recordsRequestDestinationsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestinationAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadCategoriesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ticketThreadsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroupPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroupSetInput",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "imageUrl",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "labAppend",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "radiologyAppend",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupStddevFields",
        "fields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupStddev_popFields",
        "fields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupStddev_sampFields",
        "fields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupSumFields",
        "fields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroupUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "practicegroupBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupVar_popFields",
        "fields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupVar_sampFields",
        "fields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "practicegroupVarianceFields",
        "fields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroup_avg_order_by",
        "inputFields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroup_max_order_by",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "imageUrl",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "labAppend",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radiologyAppend",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroup_min_order_by",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "imageUrl",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "labAppend",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "radiologyAppend",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroup_stddev_order_by",
        "inputFields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroup_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroup_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroup_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "practicegroup_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroup_streamCursorValueInput",
        "inputFields": [
          {
            "name": "address",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "city",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "imageUrl",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "labAppend",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "phone",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "radiologyAppend",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "state",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "zipCode",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroup_sum_order_by",
        "inputFields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroup_var_pop_order_by",
        "inputFields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroup_var_samp_order_by",
        "inputFields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "practicegroup_variance_order_by",
        "inputFields": [
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultPlaceofservice",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "medicalDirectorId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "problem",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretimeProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeProblemsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "dateOnset",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "date",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "dateResolved",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "icd10",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "plans",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problemPlan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemPlanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "plansAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "problemPlanAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemPlanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentPlans",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentPlansAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanProblemsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "problemAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problem",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "problemAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "problemMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "problemMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "problemStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "problemStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "problemStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "problemSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "problemVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "problemVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "problemVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problem_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problem_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problem_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problem_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problem_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problem_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problem_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problem_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problem_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problem_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "problemInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "problemAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblemsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "dateOnset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateResolved",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "icd10",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "plans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlanBoolExp",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblemsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblemsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "dateOnset",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "dateResolved",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "icd10",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "plans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlanArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblemsArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "problemMaxFields",
        "fields": [
          {
            "name": "dateOnset",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateResolved",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "icd10",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemMinFields",
        "fields": [
          {
            "name": "dateOnset",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateResolved",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "icd10",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problem",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "problemInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "caretimesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeProblemsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "dateOnset",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateResolved",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "icd10",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "plansAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlanAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlansAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblemsAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "problemPlan",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnote",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "plan",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "problem",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "problem",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problemPlan",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlanVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlanAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlan_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlan_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlan_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlan_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlan_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlan_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlan_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlan_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlan_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlan_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlanArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "problemPlanInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlanOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanAvgFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlanBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlanBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "plan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "problem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemBoolExp",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlanIncInput",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlanInsertInput",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "plan",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "problem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanMaxFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "plan",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanMinFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "plan",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problemPlan",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlanOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlanBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlanOrderBy",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "plan",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemOrderBy",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlanPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlanSetInput",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "plan",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanStddevFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanStddev_popFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanStddev_sampFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanSumFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlanUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlanIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemPlanSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "problemPlanBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanVar_popFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanVar_sampFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemPlanVarianceFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlan_avg_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlan_max_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "plan",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlan_min_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "plan",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlan_stddev_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlan_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlan_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlan_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "problemPlan_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlan_streamCursorValueInput",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "plan",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlan_sum_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlan_var_pop_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlan_var_samp_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemPlan_variance_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "dateOnset",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "dateResolved",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "icd10",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "problemStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problemUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "problemBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "problemVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "problemVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problem_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problem_max_order_by",
        "inputFields": [
          {
            "name": "dateOnset",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateResolved",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "icd10",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problem_min_order_by",
        "inputFields": [
          {
            "name": "dateOnset",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateResolved",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "icd10",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problem_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problem_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problem_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problem_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "problem_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problem_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "dateOnset",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "dateResolved",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "icd10",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problem_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problem_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problem_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "problem_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedure",
        "fields": [
          {
            "name": "clinicalnoteTemplates",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplatesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplateAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codeset",
            "type": {
              "kind": "OBJECT",
              "name": "codeset",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureHCPCS",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureHCPCSOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "hcpcsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureHCPCSAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureHCPCSOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "renderedProcedures",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureRendered",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRenderedOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "renderedProceduresAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureRenderedAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRenderedOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "procedureAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedure",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "procedureAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "procedureMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "procedureMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "procedureStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "procedureStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "procedureStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "procedureSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "procedureVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "procedureVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "procedureVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureAvgFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "clinicalnoteTemplates",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateBoolExp",
              "ofType": null
            }
          },
          {
            "name": "codeset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBoolExp",
              "ofType": null
            }
          },
          {
            "name": "codesetId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCSBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "renderedProcedures",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedBoolExp",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCS",
        "fields": [
          {
            "name": "hcpcs",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "hcpcs",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "procedure",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedure",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureHCPCS",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCSVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCSAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCS_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCS_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCS_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCS_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCS_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCS_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCS_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCS_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCS_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCS_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCSArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureHCPCSInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCSOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSAvgFields",
        "fields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCSBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCSBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "procedure",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureBoolExp",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCSIncInput",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCSInsertInput",
        "inputFields": [
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "procedure",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSMaxFields",
        "fields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSMinFields",
        "fields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureHCPCS",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCSOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCSBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCSOrderBy",
        "inputFields": [
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "hcpcsOrderBy",
              "ofType": null
            }
          },
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedure",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureOrderBy",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCSPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCSSetInput",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSStddevFields",
        "fields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSStddev_popFields",
        "fields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSStddev_sampFields",
        "fields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSSumFields",
        "fields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCSUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCSIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCSSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "procedureHCPCSBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSVar_popFields",
        "fields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSVar_sampFields",
        "fields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureHCPCSVarianceFields",
        "fields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCS_avg_order_by",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCS_max_order_by",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCS_min_order_by",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCS_stddev_order_by",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCS_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCS_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCS_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "procedureHCPCS_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCS_streamCursorValueInput",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCS_sum_order_by",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCS_var_pop_order_by",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCS_var_samp_order_by",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureHCPCS_variance_order_by",
        "inputFields": [
          {
            "name": "hcpcsId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureIncInput",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureInsertInput",
        "inputFields": [
          {
            "name": "clinicalnoteTemplates",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "codeset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCSArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "renderedProcedures",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureMaxFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureMinFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedure",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "procedureInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureOrderBy",
        "inputFields": [
          {
            "name": "clinicalnoteTemplatesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "codeset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetOrderBy",
              "ofType": null
            }
          },
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "hcpcsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureHCPCSAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedProceduresAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedurePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureRendered",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "appointment",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "performingUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "user",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "procedure",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedure",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentPlan",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplan",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureRendered",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRenderedVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRenderedAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRendered_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRendered_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRendered_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRendered_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRendered_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRendered_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRendered_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRendered_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRendered_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRendered_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRenderedArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "procedureRenderedInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedAvgFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRenderedBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "performingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "procedure",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureBoolExp",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanBoolExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRenderedIncInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRenderedInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "performingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "procedure",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedMaxFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedMinFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureRendered",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRenderedOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRenderedOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "performingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedure",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureOrderBy",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanOrderBy",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRenderedPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRenderedSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedStddevFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedStddev_popFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedStddev_sampFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedSumFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRenderedUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "procedureRenderedBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedVar_popFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedVar_sampFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureRenderedVarianceFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRendered_avg_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRendered_max_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRendered_min_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRendered_stddev_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRendered_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRendered_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRendered_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "procedureRendered_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRendered_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRendered_sum_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRendered_var_pop_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRendered_var_samp_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureRendered_variance_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "performingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "procedureId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureSetInput",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureStddevFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureStddev_popFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureStddev_sampFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureSumFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedureUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "procedureBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "procedureVar_popFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureVar_sampFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "procedureVarianceFields",
        "fields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedure_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "procedure_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "procedure_streamCursorValueInput",
        "inputFields": [
          {
            "name": "codesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "provider",
        "fields": [
          {
            "name": "appointments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "appointment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "appointmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "appointmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "claims",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "claim",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "claimOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "claimsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "claimAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "claimOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "classification",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassification",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "conductsVirtualVisit",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "defaultLabAgency",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyAgency",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "internalstaff",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "internalstaff",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "nPI",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orderActionsResponsibleFor",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderaction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderactionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderActionsResponsibleForAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderactionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderactionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersApproved",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersApprovedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersApprovedAsSupervisor",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersApprovedAsSupervisorAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "provider",
            "type": {
              "kind": "OBJECT",
              "name": "provider",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerSchedules",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "providerSchedule",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerScheduleOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerSchedulesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerScheduleAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerScheduleOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "scheduling",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisingProviders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "provider",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "supervisingProvidersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "providerAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "provider",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "providerAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "providerMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "providerMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "providerStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "providerStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "providerStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "providerSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "providerVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "providerVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "providerVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "provider_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "provider_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "provider_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "provider_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "provider_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "provider_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "provider_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "provider_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "provider_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "provider_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerAvgFields",
        "fields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "appointments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "claims",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimBoolExp",
              "ofType": null
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerClassificationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "classificationId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "conductsVirtualVisit",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "defaultLabAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyBoolExp",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "internalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffBoolExp",
              "ofType": null
            }
          },
          {
            "name": "nPI",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orderActionsResponsibleFor",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ordersApproved",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ordersApprovedAsSupervisor",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "provider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "providerSchedules",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerScheduleBoolExp",
              "ofType": null
            }
          },
          {
            "name": "scheduling",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "supervisingProviders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerClassification",
        "fields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "numeric",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "billsEm",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "clinicalNoteTemplates",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplateProviderClassifications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateProviderClassificationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalNoteTemplatesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplateProviderClassificationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateProviderClassificationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "primaryCodeset",
            "type": {
              "kind": "OBJECT",
              "name": "codeset",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providers",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "provider",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "providerClassification",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassificationVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationAvgFields",
        "fields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerClassificationBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerClassificationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "billsEm",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteTemplates",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "primaryCodeset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetBoolExp",
              "ofType": null
            }
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "providers",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerClassificationIncInput",
        "inputFields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerClassificationInsertInput",
        "inputFields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "billsEm",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteTemplates",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "primaryCodeset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providers",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationMaxFields",
        "fields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationMinFields",
        "fields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "providerClassification",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerClassificationObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "providerClassificationInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerClassificationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerClassificationOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerClassificationBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerClassificationOrderBy",
        "inputFields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "billsEm",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalNoteTemplatesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteTemplateProviderClassificationsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "primaryCodeset",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "codesetOrderBy",
              "ofType": null
            }
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerClassificationPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerClassificationSetInput",
        "inputFields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "billsEm",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationStddevFields",
        "fields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationStddev_popFields",
        "fields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationStddev_sampFields",
        "fields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationSumFields",
        "fields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerClassificationUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerClassificationIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerClassificationSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "providerClassificationBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationVar_popFields",
        "fields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationVar_sampFields",
        "fields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerClassificationVarianceFields",
        "fields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerClassification_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "providerClassification_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerClassification_streamCursorValueInput",
        "inputFields": [
          {
            "name": "billingAdjustment",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "billsEm",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "primaryCodesetId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerIncInput",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerInsertInput",
        "inputFields": [
          {
            "name": "appointments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "claims",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerClassificationObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "conductsVirtualVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "defaultLabAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "internalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "nPI",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orderActionsResponsibleFor",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ordersApproved",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ordersApprovedAsSupervisor",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "provider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "providerSchedules",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerScheduleArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "scheduling",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "supervisingProviders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerMaxFields",
        "fields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nPI",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerMinFields",
        "fields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nPI",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "provider",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "providerInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerOrderBy",
        "inputFields": [
          {
            "name": "appointmentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "claimsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "claimAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "classification",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerClassificationOrderBy",
              "ofType": null
            }
          },
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "conductsVirtualVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyAgency",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyOrderBy",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffOrderBy",
              "ofType": null
            }
          },
          {
            "name": "nPI",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "orderActionsResponsibleForAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ordersApprovedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ordersApprovedAsSupervisorAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "provider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "providerSchedulesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerScheduleAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "scheduling",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisingProvidersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerPkColumnsInput",
        "inputFields": [
          {
            "name": "internalStaffId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerSchedule",
        "fields": [
          {
            "name": "endAddress",
            "type": {
              "kind": "OBJECT",
              "name": "address",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endTime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "time",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "provider",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "provider",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "repetitionSchedule",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startAddress",
            "type": {
              "kind": "OBJECT",
              "name": "address",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "date",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "startTime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "time",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "providerSchedule",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "providerScheduleVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerScheduleAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerSchedule_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerSchedule_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerSchedule_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerSchedule_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerSchedule_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerSchedule_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerSchedule_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerSchedule_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerSchedule_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerSchedule_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerScheduleArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "providerScheduleInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerScheduleOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleAvgFields",
        "fields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerScheduleBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerScheduleBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "endAddress",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressBoolExp",
              "ofType": null
            }
          },
          {
            "name": "endAddressId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "endTime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimeComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "provider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerBoolExp",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "repetitionSchedule",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "startAddress",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressBoolExp",
              "ofType": null
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "startTime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimeComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerScheduleIncInput",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerScheduleInsertInput",
        "inputFields": [
          {
            "name": "endAddress",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "endTime",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "provider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "repetitionSchedule",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "startAddress",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "startTime",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleMaxFields",
        "fields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "repetitionSchedule",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleMinFields",
        "fields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "repetitionSchedule",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "providerSchedule",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerScheduleOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerScheduleBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerScheduleOrderBy",
        "inputFields": [
          {
            "name": "endAddress",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressOrderBy",
              "ofType": null
            }
          },
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "endTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "provider",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerOrderBy",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "repetitionSchedule",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startAddress",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "addressOrderBy",
              "ofType": null
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedulePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerScheduleSetInput",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "endTime",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "repetitionSchedule",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "startTime",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleStddevFields",
        "fields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleStddev_popFields",
        "fields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleStddev_sampFields",
        "fields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleSumFields",
        "fields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerScheduleUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerScheduleIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerScheduleSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "providerScheduleBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleVar_popFields",
        "fields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleVar_sampFields",
        "fields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerScheduleVarianceFields",
        "fields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedule_avg_order_by",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedule_max_order_by",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "repetitionSchedule",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedule_min_order_by",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "repetitionSchedule",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedule_stddev_order_by",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedule_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedule_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedule_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "providerSchedule_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedule_streamCursorValueInput",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "endDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "endTime",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "repetitionSchedule",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "startDate",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "startTime",
            "type": {
              "kind": "SCALAR",
              "name": "time",
              "ofType": null
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedule_sum_order_by",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedule_var_pop_order_by",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedule_var_samp_order_by",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSchedule_variance_order_by",
        "inputFields": [
          {
            "name": "endAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "startAddressId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitEquivalents",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerSetInput",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "conductsVirtualVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "nPI",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "scheduling",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerStddevFields",
        "fields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerStddev_popFields",
        "fields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerStddev_sampFields",
        "fields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerSumFields",
        "fields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "providerUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "providerSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "providerBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "providerVar_popFields",
        "fields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerVar_sampFields",
        "fields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "providerVarianceFields",
        "fields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "provider_avg_order_by",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "provider_max_order_by",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "nPI",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "provider_min_order_by",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "nPI",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "provider_stddev_order_by",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "provider_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "provider_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "provider_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "provider_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "provider_streamCursorValueInput",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "conductsVirtualVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "nPI",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "scheduling",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "provider_sum_order_by",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "provider_var_pop_order_by",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "provider_var_samp_order_by",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "provider_variance_order_by",
        "inputFields": [
          {
            "name": "classificationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultLabId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "defaultRadiologyId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalStaffId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "supervisingProviderId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "query_root",
        "fields": [
          {
            "name": "address",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "address",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "addressOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "addressAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "addressAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "addressOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "addressByPk",
            "type": {
              "kind": "OBJECT",
              "name": "address",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "agency",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agency",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "agencyLocation",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyLocation",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyLocationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyLocationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyLocationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyLocationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyLocationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocation",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "agencyPayers",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyPayers",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyPayersOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyPayersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyPayersAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyPayersOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyPayersByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayers",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "agencyServices",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyServices",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyServicesOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyServicesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyServicesAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyServicesOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyServicesByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServices",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "agencyconnection",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyconnection",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyconnectionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyconnectionAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyconnectionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyconnectionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyconnectionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnection",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "agencyjoinrequest",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyjoinrequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyjoinrequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyjoinrequestAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyjoinrequestAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyjoinrequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyjoinrequestByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequest",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "allergy",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "allergy",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "allergyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "allergyAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "allergyAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "allergyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "allergyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "allergy",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "appointment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "appointment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "appointmentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "appointmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "appointmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "assessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "assessment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "assessmentItem",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentItemAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentItemAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentItemByPk",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItem",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "assessmentItemValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItemValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentItemValueAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentItemValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentItemValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValue",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "baseProtocolOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseProtocolOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseProtocolOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseProtocolOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseProtocolOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseProtocolOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseProtocolOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "dependantorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "baseorder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseorder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseorderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseorderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "baseorder",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "caretime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretime",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimeAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "caretime",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "caretimeProblems",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretimeProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimeProblemsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeProblemsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimeProblemsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblems",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "claim",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "claim",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "claimOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "claimAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "claimAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "claimOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "claimByPk",
            "type": {
              "kind": "OBJECT",
              "name": "claim",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "clinicalNoteFutureReferrals",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalNoteFutureReferrals",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalNoteFutureReferralsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalNoteFutureReferralsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalNoteFutureReferralsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalNoteFutureReferralsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalNoteFutureReferralsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferrals",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnote",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnote",
              "ofType": null
            },
            "args": [
              {
                "name": "appointmentId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "clinicalnoteField",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteField",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteFieldAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteField",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldValueAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteFieldValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplateAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplateAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplateByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplate",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplateProviderClassifications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplateProviderClassifications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateProviderClassificationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplateProviderClassificationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplateProviderClassificationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateProviderClassificationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplateProviderClassificationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "codeset",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codeset",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetAddon",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codesetAddon",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetAddonOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetAddonAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "codesetAddonAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetAddonOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetAddonByPk",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddon",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "codesetAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "codesetAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetBase",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codesetBase",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetBaseOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetBaseAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "codesetBaseAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetBaseOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetBaseByPk",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBase",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "codesetByPk",
            "type": {
              "kind": "OBJECT",
              "name": "codeset",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "consent",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "consent",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "consentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "consentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "consentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "consentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "consentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "consent",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "cpoClaim",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "cpoClaim",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "cpoClaimOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "cpoClaimAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "cpoClaimAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "cpoClaimOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "cpoClaimByPk",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaim",
              "ofType": null
            },
            "args": [
              {
                "name": "claimPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "dependantOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "dependantOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "dependantOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "dependantOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "dependantOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "dependantOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "dependantOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "baseorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "diagnosisAbbreviation",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "diagnosisAbbreviation",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "diagnosisAbbreviationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "diagnosisAbbreviationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "diagnosisAbbreviationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "diagnosisAbbreviationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "diagnosisAbbreviationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviation",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "document",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "document",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "document",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "documentPendingReview",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "documentPendingReview",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReviewOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentPendingReviewAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentPendingReviewAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReviewOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentPendingReviewByPk",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReview",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "externalSignedOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalSignedOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalSignedOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalSignedOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalSignedOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalSignedOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalSignedOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "externalStaffMyPatients",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalStaffMyPatients",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalStaffMyPatientsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalStaffMyPatientsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalStaffMyPatientsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalStaffMyPatientsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalStaffMyPatientsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatients",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "externalstaff",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalstaff",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalstaffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalstaffAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalstaffAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalstaffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalstaffByPk",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaff",
              "ofType": null
            },
            "args": [
              {
                "name": "userId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "fax",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "fax",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "faxAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "faxAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "faxByPk",
            "type": {
              "kind": "OBJECT",
              "name": "fax",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "faxDocuments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "faxDocuments",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxDocumentsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "faxDocumentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "faxDocumentsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxDocumentsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "faxDocumentsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocuments",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "freeTextOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "freeTextOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "freeTextOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "freeTextOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "freeTextOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "freeTextOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "freeTextOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "baseorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "frequencyOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "frequencyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "frequencyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "frequencyOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "frequencyOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "frequencyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "frequencyOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "dependantorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "frequencyProtocol",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "frequencyProtocol",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "frequencyProtocolOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "frequencyProtocolAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "frequencyProtocolAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "frequencyProtocolOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "frequencyProtocolByPk",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocol",
              "ofType": null
            },
            "args": [
              {
                "name": "baseprotocolorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "hcpcs",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "hcpcsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "hcpcsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "hcpcsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "hcpcsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "hcpcsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcs",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "insuranceplan",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "insuranceplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "insuranceplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insuranceplanAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "insuranceplanAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "insuranceplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insuranceplanByPk",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplan",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "integration",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "integration",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "integrationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "integrationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "integrationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "integrationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "integrationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "integration",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "internalstaff",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "internalstaff",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "internalstaffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "internalstaffAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "internalstaffAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "internalstaffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "internalstaffByPk",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaff",
              "ofType": null
            },
            "args": [
              {
                "name": "userId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "legacyOrderFrequency",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderFrequency",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderFrequencyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderFrequencyAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderFrequencyAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderFrequencyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderFrequencyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequency",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "legacyOrderMedications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderMedications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderMedicationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderMedicationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderMedicationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderMedicationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderMedicationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedications",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "legacyOrderOrderableFieldValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderOrderableFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderableFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderOrderableFieldValueAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderOrderableFieldValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderableFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderOrderableFieldValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "legacyOrderQualification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderQualificationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderQualificationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderQualificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "legacyTreatmentplanOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyTreatmentplanOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyTreatmentplanOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyTreatmentplanOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyTreatmentplanOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyTreatmentplanOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyTreatmentplanOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "lineitem",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "lineitem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "lineitemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "lineitemAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "lineitemAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "lineitemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "lineitemByPk",
            "type": {
              "kind": "OBJECT",
              "name": "lineitem",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "medication",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medication",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "medication",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "medicationOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medicationOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "baseorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "medicationorderMedications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medicationorderMedications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationorderMedicationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationorderMedicationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationorderMedicationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationorderMedicationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationorderMedicationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedications",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "message",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "message",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "messageOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "messageAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "messageAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "messageOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "messageByPk",
            "type": {
              "kind": "OBJECT",
              "name": "message",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderOrderableField",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderOrderableField",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderableFieldAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderOrderableFieldAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderableFieldByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableField",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderOrderableLegacy",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderOrderableLegacy",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableLegacyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderableLegacyAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderOrderableLegacyAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableLegacyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderableLegacyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacy",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderable",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderable",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderable",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableCategory",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableCategory",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableCategoryOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableCategoryAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableCategoryAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableCategoryOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableCategoryByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategory",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "baseorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableOrderQualification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableOrderQualificationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableOrderQualificationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableOrderQualificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableQualification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableQualificationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableQualificationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableQualificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableSupplier",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplier",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableSupplierAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableSupplierAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableSupplierByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplier",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableSupplierPayerConnections",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplierPayerConnections",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierPayerConnectionsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableSupplierPayerConnectionsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableSupplierPayerConnectionsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierPayerConnectionsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableSupplierPayerConnectionsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnections",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderaction",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderaction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderactionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderactionAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderactionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderactionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderactionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderaction",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patient",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientAlias",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientAlias",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientAliasOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientAliasAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientAliasAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientAliasOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientAliasByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientAlias",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientCommunication",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientCommunication",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientCommunicationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientCommunicationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientCommunicationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientCommunicationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientCommunicationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunication",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientContactPerson",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientContactPerson",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientContactPersonOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientContactPersonAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientContactPersonAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientContactPersonOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientContactPersonByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPerson",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientEligibilityCheck",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientEligibilityCheck",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientEligibilityCheckOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientEligibilityCheckAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientEligibilityCheckAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientEligibilityCheckOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientEligibilityCheckByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheck",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientIntake",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntake",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientIntakeAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntakeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientIntakeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntake",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientIntakeRecordsLocations",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntakeRecordsLocations",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeRecordsLocationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientIntakeRecordsLocationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntakeRecordsLocationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeRecordsLocationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientIntakeRecordsLocationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocations",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientPhone",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientPhone",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhoneOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientPhoneAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientPhoneAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhoneOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientPhoneByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhone",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientPhoto",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientPhoto",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhotoOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientPhotoAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientPhotoAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhotoOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientPhotoByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoto",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientStatus",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatus",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusAction",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusActionAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatusActionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusActionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusAction",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientStatusAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatusAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatus",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientStatusSubsequentStatuses",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusSubsequentStatuses",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusSubsequentStatusesOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusSubsequentStatusesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatusSubsequentStatusesAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusSubsequentStatusesOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusSubsequentStatusesByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatuses",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "payer",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "payer",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "payerOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "payerAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "payerAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "payerOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "payerByPk",
            "type": {
              "kind": "OBJECT",
              "name": "payer",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "practicegroup",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "practicegroupOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "practicegroupAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "practicegroupAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "practicegroupOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "practicegroupByPk",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroup",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "problem",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problemAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "problemAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problemByPk",
            "type": {
              "kind": "OBJECT",
              "name": "problem",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "problemPlan",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problemPlan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemPlanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problemPlanAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "problemPlanAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemPlanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problemPlanByPk",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlan",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "procedure",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedure",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureByPk",
            "type": {
              "kind": "OBJECT",
              "name": "procedure",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "procedureHCPCS",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureHCPCS",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureHCPCSOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureHCPCSAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureHCPCSAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureHCPCSOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureHCPCSByPk",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCS",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "procedureRendered",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureRendered",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRenderedOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureRenderedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureRenderedAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRenderedOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureRenderedByPk",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRendered",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "provider",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "provider",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerByPk",
            "type": {
              "kind": "OBJECT",
              "name": "provider",
              "ofType": null
            },
            "args": [
              {
                "name": "internalStaffId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "providerClassification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "providerClassification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerClassificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerClassificationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerClassificationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerClassificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerClassificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassification",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "providerSchedule",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "providerSchedule",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerScheduleOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerScheduleAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerScheduleAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerScheduleOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerScheduleByPk",
            "type": {
              "kind": "OBJECT",
              "name": "providerSchedule",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "recordsRequestDestination",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "recordsRequestDestination",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "recordsRequestDestinationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "recordsRequestDestinationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "recordsRequestDestinationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "recordsRequestDestinationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "recordsRequestDestinationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestination",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "referral",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referral",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referralAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "referralAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referralAttachments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referralAttachments",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralAttachmentsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referralAttachmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "referralAttachmentsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralAttachmentsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referralAttachmentsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachments",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "referralByPk",
            "type": {
              "kind": "OBJECT",
              "name": "referral",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "renderedAssessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "renderedAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "renderedAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "renderedAssessmentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "renderedAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "renderedAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "renderedAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "schedulingAction",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "schedulingAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "schedulingActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "schedulingActionAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "schedulingActionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "schedulingActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "schedulingActionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingAction",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "serviceType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "serviceType",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "serviceTypeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "serviceTypeAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "serviceTypeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "serviceTypeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "serviceTypeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "serviceType",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "signedConsent",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signedConsent",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signedConsentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signedConsentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "signedConsentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signedConsentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signedConsentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsent",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "signoff",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signoff",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoffAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "signoffAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoffByPk",
            "type": {
              "kind": "OBJECT",
              "name": "signoff",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "signoffType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signoffType",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffTypeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoffTypeAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "signoffTypeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffTypeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoffTypeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "signoffType",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestAccessCode",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequestAccessCode",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestAccessCodeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestAccessCodeAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "telemedAppointmentRequestAccessCodeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestAccessCodeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestAccessCodeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCode",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "telemedAppointmentRequestAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestByPk",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequest",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "threadCategory",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategory",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadCategoryAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadCategoryAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadCategoryByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategory",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "threadCategoryStatus",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategoryStatus",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryStatusOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadCategoryStatusAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadCategoryStatusAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryStatusOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadCategoryStatusByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatus",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "threadField",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadField",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadFieldAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadFieldAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadFieldByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadField",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "threadFieldValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadFieldValueAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadFieldValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadFieldValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "ticketSubscription",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketSubscription",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketSubscriptionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketSubscriptionAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketSubscriptionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketSubscriptionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketSubscriptionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscription",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketThread",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketThreadAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketThreadAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketThreadByPk",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThread",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanAssessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanAssessmentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "treatmentplanByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplan",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "treatmentplanNote",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanNote",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanNoteOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanNoteAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanNoteAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanNoteOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanNoteByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNote",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "treatmentplanProblems",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanProblemsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanProblemsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanProblemsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblems",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplateAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanTemplateAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplateAssessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanTemplateAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplateAssessmentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanTemplateAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplateAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplateByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplate",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "user",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "user",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "userAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userByPk",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "userGroups",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "userGroups",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userGroupsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userGroupsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "userGroupsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userGroupsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userGroupsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "userGroups",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "userPermissions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "userPermissions",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userPermissionsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userPermissionsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "userPermissionsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userPermissionsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userPermissionsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissions",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "visitRebill",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "visitRebill",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "visitRebillOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "visitRebillAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "visitRebillAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "visitRebillOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "visitRebillByPk",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebill",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "vitals",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "vitals",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "vitalsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "vitalsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "vitalsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "vitalsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "vitalsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "vitals",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestination",
        "fields": [
          {
            "name": "fax",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "intakesRequestingFromDestination",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntakeRecordsLocations",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeRecordsLocationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "intakesRequestingFromDestinationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntakeRecordsLocationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeRecordsLocationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "practicegroup",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "recordsRequestDestination",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestinationVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestinationAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestination_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestination_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestination_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestination_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestination_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestination_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestination_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestination_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestination_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestination_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestinationArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "recordsRequestDestinationInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestinationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestinationBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestinationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "fax",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "intakesRequestingFromDestination",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocationsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestinationIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestinationInsertInput",
        "inputFields": [
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "intakesRequestingFromDestination",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocationsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationMaxFields",
        "fields": [
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationMinFields",
        "fields": [
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "recordsRequestDestination",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestinationObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "recordsRequestDestinationInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestinationOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestinationOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestinationBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestinationOrderBy",
        "inputFields": [
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "intakesRequestingFromDestinationAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeRecordsLocationsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOrderBy",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestinationPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestinationSetInput",
        "inputFields": [
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestinationUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestinationIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "recordsRequestDestinationSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "recordsRequestDestinationBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "recordsRequestDestinationVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestination_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestination_max_order_by",
        "inputFields": [
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestination_min_order_by",
        "inputFields": [
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestination_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestination_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestination_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestination_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "recordsRequestDestination_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestination_streamCursorValueInput",
        "inputFields": [
          {
            "name": "fax",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestination_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestination_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestination_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "recordsRequestDestination_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "referral",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "attachments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referralAttachments",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralAttachmentsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "attachmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "referralAttachmentsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralAttachmentsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "datetime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "destination",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyLocation",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "faxes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "fax",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "faxesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "faxAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "futureReferralsFromClinicalNotes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalNoteFutureReferrals",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalNoteFutureReferralsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "futureReferralsFromClinicalNotesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalNoteFutureReferralsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalNoteFutureReferralsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "sendOngoing",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "referralAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referral",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "referralAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "referralMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "referralMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "referralStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "referralStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "referralStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "referralSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "referralVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "referralVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "referralVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referral_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referral_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referral_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referral_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referral_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referral_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referral_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referral_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referral_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referral_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "referralInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachments",
        "fields": [
          {
            "name": "document",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "document",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "referral",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "referral",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referralAttachments",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachmentsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachmentsAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachments_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachments_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachments_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachments_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachments_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachments_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachments_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachments_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachments_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachments_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachmentsArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "referralAttachmentsInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachmentsOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsAvgFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachmentsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachmentsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "referral",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralBoolExp",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachmentsIncInput",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachmentsInsertInput",
        "inputFields": [
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referral",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsMaxFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsMinFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referralAttachments",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachmentsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachmentsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachmentsOrderBy",
        "inputFields": [
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referral",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralOrderBy",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachmentsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachmentsSetInput",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsStddevFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsStddev_popFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsStddev_sampFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsSumFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachmentsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachmentsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachmentsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "referralAttachmentsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsVar_popFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsVar_sampFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralAttachmentsVarianceFields",
        "fields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachments_avg_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachments_max_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachments_min_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachments_stddev_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachments_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachments_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachments_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "referralAttachments_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachments_streamCursorValueInput",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachments_sum_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachments_var_pop_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachments_var_samp_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralAttachments_variance_order_by",
        "inputFields": [
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "referralId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "referralAvgFields",
        "fields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "attachments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachmentsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "destination",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocationBoolExp",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "faxes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxBoolExp",
              "ofType": null
            }
          },
          {
            "name": "futureReferralsFromClinicalNotes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferralsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "sendOngoing",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralIncInput",
        "inputFields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "attachments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachmentsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "destination",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocationObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "faxes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "futureReferralsFromClinicalNotes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferralsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "sendOngoing",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "referralMaxFields",
        "fields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralMinFields",
        "fields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referral",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "referralInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "attachmentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralAttachmentsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destination",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyLocationOrderBy",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "faxesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "faxAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "futureReferralsFromClinicalNotesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalNoteFutureReferralsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "sendOngoing",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "sendOngoing",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "referralStddevFields",
        "fields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralStddev_popFields",
        "fields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralStddev_sampFields",
        "fields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralSumFields",
        "fields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referralUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "referralSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "referralBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "referralVar_popFields",
        "fields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralVar_sampFields",
        "fields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "referralVarianceFields",
        "fields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referral_avg_order_by",
        "inputFields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referral_max_order_by",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referral_min_order_by",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referral_stddev_order_by",
        "inputFields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referral_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referral_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referral_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "referral_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referral_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "sendOngoing",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referral_sum_order_by",
        "inputFields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referral_var_pop_order_by",
        "inputFields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referral_var_samp_order_by",
        "inputFields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "referral_variance_order_by",
        "inputFields": [
          {
            "name": "destinationId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessment",
        "fields": [
          {
            "name": "assessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessment",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "assessmentItemValues",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItemValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentItemValuesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentItemValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "datetime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "renderingUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "user",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentPlanAssessment",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessment",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "renderedAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessmentVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessmentAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessment_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessment_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessment_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessment_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessment_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessment_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessment_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessment_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessment_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessment_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessmentArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "renderedAssessmentInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentAvgFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessmentBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "assessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "assessmentItemValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValueBoolExp",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "renderingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanAssessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessmentIncInput",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessmentInsertInput",
        "inputFields": [
          {
            "name": "assessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "assessmentItemValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValueArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "renderingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanAssessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentMaxFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentMinFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "renderedAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessmentObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "renderedAssessmentInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessmentOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessmentOrderBy",
        "inputFields": [
          {
            "name": "assessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "assessmentItemValuesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentItemValueAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanAssessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessmentPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessmentSetInput",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentStddevFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentStddev_popFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentStddev_sampFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentSumFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessmentUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "renderedAssessmentBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentVar_popFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentVar_sampFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "renderedAssessmentVarianceFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessment_avg_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessment_max_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessment_min_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessment_stddev_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessment_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessment_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessment_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "renderedAssessment_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessment_streamCursorValueInput",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessment_sum_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessment_var_pop_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessment_var_samp_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "renderedAssessment_variance_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "schedulingAction",
        "fields": [
          {
            "name": "appointment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "appointment",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "reachable",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "refusedVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "voicemailLeft",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "schedulingAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingActionVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingActionAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingAction_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingAction_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingAction_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingAction_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingAction_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingAction_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingAction_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingAction_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingAction_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingAction_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingActionArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "schedulingActionInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingActionOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionAvgFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingActionBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingActionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "reachable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "refusedVisit",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "voicemailLeft",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingActionIncInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingActionInsertInput",
        "inputFields": [
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "reachable",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "refusedVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "voicemailLeft",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionMaxFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionMinFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "schedulingAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingActionOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingActionBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingActionOrderBy",
        "inputFields": [
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "reachable",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "refusedVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "voicemailLeft",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingActionPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingActionSetInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "reachable",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "refusedVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "voicemailLeft",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionStddevFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionStddev_popFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionStddev_sampFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionSumFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingActionUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingActionIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "schedulingActionSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "schedulingActionBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionVar_popFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionVar_sampFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "schedulingActionVarianceFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingAction_avg_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingAction_max_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingAction_min_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingAction_stddev_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingAction_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingAction_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingAction_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "schedulingAction_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingAction_streamCursorValueInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "reachable",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "refusedVisit",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "voicemailLeft",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingAction_sum_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingAction_var_pop_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingAction_var_samp_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "schedulingAction_variance_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusAction",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "serviceType",
        "fields": [
          {
            "name": "agencyServices",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyServices",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyServicesOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyServicesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyServicesAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyServicesOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "category",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "serviceType",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "serviceTypeVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeAvgFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "serviceTypeBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "serviceTypeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "agencyServices",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServicesBoolExp",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "serviceTypeIncInput",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "serviceTypeInsertInput",
        "inputFields": [
          {
            "name": "agencyServices",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServicesArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeMaxFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeMinFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "serviceType",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "serviceTypeObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "serviceTypeInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "serviceTypeOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "serviceTypeOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "serviceTypeBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "serviceTypeOrderBy",
        "inputFields": [
          {
            "name": "agencyServicesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "agencyServicesAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "serviceTypePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "serviceTypeSetInput",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeStddevFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeStddev_popFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeStddev_sampFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeSumFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "serviceTypeUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "serviceTypeIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "serviceTypeSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "serviceTypeBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeVar_popFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeVar_sampFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "serviceTypeVarianceFields",
        "fields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "serviceType_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "serviceType_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "serviceType_streamCursorValueInput",
        "inputFields": [
          {
            "name": "category",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signedConsent",
        "fields": [
          {
            "name": "consent",
            "type": {
              "kind": "OBJECT",
              "name": "consent",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "document",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "document",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "signatureImage",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signedConsent",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsentVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsentAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsent_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsent_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsent_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsent_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsent_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsent_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsent_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsent_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsent_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsent_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsentArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signedConsentInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentAvgFields",
        "fields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsentBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "consent",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "consentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "signatureImage",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsentIncInput",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsentInsertInput",
        "inputFields": [
          {
            "name": "consent",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "signatureImage",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentMaxFields",
        "fields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signatureImage",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentMinFields",
        "fields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signatureImage",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signedConsent",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsentOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsentBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsentOrderBy",
        "inputFields": [
          {
            "name": "consent",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "consentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "document",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signatureImage",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsentPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsentSetInput",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "signatureImage",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentStddevFields",
        "fields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentStddev_popFields",
        "fields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentStddev_sampFields",
        "fields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentSumFields",
        "fields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsentUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsentIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signedConsentSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "signedConsentBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentVar_popFields",
        "fields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentVar_sampFields",
        "fields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signedConsentVarianceFields",
        "fields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsent_avg_order_by",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsent_max_order_by",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signatureImage",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsent_min_order_by",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signatureImage",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsent_stddev_order_by",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsent_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsent_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsent_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "signedConsent_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsent_streamCursorValueInput",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "signatureImage",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsent_sum_order_by",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsent_var_pop_order_by",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsent_var_samp_order_by",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signedConsent_variance_order_by",
        "inputFields": [
          {
            "name": "consentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signoff",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnote",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "date",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "signoffType",
            "type": {
              "kind": "OBJECT",
              "name": "signoffType",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signoffUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "user",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "signoffAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signoff",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "signoffAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "signoffMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "signoffMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "signoffStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "signoffStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "signoffStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "signoffSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "signoffVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "signoffVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "signoffVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoff_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoff_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoff_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoff_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoff_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoff_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoff_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoff_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoff_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoff_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "signoffInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signoffAvgFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteBoolExp",
              "ofType": null
            }
          },
          {
            "name": "date",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "signoffType",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffTypeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "signoffUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffIncInput",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "date",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "signoffType",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffTypeObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "signoffUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signoffMaxFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "date",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffMinFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "date",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signoff",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "clinicalnoteOrderBy",
              "ofType": null
            }
          },
          {
            "name": "date",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signoffType",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffTypeOrderBy",
              "ofType": null
            }
          },
          {
            "name": "signoffUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "date",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signoffStddevFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffStddev_popFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffStddev_sampFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffSumFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffType",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "noteStatement",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "signoffs",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signoff",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoffsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "signoffAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signoffType",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "signoffTypeVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffTypeBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffTypeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "noteStatement",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "signoffs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffTypeIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffTypeInsertInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "noteStatement",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "signoffs",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "noteStatement",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "noteStatement",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signoffType",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffTypeObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "signoffTypeInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffTypeOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffTypeOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffTypeBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffTypeOrderBy",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "noteStatement",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "signoffsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffTypePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffTypeSetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "noteStatement",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffTypeUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffTypeIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffTypeSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "signoffTypeBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffTypeVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffType_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "signoffType_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffType_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "noteStatement",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoffUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "signoffSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "signoffBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "signoffVar_popFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffVar_sampFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "signoffVarianceFields",
        "fields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoff_avg_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoff_max_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "date",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoff_min_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "date",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoff_stddev_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoff_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoff_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoff_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "signoff_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoff_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "date",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoff_sum_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoff_var_pop_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoff_var_samp_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "signoff_variance_order_by",
        "inputFields": [
          {
            "name": "clinicalNoteId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "typeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "SCALAR",
        "name": "smallint"
      },
      {
        "kind": "OBJECT",
        "name": "subscription_root",
        "fields": [
          {
            "name": "address",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "address",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "addressOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "addressAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "addressAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "addressOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "addressByPk",
            "type": {
              "kind": "OBJECT",
              "name": "address",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "addressStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "address",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "address_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "addressBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agency",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agency",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agency",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "agencyLocation",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyLocation",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyLocationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyLocationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyLocationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyLocationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyLocationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyLocation",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "agencyLocationStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyLocation",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyLocation_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyLocationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyPayers",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyPayers",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyPayersOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyPayersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyPayersAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyPayersOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyPayersByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyPayers",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "agencyPayersStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyPayers",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyPayers_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyPayersBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyServices",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyServices",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyServicesOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyServicesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyServicesAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyServicesOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyServicesByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyServices",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "agencyServicesStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyServices",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyServices_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyServicesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agency",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agency_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyconnection",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyconnection",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyconnectionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyconnectionAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyconnectionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyconnectionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyconnectionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyconnection",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "agencyconnectionStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyconnection",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyconnection_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyconnectionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyjoinrequest",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyjoinrequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyjoinrequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyjoinrequestAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "agencyjoinrequestAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyjoinrequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "agencyjoinrequestByPk",
            "type": {
              "kind": "OBJECT",
              "name": "agencyjoinrequest",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "agencyjoinrequestStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "agencyjoinrequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "agencyjoinrequest_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "agencyjoinrequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "allergy",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "allergy",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "allergyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "allergyAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "allergyAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "allergyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "allergyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "allergy",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "allergyStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "allergy",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "allergy_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "allergyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "appointment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "appointment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "appointmentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "appointmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "appointmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "appointmentStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "appointment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "appointment_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "appointmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "assessment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "assessmentItem",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentItemAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentItemAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentItemByPk",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItem",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "assessmentItemStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItem_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentItemValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItemValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentItemValueAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessmentItemValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentItemValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "assessmentItemValue",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "assessmentItemValueStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessmentItemValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessmentItemValue_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentItemValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "assessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "assessment_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "assessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseProtocolOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseProtocolOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseProtocolOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseProtocolOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseProtocolOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseProtocolOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseProtocolOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "baseProtocolOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "dependantorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "baseProtocolOrderStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseProtocolOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseProtocolOrder_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseProtocolOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseorder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseorder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseorderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseorderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "baseorder",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "baseorderStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseorder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorder_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretime",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimeAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "caretime",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "caretimeProblems",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretimeProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimeProblemsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeProblemsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimeProblemsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "caretimeProblems",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "caretimeProblemsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretimeProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeProblems_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimeStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretime",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretime_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "claim",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "claim",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "claimOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "claimAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "claimAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "claimOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "claimByPk",
            "type": {
              "kind": "OBJECT",
              "name": "claim",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "claimStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "claim",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "claim_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "claimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalNoteFutureReferrals",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalNoteFutureReferrals",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalNoteFutureReferralsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalNoteFutureReferralsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalNoteFutureReferralsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalNoteFutureReferralsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalNoteFutureReferralsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalNoteFutureReferrals",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "clinicalNoteFutureReferralsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalNoteFutureReferrals",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalNoteFutureReferrals_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalNoteFutureReferralsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnote",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnote",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnote",
              "ofType": null
            },
            "args": [
              {
                "name": "appointmentId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "clinicalnoteField",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteField",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteFieldAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteField",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteField",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteField_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldValueAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteFieldValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "clinicalnoteFieldValueStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteFieldValue_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnote",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnote_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplateAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplateAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplateByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplate",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplateProviderClassifications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplateProviderClassifications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateProviderClassificationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplateProviderClassificationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "clinicalnoteTemplateProviderClassificationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateProviderClassificationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplateProviderClassificationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "clinicalnoteTemplateProviderClassifications",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplateProviderClassificationsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplateProviderClassifications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplateProviderClassifications_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateProviderClassificationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "clinicalnoteTemplateStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "clinicalnoteTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "clinicalnoteTemplate_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "clinicalnoteTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codeset",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codeset",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetAddon",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codesetAddon",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetAddonOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetAddonAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "codesetAddonAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetAddonOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetAddonByPk",
            "type": {
              "kind": "OBJECT",
              "name": "codesetAddon",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "codesetAddonStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codesetAddon",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetAddon_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetAddonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "codesetAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetBase",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codesetBase",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetBaseOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetBaseAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "codesetBaseAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetBaseOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetBaseByPk",
            "type": {
              "kind": "OBJECT",
              "name": "codesetBase",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "codesetBaseStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codesetBase",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codesetBase_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBaseBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "codesetByPk",
            "type": {
              "kind": "OBJECT",
              "name": "codeset",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "codesetStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "codeset",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "codeset_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "codesetBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "consent",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "consent",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "consentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "consentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "consentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "consentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "consentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "consent",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "consentStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "consent",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "consent_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "consentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "cpoClaim",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "cpoClaim",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "cpoClaimOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "cpoClaimAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "cpoClaimAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "cpoClaimOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "cpoClaimByPk",
            "type": {
              "kind": "OBJECT",
              "name": "cpoClaim",
              "ofType": null
            },
            "args": [
              {
                "name": "claimPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "cpoClaimStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "cpoClaim",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "cpoClaim_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "cpoClaimBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "dependantOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "dependantOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "dependantOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "dependantOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "dependantOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "dependantOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "dependantOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "dependantOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "baseorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "dependantOrderStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "dependantOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "dependantOrder_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "dependantOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "diagnosisAbbreviation",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "diagnosisAbbreviation",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "diagnosisAbbreviationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "diagnosisAbbreviationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "diagnosisAbbreviationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "diagnosisAbbreviationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "diagnosisAbbreviationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "diagnosisAbbreviation",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "diagnosisAbbreviationStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "diagnosisAbbreviation",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "diagnosisAbbreviation_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "diagnosisAbbreviationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "document",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "document",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "document",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "documentPendingReview",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "documentPendingReview",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReviewOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentPendingReviewAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentPendingReviewAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReviewOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentPendingReviewByPk",
            "type": {
              "kind": "OBJECT",
              "name": "documentPendingReview",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "documentPendingReviewStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "documentPendingReview",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReview_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "document",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "document_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalSignedOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalSignedOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalSignedOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalSignedOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalSignedOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalSignedOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalSignedOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "externalSignedOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "externalSignedOrderStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalSignedOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalSignedOrder_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalSignedOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalStaffMyPatients",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalStaffMyPatients",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalStaffMyPatientsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalStaffMyPatientsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalStaffMyPatientsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalStaffMyPatientsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalStaffMyPatientsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "externalStaffMyPatients",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "externalStaffMyPatientsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalStaffMyPatients",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalStaffMyPatients_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalStaffMyPatientsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalstaff",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalstaff",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalstaffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalstaffAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "externalstaffAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalstaffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "externalstaffByPk",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaff",
              "ofType": null
            },
            "args": [
              {
                "name": "userId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "externalstaffStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "externalstaff",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "externalstaff_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "externalstaffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "fax",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "fax",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "faxAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "faxAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "faxByPk",
            "type": {
              "kind": "OBJECT",
              "name": "fax",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "faxDocuments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "faxDocuments",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxDocumentsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "faxDocumentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "faxDocumentsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxDocumentsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "faxDocumentsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "faxDocuments",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "faxDocumentsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "faxDocuments",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "faxDocuments_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxDocumentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "faxStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "fax",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "fax_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "faxBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "freeTextOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "freeTextOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "freeTextOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "freeTextOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "freeTextOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "freeTextOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "freeTextOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "freeTextOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "baseorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "freeTextOrderStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "freeTextOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "freeTextOrder_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "freeTextOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "frequencyOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "frequencyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "frequencyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "frequencyOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "frequencyOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "frequencyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "frequencyOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "dependantorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "frequencyOrderStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "frequencyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "frequencyOrder_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "frequencyProtocol",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "frequencyProtocol",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "frequencyProtocolOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "frequencyProtocolAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "frequencyProtocolAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "frequencyProtocolOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "frequencyProtocolByPk",
            "type": {
              "kind": "OBJECT",
              "name": "frequencyProtocol",
              "ofType": null
            },
            "args": [
              {
                "name": "baseprotocolorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "frequencyProtocolStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "frequencyProtocol",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "frequencyProtocol_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "frequencyProtocolBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "hcpcs",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "hcpcs",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "hcpcsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "hcpcsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "hcpcsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "hcpcsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "hcpcsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "hcpcs",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "hcpcsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "hcpcs",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "hcpcs_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "hcpcsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insuranceplan",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "insuranceplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "insuranceplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insuranceplanAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "insuranceplanAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "insuranceplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "insuranceplanByPk",
            "type": {
              "kind": "OBJECT",
              "name": "insuranceplan",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "insuranceplanStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "insuranceplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "insuranceplan_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "insuranceplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "integration",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "integration",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "integrationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "integrationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "integrationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "integrationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "integrationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "integration",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "integrationStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "integration",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "integration_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "integrationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "internalstaff",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "internalstaff",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "internalstaffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "internalstaffAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "internalstaffAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "internalstaffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "internalstaffByPk",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaff",
              "ofType": null
            },
            "args": [
              {
                "name": "userId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "internalstaffStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "internalstaff",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "internalstaff_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "internalstaffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "legacyOrderFrequency",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderFrequency",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderFrequencyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderFrequencyAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderFrequencyAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderFrequencyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderFrequencyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderFrequency",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "legacyOrderFrequencyStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderFrequency",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderFrequency_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderFrequencyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderMedications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderMedications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderMedicationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderMedicationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderMedicationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderMedicationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderMedicationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderMedications",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "legacyOrderMedicationsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderMedications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderMedications_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderOrderableFieldValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderOrderableFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderableFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderOrderableFieldValueAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderOrderableFieldValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderableFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderOrderableFieldValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderOrderableFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "legacyOrderOrderableFieldValueStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderOrderableFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderableFieldValue_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderOrderableFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderQualification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderQualificationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderQualificationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderQualificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyOrderQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "legacyOrderQualificationStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderQualification_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrderStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrder_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyTreatmentplanOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyTreatmentplanOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyTreatmentplanOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyTreatmentplanOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyTreatmentplanOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyTreatmentplanOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyTreatmentplanOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "legacyTreatmentplanOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "legacyTreatmentplanOrderStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyTreatmentplanOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyTreatmentplanOrder_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "lineitem",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "lineitem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "lineitemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "lineitemAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "lineitemAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "lineitemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "lineitemByPk",
            "type": {
              "kind": "OBJECT",
              "name": "lineitem",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "lineitemStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "lineitem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "lineitem_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "lineitemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medication",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medication",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "medication",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "medicationOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medicationOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "medicationOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "baseorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "medicationOrderStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medicationOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationOrder_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medication",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medication_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationorderMedications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medicationorderMedications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationorderMedicationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationorderMedicationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "medicationorderMedicationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationorderMedicationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "medicationorderMedicationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "medicationorderMedications",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "medicationorderMedicationsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "medicationorderMedications",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "medicationorderMedications_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "medicationorderMedicationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "message",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "message",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "messageOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "messageAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "messageAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "messageOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "messageByPk",
            "type": {
              "kind": "OBJECT",
              "name": "message",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "messageStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "message",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "message_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderableField",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderOrderableField",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderableFieldAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderOrderableFieldAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderableFieldByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableField",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderOrderableFieldStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderOrderableField",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableField_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderableLegacy",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderOrderableLegacy",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableLegacyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderableLegacyAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderOrderableLegacyAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableLegacyOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderOrderableLegacyByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderOrderableLegacy",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderOrderableLegacyStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderOrderableLegacy",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderOrderableLegacy_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderOrderableLegacyBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderable",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderable",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderable",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableCategory",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableCategory",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableCategoryOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableCategoryAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableCategoryAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableCategoryOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableCategoryByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableCategory",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableCategoryStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableCategory",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableCategory_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableOrder",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableOrderAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableOrderByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrder",
              "ofType": null
            },
            "args": [
              {
                "name": "baseorderPtrId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableOrderQualification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableOrderQualificationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableOrderQualificationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableOrderQualificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableOrderQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableOrderQualificationStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrderQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrderQualification_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableOrderStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableOrder_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableQualification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableQualificationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableQualificationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableQualificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableQualificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableQualification",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableQualificationStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableQualification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableQualification_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableQualificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderable",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderable_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableSupplier",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplier",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableSupplierAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableSupplierAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableSupplierByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplier",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableSupplierPayerConnections",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplierPayerConnections",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierPayerConnectionsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableSupplierPayerConnectionsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderableSupplierPayerConnectionsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierPayerConnectionsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableSupplierPayerConnectionsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderableSupplierPayerConnections",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderableSupplierPayerConnectionsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplierPayerConnections",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplierPayerConnections_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierPayerConnectionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderableSupplierStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderableSupplier",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderableSupplier_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderableSupplierBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderaction",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderaction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderactionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderactionAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderactionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderactionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderactionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "orderaction",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "orderactionStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderaction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderaction_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patient",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientAlias",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientAlias",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientAliasOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientAliasAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientAliasAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientAliasOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientAliasByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientAlias",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientAliasStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientAlias",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientAlias_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientAliasBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientCommunication",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientCommunication",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientCommunicationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientCommunicationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientCommunicationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientCommunicationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientCommunicationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientCommunication",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientCommunicationStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientCommunication",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientCommunication_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientCommunicationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientContactPerson",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientContactPerson",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientContactPersonOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientContactPersonAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientContactPersonAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientContactPersonOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientContactPersonByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientContactPerson",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientContactPersonStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientContactPerson",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientContactPerson_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientContactPersonBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientEligibilityCheck",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientEligibilityCheck",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientEligibilityCheckOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientEligibilityCheckAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientEligibilityCheckAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientEligibilityCheckOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientEligibilityCheckByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientEligibilityCheck",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientEligibilityCheckStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientEligibilityCheck",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientEligibilityCheck_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientEligibilityCheckBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientIntake",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntake",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientIntakeAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntakeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientIntakeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntake",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientIntakeRecordsLocations",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntakeRecordsLocations",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeRecordsLocationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientIntakeRecordsLocationsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntakeRecordsLocationsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeRecordsLocationsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientIntakeRecordsLocationsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntakeRecordsLocations",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientIntakeRecordsLocationsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntakeRecordsLocations",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeRecordsLocations_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeRecordsLocationsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientIntakeStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntake",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntake_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientPhone",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientPhone",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhoneOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientPhoneAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientPhoneAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhoneOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientPhoneByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhone",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientPhoneStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientPhone",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhone_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhoneBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientPhoto",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientPhoto",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhotoOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientPhotoAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientPhotoAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhotoOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientPhotoByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientPhoto",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientPhotoStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientPhoto",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientPhoto_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientPhotoBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatus",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatus",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusAction",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusActionAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatusActionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusActionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusAction",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientStatusActionStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusAction_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatusAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatus",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientStatusStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatus",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatus_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusSubsequentStatuses",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusSubsequentStatuses",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusSubsequentStatusesOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusSubsequentStatusesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatusSubsequentStatusesAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusSubsequentStatusesOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusSubsequentStatusesByPk",
            "type": {
              "kind": "OBJECT",
              "name": "patientStatusSubsequentStatuses",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "patientStatusSubsequentStatusesStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusSubsequentStatuses",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusSubsequentStatuses_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusSubsequentStatusesBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patient",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patient_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "payer",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "payer",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "payerOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "payerAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "payerAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "payerOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "payerByPk",
            "type": {
              "kind": "OBJECT",
              "name": "payer",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "payerStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "payer",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "payer_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "payerBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "practicegroup",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "practicegroupOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "practicegroupAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "practicegroupAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "practicegroupOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "practicegroupByPk",
            "type": {
              "kind": "OBJECT",
              "name": "practicegroup",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "practicegroupStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "practicegroup",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "practicegroup_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problem",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problemAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "problemAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problemByPk",
            "type": {
              "kind": "OBJECT",
              "name": "problem",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "problemPlan",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problemPlan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemPlanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problemPlanAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "problemPlanAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemPlanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problemPlanByPk",
            "type": {
              "kind": "OBJECT",
              "name": "problemPlan",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "problemPlanStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problemPlan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problemPlan_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemPlanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "problemStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "problem",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "problem_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "problemBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedure",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedure",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureByPk",
            "type": {
              "kind": "OBJECT",
              "name": "procedure",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "procedureHCPCS",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureHCPCS",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureHCPCSOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureHCPCSAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureHCPCSAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureHCPCSOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureHCPCSByPk",
            "type": {
              "kind": "OBJECT",
              "name": "procedureHCPCS",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "procedureHCPCSStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureHCPCS",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureHCPCS_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureHCPCSBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureRendered",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureRendered",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRenderedOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureRenderedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureRenderedAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRenderedOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureRenderedByPk",
            "type": {
              "kind": "OBJECT",
              "name": "procedureRendered",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "procedureRenderedStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureRendered",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRendered_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "procedureStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedure",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedure_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "provider",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "provider",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerByPk",
            "type": {
              "kind": "OBJECT",
              "name": "provider",
              "ofType": null
            },
            "args": [
              {
                "name": "internalStaffId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "providerClassification",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "providerClassification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerClassificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerClassificationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerClassificationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerClassificationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerClassificationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "providerClassification",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "providerClassificationStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "providerClassification",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerClassification_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerClassificationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerSchedule",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "providerSchedule",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerScheduleOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerScheduleAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "providerScheduleAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerScheduleOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerScheduleByPk",
            "type": {
              "kind": "OBJECT",
              "name": "providerSchedule",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "providerScheduleStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "providerSchedule",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "providerSchedule_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerScheduleBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "providerStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "provider",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "provider_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "providerBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "recordsRequestDestination",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "recordsRequestDestination",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "recordsRequestDestinationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "recordsRequestDestinationAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "recordsRequestDestinationAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "recordsRequestDestinationOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "recordsRequestDestinationByPk",
            "type": {
              "kind": "OBJECT",
              "name": "recordsRequestDestination",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "recordsRequestDestinationStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "recordsRequestDestination",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "recordsRequestDestination_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "recordsRequestDestinationBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referral",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referral",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referralAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "referralAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referralAttachments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referralAttachments",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralAttachmentsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referralAttachmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "referralAttachmentsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralAttachmentsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referralAttachmentsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "referralAttachments",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "referralAttachmentsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referralAttachments",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referralAttachments_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralAttachmentsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "referralByPk",
            "type": {
              "kind": "OBJECT",
              "name": "referral",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "referralStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "referral",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "referral_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "referralBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "renderedAssessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "renderedAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "renderedAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "renderedAssessmentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "renderedAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "renderedAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "renderedAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "renderedAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "renderedAssessmentStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "renderedAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "renderedAssessment_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "schedulingAction",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "schedulingAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "schedulingActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "schedulingActionAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "schedulingActionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "schedulingActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "schedulingActionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "schedulingAction",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "schedulingActionStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "schedulingAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "schedulingAction_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "schedulingActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "serviceType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "serviceType",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "serviceTypeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "serviceTypeAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "serviceTypeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "serviceTypeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "serviceTypeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "serviceType",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "serviceTypeStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "serviceType",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "serviceType_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "serviceTypeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signedConsent",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signedConsent",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signedConsentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signedConsentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "signedConsentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signedConsentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signedConsentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "signedConsent",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "signedConsentStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signedConsent",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signedConsent_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signedConsentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoff",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signoff",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoffAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "signoffAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoffByPk",
            "type": {
              "kind": "OBJECT",
              "name": "signoff",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "signoffStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signoff",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoff_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoffType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signoffType",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffTypeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoffTypeAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "signoffTypeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffTypeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "signoffTypeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "signoffType",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "signoffTypeStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "signoffType",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "signoffType_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "signoffTypeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestAccessCode",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequestAccessCode",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestAccessCodeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestAccessCodeAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "telemedAppointmentRequestAccessCodeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestAccessCodeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestAccessCodeByPk",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCode",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestAccessCodeStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequestAccessCode",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestAccessCode_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "telemedAppointmentRequestAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestByPk",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequest",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequest_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadCategory",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategory",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadCategoryAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadCategoryAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadCategoryByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategory",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "threadCategoryStatus",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategoryStatus",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryStatusOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadCategoryStatusAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadCategoryStatusAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryStatusOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadCategoryStatusByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatus",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "threadCategoryStatusStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategoryStatus",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryStatus_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadCategoryStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategory",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategory_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadField",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadField",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadFieldAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadFieldAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadFieldByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadField",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "threadFieldStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadField",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadField_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadFieldValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadFieldValueAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadFieldValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadFieldValueByPk",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValue",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "threadFieldValueStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldValue_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketSubscription",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketSubscription",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketSubscriptionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketSubscriptionAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketSubscriptionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketSubscriptionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketSubscriptionByPk",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscription",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "ticketSubscriptionStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketSubscription",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketSubscription_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketThread",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketThread",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketThreadAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketThreadAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketThreadByPk",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThread",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "ticketThreadStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketThread",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThread_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanAssessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanAssessmentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "treatmentplanAssessmentStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanAssessment_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplan",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "treatmentplanNote",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanNote",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanNoteOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanNoteAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanNoteAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanNoteOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanNoteByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNote",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "treatmentplanNoteStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanNote",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanNote_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanProblems",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanProblemsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanProblemsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanProblemsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblems",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "treatmentplanProblemsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanProblems_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplan_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplateAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanTemplateAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplateAssessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanTemplateAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplateAssessmentAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanTemplateAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplateAssessmentByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessment",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplateAssessmentStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanTemplateAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateAssessment_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplateByPk",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplate",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "treatmentplanTemplateStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplate_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "user",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "user",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "userAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userByPk",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "userGroups",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "userGroups",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userGroupsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userGroupsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "userGroupsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userGroupsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userGroupsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "userGroups",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "userGroupsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "userGroups",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userGroups_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userPermissions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "userPermissions",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userPermissionsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userPermissionsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "userPermissionsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userPermissionsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userPermissionsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissions",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "userPermissionsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "userPermissions",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "userPermissions_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "user",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "user_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "userBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "visitRebill",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "visitRebill",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "visitRebillOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "visitRebillAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "visitRebillAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "visitRebillOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "visitRebillByPk",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebill",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "visitRebillStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "visitRebill",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "visitRebill_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "vitals",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "vitals",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "vitalsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "vitalsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "vitalsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "vitalsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "vitalsByPk",
            "type": {
              "kind": "OBJECT",
              "name": "vitals",
              "ofType": null
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              }
            ]
          },
          {
            "name": "vitalsStream",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "vitals",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "batchSize",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Int",
                    "ofType": null
                  }
                }
              },
              {
                "name": "cursor",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "vitals_streamCursorInput",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequest",
        "fields": [
          {
            "name": "accessCodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequestAccessCode",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestAccessCodeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "accessCodesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "telemedAppointmentRequestAccessCodeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestAccessCodeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "appointment",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUser",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "availableUntil",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "messages",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "message",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "messageOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "messagesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "messageAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "messageOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patient",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntake",
            "type": {
              "kind": "OBJECT",
              "name": "patientIntake",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerEta",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestTime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "requestingUser",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "timeAvailable",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "user_can_access",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCode",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "code",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "created",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "interval",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "telemedAppointmentRequest",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "used",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequestAccessCode",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAccessCodeVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCodeAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCode_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCode_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCode_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCode_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCode_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCode_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCode_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCode_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCode_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCode_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCodeArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "telemedAppointmentRequestAccessCodeInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCodeOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCodeBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCodeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestAccessCodeBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "code",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "created",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntervalComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestBoolExp",
              "ofType": null
            }
          },
          {
            "name": "used",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCodeIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCodeInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "code",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "created",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "used",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeMaxFields",
        "fields": [
          {
            "name": "code",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "created",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "used",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeMinFields",
        "fields": [
          {
            "name": "code",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "created",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "used",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequestAccessCode",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCodeOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCodeBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCodeOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "code",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "created",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "telemedAppointmentRequest",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestOrderBy",
              "ofType": null
            }
          },
          {
            "name": "used",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCodePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCodeSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "code",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "created",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "used",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCodeUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCodeIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCodeSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "telemedAppointmentRequestAccessCodeBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAccessCodeVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCode_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCode_max_order_by",
        "inputFields": [
          {
            "name": "code",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "created",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "used",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCode_min_order_by",
        "inputFields": [
          {
            "name": "code",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "created",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "used",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCode_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCode_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCode_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCode_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "telemedAppointmentRequestAccessCode_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCode_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "code",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "created",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "expiresIn",
            "type": {
              "kind": "SCALAR",
              "name": "interval",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "used",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCode_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCode_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCode_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAccessCode_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "visitRequestId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "telemedAppointmentRequestVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequest_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequest_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequest_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequest_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequest_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequest_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequest_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequest_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequest_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequest_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "telemedAppointmentRequestInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestAvgFields",
        "fields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "accessCodes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCodeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "approvalUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "availableUntil",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "messages",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patientIntake",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "providerEta",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "requestTime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "requestingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "timeAvailable",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "user_can_access",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestIncInput",
        "inputFields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestInsertInput",
        "inputFields": [
          {
            "name": "accessCodes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCodeArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "approvalUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "availableUntil",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "messages",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientIntake",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerEta",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "requestTime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "requestingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "timeAvailable",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestMaxFields",
        "fields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "availableUntil",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerEta",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestTime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeAvailable",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestMinFields",
        "fields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "availableUntil",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "providerEta",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestTime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "timeAvailable",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "telemedAppointmentRequestInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestOrderBy",
        "inputFields": [
          {
            "name": "accessCodesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAccessCodeAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "approvalUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "availableUntil",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messagesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntake",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerEta",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestingUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "timeAvailable",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "user_can_access",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "availableUntil",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerEta",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "requestTime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "timeAvailable",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestStddevFields",
        "fields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestStddev_popFields",
        "fields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestStddev_sampFields",
        "fields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestSumFields",
        "fields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequestUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "telemedAppointmentRequestBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestVar_popFields",
        "fields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestVar_sampFields",
        "fields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "telemedAppointmentRequestVarianceFields",
        "fields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequest_avg_order_by",
        "inputFields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequest_max_order_by",
        "inputFields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "availableUntil",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerEta",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "timeAvailable",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequest_min_order_by",
        "inputFields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "availableUntil",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "providerEta",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestTime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "timeAvailable",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequest_stddev_order_by",
        "inputFields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequest_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequest_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequest_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "telemedAppointmentRequest_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequest_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "availableUntil",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "providerEta",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "requestTime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "timeAvailable",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequest_sum_order_by",
        "inputFields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequest_var_pop_order_by",
        "inputFields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequest_var_samp_order_by",
        "inputFields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "telemedAppointmentRequest_variance_order_by",
        "inputFields": [
          {
            "name": "approvalUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "apptId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntakeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "requestingUserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadCategory",
        "fields": [
          {
            "name": "childThreadCategories",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategory",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "childThreadCategoriesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadCategoryAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "fields",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadField",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "fieldsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadFieldAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentThreadCategory",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategory",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "practicegroup",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "statuses",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategoryStatus",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryStatusOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "statusesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadCategoryStatusAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryStatusOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketthreads",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketThread",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketthreadsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketThreadAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategory",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategorySumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategory_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategory_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategory_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategory_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategory_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategory_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategory_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategory_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategory_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategory_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadCategoryInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "childThreadCategories",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryBoolExp",
              "ofType": null
            }
          },
          {
            "name": "fields",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "parentThreadCategory",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryBoolExp",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "statuses",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ticketthreads",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryInsertInput",
        "inputFields": [
          {
            "name": "childThreadCategories",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "fields",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "parentThreadCategory",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "statuses",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ticketthreads",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategory",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "threadCategoryInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryOrderBy",
        "inputFields": [
          {
            "name": "childThreadCategoriesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "fieldsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentThreadCategory",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryOrderBy",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOrderBy",
              "ofType": null
            }
          },
          {
            "name": "statusesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ticketthreadsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategorySetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatus",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "endStatus",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "previousStatus",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatus",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "subsequentStatuses",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategoryStatus",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryStatusOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "subsequentStatusesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadCategoryStatusAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadCategoryStatusOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadCategory",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadCategory",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "ticketthreads",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketThread",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketthreadsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketThreadAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategoryStatus",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatusVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatusAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatus_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatus_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatus_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatus_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatus_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatus_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatus_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatus_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatus_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatus_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatusArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadCategoryStatusInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusAvgFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatusBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadCategoryStatusBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "endStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "previousStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusBoolExp",
              "ofType": null
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "subsequentStatuses",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusBoolExp",
              "ofType": null
            }
          },
          {
            "name": "threadCategory",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ticketthreads",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatusIncInput",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatusInsertInput",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "endStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "previousStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "subsequentStatuses",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "threadCategory",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ticketthreads",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusMaxFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusMinFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadCategoryStatus",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatusObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "threadCategoryStatusInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatusOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatusOrderBy",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "endStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "previousStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusOrderBy",
              "ofType": null
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subsequentStatusesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "threadCategory",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ticketthreadsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatusPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatusSetInput",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "endStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusStddevFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusStddev_popFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusStddev_sampFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusSumFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatusUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "threadCategoryStatusBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusVar_popFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusVar_sampFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStatusVarianceFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatus_avg_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatus_max_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatus_min_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatus_stddev_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatus_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatus_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatus_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "threadCategoryStatus_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatus_streamCursorValueInput",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "endStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatus_sum_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatus_var_pop_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatus_var_samp_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryStatus_variance_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "previousStatusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategorySumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategoryUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategorySetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "threadCategoryBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadCategoryVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategory_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategory_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategory_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategory_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategory_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategory_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategory_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "threadCategory_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategory_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategory_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategory_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategory_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadCategory_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "parentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadField",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "fieldValues",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "fieldValuesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadFieldValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "threadCategory",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadCategory",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadField",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadField_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadField_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadField_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadField_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadField_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadField_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadField_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadField_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadField_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadField_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadFieldInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldAvgFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "fieldValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValueBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "threadCategory",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldIncInput",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "fieldValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValueArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "threadCategory",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryObjRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldMaxFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldMinFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadField",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "threadFieldInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "fieldValuesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValueAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadCategory",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldStddevFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldStddev_popFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldStddev_sampFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldSumFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "threadFieldBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValue",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "threadId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "threadfield",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadField",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "ticketthread",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketThread",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "threadFieldValueVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValueAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValue_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValue_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValue_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValue_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValue_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValue_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValue_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValue_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValue_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValue_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValueArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "threadFieldValueInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValueOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValueBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValueBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "threadfield",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ticketthread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValueIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValueInsertInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "threadfield",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ticketthread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValueOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValueBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValueOrderBy",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadfield",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ticketthread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadOrderBy",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValuePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValueSetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValueUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValueIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValueSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "threadFieldValueBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldValueVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValue_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValue_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValue_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValue_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValue_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValue_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValue_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "threadFieldValue_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValue_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "value",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValue_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValue_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValue_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadFieldValue_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadFieldId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldVar_popFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldVar_sampFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "threadFieldVarianceFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadField_avg_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadField_max_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadField_min_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadField_stddev_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadField_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadField_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadField_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "threadField_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadField_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadField_sum_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadField_var_pop_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadField_var_samp_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "threadField_variance_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscription",
        "fields": [
          {
            "name": "createdById",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "createdUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "user",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "dateSubscribed",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "dateUnsubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "subscribedUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "user",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "ticketthread",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketThread",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketSubscription",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "ticketSubscriptionVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscriptionAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscription_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscription_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscription_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscription_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscription_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscription_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscription_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscription_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscription_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscription_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscriptionArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "ticketSubscriptionInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscriptionOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionAvgFields",
        "fields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscriptionBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscriptionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "createdById",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "createdUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "dateSubscribed",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateUnsubscribed",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "subscribedUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "ticketthread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscriptionIncInput",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscriptionInsertInput",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "createdUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "dateSubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateUnsubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "subscribedUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketthread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionMaxFields",
        "fields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateSubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateUnsubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionMinFields",
        "fields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateSubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateUnsubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketSubscription",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscriptionOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscriptionBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscriptionOrderBy",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "createdUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "dateSubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateUnsubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "subscribedUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketthread",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadOrderBy",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscriptionPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscriptionSetInput",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "dateSubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateUnsubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionStddevFields",
        "fields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionStddev_popFields",
        "fields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionStddev_sampFields",
        "fields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionSumFields",
        "fields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscriptionUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscriptionIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscriptionSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "ticketSubscriptionBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionVar_popFields",
        "fields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionVar_sampFields",
        "fields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketSubscriptionVarianceFields",
        "fields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscription_avg_order_by",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscription_max_order_by",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateSubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateUnsubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscription_min_order_by",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateSubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateUnsubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscription_stddev_order_by",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscription_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscription_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscription_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "ticketSubscription_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscription_streamCursorValueInput",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "dateSubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "dateUnsubscribed",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscription_sum_order_by",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscription_var_pop_order_by",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscription_var_samp_order_by",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketSubscription_variance_order_by",
        "inputFields": [
          {
            "name": "createdById",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ticketId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "ticketThread",
        "fields": [
          {
            "name": "baseorders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "baseorder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "baseordersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "baseorderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "baseorderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "baseorderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretime",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "category",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategory",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateCreated",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "documents",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "document",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "documentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "emailSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "jsonData",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            },
            "args": [
              {
                "name": "path",
                "type": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "legacyOrdersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "messages",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "message",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "messageOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "messagesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "messageAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "messageOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patient",
            "type": {
              "kind": "OBJECT",
              "name": "patient",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "practicegroup",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "OBJECT",
              "name": "threadCategoryStatus",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "threadFieldValues",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "threadFieldValue",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "threadFieldValuesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "threadFieldValueAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "threadFieldValueOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "threadFieldValueBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "updatedTimestamp",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "userCreated",
            "type": {
              "kind": "OBJECT",
              "name": "user",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userSubscriptions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketSubscription",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketSubscriptionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userSubscriptionsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketSubscriptionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketSubscriptionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "user_can_access",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketThread",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "ticketThreadVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThread_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThread_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThread_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThread_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThread_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThread_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThread_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThread_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThread_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThread_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadAppendInput",
        "inputFields": [
          {
            "name": "jsonData",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "ticketThreadInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadAvgFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "baseorders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryBoolExp",
              "ofType": null
            }
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateCreated",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "documents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "emailSubmitted",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "jsonData",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "JsonbComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "legacyOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "messages",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusBoolExp",
              "ofType": null
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "threadFieldValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValueBoolExp",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "updatedTimestamp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "userCreated",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "userSubscriptions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscriptionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "user_can_access",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadDeleteAtPathInput",
        "inputFields": [
          {
            "name": "jsonData",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "SCALAR",
                  "name": "String",
                  "ofType": null
                }
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadDeleteElemInput",
        "inputFields": [
          {
            "name": "jsonData",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadDeleteKeyInput",
        "inputFields": [
          {
            "name": "jsonData",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadIncInput",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadInsertInput",
        "inputFields": [
          {
            "name": "baseorders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "dateCreated",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "documents",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "emailSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "jsonData",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "legacyOrders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "messages",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "threadFieldValues",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValueArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "updatedTimestamp",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "userCreated",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userSubscriptions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscriptionArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadMaxFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateCreated",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "emailSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "updatedTimestamp",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadMinFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateCreated",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "emailSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "updatedTimestamp",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketThread",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "ticketThreadInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadOrderBy",
        "inputFields": [
          {
            "name": "baseordersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "baseorderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "caretimesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "category",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryOrderBy",
              "ofType": null
            }
          },
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateCreated",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "documentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "emailSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "jsonData",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "legacyOrdersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "messagesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroup",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupOrderBy",
              "ofType": null
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "status",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadCategoryStatusOrderBy",
              "ofType": null
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "threadFieldValuesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "threadFieldValueAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "updatedTimestamp",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userCreated",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userSubscriptionsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscriptionAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "user_can_access",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadPrependInput",
        "inputFields": [
          {
            "name": "jsonData",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadSetInput",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "dateCreated",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "emailSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "jsonData",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "updatedTimestamp",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadStddevFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadStddev_popFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadStddev_sampFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadSumFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThreadUpdates",
        "inputFields": [
          {
            "name": "_append",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadAppendInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteAtPath",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadDeleteAtPathInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteElem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadDeleteElemInput",
              "ofType": null
            }
          },
          {
            "name": "_deleteKey",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadDeleteKeyInput",
              "ofType": null
            }
          },
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadIncInput",
              "ofType": null
            }
          },
          {
            "name": "_prepend",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadPrependInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "ticketThreadBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadVar_popFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadVar_sampFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ticketThreadVarianceFields",
        "fields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThread_avg_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThread_max_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateCreated",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "emailSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "updatedTimestamp",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThread_min_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateCreated",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "emailSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "updatedTimestamp",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThread_stddev_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThread_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThread_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThread_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "ticketThread_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThread_streamCursorValueInput",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "dateCreated",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "emailSubmitted",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "jsonData",
            "type": {
              "kind": "SCALAR",
              "name": "jsonb",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "text",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "updatedTimestamp",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThread_sum_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThread_var_pop_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThread_var_samp_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "ticketThread_variance_order_by",
        "inputFields": [
          {
            "name": "categoryId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practicegroupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "statusId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userCreatedId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "SCALAR",
        "name": "time"
      },
      {
        "kind": "SCALAR",
        "name": "timestamptz"
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplan",
        "fields": [
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "assessments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "associatedTreatmentPlanTemplate",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplate",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateStart",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "date",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "orders",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyTreatmentplanOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyTreatmentplanOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyTreatmentplanOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyTreatmentplanOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyTreatmentplanOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patient",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patient",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "proceduresRendered",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureRendered",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRenderedOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "proceduresRenderedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureRenderedAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRenderedOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentPlanProblems",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentPlanProblemsAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanProblemsAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanProblemsOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentPlanProgressNotes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanNote",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanNoteOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentPlanProgressNotesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanNoteAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanNoteOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplan_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplan_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplan_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplan_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplan_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplan_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplan_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplan_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplan_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplan_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessment",
        "fields": [
          {
            "name": "appointment",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "renderedAssessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "renderedAssessment",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplan",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanAssessmentVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessmentAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessment_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessment_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessment_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessment_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessment_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessment_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessment_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessment_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessment_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessment_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessmentArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanAssessmentInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentAvgFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessmentBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanAssessmentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessmentIncInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessmentInsertInput",
        "inputFields": [
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanObjRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentMaxFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentMinFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessmentObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanAssessmentInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessmentOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessmentOrderBy",
        "inputFields": [
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessmentPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessmentSetInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentStddevFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentStddev_popFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentStddev_sampFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentSumFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessmentUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanAssessmentBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentVar_popFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentVar_sampFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAssessmentVarianceFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessment_avg_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessment_max_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessment_min_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessment_stddev_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessment_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessment_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessment_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanAssessment_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessment_streamCursorValueInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessment_sum_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessment_var_pop_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessment_var_samp_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanAssessment_variance_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "renderedAssessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanAvgFields",
        "fields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "active",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "assessments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "associatedTreatmentPlanTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateBoolExp",
              "ofType": null
            }
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "dateStart",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "DateComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "orders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "proceduresRendered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedBoolExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanProblems",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblemsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanProgressNotes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNoteBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanIncInput",
        "inputFields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanInsertInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "assessments",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "associatedTreatmentPlanTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "dateStart",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "orders",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "proceduresRendered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanProblems",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblemsArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanProgressNotes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNoteArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanMaxFields",
        "fields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateStart",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanMinFields",
        "fields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "dateStart",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNote",
        "fields": [
          {
            "name": "datetime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "enteringUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "user",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "note",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplan",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanNote",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanNoteVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNoteAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNote_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNote_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNote_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNote_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNote_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNote_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNote_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNote_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNote_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNote_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNoteArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanNoteInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNoteOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNoteBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNoteBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "datetime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "enteringUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "note",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanBoolExp",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNoteIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNoteInsertInput",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "enteringUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "note",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteMaxFields",
        "fields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "note",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteMinFields",
        "fields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "note",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanNote",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNoteOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNoteBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNoteOrderBy",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "enteringUser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOrderBy",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "note",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanOrderBy",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNotePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNoteSetInput",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "note",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNoteUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNoteIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNoteSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanNoteBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanNoteVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNote_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNote_max_order_by",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "note",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNote_min_order_by",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "note",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNote_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNote_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNote_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNote_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanNote_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNote_streamCursorValueInput",
        "inputFields": [
          {
            "name": "datetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "note",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNote_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNote_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNote_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanNote_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "userId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanOrderBy",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "assessmentsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAssessmentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "associatedTreatmentPlanTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateStart",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "ordersAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyTreatmentplanOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patient",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "proceduresRenderedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanProblemsAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblemsAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanProgressNotesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNoteAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblems",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "problem",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "problem",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplan",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanProblemsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblemsAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblems_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblems_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblems_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblems_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblems_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblems_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblems_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblems_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblems_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblems_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblemsArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanProblemsInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblemsOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblemsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblemsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanProblemsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "problem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemBoolExp",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanBoolExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblemsIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblemsInsertInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "problem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanProblems",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblemsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblemsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblemsOrderBy",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problem",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "problemOrderBy",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplan",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanOrderBy",
              "ofType": null
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblemsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblemsSetInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblemsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblemsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanProblemsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanProblemsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanProblemsVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblems_avg_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblems_max_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblems_min_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblems_stddev_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblems_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblems_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblems_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanProblems_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblems_streamCursorValueInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblems_sum_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblems_var_pop_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblems_var_samp_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanProblems_variance_order_by",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "problemId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplanId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanSetInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "dateStart",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanStddevFields",
        "fields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanStddev_popFields",
        "fields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanStddev_sampFields",
        "fields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanSumFields",
        "fields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplate",
        "fields": [
          {
            "name": "assessmentsUsingTemplate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanTemplateAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentsUsingTemplateAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanTemplateAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanTemplateAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "icdOptions",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentplans",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplan",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentplansAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanBoolExp",
                  "ofType": null
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessment",
        "fields": [
          {
            "name": "assessment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "assessment",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentplanTemplate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanTemplate",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanTemplateAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "treatmentplanTemplateAssessmentVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessmentAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessment_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessment_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessment_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessment_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessment_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessment_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessment_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessment_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessment_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessment_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessmentArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "treatmentplanTemplateAssessmentInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessmentOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentAvgFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessmentBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateAssessmentBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "assessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentplanTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateBoolExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessmentIncInput",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessmentInsertInput",
        "inputFields": [
          {
            "name": "assessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentplanTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentMaxFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentMinFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanTemplateAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessmentOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessmentBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessmentOrderBy",
        "inputFields": [
          {
            "name": "assessment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "assessmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplanTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessmentPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessmentSetInput",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentStddevFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentStddev_popFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentStddev_sampFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentSumFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessmentUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessmentIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessmentSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanTemplateAssessmentBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentVar_popFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentVar_sampFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAssessmentVarianceFields",
        "fields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessment_avg_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessment_max_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessment_min_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessment_stddev_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessment_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessment_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessment_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanTemplateAssessment_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessment_streamCursorValueInput",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessment_sum_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessment_var_pop_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessment_var_samp_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateAssessment_variance_order_by",
        "inputFields": [
          {
            "name": "assessmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplantemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanTemplateBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "assessmentsUsingTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "icdOptions",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "_varcharComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentplans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateInsertInput",
        "inputFields": [
          {
            "name": "assessmentsUsingTemplate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessmentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "icdOptions",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "treatmentplans",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanArrRelInsertInput",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateMaxFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateMinFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanTemplate",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanTemplateInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateOrderBy",
        "inputFields": [
          {
            "name": "assessmentsUsingTemplateAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateAssessmentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "icdOptions",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentplansAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanAggregateOrderBy",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplatePkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateSetInput",
        "inputFields": [
          {
            "name": "icdOptions",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplateUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanTemplateSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanTemplateBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanTemplateVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplate_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanTemplate_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanTemplate_streamCursorValueInput",
        "inputFields": [
          {
            "name": "icdOptions",
            "type": {
              "kind": "SCALAR",
              "name": "_varchar",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplanUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplanBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanVar_popFields",
        "fields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanVar_sampFields",
        "fields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "treatmentplanVarianceFields",
        "fields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplan_avg_order_by",
        "inputFields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplan_max_order_by",
        "inputFields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateStart",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplan_min_order_by",
        "inputFields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "dateStart",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplan_stddev_order_by",
        "inputFields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplan_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplan_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplan_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "treatmentplan_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplan_streamCursorValueInput",
        "inputFields": [
          {
            "name": "active",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "dateEnd",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "dateStart",
            "type": {
              "kind": "SCALAR",
              "name": "date",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "notes",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplan_sum_order_by",
        "inputFields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplan_var_pop_order_by",
        "inputFields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplan_var_samp_order_by",
        "inputFields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "treatmentplan_variance_order_by",
        "inputFields": [
          {
            "name": "associatedTemplateId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "user",
        "fields": [
          {
            "name": "assessmentsRendered",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "renderedAssessment",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "renderedAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assessmentsRenderedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "renderedAssessmentAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "renderedAssessmentOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "renderedAssessmentBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assignedDocumentsPendingReview",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "documentPendingReview",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReviewOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "assignedDocumentsPendingReviewAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "documentPendingReviewAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "documentPendingReviewOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "documentPendingReviewBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "caretime",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "caretimesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "caretimeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "caretimeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "caretimeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "dateJoined",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "email",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "externalstaff",
            "type": {
              "kind": "OBJECT",
              "name": "externalstaff",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "internalstaff",
            "type": {
              "kind": "OBJECT",
              "name": "internalstaff",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "isActive",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "isStaff",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "isSuperuser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "lastLogin",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "messages",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "message",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "messageOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "messagesAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "messageAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "messageOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "messageBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderactionsEntered",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "orderaction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderactionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "orderactionsEnteredAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "orderactionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "orderactionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "orderactionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersApprovedAsSupervisor",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersApprovedAsSupervisorAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersFinalized",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersFinalizedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersRequested",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "legacyOrder",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ordersRequestedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "legacyOrderAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "legacyOrderOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "legacyOrderBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "password",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "patientIntakesSubmitted",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientIntake",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientIntakesSubmittedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientIntakeAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientIntakeOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientIntakeBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusActionsEntered",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "patientStatusAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "patientStatusActionsEnteredAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "patientStatusActionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "patientStatusActionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "patientStatusActionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "phoneNumber",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "practiceGroupsMedicalDirected",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "practicegroup",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "practicegroupOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "practiceGroupsMedicalDirectedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "practicegroupAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "practicegroupOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "practicegroupBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "proceduresPerformed",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "procedureRendered",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRenderedOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "proceduresPerformedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "procedureRenderedAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "procedureRenderedOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "procedureRenderedBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestsApproved",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestsApprovedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "telemedAppointmentRequestAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestsRequested",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "telemedAppointmentRequest",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "telemedAppointmentRequestsRequestedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "telemedAppointmentRequestAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "telemedAppointmentRequestOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "telemedAppointmentRequestBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketsCreated",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketThread",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketsCreatedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketThreadAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketThreadOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketThreadBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketsSubscribed",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ticketSubscription",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketSubscriptionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "ticketsSubscribedAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "ticketSubscriptionAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "ticketSubscriptionOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "ticketSubscriptionBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "treatmentPlanNotesEntered",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "treatmentplanNote",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanNoteOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "treatmentPlanNotesEnteredAggregate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "treatmentplanNoteAggregate",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "distinctOn",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "limit",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "offset",
                "type": {
                  "kind": "SCALAR",
                  "name": "Int",
                  "ofType": null
                }
              },
              {
                "name": "orderBy",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "INPUT_OBJECT",
                      "name": "treatmentplanNoteOrderBy",
                      "ofType": null
                    }
                  }
                }
              },
              {
                "name": "where",
                "type": {
                  "kind": "INPUT_OBJECT",
                  "name": "treatmentplanNoteBoolExp",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "userType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "smallint",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "username",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "String",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "userAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "user",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "userAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "userMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "userMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "userStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "userStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "userStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "userSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "userVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "userVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "userVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userAvgFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "userBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "userBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "assessmentsRendered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "assignedDocumentsPendingReview",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewBoolExp",
              "ofType": null
            }
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "dateJoined",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "email",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "externalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffBoolExp",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "internalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffBoolExp",
              "ofType": null
            }
          },
          {
            "name": "isActive",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "isStaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "isSuperuser",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "BooleanComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "lastLogin",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "messages",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageBoolExp",
              "ofType": null
            }
          },
          {
            "name": "orderactionsEntered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ordersApprovedAsSupervisor",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ordersFinalized",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ordersRequested",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderBoolExp",
              "ofType": null
            }
          },
          {
            "name": "password",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "patientIntakesSubmitted",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "patientStatusActionsEntered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "phoneNumber",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupsMedicalDirected",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupBoolExp",
              "ofType": null
            }
          },
          {
            "name": "proceduresPerformed",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedBoolExp",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequestsApproved",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestBoolExp",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequestsRequested",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ticketsCreated",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadBoolExp",
              "ofType": null
            }
          },
          {
            "name": "ticketsSubscribed",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscriptionBoolExp",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanNotesEntered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNoteBoolExp",
              "ofType": null
            }
          },
          {
            "name": "userType",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "username",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "StringComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "userGroups",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "groupId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "userGroups",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "userGroupsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsAvgFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userGroupsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userGroupsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "userGroupsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "customuserId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "groupId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userGroupsIncInput",
        "inputFields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userGroupsInsertInput",
        "inputFields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsMaxFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsMinFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "userGroups",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userGroupsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userGroupsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userGroupsOrderBy",
        "inputFields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userGroupsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userGroupsSetInput",
        "inputFields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsStddevFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsStddev_popFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsStddev_sampFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsSumFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userGroupsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userGroupsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userGroupsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "userGroupsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsVar_popFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsVar_sampFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userGroupsVarianceFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userGroups_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "userGroups_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userGroups_streamCursorValueInput",
        "inputFields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "groupId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userIncInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userInsertInput",
        "inputFields": [
          {
            "name": "assessmentsRendered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "assignedDocumentsPendingReview",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "caretimes",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "dateJoined",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "externalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "internalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "isActive",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isStaff",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isSuperuser",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "lastLogin",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "messages",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "orderactionsEntered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ordersApprovedAsSupervisor",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ordersFinalized",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ordersRequested",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "password",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "patientIntakesSubmitted",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "patientStatusActionsEntered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "phoneNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "practiceGroupsMedicalDirected",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "proceduresPerformed",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequestsApproved",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequestsRequested",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ticketsCreated",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "ticketsSubscribed",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscriptionArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "treatmentPlanNotesEntered",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNoteArrRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "username",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "userMaxFields",
        "fields": [
          {
            "name": "dateJoined",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastLogin",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "password",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "phoneNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "username",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userMinFields",
        "fields": [
          {
            "name": "dateJoined",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastLogin",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "password",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "phoneNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "username",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "user",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "userInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userOrderBy",
        "inputFields": [
          {
            "name": "assessmentsRenderedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "renderedAssessmentAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "assignedDocumentsPendingReviewAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "documentPendingReviewAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "caretimesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "dateJoined",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "externalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "externalstaffOrderBy",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "internalstaff",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "internalstaffOrderBy",
              "ofType": null
            }
          },
          {
            "name": "isActive",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "isStaff",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "isSuperuser",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastLogin",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "messagesAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "messageAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "orderactionsEnteredAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "orderactionAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ordersApprovedAsSupervisorAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ordersFinalizedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ordersRequestedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "legacyOrderAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "password",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "patientIntakesSubmittedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientIntakeAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "patientStatusActionsEnteredAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "patientStatusActionAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "phoneNumber",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "practiceGroupsMedicalDirectedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "practicegroupAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "proceduresPerformedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "procedureRenderedAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequestsApprovedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "telemedAppointmentRequestsRequestedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "telemedAppointmentRequestAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ticketsCreatedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketThreadAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "ticketsSubscribedAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "ticketSubscriptionAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "treatmentPlanNotesEnteredAggregate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "treatmentplanNoteAggregateOrderBy",
              "ofType": null
            }
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "username",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "userPermissions",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "userPermissions",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "userPermissionsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsAvgFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userPermissionsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userPermissionsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "userPermissionsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "customuserId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userPermissionsIncInput",
        "inputFields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userPermissionsInsertInput",
        "inputFields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsMaxFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsMinFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "userPermissions",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userPermissionsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userPermissionsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userPermissionsOrderBy",
        "inputFields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userPermissionsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userPermissionsSetInput",
        "inputFields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsStddevFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsStddev_popFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsStddev_sampFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsSumFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userPermissionsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userPermissionsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userPermissionsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "userPermissionsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsVar_popFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsVar_sampFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userPermissionsVarianceFields",
        "fields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userPermissions_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "userPermissions_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userPermissions_streamCursorValueInput",
        "inputFields": [
          {
            "name": "customuserId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "permissionId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userSetInput",
        "inputFields": [
          {
            "name": "dateJoined",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "isActive",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isStaff",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isSuperuser",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "lastLogin",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "password",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "phoneNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "username",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "userStddevFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userStddev_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userStddev_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userSumFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "userUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "userSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "userBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "userVar_popFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userVar_sampFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "userVarianceFields",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "user_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "user_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "user_streamCursorValueInput",
        "inputFields": [
          {
            "name": "dateJoined",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "email",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "firstName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "isActive",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isStaff",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "isSuperuser",
            "type": {
              "kind": "SCALAR",
              "name": "Boolean",
              "ofType": null
            }
          },
          {
            "name": "lastLogin",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "lastName",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "password",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "phoneNumber",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "title",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          },
          {
            "name": "userType",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "username",
            "type": {
              "kind": "SCALAR",
              "name": "String",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "visitRebill",
        "fields": [
          {
            "name": "appointment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "appointment",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "rebillDatetime",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "timestamptz",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "resultingAppointment",
            "type": {
              "kind": "OBJECT",
              "name": "appointment",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingCaretime",
            "type": {
              "kind": "OBJECT",
              "name": "caretime",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "visitRebill",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "visitRebillVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebillAggregateOrderBy",
        "inputFields": [
          {
            "name": "avg",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebill_avg_order_by",
              "ofType": null
            }
          },
          {
            "name": "count",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "max",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebill_max_order_by",
              "ofType": null
            }
          },
          {
            "name": "min",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebill_min_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebill_stddev_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebill_stddev_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "stddev_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebill_stddev_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "sum",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebill_sum_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_pop",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebill_var_pop_order_by",
              "ofType": null
            }
          },
          {
            "name": "var_samp",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebill_var_samp_order_by",
              "ofType": null
            }
          },
          {
            "name": "variance",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebill_variance_order_by",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebillArrRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "INPUT_OBJECT",
                    "name": "visitRebillInsertInput",
                    "ofType": null
                  }
                }
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillAvgFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebillBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "visitRebillBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "rebillDatetime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "TimestamptzComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "resultingAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "resultingCaretime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeBoolExp",
              "ofType": null
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebillIncInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebillInsertInput",
        "inputFields": [
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rebillDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "resultingAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "resultingCaretime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillMaxFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rebillDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillMinFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "rebillDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "visitRebill",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebillOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebillOrderBy",
        "inputFields": [
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rebillDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingAppointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingCaretime",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "caretimeOrderBy",
              "ofType": null
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebillPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebillSetInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rebillDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillStddevFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillStddev_popFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillStddev_sampFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillSumFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebillUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "visitRebillSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "visitRebillBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillVar_popFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillVar_sampFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "visitRebillVarianceFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebill_avg_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebill_max_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rebillDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebill_min_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "rebillDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebill_stddev_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebill_stddev_pop_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebill_stddev_samp_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebill_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "visitRebill_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebill_streamCursorValueInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "rebillDatetime",
            "type": {
              "kind": "SCALAR",
              "name": "timestamptz",
              "ofType": null
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebill_sum_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebill_var_pop_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebill_var_samp_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "visitRebill_variance_order_by",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingAppointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "resultingCaretimeId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "vitals",
        "fields": [
          {
            "name": "appointment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "appointment",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "vitalsAggregate",
        "fields": [
          {
            "name": "aggregate",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsAggregateFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "nodes",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "vitals",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "vitalsAggregateFields",
        "fields": [
          {
            "name": "avg",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsAvgFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "columns",
                "type": {
                  "kind": "LIST",
                  "ofType": {
                    "kind": "NON_NULL",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              },
              {
                "name": "distinct",
                "type": {
                  "kind": "SCALAR",
                  "name": "Boolean",
                  "ofType": null
                }
              }
            ]
          },
          {
            "name": "max",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsMaxFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "min",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsMinFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddev",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsStddevFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevPop",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsStddev_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "stddevSamp",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsStddev_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "sum",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsSumFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varPop",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsVar_popFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "varSamp",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsVar_sampFields",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "variance",
            "type": {
              "kind": "OBJECT",
              "name": "vitalsVarianceFields",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "vitalsAvgFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "vitalsBoolExp",
        "inputFields": [
          {
            "name": "_and",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "_not",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "vitalsBoolExp",
              "ofType": null
            }
          },
          {
            "name": "_or",
            "type": {
              "kind": "LIST",
              "ofType": {
                "kind": "NON_NULL",
                "ofType": {
                  "kind": "INPUT_OBJECT",
                  "name": "vitalsBoolExp",
                  "ofType": null
                }
              }
            }
          },
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentBoolExp",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "bmi",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "height",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "IntComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "pulse",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "spo2",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "temperature",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "weight",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "NumericComparisonExp",
              "ofType": null
            }
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "SmallintComparisonExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "vitalsIncInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "vitalsInsertInput",
        "inputFields": [
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentObjRelInsertInput",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "vitalsMaxFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "vitalsMinFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "vitalsMutationResponse",
        "fields": [
          {
            "name": "affected_rows",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "returning",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "vitals",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "vitalsObjRelInsertInput",
        "inputFields": [
          {
            "name": "data",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "vitalsInsertInput",
                "ofType": null
              }
            }
          },
          {
            "name": "onConflict",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "vitalsOnConflict",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "vitalsOnConflict",
        "inputFields": [
          {
            "name": "constraint",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            }
          },
          {
            "name": "update_columns",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "defaultValue": "[]"
          },
          {
            "name": "where",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "vitalsBoolExp",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "vitalsOrderBy",
        "inputFields": [
          {
            "name": "appointment",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "appointmentOrderBy",
              "ofType": null
            }
          },
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "vitalsPkColumnsInput",
        "inputFields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "vitalsSetInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "vitalsStddevFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "vitalsStddev_popFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "vitalsStddev_sampFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "vitalsSumFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "vitalsUpdates",
        "inputFields": [
          {
            "name": "_inc",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "vitalsIncInput",
              "ofType": null
            }
          },
          {
            "name": "_set",
            "type": {
              "kind": "INPUT_OBJECT",
              "name": "vitalsSetInput",
              "ofType": null
            }
          },
          {
            "name": "where",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "vitalsBoolExp",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "vitalsVar_popFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "vitalsVar_sampFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "vitalsVarianceFields",
        "fields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "Float",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "vitals_streamCursorInput",
        "inputFields": [
          {
            "name": "initialValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "INPUT_OBJECT",
                "name": "vitals_streamCursorValueInput",
                "ofType": null
              }
            }
          },
          {
            "name": "ordering",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            }
          }
        ]
      },
      {
        "kind": "INPUT_OBJECT",
        "name": "vitals_streamCursorValueInput",
        "inputFields": [
          {
            "name": "appointmentId",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "bmi",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "bpdiastolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "bpsystolic",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "height",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Int",
              "ofType": null
            }
          },
          {
            "name": "pulse",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "respiratoryrate",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "smokingStatus",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "spo2",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          },
          {
            "name": "temperature",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "weight",
            "type": {
              "kind": "SCALAR",
              "name": "numeric",
              "ofType": null
            }
          },
          {
            "name": "weightMethod",
            "type": {
              "kind": "SCALAR",
              "name": "smallint",
              "ofType": null
            }
          }
        ]
      },
      {
        "kind": "SCALAR",
        "name": "Any"
      }
    ],
    "directives": [
      {
        "name": "cached",
        "locations": [
          "QUERY"
        ],
        "args": [
          {
            "name": "refresh",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            },
            "defaultValue": "false"
          },
          {
            "name": "ttl",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Int",
                "ofType": null
              }
            },
            "defaultValue": "60"
          }
        ]
      },
      {
        "name": "include",
        "locations": [
          "FIELD",
          "FRAGMENT_SPREAD",
          "INLINE_FRAGMENT"
        ],
        "args": [
          {
            "name": "if",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            }
          }
        ]
      },
      {
        "name": "skip",
        "locations": [
          "FIELD",
          "FRAGMENT_SPREAD",
          "INLINE_FRAGMENT"
        ],
        "args": [
          {
            "name": "if",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Boolean",
                "ofType": null
              }
            }
          }
        ]
      }
    ]
  }
}