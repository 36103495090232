import React from 'react';
import { BackgroundView } from '../../../design/view';
import IconTextListItem, {
  IconTextListItemProps,
} from '../../../design/generics/list/IconTextListItem';

const Patient = (props) => {
  console.log(props.route);
  const patientId = props.route.params.patientId;

  const _RequestItem = ({ screen, ...rest }: { screen: string } & IconTextListItemProps) => (
    <IconTextListItem
      to={{ screen: screen, params: { patientId } }}
      className={'rounded-2xl border-2'}
      {...rest}
    />
  );
  return (
    <BackgroundView
      style={{
        flexDirection: 'column',
        flexGrow: 1,
      }}>
      <_RequestItem headingText={'Simple Frequency'} screen={'SimpleFrequencyOrder'} />
      <_RequestItem screen={'DetailedFrequencyOrder'} headingText={'Detailed Frequency'} />
      <_RequestItem screen={'MiscelaneousOrder'} headingText={'Miscelaneous'} />
    </BackgroundView>
  );
};

export default Patient;
