import * as RN from 'react-native';
import React from 'react';
import { styled } from 'nativewind';

export interface StyledInputProps extends RN.TextInputProps {
  font?: string;
  color?: string;
  fontSize?: number;
}

const BaseInputStyle = {
  borderColor: 'gray',
  borderWidth: 1,
  borderRadius: 10,
  padding: 10,
  margin: 10,
};

export const StyledTextInput = styled(
  RN.TextInput,
  'font-normal text-black rounded-2xl border-gray-300 border-2 py-2 px-4 my-2'
);

export const StyledMultilineInput = (props) => (
  <StyledTextInput multiline={true} numberOfLines={4} {...props} />
);

export interface GenericInputProps<T> {
  value: T;
  onChange: (value: T) => void;
}
