import React from 'react';
import { ErrorView, CenterViewSpinner, BackgroundView } from '../../design/view';
import { StyleSheet, View } from 'react-native';
import { Text } from '../../design/typography';
import { usePatientStatusQuery } from '../../generated/graphql-urql-reacthooks';

const PatientStatus = ({ patientId }: { patientId: number }): JSX.Element => {
  const [result, reexecuteQuery] = usePatientStatusQuery({
    variables: { id: patientId },
    requestPolicy: 'cache-and-network',
  });
  const { data, error, fetching } = result;
  const patient = data && data.patient;
  const status = patient && patient.status;
  const activeStatusAction = patient && patient.activeStatusAction;

  return (
    <BackgroundView>
      {fetching && <CenterViewSpinner />}
      {error && <ErrorView />}
      {patient && (
        <Text style={styles.sectionText}>
          Status: {status && status.name ? status.name : 'Not Set'}
        </Text>
      )}
    </BackgroundView>
  );
};

const styles = StyleSheet.create({
  sectionText: {
    alignSelf: 'center',
  },
});

export default PatientStatus;
