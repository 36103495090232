import React from 'react';
import { Button, ScrollView, View, ViewProps } from 'react-native';
import { Text } from '../../design/typography';
import NumericInput from 'react-native-numeric-input';

interface FrequencyEntryProps extends ViewProps {
  timesAWeek: number;
  setTimesAWeek: (timesAWeek: number) => void;
  weeks: number;
  setWeeks: (weeks: number) => void;
}

const FrequencyEntry = ({
  timesAWeek,
  weeks,
  setTimesAWeek,
  setWeeks,
  ...props
}: FrequencyEntryProps): JSX.Element => {
  return (
    <View {...props}>
      <Text>Visits PER WEEK:</Text>
      <NumericInput
        onChange={(value) => {
          setTimesAWeek(value);
        }}
        minValue={0}
        maxValue={7}
        value={timesAWeek}
      />
      <Text>For How Many Weeks?</Text>
      <NumericInput
        onChange={(value) => {
          setWeeks(value);
        }}
        minValue={0}
        maxValue={14}
        value={weeks}
      />
    </View>
  );
};

export default FrequencyEntry;
