import { Pressable, PressableProps, ViewProps } from 'react-native';
import IconTextListItem from './IconTextListItem';
import { AntDesign } from '@expo/vector-icons';
import React from 'react';

interface SelectListItemProps extends PressableProps {
  text: string;
  selected: boolean;
  onPress: () => void;
  style?: ViewProps['style'];
}

const SelectListItem = ({ text, selected, onPress, children, ...rest }: SelectListItemProps) => {
  return (
    <Pressable onPress={onPress} {...rest}>
      <IconTextListItem
        className={'rounded-2xl border-2'}
        headingText={text}
        icon={selected ? <AntDesign name="checkcircleo" size={24} color="black" /> : null}
      />
    </Pressable>
  );
};

export default SelectListItem;
