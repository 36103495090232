import { cacheExchange } from '@urql/exchange-graphcache';
import { authExchange } from '@urql/exchange-auth';
import { makeOperation } from '@urql/core';
import customScalarsExchange from 'urql-custom-scalars-exchange';
import { createClient, dedupExchange, fetchExchange, Provider } from 'urql';
import { useAtom } from 'jotai';
import { userAtom } from '../state';
import { getToken } from './auth/firebase';
import { Platform } from 'react-native';
import { devtoolsExchange } from '@urql/devtools';
import schema from '../generated/schema';

//@ts-ignore
const cache = cacheExchange({ schema });

const scalarsExchange = customScalarsExchange({
  //@ts-ignore
  schema: schema,
  scalars: {
    DateTime(value) {
      return new Date(value);
    },
    Date(value) {
      return new Date(value);
    },
    CustomDateTime(value) {
      return new Date(value);
    },
    CustomDate(value) {
      return new Date(value);
    },
    CustomTime(value) {
      return new Date(value);
    },
  },
});

export default function URQLProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useAtom(userAtom);
  const auth = authExchange({
    addAuthToOperation: ({ authState, operation }) => {
      console.log('adding auth');
      // the token isn't in the auth state, return the operation without changes
      if (!user) {
        console.log('!user');
        return operation;
      }
      console.log('authState');

      // fetchOptions can be a function (See Client API) but you can simplify this based on usage
      const fetchOptions =
        typeof operation.context.fetchOptions === 'function'
          ? operation.context.fetchOptions()
          : operation.context.fetchOptions || {};

      return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            Authorization: authState as string,
          },
        },
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    willAuthError: ({ authState }) => {
      //returns true so that the native firebase getAuth method is always used.
      //otherwise, the token will expire, but not get refreshed, becuase there
      //is a valid token in the auth object. This uses the native firebase token
      //refresh system.
      return true;
    },
    didAuthError: ({ error }) => {
      console.log(error);
      // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
      return error.graphQLErrors.some((e) => e.extensions?.code === 'FORBIDDEN');
    },
    getAuth: async () => {
      // for initial launch, fetch the auth state from storage (local storage, async storage etc)
      console.log('getting auth');
      if (user != null) {
        const token = getToken();
        console.log(token);
        return token;
      }
      return null;
    },
  });

  const client = createClient({
    url: 'https://hasura-vftpbbvs5a-uw.a.run.app/v1/graphql',
    //'http://127.0.0.1:8080/v1/graphql',
    //url: Platform.OS == 'web' ? 'http://127.0.0.1:8000/graphql' : 'http://127.0.0.1:8000/graphql',
    //'https://mobilehrservertesting.loca.lt/graphql',
    //"https://devehrserver-vftpbbvs5a-uw.a.run.app/graphql"
    //url: "https://devehrserver-vftpbbvs5a-uw.a.run.app/graphql",
    exchanges: [devtoolsExchange, dedupExchange, scalarsExchange, cache, auth, fetchExchange],
    requestPolicy: 'cache-and-network',
    //suspense: true,
  });

  return <Provider value={client}>{children}</Provider>;
}
