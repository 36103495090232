import { KeyboardAvoidingView, Platform, TextInput, View, ViewProps } from 'react-native';
import React from 'react';
import { HeaderText, Text } from '../../typography';
import { StepperFormStepType } from './StepperForm';
import { InlineDateInput } from '../../generics/DateTime';
import { GenericInputProps, StyledMultilineInput } from '../../generics/Inputs';

// type for prop defining basic step form steps
interface StepperFormStepProps extends ViewProps {
  title: string;
  header?: string;
  error?: () => string | null;
}
// inherits generic step form props but includes a typed input and onchange function
interface StepperFormStepInputProps<T> extends StepperFormStepProps, GenericInputProps<T> {}

//basic step form step component with header
export const HeaderedStepperFormStep = ({
  title,
  header,
  error = () => null,
  children,
  ...rest
}: StepperFormStepProps): StepperFormStepType => ({
  component: (
    <View {...rest}>
      <HeaderText style={{ alignSelf: 'center' }}>{header ?? title}</HeaderText>
      {children}
    </View>
  ),
  error,
  title,
});

//form step for text display
export const TextDisplayStepperFormStep = ({
  title,
  value,
  ...rest
}: StepperFormStepInputProps<string>): StepperFormStepType =>
  HeaderedStepperFormStep({
    title,
    children: (
      <Text
        style={{
          justifyContent: 'center',
          alignSelf: 'center',
          alignContent: 'center',
          textAlign: 'center',
        }}>
        {value}
      </Text>
    ),
    ...rest,
  });

//form step for date input
export const DateInputStepperFormStep = ({
  title,
  value,
  error,
  onChange,
  children,
  ...rest
}: StepperFormStepInputProps<Date>): StepperFormStepType =>
  HeaderedStepperFormStep({
    title,
    error,
    children: <InlineDateInput value={value} onChange={onChange} />,
    ...rest,
  });

//form step for text input
export const TextInputStepperFormStep = ({
  title,
  value,
  onChange,
  children,
  error,
  ...rest
}: StepperFormStepInputProps<string>): StepperFormStepType =>
  HeaderedStepperFormStep({
    title,
    error,
    children: (
      <KeyboardAvoidingView>
        <StyledMultilineInput
          value={value}
          onChangeText={(value) => onChange(value)}
          autoFocus={true}
        />
      </KeyboardAvoidingView>
    ),
    ...rest,
  });
