import { ActivityIndicator, SafeAreaView, View as ReactNativeView, ViewProps } from 'react-native';
import { styled } from 'nativewind';
import { SafeAreaViewProps } from 'react-native-safe-area-context';
import { Text } from './typography';

export const View = styled(ReactNativeView);

export const BackgroundView = styled(View, 'bg-white');

export const CenterView = styled(SafeAreaView, 'flex-1 items-center justify-center');

export const CenterViewSpinner = ({ children }: SafeAreaViewProps) => {
  return (
    <CenterView>
      <ActivityIndicator size="large" />
      {children}
    </CenterView>
  );
};

interface ErrorViewProps extends ViewProps {
  error?: string;
}
export const ErrorView = (props: ErrorViewProps) => {
  return (
    <View {...props}>
      <Text>ERROR</Text>
      <Text>{props.error}</Text>
    </View>
  );
};
