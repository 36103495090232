import { NavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { useMemo } from 'react';

export function NavigationProvider({ children }: { children: React.ReactNode }) {
  return (
    <NavigationContainer
      linking={useMemo(
        () => ({
          prefixes: [Linking.createURL('/')],
          config: {
            initialRouteName: 'Home',
            screens: {
              Home: '',
              Patient: {
                path: 'patient/:patientId',
                screens: {
                  Demographics: 'demos',
                  'Orders/Requests': 'ordersrequests',
                },
              },
              NewOrder: {
                path: 'requestorder',
                screens: {
                  SimpleFrequencyOrder: 'simplefrequency',
                  DetailedFrequencyOrder: 'detailedfrequency',
                  MiscelaneousOrder: 'miscorder',
                },
              },
            },
          },
        }),
        []
      )}>
      {children}
    </NavigationContainer>
  );
}
