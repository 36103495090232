import React from 'react';
import { ViewProps, View, ScrollView } from 'react-native';
import {
  OrderableOrderType,
  OrderableType,
  OrderOrderableFieldValueType,
  useOrderableOrdersForPatientQuery,
} from '../../../generated/graphql-urql-reacthooks';
import { CenterViewSpinner } from '../../../design/view';
import { HeaderText, Text } from '../../../design/typography';
import FieldValueDisplay from '../../field/FieldValueDisplay';
import OrderableOrderEntry from './OrderableOrderEntry';
import { FieldValueType } from '../../field/FieldValue';

export interface OrderableOrderEntryApprovalDataType {
  existingOrder: OrderableOrderType | null | undefined;
  newOrder: FieldValueType[];
  orderRejected: boolean;
}

interface OrderableOrderEntryApprovalProps extends ViewProps {
  orderable: OrderableType;
  patientId: number;
  entryApproval: OrderableOrderEntryApprovalDataType;
  updateEntryApproval: (value: OrderableOrderEntryApprovalDataType) => void;
}

const OrderableOrderEntryApproval = ({
  orderable,
  patientId,
  entryApproval,
  updateEntryApproval,
  ...props
}: OrderableOrderEntryApprovalProps): JSX.Element => {
  const [results] = useOrderableOrdersForPatientQuery({
    variables: { patientId: patientId, orderableId: orderable.id, active: true },
  });
  const { data, error, fetching } = results;
  const existingOrderableOrders = data?.orderableOrders;
  const existingOrderableOrder = existingOrderableOrders
    ?.filter((order) => order?.orderable?.id === orderable.id)
    .sort((a, b) => {
      if (a?.calculatedEffectiveDate && b?.calculatedEffectiveDate) {
        return a?.calculatedEffectiveDate?.getTime() - b?.calculatedEffectiveDate?.getTime();
      }
      return 0;
    })
    .reverse()[0];

  const orderEntryRequired = () => {
    return true;
    return data && (existingOrderableOrder == null || entryApproval.orderRejected);
  };

  if (fetching) {
    return <CenterViewSpinner />;
  }

  return (
    <View {...props} style={{ flex: 1 }}>
      <View style={{ flexGrow: 0, flexShrink: 0 }}>
        {orderEntryRequired() ? (
          //Order needs to be entered
          <>
            <HeaderText>New Order Required</HeaderText>
            <Text>No order exists for this discipline. Please enter order details below:</Text>
          </>
        ) : (
          //Order already exists
          <>
            <HeaderText style={{ alignSelf: 'center' }}>Existing Order</HeaderText>
            <Text>You will be requesting a frequency for the following order:</Text>
          </>
        )}
      </View>

      <ScrollView
        style={{ marginTop: 10, flexGrow: 1, flexShrink: 1 }}
        contentContainerStyle={{ alignItems: 'center', alignSelf: 'center' }}>
        {orderEntryRequired() ? (
          //Order needs to be entered
          <>
            <Text>Details, details, details</Text>
            <OrderableOrderEntry
              orderable={orderable}
              newOrderFieldValues={entryApproval.newOrder}
              updateNewOrderFieldValues={(newOrder) =>
                updateEntryApproval({ ...entryApproval, newOrder })
              }
            />
          </>
        ) : (
          //Order already exists
          <>
            <Text style={{ textDecorationLine: 'underline' }}>
              {entryApproval.existingOrder?.orderable?.title}:
            </Text>
            {entryApproval.existingOrder?.fieldvaluesOrderableorder?.map((fieldValue) => (
              <FieldValueDisplay
                key={fieldValue.id}
                fieldValue={fieldValue}
                style={{ marginBottom: 5, marginTop: 5 }}
              />
            ))}
          </>
        )}
      </ScrollView>
    </View>
  );
};

export default OrderableOrderEntryApproval;
