import React, { useMemo, useState } from 'react';
import { TextInput, View, ViewProps } from 'react-native';
import { Text } from '../../design/typography';
import { StyledTextInput } from '../../design/generics/Inputs';

import { CheckboxGroup, LabeledCheckbox } from '../../design/generics/CheckboxGroup';
import { ClinicalnoteField, OrderOrderableField } from '../../generated/graphql-urql-reacthooks';

export type FieldValueType = {
  field: OrderOrderableField | ClinicalnoteField;
  value: string | null;
};

interface FieldValueProps extends ViewProps, FieldValueType {
  onChange: (value: string) => void;
}

const FieldValue = ({ field, value, onChange, ...props }: FieldValueProps): JSX.Element => {
  const fieldData = JSON.parse(field.data as string) as {
    fields?: string[][];
  }; //possible type for data when parsed

  return (
    <View {...props}>
      <Text>{field.name}</Text>
      {
        //text field
        field.type == 3 && (
          <StyledTextInput value={value ?? ''} onChangeText={(text) => onChange(text)} editable />
        )
      }

      {
        //multi select group
        field.type == 7 && (
          <CheckboxGroup fields={fieldData.fields ?? []} value={value} onChange={onChange} />
        )
      }
    </View>
  );
};

export default FieldValue;
