import { ViewProps } from 'react-native';
import { ReactElement } from 'react';

interface renderedItems<ItemType> extends ViewProps {
  renderItem: (item: ItemType) => ReactElement;
  items: ItemType[];
}

const renderedItems = <ItemType extends object>({
  items,
  renderItem,
  ...props
}: renderedItems<ItemType>): JSX.Element[] => {
  return items.map((item) => renderItem(item));
};

export default renderedItems;
