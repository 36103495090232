import React from 'react';
import {
  OrderableOrderType,
  MedicationOrderType,
  FreeTextOrderType,
  useAllPatientOrdersQuery,
} from '../../generated/graphql-urql-reacthooks';
import { BackgroundView, CenterViewSpinner, ErrorView } from '../../design/view';
import { gray, green, orange } from '../../design/colors';
import OrdersSectionList, { OrdersSectionType } from '../orders/OrdersSectionList';
import {
  isOrderActive,
  isOrderOpen,
  isOrderExpired,
  isOrderHistorical,
} from '../orders/neworderutils';
import { FloatingAction } from 'react-native-floating-action';
import { Platform, View } from 'react-native';
import { StyledButton } from '../../design/buttons';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { Text } from '../../design/typography';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../navigation';

const PatientOrders = ({ patientId }: { patientId: number }): JSX.Element => {
  const rootNavigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const navigateToNewOrder = () => {
    rootNavigation.navigate('NewOrder', { patientId: patientId });
  };
  const nav = useNavigation();
  const [result, reexecuteQuery] = useAllPatientOrdersQuery({
    variables: { patientId: 177, details: false },
  });
  const { data, error, fetching, operation } = result;
  type AllOrdersType = OrderableOrderType | MedicationOrderType | FreeTextOrderType;
  let allOrders: AllOrdersType[] = [];
  if (error) {
    return <Text>{error.message}</Text>;
  }

  if (data) {
    const orderableOrders = data.orderableOrders?.reverse() as OrderableOrderType[];
    const medicationOrders = data.medicationOrders?.reverse() as MedicationOrderType[];
    const freeTextOrders = data.freeTextOrders?.reverse() as FreeTextOrderType[];
    allOrders = [...orderableOrders, ...medicationOrders, ...freeTextOrders];

    const ordersSections: OrdersSectionType[] = [
      {
        title: 'Active Orders',
        data: allOrders.filter((value) => isOrderActive(value ?? {})),
        colorFamily: green,
      },
      {
        title: 'Open Requests',
        data: allOrders.filter((value) => isOrderOpen(value ?? {})),
        colorFamily: orange,
      },
      {
        title: 'History',
        data: allOrders.filter((value) => isOrderHistorical(value ?? {})),
        colorFamily: gray,
      },
    ];
    const actions: { text: string; icon?: any; name: string; position: number }[] = [
      {
        text: 'New Order',
        name: 'Order',
        position: 1,
        icon: <AntDesign name="home" size={24} color="white" />,
      },
    ];
    if (Platform.OS === 'web') {
      return (
        <div
          className={'bg-white'}
          style={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
          <div style={{ flex: 1, overflow: 'scroll' }}>
            <OrdersSectionList ordersSections={ordersSections} />
          </div>
        </div>
      );
    }
    return (
      <BackgroundView>
        <OrdersSectionList ordersSections={ordersSections} />
        <FloatingAction
          actions={actions}
          onPressItem={(name) => {
            console.log(`selected button: ${name ?? 'none'}`);
            navigateToNewOrder();
          }}
        />
      </BackgroundView>
    );
  }
  return <CenterViewSpinner />;
};

export default PatientOrders;
