import React, { useEffect, useState } from 'react';
import { Button, Platform, Text, View } from 'react-native';
import { useAtom } from 'jotai';
import { authInitializingAtom, userAtom } from './state';
import auth from '@react-native-firebase/auth';
import { getAuth, getUser, loginWithEmailPassword } from './provider/auth/firebase';
import { useGetPatientQuery } from './generated/graphql-urql-reacthooks';
import { NativeNavigation } from './navigation';

export default function Main() {
  const [authInitializing] = useAtom(authInitializingAtom);
  const [user, setUser] = useAtom(userAtom);
  const [result] = useGetPatientQuery({ variables: { id: 177, details: false } });
  const { fetching, data, error } = result;

  if (authInitializing) return null;
  return <NativeNavigation />;

  if (!user) {
    return (
      <View className="flex-1 items-center justify-center">
        <Text>Login</Text>
        <Button
          title={'Login'}
          onPress={() => loginWithEmailPassword('egreenfeld@azswa.org', '!Egig12345')}
        />
      </View>
    );
  }

  return (
    <View className=" flex-1 items-center justify-center bg-pink-400">
      <Text className={'text-2xl'}>
        Open up App.tfads st to start working o adsf sn y asdf our app!!
      </Text>
      <Button title={'Logout'} onPress={() => getAuth().signOut()} />
      <Text>{JSON.stringify(data)}</Text>
    </View>
  );
}
