import React from 'react';
import PatientHeader from './PatientHeader';
import { createDrawerNavigator } from '@react-navigation/drawer';
import PatientStatus from './PatientStatus';
import { View, Platform, SafeAreaView } from 'react-native';
import { StyledButton } from '../../design/buttons';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { Text } from '../../design/typography';
import PatientDemoScreen from './demographics/PatientDemoScreen';
//import PatientOrdersView from './PatientOrdersScreen';
import { createStackNavigator } from '@react-navigation/stack';
import { RouteProp, useRoute } from '@react-navigation/native';
import { RootStackParamList } from '../../navigation';
import PatientOrdersScreen from './PatientOrdersScreen';

const Tab = createMaterialTopTabNavigator();
const screens = createStackNavigator();

const Patient = () => {
  const patientId = useRoute<RouteProp<RootStackParamList, 'Patient'>>().params.patientId;
  console.log('patientId', patientId);
  return (
    <SafeAreaView
      style={{
        flexDirection: 'column',
        flexGrow: 1,
      }}>
      <View>
        <PatientHeader patientId={patientId} />
        <PatientStatus patientId={patientId} />
      </View>
      <Tab.Navigator
        screenOptions={{
          swipeEnabled: Platform.OS != 'web',
        }}>
        <Tab.Screen name={'Demographics'}>
          {(props) => <PatientDemoScreen {...props} patientId={patientId} />}
        </Tab.Screen>

        <Tab.Screen name={'Orders/Requests'}>
          {(props) => <PatientOrdersScreen {...props} patientId={patientId} />}
        </Tab.Screen>
      </Tab.Navigator>
    </SafeAreaView>
  );
};

export default Patient;
