//import {DocumentPendingReviewType, PatientType, TicketThreadType} from "@util/generated/types_only";

export function mergePatientHistory(patient:any) : any{

interface HistoryItem{
item : any,//(DocumentType | DocumentPendingReviewType | TicketThreadType),
timestamp : Date,
title : string,
}

const documents = patient.documentsPatient as any[];
const dpr = patient.documentsPendingReviewGuessedPatient;
const tickets = patient.ticketThreadsPatient;
const combined: HistoryItem[]  = [];
documents&&combined.push(...documents.map((document)=>({
	item:document,
	timestamp: new Date(),
	title:"hi",
} as HistoryItem)));
return combined;
}

export function getAge(dob:Date) : number{
	const ageDifMs = Date.now() - dob.getTime();
	const ageDate = new Date(ageDifMs); // miliseconds from epoch
	return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function getGenderText(gender:number):string{
	if(gender == 1)
	{
		return "M";
	}
	else if(gender == 2){
		return "F";
	}
	return "";
}
