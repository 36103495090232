import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { HomeScreen } from '../features/home/screen';
import { UserDetailScreen } from '../features/users/screen';
import PatientScreen from '../features/patient/PatientScreen';
import { Platform } from 'react-native';
import NewOrderRequestScreen from '../features/orders/request/NewOrderRequestScreen';
import DetailedFrequencyOrderRequestScreen from '../features/orders/request/DetailedFrequencyOrderRequestScreen';
import SimpleFrequencyOrderRequestScreen from '../features/orders/request/SimpleFrequencyOrderRequestScreen';
import MiscelaneousOrderRequestScreen from '../features/orders/request/MiscelaneousOrderRequestScreen';

export type RootStackParamList = {
  Home: undefined;
  Patient: {
    patientId: number;
  };
  NewOrder: {
    patientId: number;
  };
  SimpleFrequencyOrder: undefined;
  DetailedFrequencyOrder: undefined;
  MiscelaneousOrder: undefined;
};

const Stack = createNativeStackNavigator<RootStackParamList>();

export function NativeNavigation() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: Platform.OS != 'web' }}>
      <Stack.Screen name="Home" component={HomeScreen} />
      <Stack.Screen name="Patient" component={PatientScreen} />
      <Stack.Screen name="NewOrder" component={NewOrderRequestScreen} />
      <Stack.Group screenOptions={{ headerBackTitle: 'Cancel' }}>
        <Stack.Screen name="SimpleFrequencyOrder" component={SimpleFrequencyOrderRequestScreen} />
        <Stack.Screen
          name="DetailedFrequencyOrder"
          component={DetailedFrequencyOrderRequestScreen}
        />
        <Stack.Screen name="MiscelaneousOrder" component={MiscelaneousOrderRequestScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}
