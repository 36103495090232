import { useLinkProps } from '@react-navigation/native';
import {
  Platform,
  View,
  TouchableOpacity,
  Text,
  Pressable,
  ViewProps,
  PressableProps,
  ViewStyle,
} from 'react-native';
import * as React from 'react';
import { ReactChild, ReactChildren, ReactElement } from 'react';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import { styled } from 'nativewind';
// ...

interface ViewPressableProps extends ViewProps {
  to: To<{ [p: string]: any }, string | number>;
  action?:
    | Readonly<{
        type: string;
        payload?: object | undefined;
        source?: string | undefined;
        target?: string | undefined;
      }>
    | undefined;
  reactToHover?: boolean;
}

export const ViewPressable = ({
  to,
  action,
  children,
  reactToHover,
  ...rest
}: ViewPressableProps) => {
  const { onPress, ...props } = useLinkProps({ to, action });

  const [isHovered, setIsHovered] = React.useState(false);

  if (Platform.OS === 'web') {
    // It's important to use a `View` or `Text` on web instead of `TouchableX`
    // Otherwise React Native for Web omits the `onClick` prop that's passed
    // You'll also need to pass `onPress` as `onClick` to the `View`
    // You can add hover effects using `onMouseEnter` and `onMouseLeave`
    return (
      <View
        // @ts-ignore due to web browser support
        onClick={onPress}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={reactToHover ? { opacity: isHovered ? 0.5 : 1 } : {}}
        {...props}
        {...rest}>
        {children}
      </View>
    );
  }

  return (
    <Pressable onPress={onPress} {...props} {...rest}>
      {children}
    </Pressable>
  );
};

//same as above, except the destination is optional. Can be used if conditionally rendering pressable.
export interface ViewPressablePropsToOptional extends Omit<ViewPressableProps, 'to'> {
  to?: To<{ [p: string]: any }, string | number>;
}

export const ViewPressableToOptional = ({ to, ...rest }: ViewPressablePropsToOptional) => {
  if (to) {
    return <ViewPressable to={to} {...rest} />;
  }
  return <View {...rest} />;
};

export const OutlineRoundedViewPressable = styled(
  ViewPressable,
  'borderRadius-10 border-1 border-black'
);
