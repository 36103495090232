import {StepIndicatorProps} from "react-native-step-indicator/src/types";
import {Button, View, ViewProps, Text, KeyboardAvoidingView} from "react-native";
import React from "react";
import StepIndicator from "react-native-step-indicator/src";
import {StyledScrollView} from "@components/generics/views/StyledViews";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";


export interface StepperFormStepType {
  title: string;
  component: React.ReactElement;
  error?: () => string | null;
}

interface StepperFormProps extends ViewProps, StepIndicatorProps{
  steps: StepperFormStepType[];
  onFinish: () => void;
}


export const StepperForm = ({steps, onFinish, style, ...rest}:StepperFormProps) : JSX.Element => {
	const labels = steps.map(({title}) => title);
	const [step, setStep] = React.useState(0);
	const atEnd = () => step === steps.length - 1;
	const atStart = () => step === 0;
	const currentStep = () => steps[step];

	const onNext = () => {
		if(atEnd()){
			onFinish();
		} else{
			const errorcheck = currentStep().error;
			if((errorcheck && errorcheck() == null) || errorcheck == null){
				setStep(step + 1);
			}
			else{
				alert(errorcheck());
			}
		}
	};

	return<View style={[{}, style]} {...rest}>
		<View style={{flexGrow:0}}>
			<StepIndicator currentPosition={step} labels={labels} stepCount={steps.length}/>

		</View>
		<KeyboardAvoidingView behavior={"padding"} keyboardVerticalOffset={140} style={{flex:1}}>

			<View style={{flexGrow:1, flexShrink:1}}>
				{steps[step].component}
			</View>

			<View style={{flexGrow:0, flexShrink:0}}>
				<View style={{display:"flex", flexDirection:"row", justifyContent:"space-around"}}>
					{!atStart()&&<Button title={"Back"} onPress={()=>setStep(step-1)}/>}
					<Button title={atEnd()?"Finish":"Next"} onPress={onNext}/>
				</View>
			</View>

		</KeyboardAvoidingView>

	</View>;
};
