// @ts-nocheck
/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY DIRECTLY
 */

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _varchar: any;
  date: any;
  float8: any;
  interval: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  time: any;
  timestamptz: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8ComparisonExp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export type IntervalComparisonExp = {
  _eq?: InputMaybe<Scalars['interval']>;
  _gt?: InputMaybe<Scalars['interval']>;
  _gte?: InputMaybe<Scalars['interval']>;
  _in?: InputMaybe<Array<Scalars['interval']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['interval']>;
  _lte?: InputMaybe<Scalars['interval']>;
  _neq?: InputMaybe<Scalars['interval']>;
  _nin?: InputMaybe<Array<Scalars['interval']>>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST'
}

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type SmallintComparisonExp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type TimeComparisonExp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "_varchar". All fields are combined with logical 'AND'. */
export type _VarcharComparisonExp = {
  _eq?: InputMaybe<Scalars['_varchar']>;
  _gt?: InputMaybe<Scalars['_varchar']>;
  _gte?: InputMaybe<Scalars['_varchar']>;
  _in?: InputMaybe<Array<Scalars['_varchar']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_varchar']>;
  _lte?: InputMaybe<Scalars['_varchar']>;
  _neq?: InputMaybe<Scalars['_varchar']>;
  _nin?: InputMaybe<Array<Scalars['_varchar']>>;
};

/** columns and relationships of "server_address" */
export type Address = {
  __typename?: 'address';
  city: Scalars['String'];
  id: Scalars['Int'];
  latitude?: Maybe<Scalars['numeric']>;
  line1: Scalars['String'];
  line2: Scalars['String'];
  longitude?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  patient?: Maybe<Patient>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

/** aggregated selection of "server_address" */
export type AddressAggregate = {
  __typename?: 'addressAggregate';
  aggregate?: Maybe<AddressAggregateFields>;
  nodes: Array<Address>;
};

/** aggregate fields of "server_address" */
export type AddressAggregateFields = {
  __typename?: 'addressAggregateFields';
  avg?: Maybe<AddressAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AddressMaxFields>;
  min?: Maybe<AddressMinFields>;
  stddev?: Maybe<AddressStddevFields>;
  stddevPop?: Maybe<AddressStddev_PopFields>;
  stddevSamp?: Maybe<AddressStddev_SampFields>;
  sum?: Maybe<AddressSumFields>;
  varPop?: Maybe<AddressVar_PopFields>;
  varSamp?: Maybe<AddressVar_SampFields>;
  variance?: Maybe<AddressVarianceFields>;
};


/** aggregate fields of "server_address" */
export type AddressAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AddressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AddressAvgFields = {
  __typename?: 'addressAvgFields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_address". All fields are combined with a logical 'AND'. */
export type AddressBoolExp = {
  _and?: InputMaybe<Array<AddressBoolExp>>;
  _not?: InputMaybe<AddressBoolExp>;
  _or?: InputMaybe<Array<AddressBoolExp>>;
  city?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  latitude?: InputMaybe<NumericComparisonExp>;
  line1?: InputMaybe<StringComparisonExp>;
  line2?: InputMaybe<StringComparisonExp>;
  longitude?: InputMaybe<NumericComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  state?: InputMaybe<StringComparisonExp>;
  zipCode?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_address" */
export enum AddressConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerAddressPkey = 'server_address_pkey'
}

/** input type for incrementing numeric columns in table "server_address" */
export type AddressIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "server_address" */
export type AddressInsertInput = {
  city?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AddressMaxFields = {
  __typename?: 'addressMaxFields';
  city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['numeric']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['numeric']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AddressMinFields = {
  __typename?: 'addressMinFields';
  city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['numeric']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['numeric']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_address" */
export type AddressMutationResponse = {
  __typename?: 'addressMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "server_address" */
export type AddressObjRelInsertInput = {
  data: AddressInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AddressOnConflict>;
};

/** on_conflict condition type for table "server_address" */
export type AddressOnConflict = {
  constraint: AddressConstraint;
  update_columns?: Array<AddressUpdateColumn>;
  where?: InputMaybe<AddressBoolExp>;
};

/** Ordering options when selecting data from "server_address". */
export type AddressOrderBy = {
  city?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  line1?: InputMaybe<OrderBy>;
  line2?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  state?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_address */
export type AddressPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_address" */
export enum AddressSelectColumn {
  /** column name */
  City = 'city',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Line1 = 'line1',
  /** column name */
  Line2 = 'line2',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  State = 'state',
  /** column name */
  ZipCode = 'zipCode'
}

/** input type for updating data in table "server_address" */
export type AddressSetInput = {
  city?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type AddressStddevFields = {
  __typename?: 'addressStddevFields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AddressStddev_PopFields = {
  __typename?: 'addressStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AddressStddev_SampFields = {
  __typename?: 'addressStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AddressSumFields = {
  __typename?: 'addressSumFields';
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
};

/** update columns of table "server_address" */
export enum AddressUpdateColumn {
  /** column name */
  City = 'city',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Line1 = 'line1',
  /** column name */
  Line2 = 'line2',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  State = 'state',
  /** column name */
  ZipCode = 'zipCode'
}

export type AddressUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AddressIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AddressSetInput>;
  where: AddressBoolExp;
};

/** aggregate var_pop on columns */
export type AddressVar_PopFields = {
  __typename?: 'addressVar_popFields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AddressVar_SampFields = {
  __typename?: 'addressVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AddressVarianceFields = {
  __typename?: 'addressVarianceFields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "address" */
export type Address_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Address_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_StreamCursorValueInput = {
  city?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "server_agency" */
export type Agency = {
  __typename?: 'agency';
  /** An array relationship */
  agencyLocations: Array<AgencyLocation>;
  /** An aggregate relationship */
  agencyLocationsAggregate: AgencyLocationAggregate;
  /** An array relationship */
  agencyServices: Array<AgencyServices>;
  /** An aggregate relationship */
  agencyServicesAggregate: AgencyServicesAggregate;
  /** An array relationship */
  agencyconnections: Array<Agencyconnection>;
  /** An aggregate relationship */
  agencyconnectionsAggregate: AgencyconnectionAggregate;
  /** An array relationship */
  agencyjoinrequests: Array<Agencyjoinrequest>;
  /** An aggregate relationship */
  agencyjoinrequestsAggregate: AgencyjoinrequestAggregate;
  category: Scalars['smallint'];
  description: Scalars['String'];
  /** An array relationship */
  destinedBaseOrders: Array<Baseorder>;
  /** An aggregate relationship */
  destinedBaseOrdersAggregate: BaseorderAggregate;
  fax?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  intakeSubmissions: Array<PatientIntake>;
  /** An aggregate relationship */
  intakeSubmissionsAggregate: PatientIntakeAggregate;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  notificationMode: Scalars['smallint'];
  /** An array relationship */
  payers: Array<AgencyPayers>;
  /** An aggregate relationship */
  payersAggregate: AgencyPayersAggregate;
  phone?: Maybe<Scalars['String']>;
  /** An array relationship */
  suppliesOrderables: Array<OrderableSupplier>;
  /** An aggregate relationship */
  suppliesOrderablesAggregate: OrderableSupplierAggregate;
};


/** columns and relationships of "server_agency" */
export type AgencyAgencyLocationsArgs = {
  distinctOn?: InputMaybe<Array<AgencyLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyLocationOrderBy>>;
  where?: InputMaybe<AgencyLocationBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyAgencyLocationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyLocationOrderBy>>;
  where?: InputMaybe<AgencyLocationBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyAgencyServicesArgs = {
  distinctOn?: InputMaybe<Array<AgencyServicesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyServicesOrderBy>>;
  where?: InputMaybe<AgencyServicesBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyAgencyServicesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyServicesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyServicesOrderBy>>;
  where?: InputMaybe<AgencyServicesBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyAgencyconnectionsArgs = {
  distinctOn?: InputMaybe<Array<AgencyconnectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyconnectionOrderBy>>;
  where?: InputMaybe<AgencyconnectionBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyAgencyconnectionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyconnectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyconnectionOrderBy>>;
  where?: InputMaybe<AgencyconnectionBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyAgencyjoinrequestsArgs = {
  distinctOn?: InputMaybe<Array<AgencyjoinrequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyjoinrequestOrderBy>>;
  where?: InputMaybe<AgencyjoinrequestBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyAgencyjoinrequestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyjoinrequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyjoinrequestOrderBy>>;
  where?: InputMaybe<AgencyjoinrequestBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyDestinedBaseOrdersArgs = {
  distinctOn?: InputMaybe<Array<BaseorderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseorderOrderBy>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyDestinedBaseOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BaseorderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseorderOrderBy>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyIntakeSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeOrderBy>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyIntakeSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeOrderBy>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyPayersArgs = {
  distinctOn?: InputMaybe<Array<AgencyPayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyPayersOrderBy>>;
  where?: InputMaybe<AgencyPayersBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencyPayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyPayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyPayersOrderBy>>;
  where?: InputMaybe<AgencyPayersBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencySuppliesOrderablesArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierOrderBy>>;
  where?: InputMaybe<OrderableSupplierBoolExp>;
};


/** columns and relationships of "server_agency" */
export type AgencySuppliesOrderablesAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierOrderBy>>;
  where?: InputMaybe<OrderableSupplierBoolExp>;
};

/** aggregated selection of "server_agency" */
export type AgencyAggregate = {
  __typename?: 'agencyAggregate';
  aggregate?: Maybe<AgencyAggregateFields>;
  nodes: Array<Agency>;
};

/** aggregate fields of "server_agency" */
export type AgencyAggregateFields = {
  __typename?: 'agencyAggregateFields';
  avg?: Maybe<AgencyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AgencyMaxFields>;
  min?: Maybe<AgencyMinFields>;
  stddev?: Maybe<AgencyStddevFields>;
  stddevPop?: Maybe<AgencyStddev_PopFields>;
  stddevSamp?: Maybe<AgencyStddev_SampFields>;
  sum?: Maybe<AgencySumFields>;
  varPop?: Maybe<AgencyVar_PopFields>;
  varSamp?: Maybe<AgencyVar_SampFields>;
  variance?: Maybe<AgencyVarianceFields>;
};


/** aggregate fields of "server_agency" */
export type AgencyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AgencySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AgencyAvgFields = {
  __typename?: 'agencyAvgFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  notificationMode?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_agency". All fields are combined with a logical 'AND'. */
export type AgencyBoolExp = {
  _and?: InputMaybe<Array<AgencyBoolExp>>;
  _not?: InputMaybe<AgencyBoolExp>;
  _or?: InputMaybe<Array<AgencyBoolExp>>;
  agencyLocations?: InputMaybe<AgencyLocationBoolExp>;
  agencyServices?: InputMaybe<AgencyServicesBoolExp>;
  agencyconnections?: InputMaybe<AgencyconnectionBoolExp>;
  agencyjoinrequests?: InputMaybe<AgencyjoinrequestBoolExp>;
  category?: InputMaybe<SmallintComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  destinedBaseOrders?: InputMaybe<BaseorderBoolExp>;
  fax?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  intakeSubmissions?: InputMaybe<PatientIntakeBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  notificationMode?: InputMaybe<SmallintComparisonExp>;
  payers?: InputMaybe<AgencyPayersBoolExp>;
  phone?: InputMaybe<StringComparisonExp>;
  suppliesOrderables?: InputMaybe<OrderableSupplierBoolExp>;
};

/** unique or primary key constraints on table "server_agency" */
export enum AgencyConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerAgencyPkey = 'server_agency_pkey'
}

/** input type for incrementing numeric columns in table "server_agency" */
export type AgencyIncInput = {
  category?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  notificationMode?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_agency" */
export type AgencyInsertInput = {
  agencyLocations?: InputMaybe<AgencyLocationArrRelInsertInput>;
  agencyServices?: InputMaybe<AgencyServicesArrRelInsertInput>;
  agencyconnections?: InputMaybe<AgencyconnectionArrRelInsertInput>;
  agencyjoinrequests?: InputMaybe<AgencyjoinrequestArrRelInsertInput>;
  category?: InputMaybe<Scalars['smallint']>;
  description?: InputMaybe<Scalars['String']>;
  destinedBaseOrders?: InputMaybe<BaseorderArrRelInsertInput>;
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  intakeSubmissions?: InputMaybe<PatientIntakeArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  notificationMode?: InputMaybe<Scalars['smallint']>;
  payers?: InputMaybe<AgencyPayersArrRelInsertInput>;
  phone?: InputMaybe<Scalars['String']>;
  suppliesOrderables?: InputMaybe<OrderableSupplierArrRelInsertInput>;
};

/** columns and relationships of "server_agencylocation" */
export type AgencyLocation = {
  __typename?: 'agencyLocation';
  address?: Maybe<Scalars['String']>;
  /** An object relationship */
  agency: Agency;
  agencyId: Scalars['Int'];
  city?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  mobile: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['smallint']>;
  /** An array relationship */
  referralsToLocation: Array<Referral>;
  /** An aggregate relationship */
  referralsToLocationAggregate: ReferralAggregate;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};


/** columns and relationships of "server_agencylocation" */
export type AgencyLocationReferralsToLocationArgs = {
  distinctOn?: InputMaybe<Array<ReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralOrderBy>>;
  where?: InputMaybe<ReferralBoolExp>;
};


/** columns and relationships of "server_agencylocation" */
export type AgencyLocationReferralsToLocationAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralOrderBy>>;
  where?: InputMaybe<ReferralBoolExp>;
};

/** aggregated selection of "server_agencylocation" */
export type AgencyLocationAggregate = {
  __typename?: 'agencyLocationAggregate';
  aggregate?: Maybe<AgencyLocationAggregateFields>;
  nodes: Array<AgencyLocation>;
};

/** aggregate fields of "server_agencylocation" */
export type AgencyLocationAggregateFields = {
  __typename?: 'agencyLocationAggregateFields';
  avg?: Maybe<AgencyLocationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AgencyLocationMaxFields>;
  min?: Maybe<AgencyLocationMinFields>;
  stddev?: Maybe<AgencyLocationStddevFields>;
  stddevPop?: Maybe<AgencyLocationStddev_PopFields>;
  stddevSamp?: Maybe<AgencyLocationStddev_SampFields>;
  sum?: Maybe<AgencyLocationSumFields>;
  varPop?: Maybe<AgencyLocationVar_PopFields>;
  varSamp?: Maybe<AgencyLocationVar_SampFields>;
  variance?: Maybe<AgencyLocationVarianceFields>;
};


/** aggregate fields of "server_agencylocation" */
export type AgencyLocationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AgencyLocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_agencylocation" */
export type AgencyLocationAggregateOrderBy = {
  avg?: InputMaybe<AgencyLocation_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AgencyLocation_Max_Order_By>;
  min?: InputMaybe<AgencyLocation_Min_Order_By>;
  stddev?: InputMaybe<AgencyLocation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AgencyLocation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AgencyLocation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AgencyLocation_Sum_Order_By>;
  var_pop?: InputMaybe<AgencyLocation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AgencyLocation_Var_Samp_Order_By>;
  variance?: InputMaybe<AgencyLocation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_agencylocation" */
export type AgencyLocationArrRelInsertInput = {
  data: Array<AgencyLocationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AgencyLocationOnConflict>;
};

/** aggregate avg on columns */
export type AgencyLocationAvgFields = {
  __typename?: 'agencyLocationAvgFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_agencylocation". All fields are combined with a logical 'AND'. */
export type AgencyLocationBoolExp = {
  _and?: InputMaybe<Array<AgencyLocationBoolExp>>;
  _not?: InputMaybe<AgencyLocationBoolExp>;
  _or?: InputMaybe<Array<AgencyLocationBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  agency?: InputMaybe<AgencyBoolExp>;
  agencyId?: InputMaybe<IntComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  fax?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  latitude?: InputMaybe<NumericComparisonExp>;
  longitude?: InputMaybe<NumericComparisonExp>;
  mobile?: InputMaybe<BooleanComparisonExp>;
  phone?: InputMaybe<StringComparisonExp>;
  radius?: InputMaybe<SmallintComparisonExp>;
  referralsToLocation?: InputMaybe<ReferralBoolExp>;
  state?: InputMaybe<StringComparisonExp>;
  zipCode?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_agencylocation" */
export enum AgencyLocationConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerAgencylocationPkey = 'server_agencylocation_pkey'
}

/** input type for incrementing numeric columns in table "server_agencylocation" */
export type AgencyLocationIncInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  radius?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_agencylocation" */
export type AgencyLocationInsertInput = {
  address?: InputMaybe<Scalars['String']>;
  agency?: InputMaybe<AgencyObjRelInsertInput>;
  agencyId?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  mobile?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  radius?: InputMaybe<Scalars['smallint']>;
  referralsToLocation?: InputMaybe<ReferralArrRelInsertInput>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AgencyLocationMaxFields = {
  __typename?: 'agencyLocationMaxFields';
  address?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  phone?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['smallint']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AgencyLocationMinFields = {
  __typename?: 'agencyLocationMinFields';
  address?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  phone?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['smallint']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_agencylocation" */
export type AgencyLocationMutationResponse = {
  __typename?: 'agencyLocationMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AgencyLocation>;
};

/** input type for inserting object relation for remote table "server_agencylocation" */
export type AgencyLocationObjRelInsertInput = {
  data: AgencyLocationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AgencyLocationOnConflict>;
};

/** on_conflict condition type for table "server_agencylocation" */
export type AgencyLocationOnConflict = {
  constraint: AgencyLocationConstraint;
  update_columns?: Array<AgencyLocationUpdateColumn>;
  where?: InputMaybe<AgencyLocationBoolExp>;
};

/** Ordering options when selecting data from "server_agencylocation". */
export type AgencyLocationOrderBy = {
  address?: InputMaybe<OrderBy>;
  agency?: InputMaybe<AgencyOrderBy>;
  agencyId?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  fax?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  mobile?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  radius?: InputMaybe<OrderBy>;
  referralsToLocationAggregate?: InputMaybe<ReferralAggregateOrderBy>;
  state?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_agencylocation */
export type AgencyLocationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_agencylocation" */
export enum AgencyLocationSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  City = 'city',
  /** column name */
  Fax = 'fax',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Phone = 'phone',
  /** column name */
  Radius = 'radius',
  /** column name */
  State = 'state',
  /** column name */
  ZipCode = 'zipCode'
}

/** input type for updating data in table "server_agencylocation" */
export type AgencyLocationSetInput = {
  address?: InputMaybe<Scalars['String']>;
  agencyId?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  mobile?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  radius?: InputMaybe<Scalars['smallint']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type AgencyLocationStddevFields = {
  __typename?: 'agencyLocationStddevFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AgencyLocationStddev_PopFields = {
  __typename?: 'agencyLocationStddev_popFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AgencyLocationStddev_SampFields = {
  __typename?: 'agencyLocationStddev_sampFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AgencyLocationSumFields = {
  __typename?: 'agencyLocationSumFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  radius?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_agencylocation" */
export enum AgencyLocationUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  City = 'city',
  /** column name */
  Fax = 'fax',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Phone = 'phone',
  /** column name */
  Radius = 'radius',
  /** column name */
  State = 'state',
  /** column name */
  ZipCode = 'zipCode'
}

export type AgencyLocationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AgencyLocationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AgencyLocationSetInput>;
  where: AgencyLocationBoolExp;
};

/** aggregate var_pop on columns */
export type AgencyLocationVar_PopFields = {
  __typename?: 'agencyLocationVar_popFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AgencyLocationVar_SampFields = {
  __typename?: 'agencyLocationVar_sampFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AgencyLocationVarianceFields = {
  __typename?: 'agencyLocationVarianceFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_agencylocation" */
export type AgencyLocation_Avg_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  radius?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_agencylocation" */
export type AgencyLocation_Max_Order_By = {
  address?: InputMaybe<OrderBy>;
  agencyId?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  fax?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  radius?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_agencylocation" */
export type AgencyLocation_Min_Order_By = {
  address?: InputMaybe<OrderBy>;
  agencyId?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  fax?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  radius?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_agencylocation" */
export type AgencyLocation_Stddev_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  radius?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_agencylocation" */
export type AgencyLocation_Stddev_Pop_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  radius?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_agencylocation" */
export type AgencyLocation_Stddev_Samp_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  radius?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "agencyLocation" */
export type AgencyLocation_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AgencyLocation_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AgencyLocation_StreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']>;
  agencyId?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  mobile?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  radius?: InputMaybe<Scalars['smallint']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_agencylocation" */
export type AgencyLocation_Sum_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  radius?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_agencylocation" */
export type AgencyLocation_Var_Pop_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  radius?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_agencylocation" */
export type AgencyLocation_Var_Samp_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  radius?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_agencylocation" */
export type AgencyLocation_Variance_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  radius?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type AgencyMaxFields = {
  __typename?: 'agencyMaxFields';
  category?: Maybe<Scalars['smallint']>;
  description?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notificationMode?: Maybe<Scalars['smallint']>;
  phone?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AgencyMinFields = {
  __typename?: 'agencyMinFields';
  category?: Maybe<Scalars['smallint']>;
  description?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notificationMode?: Maybe<Scalars['smallint']>;
  phone?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_agency" */
export type AgencyMutationResponse = {
  __typename?: 'agencyMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agency>;
};

/** input type for inserting object relation for remote table "server_agency" */
export type AgencyObjRelInsertInput = {
  data: AgencyInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AgencyOnConflict>;
};

/** on_conflict condition type for table "server_agency" */
export type AgencyOnConflict = {
  constraint: AgencyConstraint;
  update_columns?: Array<AgencyUpdateColumn>;
  where?: InputMaybe<AgencyBoolExp>;
};

/** Ordering options when selecting data from "server_agency". */
export type AgencyOrderBy = {
  agencyLocationsAggregate?: InputMaybe<AgencyLocationAggregateOrderBy>;
  agencyServicesAggregate?: InputMaybe<AgencyServicesAggregateOrderBy>;
  agencyconnectionsAggregate?: InputMaybe<AgencyconnectionAggregateOrderBy>;
  agencyjoinrequestsAggregate?: InputMaybe<AgencyjoinrequestAggregateOrderBy>;
  category?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  destinedBaseOrdersAggregate?: InputMaybe<BaseorderAggregateOrderBy>;
  fax?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  intakeSubmissionsAggregate?: InputMaybe<PatientIntakeAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  notificationMode?: InputMaybe<OrderBy>;
  payersAggregate?: InputMaybe<AgencyPayersAggregateOrderBy>;
  phone?: InputMaybe<OrderBy>;
  suppliesOrderablesAggregate?: InputMaybe<OrderableSupplierAggregateOrderBy>;
};

/** columns and relationships of "server_agency_payers" */
export type AgencyPayers = {
  __typename?: 'agencyPayers';
  /** An object relationship */
  agency: Agency;
  agencyId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  payer: Payer;
  payerId: Scalars['Int'];
};

/** aggregated selection of "server_agency_payers" */
export type AgencyPayersAggregate = {
  __typename?: 'agencyPayersAggregate';
  aggregate?: Maybe<AgencyPayersAggregateFields>;
  nodes: Array<AgencyPayers>;
};

/** aggregate fields of "server_agency_payers" */
export type AgencyPayersAggregateFields = {
  __typename?: 'agencyPayersAggregateFields';
  avg?: Maybe<AgencyPayersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AgencyPayersMaxFields>;
  min?: Maybe<AgencyPayersMinFields>;
  stddev?: Maybe<AgencyPayersStddevFields>;
  stddevPop?: Maybe<AgencyPayersStddev_PopFields>;
  stddevSamp?: Maybe<AgencyPayersStddev_SampFields>;
  sum?: Maybe<AgencyPayersSumFields>;
  varPop?: Maybe<AgencyPayersVar_PopFields>;
  varSamp?: Maybe<AgencyPayersVar_SampFields>;
  variance?: Maybe<AgencyPayersVarianceFields>;
};


/** aggregate fields of "server_agency_payers" */
export type AgencyPayersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AgencyPayersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_agency_payers" */
export type AgencyPayersAggregateOrderBy = {
  avg?: InputMaybe<AgencyPayers_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AgencyPayers_Max_Order_By>;
  min?: InputMaybe<AgencyPayers_Min_Order_By>;
  stddev?: InputMaybe<AgencyPayers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AgencyPayers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AgencyPayers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AgencyPayers_Sum_Order_By>;
  var_pop?: InputMaybe<AgencyPayers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AgencyPayers_Var_Samp_Order_By>;
  variance?: InputMaybe<AgencyPayers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_agency_payers" */
export type AgencyPayersArrRelInsertInput = {
  data: Array<AgencyPayersInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AgencyPayersOnConflict>;
};

/** aggregate avg on columns */
export type AgencyPayersAvgFields = {
  __typename?: 'agencyPayersAvgFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_agency_payers". All fields are combined with a logical 'AND'. */
export type AgencyPayersBoolExp = {
  _and?: InputMaybe<Array<AgencyPayersBoolExp>>;
  _not?: InputMaybe<AgencyPayersBoolExp>;
  _or?: InputMaybe<Array<AgencyPayersBoolExp>>;
  agency?: InputMaybe<AgencyBoolExp>;
  agencyId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  payer?: InputMaybe<PayerBoolExp>;
  payerId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_agency_payers" */
export enum AgencyPayersConstraint {
  /** unique or primary key constraint on columns "payer_id", "agency_id" */
  ServerAgencyPayersAgencyIdPayerId_33039c21Uniq = 'server_agency_payers_agency_id_payer_id_33039c21_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerAgencyPayersPkey = 'server_agency_payers_pkey'
}

/** input type for incrementing numeric columns in table "server_agency_payers" */
export type AgencyPayersIncInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  payerId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_agency_payers" */
export type AgencyPayersInsertInput = {
  agency?: InputMaybe<AgencyObjRelInsertInput>;
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  payer?: InputMaybe<PayerObjRelInsertInput>;
  payerId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type AgencyPayersMaxFields = {
  __typename?: 'agencyPayersMaxFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  payerId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type AgencyPayersMinFields = {
  __typename?: 'agencyPayersMinFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  payerId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_agency_payers" */
export type AgencyPayersMutationResponse = {
  __typename?: 'agencyPayersMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AgencyPayers>;
};

/** on_conflict condition type for table "server_agency_payers" */
export type AgencyPayersOnConflict = {
  constraint: AgencyPayersConstraint;
  update_columns?: Array<AgencyPayersUpdateColumn>;
  where?: InputMaybe<AgencyPayersBoolExp>;
};

/** Ordering options when selecting data from "server_agency_payers". */
export type AgencyPayersOrderBy = {
  agency?: InputMaybe<AgencyOrderBy>;
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payer?: InputMaybe<PayerOrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_agency_payers */
export type AgencyPayersPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_agency_payers" */
export enum AgencyPayersSelectColumn {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  Id = 'id',
  /** column name */
  PayerId = 'payerId'
}

/** input type for updating data in table "server_agency_payers" */
export type AgencyPayersSetInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  payerId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AgencyPayersStddevFields = {
  __typename?: 'agencyPayersStddevFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AgencyPayersStddev_PopFields = {
  __typename?: 'agencyPayersStddev_popFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AgencyPayersStddev_SampFields = {
  __typename?: 'agencyPayersStddev_sampFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AgencyPayersSumFields = {
  __typename?: 'agencyPayersSumFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  payerId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_agency_payers" */
export enum AgencyPayersUpdateColumn {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  Id = 'id',
  /** column name */
  PayerId = 'payerId'
}

export type AgencyPayersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AgencyPayersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AgencyPayersSetInput>;
  where: AgencyPayersBoolExp;
};

/** aggregate var_pop on columns */
export type AgencyPayersVar_PopFields = {
  __typename?: 'agencyPayersVar_popFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AgencyPayersVar_SampFields = {
  __typename?: 'agencyPayersVar_sampFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AgencyPayersVarianceFields = {
  __typename?: 'agencyPayersVarianceFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_agency_payers" */
export type AgencyPayers_Avg_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_agency_payers" */
export type AgencyPayers_Max_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_agency_payers" */
export type AgencyPayers_Min_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_agency_payers" */
export type AgencyPayers_Stddev_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_agency_payers" */
export type AgencyPayers_Stddev_Pop_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_agency_payers" */
export type AgencyPayers_Stddev_Samp_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "agencyPayers" */
export type AgencyPayers_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AgencyPayers_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AgencyPayers_StreamCursorValueInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  payerId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_agency_payers" */
export type AgencyPayers_Sum_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_agency_payers" */
export type AgencyPayers_Var_Pop_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_agency_payers" */
export type AgencyPayers_Var_Samp_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_agency_payers" */
export type AgencyPayers_Variance_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_agency */
export type AgencyPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_agency" */
export enum AgencySelectColumn {
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description',
  /** column name */
  Fax = 'fax',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  NotificationMode = 'notificationMode',
  /** column name */
  Phone = 'phone'
}

/** columns and relationships of "server_agency_services" */
export type AgencyServices = {
  __typename?: 'agencyServices';
  /** An object relationship */
  agency: Agency;
  agencyId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  serviceType: ServiceType;
  servicetypeId: Scalars['Int'];
};

/** aggregated selection of "server_agency_services" */
export type AgencyServicesAggregate = {
  __typename?: 'agencyServicesAggregate';
  aggregate?: Maybe<AgencyServicesAggregateFields>;
  nodes: Array<AgencyServices>;
};

/** aggregate fields of "server_agency_services" */
export type AgencyServicesAggregateFields = {
  __typename?: 'agencyServicesAggregateFields';
  avg?: Maybe<AgencyServicesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AgencyServicesMaxFields>;
  min?: Maybe<AgencyServicesMinFields>;
  stddev?: Maybe<AgencyServicesStddevFields>;
  stddevPop?: Maybe<AgencyServicesStddev_PopFields>;
  stddevSamp?: Maybe<AgencyServicesStddev_SampFields>;
  sum?: Maybe<AgencyServicesSumFields>;
  varPop?: Maybe<AgencyServicesVar_PopFields>;
  varSamp?: Maybe<AgencyServicesVar_SampFields>;
  variance?: Maybe<AgencyServicesVarianceFields>;
};


/** aggregate fields of "server_agency_services" */
export type AgencyServicesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AgencyServicesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_agency_services" */
export type AgencyServicesAggregateOrderBy = {
  avg?: InputMaybe<AgencyServices_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AgencyServices_Max_Order_By>;
  min?: InputMaybe<AgencyServices_Min_Order_By>;
  stddev?: InputMaybe<AgencyServices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AgencyServices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AgencyServices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AgencyServices_Sum_Order_By>;
  var_pop?: InputMaybe<AgencyServices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AgencyServices_Var_Samp_Order_By>;
  variance?: InputMaybe<AgencyServices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_agency_services" */
export type AgencyServicesArrRelInsertInput = {
  data: Array<AgencyServicesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AgencyServicesOnConflict>;
};

/** aggregate avg on columns */
export type AgencyServicesAvgFields = {
  __typename?: 'agencyServicesAvgFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  servicetypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_agency_services". All fields are combined with a logical 'AND'. */
export type AgencyServicesBoolExp = {
  _and?: InputMaybe<Array<AgencyServicesBoolExp>>;
  _not?: InputMaybe<AgencyServicesBoolExp>;
  _or?: InputMaybe<Array<AgencyServicesBoolExp>>;
  agency?: InputMaybe<AgencyBoolExp>;
  agencyId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  serviceType?: InputMaybe<ServiceTypeBoolExp>;
  servicetypeId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_agency_services" */
export enum AgencyServicesConstraint {
  /** unique or primary key constraint on columns "agency_id", "servicetype_id" */
  ServerAgencyServicesAgencyIdServicetypeId_7da15d0bUniq = 'server_agency_services_agency_id_servicetype_id_7da15d0b_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerAgencyServicesPkey = 'server_agency_services_pkey'
}

/** input type for incrementing numeric columns in table "server_agency_services" */
export type AgencyServicesIncInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  servicetypeId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_agency_services" */
export type AgencyServicesInsertInput = {
  agency?: InputMaybe<AgencyObjRelInsertInput>;
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  serviceType?: InputMaybe<ServiceTypeObjRelInsertInput>;
  servicetypeId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type AgencyServicesMaxFields = {
  __typename?: 'agencyServicesMaxFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  servicetypeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type AgencyServicesMinFields = {
  __typename?: 'agencyServicesMinFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  servicetypeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_agency_services" */
export type AgencyServicesMutationResponse = {
  __typename?: 'agencyServicesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AgencyServices>;
};

/** on_conflict condition type for table "server_agency_services" */
export type AgencyServicesOnConflict = {
  constraint: AgencyServicesConstraint;
  update_columns?: Array<AgencyServicesUpdateColumn>;
  where?: InputMaybe<AgencyServicesBoolExp>;
};

/** Ordering options when selecting data from "server_agency_services". */
export type AgencyServicesOrderBy = {
  agency?: InputMaybe<AgencyOrderBy>;
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  serviceType?: InputMaybe<ServiceTypeOrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_agency_services */
export type AgencyServicesPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_agency_services" */
export enum AgencyServicesSelectColumn {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  Id = 'id',
  /** column name */
  ServicetypeId = 'servicetypeId'
}

/** input type for updating data in table "server_agency_services" */
export type AgencyServicesSetInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  servicetypeId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AgencyServicesStddevFields = {
  __typename?: 'agencyServicesStddevFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  servicetypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AgencyServicesStddev_PopFields = {
  __typename?: 'agencyServicesStddev_popFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  servicetypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AgencyServicesStddev_SampFields = {
  __typename?: 'agencyServicesStddev_sampFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  servicetypeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AgencyServicesSumFields = {
  __typename?: 'agencyServicesSumFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  servicetypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_agency_services" */
export enum AgencyServicesUpdateColumn {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  Id = 'id',
  /** column name */
  ServicetypeId = 'servicetypeId'
}

export type AgencyServicesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AgencyServicesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AgencyServicesSetInput>;
  where: AgencyServicesBoolExp;
};

/** aggregate var_pop on columns */
export type AgencyServicesVar_PopFields = {
  __typename?: 'agencyServicesVar_popFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  servicetypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AgencyServicesVar_SampFields = {
  __typename?: 'agencyServicesVar_sampFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  servicetypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AgencyServicesVarianceFields = {
  __typename?: 'agencyServicesVarianceFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  servicetypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_agency_services" */
export type AgencyServices_Avg_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_agency_services" */
export type AgencyServices_Max_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_agency_services" */
export type AgencyServices_Min_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_agency_services" */
export type AgencyServices_Stddev_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_agency_services" */
export type AgencyServices_Stddev_Pop_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_agency_services" */
export type AgencyServices_Stddev_Samp_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "agencyServices" */
export type AgencyServices_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AgencyServices_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AgencyServices_StreamCursorValueInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  servicetypeId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_agency_services" */
export type AgencyServices_Sum_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_agency_services" */
export type AgencyServices_Var_Pop_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_agency_services" */
export type AgencyServices_Var_Samp_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_agency_services" */
export type AgencyServices_Variance_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** input type for updating data in table "server_agency" */
export type AgencySetInput = {
  category?: InputMaybe<Scalars['smallint']>;
  description?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  notificationMode?: InputMaybe<Scalars['smallint']>;
  phone?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type AgencyStddevFields = {
  __typename?: 'agencyStddevFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  notificationMode?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AgencyStddev_PopFields = {
  __typename?: 'agencyStddev_popFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  notificationMode?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AgencyStddev_SampFields = {
  __typename?: 'agencyStddev_sampFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  notificationMode?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AgencySumFields = {
  __typename?: 'agencySumFields';
  category?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  notificationMode?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_agency" */
export enum AgencyUpdateColumn {
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description',
  /** column name */
  Fax = 'fax',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  NotificationMode = 'notificationMode',
  /** column name */
  Phone = 'phone'
}

export type AgencyUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AgencyIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AgencySetInput>;
  where: AgencyBoolExp;
};

/** aggregate var_pop on columns */
export type AgencyVar_PopFields = {
  __typename?: 'agencyVar_popFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  notificationMode?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AgencyVar_SampFields = {
  __typename?: 'agencyVar_sampFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  notificationMode?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AgencyVarianceFields = {
  __typename?: 'agencyVarianceFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  notificationMode?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "agency" */
export type Agency_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Agency_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Agency_StreamCursorValueInput = {
  category?: InputMaybe<Scalars['smallint']>;
  description?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  notificationMode?: InputMaybe<Scalars['smallint']>;
  phone?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "server_agencyconnection" */
export type Agencyconnection = {
  __typename?: 'agencyconnection';
  active: Scalars['Boolean'];
  /** An object relationship */
  agency?: Maybe<Agency>;
  agencyId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "server_agencyconnection" */
export type AgencyconnectionAggregate = {
  __typename?: 'agencyconnectionAggregate';
  aggregate?: Maybe<AgencyconnectionAggregateFields>;
  nodes: Array<Agencyconnection>;
};

/** aggregate fields of "server_agencyconnection" */
export type AgencyconnectionAggregateFields = {
  __typename?: 'agencyconnectionAggregateFields';
  avg?: Maybe<AgencyconnectionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AgencyconnectionMaxFields>;
  min?: Maybe<AgencyconnectionMinFields>;
  stddev?: Maybe<AgencyconnectionStddevFields>;
  stddevPop?: Maybe<AgencyconnectionStddev_PopFields>;
  stddevSamp?: Maybe<AgencyconnectionStddev_SampFields>;
  sum?: Maybe<AgencyconnectionSumFields>;
  varPop?: Maybe<AgencyconnectionVar_PopFields>;
  varSamp?: Maybe<AgencyconnectionVar_SampFields>;
  variance?: Maybe<AgencyconnectionVarianceFields>;
};


/** aggregate fields of "server_agencyconnection" */
export type AgencyconnectionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AgencyconnectionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_agencyconnection" */
export type AgencyconnectionAggregateOrderBy = {
  avg?: InputMaybe<Agencyconnection_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Agencyconnection_Max_Order_By>;
  min?: InputMaybe<Agencyconnection_Min_Order_By>;
  stddev?: InputMaybe<Agencyconnection_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Agencyconnection_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Agencyconnection_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Agencyconnection_Sum_Order_By>;
  var_pop?: InputMaybe<Agencyconnection_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Agencyconnection_Var_Samp_Order_By>;
  variance?: InputMaybe<Agencyconnection_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_agencyconnection" */
export type AgencyconnectionArrRelInsertInput = {
  data: Array<AgencyconnectionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AgencyconnectionOnConflict>;
};

/** aggregate avg on columns */
export type AgencyconnectionAvgFields = {
  __typename?: 'agencyconnectionAvgFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_agencyconnection". All fields are combined with a logical 'AND'. */
export type AgencyconnectionBoolExp = {
  _and?: InputMaybe<Array<AgencyconnectionBoolExp>>;
  _not?: InputMaybe<AgencyconnectionBoolExp>;
  _or?: InputMaybe<Array<AgencyconnectionBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  agency?: InputMaybe<AgencyBoolExp>;
  agencyId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_agencyconnection" */
export enum AgencyconnectionConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerAgencyconnectionsPkey = 'server_agencyconnections_pkey'
}

/** input type for incrementing numeric columns in table "server_agencyconnection" */
export type AgencyconnectionIncInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_agencyconnection" */
export type AgencyconnectionInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  agency?: InputMaybe<AgencyObjRelInsertInput>;
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type AgencyconnectionMaxFields = {
  __typename?: 'agencyconnectionMaxFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type AgencyconnectionMinFields = {
  __typename?: 'agencyconnectionMinFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_agencyconnection" */
export type AgencyconnectionMutationResponse = {
  __typename?: 'agencyconnectionMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agencyconnection>;
};

/** on_conflict condition type for table "server_agencyconnection" */
export type AgencyconnectionOnConflict = {
  constraint: AgencyconnectionConstraint;
  update_columns?: Array<AgencyconnectionUpdateColumn>;
  where?: InputMaybe<AgencyconnectionBoolExp>;
};

/** Ordering options when selecting data from "server_agencyconnection". */
export type AgencyconnectionOrderBy = {
  active?: InputMaybe<OrderBy>;
  agency?: InputMaybe<AgencyOrderBy>;
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_agencyconnection */
export type AgencyconnectionPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_agencyconnection" */
export enum AgencyconnectionSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId'
}

/** input type for updating data in table "server_agencyconnection" */
export type AgencyconnectionSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AgencyconnectionStddevFields = {
  __typename?: 'agencyconnectionStddevFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AgencyconnectionStddev_PopFields = {
  __typename?: 'agencyconnectionStddev_popFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AgencyconnectionStddev_SampFields = {
  __typename?: 'agencyconnectionStddev_sampFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AgencyconnectionSumFields = {
  __typename?: 'agencyconnectionSumFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_agencyconnection" */
export enum AgencyconnectionUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId'
}

export type AgencyconnectionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AgencyconnectionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AgencyconnectionSetInput>;
  where: AgencyconnectionBoolExp;
};

/** aggregate var_pop on columns */
export type AgencyconnectionVar_PopFields = {
  __typename?: 'agencyconnectionVar_popFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AgencyconnectionVar_SampFields = {
  __typename?: 'agencyconnectionVar_sampFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AgencyconnectionVarianceFields = {
  __typename?: 'agencyconnectionVarianceFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_agencyconnection" */
export type Agencyconnection_Avg_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_agencyconnection" */
export type Agencyconnection_Max_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_agencyconnection" */
export type Agencyconnection_Min_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_agencyconnection" */
export type Agencyconnection_Stddev_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_agencyconnection" */
export type Agencyconnection_Stddev_Pop_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_agencyconnection" */
export type Agencyconnection_Stddev_Samp_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "agencyconnection" */
export type Agencyconnection_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Agencyconnection_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Agencyconnection_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_agencyconnection" */
export type Agencyconnection_Sum_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_agencyconnection" */
export type Agencyconnection_Var_Pop_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_agencyconnection" */
export type Agencyconnection_Var_Samp_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_agencyconnection" */
export type Agencyconnection_Variance_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_agencyjoinrequest" */
export type Agencyjoinrequest = {
  __typename?: 'agencyjoinrequest';
  /** An object relationship */
  agency: Agency;
  agencyId: Scalars['Int'];
  /** An object relationship */
  approvalStaffMember?: Maybe<Externalstaff>;
  approvalUserId?: Maybe<Scalars['Int']>;
  approved: Scalars['Boolean'];
  dateApproved?: Maybe<Scalars['timestamptz']>;
  dateSubmitted: Scalars['timestamptz'];
  externalStaffId: Scalars['Int'];
  /** An object relationship */
  externalstaff: Externalstaff;
  id: Scalars['Int'];
};

/** aggregated selection of "server_agencyjoinrequest" */
export type AgencyjoinrequestAggregate = {
  __typename?: 'agencyjoinrequestAggregate';
  aggregate?: Maybe<AgencyjoinrequestAggregateFields>;
  nodes: Array<Agencyjoinrequest>;
};

/** aggregate fields of "server_agencyjoinrequest" */
export type AgencyjoinrequestAggregateFields = {
  __typename?: 'agencyjoinrequestAggregateFields';
  avg?: Maybe<AgencyjoinrequestAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AgencyjoinrequestMaxFields>;
  min?: Maybe<AgencyjoinrequestMinFields>;
  stddev?: Maybe<AgencyjoinrequestStddevFields>;
  stddevPop?: Maybe<AgencyjoinrequestStddev_PopFields>;
  stddevSamp?: Maybe<AgencyjoinrequestStddev_SampFields>;
  sum?: Maybe<AgencyjoinrequestSumFields>;
  varPop?: Maybe<AgencyjoinrequestVar_PopFields>;
  varSamp?: Maybe<AgencyjoinrequestVar_SampFields>;
  variance?: Maybe<AgencyjoinrequestVarianceFields>;
};


/** aggregate fields of "server_agencyjoinrequest" */
export type AgencyjoinrequestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AgencyjoinrequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_agencyjoinrequest" */
export type AgencyjoinrequestAggregateOrderBy = {
  avg?: InputMaybe<Agencyjoinrequest_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Agencyjoinrequest_Max_Order_By>;
  min?: InputMaybe<Agencyjoinrequest_Min_Order_By>;
  stddev?: InputMaybe<Agencyjoinrequest_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Agencyjoinrequest_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Agencyjoinrequest_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Agencyjoinrequest_Sum_Order_By>;
  var_pop?: InputMaybe<Agencyjoinrequest_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Agencyjoinrequest_Var_Samp_Order_By>;
  variance?: InputMaybe<Agencyjoinrequest_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_agencyjoinrequest" */
export type AgencyjoinrequestArrRelInsertInput = {
  data: Array<AgencyjoinrequestInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AgencyjoinrequestOnConflict>;
};

/** aggregate avg on columns */
export type AgencyjoinrequestAvgFields = {
  __typename?: 'agencyjoinrequestAvgFields';
  agencyId?: Maybe<Scalars['Float']>;
  approvalUserId?: Maybe<Scalars['Float']>;
  externalStaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_agencyjoinrequest". All fields are combined with a logical 'AND'. */
export type AgencyjoinrequestBoolExp = {
  _and?: InputMaybe<Array<AgencyjoinrequestBoolExp>>;
  _not?: InputMaybe<AgencyjoinrequestBoolExp>;
  _or?: InputMaybe<Array<AgencyjoinrequestBoolExp>>;
  agency?: InputMaybe<AgencyBoolExp>;
  agencyId?: InputMaybe<IntComparisonExp>;
  approvalStaffMember?: InputMaybe<ExternalstaffBoolExp>;
  approvalUserId?: InputMaybe<IntComparisonExp>;
  approved?: InputMaybe<BooleanComparisonExp>;
  dateApproved?: InputMaybe<TimestamptzComparisonExp>;
  dateSubmitted?: InputMaybe<TimestamptzComparisonExp>;
  externalStaffId?: InputMaybe<IntComparisonExp>;
  externalstaff?: InputMaybe<ExternalstaffBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_agencyjoinrequest" */
export enum AgencyjoinrequestConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerAgencyjoinrequestPkey = 'server_agencyjoinrequest_pkey'
}

/** input type for incrementing numeric columns in table "server_agencyjoinrequest" */
export type AgencyjoinrequestIncInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  approvalUserId?: InputMaybe<Scalars['Int']>;
  externalStaffId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_agencyjoinrequest" */
export type AgencyjoinrequestInsertInput = {
  agency?: InputMaybe<AgencyObjRelInsertInput>;
  agencyId?: InputMaybe<Scalars['Int']>;
  approvalStaffMember?: InputMaybe<ExternalstaffObjRelInsertInput>;
  approvalUserId?: InputMaybe<Scalars['Int']>;
  approved?: InputMaybe<Scalars['Boolean']>;
  dateApproved?: InputMaybe<Scalars['timestamptz']>;
  dateSubmitted?: InputMaybe<Scalars['timestamptz']>;
  externalStaffId?: InputMaybe<Scalars['Int']>;
  externalstaff?: InputMaybe<ExternalstaffObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type AgencyjoinrequestMaxFields = {
  __typename?: 'agencyjoinrequestMaxFields';
  agencyId?: Maybe<Scalars['Int']>;
  approvalUserId?: Maybe<Scalars['Int']>;
  dateApproved?: Maybe<Scalars['timestamptz']>;
  dateSubmitted?: Maybe<Scalars['timestamptz']>;
  externalStaffId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type AgencyjoinrequestMinFields = {
  __typename?: 'agencyjoinrequestMinFields';
  agencyId?: Maybe<Scalars['Int']>;
  approvalUserId?: Maybe<Scalars['Int']>;
  dateApproved?: Maybe<Scalars['timestamptz']>;
  dateSubmitted?: Maybe<Scalars['timestamptz']>;
  externalStaffId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_agencyjoinrequest" */
export type AgencyjoinrequestMutationResponse = {
  __typename?: 'agencyjoinrequestMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agencyjoinrequest>;
};

/** on_conflict condition type for table "server_agencyjoinrequest" */
export type AgencyjoinrequestOnConflict = {
  constraint: AgencyjoinrequestConstraint;
  update_columns?: Array<AgencyjoinrequestUpdateColumn>;
  where?: InputMaybe<AgencyjoinrequestBoolExp>;
};

/** Ordering options when selecting data from "server_agencyjoinrequest". */
export type AgencyjoinrequestOrderBy = {
  agency?: InputMaybe<AgencyOrderBy>;
  agencyId?: InputMaybe<OrderBy>;
  approvalStaffMember?: InputMaybe<ExternalstaffOrderBy>;
  approvalUserId?: InputMaybe<OrderBy>;
  approved?: InputMaybe<OrderBy>;
  dateApproved?: InputMaybe<OrderBy>;
  dateSubmitted?: InputMaybe<OrderBy>;
  externalStaffId?: InputMaybe<OrderBy>;
  externalstaff?: InputMaybe<ExternalstaffOrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_agencyjoinrequest */
export type AgencyjoinrequestPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_agencyjoinrequest" */
export enum AgencyjoinrequestSelectColumn {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  ApprovalUserId = 'approvalUserId',
  /** column name */
  Approved = 'approved',
  /** column name */
  DateApproved = 'dateApproved',
  /** column name */
  DateSubmitted = 'dateSubmitted',
  /** column name */
  ExternalStaffId = 'externalStaffId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "server_agencyjoinrequest" */
export type AgencyjoinrequestSetInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  approvalUserId?: InputMaybe<Scalars['Int']>;
  approved?: InputMaybe<Scalars['Boolean']>;
  dateApproved?: InputMaybe<Scalars['timestamptz']>;
  dateSubmitted?: InputMaybe<Scalars['timestamptz']>;
  externalStaffId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AgencyjoinrequestStddevFields = {
  __typename?: 'agencyjoinrequestStddevFields';
  agencyId?: Maybe<Scalars['Float']>;
  approvalUserId?: Maybe<Scalars['Float']>;
  externalStaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AgencyjoinrequestStddev_PopFields = {
  __typename?: 'agencyjoinrequestStddev_popFields';
  agencyId?: Maybe<Scalars['Float']>;
  approvalUserId?: Maybe<Scalars['Float']>;
  externalStaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AgencyjoinrequestStddev_SampFields = {
  __typename?: 'agencyjoinrequestStddev_sampFields';
  agencyId?: Maybe<Scalars['Float']>;
  approvalUserId?: Maybe<Scalars['Float']>;
  externalStaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AgencyjoinrequestSumFields = {
  __typename?: 'agencyjoinrequestSumFields';
  agencyId?: Maybe<Scalars['Int']>;
  approvalUserId?: Maybe<Scalars['Int']>;
  externalStaffId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_agencyjoinrequest" */
export enum AgencyjoinrequestUpdateColumn {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  ApprovalUserId = 'approvalUserId',
  /** column name */
  Approved = 'approved',
  /** column name */
  DateApproved = 'dateApproved',
  /** column name */
  DateSubmitted = 'dateSubmitted',
  /** column name */
  ExternalStaffId = 'externalStaffId',
  /** column name */
  Id = 'id'
}

export type AgencyjoinrequestUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AgencyjoinrequestIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AgencyjoinrequestSetInput>;
  where: AgencyjoinrequestBoolExp;
};

/** aggregate var_pop on columns */
export type AgencyjoinrequestVar_PopFields = {
  __typename?: 'agencyjoinrequestVar_popFields';
  agencyId?: Maybe<Scalars['Float']>;
  approvalUserId?: Maybe<Scalars['Float']>;
  externalStaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AgencyjoinrequestVar_SampFields = {
  __typename?: 'agencyjoinrequestVar_sampFields';
  agencyId?: Maybe<Scalars['Float']>;
  approvalUserId?: Maybe<Scalars['Float']>;
  externalStaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AgencyjoinrequestVarianceFields = {
  __typename?: 'agencyjoinrequestVarianceFields';
  agencyId?: Maybe<Scalars['Float']>;
  approvalUserId?: Maybe<Scalars['Float']>;
  externalStaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_agencyjoinrequest" */
export type Agencyjoinrequest_Avg_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  approvalUserId?: InputMaybe<OrderBy>;
  externalStaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_agencyjoinrequest" */
export type Agencyjoinrequest_Max_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  approvalUserId?: InputMaybe<OrderBy>;
  dateApproved?: InputMaybe<OrderBy>;
  dateSubmitted?: InputMaybe<OrderBy>;
  externalStaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_agencyjoinrequest" */
export type Agencyjoinrequest_Min_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  approvalUserId?: InputMaybe<OrderBy>;
  dateApproved?: InputMaybe<OrderBy>;
  dateSubmitted?: InputMaybe<OrderBy>;
  externalStaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_agencyjoinrequest" */
export type Agencyjoinrequest_Stddev_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  approvalUserId?: InputMaybe<OrderBy>;
  externalStaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_agencyjoinrequest" */
export type Agencyjoinrequest_Stddev_Pop_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  approvalUserId?: InputMaybe<OrderBy>;
  externalStaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_agencyjoinrequest" */
export type Agencyjoinrequest_Stddev_Samp_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  approvalUserId?: InputMaybe<OrderBy>;
  externalStaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "agencyjoinrequest" */
export type Agencyjoinrequest_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Agencyjoinrequest_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Agencyjoinrequest_StreamCursorValueInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  approvalUserId?: InputMaybe<Scalars['Int']>;
  approved?: InputMaybe<Scalars['Boolean']>;
  dateApproved?: InputMaybe<Scalars['timestamptz']>;
  dateSubmitted?: InputMaybe<Scalars['timestamptz']>;
  externalStaffId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_agencyjoinrequest" */
export type Agencyjoinrequest_Sum_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  approvalUserId?: InputMaybe<OrderBy>;
  externalStaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_agencyjoinrequest" */
export type Agencyjoinrequest_Var_Pop_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  approvalUserId?: InputMaybe<OrderBy>;
  externalStaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_agencyjoinrequest" */
export type Agencyjoinrequest_Var_Samp_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  approvalUserId?: InputMaybe<OrderBy>;
  externalStaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_agencyjoinrequest" */
export type Agencyjoinrequest_Variance_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  approvalUserId?: InputMaybe<OrderBy>;
  externalStaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_allergy" */
export type Allergy = {
  __typename?: 'allergy';
  active: Scalars['Boolean'];
  allergyCategory: Scalars['smallint'];
  /** An object relationship */
  enteredProvider?: Maybe<Provider>;
  externalId?: Maybe<Scalars['Int']>;
  externalJson?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  providerId?: Maybe<Scalars['Int']>;
  publicDbId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};


/** columns and relationships of "server_allergy" */
export type AllergyExternalJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "server_allergy" */
export type AllergyAggregate = {
  __typename?: 'allergyAggregate';
  aggregate?: Maybe<AllergyAggregateFields>;
  nodes: Array<Allergy>;
};

/** aggregate fields of "server_allergy" */
export type AllergyAggregateFields = {
  __typename?: 'allergyAggregateFields';
  avg?: Maybe<AllergyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AllergyMaxFields>;
  min?: Maybe<AllergyMinFields>;
  stddev?: Maybe<AllergyStddevFields>;
  stddevPop?: Maybe<AllergyStddev_PopFields>;
  stddevSamp?: Maybe<AllergyStddev_SampFields>;
  sum?: Maybe<AllergySumFields>;
  varPop?: Maybe<AllergyVar_PopFields>;
  varSamp?: Maybe<AllergyVar_SampFields>;
  variance?: Maybe<AllergyVarianceFields>;
};


/** aggregate fields of "server_allergy" */
export type AllergyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AllergySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_allergy" */
export type AllergyAggregateOrderBy = {
  avg?: InputMaybe<Allergy_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Allergy_Max_Order_By>;
  min?: InputMaybe<Allergy_Min_Order_By>;
  stddev?: InputMaybe<Allergy_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Allergy_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Allergy_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Allergy_Sum_Order_By>;
  var_pop?: InputMaybe<Allergy_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Allergy_Var_Samp_Order_By>;
  variance?: InputMaybe<Allergy_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AllergyAppendInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_allergy" */
export type AllergyArrRelInsertInput = {
  data: Array<AllergyInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AllergyOnConflict>;
};

/** aggregate avg on columns */
export type AllergyAvgFields = {
  __typename?: 'allergyAvgFields';
  allergyCategory?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_allergy". All fields are combined with a logical 'AND'. */
export type AllergyBoolExp = {
  _and?: InputMaybe<Array<AllergyBoolExp>>;
  _not?: InputMaybe<AllergyBoolExp>;
  _or?: InputMaybe<Array<AllergyBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  allergyCategory?: InputMaybe<SmallintComparisonExp>;
  enteredProvider?: InputMaybe<ProviderBoolExp>;
  externalId?: InputMaybe<IntComparisonExp>;
  externalJson?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  providerId?: InputMaybe<IntComparisonExp>;
  publicDbId?: InputMaybe<StringComparisonExp>;
  reaction?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_allergy" */
export enum AllergyConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerAllergyPkey = 'server_allergy_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AllergyDeleteAtPathInput = {
  externalJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AllergyDeleteElemInput = {
  externalJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AllergyDeleteKeyInput = {
  externalJson?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_allergy" */
export type AllergyIncInput = {
  allergyCategory?: InputMaybe<Scalars['smallint']>;
  externalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_allergy" */
export type AllergyInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  allergyCategory?: InputMaybe<Scalars['smallint']>;
  enteredProvider?: InputMaybe<ProviderObjRelInsertInput>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
  publicDbId?: InputMaybe<Scalars['String']>;
  reaction?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AllergyMaxFields = {
  __typename?: 'allergyMaxFields';
  allergyCategory?: Maybe<Scalars['smallint']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
  publicDbId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AllergyMinFields = {
  __typename?: 'allergyMinFields';
  allergyCategory?: Maybe<Scalars['smallint']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
  publicDbId?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_allergy" */
export type AllergyMutationResponse = {
  __typename?: 'allergyMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Allergy>;
};

/** on_conflict condition type for table "server_allergy" */
export type AllergyOnConflict = {
  constraint: AllergyConstraint;
  update_columns?: Array<AllergyUpdateColumn>;
  where?: InputMaybe<AllergyBoolExp>;
};

/** Ordering options when selecting data from "server_allergy". */
export type AllergyOrderBy = {
  active?: InputMaybe<OrderBy>;
  allergyCategory?: InputMaybe<OrderBy>;
  enteredProvider?: InputMaybe<ProviderOrderBy>;
  externalId?: InputMaybe<OrderBy>;
  externalJson?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  publicDbId?: InputMaybe<OrderBy>;
  reaction?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_allergy */
export type AllergyPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AllergyPrependInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_allergy" */
export enum AllergySelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AllergyCategory = 'allergyCategory',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  PublicDbId = 'publicDbId',
  /** column name */
  Reaction = 'reaction'
}

/** input type for updating data in table "server_allergy" */
export type AllergySetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  allergyCategory?: InputMaybe<Scalars['smallint']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
  publicDbId?: InputMaybe<Scalars['String']>;
  reaction?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type AllergyStddevFields = {
  __typename?: 'allergyStddevFields';
  allergyCategory?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AllergyStddev_PopFields = {
  __typename?: 'allergyStddev_popFields';
  allergyCategory?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AllergyStddev_SampFields = {
  __typename?: 'allergyStddev_sampFields';
  allergyCategory?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AllergySumFields = {
  __typename?: 'allergySumFields';
  allergyCategory?: Maybe<Scalars['smallint']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_allergy" */
export enum AllergyUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AllergyCategory = 'allergyCategory',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  PublicDbId = 'publicDbId',
  /** column name */
  Reaction = 'reaction'
}

export type AllergyUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AllergyAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<AllergyDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<AllergyDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<AllergyDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AllergyIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AllergyPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AllergySetInput>;
  where: AllergyBoolExp;
};

/** aggregate var_pop on columns */
export type AllergyVar_PopFields = {
  __typename?: 'allergyVar_popFields';
  allergyCategory?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AllergyVar_SampFields = {
  __typename?: 'allergyVar_sampFields';
  allergyCategory?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AllergyVarianceFields = {
  __typename?: 'allergyVarianceFields';
  allergyCategory?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_allergy" */
export type Allergy_Avg_Order_By = {
  allergyCategory?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_allergy" */
export type Allergy_Max_Order_By = {
  allergyCategory?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  publicDbId?: InputMaybe<OrderBy>;
  reaction?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_allergy" */
export type Allergy_Min_Order_By = {
  allergyCategory?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  publicDbId?: InputMaybe<OrderBy>;
  reaction?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_allergy" */
export type Allergy_Stddev_Order_By = {
  allergyCategory?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_allergy" */
export type Allergy_Stddev_Pop_Order_By = {
  allergyCategory?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_allergy" */
export type Allergy_Stddev_Samp_Order_By = {
  allergyCategory?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "allergy" */
export type Allergy_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Allergy_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Allergy_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  allergyCategory?: InputMaybe<Scalars['smallint']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
  publicDbId?: InputMaybe<Scalars['String']>;
  reaction?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_allergy" */
export type Allergy_Sum_Order_By = {
  allergyCategory?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_allergy" */
export type Allergy_Var_Pop_Order_By = {
  allergyCategory?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_allergy" */
export type Allergy_Var_Samp_Order_By = {
  allergyCategory?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_allergy" */
export type Allergy_Variance_Order_By = {
  allergyCategory?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_appointment" */
export type Appointment = {
  __typename?: 'appointment';
  active: Scalars['Boolean'];
  appointmentDatetime?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  associatedStatusAction?: Maybe<PatientStatusAction>;
  /** An array relationship */
  baseorders: Array<Baseorder>;
  /** An aggregate relationship */
  baseordersAggregate: BaseorderAggregate;
  /** An object relationship */
  caretime?: Maybe<Caretime>;
  caretimeId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  childAppointments: Array<Appointment>;
  /** An aggregate relationship */
  childAppointmentsAggregate: AppointmentAggregate;
  /** An object relationship */
  claim?: Maybe<Claim>;
  claimId?: Maybe<Scalars['Int']>;
  classification: Scalars['smallint'];
  /** An object relationship */
  clinicalnote?: Maybe<Clinicalnote>;
  complexity?: Maybe<Scalars['smallint']>;
  externalId?: Maybe<Scalars['Int']>;
  externalJson?: Maybe<Scalars['jsonb']>;
  followUpMaxDays?: Maybe<Scalars['smallint']>;
  followUpMinDays?: Maybe<Scalars['smallint']>;
  id: Scalars['Int'];
  maxDueDate?: Maybe<Scalars['date']>;
  minDueDate?: Maybe<Scalars['date']>;
  newVisit: Scalars['Boolean'];
  notes: Scalars['String'];
  outsideTime: Scalars['smallint'];
  /** An object relationship */
  parentAppointment?: Maybe<Appointment>;
  parentAppointmentId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  placeOfService: Scalars['smallint'];
  priority: Scalars['smallint'];
  /** An array relationship */
  proceduresRendered: Array<ProcedureRendered>;
  /** An aggregate relationship */
  proceduresRenderedAggregate: ProcedureRenderedAggregate;
  /** An object relationship */
  provider: Provider;
  providerId: Scalars['Int'];
  /** An array relationship */
  rebillsResultedHere: Array<VisitRebill>;
  /** An aggregate relationship */
  rebillsResultedHereAggregate: VisitRebillAggregate;
  /** An array relationship */
  schedulingActions: Array<SchedulingAction>;
  /** An aggregate relationship */
  schedulingActionsAggregate: SchedulingActionAggregate;
  status: Scalars['smallint'];
  /** An object relationship */
  telemedAppointmentRequest?: Maybe<TelemedAppointmentRequest>;
  tentativeDate?: Maybe<Scalars['date']>;
  timeIn?: Maybe<Scalars['time']>;
  timeOut?: Maybe<Scalars['time']>;
  /** An array relationship */
  treatmentPlanAssessments: Array<TreatmentplanAssessment>;
  /** An aggregate relationship */
  treatmentPlanAssessmentsAggregate: TreatmentplanAssessmentAggregate;
  typePreference: Scalars['smallint'];
  virtualVisit: Scalars['Boolean'];
  /** An array relationship */
  visitRebills: Array<VisitRebill>;
  /** An aggregate relationship */
  visitRebillsAggregate: VisitRebillAggregate;
  /** An object relationship */
  vitals?: Maybe<Vitals>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentBaseordersArgs = {
  distinctOn?: InputMaybe<Array<BaseorderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseorderOrderBy>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentBaseordersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BaseorderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseorderOrderBy>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentChildAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentChildAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentExternalJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentProceduresRenderedArgs = {
  distinctOn?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureRenderedOrderBy>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentProceduresRenderedAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureRenderedOrderBy>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentRebillsResultedHereArgs = {
  distinctOn?: InputMaybe<Array<VisitRebillSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VisitRebillOrderBy>>;
  where?: InputMaybe<VisitRebillBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentRebillsResultedHereAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitRebillSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VisitRebillOrderBy>>;
  where?: InputMaybe<VisitRebillBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentSchedulingActionsArgs = {
  distinctOn?: InputMaybe<Array<SchedulingActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchedulingActionOrderBy>>;
  where?: InputMaybe<SchedulingActionBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentSchedulingActionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SchedulingActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchedulingActionOrderBy>>;
  where?: InputMaybe<SchedulingActionBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentTreatmentPlanAssessmentsArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanAssessmentBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentTreatmentPlanAssessmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanAssessmentBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentVisitRebillsArgs = {
  distinctOn?: InputMaybe<Array<VisitRebillSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VisitRebillOrderBy>>;
  where?: InputMaybe<VisitRebillBoolExp>;
};


/** columns and relationships of "server_appointment" */
export type AppointmentVisitRebillsAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitRebillSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VisitRebillOrderBy>>;
  where?: InputMaybe<VisitRebillBoolExp>;
};

/** aggregated selection of "server_appointment" */
export type AppointmentAggregate = {
  __typename?: 'appointmentAggregate';
  aggregate?: Maybe<AppointmentAggregateFields>;
  nodes: Array<Appointment>;
};

/** aggregate fields of "server_appointment" */
export type AppointmentAggregateFields = {
  __typename?: 'appointmentAggregateFields';
  avg?: Maybe<AppointmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AppointmentMaxFields>;
  min?: Maybe<AppointmentMinFields>;
  stddev?: Maybe<AppointmentStddevFields>;
  stddevPop?: Maybe<AppointmentStddev_PopFields>;
  stddevSamp?: Maybe<AppointmentStddev_SampFields>;
  sum?: Maybe<AppointmentSumFields>;
  varPop?: Maybe<AppointmentVar_PopFields>;
  varSamp?: Maybe<AppointmentVar_SampFields>;
  variance?: Maybe<AppointmentVarianceFields>;
};


/** aggregate fields of "server_appointment" */
export type AppointmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AppointmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_appointment" */
export type AppointmentAggregateOrderBy = {
  avg?: InputMaybe<Appointment_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Appointment_Max_Order_By>;
  min?: InputMaybe<Appointment_Min_Order_By>;
  stddev?: InputMaybe<Appointment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Appointment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Appointment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Appointment_Sum_Order_By>;
  var_pop?: InputMaybe<Appointment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Appointment_Var_Samp_Order_By>;
  variance?: InputMaybe<Appointment_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AppointmentAppendInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_appointment" */
export type AppointmentArrRelInsertInput = {
  data: Array<AppointmentInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AppointmentOnConflict>;
};

/** aggregate avg on columns */
export type AppointmentAvgFields = {
  __typename?: 'appointmentAvgFields';
  caretimeId?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  classification?: Maybe<Scalars['Float']>;
  complexity?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  followUpMaxDays?: Maybe<Scalars['Float']>;
  followUpMinDays?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  outsideTime?: Maybe<Scalars['Float']>;
  parentAppointmentId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  typePreference?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_appointment". All fields are combined with a logical 'AND'. */
export type AppointmentBoolExp = {
  _and?: InputMaybe<Array<AppointmentBoolExp>>;
  _not?: InputMaybe<AppointmentBoolExp>;
  _or?: InputMaybe<Array<AppointmentBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  appointmentDatetime?: InputMaybe<TimestamptzComparisonExp>;
  associatedStatusAction?: InputMaybe<PatientStatusActionBoolExp>;
  baseorders?: InputMaybe<BaseorderBoolExp>;
  caretime?: InputMaybe<CaretimeBoolExp>;
  caretimeId?: InputMaybe<IntComparisonExp>;
  childAppointments?: InputMaybe<AppointmentBoolExp>;
  claim?: InputMaybe<ClaimBoolExp>;
  claimId?: InputMaybe<IntComparisonExp>;
  classification?: InputMaybe<SmallintComparisonExp>;
  clinicalnote?: InputMaybe<ClinicalnoteBoolExp>;
  complexity?: InputMaybe<SmallintComparisonExp>;
  externalId?: InputMaybe<IntComparisonExp>;
  externalJson?: InputMaybe<JsonbComparisonExp>;
  followUpMaxDays?: InputMaybe<SmallintComparisonExp>;
  followUpMinDays?: InputMaybe<SmallintComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  maxDueDate?: InputMaybe<DateComparisonExp>;
  minDueDate?: InputMaybe<DateComparisonExp>;
  newVisit?: InputMaybe<BooleanComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  outsideTime?: InputMaybe<SmallintComparisonExp>;
  parentAppointment?: InputMaybe<AppointmentBoolExp>;
  parentAppointmentId?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  placeOfService?: InputMaybe<SmallintComparisonExp>;
  priority?: InputMaybe<SmallintComparisonExp>;
  proceduresRendered?: InputMaybe<ProcedureRenderedBoolExp>;
  provider?: InputMaybe<ProviderBoolExp>;
  providerId?: InputMaybe<IntComparisonExp>;
  rebillsResultedHere?: InputMaybe<VisitRebillBoolExp>;
  schedulingActions?: InputMaybe<SchedulingActionBoolExp>;
  status?: InputMaybe<SmallintComparisonExp>;
  telemedAppointmentRequest?: InputMaybe<TelemedAppointmentRequestBoolExp>;
  tentativeDate?: InputMaybe<DateComparisonExp>;
  timeIn?: InputMaybe<TimeComparisonExp>;
  timeOut?: InputMaybe<TimeComparisonExp>;
  treatmentPlanAssessments?: InputMaybe<TreatmentplanAssessmentBoolExp>;
  typePreference?: InputMaybe<SmallintComparisonExp>;
  virtualVisit?: InputMaybe<BooleanComparisonExp>;
  visitRebills?: InputMaybe<VisitRebillBoolExp>;
  vitals?: InputMaybe<VitalsBoolExp>;
};

/** unique or primary key constraints on table "server_appointment" */
export enum AppointmentConstraint {
  /** unique or primary key constraint on columns "external_id" */
  ServerAppointmentExternalIdKey = 'server_appointment_external_id_key',
  /** unique or primary key constraint on columns "id" */
  ServerAppointmentPkey = 'server_appointment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AppointmentDeleteAtPathInput = {
  externalJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AppointmentDeleteElemInput = {
  externalJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AppointmentDeleteKeyInput = {
  externalJson?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_appointment" */
export type AppointmentIncInput = {
  caretimeId?: InputMaybe<Scalars['Int']>;
  claimId?: InputMaybe<Scalars['Int']>;
  classification?: InputMaybe<Scalars['smallint']>;
  complexity?: InputMaybe<Scalars['smallint']>;
  externalId?: InputMaybe<Scalars['Int']>;
  followUpMaxDays?: InputMaybe<Scalars['smallint']>;
  followUpMinDays?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  outsideTime?: InputMaybe<Scalars['smallint']>;
  parentAppointmentId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  placeOfService?: InputMaybe<Scalars['smallint']>;
  priority?: InputMaybe<Scalars['smallint']>;
  providerId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
  typePreference?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_appointment" */
export type AppointmentInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  appointmentDatetime?: InputMaybe<Scalars['timestamptz']>;
  associatedStatusAction?: InputMaybe<PatientStatusActionObjRelInsertInput>;
  baseorders?: InputMaybe<BaseorderArrRelInsertInput>;
  caretime?: InputMaybe<CaretimeObjRelInsertInput>;
  caretimeId?: InputMaybe<Scalars['Int']>;
  childAppointments?: InputMaybe<AppointmentArrRelInsertInput>;
  claim?: InputMaybe<ClaimObjRelInsertInput>;
  claimId?: InputMaybe<Scalars['Int']>;
  classification?: InputMaybe<Scalars['smallint']>;
  clinicalnote?: InputMaybe<ClinicalnoteObjRelInsertInput>;
  complexity?: InputMaybe<Scalars['smallint']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  followUpMaxDays?: InputMaybe<Scalars['smallint']>;
  followUpMinDays?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  maxDueDate?: InputMaybe<Scalars['date']>;
  minDueDate?: InputMaybe<Scalars['date']>;
  newVisit?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  outsideTime?: InputMaybe<Scalars['smallint']>;
  parentAppointment?: InputMaybe<AppointmentObjRelInsertInput>;
  parentAppointmentId?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  placeOfService?: InputMaybe<Scalars['smallint']>;
  priority?: InputMaybe<Scalars['smallint']>;
  proceduresRendered?: InputMaybe<ProcedureRenderedArrRelInsertInput>;
  provider?: InputMaybe<ProviderObjRelInsertInput>;
  providerId?: InputMaybe<Scalars['Int']>;
  rebillsResultedHere?: InputMaybe<VisitRebillArrRelInsertInput>;
  schedulingActions?: InputMaybe<SchedulingActionArrRelInsertInput>;
  status?: InputMaybe<Scalars['smallint']>;
  telemedAppointmentRequest?: InputMaybe<TelemedAppointmentRequestObjRelInsertInput>;
  tentativeDate?: InputMaybe<Scalars['date']>;
  timeIn?: InputMaybe<Scalars['time']>;
  timeOut?: InputMaybe<Scalars['time']>;
  treatmentPlanAssessments?: InputMaybe<TreatmentplanAssessmentArrRelInsertInput>;
  typePreference?: InputMaybe<Scalars['smallint']>;
  virtualVisit?: InputMaybe<Scalars['Boolean']>;
  visitRebills?: InputMaybe<VisitRebillArrRelInsertInput>;
  vitals?: InputMaybe<VitalsObjRelInsertInput>;
};

/** aggregate max on columns */
export type AppointmentMaxFields = {
  __typename?: 'appointmentMaxFields';
  appointmentDatetime?: Maybe<Scalars['timestamptz']>;
  caretimeId?: Maybe<Scalars['Int']>;
  claimId?: Maybe<Scalars['Int']>;
  classification?: Maybe<Scalars['smallint']>;
  complexity?: Maybe<Scalars['smallint']>;
  externalId?: Maybe<Scalars['Int']>;
  followUpMaxDays?: Maybe<Scalars['smallint']>;
  followUpMinDays?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  maxDueDate?: Maybe<Scalars['date']>;
  minDueDate?: Maybe<Scalars['date']>;
  notes?: Maybe<Scalars['String']>;
  outsideTime?: Maybe<Scalars['smallint']>;
  parentAppointmentId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  placeOfService?: Maybe<Scalars['smallint']>;
  priority?: Maybe<Scalars['smallint']>;
  providerId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
  tentativeDate?: Maybe<Scalars['date']>;
  typePreference?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type AppointmentMinFields = {
  __typename?: 'appointmentMinFields';
  appointmentDatetime?: Maybe<Scalars['timestamptz']>;
  caretimeId?: Maybe<Scalars['Int']>;
  claimId?: Maybe<Scalars['Int']>;
  classification?: Maybe<Scalars['smallint']>;
  complexity?: Maybe<Scalars['smallint']>;
  externalId?: Maybe<Scalars['Int']>;
  followUpMaxDays?: Maybe<Scalars['smallint']>;
  followUpMinDays?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  maxDueDate?: Maybe<Scalars['date']>;
  minDueDate?: Maybe<Scalars['date']>;
  notes?: Maybe<Scalars['String']>;
  outsideTime?: Maybe<Scalars['smallint']>;
  parentAppointmentId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  placeOfService?: Maybe<Scalars['smallint']>;
  priority?: Maybe<Scalars['smallint']>;
  providerId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
  tentativeDate?: Maybe<Scalars['date']>;
  typePreference?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_appointment" */
export type AppointmentMutationResponse = {
  __typename?: 'appointmentMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointment>;
};

/** input type for inserting object relation for remote table "server_appointment" */
export type AppointmentObjRelInsertInput = {
  data: AppointmentInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AppointmentOnConflict>;
};

/** on_conflict condition type for table "server_appointment" */
export type AppointmentOnConflict = {
  constraint: AppointmentConstraint;
  update_columns?: Array<AppointmentUpdateColumn>;
  where?: InputMaybe<AppointmentBoolExp>;
};

/** Ordering options when selecting data from "server_appointment". */
export type AppointmentOrderBy = {
  active?: InputMaybe<OrderBy>;
  appointmentDatetime?: InputMaybe<OrderBy>;
  associatedStatusAction?: InputMaybe<PatientStatusActionOrderBy>;
  baseordersAggregate?: InputMaybe<BaseorderAggregateOrderBy>;
  caretime?: InputMaybe<CaretimeOrderBy>;
  caretimeId?: InputMaybe<OrderBy>;
  childAppointmentsAggregate?: InputMaybe<AppointmentAggregateOrderBy>;
  claim?: InputMaybe<ClaimOrderBy>;
  claimId?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  clinicalnote?: InputMaybe<ClinicalnoteOrderBy>;
  complexity?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  externalJson?: InputMaybe<OrderBy>;
  followUpMaxDays?: InputMaybe<OrderBy>;
  followUpMinDays?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxDueDate?: InputMaybe<OrderBy>;
  minDueDate?: InputMaybe<OrderBy>;
  newVisit?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  outsideTime?: InputMaybe<OrderBy>;
  parentAppointment?: InputMaybe<AppointmentOrderBy>;
  parentAppointmentId?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  proceduresRenderedAggregate?: InputMaybe<ProcedureRenderedAggregateOrderBy>;
  provider?: InputMaybe<ProviderOrderBy>;
  providerId?: InputMaybe<OrderBy>;
  rebillsResultedHereAggregate?: InputMaybe<VisitRebillAggregateOrderBy>;
  schedulingActionsAggregate?: InputMaybe<SchedulingActionAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
  telemedAppointmentRequest?: InputMaybe<TelemedAppointmentRequestOrderBy>;
  tentativeDate?: InputMaybe<OrderBy>;
  timeIn?: InputMaybe<OrderBy>;
  timeOut?: InputMaybe<OrderBy>;
  treatmentPlanAssessmentsAggregate?: InputMaybe<TreatmentplanAssessmentAggregateOrderBy>;
  typePreference?: InputMaybe<OrderBy>;
  virtualVisit?: InputMaybe<OrderBy>;
  visitRebillsAggregate?: InputMaybe<VisitRebillAggregateOrderBy>;
  vitals?: InputMaybe<VitalsOrderBy>;
};

/** primary key columns input for table: server_appointment */
export type AppointmentPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AppointmentPrependInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_appointment" */
export enum AppointmentSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AppointmentDatetime = 'appointmentDatetime',
  /** column name */
  CaretimeId = 'caretimeId',
  /** column name */
  ClaimId = 'claimId',
  /** column name */
  Classification = 'classification',
  /** column name */
  Complexity = 'complexity',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  FollowUpMaxDays = 'followUpMaxDays',
  /** column name */
  FollowUpMinDays = 'followUpMinDays',
  /** column name */
  Id = 'id',
  /** column name */
  MaxDueDate = 'maxDueDate',
  /** column name */
  MinDueDate = 'minDueDate',
  /** column name */
  NewVisit = 'newVisit',
  /** column name */
  Notes = 'notes',
  /** column name */
  OutsideTime = 'outsideTime',
  /** column name */
  ParentAppointmentId = 'parentAppointmentId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PlaceOfService = 'placeOfService',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Status = 'status',
  /** column name */
  TentativeDate = 'tentativeDate',
  /** column name */
  TimeIn = 'timeIn',
  /** column name */
  TimeOut = 'timeOut',
  /** column name */
  TypePreference = 'typePreference',
  /** column name */
  VirtualVisit = 'virtualVisit'
}

/** input type for updating data in table "server_appointment" */
export type AppointmentSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  appointmentDatetime?: InputMaybe<Scalars['timestamptz']>;
  caretimeId?: InputMaybe<Scalars['Int']>;
  claimId?: InputMaybe<Scalars['Int']>;
  classification?: InputMaybe<Scalars['smallint']>;
  complexity?: InputMaybe<Scalars['smallint']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  followUpMaxDays?: InputMaybe<Scalars['smallint']>;
  followUpMinDays?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  maxDueDate?: InputMaybe<Scalars['date']>;
  minDueDate?: InputMaybe<Scalars['date']>;
  newVisit?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  outsideTime?: InputMaybe<Scalars['smallint']>;
  parentAppointmentId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  placeOfService?: InputMaybe<Scalars['smallint']>;
  priority?: InputMaybe<Scalars['smallint']>;
  providerId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
  tentativeDate?: InputMaybe<Scalars['date']>;
  timeIn?: InputMaybe<Scalars['time']>;
  timeOut?: InputMaybe<Scalars['time']>;
  typePreference?: InputMaybe<Scalars['smallint']>;
  virtualVisit?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type AppointmentStddevFields = {
  __typename?: 'appointmentStddevFields';
  caretimeId?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  classification?: Maybe<Scalars['Float']>;
  complexity?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  followUpMaxDays?: Maybe<Scalars['Float']>;
  followUpMinDays?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  outsideTime?: Maybe<Scalars['Float']>;
  parentAppointmentId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  typePreference?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AppointmentStddev_PopFields = {
  __typename?: 'appointmentStddev_popFields';
  caretimeId?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  classification?: Maybe<Scalars['Float']>;
  complexity?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  followUpMaxDays?: Maybe<Scalars['Float']>;
  followUpMinDays?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  outsideTime?: Maybe<Scalars['Float']>;
  parentAppointmentId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  typePreference?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AppointmentStddev_SampFields = {
  __typename?: 'appointmentStddev_sampFields';
  caretimeId?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  classification?: Maybe<Scalars['Float']>;
  complexity?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  followUpMaxDays?: Maybe<Scalars['Float']>;
  followUpMinDays?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  outsideTime?: Maybe<Scalars['Float']>;
  parentAppointmentId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  typePreference?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AppointmentSumFields = {
  __typename?: 'appointmentSumFields';
  caretimeId?: Maybe<Scalars['Int']>;
  claimId?: Maybe<Scalars['Int']>;
  classification?: Maybe<Scalars['smallint']>;
  complexity?: Maybe<Scalars['smallint']>;
  externalId?: Maybe<Scalars['Int']>;
  followUpMaxDays?: Maybe<Scalars['smallint']>;
  followUpMinDays?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  outsideTime?: Maybe<Scalars['smallint']>;
  parentAppointmentId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  placeOfService?: Maybe<Scalars['smallint']>;
  priority?: Maybe<Scalars['smallint']>;
  providerId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
  typePreference?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_appointment" */
export enum AppointmentUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AppointmentDatetime = 'appointmentDatetime',
  /** column name */
  CaretimeId = 'caretimeId',
  /** column name */
  ClaimId = 'claimId',
  /** column name */
  Classification = 'classification',
  /** column name */
  Complexity = 'complexity',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  FollowUpMaxDays = 'followUpMaxDays',
  /** column name */
  FollowUpMinDays = 'followUpMinDays',
  /** column name */
  Id = 'id',
  /** column name */
  MaxDueDate = 'maxDueDate',
  /** column name */
  MinDueDate = 'minDueDate',
  /** column name */
  NewVisit = 'newVisit',
  /** column name */
  Notes = 'notes',
  /** column name */
  OutsideTime = 'outsideTime',
  /** column name */
  ParentAppointmentId = 'parentAppointmentId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PlaceOfService = 'placeOfService',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  Status = 'status',
  /** column name */
  TentativeDate = 'tentativeDate',
  /** column name */
  TimeIn = 'timeIn',
  /** column name */
  TimeOut = 'timeOut',
  /** column name */
  TypePreference = 'typePreference',
  /** column name */
  VirtualVisit = 'virtualVisit'
}

export type AppointmentUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AppointmentAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<AppointmentDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<AppointmentDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<AppointmentDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AppointmentIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AppointmentPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppointmentSetInput>;
  where: AppointmentBoolExp;
};

/** aggregate var_pop on columns */
export type AppointmentVar_PopFields = {
  __typename?: 'appointmentVar_popFields';
  caretimeId?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  classification?: Maybe<Scalars['Float']>;
  complexity?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  followUpMaxDays?: Maybe<Scalars['Float']>;
  followUpMinDays?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  outsideTime?: Maybe<Scalars['Float']>;
  parentAppointmentId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  typePreference?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AppointmentVar_SampFields = {
  __typename?: 'appointmentVar_sampFields';
  caretimeId?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  classification?: Maybe<Scalars['Float']>;
  complexity?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  followUpMaxDays?: Maybe<Scalars['Float']>;
  followUpMinDays?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  outsideTime?: Maybe<Scalars['Float']>;
  parentAppointmentId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  typePreference?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AppointmentVarianceFields = {
  __typename?: 'appointmentVarianceFields';
  caretimeId?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  classification?: Maybe<Scalars['Float']>;
  complexity?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  followUpMaxDays?: Maybe<Scalars['Float']>;
  followUpMinDays?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  outsideTime?: Maybe<Scalars['Float']>;
  parentAppointmentId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  typePreference?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_appointment" */
export type Appointment_Avg_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  complexity?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  followUpMaxDays?: InputMaybe<OrderBy>;
  followUpMinDays?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  outsideTime?: InputMaybe<OrderBy>;
  parentAppointmentId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  typePreference?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_appointment" */
export type Appointment_Max_Order_By = {
  appointmentDatetime?: InputMaybe<OrderBy>;
  caretimeId?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  complexity?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  followUpMaxDays?: InputMaybe<OrderBy>;
  followUpMinDays?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxDueDate?: InputMaybe<OrderBy>;
  minDueDate?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  outsideTime?: InputMaybe<OrderBy>;
  parentAppointmentId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tentativeDate?: InputMaybe<OrderBy>;
  typePreference?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_appointment" */
export type Appointment_Min_Order_By = {
  appointmentDatetime?: InputMaybe<OrderBy>;
  caretimeId?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  complexity?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  followUpMaxDays?: InputMaybe<OrderBy>;
  followUpMinDays?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxDueDate?: InputMaybe<OrderBy>;
  minDueDate?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  outsideTime?: InputMaybe<OrderBy>;
  parentAppointmentId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tentativeDate?: InputMaybe<OrderBy>;
  typePreference?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_appointment" */
export type Appointment_Stddev_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  complexity?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  followUpMaxDays?: InputMaybe<OrderBy>;
  followUpMinDays?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  outsideTime?: InputMaybe<OrderBy>;
  parentAppointmentId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  typePreference?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_appointment" */
export type Appointment_Stddev_Pop_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  complexity?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  followUpMaxDays?: InputMaybe<OrderBy>;
  followUpMinDays?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  outsideTime?: InputMaybe<OrderBy>;
  parentAppointmentId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  typePreference?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_appointment" */
export type Appointment_Stddev_Samp_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  complexity?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  followUpMaxDays?: InputMaybe<OrderBy>;
  followUpMinDays?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  outsideTime?: InputMaybe<OrderBy>;
  parentAppointmentId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  typePreference?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "appointment" */
export type Appointment_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Appointment_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Appointment_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  appointmentDatetime?: InputMaybe<Scalars['timestamptz']>;
  caretimeId?: InputMaybe<Scalars['Int']>;
  claimId?: InputMaybe<Scalars['Int']>;
  classification?: InputMaybe<Scalars['smallint']>;
  complexity?: InputMaybe<Scalars['smallint']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  followUpMaxDays?: InputMaybe<Scalars['smallint']>;
  followUpMinDays?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  maxDueDate?: InputMaybe<Scalars['date']>;
  minDueDate?: InputMaybe<Scalars['date']>;
  newVisit?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  outsideTime?: InputMaybe<Scalars['smallint']>;
  parentAppointmentId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  placeOfService?: InputMaybe<Scalars['smallint']>;
  priority?: InputMaybe<Scalars['smallint']>;
  providerId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
  tentativeDate?: InputMaybe<Scalars['date']>;
  timeIn?: InputMaybe<Scalars['time']>;
  timeOut?: InputMaybe<Scalars['time']>;
  typePreference?: InputMaybe<Scalars['smallint']>;
  virtualVisit?: InputMaybe<Scalars['Boolean']>;
};

/** order by sum() on columns of table "server_appointment" */
export type Appointment_Sum_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  complexity?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  followUpMaxDays?: InputMaybe<OrderBy>;
  followUpMinDays?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  outsideTime?: InputMaybe<OrderBy>;
  parentAppointmentId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  typePreference?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_appointment" */
export type Appointment_Var_Pop_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  complexity?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  followUpMaxDays?: InputMaybe<OrderBy>;
  followUpMinDays?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  outsideTime?: InputMaybe<OrderBy>;
  parentAppointmentId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  typePreference?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_appointment" */
export type Appointment_Var_Samp_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  complexity?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  followUpMaxDays?: InputMaybe<OrderBy>;
  followUpMinDays?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  outsideTime?: InputMaybe<OrderBy>;
  parentAppointmentId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  typePreference?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_appointment" */
export type Appointment_Variance_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  classification?: InputMaybe<OrderBy>;
  complexity?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  followUpMaxDays?: InputMaybe<OrderBy>;
  followUpMinDays?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  outsideTime?: InputMaybe<OrderBy>;
  parentAppointmentId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  typePreference?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_assessment" */
export type Assessment = {
  __typename?: 'assessment';
  /** An array relationship */
  assessmentItems: Array<AssessmentItem>;
  /** An aggregate relationship */
  assessmentItemsAggregate: AssessmentItemAggregate;
  associatedIcdCodes?: Maybe<Scalars['_varchar']>;
  /** An array relationship */
  clinicalNoteTemplates: Array<ClinicalnoteTemplate>;
  /** An aggregate relationship */
  clinicalNoteTemplatesAggregate: ClinicalnoteTemplateAggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  practiceGroupId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  practicegroup?: Maybe<Practicegroup>;
  /** An array relationship */
  renderedAssessments: Array<RenderedAssessment>;
  /** An aggregate relationship */
  renderedAssessmentsAggregate: RenderedAssessmentAggregate;
  /** An array relationship */
  treatmentPlanTemplates: Array<TreatmentplanTemplateAssessment>;
  /** An aggregate relationship */
  treatmentPlanTemplatesAggregate: TreatmentplanTemplateAssessmentAggregate;
};


/** columns and relationships of "server_assessment" */
export type AssessmentAssessmentItemsArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemOrderBy>>;
  where?: InputMaybe<AssessmentItemBoolExp>;
};


/** columns and relationships of "server_assessment" */
export type AssessmentAssessmentItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemOrderBy>>;
  where?: InputMaybe<AssessmentItemBoolExp>;
};


/** columns and relationships of "server_assessment" */
export type AssessmentClinicalNoteTemplatesArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateBoolExp>;
};


/** columns and relationships of "server_assessment" */
export type AssessmentClinicalNoteTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateBoolExp>;
};


/** columns and relationships of "server_assessment" */
export type AssessmentRenderedAssessmentsArgs = {
  distinctOn?: InputMaybe<Array<RenderedAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RenderedAssessmentOrderBy>>;
  where?: InputMaybe<RenderedAssessmentBoolExp>;
};


/** columns and relationships of "server_assessment" */
export type AssessmentRenderedAssessmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<RenderedAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RenderedAssessmentOrderBy>>;
  where?: InputMaybe<RenderedAssessmentBoolExp>;
};


/** columns and relationships of "server_assessment" */
export type AssessmentTreatmentPlanTemplatesArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanTemplateAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanTemplateAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
};


/** columns and relationships of "server_assessment" */
export type AssessmentTreatmentPlanTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanTemplateAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanTemplateAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
};

/** aggregated selection of "server_assessment" */
export type AssessmentAggregate = {
  __typename?: 'assessmentAggregate';
  aggregate?: Maybe<AssessmentAggregateFields>;
  nodes: Array<Assessment>;
};

/** aggregate fields of "server_assessment" */
export type AssessmentAggregateFields = {
  __typename?: 'assessmentAggregateFields';
  avg?: Maybe<AssessmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AssessmentMaxFields>;
  min?: Maybe<AssessmentMinFields>;
  stddev?: Maybe<AssessmentStddevFields>;
  stddevPop?: Maybe<AssessmentStddev_PopFields>;
  stddevSamp?: Maybe<AssessmentStddev_SampFields>;
  sum?: Maybe<AssessmentSumFields>;
  varPop?: Maybe<AssessmentVar_PopFields>;
  varSamp?: Maybe<AssessmentVar_SampFields>;
  variance?: Maybe<AssessmentVarianceFields>;
};


/** aggregate fields of "server_assessment" */
export type AssessmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssessmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_assessment" */
export type AssessmentAggregateOrderBy = {
  avg?: InputMaybe<Assessment_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Assessment_Max_Order_By>;
  min?: InputMaybe<Assessment_Min_Order_By>;
  stddev?: InputMaybe<Assessment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Assessment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Assessment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Assessment_Sum_Order_By>;
  var_pop?: InputMaybe<Assessment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Assessment_Var_Samp_Order_By>;
  variance?: InputMaybe<Assessment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_assessment" */
export type AssessmentArrRelInsertInput = {
  data: Array<AssessmentInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AssessmentOnConflict>;
};

/** aggregate avg on columns */
export type AssessmentAvgFields = {
  __typename?: 'assessmentAvgFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_assessment". All fields are combined with a logical 'AND'. */
export type AssessmentBoolExp = {
  _and?: InputMaybe<Array<AssessmentBoolExp>>;
  _not?: InputMaybe<AssessmentBoolExp>;
  _or?: InputMaybe<Array<AssessmentBoolExp>>;
  assessmentItems?: InputMaybe<AssessmentItemBoolExp>;
  associatedIcdCodes?: InputMaybe<_VarcharComparisonExp>;
  clinicalNoteTemplates?: InputMaybe<ClinicalnoteTemplateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  practiceGroupId?: InputMaybe<IntComparisonExp>;
  practicegroup?: InputMaybe<PracticegroupBoolExp>;
  renderedAssessments?: InputMaybe<RenderedAssessmentBoolExp>;
  treatmentPlanTemplates?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
};

/** unique or primary key constraints on table "server_assessment" */
export enum AssessmentConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerAssessmentPkey = 'server_assessment_pkey'
}

/** input type for incrementing numeric columns in table "server_assessment" */
export type AssessmentIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_assessment" */
export type AssessmentInsertInput = {
  assessmentItems?: InputMaybe<AssessmentItemArrRelInsertInput>;
  associatedIcdCodes?: InputMaybe<Scalars['_varchar']>;
  clinicalNoteTemplates?: InputMaybe<ClinicalnoteTemplateArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  practicegroup?: InputMaybe<PracticegroupObjRelInsertInput>;
  renderedAssessments?: InputMaybe<RenderedAssessmentArrRelInsertInput>;
  treatmentPlanTemplates?: InputMaybe<TreatmentplanTemplateAssessmentArrRelInsertInput>;
};

/** columns and relationships of "server_assessmentitem" */
export type AssessmentItem = {
  __typename?: 'assessmentItem';
  /** An object relationship */
  assessment: Assessment;
  assessmentId: Scalars['Int'];
  data?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  fields: Array<AssessmentItemValue>;
  /** An aggregate relationship */
  fieldsAggregate: AssessmentItemValueAggregate;
  id: Scalars['Int'];
  insertText: Scalars['String'];
  name: Scalars['String'];
  orderWithinGroup: Scalars['smallint'];
  type: Scalars['smallint'];
};


/** columns and relationships of "server_assessmentitem" */
export type AssessmentItemDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "server_assessmentitem" */
export type AssessmentItemFieldsArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemValueOrderBy>>;
  where?: InputMaybe<AssessmentItemValueBoolExp>;
};


/** columns and relationships of "server_assessmentitem" */
export type AssessmentItemFieldsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemValueOrderBy>>;
  where?: InputMaybe<AssessmentItemValueBoolExp>;
};

/** aggregated selection of "server_assessmentitem" */
export type AssessmentItemAggregate = {
  __typename?: 'assessmentItemAggregate';
  aggregate?: Maybe<AssessmentItemAggregateFields>;
  nodes: Array<AssessmentItem>;
};

/** aggregate fields of "server_assessmentitem" */
export type AssessmentItemAggregateFields = {
  __typename?: 'assessmentItemAggregateFields';
  avg?: Maybe<AssessmentItemAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AssessmentItemMaxFields>;
  min?: Maybe<AssessmentItemMinFields>;
  stddev?: Maybe<AssessmentItemStddevFields>;
  stddevPop?: Maybe<AssessmentItemStddev_PopFields>;
  stddevSamp?: Maybe<AssessmentItemStddev_SampFields>;
  sum?: Maybe<AssessmentItemSumFields>;
  varPop?: Maybe<AssessmentItemVar_PopFields>;
  varSamp?: Maybe<AssessmentItemVar_SampFields>;
  variance?: Maybe<AssessmentItemVarianceFields>;
};


/** aggregate fields of "server_assessmentitem" */
export type AssessmentItemAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssessmentItemSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_assessmentitem" */
export type AssessmentItemAggregateOrderBy = {
  avg?: InputMaybe<AssessmentItem_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssessmentItem_Max_Order_By>;
  min?: InputMaybe<AssessmentItem_Min_Order_By>;
  stddev?: InputMaybe<AssessmentItem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AssessmentItem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AssessmentItem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AssessmentItem_Sum_Order_By>;
  var_pop?: InputMaybe<AssessmentItem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AssessmentItem_Var_Samp_Order_By>;
  variance?: InputMaybe<AssessmentItem_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AssessmentItemAppendInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_assessmentitem" */
export type AssessmentItemArrRelInsertInput = {
  data: Array<AssessmentItemInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AssessmentItemOnConflict>;
};

/** aggregate avg on columns */
export type AssessmentItemAvgFields = {
  __typename?: 'assessmentItemAvgFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_assessmentitem". All fields are combined with a logical 'AND'. */
export type AssessmentItemBoolExp = {
  _and?: InputMaybe<Array<AssessmentItemBoolExp>>;
  _not?: InputMaybe<AssessmentItemBoolExp>;
  _or?: InputMaybe<Array<AssessmentItemBoolExp>>;
  assessment?: InputMaybe<AssessmentBoolExp>;
  assessmentId?: InputMaybe<IntComparisonExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  fields?: InputMaybe<AssessmentItemValueBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  insertText?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  orderWithinGroup?: InputMaybe<SmallintComparisonExp>;
  type?: InputMaybe<SmallintComparisonExp>;
};

/** unique or primary key constraints on table "server_assessmentitem" */
export enum AssessmentItemConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerAssessmentitemPkey = 'server_assessmentitem_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AssessmentItemDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AssessmentItemDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AssessmentItemDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_assessmentitem" */
export type AssessmentItemIncInput = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderWithinGroup?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_assessmentitem" */
export type AssessmentItemInsertInput = {
  assessment?: InputMaybe<AssessmentObjRelInsertInput>;
  assessmentId?: InputMaybe<Scalars['Int']>;
  data?: InputMaybe<Scalars['jsonb']>;
  fields?: InputMaybe<AssessmentItemValueArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  insertText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderWithinGroup?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type AssessmentItemMaxFields = {
  __typename?: 'assessmentItemMaxFields';
  assessmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  insertText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderWithinGroup?: Maybe<Scalars['smallint']>;
  type?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type AssessmentItemMinFields = {
  __typename?: 'assessmentItemMinFields';
  assessmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  insertText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderWithinGroup?: Maybe<Scalars['smallint']>;
  type?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_assessmentitem" */
export type AssessmentItemMutationResponse = {
  __typename?: 'assessmentItemMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssessmentItem>;
};

/** input type for inserting object relation for remote table "server_assessmentitem" */
export type AssessmentItemObjRelInsertInput = {
  data: AssessmentItemInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AssessmentItemOnConflict>;
};

/** on_conflict condition type for table "server_assessmentitem" */
export type AssessmentItemOnConflict = {
  constraint: AssessmentItemConstraint;
  update_columns?: Array<AssessmentItemUpdateColumn>;
  where?: InputMaybe<AssessmentItemBoolExp>;
};

/** Ordering options when selecting data from "server_assessmentitem". */
export type AssessmentItemOrderBy = {
  assessment?: InputMaybe<AssessmentOrderBy>;
  assessmentId?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  fieldsAggregate?: InputMaybe<AssessmentItemValueAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  insertText?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_assessmentitem */
export type AssessmentItemPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AssessmentItemPrependInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_assessmentitem" */
export enum AssessmentItemSelectColumn {
  /** column name */
  AssessmentId = 'assessmentId',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  InsertText = 'insertText',
  /** column name */
  Name = 'name',
  /** column name */
  OrderWithinGroup = 'orderWithinGroup',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "server_assessmentitem" */
export type AssessmentItemSetInput = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  insertText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderWithinGroup?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type AssessmentItemStddevFields = {
  __typename?: 'assessmentItemStddevFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AssessmentItemStddev_PopFields = {
  __typename?: 'assessmentItemStddev_popFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AssessmentItemStddev_SampFields = {
  __typename?: 'assessmentItemStddev_sampFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AssessmentItemSumFields = {
  __typename?: 'assessmentItemSumFields';
  assessmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderWithinGroup?: Maybe<Scalars['smallint']>;
  type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_assessmentitem" */
export enum AssessmentItemUpdateColumn {
  /** column name */
  AssessmentId = 'assessmentId',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  InsertText = 'insertText',
  /** column name */
  Name = 'name',
  /** column name */
  OrderWithinGroup = 'orderWithinGroup',
  /** column name */
  Type = 'type'
}

export type AssessmentItemUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AssessmentItemAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<AssessmentItemDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<AssessmentItemDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<AssessmentItemDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AssessmentItemIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AssessmentItemPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssessmentItemSetInput>;
  where: AssessmentItemBoolExp;
};

/** columns and relationships of "server_assessmentitemvalue" */
export type AssessmentItemValue = {
  __typename?: 'assessmentItemValue';
  /** An object relationship */
  assessmentItemField: AssessmentItem;
  fieldId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  renderedAssessment: RenderedAssessment;
  renderedAssessmentId: Scalars['Int'];
  show: Scalars['Boolean'];
  value: Scalars['String'];
};

/** aggregated selection of "server_assessmentitemvalue" */
export type AssessmentItemValueAggregate = {
  __typename?: 'assessmentItemValueAggregate';
  aggregate?: Maybe<AssessmentItemValueAggregateFields>;
  nodes: Array<AssessmentItemValue>;
};

/** aggregate fields of "server_assessmentitemvalue" */
export type AssessmentItemValueAggregateFields = {
  __typename?: 'assessmentItemValueAggregateFields';
  avg?: Maybe<AssessmentItemValueAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AssessmentItemValueMaxFields>;
  min?: Maybe<AssessmentItemValueMinFields>;
  stddev?: Maybe<AssessmentItemValueStddevFields>;
  stddevPop?: Maybe<AssessmentItemValueStddev_PopFields>;
  stddevSamp?: Maybe<AssessmentItemValueStddev_SampFields>;
  sum?: Maybe<AssessmentItemValueSumFields>;
  varPop?: Maybe<AssessmentItemValueVar_PopFields>;
  varSamp?: Maybe<AssessmentItemValueVar_SampFields>;
  variance?: Maybe<AssessmentItemValueVarianceFields>;
};


/** aggregate fields of "server_assessmentitemvalue" */
export type AssessmentItemValueAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssessmentItemValueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_assessmentitemvalue" */
export type AssessmentItemValueAggregateOrderBy = {
  avg?: InputMaybe<AssessmentItemValue_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssessmentItemValue_Max_Order_By>;
  min?: InputMaybe<AssessmentItemValue_Min_Order_By>;
  stddev?: InputMaybe<AssessmentItemValue_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AssessmentItemValue_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AssessmentItemValue_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AssessmentItemValue_Sum_Order_By>;
  var_pop?: InputMaybe<AssessmentItemValue_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AssessmentItemValue_Var_Samp_Order_By>;
  variance?: InputMaybe<AssessmentItemValue_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_assessmentitemvalue" */
export type AssessmentItemValueArrRelInsertInput = {
  data: Array<AssessmentItemValueInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AssessmentItemValueOnConflict>;
};

/** aggregate avg on columns */
export type AssessmentItemValueAvgFields = {
  __typename?: 'assessmentItemValueAvgFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_assessmentitemvalue". All fields are combined with a logical 'AND'. */
export type AssessmentItemValueBoolExp = {
  _and?: InputMaybe<Array<AssessmentItemValueBoolExp>>;
  _not?: InputMaybe<AssessmentItemValueBoolExp>;
  _or?: InputMaybe<Array<AssessmentItemValueBoolExp>>;
  assessmentItemField?: InputMaybe<AssessmentItemBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  renderedAssessment?: InputMaybe<RenderedAssessmentBoolExp>;
  renderedAssessmentId?: InputMaybe<IntComparisonExp>;
  show?: InputMaybe<BooleanComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_assessmentitemvalue" */
export enum AssessmentItemValueConstraint {
  /** unique or primary key constraint on columns "field_id", "rendered_assessment_id" */
  ServerAssessmentitemvalRenderedAssessmentIdF_4d92697cUniq = 'server_assessmentitemval_rendered_assessment_id_f_4d92697c_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerAssessmentitemvaluePkey = 'server_assessmentitemvalue_pkey'
}

/** input type for incrementing numeric columns in table "server_assessmentitemvalue" */
export type AssessmentItemValueIncInput = {
  fieldId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  renderedAssessmentId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_assessmentitemvalue" */
export type AssessmentItemValueInsertInput = {
  assessmentItemField?: InputMaybe<AssessmentItemObjRelInsertInput>;
  fieldId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  renderedAssessment?: InputMaybe<RenderedAssessmentObjRelInsertInput>;
  renderedAssessmentId?: InputMaybe<Scalars['Int']>;
  show?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AssessmentItemValueMaxFields = {
  __typename?: 'assessmentItemValueMaxFields';
  fieldId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  renderedAssessmentId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AssessmentItemValueMinFields = {
  __typename?: 'assessmentItemValueMinFields';
  fieldId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  renderedAssessmentId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_assessmentitemvalue" */
export type AssessmentItemValueMutationResponse = {
  __typename?: 'assessmentItemValueMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AssessmentItemValue>;
};

/** on_conflict condition type for table "server_assessmentitemvalue" */
export type AssessmentItemValueOnConflict = {
  constraint: AssessmentItemValueConstraint;
  update_columns?: Array<AssessmentItemValueUpdateColumn>;
  where?: InputMaybe<AssessmentItemValueBoolExp>;
};

/** Ordering options when selecting data from "server_assessmentitemvalue". */
export type AssessmentItemValueOrderBy = {
  assessmentItemField?: InputMaybe<AssessmentItemOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessment?: InputMaybe<RenderedAssessmentOrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  show?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_assessmentitemvalue */
export type AssessmentItemValuePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_assessmentitemvalue" */
export enum AssessmentItemValueSelectColumn {
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id',
  /** column name */
  RenderedAssessmentId = 'renderedAssessmentId',
  /** column name */
  Show = 'show',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "server_assessmentitemvalue" */
export type AssessmentItemValueSetInput = {
  fieldId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  renderedAssessmentId?: InputMaybe<Scalars['Int']>;
  show?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type AssessmentItemValueStddevFields = {
  __typename?: 'assessmentItemValueStddevFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AssessmentItemValueStddev_PopFields = {
  __typename?: 'assessmentItemValueStddev_popFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AssessmentItemValueStddev_SampFields = {
  __typename?: 'assessmentItemValueStddev_sampFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AssessmentItemValueSumFields = {
  __typename?: 'assessmentItemValueSumFields';
  fieldId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  renderedAssessmentId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_assessmentitemvalue" */
export enum AssessmentItemValueUpdateColumn {
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id',
  /** column name */
  RenderedAssessmentId = 'renderedAssessmentId',
  /** column name */
  Show = 'show',
  /** column name */
  Value = 'value'
}

export type AssessmentItemValueUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AssessmentItemValueIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssessmentItemValueSetInput>;
  where: AssessmentItemValueBoolExp;
};

/** aggregate var_pop on columns */
export type AssessmentItemValueVar_PopFields = {
  __typename?: 'assessmentItemValueVar_popFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AssessmentItemValueVar_SampFields = {
  __typename?: 'assessmentItemValueVar_sampFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AssessmentItemValueVarianceFields = {
  __typename?: 'assessmentItemValueVarianceFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_assessmentitemvalue" */
export type AssessmentItemValue_Avg_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_assessmentitemvalue" */
export type AssessmentItemValue_Max_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_assessmentitemvalue" */
export type AssessmentItemValue_Min_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_assessmentitemvalue" */
export type AssessmentItemValue_Stddev_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_assessmentitemvalue" */
export type AssessmentItemValue_Stddev_Pop_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_assessmentitemvalue" */
export type AssessmentItemValue_Stddev_Samp_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "assessmentItemValue" */
export type AssessmentItemValue_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AssessmentItemValue_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssessmentItemValue_StreamCursorValueInput = {
  fieldId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  renderedAssessmentId?: InputMaybe<Scalars['Int']>;
  show?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_assessmentitemvalue" */
export type AssessmentItemValue_Sum_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_assessmentitemvalue" */
export type AssessmentItemValue_Var_Pop_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_assessmentitemvalue" */
export type AssessmentItemValue_Var_Samp_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_assessmentitemvalue" */
export type AssessmentItemValue_Variance_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type AssessmentItemVar_PopFields = {
  __typename?: 'assessmentItemVar_popFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AssessmentItemVar_SampFields = {
  __typename?: 'assessmentItemVar_sampFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AssessmentItemVarianceFields = {
  __typename?: 'assessmentItemVarianceFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_assessmentitem" */
export type AssessmentItem_Avg_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_assessmentitem" */
export type AssessmentItem_Max_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertText?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_assessmentitem" */
export type AssessmentItem_Min_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertText?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_assessmentitem" */
export type AssessmentItem_Stddev_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_assessmentitem" */
export type AssessmentItem_Stddev_Pop_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_assessmentitem" */
export type AssessmentItem_Stddev_Samp_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "assessmentItem" */
export type AssessmentItem_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AssessmentItem_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssessmentItem_StreamCursorValueInput = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  insertText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderWithinGroup?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "server_assessmentitem" */
export type AssessmentItem_Sum_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_assessmentitem" */
export type AssessmentItem_Var_Pop_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_assessmentitem" */
export type AssessmentItem_Var_Samp_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_assessmentitem" */
export type AssessmentItem_Variance_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type AssessmentMaxFields = {
  __typename?: 'assessmentMaxFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type AssessmentMinFields = {
  __typename?: 'assessmentMinFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_assessment" */
export type AssessmentMutationResponse = {
  __typename?: 'assessmentMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assessment>;
};

/** input type for inserting object relation for remote table "server_assessment" */
export type AssessmentObjRelInsertInput = {
  data: AssessmentInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AssessmentOnConflict>;
};

/** on_conflict condition type for table "server_assessment" */
export type AssessmentOnConflict = {
  constraint: AssessmentConstraint;
  update_columns?: Array<AssessmentUpdateColumn>;
  where?: InputMaybe<AssessmentBoolExp>;
};

/** Ordering options when selecting data from "server_assessment". */
export type AssessmentOrderBy = {
  assessmentItemsAggregate?: InputMaybe<AssessmentItemAggregateOrderBy>;
  associatedIcdCodes?: InputMaybe<OrderBy>;
  clinicalNoteTemplatesAggregate?: InputMaybe<ClinicalnoteTemplateAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  practicegroup?: InputMaybe<PracticegroupOrderBy>;
  renderedAssessmentsAggregate?: InputMaybe<RenderedAssessmentAggregateOrderBy>;
  treatmentPlanTemplatesAggregate?: InputMaybe<TreatmentplanTemplateAssessmentAggregateOrderBy>;
};

/** primary key columns input for table: server_assessment */
export type AssessmentPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_assessment" */
export enum AssessmentSelectColumn {
  /** column name */
  AssociatedIcdCodes = 'associatedIcdCodes',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PracticeGroupId = 'practiceGroupId'
}

/** input type for updating data in table "server_assessment" */
export type AssessmentSetInput = {
  associatedIcdCodes?: InputMaybe<Scalars['_varchar']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AssessmentStddevFields = {
  __typename?: 'assessmentStddevFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type AssessmentStddev_PopFields = {
  __typename?: 'assessmentStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type AssessmentStddev_SampFields = {
  __typename?: 'assessmentStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type AssessmentSumFields = {
  __typename?: 'assessmentSumFields';
  id?: Maybe<Scalars['Int']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_assessment" */
export enum AssessmentUpdateColumn {
  /** column name */
  AssociatedIcdCodes = 'associatedIcdCodes',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PracticeGroupId = 'practiceGroupId'
}

export type AssessmentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AssessmentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssessmentSetInput>;
  where: AssessmentBoolExp;
};

/** aggregate var_pop on columns */
export type AssessmentVar_PopFields = {
  __typename?: 'assessmentVar_popFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type AssessmentVar_SampFields = {
  __typename?: 'assessmentVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type AssessmentVarianceFields = {
  __typename?: 'assessmentVarianceFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_assessment" */
export type Assessment_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_assessment" */
export type Assessment_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_assessment" */
export type Assessment_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_assessment" */
export type Assessment_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_assessment" */
export type Assessment_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_assessment" */
export type Assessment_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "assessment" */
export type Assessment_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Assessment_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_StreamCursorValueInput = {
  associatedIcdCodes?: InputMaybe<Scalars['_varchar']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_assessment" */
export type Assessment_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_assessment" */
export type Assessment_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_assessment" */
export type Assessment_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_assessment" */
export type Assessment_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_baseprotocolorder" */
export type BaseProtocolOrder = {
  __typename?: 'baseProtocolOrder';
  dependantorderPtrId: Scalars['Int'];
  duration?: Maybe<Scalars['interval']>;
  /** An object relationship */
  parentOrder: DependantOrder;
};

/** aggregated selection of "server_baseprotocolorder" */
export type BaseProtocolOrderAggregate = {
  __typename?: 'baseProtocolOrderAggregate';
  aggregate?: Maybe<BaseProtocolOrderAggregateFields>;
  nodes: Array<BaseProtocolOrder>;
};

/** aggregate fields of "server_baseprotocolorder" */
export type BaseProtocolOrderAggregateFields = {
  __typename?: 'baseProtocolOrderAggregateFields';
  avg?: Maybe<BaseProtocolOrderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<BaseProtocolOrderMaxFields>;
  min?: Maybe<BaseProtocolOrderMinFields>;
  stddev?: Maybe<BaseProtocolOrderStddevFields>;
  stddevPop?: Maybe<BaseProtocolOrderStddev_PopFields>;
  stddevSamp?: Maybe<BaseProtocolOrderStddev_SampFields>;
  sum?: Maybe<BaseProtocolOrderSumFields>;
  varPop?: Maybe<BaseProtocolOrderVar_PopFields>;
  varSamp?: Maybe<BaseProtocolOrderVar_SampFields>;
  variance?: Maybe<BaseProtocolOrderVarianceFields>;
};


/** aggregate fields of "server_baseprotocolorder" */
export type BaseProtocolOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BaseProtocolOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type BaseProtocolOrderAvgFields = {
  __typename?: 'baseProtocolOrderAvgFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_baseprotocolorder". All fields are combined with a logical 'AND'. */
export type BaseProtocolOrderBoolExp = {
  _and?: InputMaybe<Array<BaseProtocolOrderBoolExp>>;
  _not?: InputMaybe<BaseProtocolOrderBoolExp>;
  _or?: InputMaybe<Array<BaseProtocolOrderBoolExp>>;
  dependantorderPtrId?: InputMaybe<IntComparisonExp>;
  duration?: InputMaybe<IntervalComparisonExp>;
  parentOrder?: InputMaybe<DependantOrderBoolExp>;
};

/** unique or primary key constraints on table "server_baseprotocolorder" */
export enum BaseProtocolOrderConstraint {
  /** unique or primary key constraint on columns "dependantorder_ptr_id" */
  ServerBaseprotocolorderPkey = 'server_baseprotocolorder_pkey'
}

/** input type for incrementing numeric columns in table "server_baseprotocolorder" */
export type BaseProtocolOrderIncInput = {
  dependantorderPtrId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_baseprotocolorder" */
export type BaseProtocolOrderInsertInput = {
  dependantorderPtrId?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['interval']>;
  parentOrder?: InputMaybe<DependantOrderObjRelInsertInput>;
};

/** aggregate max on columns */
export type BaseProtocolOrderMaxFields = {
  __typename?: 'baseProtocolOrderMaxFields';
  dependantorderPtrId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type BaseProtocolOrderMinFields = {
  __typename?: 'baseProtocolOrderMinFields';
  dependantorderPtrId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_baseprotocolorder" */
export type BaseProtocolOrderMutationResponse = {
  __typename?: 'baseProtocolOrderMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BaseProtocolOrder>;
};

/** input type for inserting object relation for remote table "server_baseprotocolorder" */
export type BaseProtocolOrderObjRelInsertInput = {
  data: BaseProtocolOrderInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<BaseProtocolOrderOnConflict>;
};

/** on_conflict condition type for table "server_baseprotocolorder" */
export type BaseProtocolOrderOnConflict = {
  constraint: BaseProtocolOrderConstraint;
  update_columns?: Array<BaseProtocolOrderUpdateColumn>;
  where?: InputMaybe<BaseProtocolOrderBoolExp>;
};

/** Ordering options when selecting data from "server_baseprotocolorder". */
export type BaseProtocolOrderOrderBy = {
  dependantorderPtrId?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  parentOrder?: InputMaybe<DependantOrderOrderBy>;
};

/** primary key columns input for table: server_baseprotocolorder */
export type BaseProtocolOrderPkColumnsInput = {
  dependantorderPtrId: Scalars['Int'];
};

/** select columns of table "server_baseprotocolorder" */
export enum BaseProtocolOrderSelectColumn {
  /** column name */
  DependantorderPtrId = 'dependantorderPtrId',
  /** column name */
  Duration = 'duration'
}

/** input type for updating data in table "server_baseprotocolorder" */
export type BaseProtocolOrderSetInput = {
  dependantorderPtrId?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['interval']>;
};

/** aggregate stddev on columns */
export type BaseProtocolOrderStddevFields = {
  __typename?: 'baseProtocolOrderStddevFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BaseProtocolOrderStddev_PopFields = {
  __typename?: 'baseProtocolOrderStddev_popFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BaseProtocolOrderStddev_SampFields = {
  __typename?: 'baseProtocolOrderStddev_sampFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type BaseProtocolOrderSumFields = {
  __typename?: 'baseProtocolOrderSumFields';
  dependantorderPtrId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_baseprotocolorder" */
export enum BaseProtocolOrderUpdateColumn {
  /** column name */
  DependantorderPtrId = 'dependantorderPtrId',
  /** column name */
  Duration = 'duration'
}

export type BaseProtocolOrderUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BaseProtocolOrderIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BaseProtocolOrderSetInput>;
  where: BaseProtocolOrderBoolExp;
};

/** aggregate var_pop on columns */
export type BaseProtocolOrderVar_PopFields = {
  __typename?: 'baseProtocolOrderVar_popFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BaseProtocolOrderVar_SampFields = {
  __typename?: 'baseProtocolOrderVar_sampFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BaseProtocolOrderVarianceFields = {
  __typename?: 'baseProtocolOrderVarianceFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "baseProtocolOrder" */
export type BaseProtocolOrder_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BaseProtocolOrder_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BaseProtocolOrder_StreamCursorValueInput = {
  dependantorderPtrId?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['interval']>;
};

/** columns and relationships of "server_baseorder" */
export type Baseorder = {
  __typename?: 'baseorder';
  /** A computed field, executes function "baseorder_active" */
  active?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "baseorder_approvalaction" */
  approvalAction?: Maybe<Array<Orderaction>>;
  /** A computed field, executes function "baseorder_approved" */
  approved?: Maybe<Scalars['Boolean']>;
  archivalApproved?: Maybe<Scalars['Boolean']>;
  archivalData?: Maybe<Scalars['jsonb']>;
  archivalType: Scalars['smallint'];
  /** An object relationship */
  associatedAppointment?: Maybe<Appointment>;
  associatedAppointmentId?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "baseorder_calculatedeffectivedate" */
  calculatedEffectiveDate?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "baseorder_cancelled" */
  cancelled?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  childOrders: Array<DependantOrder>;
  /** An aggregate relationship */
  childOrdersAggregate: DependantOrderAggregate;
  dateEffective?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "baseorder_enddate" */
  dateEnd?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  dependantOrder?: Maybe<DependantOrder>;
  /** An object relationship */
  destinationAgency?: Maybe<Agency>;
  destinationId?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "baseorder_expirationdate" */
  expirationDate?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "baseorder_expired" */
  expired?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  freetextOrder?: Maybe<FreeTextOrder>;
  /** An object relationship */
  historicalOrder?: Maybe<LegacyOrder>;
  historicalOrderId?: Maybe<Scalars['Int']>;
  icdCodes: Scalars['_varchar'];
  id: Scalars['Int'];
  /** An object relationship */
  medicationOrder?: Maybe<MedicationOrder>;
  notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  orderActions: Array<Orderaction>;
  /** An aggregate relationship */
  orderActionsAggregate: OrderactionAggregate;
  /** An array relationship */
  orderQualifications: Array<OrderableOrderQualification>;
  /** An aggregate relationship */
  orderQualificationsAggregate: OrderableOrderQualificationAggregate;
  orderStatus: Scalars['smallint'];
  /** An object relationship */
  orderableOrder?: Maybe<OrderableOrder>;
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  polymorphicCtypeId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  ticketThread?: Maybe<TicketThread>;
  validTime: Scalars['interval'];
};


/** columns and relationships of "server_baseorder" */
export type BaseorderApprovalActionArgs = {
  distinctOn?: InputMaybe<Array<OrderactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderactionOrderBy>>;
  where?: InputMaybe<OrderactionBoolExp>;
};


/** columns and relationships of "server_baseorder" */
export type BaseorderArchivalDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "server_baseorder" */
export type BaseorderChildOrdersArgs = {
  distinctOn?: InputMaybe<Array<DependantOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DependantOrderOrderBy>>;
  where?: InputMaybe<DependantOrderBoolExp>;
};


/** columns and relationships of "server_baseorder" */
export type BaseorderChildOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<DependantOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DependantOrderOrderBy>>;
  where?: InputMaybe<DependantOrderBoolExp>;
};


/** columns and relationships of "server_baseorder" */
export type BaseorderOrderActionsArgs = {
  distinctOn?: InputMaybe<Array<OrderactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderactionOrderBy>>;
  where?: InputMaybe<OrderactionBoolExp>;
};


/** columns and relationships of "server_baseorder" */
export type BaseorderOrderActionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderactionOrderBy>>;
  where?: InputMaybe<OrderactionBoolExp>;
};


/** columns and relationships of "server_baseorder" */
export type BaseorderOrderQualificationsArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderQualificationOrderBy>>;
  where?: InputMaybe<OrderableOrderQualificationBoolExp>;
};


/** columns and relationships of "server_baseorder" */
export type BaseorderOrderQualificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderQualificationOrderBy>>;
  where?: InputMaybe<OrderableOrderQualificationBoolExp>;
};

/** aggregated selection of "server_baseorder" */
export type BaseorderAggregate = {
  __typename?: 'baseorderAggregate';
  aggregate?: Maybe<BaseorderAggregateFields>;
  nodes: Array<Baseorder>;
};

/** aggregate fields of "server_baseorder" */
export type BaseorderAggregateFields = {
  __typename?: 'baseorderAggregateFields';
  avg?: Maybe<BaseorderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<BaseorderMaxFields>;
  min?: Maybe<BaseorderMinFields>;
  stddev?: Maybe<BaseorderStddevFields>;
  stddevPop?: Maybe<BaseorderStddev_PopFields>;
  stddevSamp?: Maybe<BaseorderStddev_SampFields>;
  sum?: Maybe<BaseorderSumFields>;
  varPop?: Maybe<BaseorderVar_PopFields>;
  varSamp?: Maybe<BaseorderVar_SampFields>;
  variance?: Maybe<BaseorderVarianceFields>;
};


/** aggregate fields of "server_baseorder" */
export type BaseorderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BaseorderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_baseorder" */
export type BaseorderAggregateOrderBy = {
  avg?: InputMaybe<Baseorder_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Baseorder_Max_Order_By>;
  min?: InputMaybe<Baseorder_Min_Order_By>;
  stddev?: InputMaybe<Baseorder_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Baseorder_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Baseorder_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Baseorder_Sum_Order_By>;
  var_pop?: InputMaybe<Baseorder_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Baseorder_Var_Samp_Order_By>;
  variance?: InputMaybe<Baseorder_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type BaseorderAppendInput = {
  archivalData?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_baseorder" */
export type BaseorderArrRelInsertInput = {
  data: Array<BaseorderInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<BaseorderOnConflict>;
};

/** aggregate avg on columns */
export type BaseorderAvgFields = {
  __typename?: 'baseorderAvgFields';
  archivalType?: Maybe<Scalars['Float']>;
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  historicalOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  polymorphicCtypeId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_baseorder". All fields are combined with a logical 'AND'. */
export type BaseorderBoolExp = {
  _and?: InputMaybe<Array<BaseorderBoolExp>>;
  _not?: InputMaybe<BaseorderBoolExp>;
  _or?: InputMaybe<Array<BaseorderBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  approvalAction?: InputMaybe<OrderactionBoolExp>;
  approved?: InputMaybe<BooleanComparisonExp>;
  archivalApproved?: InputMaybe<BooleanComparisonExp>;
  archivalData?: InputMaybe<JsonbComparisonExp>;
  archivalType?: InputMaybe<SmallintComparisonExp>;
  associatedAppointment?: InputMaybe<AppointmentBoolExp>;
  associatedAppointmentId?: InputMaybe<IntComparisonExp>;
  calculatedEffectiveDate?: InputMaybe<BooleanComparisonExp>;
  cancelled?: InputMaybe<BooleanComparisonExp>;
  childOrders?: InputMaybe<DependantOrderBoolExp>;
  dateEffective?: InputMaybe<TimestamptzComparisonExp>;
  dateEnd?: InputMaybe<TimestamptzComparisonExp>;
  dependantOrder?: InputMaybe<DependantOrderBoolExp>;
  destinationAgency?: InputMaybe<AgencyBoolExp>;
  destinationId?: InputMaybe<IntComparisonExp>;
  expirationDate?: InputMaybe<TimestamptzComparisonExp>;
  expired?: InputMaybe<BooleanComparisonExp>;
  freetextOrder?: InputMaybe<FreeTextOrderBoolExp>;
  historicalOrder?: InputMaybe<LegacyOrderBoolExp>;
  historicalOrderId?: InputMaybe<IntComparisonExp>;
  icdCodes?: InputMaybe<_VarcharComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  medicationOrder?: InputMaybe<MedicationOrderBoolExp>;
  notes?: InputMaybe<StringComparisonExp>;
  orderActions?: InputMaybe<OrderactionBoolExp>;
  orderQualifications?: InputMaybe<OrderableOrderQualificationBoolExp>;
  orderStatus?: InputMaybe<SmallintComparisonExp>;
  orderableOrder?: InputMaybe<OrderableOrderBoolExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  polymorphicCtypeId?: InputMaybe<IntComparisonExp>;
  ticketId?: InputMaybe<IntComparisonExp>;
  ticketThread?: InputMaybe<TicketThreadBoolExp>;
  validTime?: InputMaybe<IntervalComparisonExp>;
};

/** unique or primary key constraints on table "server_baseorder" */
export enum BaseorderConstraint {
  /** unique or primary key constraint on columns "historical_order_id" */
  ServerBaseorderHistoricalOrderIdE9cd0aecUniq = 'server_baseorder_historical_order_id_e9cd0aec_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerBaseorderPkey = 'server_baseorder_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type BaseorderDeleteAtPathInput = {
  archivalData?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type BaseorderDeleteElemInput = {
  archivalData?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type BaseorderDeleteKeyInput = {
  archivalData?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_baseorder" */
export type BaseorderIncInput = {
  archivalType?: InputMaybe<Scalars['smallint']>;
  associatedAppointmentId?: InputMaybe<Scalars['Int']>;
  destinationId?: InputMaybe<Scalars['Int']>;
  historicalOrderId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderStatus?: InputMaybe<Scalars['smallint']>;
  patientId?: InputMaybe<Scalars['Int']>;
  polymorphicCtypeId?: InputMaybe<Scalars['Int']>;
  ticketId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_baseorder" */
export type BaseorderInsertInput = {
  archivalApproved?: InputMaybe<Scalars['Boolean']>;
  archivalData?: InputMaybe<Scalars['jsonb']>;
  archivalType?: InputMaybe<Scalars['smallint']>;
  associatedAppointment?: InputMaybe<AppointmentObjRelInsertInput>;
  associatedAppointmentId?: InputMaybe<Scalars['Int']>;
  childOrders?: InputMaybe<DependantOrderArrRelInsertInput>;
  dateEffective?: InputMaybe<Scalars['timestamptz']>;
  dependantOrder?: InputMaybe<DependantOrderObjRelInsertInput>;
  destinationAgency?: InputMaybe<AgencyObjRelInsertInput>;
  destinationId?: InputMaybe<Scalars['Int']>;
  freetextOrder?: InputMaybe<FreeTextOrderObjRelInsertInput>;
  historicalOrder?: InputMaybe<LegacyOrderObjRelInsertInput>;
  historicalOrderId?: InputMaybe<Scalars['Int']>;
  icdCodes?: InputMaybe<Scalars['_varchar']>;
  id?: InputMaybe<Scalars['Int']>;
  medicationOrder?: InputMaybe<MedicationOrderObjRelInsertInput>;
  notes?: InputMaybe<Scalars['String']>;
  orderActions?: InputMaybe<OrderactionArrRelInsertInput>;
  orderQualifications?: InputMaybe<OrderableOrderQualificationArrRelInsertInput>;
  orderStatus?: InputMaybe<Scalars['smallint']>;
  orderableOrder?: InputMaybe<OrderableOrderObjRelInsertInput>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  polymorphicCtypeId?: InputMaybe<Scalars['Int']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  ticketThread?: InputMaybe<TicketThreadObjRelInsertInput>;
  validTime?: InputMaybe<Scalars['interval']>;
};

/** aggregate max on columns */
export type BaseorderMaxFields = {
  __typename?: 'baseorderMaxFields';
  archivalType?: Maybe<Scalars['smallint']>;
  associatedAppointmentId?: Maybe<Scalars['Int']>;
  dateEffective?: Maybe<Scalars['timestamptz']>;
  destinationId?: Maybe<Scalars['Int']>;
  historicalOrderId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['smallint']>;
  patientId?: Maybe<Scalars['Int']>;
  polymorphicCtypeId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type BaseorderMinFields = {
  __typename?: 'baseorderMinFields';
  archivalType?: Maybe<Scalars['smallint']>;
  associatedAppointmentId?: Maybe<Scalars['Int']>;
  dateEffective?: Maybe<Scalars['timestamptz']>;
  destinationId?: Maybe<Scalars['Int']>;
  historicalOrderId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['smallint']>;
  patientId?: Maybe<Scalars['Int']>;
  polymorphicCtypeId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_baseorder" */
export type BaseorderMutationResponse = {
  __typename?: 'baseorderMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Baseorder>;
};

/** input type for inserting object relation for remote table "server_baseorder" */
export type BaseorderObjRelInsertInput = {
  data: BaseorderInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<BaseorderOnConflict>;
};

/** on_conflict condition type for table "server_baseorder" */
export type BaseorderOnConflict = {
  constraint: BaseorderConstraint;
  update_columns?: Array<BaseorderUpdateColumn>;
  where?: InputMaybe<BaseorderBoolExp>;
};

/** Ordering options when selecting data from "server_baseorder". */
export type BaseorderOrderBy = {
  active?: InputMaybe<OrderBy>;
  approvalActionAggregate?: InputMaybe<OrderactionAggregateOrderBy>;
  approved?: InputMaybe<OrderBy>;
  archivalApproved?: InputMaybe<OrderBy>;
  archivalData?: InputMaybe<OrderBy>;
  archivalType?: InputMaybe<OrderBy>;
  associatedAppointment?: InputMaybe<AppointmentOrderBy>;
  associatedAppointmentId?: InputMaybe<OrderBy>;
  calculatedEffectiveDate?: InputMaybe<OrderBy>;
  cancelled?: InputMaybe<OrderBy>;
  childOrdersAggregate?: InputMaybe<DependantOrderAggregateOrderBy>;
  dateEffective?: InputMaybe<OrderBy>;
  dateEnd?: InputMaybe<OrderBy>;
  dependantOrder?: InputMaybe<DependantOrderOrderBy>;
  destinationAgency?: InputMaybe<AgencyOrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  expirationDate?: InputMaybe<OrderBy>;
  expired?: InputMaybe<OrderBy>;
  freetextOrder?: InputMaybe<FreeTextOrderOrderBy>;
  historicalOrder?: InputMaybe<LegacyOrderOrderBy>;
  historicalOrderId?: InputMaybe<OrderBy>;
  icdCodes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicationOrder?: InputMaybe<MedicationOrderOrderBy>;
  notes?: InputMaybe<OrderBy>;
  orderActionsAggregate?: InputMaybe<OrderactionAggregateOrderBy>;
  orderQualificationsAggregate?: InputMaybe<OrderableOrderQualificationAggregateOrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  orderableOrder?: InputMaybe<OrderableOrderOrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  polymorphicCtypeId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  ticketThread?: InputMaybe<TicketThreadOrderBy>;
  validTime?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_baseorder */
export type BaseorderPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type BaseorderPrependInput = {
  archivalData?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_baseorder" */
export enum BaseorderSelectColumn {
  /** column name */
  ArchivalApproved = 'archivalApproved',
  /** column name */
  ArchivalData = 'archivalData',
  /** column name */
  ArchivalType = 'archivalType',
  /** column name */
  AssociatedAppointmentId = 'associatedAppointmentId',
  /** column name */
  DateEffective = 'dateEffective',
  /** column name */
  DestinationId = 'destinationId',
  /** column name */
  HistoricalOrderId = 'historicalOrderId',
  /** column name */
  IcdCodes = 'icdCodes',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderStatus = 'orderStatus',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PolymorphicCtypeId = 'polymorphicCtypeId',
  /** column name */
  TicketId = 'ticketId',
  /** column name */
  ValidTime = 'validTime'
}

/** input type for updating data in table "server_baseorder" */
export type BaseorderSetInput = {
  archivalApproved?: InputMaybe<Scalars['Boolean']>;
  archivalData?: InputMaybe<Scalars['jsonb']>;
  archivalType?: InputMaybe<Scalars['smallint']>;
  associatedAppointmentId?: InputMaybe<Scalars['Int']>;
  dateEffective?: InputMaybe<Scalars['timestamptz']>;
  destinationId?: InputMaybe<Scalars['Int']>;
  historicalOrderId?: InputMaybe<Scalars['Int']>;
  icdCodes?: InputMaybe<Scalars['_varchar']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  orderStatus?: InputMaybe<Scalars['smallint']>;
  patientId?: InputMaybe<Scalars['Int']>;
  polymorphicCtypeId?: InputMaybe<Scalars['Int']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  validTime?: InputMaybe<Scalars['interval']>;
};

/** aggregate stddev on columns */
export type BaseorderStddevFields = {
  __typename?: 'baseorderStddevFields';
  archivalType?: Maybe<Scalars['Float']>;
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  historicalOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  polymorphicCtypeId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type BaseorderStddev_PopFields = {
  __typename?: 'baseorderStddev_popFields';
  archivalType?: Maybe<Scalars['Float']>;
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  historicalOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  polymorphicCtypeId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type BaseorderStddev_SampFields = {
  __typename?: 'baseorderStddev_sampFields';
  archivalType?: Maybe<Scalars['Float']>;
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  historicalOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  polymorphicCtypeId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type BaseorderSumFields = {
  __typename?: 'baseorderSumFields';
  archivalType?: Maybe<Scalars['smallint']>;
  associatedAppointmentId?: Maybe<Scalars['Int']>;
  destinationId?: Maybe<Scalars['Int']>;
  historicalOrderId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderStatus?: Maybe<Scalars['smallint']>;
  patientId?: Maybe<Scalars['Int']>;
  polymorphicCtypeId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_baseorder" */
export enum BaseorderUpdateColumn {
  /** column name */
  ArchivalApproved = 'archivalApproved',
  /** column name */
  ArchivalData = 'archivalData',
  /** column name */
  ArchivalType = 'archivalType',
  /** column name */
  AssociatedAppointmentId = 'associatedAppointmentId',
  /** column name */
  DateEffective = 'dateEffective',
  /** column name */
  DestinationId = 'destinationId',
  /** column name */
  HistoricalOrderId = 'historicalOrderId',
  /** column name */
  IcdCodes = 'icdCodes',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderStatus = 'orderStatus',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PolymorphicCtypeId = 'polymorphicCtypeId',
  /** column name */
  TicketId = 'ticketId',
  /** column name */
  ValidTime = 'validTime'
}

export type BaseorderUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<BaseorderAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<BaseorderDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<BaseorderDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<BaseorderDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BaseorderIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<BaseorderPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BaseorderSetInput>;
  where: BaseorderBoolExp;
};

/** aggregate var_pop on columns */
export type BaseorderVar_PopFields = {
  __typename?: 'baseorderVar_popFields';
  archivalType?: Maybe<Scalars['Float']>;
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  historicalOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  polymorphicCtypeId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type BaseorderVar_SampFields = {
  __typename?: 'baseorderVar_sampFields';
  archivalType?: Maybe<Scalars['Float']>;
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  historicalOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  polymorphicCtypeId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type BaseorderVarianceFields = {
  __typename?: 'baseorderVarianceFields';
  archivalType?: Maybe<Scalars['Float']>;
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  historicalOrderId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  polymorphicCtypeId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_baseorder" */
export type Baseorder_Avg_Order_By = {
  archivalType?: InputMaybe<OrderBy>;
  associatedAppointmentId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  historicalOrderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  polymorphicCtypeId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_baseorder" */
export type Baseorder_Max_Order_By = {
  archivalType?: InputMaybe<OrderBy>;
  associatedAppointmentId?: InputMaybe<OrderBy>;
  dateEffective?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  historicalOrderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  polymorphicCtypeId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_baseorder" */
export type Baseorder_Min_Order_By = {
  archivalType?: InputMaybe<OrderBy>;
  associatedAppointmentId?: InputMaybe<OrderBy>;
  dateEffective?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  historicalOrderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  polymorphicCtypeId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_baseorder" */
export type Baseorder_Stddev_Order_By = {
  archivalType?: InputMaybe<OrderBy>;
  associatedAppointmentId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  historicalOrderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  polymorphicCtypeId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_baseorder" */
export type Baseorder_Stddev_Pop_Order_By = {
  archivalType?: InputMaybe<OrderBy>;
  associatedAppointmentId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  historicalOrderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  polymorphicCtypeId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_baseorder" */
export type Baseorder_Stddev_Samp_Order_By = {
  archivalType?: InputMaybe<OrderBy>;
  associatedAppointmentId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  historicalOrderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  polymorphicCtypeId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "baseorder" */
export type Baseorder_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Baseorder_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Baseorder_StreamCursorValueInput = {
  archivalApproved?: InputMaybe<Scalars['Boolean']>;
  archivalData?: InputMaybe<Scalars['jsonb']>;
  archivalType?: InputMaybe<Scalars['smallint']>;
  associatedAppointmentId?: InputMaybe<Scalars['Int']>;
  dateEffective?: InputMaybe<Scalars['timestamptz']>;
  destinationId?: InputMaybe<Scalars['Int']>;
  historicalOrderId?: InputMaybe<Scalars['Int']>;
  icdCodes?: InputMaybe<Scalars['_varchar']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  orderStatus?: InputMaybe<Scalars['smallint']>;
  patientId?: InputMaybe<Scalars['Int']>;
  polymorphicCtypeId?: InputMaybe<Scalars['Int']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  validTime?: InputMaybe<Scalars['interval']>;
};

/** order by sum() on columns of table "server_baseorder" */
export type Baseorder_Sum_Order_By = {
  archivalType?: InputMaybe<OrderBy>;
  associatedAppointmentId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  historicalOrderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  polymorphicCtypeId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_baseorder" */
export type Baseorder_Var_Pop_Order_By = {
  archivalType?: InputMaybe<OrderBy>;
  associatedAppointmentId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  historicalOrderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  polymorphicCtypeId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_baseorder" */
export type Baseorder_Var_Samp_Order_By = {
  archivalType?: InputMaybe<OrderBy>;
  associatedAppointmentId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  historicalOrderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  polymorphicCtypeId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_baseorder" */
export type Baseorder_Variance_Order_By = {
  archivalType?: InputMaybe<OrderBy>;
  associatedAppointmentId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  historicalOrderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  polymorphicCtypeId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_caretime" */
export type Caretime = {
  __typename?: 'caretime';
  /** An array relationship */
  appointments: Array<Appointment>;
  /** An aggregate relationship */
  appointmentsAggregate: AppointmentAggregate;
  /** An object relationship */
  claim?: Maybe<Claim>;
  claimId?: Maybe<Scalars['Int']>;
  datetime: Scalars['timestamptz'];
  duration: Scalars['smallint'];
  id: Scalars['Int'];
  /** An object relationship */
  message?: Maybe<Message>;
  messageId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  patient?: Maybe<Patient>;
  /** An array relationship */
  patientCommunications: Array<PatientCommunication>;
  /** An aggregate relationship */
  patientCommunicationsAggregate: PatientCommunicationAggregate;
  patientId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  problems: Array<CaretimeProblems>;
  /** An aggregate relationship */
  problemsAggregate: CaretimeProblemsAggregate;
  /** An array relationship */
  resultedFromRebills: Array<VisitRebill>;
  /** An aggregate relationship */
  resultedFromRebillsAggregate: VisitRebillAggregate;
  ticketId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  ticketThread?: Maybe<TicketThread>;
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "server_caretime" */
export type CaretimeAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


/** columns and relationships of "server_caretime" */
export type CaretimeAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


/** columns and relationships of "server_caretime" */
export type CaretimePatientCommunicationsArgs = {
  distinctOn?: InputMaybe<Array<PatientCommunicationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientCommunicationOrderBy>>;
  where?: InputMaybe<PatientCommunicationBoolExp>;
};


/** columns and relationships of "server_caretime" */
export type CaretimePatientCommunicationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientCommunicationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientCommunicationOrderBy>>;
  where?: InputMaybe<PatientCommunicationBoolExp>;
};


/** columns and relationships of "server_caretime" */
export type CaretimeProblemsArgs = {
  distinctOn?: InputMaybe<Array<CaretimeProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeProblemsOrderBy>>;
  where?: InputMaybe<CaretimeProblemsBoolExp>;
};


/** columns and relationships of "server_caretime" */
export type CaretimeProblemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CaretimeProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeProblemsOrderBy>>;
  where?: InputMaybe<CaretimeProblemsBoolExp>;
};


/** columns and relationships of "server_caretime" */
export type CaretimeResultedFromRebillsArgs = {
  distinctOn?: InputMaybe<Array<VisitRebillSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VisitRebillOrderBy>>;
  where?: InputMaybe<VisitRebillBoolExp>;
};


/** columns and relationships of "server_caretime" */
export type CaretimeResultedFromRebillsAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitRebillSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VisitRebillOrderBy>>;
  where?: InputMaybe<VisitRebillBoolExp>;
};

/** aggregated selection of "server_caretime" */
export type CaretimeAggregate = {
  __typename?: 'caretimeAggregate';
  aggregate?: Maybe<CaretimeAggregateFields>;
  nodes: Array<Caretime>;
};

/** aggregate fields of "server_caretime" */
export type CaretimeAggregateFields = {
  __typename?: 'caretimeAggregateFields';
  avg?: Maybe<CaretimeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CaretimeMaxFields>;
  min?: Maybe<CaretimeMinFields>;
  stddev?: Maybe<CaretimeStddevFields>;
  stddevPop?: Maybe<CaretimeStddev_PopFields>;
  stddevSamp?: Maybe<CaretimeStddev_SampFields>;
  sum?: Maybe<CaretimeSumFields>;
  varPop?: Maybe<CaretimeVar_PopFields>;
  varSamp?: Maybe<CaretimeVar_SampFields>;
  variance?: Maybe<CaretimeVarianceFields>;
};


/** aggregate fields of "server_caretime" */
export type CaretimeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CaretimeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_caretime" */
export type CaretimeAggregateOrderBy = {
  avg?: InputMaybe<Caretime_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Caretime_Max_Order_By>;
  min?: InputMaybe<Caretime_Min_Order_By>;
  stddev?: InputMaybe<Caretime_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Caretime_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Caretime_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Caretime_Sum_Order_By>;
  var_pop?: InputMaybe<Caretime_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Caretime_Var_Samp_Order_By>;
  variance?: InputMaybe<Caretime_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_caretime" */
export type CaretimeArrRelInsertInput = {
  data: Array<CaretimeInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CaretimeOnConflict>;
};

/** aggregate avg on columns */
export type CaretimeAvgFields = {
  __typename?: 'caretimeAvgFields';
  claimId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_caretime". All fields are combined with a logical 'AND'. */
export type CaretimeBoolExp = {
  _and?: InputMaybe<Array<CaretimeBoolExp>>;
  _not?: InputMaybe<CaretimeBoolExp>;
  _or?: InputMaybe<Array<CaretimeBoolExp>>;
  appointments?: InputMaybe<AppointmentBoolExp>;
  claim?: InputMaybe<ClaimBoolExp>;
  claimId?: InputMaybe<IntComparisonExp>;
  datetime?: InputMaybe<TimestamptzComparisonExp>;
  duration?: InputMaybe<SmallintComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  message?: InputMaybe<MessageBoolExp>;
  messageId?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientCommunications?: InputMaybe<PatientCommunicationBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  problems?: InputMaybe<CaretimeProblemsBoolExp>;
  resultedFromRebills?: InputMaybe<VisitRebillBoolExp>;
  ticketId?: InputMaybe<IntComparisonExp>;
  ticketThread?: InputMaybe<TicketThreadBoolExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_caretime" */
export enum CaretimeConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerCaretimePkey = 'server_caretime_pkey'
}

/** input type for incrementing numeric columns in table "server_caretime" */
export type CaretimeIncInput = {
  claimId?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  messageId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_caretime" */
export type CaretimeInsertInput = {
  appointments?: InputMaybe<AppointmentArrRelInsertInput>;
  claim?: InputMaybe<ClaimObjRelInsertInput>;
  claimId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<MessageObjRelInsertInput>;
  messageId?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientCommunications?: InputMaybe<PatientCommunicationArrRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  problems?: InputMaybe<CaretimeProblemsArrRelInsertInput>;
  resultedFromRebills?: InputMaybe<VisitRebillArrRelInsertInput>;
  ticketId?: InputMaybe<Scalars['Int']>;
  ticketThread?: InputMaybe<TicketThreadObjRelInsertInput>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CaretimeMaxFields = {
  __typename?: 'caretimeMaxFields';
  claimId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  messageId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CaretimeMinFields = {
  __typename?: 'caretimeMinFields';
  claimId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  messageId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_caretime" */
export type CaretimeMutationResponse = {
  __typename?: 'caretimeMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Caretime>;
};

/** input type for inserting object relation for remote table "server_caretime" */
export type CaretimeObjRelInsertInput = {
  data: CaretimeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CaretimeOnConflict>;
};

/** on_conflict condition type for table "server_caretime" */
export type CaretimeOnConflict = {
  constraint: CaretimeConstraint;
  update_columns?: Array<CaretimeUpdateColumn>;
  where?: InputMaybe<CaretimeBoolExp>;
};

/** Ordering options when selecting data from "server_caretime". */
export type CaretimeOrderBy = {
  appointmentsAggregate?: InputMaybe<AppointmentAggregateOrderBy>;
  claim?: InputMaybe<ClaimOrderBy>;
  claimId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  message?: InputMaybe<MessageOrderBy>;
  messageId?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientCommunicationsAggregate?: InputMaybe<PatientCommunicationAggregateOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  problemsAggregate?: InputMaybe<CaretimeProblemsAggregateOrderBy>;
  resultedFromRebillsAggregate?: InputMaybe<VisitRebillAggregateOrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  ticketThread?: InputMaybe<TicketThreadOrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_caretime */
export type CaretimePkColumnsInput = {
  id: Scalars['Int'];
};

/** columns and relationships of "server_caretime_problems" */
export type CaretimeProblems = {
  __typename?: 'caretimeProblems';
  /** An object relationship */
  caretime: Caretime;
  caretimeId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  problem: Problem;
  problemId: Scalars['Int'];
};

/** aggregated selection of "server_caretime_problems" */
export type CaretimeProblemsAggregate = {
  __typename?: 'caretimeProblemsAggregate';
  aggregate?: Maybe<CaretimeProblemsAggregateFields>;
  nodes: Array<CaretimeProblems>;
};

/** aggregate fields of "server_caretime_problems" */
export type CaretimeProblemsAggregateFields = {
  __typename?: 'caretimeProblemsAggregateFields';
  avg?: Maybe<CaretimeProblemsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CaretimeProblemsMaxFields>;
  min?: Maybe<CaretimeProblemsMinFields>;
  stddev?: Maybe<CaretimeProblemsStddevFields>;
  stddevPop?: Maybe<CaretimeProblemsStddev_PopFields>;
  stddevSamp?: Maybe<CaretimeProblemsStddev_SampFields>;
  sum?: Maybe<CaretimeProblemsSumFields>;
  varPop?: Maybe<CaretimeProblemsVar_PopFields>;
  varSamp?: Maybe<CaretimeProblemsVar_SampFields>;
  variance?: Maybe<CaretimeProblemsVarianceFields>;
};


/** aggregate fields of "server_caretime_problems" */
export type CaretimeProblemsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CaretimeProblemsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_caretime_problems" */
export type CaretimeProblemsAggregateOrderBy = {
  avg?: InputMaybe<CaretimeProblems_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CaretimeProblems_Max_Order_By>;
  min?: InputMaybe<CaretimeProblems_Min_Order_By>;
  stddev?: InputMaybe<CaretimeProblems_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CaretimeProblems_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CaretimeProblems_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CaretimeProblems_Sum_Order_By>;
  var_pop?: InputMaybe<CaretimeProblems_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CaretimeProblems_Var_Samp_Order_By>;
  variance?: InputMaybe<CaretimeProblems_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_caretime_problems" */
export type CaretimeProblemsArrRelInsertInput = {
  data: Array<CaretimeProblemsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CaretimeProblemsOnConflict>;
};

/** aggregate avg on columns */
export type CaretimeProblemsAvgFields = {
  __typename?: 'caretimeProblemsAvgFields';
  caretimeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_caretime_problems". All fields are combined with a logical 'AND'. */
export type CaretimeProblemsBoolExp = {
  _and?: InputMaybe<Array<CaretimeProblemsBoolExp>>;
  _not?: InputMaybe<CaretimeProblemsBoolExp>;
  _or?: InputMaybe<Array<CaretimeProblemsBoolExp>>;
  caretime?: InputMaybe<CaretimeBoolExp>;
  caretimeId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  problem?: InputMaybe<ProblemBoolExp>;
  problemId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_caretime_problems" */
export enum CaretimeProblemsConstraint {
  /** unique or primary key constraint on columns "problem_id", "caretime_id" */
  ServerCaretimeProblemsCaretimeIdProblemId_95f7791aUniq = 'server_caretime_problems_caretime_id_problem_id_95f7791a_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerCaretimeProblemsPkey = 'server_caretime_problems_pkey'
}

/** input type for incrementing numeric columns in table "server_caretime_problems" */
export type CaretimeProblemsIncInput = {
  caretimeId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  problemId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_caretime_problems" */
export type CaretimeProblemsInsertInput = {
  caretime?: InputMaybe<CaretimeObjRelInsertInput>;
  caretimeId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  problem?: InputMaybe<ProblemObjRelInsertInput>;
  problemId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CaretimeProblemsMaxFields = {
  __typename?: 'caretimeProblemsMaxFields';
  caretimeId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  problemId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CaretimeProblemsMinFields = {
  __typename?: 'caretimeProblemsMinFields';
  caretimeId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  problemId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_caretime_problems" */
export type CaretimeProblemsMutationResponse = {
  __typename?: 'caretimeProblemsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CaretimeProblems>;
};

/** on_conflict condition type for table "server_caretime_problems" */
export type CaretimeProblemsOnConflict = {
  constraint: CaretimeProblemsConstraint;
  update_columns?: Array<CaretimeProblemsUpdateColumn>;
  where?: InputMaybe<CaretimeProblemsBoolExp>;
};

/** Ordering options when selecting data from "server_caretime_problems". */
export type CaretimeProblemsOrderBy = {
  caretime?: InputMaybe<CaretimeOrderBy>;
  caretimeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problem?: InputMaybe<ProblemOrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_caretime_problems */
export type CaretimeProblemsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_caretime_problems" */
export enum CaretimeProblemsSelectColumn {
  /** column name */
  CaretimeId = 'caretimeId',
  /** column name */
  Id = 'id',
  /** column name */
  ProblemId = 'problemId'
}

/** input type for updating data in table "server_caretime_problems" */
export type CaretimeProblemsSetInput = {
  caretimeId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  problemId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CaretimeProblemsStddevFields = {
  __typename?: 'caretimeProblemsStddevFields';
  caretimeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CaretimeProblemsStddev_PopFields = {
  __typename?: 'caretimeProblemsStddev_popFields';
  caretimeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CaretimeProblemsStddev_SampFields = {
  __typename?: 'caretimeProblemsStddev_sampFields';
  caretimeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CaretimeProblemsSumFields = {
  __typename?: 'caretimeProblemsSumFields';
  caretimeId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  problemId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_caretime_problems" */
export enum CaretimeProblemsUpdateColumn {
  /** column name */
  CaretimeId = 'caretimeId',
  /** column name */
  Id = 'id',
  /** column name */
  ProblemId = 'problemId'
}

export type CaretimeProblemsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CaretimeProblemsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CaretimeProblemsSetInput>;
  where: CaretimeProblemsBoolExp;
};

/** aggregate var_pop on columns */
export type CaretimeProblemsVar_PopFields = {
  __typename?: 'caretimeProblemsVar_popFields';
  caretimeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CaretimeProblemsVar_SampFields = {
  __typename?: 'caretimeProblemsVar_sampFields';
  caretimeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CaretimeProblemsVarianceFields = {
  __typename?: 'caretimeProblemsVarianceFields';
  caretimeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_caretime_problems" */
export type CaretimeProblems_Avg_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_caretime_problems" */
export type CaretimeProblems_Max_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_caretime_problems" */
export type CaretimeProblems_Min_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_caretime_problems" */
export type CaretimeProblems_Stddev_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_caretime_problems" */
export type CaretimeProblems_Stddev_Pop_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_caretime_problems" */
export type CaretimeProblems_Stddev_Samp_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "caretimeProblems" */
export type CaretimeProblems_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CaretimeProblems_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CaretimeProblems_StreamCursorValueInput = {
  caretimeId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  problemId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_caretime_problems" */
export type CaretimeProblems_Sum_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_caretime_problems" */
export type CaretimeProblems_Var_Pop_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_caretime_problems" */
export type CaretimeProblems_Var_Samp_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_caretime_problems" */
export type CaretimeProblems_Variance_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** select columns of table "server_caretime" */
export enum CaretimeSelectColumn {
  /** column name */
  ClaimId = 'claimId',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'messageId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  TicketId = 'ticketId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "server_caretime" */
export type CaretimeSetInput = {
  claimId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  messageId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CaretimeStddevFields = {
  __typename?: 'caretimeStddevFields';
  claimId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CaretimeStddev_PopFields = {
  __typename?: 'caretimeStddev_popFields';
  claimId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CaretimeStddev_SampFields = {
  __typename?: 'caretimeStddev_sampFields';
  claimId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CaretimeSumFields = {
  __typename?: 'caretimeSumFields';
  claimId?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  messageId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_caretime" */
export enum CaretimeUpdateColumn {
  /** column name */
  ClaimId = 'claimId',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'messageId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  TicketId = 'ticketId',
  /** column name */
  UserId = 'userId'
}

export type CaretimeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CaretimeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CaretimeSetInput>;
  where: CaretimeBoolExp;
};

/** aggregate var_pop on columns */
export type CaretimeVar_PopFields = {
  __typename?: 'caretimeVar_popFields';
  claimId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CaretimeVar_SampFields = {
  __typename?: 'caretimeVar_sampFields';
  claimId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CaretimeVarianceFields = {
  __typename?: 'caretimeVarianceFields';
  claimId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_caretime" */
export type Caretime_Avg_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_caretime" */
export type Caretime_Max_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_caretime" */
export type Caretime_Min_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_caretime" */
export type Caretime_Stddev_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_caretime" */
export type Caretime_Stddev_Pop_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_caretime" */
export type Caretime_Stddev_Samp_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "caretime" */
export type Caretime_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Caretime_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Caretime_StreamCursorValueInput = {
  claimId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  messageId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_caretime" */
export type Caretime_Sum_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_caretime" */
export type Caretime_Var_Pop_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_caretime" */
export type Caretime_Var_Samp_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_caretime" */
export type Caretime_Variance_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_claim" */
export type Claim = {
  __typename?: 'claim';
  /** An array relationship */
  appointments: Array<Appointment>;
  /** An aggregate relationship */
  appointmentsAggregate: AppointmentAggregate;
  /** An object relationship */
  billingProvider: Provider;
  billingProviderId: Scalars['Int'];
  /** An array relationship */
  caretimes: Array<Caretime>;
  /** An aggregate relationship */
  caretimesAggregate: CaretimeAggregate;
  /** An object relationship */
  cpoClaim?: Maybe<CpoClaim>;
  dateofservice?: Maybe<Scalars['date']>;
  drchronoId?: Maybe<Scalars['Int']>;
  icdCodes?: Maybe<Scalars['_varchar']>;
  id: Scalars['Int'];
  /** An array relationship */
  lineitems: Array<Lineitem>;
  /** An aggregate relationship */
  lineitemsAggregate: LineitemAggregate;
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  placeOfService: Scalars['smallint'];
  status: Scalars['smallint'];
  type: Scalars['smallint'];
  wellnessIcd?: Maybe<Scalars['String']>;
};


/** columns and relationships of "server_claim" */
export type ClaimAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


/** columns and relationships of "server_claim" */
export type ClaimAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


/** columns and relationships of "server_claim" */
export type ClaimCaretimesArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


/** columns and relationships of "server_claim" */
export type ClaimCaretimesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


/** columns and relationships of "server_claim" */
export type ClaimLineitemsArgs = {
  distinctOn?: InputMaybe<Array<LineitemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LineitemOrderBy>>;
  where?: InputMaybe<LineitemBoolExp>;
};


/** columns and relationships of "server_claim" */
export type ClaimLineitemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<LineitemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LineitemOrderBy>>;
  where?: InputMaybe<LineitemBoolExp>;
};

/** aggregated selection of "server_claim" */
export type ClaimAggregate = {
  __typename?: 'claimAggregate';
  aggregate?: Maybe<ClaimAggregateFields>;
  nodes: Array<Claim>;
};

/** aggregate fields of "server_claim" */
export type ClaimAggregateFields = {
  __typename?: 'claimAggregateFields';
  avg?: Maybe<ClaimAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClaimMaxFields>;
  min?: Maybe<ClaimMinFields>;
  stddev?: Maybe<ClaimStddevFields>;
  stddevPop?: Maybe<ClaimStddev_PopFields>;
  stddevSamp?: Maybe<ClaimStddev_SampFields>;
  sum?: Maybe<ClaimSumFields>;
  varPop?: Maybe<ClaimVar_PopFields>;
  varSamp?: Maybe<ClaimVar_SampFields>;
  variance?: Maybe<ClaimVarianceFields>;
};


/** aggregate fields of "server_claim" */
export type ClaimAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClaimSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_claim" */
export type ClaimAggregateOrderBy = {
  avg?: InputMaybe<Claim_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Claim_Max_Order_By>;
  min?: InputMaybe<Claim_Min_Order_By>;
  stddev?: InputMaybe<Claim_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Claim_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Claim_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Claim_Sum_Order_By>;
  var_pop?: InputMaybe<Claim_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Claim_Var_Samp_Order_By>;
  variance?: InputMaybe<Claim_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_claim" */
export type ClaimArrRelInsertInput = {
  data: Array<ClaimInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClaimOnConflict>;
};

/** aggregate avg on columns */
export type ClaimAvgFields = {
  __typename?: 'claimAvgFields';
  billingProviderId?: Maybe<Scalars['Float']>;
  drchronoId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_claim". All fields are combined with a logical 'AND'. */
export type ClaimBoolExp = {
  _and?: InputMaybe<Array<ClaimBoolExp>>;
  _not?: InputMaybe<ClaimBoolExp>;
  _or?: InputMaybe<Array<ClaimBoolExp>>;
  appointments?: InputMaybe<AppointmentBoolExp>;
  billingProvider?: InputMaybe<ProviderBoolExp>;
  billingProviderId?: InputMaybe<IntComparisonExp>;
  caretimes?: InputMaybe<CaretimeBoolExp>;
  cpoClaim?: InputMaybe<CpoClaimBoolExp>;
  dateofservice?: InputMaybe<DateComparisonExp>;
  drchronoId?: InputMaybe<IntComparisonExp>;
  icdCodes?: InputMaybe<_VarcharComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lineitems?: InputMaybe<LineitemBoolExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  placeOfService?: InputMaybe<SmallintComparisonExp>;
  status?: InputMaybe<SmallintComparisonExp>;
  type?: InputMaybe<SmallintComparisonExp>;
  wellnessIcd?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_claim" */
export enum ClaimConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerClaimPkey = 'server_claim_pkey'
}

/** input type for incrementing numeric columns in table "server_claim" */
export type ClaimIncInput = {
  billingProviderId?: InputMaybe<Scalars['Int']>;
  drchronoId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  placeOfService?: InputMaybe<Scalars['smallint']>;
  status?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_claim" */
export type ClaimInsertInput = {
  appointments?: InputMaybe<AppointmentArrRelInsertInput>;
  billingProvider?: InputMaybe<ProviderObjRelInsertInput>;
  billingProviderId?: InputMaybe<Scalars['Int']>;
  caretimes?: InputMaybe<CaretimeArrRelInsertInput>;
  cpoClaim?: InputMaybe<CpoClaimObjRelInsertInput>;
  dateofservice?: InputMaybe<Scalars['date']>;
  drchronoId?: InputMaybe<Scalars['Int']>;
  icdCodes?: InputMaybe<Scalars['_varchar']>;
  id?: InputMaybe<Scalars['Int']>;
  lineitems?: InputMaybe<LineitemArrRelInsertInput>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  placeOfService?: InputMaybe<Scalars['smallint']>;
  status?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Scalars['smallint']>;
  wellnessIcd?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClaimMaxFields = {
  __typename?: 'claimMaxFields';
  billingProviderId?: Maybe<Scalars['Int']>;
  dateofservice?: Maybe<Scalars['date']>;
  drchronoId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  placeOfService?: Maybe<Scalars['smallint']>;
  status?: Maybe<Scalars['smallint']>;
  type?: Maybe<Scalars['smallint']>;
  wellnessIcd?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ClaimMinFields = {
  __typename?: 'claimMinFields';
  billingProviderId?: Maybe<Scalars['Int']>;
  dateofservice?: Maybe<Scalars['date']>;
  drchronoId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  placeOfService?: Maybe<Scalars['smallint']>;
  status?: Maybe<Scalars['smallint']>;
  type?: Maybe<Scalars['smallint']>;
  wellnessIcd?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_claim" */
export type ClaimMutationResponse = {
  __typename?: 'claimMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Claim>;
};

/** input type for inserting object relation for remote table "server_claim" */
export type ClaimObjRelInsertInput = {
  data: ClaimInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClaimOnConflict>;
};

/** on_conflict condition type for table "server_claim" */
export type ClaimOnConflict = {
  constraint: ClaimConstraint;
  update_columns?: Array<ClaimUpdateColumn>;
  where?: InputMaybe<ClaimBoolExp>;
};

/** Ordering options when selecting data from "server_claim". */
export type ClaimOrderBy = {
  appointmentsAggregate?: InputMaybe<AppointmentAggregateOrderBy>;
  billingProvider?: InputMaybe<ProviderOrderBy>;
  billingProviderId?: InputMaybe<OrderBy>;
  caretimesAggregate?: InputMaybe<CaretimeAggregateOrderBy>;
  cpoClaim?: InputMaybe<CpoClaimOrderBy>;
  dateofservice?: InputMaybe<OrderBy>;
  drchronoId?: InputMaybe<OrderBy>;
  icdCodes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lineitemsAggregate?: InputMaybe<LineitemAggregateOrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  wellnessIcd?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_claim */
export type ClaimPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_claim" */
export enum ClaimSelectColumn {
  /** column name */
  BillingProviderId = 'billingProviderId',
  /** column name */
  Dateofservice = 'dateofservice',
  /** column name */
  DrchronoId = 'drchronoId',
  /** column name */
  IcdCodes = 'icdCodes',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PlaceOfService = 'placeOfService',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  WellnessIcd = 'wellnessIcd'
}

/** input type for updating data in table "server_claim" */
export type ClaimSetInput = {
  billingProviderId?: InputMaybe<Scalars['Int']>;
  dateofservice?: InputMaybe<Scalars['date']>;
  drchronoId?: InputMaybe<Scalars['Int']>;
  icdCodes?: InputMaybe<Scalars['_varchar']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  placeOfService?: InputMaybe<Scalars['smallint']>;
  status?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Scalars['smallint']>;
  wellnessIcd?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ClaimStddevFields = {
  __typename?: 'claimStddevFields';
  billingProviderId?: Maybe<Scalars['Float']>;
  drchronoId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClaimStddev_PopFields = {
  __typename?: 'claimStddev_popFields';
  billingProviderId?: Maybe<Scalars['Float']>;
  drchronoId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClaimStddev_SampFields = {
  __typename?: 'claimStddev_sampFields';
  billingProviderId?: Maybe<Scalars['Float']>;
  drchronoId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ClaimSumFields = {
  __typename?: 'claimSumFields';
  billingProviderId?: Maybe<Scalars['Int']>;
  drchronoId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  placeOfService?: Maybe<Scalars['smallint']>;
  status?: Maybe<Scalars['smallint']>;
  type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_claim" */
export enum ClaimUpdateColumn {
  /** column name */
  BillingProviderId = 'billingProviderId',
  /** column name */
  Dateofservice = 'dateofservice',
  /** column name */
  DrchronoId = 'drchronoId',
  /** column name */
  IcdCodes = 'icdCodes',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PlaceOfService = 'placeOfService',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  WellnessIcd = 'wellnessIcd'
}

export type ClaimUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClaimIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClaimSetInput>;
  where: ClaimBoolExp;
};

/** aggregate var_pop on columns */
export type ClaimVar_PopFields = {
  __typename?: 'claimVar_popFields';
  billingProviderId?: Maybe<Scalars['Float']>;
  drchronoId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClaimVar_SampFields = {
  __typename?: 'claimVar_sampFields';
  billingProviderId?: Maybe<Scalars['Float']>;
  drchronoId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClaimVarianceFields = {
  __typename?: 'claimVarianceFields';
  billingProviderId?: Maybe<Scalars['Float']>;
  drchronoId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  placeOfService?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_claim" */
export type Claim_Avg_Order_By = {
  billingProviderId?: InputMaybe<OrderBy>;
  drchronoId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_claim" */
export type Claim_Max_Order_By = {
  billingProviderId?: InputMaybe<OrderBy>;
  dateofservice?: InputMaybe<OrderBy>;
  drchronoId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  wellnessIcd?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_claim" */
export type Claim_Min_Order_By = {
  billingProviderId?: InputMaybe<OrderBy>;
  dateofservice?: InputMaybe<OrderBy>;
  drchronoId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  wellnessIcd?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_claim" */
export type Claim_Stddev_Order_By = {
  billingProviderId?: InputMaybe<OrderBy>;
  drchronoId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_claim" */
export type Claim_Stddev_Pop_Order_By = {
  billingProviderId?: InputMaybe<OrderBy>;
  drchronoId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_claim" */
export type Claim_Stddev_Samp_Order_By = {
  billingProviderId?: InputMaybe<OrderBy>;
  drchronoId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "claim" */
export type Claim_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Claim_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Claim_StreamCursorValueInput = {
  billingProviderId?: InputMaybe<Scalars['Int']>;
  dateofservice?: InputMaybe<Scalars['date']>;
  drchronoId?: InputMaybe<Scalars['Int']>;
  icdCodes?: InputMaybe<Scalars['_varchar']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  placeOfService?: InputMaybe<Scalars['smallint']>;
  status?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Scalars['smallint']>;
  wellnessIcd?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_claim" */
export type Claim_Sum_Order_By = {
  billingProviderId?: InputMaybe<OrderBy>;
  drchronoId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_claim" */
export type Claim_Var_Pop_Order_By = {
  billingProviderId?: InputMaybe<OrderBy>;
  drchronoId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_claim" */
export type Claim_Var_Samp_Order_By = {
  billingProviderId?: InputMaybe<OrderBy>;
  drchronoId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_claim" */
export type Claim_Variance_Order_By = {
  billingProviderId?: InputMaybe<OrderBy>;
  drchronoId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  placeOfService?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferrals = {
  __typename?: 'clinicalNoteFutureReferrals';
  active: Scalars['Boolean'];
  clinicalNoteId: Scalars['Int'];
  /** An object relationship */
  clinicalnote: Clinicalnote;
  id: Scalars['Int'];
  /** An object relationship */
  referral: Referral;
  referralId: Scalars['Int'];
  sent: Scalars['Boolean'];
};

/** aggregated selection of "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferralsAggregate = {
  __typename?: 'clinicalNoteFutureReferralsAggregate';
  aggregate?: Maybe<ClinicalNoteFutureReferralsAggregateFields>;
  nodes: Array<ClinicalNoteFutureReferrals>;
};

/** aggregate fields of "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferralsAggregateFields = {
  __typename?: 'clinicalNoteFutureReferralsAggregateFields';
  avg?: Maybe<ClinicalNoteFutureReferralsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClinicalNoteFutureReferralsMaxFields>;
  min?: Maybe<ClinicalNoteFutureReferralsMinFields>;
  stddev?: Maybe<ClinicalNoteFutureReferralsStddevFields>;
  stddevPop?: Maybe<ClinicalNoteFutureReferralsStddev_PopFields>;
  stddevSamp?: Maybe<ClinicalNoteFutureReferralsStddev_SampFields>;
  sum?: Maybe<ClinicalNoteFutureReferralsSumFields>;
  varPop?: Maybe<ClinicalNoteFutureReferralsVar_PopFields>;
  varSamp?: Maybe<ClinicalNoteFutureReferralsVar_SampFields>;
  variance?: Maybe<ClinicalNoteFutureReferralsVarianceFields>;
};


/** aggregate fields of "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferralsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClinicalNoteFutureReferralsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferralsAggregateOrderBy = {
  avg?: InputMaybe<ClinicalNoteFutureReferrals_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClinicalNoteFutureReferrals_Max_Order_By>;
  min?: InputMaybe<ClinicalNoteFutureReferrals_Min_Order_By>;
  stddev?: InputMaybe<ClinicalNoteFutureReferrals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ClinicalNoteFutureReferrals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ClinicalNoteFutureReferrals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ClinicalNoteFutureReferrals_Sum_Order_By>;
  var_pop?: InputMaybe<ClinicalNoteFutureReferrals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ClinicalNoteFutureReferrals_Var_Samp_Order_By>;
  variance?: InputMaybe<ClinicalNoteFutureReferrals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferralsArrRelInsertInput = {
  data: Array<ClinicalNoteFutureReferralsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClinicalNoteFutureReferralsOnConflict>;
};

/** aggregate avg on columns */
export type ClinicalNoteFutureReferralsAvgFields = {
  __typename?: 'clinicalNoteFutureReferralsAvgFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_futurereferralclinicalnote". All fields are combined with a logical 'AND'. */
export type ClinicalNoteFutureReferralsBoolExp = {
  _and?: InputMaybe<Array<ClinicalNoteFutureReferralsBoolExp>>;
  _not?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
  _or?: InputMaybe<Array<ClinicalNoteFutureReferralsBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  clinicalNoteId?: InputMaybe<IntComparisonExp>;
  clinicalnote?: InputMaybe<ClinicalnoteBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  referral?: InputMaybe<ReferralBoolExp>;
  referralId?: InputMaybe<IntComparisonExp>;
  sent?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "server_futurereferralclinicalnote" */
export enum ClinicalNoteFutureReferralsConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerFuturereferralclinicalnotePkey = 'server_futurereferralclinicalnote_pkey'
}

/** input type for incrementing numeric columns in table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferralsIncInput = {
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  referralId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferralsInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  clinicalnote?: InputMaybe<ClinicalnoteObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  referral?: InputMaybe<ReferralObjRelInsertInput>;
  referralId?: InputMaybe<Scalars['Int']>;
  sent?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type ClinicalNoteFutureReferralsMaxFields = {
  __typename?: 'clinicalNoteFutureReferralsMaxFields';
  clinicalNoteId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  referralId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ClinicalNoteFutureReferralsMinFields = {
  __typename?: 'clinicalNoteFutureReferralsMinFields';
  clinicalNoteId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  referralId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferralsMutationResponse = {
  __typename?: 'clinicalNoteFutureReferralsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClinicalNoteFutureReferrals>;
};

/** on_conflict condition type for table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferralsOnConflict = {
  constraint: ClinicalNoteFutureReferralsConstraint;
  update_columns?: Array<ClinicalNoteFutureReferralsUpdateColumn>;
  where?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
};

/** Ordering options when selecting data from "server_futurereferralclinicalnote". */
export type ClinicalNoteFutureReferralsOrderBy = {
  active?: InputMaybe<OrderBy>;
  clinicalNoteId?: InputMaybe<OrderBy>;
  clinicalnote?: InputMaybe<ClinicalnoteOrderBy>;
  id?: InputMaybe<OrderBy>;
  referral?: InputMaybe<ReferralOrderBy>;
  referralId?: InputMaybe<OrderBy>;
  sent?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_futurereferralclinicalnote */
export type ClinicalNoteFutureReferralsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_futurereferralclinicalnote" */
export enum ClinicalNoteFutureReferralsSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  ClinicalNoteId = 'clinicalNoteId',
  /** column name */
  Id = 'id',
  /** column name */
  ReferralId = 'referralId',
  /** column name */
  Sent = 'sent'
}

/** input type for updating data in table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferralsSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  referralId?: InputMaybe<Scalars['Int']>;
  sent?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type ClinicalNoteFutureReferralsStddevFields = {
  __typename?: 'clinicalNoteFutureReferralsStddevFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClinicalNoteFutureReferralsStddev_PopFields = {
  __typename?: 'clinicalNoteFutureReferralsStddev_popFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClinicalNoteFutureReferralsStddev_SampFields = {
  __typename?: 'clinicalNoteFutureReferralsStddev_sampFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ClinicalNoteFutureReferralsSumFields = {
  __typename?: 'clinicalNoteFutureReferralsSumFields';
  clinicalNoteId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  referralId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_futurereferralclinicalnote" */
export enum ClinicalNoteFutureReferralsUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  ClinicalNoteId = 'clinicalNoteId',
  /** column name */
  Id = 'id',
  /** column name */
  ReferralId = 'referralId',
  /** column name */
  Sent = 'sent'
}

export type ClinicalNoteFutureReferralsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClinicalNoteFutureReferralsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClinicalNoteFutureReferralsSetInput>;
  where: ClinicalNoteFutureReferralsBoolExp;
};

/** aggregate var_pop on columns */
export type ClinicalNoteFutureReferralsVar_PopFields = {
  __typename?: 'clinicalNoteFutureReferralsVar_popFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClinicalNoteFutureReferralsVar_SampFields = {
  __typename?: 'clinicalNoteFutureReferralsVar_sampFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClinicalNoteFutureReferralsVarianceFields = {
  __typename?: 'clinicalNoteFutureReferralsVarianceFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferrals_Avg_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferrals_Max_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferrals_Min_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferrals_Stddev_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferrals_Stddev_Pop_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferrals_Stddev_Samp_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "clinicalNoteFutureReferrals" */
export type ClinicalNoteFutureReferrals_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClinicalNoteFutureReferrals_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClinicalNoteFutureReferrals_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  referralId?: InputMaybe<Scalars['Int']>;
  sent?: InputMaybe<Scalars['Boolean']>;
};

/** order by sum() on columns of table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferrals_Sum_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferrals_Var_Pop_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferrals_Var_Samp_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_futurereferralclinicalnote" */
export type ClinicalNoteFutureReferrals_Variance_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_clinicalnote" */
export type Clinicalnote = {
  __typename?: 'clinicalnote';
  /** An object relationship */
  appointment: Appointment;
  appointmentId: Scalars['Int'];
  /** An array relationship */
  clinicalnoteFieldValues: Array<ClinicalnoteFieldValue>;
  /** An aggregate relationship */
  clinicalnoteFieldValuesAggregate: ClinicalnoteFieldValueAggregate;
  /** An array relationship */
  documentsClinicalNote: Array<Document>;
  /** An aggregate relationship */
  documentsClinicalNoteAggregate: DocumentAggregate;
  /** An array relationship */
  futureReferrals: Array<ClinicalNoteFutureReferrals>;
  /** An aggregate relationship */
  futureReferralsAggregate: ClinicalNoteFutureReferralsAggregate;
  locked: Scalars['Boolean'];
  lockedDate?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  pdfDocument?: Maybe<Document>;
  pdfId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  problemPlans: Array<ProblemPlan>;
  /** An aggregate relationship */
  problemPlansAggregate: ProblemPlanAggregate;
  /** An array relationship */
  signoffs: Array<Signoff>;
  /** An aggregate relationship */
  signoffsAggregate: SignoffAggregate;
};


/** columns and relationships of "server_clinicalnote" */
export type ClinicalnoteClinicalnoteFieldValuesArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldValueOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
};


/** columns and relationships of "server_clinicalnote" */
export type ClinicalnoteClinicalnoteFieldValuesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldValueOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
};


/** columns and relationships of "server_clinicalnote" */
export type ClinicalnoteDocumentsClinicalNoteArgs = {
  distinctOn?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


/** columns and relationships of "server_clinicalnote" */
export type ClinicalnoteDocumentsClinicalNoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


/** columns and relationships of "server_clinicalnote" */
export type ClinicalnoteFutureReferralsArgs = {
  distinctOn?: InputMaybe<Array<ClinicalNoteFutureReferralsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalNoteFutureReferralsOrderBy>>;
  where?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
};


/** columns and relationships of "server_clinicalnote" */
export type ClinicalnoteFutureReferralsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalNoteFutureReferralsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalNoteFutureReferralsOrderBy>>;
  where?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
};


/** columns and relationships of "server_clinicalnote" */
export type ClinicalnoteProblemPlansArgs = {
  distinctOn?: InputMaybe<Array<ProblemPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemPlanOrderBy>>;
  where?: InputMaybe<ProblemPlanBoolExp>;
};


/** columns and relationships of "server_clinicalnote" */
export type ClinicalnoteProblemPlansAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProblemPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemPlanOrderBy>>;
  where?: InputMaybe<ProblemPlanBoolExp>;
};


/** columns and relationships of "server_clinicalnote" */
export type ClinicalnoteSignoffsArgs = {
  distinctOn?: InputMaybe<Array<SignoffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignoffOrderBy>>;
  where?: InputMaybe<SignoffBoolExp>;
};


/** columns and relationships of "server_clinicalnote" */
export type ClinicalnoteSignoffsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SignoffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignoffOrderBy>>;
  where?: InputMaybe<SignoffBoolExp>;
};

/** aggregated selection of "server_clinicalnote" */
export type ClinicalnoteAggregate = {
  __typename?: 'clinicalnoteAggregate';
  aggregate?: Maybe<ClinicalnoteAggregateFields>;
  nodes: Array<Clinicalnote>;
};

/** aggregate fields of "server_clinicalnote" */
export type ClinicalnoteAggregateFields = {
  __typename?: 'clinicalnoteAggregateFields';
  avg?: Maybe<ClinicalnoteAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClinicalnoteMaxFields>;
  min?: Maybe<ClinicalnoteMinFields>;
  stddev?: Maybe<ClinicalnoteStddevFields>;
  stddevPop?: Maybe<ClinicalnoteStddev_PopFields>;
  stddevSamp?: Maybe<ClinicalnoteStddev_SampFields>;
  sum?: Maybe<ClinicalnoteSumFields>;
  varPop?: Maybe<ClinicalnoteVar_PopFields>;
  varSamp?: Maybe<ClinicalnoteVar_SampFields>;
  variance?: Maybe<ClinicalnoteVarianceFields>;
};


/** aggregate fields of "server_clinicalnote" */
export type ClinicalnoteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClinicalnoteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ClinicalnoteAvgFields = {
  __typename?: 'clinicalnoteAvgFields';
  appointmentId?: Maybe<Scalars['Float']>;
  pdfId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_clinicalnote". All fields are combined with a logical 'AND'. */
export type ClinicalnoteBoolExp = {
  _and?: InputMaybe<Array<ClinicalnoteBoolExp>>;
  _not?: InputMaybe<ClinicalnoteBoolExp>;
  _or?: InputMaybe<Array<ClinicalnoteBoolExp>>;
  appointment?: InputMaybe<AppointmentBoolExp>;
  appointmentId?: InputMaybe<IntComparisonExp>;
  clinicalnoteFieldValues?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
  documentsClinicalNote?: InputMaybe<DocumentBoolExp>;
  futureReferrals?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
  locked?: InputMaybe<BooleanComparisonExp>;
  lockedDate?: InputMaybe<TimestamptzComparisonExp>;
  pdfDocument?: InputMaybe<DocumentBoolExp>;
  pdfId?: InputMaybe<IntComparisonExp>;
  problemPlans?: InputMaybe<ProblemPlanBoolExp>;
  signoffs?: InputMaybe<SignoffBoolExp>;
};

/** unique or primary key constraints on table "server_clinicalnote" */
export enum ClinicalnoteConstraint {
  /** unique or primary key constraint on columns "appointment_id" */
  ServerClinicalnotePkey = 'server_clinicalnote_pkey'
}

/** columns and relationships of "server_clinicalnotefield" */
export type ClinicalnoteField = {
  __typename?: 'clinicalnoteField';
  /** An object relationship */
  clinicalnoteTemplate: ClinicalnoteTemplate;
  data?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  fieldvalues: Array<ClinicalnoteFieldValue>;
  /** An aggregate relationship */
  fieldvaluesAggregate: ClinicalnoteFieldValueAggregate;
  id: Scalars['Int'];
  insertText: Scalars['String'];
  name: Scalars['String'];
  orderInTemplate: Scalars['smallint'];
  orderWithinGroup: Scalars['smallint'];
  templateId: Scalars['Int'];
  type: Scalars['smallint'];
};


/** columns and relationships of "server_clinicalnotefield" */
export type ClinicalnoteFieldDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "server_clinicalnotefield" */
export type ClinicalnoteFieldFieldvaluesArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldValueOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
};


/** columns and relationships of "server_clinicalnotefield" */
export type ClinicalnoteFieldFieldvaluesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldValueOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
};

/** aggregated selection of "server_clinicalnotefield" */
export type ClinicalnoteFieldAggregate = {
  __typename?: 'clinicalnoteFieldAggregate';
  aggregate?: Maybe<ClinicalnoteFieldAggregateFields>;
  nodes: Array<ClinicalnoteField>;
};

/** aggregate fields of "server_clinicalnotefield" */
export type ClinicalnoteFieldAggregateFields = {
  __typename?: 'clinicalnoteFieldAggregateFields';
  avg?: Maybe<ClinicalnoteFieldAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClinicalnoteFieldMaxFields>;
  min?: Maybe<ClinicalnoteFieldMinFields>;
  stddev?: Maybe<ClinicalnoteFieldStddevFields>;
  stddevPop?: Maybe<ClinicalnoteFieldStddev_PopFields>;
  stddevSamp?: Maybe<ClinicalnoteFieldStddev_SampFields>;
  sum?: Maybe<ClinicalnoteFieldSumFields>;
  varPop?: Maybe<ClinicalnoteFieldVar_PopFields>;
  varSamp?: Maybe<ClinicalnoteFieldVar_SampFields>;
  variance?: Maybe<ClinicalnoteFieldVarianceFields>;
};


/** aggregate fields of "server_clinicalnotefield" */
export type ClinicalnoteFieldAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClinicalnoteFieldSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_clinicalnotefield" */
export type ClinicalnoteFieldAggregateOrderBy = {
  avg?: InputMaybe<ClinicalnoteField_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClinicalnoteField_Max_Order_By>;
  min?: InputMaybe<ClinicalnoteField_Min_Order_By>;
  stddev?: InputMaybe<ClinicalnoteField_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ClinicalnoteField_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ClinicalnoteField_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ClinicalnoteField_Sum_Order_By>;
  var_pop?: InputMaybe<ClinicalnoteField_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ClinicalnoteField_Var_Samp_Order_By>;
  variance?: InputMaybe<ClinicalnoteField_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ClinicalnoteFieldAppendInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_clinicalnotefield" */
export type ClinicalnoteFieldArrRelInsertInput = {
  data: Array<ClinicalnoteFieldInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClinicalnoteFieldOnConflict>;
};

/** aggregate avg on columns */
export type ClinicalnoteFieldAvgFields = {
  __typename?: 'clinicalnoteFieldAvgFields';
  id?: Maybe<Scalars['Float']>;
  orderInTemplate?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  templateId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_clinicalnotefield". All fields are combined with a logical 'AND'. */
export type ClinicalnoteFieldBoolExp = {
  _and?: InputMaybe<Array<ClinicalnoteFieldBoolExp>>;
  _not?: InputMaybe<ClinicalnoteFieldBoolExp>;
  _or?: InputMaybe<Array<ClinicalnoteFieldBoolExp>>;
  clinicalnoteTemplate?: InputMaybe<ClinicalnoteTemplateBoolExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  fieldvalues?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  insertText?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  orderInTemplate?: InputMaybe<SmallintComparisonExp>;
  orderWithinGroup?: InputMaybe<SmallintComparisonExp>;
  templateId?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<SmallintComparisonExp>;
};

/** unique or primary key constraints on table "server_clinicalnotefield" */
export enum ClinicalnoteFieldConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerClinicalnotefieldPkey = 'server_clinicalnotefield_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ClinicalnoteFieldDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ClinicalnoteFieldDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ClinicalnoteFieldDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_clinicalnotefield" */
export type ClinicalnoteFieldIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderInTemplate?: InputMaybe<Scalars['smallint']>;
  orderWithinGroup?: InputMaybe<Scalars['smallint']>;
  templateId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_clinicalnotefield" */
export type ClinicalnoteFieldInsertInput = {
  clinicalnoteTemplate?: InputMaybe<ClinicalnoteTemplateObjRelInsertInput>;
  data?: InputMaybe<Scalars['jsonb']>;
  fieldvalues?: InputMaybe<ClinicalnoteFieldValueArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  insertText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderInTemplate?: InputMaybe<Scalars['smallint']>;
  orderWithinGroup?: InputMaybe<Scalars['smallint']>;
  templateId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type ClinicalnoteFieldMaxFields = {
  __typename?: 'clinicalnoteFieldMaxFields';
  id?: Maybe<Scalars['Int']>;
  insertText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderInTemplate?: Maybe<Scalars['smallint']>;
  orderWithinGroup?: Maybe<Scalars['smallint']>;
  templateId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type ClinicalnoteFieldMinFields = {
  __typename?: 'clinicalnoteFieldMinFields';
  id?: Maybe<Scalars['Int']>;
  insertText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderInTemplate?: Maybe<Scalars['smallint']>;
  orderWithinGroup?: Maybe<Scalars['smallint']>;
  templateId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_clinicalnotefield" */
export type ClinicalnoteFieldMutationResponse = {
  __typename?: 'clinicalnoteFieldMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClinicalnoteField>;
};

/** input type for inserting object relation for remote table "server_clinicalnotefield" */
export type ClinicalnoteFieldObjRelInsertInput = {
  data: ClinicalnoteFieldInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClinicalnoteFieldOnConflict>;
};

/** on_conflict condition type for table "server_clinicalnotefield" */
export type ClinicalnoteFieldOnConflict = {
  constraint: ClinicalnoteFieldConstraint;
  update_columns?: Array<ClinicalnoteFieldUpdateColumn>;
  where?: InputMaybe<ClinicalnoteFieldBoolExp>;
};

/** Ordering options when selecting data from "server_clinicalnotefield". */
export type ClinicalnoteFieldOrderBy = {
  clinicalnoteTemplate?: InputMaybe<ClinicalnoteTemplateOrderBy>;
  data?: InputMaybe<OrderBy>;
  fieldvaluesAggregate?: InputMaybe<ClinicalnoteFieldValueAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  insertText?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderInTemplate?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_clinicalnotefield */
export type ClinicalnoteFieldPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ClinicalnoteFieldPrependInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_clinicalnotefield" */
export enum ClinicalnoteFieldSelectColumn {
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  InsertText = 'insertText',
  /** column name */
  Name = 'name',
  /** column name */
  OrderInTemplate = 'orderInTemplate',
  /** column name */
  OrderWithinGroup = 'orderWithinGroup',
  /** column name */
  TemplateId = 'templateId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "server_clinicalnotefield" */
export type ClinicalnoteFieldSetInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  insertText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderInTemplate?: InputMaybe<Scalars['smallint']>;
  orderWithinGroup?: InputMaybe<Scalars['smallint']>;
  templateId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type ClinicalnoteFieldStddevFields = {
  __typename?: 'clinicalnoteFieldStddevFields';
  id?: Maybe<Scalars['Float']>;
  orderInTemplate?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  templateId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClinicalnoteFieldStddev_PopFields = {
  __typename?: 'clinicalnoteFieldStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  orderInTemplate?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  templateId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClinicalnoteFieldStddev_SampFields = {
  __typename?: 'clinicalnoteFieldStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderInTemplate?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  templateId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ClinicalnoteFieldSumFields = {
  __typename?: 'clinicalnoteFieldSumFields';
  id?: Maybe<Scalars['Int']>;
  orderInTemplate?: Maybe<Scalars['smallint']>;
  orderWithinGroup?: Maybe<Scalars['smallint']>;
  templateId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_clinicalnotefield" */
export enum ClinicalnoteFieldUpdateColumn {
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  InsertText = 'insertText',
  /** column name */
  Name = 'name',
  /** column name */
  OrderInTemplate = 'orderInTemplate',
  /** column name */
  OrderWithinGroup = 'orderWithinGroup',
  /** column name */
  TemplateId = 'templateId',
  /** column name */
  Type = 'type'
}

export type ClinicalnoteFieldUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ClinicalnoteFieldAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<ClinicalnoteFieldDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<ClinicalnoteFieldDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<ClinicalnoteFieldDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClinicalnoteFieldIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ClinicalnoteFieldPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClinicalnoteFieldSetInput>;
  where: ClinicalnoteFieldBoolExp;
};

/** columns and relationships of "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValue = {
  __typename?: 'clinicalnoteFieldValue';
  clinicalNoteId: Scalars['Int'];
  /** An object relationship */
  clinicalnote: Clinicalnote;
  /** An object relationship */
  clinicalnoteField: ClinicalnoteField;
  fieldId: Scalars['Int'];
  id: Scalars['Int'];
  show: Scalars['Boolean'];
  value: Scalars['String'];
};

/** aggregated selection of "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValueAggregate = {
  __typename?: 'clinicalnoteFieldValueAggregate';
  aggregate?: Maybe<ClinicalnoteFieldValueAggregateFields>;
  nodes: Array<ClinicalnoteFieldValue>;
};

/** aggregate fields of "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValueAggregateFields = {
  __typename?: 'clinicalnoteFieldValueAggregateFields';
  avg?: Maybe<ClinicalnoteFieldValueAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClinicalnoteFieldValueMaxFields>;
  min?: Maybe<ClinicalnoteFieldValueMinFields>;
  stddev?: Maybe<ClinicalnoteFieldValueStddevFields>;
  stddevPop?: Maybe<ClinicalnoteFieldValueStddev_PopFields>;
  stddevSamp?: Maybe<ClinicalnoteFieldValueStddev_SampFields>;
  sum?: Maybe<ClinicalnoteFieldValueSumFields>;
  varPop?: Maybe<ClinicalnoteFieldValueVar_PopFields>;
  varSamp?: Maybe<ClinicalnoteFieldValueVar_SampFields>;
  variance?: Maybe<ClinicalnoteFieldValueVarianceFields>;
};


/** aggregate fields of "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValueAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClinicalnoteFieldValueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValueAggregateOrderBy = {
  avg?: InputMaybe<ClinicalnoteFieldValue_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClinicalnoteFieldValue_Max_Order_By>;
  min?: InputMaybe<ClinicalnoteFieldValue_Min_Order_By>;
  stddev?: InputMaybe<ClinicalnoteFieldValue_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ClinicalnoteFieldValue_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ClinicalnoteFieldValue_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ClinicalnoteFieldValue_Sum_Order_By>;
  var_pop?: InputMaybe<ClinicalnoteFieldValue_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ClinicalnoteFieldValue_Var_Samp_Order_By>;
  variance?: InputMaybe<ClinicalnoteFieldValue_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValueArrRelInsertInput = {
  data: Array<ClinicalnoteFieldValueInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClinicalnoteFieldValueOnConflict>;
};

/** aggregate avg on columns */
export type ClinicalnoteFieldValueAvgFields = {
  __typename?: 'clinicalnoteFieldValueAvgFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_clinicalnotefieldvalue". All fields are combined with a logical 'AND'. */
export type ClinicalnoteFieldValueBoolExp = {
  _and?: InputMaybe<Array<ClinicalnoteFieldValueBoolExp>>;
  _not?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
  _or?: InputMaybe<Array<ClinicalnoteFieldValueBoolExp>>;
  clinicalNoteId?: InputMaybe<IntComparisonExp>;
  clinicalnote?: InputMaybe<ClinicalnoteBoolExp>;
  clinicalnoteField?: InputMaybe<ClinicalnoteFieldBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  show?: InputMaybe<BooleanComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_clinicalnotefieldvalue" */
export enum ClinicalnoteFieldValueConstraint {
  /** unique or primary key constraint on columns "clinical_note_id", "field_id" */
  ServerClinicalnotefieldClinicalNoteFieldIdCFd5c5456Uniq = 'server_clinicalnotefield_clinical_note_field_id_c_fd5c5456_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerClinicalnotefieldvaluePkey = 'server_clinicalnotefieldvalue_pkey'
}

/** input type for incrementing numeric columns in table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValueIncInput = {
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  fieldId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValueInsertInput = {
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  clinicalnote?: InputMaybe<ClinicalnoteObjRelInsertInput>;
  clinicalnoteField?: InputMaybe<ClinicalnoteFieldObjRelInsertInput>;
  fieldId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  show?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClinicalnoteFieldValueMaxFields = {
  __typename?: 'clinicalnoteFieldValueMaxFields';
  clinicalNoteId?: Maybe<Scalars['Int']>;
  fieldId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ClinicalnoteFieldValueMinFields = {
  __typename?: 'clinicalnoteFieldValueMinFields';
  clinicalNoteId?: Maybe<Scalars['Int']>;
  fieldId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValueMutationResponse = {
  __typename?: 'clinicalnoteFieldValueMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClinicalnoteFieldValue>;
};

/** on_conflict condition type for table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValueOnConflict = {
  constraint: ClinicalnoteFieldValueConstraint;
  update_columns?: Array<ClinicalnoteFieldValueUpdateColumn>;
  where?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
};

/** Ordering options when selecting data from "server_clinicalnotefieldvalue". */
export type ClinicalnoteFieldValueOrderBy = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  clinicalnote?: InputMaybe<ClinicalnoteOrderBy>;
  clinicalnoteField?: InputMaybe<ClinicalnoteFieldOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  show?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_clinicalnotefieldvalue */
export type ClinicalnoteFieldValuePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_clinicalnotefieldvalue" */
export enum ClinicalnoteFieldValueSelectColumn {
  /** column name */
  ClinicalNoteId = 'clinicalNoteId',
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id',
  /** column name */
  Show = 'show',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValueSetInput = {
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  fieldId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  show?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ClinicalnoteFieldValueStddevFields = {
  __typename?: 'clinicalnoteFieldValueStddevFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClinicalnoteFieldValueStddev_PopFields = {
  __typename?: 'clinicalnoteFieldValueStddev_popFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClinicalnoteFieldValueStddev_SampFields = {
  __typename?: 'clinicalnoteFieldValueStddev_sampFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ClinicalnoteFieldValueSumFields = {
  __typename?: 'clinicalnoteFieldValueSumFields';
  clinicalNoteId?: Maybe<Scalars['Int']>;
  fieldId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_clinicalnotefieldvalue" */
export enum ClinicalnoteFieldValueUpdateColumn {
  /** column name */
  ClinicalNoteId = 'clinicalNoteId',
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id',
  /** column name */
  Show = 'show',
  /** column name */
  Value = 'value'
}

export type ClinicalnoteFieldValueUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClinicalnoteFieldValueIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClinicalnoteFieldValueSetInput>;
  where: ClinicalnoteFieldValueBoolExp;
};

/** aggregate var_pop on columns */
export type ClinicalnoteFieldValueVar_PopFields = {
  __typename?: 'clinicalnoteFieldValueVar_popFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClinicalnoteFieldValueVar_SampFields = {
  __typename?: 'clinicalnoteFieldValueVar_sampFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClinicalnoteFieldValueVarianceFields = {
  __typename?: 'clinicalnoteFieldValueVarianceFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValue_Avg_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValue_Max_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValue_Min_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValue_Stddev_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValue_Stddev_Pop_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValue_Stddev_Samp_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "clinicalnoteFieldValue" */
export type ClinicalnoteFieldValue_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClinicalnoteFieldValue_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClinicalnoteFieldValue_StreamCursorValueInput = {
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  fieldId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  show?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValue_Sum_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValue_Var_Pop_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValue_Var_Samp_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_clinicalnotefieldvalue" */
export type ClinicalnoteFieldValue_Variance_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ClinicalnoteFieldVar_PopFields = {
  __typename?: 'clinicalnoteFieldVar_popFields';
  id?: Maybe<Scalars['Float']>;
  orderInTemplate?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  templateId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClinicalnoteFieldVar_SampFields = {
  __typename?: 'clinicalnoteFieldVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderInTemplate?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  templateId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClinicalnoteFieldVarianceFields = {
  __typename?: 'clinicalnoteFieldVarianceFields';
  id?: Maybe<Scalars['Float']>;
  orderInTemplate?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  templateId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_clinicalnotefield" */
export type ClinicalnoteField_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderInTemplate?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_clinicalnotefield" */
export type ClinicalnoteField_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  insertText?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderInTemplate?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_clinicalnotefield" */
export type ClinicalnoteField_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  insertText?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderInTemplate?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_clinicalnotefield" */
export type ClinicalnoteField_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderInTemplate?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_clinicalnotefield" */
export type ClinicalnoteField_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderInTemplate?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_clinicalnotefield" */
export type ClinicalnoteField_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderInTemplate?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "clinicalnoteField" */
export type ClinicalnoteField_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClinicalnoteField_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClinicalnoteField_StreamCursorValueInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  insertText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderInTemplate?: InputMaybe<Scalars['smallint']>;
  orderWithinGroup?: InputMaybe<Scalars['smallint']>;
  templateId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "server_clinicalnotefield" */
export type ClinicalnoteField_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderInTemplate?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_clinicalnotefield" */
export type ClinicalnoteField_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderInTemplate?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_clinicalnotefield" */
export type ClinicalnoteField_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderInTemplate?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_clinicalnotefield" */
export type ClinicalnoteField_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderInTemplate?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "server_clinicalnote" */
export type ClinicalnoteIncInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  pdfId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_clinicalnote" */
export type ClinicalnoteInsertInput = {
  appointment?: InputMaybe<AppointmentObjRelInsertInput>;
  appointmentId?: InputMaybe<Scalars['Int']>;
  clinicalnoteFieldValues?: InputMaybe<ClinicalnoteFieldValueArrRelInsertInput>;
  documentsClinicalNote?: InputMaybe<DocumentArrRelInsertInput>;
  futureReferrals?: InputMaybe<ClinicalNoteFutureReferralsArrRelInsertInput>;
  locked?: InputMaybe<Scalars['Boolean']>;
  lockedDate?: InputMaybe<Scalars['timestamptz']>;
  pdfDocument?: InputMaybe<DocumentObjRelInsertInput>;
  pdfId?: InputMaybe<Scalars['Int']>;
  problemPlans?: InputMaybe<ProblemPlanArrRelInsertInput>;
  signoffs?: InputMaybe<SignoffArrRelInsertInput>;
};

/** aggregate max on columns */
export type ClinicalnoteMaxFields = {
  __typename?: 'clinicalnoteMaxFields';
  appointmentId?: Maybe<Scalars['Int']>;
  lockedDate?: Maybe<Scalars['timestamptz']>;
  pdfId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ClinicalnoteMinFields = {
  __typename?: 'clinicalnoteMinFields';
  appointmentId?: Maybe<Scalars['Int']>;
  lockedDate?: Maybe<Scalars['timestamptz']>;
  pdfId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_clinicalnote" */
export type ClinicalnoteMutationResponse = {
  __typename?: 'clinicalnoteMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clinicalnote>;
};

/** input type for inserting object relation for remote table "server_clinicalnote" */
export type ClinicalnoteObjRelInsertInput = {
  data: ClinicalnoteInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClinicalnoteOnConflict>;
};

/** on_conflict condition type for table "server_clinicalnote" */
export type ClinicalnoteOnConflict = {
  constraint: ClinicalnoteConstraint;
  update_columns?: Array<ClinicalnoteUpdateColumn>;
  where?: InputMaybe<ClinicalnoteBoolExp>;
};

/** Ordering options when selecting data from "server_clinicalnote". */
export type ClinicalnoteOrderBy = {
  appointment?: InputMaybe<AppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
  clinicalnoteFieldValuesAggregate?: InputMaybe<ClinicalnoteFieldValueAggregateOrderBy>;
  documentsClinicalNoteAggregate?: InputMaybe<DocumentAggregateOrderBy>;
  futureReferralsAggregate?: InputMaybe<ClinicalNoteFutureReferralsAggregateOrderBy>;
  locked?: InputMaybe<OrderBy>;
  lockedDate?: InputMaybe<OrderBy>;
  pdfDocument?: InputMaybe<DocumentOrderBy>;
  pdfId?: InputMaybe<OrderBy>;
  problemPlansAggregate?: InputMaybe<ProblemPlanAggregateOrderBy>;
  signoffsAggregate?: InputMaybe<SignoffAggregateOrderBy>;
};

/** primary key columns input for table: server_clinicalnote */
export type ClinicalnotePkColumnsInput = {
  appointmentId: Scalars['Int'];
};

/** select columns of table "server_clinicalnote" */
export enum ClinicalnoteSelectColumn {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Locked = 'locked',
  /** column name */
  LockedDate = 'lockedDate',
  /** column name */
  PdfId = 'pdfId'
}

/** input type for updating data in table "server_clinicalnote" */
export type ClinicalnoteSetInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  lockedDate?: InputMaybe<Scalars['timestamptz']>;
  pdfId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ClinicalnoteStddevFields = {
  __typename?: 'clinicalnoteStddevFields';
  appointmentId?: Maybe<Scalars['Float']>;
  pdfId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClinicalnoteStddev_PopFields = {
  __typename?: 'clinicalnoteStddev_popFields';
  appointmentId?: Maybe<Scalars['Float']>;
  pdfId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClinicalnoteStddev_SampFields = {
  __typename?: 'clinicalnoteStddev_sampFields';
  appointmentId?: Maybe<Scalars['Float']>;
  pdfId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ClinicalnoteSumFields = {
  __typename?: 'clinicalnoteSumFields';
  appointmentId?: Maybe<Scalars['Int']>;
  pdfId?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "server_clinicalnotetemplate" */
export type ClinicalnoteTemplate = {
  __typename?: 'clinicalnoteTemplate';
  /** An object relationship */
  assessment?: Maybe<Assessment>;
  assessmentId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  clinicalnoteFields: Array<ClinicalnoteField>;
  /** An aggregate relationship */
  clinicalnoteFieldsAggregate: ClinicalnoteFieldAggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  orderInNote: Scalars['smallint'];
  practiceGroupId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  practicegroup?: Maybe<Practicegroup>;
  /** An object relationship */
  procedure?: Maybe<Procedure>;
  procedureId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  providerClassifications: Array<ClinicalnoteTemplateProviderClassifications>;
  /** An aggregate relationship */
  providerClassificationsAggregate: ClinicalnoteTemplateProviderClassificationsAggregate;
  showInPhysical: Scalars['Boolean'];
  showInVirtual: Scalars['Boolean'];
};


/** columns and relationships of "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateClinicalnoteFieldsArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldBoolExp>;
};


/** columns and relationships of "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateClinicalnoteFieldsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldBoolExp>;
};


/** columns and relationships of "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateProviderClassificationsArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
};


/** columns and relationships of "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateProviderClassificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
};

/** aggregated selection of "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateAggregate = {
  __typename?: 'clinicalnoteTemplateAggregate';
  aggregate?: Maybe<ClinicalnoteTemplateAggregateFields>;
  nodes: Array<ClinicalnoteTemplate>;
};

/** aggregate fields of "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateAggregateFields = {
  __typename?: 'clinicalnoteTemplateAggregateFields';
  avg?: Maybe<ClinicalnoteTemplateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClinicalnoteTemplateMaxFields>;
  min?: Maybe<ClinicalnoteTemplateMinFields>;
  stddev?: Maybe<ClinicalnoteTemplateStddevFields>;
  stddevPop?: Maybe<ClinicalnoteTemplateStddev_PopFields>;
  stddevSamp?: Maybe<ClinicalnoteTemplateStddev_SampFields>;
  sum?: Maybe<ClinicalnoteTemplateSumFields>;
  varPop?: Maybe<ClinicalnoteTemplateVar_PopFields>;
  varSamp?: Maybe<ClinicalnoteTemplateVar_SampFields>;
  variance?: Maybe<ClinicalnoteTemplateVarianceFields>;
};


/** aggregate fields of "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClinicalnoteTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateAggregateOrderBy = {
  avg?: InputMaybe<ClinicalnoteTemplate_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClinicalnoteTemplate_Max_Order_By>;
  min?: InputMaybe<ClinicalnoteTemplate_Min_Order_By>;
  stddev?: InputMaybe<ClinicalnoteTemplate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ClinicalnoteTemplate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ClinicalnoteTemplate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ClinicalnoteTemplate_Sum_Order_By>;
  var_pop?: InputMaybe<ClinicalnoteTemplate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ClinicalnoteTemplate_Var_Samp_Order_By>;
  variance?: InputMaybe<ClinicalnoteTemplate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateArrRelInsertInput = {
  data: Array<ClinicalnoteTemplateInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClinicalnoteTemplateOnConflict>;
};

/** aggregate avg on columns */
export type ClinicalnoteTemplateAvgFields = {
  __typename?: 'clinicalnoteTemplateAvgFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderInNote?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_clinicalnotetemplate". All fields are combined with a logical 'AND'. */
export type ClinicalnoteTemplateBoolExp = {
  _and?: InputMaybe<Array<ClinicalnoteTemplateBoolExp>>;
  _not?: InputMaybe<ClinicalnoteTemplateBoolExp>;
  _or?: InputMaybe<Array<ClinicalnoteTemplateBoolExp>>;
  assessment?: InputMaybe<AssessmentBoolExp>;
  assessmentId?: InputMaybe<IntComparisonExp>;
  clinicalnoteFields?: InputMaybe<ClinicalnoteFieldBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  orderInNote?: InputMaybe<SmallintComparisonExp>;
  practiceGroupId?: InputMaybe<IntComparisonExp>;
  practicegroup?: InputMaybe<PracticegroupBoolExp>;
  procedure?: InputMaybe<ProcedureBoolExp>;
  procedureId?: InputMaybe<IntComparisonExp>;
  providerClassifications?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
  showInPhysical?: InputMaybe<BooleanComparisonExp>;
  showInVirtual?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "server_clinicalnotetemplate" */
export enum ClinicalnoteTemplateConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerClinicalnotetemplatePkey = 'server_clinicalnotetemplate_pkey'
}

/** input type for incrementing numeric columns in table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateIncInput = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderInNote?: InputMaybe<Scalars['smallint']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  procedureId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateInsertInput = {
  assessment?: InputMaybe<AssessmentObjRelInsertInput>;
  assessmentId?: InputMaybe<Scalars['Int']>;
  clinicalnoteFields?: InputMaybe<ClinicalnoteFieldArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderInNote?: InputMaybe<Scalars['smallint']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  practicegroup?: InputMaybe<PracticegroupObjRelInsertInput>;
  procedure?: InputMaybe<ProcedureObjRelInsertInput>;
  procedureId?: InputMaybe<Scalars['Int']>;
  providerClassifications?: InputMaybe<ClinicalnoteTemplateProviderClassificationsArrRelInsertInput>;
  showInPhysical?: InputMaybe<Scalars['Boolean']>;
  showInVirtual?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type ClinicalnoteTemplateMaxFields = {
  __typename?: 'clinicalnoteTemplateMaxFields';
  assessmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  orderInNote?: Maybe<Scalars['smallint']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
  procedureId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ClinicalnoteTemplateMinFields = {
  __typename?: 'clinicalnoteTemplateMinFields';
  assessmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  orderInNote?: Maybe<Scalars['smallint']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
  procedureId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateMutationResponse = {
  __typename?: 'clinicalnoteTemplateMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClinicalnoteTemplate>;
};

/** input type for inserting object relation for remote table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateObjRelInsertInput = {
  data: ClinicalnoteTemplateInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClinicalnoteTemplateOnConflict>;
};

/** on_conflict condition type for table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateOnConflict = {
  constraint: ClinicalnoteTemplateConstraint;
  update_columns?: Array<ClinicalnoteTemplateUpdateColumn>;
  where?: InputMaybe<ClinicalnoteTemplateBoolExp>;
};

/** Ordering options when selecting data from "server_clinicalnotetemplate". */
export type ClinicalnoteTemplateOrderBy = {
  assessment?: InputMaybe<AssessmentOrderBy>;
  assessmentId?: InputMaybe<OrderBy>;
  clinicalnoteFieldsAggregate?: InputMaybe<ClinicalnoteFieldAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderInNote?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  practicegroup?: InputMaybe<PracticegroupOrderBy>;
  procedure?: InputMaybe<ProcedureOrderBy>;
  procedureId?: InputMaybe<OrderBy>;
  providerClassificationsAggregate?: InputMaybe<ClinicalnoteTemplateProviderClassificationsAggregateOrderBy>;
  showInPhysical?: InputMaybe<OrderBy>;
  showInVirtual?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_clinicalnotetemplate */
export type ClinicalnoteTemplatePkColumnsInput = {
  id: Scalars['Int'];
};

/** columns and relationships of "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassifications = {
  __typename?: 'clinicalnoteTemplateProviderClassifications';
  /** An object relationship */
  clinicalnoteTemplate: ClinicalnoteTemplate;
  clinicalnotetemplateId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  providerClassification: ProviderClassification;
  providerclassificationId: Scalars['Int'];
};

/** aggregated selection of "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassificationsAggregate = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsAggregate';
  aggregate?: Maybe<ClinicalnoteTemplateProviderClassificationsAggregateFields>;
  nodes: Array<ClinicalnoteTemplateProviderClassifications>;
};

/** aggregate fields of "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassificationsAggregateFields = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsAggregateFields';
  avg?: Maybe<ClinicalnoteTemplateProviderClassificationsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClinicalnoteTemplateProviderClassificationsMaxFields>;
  min?: Maybe<ClinicalnoteTemplateProviderClassificationsMinFields>;
  stddev?: Maybe<ClinicalnoteTemplateProviderClassificationsStddevFields>;
  stddevPop?: Maybe<ClinicalnoteTemplateProviderClassificationsStddev_PopFields>;
  stddevSamp?: Maybe<ClinicalnoteTemplateProviderClassificationsStddev_SampFields>;
  sum?: Maybe<ClinicalnoteTemplateProviderClassificationsSumFields>;
  varPop?: Maybe<ClinicalnoteTemplateProviderClassificationsVar_PopFields>;
  varSamp?: Maybe<ClinicalnoteTemplateProviderClassificationsVar_SampFields>;
  variance?: Maybe<ClinicalnoteTemplateProviderClassificationsVarianceFields>;
};


/** aggregate fields of "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassificationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassificationsAggregateOrderBy = {
  avg?: InputMaybe<ClinicalnoteTemplateProviderClassifications_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClinicalnoteTemplateProviderClassifications_Max_Order_By>;
  min?: InputMaybe<ClinicalnoteTemplateProviderClassifications_Min_Order_By>;
  stddev?: InputMaybe<ClinicalnoteTemplateProviderClassifications_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ClinicalnoteTemplateProviderClassifications_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ClinicalnoteTemplateProviderClassifications_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ClinicalnoteTemplateProviderClassifications_Sum_Order_By>;
  var_pop?: InputMaybe<ClinicalnoteTemplateProviderClassifications_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ClinicalnoteTemplateProviderClassifications_Var_Samp_Order_By>;
  variance?: InputMaybe<ClinicalnoteTemplateProviderClassifications_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassificationsArrRelInsertInput = {
  data: Array<ClinicalnoteTemplateProviderClassificationsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClinicalnoteTemplateProviderClassificationsOnConflict>;
};

/** aggregate avg on columns */
export type ClinicalnoteTemplateProviderClassificationsAvgFields = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsAvgFields';
  clinicalnotetemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  providerclassificationId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_clinicalnotetemplate_provider_classifications". All fields are combined with a logical 'AND'. */
export type ClinicalnoteTemplateProviderClassificationsBoolExp = {
  _and?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsBoolExp>>;
  _not?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
  _or?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsBoolExp>>;
  clinicalnoteTemplate?: InputMaybe<ClinicalnoteTemplateBoolExp>;
  clinicalnotetemplateId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  providerClassification?: InputMaybe<ProviderClassificationBoolExp>;
  providerclassificationId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_clinicalnotetemplate_provider_classifications" */
export enum ClinicalnoteTemplateProviderClassificationsConstraint {
  /** unique or primary key constraint on columns "providerclassification_id", "clinicalnotetemplate_id" */
  ServerClinicalnotetemplClinicalnotetemplateId_9e385145Uniq = 'server_clinicalnotetempl_clinicalnotetemplate_id__9e385145_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerClinicalnotetemplateProviderClassificationsPkey = 'server_clinicalnotetemplate_provider_classifications_pkey'
}

/** input type for incrementing numeric columns in table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassificationsIncInput = {
  clinicalnotetemplateId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  providerclassificationId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassificationsInsertInput = {
  clinicalnoteTemplate?: InputMaybe<ClinicalnoteTemplateObjRelInsertInput>;
  clinicalnotetemplateId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  providerClassification?: InputMaybe<ProviderClassificationObjRelInsertInput>;
  providerclassificationId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ClinicalnoteTemplateProviderClassificationsMaxFields = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsMaxFields';
  clinicalnotetemplateId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  providerclassificationId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ClinicalnoteTemplateProviderClassificationsMinFields = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsMinFields';
  clinicalnotetemplateId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  providerclassificationId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassificationsMutationResponse = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClinicalnoteTemplateProviderClassifications>;
};

/** on_conflict condition type for table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassificationsOnConflict = {
  constraint: ClinicalnoteTemplateProviderClassificationsConstraint;
  update_columns?: Array<ClinicalnoteTemplateProviderClassificationsUpdateColumn>;
  where?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
};

/** Ordering options when selecting data from "server_clinicalnotetemplate_provider_classifications". */
export type ClinicalnoteTemplateProviderClassificationsOrderBy = {
  clinicalnoteTemplate?: InputMaybe<ClinicalnoteTemplateOrderBy>;
  clinicalnotetemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerClassification?: InputMaybe<ProviderClassificationOrderBy>;
  providerclassificationId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_clinicalnotetemplate_provider_classifications */
export type ClinicalnoteTemplateProviderClassificationsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_clinicalnotetemplate_provider_classifications" */
export enum ClinicalnoteTemplateProviderClassificationsSelectColumn {
  /** column name */
  ClinicalnotetemplateId = 'clinicalnotetemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderclassificationId = 'providerclassificationId'
}

/** input type for updating data in table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassificationsSetInput = {
  clinicalnotetemplateId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  providerclassificationId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ClinicalnoteTemplateProviderClassificationsStddevFields = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsStddevFields';
  clinicalnotetemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  providerclassificationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClinicalnoteTemplateProviderClassificationsStddev_PopFields = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsStddev_popFields';
  clinicalnotetemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  providerclassificationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClinicalnoteTemplateProviderClassificationsStddev_SampFields = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsStddev_sampFields';
  clinicalnotetemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  providerclassificationId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ClinicalnoteTemplateProviderClassificationsSumFields = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsSumFields';
  clinicalnotetemplateId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  providerclassificationId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_clinicalnotetemplate_provider_classifications" */
export enum ClinicalnoteTemplateProviderClassificationsUpdateColumn {
  /** column name */
  ClinicalnotetemplateId = 'clinicalnotetemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderclassificationId = 'providerclassificationId'
}

export type ClinicalnoteTemplateProviderClassificationsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClinicalnoteTemplateProviderClassificationsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClinicalnoteTemplateProviderClassificationsSetInput>;
  where: ClinicalnoteTemplateProviderClassificationsBoolExp;
};

/** aggregate var_pop on columns */
export type ClinicalnoteTemplateProviderClassificationsVar_PopFields = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsVar_popFields';
  clinicalnotetemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  providerclassificationId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClinicalnoteTemplateProviderClassificationsVar_SampFields = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsVar_sampFields';
  clinicalnotetemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  providerclassificationId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClinicalnoteTemplateProviderClassificationsVarianceFields = {
  __typename?: 'clinicalnoteTemplateProviderClassificationsVarianceFields';
  clinicalnotetemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  providerclassificationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassifications_Avg_Order_By = {
  clinicalnotetemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerclassificationId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassifications_Max_Order_By = {
  clinicalnotetemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerclassificationId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassifications_Min_Order_By = {
  clinicalnotetemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerclassificationId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassifications_Stddev_Order_By = {
  clinicalnotetemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerclassificationId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassifications_Stddev_Pop_Order_By = {
  clinicalnotetemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerclassificationId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassifications_Stddev_Samp_Order_By = {
  clinicalnotetemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerclassificationId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "clinicalnoteTemplateProviderClassifications" */
export type ClinicalnoteTemplateProviderClassifications_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClinicalnoteTemplateProviderClassifications_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClinicalnoteTemplateProviderClassifications_StreamCursorValueInput = {
  clinicalnotetemplateId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  providerclassificationId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassifications_Sum_Order_By = {
  clinicalnotetemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerclassificationId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassifications_Var_Pop_Order_By = {
  clinicalnotetemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerclassificationId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassifications_Var_Samp_Order_By = {
  clinicalnotetemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerclassificationId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_clinicalnotetemplate_provider_classifications" */
export type ClinicalnoteTemplateProviderClassifications_Variance_Order_By = {
  clinicalnotetemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerclassificationId?: InputMaybe<OrderBy>;
};

/** select columns of table "server_clinicalnotetemplate" */
export enum ClinicalnoteTemplateSelectColumn {
  /** column name */
  AssessmentId = 'assessmentId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrderInNote = 'orderInNote',
  /** column name */
  PracticeGroupId = 'practiceGroupId',
  /** column name */
  ProcedureId = 'procedureId',
  /** column name */
  ShowInPhysical = 'showInPhysical',
  /** column name */
  ShowInVirtual = 'showInVirtual'
}

/** input type for updating data in table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplateSetInput = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderInNote?: InputMaybe<Scalars['smallint']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  procedureId?: InputMaybe<Scalars['Int']>;
  showInPhysical?: InputMaybe<Scalars['Boolean']>;
  showInVirtual?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type ClinicalnoteTemplateStddevFields = {
  __typename?: 'clinicalnoteTemplateStddevFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderInNote?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClinicalnoteTemplateStddev_PopFields = {
  __typename?: 'clinicalnoteTemplateStddev_popFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderInNote?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClinicalnoteTemplateStddev_SampFields = {
  __typename?: 'clinicalnoteTemplateStddev_sampFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderInNote?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ClinicalnoteTemplateSumFields = {
  __typename?: 'clinicalnoteTemplateSumFields';
  assessmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderInNote?: Maybe<Scalars['smallint']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
  procedureId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_clinicalnotetemplate" */
export enum ClinicalnoteTemplateUpdateColumn {
  /** column name */
  AssessmentId = 'assessmentId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrderInNote = 'orderInNote',
  /** column name */
  PracticeGroupId = 'practiceGroupId',
  /** column name */
  ProcedureId = 'procedureId',
  /** column name */
  ShowInPhysical = 'showInPhysical',
  /** column name */
  ShowInVirtual = 'showInVirtual'
}

export type ClinicalnoteTemplateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClinicalnoteTemplateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClinicalnoteTemplateSetInput>;
  where: ClinicalnoteTemplateBoolExp;
};

/** aggregate var_pop on columns */
export type ClinicalnoteTemplateVar_PopFields = {
  __typename?: 'clinicalnoteTemplateVar_popFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderInNote?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClinicalnoteTemplateVar_SampFields = {
  __typename?: 'clinicalnoteTemplateVar_sampFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderInNote?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClinicalnoteTemplateVarianceFields = {
  __typename?: 'clinicalnoteTemplateVarianceFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderInNote?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplate_Avg_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderInNote?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplate_Max_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderInNote?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplate_Min_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderInNote?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplate_Stddev_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderInNote?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplate_Stddev_Pop_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderInNote?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplate_Stddev_Samp_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderInNote?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "clinicalnoteTemplate" */
export type ClinicalnoteTemplate_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClinicalnoteTemplate_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClinicalnoteTemplate_StreamCursorValueInput = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderInNote?: InputMaybe<Scalars['smallint']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  procedureId?: InputMaybe<Scalars['Int']>;
  showInPhysical?: InputMaybe<Scalars['Boolean']>;
  showInVirtual?: InputMaybe<Scalars['Boolean']>;
};

/** order by sum() on columns of table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplate_Sum_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderInNote?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplate_Var_Pop_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderInNote?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplate_Var_Samp_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderInNote?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_clinicalnotetemplate" */
export type ClinicalnoteTemplate_Variance_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderInNote?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** update columns of table "server_clinicalnote" */
export enum ClinicalnoteUpdateColumn {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Locked = 'locked',
  /** column name */
  LockedDate = 'lockedDate',
  /** column name */
  PdfId = 'pdfId'
}

export type ClinicalnoteUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClinicalnoteIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClinicalnoteSetInput>;
  where: ClinicalnoteBoolExp;
};

/** aggregate var_pop on columns */
export type ClinicalnoteVar_PopFields = {
  __typename?: 'clinicalnoteVar_popFields';
  appointmentId?: Maybe<Scalars['Float']>;
  pdfId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClinicalnoteVar_SampFields = {
  __typename?: 'clinicalnoteVar_sampFields';
  appointmentId?: Maybe<Scalars['Float']>;
  pdfId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClinicalnoteVarianceFields = {
  __typename?: 'clinicalnoteVarianceFields';
  appointmentId?: Maybe<Scalars['Float']>;
  pdfId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "clinicalnote" */
export type Clinicalnote_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Clinicalnote_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Clinicalnote_StreamCursorValueInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  lockedDate?: InputMaybe<Scalars['timestamptz']>;
  pdfId?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "server_codeset" */
export type Codeset = {
  __typename?: 'codeset';
  id: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['smallint'];
};

/** columns and relationships of "server_codeset_addon_codeset" */
export type CodesetAddon = {
  __typename?: 'codesetAddon';
  /** An object relationship */
  codeset: Codeset;
  codesetId: Scalars['Int'];
  /** An object relationship */
  hcpcs: Hcpcs;
  hcpcsId: Scalars['Int'];
  id: Scalars['Int'];
};

/** aggregated selection of "server_codeset_addon_codeset" */
export type CodesetAddonAggregate = {
  __typename?: 'codesetAddonAggregate';
  aggregate?: Maybe<CodesetAddonAggregateFields>;
  nodes: Array<CodesetAddon>;
};

/** aggregate fields of "server_codeset_addon_codeset" */
export type CodesetAddonAggregateFields = {
  __typename?: 'codesetAddonAggregateFields';
  avg?: Maybe<CodesetAddonAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CodesetAddonMaxFields>;
  min?: Maybe<CodesetAddonMinFields>;
  stddev?: Maybe<CodesetAddonStddevFields>;
  stddevPop?: Maybe<CodesetAddonStddev_PopFields>;
  stddevSamp?: Maybe<CodesetAddonStddev_SampFields>;
  sum?: Maybe<CodesetAddonSumFields>;
  varPop?: Maybe<CodesetAddonVar_PopFields>;
  varSamp?: Maybe<CodesetAddonVar_SampFields>;
  variance?: Maybe<CodesetAddonVarianceFields>;
};


/** aggregate fields of "server_codeset_addon_codeset" */
export type CodesetAddonAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CodesetAddonSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_codeset_addon_codeset" */
export type CodesetAddonAggregateOrderBy = {
  avg?: InputMaybe<CodesetAddon_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CodesetAddon_Max_Order_By>;
  min?: InputMaybe<CodesetAddon_Min_Order_By>;
  stddev?: InputMaybe<CodesetAddon_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CodesetAddon_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CodesetAddon_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CodesetAddon_Sum_Order_By>;
  var_pop?: InputMaybe<CodesetAddon_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CodesetAddon_Var_Samp_Order_By>;
  variance?: InputMaybe<CodesetAddon_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_codeset_addon_codeset" */
export type CodesetAddonArrRelInsertInput = {
  data: Array<CodesetAddonInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CodesetAddonOnConflict>;
};

/** aggregate avg on columns */
export type CodesetAddonAvgFields = {
  __typename?: 'codesetAddonAvgFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_codeset_addon_codeset". All fields are combined with a logical 'AND'. */
export type CodesetAddonBoolExp = {
  _and?: InputMaybe<Array<CodesetAddonBoolExp>>;
  _not?: InputMaybe<CodesetAddonBoolExp>;
  _or?: InputMaybe<Array<CodesetAddonBoolExp>>;
  codeset?: InputMaybe<CodesetBoolExp>;
  codesetId?: InputMaybe<IntComparisonExp>;
  hcpcs?: InputMaybe<HcpcsBoolExp>;
  hcpcsId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_codeset_addon_codeset" */
export enum CodesetAddonConstraint {
  /** unique or primary key constraint on columns "hcpcs_id", "codeset_id" */
  ServerCodesetAddonCodesetCodesetIdHcpcsId_7cad8a75Uniq = 'server_codeset_addon_codeset_codeset_id_hcpcs_id_7cad8a75_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerCodesetAddonCodesetPkey = 'server_codeset_addon_codeset_pkey'
}

/** input type for incrementing numeric columns in table "server_codeset_addon_codeset" */
export type CodesetAddonIncInput = {
  codesetId?: InputMaybe<Scalars['Int']>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_codeset_addon_codeset" */
export type CodesetAddonInsertInput = {
  codeset?: InputMaybe<CodesetObjRelInsertInput>;
  codesetId?: InputMaybe<Scalars['Int']>;
  hcpcs?: InputMaybe<HcpcsObjRelInsertInput>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CodesetAddonMaxFields = {
  __typename?: 'codesetAddonMaxFields';
  codesetId?: Maybe<Scalars['Int']>;
  hcpcsId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CodesetAddonMinFields = {
  __typename?: 'codesetAddonMinFields';
  codesetId?: Maybe<Scalars['Int']>;
  hcpcsId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_codeset_addon_codeset" */
export type CodesetAddonMutationResponse = {
  __typename?: 'codesetAddonMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CodesetAddon>;
};

/** on_conflict condition type for table "server_codeset_addon_codeset" */
export type CodesetAddonOnConflict = {
  constraint: CodesetAddonConstraint;
  update_columns?: Array<CodesetAddonUpdateColumn>;
  where?: InputMaybe<CodesetAddonBoolExp>;
};

/** Ordering options when selecting data from "server_codeset_addon_codeset". */
export type CodesetAddonOrderBy = {
  codeset?: InputMaybe<CodesetOrderBy>;
  codesetId?: InputMaybe<OrderBy>;
  hcpcs?: InputMaybe<HcpcsOrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_codeset_addon_codeset */
export type CodesetAddonPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_codeset_addon_codeset" */
export enum CodesetAddonSelectColumn {
  /** column name */
  CodesetId = 'codesetId',
  /** column name */
  HcpcsId = 'hcpcsId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "server_codeset_addon_codeset" */
export type CodesetAddonSetInput = {
  codesetId?: InputMaybe<Scalars['Int']>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CodesetAddonStddevFields = {
  __typename?: 'codesetAddonStddevFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CodesetAddonStddev_PopFields = {
  __typename?: 'codesetAddonStddev_popFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CodesetAddonStddev_SampFields = {
  __typename?: 'codesetAddonStddev_sampFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CodesetAddonSumFields = {
  __typename?: 'codesetAddonSumFields';
  codesetId?: Maybe<Scalars['Int']>;
  hcpcsId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_codeset_addon_codeset" */
export enum CodesetAddonUpdateColumn {
  /** column name */
  CodesetId = 'codesetId',
  /** column name */
  HcpcsId = 'hcpcsId',
  /** column name */
  Id = 'id'
}

export type CodesetAddonUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CodesetAddonIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CodesetAddonSetInput>;
  where: CodesetAddonBoolExp;
};

/** aggregate var_pop on columns */
export type CodesetAddonVar_PopFields = {
  __typename?: 'codesetAddonVar_popFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CodesetAddonVar_SampFields = {
  __typename?: 'codesetAddonVar_sampFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CodesetAddonVarianceFields = {
  __typename?: 'codesetAddonVarianceFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_codeset_addon_codeset" */
export type CodesetAddon_Avg_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_codeset_addon_codeset" */
export type CodesetAddon_Max_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_codeset_addon_codeset" */
export type CodesetAddon_Min_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_codeset_addon_codeset" */
export type CodesetAddon_Stddev_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_codeset_addon_codeset" */
export type CodesetAddon_Stddev_Pop_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_codeset_addon_codeset" */
export type CodesetAddon_Stddev_Samp_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "codesetAddon" */
export type CodesetAddon_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CodesetAddon_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CodesetAddon_StreamCursorValueInput = {
  codesetId?: InputMaybe<Scalars['Int']>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_codeset_addon_codeset" */
export type CodesetAddon_Sum_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_codeset_addon_codeset" */
export type CodesetAddon_Var_Pop_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_codeset_addon_codeset" */
export type CodesetAddon_Var_Samp_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_codeset_addon_codeset" */
export type CodesetAddon_Variance_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregated selection of "server_codeset" */
export type CodesetAggregate = {
  __typename?: 'codesetAggregate';
  aggregate?: Maybe<CodesetAggregateFields>;
  nodes: Array<Codeset>;
};

/** aggregate fields of "server_codeset" */
export type CodesetAggregateFields = {
  __typename?: 'codesetAggregateFields';
  avg?: Maybe<CodesetAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CodesetMaxFields>;
  min?: Maybe<CodesetMinFields>;
  stddev?: Maybe<CodesetStddevFields>;
  stddevPop?: Maybe<CodesetStddev_PopFields>;
  stddevSamp?: Maybe<CodesetStddev_SampFields>;
  sum?: Maybe<CodesetSumFields>;
  varPop?: Maybe<CodesetVar_PopFields>;
  varSamp?: Maybe<CodesetVar_SampFields>;
  variance?: Maybe<CodesetVarianceFields>;
};


/** aggregate fields of "server_codeset" */
export type CodesetAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CodesetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CodesetAvgFields = {
  __typename?: 'codesetAvgFields';
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "server_codeset_base_codeset" */
export type CodesetBase = {
  __typename?: 'codesetBase';
  /** An object relationship */
  codeset: Codeset;
  codesetId: Scalars['Int'];
  /** An object relationship */
  hcpcs: Hcpcs;
  hcpcsId: Scalars['Int'];
  id: Scalars['Int'];
};

/** aggregated selection of "server_codeset_base_codeset" */
export type CodesetBaseAggregate = {
  __typename?: 'codesetBaseAggregate';
  aggregate?: Maybe<CodesetBaseAggregateFields>;
  nodes: Array<CodesetBase>;
};

/** aggregate fields of "server_codeset_base_codeset" */
export type CodesetBaseAggregateFields = {
  __typename?: 'codesetBaseAggregateFields';
  avg?: Maybe<CodesetBaseAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CodesetBaseMaxFields>;
  min?: Maybe<CodesetBaseMinFields>;
  stddev?: Maybe<CodesetBaseStddevFields>;
  stddevPop?: Maybe<CodesetBaseStddev_PopFields>;
  stddevSamp?: Maybe<CodesetBaseStddev_SampFields>;
  sum?: Maybe<CodesetBaseSumFields>;
  varPop?: Maybe<CodesetBaseVar_PopFields>;
  varSamp?: Maybe<CodesetBaseVar_SampFields>;
  variance?: Maybe<CodesetBaseVarianceFields>;
};


/** aggregate fields of "server_codeset_base_codeset" */
export type CodesetBaseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CodesetBaseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_codeset_base_codeset" */
export type CodesetBaseAggregateOrderBy = {
  avg?: InputMaybe<CodesetBase_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CodesetBase_Max_Order_By>;
  min?: InputMaybe<CodesetBase_Min_Order_By>;
  stddev?: InputMaybe<CodesetBase_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CodesetBase_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CodesetBase_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CodesetBase_Sum_Order_By>;
  var_pop?: InputMaybe<CodesetBase_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CodesetBase_Var_Samp_Order_By>;
  variance?: InputMaybe<CodesetBase_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_codeset_base_codeset" */
export type CodesetBaseArrRelInsertInput = {
  data: Array<CodesetBaseInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CodesetBaseOnConflict>;
};

/** aggregate avg on columns */
export type CodesetBaseAvgFields = {
  __typename?: 'codesetBaseAvgFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_codeset_base_codeset". All fields are combined with a logical 'AND'. */
export type CodesetBaseBoolExp = {
  _and?: InputMaybe<Array<CodesetBaseBoolExp>>;
  _not?: InputMaybe<CodesetBaseBoolExp>;
  _or?: InputMaybe<Array<CodesetBaseBoolExp>>;
  codeset?: InputMaybe<CodesetBoolExp>;
  codesetId?: InputMaybe<IntComparisonExp>;
  hcpcs?: InputMaybe<HcpcsBoolExp>;
  hcpcsId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_codeset_base_codeset" */
export enum CodesetBaseConstraint {
  /** unique or primary key constraint on columns "hcpcs_id", "codeset_id" */
  ServerCodesetBaseCodesetCodesetIdHcpcsId_8f6da041Uniq = 'server_codeset_base_codeset_codeset_id_hcpcs_id_8f6da041_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerCodesetBaseCodesetPkey = 'server_codeset_base_codeset_pkey'
}

/** input type for incrementing numeric columns in table "server_codeset_base_codeset" */
export type CodesetBaseIncInput = {
  codesetId?: InputMaybe<Scalars['Int']>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_codeset_base_codeset" */
export type CodesetBaseInsertInput = {
  codeset?: InputMaybe<CodesetObjRelInsertInput>;
  codesetId?: InputMaybe<Scalars['Int']>;
  hcpcs?: InputMaybe<HcpcsObjRelInsertInput>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CodesetBaseMaxFields = {
  __typename?: 'codesetBaseMaxFields';
  codesetId?: Maybe<Scalars['Int']>;
  hcpcsId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CodesetBaseMinFields = {
  __typename?: 'codesetBaseMinFields';
  codesetId?: Maybe<Scalars['Int']>;
  hcpcsId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_codeset_base_codeset" */
export type CodesetBaseMutationResponse = {
  __typename?: 'codesetBaseMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CodesetBase>;
};

/** on_conflict condition type for table "server_codeset_base_codeset" */
export type CodesetBaseOnConflict = {
  constraint: CodesetBaseConstraint;
  update_columns?: Array<CodesetBaseUpdateColumn>;
  where?: InputMaybe<CodesetBaseBoolExp>;
};

/** Ordering options when selecting data from "server_codeset_base_codeset". */
export type CodesetBaseOrderBy = {
  codeset?: InputMaybe<CodesetOrderBy>;
  codesetId?: InputMaybe<OrderBy>;
  hcpcs?: InputMaybe<HcpcsOrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_codeset_base_codeset */
export type CodesetBasePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_codeset_base_codeset" */
export enum CodesetBaseSelectColumn {
  /** column name */
  CodesetId = 'codesetId',
  /** column name */
  HcpcsId = 'hcpcsId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "server_codeset_base_codeset" */
export type CodesetBaseSetInput = {
  codesetId?: InputMaybe<Scalars['Int']>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CodesetBaseStddevFields = {
  __typename?: 'codesetBaseStddevFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CodesetBaseStddev_PopFields = {
  __typename?: 'codesetBaseStddev_popFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CodesetBaseStddev_SampFields = {
  __typename?: 'codesetBaseStddev_sampFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CodesetBaseSumFields = {
  __typename?: 'codesetBaseSumFields';
  codesetId?: Maybe<Scalars['Int']>;
  hcpcsId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_codeset_base_codeset" */
export enum CodesetBaseUpdateColumn {
  /** column name */
  CodesetId = 'codesetId',
  /** column name */
  HcpcsId = 'hcpcsId',
  /** column name */
  Id = 'id'
}

export type CodesetBaseUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CodesetBaseIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CodesetBaseSetInput>;
  where: CodesetBaseBoolExp;
};

/** aggregate var_pop on columns */
export type CodesetBaseVar_PopFields = {
  __typename?: 'codesetBaseVar_popFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CodesetBaseVar_SampFields = {
  __typename?: 'codesetBaseVar_sampFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CodesetBaseVarianceFields = {
  __typename?: 'codesetBaseVarianceFields';
  codesetId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_codeset_base_codeset" */
export type CodesetBase_Avg_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_codeset_base_codeset" */
export type CodesetBase_Max_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_codeset_base_codeset" */
export type CodesetBase_Min_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_codeset_base_codeset" */
export type CodesetBase_Stddev_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_codeset_base_codeset" */
export type CodesetBase_Stddev_Pop_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_codeset_base_codeset" */
export type CodesetBase_Stddev_Samp_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "codesetBase" */
export type CodesetBase_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CodesetBase_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CodesetBase_StreamCursorValueInput = {
  codesetId?: InputMaybe<Scalars['Int']>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_codeset_base_codeset" */
export type CodesetBase_Sum_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_codeset_base_codeset" */
export type CodesetBase_Var_Pop_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_codeset_base_codeset" */
export type CodesetBase_Var_Samp_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_codeset_base_codeset" */
export type CodesetBase_Variance_Order_By = {
  codesetId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "server_codeset". All fields are combined with a logical 'AND'. */
export type CodesetBoolExp = {
  _and?: InputMaybe<Array<CodesetBoolExp>>;
  _not?: InputMaybe<CodesetBoolExp>;
  _or?: InputMaybe<Array<CodesetBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<SmallintComparisonExp>;
};

/** unique or primary key constraints on table "server_codeset" */
export enum CodesetConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerCodesetPkey = 'server_codeset_pkey'
}

/** input type for incrementing numeric columns in table "server_codeset" */
export type CodesetIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_codeset" */
export type CodesetInsertInput = {
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type CodesetMaxFields = {
  __typename?: 'codesetMaxFields';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type CodesetMinFields = {
  __typename?: 'codesetMinFields';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_codeset" */
export type CodesetMutationResponse = {
  __typename?: 'codesetMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Codeset>;
};

/** input type for inserting object relation for remote table "server_codeset" */
export type CodesetObjRelInsertInput = {
  data: CodesetInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CodesetOnConflict>;
};

/** on_conflict condition type for table "server_codeset" */
export type CodesetOnConflict = {
  constraint: CodesetConstraint;
  update_columns?: Array<CodesetUpdateColumn>;
  where?: InputMaybe<CodesetBoolExp>;
};

/** Ordering options when selecting data from "server_codeset". */
export type CodesetOrderBy = {
  id?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_codeset */
export type CodesetPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_codeset" */
export enum CodesetSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "server_codeset" */
export type CodesetSetInput = {
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type CodesetStddevFields = {
  __typename?: 'codesetStddevFields';
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CodesetStddev_PopFields = {
  __typename?: 'codesetStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CodesetStddev_SampFields = {
  __typename?: 'codesetStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CodesetSumFields = {
  __typename?: 'codesetSumFields';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_codeset" */
export enum CodesetUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

export type CodesetUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CodesetIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CodesetSetInput>;
  where: CodesetBoolExp;
};

/** aggregate var_pop on columns */
export type CodesetVar_PopFields = {
  __typename?: 'codesetVar_popFields';
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CodesetVar_SampFields = {
  __typename?: 'codesetVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CodesetVarianceFields = {
  __typename?: 'codesetVarianceFields';
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "codeset" */
export type Codeset_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Codeset_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Codeset_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** columns and relationships of "server_consent" */
export type Consent = {
  __typename?: 'consent';
  consentType: Scalars['smallint'];
  data: Scalars['String'];
  id: Scalars['Int'];
  practiceId: Scalars['Int'];
  /** An object relationship */
  practicegroup: Practicegroup;
  requiredPhysicalVisit: Scalars['Boolean'];
  requiredVirtualVisit: Scalars['Boolean'];
  /** An array relationship */
  signedConsents: Array<SignedConsent>;
  /** An aggregate relationship */
  signedConsentsAggregate: SignedConsentAggregate;
  title: Scalars['String'];
};


/** columns and relationships of "server_consent" */
export type ConsentSignedConsentsArgs = {
  distinctOn?: InputMaybe<Array<SignedConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignedConsentOrderBy>>;
  where?: InputMaybe<SignedConsentBoolExp>;
};


/** columns and relationships of "server_consent" */
export type ConsentSignedConsentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SignedConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignedConsentOrderBy>>;
  where?: InputMaybe<SignedConsentBoolExp>;
};

/** aggregated selection of "server_consent" */
export type ConsentAggregate = {
  __typename?: 'consentAggregate';
  aggregate?: Maybe<ConsentAggregateFields>;
  nodes: Array<Consent>;
};

/** aggregate fields of "server_consent" */
export type ConsentAggregateFields = {
  __typename?: 'consentAggregateFields';
  avg?: Maybe<ConsentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ConsentMaxFields>;
  min?: Maybe<ConsentMinFields>;
  stddev?: Maybe<ConsentStddevFields>;
  stddevPop?: Maybe<ConsentStddev_PopFields>;
  stddevSamp?: Maybe<ConsentStddev_SampFields>;
  sum?: Maybe<ConsentSumFields>;
  varPop?: Maybe<ConsentVar_PopFields>;
  varSamp?: Maybe<ConsentVar_SampFields>;
  variance?: Maybe<ConsentVarianceFields>;
};


/** aggregate fields of "server_consent" */
export type ConsentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConsentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_consent" */
export type ConsentAggregateOrderBy = {
  avg?: InputMaybe<Consent_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Consent_Max_Order_By>;
  min?: InputMaybe<Consent_Min_Order_By>;
  stddev?: InputMaybe<Consent_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Consent_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Consent_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Consent_Sum_Order_By>;
  var_pop?: InputMaybe<Consent_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Consent_Var_Samp_Order_By>;
  variance?: InputMaybe<Consent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_consent" */
export type ConsentArrRelInsertInput = {
  data: Array<ConsentInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ConsentOnConflict>;
};

/** aggregate avg on columns */
export type ConsentAvgFields = {
  __typename?: 'consentAvgFields';
  consentType?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_consent". All fields are combined with a logical 'AND'. */
export type ConsentBoolExp = {
  _and?: InputMaybe<Array<ConsentBoolExp>>;
  _not?: InputMaybe<ConsentBoolExp>;
  _or?: InputMaybe<Array<ConsentBoolExp>>;
  consentType?: InputMaybe<SmallintComparisonExp>;
  data?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  practiceId?: InputMaybe<IntComparisonExp>;
  practicegroup?: InputMaybe<PracticegroupBoolExp>;
  requiredPhysicalVisit?: InputMaybe<BooleanComparisonExp>;
  requiredVirtualVisit?: InputMaybe<BooleanComparisonExp>;
  signedConsents?: InputMaybe<SignedConsentBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_consent" */
export enum ConsentConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerConsentPkey = 'server_consent_pkey'
}

/** input type for incrementing numeric columns in table "server_consent" */
export type ConsentIncInput = {
  consentType?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  practiceId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_consent" */
export type ConsentInsertInput = {
  consentType?: InputMaybe<Scalars['smallint']>;
  data?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  practiceId?: InputMaybe<Scalars['Int']>;
  practicegroup?: InputMaybe<PracticegroupObjRelInsertInput>;
  requiredPhysicalVisit?: InputMaybe<Scalars['Boolean']>;
  requiredVirtualVisit?: InputMaybe<Scalars['Boolean']>;
  signedConsents?: InputMaybe<SignedConsentArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ConsentMaxFields = {
  __typename?: 'consentMaxFields';
  consentType?: Maybe<Scalars['smallint']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  practiceId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ConsentMinFields = {
  __typename?: 'consentMinFields';
  consentType?: Maybe<Scalars['smallint']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  practiceId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_consent" */
export type ConsentMutationResponse = {
  __typename?: 'consentMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Consent>;
};

/** input type for inserting object relation for remote table "server_consent" */
export type ConsentObjRelInsertInput = {
  data: ConsentInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ConsentOnConflict>;
};

/** on_conflict condition type for table "server_consent" */
export type ConsentOnConflict = {
  constraint: ConsentConstraint;
  update_columns?: Array<ConsentUpdateColumn>;
  where?: InputMaybe<ConsentBoolExp>;
};

/** Ordering options when selecting data from "server_consent". */
export type ConsentOrderBy = {
  consentType?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  practicegroup?: InputMaybe<PracticegroupOrderBy>;
  requiredPhysicalVisit?: InputMaybe<OrderBy>;
  requiredVirtualVisit?: InputMaybe<OrderBy>;
  signedConsentsAggregate?: InputMaybe<SignedConsentAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_consent */
export type ConsentPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_consent" */
export enum ConsentSelectColumn {
  /** column name */
  ConsentType = 'consentType',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  PracticeId = 'practiceId',
  /** column name */
  RequiredPhysicalVisit = 'requiredPhysicalVisit',
  /** column name */
  RequiredVirtualVisit = 'requiredVirtualVisit',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "server_consent" */
export type ConsentSetInput = {
  consentType?: InputMaybe<Scalars['smallint']>;
  data?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  practiceId?: InputMaybe<Scalars['Int']>;
  requiredPhysicalVisit?: InputMaybe<Scalars['Boolean']>;
  requiredVirtualVisit?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ConsentStddevFields = {
  __typename?: 'consentStddevFields';
  consentType?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ConsentStddev_PopFields = {
  __typename?: 'consentStddev_popFields';
  consentType?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ConsentStddev_SampFields = {
  __typename?: 'consentStddev_sampFields';
  consentType?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ConsentSumFields = {
  __typename?: 'consentSumFields';
  consentType?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  practiceId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_consent" */
export enum ConsentUpdateColumn {
  /** column name */
  ConsentType = 'consentType',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  PracticeId = 'practiceId',
  /** column name */
  RequiredPhysicalVisit = 'requiredPhysicalVisit',
  /** column name */
  RequiredVirtualVisit = 'requiredVirtualVisit',
  /** column name */
  Title = 'title'
}

export type ConsentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ConsentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConsentSetInput>;
  where: ConsentBoolExp;
};

/** aggregate var_pop on columns */
export type ConsentVar_PopFields = {
  __typename?: 'consentVar_popFields';
  consentType?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ConsentVar_SampFields = {
  __typename?: 'consentVar_sampFields';
  consentType?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ConsentVarianceFields = {
  __typename?: 'consentVarianceFields';
  consentType?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_consent" */
export type Consent_Avg_Order_By = {
  consentType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_consent" */
export type Consent_Max_Order_By = {
  consentType?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_consent" */
export type Consent_Min_Order_By = {
  consentType?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_consent" */
export type Consent_Stddev_Order_By = {
  consentType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_consent" */
export type Consent_Stddev_Pop_Order_By = {
  consentType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_consent" */
export type Consent_Stddev_Samp_Order_By = {
  consentType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "consent" */
export type Consent_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Consent_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Consent_StreamCursorValueInput = {
  consentType?: InputMaybe<Scalars['smallint']>;
  data?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  practiceId?: InputMaybe<Scalars['Int']>;
  requiredPhysicalVisit?: InputMaybe<Scalars['Boolean']>;
  requiredVirtualVisit?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_consent" */
export type Consent_Sum_Order_By = {
  consentType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_consent" */
export type Consent_Var_Pop_Order_By = {
  consentType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_consent" */
export type Consent_Var_Samp_Order_By = {
  consentType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_consent" */
export type Consent_Variance_Order_By = {
  consentType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_cpoclaim" */
export type CpoClaim = {
  __typename?: 'cpoClaim';
  /** An object relationship */
  claim: Claim;
  claimPtrId: Scalars['Int'];
  endDate: Scalars['date'];
  /** An array relationship */
  externalSignedOrders: Array<ExternalSignedOrder>;
  /** An aggregate relationship */
  externalSignedOrdersAggregate: ExternalSignedOrderAggregate;
  startDate: Scalars['date'];
};


/** columns and relationships of "server_cpoclaim" */
export type CpoClaimExternalSignedOrdersArgs = {
  distinctOn?: InputMaybe<Array<ExternalSignedOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalSignedOrderOrderBy>>;
  where?: InputMaybe<ExternalSignedOrderBoolExp>;
};


/** columns and relationships of "server_cpoclaim" */
export type CpoClaimExternalSignedOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExternalSignedOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalSignedOrderOrderBy>>;
  where?: InputMaybe<ExternalSignedOrderBoolExp>;
};

/** aggregated selection of "server_cpoclaim" */
export type CpoClaimAggregate = {
  __typename?: 'cpoClaimAggregate';
  aggregate?: Maybe<CpoClaimAggregateFields>;
  nodes: Array<CpoClaim>;
};

/** aggregate fields of "server_cpoclaim" */
export type CpoClaimAggregateFields = {
  __typename?: 'cpoClaimAggregateFields';
  avg?: Maybe<CpoClaimAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CpoClaimMaxFields>;
  min?: Maybe<CpoClaimMinFields>;
  stddev?: Maybe<CpoClaimStddevFields>;
  stddevPop?: Maybe<CpoClaimStddev_PopFields>;
  stddevSamp?: Maybe<CpoClaimStddev_SampFields>;
  sum?: Maybe<CpoClaimSumFields>;
  varPop?: Maybe<CpoClaimVar_PopFields>;
  varSamp?: Maybe<CpoClaimVar_SampFields>;
  variance?: Maybe<CpoClaimVarianceFields>;
};


/** aggregate fields of "server_cpoclaim" */
export type CpoClaimAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CpoClaimSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CpoClaimAvgFields = {
  __typename?: 'cpoClaimAvgFields';
  claimPtrId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_cpoclaim". All fields are combined with a logical 'AND'. */
export type CpoClaimBoolExp = {
  _and?: InputMaybe<Array<CpoClaimBoolExp>>;
  _not?: InputMaybe<CpoClaimBoolExp>;
  _or?: InputMaybe<Array<CpoClaimBoolExp>>;
  claim?: InputMaybe<ClaimBoolExp>;
  claimPtrId?: InputMaybe<IntComparisonExp>;
  endDate?: InputMaybe<DateComparisonExp>;
  externalSignedOrders?: InputMaybe<ExternalSignedOrderBoolExp>;
  startDate?: InputMaybe<DateComparisonExp>;
};

/** unique or primary key constraints on table "server_cpoclaim" */
export enum CpoClaimConstraint {
  /** unique or primary key constraint on columns "claim_ptr_id" */
  ServerCpoclaimPkey = 'server_cpoclaim_pkey'
}

/** input type for incrementing numeric columns in table "server_cpoclaim" */
export type CpoClaimIncInput = {
  claimPtrId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_cpoclaim" */
export type CpoClaimInsertInput = {
  claim?: InputMaybe<ClaimObjRelInsertInput>;
  claimPtrId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['date']>;
  externalSignedOrders?: InputMaybe<ExternalSignedOrderArrRelInsertInput>;
  startDate?: InputMaybe<Scalars['date']>;
};

/** aggregate max on columns */
export type CpoClaimMaxFields = {
  __typename?: 'cpoClaimMaxFields';
  claimPtrId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['date']>;
  startDate?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type CpoClaimMinFields = {
  __typename?: 'cpoClaimMinFields';
  claimPtrId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['date']>;
  startDate?: Maybe<Scalars['date']>;
};

/** response of any mutation on the table "server_cpoclaim" */
export type CpoClaimMutationResponse = {
  __typename?: 'cpoClaimMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CpoClaim>;
};

/** input type for inserting object relation for remote table "server_cpoclaim" */
export type CpoClaimObjRelInsertInput = {
  data: CpoClaimInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CpoClaimOnConflict>;
};

/** on_conflict condition type for table "server_cpoclaim" */
export type CpoClaimOnConflict = {
  constraint: CpoClaimConstraint;
  update_columns?: Array<CpoClaimUpdateColumn>;
  where?: InputMaybe<CpoClaimBoolExp>;
};

/** Ordering options when selecting data from "server_cpoclaim". */
export type CpoClaimOrderBy = {
  claim?: InputMaybe<ClaimOrderBy>;
  claimPtrId?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  externalSignedOrdersAggregate?: InputMaybe<ExternalSignedOrderAggregateOrderBy>;
  startDate?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_cpoclaim */
export type CpoClaimPkColumnsInput = {
  claimPtrId: Scalars['Int'];
};

/** select columns of table "server_cpoclaim" */
export enum CpoClaimSelectColumn {
  /** column name */
  ClaimPtrId = 'claimPtrId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  StartDate = 'startDate'
}

/** input type for updating data in table "server_cpoclaim" */
export type CpoClaimSetInput = {
  claimPtrId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['date']>;
  startDate?: InputMaybe<Scalars['date']>;
};

/** aggregate stddev on columns */
export type CpoClaimStddevFields = {
  __typename?: 'cpoClaimStddevFields';
  claimPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CpoClaimStddev_PopFields = {
  __typename?: 'cpoClaimStddev_popFields';
  claimPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CpoClaimStddev_SampFields = {
  __typename?: 'cpoClaimStddev_sampFields';
  claimPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CpoClaimSumFields = {
  __typename?: 'cpoClaimSumFields';
  claimPtrId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_cpoclaim" */
export enum CpoClaimUpdateColumn {
  /** column name */
  ClaimPtrId = 'claimPtrId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  StartDate = 'startDate'
}

export type CpoClaimUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CpoClaimIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CpoClaimSetInput>;
  where: CpoClaimBoolExp;
};

/** aggregate var_pop on columns */
export type CpoClaimVar_PopFields = {
  __typename?: 'cpoClaimVar_popFields';
  claimPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CpoClaimVar_SampFields = {
  __typename?: 'cpoClaimVar_sampFields';
  claimPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CpoClaimVarianceFields = {
  __typename?: 'cpoClaimVarianceFields';
  claimPtrId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "cpoClaim" */
export type CpoClaim_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CpoClaim_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CpoClaim_StreamCursorValueInput = {
  claimPtrId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['date']>;
  startDate?: InputMaybe<Scalars['date']>;
};

/** columns and relationships of "server_dependantorder" */
export type DependantOrder = {
  __typename?: 'dependantOrder';
  /** An object relationship */
  baseorder: Baseorder;
  baseorderPtrId: Scalars['Int'];
  /** An object relationship */
  parentOrder: Baseorder;
  parentOrderId: Scalars['Int'];
};

/** aggregated selection of "server_dependantorder" */
export type DependantOrderAggregate = {
  __typename?: 'dependantOrderAggregate';
  aggregate?: Maybe<DependantOrderAggregateFields>;
  nodes: Array<DependantOrder>;
};

/** aggregate fields of "server_dependantorder" */
export type DependantOrderAggregateFields = {
  __typename?: 'dependantOrderAggregateFields';
  avg?: Maybe<DependantOrderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DependantOrderMaxFields>;
  min?: Maybe<DependantOrderMinFields>;
  stddev?: Maybe<DependantOrderStddevFields>;
  stddevPop?: Maybe<DependantOrderStddev_PopFields>;
  stddevSamp?: Maybe<DependantOrderStddev_SampFields>;
  sum?: Maybe<DependantOrderSumFields>;
  varPop?: Maybe<DependantOrderVar_PopFields>;
  varSamp?: Maybe<DependantOrderVar_SampFields>;
  variance?: Maybe<DependantOrderVarianceFields>;
};


/** aggregate fields of "server_dependantorder" */
export type DependantOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DependantOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_dependantorder" */
export type DependantOrderAggregateOrderBy = {
  avg?: InputMaybe<DependantOrder_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DependantOrder_Max_Order_By>;
  min?: InputMaybe<DependantOrder_Min_Order_By>;
  stddev?: InputMaybe<DependantOrder_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DependantOrder_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DependantOrder_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DependantOrder_Sum_Order_By>;
  var_pop?: InputMaybe<DependantOrder_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DependantOrder_Var_Samp_Order_By>;
  variance?: InputMaybe<DependantOrder_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_dependantorder" */
export type DependantOrderArrRelInsertInput = {
  data: Array<DependantOrderInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<DependantOrderOnConflict>;
};

/** aggregate avg on columns */
export type DependantOrderAvgFields = {
  __typename?: 'dependantOrderAvgFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  parentOrderId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_dependantorder". All fields are combined with a logical 'AND'. */
export type DependantOrderBoolExp = {
  _and?: InputMaybe<Array<DependantOrderBoolExp>>;
  _not?: InputMaybe<DependantOrderBoolExp>;
  _or?: InputMaybe<Array<DependantOrderBoolExp>>;
  baseorder?: InputMaybe<BaseorderBoolExp>;
  baseorderPtrId?: InputMaybe<IntComparisonExp>;
  parentOrder?: InputMaybe<BaseorderBoolExp>;
  parentOrderId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_dependantorder" */
export enum DependantOrderConstraint {
  /** unique or primary key constraint on columns "baseorder_ptr_id" */
  ServerDependantorderPkey = 'server_dependantorder_pkey'
}

/** input type for incrementing numeric columns in table "server_dependantorder" */
export type DependantOrderIncInput = {
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
  parentOrderId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_dependantorder" */
export type DependantOrderInsertInput = {
  baseorder?: InputMaybe<BaseorderObjRelInsertInput>;
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
  parentOrder?: InputMaybe<BaseorderObjRelInsertInput>;
  parentOrderId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type DependantOrderMaxFields = {
  __typename?: 'dependantOrderMaxFields';
  baseorderPtrId?: Maybe<Scalars['Int']>;
  parentOrderId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type DependantOrderMinFields = {
  __typename?: 'dependantOrderMinFields';
  baseorderPtrId?: Maybe<Scalars['Int']>;
  parentOrderId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_dependantorder" */
export type DependantOrderMutationResponse = {
  __typename?: 'dependantOrderMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DependantOrder>;
};

/** input type for inserting object relation for remote table "server_dependantorder" */
export type DependantOrderObjRelInsertInput = {
  data: DependantOrderInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<DependantOrderOnConflict>;
};

/** on_conflict condition type for table "server_dependantorder" */
export type DependantOrderOnConflict = {
  constraint: DependantOrderConstraint;
  update_columns?: Array<DependantOrderUpdateColumn>;
  where?: InputMaybe<DependantOrderBoolExp>;
};

/** Ordering options when selecting data from "server_dependantorder". */
export type DependantOrderOrderBy = {
  baseorder?: InputMaybe<BaseorderOrderBy>;
  baseorderPtrId?: InputMaybe<OrderBy>;
  parentOrder?: InputMaybe<BaseorderOrderBy>;
  parentOrderId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_dependantorder */
export type DependantOrderPkColumnsInput = {
  baseorderPtrId: Scalars['Int'];
};

/** select columns of table "server_dependantorder" */
export enum DependantOrderSelectColumn {
  /** column name */
  BaseorderPtrId = 'baseorderPtrId',
  /** column name */
  ParentOrderId = 'parentOrderId'
}

/** input type for updating data in table "server_dependantorder" */
export type DependantOrderSetInput = {
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
  parentOrderId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type DependantOrderStddevFields = {
  __typename?: 'dependantOrderStddevFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  parentOrderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type DependantOrderStddev_PopFields = {
  __typename?: 'dependantOrderStddev_popFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  parentOrderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type DependantOrderStddev_SampFields = {
  __typename?: 'dependantOrderStddev_sampFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  parentOrderId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type DependantOrderSumFields = {
  __typename?: 'dependantOrderSumFields';
  baseorderPtrId?: Maybe<Scalars['Int']>;
  parentOrderId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_dependantorder" */
export enum DependantOrderUpdateColumn {
  /** column name */
  BaseorderPtrId = 'baseorderPtrId',
  /** column name */
  ParentOrderId = 'parentOrderId'
}

export type DependantOrderUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DependantOrderIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DependantOrderSetInput>;
  where: DependantOrderBoolExp;
};

/** aggregate var_pop on columns */
export type DependantOrderVar_PopFields = {
  __typename?: 'dependantOrderVar_popFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  parentOrderId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type DependantOrderVar_SampFields = {
  __typename?: 'dependantOrderVar_sampFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  parentOrderId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DependantOrderVarianceFields = {
  __typename?: 'dependantOrderVarianceFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  parentOrderId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_dependantorder" */
export type DependantOrder_Avg_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  parentOrderId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_dependantorder" */
export type DependantOrder_Max_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  parentOrderId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_dependantorder" */
export type DependantOrder_Min_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  parentOrderId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_dependantorder" */
export type DependantOrder_Stddev_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  parentOrderId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_dependantorder" */
export type DependantOrder_Stddev_Pop_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  parentOrderId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_dependantorder" */
export type DependantOrder_Stddev_Samp_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  parentOrderId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "dependantOrder" */
export type DependantOrder_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DependantOrder_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DependantOrder_StreamCursorValueInput = {
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
  parentOrderId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_dependantorder" */
export type DependantOrder_Sum_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  parentOrderId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_dependantorder" */
export type DependantOrder_Var_Pop_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  parentOrderId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_dependantorder" */
export type DependantOrder_Var_Samp_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  parentOrderId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_dependantorder" */
export type DependantOrder_Variance_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  parentOrderId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_diagnosisabbreviation" */
export type DiagnosisAbbreviation = {
  __typename?: 'diagnosisAbbreviation';
  abbreviation: Scalars['String'];
  id: Scalars['Int'];
  substitution: Scalars['String'];
};

/** aggregated selection of "server_diagnosisabbreviation" */
export type DiagnosisAbbreviationAggregate = {
  __typename?: 'diagnosisAbbreviationAggregate';
  aggregate?: Maybe<DiagnosisAbbreviationAggregateFields>;
  nodes: Array<DiagnosisAbbreviation>;
};

/** aggregate fields of "server_diagnosisabbreviation" */
export type DiagnosisAbbreviationAggregateFields = {
  __typename?: 'diagnosisAbbreviationAggregateFields';
  avg?: Maybe<DiagnosisAbbreviationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DiagnosisAbbreviationMaxFields>;
  min?: Maybe<DiagnosisAbbreviationMinFields>;
  stddev?: Maybe<DiagnosisAbbreviationStddevFields>;
  stddevPop?: Maybe<DiagnosisAbbreviationStddev_PopFields>;
  stddevSamp?: Maybe<DiagnosisAbbreviationStddev_SampFields>;
  sum?: Maybe<DiagnosisAbbreviationSumFields>;
  varPop?: Maybe<DiagnosisAbbreviationVar_PopFields>;
  varSamp?: Maybe<DiagnosisAbbreviationVar_SampFields>;
  variance?: Maybe<DiagnosisAbbreviationVarianceFields>;
};


/** aggregate fields of "server_diagnosisabbreviation" */
export type DiagnosisAbbreviationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DiagnosisAbbreviationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DiagnosisAbbreviationAvgFields = {
  __typename?: 'diagnosisAbbreviationAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_diagnosisabbreviation". All fields are combined with a logical 'AND'. */
export type DiagnosisAbbreviationBoolExp = {
  _and?: InputMaybe<Array<DiagnosisAbbreviationBoolExp>>;
  _not?: InputMaybe<DiagnosisAbbreviationBoolExp>;
  _or?: InputMaybe<Array<DiagnosisAbbreviationBoolExp>>;
  abbreviation?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  substitution?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_diagnosisabbreviation" */
export enum DiagnosisAbbreviationConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerDiagnosisabbreviationPkey = 'server_diagnosisabbreviation_pkey'
}

/** input type for incrementing numeric columns in table "server_diagnosisabbreviation" */
export type DiagnosisAbbreviationIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_diagnosisabbreviation" */
export type DiagnosisAbbreviationInsertInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  substitution?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DiagnosisAbbreviationMaxFields = {
  __typename?: 'diagnosisAbbreviationMaxFields';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  substitution?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DiagnosisAbbreviationMinFields = {
  __typename?: 'diagnosisAbbreviationMinFields';
  abbreviation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  substitution?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_diagnosisabbreviation" */
export type DiagnosisAbbreviationMutationResponse = {
  __typename?: 'diagnosisAbbreviationMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DiagnosisAbbreviation>;
};

/** on_conflict condition type for table "server_diagnosisabbreviation" */
export type DiagnosisAbbreviationOnConflict = {
  constraint: DiagnosisAbbreviationConstraint;
  update_columns?: Array<DiagnosisAbbreviationUpdateColumn>;
  where?: InputMaybe<DiagnosisAbbreviationBoolExp>;
};

/** Ordering options when selecting data from "server_diagnosisabbreviation". */
export type DiagnosisAbbreviationOrderBy = {
  abbreviation?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  substitution?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_diagnosisabbreviation */
export type DiagnosisAbbreviationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_diagnosisabbreviation" */
export enum DiagnosisAbbreviationSelectColumn {
  /** column name */
  Abbreviation = 'abbreviation',
  /** column name */
  Id = 'id',
  /** column name */
  Substitution = 'substitution'
}

/** input type for updating data in table "server_diagnosisabbreviation" */
export type DiagnosisAbbreviationSetInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  substitution?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DiagnosisAbbreviationStddevFields = {
  __typename?: 'diagnosisAbbreviationStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type DiagnosisAbbreviationStddev_PopFields = {
  __typename?: 'diagnosisAbbreviationStddev_popFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type DiagnosisAbbreviationStddev_SampFields = {
  __typename?: 'diagnosisAbbreviationStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type DiagnosisAbbreviationSumFields = {
  __typename?: 'diagnosisAbbreviationSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_diagnosisabbreviation" */
export enum DiagnosisAbbreviationUpdateColumn {
  /** column name */
  Abbreviation = 'abbreviation',
  /** column name */
  Id = 'id',
  /** column name */
  Substitution = 'substitution'
}

export type DiagnosisAbbreviationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DiagnosisAbbreviationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DiagnosisAbbreviationSetInput>;
  where: DiagnosisAbbreviationBoolExp;
};

/** aggregate var_pop on columns */
export type DiagnosisAbbreviationVar_PopFields = {
  __typename?: 'diagnosisAbbreviationVar_popFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type DiagnosisAbbreviationVar_SampFields = {
  __typename?: 'diagnosisAbbreviationVar_sampFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DiagnosisAbbreviationVarianceFields = {
  __typename?: 'diagnosisAbbreviationVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "diagnosisAbbreviation" */
export type DiagnosisAbbreviation_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DiagnosisAbbreviation_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DiagnosisAbbreviation_StreamCursorValueInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  substitution?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "server_document" */
export type Document = {
  __typename?: 'document';
  archived: Scalars['Boolean'];
  category: Scalars['smallint'];
  /** An object relationship */
  clinicalnote?: Maybe<Clinicalnote>;
  clinicalnoteId?: Maybe<Scalars['Int']>;
  contentType?: Maybe<Scalars['String']>;
  datetime: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  externalJson?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  location?: Maybe<Scalars['String']>;
  /** An object relationship */
  message?: Maybe<Message>;
  messageId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  order?: Maybe<LegacyOrder>;
  orderId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  pendingReviews: Array<DocumentPendingReview>;
  /** An aggregate relationship */
  pendingReviewsAggregate: DocumentPendingReviewAggregate;
  /** An object relationship */
  ticketthread?: Maybe<TicketThread>;
  ticketthreadId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "server_document" */
export type DocumentExternalJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "server_document" */
export type DocumentPendingReviewsArgs = {
  distinctOn?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentPendingReviewOrderBy>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};


/** columns and relationships of "server_document" */
export type DocumentPendingReviewsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentPendingReviewOrderBy>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};

/** aggregated selection of "server_document" */
export type DocumentAggregate = {
  __typename?: 'documentAggregate';
  aggregate?: Maybe<DocumentAggregateFields>;
  nodes: Array<Document>;
};

/** aggregate fields of "server_document" */
export type DocumentAggregateFields = {
  __typename?: 'documentAggregateFields';
  avg?: Maybe<DocumentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DocumentMaxFields>;
  min?: Maybe<DocumentMinFields>;
  stddev?: Maybe<DocumentStddevFields>;
  stddevPop?: Maybe<DocumentStddev_PopFields>;
  stddevSamp?: Maybe<DocumentStddev_SampFields>;
  sum?: Maybe<DocumentSumFields>;
  varPop?: Maybe<DocumentVar_PopFields>;
  varSamp?: Maybe<DocumentVar_SampFields>;
  variance?: Maybe<DocumentVarianceFields>;
};


/** aggregate fields of "server_document" */
export type DocumentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_document" */
export type DocumentAggregateOrderBy = {
  avg?: InputMaybe<Document_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Document_Max_Order_By>;
  min?: InputMaybe<Document_Min_Order_By>;
  stddev?: InputMaybe<Document_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Document_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Document_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Document_Sum_Order_By>;
  var_pop?: InputMaybe<Document_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Document_Var_Samp_Order_By>;
  variance?: InputMaybe<Document_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DocumentAppendInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_document" */
export type DocumentArrRelInsertInput = {
  data: Array<DocumentInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<DocumentOnConflict>;
};

/** aggregate avg on columns */
export type DocumentAvgFields = {
  __typename?: 'documentAvgFields';
  category?: Maybe<Scalars['Float']>;
  clinicalnoteId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketthreadId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_document". All fields are combined with a logical 'AND'. */
export type DocumentBoolExp = {
  _and?: InputMaybe<Array<DocumentBoolExp>>;
  _not?: InputMaybe<DocumentBoolExp>;
  _or?: InputMaybe<Array<DocumentBoolExp>>;
  archived?: InputMaybe<BooleanComparisonExp>;
  category?: InputMaybe<SmallintComparisonExp>;
  clinicalnote?: InputMaybe<ClinicalnoteBoolExp>;
  clinicalnoteId?: InputMaybe<IntComparisonExp>;
  contentType?: InputMaybe<StringComparisonExp>;
  datetime?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  externalId?: InputMaybe<IntComparisonExp>;
  externalJson?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  location?: InputMaybe<StringComparisonExp>;
  message?: InputMaybe<MessageBoolExp>;
  messageId?: InputMaybe<IntComparisonExp>;
  order?: InputMaybe<LegacyOrderBoolExp>;
  orderId?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  pendingReviews?: InputMaybe<DocumentPendingReviewBoolExp>;
  ticketthread?: InputMaybe<TicketThreadBoolExp>;
  ticketthreadId?: InputMaybe<IntComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "server_document" */
export enum DocumentConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerDocumentPkey = 'server_document_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DocumentDeleteAtPathInput = {
  externalJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DocumentDeleteElemInput = {
  externalJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DocumentDeleteKeyInput = {
  externalJson?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_document" */
export type DocumentIncInput = {
  category?: InputMaybe<Scalars['smallint']>;
  clinicalnoteId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  messageId?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  ticketthreadId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_document" */
export type DocumentInsertInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['smallint']>;
  clinicalnote?: InputMaybe<ClinicalnoteObjRelInsertInput>;
  clinicalnoteId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<MessageObjRelInsertInput>;
  messageId?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<LegacyOrderObjRelInsertInput>;
  orderId?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  pendingReviews?: InputMaybe<DocumentPendingReviewArrRelInsertInput>;
  ticketthread?: InputMaybe<TicketThreadObjRelInsertInput>;
  ticketthreadId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DocumentMaxFields = {
  __typename?: 'documentMaxFields';
  category?: Maybe<Scalars['smallint']>;
  clinicalnoteId?: Maybe<Scalars['Int']>;
  contentType?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  ticketthreadId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type DocumentMinFields = {
  __typename?: 'documentMinFields';
  category?: Maybe<Scalars['smallint']>;
  clinicalnoteId?: Maybe<Scalars['Int']>;
  contentType?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  ticketthreadId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "server_document" */
export type DocumentMutationResponse = {
  __typename?: 'documentMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Document>;
};

/** input type for inserting object relation for remote table "server_document" */
export type DocumentObjRelInsertInput = {
  data: DocumentInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<DocumentOnConflict>;
};

/** on_conflict condition type for table "server_document" */
export type DocumentOnConflict = {
  constraint: DocumentConstraint;
  update_columns?: Array<DocumentUpdateColumn>;
  where?: InputMaybe<DocumentBoolExp>;
};

/** Ordering options when selecting data from "server_document". */
export type DocumentOrderBy = {
  archived?: InputMaybe<OrderBy>;
  category?: InputMaybe<OrderBy>;
  clinicalnote?: InputMaybe<ClinicalnoteOrderBy>;
  clinicalnoteId?: InputMaybe<OrderBy>;
  contentType?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  externalJson?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  message?: InputMaybe<MessageOrderBy>;
  messageId?: InputMaybe<OrderBy>;
  order?: InputMaybe<LegacyOrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  pendingReviewsAggregate?: InputMaybe<DocumentPendingReviewAggregateOrderBy>;
  ticketthread?: InputMaybe<TicketThreadOrderBy>;
  ticketthreadId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_documentpendingreview" */
export type DocumentPendingReview = {
  __typename?: 'documentPendingReview';
  /** An object relationship */
  document: Document;
  documentId: Scalars['Int'];
  documentUpdated?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['Int']>;
  externalJson?: Maybe<Scalars['jsonb']>;
  filename?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  orderGuess?: Maybe<LegacyOrder>;
  orderGuessId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  patientGuess?: Maybe<Patient>;
  patientGuessId?: Maybe<Scalars['Int']>;
  practiceId: Scalars['Int'];
  /** An object relationship */
  practicegroup: Practicegroup;
  read: Scalars['Boolean'];
  requiresFurtherAttention: Scalars['Boolean'];
  scanId?: Maybe<Scalars['String']>;
  scanResults?: Maybe<Scalars['jsonb']>;
  typeGuess?: Maybe<Scalars['smallint']>;
  /** An object relationship */
  userAssigned?: Maybe<User>;
  userAssignedId?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "server_documentpendingreview" */
export type DocumentPendingReviewExternalJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "server_documentpendingreview" */
export type DocumentPendingReviewScanResultsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "server_documentpendingreview" */
export type DocumentPendingReviewAggregate = {
  __typename?: 'documentPendingReviewAggregate';
  aggregate?: Maybe<DocumentPendingReviewAggregateFields>;
  nodes: Array<DocumentPendingReview>;
};

/** aggregate fields of "server_documentpendingreview" */
export type DocumentPendingReviewAggregateFields = {
  __typename?: 'documentPendingReviewAggregateFields';
  avg?: Maybe<DocumentPendingReviewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DocumentPendingReviewMaxFields>;
  min?: Maybe<DocumentPendingReviewMinFields>;
  stddev?: Maybe<DocumentPendingReviewStddevFields>;
  stddevPop?: Maybe<DocumentPendingReviewStddev_PopFields>;
  stddevSamp?: Maybe<DocumentPendingReviewStddev_SampFields>;
  sum?: Maybe<DocumentPendingReviewSumFields>;
  varPop?: Maybe<DocumentPendingReviewVar_PopFields>;
  varSamp?: Maybe<DocumentPendingReviewVar_SampFields>;
  variance?: Maybe<DocumentPendingReviewVarianceFields>;
};


/** aggregate fields of "server_documentpendingreview" */
export type DocumentPendingReviewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_documentpendingreview" */
export type DocumentPendingReviewAggregateOrderBy = {
  avg?: InputMaybe<DocumentPendingReview_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DocumentPendingReview_Max_Order_By>;
  min?: InputMaybe<DocumentPendingReview_Min_Order_By>;
  stddev?: InputMaybe<DocumentPendingReview_Stddev_Order_By>;
  stddev_pop?: InputMaybe<DocumentPendingReview_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<DocumentPendingReview_Stddev_Samp_Order_By>;
  sum?: InputMaybe<DocumentPendingReview_Sum_Order_By>;
  var_pop?: InputMaybe<DocumentPendingReview_Var_Pop_Order_By>;
  var_samp?: InputMaybe<DocumentPendingReview_Var_Samp_Order_By>;
  variance?: InputMaybe<DocumentPendingReview_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DocumentPendingReviewAppendInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
  scanResults?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_documentpendingreview" */
export type DocumentPendingReviewArrRelInsertInput = {
  data: Array<DocumentPendingReviewInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<DocumentPendingReviewOnConflict>;
};

/** aggregate avg on columns */
export type DocumentPendingReviewAvgFields = {
  __typename?: 'documentPendingReviewAvgFields';
  documentId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderGuessId?: Maybe<Scalars['Float']>;
  patientGuessId?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
  typeGuess?: Maybe<Scalars['Float']>;
  userAssignedId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_documentpendingreview". All fields are combined with a logical 'AND'. */
export type DocumentPendingReviewBoolExp = {
  _and?: InputMaybe<Array<DocumentPendingReviewBoolExp>>;
  _not?: InputMaybe<DocumentPendingReviewBoolExp>;
  _or?: InputMaybe<Array<DocumentPendingReviewBoolExp>>;
  document?: InputMaybe<DocumentBoolExp>;
  documentId?: InputMaybe<IntComparisonExp>;
  documentUpdated?: InputMaybe<TimestamptzComparisonExp>;
  externalId?: InputMaybe<IntComparisonExp>;
  externalJson?: InputMaybe<JsonbComparisonExp>;
  filename?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  orderGuess?: InputMaybe<LegacyOrderBoolExp>;
  orderGuessId?: InputMaybe<IntComparisonExp>;
  patientGuess?: InputMaybe<PatientBoolExp>;
  patientGuessId?: InputMaybe<IntComparisonExp>;
  practiceId?: InputMaybe<IntComparisonExp>;
  practicegroup?: InputMaybe<PracticegroupBoolExp>;
  read?: InputMaybe<BooleanComparisonExp>;
  requiresFurtherAttention?: InputMaybe<BooleanComparisonExp>;
  scanId?: InputMaybe<StringComparisonExp>;
  scanResults?: InputMaybe<JsonbComparisonExp>;
  typeGuess?: InputMaybe<SmallintComparisonExp>;
  userAssigned?: InputMaybe<UserBoolExp>;
  userAssignedId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_documentpendingreview" */
export enum DocumentPendingReviewConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerDocumentpendingreviewPkey = 'server_documentpendingreview_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DocumentPendingReviewDeleteAtPathInput = {
  externalJson?: InputMaybe<Array<Scalars['String']>>;
  scanResults?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DocumentPendingReviewDeleteElemInput = {
  externalJson?: InputMaybe<Scalars['Int']>;
  scanResults?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DocumentPendingReviewDeleteKeyInput = {
  externalJson?: InputMaybe<Scalars['String']>;
  scanResults?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_documentpendingreview" */
export type DocumentPendingReviewIncInput = {
  documentId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderGuessId?: InputMaybe<Scalars['Int']>;
  patientGuessId?: InputMaybe<Scalars['Int']>;
  practiceId?: InputMaybe<Scalars['Int']>;
  typeGuess?: InputMaybe<Scalars['smallint']>;
  userAssignedId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_documentpendingreview" */
export type DocumentPendingReviewInsertInput = {
  document?: InputMaybe<DocumentObjRelInsertInput>;
  documentId?: InputMaybe<Scalars['Int']>;
  documentUpdated?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  orderGuess?: InputMaybe<LegacyOrderObjRelInsertInput>;
  orderGuessId?: InputMaybe<Scalars['Int']>;
  patientGuess?: InputMaybe<PatientObjRelInsertInput>;
  patientGuessId?: InputMaybe<Scalars['Int']>;
  practiceId?: InputMaybe<Scalars['Int']>;
  practicegroup?: InputMaybe<PracticegroupObjRelInsertInput>;
  read?: InputMaybe<Scalars['Boolean']>;
  requiresFurtherAttention?: InputMaybe<Scalars['Boolean']>;
  scanId?: InputMaybe<Scalars['String']>;
  scanResults?: InputMaybe<Scalars['jsonb']>;
  typeGuess?: InputMaybe<Scalars['smallint']>;
  userAssigned?: InputMaybe<UserObjRelInsertInput>;
  userAssignedId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type DocumentPendingReviewMaxFields = {
  __typename?: 'documentPendingReviewMaxFields';
  documentId?: Maybe<Scalars['Int']>;
  documentUpdated?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  orderGuessId?: Maybe<Scalars['Int']>;
  patientGuessId?: Maybe<Scalars['Int']>;
  practiceId?: Maybe<Scalars['Int']>;
  scanId?: Maybe<Scalars['String']>;
  typeGuess?: Maybe<Scalars['smallint']>;
  userAssignedId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type DocumentPendingReviewMinFields = {
  __typename?: 'documentPendingReviewMinFields';
  documentId?: Maybe<Scalars['Int']>;
  documentUpdated?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  orderGuessId?: Maybe<Scalars['Int']>;
  patientGuessId?: Maybe<Scalars['Int']>;
  practiceId?: Maybe<Scalars['Int']>;
  scanId?: Maybe<Scalars['String']>;
  typeGuess?: Maybe<Scalars['smallint']>;
  userAssignedId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_documentpendingreview" */
export type DocumentPendingReviewMutationResponse = {
  __typename?: 'documentPendingReviewMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentPendingReview>;
};

/** on_conflict condition type for table "server_documentpendingreview" */
export type DocumentPendingReviewOnConflict = {
  constraint: DocumentPendingReviewConstraint;
  update_columns?: Array<DocumentPendingReviewUpdateColumn>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};

/** Ordering options when selecting data from "server_documentpendingreview". */
export type DocumentPendingReviewOrderBy = {
  document?: InputMaybe<DocumentOrderBy>;
  documentId?: InputMaybe<OrderBy>;
  documentUpdated?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  externalJson?: InputMaybe<OrderBy>;
  filename?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderGuess?: InputMaybe<LegacyOrderOrderBy>;
  orderGuessId?: InputMaybe<OrderBy>;
  patientGuess?: InputMaybe<PatientOrderBy>;
  patientGuessId?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  practicegroup?: InputMaybe<PracticegroupOrderBy>;
  read?: InputMaybe<OrderBy>;
  requiresFurtherAttention?: InputMaybe<OrderBy>;
  scanId?: InputMaybe<OrderBy>;
  scanResults?: InputMaybe<OrderBy>;
  typeGuess?: InputMaybe<OrderBy>;
  userAssigned?: InputMaybe<UserOrderBy>;
  userAssignedId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_documentpendingreview */
export type DocumentPendingReviewPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DocumentPendingReviewPrependInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
  scanResults?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_documentpendingreview" */
export enum DocumentPendingReviewSelectColumn {
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  DocumentUpdated = 'documentUpdated',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  OrderGuessId = 'orderGuessId',
  /** column name */
  PatientGuessId = 'patientGuessId',
  /** column name */
  PracticeId = 'practiceId',
  /** column name */
  Read = 'read',
  /** column name */
  RequiresFurtherAttention = 'requiresFurtherAttention',
  /** column name */
  ScanId = 'scanId',
  /** column name */
  ScanResults = 'scanResults',
  /** column name */
  TypeGuess = 'typeGuess',
  /** column name */
  UserAssignedId = 'userAssignedId'
}

/** input type for updating data in table "server_documentpendingreview" */
export type DocumentPendingReviewSetInput = {
  documentId?: InputMaybe<Scalars['Int']>;
  documentUpdated?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  orderGuessId?: InputMaybe<Scalars['Int']>;
  patientGuessId?: InputMaybe<Scalars['Int']>;
  practiceId?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  requiresFurtherAttention?: InputMaybe<Scalars['Boolean']>;
  scanId?: InputMaybe<Scalars['String']>;
  scanResults?: InputMaybe<Scalars['jsonb']>;
  typeGuess?: InputMaybe<Scalars['smallint']>;
  userAssignedId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type DocumentPendingReviewStddevFields = {
  __typename?: 'documentPendingReviewStddevFields';
  documentId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderGuessId?: Maybe<Scalars['Float']>;
  patientGuessId?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
  typeGuess?: Maybe<Scalars['Float']>;
  userAssignedId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type DocumentPendingReviewStddev_PopFields = {
  __typename?: 'documentPendingReviewStddev_popFields';
  documentId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderGuessId?: Maybe<Scalars['Float']>;
  patientGuessId?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
  typeGuess?: Maybe<Scalars['Float']>;
  userAssignedId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type DocumentPendingReviewStddev_SampFields = {
  __typename?: 'documentPendingReviewStddev_sampFields';
  documentId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderGuessId?: Maybe<Scalars['Float']>;
  patientGuessId?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
  typeGuess?: Maybe<Scalars['Float']>;
  userAssignedId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type DocumentPendingReviewSumFields = {
  __typename?: 'documentPendingReviewSumFields';
  documentId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderGuessId?: Maybe<Scalars['Int']>;
  patientGuessId?: Maybe<Scalars['Int']>;
  practiceId?: Maybe<Scalars['Int']>;
  typeGuess?: Maybe<Scalars['smallint']>;
  userAssignedId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_documentpendingreview" */
export enum DocumentPendingReviewUpdateColumn {
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  DocumentUpdated = 'documentUpdated',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  OrderGuessId = 'orderGuessId',
  /** column name */
  PatientGuessId = 'patientGuessId',
  /** column name */
  PracticeId = 'practiceId',
  /** column name */
  Read = 'read',
  /** column name */
  RequiresFurtherAttention = 'requiresFurtherAttention',
  /** column name */
  ScanId = 'scanId',
  /** column name */
  ScanResults = 'scanResults',
  /** column name */
  TypeGuess = 'typeGuess',
  /** column name */
  UserAssignedId = 'userAssignedId'
}

export type DocumentPendingReviewUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DocumentPendingReviewAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<DocumentPendingReviewDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<DocumentPendingReviewDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<DocumentPendingReviewDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DocumentPendingReviewIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DocumentPendingReviewPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentPendingReviewSetInput>;
  where: DocumentPendingReviewBoolExp;
};

/** aggregate var_pop on columns */
export type DocumentPendingReviewVar_PopFields = {
  __typename?: 'documentPendingReviewVar_popFields';
  documentId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderGuessId?: Maybe<Scalars['Float']>;
  patientGuessId?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
  typeGuess?: Maybe<Scalars['Float']>;
  userAssignedId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type DocumentPendingReviewVar_SampFields = {
  __typename?: 'documentPendingReviewVar_sampFields';
  documentId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderGuessId?: Maybe<Scalars['Float']>;
  patientGuessId?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
  typeGuess?: Maybe<Scalars['Float']>;
  userAssignedId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DocumentPendingReviewVarianceFields = {
  __typename?: 'documentPendingReviewVarianceFields';
  documentId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderGuessId?: Maybe<Scalars['Float']>;
  patientGuessId?: Maybe<Scalars['Float']>;
  practiceId?: Maybe<Scalars['Float']>;
  typeGuess?: Maybe<Scalars['Float']>;
  userAssignedId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_documentpendingreview" */
export type DocumentPendingReview_Avg_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderGuessId?: InputMaybe<OrderBy>;
  patientGuessId?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  typeGuess?: InputMaybe<OrderBy>;
  userAssignedId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_documentpendingreview" */
export type DocumentPendingReview_Max_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  documentUpdated?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  filename?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderGuessId?: InputMaybe<OrderBy>;
  patientGuessId?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  scanId?: InputMaybe<OrderBy>;
  typeGuess?: InputMaybe<OrderBy>;
  userAssignedId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_documentpendingreview" */
export type DocumentPendingReview_Min_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  documentUpdated?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  filename?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderGuessId?: InputMaybe<OrderBy>;
  patientGuessId?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  scanId?: InputMaybe<OrderBy>;
  typeGuess?: InputMaybe<OrderBy>;
  userAssignedId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_documentpendingreview" */
export type DocumentPendingReview_Stddev_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderGuessId?: InputMaybe<OrderBy>;
  patientGuessId?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  typeGuess?: InputMaybe<OrderBy>;
  userAssignedId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_documentpendingreview" */
export type DocumentPendingReview_Stddev_Pop_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderGuessId?: InputMaybe<OrderBy>;
  patientGuessId?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  typeGuess?: InputMaybe<OrderBy>;
  userAssignedId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_documentpendingreview" */
export type DocumentPendingReview_Stddev_Samp_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderGuessId?: InputMaybe<OrderBy>;
  patientGuessId?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  typeGuess?: InputMaybe<OrderBy>;
  userAssignedId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "documentPendingReview" */
export type DocumentPendingReview_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DocumentPendingReview_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentPendingReview_StreamCursorValueInput = {
  documentId?: InputMaybe<Scalars['Int']>;
  documentUpdated?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  orderGuessId?: InputMaybe<Scalars['Int']>;
  patientGuessId?: InputMaybe<Scalars['Int']>;
  practiceId?: InputMaybe<Scalars['Int']>;
  read?: InputMaybe<Scalars['Boolean']>;
  requiresFurtherAttention?: InputMaybe<Scalars['Boolean']>;
  scanId?: InputMaybe<Scalars['String']>;
  scanResults?: InputMaybe<Scalars['jsonb']>;
  typeGuess?: InputMaybe<Scalars['smallint']>;
  userAssignedId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_documentpendingreview" */
export type DocumentPendingReview_Sum_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderGuessId?: InputMaybe<OrderBy>;
  patientGuessId?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  typeGuess?: InputMaybe<OrderBy>;
  userAssignedId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_documentpendingreview" */
export type DocumentPendingReview_Var_Pop_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderGuessId?: InputMaybe<OrderBy>;
  patientGuessId?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  typeGuess?: InputMaybe<OrderBy>;
  userAssignedId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_documentpendingreview" */
export type DocumentPendingReview_Var_Samp_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderGuessId?: InputMaybe<OrderBy>;
  patientGuessId?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  typeGuess?: InputMaybe<OrderBy>;
  userAssignedId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_documentpendingreview" */
export type DocumentPendingReview_Variance_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderGuessId?: InputMaybe<OrderBy>;
  patientGuessId?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  typeGuess?: InputMaybe<OrderBy>;
  userAssignedId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_document */
export type DocumentPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DocumentPrependInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_document" */
export enum DocumentSelectColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  Category = 'category',
  /** column name */
  ClinicalnoteId = 'clinicalnoteId',
  /** column name */
  ContentType = 'contentType',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  MessageId = 'messageId',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  TicketthreadId = 'ticketthreadId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "server_document" */
export type DocumentSetInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['smallint']>;
  clinicalnoteId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  messageId?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  ticketthreadId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DocumentStddevFields = {
  __typename?: 'documentStddevFields';
  category?: Maybe<Scalars['Float']>;
  clinicalnoteId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketthreadId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type DocumentStddev_PopFields = {
  __typename?: 'documentStddev_popFields';
  category?: Maybe<Scalars['Float']>;
  clinicalnoteId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketthreadId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type DocumentStddev_SampFields = {
  __typename?: 'documentStddev_sampFields';
  category?: Maybe<Scalars['Float']>;
  clinicalnoteId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketthreadId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type DocumentSumFields = {
  __typename?: 'documentSumFields';
  category?: Maybe<Scalars['smallint']>;
  clinicalnoteId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  messageId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  ticketthreadId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_document" */
export enum DocumentUpdateColumn {
  /** column name */
  Archived = 'archived',
  /** column name */
  Category = 'category',
  /** column name */
  ClinicalnoteId = 'clinicalnoteId',
  /** column name */
  ContentType = 'contentType',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  MessageId = 'messageId',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  TicketthreadId = 'ticketthreadId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type DocumentUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DocumentAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<DocumentDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<DocumentDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<DocumentDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DocumentIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DocumentPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentSetInput>;
  where: DocumentBoolExp;
};

/** aggregate var_pop on columns */
export type DocumentVar_PopFields = {
  __typename?: 'documentVar_popFields';
  category?: Maybe<Scalars['Float']>;
  clinicalnoteId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketthreadId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type DocumentVar_SampFields = {
  __typename?: 'documentVar_sampFields';
  category?: Maybe<Scalars['Float']>;
  clinicalnoteId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketthreadId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DocumentVarianceFields = {
  __typename?: 'documentVarianceFields';
  category?: Maybe<Scalars['Float']>;
  clinicalnoteId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  messageId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  ticketthreadId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_document" */
export type Document_Avg_Order_By = {
  category?: InputMaybe<OrderBy>;
  clinicalnoteId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketthreadId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_document" */
export type Document_Max_Order_By = {
  category?: InputMaybe<OrderBy>;
  clinicalnoteId?: InputMaybe<OrderBy>;
  contentType?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketthreadId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_document" */
export type Document_Min_Order_By = {
  category?: InputMaybe<OrderBy>;
  clinicalnoteId?: InputMaybe<OrderBy>;
  contentType?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketthreadId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_document" */
export type Document_Stddev_Order_By = {
  category?: InputMaybe<OrderBy>;
  clinicalnoteId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketthreadId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_document" */
export type Document_Stddev_Pop_Order_By = {
  category?: InputMaybe<OrderBy>;
  clinicalnoteId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketthreadId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_document" */
export type Document_Stddev_Samp_Order_By = {
  category?: InputMaybe<OrderBy>;
  clinicalnoteId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketthreadId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "document" */
export type Document_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Document_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_StreamCursorValueInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['smallint']>;
  clinicalnoteId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  messageId?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  ticketthreadId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "server_document" */
export type Document_Sum_Order_By = {
  category?: InputMaybe<OrderBy>;
  clinicalnoteId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketthreadId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_document" */
export type Document_Var_Pop_Order_By = {
  category?: InputMaybe<OrderBy>;
  clinicalnoteId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketthreadId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_document" */
export type Document_Var_Samp_Order_By = {
  category?: InputMaybe<OrderBy>;
  clinicalnoteId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketthreadId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_document" */
export type Document_Variance_Order_By = {
  category?: InputMaybe<OrderBy>;
  clinicalnoteId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  ticketthreadId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_externalsignedorder" */
export type ExternalSignedOrder = {
  __typename?: 'externalSignedOrder';
  agencyname: Scalars['String'];
  claimId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  cpoClaim?: Maybe<CpoClaim>;
  datesigned?: Maybe<Scalars['date']>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  orderdate?: Maybe<Scalars['date']>;
  /** An object relationship */
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  title: Scalars['String'];
};

/** aggregated selection of "server_externalsignedorder" */
export type ExternalSignedOrderAggregate = {
  __typename?: 'externalSignedOrderAggregate';
  aggregate?: Maybe<ExternalSignedOrderAggregateFields>;
  nodes: Array<ExternalSignedOrder>;
};

/** aggregate fields of "server_externalsignedorder" */
export type ExternalSignedOrderAggregateFields = {
  __typename?: 'externalSignedOrderAggregateFields';
  avg?: Maybe<ExternalSignedOrderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ExternalSignedOrderMaxFields>;
  min?: Maybe<ExternalSignedOrderMinFields>;
  stddev?: Maybe<ExternalSignedOrderStddevFields>;
  stddevPop?: Maybe<ExternalSignedOrderStddev_PopFields>;
  stddevSamp?: Maybe<ExternalSignedOrderStddev_SampFields>;
  sum?: Maybe<ExternalSignedOrderSumFields>;
  varPop?: Maybe<ExternalSignedOrderVar_PopFields>;
  varSamp?: Maybe<ExternalSignedOrderVar_SampFields>;
  variance?: Maybe<ExternalSignedOrderVarianceFields>;
};


/** aggregate fields of "server_externalsignedorder" */
export type ExternalSignedOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalSignedOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_externalsignedorder" */
export type ExternalSignedOrderAggregateOrderBy = {
  avg?: InputMaybe<ExternalSignedOrder_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ExternalSignedOrder_Max_Order_By>;
  min?: InputMaybe<ExternalSignedOrder_Min_Order_By>;
  stddev?: InputMaybe<ExternalSignedOrder_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ExternalSignedOrder_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ExternalSignedOrder_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ExternalSignedOrder_Sum_Order_By>;
  var_pop?: InputMaybe<ExternalSignedOrder_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ExternalSignedOrder_Var_Samp_Order_By>;
  variance?: InputMaybe<ExternalSignedOrder_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_externalsignedorder" */
export type ExternalSignedOrderArrRelInsertInput = {
  data: Array<ExternalSignedOrderInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ExternalSignedOrderOnConflict>;
};

/** aggregate avg on columns */
export type ExternalSignedOrderAvgFields = {
  __typename?: 'externalSignedOrderAvgFields';
  claimId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_externalsignedorder". All fields are combined with a logical 'AND'. */
export type ExternalSignedOrderBoolExp = {
  _and?: InputMaybe<Array<ExternalSignedOrderBoolExp>>;
  _not?: InputMaybe<ExternalSignedOrderBoolExp>;
  _or?: InputMaybe<Array<ExternalSignedOrderBoolExp>>;
  agencyname?: InputMaybe<StringComparisonExp>;
  claimId?: InputMaybe<IntComparisonExp>;
  cpoClaim?: InputMaybe<CpoClaimBoolExp>;
  datesigned?: InputMaybe<DateComparisonExp>;
  externalId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  orderdate?: InputMaybe<DateComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_externalsignedorder" */
export enum ExternalSignedOrderConstraint {
  /** unique or primary key constraint on columns "external_id" */
  ServerExternalsignedorderExternalId_6940c961Uniq = 'server_externalsignedorder_external_id_6940c961_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerExternalsignedorderPkey = 'server_externalsignedorder_pkey'
}

/** input type for incrementing numeric columns in table "server_externalsignedorder" */
export type ExternalSignedOrderIncInput = {
  claimId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_externalsignedorder" */
export type ExternalSignedOrderInsertInput = {
  agencyname?: InputMaybe<Scalars['String']>;
  claimId?: InputMaybe<Scalars['Int']>;
  cpoClaim?: InputMaybe<CpoClaimObjRelInsertInput>;
  datesigned?: InputMaybe<Scalars['date']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  orderdate?: InputMaybe<Scalars['date']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ExternalSignedOrderMaxFields = {
  __typename?: 'externalSignedOrderMaxFields';
  agencyname?: Maybe<Scalars['String']>;
  claimId?: Maybe<Scalars['Int']>;
  datesigned?: Maybe<Scalars['date']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  orderdate?: Maybe<Scalars['date']>;
  patientId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ExternalSignedOrderMinFields = {
  __typename?: 'externalSignedOrderMinFields';
  agencyname?: Maybe<Scalars['String']>;
  claimId?: Maybe<Scalars['Int']>;
  datesigned?: Maybe<Scalars['date']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  orderdate?: Maybe<Scalars['date']>;
  patientId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_externalsignedorder" */
export type ExternalSignedOrderMutationResponse = {
  __typename?: 'externalSignedOrderMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalSignedOrder>;
};

/** on_conflict condition type for table "server_externalsignedorder" */
export type ExternalSignedOrderOnConflict = {
  constraint: ExternalSignedOrderConstraint;
  update_columns?: Array<ExternalSignedOrderUpdateColumn>;
  where?: InputMaybe<ExternalSignedOrderBoolExp>;
};

/** Ordering options when selecting data from "server_externalsignedorder". */
export type ExternalSignedOrderOrderBy = {
  agencyname?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  cpoClaim?: InputMaybe<CpoClaimOrderBy>;
  datesigned?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderdate?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_externalsignedorder */
export type ExternalSignedOrderPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_externalsignedorder" */
export enum ExternalSignedOrderSelectColumn {
  /** column name */
  Agencyname = 'agencyname',
  /** column name */
  ClaimId = 'claimId',
  /** column name */
  Datesigned = 'datesigned',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Orderdate = 'orderdate',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "server_externalsignedorder" */
export type ExternalSignedOrderSetInput = {
  agencyname?: InputMaybe<Scalars['String']>;
  claimId?: InputMaybe<Scalars['Int']>;
  datesigned?: InputMaybe<Scalars['date']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  orderdate?: InputMaybe<Scalars['date']>;
  patientId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ExternalSignedOrderStddevFields = {
  __typename?: 'externalSignedOrderStddevFields';
  claimId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ExternalSignedOrderStddev_PopFields = {
  __typename?: 'externalSignedOrderStddev_popFields';
  claimId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ExternalSignedOrderStddev_SampFields = {
  __typename?: 'externalSignedOrderStddev_sampFields';
  claimId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ExternalSignedOrderSumFields = {
  __typename?: 'externalSignedOrderSumFields';
  claimId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_externalsignedorder" */
export enum ExternalSignedOrderUpdateColumn {
  /** column name */
  Agencyname = 'agencyname',
  /** column name */
  ClaimId = 'claimId',
  /** column name */
  Datesigned = 'datesigned',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Orderdate = 'orderdate',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title'
}

export type ExternalSignedOrderUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ExternalSignedOrderIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalSignedOrderSetInput>;
  where: ExternalSignedOrderBoolExp;
};

/** aggregate var_pop on columns */
export type ExternalSignedOrderVar_PopFields = {
  __typename?: 'externalSignedOrderVar_popFields';
  claimId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ExternalSignedOrderVar_SampFields = {
  __typename?: 'externalSignedOrderVar_sampFields';
  claimId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ExternalSignedOrderVarianceFields = {
  __typename?: 'externalSignedOrderVarianceFields';
  claimId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_externalsignedorder" */
export type ExternalSignedOrder_Avg_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_externalsignedorder" */
export type ExternalSignedOrder_Max_Order_By = {
  agencyname?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  datesigned?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderdate?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_externalsignedorder" */
export type ExternalSignedOrder_Min_Order_By = {
  agencyname?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  datesigned?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderdate?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_externalsignedorder" */
export type ExternalSignedOrder_Stddev_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_externalsignedorder" */
export type ExternalSignedOrder_Stddev_Pop_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_externalsignedorder" */
export type ExternalSignedOrder_Stddev_Samp_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "externalSignedOrder" */
export type ExternalSignedOrder_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ExternalSignedOrder_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalSignedOrder_StreamCursorValueInput = {
  agencyname?: InputMaybe<Scalars['String']>;
  claimId?: InputMaybe<Scalars['Int']>;
  datesigned?: InputMaybe<Scalars['date']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  orderdate?: InputMaybe<Scalars['date']>;
  patientId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_externalsignedorder" */
export type ExternalSignedOrder_Sum_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_externalsignedorder" */
export type ExternalSignedOrder_Var_Pop_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_externalsignedorder" */
export type ExternalSignedOrder_Var_Samp_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_externalsignedorder" */
export type ExternalSignedOrder_Variance_Order_By = {
  claimId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_externalstaff_my_patients" */
export type ExternalStaffMyPatients = {
  __typename?: 'externalStaffMyPatients';
  /** An object relationship */
  externalstaff: Externalstaff;
  externalstaffId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
};

/** aggregated selection of "server_externalstaff_my_patients" */
export type ExternalStaffMyPatientsAggregate = {
  __typename?: 'externalStaffMyPatientsAggregate';
  aggregate?: Maybe<ExternalStaffMyPatientsAggregateFields>;
  nodes: Array<ExternalStaffMyPatients>;
};

/** aggregate fields of "server_externalstaff_my_patients" */
export type ExternalStaffMyPatientsAggregateFields = {
  __typename?: 'externalStaffMyPatientsAggregateFields';
  avg?: Maybe<ExternalStaffMyPatientsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ExternalStaffMyPatientsMaxFields>;
  min?: Maybe<ExternalStaffMyPatientsMinFields>;
  stddev?: Maybe<ExternalStaffMyPatientsStddevFields>;
  stddevPop?: Maybe<ExternalStaffMyPatientsStddev_PopFields>;
  stddevSamp?: Maybe<ExternalStaffMyPatientsStddev_SampFields>;
  sum?: Maybe<ExternalStaffMyPatientsSumFields>;
  varPop?: Maybe<ExternalStaffMyPatientsVar_PopFields>;
  varSamp?: Maybe<ExternalStaffMyPatientsVar_SampFields>;
  variance?: Maybe<ExternalStaffMyPatientsVarianceFields>;
};


/** aggregate fields of "server_externalstaff_my_patients" */
export type ExternalStaffMyPatientsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalStaffMyPatientsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatientsAggregateOrderBy = {
  avg?: InputMaybe<ExternalStaffMyPatients_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ExternalStaffMyPatients_Max_Order_By>;
  min?: InputMaybe<ExternalStaffMyPatients_Min_Order_By>;
  stddev?: InputMaybe<ExternalStaffMyPatients_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ExternalStaffMyPatients_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ExternalStaffMyPatients_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ExternalStaffMyPatients_Sum_Order_By>;
  var_pop?: InputMaybe<ExternalStaffMyPatients_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ExternalStaffMyPatients_Var_Samp_Order_By>;
  variance?: InputMaybe<ExternalStaffMyPatients_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatientsArrRelInsertInput = {
  data: Array<ExternalStaffMyPatientsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ExternalStaffMyPatientsOnConflict>;
};

/** aggregate avg on columns */
export type ExternalStaffMyPatientsAvgFields = {
  __typename?: 'externalStaffMyPatientsAvgFields';
  externalstaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_externalstaff_my_patients". All fields are combined with a logical 'AND'. */
export type ExternalStaffMyPatientsBoolExp = {
  _and?: InputMaybe<Array<ExternalStaffMyPatientsBoolExp>>;
  _not?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
  _or?: InputMaybe<Array<ExternalStaffMyPatientsBoolExp>>;
  externalstaff?: InputMaybe<ExternalstaffBoolExp>;
  externalstaffId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_externalstaff_my_patients" */
export enum ExternalStaffMyPatientsConstraint {
  /** unique or primary key constraint on columns "patient_id", "externalstaff_id" */
  ServerExternalstaffMyExternalstaffIdPatient_49dc9c2cUniq = 'server_externalstaff_my__externalstaff_id_patient_49dc9c2c_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerExternalstaffMyPatientsPkey = 'server_externalstaff_my_patients_pkey'
}

/** input type for incrementing numeric columns in table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatientsIncInput = {
  externalstaffId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatientsInsertInput = {
  externalstaff?: InputMaybe<ExternalstaffObjRelInsertInput>;
  externalstaffId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ExternalStaffMyPatientsMaxFields = {
  __typename?: 'externalStaffMyPatientsMaxFields';
  externalstaffId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ExternalStaffMyPatientsMinFields = {
  __typename?: 'externalStaffMyPatientsMinFields';
  externalstaffId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatientsMutationResponse = {
  __typename?: 'externalStaffMyPatientsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalStaffMyPatients>;
};

/** on_conflict condition type for table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatientsOnConflict = {
  constraint: ExternalStaffMyPatientsConstraint;
  update_columns?: Array<ExternalStaffMyPatientsUpdateColumn>;
  where?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
};

/** Ordering options when selecting data from "server_externalstaff_my_patients". */
export type ExternalStaffMyPatientsOrderBy = {
  externalstaff?: InputMaybe<ExternalstaffOrderBy>;
  externalstaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_externalstaff_my_patients */
export type ExternalStaffMyPatientsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_externalstaff_my_patients" */
export enum ExternalStaffMyPatientsSelectColumn {
  /** column name */
  ExternalstaffId = 'externalstaffId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId'
}

/** input type for updating data in table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatientsSetInput = {
  externalstaffId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ExternalStaffMyPatientsStddevFields = {
  __typename?: 'externalStaffMyPatientsStddevFields';
  externalstaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ExternalStaffMyPatientsStddev_PopFields = {
  __typename?: 'externalStaffMyPatientsStddev_popFields';
  externalstaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ExternalStaffMyPatientsStddev_SampFields = {
  __typename?: 'externalStaffMyPatientsStddev_sampFields';
  externalstaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ExternalStaffMyPatientsSumFields = {
  __typename?: 'externalStaffMyPatientsSumFields';
  externalstaffId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_externalstaff_my_patients" */
export enum ExternalStaffMyPatientsUpdateColumn {
  /** column name */
  ExternalstaffId = 'externalstaffId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId'
}

export type ExternalStaffMyPatientsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ExternalStaffMyPatientsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalStaffMyPatientsSetInput>;
  where: ExternalStaffMyPatientsBoolExp;
};

/** aggregate var_pop on columns */
export type ExternalStaffMyPatientsVar_PopFields = {
  __typename?: 'externalStaffMyPatientsVar_popFields';
  externalstaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ExternalStaffMyPatientsVar_SampFields = {
  __typename?: 'externalStaffMyPatientsVar_sampFields';
  externalstaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ExternalStaffMyPatientsVarianceFields = {
  __typename?: 'externalStaffMyPatientsVarianceFields';
  externalstaffId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatients_Avg_Order_By = {
  externalstaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatients_Max_Order_By = {
  externalstaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatients_Min_Order_By = {
  externalstaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatients_Stddev_Order_By = {
  externalstaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatients_Stddev_Pop_Order_By = {
  externalstaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatients_Stddev_Samp_Order_By = {
  externalstaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "externalStaffMyPatients" */
export type ExternalStaffMyPatients_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ExternalStaffMyPatients_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalStaffMyPatients_StreamCursorValueInput = {
  externalstaffId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatients_Sum_Order_By = {
  externalstaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatients_Var_Pop_Order_By = {
  externalstaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatients_Var_Samp_Order_By = {
  externalstaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_externalstaff_my_patients" */
export type ExternalStaffMyPatients_Variance_Order_By = {
  externalstaffId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_externalstaff" */
export type Externalstaff = {
  __typename?: 'externalstaff';
  /** An array relationship */
  agencyJoinRequests: Array<Agencyjoinrequest>;
  /** An aggregate relationship */
  agencyJoinRequestsAggregate: AgencyjoinrequestAggregate;
  /** An array relationship */
  agencyJoinRequestsApproved: Array<Agencyjoinrequest>;
  /** An aggregate relationship */
  agencyJoinRequestsApprovedAggregate: AgencyjoinrequestAggregate;
  discipline?: Maybe<Scalars['smallint']>;
  isClinician: Scalars['Boolean'];
  isManager: Scalars['Boolean'];
  /** An array relationship */
  myPatients: Array<ExternalStaffMyPatients>;
  /** An aggregate relationship */
  myPatientsAggregate: ExternalStaffMyPatientsAggregate;
  /** An object relationship */
  user: User;
  userId: Scalars['Int'];
};


/** columns and relationships of "server_externalstaff" */
export type ExternalstaffAgencyJoinRequestsArgs = {
  distinctOn?: InputMaybe<Array<AgencyjoinrequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyjoinrequestOrderBy>>;
  where?: InputMaybe<AgencyjoinrequestBoolExp>;
};


/** columns and relationships of "server_externalstaff" */
export type ExternalstaffAgencyJoinRequestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyjoinrequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyjoinrequestOrderBy>>;
  where?: InputMaybe<AgencyjoinrequestBoolExp>;
};


/** columns and relationships of "server_externalstaff" */
export type ExternalstaffAgencyJoinRequestsApprovedArgs = {
  distinctOn?: InputMaybe<Array<AgencyjoinrequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyjoinrequestOrderBy>>;
  where?: InputMaybe<AgencyjoinrequestBoolExp>;
};


/** columns and relationships of "server_externalstaff" */
export type ExternalstaffAgencyJoinRequestsApprovedAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyjoinrequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyjoinrequestOrderBy>>;
  where?: InputMaybe<AgencyjoinrequestBoolExp>;
};


/** columns and relationships of "server_externalstaff" */
export type ExternalstaffMyPatientsArgs = {
  distinctOn?: InputMaybe<Array<ExternalStaffMyPatientsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalStaffMyPatientsOrderBy>>;
  where?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
};


/** columns and relationships of "server_externalstaff" */
export type ExternalstaffMyPatientsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExternalStaffMyPatientsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalStaffMyPatientsOrderBy>>;
  where?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
};

/** aggregated selection of "server_externalstaff" */
export type ExternalstaffAggregate = {
  __typename?: 'externalstaffAggregate';
  aggregate?: Maybe<ExternalstaffAggregateFields>;
  nodes: Array<Externalstaff>;
};

/** aggregate fields of "server_externalstaff" */
export type ExternalstaffAggregateFields = {
  __typename?: 'externalstaffAggregateFields';
  avg?: Maybe<ExternalstaffAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ExternalstaffMaxFields>;
  min?: Maybe<ExternalstaffMinFields>;
  stddev?: Maybe<ExternalstaffStddevFields>;
  stddevPop?: Maybe<ExternalstaffStddev_PopFields>;
  stddevSamp?: Maybe<ExternalstaffStddev_SampFields>;
  sum?: Maybe<ExternalstaffSumFields>;
  varPop?: Maybe<ExternalstaffVar_PopFields>;
  varSamp?: Maybe<ExternalstaffVar_SampFields>;
  variance?: Maybe<ExternalstaffVarianceFields>;
};


/** aggregate fields of "server_externalstaff" */
export type ExternalstaffAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ExternalstaffSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ExternalstaffAvgFields = {
  __typename?: 'externalstaffAvgFields';
  discipline?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_externalstaff". All fields are combined with a logical 'AND'. */
export type ExternalstaffBoolExp = {
  _and?: InputMaybe<Array<ExternalstaffBoolExp>>;
  _not?: InputMaybe<ExternalstaffBoolExp>;
  _or?: InputMaybe<Array<ExternalstaffBoolExp>>;
  agencyJoinRequests?: InputMaybe<AgencyjoinrequestBoolExp>;
  agencyJoinRequestsApproved?: InputMaybe<AgencyjoinrequestBoolExp>;
  discipline?: InputMaybe<SmallintComparisonExp>;
  isClinician?: InputMaybe<BooleanComparisonExp>;
  isManager?: InputMaybe<BooleanComparisonExp>;
  myPatients?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_externalstaff" */
export enum ExternalstaffConstraint {
  /** unique or primary key constraint on columns "user_id" */
  ServerExternalstaffPkey = 'server_externalstaff_pkey'
}

/** input type for incrementing numeric columns in table "server_externalstaff" */
export type ExternalstaffIncInput = {
  discipline?: InputMaybe<Scalars['smallint']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_externalstaff" */
export type ExternalstaffInsertInput = {
  agencyJoinRequests?: InputMaybe<AgencyjoinrequestArrRelInsertInput>;
  agencyJoinRequestsApproved?: InputMaybe<AgencyjoinrequestArrRelInsertInput>;
  discipline?: InputMaybe<Scalars['smallint']>;
  isClinician?: InputMaybe<Scalars['Boolean']>;
  isManager?: InputMaybe<Scalars['Boolean']>;
  myPatients?: InputMaybe<ExternalStaffMyPatientsArrRelInsertInput>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ExternalstaffMaxFields = {
  __typename?: 'externalstaffMaxFields';
  discipline?: Maybe<Scalars['smallint']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ExternalstaffMinFields = {
  __typename?: 'externalstaffMinFields';
  discipline?: Maybe<Scalars['smallint']>;
  userId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_externalstaff" */
export type ExternalstaffMutationResponse = {
  __typename?: 'externalstaffMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Externalstaff>;
};

/** input type for inserting object relation for remote table "server_externalstaff" */
export type ExternalstaffObjRelInsertInput = {
  data: ExternalstaffInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ExternalstaffOnConflict>;
};

/** on_conflict condition type for table "server_externalstaff" */
export type ExternalstaffOnConflict = {
  constraint: ExternalstaffConstraint;
  update_columns?: Array<ExternalstaffUpdateColumn>;
  where?: InputMaybe<ExternalstaffBoolExp>;
};

/** Ordering options when selecting data from "server_externalstaff". */
export type ExternalstaffOrderBy = {
  agencyJoinRequestsAggregate?: InputMaybe<AgencyjoinrequestAggregateOrderBy>;
  agencyJoinRequestsApprovedAggregate?: InputMaybe<AgencyjoinrequestAggregateOrderBy>;
  discipline?: InputMaybe<OrderBy>;
  isClinician?: InputMaybe<OrderBy>;
  isManager?: InputMaybe<OrderBy>;
  myPatientsAggregate?: InputMaybe<ExternalStaffMyPatientsAggregateOrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_externalstaff */
export type ExternalstaffPkColumnsInput = {
  userId: Scalars['Int'];
};

/** select columns of table "server_externalstaff" */
export enum ExternalstaffSelectColumn {
  /** column name */
  Discipline = 'discipline',
  /** column name */
  IsClinician = 'isClinician',
  /** column name */
  IsManager = 'isManager',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "server_externalstaff" */
export type ExternalstaffSetInput = {
  discipline?: InputMaybe<Scalars['smallint']>;
  isClinician?: InputMaybe<Scalars['Boolean']>;
  isManager?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ExternalstaffStddevFields = {
  __typename?: 'externalstaffStddevFields';
  discipline?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ExternalstaffStddev_PopFields = {
  __typename?: 'externalstaffStddev_popFields';
  discipline?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ExternalstaffStddev_SampFields = {
  __typename?: 'externalstaffStddev_sampFields';
  discipline?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ExternalstaffSumFields = {
  __typename?: 'externalstaffSumFields';
  discipline?: Maybe<Scalars['smallint']>;
  userId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_externalstaff" */
export enum ExternalstaffUpdateColumn {
  /** column name */
  Discipline = 'discipline',
  /** column name */
  IsClinician = 'isClinician',
  /** column name */
  IsManager = 'isManager',
  /** column name */
  UserId = 'userId'
}

export type ExternalstaffUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ExternalstaffIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalstaffSetInput>;
  where: ExternalstaffBoolExp;
};

/** aggregate var_pop on columns */
export type ExternalstaffVar_PopFields = {
  __typename?: 'externalstaffVar_popFields';
  discipline?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ExternalstaffVar_SampFields = {
  __typename?: 'externalstaffVar_sampFields';
  discipline?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ExternalstaffVarianceFields = {
  __typename?: 'externalstaffVarianceFields';
  discipline?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "externalstaff" */
export type Externalstaff_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Externalstaff_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Externalstaff_StreamCursorValueInput = {
  discipline?: InputMaybe<Scalars['smallint']>;
  isClinician?: InputMaybe<Scalars['Boolean']>;
  isManager?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "server_fax" */
export type Fax = {
  __typename?: 'fax';
  category: Scalars['smallint'];
  datetime: Scalars['timestamptz'];
  /** An array relationship */
  documents: Array<FaxDocuments>;
  /** An aggregate relationship */
  documentsAggregate: FaxDocumentsAggregate;
  externalId?: Maybe<Scalars['Int']>;
  faxName: Scalars['String'];
  faxNumber: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  referral?: Maybe<Referral>;
  referralId?: Maybe<Scalars['Int']>;
  status: Scalars['smallint'];
};


/** columns and relationships of "server_fax" */
export type FaxDocumentsArgs = {
  distinctOn?: InputMaybe<Array<FaxDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxDocumentsOrderBy>>;
  where?: InputMaybe<FaxDocumentsBoolExp>;
};


/** columns and relationships of "server_fax" */
export type FaxDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FaxDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxDocumentsOrderBy>>;
  where?: InputMaybe<FaxDocumentsBoolExp>;
};

/** aggregated selection of "server_fax" */
export type FaxAggregate = {
  __typename?: 'faxAggregate';
  aggregate?: Maybe<FaxAggregateFields>;
  nodes: Array<Fax>;
};

/** aggregate fields of "server_fax" */
export type FaxAggregateFields = {
  __typename?: 'faxAggregateFields';
  avg?: Maybe<FaxAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<FaxMaxFields>;
  min?: Maybe<FaxMinFields>;
  stddev?: Maybe<FaxStddevFields>;
  stddevPop?: Maybe<FaxStddev_PopFields>;
  stddevSamp?: Maybe<FaxStddev_SampFields>;
  sum?: Maybe<FaxSumFields>;
  varPop?: Maybe<FaxVar_PopFields>;
  varSamp?: Maybe<FaxVar_SampFields>;
  variance?: Maybe<FaxVarianceFields>;
};


/** aggregate fields of "server_fax" */
export type FaxAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FaxSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_fax" */
export type FaxAggregateOrderBy = {
  avg?: InputMaybe<Fax_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Fax_Max_Order_By>;
  min?: InputMaybe<Fax_Min_Order_By>;
  stddev?: InputMaybe<Fax_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Fax_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Fax_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Fax_Sum_Order_By>;
  var_pop?: InputMaybe<Fax_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Fax_Var_Samp_Order_By>;
  variance?: InputMaybe<Fax_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_fax" */
export type FaxArrRelInsertInput = {
  data: Array<FaxInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FaxOnConflict>;
};

/** aggregate avg on columns */
export type FaxAvgFields = {
  __typename?: 'faxAvgFields';
  category?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_fax". All fields are combined with a logical 'AND'. */
export type FaxBoolExp = {
  _and?: InputMaybe<Array<FaxBoolExp>>;
  _not?: InputMaybe<FaxBoolExp>;
  _or?: InputMaybe<Array<FaxBoolExp>>;
  category?: InputMaybe<SmallintComparisonExp>;
  datetime?: InputMaybe<TimestamptzComparisonExp>;
  documents?: InputMaybe<FaxDocumentsBoolExp>;
  externalId?: InputMaybe<IntComparisonExp>;
  faxName?: InputMaybe<StringComparisonExp>;
  faxNumber?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  referral?: InputMaybe<ReferralBoolExp>;
  referralId?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<SmallintComparisonExp>;
};

/** unique or primary key constraints on table "server_fax" */
export enum FaxConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerFaxPkey = 'server_fax_pkey'
}

/** columns and relationships of "server_fax_documents" */
export type FaxDocuments = {
  __typename?: 'faxDocuments';
  /** An object relationship */
  document: Document;
  documentId: Scalars['Int'];
  /** An object relationship */
  fax: Fax;
  faxId: Scalars['Int'];
  id: Scalars['Int'];
};

/** aggregated selection of "server_fax_documents" */
export type FaxDocumentsAggregate = {
  __typename?: 'faxDocumentsAggregate';
  aggregate?: Maybe<FaxDocumentsAggregateFields>;
  nodes: Array<FaxDocuments>;
};

/** aggregate fields of "server_fax_documents" */
export type FaxDocumentsAggregateFields = {
  __typename?: 'faxDocumentsAggregateFields';
  avg?: Maybe<FaxDocumentsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<FaxDocumentsMaxFields>;
  min?: Maybe<FaxDocumentsMinFields>;
  stddev?: Maybe<FaxDocumentsStddevFields>;
  stddevPop?: Maybe<FaxDocumentsStddev_PopFields>;
  stddevSamp?: Maybe<FaxDocumentsStddev_SampFields>;
  sum?: Maybe<FaxDocumentsSumFields>;
  varPop?: Maybe<FaxDocumentsVar_PopFields>;
  varSamp?: Maybe<FaxDocumentsVar_SampFields>;
  variance?: Maybe<FaxDocumentsVarianceFields>;
};


/** aggregate fields of "server_fax_documents" */
export type FaxDocumentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FaxDocumentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_fax_documents" */
export type FaxDocumentsAggregateOrderBy = {
  avg?: InputMaybe<FaxDocuments_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FaxDocuments_Max_Order_By>;
  min?: InputMaybe<FaxDocuments_Min_Order_By>;
  stddev?: InputMaybe<FaxDocuments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<FaxDocuments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<FaxDocuments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<FaxDocuments_Sum_Order_By>;
  var_pop?: InputMaybe<FaxDocuments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<FaxDocuments_Var_Samp_Order_By>;
  variance?: InputMaybe<FaxDocuments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_fax_documents" */
export type FaxDocumentsArrRelInsertInput = {
  data: Array<FaxDocumentsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FaxDocumentsOnConflict>;
};

/** aggregate avg on columns */
export type FaxDocumentsAvgFields = {
  __typename?: 'faxDocumentsAvgFields';
  documentId?: Maybe<Scalars['Float']>;
  faxId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_fax_documents". All fields are combined with a logical 'AND'. */
export type FaxDocumentsBoolExp = {
  _and?: InputMaybe<Array<FaxDocumentsBoolExp>>;
  _not?: InputMaybe<FaxDocumentsBoolExp>;
  _or?: InputMaybe<Array<FaxDocumentsBoolExp>>;
  document?: InputMaybe<DocumentBoolExp>;
  documentId?: InputMaybe<IntComparisonExp>;
  fax?: InputMaybe<FaxBoolExp>;
  faxId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_fax_documents" */
export enum FaxDocumentsConstraint {
  /** unique or primary key constraint on columns "document_id", "fax_id" */
  ServerFaxDocumentsFaxIdDocumentId_8001be75Uniq = 'server_fax_documents_fax_id_document_id_8001be75_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerFaxDocumentsPkey = 'server_fax_documents_pkey'
}

/** input type for incrementing numeric columns in table "server_fax_documents" */
export type FaxDocumentsIncInput = {
  documentId?: InputMaybe<Scalars['Int']>;
  faxId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_fax_documents" */
export type FaxDocumentsInsertInput = {
  document?: InputMaybe<DocumentObjRelInsertInput>;
  documentId?: InputMaybe<Scalars['Int']>;
  fax?: InputMaybe<FaxObjRelInsertInput>;
  faxId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type FaxDocumentsMaxFields = {
  __typename?: 'faxDocumentsMaxFields';
  documentId?: Maybe<Scalars['Int']>;
  faxId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type FaxDocumentsMinFields = {
  __typename?: 'faxDocumentsMinFields';
  documentId?: Maybe<Scalars['Int']>;
  faxId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_fax_documents" */
export type FaxDocumentsMutationResponse = {
  __typename?: 'faxDocumentsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FaxDocuments>;
};

/** on_conflict condition type for table "server_fax_documents" */
export type FaxDocumentsOnConflict = {
  constraint: FaxDocumentsConstraint;
  update_columns?: Array<FaxDocumentsUpdateColumn>;
  where?: InputMaybe<FaxDocumentsBoolExp>;
};

/** Ordering options when selecting data from "server_fax_documents". */
export type FaxDocumentsOrderBy = {
  document?: InputMaybe<DocumentOrderBy>;
  documentId?: InputMaybe<OrderBy>;
  fax?: InputMaybe<FaxOrderBy>;
  faxId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_fax_documents */
export type FaxDocumentsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_fax_documents" */
export enum FaxDocumentsSelectColumn {
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  FaxId = 'faxId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "server_fax_documents" */
export type FaxDocumentsSetInput = {
  documentId?: InputMaybe<Scalars['Int']>;
  faxId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type FaxDocumentsStddevFields = {
  __typename?: 'faxDocumentsStddevFields';
  documentId?: Maybe<Scalars['Float']>;
  faxId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FaxDocumentsStddev_PopFields = {
  __typename?: 'faxDocumentsStddev_popFields';
  documentId?: Maybe<Scalars['Float']>;
  faxId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FaxDocumentsStddev_SampFields = {
  __typename?: 'faxDocumentsStddev_sampFields';
  documentId?: Maybe<Scalars['Float']>;
  faxId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FaxDocumentsSumFields = {
  __typename?: 'faxDocumentsSumFields';
  documentId?: Maybe<Scalars['Int']>;
  faxId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_fax_documents" */
export enum FaxDocumentsUpdateColumn {
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  FaxId = 'faxId',
  /** column name */
  Id = 'id'
}

export type FaxDocumentsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FaxDocumentsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FaxDocumentsSetInput>;
  where: FaxDocumentsBoolExp;
};

/** aggregate var_pop on columns */
export type FaxDocumentsVar_PopFields = {
  __typename?: 'faxDocumentsVar_popFields';
  documentId?: Maybe<Scalars['Float']>;
  faxId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FaxDocumentsVar_SampFields = {
  __typename?: 'faxDocumentsVar_sampFields';
  documentId?: Maybe<Scalars['Float']>;
  faxId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FaxDocumentsVarianceFields = {
  __typename?: 'faxDocumentsVarianceFields';
  documentId?: Maybe<Scalars['Float']>;
  faxId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_fax_documents" */
export type FaxDocuments_Avg_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  faxId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_fax_documents" */
export type FaxDocuments_Max_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  faxId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_fax_documents" */
export type FaxDocuments_Min_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  faxId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_fax_documents" */
export type FaxDocuments_Stddev_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  faxId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_fax_documents" */
export type FaxDocuments_Stddev_Pop_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  faxId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_fax_documents" */
export type FaxDocuments_Stddev_Samp_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  faxId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "faxDocuments" */
export type FaxDocuments_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FaxDocuments_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FaxDocuments_StreamCursorValueInput = {
  documentId?: InputMaybe<Scalars['Int']>;
  faxId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_fax_documents" */
export type FaxDocuments_Sum_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  faxId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_fax_documents" */
export type FaxDocuments_Var_Pop_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  faxId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_fax_documents" */
export type FaxDocuments_Var_Samp_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  faxId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_fax_documents" */
export type FaxDocuments_Variance_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  faxId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "server_fax" */
export type FaxIncInput = {
  category?: InputMaybe<Scalars['smallint']>;
  externalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  referralId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_fax" */
export type FaxInsertInput = {
  category?: InputMaybe<Scalars['smallint']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  documents?: InputMaybe<FaxDocumentsArrRelInsertInput>;
  externalId?: InputMaybe<Scalars['Int']>;
  faxName?: InputMaybe<Scalars['String']>;
  faxNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  referral?: InputMaybe<ReferralObjRelInsertInput>;
  referralId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type FaxMaxFields = {
  __typename?: 'faxMaxFields';
  category?: Maybe<Scalars['smallint']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['Int']>;
  faxName?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  referralId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type FaxMinFields = {
  __typename?: 'faxMinFields';
  category?: Maybe<Scalars['smallint']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['Int']>;
  faxName?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  referralId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_fax" */
export type FaxMutationResponse = {
  __typename?: 'faxMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fax>;
};

/** input type for inserting object relation for remote table "server_fax" */
export type FaxObjRelInsertInput = {
  data: FaxInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<FaxOnConflict>;
};

/** on_conflict condition type for table "server_fax" */
export type FaxOnConflict = {
  constraint: FaxConstraint;
  update_columns?: Array<FaxUpdateColumn>;
  where?: InputMaybe<FaxBoolExp>;
};

/** Ordering options when selecting data from "server_fax". */
export type FaxOrderBy = {
  category?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  documentsAggregate?: InputMaybe<FaxDocumentsAggregateOrderBy>;
  externalId?: InputMaybe<OrderBy>;
  faxName?: InputMaybe<OrderBy>;
  faxNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  referral?: InputMaybe<ReferralOrderBy>;
  referralId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_fax */
export type FaxPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_fax" */
export enum FaxSelectColumn {
  /** column name */
  Category = 'category',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  FaxName = 'faxName',
  /** column name */
  FaxNumber = 'faxNumber',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  ReferralId = 'referralId',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "server_fax" */
export type FaxSetInput = {
  category?: InputMaybe<Scalars['smallint']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['Int']>;
  faxName?: InputMaybe<Scalars['String']>;
  faxNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  referralId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type FaxStddevFields = {
  __typename?: 'faxStddevFields';
  category?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FaxStddev_PopFields = {
  __typename?: 'faxStddev_popFields';
  category?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FaxStddev_SampFields = {
  __typename?: 'faxStddev_sampFields';
  category?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FaxSumFields = {
  __typename?: 'faxSumFields';
  category?: Maybe<Scalars['smallint']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  referralId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_fax" */
export enum FaxUpdateColumn {
  /** column name */
  Category = 'category',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  FaxName = 'faxName',
  /** column name */
  FaxNumber = 'faxNumber',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  ReferralId = 'referralId',
  /** column name */
  Status = 'status'
}

export type FaxUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FaxIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FaxSetInput>;
  where: FaxBoolExp;
};

/** aggregate var_pop on columns */
export type FaxVar_PopFields = {
  __typename?: 'faxVar_popFields';
  category?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FaxVar_SampFields = {
  __typename?: 'faxVar_sampFields';
  category?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FaxVarianceFields = {
  __typename?: 'faxVarianceFields';
  category?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_fax" */
export type Fax_Avg_Order_By = {
  category?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_fax" */
export type Fax_Max_Order_By = {
  category?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  faxName?: InputMaybe<OrderBy>;
  faxNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_fax" */
export type Fax_Min_Order_By = {
  category?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  faxName?: InputMaybe<OrderBy>;
  faxNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_fax" */
export type Fax_Stddev_Order_By = {
  category?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_fax" */
export type Fax_Stddev_Pop_Order_By = {
  category?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_fax" */
export type Fax_Stddev_Samp_Order_By = {
  category?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "fax" */
export type Fax_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Fax_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Fax_StreamCursorValueInput = {
  category?: InputMaybe<Scalars['smallint']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['Int']>;
  faxName?: InputMaybe<Scalars['String']>;
  faxNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  referralId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "server_fax" */
export type Fax_Sum_Order_By = {
  category?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_fax" */
export type Fax_Var_Pop_Order_By = {
  category?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_fax" */
export type Fax_Var_Samp_Order_By = {
  category?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_fax" */
export type Fax_Variance_Order_By = {
  category?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_freetextorder" */
export type FreeTextOrder = {
  __typename?: 'freeTextOrder';
  /** An object relationship */
  baseorder: Baseorder;
  baseorderPtrId: Scalars['Int'];
  orderText?: Maybe<Scalars['String']>;
};

/** aggregated selection of "server_freetextorder" */
export type FreeTextOrderAggregate = {
  __typename?: 'freeTextOrderAggregate';
  aggregate?: Maybe<FreeTextOrderAggregateFields>;
  nodes: Array<FreeTextOrder>;
};

/** aggregate fields of "server_freetextorder" */
export type FreeTextOrderAggregateFields = {
  __typename?: 'freeTextOrderAggregateFields';
  avg?: Maybe<FreeTextOrderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<FreeTextOrderMaxFields>;
  min?: Maybe<FreeTextOrderMinFields>;
  stddev?: Maybe<FreeTextOrderStddevFields>;
  stddevPop?: Maybe<FreeTextOrderStddev_PopFields>;
  stddevSamp?: Maybe<FreeTextOrderStddev_SampFields>;
  sum?: Maybe<FreeTextOrderSumFields>;
  varPop?: Maybe<FreeTextOrderVar_PopFields>;
  varSamp?: Maybe<FreeTextOrderVar_SampFields>;
  variance?: Maybe<FreeTextOrderVarianceFields>;
};


/** aggregate fields of "server_freetextorder" */
export type FreeTextOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FreeTextOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FreeTextOrderAvgFields = {
  __typename?: 'freeTextOrderAvgFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_freetextorder". All fields are combined with a logical 'AND'. */
export type FreeTextOrderBoolExp = {
  _and?: InputMaybe<Array<FreeTextOrderBoolExp>>;
  _not?: InputMaybe<FreeTextOrderBoolExp>;
  _or?: InputMaybe<Array<FreeTextOrderBoolExp>>;
  baseorder?: InputMaybe<BaseorderBoolExp>;
  baseorderPtrId?: InputMaybe<IntComparisonExp>;
  orderText?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_freetextorder" */
export enum FreeTextOrderConstraint {
  /** unique or primary key constraint on columns "baseorder_ptr_id" */
  ServerFreetextorderPkey = 'server_freetextorder_pkey'
}

/** input type for incrementing numeric columns in table "server_freetextorder" */
export type FreeTextOrderIncInput = {
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_freetextorder" */
export type FreeTextOrderInsertInput = {
  baseorder?: InputMaybe<BaseorderObjRelInsertInput>;
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
  orderText?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type FreeTextOrderMaxFields = {
  __typename?: 'freeTextOrderMaxFields';
  baseorderPtrId?: Maybe<Scalars['Int']>;
  orderText?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type FreeTextOrderMinFields = {
  __typename?: 'freeTextOrderMinFields';
  baseorderPtrId?: Maybe<Scalars['Int']>;
  orderText?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_freetextorder" */
export type FreeTextOrderMutationResponse = {
  __typename?: 'freeTextOrderMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FreeTextOrder>;
};

/** input type for inserting object relation for remote table "server_freetextorder" */
export type FreeTextOrderObjRelInsertInput = {
  data: FreeTextOrderInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<FreeTextOrderOnConflict>;
};

/** on_conflict condition type for table "server_freetextorder" */
export type FreeTextOrderOnConflict = {
  constraint: FreeTextOrderConstraint;
  update_columns?: Array<FreeTextOrderUpdateColumn>;
  where?: InputMaybe<FreeTextOrderBoolExp>;
};

/** Ordering options when selecting data from "server_freetextorder". */
export type FreeTextOrderOrderBy = {
  baseorder?: InputMaybe<BaseorderOrderBy>;
  baseorderPtrId?: InputMaybe<OrderBy>;
  orderText?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_freetextorder */
export type FreeTextOrderPkColumnsInput = {
  baseorderPtrId: Scalars['Int'];
};

/** select columns of table "server_freetextorder" */
export enum FreeTextOrderSelectColumn {
  /** column name */
  BaseorderPtrId = 'baseorderPtrId',
  /** column name */
  OrderText = 'orderText'
}

/** input type for updating data in table "server_freetextorder" */
export type FreeTextOrderSetInput = {
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
  orderText?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type FreeTextOrderStddevFields = {
  __typename?: 'freeTextOrderStddevFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FreeTextOrderStddev_PopFields = {
  __typename?: 'freeTextOrderStddev_popFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FreeTextOrderStddev_SampFields = {
  __typename?: 'freeTextOrderStddev_sampFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FreeTextOrderSumFields = {
  __typename?: 'freeTextOrderSumFields';
  baseorderPtrId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_freetextorder" */
export enum FreeTextOrderUpdateColumn {
  /** column name */
  BaseorderPtrId = 'baseorderPtrId',
  /** column name */
  OrderText = 'orderText'
}

export type FreeTextOrderUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FreeTextOrderIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FreeTextOrderSetInput>;
  where: FreeTextOrderBoolExp;
};

/** aggregate var_pop on columns */
export type FreeTextOrderVar_PopFields = {
  __typename?: 'freeTextOrderVar_popFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FreeTextOrderVar_SampFields = {
  __typename?: 'freeTextOrderVar_sampFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FreeTextOrderVarianceFields = {
  __typename?: 'freeTextOrderVarianceFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "freeTextOrder" */
export type FreeTextOrder_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FreeTextOrder_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FreeTextOrder_StreamCursorValueInput = {
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
  orderText?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "server_frequencyorder" */
export type FrequencyOrder = {
  __typename?: 'frequencyOrder';
  /** An object relationship */
  dependantOrder: DependantOrder;
  dependantorderPtrId: Scalars['Int'];
  endDate: Scalars['date'];
  occurances: Scalars['smallint'];
  prn: Scalars['Boolean'];
};

/** aggregated selection of "server_frequencyorder" */
export type FrequencyOrderAggregate = {
  __typename?: 'frequencyOrderAggregate';
  aggregate?: Maybe<FrequencyOrderAggregateFields>;
  nodes: Array<FrequencyOrder>;
};

/** aggregate fields of "server_frequencyorder" */
export type FrequencyOrderAggregateFields = {
  __typename?: 'frequencyOrderAggregateFields';
  avg?: Maybe<FrequencyOrderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<FrequencyOrderMaxFields>;
  min?: Maybe<FrequencyOrderMinFields>;
  stddev?: Maybe<FrequencyOrderStddevFields>;
  stddevPop?: Maybe<FrequencyOrderStddev_PopFields>;
  stddevSamp?: Maybe<FrequencyOrderStddev_SampFields>;
  sum?: Maybe<FrequencyOrderSumFields>;
  varPop?: Maybe<FrequencyOrderVar_PopFields>;
  varSamp?: Maybe<FrequencyOrderVar_SampFields>;
  variance?: Maybe<FrequencyOrderVarianceFields>;
};


/** aggregate fields of "server_frequencyorder" */
export type FrequencyOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FrequencyOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FrequencyOrderAvgFields = {
  __typename?: 'frequencyOrderAvgFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
  occurances?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_frequencyorder". All fields are combined with a logical 'AND'. */
export type FrequencyOrderBoolExp = {
  _and?: InputMaybe<Array<FrequencyOrderBoolExp>>;
  _not?: InputMaybe<FrequencyOrderBoolExp>;
  _or?: InputMaybe<Array<FrequencyOrderBoolExp>>;
  dependantOrder?: InputMaybe<DependantOrderBoolExp>;
  dependantorderPtrId?: InputMaybe<IntComparisonExp>;
  endDate?: InputMaybe<DateComparisonExp>;
  occurances?: InputMaybe<SmallintComparisonExp>;
  prn?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "server_frequencyorder" */
export enum FrequencyOrderConstraint {
  /** unique or primary key constraint on columns "dependantorder_ptr_id" */
  ServerFrequencyorderPkey = 'server_frequencyorder_pkey'
}

/** input type for incrementing numeric columns in table "server_frequencyorder" */
export type FrequencyOrderIncInput = {
  dependantorderPtrId?: InputMaybe<Scalars['Int']>;
  occurances?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_frequencyorder" */
export type FrequencyOrderInsertInput = {
  dependantOrder?: InputMaybe<DependantOrderObjRelInsertInput>;
  dependantorderPtrId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['date']>;
  occurances?: InputMaybe<Scalars['smallint']>;
  prn?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type FrequencyOrderMaxFields = {
  __typename?: 'frequencyOrderMaxFields';
  dependantorderPtrId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['date']>;
  occurances?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type FrequencyOrderMinFields = {
  __typename?: 'frequencyOrderMinFields';
  dependantorderPtrId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['date']>;
  occurances?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_frequencyorder" */
export type FrequencyOrderMutationResponse = {
  __typename?: 'frequencyOrderMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FrequencyOrder>;
};

/** on_conflict condition type for table "server_frequencyorder" */
export type FrequencyOrderOnConflict = {
  constraint: FrequencyOrderConstraint;
  update_columns?: Array<FrequencyOrderUpdateColumn>;
  where?: InputMaybe<FrequencyOrderBoolExp>;
};

/** Ordering options when selecting data from "server_frequencyorder". */
export type FrequencyOrderOrderBy = {
  dependantOrder?: InputMaybe<DependantOrderOrderBy>;
  dependantorderPtrId?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  occurances?: InputMaybe<OrderBy>;
  prn?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_frequencyorder */
export type FrequencyOrderPkColumnsInput = {
  dependantorderPtrId: Scalars['Int'];
};

/** select columns of table "server_frequencyorder" */
export enum FrequencyOrderSelectColumn {
  /** column name */
  DependantorderPtrId = 'dependantorderPtrId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Occurances = 'occurances',
  /** column name */
  Prn = 'prn'
}

/** input type for updating data in table "server_frequencyorder" */
export type FrequencyOrderSetInput = {
  dependantorderPtrId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['date']>;
  occurances?: InputMaybe<Scalars['smallint']>;
  prn?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type FrequencyOrderStddevFields = {
  __typename?: 'frequencyOrderStddevFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
  occurances?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FrequencyOrderStddev_PopFields = {
  __typename?: 'frequencyOrderStddev_popFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
  occurances?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FrequencyOrderStddev_SampFields = {
  __typename?: 'frequencyOrderStddev_sampFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
  occurances?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FrequencyOrderSumFields = {
  __typename?: 'frequencyOrderSumFields';
  dependantorderPtrId?: Maybe<Scalars['Int']>;
  occurances?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_frequencyorder" */
export enum FrequencyOrderUpdateColumn {
  /** column name */
  DependantorderPtrId = 'dependantorderPtrId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Occurances = 'occurances',
  /** column name */
  Prn = 'prn'
}

export type FrequencyOrderUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FrequencyOrderIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FrequencyOrderSetInput>;
  where: FrequencyOrderBoolExp;
};

/** aggregate var_pop on columns */
export type FrequencyOrderVar_PopFields = {
  __typename?: 'frequencyOrderVar_popFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
  occurances?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FrequencyOrderVar_SampFields = {
  __typename?: 'frequencyOrderVar_sampFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
  occurances?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FrequencyOrderVarianceFields = {
  __typename?: 'frequencyOrderVarianceFields';
  dependantorderPtrId?: Maybe<Scalars['Float']>;
  occurances?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "frequencyOrder" */
export type FrequencyOrder_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FrequencyOrder_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FrequencyOrder_StreamCursorValueInput = {
  dependantorderPtrId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['date']>;
  occurances?: InputMaybe<Scalars['smallint']>;
  prn?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "server_frequencyprotocol" */
export type FrequencyProtocol = {
  __typename?: 'frequencyProtocol';
  /** An object relationship */
  baseProtocolOrder: BaseProtocolOrder;
  baseprotocolorderPtrId: Scalars['Int'];
  max: Scalars['smallint'];
  min: Scalars['smallint'];
  prn: Scalars['Boolean'];
  units: Scalars['smallint'];
};

/** aggregated selection of "server_frequencyprotocol" */
export type FrequencyProtocolAggregate = {
  __typename?: 'frequencyProtocolAggregate';
  aggregate?: Maybe<FrequencyProtocolAggregateFields>;
  nodes: Array<FrequencyProtocol>;
};

/** aggregate fields of "server_frequencyprotocol" */
export type FrequencyProtocolAggregateFields = {
  __typename?: 'frequencyProtocolAggregateFields';
  avg?: Maybe<FrequencyProtocolAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<FrequencyProtocolMaxFields>;
  min?: Maybe<FrequencyProtocolMinFields>;
  stddev?: Maybe<FrequencyProtocolStddevFields>;
  stddevPop?: Maybe<FrequencyProtocolStddev_PopFields>;
  stddevSamp?: Maybe<FrequencyProtocolStddev_SampFields>;
  sum?: Maybe<FrequencyProtocolSumFields>;
  varPop?: Maybe<FrequencyProtocolVar_PopFields>;
  varSamp?: Maybe<FrequencyProtocolVar_SampFields>;
  variance?: Maybe<FrequencyProtocolVarianceFields>;
};


/** aggregate fields of "server_frequencyprotocol" */
export type FrequencyProtocolAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FrequencyProtocolSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FrequencyProtocolAvgFields = {
  __typename?: 'frequencyProtocolAvgFields';
  baseprotocolorderPtrId?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_frequencyprotocol". All fields are combined with a logical 'AND'. */
export type FrequencyProtocolBoolExp = {
  _and?: InputMaybe<Array<FrequencyProtocolBoolExp>>;
  _not?: InputMaybe<FrequencyProtocolBoolExp>;
  _or?: InputMaybe<Array<FrequencyProtocolBoolExp>>;
  baseProtocolOrder?: InputMaybe<BaseProtocolOrderBoolExp>;
  baseprotocolorderPtrId?: InputMaybe<IntComparisonExp>;
  max?: InputMaybe<SmallintComparisonExp>;
  min?: InputMaybe<SmallintComparisonExp>;
  prn?: InputMaybe<BooleanComparisonExp>;
  units?: InputMaybe<SmallintComparisonExp>;
};

/** unique or primary key constraints on table "server_frequencyprotocol" */
export enum FrequencyProtocolConstraint {
  /** unique or primary key constraint on columns "baseprotocolorder_ptr_id" */
  ServerFrequencyprotocolPkey = 'server_frequencyprotocol_pkey'
}

/** input type for incrementing numeric columns in table "server_frequencyprotocol" */
export type FrequencyProtocolIncInput = {
  baseprotocolorderPtrId?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['smallint']>;
  min?: InputMaybe<Scalars['smallint']>;
  units?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_frequencyprotocol" */
export type FrequencyProtocolInsertInput = {
  baseProtocolOrder?: InputMaybe<BaseProtocolOrderObjRelInsertInput>;
  baseprotocolorderPtrId?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['smallint']>;
  min?: InputMaybe<Scalars['smallint']>;
  prn?: InputMaybe<Scalars['Boolean']>;
  units?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type FrequencyProtocolMaxFields = {
  __typename?: 'frequencyProtocolMaxFields';
  baseprotocolorderPtrId?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['smallint']>;
  min?: Maybe<Scalars['smallint']>;
  units?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type FrequencyProtocolMinFields = {
  __typename?: 'frequencyProtocolMinFields';
  baseprotocolorderPtrId?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['smallint']>;
  min?: Maybe<Scalars['smallint']>;
  units?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_frequencyprotocol" */
export type FrequencyProtocolMutationResponse = {
  __typename?: 'frequencyProtocolMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FrequencyProtocol>;
};

/** on_conflict condition type for table "server_frequencyprotocol" */
export type FrequencyProtocolOnConflict = {
  constraint: FrequencyProtocolConstraint;
  update_columns?: Array<FrequencyProtocolUpdateColumn>;
  where?: InputMaybe<FrequencyProtocolBoolExp>;
};

/** Ordering options when selecting data from "server_frequencyprotocol". */
export type FrequencyProtocolOrderBy = {
  baseProtocolOrder?: InputMaybe<BaseProtocolOrderOrderBy>;
  baseprotocolorderPtrId?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrderBy>;
  min?: InputMaybe<OrderBy>;
  prn?: InputMaybe<OrderBy>;
  units?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_frequencyprotocol */
export type FrequencyProtocolPkColumnsInput = {
  baseprotocolorderPtrId: Scalars['Int'];
};

/** select columns of table "server_frequencyprotocol" */
export enum FrequencyProtocolSelectColumn {
  /** column name */
  BaseprotocolorderPtrId = 'baseprotocolorderPtrId',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  Prn = 'prn',
  /** column name */
  Units = 'units'
}

/** input type for updating data in table "server_frequencyprotocol" */
export type FrequencyProtocolSetInput = {
  baseprotocolorderPtrId?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['smallint']>;
  min?: InputMaybe<Scalars['smallint']>;
  prn?: InputMaybe<Scalars['Boolean']>;
  units?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type FrequencyProtocolStddevFields = {
  __typename?: 'frequencyProtocolStddevFields';
  baseprotocolorderPtrId?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FrequencyProtocolStddev_PopFields = {
  __typename?: 'frequencyProtocolStddev_popFields';
  baseprotocolorderPtrId?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FrequencyProtocolStddev_SampFields = {
  __typename?: 'frequencyProtocolStddev_sampFields';
  baseprotocolorderPtrId?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FrequencyProtocolSumFields = {
  __typename?: 'frequencyProtocolSumFields';
  baseprotocolorderPtrId?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['smallint']>;
  min?: Maybe<Scalars['smallint']>;
  units?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_frequencyprotocol" */
export enum FrequencyProtocolUpdateColumn {
  /** column name */
  BaseprotocolorderPtrId = 'baseprotocolorderPtrId',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  Prn = 'prn',
  /** column name */
  Units = 'units'
}

export type FrequencyProtocolUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FrequencyProtocolIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FrequencyProtocolSetInput>;
  where: FrequencyProtocolBoolExp;
};

/** aggregate var_pop on columns */
export type FrequencyProtocolVar_PopFields = {
  __typename?: 'frequencyProtocolVar_popFields';
  baseprotocolorderPtrId?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FrequencyProtocolVar_SampFields = {
  __typename?: 'frequencyProtocolVar_sampFields';
  baseprotocolorderPtrId?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FrequencyProtocolVarianceFields = {
  __typename?: 'frequencyProtocolVarianceFields';
  baseprotocolorderPtrId?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "frequencyProtocol" */
export type FrequencyProtocol_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FrequencyProtocol_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FrequencyProtocol_StreamCursorValueInput = {
  baseprotocolorderPtrId?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['smallint']>;
  min?: InputMaybe<Scalars['smallint']>;
  prn?: InputMaybe<Scalars['Boolean']>;
  units?: InputMaybe<Scalars['smallint']>;
};

/** columns and relationships of "server_hcpcs" */
export type Hcpcs = {
  __typename?: 'hcpcs';
  /** An array relationship */
  addonCodesets: Array<CodesetAddon>;
  /** An aggregate relationship */
  addonCodesetsAggregate: CodesetAddonAggregate;
  allowedTelemedicine: Scalars['Boolean'];
  /** An array relationship */
  baseCodesets: Array<CodesetBase>;
  /** An aggregate relationship */
  baseCodesetsAggregate: CodesetBaseAggregate;
  billingPos?: Maybe<Scalars['String']>;
  complexity?: Maybe<Scalars['smallint']>;
  description: Scalars['String'];
  hcpcsCode: Scalars['String'];
  id: Scalars['Int'];
  isAddon: Scalars['Boolean'];
  isCpt: Scalars['Boolean'];
  isEm: Scalars['Boolean'];
  isExtension: Scalars['Boolean'];
  isWellness: Scalars['Boolean'];
  /** An array relationship */
  lineitems: Array<Lineitem>;
  /** An aggregate relationship */
  lineitemsAggregate: LineitemAggregate;
  mayBeBilateral: Scalars['Boolean'];
  newVisitOnly: Scalars['Boolean'];
  patientPresent: Scalars['Boolean'];
  /** An array relationship */
  procedures: Array<ProcedureHcpcs>;
  /** An aggregate relationship */
  proceduresAggregate: ProcedureHcpcsAggregate;
  referencePrice: Scalars['numeric'];
  timeMinimum?: Maybe<Scalars['smallint']>;
  visitPos: Scalars['String'];
  workRvu: Scalars['numeric'];
};


/** columns and relationships of "server_hcpcs" */
export type HcpcsAddonCodesetsArgs = {
  distinctOn?: InputMaybe<Array<CodesetAddonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetAddonOrderBy>>;
  where?: InputMaybe<CodesetAddonBoolExp>;
};


/** columns and relationships of "server_hcpcs" */
export type HcpcsAddonCodesetsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CodesetAddonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetAddonOrderBy>>;
  where?: InputMaybe<CodesetAddonBoolExp>;
};


/** columns and relationships of "server_hcpcs" */
export type HcpcsBaseCodesetsArgs = {
  distinctOn?: InputMaybe<Array<CodesetBaseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetBaseOrderBy>>;
  where?: InputMaybe<CodesetBaseBoolExp>;
};


/** columns and relationships of "server_hcpcs" */
export type HcpcsBaseCodesetsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CodesetBaseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetBaseOrderBy>>;
  where?: InputMaybe<CodesetBaseBoolExp>;
};


/** columns and relationships of "server_hcpcs" */
export type HcpcsLineitemsArgs = {
  distinctOn?: InputMaybe<Array<LineitemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LineitemOrderBy>>;
  where?: InputMaybe<LineitemBoolExp>;
};


/** columns and relationships of "server_hcpcs" */
export type HcpcsLineitemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<LineitemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LineitemOrderBy>>;
  where?: InputMaybe<LineitemBoolExp>;
};


/** columns and relationships of "server_hcpcs" */
export type HcpcsProceduresArgs = {
  distinctOn?: InputMaybe<Array<ProcedureHcpcsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureHcpcsOrderBy>>;
  where?: InputMaybe<ProcedureHcpcsBoolExp>;
};


/** columns and relationships of "server_hcpcs" */
export type HcpcsProceduresAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcedureHcpcsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureHcpcsOrderBy>>;
  where?: InputMaybe<ProcedureHcpcsBoolExp>;
};

/** aggregated selection of "server_hcpcs" */
export type HcpcsAggregate = {
  __typename?: 'hcpcsAggregate';
  aggregate?: Maybe<HcpcsAggregateFields>;
  nodes: Array<Hcpcs>;
};

/** aggregate fields of "server_hcpcs" */
export type HcpcsAggregateFields = {
  __typename?: 'hcpcsAggregateFields';
  avg?: Maybe<HcpcsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<HcpcsMaxFields>;
  min?: Maybe<HcpcsMinFields>;
  stddev?: Maybe<HcpcsStddevFields>;
  stddevPop?: Maybe<HcpcsStddev_PopFields>;
  stddevSamp?: Maybe<HcpcsStddev_SampFields>;
  sum?: Maybe<HcpcsSumFields>;
  varPop?: Maybe<HcpcsVar_PopFields>;
  varSamp?: Maybe<HcpcsVar_SampFields>;
  variance?: Maybe<HcpcsVarianceFields>;
};


/** aggregate fields of "server_hcpcs" */
export type HcpcsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<HcpcsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type HcpcsAvgFields = {
  __typename?: 'hcpcsAvgFields';
  complexity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referencePrice?: Maybe<Scalars['Float']>;
  timeMinimum?: Maybe<Scalars['Float']>;
  workRvu?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_hcpcs". All fields are combined with a logical 'AND'. */
export type HcpcsBoolExp = {
  _and?: InputMaybe<Array<HcpcsBoolExp>>;
  _not?: InputMaybe<HcpcsBoolExp>;
  _or?: InputMaybe<Array<HcpcsBoolExp>>;
  addonCodesets?: InputMaybe<CodesetAddonBoolExp>;
  allowedTelemedicine?: InputMaybe<BooleanComparisonExp>;
  baseCodesets?: InputMaybe<CodesetBaseBoolExp>;
  billingPos?: InputMaybe<StringComparisonExp>;
  complexity?: InputMaybe<SmallintComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  hcpcsCode?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isAddon?: InputMaybe<BooleanComparisonExp>;
  isCpt?: InputMaybe<BooleanComparisonExp>;
  isEm?: InputMaybe<BooleanComparisonExp>;
  isExtension?: InputMaybe<BooleanComparisonExp>;
  isWellness?: InputMaybe<BooleanComparisonExp>;
  lineitems?: InputMaybe<LineitemBoolExp>;
  mayBeBilateral?: InputMaybe<BooleanComparisonExp>;
  newVisitOnly?: InputMaybe<BooleanComparisonExp>;
  patientPresent?: InputMaybe<BooleanComparisonExp>;
  procedures?: InputMaybe<ProcedureHcpcsBoolExp>;
  referencePrice?: InputMaybe<NumericComparisonExp>;
  timeMinimum?: InputMaybe<SmallintComparisonExp>;
  visitPos?: InputMaybe<StringComparisonExp>;
  workRvu?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "server_hcpcs" */
export enum HcpcsConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerHcpcsPkey = 'server_hcpcs_pkey'
}

/** input type for incrementing numeric columns in table "server_hcpcs" */
export type HcpcsIncInput = {
  complexity?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  referencePrice?: InputMaybe<Scalars['numeric']>;
  timeMinimum?: InputMaybe<Scalars['smallint']>;
  workRvu?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "server_hcpcs" */
export type HcpcsInsertInput = {
  addonCodesets?: InputMaybe<CodesetAddonArrRelInsertInput>;
  allowedTelemedicine?: InputMaybe<Scalars['Boolean']>;
  baseCodesets?: InputMaybe<CodesetBaseArrRelInsertInput>;
  billingPos?: InputMaybe<Scalars['String']>;
  complexity?: InputMaybe<Scalars['smallint']>;
  description?: InputMaybe<Scalars['String']>;
  hcpcsCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isAddon?: InputMaybe<Scalars['Boolean']>;
  isCpt?: InputMaybe<Scalars['Boolean']>;
  isEm?: InputMaybe<Scalars['Boolean']>;
  isExtension?: InputMaybe<Scalars['Boolean']>;
  isWellness?: InputMaybe<Scalars['Boolean']>;
  lineitems?: InputMaybe<LineitemArrRelInsertInput>;
  mayBeBilateral?: InputMaybe<Scalars['Boolean']>;
  newVisitOnly?: InputMaybe<Scalars['Boolean']>;
  patientPresent?: InputMaybe<Scalars['Boolean']>;
  procedures?: InputMaybe<ProcedureHcpcsArrRelInsertInput>;
  referencePrice?: InputMaybe<Scalars['numeric']>;
  timeMinimum?: InputMaybe<Scalars['smallint']>;
  visitPos?: InputMaybe<Scalars['String']>;
  workRvu?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type HcpcsMaxFields = {
  __typename?: 'hcpcsMaxFields';
  billingPos?: Maybe<Scalars['String']>;
  complexity?: Maybe<Scalars['smallint']>;
  description?: Maybe<Scalars['String']>;
  hcpcsCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  referencePrice?: Maybe<Scalars['numeric']>;
  timeMinimum?: Maybe<Scalars['smallint']>;
  visitPos?: Maybe<Scalars['String']>;
  workRvu?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type HcpcsMinFields = {
  __typename?: 'hcpcsMinFields';
  billingPos?: Maybe<Scalars['String']>;
  complexity?: Maybe<Scalars['smallint']>;
  description?: Maybe<Scalars['String']>;
  hcpcsCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  referencePrice?: Maybe<Scalars['numeric']>;
  timeMinimum?: Maybe<Scalars['smallint']>;
  visitPos?: Maybe<Scalars['String']>;
  workRvu?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "server_hcpcs" */
export type HcpcsMutationResponse = {
  __typename?: 'hcpcsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hcpcs>;
};

/** input type for inserting object relation for remote table "server_hcpcs" */
export type HcpcsObjRelInsertInput = {
  data: HcpcsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<HcpcsOnConflict>;
};

/** on_conflict condition type for table "server_hcpcs" */
export type HcpcsOnConflict = {
  constraint: HcpcsConstraint;
  update_columns?: Array<HcpcsUpdateColumn>;
  where?: InputMaybe<HcpcsBoolExp>;
};

/** Ordering options when selecting data from "server_hcpcs". */
export type HcpcsOrderBy = {
  addonCodesetsAggregate?: InputMaybe<CodesetAddonAggregateOrderBy>;
  allowedTelemedicine?: InputMaybe<OrderBy>;
  baseCodesetsAggregate?: InputMaybe<CodesetBaseAggregateOrderBy>;
  billingPos?: InputMaybe<OrderBy>;
  complexity?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  hcpcsCode?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAddon?: InputMaybe<OrderBy>;
  isCpt?: InputMaybe<OrderBy>;
  isEm?: InputMaybe<OrderBy>;
  isExtension?: InputMaybe<OrderBy>;
  isWellness?: InputMaybe<OrderBy>;
  lineitemsAggregate?: InputMaybe<LineitemAggregateOrderBy>;
  mayBeBilateral?: InputMaybe<OrderBy>;
  newVisitOnly?: InputMaybe<OrderBy>;
  patientPresent?: InputMaybe<OrderBy>;
  proceduresAggregate?: InputMaybe<ProcedureHcpcsAggregateOrderBy>;
  referencePrice?: InputMaybe<OrderBy>;
  timeMinimum?: InputMaybe<OrderBy>;
  visitPos?: InputMaybe<OrderBy>;
  workRvu?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_hcpcs */
export type HcpcsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_hcpcs" */
export enum HcpcsSelectColumn {
  /** column name */
  AllowedTelemedicine = 'allowedTelemedicine',
  /** column name */
  BillingPos = 'billingPos',
  /** column name */
  Complexity = 'complexity',
  /** column name */
  Description = 'description',
  /** column name */
  HcpcsCode = 'hcpcsCode',
  /** column name */
  Id = 'id',
  /** column name */
  IsAddon = 'isAddon',
  /** column name */
  IsCpt = 'isCpt',
  /** column name */
  IsEm = 'isEm',
  /** column name */
  IsExtension = 'isExtension',
  /** column name */
  IsWellness = 'isWellness',
  /** column name */
  MayBeBilateral = 'mayBeBilateral',
  /** column name */
  NewVisitOnly = 'newVisitOnly',
  /** column name */
  PatientPresent = 'patientPresent',
  /** column name */
  ReferencePrice = 'referencePrice',
  /** column name */
  TimeMinimum = 'timeMinimum',
  /** column name */
  VisitPos = 'visitPos',
  /** column name */
  WorkRvu = 'workRvu'
}

/** input type for updating data in table "server_hcpcs" */
export type HcpcsSetInput = {
  allowedTelemedicine?: InputMaybe<Scalars['Boolean']>;
  billingPos?: InputMaybe<Scalars['String']>;
  complexity?: InputMaybe<Scalars['smallint']>;
  description?: InputMaybe<Scalars['String']>;
  hcpcsCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isAddon?: InputMaybe<Scalars['Boolean']>;
  isCpt?: InputMaybe<Scalars['Boolean']>;
  isEm?: InputMaybe<Scalars['Boolean']>;
  isExtension?: InputMaybe<Scalars['Boolean']>;
  isWellness?: InputMaybe<Scalars['Boolean']>;
  mayBeBilateral?: InputMaybe<Scalars['Boolean']>;
  newVisitOnly?: InputMaybe<Scalars['Boolean']>;
  patientPresent?: InputMaybe<Scalars['Boolean']>;
  referencePrice?: InputMaybe<Scalars['numeric']>;
  timeMinimum?: InputMaybe<Scalars['smallint']>;
  visitPos?: InputMaybe<Scalars['String']>;
  workRvu?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type HcpcsStddevFields = {
  __typename?: 'hcpcsStddevFields';
  complexity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referencePrice?: Maybe<Scalars['Float']>;
  timeMinimum?: Maybe<Scalars['Float']>;
  workRvu?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type HcpcsStddev_PopFields = {
  __typename?: 'hcpcsStddev_popFields';
  complexity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referencePrice?: Maybe<Scalars['Float']>;
  timeMinimum?: Maybe<Scalars['Float']>;
  workRvu?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type HcpcsStddev_SampFields = {
  __typename?: 'hcpcsStddev_sampFields';
  complexity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referencePrice?: Maybe<Scalars['Float']>;
  timeMinimum?: Maybe<Scalars['Float']>;
  workRvu?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type HcpcsSumFields = {
  __typename?: 'hcpcsSumFields';
  complexity?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  referencePrice?: Maybe<Scalars['numeric']>;
  timeMinimum?: Maybe<Scalars['smallint']>;
  workRvu?: Maybe<Scalars['numeric']>;
};

/** update columns of table "server_hcpcs" */
export enum HcpcsUpdateColumn {
  /** column name */
  AllowedTelemedicine = 'allowedTelemedicine',
  /** column name */
  BillingPos = 'billingPos',
  /** column name */
  Complexity = 'complexity',
  /** column name */
  Description = 'description',
  /** column name */
  HcpcsCode = 'hcpcsCode',
  /** column name */
  Id = 'id',
  /** column name */
  IsAddon = 'isAddon',
  /** column name */
  IsCpt = 'isCpt',
  /** column name */
  IsEm = 'isEm',
  /** column name */
  IsExtension = 'isExtension',
  /** column name */
  IsWellness = 'isWellness',
  /** column name */
  MayBeBilateral = 'mayBeBilateral',
  /** column name */
  NewVisitOnly = 'newVisitOnly',
  /** column name */
  PatientPresent = 'patientPresent',
  /** column name */
  ReferencePrice = 'referencePrice',
  /** column name */
  TimeMinimum = 'timeMinimum',
  /** column name */
  VisitPos = 'visitPos',
  /** column name */
  WorkRvu = 'workRvu'
}

export type HcpcsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<HcpcsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<HcpcsSetInput>;
  where: HcpcsBoolExp;
};

/** aggregate var_pop on columns */
export type HcpcsVar_PopFields = {
  __typename?: 'hcpcsVar_popFields';
  complexity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referencePrice?: Maybe<Scalars['Float']>;
  timeMinimum?: Maybe<Scalars['Float']>;
  workRvu?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type HcpcsVar_SampFields = {
  __typename?: 'hcpcsVar_sampFields';
  complexity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referencePrice?: Maybe<Scalars['Float']>;
  timeMinimum?: Maybe<Scalars['Float']>;
  workRvu?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type HcpcsVarianceFields = {
  __typename?: 'hcpcsVarianceFields';
  complexity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referencePrice?: Maybe<Scalars['Float']>;
  timeMinimum?: Maybe<Scalars['Float']>;
  workRvu?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "hcpcs" */
export type Hcpcs_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Hcpcs_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Hcpcs_StreamCursorValueInput = {
  allowedTelemedicine?: InputMaybe<Scalars['Boolean']>;
  billingPos?: InputMaybe<Scalars['String']>;
  complexity?: InputMaybe<Scalars['smallint']>;
  description?: InputMaybe<Scalars['String']>;
  hcpcsCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isAddon?: InputMaybe<Scalars['Boolean']>;
  isCpt?: InputMaybe<Scalars['Boolean']>;
  isEm?: InputMaybe<Scalars['Boolean']>;
  isExtension?: InputMaybe<Scalars['Boolean']>;
  isWellness?: InputMaybe<Scalars['Boolean']>;
  mayBeBilateral?: InputMaybe<Scalars['Boolean']>;
  newVisitOnly?: InputMaybe<Scalars['Boolean']>;
  patientPresent?: InputMaybe<Scalars['Boolean']>;
  referencePrice?: InputMaybe<Scalars['numeric']>;
  timeMinimum?: InputMaybe<Scalars['smallint']>;
  visitPos?: InputMaybe<Scalars['String']>;
  workRvu?: InputMaybe<Scalars['numeric']>;
};

/** columns and relationships of "server_insuranceplan" */
export type Insuranceplan = {
  __typename?: 'insuranceplan';
  active: Scalars['Boolean'];
  /** An array relationship */
  eligibilityChecks: Array<PatientEligibilityCheck>;
  /** An aggregate relationship */
  eligibilityChecksAggregate: PatientEligibilityCheckAggregate;
  id: Scalars['Int'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  /** An object relationship */
  payer: Payer;
  payerId: Scalars['Int'];
  rank: Scalars['smallint'];
  subscriberDob?: Maybe<Scalars['String']>;
  subscriberFirstName?: Maybe<Scalars['String']>;
  subscriberGroupNumber?: Maybe<Scalars['String']>;
  subscriberIdNumber?: Maybe<Scalars['String']>;
  subscriberLastName?: Maybe<Scalars['String']>;
};


/** columns and relationships of "server_insuranceplan" */
export type InsuranceplanEligibilityChecksArgs = {
  distinctOn?: InputMaybe<Array<PatientEligibilityCheckSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientEligibilityCheckOrderBy>>;
  where?: InputMaybe<PatientEligibilityCheckBoolExp>;
};


/** columns and relationships of "server_insuranceplan" */
export type InsuranceplanEligibilityChecksAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientEligibilityCheckSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientEligibilityCheckOrderBy>>;
  where?: InputMaybe<PatientEligibilityCheckBoolExp>;
};

/** aggregated selection of "server_insuranceplan" */
export type InsuranceplanAggregate = {
  __typename?: 'insuranceplanAggregate';
  aggregate?: Maybe<InsuranceplanAggregateFields>;
  nodes: Array<Insuranceplan>;
};

/** aggregate fields of "server_insuranceplan" */
export type InsuranceplanAggregateFields = {
  __typename?: 'insuranceplanAggregateFields';
  avg?: Maybe<InsuranceplanAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<InsuranceplanMaxFields>;
  min?: Maybe<InsuranceplanMinFields>;
  stddev?: Maybe<InsuranceplanStddevFields>;
  stddevPop?: Maybe<InsuranceplanStddev_PopFields>;
  stddevSamp?: Maybe<InsuranceplanStddev_SampFields>;
  sum?: Maybe<InsuranceplanSumFields>;
  varPop?: Maybe<InsuranceplanVar_PopFields>;
  varSamp?: Maybe<InsuranceplanVar_SampFields>;
  variance?: Maybe<InsuranceplanVarianceFields>;
};


/** aggregate fields of "server_insuranceplan" */
export type InsuranceplanAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InsuranceplanSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_insuranceplan" */
export type InsuranceplanAggregateOrderBy = {
  avg?: InputMaybe<Insuranceplan_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Insuranceplan_Max_Order_By>;
  min?: InputMaybe<Insuranceplan_Min_Order_By>;
  stddev?: InputMaybe<Insuranceplan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Insuranceplan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Insuranceplan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Insuranceplan_Sum_Order_By>;
  var_pop?: InputMaybe<Insuranceplan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Insuranceplan_Var_Samp_Order_By>;
  variance?: InputMaybe<Insuranceplan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_insuranceplan" */
export type InsuranceplanArrRelInsertInput = {
  data: Array<InsuranceplanInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<InsuranceplanOnConflict>;
};

/** aggregate avg on columns */
export type InsuranceplanAvgFields = {
  __typename?: 'insuranceplanAvgFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_insuranceplan". All fields are combined with a logical 'AND'. */
export type InsuranceplanBoolExp = {
  _and?: InputMaybe<Array<InsuranceplanBoolExp>>;
  _not?: InputMaybe<InsuranceplanBoolExp>;
  _or?: InputMaybe<Array<InsuranceplanBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  eligibilityChecks?: InputMaybe<PatientEligibilityCheckBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  payer?: InputMaybe<PayerBoolExp>;
  payerId?: InputMaybe<IntComparisonExp>;
  rank?: InputMaybe<SmallintComparisonExp>;
  subscriberDob?: InputMaybe<StringComparisonExp>;
  subscriberFirstName?: InputMaybe<StringComparisonExp>;
  subscriberGroupNumber?: InputMaybe<StringComparisonExp>;
  subscriberIdNumber?: InputMaybe<StringComparisonExp>;
  subscriberLastName?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_insuranceplan" */
export enum InsuranceplanConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerInsuranceplanPkey = 'server_insuranceplan_pkey'
}

/** input type for incrementing numeric columns in table "server_insuranceplan" */
export type InsuranceplanIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  payerId?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_insuranceplan" */
export type InsuranceplanInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  eligibilityChecks?: InputMaybe<PatientEligibilityCheckArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  payer?: InputMaybe<PayerObjRelInsertInput>;
  payerId?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['smallint']>;
  subscriberDob?: InputMaybe<Scalars['String']>;
  subscriberFirstName?: InputMaybe<Scalars['String']>;
  subscriberGroupNumber?: InputMaybe<Scalars['String']>;
  subscriberIdNumber?: InputMaybe<Scalars['String']>;
  subscriberLastName?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type InsuranceplanMaxFields = {
  __typename?: 'insuranceplanMaxFields';
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  payerId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['smallint']>;
  subscriberDob?: Maybe<Scalars['String']>;
  subscriberFirstName?: Maybe<Scalars['String']>;
  subscriberGroupNumber?: Maybe<Scalars['String']>;
  subscriberIdNumber?: Maybe<Scalars['String']>;
  subscriberLastName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type InsuranceplanMinFields = {
  __typename?: 'insuranceplanMinFields';
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  payerId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['smallint']>;
  subscriberDob?: Maybe<Scalars['String']>;
  subscriberFirstName?: Maybe<Scalars['String']>;
  subscriberGroupNumber?: Maybe<Scalars['String']>;
  subscriberIdNumber?: Maybe<Scalars['String']>;
  subscriberLastName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_insuranceplan" */
export type InsuranceplanMutationResponse = {
  __typename?: 'insuranceplanMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insuranceplan>;
};

/** input type for inserting object relation for remote table "server_insuranceplan" */
export type InsuranceplanObjRelInsertInput = {
  data: InsuranceplanInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<InsuranceplanOnConflict>;
};

/** on_conflict condition type for table "server_insuranceplan" */
export type InsuranceplanOnConflict = {
  constraint: InsuranceplanConstraint;
  update_columns?: Array<InsuranceplanUpdateColumn>;
  where?: InputMaybe<InsuranceplanBoolExp>;
};

/** Ordering options when selecting data from "server_insuranceplan". */
export type InsuranceplanOrderBy = {
  active?: InputMaybe<OrderBy>;
  eligibilityChecksAggregate?: InputMaybe<PatientEligibilityCheckAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  payer?: InputMaybe<PayerOrderBy>;
  payerId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
  subscriberDob?: InputMaybe<OrderBy>;
  subscriberFirstName?: InputMaybe<OrderBy>;
  subscriberGroupNumber?: InputMaybe<OrderBy>;
  subscriberIdNumber?: InputMaybe<OrderBy>;
  subscriberLastName?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_insuranceplan */
export type InsuranceplanPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_insuranceplan" */
export enum InsuranceplanSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PayerId = 'payerId',
  /** column name */
  Rank = 'rank',
  /** column name */
  SubscriberDob = 'subscriberDob',
  /** column name */
  SubscriberFirstName = 'subscriberFirstName',
  /** column name */
  SubscriberGroupNumber = 'subscriberGroupNumber',
  /** column name */
  SubscriberIdNumber = 'subscriberIdNumber',
  /** column name */
  SubscriberLastName = 'subscriberLastName'
}

/** input type for updating data in table "server_insuranceplan" */
export type InsuranceplanSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  payerId?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['smallint']>;
  subscriberDob?: InputMaybe<Scalars['String']>;
  subscriberFirstName?: InputMaybe<Scalars['String']>;
  subscriberGroupNumber?: InputMaybe<Scalars['String']>;
  subscriberIdNumber?: InputMaybe<Scalars['String']>;
  subscriberLastName?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type InsuranceplanStddevFields = {
  __typename?: 'insuranceplanStddevFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type InsuranceplanStddev_PopFields = {
  __typename?: 'insuranceplanStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type InsuranceplanStddev_SampFields = {
  __typename?: 'insuranceplanStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type InsuranceplanSumFields = {
  __typename?: 'insuranceplanSumFields';
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  payerId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_insuranceplan" */
export enum InsuranceplanUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PayerId = 'payerId',
  /** column name */
  Rank = 'rank',
  /** column name */
  SubscriberDob = 'subscriberDob',
  /** column name */
  SubscriberFirstName = 'subscriberFirstName',
  /** column name */
  SubscriberGroupNumber = 'subscriberGroupNumber',
  /** column name */
  SubscriberIdNumber = 'subscriberIdNumber',
  /** column name */
  SubscriberLastName = 'subscriberLastName'
}

export type InsuranceplanUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InsuranceplanIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InsuranceplanSetInput>;
  where: InsuranceplanBoolExp;
};

/** aggregate var_pop on columns */
export type InsuranceplanVar_PopFields = {
  __typename?: 'insuranceplanVar_popFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type InsuranceplanVar_SampFields = {
  __typename?: 'insuranceplanVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type InsuranceplanVarianceFields = {
  __typename?: 'insuranceplanVarianceFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_insuranceplan" */
export type Insuranceplan_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_insuranceplan" */
export type Insuranceplan_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
  subscriberDob?: InputMaybe<OrderBy>;
  subscriberFirstName?: InputMaybe<OrderBy>;
  subscriberGroupNumber?: InputMaybe<OrderBy>;
  subscriberIdNumber?: InputMaybe<OrderBy>;
  subscriberLastName?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_insuranceplan" */
export type Insuranceplan_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
  subscriberDob?: InputMaybe<OrderBy>;
  subscriberFirstName?: InputMaybe<OrderBy>;
  subscriberGroupNumber?: InputMaybe<OrderBy>;
  subscriberIdNumber?: InputMaybe<OrderBy>;
  subscriberLastName?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_insuranceplan" */
export type Insuranceplan_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_insuranceplan" */
export type Insuranceplan_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_insuranceplan" */
export type Insuranceplan_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "insuranceplan" */
export type Insuranceplan_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Insuranceplan_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Insuranceplan_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  payerId?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['smallint']>;
  subscriberDob?: InputMaybe<Scalars['String']>;
  subscriberFirstName?: InputMaybe<Scalars['String']>;
  subscriberGroupNumber?: InputMaybe<Scalars['String']>;
  subscriberIdNumber?: InputMaybe<Scalars['String']>;
  subscriberLastName?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_insuranceplan" */
export type Insuranceplan_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_insuranceplan" */
export type Insuranceplan_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_insuranceplan" */
export type Insuranceplan_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_insuranceplan" */
export type Insuranceplan_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_integration" */
export type Integration = {
  __typename?: 'integration';
  accessToken?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  clonedNoteProfile?: Maybe<Scalars['Int']>;
  defaultDoctorId?: Maybe<Scalars['Int']>;
  defaultPhysicalVisitProfile?: Maybe<Scalars['Int']>;
  /** An object relationship */
  defaultProvider?: Maybe<Provider>;
  defaultVirtualVisitProfile?: Maybe<Scalars['Int']>;
  expiresIn?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  officesExams?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  practicegroup: Practicegroup;
  practicegroupId: Scalars['Int'];
  redirectUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  signedNoteProfile?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type: Scalars['smallint'];
};


/** columns and relationships of "server_integration" */
export type IntegrationOfficesExamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "server_integration" */
export type IntegrationAggregate = {
  __typename?: 'integrationAggregate';
  aggregate?: Maybe<IntegrationAggregateFields>;
  nodes: Array<Integration>;
};

/** aggregate fields of "server_integration" */
export type IntegrationAggregateFields = {
  __typename?: 'integrationAggregateFields';
  avg?: Maybe<IntegrationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IntegrationMaxFields>;
  min?: Maybe<IntegrationMinFields>;
  stddev?: Maybe<IntegrationStddevFields>;
  stddevPop?: Maybe<IntegrationStddev_PopFields>;
  stddevSamp?: Maybe<IntegrationStddev_SampFields>;
  sum?: Maybe<IntegrationSumFields>;
  varPop?: Maybe<IntegrationVar_PopFields>;
  varSamp?: Maybe<IntegrationVar_SampFields>;
  variance?: Maybe<IntegrationVarianceFields>;
};


/** aggregate fields of "server_integration" */
export type IntegrationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IntegrationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_integration" */
export type IntegrationAggregateOrderBy = {
  avg?: InputMaybe<Integration_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Integration_Max_Order_By>;
  min?: InputMaybe<Integration_Min_Order_By>;
  stddev?: InputMaybe<Integration_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Integration_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Integration_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Integration_Sum_Order_By>;
  var_pop?: InputMaybe<Integration_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Integration_Var_Samp_Order_By>;
  variance?: InputMaybe<Integration_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IntegrationAppendInput = {
  officesExams?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_integration" */
export type IntegrationArrRelInsertInput = {
  data: Array<IntegrationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<IntegrationOnConflict>;
};

/** aggregate avg on columns */
export type IntegrationAvgFields = {
  __typename?: 'integrationAvgFields';
  clonedNoteProfile?: Maybe<Scalars['Float']>;
  defaultDoctorId?: Maybe<Scalars['Float']>;
  defaultPhysicalVisitProfile?: Maybe<Scalars['Float']>;
  defaultVirtualVisitProfile?: Maybe<Scalars['Float']>;
  expiresIn?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  signedNoteProfile?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_integration". All fields are combined with a logical 'AND'. */
export type IntegrationBoolExp = {
  _and?: InputMaybe<Array<IntegrationBoolExp>>;
  _not?: InputMaybe<IntegrationBoolExp>;
  _or?: InputMaybe<Array<IntegrationBoolExp>>;
  accessToken?: InputMaybe<StringComparisonExp>;
  clientId?: InputMaybe<StringComparisonExp>;
  clientSecret?: InputMaybe<StringComparisonExp>;
  clonedNoteProfile?: InputMaybe<IntComparisonExp>;
  defaultDoctorId?: InputMaybe<IntComparisonExp>;
  defaultPhysicalVisitProfile?: InputMaybe<IntComparisonExp>;
  defaultProvider?: InputMaybe<ProviderBoolExp>;
  defaultVirtualVisitProfile?: InputMaybe<IntComparisonExp>;
  expiresIn?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  officesExams?: InputMaybe<JsonbComparisonExp>;
  practicegroup?: InputMaybe<PracticegroupBoolExp>;
  practicegroupId?: InputMaybe<IntComparisonExp>;
  redirectUrl?: InputMaybe<StringComparisonExp>;
  refreshToken?: InputMaybe<StringComparisonExp>;
  signedNoteProfile?: InputMaybe<IntComparisonExp>;
  timestamp?: InputMaybe<TimestamptzComparisonExp>;
  type?: InputMaybe<SmallintComparisonExp>;
};

/** unique or primary key constraints on table "server_integration" */
export enum IntegrationConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerIntegrationPkey = 'server_integration_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IntegrationDeleteAtPathInput = {
  officesExams?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IntegrationDeleteElemInput = {
  officesExams?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IntegrationDeleteKeyInput = {
  officesExams?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_integration" */
export type IntegrationIncInput = {
  clonedNoteProfile?: InputMaybe<Scalars['Int']>;
  defaultDoctorId?: InputMaybe<Scalars['Int']>;
  defaultPhysicalVisitProfile?: InputMaybe<Scalars['Int']>;
  defaultVirtualVisitProfile?: InputMaybe<Scalars['Int']>;
  expiresIn?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  practicegroupId?: InputMaybe<Scalars['Int']>;
  signedNoteProfile?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_integration" */
export type IntegrationInsertInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  clonedNoteProfile?: InputMaybe<Scalars['Int']>;
  defaultDoctorId?: InputMaybe<Scalars['Int']>;
  defaultPhysicalVisitProfile?: InputMaybe<Scalars['Int']>;
  defaultProvider?: InputMaybe<ProviderObjRelInsertInput>;
  defaultVirtualVisitProfile?: InputMaybe<Scalars['Int']>;
  expiresIn?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  officesExams?: InputMaybe<Scalars['jsonb']>;
  practicegroup?: InputMaybe<PracticegroupObjRelInsertInput>;
  practicegroupId?: InputMaybe<Scalars['Int']>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  signedNoteProfile?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type IntegrationMaxFields = {
  __typename?: 'integrationMaxFields';
  accessToken?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  clonedNoteProfile?: Maybe<Scalars['Int']>;
  defaultDoctorId?: Maybe<Scalars['Int']>;
  defaultPhysicalVisitProfile?: Maybe<Scalars['Int']>;
  defaultVirtualVisitProfile?: Maybe<Scalars['Int']>;
  expiresIn?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  practicegroupId?: Maybe<Scalars['Int']>;
  redirectUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  signedNoteProfile?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type IntegrationMinFields = {
  __typename?: 'integrationMinFields';
  accessToken?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  clonedNoteProfile?: Maybe<Scalars['Int']>;
  defaultDoctorId?: Maybe<Scalars['Int']>;
  defaultPhysicalVisitProfile?: Maybe<Scalars['Int']>;
  defaultVirtualVisitProfile?: Maybe<Scalars['Int']>;
  expiresIn?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  practicegroupId?: Maybe<Scalars['Int']>;
  redirectUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  signedNoteProfile?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_integration" */
export type IntegrationMutationResponse = {
  __typename?: 'integrationMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration>;
};

/** on_conflict condition type for table "server_integration" */
export type IntegrationOnConflict = {
  constraint: IntegrationConstraint;
  update_columns?: Array<IntegrationUpdateColumn>;
  where?: InputMaybe<IntegrationBoolExp>;
};

/** Ordering options when selecting data from "server_integration". */
export type IntegrationOrderBy = {
  accessToken?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  clientSecret?: InputMaybe<OrderBy>;
  clonedNoteProfile?: InputMaybe<OrderBy>;
  defaultDoctorId?: InputMaybe<OrderBy>;
  defaultPhysicalVisitProfile?: InputMaybe<OrderBy>;
  defaultProvider?: InputMaybe<ProviderOrderBy>;
  defaultVirtualVisitProfile?: InputMaybe<OrderBy>;
  expiresIn?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  officesExams?: InputMaybe<OrderBy>;
  practicegroup?: InputMaybe<PracticegroupOrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  redirectUrl?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  signedNoteProfile?: InputMaybe<OrderBy>;
  timestamp?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_integration */
export type IntegrationPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IntegrationPrependInput = {
  officesExams?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_integration" */
export enum IntegrationSelectColumn {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientSecret = 'clientSecret',
  /** column name */
  ClonedNoteProfile = 'clonedNoteProfile',
  /** column name */
  DefaultDoctorId = 'defaultDoctorId',
  /** column name */
  DefaultPhysicalVisitProfile = 'defaultPhysicalVisitProfile',
  /** column name */
  DefaultVirtualVisitProfile = 'defaultVirtualVisitProfile',
  /** column name */
  ExpiresIn = 'expiresIn',
  /** column name */
  Id = 'id',
  /** column name */
  OfficesExams = 'officesExams',
  /** column name */
  PracticegroupId = 'practicegroupId',
  /** column name */
  RedirectUrl = 'redirectUrl',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  SignedNoteProfile = 'signedNoteProfile',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "server_integration" */
export type IntegrationSetInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  clonedNoteProfile?: InputMaybe<Scalars['Int']>;
  defaultDoctorId?: InputMaybe<Scalars['Int']>;
  defaultPhysicalVisitProfile?: InputMaybe<Scalars['Int']>;
  defaultVirtualVisitProfile?: InputMaybe<Scalars['Int']>;
  expiresIn?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  officesExams?: InputMaybe<Scalars['jsonb']>;
  practicegroupId?: InputMaybe<Scalars['Int']>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  signedNoteProfile?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type IntegrationStddevFields = {
  __typename?: 'integrationStddevFields';
  clonedNoteProfile?: Maybe<Scalars['Float']>;
  defaultDoctorId?: Maybe<Scalars['Float']>;
  defaultPhysicalVisitProfile?: Maybe<Scalars['Float']>;
  defaultVirtualVisitProfile?: Maybe<Scalars['Float']>;
  expiresIn?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  signedNoteProfile?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IntegrationStddev_PopFields = {
  __typename?: 'integrationStddev_popFields';
  clonedNoteProfile?: Maybe<Scalars['Float']>;
  defaultDoctorId?: Maybe<Scalars['Float']>;
  defaultPhysicalVisitProfile?: Maybe<Scalars['Float']>;
  defaultVirtualVisitProfile?: Maybe<Scalars['Float']>;
  expiresIn?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  signedNoteProfile?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IntegrationStddev_SampFields = {
  __typename?: 'integrationStddev_sampFields';
  clonedNoteProfile?: Maybe<Scalars['Float']>;
  defaultDoctorId?: Maybe<Scalars['Float']>;
  defaultPhysicalVisitProfile?: Maybe<Scalars['Float']>;
  defaultVirtualVisitProfile?: Maybe<Scalars['Float']>;
  expiresIn?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  signedNoteProfile?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type IntegrationSumFields = {
  __typename?: 'integrationSumFields';
  clonedNoteProfile?: Maybe<Scalars['Int']>;
  defaultDoctorId?: Maybe<Scalars['Int']>;
  defaultPhysicalVisitProfile?: Maybe<Scalars['Int']>;
  defaultVirtualVisitProfile?: Maybe<Scalars['Int']>;
  expiresIn?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  practicegroupId?: Maybe<Scalars['Int']>;
  signedNoteProfile?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_integration" */
export enum IntegrationUpdateColumn {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientSecret = 'clientSecret',
  /** column name */
  ClonedNoteProfile = 'clonedNoteProfile',
  /** column name */
  DefaultDoctorId = 'defaultDoctorId',
  /** column name */
  DefaultPhysicalVisitProfile = 'defaultPhysicalVisitProfile',
  /** column name */
  DefaultVirtualVisitProfile = 'defaultVirtualVisitProfile',
  /** column name */
  ExpiresIn = 'expiresIn',
  /** column name */
  Id = 'id',
  /** column name */
  OfficesExams = 'officesExams',
  /** column name */
  PracticegroupId = 'practicegroupId',
  /** column name */
  RedirectUrl = 'redirectUrl',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  SignedNoteProfile = 'signedNoteProfile',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type'
}

export type IntegrationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IntegrationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<IntegrationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<IntegrationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<IntegrationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IntegrationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IntegrationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IntegrationSetInput>;
  where: IntegrationBoolExp;
};

/** aggregate var_pop on columns */
export type IntegrationVar_PopFields = {
  __typename?: 'integrationVar_popFields';
  clonedNoteProfile?: Maybe<Scalars['Float']>;
  defaultDoctorId?: Maybe<Scalars['Float']>;
  defaultPhysicalVisitProfile?: Maybe<Scalars['Float']>;
  defaultVirtualVisitProfile?: Maybe<Scalars['Float']>;
  expiresIn?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  signedNoteProfile?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IntegrationVar_SampFields = {
  __typename?: 'integrationVar_sampFields';
  clonedNoteProfile?: Maybe<Scalars['Float']>;
  defaultDoctorId?: Maybe<Scalars['Float']>;
  defaultPhysicalVisitProfile?: Maybe<Scalars['Float']>;
  defaultVirtualVisitProfile?: Maybe<Scalars['Float']>;
  expiresIn?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  signedNoteProfile?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IntegrationVarianceFields = {
  __typename?: 'integrationVarianceFields';
  clonedNoteProfile?: Maybe<Scalars['Float']>;
  defaultDoctorId?: Maybe<Scalars['Float']>;
  defaultPhysicalVisitProfile?: Maybe<Scalars['Float']>;
  defaultVirtualVisitProfile?: Maybe<Scalars['Float']>;
  expiresIn?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  signedNoteProfile?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_integration" */
export type Integration_Avg_Order_By = {
  clonedNoteProfile?: InputMaybe<OrderBy>;
  defaultDoctorId?: InputMaybe<OrderBy>;
  defaultPhysicalVisitProfile?: InputMaybe<OrderBy>;
  defaultVirtualVisitProfile?: InputMaybe<OrderBy>;
  expiresIn?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  signedNoteProfile?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_integration" */
export type Integration_Max_Order_By = {
  accessToken?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  clientSecret?: InputMaybe<OrderBy>;
  clonedNoteProfile?: InputMaybe<OrderBy>;
  defaultDoctorId?: InputMaybe<OrderBy>;
  defaultPhysicalVisitProfile?: InputMaybe<OrderBy>;
  defaultVirtualVisitProfile?: InputMaybe<OrderBy>;
  expiresIn?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  redirectUrl?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  signedNoteProfile?: InputMaybe<OrderBy>;
  timestamp?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_integration" */
export type Integration_Min_Order_By = {
  accessToken?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  clientSecret?: InputMaybe<OrderBy>;
  clonedNoteProfile?: InputMaybe<OrderBy>;
  defaultDoctorId?: InputMaybe<OrderBy>;
  defaultPhysicalVisitProfile?: InputMaybe<OrderBy>;
  defaultVirtualVisitProfile?: InputMaybe<OrderBy>;
  expiresIn?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  redirectUrl?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  signedNoteProfile?: InputMaybe<OrderBy>;
  timestamp?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_integration" */
export type Integration_Stddev_Order_By = {
  clonedNoteProfile?: InputMaybe<OrderBy>;
  defaultDoctorId?: InputMaybe<OrderBy>;
  defaultPhysicalVisitProfile?: InputMaybe<OrderBy>;
  defaultVirtualVisitProfile?: InputMaybe<OrderBy>;
  expiresIn?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  signedNoteProfile?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_integration" */
export type Integration_Stddev_Pop_Order_By = {
  clonedNoteProfile?: InputMaybe<OrderBy>;
  defaultDoctorId?: InputMaybe<OrderBy>;
  defaultPhysicalVisitProfile?: InputMaybe<OrderBy>;
  defaultVirtualVisitProfile?: InputMaybe<OrderBy>;
  expiresIn?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  signedNoteProfile?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_integration" */
export type Integration_Stddev_Samp_Order_By = {
  clonedNoteProfile?: InputMaybe<OrderBy>;
  defaultDoctorId?: InputMaybe<OrderBy>;
  defaultPhysicalVisitProfile?: InputMaybe<OrderBy>;
  defaultVirtualVisitProfile?: InputMaybe<OrderBy>;
  expiresIn?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  signedNoteProfile?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "integration" */
export type Integration_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Integration_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_StreamCursorValueInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  clonedNoteProfile?: InputMaybe<Scalars['Int']>;
  defaultDoctorId?: InputMaybe<Scalars['Int']>;
  defaultPhysicalVisitProfile?: InputMaybe<Scalars['Int']>;
  defaultVirtualVisitProfile?: InputMaybe<Scalars['Int']>;
  expiresIn?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  officesExams?: InputMaybe<Scalars['jsonb']>;
  practicegroupId?: InputMaybe<Scalars['Int']>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  signedNoteProfile?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "server_integration" */
export type Integration_Sum_Order_By = {
  clonedNoteProfile?: InputMaybe<OrderBy>;
  defaultDoctorId?: InputMaybe<OrderBy>;
  defaultPhysicalVisitProfile?: InputMaybe<OrderBy>;
  defaultVirtualVisitProfile?: InputMaybe<OrderBy>;
  expiresIn?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  signedNoteProfile?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_integration" */
export type Integration_Var_Pop_Order_By = {
  clonedNoteProfile?: InputMaybe<OrderBy>;
  defaultDoctorId?: InputMaybe<OrderBy>;
  defaultPhysicalVisitProfile?: InputMaybe<OrderBy>;
  defaultVirtualVisitProfile?: InputMaybe<OrderBy>;
  expiresIn?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  signedNoteProfile?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_integration" */
export type Integration_Var_Samp_Order_By = {
  clonedNoteProfile?: InputMaybe<OrderBy>;
  defaultDoctorId?: InputMaybe<OrderBy>;
  defaultPhysicalVisitProfile?: InputMaybe<OrderBy>;
  defaultVirtualVisitProfile?: InputMaybe<OrderBy>;
  expiresIn?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  signedNoteProfile?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_integration" */
export type Integration_Variance_Order_By = {
  clonedNoteProfile?: InputMaybe<OrderBy>;
  defaultDoctorId?: InputMaybe<OrderBy>;
  defaultPhysicalVisitProfile?: InputMaybe<OrderBy>;
  defaultVirtualVisitProfile?: InputMaybe<OrderBy>;
  expiresIn?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  signedNoteProfile?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_internalstaff" */
export type Internalstaff = {
  __typename?: 'internalstaff';
  isSupervisor: Scalars['Boolean'];
  practiceId: Scalars['Int'];
  /** An object relationship */
  practicegroup: Practicegroup;
  role: Scalars['smallint'];
  /** An object relationship */
  user: User;
  userId: Scalars['Int'];
};

/** aggregated selection of "server_internalstaff" */
export type InternalstaffAggregate = {
  __typename?: 'internalstaffAggregate';
  aggregate?: Maybe<InternalstaffAggregateFields>;
  nodes: Array<Internalstaff>;
};

/** aggregate fields of "server_internalstaff" */
export type InternalstaffAggregateFields = {
  __typename?: 'internalstaffAggregateFields';
  avg?: Maybe<InternalstaffAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<InternalstaffMaxFields>;
  min?: Maybe<InternalstaffMinFields>;
  stddev?: Maybe<InternalstaffStddevFields>;
  stddevPop?: Maybe<InternalstaffStddev_PopFields>;
  stddevSamp?: Maybe<InternalstaffStddev_SampFields>;
  sum?: Maybe<InternalstaffSumFields>;
  varPop?: Maybe<InternalstaffVar_PopFields>;
  varSamp?: Maybe<InternalstaffVar_SampFields>;
  variance?: Maybe<InternalstaffVarianceFields>;
};


/** aggregate fields of "server_internalstaff" */
export type InternalstaffAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InternalstaffSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_internalstaff" */
export type InternalstaffAggregateOrderBy = {
  avg?: InputMaybe<Internalstaff_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Internalstaff_Max_Order_By>;
  min?: InputMaybe<Internalstaff_Min_Order_By>;
  stddev?: InputMaybe<Internalstaff_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Internalstaff_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Internalstaff_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Internalstaff_Sum_Order_By>;
  var_pop?: InputMaybe<Internalstaff_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Internalstaff_Var_Samp_Order_By>;
  variance?: InputMaybe<Internalstaff_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_internalstaff" */
export type InternalstaffArrRelInsertInput = {
  data: Array<InternalstaffInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<InternalstaffOnConflict>;
};

/** aggregate avg on columns */
export type InternalstaffAvgFields = {
  __typename?: 'internalstaffAvgFields';
  practiceId?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_internalstaff". All fields are combined with a logical 'AND'. */
export type InternalstaffBoolExp = {
  _and?: InputMaybe<Array<InternalstaffBoolExp>>;
  _not?: InputMaybe<InternalstaffBoolExp>;
  _or?: InputMaybe<Array<InternalstaffBoolExp>>;
  isSupervisor?: InputMaybe<BooleanComparisonExp>;
  practiceId?: InputMaybe<IntComparisonExp>;
  practicegroup?: InputMaybe<PracticegroupBoolExp>;
  role?: InputMaybe<SmallintComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_internalstaff" */
export enum InternalstaffConstraint {
  /** unique or primary key constraint on columns "user_id" */
  ServerInternalstaffPkey = 'server_internalstaff_pkey'
}

/** input type for incrementing numeric columns in table "server_internalstaff" */
export type InternalstaffIncInput = {
  practiceId?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['smallint']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_internalstaff" */
export type InternalstaffInsertInput = {
  isSupervisor?: InputMaybe<Scalars['Boolean']>;
  practiceId?: InputMaybe<Scalars['Int']>;
  practicegroup?: InputMaybe<PracticegroupObjRelInsertInput>;
  role?: InputMaybe<Scalars['smallint']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type InternalstaffMaxFields = {
  __typename?: 'internalstaffMaxFields';
  practiceId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['smallint']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type InternalstaffMinFields = {
  __typename?: 'internalstaffMinFields';
  practiceId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['smallint']>;
  userId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_internalstaff" */
export type InternalstaffMutationResponse = {
  __typename?: 'internalstaffMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Internalstaff>;
};

/** input type for inserting object relation for remote table "server_internalstaff" */
export type InternalstaffObjRelInsertInput = {
  data: InternalstaffInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<InternalstaffOnConflict>;
};

/** on_conflict condition type for table "server_internalstaff" */
export type InternalstaffOnConflict = {
  constraint: InternalstaffConstraint;
  update_columns?: Array<InternalstaffUpdateColumn>;
  where?: InputMaybe<InternalstaffBoolExp>;
};

/** Ordering options when selecting data from "server_internalstaff". */
export type InternalstaffOrderBy = {
  isSupervisor?: InputMaybe<OrderBy>;
  practiceId?: InputMaybe<OrderBy>;
  practicegroup?: InputMaybe<PracticegroupOrderBy>;
  role?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_internalstaff */
export type InternalstaffPkColumnsInput = {
  userId: Scalars['Int'];
};

/** select columns of table "server_internalstaff" */
export enum InternalstaffSelectColumn {
  /** column name */
  IsSupervisor = 'isSupervisor',
  /** column name */
  PracticeId = 'practiceId',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "server_internalstaff" */
export type InternalstaffSetInput = {
  isSupervisor?: InputMaybe<Scalars['Boolean']>;
  practiceId?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['smallint']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type InternalstaffStddevFields = {
  __typename?: 'internalstaffStddevFields';
  practiceId?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type InternalstaffStddev_PopFields = {
  __typename?: 'internalstaffStddev_popFields';
  practiceId?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type InternalstaffStddev_SampFields = {
  __typename?: 'internalstaffStddev_sampFields';
  practiceId?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type InternalstaffSumFields = {
  __typename?: 'internalstaffSumFields';
  practiceId?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['smallint']>;
  userId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_internalstaff" */
export enum InternalstaffUpdateColumn {
  /** column name */
  IsSupervisor = 'isSupervisor',
  /** column name */
  PracticeId = 'practiceId',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

export type InternalstaffUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InternalstaffIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InternalstaffSetInput>;
  where: InternalstaffBoolExp;
};

/** aggregate var_pop on columns */
export type InternalstaffVar_PopFields = {
  __typename?: 'internalstaffVar_popFields';
  practiceId?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type InternalstaffVar_SampFields = {
  __typename?: 'internalstaffVar_sampFields';
  practiceId?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type InternalstaffVarianceFields = {
  __typename?: 'internalstaffVarianceFields';
  practiceId?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_internalstaff" */
export type Internalstaff_Avg_Order_By = {
  practiceId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_internalstaff" */
export type Internalstaff_Max_Order_By = {
  practiceId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_internalstaff" */
export type Internalstaff_Min_Order_By = {
  practiceId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_internalstaff" */
export type Internalstaff_Stddev_Order_By = {
  practiceId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_internalstaff" */
export type Internalstaff_Stddev_Pop_Order_By = {
  practiceId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_internalstaff" */
export type Internalstaff_Stddev_Samp_Order_By = {
  practiceId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "internalstaff" */
export type Internalstaff_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Internalstaff_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Internalstaff_StreamCursorValueInput = {
  isSupervisor?: InputMaybe<Scalars['Boolean']>;
  practiceId?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['smallint']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_internalstaff" */
export type Internalstaff_Sum_Order_By = {
  practiceId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_internalstaff" */
export type Internalstaff_Var_Pop_Order_By = {
  practiceId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_internalstaff" */
export type Internalstaff_Var_Samp_Order_By = {
  practiceId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_internalstaff" */
export type Internalstaff_Variance_Order_By = {
  practiceId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_order" */
export type LegacyOrder = {
  __typename?: 'legacyOrder';
  /** An object relationship */
  approvalAppointment?: Maybe<Appointment>;
  approvalAppointmentId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  approvalProvider?: Maybe<Provider>;
  approvalProviderId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  approvalSupervisor?: Maybe<Provider>;
  approvalSupervisorId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  approvalSupervisorUser?: Maybe<User>;
  approvalSupervisorUserId?: Maybe<Scalars['Int']>;
  approved: Scalars['Boolean'];
  data?: Maybe<Scalars['jsonb']>;
  dateEffective?: Maybe<Scalars['timestamptz']>;
  dateExpires?: Maybe<Scalars['timestamptz']>;
  dateFinalized?: Maybe<Scalars['timestamptz']>;
  dateRequested?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  destinationAgency?: Maybe<Agency>;
  destinationId?: Maybe<Scalars['Int']>;
  discipline?: Maybe<Scalars['smallint']>;
  finalizedUserId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  finalizingUser?: Maybe<User>;
  html?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  newBaseOrder?: Maybe<Baseorder>;
  notes?: Maybe<Scalars['String']>;
  orderStatus: Scalars['smallint'];
  orderText?: Maybe<Scalars['String']>;
  orderType: Scalars['smallint'];
  /** An object relationship */
  orderable?: Maybe<Orderable>;
  orderableId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  requestUserId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  requestingUser?: Maybe<User>;
  supervisorApprovalDate?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  ticket?: Maybe<TicketThread>;
  ticketId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  treatmentPlanOrder?: Maybe<LegacyTreatmentplanOrder>;
};


/** columns and relationships of "server_order" */
export type LegacyOrderDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "server_order" */
export type LegacyOrderAggregate = {
  __typename?: 'legacyOrderAggregate';
  aggregate?: Maybe<LegacyOrderAggregateFields>;
  nodes: Array<LegacyOrder>;
};

/** aggregate fields of "server_order" */
export type LegacyOrderAggregateFields = {
  __typename?: 'legacyOrderAggregateFields';
  avg?: Maybe<LegacyOrderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LegacyOrderMaxFields>;
  min?: Maybe<LegacyOrderMinFields>;
  stddev?: Maybe<LegacyOrderStddevFields>;
  stddevPop?: Maybe<LegacyOrderStddev_PopFields>;
  stddevSamp?: Maybe<LegacyOrderStddev_SampFields>;
  sum?: Maybe<LegacyOrderSumFields>;
  varPop?: Maybe<LegacyOrderVar_PopFields>;
  varSamp?: Maybe<LegacyOrderVar_SampFields>;
  variance?: Maybe<LegacyOrderVarianceFields>;
};


/** aggregate fields of "server_order" */
export type LegacyOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_order" */
export type LegacyOrderAggregateOrderBy = {
  avg?: InputMaybe<LegacyOrder_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LegacyOrder_Max_Order_By>;
  min?: InputMaybe<LegacyOrder_Min_Order_By>;
  stddev?: InputMaybe<LegacyOrder_Stddev_Order_By>;
  stddev_pop?: InputMaybe<LegacyOrder_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<LegacyOrder_Stddev_Samp_Order_By>;
  sum?: InputMaybe<LegacyOrder_Sum_Order_By>;
  var_pop?: InputMaybe<LegacyOrder_Var_Pop_Order_By>;
  var_samp?: InputMaybe<LegacyOrder_Var_Samp_Order_By>;
  variance?: InputMaybe<LegacyOrder_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LegacyOrderAppendInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_order" */
export type LegacyOrderArrRelInsertInput = {
  data: Array<LegacyOrderInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<LegacyOrderOnConflict>;
};

/** aggregate avg on columns */
export type LegacyOrderAvgFields = {
  __typename?: 'legacyOrderAvgFields';
  approvalAppointmentId?: Maybe<Scalars['Float']>;
  approvalProviderId?: Maybe<Scalars['Float']>;
  approvalSupervisorId?: Maybe<Scalars['Float']>;
  approvalSupervisorUserId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  discipline?: Maybe<Scalars['Float']>;
  finalizedUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  orderType?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  requestUserId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_order". All fields are combined with a logical 'AND'. */
export type LegacyOrderBoolExp = {
  _and?: InputMaybe<Array<LegacyOrderBoolExp>>;
  _not?: InputMaybe<LegacyOrderBoolExp>;
  _or?: InputMaybe<Array<LegacyOrderBoolExp>>;
  approvalAppointment?: InputMaybe<AppointmentBoolExp>;
  approvalAppointmentId?: InputMaybe<IntComparisonExp>;
  approvalProvider?: InputMaybe<ProviderBoolExp>;
  approvalProviderId?: InputMaybe<IntComparisonExp>;
  approvalSupervisor?: InputMaybe<ProviderBoolExp>;
  approvalSupervisorId?: InputMaybe<IntComparisonExp>;
  approvalSupervisorUser?: InputMaybe<UserBoolExp>;
  approvalSupervisorUserId?: InputMaybe<IntComparisonExp>;
  approved?: InputMaybe<BooleanComparisonExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  dateEffective?: InputMaybe<TimestamptzComparisonExp>;
  dateExpires?: InputMaybe<TimestamptzComparisonExp>;
  dateFinalized?: InputMaybe<TimestamptzComparisonExp>;
  dateRequested?: InputMaybe<TimestamptzComparisonExp>;
  destinationAgency?: InputMaybe<AgencyBoolExp>;
  destinationId?: InputMaybe<IntComparisonExp>;
  discipline?: InputMaybe<SmallintComparisonExp>;
  finalizedUserId?: InputMaybe<IntComparisonExp>;
  finalizingUser?: InputMaybe<UserBoolExp>;
  html?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  newBaseOrder?: InputMaybe<BaseorderBoolExp>;
  notes?: InputMaybe<StringComparisonExp>;
  orderStatus?: InputMaybe<SmallintComparisonExp>;
  orderText?: InputMaybe<StringComparisonExp>;
  orderType?: InputMaybe<SmallintComparisonExp>;
  orderable?: InputMaybe<OrderableBoolExp>;
  orderableId?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  requestUserId?: InputMaybe<IntComparisonExp>;
  requestingUser?: InputMaybe<UserBoolExp>;
  supervisorApprovalDate?: InputMaybe<TimestamptzComparisonExp>;
  ticket?: InputMaybe<TicketThreadBoolExp>;
  ticketId?: InputMaybe<IntComparisonExp>;
  treatmentPlanOrder?: InputMaybe<LegacyTreatmentplanOrderBoolExp>;
};

/** unique or primary key constraints on table "server_order" */
export enum LegacyOrderConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerOrderPkey = 'server_order_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LegacyOrderDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LegacyOrderDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LegacyOrderDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "server_orderfrequency" */
export type LegacyOrderFrequency = {
  __typename?: 'legacyOrderFrequency';
  duration?: Maybe<Scalars['interval']>;
  id: Scalars['Int'];
  /** An object relationship */
  legacyOrder: LegacyOrder;
  max: Scalars['smallint'];
  min: Scalars['smallint'];
  orderId: Scalars['Int'];
  prn: Scalars['Boolean'];
  startDate: Scalars['date'];
};

/** aggregated selection of "server_orderfrequency" */
export type LegacyOrderFrequencyAggregate = {
  __typename?: 'legacyOrderFrequencyAggregate';
  aggregate?: Maybe<LegacyOrderFrequencyAggregateFields>;
  nodes: Array<LegacyOrderFrequency>;
};

/** aggregate fields of "server_orderfrequency" */
export type LegacyOrderFrequencyAggregateFields = {
  __typename?: 'legacyOrderFrequencyAggregateFields';
  avg?: Maybe<LegacyOrderFrequencyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LegacyOrderFrequencyMaxFields>;
  min?: Maybe<LegacyOrderFrequencyMinFields>;
  stddev?: Maybe<LegacyOrderFrequencyStddevFields>;
  stddevPop?: Maybe<LegacyOrderFrequencyStddev_PopFields>;
  stddevSamp?: Maybe<LegacyOrderFrequencyStddev_SampFields>;
  sum?: Maybe<LegacyOrderFrequencySumFields>;
  varPop?: Maybe<LegacyOrderFrequencyVar_PopFields>;
  varSamp?: Maybe<LegacyOrderFrequencyVar_SampFields>;
  variance?: Maybe<LegacyOrderFrequencyVarianceFields>;
};


/** aggregate fields of "server_orderfrequency" */
export type LegacyOrderFrequencyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LegacyOrderFrequencySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LegacyOrderFrequencyAvgFields = {
  __typename?: 'legacyOrderFrequencyAvgFields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderfrequency". All fields are combined with a logical 'AND'. */
export type LegacyOrderFrequencyBoolExp = {
  _and?: InputMaybe<Array<LegacyOrderFrequencyBoolExp>>;
  _not?: InputMaybe<LegacyOrderFrequencyBoolExp>;
  _or?: InputMaybe<Array<LegacyOrderFrequencyBoolExp>>;
  duration?: InputMaybe<IntervalComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  legacyOrder?: InputMaybe<LegacyOrderBoolExp>;
  max?: InputMaybe<SmallintComparisonExp>;
  min?: InputMaybe<SmallintComparisonExp>;
  orderId?: InputMaybe<IntComparisonExp>;
  prn?: InputMaybe<BooleanComparisonExp>;
  startDate?: InputMaybe<DateComparisonExp>;
};

/** unique or primary key constraints on table "server_orderfrequency" */
export enum LegacyOrderFrequencyConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerOrderfrequencyPkey = 'server_orderfrequency_pkey'
}

/** input type for incrementing numeric columns in table "server_orderfrequency" */
export type LegacyOrderFrequencyIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['smallint']>;
  min?: InputMaybe<Scalars['smallint']>;
  orderId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_orderfrequency" */
export type LegacyOrderFrequencyInsertInput = {
  duration?: InputMaybe<Scalars['interval']>;
  id?: InputMaybe<Scalars['Int']>;
  legacyOrder?: InputMaybe<LegacyOrderObjRelInsertInput>;
  max?: InputMaybe<Scalars['smallint']>;
  min?: InputMaybe<Scalars['smallint']>;
  orderId?: InputMaybe<Scalars['Int']>;
  prn?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['date']>;
};

/** aggregate max on columns */
export type LegacyOrderFrequencyMaxFields = {
  __typename?: 'legacyOrderFrequencyMaxFields';
  id?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['smallint']>;
  min?: Maybe<Scalars['smallint']>;
  orderId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type LegacyOrderFrequencyMinFields = {
  __typename?: 'legacyOrderFrequencyMinFields';
  id?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['smallint']>;
  min?: Maybe<Scalars['smallint']>;
  orderId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
};

/** response of any mutation on the table "server_orderfrequency" */
export type LegacyOrderFrequencyMutationResponse = {
  __typename?: 'legacyOrderFrequencyMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LegacyOrderFrequency>;
};

/** on_conflict condition type for table "server_orderfrequency" */
export type LegacyOrderFrequencyOnConflict = {
  constraint: LegacyOrderFrequencyConstraint;
  update_columns?: Array<LegacyOrderFrequencyUpdateColumn>;
  where?: InputMaybe<LegacyOrderFrequencyBoolExp>;
};

/** Ordering options when selecting data from "server_orderfrequency". */
export type LegacyOrderFrequencyOrderBy = {
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legacyOrder?: InputMaybe<LegacyOrderOrderBy>;
  max?: InputMaybe<OrderBy>;
  min?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  prn?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_orderfrequency */
export type LegacyOrderFrequencyPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_orderfrequency" */
export enum LegacyOrderFrequencySelectColumn {
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Prn = 'prn',
  /** column name */
  StartDate = 'startDate'
}

/** input type for updating data in table "server_orderfrequency" */
export type LegacyOrderFrequencySetInput = {
  duration?: InputMaybe<Scalars['interval']>;
  id?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['smallint']>;
  min?: InputMaybe<Scalars['smallint']>;
  orderId?: InputMaybe<Scalars['Int']>;
  prn?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['date']>;
};

/** aggregate stddev on columns */
export type LegacyOrderFrequencyStddevFields = {
  __typename?: 'legacyOrderFrequencyStddevFields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LegacyOrderFrequencyStddev_PopFields = {
  __typename?: 'legacyOrderFrequencyStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LegacyOrderFrequencyStddev_SampFields = {
  __typename?: 'legacyOrderFrequencyStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LegacyOrderFrequencySumFields = {
  __typename?: 'legacyOrderFrequencySumFields';
  id?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['smallint']>;
  min?: Maybe<Scalars['smallint']>;
  orderId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_orderfrequency" */
export enum LegacyOrderFrequencyUpdateColumn {
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Prn = 'prn',
  /** column name */
  StartDate = 'startDate'
}

export type LegacyOrderFrequencyUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LegacyOrderFrequencyIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LegacyOrderFrequencySetInput>;
  where: LegacyOrderFrequencyBoolExp;
};

/** aggregate var_pop on columns */
export type LegacyOrderFrequencyVar_PopFields = {
  __typename?: 'legacyOrderFrequencyVar_popFields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LegacyOrderFrequencyVar_SampFields = {
  __typename?: 'legacyOrderFrequencyVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LegacyOrderFrequencyVarianceFields = {
  __typename?: 'legacyOrderFrequencyVarianceFields';
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "legacyOrderFrequency" */
export type LegacyOrderFrequency_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LegacyOrderFrequency_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LegacyOrderFrequency_StreamCursorValueInput = {
  duration?: InputMaybe<Scalars['interval']>;
  id?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['smallint']>;
  min?: InputMaybe<Scalars['smallint']>;
  orderId?: InputMaybe<Scalars['Int']>;
  prn?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['date']>;
};

/** input type for incrementing numeric columns in table "server_order" */
export type LegacyOrderIncInput = {
  approvalAppointmentId?: InputMaybe<Scalars['Int']>;
  approvalProviderId?: InputMaybe<Scalars['Int']>;
  approvalSupervisorId?: InputMaybe<Scalars['Int']>;
  approvalSupervisorUserId?: InputMaybe<Scalars['Int']>;
  destinationId?: InputMaybe<Scalars['Int']>;
  discipline?: InputMaybe<Scalars['smallint']>;
  finalizedUserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderStatus?: InputMaybe<Scalars['smallint']>;
  orderType?: InputMaybe<Scalars['smallint']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  requestUserId?: InputMaybe<Scalars['Int']>;
  ticketId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_order" */
export type LegacyOrderInsertInput = {
  approvalAppointment?: InputMaybe<AppointmentObjRelInsertInput>;
  approvalAppointmentId?: InputMaybe<Scalars['Int']>;
  approvalProvider?: InputMaybe<ProviderObjRelInsertInput>;
  approvalProviderId?: InputMaybe<Scalars['Int']>;
  approvalSupervisor?: InputMaybe<ProviderObjRelInsertInput>;
  approvalSupervisorId?: InputMaybe<Scalars['Int']>;
  approvalSupervisorUser?: InputMaybe<UserObjRelInsertInput>;
  approvalSupervisorUserId?: InputMaybe<Scalars['Int']>;
  approved?: InputMaybe<Scalars['Boolean']>;
  data?: InputMaybe<Scalars['jsonb']>;
  dateEffective?: InputMaybe<Scalars['timestamptz']>;
  dateExpires?: InputMaybe<Scalars['timestamptz']>;
  dateFinalized?: InputMaybe<Scalars['timestamptz']>;
  dateRequested?: InputMaybe<Scalars['timestamptz']>;
  destinationAgency?: InputMaybe<AgencyObjRelInsertInput>;
  destinationId?: InputMaybe<Scalars['Int']>;
  discipline?: InputMaybe<Scalars['smallint']>;
  finalizedUserId?: InputMaybe<Scalars['Int']>;
  finalizingUser?: InputMaybe<UserObjRelInsertInput>;
  html?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  newBaseOrder?: InputMaybe<BaseorderObjRelInsertInput>;
  notes?: InputMaybe<Scalars['String']>;
  orderStatus?: InputMaybe<Scalars['smallint']>;
  orderText?: InputMaybe<Scalars['String']>;
  orderType?: InputMaybe<Scalars['smallint']>;
  orderable?: InputMaybe<OrderableObjRelInsertInput>;
  orderableId?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  requestUserId?: InputMaybe<Scalars['Int']>;
  requestingUser?: InputMaybe<UserObjRelInsertInput>;
  supervisorApprovalDate?: InputMaybe<Scalars['timestamptz']>;
  ticket?: InputMaybe<TicketThreadObjRelInsertInput>;
  ticketId?: InputMaybe<Scalars['Int']>;
  treatmentPlanOrder?: InputMaybe<LegacyTreatmentplanOrderObjRelInsertInput>;
};

/** aggregate max on columns */
export type LegacyOrderMaxFields = {
  __typename?: 'legacyOrderMaxFields';
  approvalAppointmentId?: Maybe<Scalars['Int']>;
  approvalProviderId?: Maybe<Scalars['Int']>;
  approvalSupervisorId?: Maybe<Scalars['Int']>;
  approvalSupervisorUserId?: Maybe<Scalars['Int']>;
  dateEffective?: Maybe<Scalars['timestamptz']>;
  dateExpires?: Maybe<Scalars['timestamptz']>;
  dateFinalized?: Maybe<Scalars['timestamptz']>;
  dateRequested?: Maybe<Scalars['timestamptz']>;
  destinationId?: Maybe<Scalars['Int']>;
  discipline?: Maybe<Scalars['smallint']>;
  finalizedUserId?: Maybe<Scalars['Int']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['smallint']>;
  orderText?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['smallint']>;
  orderableId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  requestUserId?: Maybe<Scalars['Int']>;
  supervisorApprovalDate?: Maybe<Scalars['timestamptz']>;
  ticketId?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "server_order_medications" */
export type LegacyOrderMedications = {
  __typename?: 'legacyOrderMedications';
  id: Scalars['Int'];
  /** An object relationship */
  medication: Medication;
  medicationId: Scalars['Int'];
  /** An object relationship */
  order: LegacyOrder;
  orderId: Scalars['Int'];
};

/** aggregated selection of "server_order_medications" */
export type LegacyOrderMedicationsAggregate = {
  __typename?: 'legacyOrderMedicationsAggregate';
  aggregate?: Maybe<LegacyOrderMedicationsAggregateFields>;
  nodes: Array<LegacyOrderMedications>;
};

/** aggregate fields of "server_order_medications" */
export type LegacyOrderMedicationsAggregateFields = {
  __typename?: 'legacyOrderMedicationsAggregateFields';
  avg?: Maybe<LegacyOrderMedicationsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LegacyOrderMedicationsMaxFields>;
  min?: Maybe<LegacyOrderMedicationsMinFields>;
  stddev?: Maybe<LegacyOrderMedicationsStddevFields>;
  stddevPop?: Maybe<LegacyOrderMedicationsStddev_PopFields>;
  stddevSamp?: Maybe<LegacyOrderMedicationsStddev_SampFields>;
  sum?: Maybe<LegacyOrderMedicationsSumFields>;
  varPop?: Maybe<LegacyOrderMedicationsVar_PopFields>;
  varSamp?: Maybe<LegacyOrderMedicationsVar_SampFields>;
  variance?: Maybe<LegacyOrderMedicationsVarianceFields>;
};


/** aggregate fields of "server_order_medications" */
export type LegacyOrderMedicationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LegacyOrderMedicationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LegacyOrderMedicationsAvgFields = {
  __typename?: 'legacyOrderMedicationsAvgFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_order_medications". All fields are combined with a logical 'AND'. */
export type LegacyOrderMedicationsBoolExp = {
  _and?: InputMaybe<Array<LegacyOrderMedicationsBoolExp>>;
  _not?: InputMaybe<LegacyOrderMedicationsBoolExp>;
  _or?: InputMaybe<Array<LegacyOrderMedicationsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  medication?: InputMaybe<MedicationBoolExp>;
  medicationId?: InputMaybe<IntComparisonExp>;
  order?: InputMaybe<LegacyOrderBoolExp>;
  orderId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_order_medications" */
export enum LegacyOrderMedicationsConstraint {
  /** unique or primary key constraint on columns "medication_id", "order_id" */
  ServerOrderMedicationsOrderIdMedicationId_71378d10Uniq = 'server_order_medications_order_id_medication_id_71378d10_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerOrderMedicationsPkey = 'server_order_medications_pkey'
}

/** input type for incrementing numeric columns in table "server_order_medications" */
export type LegacyOrderMedicationsIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  medicationId?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_order_medications" */
export type LegacyOrderMedicationsInsertInput = {
  id?: InputMaybe<Scalars['Int']>;
  medication?: InputMaybe<MedicationObjRelInsertInput>;
  medicationId?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<LegacyOrderObjRelInsertInput>;
  orderId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type LegacyOrderMedicationsMaxFields = {
  __typename?: 'legacyOrderMedicationsMaxFields';
  id?: Maybe<Scalars['Int']>;
  medicationId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type LegacyOrderMedicationsMinFields = {
  __typename?: 'legacyOrderMedicationsMinFields';
  id?: Maybe<Scalars['Int']>;
  medicationId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_order_medications" */
export type LegacyOrderMedicationsMutationResponse = {
  __typename?: 'legacyOrderMedicationsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LegacyOrderMedications>;
};

/** on_conflict condition type for table "server_order_medications" */
export type LegacyOrderMedicationsOnConflict = {
  constraint: LegacyOrderMedicationsConstraint;
  update_columns?: Array<LegacyOrderMedicationsUpdateColumn>;
  where?: InputMaybe<LegacyOrderMedicationsBoolExp>;
};

/** Ordering options when selecting data from "server_order_medications". */
export type LegacyOrderMedicationsOrderBy = {
  id?: InputMaybe<OrderBy>;
  medication?: InputMaybe<MedicationOrderBy>;
  medicationId?: InputMaybe<OrderBy>;
  order?: InputMaybe<LegacyOrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_order_medications */
export type LegacyOrderMedicationsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_order_medications" */
export enum LegacyOrderMedicationsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medicationId',
  /** column name */
  OrderId = 'orderId'
}

/** input type for updating data in table "server_order_medications" */
export type LegacyOrderMedicationsSetInput = {
  id?: InputMaybe<Scalars['Int']>;
  medicationId?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LegacyOrderMedicationsStddevFields = {
  __typename?: 'legacyOrderMedicationsStddevFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LegacyOrderMedicationsStddev_PopFields = {
  __typename?: 'legacyOrderMedicationsStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LegacyOrderMedicationsStddev_SampFields = {
  __typename?: 'legacyOrderMedicationsStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LegacyOrderMedicationsSumFields = {
  __typename?: 'legacyOrderMedicationsSumFields';
  id?: Maybe<Scalars['Int']>;
  medicationId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_order_medications" */
export enum LegacyOrderMedicationsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medicationId',
  /** column name */
  OrderId = 'orderId'
}

export type LegacyOrderMedicationsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LegacyOrderMedicationsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LegacyOrderMedicationsSetInput>;
  where: LegacyOrderMedicationsBoolExp;
};

/** aggregate var_pop on columns */
export type LegacyOrderMedicationsVar_PopFields = {
  __typename?: 'legacyOrderMedicationsVar_popFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LegacyOrderMedicationsVar_SampFields = {
  __typename?: 'legacyOrderMedicationsVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LegacyOrderMedicationsVarianceFields = {
  __typename?: 'legacyOrderMedicationsVarianceFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "legacyOrderMedications" */
export type LegacyOrderMedications_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LegacyOrderMedications_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LegacyOrderMedications_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  medicationId?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type LegacyOrderMinFields = {
  __typename?: 'legacyOrderMinFields';
  approvalAppointmentId?: Maybe<Scalars['Int']>;
  approvalProviderId?: Maybe<Scalars['Int']>;
  approvalSupervisorId?: Maybe<Scalars['Int']>;
  approvalSupervisorUserId?: Maybe<Scalars['Int']>;
  dateEffective?: Maybe<Scalars['timestamptz']>;
  dateExpires?: Maybe<Scalars['timestamptz']>;
  dateFinalized?: Maybe<Scalars['timestamptz']>;
  dateRequested?: Maybe<Scalars['timestamptz']>;
  destinationId?: Maybe<Scalars['Int']>;
  discipline?: Maybe<Scalars['smallint']>;
  finalizedUserId?: Maybe<Scalars['Int']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['smallint']>;
  orderText?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['smallint']>;
  orderableId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  requestUserId?: Maybe<Scalars['Int']>;
  supervisorApprovalDate?: Maybe<Scalars['timestamptz']>;
  ticketId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_order" */
export type LegacyOrderMutationResponse = {
  __typename?: 'legacyOrderMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LegacyOrder>;
};

/** input type for inserting object relation for remote table "server_order" */
export type LegacyOrderObjRelInsertInput = {
  data: LegacyOrderInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<LegacyOrderOnConflict>;
};

/** on_conflict condition type for table "server_order" */
export type LegacyOrderOnConflict = {
  constraint: LegacyOrderConstraint;
  update_columns?: Array<LegacyOrderUpdateColumn>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};

/** Ordering options when selecting data from "server_order". */
export type LegacyOrderOrderBy = {
  approvalAppointment?: InputMaybe<AppointmentOrderBy>;
  approvalAppointmentId?: InputMaybe<OrderBy>;
  approvalProvider?: InputMaybe<ProviderOrderBy>;
  approvalProviderId?: InputMaybe<OrderBy>;
  approvalSupervisor?: InputMaybe<ProviderOrderBy>;
  approvalSupervisorId?: InputMaybe<OrderBy>;
  approvalSupervisorUser?: InputMaybe<UserOrderBy>;
  approvalSupervisorUserId?: InputMaybe<OrderBy>;
  approved?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  dateEffective?: InputMaybe<OrderBy>;
  dateExpires?: InputMaybe<OrderBy>;
  dateFinalized?: InputMaybe<OrderBy>;
  dateRequested?: InputMaybe<OrderBy>;
  destinationAgency?: InputMaybe<AgencyOrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  discipline?: InputMaybe<OrderBy>;
  finalizedUserId?: InputMaybe<OrderBy>;
  finalizingUser?: InputMaybe<UserOrderBy>;
  html?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  newBaseOrder?: InputMaybe<BaseorderOrderBy>;
  notes?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  orderText?: InputMaybe<OrderBy>;
  orderType?: InputMaybe<OrderBy>;
  orderable?: InputMaybe<OrderableOrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  requestUserId?: InputMaybe<OrderBy>;
  requestingUser?: InputMaybe<UserOrderBy>;
  supervisorApprovalDate?: InputMaybe<OrderBy>;
  ticket?: InputMaybe<TicketThreadOrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  treatmentPlanOrder?: InputMaybe<LegacyTreatmentplanOrderOrderBy>;
};

/** columns and relationships of "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValue = {
  __typename?: 'legacyOrderOrderableFieldValue';
  /** An object relationship */
  field: OrderOrderableField;
  fieldId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  legacyOrder: OrderableOrder;
  orderId: Scalars['Int'];
  show: Scalars['Boolean'];
  value: Scalars['String'];
};

/** aggregated selection of "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValueAggregate = {
  __typename?: 'legacyOrderOrderableFieldValueAggregate';
  aggregate?: Maybe<LegacyOrderOrderableFieldValueAggregateFields>;
  nodes: Array<LegacyOrderOrderableFieldValue>;
};

/** aggregate fields of "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValueAggregateFields = {
  __typename?: 'legacyOrderOrderableFieldValueAggregateFields';
  avg?: Maybe<LegacyOrderOrderableFieldValueAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LegacyOrderOrderableFieldValueMaxFields>;
  min?: Maybe<LegacyOrderOrderableFieldValueMinFields>;
  stddev?: Maybe<LegacyOrderOrderableFieldValueStddevFields>;
  stddevPop?: Maybe<LegacyOrderOrderableFieldValueStddev_PopFields>;
  stddevSamp?: Maybe<LegacyOrderOrderableFieldValueStddev_SampFields>;
  sum?: Maybe<LegacyOrderOrderableFieldValueSumFields>;
  varPop?: Maybe<LegacyOrderOrderableFieldValueVar_PopFields>;
  varSamp?: Maybe<LegacyOrderOrderableFieldValueVar_SampFields>;
  variance?: Maybe<LegacyOrderOrderableFieldValueVarianceFields>;
};


/** aggregate fields of "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValueAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LegacyOrderOrderableFieldValueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValueAggregateOrderBy = {
  avg?: InputMaybe<LegacyOrderOrderableFieldValue_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LegacyOrderOrderableFieldValue_Max_Order_By>;
  min?: InputMaybe<LegacyOrderOrderableFieldValue_Min_Order_By>;
  stddev?: InputMaybe<LegacyOrderOrderableFieldValue_Stddev_Order_By>;
  stddev_pop?: InputMaybe<LegacyOrderOrderableFieldValue_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<LegacyOrderOrderableFieldValue_Stddev_Samp_Order_By>;
  sum?: InputMaybe<LegacyOrderOrderableFieldValue_Sum_Order_By>;
  var_pop?: InputMaybe<LegacyOrderOrderableFieldValue_Var_Pop_Order_By>;
  var_samp?: InputMaybe<LegacyOrderOrderableFieldValue_Var_Samp_Order_By>;
  variance?: InputMaybe<LegacyOrderOrderableFieldValue_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValueArrRelInsertInput = {
  data: Array<LegacyOrderOrderableFieldValueInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<LegacyOrderOrderableFieldValueOnConflict>;
};

/** aggregate avg on columns */
export type LegacyOrderOrderableFieldValueAvgFields = {
  __typename?: 'legacyOrderOrderableFieldValueAvgFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderorderablefieldvalue". All fields are combined with a logical 'AND'. */
export type LegacyOrderOrderableFieldValueBoolExp = {
  _and?: InputMaybe<Array<LegacyOrderOrderableFieldValueBoolExp>>;
  _not?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
  _or?: InputMaybe<Array<LegacyOrderOrderableFieldValueBoolExp>>;
  field?: InputMaybe<OrderOrderableFieldBoolExp>;
  fieldId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  legacyOrder?: InputMaybe<OrderableOrderBoolExp>;
  orderId?: InputMaybe<IntComparisonExp>;
  show?: InputMaybe<BooleanComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_orderorderablefieldvalue" */
export enum LegacyOrderOrderableFieldValueConstraint {
  /** unique or primary key constraint on columns "order_id", "field_id" */
  ServerOrderorderablefieldvalueOrderIdFieldIdBf1f1b62Uniq = 'server_orderorderablefieldvalue_order_id_field_id_bf1f1b62_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerOrderorderablefieldvaluePkey = 'server_orderorderablefieldvalue_pkey'
}

/** input type for incrementing numeric columns in table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValueIncInput = {
  fieldId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValueInsertInput = {
  field?: InputMaybe<OrderOrderableFieldObjRelInsertInput>;
  fieldId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  legacyOrder?: InputMaybe<OrderableOrderObjRelInsertInput>;
  orderId?: InputMaybe<Scalars['Int']>;
  show?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LegacyOrderOrderableFieldValueMaxFields = {
  __typename?: 'legacyOrderOrderableFieldValueMaxFields';
  fieldId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LegacyOrderOrderableFieldValueMinFields = {
  __typename?: 'legacyOrderOrderableFieldValueMinFields';
  fieldId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValueMutationResponse = {
  __typename?: 'legacyOrderOrderableFieldValueMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LegacyOrderOrderableFieldValue>;
};

/** on_conflict condition type for table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValueOnConflict = {
  constraint: LegacyOrderOrderableFieldValueConstraint;
  update_columns?: Array<LegacyOrderOrderableFieldValueUpdateColumn>;
  where?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
};

/** Ordering options when selecting data from "server_orderorderablefieldvalue". */
export type LegacyOrderOrderableFieldValueOrderBy = {
  field?: InputMaybe<OrderOrderableFieldOrderBy>;
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legacyOrder?: InputMaybe<OrderableOrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
  show?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_orderorderablefieldvalue */
export type LegacyOrderOrderableFieldValuePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_orderorderablefieldvalue" */
export enum LegacyOrderOrderableFieldValueSelectColumn {
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Show = 'show',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValueSetInput = {
  fieldId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  show?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type LegacyOrderOrderableFieldValueStddevFields = {
  __typename?: 'legacyOrderOrderableFieldValueStddevFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LegacyOrderOrderableFieldValueStddev_PopFields = {
  __typename?: 'legacyOrderOrderableFieldValueStddev_popFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LegacyOrderOrderableFieldValueStddev_SampFields = {
  __typename?: 'legacyOrderOrderableFieldValueStddev_sampFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LegacyOrderOrderableFieldValueSumFields = {
  __typename?: 'legacyOrderOrderableFieldValueSumFields';
  fieldId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_orderorderablefieldvalue" */
export enum LegacyOrderOrderableFieldValueUpdateColumn {
  /** column name */
  FieldId = 'fieldId',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Show = 'show',
  /** column name */
  Value = 'value'
}

export type LegacyOrderOrderableFieldValueUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LegacyOrderOrderableFieldValueIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LegacyOrderOrderableFieldValueSetInput>;
  where: LegacyOrderOrderableFieldValueBoolExp;
};

/** aggregate var_pop on columns */
export type LegacyOrderOrderableFieldValueVar_PopFields = {
  __typename?: 'legacyOrderOrderableFieldValueVar_popFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LegacyOrderOrderableFieldValueVar_SampFields = {
  __typename?: 'legacyOrderOrderableFieldValueVar_sampFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LegacyOrderOrderableFieldValueVarianceFields = {
  __typename?: 'legacyOrderOrderableFieldValueVarianceFields';
  fieldId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValue_Avg_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValue_Max_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValue_Min_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValue_Stddev_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValue_Stddev_Pop_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValue_Stddev_Samp_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "legacyOrderOrderableFieldValue" */
export type LegacyOrderOrderableFieldValue_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LegacyOrderOrderableFieldValue_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LegacyOrderOrderableFieldValue_StreamCursorValueInput = {
  fieldId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  show?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValue_Sum_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValue_Var_Pop_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValue_Var_Samp_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_orderorderablefieldvalue" */
export type LegacyOrderOrderableFieldValue_Variance_Order_By = {
  fieldId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_order */
export type LegacyOrderPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LegacyOrderPrependInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "server_orderqualification" */
export type LegacyOrderQualification = {
  __typename?: 'legacyOrderQualification';
  id: Scalars['Int'];
  /** An object relationship */
  legacyOrder: LegacyOrder;
  orderId: Scalars['Int'];
  /** An object relationship */
  qualification: OrderableQualification;
  qualificationId: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "server_orderqualification" */
export type LegacyOrderQualificationAggregate = {
  __typename?: 'legacyOrderQualificationAggregate';
  aggregate?: Maybe<LegacyOrderQualificationAggregateFields>;
  nodes: Array<LegacyOrderQualification>;
};

/** aggregate fields of "server_orderqualification" */
export type LegacyOrderQualificationAggregateFields = {
  __typename?: 'legacyOrderQualificationAggregateFields';
  avg?: Maybe<LegacyOrderQualificationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LegacyOrderQualificationMaxFields>;
  min?: Maybe<LegacyOrderQualificationMinFields>;
  stddev?: Maybe<LegacyOrderQualificationStddevFields>;
  stddevPop?: Maybe<LegacyOrderQualificationStddev_PopFields>;
  stddevSamp?: Maybe<LegacyOrderQualificationStddev_SampFields>;
  sum?: Maybe<LegacyOrderQualificationSumFields>;
  varPop?: Maybe<LegacyOrderQualificationVar_PopFields>;
  varSamp?: Maybe<LegacyOrderQualificationVar_SampFields>;
  variance?: Maybe<LegacyOrderQualificationVarianceFields>;
};


/** aggregate fields of "server_orderqualification" */
export type LegacyOrderQualificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LegacyOrderQualificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_orderqualification" */
export type LegacyOrderQualificationAggregateOrderBy = {
  avg?: InputMaybe<LegacyOrderQualification_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LegacyOrderQualification_Max_Order_By>;
  min?: InputMaybe<LegacyOrderQualification_Min_Order_By>;
  stddev?: InputMaybe<LegacyOrderQualification_Stddev_Order_By>;
  stddev_pop?: InputMaybe<LegacyOrderQualification_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<LegacyOrderQualification_Stddev_Samp_Order_By>;
  sum?: InputMaybe<LegacyOrderQualification_Sum_Order_By>;
  var_pop?: InputMaybe<LegacyOrderQualification_Var_Pop_Order_By>;
  var_samp?: InputMaybe<LegacyOrderQualification_Var_Samp_Order_By>;
  variance?: InputMaybe<LegacyOrderQualification_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_orderqualification" */
export type LegacyOrderQualificationArrRelInsertInput = {
  data: Array<LegacyOrderQualificationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<LegacyOrderQualificationOnConflict>;
};

/** aggregate avg on columns */
export type LegacyOrderQualificationAvgFields = {
  __typename?: 'legacyOrderQualificationAvgFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderqualification". All fields are combined with a logical 'AND'. */
export type LegacyOrderQualificationBoolExp = {
  _and?: InputMaybe<Array<LegacyOrderQualificationBoolExp>>;
  _not?: InputMaybe<LegacyOrderQualificationBoolExp>;
  _or?: InputMaybe<Array<LegacyOrderQualificationBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  legacyOrder?: InputMaybe<LegacyOrderBoolExp>;
  orderId?: InputMaybe<IntComparisonExp>;
  qualification?: InputMaybe<OrderableQualificationBoolExp>;
  qualificationId?: InputMaybe<IntComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_orderqualification" */
export enum LegacyOrderQualificationConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerOrderqualificationPkey = 'server_orderqualification_pkey'
}

/** input type for incrementing numeric columns in table "server_orderqualification" */
export type LegacyOrderQualificationIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  qualificationId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_orderqualification" */
export type LegacyOrderQualificationInsertInput = {
  id?: InputMaybe<Scalars['Int']>;
  legacyOrder?: InputMaybe<LegacyOrderObjRelInsertInput>;
  orderId?: InputMaybe<Scalars['Int']>;
  qualification?: InputMaybe<OrderableQualificationObjRelInsertInput>;
  qualificationId?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LegacyOrderQualificationMaxFields = {
  __typename?: 'legacyOrderQualificationMaxFields';
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  qualificationId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LegacyOrderQualificationMinFields = {
  __typename?: 'legacyOrderQualificationMinFields';
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  qualificationId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_orderqualification" */
export type LegacyOrderQualificationMutationResponse = {
  __typename?: 'legacyOrderQualificationMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LegacyOrderQualification>;
};

/** on_conflict condition type for table "server_orderqualification" */
export type LegacyOrderQualificationOnConflict = {
  constraint: LegacyOrderQualificationConstraint;
  update_columns?: Array<LegacyOrderQualificationUpdateColumn>;
  where?: InputMaybe<LegacyOrderQualificationBoolExp>;
};

/** Ordering options when selecting data from "server_orderqualification". */
export type LegacyOrderQualificationOrderBy = {
  id?: InputMaybe<OrderBy>;
  legacyOrder?: InputMaybe<LegacyOrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualification?: InputMaybe<OrderableQualificationOrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_orderqualification */
export type LegacyOrderQualificationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_orderqualification" */
export enum LegacyOrderQualificationSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  QualificationId = 'qualificationId',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "server_orderqualification" */
export type LegacyOrderQualificationSetInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  qualificationId?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type LegacyOrderQualificationStddevFields = {
  __typename?: 'legacyOrderQualificationStddevFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LegacyOrderQualificationStddev_PopFields = {
  __typename?: 'legacyOrderQualificationStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LegacyOrderQualificationStddev_SampFields = {
  __typename?: 'legacyOrderQualificationStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LegacyOrderQualificationSumFields = {
  __typename?: 'legacyOrderQualificationSumFields';
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  qualificationId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_orderqualification" */
export enum LegacyOrderQualificationUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  QualificationId = 'qualificationId',
  /** column name */
  Value = 'value'
}

export type LegacyOrderQualificationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LegacyOrderQualificationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LegacyOrderQualificationSetInput>;
  where: LegacyOrderQualificationBoolExp;
};

/** aggregate var_pop on columns */
export type LegacyOrderQualificationVar_PopFields = {
  __typename?: 'legacyOrderQualificationVar_popFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LegacyOrderQualificationVar_SampFields = {
  __typename?: 'legacyOrderQualificationVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LegacyOrderQualificationVarianceFields = {
  __typename?: 'legacyOrderQualificationVarianceFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_orderqualification" */
export type LegacyOrderQualification_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_orderqualification" */
export type LegacyOrderQualification_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_orderqualification" */
export type LegacyOrderQualification_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_orderqualification" */
export type LegacyOrderQualification_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_orderqualification" */
export type LegacyOrderQualification_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_orderqualification" */
export type LegacyOrderQualification_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "legacyOrderQualification" */
export type LegacyOrderQualification_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LegacyOrderQualification_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LegacyOrderQualification_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  qualificationId?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_orderqualification" */
export type LegacyOrderQualification_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_orderqualification" */
export type LegacyOrderQualification_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_orderqualification" */
export type LegacyOrderQualification_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_orderqualification" */
export type LegacyOrderQualification_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** select columns of table "server_order" */
export enum LegacyOrderSelectColumn {
  /** column name */
  ApprovalAppointmentId = 'approvalAppointmentId',
  /** column name */
  ApprovalProviderId = 'approvalProviderId',
  /** column name */
  ApprovalSupervisorId = 'approvalSupervisorId',
  /** column name */
  ApprovalSupervisorUserId = 'approvalSupervisorUserId',
  /** column name */
  Approved = 'approved',
  /** column name */
  Data = 'data',
  /** column name */
  DateEffective = 'dateEffective',
  /** column name */
  DateExpires = 'dateExpires',
  /** column name */
  DateFinalized = 'dateFinalized',
  /** column name */
  DateRequested = 'dateRequested',
  /** column name */
  DestinationId = 'destinationId',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  FinalizedUserId = 'finalizedUserId',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderStatus = 'orderStatus',
  /** column name */
  OrderText = 'orderText',
  /** column name */
  OrderType = 'orderType',
  /** column name */
  OrderableId = 'orderableId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  RequestUserId = 'requestUserId',
  /** column name */
  SupervisorApprovalDate = 'supervisorApprovalDate',
  /** column name */
  TicketId = 'ticketId'
}

/** input type for updating data in table "server_order" */
export type LegacyOrderSetInput = {
  approvalAppointmentId?: InputMaybe<Scalars['Int']>;
  approvalProviderId?: InputMaybe<Scalars['Int']>;
  approvalSupervisorId?: InputMaybe<Scalars['Int']>;
  approvalSupervisorUserId?: InputMaybe<Scalars['Int']>;
  approved?: InputMaybe<Scalars['Boolean']>;
  data?: InputMaybe<Scalars['jsonb']>;
  dateEffective?: InputMaybe<Scalars['timestamptz']>;
  dateExpires?: InputMaybe<Scalars['timestamptz']>;
  dateFinalized?: InputMaybe<Scalars['timestamptz']>;
  dateRequested?: InputMaybe<Scalars['timestamptz']>;
  destinationId?: InputMaybe<Scalars['Int']>;
  discipline?: InputMaybe<Scalars['smallint']>;
  finalizedUserId?: InputMaybe<Scalars['Int']>;
  html?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  orderStatus?: InputMaybe<Scalars['smallint']>;
  orderText?: InputMaybe<Scalars['String']>;
  orderType?: InputMaybe<Scalars['smallint']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  requestUserId?: InputMaybe<Scalars['Int']>;
  supervisorApprovalDate?: InputMaybe<Scalars['timestamptz']>;
  ticketId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LegacyOrderStddevFields = {
  __typename?: 'legacyOrderStddevFields';
  approvalAppointmentId?: Maybe<Scalars['Float']>;
  approvalProviderId?: Maybe<Scalars['Float']>;
  approvalSupervisorId?: Maybe<Scalars['Float']>;
  approvalSupervisorUserId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  discipline?: Maybe<Scalars['Float']>;
  finalizedUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  orderType?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  requestUserId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LegacyOrderStddev_PopFields = {
  __typename?: 'legacyOrderStddev_popFields';
  approvalAppointmentId?: Maybe<Scalars['Float']>;
  approvalProviderId?: Maybe<Scalars['Float']>;
  approvalSupervisorId?: Maybe<Scalars['Float']>;
  approvalSupervisorUserId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  discipline?: Maybe<Scalars['Float']>;
  finalizedUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  orderType?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  requestUserId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LegacyOrderStddev_SampFields = {
  __typename?: 'legacyOrderStddev_sampFields';
  approvalAppointmentId?: Maybe<Scalars['Float']>;
  approvalProviderId?: Maybe<Scalars['Float']>;
  approvalSupervisorId?: Maybe<Scalars['Float']>;
  approvalSupervisorUserId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  discipline?: Maybe<Scalars['Float']>;
  finalizedUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  orderType?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  requestUserId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LegacyOrderSumFields = {
  __typename?: 'legacyOrderSumFields';
  approvalAppointmentId?: Maybe<Scalars['Int']>;
  approvalProviderId?: Maybe<Scalars['Int']>;
  approvalSupervisorId?: Maybe<Scalars['Int']>;
  approvalSupervisorUserId?: Maybe<Scalars['Int']>;
  destinationId?: Maybe<Scalars['Int']>;
  discipline?: Maybe<Scalars['smallint']>;
  finalizedUserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderStatus?: Maybe<Scalars['smallint']>;
  orderType?: Maybe<Scalars['smallint']>;
  orderableId?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  requestUserId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_order" */
export enum LegacyOrderUpdateColumn {
  /** column name */
  ApprovalAppointmentId = 'approvalAppointmentId',
  /** column name */
  ApprovalProviderId = 'approvalProviderId',
  /** column name */
  ApprovalSupervisorId = 'approvalSupervisorId',
  /** column name */
  ApprovalSupervisorUserId = 'approvalSupervisorUserId',
  /** column name */
  Approved = 'approved',
  /** column name */
  Data = 'data',
  /** column name */
  DateEffective = 'dateEffective',
  /** column name */
  DateExpires = 'dateExpires',
  /** column name */
  DateFinalized = 'dateFinalized',
  /** column name */
  DateRequested = 'dateRequested',
  /** column name */
  DestinationId = 'destinationId',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  FinalizedUserId = 'finalizedUserId',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderStatus = 'orderStatus',
  /** column name */
  OrderText = 'orderText',
  /** column name */
  OrderType = 'orderType',
  /** column name */
  OrderableId = 'orderableId',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  RequestUserId = 'requestUserId',
  /** column name */
  SupervisorApprovalDate = 'supervisorApprovalDate',
  /** column name */
  TicketId = 'ticketId'
}

export type LegacyOrderUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LegacyOrderAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<LegacyOrderDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<LegacyOrderDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<LegacyOrderDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LegacyOrderIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LegacyOrderPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LegacyOrderSetInput>;
  where: LegacyOrderBoolExp;
};

/** aggregate var_pop on columns */
export type LegacyOrderVar_PopFields = {
  __typename?: 'legacyOrderVar_popFields';
  approvalAppointmentId?: Maybe<Scalars['Float']>;
  approvalProviderId?: Maybe<Scalars['Float']>;
  approvalSupervisorId?: Maybe<Scalars['Float']>;
  approvalSupervisorUserId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  discipline?: Maybe<Scalars['Float']>;
  finalizedUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  orderType?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  requestUserId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LegacyOrderVar_SampFields = {
  __typename?: 'legacyOrderVar_sampFields';
  approvalAppointmentId?: Maybe<Scalars['Float']>;
  approvalProviderId?: Maybe<Scalars['Float']>;
  approvalSupervisorId?: Maybe<Scalars['Float']>;
  approvalSupervisorUserId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  discipline?: Maybe<Scalars['Float']>;
  finalizedUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  orderType?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  requestUserId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LegacyOrderVarianceFields = {
  __typename?: 'legacyOrderVarianceFields';
  approvalAppointmentId?: Maybe<Scalars['Float']>;
  approvalProviderId?: Maybe<Scalars['Float']>;
  approvalSupervisorId?: Maybe<Scalars['Float']>;
  approvalSupervisorUserId?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  discipline?: Maybe<Scalars['Float']>;
  finalizedUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderStatus?: Maybe<Scalars['Float']>;
  orderType?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  requestUserId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_order" */
export type LegacyOrder_Avg_Order_By = {
  approvalAppointmentId?: InputMaybe<OrderBy>;
  approvalProviderId?: InputMaybe<OrderBy>;
  approvalSupervisorId?: InputMaybe<OrderBy>;
  approvalSupervisorUserId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  discipline?: InputMaybe<OrderBy>;
  finalizedUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  orderType?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  requestUserId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_order" */
export type LegacyOrder_Max_Order_By = {
  approvalAppointmentId?: InputMaybe<OrderBy>;
  approvalProviderId?: InputMaybe<OrderBy>;
  approvalSupervisorId?: InputMaybe<OrderBy>;
  approvalSupervisorUserId?: InputMaybe<OrderBy>;
  dateEffective?: InputMaybe<OrderBy>;
  dateExpires?: InputMaybe<OrderBy>;
  dateFinalized?: InputMaybe<OrderBy>;
  dateRequested?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  discipline?: InputMaybe<OrderBy>;
  finalizedUserId?: InputMaybe<OrderBy>;
  html?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  orderText?: InputMaybe<OrderBy>;
  orderType?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  requestUserId?: InputMaybe<OrderBy>;
  supervisorApprovalDate?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_order" */
export type LegacyOrder_Min_Order_By = {
  approvalAppointmentId?: InputMaybe<OrderBy>;
  approvalProviderId?: InputMaybe<OrderBy>;
  approvalSupervisorId?: InputMaybe<OrderBy>;
  approvalSupervisorUserId?: InputMaybe<OrderBy>;
  dateEffective?: InputMaybe<OrderBy>;
  dateExpires?: InputMaybe<OrderBy>;
  dateFinalized?: InputMaybe<OrderBy>;
  dateRequested?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  discipline?: InputMaybe<OrderBy>;
  finalizedUserId?: InputMaybe<OrderBy>;
  html?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  orderText?: InputMaybe<OrderBy>;
  orderType?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  requestUserId?: InputMaybe<OrderBy>;
  supervisorApprovalDate?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_order" */
export type LegacyOrder_Stddev_Order_By = {
  approvalAppointmentId?: InputMaybe<OrderBy>;
  approvalProviderId?: InputMaybe<OrderBy>;
  approvalSupervisorId?: InputMaybe<OrderBy>;
  approvalSupervisorUserId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  discipline?: InputMaybe<OrderBy>;
  finalizedUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  orderType?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  requestUserId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_order" */
export type LegacyOrder_Stddev_Pop_Order_By = {
  approvalAppointmentId?: InputMaybe<OrderBy>;
  approvalProviderId?: InputMaybe<OrderBy>;
  approvalSupervisorId?: InputMaybe<OrderBy>;
  approvalSupervisorUserId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  discipline?: InputMaybe<OrderBy>;
  finalizedUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  orderType?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  requestUserId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_order" */
export type LegacyOrder_Stddev_Samp_Order_By = {
  approvalAppointmentId?: InputMaybe<OrderBy>;
  approvalProviderId?: InputMaybe<OrderBy>;
  approvalSupervisorId?: InputMaybe<OrderBy>;
  approvalSupervisorUserId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  discipline?: InputMaybe<OrderBy>;
  finalizedUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  orderType?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  requestUserId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "legacyOrder" */
export type LegacyOrder_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LegacyOrder_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LegacyOrder_StreamCursorValueInput = {
  approvalAppointmentId?: InputMaybe<Scalars['Int']>;
  approvalProviderId?: InputMaybe<Scalars['Int']>;
  approvalSupervisorId?: InputMaybe<Scalars['Int']>;
  approvalSupervisorUserId?: InputMaybe<Scalars['Int']>;
  approved?: InputMaybe<Scalars['Boolean']>;
  data?: InputMaybe<Scalars['jsonb']>;
  dateEffective?: InputMaybe<Scalars['timestamptz']>;
  dateExpires?: InputMaybe<Scalars['timestamptz']>;
  dateFinalized?: InputMaybe<Scalars['timestamptz']>;
  dateRequested?: InputMaybe<Scalars['timestamptz']>;
  destinationId?: InputMaybe<Scalars['Int']>;
  discipline?: InputMaybe<Scalars['smallint']>;
  finalizedUserId?: InputMaybe<Scalars['Int']>;
  html?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  orderStatus?: InputMaybe<Scalars['smallint']>;
  orderText?: InputMaybe<Scalars['String']>;
  orderType?: InputMaybe<Scalars['smallint']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  requestUserId?: InputMaybe<Scalars['Int']>;
  supervisorApprovalDate?: InputMaybe<Scalars['timestamptz']>;
  ticketId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_order" */
export type LegacyOrder_Sum_Order_By = {
  approvalAppointmentId?: InputMaybe<OrderBy>;
  approvalProviderId?: InputMaybe<OrderBy>;
  approvalSupervisorId?: InputMaybe<OrderBy>;
  approvalSupervisorUserId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  discipline?: InputMaybe<OrderBy>;
  finalizedUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  orderType?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  requestUserId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_order" */
export type LegacyOrder_Var_Pop_Order_By = {
  approvalAppointmentId?: InputMaybe<OrderBy>;
  approvalProviderId?: InputMaybe<OrderBy>;
  approvalSupervisorId?: InputMaybe<OrderBy>;
  approvalSupervisorUserId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  discipline?: InputMaybe<OrderBy>;
  finalizedUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  orderType?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  requestUserId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_order" */
export type LegacyOrder_Var_Samp_Order_By = {
  approvalAppointmentId?: InputMaybe<OrderBy>;
  approvalProviderId?: InputMaybe<OrderBy>;
  approvalSupervisorId?: InputMaybe<OrderBy>;
  approvalSupervisorUserId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  discipline?: InputMaybe<OrderBy>;
  finalizedUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  orderType?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  requestUserId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_order" */
export type LegacyOrder_Variance_Order_By = {
  approvalAppointmentId?: InputMaybe<OrderBy>;
  approvalProviderId?: InputMaybe<OrderBy>;
  approvalSupervisorId?: InputMaybe<OrderBy>;
  approvalSupervisorUserId?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  discipline?: InputMaybe<OrderBy>;
  finalizedUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderStatus?: InputMaybe<OrderBy>;
  orderType?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  requestUserId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_treatmentplanorder" */
export type LegacyTreatmentplanOrder = {
  __typename?: 'legacyTreatmentplanOrder';
  id: Scalars['Int'];
  /** An object relationship */
  legacyOrder: LegacyOrder;
  orderId: Scalars['Int'];
  treatmentPlanId: Scalars['Int'];
  /** An object relationship */
  treatmentplan: Treatmentplan;
};

/** aggregated selection of "server_treatmentplanorder" */
export type LegacyTreatmentplanOrderAggregate = {
  __typename?: 'legacyTreatmentplanOrderAggregate';
  aggregate?: Maybe<LegacyTreatmentplanOrderAggregateFields>;
  nodes: Array<LegacyTreatmentplanOrder>;
};

/** aggregate fields of "server_treatmentplanorder" */
export type LegacyTreatmentplanOrderAggregateFields = {
  __typename?: 'legacyTreatmentplanOrderAggregateFields';
  avg?: Maybe<LegacyTreatmentplanOrderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LegacyTreatmentplanOrderMaxFields>;
  min?: Maybe<LegacyTreatmentplanOrderMinFields>;
  stddev?: Maybe<LegacyTreatmentplanOrderStddevFields>;
  stddevPop?: Maybe<LegacyTreatmentplanOrderStddev_PopFields>;
  stddevSamp?: Maybe<LegacyTreatmentplanOrderStddev_SampFields>;
  sum?: Maybe<LegacyTreatmentplanOrderSumFields>;
  varPop?: Maybe<LegacyTreatmentplanOrderVar_PopFields>;
  varSamp?: Maybe<LegacyTreatmentplanOrderVar_SampFields>;
  variance?: Maybe<LegacyTreatmentplanOrderVarianceFields>;
};


/** aggregate fields of "server_treatmentplanorder" */
export type LegacyTreatmentplanOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LegacyTreatmentplanOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrderAggregateOrderBy = {
  avg?: InputMaybe<LegacyTreatmentplanOrder_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LegacyTreatmentplanOrder_Max_Order_By>;
  min?: InputMaybe<LegacyTreatmentplanOrder_Min_Order_By>;
  stddev?: InputMaybe<LegacyTreatmentplanOrder_Stddev_Order_By>;
  stddev_pop?: InputMaybe<LegacyTreatmentplanOrder_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<LegacyTreatmentplanOrder_Stddev_Samp_Order_By>;
  sum?: InputMaybe<LegacyTreatmentplanOrder_Sum_Order_By>;
  var_pop?: InputMaybe<LegacyTreatmentplanOrder_Var_Pop_Order_By>;
  var_samp?: InputMaybe<LegacyTreatmentplanOrder_Var_Samp_Order_By>;
  variance?: InputMaybe<LegacyTreatmentplanOrder_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrderArrRelInsertInput = {
  data: Array<LegacyTreatmentplanOrderInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<LegacyTreatmentplanOrderOnConflict>;
};

/** aggregate avg on columns */
export type LegacyTreatmentplanOrderAvgFields = {
  __typename?: 'legacyTreatmentplanOrderAvgFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_treatmentplanorder". All fields are combined with a logical 'AND'. */
export type LegacyTreatmentplanOrderBoolExp = {
  _and?: InputMaybe<Array<LegacyTreatmentplanOrderBoolExp>>;
  _not?: InputMaybe<LegacyTreatmentplanOrderBoolExp>;
  _or?: InputMaybe<Array<LegacyTreatmentplanOrderBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  legacyOrder?: InputMaybe<LegacyOrderBoolExp>;
  orderId?: InputMaybe<IntComparisonExp>;
  treatmentPlanId?: InputMaybe<IntComparisonExp>;
  treatmentplan?: InputMaybe<TreatmentplanBoolExp>;
};

/** unique or primary key constraints on table "server_treatmentplanorder" */
export enum LegacyTreatmentplanOrderConstraint {
  /** unique or primary key constraint on columns "order_id" */
  ServerTreatmentplanorderOrderIdKey = 'server_treatmentplanorder_order_id_key',
  /** unique or primary key constraint on columns "id" */
  ServerTreatmentplanorderPkey = 'server_treatmentplanorder_pkey'
}

/** input type for incrementing numeric columns in table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrderIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrderInsertInput = {
  id?: InputMaybe<Scalars['Int']>;
  legacyOrder?: InputMaybe<LegacyOrderObjRelInsertInput>;
  orderId?: InputMaybe<Scalars['Int']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
  treatmentplan?: InputMaybe<TreatmentplanObjRelInsertInput>;
};

/** aggregate max on columns */
export type LegacyTreatmentplanOrderMaxFields = {
  __typename?: 'legacyTreatmentplanOrderMaxFields';
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type LegacyTreatmentplanOrderMinFields = {
  __typename?: 'legacyTreatmentplanOrderMinFields';
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrderMutationResponse = {
  __typename?: 'legacyTreatmentplanOrderMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LegacyTreatmentplanOrder>;
};

/** input type for inserting object relation for remote table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrderObjRelInsertInput = {
  data: LegacyTreatmentplanOrderInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<LegacyTreatmentplanOrderOnConflict>;
};

/** on_conflict condition type for table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrderOnConflict = {
  constraint: LegacyTreatmentplanOrderConstraint;
  update_columns?: Array<LegacyTreatmentplanOrderUpdateColumn>;
  where?: InputMaybe<LegacyTreatmentplanOrderBoolExp>;
};

/** Ordering options when selecting data from "server_treatmentplanorder". */
export type LegacyTreatmentplanOrderOrderBy = {
  id?: InputMaybe<OrderBy>;
  legacyOrder?: InputMaybe<LegacyOrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  treatmentplan?: InputMaybe<TreatmentplanOrderBy>;
};

/** primary key columns input for table: server_treatmentplanorder */
export type LegacyTreatmentplanOrderPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_treatmentplanorder" */
export enum LegacyTreatmentplanOrderSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  TreatmentPlanId = 'treatmentPlanId'
}

/** input type for updating data in table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrderSetInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type LegacyTreatmentplanOrderStddevFields = {
  __typename?: 'legacyTreatmentplanOrderStddevFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LegacyTreatmentplanOrderStddev_PopFields = {
  __typename?: 'legacyTreatmentplanOrderStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LegacyTreatmentplanOrderStddev_SampFields = {
  __typename?: 'legacyTreatmentplanOrderStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LegacyTreatmentplanOrderSumFields = {
  __typename?: 'legacyTreatmentplanOrderSumFields';
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_treatmentplanorder" */
export enum LegacyTreatmentplanOrderUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  TreatmentPlanId = 'treatmentPlanId'
}

export type LegacyTreatmentplanOrderUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LegacyTreatmentplanOrderIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LegacyTreatmentplanOrderSetInput>;
  where: LegacyTreatmentplanOrderBoolExp;
};

/** aggregate var_pop on columns */
export type LegacyTreatmentplanOrderVar_PopFields = {
  __typename?: 'legacyTreatmentplanOrderVar_popFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LegacyTreatmentplanOrderVar_SampFields = {
  __typename?: 'legacyTreatmentplanOrderVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LegacyTreatmentplanOrderVarianceFields = {
  __typename?: 'legacyTreatmentplanOrderVarianceFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrder_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrder_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrder_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrder_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrder_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrder_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "legacyTreatmentplanOrder" */
export type LegacyTreatmentplanOrder_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LegacyTreatmentplanOrder_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LegacyTreatmentplanOrder_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrder_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrder_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrder_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_treatmentplanorder" */
export type LegacyTreatmentplanOrder_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_lineitem" */
export type Lineitem = {
  __typename?: 'lineitem';
  balanceIns?: Maybe<Scalars['numeric']>;
  balancePt?: Maybe<Scalars['numeric']>;
  billed?: Maybe<Scalars['numeric']>;
  billingStatus?: Maybe<Scalars['String']>;
  /** An object relationship */
  claim: Claim;
  claimId: Scalars['Int'];
  deniedFlag: Scalars['Boolean'];
  externalId?: Maybe<Scalars['Int']>;
  externalJson?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  hcpcs: Hcpcs;
  hcpcsId: Scalars['Int'];
  id: Scalars['Int'];
  ins1Paid?: Maybe<Scalars['numeric']>;
  ins2Paid?: Maybe<Scalars['numeric']>;
  ins3Paid?: Maybe<Scalars['numeric']>;
  insuranceStatus?: Maybe<Scalars['String']>;
  modifiers?: Maybe<Scalars['_varchar']>;
  patientPaid?: Maybe<Scalars['numeric']>;
  postedDate?: Maybe<Scalars['date']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "server_lineitem" */
export type LineitemExternalJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "server_lineitem" */
export type LineitemAggregate = {
  __typename?: 'lineitemAggregate';
  aggregate?: Maybe<LineitemAggregateFields>;
  nodes: Array<Lineitem>;
};

/** aggregate fields of "server_lineitem" */
export type LineitemAggregateFields = {
  __typename?: 'lineitemAggregateFields';
  avg?: Maybe<LineitemAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LineitemMaxFields>;
  min?: Maybe<LineitemMinFields>;
  stddev?: Maybe<LineitemStddevFields>;
  stddevPop?: Maybe<LineitemStddev_PopFields>;
  stddevSamp?: Maybe<LineitemStddev_SampFields>;
  sum?: Maybe<LineitemSumFields>;
  varPop?: Maybe<LineitemVar_PopFields>;
  varSamp?: Maybe<LineitemVar_SampFields>;
  variance?: Maybe<LineitemVarianceFields>;
};


/** aggregate fields of "server_lineitem" */
export type LineitemAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LineitemSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_lineitem" */
export type LineitemAggregateOrderBy = {
  avg?: InputMaybe<Lineitem_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Lineitem_Max_Order_By>;
  min?: InputMaybe<Lineitem_Min_Order_By>;
  stddev?: InputMaybe<Lineitem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lineitem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lineitem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lineitem_Sum_Order_By>;
  var_pop?: InputMaybe<Lineitem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lineitem_Var_Samp_Order_By>;
  variance?: InputMaybe<Lineitem_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LineitemAppendInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_lineitem" */
export type LineitemArrRelInsertInput = {
  data: Array<LineitemInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<LineitemOnConflict>;
};

/** aggregate avg on columns */
export type LineitemAvgFields = {
  __typename?: 'lineitemAvgFields';
  balanceIns?: Maybe<Scalars['Float']>;
  balancePt?: Maybe<Scalars['Float']>;
  billed?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ins1Paid?: Maybe<Scalars['Float']>;
  ins2Paid?: Maybe<Scalars['Float']>;
  ins3Paid?: Maybe<Scalars['Float']>;
  patientPaid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_lineitem". All fields are combined with a logical 'AND'. */
export type LineitemBoolExp = {
  _and?: InputMaybe<Array<LineitemBoolExp>>;
  _not?: InputMaybe<LineitemBoolExp>;
  _or?: InputMaybe<Array<LineitemBoolExp>>;
  balanceIns?: InputMaybe<NumericComparisonExp>;
  balancePt?: InputMaybe<NumericComparisonExp>;
  billed?: InputMaybe<NumericComparisonExp>;
  billingStatus?: InputMaybe<StringComparisonExp>;
  claim?: InputMaybe<ClaimBoolExp>;
  claimId?: InputMaybe<IntComparisonExp>;
  deniedFlag?: InputMaybe<BooleanComparisonExp>;
  externalId?: InputMaybe<IntComparisonExp>;
  externalJson?: InputMaybe<JsonbComparisonExp>;
  hcpcs?: InputMaybe<HcpcsBoolExp>;
  hcpcsId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  ins1Paid?: InputMaybe<NumericComparisonExp>;
  ins2Paid?: InputMaybe<NumericComparisonExp>;
  ins3Paid?: InputMaybe<NumericComparisonExp>;
  insuranceStatus?: InputMaybe<StringComparisonExp>;
  modifiers?: InputMaybe<_VarcharComparisonExp>;
  patientPaid?: InputMaybe<NumericComparisonExp>;
  postedDate?: InputMaybe<DateComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "server_lineitem" */
export enum LineitemConstraint {
  /** unique or primary key constraint on columns "external_id" */
  ServerLineitemExternalIdKey = 'server_lineitem_external_id_key',
  /** unique or primary key constraint on columns "id" */
  ServerLineitemPkey = 'server_lineitem_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LineitemDeleteAtPathInput = {
  externalJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LineitemDeleteElemInput = {
  externalJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LineitemDeleteKeyInput = {
  externalJson?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_lineitem" */
export type LineitemIncInput = {
  balanceIns?: InputMaybe<Scalars['numeric']>;
  balancePt?: InputMaybe<Scalars['numeric']>;
  billed?: InputMaybe<Scalars['numeric']>;
  claimId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['Int']>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ins1Paid?: InputMaybe<Scalars['numeric']>;
  ins2Paid?: InputMaybe<Scalars['numeric']>;
  ins3Paid?: InputMaybe<Scalars['numeric']>;
  patientPaid?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "server_lineitem" */
export type LineitemInsertInput = {
  balanceIns?: InputMaybe<Scalars['numeric']>;
  balancePt?: InputMaybe<Scalars['numeric']>;
  billed?: InputMaybe<Scalars['numeric']>;
  billingStatus?: InputMaybe<Scalars['String']>;
  claim?: InputMaybe<ClaimObjRelInsertInput>;
  claimId?: InputMaybe<Scalars['Int']>;
  deniedFlag?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  hcpcs?: InputMaybe<HcpcsObjRelInsertInput>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ins1Paid?: InputMaybe<Scalars['numeric']>;
  ins2Paid?: InputMaybe<Scalars['numeric']>;
  ins3Paid?: InputMaybe<Scalars['numeric']>;
  insuranceStatus?: InputMaybe<Scalars['String']>;
  modifiers?: InputMaybe<Scalars['_varchar']>;
  patientPaid?: InputMaybe<Scalars['numeric']>;
  postedDate?: InputMaybe<Scalars['date']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type LineitemMaxFields = {
  __typename?: 'lineitemMaxFields';
  balanceIns?: Maybe<Scalars['numeric']>;
  balancePt?: Maybe<Scalars['numeric']>;
  billed?: Maybe<Scalars['numeric']>;
  billingStatus?: Maybe<Scalars['String']>;
  claimId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  hcpcsId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ins1Paid?: Maybe<Scalars['numeric']>;
  ins2Paid?: Maybe<Scalars['numeric']>;
  ins3Paid?: Maybe<Scalars['numeric']>;
  insuranceStatus?: Maybe<Scalars['String']>;
  patientPaid?: Maybe<Scalars['numeric']>;
  postedDate?: Maybe<Scalars['date']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type LineitemMinFields = {
  __typename?: 'lineitemMinFields';
  balanceIns?: Maybe<Scalars['numeric']>;
  balancePt?: Maybe<Scalars['numeric']>;
  billed?: Maybe<Scalars['numeric']>;
  billingStatus?: Maybe<Scalars['String']>;
  claimId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  hcpcsId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ins1Paid?: Maybe<Scalars['numeric']>;
  ins2Paid?: Maybe<Scalars['numeric']>;
  ins3Paid?: Maybe<Scalars['numeric']>;
  insuranceStatus?: Maybe<Scalars['String']>;
  patientPaid?: Maybe<Scalars['numeric']>;
  postedDate?: Maybe<Scalars['date']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "server_lineitem" */
export type LineitemMutationResponse = {
  __typename?: 'lineitemMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lineitem>;
};

/** on_conflict condition type for table "server_lineitem" */
export type LineitemOnConflict = {
  constraint: LineitemConstraint;
  update_columns?: Array<LineitemUpdateColumn>;
  where?: InputMaybe<LineitemBoolExp>;
};

/** Ordering options when selecting data from "server_lineitem". */
export type LineitemOrderBy = {
  balanceIns?: InputMaybe<OrderBy>;
  balancePt?: InputMaybe<OrderBy>;
  billed?: InputMaybe<OrderBy>;
  billingStatus?: InputMaybe<OrderBy>;
  claim?: InputMaybe<ClaimOrderBy>;
  claimId?: InputMaybe<OrderBy>;
  deniedFlag?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  externalJson?: InputMaybe<OrderBy>;
  hcpcs?: InputMaybe<HcpcsOrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ins1Paid?: InputMaybe<OrderBy>;
  ins2Paid?: InputMaybe<OrderBy>;
  ins3Paid?: InputMaybe<OrderBy>;
  insuranceStatus?: InputMaybe<OrderBy>;
  modifiers?: InputMaybe<OrderBy>;
  patientPaid?: InputMaybe<OrderBy>;
  postedDate?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_lineitem */
export type LineitemPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LineitemPrependInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_lineitem" */
export enum LineitemSelectColumn {
  /** column name */
  BalanceIns = 'balanceIns',
  /** column name */
  BalancePt = 'balancePt',
  /** column name */
  Billed = 'billed',
  /** column name */
  BillingStatus = 'billingStatus',
  /** column name */
  ClaimId = 'claimId',
  /** column name */
  DeniedFlag = 'deniedFlag',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  HcpcsId = 'hcpcsId',
  /** column name */
  Id = 'id',
  /** column name */
  Ins1Paid = 'ins1Paid',
  /** column name */
  Ins2Paid = 'ins2Paid',
  /** column name */
  Ins3Paid = 'ins3Paid',
  /** column name */
  InsuranceStatus = 'insuranceStatus',
  /** column name */
  Modifiers = 'modifiers',
  /** column name */
  PatientPaid = 'patientPaid',
  /** column name */
  PostedDate = 'postedDate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "server_lineitem" */
export type LineitemSetInput = {
  balanceIns?: InputMaybe<Scalars['numeric']>;
  balancePt?: InputMaybe<Scalars['numeric']>;
  billed?: InputMaybe<Scalars['numeric']>;
  billingStatus?: InputMaybe<Scalars['String']>;
  claimId?: InputMaybe<Scalars['Int']>;
  deniedFlag?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ins1Paid?: InputMaybe<Scalars['numeric']>;
  ins2Paid?: InputMaybe<Scalars['numeric']>;
  ins3Paid?: InputMaybe<Scalars['numeric']>;
  insuranceStatus?: InputMaybe<Scalars['String']>;
  modifiers?: InputMaybe<Scalars['_varchar']>;
  patientPaid?: InputMaybe<Scalars['numeric']>;
  postedDate?: InputMaybe<Scalars['date']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type LineitemStddevFields = {
  __typename?: 'lineitemStddevFields';
  balanceIns?: Maybe<Scalars['Float']>;
  balancePt?: Maybe<Scalars['Float']>;
  billed?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ins1Paid?: Maybe<Scalars['Float']>;
  ins2Paid?: Maybe<Scalars['Float']>;
  ins3Paid?: Maybe<Scalars['Float']>;
  patientPaid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LineitemStddev_PopFields = {
  __typename?: 'lineitemStddev_popFields';
  balanceIns?: Maybe<Scalars['Float']>;
  balancePt?: Maybe<Scalars['Float']>;
  billed?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ins1Paid?: Maybe<Scalars['Float']>;
  ins2Paid?: Maybe<Scalars['Float']>;
  ins3Paid?: Maybe<Scalars['Float']>;
  patientPaid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LineitemStddev_SampFields = {
  __typename?: 'lineitemStddev_sampFields';
  balanceIns?: Maybe<Scalars['Float']>;
  balancePt?: Maybe<Scalars['Float']>;
  billed?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ins1Paid?: Maybe<Scalars['Float']>;
  ins2Paid?: Maybe<Scalars['Float']>;
  ins3Paid?: Maybe<Scalars['Float']>;
  patientPaid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type LineitemSumFields = {
  __typename?: 'lineitemSumFields';
  balanceIns?: Maybe<Scalars['numeric']>;
  balancePt?: Maybe<Scalars['numeric']>;
  billed?: Maybe<Scalars['numeric']>;
  claimId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  hcpcsId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ins1Paid?: Maybe<Scalars['numeric']>;
  ins2Paid?: Maybe<Scalars['numeric']>;
  ins3Paid?: Maybe<Scalars['numeric']>;
  patientPaid?: Maybe<Scalars['numeric']>;
};

/** update columns of table "server_lineitem" */
export enum LineitemUpdateColumn {
  /** column name */
  BalanceIns = 'balanceIns',
  /** column name */
  BalancePt = 'balancePt',
  /** column name */
  Billed = 'billed',
  /** column name */
  BillingStatus = 'billingStatus',
  /** column name */
  ClaimId = 'claimId',
  /** column name */
  DeniedFlag = 'deniedFlag',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  HcpcsId = 'hcpcsId',
  /** column name */
  Id = 'id',
  /** column name */
  Ins1Paid = 'ins1Paid',
  /** column name */
  Ins2Paid = 'ins2Paid',
  /** column name */
  Ins3Paid = 'ins3Paid',
  /** column name */
  InsuranceStatus = 'insuranceStatus',
  /** column name */
  Modifiers = 'modifiers',
  /** column name */
  PatientPaid = 'patientPaid',
  /** column name */
  PostedDate = 'postedDate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type LineitemUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LineitemAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<LineitemDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<LineitemDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<LineitemDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LineitemIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LineitemPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LineitemSetInput>;
  where: LineitemBoolExp;
};

/** aggregate var_pop on columns */
export type LineitemVar_PopFields = {
  __typename?: 'lineitemVar_popFields';
  balanceIns?: Maybe<Scalars['Float']>;
  balancePt?: Maybe<Scalars['Float']>;
  billed?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ins1Paid?: Maybe<Scalars['Float']>;
  ins2Paid?: Maybe<Scalars['Float']>;
  ins3Paid?: Maybe<Scalars['Float']>;
  patientPaid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LineitemVar_SampFields = {
  __typename?: 'lineitemVar_sampFields';
  balanceIns?: Maybe<Scalars['Float']>;
  balancePt?: Maybe<Scalars['Float']>;
  billed?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ins1Paid?: Maybe<Scalars['Float']>;
  ins2Paid?: Maybe<Scalars['Float']>;
  ins3Paid?: Maybe<Scalars['Float']>;
  patientPaid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LineitemVarianceFields = {
  __typename?: 'lineitemVarianceFields';
  balanceIns?: Maybe<Scalars['Float']>;
  balancePt?: Maybe<Scalars['Float']>;
  billed?: Maybe<Scalars['Float']>;
  claimId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ins1Paid?: Maybe<Scalars['Float']>;
  ins2Paid?: Maybe<Scalars['Float']>;
  ins3Paid?: Maybe<Scalars['Float']>;
  patientPaid?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_lineitem" */
export type Lineitem_Avg_Order_By = {
  balanceIns?: InputMaybe<OrderBy>;
  balancePt?: InputMaybe<OrderBy>;
  billed?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ins1Paid?: InputMaybe<OrderBy>;
  ins2Paid?: InputMaybe<OrderBy>;
  ins3Paid?: InputMaybe<OrderBy>;
  patientPaid?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_lineitem" */
export type Lineitem_Max_Order_By = {
  balanceIns?: InputMaybe<OrderBy>;
  balancePt?: InputMaybe<OrderBy>;
  billed?: InputMaybe<OrderBy>;
  billingStatus?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ins1Paid?: InputMaybe<OrderBy>;
  ins2Paid?: InputMaybe<OrderBy>;
  ins3Paid?: InputMaybe<OrderBy>;
  insuranceStatus?: InputMaybe<OrderBy>;
  patientPaid?: InputMaybe<OrderBy>;
  postedDate?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_lineitem" */
export type Lineitem_Min_Order_By = {
  balanceIns?: InputMaybe<OrderBy>;
  balancePt?: InputMaybe<OrderBy>;
  billed?: InputMaybe<OrderBy>;
  billingStatus?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ins1Paid?: InputMaybe<OrderBy>;
  ins2Paid?: InputMaybe<OrderBy>;
  ins3Paid?: InputMaybe<OrderBy>;
  insuranceStatus?: InputMaybe<OrderBy>;
  patientPaid?: InputMaybe<OrderBy>;
  postedDate?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_lineitem" */
export type Lineitem_Stddev_Order_By = {
  balanceIns?: InputMaybe<OrderBy>;
  balancePt?: InputMaybe<OrderBy>;
  billed?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ins1Paid?: InputMaybe<OrderBy>;
  ins2Paid?: InputMaybe<OrderBy>;
  ins3Paid?: InputMaybe<OrderBy>;
  patientPaid?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_lineitem" */
export type Lineitem_Stddev_Pop_Order_By = {
  balanceIns?: InputMaybe<OrderBy>;
  balancePt?: InputMaybe<OrderBy>;
  billed?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ins1Paid?: InputMaybe<OrderBy>;
  ins2Paid?: InputMaybe<OrderBy>;
  ins3Paid?: InputMaybe<OrderBy>;
  patientPaid?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_lineitem" */
export type Lineitem_Stddev_Samp_Order_By = {
  balanceIns?: InputMaybe<OrderBy>;
  balancePt?: InputMaybe<OrderBy>;
  billed?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ins1Paid?: InputMaybe<OrderBy>;
  ins2Paid?: InputMaybe<OrderBy>;
  ins3Paid?: InputMaybe<OrderBy>;
  patientPaid?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "lineitem" */
export type Lineitem_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Lineitem_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Lineitem_StreamCursorValueInput = {
  balanceIns?: InputMaybe<Scalars['numeric']>;
  balancePt?: InputMaybe<Scalars['numeric']>;
  billed?: InputMaybe<Scalars['numeric']>;
  billingStatus?: InputMaybe<Scalars['String']>;
  claimId?: InputMaybe<Scalars['Int']>;
  deniedFlag?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ins1Paid?: InputMaybe<Scalars['numeric']>;
  ins2Paid?: InputMaybe<Scalars['numeric']>;
  ins3Paid?: InputMaybe<Scalars['numeric']>;
  insuranceStatus?: InputMaybe<Scalars['String']>;
  modifiers?: InputMaybe<Scalars['_varchar']>;
  patientPaid?: InputMaybe<Scalars['numeric']>;
  postedDate?: InputMaybe<Scalars['date']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "server_lineitem" */
export type Lineitem_Sum_Order_By = {
  balanceIns?: InputMaybe<OrderBy>;
  balancePt?: InputMaybe<OrderBy>;
  billed?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ins1Paid?: InputMaybe<OrderBy>;
  ins2Paid?: InputMaybe<OrderBy>;
  ins3Paid?: InputMaybe<OrderBy>;
  patientPaid?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_lineitem" */
export type Lineitem_Var_Pop_Order_By = {
  balanceIns?: InputMaybe<OrderBy>;
  balancePt?: InputMaybe<OrderBy>;
  billed?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ins1Paid?: InputMaybe<OrderBy>;
  ins2Paid?: InputMaybe<OrderBy>;
  ins3Paid?: InputMaybe<OrderBy>;
  patientPaid?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_lineitem" */
export type Lineitem_Var_Samp_Order_By = {
  balanceIns?: InputMaybe<OrderBy>;
  balancePt?: InputMaybe<OrderBy>;
  billed?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ins1Paid?: InputMaybe<OrderBy>;
  ins2Paid?: InputMaybe<OrderBy>;
  ins3Paid?: InputMaybe<OrderBy>;
  patientPaid?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_lineitem" */
export type Lineitem_Variance_Order_By = {
  balanceIns?: InputMaybe<OrderBy>;
  balancePt?: InputMaybe<OrderBy>;
  billed?: InputMaybe<OrderBy>;
  claimId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ins1Paid?: InputMaybe<OrderBy>;
  ins2Paid?: InputMaybe<OrderBy>;
  ins3Paid?: InputMaybe<OrderBy>;
  patientPaid?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_medication" */
export type Medication = {
  __typename?: 'medication';
  active: Scalars['Boolean'];
  dateStartedTaking?: Maybe<Scalars['date']>;
  dateStoppedTaking?: Maybe<Scalars['date']>;
  drugName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  externalJson?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  indication?: Maybe<Scalars['String']>;
  medType: Scalars['smallint'];
  name?: Maybe<Scalars['String']>;
  ndc?: Maybe<Scalars['String']>;
  ndcExpiration?: Maybe<Scalars['date']>;
  /** An array relationship */
  ordersForMedication: Array<MedicationorderMedications>;
  /** An aggregate relationship */
  ordersForMedicationAggregate: MedicationorderMedicationsAggregate;
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  pharmacyNote?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['float8']>;
  refills?: Maybe<Scalars['smallint']>;
  route?: Maybe<Scalars['String']>;
  rxnorm?: Maybe<Scalars['String']>;
  sig?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
};


/** columns and relationships of "server_medication" */
export type MedicationExternalJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "server_medication" */
export type MedicationOrdersForMedicationArgs = {
  distinctOn?: InputMaybe<Array<MedicationorderMedicationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationorderMedicationsOrderBy>>;
  where?: InputMaybe<MedicationorderMedicationsBoolExp>;
};


/** columns and relationships of "server_medication" */
export type MedicationOrdersForMedicationAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicationorderMedicationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationorderMedicationsOrderBy>>;
  where?: InputMaybe<MedicationorderMedicationsBoolExp>;
};

/** aggregated selection of "server_medication" */
export type MedicationAggregate = {
  __typename?: 'medicationAggregate';
  aggregate?: Maybe<MedicationAggregateFields>;
  nodes: Array<Medication>;
};

/** aggregate fields of "server_medication" */
export type MedicationAggregateFields = {
  __typename?: 'medicationAggregateFields';
  avg?: Maybe<MedicationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MedicationMaxFields>;
  min?: Maybe<MedicationMinFields>;
  stddev?: Maybe<MedicationStddevFields>;
  stddevPop?: Maybe<MedicationStddev_PopFields>;
  stddevSamp?: Maybe<MedicationStddev_SampFields>;
  sum?: Maybe<MedicationSumFields>;
  varPop?: Maybe<MedicationVar_PopFields>;
  varSamp?: Maybe<MedicationVar_SampFields>;
  variance?: Maybe<MedicationVarianceFields>;
};


/** aggregate fields of "server_medication" */
export type MedicationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedicationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_medication" */
export type MedicationAggregateOrderBy = {
  avg?: InputMaybe<Medication_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Medication_Max_Order_By>;
  min?: InputMaybe<Medication_Min_Order_By>;
  stddev?: InputMaybe<Medication_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Medication_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Medication_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Medication_Sum_Order_By>;
  var_pop?: InputMaybe<Medication_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Medication_Var_Samp_Order_By>;
  variance?: InputMaybe<Medication_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type MedicationAppendInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_medication" */
export type MedicationArrRelInsertInput = {
  data: Array<MedicationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedicationOnConflict>;
};

/** aggregate avg on columns */
export type MedicationAvgFields = {
  __typename?: 'medicationAvgFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medType?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  refills?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_medication". All fields are combined with a logical 'AND'. */
export type MedicationBoolExp = {
  _and?: InputMaybe<Array<MedicationBoolExp>>;
  _not?: InputMaybe<MedicationBoolExp>;
  _or?: InputMaybe<Array<MedicationBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  dateStartedTaking?: InputMaybe<DateComparisonExp>;
  dateStoppedTaking?: InputMaybe<DateComparisonExp>;
  drugName?: InputMaybe<StringComparisonExp>;
  externalId?: InputMaybe<IntComparisonExp>;
  externalJson?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  indication?: InputMaybe<StringComparisonExp>;
  medType?: InputMaybe<SmallintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  ndc?: InputMaybe<StringComparisonExp>;
  ndcExpiration?: InputMaybe<DateComparisonExp>;
  ordersForMedication?: InputMaybe<MedicationorderMedicationsBoolExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  pharmacyNote?: InputMaybe<StringComparisonExp>;
  quantity?: InputMaybe<Float8ComparisonExp>;
  refills?: InputMaybe<SmallintComparisonExp>;
  route?: InputMaybe<StringComparisonExp>;
  rxnorm?: InputMaybe<StringComparisonExp>;
  sig?: InputMaybe<StringComparisonExp>;
  strength?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_medication" */
export enum MedicationConstraint {
  /** unique or primary key constraint on columns "external_id" */
  ServerMedicationExternalIdE3c3a6bfUniq = 'server_medication_external_id_e3c3a6bf_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerMedicationPkey = 'server_medication_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type MedicationDeleteAtPathInput = {
  externalJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type MedicationDeleteElemInput = {
  externalJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type MedicationDeleteKeyInput = {
  externalJson?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_medication" */
export type MedicationIncInput = {
  externalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  medType?: InputMaybe<Scalars['smallint']>;
  patientId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['float8']>;
  refills?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_medication" */
export type MedicationInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  dateStartedTaking?: InputMaybe<Scalars['date']>;
  dateStoppedTaking?: InputMaybe<Scalars['date']>;
  drugName?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  indication?: InputMaybe<Scalars['String']>;
  medType?: InputMaybe<Scalars['smallint']>;
  name?: InputMaybe<Scalars['String']>;
  ndc?: InputMaybe<Scalars['String']>;
  ndcExpiration?: InputMaybe<Scalars['date']>;
  ordersForMedication?: InputMaybe<MedicationorderMedicationsArrRelInsertInput>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  pharmacyNote?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['float8']>;
  refills?: InputMaybe<Scalars['smallint']>;
  route?: InputMaybe<Scalars['String']>;
  rxnorm?: InputMaybe<Scalars['String']>;
  sig?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MedicationMaxFields = {
  __typename?: 'medicationMaxFields';
  dateStartedTaking?: Maybe<Scalars['date']>;
  dateStoppedTaking?: Maybe<Scalars['date']>;
  drugName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  indication?: Maybe<Scalars['String']>;
  medType?: Maybe<Scalars['smallint']>;
  name?: Maybe<Scalars['String']>;
  ndc?: Maybe<Scalars['String']>;
  ndcExpiration?: Maybe<Scalars['date']>;
  patientId?: Maybe<Scalars['Int']>;
  pharmacyNote?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['float8']>;
  refills?: Maybe<Scalars['smallint']>;
  route?: Maybe<Scalars['String']>;
  rxnorm?: Maybe<Scalars['String']>;
  sig?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type MedicationMinFields = {
  __typename?: 'medicationMinFields';
  dateStartedTaking?: Maybe<Scalars['date']>;
  dateStoppedTaking?: Maybe<Scalars['date']>;
  drugName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  indication?: Maybe<Scalars['String']>;
  medType?: Maybe<Scalars['smallint']>;
  name?: Maybe<Scalars['String']>;
  ndc?: Maybe<Scalars['String']>;
  ndcExpiration?: Maybe<Scalars['date']>;
  patientId?: Maybe<Scalars['Int']>;
  pharmacyNote?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['float8']>;
  refills?: Maybe<Scalars['smallint']>;
  route?: Maybe<Scalars['String']>;
  rxnorm?: Maybe<Scalars['String']>;
  sig?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_medication" */
export type MedicationMutationResponse = {
  __typename?: 'medicationMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Medication>;
};

/** input type for inserting object relation for remote table "server_medication" */
export type MedicationObjRelInsertInput = {
  data: MedicationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MedicationOnConflict>;
};

/** on_conflict condition type for table "server_medication" */
export type MedicationOnConflict = {
  constraint: MedicationConstraint;
  update_columns?: Array<MedicationUpdateColumn>;
  where?: InputMaybe<MedicationBoolExp>;
};

/** columns and relationships of "server_medicationorder" */
export type MedicationOrder = {
  __typename?: 'medicationOrder';
  /** An object relationship */
  baseorder: Baseorder;
  baseorderPtrId: Scalars['Int'];
  /** An array relationship */
  medicationOrderSpecifics: Array<MedicationorderMedications>;
  /** An aggregate relationship */
  medicationOrderSpecificsAggregate: MedicationorderMedicationsAggregate;
};


/** columns and relationships of "server_medicationorder" */
export type MedicationOrderMedicationOrderSpecificsArgs = {
  distinctOn?: InputMaybe<Array<MedicationorderMedicationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationorderMedicationsOrderBy>>;
  where?: InputMaybe<MedicationorderMedicationsBoolExp>;
};


/** columns and relationships of "server_medicationorder" */
export type MedicationOrderMedicationOrderSpecificsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicationorderMedicationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationorderMedicationsOrderBy>>;
  where?: InputMaybe<MedicationorderMedicationsBoolExp>;
};

/** aggregated selection of "server_medicationorder" */
export type MedicationOrderAggregate = {
  __typename?: 'medicationOrderAggregate';
  aggregate?: Maybe<MedicationOrderAggregateFields>;
  nodes: Array<MedicationOrder>;
};

/** aggregate fields of "server_medicationorder" */
export type MedicationOrderAggregateFields = {
  __typename?: 'medicationOrderAggregateFields';
  avg?: Maybe<MedicationOrderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MedicationOrderMaxFields>;
  min?: Maybe<MedicationOrderMinFields>;
  stddev?: Maybe<MedicationOrderStddevFields>;
  stddevPop?: Maybe<MedicationOrderStddev_PopFields>;
  stddevSamp?: Maybe<MedicationOrderStddev_SampFields>;
  sum?: Maybe<MedicationOrderSumFields>;
  varPop?: Maybe<MedicationOrderVar_PopFields>;
  varSamp?: Maybe<MedicationOrderVar_SampFields>;
  variance?: Maybe<MedicationOrderVarianceFields>;
};


/** aggregate fields of "server_medicationorder" */
export type MedicationOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedicationOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MedicationOrderAvgFields = {
  __typename?: 'medicationOrderAvgFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_medicationorder". All fields are combined with a logical 'AND'. */
export type MedicationOrderBoolExp = {
  _and?: InputMaybe<Array<MedicationOrderBoolExp>>;
  _not?: InputMaybe<MedicationOrderBoolExp>;
  _or?: InputMaybe<Array<MedicationOrderBoolExp>>;
  baseorder?: InputMaybe<BaseorderBoolExp>;
  baseorderPtrId?: InputMaybe<IntComparisonExp>;
  medicationOrderSpecifics?: InputMaybe<MedicationorderMedicationsBoolExp>;
};

/** Ordering options when selecting data from "server_medication". */
export type MedicationOrderBy = {
  active?: InputMaybe<OrderBy>;
  dateStartedTaking?: InputMaybe<OrderBy>;
  dateStoppedTaking?: InputMaybe<OrderBy>;
  drugName?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  externalJson?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  indication?: InputMaybe<OrderBy>;
  medType?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  ndc?: InputMaybe<OrderBy>;
  ndcExpiration?: InputMaybe<OrderBy>;
  ordersForMedicationAggregate?: InputMaybe<MedicationorderMedicationsAggregateOrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  pharmacyNote?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  refills?: InputMaybe<OrderBy>;
  route?: InputMaybe<OrderBy>;
  rxnorm?: InputMaybe<OrderBy>;
  sig?: InputMaybe<OrderBy>;
  strength?: InputMaybe<OrderBy>;
};

/** unique or primary key constraints on table "server_medicationorder" */
export enum MedicationOrderConstraint {
  /** unique or primary key constraint on columns "baseorder_ptr_id" */
  ServerMedicationorderPkey = 'server_medicationorder_pkey'
}

/** input type for incrementing numeric columns in table "server_medicationorder" */
export type MedicationOrderIncInput = {
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_medicationorder" */
export type MedicationOrderInsertInput = {
  baseorder?: InputMaybe<BaseorderObjRelInsertInput>;
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
  medicationOrderSpecifics?: InputMaybe<MedicationorderMedicationsArrRelInsertInput>;
};

/** aggregate max on columns */
export type MedicationOrderMaxFields = {
  __typename?: 'medicationOrderMaxFields';
  baseorderPtrId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type MedicationOrderMinFields = {
  __typename?: 'medicationOrderMinFields';
  baseorderPtrId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_medicationorder" */
export type MedicationOrderMutationResponse = {
  __typename?: 'medicationOrderMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MedicationOrder>;
};

/** input type for inserting object relation for remote table "server_medicationorder" */
export type MedicationOrderObjRelInsertInput = {
  data: MedicationOrderInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MedicationOrderOnConflict>;
};

/** on_conflict condition type for table "server_medicationorder" */
export type MedicationOrderOnConflict = {
  constraint: MedicationOrderConstraint;
  update_columns?: Array<MedicationOrderUpdateColumn>;
  where?: InputMaybe<MedicationOrderBoolExp>;
};

/** Ordering options when selecting data from "server_medicationorder". */
export type MedicationOrderOrderBy = {
  baseorder?: InputMaybe<BaseorderOrderBy>;
  baseorderPtrId?: InputMaybe<OrderBy>;
  medicationOrderSpecificsAggregate?: InputMaybe<MedicationorderMedicationsAggregateOrderBy>;
};

/** primary key columns input for table: server_medicationorder */
export type MedicationOrderPkColumnsInput = {
  baseorderPtrId: Scalars['Int'];
};

/** select columns of table "server_medicationorder" */
export enum MedicationOrderSelectColumn {
  /** column name */
  BaseorderPtrId = 'baseorderPtrId'
}

/** input type for updating data in table "server_medicationorder" */
export type MedicationOrderSetInput = {
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type MedicationOrderStddevFields = {
  __typename?: 'medicationOrderStddevFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MedicationOrderStddev_PopFields = {
  __typename?: 'medicationOrderStddev_popFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MedicationOrderStddev_SampFields = {
  __typename?: 'medicationOrderStddev_sampFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type MedicationOrderSumFields = {
  __typename?: 'medicationOrderSumFields';
  baseorderPtrId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_medicationorder" */
export enum MedicationOrderUpdateColumn {
  /** column name */
  BaseorderPtrId = 'baseorderPtrId'
}

export type MedicationOrderUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedicationOrderIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedicationOrderSetInput>;
  where: MedicationOrderBoolExp;
};

/** aggregate var_pop on columns */
export type MedicationOrderVar_PopFields = {
  __typename?: 'medicationOrderVar_popFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MedicationOrderVar_SampFields = {
  __typename?: 'medicationOrderVar_sampFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MedicationOrderVarianceFields = {
  __typename?: 'medicationOrderVarianceFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "medicationOrder" */
export type MedicationOrder_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedicationOrder_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedicationOrder_StreamCursorValueInput = {
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
};

/** primary key columns input for table: server_medication */
export type MedicationPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type MedicationPrependInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_medication" */
export enum MedicationSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DateStartedTaking = 'dateStartedTaking',
  /** column name */
  DateStoppedTaking = 'dateStoppedTaking',
  /** column name */
  DrugName = 'drugName',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  Id = 'id',
  /** column name */
  Indication = 'indication',
  /** column name */
  MedType = 'medType',
  /** column name */
  Name = 'name',
  /** column name */
  Ndc = 'ndc',
  /** column name */
  NdcExpiration = 'ndcExpiration',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PharmacyNote = 'pharmacyNote',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Refills = 'refills',
  /** column name */
  Route = 'route',
  /** column name */
  Rxnorm = 'rxnorm',
  /** column name */
  Sig = 'sig',
  /** column name */
  Strength = 'strength'
}

/** input type for updating data in table "server_medication" */
export type MedicationSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  dateStartedTaking?: InputMaybe<Scalars['date']>;
  dateStoppedTaking?: InputMaybe<Scalars['date']>;
  drugName?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  indication?: InputMaybe<Scalars['String']>;
  medType?: InputMaybe<Scalars['smallint']>;
  name?: InputMaybe<Scalars['String']>;
  ndc?: InputMaybe<Scalars['String']>;
  ndcExpiration?: InputMaybe<Scalars['date']>;
  patientId?: InputMaybe<Scalars['Int']>;
  pharmacyNote?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['float8']>;
  refills?: InputMaybe<Scalars['smallint']>;
  route?: InputMaybe<Scalars['String']>;
  rxnorm?: InputMaybe<Scalars['String']>;
  sig?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type MedicationStddevFields = {
  __typename?: 'medicationStddevFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medType?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  refills?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MedicationStddev_PopFields = {
  __typename?: 'medicationStddev_popFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medType?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  refills?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MedicationStddev_SampFields = {
  __typename?: 'medicationStddev_sampFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medType?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  refills?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type MedicationSumFields = {
  __typename?: 'medicationSumFields';
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  medType?: Maybe<Scalars['smallint']>;
  patientId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['float8']>;
  refills?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_medication" */
export enum MedicationUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DateStartedTaking = 'dateStartedTaking',
  /** column name */
  DateStoppedTaking = 'dateStoppedTaking',
  /** column name */
  DrugName = 'drugName',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  Id = 'id',
  /** column name */
  Indication = 'indication',
  /** column name */
  MedType = 'medType',
  /** column name */
  Name = 'name',
  /** column name */
  Ndc = 'ndc',
  /** column name */
  NdcExpiration = 'ndcExpiration',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PharmacyNote = 'pharmacyNote',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Refills = 'refills',
  /** column name */
  Route = 'route',
  /** column name */
  Rxnorm = 'rxnorm',
  /** column name */
  Sig = 'sig',
  /** column name */
  Strength = 'strength'
}

export type MedicationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<MedicationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<MedicationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<MedicationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<MedicationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedicationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<MedicationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedicationSetInput>;
  where: MedicationBoolExp;
};

/** aggregate var_pop on columns */
export type MedicationVar_PopFields = {
  __typename?: 'medicationVar_popFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medType?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  refills?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MedicationVar_SampFields = {
  __typename?: 'medicationVar_sampFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medType?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  refills?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MedicationVarianceFields = {
  __typename?: 'medicationVarianceFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medType?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  refills?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_medication" */
export type Medication_Avg_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medType?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  refills?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_medication" */
export type Medication_Max_Order_By = {
  dateStartedTaking?: InputMaybe<OrderBy>;
  dateStoppedTaking?: InputMaybe<OrderBy>;
  drugName?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  indication?: InputMaybe<OrderBy>;
  medType?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  ndc?: InputMaybe<OrderBy>;
  ndcExpiration?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  pharmacyNote?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  refills?: InputMaybe<OrderBy>;
  route?: InputMaybe<OrderBy>;
  rxnorm?: InputMaybe<OrderBy>;
  sig?: InputMaybe<OrderBy>;
  strength?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_medication" */
export type Medication_Min_Order_By = {
  dateStartedTaking?: InputMaybe<OrderBy>;
  dateStoppedTaking?: InputMaybe<OrderBy>;
  drugName?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  indication?: InputMaybe<OrderBy>;
  medType?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  ndc?: InputMaybe<OrderBy>;
  ndcExpiration?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  pharmacyNote?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  refills?: InputMaybe<OrderBy>;
  route?: InputMaybe<OrderBy>;
  rxnorm?: InputMaybe<OrderBy>;
  sig?: InputMaybe<OrderBy>;
  strength?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_medication" */
export type Medication_Stddev_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medType?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  refills?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_medication" */
export type Medication_Stddev_Pop_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medType?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  refills?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_medication" */
export type Medication_Stddev_Samp_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medType?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  refills?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medication" */
export type Medication_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Medication_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Medication_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  dateStartedTaking?: InputMaybe<Scalars['date']>;
  dateStoppedTaking?: InputMaybe<Scalars['date']>;
  drugName?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  indication?: InputMaybe<Scalars['String']>;
  medType?: InputMaybe<Scalars['smallint']>;
  name?: InputMaybe<Scalars['String']>;
  ndc?: InputMaybe<Scalars['String']>;
  ndcExpiration?: InputMaybe<Scalars['date']>;
  patientId?: InputMaybe<Scalars['Int']>;
  pharmacyNote?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['float8']>;
  refills?: InputMaybe<Scalars['smallint']>;
  route?: InputMaybe<Scalars['String']>;
  rxnorm?: InputMaybe<Scalars['String']>;
  sig?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_medication" */
export type Medication_Sum_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medType?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  refills?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_medication" */
export type Medication_Var_Pop_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medType?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  refills?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_medication" */
export type Medication_Var_Samp_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medType?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  refills?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_medication" */
export type Medication_Variance_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medType?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  refills?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_medicationorder_medications" */
export type MedicationorderMedications = {
  __typename?: 'medicationorderMedications';
  /** An object relationship */
  baseorder: MedicationOrder;
  id: Scalars['Int'];
  /** An object relationship */
  medication: Medication;
  medicationId: Scalars['Int'];
  medicationorderId: Scalars['Int'];
};

/** aggregated selection of "server_medicationorder_medications" */
export type MedicationorderMedicationsAggregate = {
  __typename?: 'medicationorderMedicationsAggregate';
  aggregate?: Maybe<MedicationorderMedicationsAggregateFields>;
  nodes: Array<MedicationorderMedications>;
};

/** aggregate fields of "server_medicationorder_medications" */
export type MedicationorderMedicationsAggregateFields = {
  __typename?: 'medicationorderMedicationsAggregateFields';
  avg?: Maybe<MedicationorderMedicationsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MedicationorderMedicationsMaxFields>;
  min?: Maybe<MedicationorderMedicationsMinFields>;
  stddev?: Maybe<MedicationorderMedicationsStddevFields>;
  stddevPop?: Maybe<MedicationorderMedicationsStddev_PopFields>;
  stddevSamp?: Maybe<MedicationorderMedicationsStddev_SampFields>;
  sum?: Maybe<MedicationorderMedicationsSumFields>;
  varPop?: Maybe<MedicationorderMedicationsVar_PopFields>;
  varSamp?: Maybe<MedicationorderMedicationsVar_SampFields>;
  variance?: Maybe<MedicationorderMedicationsVarianceFields>;
};


/** aggregate fields of "server_medicationorder_medications" */
export type MedicationorderMedicationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedicationorderMedicationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_medicationorder_medications" */
export type MedicationorderMedicationsAggregateOrderBy = {
  avg?: InputMaybe<MedicationorderMedications_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedicationorderMedications_Max_Order_By>;
  min?: InputMaybe<MedicationorderMedications_Min_Order_By>;
  stddev?: InputMaybe<MedicationorderMedications_Stddev_Order_By>;
  stddev_pop?: InputMaybe<MedicationorderMedications_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<MedicationorderMedications_Stddev_Samp_Order_By>;
  sum?: InputMaybe<MedicationorderMedications_Sum_Order_By>;
  var_pop?: InputMaybe<MedicationorderMedications_Var_Pop_Order_By>;
  var_samp?: InputMaybe<MedicationorderMedications_Var_Samp_Order_By>;
  variance?: InputMaybe<MedicationorderMedications_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_medicationorder_medications" */
export type MedicationorderMedicationsArrRelInsertInput = {
  data: Array<MedicationorderMedicationsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedicationorderMedicationsOnConflict>;
};

/** aggregate avg on columns */
export type MedicationorderMedicationsAvgFields = {
  __typename?: 'medicationorderMedicationsAvgFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  medicationorderId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_medicationorder_medications". All fields are combined with a logical 'AND'. */
export type MedicationorderMedicationsBoolExp = {
  _and?: InputMaybe<Array<MedicationorderMedicationsBoolExp>>;
  _not?: InputMaybe<MedicationorderMedicationsBoolExp>;
  _or?: InputMaybe<Array<MedicationorderMedicationsBoolExp>>;
  baseorder?: InputMaybe<MedicationOrderBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  medication?: InputMaybe<MedicationBoolExp>;
  medicationId?: InputMaybe<IntComparisonExp>;
  medicationorderId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_medicationorder_medications" */
export enum MedicationorderMedicationsConstraint {
  /** unique or primary key constraint on columns "medicationorder_id", "medication_id" */
  ServerMedicationorderMMedicationorderIdMedicC8e1c3a6Uniq = 'server_medicationorder_m_medicationorder_id_medic_c8e1c3a6_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerMedicationorderMedicationsPkey = 'server_medicationorder_medications_pkey'
}

/** input type for incrementing numeric columns in table "server_medicationorder_medications" */
export type MedicationorderMedicationsIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  medicationId?: InputMaybe<Scalars['Int']>;
  medicationorderId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_medicationorder_medications" */
export type MedicationorderMedicationsInsertInput = {
  baseorder?: InputMaybe<MedicationOrderObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  medication?: InputMaybe<MedicationObjRelInsertInput>;
  medicationId?: InputMaybe<Scalars['Int']>;
  medicationorderId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type MedicationorderMedicationsMaxFields = {
  __typename?: 'medicationorderMedicationsMaxFields';
  id?: Maybe<Scalars['Int']>;
  medicationId?: Maybe<Scalars['Int']>;
  medicationorderId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type MedicationorderMedicationsMinFields = {
  __typename?: 'medicationorderMedicationsMinFields';
  id?: Maybe<Scalars['Int']>;
  medicationId?: Maybe<Scalars['Int']>;
  medicationorderId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_medicationorder_medications" */
export type MedicationorderMedicationsMutationResponse = {
  __typename?: 'medicationorderMedicationsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MedicationorderMedications>;
};

/** on_conflict condition type for table "server_medicationorder_medications" */
export type MedicationorderMedicationsOnConflict = {
  constraint: MedicationorderMedicationsConstraint;
  update_columns?: Array<MedicationorderMedicationsUpdateColumn>;
  where?: InputMaybe<MedicationorderMedicationsBoolExp>;
};

/** Ordering options when selecting data from "server_medicationorder_medications". */
export type MedicationorderMedicationsOrderBy = {
  baseorder?: InputMaybe<MedicationOrderOrderBy>;
  id?: InputMaybe<OrderBy>;
  medication?: InputMaybe<MedicationOrderBy>;
  medicationId?: InputMaybe<OrderBy>;
  medicationorderId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_medicationorder_medications */
export type MedicationorderMedicationsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_medicationorder_medications" */
export enum MedicationorderMedicationsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medicationId',
  /** column name */
  MedicationorderId = 'medicationorderId'
}

/** input type for updating data in table "server_medicationorder_medications" */
export type MedicationorderMedicationsSetInput = {
  id?: InputMaybe<Scalars['Int']>;
  medicationId?: InputMaybe<Scalars['Int']>;
  medicationorderId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type MedicationorderMedicationsStddevFields = {
  __typename?: 'medicationorderMedicationsStddevFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  medicationorderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MedicationorderMedicationsStddev_PopFields = {
  __typename?: 'medicationorderMedicationsStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  medicationorderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MedicationorderMedicationsStddev_SampFields = {
  __typename?: 'medicationorderMedicationsStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  medicationorderId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type MedicationorderMedicationsSumFields = {
  __typename?: 'medicationorderMedicationsSumFields';
  id?: Maybe<Scalars['Int']>;
  medicationId?: Maybe<Scalars['Int']>;
  medicationorderId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_medicationorder_medications" */
export enum MedicationorderMedicationsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medicationId',
  /** column name */
  MedicationorderId = 'medicationorderId'
}

export type MedicationorderMedicationsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedicationorderMedicationsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedicationorderMedicationsSetInput>;
  where: MedicationorderMedicationsBoolExp;
};

/** aggregate var_pop on columns */
export type MedicationorderMedicationsVar_PopFields = {
  __typename?: 'medicationorderMedicationsVar_popFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  medicationorderId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MedicationorderMedicationsVar_SampFields = {
  __typename?: 'medicationorderMedicationsVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  medicationorderId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MedicationorderMedicationsVarianceFields = {
  __typename?: 'medicationorderMedicationsVarianceFields';
  id?: Maybe<Scalars['Float']>;
  medicationId?: Maybe<Scalars['Float']>;
  medicationorderId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_medicationorder_medications" */
export type MedicationorderMedications_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  medicationId?: InputMaybe<OrderBy>;
  medicationorderId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_medicationorder_medications" */
export type MedicationorderMedications_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  medicationId?: InputMaybe<OrderBy>;
  medicationorderId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_medicationorder_medications" */
export type MedicationorderMedications_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  medicationId?: InputMaybe<OrderBy>;
  medicationorderId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_medicationorder_medications" */
export type MedicationorderMedications_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  medicationId?: InputMaybe<OrderBy>;
  medicationorderId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_medicationorder_medications" */
export type MedicationorderMedications_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  medicationId?: InputMaybe<OrderBy>;
  medicationorderId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_medicationorder_medications" */
export type MedicationorderMedications_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  medicationId?: InputMaybe<OrderBy>;
  medicationorderId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medicationorderMedications" */
export type MedicationorderMedications_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedicationorderMedications_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedicationorderMedications_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  medicationId?: InputMaybe<Scalars['Int']>;
  medicationorderId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_medicationorder_medications" */
export type MedicationorderMedications_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  medicationId?: InputMaybe<OrderBy>;
  medicationorderId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_medicationorder_medications" */
export type MedicationorderMedications_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  medicationId?: InputMaybe<OrderBy>;
  medicationorderId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_medicationorder_medications" */
export type MedicationorderMedications_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  medicationId?: InputMaybe<OrderBy>;
  medicationorderId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_medicationorder_medications" */
export type MedicationorderMedications_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  medicationId?: InputMaybe<OrderBy>;
  medicationorderId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_message" */
export type Message = {
  __typename?: 'message';
  /** An array relationship */
  caretimes: Array<Caretime>;
  /** An aggregate relationship */
  caretimesAggregate: CaretimeAggregate;
  datetimeSubmitted: Scalars['timestamptz'];
  /** An array relationship */
  documents: Array<Document>;
  /** An aggregate relationship */
  documentsAggregate: DocumentAggregate;
  id: Scalars['Int'];
  intakeId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  patientIntake?: Maybe<PatientIntake>;
  practiceStaffOnly: Scalars['Boolean'];
  /** An object relationship */
  telemedAppointmentRequest?: Maybe<TelemedAppointmentRequest>;
  telemedRequestId?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
  ticketId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  ticketThread?: Maybe<TicketThread>;
  /** An object relationship */
  user: User;
  userId: Scalars['Int'];
};


/** columns and relationships of "server_message" */
export type MessageCaretimesArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


/** columns and relationships of "server_message" */
export type MessageCaretimesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


/** columns and relationships of "server_message" */
export type MessageDocumentsArgs = {
  distinctOn?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


/** columns and relationships of "server_message" */
export type MessageDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};

/** aggregated selection of "server_message" */
export type MessageAggregate = {
  __typename?: 'messageAggregate';
  aggregate?: Maybe<MessageAggregateFields>;
  nodes: Array<Message>;
};

/** aggregate fields of "server_message" */
export type MessageAggregateFields = {
  __typename?: 'messageAggregateFields';
  avg?: Maybe<MessageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MessageMaxFields>;
  min?: Maybe<MessageMinFields>;
  stddev?: Maybe<MessageStddevFields>;
  stddevPop?: Maybe<MessageStddev_PopFields>;
  stddevSamp?: Maybe<MessageStddev_SampFields>;
  sum?: Maybe<MessageSumFields>;
  varPop?: Maybe<MessageVar_PopFields>;
  varSamp?: Maybe<MessageVar_SampFields>;
  variance?: Maybe<MessageVarianceFields>;
};


/** aggregate fields of "server_message" */
export type MessageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MessageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_message" */
export type MessageAggregateOrderBy = {
  avg?: InputMaybe<Message_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Message_Max_Order_By>;
  min?: InputMaybe<Message_Min_Order_By>;
  stddev?: InputMaybe<Message_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Message_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Message_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Message_Sum_Order_By>;
  var_pop?: InputMaybe<Message_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Message_Var_Samp_Order_By>;
  variance?: InputMaybe<Message_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_message" */
export type MessageArrRelInsertInput = {
  data: Array<MessageInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MessageOnConflict>;
};

/** aggregate avg on columns */
export type MessageAvgFields = {
  __typename?: 'messageAvgFields';
  id?: Maybe<Scalars['Float']>;
  intakeId?: Maybe<Scalars['Float']>;
  telemedRequestId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_message". All fields are combined with a logical 'AND'. */
export type MessageBoolExp = {
  _and?: InputMaybe<Array<MessageBoolExp>>;
  _not?: InputMaybe<MessageBoolExp>;
  _or?: InputMaybe<Array<MessageBoolExp>>;
  caretimes?: InputMaybe<CaretimeBoolExp>;
  datetimeSubmitted?: InputMaybe<TimestamptzComparisonExp>;
  documents?: InputMaybe<DocumentBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  intakeId?: InputMaybe<IntComparisonExp>;
  patientIntake?: InputMaybe<PatientIntakeBoolExp>;
  practiceStaffOnly?: InputMaybe<BooleanComparisonExp>;
  telemedAppointmentRequest?: InputMaybe<TelemedAppointmentRequestBoolExp>;
  telemedRequestId?: InputMaybe<IntComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  ticketId?: InputMaybe<IntComparisonExp>;
  ticketThread?: InputMaybe<TicketThreadBoolExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_message" */
export enum MessageConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerMessagePkey = 'server_message_pkey'
}

/** input type for incrementing numeric columns in table "server_message" */
export type MessageIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  intakeId?: InputMaybe<Scalars['Int']>;
  telemedRequestId?: InputMaybe<Scalars['Int']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_message" */
export type MessageInsertInput = {
  caretimes?: InputMaybe<CaretimeArrRelInsertInput>;
  datetimeSubmitted?: InputMaybe<Scalars['timestamptz']>;
  documents?: InputMaybe<DocumentArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  intakeId?: InputMaybe<Scalars['Int']>;
  patientIntake?: InputMaybe<PatientIntakeObjRelInsertInput>;
  practiceStaffOnly?: InputMaybe<Scalars['Boolean']>;
  telemedAppointmentRequest?: InputMaybe<TelemedAppointmentRequestObjRelInsertInput>;
  telemedRequestId?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  ticketThread?: InputMaybe<TicketThreadObjRelInsertInput>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type MessageMaxFields = {
  __typename?: 'messageMaxFields';
  datetimeSubmitted?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  intakeId?: Maybe<Scalars['Int']>;
  telemedRequestId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  ticketId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type MessageMinFields = {
  __typename?: 'messageMinFields';
  datetimeSubmitted?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  intakeId?: Maybe<Scalars['Int']>;
  telemedRequestId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  ticketId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_message" */
export type MessageMutationResponse = {
  __typename?: 'messageMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Message>;
};

/** input type for inserting object relation for remote table "server_message" */
export type MessageObjRelInsertInput = {
  data: MessageInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MessageOnConflict>;
};

/** on_conflict condition type for table "server_message" */
export type MessageOnConflict = {
  constraint: MessageConstraint;
  update_columns?: Array<MessageUpdateColumn>;
  where?: InputMaybe<MessageBoolExp>;
};

/** Ordering options when selecting data from "server_message". */
export type MessageOrderBy = {
  caretimesAggregate?: InputMaybe<CaretimeAggregateOrderBy>;
  datetimeSubmitted?: InputMaybe<OrderBy>;
  documentsAggregate?: InputMaybe<DocumentAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  intakeId?: InputMaybe<OrderBy>;
  patientIntake?: InputMaybe<PatientIntakeOrderBy>;
  practiceStaffOnly?: InputMaybe<OrderBy>;
  telemedAppointmentRequest?: InputMaybe<TelemedAppointmentRequestOrderBy>;
  telemedRequestId?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  ticketThread?: InputMaybe<TicketThreadOrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_message */
export type MessagePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_message" */
export enum MessageSelectColumn {
  /** column name */
  DatetimeSubmitted = 'datetimeSubmitted',
  /** column name */
  Id = 'id',
  /** column name */
  IntakeId = 'intakeId',
  /** column name */
  PracticeStaffOnly = 'practiceStaffOnly',
  /** column name */
  TelemedRequestId = 'telemedRequestId',
  /** column name */
  Text = 'text',
  /** column name */
  TicketId = 'ticketId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "server_message" */
export type MessageSetInput = {
  datetimeSubmitted?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  intakeId?: InputMaybe<Scalars['Int']>;
  practiceStaffOnly?: InputMaybe<Scalars['Boolean']>;
  telemedRequestId?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type MessageStddevFields = {
  __typename?: 'messageStddevFields';
  id?: Maybe<Scalars['Float']>;
  intakeId?: Maybe<Scalars['Float']>;
  telemedRequestId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MessageStddev_PopFields = {
  __typename?: 'messageStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  intakeId?: Maybe<Scalars['Float']>;
  telemedRequestId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MessageStddev_SampFields = {
  __typename?: 'messageStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  intakeId?: Maybe<Scalars['Float']>;
  telemedRequestId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type MessageSumFields = {
  __typename?: 'messageSumFields';
  id?: Maybe<Scalars['Int']>;
  intakeId?: Maybe<Scalars['Int']>;
  telemedRequestId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_message" */
export enum MessageUpdateColumn {
  /** column name */
  DatetimeSubmitted = 'datetimeSubmitted',
  /** column name */
  Id = 'id',
  /** column name */
  IntakeId = 'intakeId',
  /** column name */
  PracticeStaffOnly = 'practiceStaffOnly',
  /** column name */
  TelemedRequestId = 'telemedRequestId',
  /** column name */
  Text = 'text',
  /** column name */
  TicketId = 'ticketId',
  /** column name */
  UserId = 'userId'
}

export type MessageUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MessageIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MessageSetInput>;
  where: MessageBoolExp;
};

/** aggregate var_pop on columns */
export type MessageVar_PopFields = {
  __typename?: 'messageVar_popFields';
  id?: Maybe<Scalars['Float']>;
  intakeId?: Maybe<Scalars['Float']>;
  telemedRequestId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MessageVar_SampFields = {
  __typename?: 'messageVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  intakeId?: Maybe<Scalars['Float']>;
  telemedRequestId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MessageVarianceFields = {
  __typename?: 'messageVarianceFields';
  id?: Maybe<Scalars['Float']>;
  intakeId?: Maybe<Scalars['Float']>;
  telemedRequestId?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_message" */
export type Message_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  intakeId?: InputMaybe<OrderBy>;
  telemedRequestId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_message" */
export type Message_Max_Order_By = {
  datetimeSubmitted?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  intakeId?: InputMaybe<OrderBy>;
  telemedRequestId?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_message" */
export type Message_Min_Order_By = {
  datetimeSubmitted?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  intakeId?: InputMaybe<OrderBy>;
  telemedRequestId?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_message" */
export type Message_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  intakeId?: InputMaybe<OrderBy>;
  telemedRequestId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_message" */
export type Message_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  intakeId?: InputMaybe<OrderBy>;
  telemedRequestId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_message" */
export type Message_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  intakeId?: InputMaybe<OrderBy>;
  telemedRequestId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "message" */
export type Message_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Message_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Message_StreamCursorValueInput = {
  datetimeSubmitted?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  intakeId?: InputMaybe<Scalars['Int']>;
  practiceStaffOnly?: InputMaybe<Scalars['Boolean']>;
  telemedRequestId?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_message" */
export type Message_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  intakeId?: InputMaybe<OrderBy>;
  telemedRequestId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_message" */
export type Message_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  intakeId?: InputMaybe<OrderBy>;
  telemedRequestId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_message" */
export type Message_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  intakeId?: InputMaybe<OrderBy>;
  telemedRequestId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_message" */
export type Message_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  intakeId?: InputMaybe<OrderBy>;
  telemedRequestId?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "server_address" */
  deleteAddress?: Maybe<AddressMutationResponse>;
  /** delete single row from the table: "server_address" */
  deleteAddressByPk?: Maybe<Address>;
  /** delete data from the table: "server_agency" */
  deleteAgency?: Maybe<AgencyMutationResponse>;
  /** delete single row from the table: "server_agency" */
  deleteAgencyByPk?: Maybe<Agency>;
  /** delete data from the table: "server_agencylocation" */
  deleteAgencyLocation?: Maybe<AgencyLocationMutationResponse>;
  /** delete single row from the table: "server_agencylocation" */
  deleteAgencyLocationByPk?: Maybe<AgencyLocation>;
  /** delete data from the table: "server_agency_payers" */
  deleteAgencyPayers?: Maybe<AgencyPayersMutationResponse>;
  /** delete single row from the table: "server_agency_payers" */
  deleteAgencyPayersByPk?: Maybe<AgencyPayers>;
  /** delete data from the table: "server_agency_services" */
  deleteAgencyServices?: Maybe<AgencyServicesMutationResponse>;
  /** delete single row from the table: "server_agency_services" */
  deleteAgencyServicesByPk?: Maybe<AgencyServices>;
  /** delete data from the table: "server_agencyconnection" */
  deleteAgencyconnection?: Maybe<AgencyconnectionMutationResponse>;
  /** delete single row from the table: "server_agencyconnection" */
  deleteAgencyconnectionByPk?: Maybe<Agencyconnection>;
  /** delete data from the table: "server_agencyjoinrequest" */
  deleteAgencyjoinrequest?: Maybe<AgencyjoinrequestMutationResponse>;
  /** delete single row from the table: "server_agencyjoinrequest" */
  deleteAgencyjoinrequestByPk?: Maybe<Agencyjoinrequest>;
  /** delete data from the table: "server_allergy" */
  deleteAllergy?: Maybe<AllergyMutationResponse>;
  /** delete single row from the table: "server_allergy" */
  deleteAllergyByPk?: Maybe<Allergy>;
  /** delete data from the table: "server_appointment" */
  deleteAppointment?: Maybe<AppointmentMutationResponse>;
  /** delete single row from the table: "server_appointment" */
  deleteAppointmentByPk?: Maybe<Appointment>;
  /** delete data from the table: "server_assessment" */
  deleteAssessment?: Maybe<AssessmentMutationResponse>;
  /** delete single row from the table: "server_assessment" */
  deleteAssessmentByPk?: Maybe<Assessment>;
  /** delete data from the table: "server_assessmentitem" */
  deleteAssessmentItem?: Maybe<AssessmentItemMutationResponse>;
  /** delete single row from the table: "server_assessmentitem" */
  deleteAssessmentItemByPk?: Maybe<AssessmentItem>;
  /** delete data from the table: "server_assessmentitemvalue" */
  deleteAssessmentItemValue?: Maybe<AssessmentItemValueMutationResponse>;
  /** delete single row from the table: "server_assessmentitemvalue" */
  deleteAssessmentItemValueByPk?: Maybe<AssessmentItemValue>;
  /** delete data from the table: "server_baseprotocolorder" */
  deleteBaseProtocolOrder?: Maybe<BaseProtocolOrderMutationResponse>;
  /** delete single row from the table: "server_baseprotocolorder" */
  deleteBaseProtocolOrderByPk?: Maybe<BaseProtocolOrder>;
  /** delete data from the table: "server_baseorder" */
  deleteBaseorder?: Maybe<BaseorderMutationResponse>;
  /** delete single row from the table: "server_baseorder" */
  deleteBaseorderByPk?: Maybe<Baseorder>;
  /** delete data from the table: "server_caretime" */
  deleteCaretime?: Maybe<CaretimeMutationResponse>;
  /** delete single row from the table: "server_caretime" */
  deleteCaretimeByPk?: Maybe<Caretime>;
  /** delete data from the table: "server_caretime_problems" */
  deleteCaretimeProblems?: Maybe<CaretimeProblemsMutationResponse>;
  /** delete single row from the table: "server_caretime_problems" */
  deleteCaretimeProblemsByPk?: Maybe<CaretimeProblems>;
  /** delete data from the table: "server_claim" */
  deleteClaim?: Maybe<ClaimMutationResponse>;
  /** delete single row from the table: "server_claim" */
  deleteClaimByPk?: Maybe<Claim>;
  /** delete data from the table: "server_futurereferralclinicalnote" */
  deleteClinicalNoteFutureReferrals?: Maybe<ClinicalNoteFutureReferralsMutationResponse>;
  /** delete single row from the table: "server_futurereferralclinicalnote" */
  deleteClinicalNoteFutureReferralsByPk?: Maybe<ClinicalNoteFutureReferrals>;
  /** delete data from the table: "server_clinicalnote" */
  deleteClinicalnote?: Maybe<ClinicalnoteMutationResponse>;
  /** delete single row from the table: "server_clinicalnote" */
  deleteClinicalnoteByPk?: Maybe<Clinicalnote>;
  /** delete data from the table: "server_clinicalnotefield" */
  deleteClinicalnoteField?: Maybe<ClinicalnoteFieldMutationResponse>;
  /** delete single row from the table: "server_clinicalnotefield" */
  deleteClinicalnoteFieldByPk?: Maybe<ClinicalnoteField>;
  /** delete data from the table: "server_clinicalnotefieldvalue" */
  deleteClinicalnoteFieldValue?: Maybe<ClinicalnoteFieldValueMutationResponse>;
  /** delete single row from the table: "server_clinicalnotefieldvalue" */
  deleteClinicalnoteFieldValueByPk?: Maybe<ClinicalnoteFieldValue>;
  /** delete data from the table: "server_clinicalnotetemplate" */
  deleteClinicalnoteTemplate?: Maybe<ClinicalnoteTemplateMutationResponse>;
  /** delete single row from the table: "server_clinicalnotetemplate" */
  deleteClinicalnoteTemplateByPk?: Maybe<ClinicalnoteTemplate>;
  /** delete data from the table: "server_clinicalnotetemplate_provider_classifications" */
  deleteClinicalnoteTemplateProviderClassifications?: Maybe<ClinicalnoteTemplateProviderClassificationsMutationResponse>;
  /** delete single row from the table: "server_clinicalnotetemplate_provider_classifications" */
  deleteClinicalnoteTemplateProviderClassificationsByPk?: Maybe<ClinicalnoteTemplateProviderClassifications>;
  /** delete data from the table: "server_codeset" */
  deleteCodeset?: Maybe<CodesetMutationResponse>;
  /** delete data from the table: "server_codeset_addon_codeset" */
  deleteCodesetAddon?: Maybe<CodesetAddonMutationResponse>;
  /** delete single row from the table: "server_codeset_addon_codeset" */
  deleteCodesetAddonByPk?: Maybe<CodesetAddon>;
  /** delete data from the table: "server_codeset_base_codeset" */
  deleteCodesetBase?: Maybe<CodesetBaseMutationResponse>;
  /** delete single row from the table: "server_codeset_base_codeset" */
  deleteCodesetBaseByPk?: Maybe<CodesetBase>;
  /** delete single row from the table: "server_codeset" */
  deleteCodesetByPk?: Maybe<Codeset>;
  /** delete data from the table: "server_consent" */
  deleteConsent?: Maybe<ConsentMutationResponse>;
  /** delete single row from the table: "server_consent" */
  deleteConsentByPk?: Maybe<Consent>;
  /** delete data from the table: "server_cpoclaim" */
  deleteCpoClaim?: Maybe<CpoClaimMutationResponse>;
  /** delete single row from the table: "server_cpoclaim" */
  deleteCpoClaimByPk?: Maybe<CpoClaim>;
  /** delete data from the table: "server_dependantorder" */
  deleteDependantOrder?: Maybe<DependantOrderMutationResponse>;
  /** delete single row from the table: "server_dependantorder" */
  deleteDependantOrderByPk?: Maybe<DependantOrder>;
  /** delete data from the table: "server_diagnosisabbreviation" */
  deleteDiagnosisAbbreviation?: Maybe<DiagnosisAbbreviationMutationResponse>;
  /** delete single row from the table: "server_diagnosisabbreviation" */
  deleteDiagnosisAbbreviationByPk?: Maybe<DiagnosisAbbreviation>;
  /** delete data from the table: "server_document" */
  deleteDocument?: Maybe<DocumentMutationResponse>;
  /** delete single row from the table: "server_document" */
  deleteDocumentByPk?: Maybe<Document>;
  /** delete data from the table: "server_documentpendingreview" */
  deleteDocumentPendingReview?: Maybe<DocumentPendingReviewMutationResponse>;
  /** delete single row from the table: "server_documentpendingreview" */
  deleteDocumentPendingReviewByPk?: Maybe<DocumentPendingReview>;
  /** delete data from the table: "server_externalsignedorder" */
  deleteExternalSignedOrder?: Maybe<ExternalSignedOrderMutationResponse>;
  /** delete single row from the table: "server_externalsignedorder" */
  deleteExternalSignedOrderByPk?: Maybe<ExternalSignedOrder>;
  /** delete data from the table: "server_externalstaff_my_patients" */
  deleteExternalStaffMyPatients?: Maybe<ExternalStaffMyPatientsMutationResponse>;
  /** delete single row from the table: "server_externalstaff_my_patients" */
  deleteExternalStaffMyPatientsByPk?: Maybe<ExternalStaffMyPatients>;
  /** delete data from the table: "server_externalstaff" */
  deleteExternalstaff?: Maybe<ExternalstaffMutationResponse>;
  /** delete single row from the table: "server_externalstaff" */
  deleteExternalstaffByPk?: Maybe<Externalstaff>;
  /** delete data from the table: "server_fax" */
  deleteFax?: Maybe<FaxMutationResponse>;
  /** delete single row from the table: "server_fax" */
  deleteFaxByPk?: Maybe<Fax>;
  /** delete data from the table: "server_fax_documents" */
  deleteFaxDocuments?: Maybe<FaxDocumentsMutationResponse>;
  /** delete single row from the table: "server_fax_documents" */
  deleteFaxDocumentsByPk?: Maybe<FaxDocuments>;
  /** delete data from the table: "server_freetextorder" */
  deleteFreeTextOrder?: Maybe<FreeTextOrderMutationResponse>;
  /** delete single row from the table: "server_freetextorder" */
  deleteFreeTextOrderByPk?: Maybe<FreeTextOrder>;
  /** delete data from the table: "server_frequencyorder" */
  deleteFrequencyOrder?: Maybe<FrequencyOrderMutationResponse>;
  /** delete single row from the table: "server_frequencyorder" */
  deleteFrequencyOrderByPk?: Maybe<FrequencyOrder>;
  /** delete data from the table: "server_frequencyprotocol" */
  deleteFrequencyProtocol?: Maybe<FrequencyProtocolMutationResponse>;
  /** delete single row from the table: "server_frequencyprotocol" */
  deleteFrequencyProtocolByPk?: Maybe<FrequencyProtocol>;
  /** delete data from the table: "server_hcpcs" */
  deleteHcpcs?: Maybe<HcpcsMutationResponse>;
  /** delete single row from the table: "server_hcpcs" */
  deleteHcpcsByPk?: Maybe<Hcpcs>;
  /** delete data from the table: "server_insuranceplan" */
  deleteInsuranceplan?: Maybe<InsuranceplanMutationResponse>;
  /** delete single row from the table: "server_insuranceplan" */
  deleteInsuranceplanByPk?: Maybe<Insuranceplan>;
  /** delete data from the table: "server_integration" */
  deleteIntegration?: Maybe<IntegrationMutationResponse>;
  /** delete single row from the table: "server_integration" */
  deleteIntegrationByPk?: Maybe<Integration>;
  /** delete data from the table: "server_internalstaff" */
  deleteInternalstaff?: Maybe<InternalstaffMutationResponse>;
  /** delete single row from the table: "server_internalstaff" */
  deleteInternalstaffByPk?: Maybe<Internalstaff>;
  /** delete data from the table: "server_order" */
  deleteLegacyOrder?: Maybe<LegacyOrderMutationResponse>;
  /** delete single row from the table: "server_order" */
  deleteLegacyOrderByPk?: Maybe<LegacyOrder>;
  /** delete data from the table: "server_orderfrequency" */
  deleteLegacyOrderFrequency?: Maybe<LegacyOrderFrequencyMutationResponse>;
  /** delete single row from the table: "server_orderfrequency" */
  deleteLegacyOrderFrequencyByPk?: Maybe<LegacyOrderFrequency>;
  /** delete data from the table: "server_order_medications" */
  deleteLegacyOrderMedications?: Maybe<LegacyOrderMedicationsMutationResponse>;
  /** delete single row from the table: "server_order_medications" */
  deleteLegacyOrderMedicationsByPk?: Maybe<LegacyOrderMedications>;
  /** delete data from the table: "server_orderorderablefieldvalue" */
  deleteLegacyOrderOrderableFieldValue?: Maybe<LegacyOrderOrderableFieldValueMutationResponse>;
  /** delete single row from the table: "server_orderorderablefieldvalue" */
  deleteLegacyOrderOrderableFieldValueByPk?: Maybe<LegacyOrderOrderableFieldValue>;
  /** delete data from the table: "server_orderqualification" */
  deleteLegacyOrderQualification?: Maybe<LegacyOrderQualificationMutationResponse>;
  /** delete single row from the table: "server_orderqualification" */
  deleteLegacyOrderQualificationByPk?: Maybe<LegacyOrderQualification>;
  /** delete data from the table: "server_treatmentplanorder" */
  deleteLegacyTreatmentplanOrder?: Maybe<LegacyTreatmentplanOrderMutationResponse>;
  /** delete single row from the table: "server_treatmentplanorder" */
  deleteLegacyTreatmentplanOrderByPk?: Maybe<LegacyTreatmentplanOrder>;
  /** delete data from the table: "server_lineitem" */
  deleteLineitem?: Maybe<LineitemMutationResponse>;
  /** delete single row from the table: "server_lineitem" */
  deleteLineitemByPk?: Maybe<Lineitem>;
  /** delete data from the table: "server_medication" */
  deleteMedication?: Maybe<MedicationMutationResponse>;
  /** delete single row from the table: "server_medication" */
  deleteMedicationByPk?: Maybe<Medication>;
  /** delete data from the table: "server_medicationorder" */
  deleteMedicationOrder?: Maybe<MedicationOrderMutationResponse>;
  /** delete single row from the table: "server_medicationorder" */
  deleteMedicationOrderByPk?: Maybe<MedicationOrder>;
  /** delete data from the table: "server_medicationorder_medications" */
  deleteMedicationorderMedications?: Maybe<MedicationorderMedicationsMutationResponse>;
  /** delete single row from the table: "server_medicationorder_medications" */
  deleteMedicationorderMedicationsByPk?: Maybe<MedicationorderMedications>;
  /** delete data from the table: "server_message" */
  deleteMessage?: Maybe<MessageMutationResponse>;
  /** delete single row from the table: "server_message" */
  deleteMessageByPk?: Maybe<Message>;
  /** delete data from the table: "server_orderorderablefield" */
  deleteOrderOrderableField?: Maybe<OrderOrderableFieldMutationResponse>;
  /** delete single row from the table: "server_orderorderablefield" */
  deleteOrderOrderableFieldByPk?: Maybe<OrderOrderableField>;
  /** delete data from the table: "server_orderorderable" */
  deleteOrderOrderableLegacy?: Maybe<OrderOrderableLegacyMutationResponse>;
  /** delete single row from the table: "server_orderorderable" */
  deleteOrderOrderableLegacyByPk?: Maybe<OrderOrderableLegacy>;
  /** delete data from the table: "server_orderable" */
  deleteOrderable?: Maybe<OrderableMutationResponse>;
  /** delete single row from the table: "server_orderable" */
  deleteOrderableByPk?: Maybe<Orderable>;
  /** delete data from the table: "server_orderablecategory" */
  deleteOrderableCategory?: Maybe<OrderableCategoryMutationResponse>;
  /** delete single row from the table: "server_orderablecategory" */
  deleteOrderableCategoryByPk?: Maybe<OrderableCategory>;
  /** delete data from the table: "server_orderableorder" */
  deleteOrderableOrder?: Maybe<OrderableOrderMutationResponse>;
  /** delete single row from the table: "server_orderableorder" */
  deleteOrderableOrderByPk?: Maybe<OrderableOrder>;
  /** delete data from the table: "server_orderableorderqualification" */
  deleteOrderableOrderQualification?: Maybe<OrderableOrderQualificationMutationResponse>;
  /** delete single row from the table: "server_orderableorderqualification" */
  deleteOrderableOrderQualificationByPk?: Maybe<OrderableOrderQualification>;
  /** delete data from the table: "server_orderablequalification" */
  deleteOrderableQualification?: Maybe<OrderableQualificationMutationResponse>;
  /** delete single row from the table: "server_orderablequalification" */
  deleteOrderableQualificationByPk?: Maybe<OrderableQualification>;
  /** delete data from the table: "server_orderablesupplier" */
  deleteOrderableSupplier?: Maybe<OrderableSupplierMutationResponse>;
  /** delete single row from the table: "server_orderablesupplier" */
  deleteOrderableSupplierByPk?: Maybe<OrderableSupplier>;
  /** delete data from the table: "server_orderablesupplier_payers" */
  deleteOrderableSupplierPayerConnections?: Maybe<OrderableSupplierPayerConnectionsMutationResponse>;
  /** delete single row from the table: "server_orderablesupplier_payers" */
  deleteOrderableSupplierPayerConnectionsByPk?: Maybe<OrderableSupplierPayerConnections>;
  /** delete data from the table: "server_orderaction" */
  deleteOrderaction?: Maybe<OrderactionMutationResponse>;
  /** delete single row from the table: "server_orderaction" */
  deleteOrderactionByPk?: Maybe<Orderaction>;
  /** delete data from the table: "server_patient" */
  deletePatient?: Maybe<PatientMutationResponse>;
  /** delete data from the table: "server_patientalias" */
  deletePatientAlias?: Maybe<PatientAliasMutationResponse>;
  /** delete single row from the table: "server_patientalias" */
  deletePatientAliasByPk?: Maybe<PatientAlias>;
  /** delete single row from the table: "server_patient" */
  deletePatientByPk?: Maybe<Patient>;
  /** delete data from the table: "server_patientcommunication" */
  deletePatientCommunication?: Maybe<PatientCommunicationMutationResponse>;
  /** delete single row from the table: "server_patientcommunication" */
  deletePatientCommunicationByPk?: Maybe<PatientCommunication>;
  /** delete data from the table: "server_patientcontactperson" */
  deletePatientContactPerson?: Maybe<PatientContactPersonMutationResponse>;
  /** delete single row from the table: "server_patientcontactperson" */
  deletePatientContactPersonByPk?: Maybe<PatientContactPerson>;
  /** delete data from the table: "server_patienteligibilitycheck" */
  deletePatientEligibilityCheck?: Maybe<PatientEligibilityCheckMutationResponse>;
  /** delete single row from the table: "server_patienteligibilitycheck" */
  deletePatientEligibilityCheckByPk?: Maybe<PatientEligibilityCheck>;
  /** delete data from the table: "server_patientintake" */
  deletePatientIntake?: Maybe<PatientIntakeMutationResponse>;
  /** delete single row from the table: "server_patientintake" */
  deletePatientIntakeByPk?: Maybe<PatientIntake>;
  /** delete data from the table: "server_patientintake_records_locations" */
  deletePatientIntakeRecordsLocations?: Maybe<PatientIntakeRecordsLocationsMutationResponse>;
  /** delete single row from the table: "server_patientintake_records_locations" */
  deletePatientIntakeRecordsLocationsByPk?: Maybe<PatientIntakeRecordsLocations>;
  /** delete data from the table: "server_patientphone" */
  deletePatientPhone?: Maybe<PatientPhoneMutationResponse>;
  /** delete single row from the table: "server_patientphone" */
  deletePatientPhoneByPk?: Maybe<PatientPhone>;
  /** delete data from the table: "server_patientphoto" */
  deletePatientPhoto?: Maybe<PatientPhotoMutationResponse>;
  /** delete single row from the table: "server_patientphoto" */
  deletePatientPhotoByPk?: Maybe<PatientPhoto>;
  /** delete data from the table: "server_patientstatus" */
  deletePatientStatus?: Maybe<PatientStatusMutationResponse>;
  /** delete data from the table: "server_patientstatusaction" */
  deletePatientStatusAction?: Maybe<PatientStatusActionMutationResponse>;
  /** delete single row from the table: "server_patientstatusaction" */
  deletePatientStatusActionByPk?: Maybe<PatientStatusAction>;
  /** delete single row from the table: "server_patientstatus" */
  deletePatientStatusByPk?: Maybe<PatientStatus>;
  /** delete data from the table: "server_patientstatus_subsequent_statuses" */
  deletePatientStatusSubsequentStatuses?: Maybe<PatientStatusSubsequentStatusesMutationResponse>;
  /** delete single row from the table: "server_patientstatus_subsequent_statuses" */
  deletePatientStatusSubsequentStatusesByPk?: Maybe<PatientStatusSubsequentStatuses>;
  /** delete data from the table: "server_payer" */
  deletePayer?: Maybe<PayerMutationResponse>;
  /** delete single row from the table: "server_payer" */
  deletePayerByPk?: Maybe<Payer>;
  /** delete data from the table: "server_practicegroup" */
  deletePracticegroup?: Maybe<PracticegroupMutationResponse>;
  /** delete single row from the table: "server_practicegroup" */
  deletePracticegroupByPk?: Maybe<Practicegroup>;
  /** delete data from the table: "server_problem" */
  deleteProblem?: Maybe<ProblemMutationResponse>;
  /** delete single row from the table: "server_problem" */
  deleteProblemByPk?: Maybe<Problem>;
  /** delete data from the table: "server_problemplan" */
  deleteProblemPlan?: Maybe<ProblemPlanMutationResponse>;
  /** delete single row from the table: "server_problemplan" */
  deleteProblemPlanByPk?: Maybe<ProblemPlan>;
  /** delete data from the table: "server_procedure" */
  deleteProcedure?: Maybe<ProcedureMutationResponse>;
  /** delete single row from the table: "server_procedure" */
  deleteProcedureByPk?: Maybe<Procedure>;
  /** delete data from the table: "server_procedure_hcpcs" */
  deleteProcedureHCPCS?: Maybe<ProcedureHcpcsMutationResponse>;
  /** delete single row from the table: "server_procedure_hcpcs" */
  deleteProcedureHCPCSByPk?: Maybe<ProcedureHcpcs>;
  /** delete data from the table: "server_procedurerendered" */
  deleteProcedureRendered?: Maybe<ProcedureRenderedMutationResponse>;
  /** delete single row from the table: "server_procedurerendered" */
  deleteProcedureRenderedByPk?: Maybe<ProcedureRendered>;
  /** delete data from the table: "server_provider" */
  deleteProvider?: Maybe<ProviderMutationResponse>;
  /** delete single row from the table: "server_provider" */
  deleteProviderByPk?: Maybe<Provider>;
  /** delete data from the table: "server_providerclassification" */
  deleteProviderClassification?: Maybe<ProviderClassificationMutationResponse>;
  /** delete single row from the table: "server_providerclassification" */
  deleteProviderClassificationByPk?: Maybe<ProviderClassification>;
  /** delete data from the table: "server_providerschedule" */
  deleteProviderSchedule?: Maybe<ProviderScheduleMutationResponse>;
  /** delete single row from the table: "server_providerschedule" */
  deleteProviderScheduleByPk?: Maybe<ProviderSchedule>;
  /** delete data from the table: "server_recordsrequestdestination" */
  deleteRecordsRequestDestination?: Maybe<RecordsRequestDestinationMutationResponse>;
  /** delete single row from the table: "server_recordsrequestdestination" */
  deleteRecordsRequestDestinationByPk?: Maybe<RecordsRequestDestination>;
  /** delete data from the table: "server_referral" */
  deleteReferral?: Maybe<ReferralMutationResponse>;
  /** delete data from the table: "server_referral_attachments" */
  deleteReferralAttachments?: Maybe<ReferralAttachmentsMutationResponse>;
  /** delete single row from the table: "server_referral_attachments" */
  deleteReferralAttachmentsByPk?: Maybe<ReferralAttachments>;
  /** delete single row from the table: "server_referral" */
  deleteReferralByPk?: Maybe<Referral>;
  /** delete data from the table: "server_renderedassessment" */
  deleteRenderedAssessment?: Maybe<RenderedAssessmentMutationResponse>;
  /** delete single row from the table: "server_renderedassessment" */
  deleteRenderedAssessmentByPk?: Maybe<RenderedAssessment>;
  /** delete data from the table: "server_schedulingaction" */
  deleteSchedulingAction?: Maybe<SchedulingActionMutationResponse>;
  /** delete single row from the table: "server_schedulingaction" */
  deleteSchedulingActionByPk?: Maybe<SchedulingAction>;
  /** delete data from the table: "server_servicetype" */
  deleteServiceType?: Maybe<ServiceTypeMutationResponse>;
  /** delete single row from the table: "server_servicetype" */
  deleteServiceTypeByPk?: Maybe<ServiceType>;
  /** delete data from the table: "server_signedconsent" */
  deleteSignedConsent?: Maybe<SignedConsentMutationResponse>;
  /** delete single row from the table: "server_signedconsent" */
  deleteSignedConsentByPk?: Maybe<SignedConsent>;
  /** delete data from the table: "server_signoff" */
  deleteSignoff?: Maybe<SignoffMutationResponse>;
  /** delete single row from the table: "server_signoff" */
  deleteSignoffByPk?: Maybe<Signoff>;
  /** delete data from the table: "server_signofftype" */
  deleteSignoffType?: Maybe<SignoffTypeMutationResponse>;
  /** delete single row from the table: "server_signofftype" */
  deleteSignoffTypeByPk?: Maybe<SignoffType>;
  /** delete data from the table: "server_telemedappointmentrequest" */
  deleteTelemedAppointmentRequest?: Maybe<TelemedAppointmentRequestMutationResponse>;
  /** delete data from the table: "server_telemedappointmentrequestaccesscode" */
  deleteTelemedAppointmentRequestAccessCode?: Maybe<TelemedAppointmentRequestAccessCodeMutationResponse>;
  /** delete single row from the table: "server_telemedappointmentrequestaccesscode" */
  deleteTelemedAppointmentRequestAccessCodeByPk?: Maybe<TelemedAppointmentRequestAccessCode>;
  /** delete single row from the table: "server_telemedappointmentrequest" */
  deleteTelemedAppointmentRequestByPk?: Maybe<TelemedAppointmentRequest>;
  /** delete data from the table: "server_threadcategory" */
  deleteThreadCategory?: Maybe<ThreadCategoryMutationResponse>;
  /** delete single row from the table: "server_threadcategory" */
  deleteThreadCategoryByPk?: Maybe<ThreadCategory>;
  /** delete data from the table: "server_threadcategorystatus" */
  deleteThreadCategoryStatus?: Maybe<ThreadCategoryStatusMutationResponse>;
  /** delete single row from the table: "server_threadcategorystatus" */
  deleteThreadCategoryStatusByPk?: Maybe<ThreadCategoryStatus>;
  /** delete data from the table: "server_threadfield" */
  deleteThreadField?: Maybe<ThreadFieldMutationResponse>;
  /** delete single row from the table: "server_threadfield" */
  deleteThreadFieldByPk?: Maybe<ThreadField>;
  /** delete data from the table: "server_threadfieldvalue" */
  deleteThreadFieldValue?: Maybe<ThreadFieldValueMutationResponse>;
  /** delete single row from the table: "server_threadfieldvalue" */
  deleteThreadFieldValueByPk?: Maybe<ThreadFieldValue>;
  /** delete data from the table: "server_ticketsubscription" */
  deleteTicketSubscription?: Maybe<TicketSubscriptionMutationResponse>;
  /** delete single row from the table: "server_ticketsubscription" */
  deleteTicketSubscriptionByPk?: Maybe<TicketSubscription>;
  /** delete data from the table: "server_ticketthread" */
  deleteTicketThread?: Maybe<TicketThreadMutationResponse>;
  /** delete single row from the table: "server_ticketthread" */
  deleteTicketThreadByPk?: Maybe<TicketThread>;
  /** delete data from the table: "server_treatmentplan" */
  deleteTreatmentplan?: Maybe<TreatmentplanMutationResponse>;
  /** delete data from the table: "server_treatmentplanassessment" */
  deleteTreatmentplanAssessment?: Maybe<TreatmentplanAssessmentMutationResponse>;
  /** delete single row from the table: "server_treatmentplanassessment" */
  deleteTreatmentplanAssessmentByPk?: Maybe<TreatmentplanAssessment>;
  /** delete single row from the table: "server_treatmentplan" */
  deleteTreatmentplanByPk?: Maybe<Treatmentplan>;
  /** delete data from the table: "server_treatmentplannote" */
  deleteTreatmentplanNote?: Maybe<TreatmentplanNoteMutationResponse>;
  /** delete single row from the table: "server_treatmentplannote" */
  deleteTreatmentplanNoteByPk?: Maybe<TreatmentplanNote>;
  /** delete data from the table: "server_treatmentplan_problems" */
  deleteTreatmentplanProblems?: Maybe<TreatmentplanProblemsMutationResponse>;
  /** delete single row from the table: "server_treatmentplan_problems" */
  deleteTreatmentplanProblemsByPk?: Maybe<TreatmentplanProblems>;
  /** delete data from the table: "server_treatmentplantemplate" */
  deleteTreatmentplanTemplate?: Maybe<TreatmentplanTemplateMutationResponse>;
  /** delete data from the table: "server_treatmentplantemplate_assessments" */
  deleteTreatmentplanTemplateAssessment?: Maybe<TreatmentplanTemplateAssessmentMutationResponse>;
  /** delete single row from the table: "server_treatmentplantemplate_assessments" */
  deleteTreatmentplanTemplateAssessmentByPk?: Maybe<TreatmentplanTemplateAssessment>;
  /** delete single row from the table: "server_treatmentplantemplate" */
  deleteTreatmentplanTemplateByPk?: Maybe<TreatmentplanTemplate>;
  /** delete data from the table: "server_customuser" */
  deleteUser?: Maybe<UserMutationResponse>;
  /** delete single row from the table: "server_customuser" */
  deleteUserByPk?: Maybe<User>;
  /** delete data from the table: "server_customuser_groups" */
  deleteUserGroups?: Maybe<UserGroupsMutationResponse>;
  /** delete single row from the table: "server_customuser_groups" */
  deleteUserGroupsByPk?: Maybe<UserGroups>;
  /** delete data from the table: "server_customuser_user_permissions" */
  deleteUserPermissions?: Maybe<UserPermissionsMutationResponse>;
  /** delete single row from the table: "server_customuser_user_permissions" */
  deleteUserPermissionsByPk?: Maybe<UserPermissions>;
  /** delete data from the table: "server_visitrebill" */
  deleteVisitRebill?: Maybe<VisitRebillMutationResponse>;
  /** delete single row from the table: "server_visitrebill" */
  deleteVisitRebillByPk?: Maybe<VisitRebill>;
  /** delete data from the table: "server_vitals" */
  deleteVitals?: Maybe<VitalsMutationResponse>;
  /** delete single row from the table: "server_vitals" */
  deleteVitalsByPk?: Maybe<Vitals>;
  /** insert data into the table: "server_address" */
  insertAddress?: Maybe<AddressMutationResponse>;
  /** insert a single row into the table: "server_address" */
  insertAddressOne?: Maybe<Address>;
  /** insert data into the table: "server_agency" */
  insertAgency?: Maybe<AgencyMutationResponse>;
  /** insert data into the table: "server_agencylocation" */
  insertAgencyLocation?: Maybe<AgencyLocationMutationResponse>;
  /** insert a single row into the table: "server_agencylocation" */
  insertAgencyLocationOne?: Maybe<AgencyLocation>;
  /** insert a single row into the table: "server_agency" */
  insertAgencyOne?: Maybe<Agency>;
  /** insert data into the table: "server_agency_payers" */
  insertAgencyPayers?: Maybe<AgencyPayersMutationResponse>;
  /** insert a single row into the table: "server_agency_payers" */
  insertAgencyPayersOne?: Maybe<AgencyPayers>;
  /** insert data into the table: "server_agency_services" */
  insertAgencyServices?: Maybe<AgencyServicesMutationResponse>;
  /** insert a single row into the table: "server_agency_services" */
  insertAgencyServicesOne?: Maybe<AgencyServices>;
  /** insert data into the table: "server_agencyconnection" */
  insertAgencyconnection?: Maybe<AgencyconnectionMutationResponse>;
  /** insert a single row into the table: "server_agencyconnection" */
  insertAgencyconnectionOne?: Maybe<Agencyconnection>;
  /** insert data into the table: "server_agencyjoinrequest" */
  insertAgencyjoinrequest?: Maybe<AgencyjoinrequestMutationResponse>;
  /** insert a single row into the table: "server_agencyjoinrequest" */
  insertAgencyjoinrequestOne?: Maybe<Agencyjoinrequest>;
  /** insert data into the table: "server_allergy" */
  insertAllergy?: Maybe<AllergyMutationResponse>;
  /** insert a single row into the table: "server_allergy" */
  insertAllergyOne?: Maybe<Allergy>;
  /** insert data into the table: "server_appointment" */
  insertAppointment?: Maybe<AppointmentMutationResponse>;
  /** insert a single row into the table: "server_appointment" */
  insertAppointmentOne?: Maybe<Appointment>;
  /** insert data into the table: "server_assessment" */
  insertAssessment?: Maybe<AssessmentMutationResponse>;
  /** insert data into the table: "server_assessmentitem" */
  insertAssessmentItem?: Maybe<AssessmentItemMutationResponse>;
  /** insert a single row into the table: "server_assessmentitem" */
  insertAssessmentItemOne?: Maybe<AssessmentItem>;
  /** insert data into the table: "server_assessmentitemvalue" */
  insertAssessmentItemValue?: Maybe<AssessmentItemValueMutationResponse>;
  /** insert a single row into the table: "server_assessmentitemvalue" */
  insertAssessmentItemValueOne?: Maybe<AssessmentItemValue>;
  /** insert a single row into the table: "server_assessment" */
  insertAssessmentOne?: Maybe<Assessment>;
  /** insert data into the table: "server_baseprotocolorder" */
  insertBaseProtocolOrder?: Maybe<BaseProtocolOrderMutationResponse>;
  /** insert a single row into the table: "server_baseprotocolorder" */
  insertBaseProtocolOrderOne?: Maybe<BaseProtocolOrder>;
  /** insert data into the table: "server_baseorder" */
  insertBaseorder?: Maybe<BaseorderMutationResponse>;
  /** insert a single row into the table: "server_baseorder" */
  insertBaseorderOne?: Maybe<Baseorder>;
  /** insert data into the table: "server_caretime" */
  insertCaretime?: Maybe<CaretimeMutationResponse>;
  /** insert a single row into the table: "server_caretime" */
  insertCaretimeOne?: Maybe<Caretime>;
  /** insert data into the table: "server_caretime_problems" */
  insertCaretimeProblems?: Maybe<CaretimeProblemsMutationResponse>;
  /** insert a single row into the table: "server_caretime_problems" */
  insertCaretimeProblemsOne?: Maybe<CaretimeProblems>;
  /** insert data into the table: "server_claim" */
  insertClaim?: Maybe<ClaimMutationResponse>;
  /** insert a single row into the table: "server_claim" */
  insertClaimOne?: Maybe<Claim>;
  /** insert data into the table: "server_futurereferralclinicalnote" */
  insertClinicalNoteFutureReferrals?: Maybe<ClinicalNoteFutureReferralsMutationResponse>;
  /** insert a single row into the table: "server_futurereferralclinicalnote" */
  insertClinicalNoteFutureReferralsOne?: Maybe<ClinicalNoteFutureReferrals>;
  /** insert data into the table: "server_clinicalnote" */
  insertClinicalnote?: Maybe<ClinicalnoteMutationResponse>;
  /** insert data into the table: "server_clinicalnotefield" */
  insertClinicalnoteField?: Maybe<ClinicalnoteFieldMutationResponse>;
  /** insert a single row into the table: "server_clinicalnotefield" */
  insertClinicalnoteFieldOne?: Maybe<ClinicalnoteField>;
  /** insert data into the table: "server_clinicalnotefieldvalue" */
  insertClinicalnoteFieldValue?: Maybe<ClinicalnoteFieldValueMutationResponse>;
  /** insert a single row into the table: "server_clinicalnotefieldvalue" */
  insertClinicalnoteFieldValueOne?: Maybe<ClinicalnoteFieldValue>;
  /** insert a single row into the table: "server_clinicalnote" */
  insertClinicalnoteOne?: Maybe<Clinicalnote>;
  /** insert data into the table: "server_clinicalnotetemplate" */
  insertClinicalnoteTemplate?: Maybe<ClinicalnoteTemplateMutationResponse>;
  /** insert a single row into the table: "server_clinicalnotetemplate" */
  insertClinicalnoteTemplateOne?: Maybe<ClinicalnoteTemplate>;
  /** insert data into the table: "server_clinicalnotetemplate_provider_classifications" */
  insertClinicalnoteTemplateProviderClassifications?: Maybe<ClinicalnoteTemplateProviderClassificationsMutationResponse>;
  /** insert a single row into the table: "server_clinicalnotetemplate_provider_classifications" */
  insertClinicalnoteTemplateProviderClassificationsOne?: Maybe<ClinicalnoteTemplateProviderClassifications>;
  /** insert data into the table: "server_codeset" */
  insertCodeset?: Maybe<CodesetMutationResponse>;
  /** insert data into the table: "server_codeset_addon_codeset" */
  insertCodesetAddon?: Maybe<CodesetAddonMutationResponse>;
  /** insert a single row into the table: "server_codeset_addon_codeset" */
  insertCodesetAddonOne?: Maybe<CodesetAddon>;
  /** insert data into the table: "server_codeset_base_codeset" */
  insertCodesetBase?: Maybe<CodesetBaseMutationResponse>;
  /** insert a single row into the table: "server_codeset_base_codeset" */
  insertCodesetBaseOne?: Maybe<CodesetBase>;
  /** insert a single row into the table: "server_codeset" */
  insertCodesetOne?: Maybe<Codeset>;
  /** insert data into the table: "server_consent" */
  insertConsent?: Maybe<ConsentMutationResponse>;
  /** insert a single row into the table: "server_consent" */
  insertConsentOne?: Maybe<Consent>;
  /** insert data into the table: "server_cpoclaim" */
  insertCpoClaim?: Maybe<CpoClaimMutationResponse>;
  /** insert a single row into the table: "server_cpoclaim" */
  insertCpoClaimOne?: Maybe<CpoClaim>;
  /** insert data into the table: "server_dependantorder" */
  insertDependantOrder?: Maybe<DependantOrderMutationResponse>;
  /** insert a single row into the table: "server_dependantorder" */
  insertDependantOrderOne?: Maybe<DependantOrder>;
  /** insert data into the table: "server_diagnosisabbreviation" */
  insertDiagnosisAbbreviation?: Maybe<DiagnosisAbbreviationMutationResponse>;
  /** insert a single row into the table: "server_diagnosisabbreviation" */
  insertDiagnosisAbbreviationOne?: Maybe<DiagnosisAbbreviation>;
  /** insert data into the table: "server_document" */
  insertDocument?: Maybe<DocumentMutationResponse>;
  /** insert a single row into the table: "server_document" */
  insertDocumentOne?: Maybe<Document>;
  /** insert data into the table: "server_documentpendingreview" */
  insertDocumentPendingReview?: Maybe<DocumentPendingReviewMutationResponse>;
  /** insert a single row into the table: "server_documentpendingreview" */
  insertDocumentPendingReviewOne?: Maybe<DocumentPendingReview>;
  /** insert data into the table: "server_externalsignedorder" */
  insertExternalSignedOrder?: Maybe<ExternalSignedOrderMutationResponse>;
  /** insert a single row into the table: "server_externalsignedorder" */
  insertExternalSignedOrderOne?: Maybe<ExternalSignedOrder>;
  /** insert data into the table: "server_externalstaff_my_patients" */
  insertExternalStaffMyPatients?: Maybe<ExternalStaffMyPatientsMutationResponse>;
  /** insert a single row into the table: "server_externalstaff_my_patients" */
  insertExternalStaffMyPatientsOne?: Maybe<ExternalStaffMyPatients>;
  /** insert data into the table: "server_externalstaff" */
  insertExternalstaff?: Maybe<ExternalstaffMutationResponse>;
  /** insert a single row into the table: "server_externalstaff" */
  insertExternalstaffOne?: Maybe<Externalstaff>;
  /** insert data into the table: "server_fax" */
  insertFax?: Maybe<FaxMutationResponse>;
  /** insert data into the table: "server_fax_documents" */
  insertFaxDocuments?: Maybe<FaxDocumentsMutationResponse>;
  /** insert a single row into the table: "server_fax_documents" */
  insertFaxDocumentsOne?: Maybe<FaxDocuments>;
  /** insert a single row into the table: "server_fax" */
  insertFaxOne?: Maybe<Fax>;
  /** insert data into the table: "server_freetextorder" */
  insertFreeTextOrder?: Maybe<FreeTextOrderMutationResponse>;
  /** insert a single row into the table: "server_freetextorder" */
  insertFreeTextOrderOne?: Maybe<FreeTextOrder>;
  /** insert data into the table: "server_frequencyorder" */
  insertFrequencyOrder?: Maybe<FrequencyOrderMutationResponse>;
  /** insert a single row into the table: "server_frequencyorder" */
  insertFrequencyOrderOne?: Maybe<FrequencyOrder>;
  /** insert data into the table: "server_frequencyprotocol" */
  insertFrequencyProtocol?: Maybe<FrequencyProtocolMutationResponse>;
  /** insert a single row into the table: "server_frequencyprotocol" */
  insertFrequencyProtocolOne?: Maybe<FrequencyProtocol>;
  /** insert data into the table: "server_hcpcs" */
  insertHcpcs?: Maybe<HcpcsMutationResponse>;
  /** insert a single row into the table: "server_hcpcs" */
  insertHcpcsOne?: Maybe<Hcpcs>;
  /** insert data into the table: "server_insuranceplan" */
  insertInsuranceplan?: Maybe<InsuranceplanMutationResponse>;
  /** insert a single row into the table: "server_insuranceplan" */
  insertInsuranceplanOne?: Maybe<Insuranceplan>;
  /** insert data into the table: "server_integration" */
  insertIntegration?: Maybe<IntegrationMutationResponse>;
  /** insert a single row into the table: "server_integration" */
  insertIntegrationOne?: Maybe<Integration>;
  /** insert data into the table: "server_internalstaff" */
  insertInternalstaff?: Maybe<InternalstaffMutationResponse>;
  /** insert a single row into the table: "server_internalstaff" */
  insertInternalstaffOne?: Maybe<Internalstaff>;
  /** insert data into the table: "server_order" */
  insertLegacyOrder?: Maybe<LegacyOrderMutationResponse>;
  /** insert data into the table: "server_orderfrequency" */
  insertLegacyOrderFrequency?: Maybe<LegacyOrderFrequencyMutationResponse>;
  /** insert a single row into the table: "server_orderfrequency" */
  insertLegacyOrderFrequencyOne?: Maybe<LegacyOrderFrequency>;
  /** insert data into the table: "server_order_medications" */
  insertLegacyOrderMedications?: Maybe<LegacyOrderMedicationsMutationResponse>;
  /** insert a single row into the table: "server_order_medications" */
  insertLegacyOrderMedicationsOne?: Maybe<LegacyOrderMedications>;
  /** insert a single row into the table: "server_order" */
  insertLegacyOrderOne?: Maybe<LegacyOrder>;
  /** insert data into the table: "server_orderorderablefieldvalue" */
  insertLegacyOrderOrderableFieldValue?: Maybe<LegacyOrderOrderableFieldValueMutationResponse>;
  /** insert a single row into the table: "server_orderorderablefieldvalue" */
  insertLegacyOrderOrderableFieldValueOne?: Maybe<LegacyOrderOrderableFieldValue>;
  /** insert data into the table: "server_orderqualification" */
  insertLegacyOrderQualification?: Maybe<LegacyOrderQualificationMutationResponse>;
  /** insert a single row into the table: "server_orderqualification" */
  insertLegacyOrderQualificationOne?: Maybe<LegacyOrderQualification>;
  /** insert data into the table: "server_treatmentplanorder" */
  insertLegacyTreatmentplanOrder?: Maybe<LegacyTreatmentplanOrderMutationResponse>;
  /** insert a single row into the table: "server_treatmentplanorder" */
  insertLegacyTreatmentplanOrderOne?: Maybe<LegacyTreatmentplanOrder>;
  /** insert data into the table: "server_lineitem" */
  insertLineitem?: Maybe<LineitemMutationResponse>;
  /** insert a single row into the table: "server_lineitem" */
  insertLineitemOne?: Maybe<Lineitem>;
  /** insert data into the table: "server_medication" */
  insertMedication?: Maybe<MedicationMutationResponse>;
  /** insert a single row into the table: "server_medication" */
  insertMedicationOne?: Maybe<Medication>;
  /** insert data into the table: "server_medicationorder" */
  insertMedicationOrder?: Maybe<MedicationOrderMutationResponse>;
  /** insert a single row into the table: "server_medicationorder" */
  insertMedicationOrderOne?: Maybe<MedicationOrder>;
  /** insert data into the table: "server_medicationorder_medications" */
  insertMedicationorderMedications?: Maybe<MedicationorderMedicationsMutationResponse>;
  /** insert a single row into the table: "server_medicationorder_medications" */
  insertMedicationorderMedicationsOne?: Maybe<MedicationorderMedications>;
  /** insert data into the table: "server_message" */
  insertMessage?: Maybe<MessageMutationResponse>;
  /** insert a single row into the table: "server_message" */
  insertMessageOne?: Maybe<Message>;
  /** insert data into the table: "server_orderorderablefield" */
  insertOrderOrderableField?: Maybe<OrderOrderableFieldMutationResponse>;
  /** insert a single row into the table: "server_orderorderablefield" */
  insertOrderOrderableFieldOne?: Maybe<OrderOrderableField>;
  /** insert data into the table: "server_orderorderable" */
  insertOrderOrderableLegacy?: Maybe<OrderOrderableLegacyMutationResponse>;
  /** insert a single row into the table: "server_orderorderable" */
  insertOrderOrderableLegacyOne?: Maybe<OrderOrderableLegacy>;
  /** insert data into the table: "server_orderable" */
  insertOrderable?: Maybe<OrderableMutationResponse>;
  /** insert data into the table: "server_orderablecategory" */
  insertOrderableCategory?: Maybe<OrderableCategoryMutationResponse>;
  /** insert a single row into the table: "server_orderablecategory" */
  insertOrderableCategoryOne?: Maybe<OrderableCategory>;
  /** insert a single row into the table: "server_orderable" */
  insertOrderableOne?: Maybe<Orderable>;
  /** insert data into the table: "server_orderableorder" */
  insertOrderableOrder?: Maybe<OrderableOrderMutationResponse>;
  /** insert a single row into the table: "server_orderableorder" */
  insertOrderableOrderOne?: Maybe<OrderableOrder>;
  /** insert data into the table: "server_orderableorderqualification" */
  insertOrderableOrderQualification?: Maybe<OrderableOrderQualificationMutationResponse>;
  /** insert a single row into the table: "server_orderableorderqualification" */
  insertOrderableOrderQualificationOne?: Maybe<OrderableOrderQualification>;
  /** insert data into the table: "server_orderablequalification" */
  insertOrderableQualification?: Maybe<OrderableQualificationMutationResponse>;
  /** insert a single row into the table: "server_orderablequalification" */
  insertOrderableQualificationOne?: Maybe<OrderableQualification>;
  /** insert data into the table: "server_orderablesupplier" */
  insertOrderableSupplier?: Maybe<OrderableSupplierMutationResponse>;
  /** insert a single row into the table: "server_orderablesupplier" */
  insertOrderableSupplierOne?: Maybe<OrderableSupplier>;
  /** insert data into the table: "server_orderablesupplier_payers" */
  insertOrderableSupplierPayerConnections?: Maybe<OrderableSupplierPayerConnectionsMutationResponse>;
  /** insert a single row into the table: "server_orderablesupplier_payers" */
  insertOrderableSupplierPayerConnectionsOne?: Maybe<OrderableSupplierPayerConnections>;
  /** insert data into the table: "server_orderaction" */
  insertOrderaction?: Maybe<OrderactionMutationResponse>;
  /** insert a single row into the table: "server_orderaction" */
  insertOrderactionOne?: Maybe<Orderaction>;
  /** insert data into the table: "server_patient" */
  insertPatient?: Maybe<PatientMutationResponse>;
  /** insert data into the table: "server_patientalias" */
  insertPatientAlias?: Maybe<PatientAliasMutationResponse>;
  /** insert a single row into the table: "server_patientalias" */
  insertPatientAliasOne?: Maybe<PatientAlias>;
  /** insert data into the table: "server_patientcommunication" */
  insertPatientCommunication?: Maybe<PatientCommunicationMutationResponse>;
  /** insert a single row into the table: "server_patientcommunication" */
  insertPatientCommunicationOne?: Maybe<PatientCommunication>;
  /** insert data into the table: "server_patientcontactperson" */
  insertPatientContactPerson?: Maybe<PatientContactPersonMutationResponse>;
  /** insert a single row into the table: "server_patientcontactperson" */
  insertPatientContactPersonOne?: Maybe<PatientContactPerson>;
  /** insert data into the table: "server_patienteligibilitycheck" */
  insertPatientEligibilityCheck?: Maybe<PatientEligibilityCheckMutationResponse>;
  /** insert a single row into the table: "server_patienteligibilitycheck" */
  insertPatientEligibilityCheckOne?: Maybe<PatientEligibilityCheck>;
  /** insert data into the table: "server_patientintake" */
  insertPatientIntake?: Maybe<PatientIntakeMutationResponse>;
  /** insert a single row into the table: "server_patientintake" */
  insertPatientIntakeOne?: Maybe<PatientIntake>;
  /** insert data into the table: "server_patientintake_records_locations" */
  insertPatientIntakeRecordsLocations?: Maybe<PatientIntakeRecordsLocationsMutationResponse>;
  /** insert a single row into the table: "server_patientintake_records_locations" */
  insertPatientIntakeRecordsLocationsOne?: Maybe<PatientIntakeRecordsLocations>;
  /** insert a single row into the table: "server_patient" */
  insertPatientOne?: Maybe<Patient>;
  /** insert data into the table: "server_patientphone" */
  insertPatientPhone?: Maybe<PatientPhoneMutationResponse>;
  /** insert a single row into the table: "server_patientphone" */
  insertPatientPhoneOne?: Maybe<PatientPhone>;
  /** insert data into the table: "server_patientphoto" */
  insertPatientPhoto?: Maybe<PatientPhotoMutationResponse>;
  /** insert a single row into the table: "server_patientphoto" */
  insertPatientPhotoOne?: Maybe<PatientPhoto>;
  /** insert data into the table: "server_patientstatus" */
  insertPatientStatus?: Maybe<PatientStatusMutationResponse>;
  /** insert data into the table: "server_patientstatusaction" */
  insertPatientStatusAction?: Maybe<PatientStatusActionMutationResponse>;
  /** insert a single row into the table: "server_patientstatusaction" */
  insertPatientStatusActionOne?: Maybe<PatientStatusAction>;
  /** insert a single row into the table: "server_patientstatus" */
  insertPatientStatusOne?: Maybe<PatientStatus>;
  /** insert data into the table: "server_patientstatus_subsequent_statuses" */
  insertPatientStatusSubsequentStatuses?: Maybe<PatientStatusSubsequentStatusesMutationResponse>;
  /** insert a single row into the table: "server_patientstatus_subsequent_statuses" */
  insertPatientStatusSubsequentStatusesOne?: Maybe<PatientStatusSubsequentStatuses>;
  /** insert data into the table: "server_payer" */
  insertPayer?: Maybe<PayerMutationResponse>;
  /** insert a single row into the table: "server_payer" */
  insertPayerOne?: Maybe<Payer>;
  /** insert data into the table: "server_practicegroup" */
  insertPracticegroup?: Maybe<PracticegroupMutationResponse>;
  /** insert a single row into the table: "server_practicegroup" */
  insertPracticegroupOne?: Maybe<Practicegroup>;
  /** insert data into the table: "server_problem" */
  insertProblem?: Maybe<ProblemMutationResponse>;
  /** insert a single row into the table: "server_problem" */
  insertProblemOne?: Maybe<Problem>;
  /** insert data into the table: "server_problemplan" */
  insertProblemPlan?: Maybe<ProblemPlanMutationResponse>;
  /** insert a single row into the table: "server_problemplan" */
  insertProblemPlanOne?: Maybe<ProblemPlan>;
  /** insert data into the table: "server_procedure" */
  insertProcedure?: Maybe<ProcedureMutationResponse>;
  /** insert data into the table: "server_procedure_hcpcs" */
  insertProcedureHCPCS?: Maybe<ProcedureHcpcsMutationResponse>;
  /** insert a single row into the table: "server_procedure_hcpcs" */
  insertProcedureHCPCSOne?: Maybe<ProcedureHcpcs>;
  /** insert a single row into the table: "server_procedure" */
  insertProcedureOne?: Maybe<Procedure>;
  /** insert data into the table: "server_procedurerendered" */
  insertProcedureRendered?: Maybe<ProcedureRenderedMutationResponse>;
  /** insert a single row into the table: "server_procedurerendered" */
  insertProcedureRenderedOne?: Maybe<ProcedureRendered>;
  /** insert data into the table: "server_provider" */
  insertProvider?: Maybe<ProviderMutationResponse>;
  /** insert data into the table: "server_providerclassification" */
  insertProviderClassification?: Maybe<ProviderClassificationMutationResponse>;
  /** insert a single row into the table: "server_providerclassification" */
  insertProviderClassificationOne?: Maybe<ProviderClassification>;
  /** insert a single row into the table: "server_provider" */
  insertProviderOne?: Maybe<Provider>;
  /** insert data into the table: "server_providerschedule" */
  insertProviderSchedule?: Maybe<ProviderScheduleMutationResponse>;
  /** insert a single row into the table: "server_providerschedule" */
  insertProviderScheduleOne?: Maybe<ProviderSchedule>;
  /** insert data into the table: "server_recordsrequestdestination" */
  insertRecordsRequestDestination?: Maybe<RecordsRequestDestinationMutationResponse>;
  /** insert a single row into the table: "server_recordsrequestdestination" */
  insertRecordsRequestDestinationOne?: Maybe<RecordsRequestDestination>;
  /** insert data into the table: "server_referral" */
  insertReferral?: Maybe<ReferralMutationResponse>;
  /** insert data into the table: "server_referral_attachments" */
  insertReferralAttachments?: Maybe<ReferralAttachmentsMutationResponse>;
  /** insert a single row into the table: "server_referral_attachments" */
  insertReferralAttachmentsOne?: Maybe<ReferralAttachments>;
  /** insert a single row into the table: "server_referral" */
  insertReferralOne?: Maybe<Referral>;
  /** insert data into the table: "server_renderedassessment" */
  insertRenderedAssessment?: Maybe<RenderedAssessmentMutationResponse>;
  /** insert a single row into the table: "server_renderedassessment" */
  insertRenderedAssessmentOne?: Maybe<RenderedAssessment>;
  /** insert data into the table: "server_schedulingaction" */
  insertSchedulingAction?: Maybe<SchedulingActionMutationResponse>;
  /** insert a single row into the table: "server_schedulingaction" */
  insertSchedulingActionOne?: Maybe<SchedulingAction>;
  /** insert data into the table: "server_servicetype" */
  insertServiceType?: Maybe<ServiceTypeMutationResponse>;
  /** insert a single row into the table: "server_servicetype" */
  insertServiceTypeOne?: Maybe<ServiceType>;
  /** insert data into the table: "server_signedconsent" */
  insertSignedConsent?: Maybe<SignedConsentMutationResponse>;
  /** insert a single row into the table: "server_signedconsent" */
  insertSignedConsentOne?: Maybe<SignedConsent>;
  /** insert data into the table: "server_signoff" */
  insertSignoff?: Maybe<SignoffMutationResponse>;
  /** insert a single row into the table: "server_signoff" */
  insertSignoffOne?: Maybe<Signoff>;
  /** insert data into the table: "server_signofftype" */
  insertSignoffType?: Maybe<SignoffTypeMutationResponse>;
  /** insert a single row into the table: "server_signofftype" */
  insertSignoffTypeOne?: Maybe<SignoffType>;
  /** insert data into the table: "server_telemedappointmentrequest" */
  insertTelemedAppointmentRequest?: Maybe<TelemedAppointmentRequestMutationResponse>;
  /** insert data into the table: "server_telemedappointmentrequestaccesscode" */
  insertTelemedAppointmentRequestAccessCode?: Maybe<TelemedAppointmentRequestAccessCodeMutationResponse>;
  /** insert a single row into the table: "server_telemedappointmentrequestaccesscode" */
  insertTelemedAppointmentRequestAccessCodeOne?: Maybe<TelemedAppointmentRequestAccessCode>;
  /** insert a single row into the table: "server_telemedappointmentrequest" */
  insertTelemedAppointmentRequestOne?: Maybe<TelemedAppointmentRequest>;
  /** insert data into the table: "server_threadcategory" */
  insertThreadCategory?: Maybe<ThreadCategoryMutationResponse>;
  /** insert a single row into the table: "server_threadcategory" */
  insertThreadCategoryOne?: Maybe<ThreadCategory>;
  /** insert data into the table: "server_threadcategorystatus" */
  insertThreadCategoryStatus?: Maybe<ThreadCategoryStatusMutationResponse>;
  /** insert a single row into the table: "server_threadcategorystatus" */
  insertThreadCategoryStatusOne?: Maybe<ThreadCategoryStatus>;
  /** insert data into the table: "server_threadfield" */
  insertThreadField?: Maybe<ThreadFieldMutationResponse>;
  /** insert a single row into the table: "server_threadfield" */
  insertThreadFieldOne?: Maybe<ThreadField>;
  /** insert data into the table: "server_threadfieldvalue" */
  insertThreadFieldValue?: Maybe<ThreadFieldValueMutationResponse>;
  /** insert a single row into the table: "server_threadfieldvalue" */
  insertThreadFieldValueOne?: Maybe<ThreadFieldValue>;
  /** insert data into the table: "server_ticketsubscription" */
  insertTicketSubscription?: Maybe<TicketSubscriptionMutationResponse>;
  /** insert a single row into the table: "server_ticketsubscription" */
  insertTicketSubscriptionOne?: Maybe<TicketSubscription>;
  /** insert data into the table: "server_ticketthread" */
  insertTicketThread?: Maybe<TicketThreadMutationResponse>;
  /** insert a single row into the table: "server_ticketthread" */
  insertTicketThreadOne?: Maybe<TicketThread>;
  /** insert data into the table: "server_treatmentplan" */
  insertTreatmentplan?: Maybe<TreatmentplanMutationResponse>;
  /** insert data into the table: "server_treatmentplanassessment" */
  insertTreatmentplanAssessment?: Maybe<TreatmentplanAssessmentMutationResponse>;
  /** insert a single row into the table: "server_treatmentplanassessment" */
  insertTreatmentplanAssessmentOne?: Maybe<TreatmentplanAssessment>;
  /** insert data into the table: "server_treatmentplannote" */
  insertTreatmentplanNote?: Maybe<TreatmentplanNoteMutationResponse>;
  /** insert a single row into the table: "server_treatmentplannote" */
  insertTreatmentplanNoteOne?: Maybe<TreatmentplanNote>;
  /** insert a single row into the table: "server_treatmentplan" */
  insertTreatmentplanOne?: Maybe<Treatmentplan>;
  /** insert data into the table: "server_treatmentplan_problems" */
  insertTreatmentplanProblems?: Maybe<TreatmentplanProblemsMutationResponse>;
  /** insert a single row into the table: "server_treatmentplan_problems" */
  insertTreatmentplanProblemsOne?: Maybe<TreatmentplanProblems>;
  /** insert data into the table: "server_treatmentplantemplate" */
  insertTreatmentplanTemplate?: Maybe<TreatmentplanTemplateMutationResponse>;
  /** insert data into the table: "server_treatmentplantemplate_assessments" */
  insertTreatmentplanTemplateAssessment?: Maybe<TreatmentplanTemplateAssessmentMutationResponse>;
  /** insert a single row into the table: "server_treatmentplantemplate_assessments" */
  insertTreatmentplanTemplateAssessmentOne?: Maybe<TreatmentplanTemplateAssessment>;
  /** insert a single row into the table: "server_treatmentplantemplate" */
  insertTreatmentplanTemplateOne?: Maybe<TreatmentplanTemplate>;
  /** insert data into the table: "server_customuser" */
  insertUser?: Maybe<UserMutationResponse>;
  /** insert data into the table: "server_customuser_groups" */
  insertUserGroups?: Maybe<UserGroupsMutationResponse>;
  /** insert a single row into the table: "server_customuser_groups" */
  insertUserGroupsOne?: Maybe<UserGroups>;
  /** insert a single row into the table: "server_customuser" */
  insertUserOne?: Maybe<User>;
  /** insert data into the table: "server_customuser_user_permissions" */
  insertUserPermissions?: Maybe<UserPermissionsMutationResponse>;
  /** insert a single row into the table: "server_customuser_user_permissions" */
  insertUserPermissionsOne?: Maybe<UserPermissions>;
  /** insert data into the table: "server_visitrebill" */
  insertVisitRebill?: Maybe<VisitRebillMutationResponse>;
  /** insert a single row into the table: "server_visitrebill" */
  insertVisitRebillOne?: Maybe<VisitRebill>;
  /** insert data into the table: "server_vitals" */
  insertVitals?: Maybe<VitalsMutationResponse>;
  /** insert a single row into the table: "server_vitals" */
  insertVitalsOne?: Maybe<Vitals>;
  /** update data of the table: "server_address" */
  updateAddress?: Maybe<AddressMutationResponse>;
  /** update single row of the table: "server_address" */
  updateAddressByPk?: Maybe<Address>;
  /** update multiples rows of table: "server_address" */
  updateAddressMany?: Maybe<Array<Maybe<AddressMutationResponse>>>;
  /** update data of the table: "server_agency" */
  updateAgency?: Maybe<AgencyMutationResponse>;
  /** update single row of the table: "server_agency" */
  updateAgencyByPk?: Maybe<Agency>;
  /** update data of the table: "server_agencylocation" */
  updateAgencyLocation?: Maybe<AgencyLocationMutationResponse>;
  /** update single row of the table: "server_agencylocation" */
  updateAgencyLocationByPk?: Maybe<AgencyLocation>;
  /** update multiples rows of table: "server_agencylocation" */
  updateAgencyLocationMany?: Maybe<Array<Maybe<AgencyLocationMutationResponse>>>;
  /** update multiples rows of table: "server_agency" */
  updateAgencyMany?: Maybe<Array<Maybe<AgencyMutationResponse>>>;
  /** update data of the table: "server_agency_payers" */
  updateAgencyPayers?: Maybe<AgencyPayersMutationResponse>;
  /** update single row of the table: "server_agency_payers" */
  updateAgencyPayersByPk?: Maybe<AgencyPayers>;
  /** update multiples rows of table: "server_agency_payers" */
  updateAgencyPayersMany?: Maybe<Array<Maybe<AgencyPayersMutationResponse>>>;
  /** update data of the table: "server_agency_services" */
  updateAgencyServices?: Maybe<AgencyServicesMutationResponse>;
  /** update single row of the table: "server_agency_services" */
  updateAgencyServicesByPk?: Maybe<AgencyServices>;
  /** update multiples rows of table: "server_agency_services" */
  updateAgencyServicesMany?: Maybe<Array<Maybe<AgencyServicesMutationResponse>>>;
  /** update data of the table: "server_agencyconnection" */
  updateAgencyconnection?: Maybe<AgencyconnectionMutationResponse>;
  /** update single row of the table: "server_agencyconnection" */
  updateAgencyconnectionByPk?: Maybe<Agencyconnection>;
  /** update multiples rows of table: "server_agencyconnection" */
  updateAgencyconnectionMany?: Maybe<Array<Maybe<AgencyconnectionMutationResponse>>>;
  /** update data of the table: "server_agencyjoinrequest" */
  updateAgencyjoinrequest?: Maybe<AgencyjoinrequestMutationResponse>;
  /** update single row of the table: "server_agencyjoinrequest" */
  updateAgencyjoinrequestByPk?: Maybe<Agencyjoinrequest>;
  /** update multiples rows of table: "server_agencyjoinrequest" */
  updateAgencyjoinrequestMany?: Maybe<Array<Maybe<AgencyjoinrequestMutationResponse>>>;
  /** update data of the table: "server_allergy" */
  updateAllergy?: Maybe<AllergyMutationResponse>;
  /** update single row of the table: "server_allergy" */
  updateAllergyByPk?: Maybe<Allergy>;
  /** update multiples rows of table: "server_allergy" */
  updateAllergyMany?: Maybe<Array<Maybe<AllergyMutationResponse>>>;
  /** update data of the table: "server_appointment" */
  updateAppointment?: Maybe<AppointmentMutationResponse>;
  /** update single row of the table: "server_appointment" */
  updateAppointmentByPk?: Maybe<Appointment>;
  /** update multiples rows of table: "server_appointment" */
  updateAppointmentMany?: Maybe<Array<Maybe<AppointmentMutationResponse>>>;
  /** update data of the table: "server_assessment" */
  updateAssessment?: Maybe<AssessmentMutationResponse>;
  /** update single row of the table: "server_assessment" */
  updateAssessmentByPk?: Maybe<Assessment>;
  /** update data of the table: "server_assessmentitem" */
  updateAssessmentItem?: Maybe<AssessmentItemMutationResponse>;
  /** update single row of the table: "server_assessmentitem" */
  updateAssessmentItemByPk?: Maybe<AssessmentItem>;
  /** update multiples rows of table: "server_assessmentitem" */
  updateAssessmentItemMany?: Maybe<Array<Maybe<AssessmentItemMutationResponse>>>;
  /** update data of the table: "server_assessmentitemvalue" */
  updateAssessmentItemValue?: Maybe<AssessmentItemValueMutationResponse>;
  /** update single row of the table: "server_assessmentitemvalue" */
  updateAssessmentItemValueByPk?: Maybe<AssessmentItemValue>;
  /** update multiples rows of table: "server_assessmentitemvalue" */
  updateAssessmentItemValueMany?: Maybe<Array<Maybe<AssessmentItemValueMutationResponse>>>;
  /** update multiples rows of table: "server_assessment" */
  updateAssessmentMany?: Maybe<Array<Maybe<AssessmentMutationResponse>>>;
  /** update data of the table: "server_baseprotocolorder" */
  updateBaseProtocolOrder?: Maybe<BaseProtocolOrderMutationResponse>;
  /** update single row of the table: "server_baseprotocolorder" */
  updateBaseProtocolOrderByPk?: Maybe<BaseProtocolOrder>;
  /** update multiples rows of table: "server_baseprotocolorder" */
  updateBaseProtocolOrderMany?: Maybe<Array<Maybe<BaseProtocolOrderMutationResponse>>>;
  /** update data of the table: "server_baseorder" */
  updateBaseorder?: Maybe<BaseorderMutationResponse>;
  /** update single row of the table: "server_baseorder" */
  updateBaseorderByPk?: Maybe<Baseorder>;
  /** update multiples rows of table: "server_baseorder" */
  updateBaseorderMany?: Maybe<Array<Maybe<BaseorderMutationResponse>>>;
  /** update data of the table: "server_caretime" */
  updateCaretime?: Maybe<CaretimeMutationResponse>;
  /** update single row of the table: "server_caretime" */
  updateCaretimeByPk?: Maybe<Caretime>;
  /** update multiples rows of table: "server_caretime" */
  updateCaretimeMany?: Maybe<Array<Maybe<CaretimeMutationResponse>>>;
  /** update data of the table: "server_caretime_problems" */
  updateCaretimeProblems?: Maybe<CaretimeProblemsMutationResponse>;
  /** update single row of the table: "server_caretime_problems" */
  updateCaretimeProblemsByPk?: Maybe<CaretimeProblems>;
  /** update multiples rows of table: "server_caretime_problems" */
  updateCaretimeProblemsMany?: Maybe<Array<Maybe<CaretimeProblemsMutationResponse>>>;
  /** update data of the table: "server_claim" */
  updateClaim?: Maybe<ClaimMutationResponse>;
  /** update single row of the table: "server_claim" */
  updateClaimByPk?: Maybe<Claim>;
  /** update multiples rows of table: "server_claim" */
  updateClaimMany?: Maybe<Array<Maybe<ClaimMutationResponse>>>;
  /** update data of the table: "server_futurereferralclinicalnote" */
  updateClinicalNoteFutureReferrals?: Maybe<ClinicalNoteFutureReferralsMutationResponse>;
  /** update single row of the table: "server_futurereferralclinicalnote" */
  updateClinicalNoteFutureReferralsByPk?: Maybe<ClinicalNoteFutureReferrals>;
  /** update multiples rows of table: "server_futurereferralclinicalnote" */
  updateClinicalNoteFutureReferralsMany?: Maybe<Array<Maybe<ClinicalNoteFutureReferralsMutationResponse>>>;
  /** update data of the table: "server_clinicalnote" */
  updateClinicalnote?: Maybe<ClinicalnoteMutationResponse>;
  /** update single row of the table: "server_clinicalnote" */
  updateClinicalnoteByPk?: Maybe<Clinicalnote>;
  /** update data of the table: "server_clinicalnotefield" */
  updateClinicalnoteField?: Maybe<ClinicalnoteFieldMutationResponse>;
  /** update single row of the table: "server_clinicalnotefield" */
  updateClinicalnoteFieldByPk?: Maybe<ClinicalnoteField>;
  /** update multiples rows of table: "server_clinicalnotefield" */
  updateClinicalnoteFieldMany?: Maybe<Array<Maybe<ClinicalnoteFieldMutationResponse>>>;
  /** update data of the table: "server_clinicalnotefieldvalue" */
  updateClinicalnoteFieldValue?: Maybe<ClinicalnoteFieldValueMutationResponse>;
  /** update single row of the table: "server_clinicalnotefieldvalue" */
  updateClinicalnoteFieldValueByPk?: Maybe<ClinicalnoteFieldValue>;
  /** update multiples rows of table: "server_clinicalnotefieldvalue" */
  updateClinicalnoteFieldValueMany?: Maybe<Array<Maybe<ClinicalnoteFieldValueMutationResponse>>>;
  /** update multiples rows of table: "server_clinicalnote" */
  updateClinicalnoteMany?: Maybe<Array<Maybe<ClinicalnoteMutationResponse>>>;
  /** update data of the table: "server_clinicalnotetemplate" */
  updateClinicalnoteTemplate?: Maybe<ClinicalnoteTemplateMutationResponse>;
  /** update single row of the table: "server_clinicalnotetemplate" */
  updateClinicalnoteTemplateByPk?: Maybe<ClinicalnoteTemplate>;
  /** update multiples rows of table: "server_clinicalnotetemplate" */
  updateClinicalnoteTemplateMany?: Maybe<Array<Maybe<ClinicalnoteTemplateMutationResponse>>>;
  /** update data of the table: "server_clinicalnotetemplate_provider_classifications" */
  updateClinicalnoteTemplateProviderClassifications?: Maybe<ClinicalnoteTemplateProviderClassificationsMutationResponse>;
  /** update single row of the table: "server_clinicalnotetemplate_provider_classifications" */
  updateClinicalnoteTemplateProviderClassificationsByPk?: Maybe<ClinicalnoteTemplateProviderClassifications>;
  /** update multiples rows of table: "server_clinicalnotetemplate_provider_classifications" */
  updateClinicalnoteTemplateProviderClassificationsMany?: Maybe<Array<Maybe<ClinicalnoteTemplateProviderClassificationsMutationResponse>>>;
  /** update data of the table: "server_codeset" */
  updateCodeset?: Maybe<CodesetMutationResponse>;
  /** update data of the table: "server_codeset_addon_codeset" */
  updateCodesetAddon?: Maybe<CodesetAddonMutationResponse>;
  /** update single row of the table: "server_codeset_addon_codeset" */
  updateCodesetAddonByPk?: Maybe<CodesetAddon>;
  /** update multiples rows of table: "server_codeset_addon_codeset" */
  updateCodesetAddonMany?: Maybe<Array<Maybe<CodesetAddonMutationResponse>>>;
  /** update data of the table: "server_codeset_base_codeset" */
  updateCodesetBase?: Maybe<CodesetBaseMutationResponse>;
  /** update single row of the table: "server_codeset_base_codeset" */
  updateCodesetBaseByPk?: Maybe<CodesetBase>;
  /** update multiples rows of table: "server_codeset_base_codeset" */
  updateCodesetBaseMany?: Maybe<Array<Maybe<CodesetBaseMutationResponse>>>;
  /** update single row of the table: "server_codeset" */
  updateCodesetByPk?: Maybe<Codeset>;
  /** update multiples rows of table: "server_codeset" */
  updateCodesetMany?: Maybe<Array<Maybe<CodesetMutationResponse>>>;
  /** update data of the table: "server_consent" */
  updateConsent?: Maybe<ConsentMutationResponse>;
  /** update single row of the table: "server_consent" */
  updateConsentByPk?: Maybe<Consent>;
  /** update multiples rows of table: "server_consent" */
  updateConsentMany?: Maybe<Array<Maybe<ConsentMutationResponse>>>;
  /** update data of the table: "server_cpoclaim" */
  updateCpoClaim?: Maybe<CpoClaimMutationResponse>;
  /** update single row of the table: "server_cpoclaim" */
  updateCpoClaimByPk?: Maybe<CpoClaim>;
  /** update multiples rows of table: "server_cpoclaim" */
  updateCpoClaimMany?: Maybe<Array<Maybe<CpoClaimMutationResponse>>>;
  /** update data of the table: "server_dependantorder" */
  updateDependantOrder?: Maybe<DependantOrderMutationResponse>;
  /** update single row of the table: "server_dependantorder" */
  updateDependantOrderByPk?: Maybe<DependantOrder>;
  /** update multiples rows of table: "server_dependantorder" */
  updateDependantOrderMany?: Maybe<Array<Maybe<DependantOrderMutationResponse>>>;
  /** update data of the table: "server_diagnosisabbreviation" */
  updateDiagnosisAbbreviation?: Maybe<DiagnosisAbbreviationMutationResponse>;
  /** update single row of the table: "server_diagnosisabbreviation" */
  updateDiagnosisAbbreviationByPk?: Maybe<DiagnosisAbbreviation>;
  /** update multiples rows of table: "server_diagnosisabbreviation" */
  updateDiagnosisAbbreviationMany?: Maybe<Array<Maybe<DiagnosisAbbreviationMutationResponse>>>;
  /** update data of the table: "server_document" */
  updateDocument?: Maybe<DocumentMutationResponse>;
  /** update single row of the table: "server_document" */
  updateDocumentByPk?: Maybe<Document>;
  /** update multiples rows of table: "server_document" */
  updateDocumentMany?: Maybe<Array<Maybe<DocumentMutationResponse>>>;
  /** update data of the table: "server_documentpendingreview" */
  updateDocumentPendingReview?: Maybe<DocumentPendingReviewMutationResponse>;
  /** update single row of the table: "server_documentpendingreview" */
  updateDocumentPendingReviewByPk?: Maybe<DocumentPendingReview>;
  /** update multiples rows of table: "server_documentpendingreview" */
  updateDocumentPendingReviewMany?: Maybe<Array<Maybe<DocumentPendingReviewMutationResponse>>>;
  /** update data of the table: "server_externalsignedorder" */
  updateExternalSignedOrder?: Maybe<ExternalSignedOrderMutationResponse>;
  /** update single row of the table: "server_externalsignedorder" */
  updateExternalSignedOrderByPk?: Maybe<ExternalSignedOrder>;
  /** update multiples rows of table: "server_externalsignedorder" */
  updateExternalSignedOrderMany?: Maybe<Array<Maybe<ExternalSignedOrderMutationResponse>>>;
  /** update data of the table: "server_externalstaff_my_patients" */
  updateExternalStaffMyPatients?: Maybe<ExternalStaffMyPatientsMutationResponse>;
  /** update single row of the table: "server_externalstaff_my_patients" */
  updateExternalStaffMyPatientsByPk?: Maybe<ExternalStaffMyPatients>;
  /** update multiples rows of table: "server_externalstaff_my_patients" */
  updateExternalStaffMyPatientsMany?: Maybe<Array<Maybe<ExternalStaffMyPatientsMutationResponse>>>;
  /** update data of the table: "server_externalstaff" */
  updateExternalstaff?: Maybe<ExternalstaffMutationResponse>;
  /** update single row of the table: "server_externalstaff" */
  updateExternalstaffByPk?: Maybe<Externalstaff>;
  /** update multiples rows of table: "server_externalstaff" */
  updateExternalstaffMany?: Maybe<Array<Maybe<ExternalstaffMutationResponse>>>;
  /** update data of the table: "server_fax" */
  updateFax?: Maybe<FaxMutationResponse>;
  /** update single row of the table: "server_fax" */
  updateFaxByPk?: Maybe<Fax>;
  /** update data of the table: "server_fax_documents" */
  updateFaxDocuments?: Maybe<FaxDocumentsMutationResponse>;
  /** update single row of the table: "server_fax_documents" */
  updateFaxDocumentsByPk?: Maybe<FaxDocuments>;
  /** update multiples rows of table: "server_fax_documents" */
  updateFaxDocumentsMany?: Maybe<Array<Maybe<FaxDocumentsMutationResponse>>>;
  /** update multiples rows of table: "server_fax" */
  updateFaxMany?: Maybe<Array<Maybe<FaxMutationResponse>>>;
  /** update data of the table: "server_freetextorder" */
  updateFreeTextOrder?: Maybe<FreeTextOrderMutationResponse>;
  /** update single row of the table: "server_freetextorder" */
  updateFreeTextOrderByPk?: Maybe<FreeTextOrder>;
  /** update multiples rows of table: "server_freetextorder" */
  updateFreeTextOrderMany?: Maybe<Array<Maybe<FreeTextOrderMutationResponse>>>;
  /** update data of the table: "server_frequencyorder" */
  updateFrequencyOrder?: Maybe<FrequencyOrderMutationResponse>;
  /** update single row of the table: "server_frequencyorder" */
  updateFrequencyOrderByPk?: Maybe<FrequencyOrder>;
  /** update multiples rows of table: "server_frequencyorder" */
  updateFrequencyOrderMany?: Maybe<Array<Maybe<FrequencyOrderMutationResponse>>>;
  /** update data of the table: "server_frequencyprotocol" */
  updateFrequencyProtocol?: Maybe<FrequencyProtocolMutationResponse>;
  /** update single row of the table: "server_frequencyprotocol" */
  updateFrequencyProtocolByPk?: Maybe<FrequencyProtocol>;
  /** update multiples rows of table: "server_frequencyprotocol" */
  updateFrequencyProtocolMany?: Maybe<Array<Maybe<FrequencyProtocolMutationResponse>>>;
  /** update data of the table: "server_hcpcs" */
  updateHcpcs?: Maybe<HcpcsMutationResponse>;
  /** update single row of the table: "server_hcpcs" */
  updateHcpcsByPk?: Maybe<Hcpcs>;
  /** update multiples rows of table: "server_hcpcs" */
  updateHcpcsMany?: Maybe<Array<Maybe<HcpcsMutationResponse>>>;
  /** update data of the table: "server_insuranceplan" */
  updateInsuranceplan?: Maybe<InsuranceplanMutationResponse>;
  /** update single row of the table: "server_insuranceplan" */
  updateInsuranceplanByPk?: Maybe<Insuranceplan>;
  /** update multiples rows of table: "server_insuranceplan" */
  updateInsuranceplanMany?: Maybe<Array<Maybe<InsuranceplanMutationResponse>>>;
  /** update data of the table: "server_integration" */
  updateIntegration?: Maybe<IntegrationMutationResponse>;
  /** update single row of the table: "server_integration" */
  updateIntegrationByPk?: Maybe<Integration>;
  /** update multiples rows of table: "server_integration" */
  updateIntegrationMany?: Maybe<Array<Maybe<IntegrationMutationResponse>>>;
  /** update data of the table: "server_internalstaff" */
  updateInternalstaff?: Maybe<InternalstaffMutationResponse>;
  /** update single row of the table: "server_internalstaff" */
  updateInternalstaffByPk?: Maybe<Internalstaff>;
  /** update multiples rows of table: "server_internalstaff" */
  updateInternalstaffMany?: Maybe<Array<Maybe<InternalstaffMutationResponse>>>;
  /** update data of the table: "server_order" */
  updateLegacyOrder?: Maybe<LegacyOrderMutationResponse>;
  /** update single row of the table: "server_order" */
  updateLegacyOrderByPk?: Maybe<LegacyOrder>;
  /** update data of the table: "server_orderfrequency" */
  updateLegacyOrderFrequency?: Maybe<LegacyOrderFrequencyMutationResponse>;
  /** update single row of the table: "server_orderfrequency" */
  updateLegacyOrderFrequencyByPk?: Maybe<LegacyOrderFrequency>;
  /** update multiples rows of table: "server_orderfrequency" */
  updateLegacyOrderFrequencyMany?: Maybe<Array<Maybe<LegacyOrderFrequencyMutationResponse>>>;
  /** update multiples rows of table: "server_order" */
  updateLegacyOrderMany?: Maybe<Array<Maybe<LegacyOrderMutationResponse>>>;
  /** update data of the table: "server_order_medications" */
  updateLegacyOrderMedications?: Maybe<LegacyOrderMedicationsMutationResponse>;
  /** update single row of the table: "server_order_medications" */
  updateLegacyOrderMedicationsByPk?: Maybe<LegacyOrderMedications>;
  /** update multiples rows of table: "server_order_medications" */
  updateLegacyOrderMedicationsMany?: Maybe<Array<Maybe<LegacyOrderMedicationsMutationResponse>>>;
  /** update data of the table: "server_orderorderablefieldvalue" */
  updateLegacyOrderOrderableFieldValue?: Maybe<LegacyOrderOrderableFieldValueMutationResponse>;
  /** update single row of the table: "server_orderorderablefieldvalue" */
  updateLegacyOrderOrderableFieldValueByPk?: Maybe<LegacyOrderOrderableFieldValue>;
  /** update multiples rows of table: "server_orderorderablefieldvalue" */
  updateLegacyOrderOrderableFieldValueMany?: Maybe<Array<Maybe<LegacyOrderOrderableFieldValueMutationResponse>>>;
  /** update data of the table: "server_orderqualification" */
  updateLegacyOrderQualification?: Maybe<LegacyOrderQualificationMutationResponse>;
  /** update single row of the table: "server_orderqualification" */
  updateLegacyOrderQualificationByPk?: Maybe<LegacyOrderQualification>;
  /** update multiples rows of table: "server_orderqualification" */
  updateLegacyOrderQualificationMany?: Maybe<Array<Maybe<LegacyOrderQualificationMutationResponse>>>;
  /** update data of the table: "server_treatmentplanorder" */
  updateLegacyTreatmentplanOrder?: Maybe<LegacyTreatmentplanOrderMutationResponse>;
  /** update single row of the table: "server_treatmentplanorder" */
  updateLegacyTreatmentplanOrderByPk?: Maybe<LegacyTreatmentplanOrder>;
  /** update multiples rows of table: "server_treatmentplanorder" */
  updateLegacyTreatmentplanOrderMany?: Maybe<Array<Maybe<LegacyTreatmentplanOrderMutationResponse>>>;
  /** update data of the table: "server_lineitem" */
  updateLineitem?: Maybe<LineitemMutationResponse>;
  /** update single row of the table: "server_lineitem" */
  updateLineitemByPk?: Maybe<Lineitem>;
  /** update multiples rows of table: "server_lineitem" */
  updateLineitemMany?: Maybe<Array<Maybe<LineitemMutationResponse>>>;
  /** update data of the table: "server_medication" */
  updateMedication?: Maybe<MedicationMutationResponse>;
  /** update single row of the table: "server_medication" */
  updateMedicationByPk?: Maybe<Medication>;
  /** update multiples rows of table: "server_medication" */
  updateMedicationMany?: Maybe<Array<Maybe<MedicationMutationResponse>>>;
  /** update data of the table: "server_medicationorder" */
  updateMedicationOrder?: Maybe<MedicationOrderMutationResponse>;
  /** update single row of the table: "server_medicationorder" */
  updateMedicationOrderByPk?: Maybe<MedicationOrder>;
  /** update multiples rows of table: "server_medicationorder" */
  updateMedicationOrderMany?: Maybe<Array<Maybe<MedicationOrderMutationResponse>>>;
  /** update data of the table: "server_medicationorder_medications" */
  updateMedicationorderMedications?: Maybe<MedicationorderMedicationsMutationResponse>;
  /** update single row of the table: "server_medicationorder_medications" */
  updateMedicationorderMedicationsByPk?: Maybe<MedicationorderMedications>;
  /** update multiples rows of table: "server_medicationorder_medications" */
  updateMedicationorderMedicationsMany?: Maybe<Array<Maybe<MedicationorderMedicationsMutationResponse>>>;
  /** update data of the table: "server_message" */
  updateMessage?: Maybe<MessageMutationResponse>;
  /** update single row of the table: "server_message" */
  updateMessageByPk?: Maybe<Message>;
  /** update multiples rows of table: "server_message" */
  updateMessageMany?: Maybe<Array<Maybe<MessageMutationResponse>>>;
  /** update data of the table: "server_orderorderablefield" */
  updateOrderOrderableField?: Maybe<OrderOrderableFieldMutationResponse>;
  /** update single row of the table: "server_orderorderablefield" */
  updateOrderOrderableFieldByPk?: Maybe<OrderOrderableField>;
  /** update multiples rows of table: "server_orderorderablefield" */
  updateOrderOrderableFieldMany?: Maybe<Array<Maybe<OrderOrderableFieldMutationResponse>>>;
  /** update data of the table: "server_orderorderable" */
  updateOrderOrderableLegacy?: Maybe<OrderOrderableLegacyMutationResponse>;
  /** update single row of the table: "server_orderorderable" */
  updateOrderOrderableLegacyByPk?: Maybe<OrderOrderableLegacy>;
  /** update multiples rows of table: "server_orderorderable" */
  updateOrderOrderableLegacyMany?: Maybe<Array<Maybe<OrderOrderableLegacyMutationResponse>>>;
  /** update data of the table: "server_orderable" */
  updateOrderable?: Maybe<OrderableMutationResponse>;
  /** update single row of the table: "server_orderable" */
  updateOrderableByPk?: Maybe<Orderable>;
  /** update data of the table: "server_orderablecategory" */
  updateOrderableCategory?: Maybe<OrderableCategoryMutationResponse>;
  /** update single row of the table: "server_orderablecategory" */
  updateOrderableCategoryByPk?: Maybe<OrderableCategory>;
  /** update multiples rows of table: "server_orderablecategory" */
  updateOrderableCategoryMany?: Maybe<Array<Maybe<OrderableCategoryMutationResponse>>>;
  /** update multiples rows of table: "server_orderable" */
  updateOrderableMany?: Maybe<Array<Maybe<OrderableMutationResponse>>>;
  /** update data of the table: "server_orderableorder" */
  updateOrderableOrder?: Maybe<OrderableOrderMutationResponse>;
  /** update single row of the table: "server_orderableorder" */
  updateOrderableOrderByPk?: Maybe<OrderableOrder>;
  /** update multiples rows of table: "server_orderableorder" */
  updateOrderableOrderMany?: Maybe<Array<Maybe<OrderableOrderMutationResponse>>>;
  /** update data of the table: "server_orderableorderqualification" */
  updateOrderableOrderQualification?: Maybe<OrderableOrderQualificationMutationResponse>;
  /** update single row of the table: "server_orderableorderqualification" */
  updateOrderableOrderQualificationByPk?: Maybe<OrderableOrderQualification>;
  /** update multiples rows of table: "server_orderableorderqualification" */
  updateOrderableOrderQualificationMany?: Maybe<Array<Maybe<OrderableOrderQualificationMutationResponse>>>;
  /** update data of the table: "server_orderablequalification" */
  updateOrderableQualification?: Maybe<OrderableQualificationMutationResponse>;
  /** update single row of the table: "server_orderablequalification" */
  updateOrderableQualificationByPk?: Maybe<OrderableQualification>;
  /** update multiples rows of table: "server_orderablequalification" */
  updateOrderableQualificationMany?: Maybe<Array<Maybe<OrderableQualificationMutationResponse>>>;
  /** update data of the table: "server_orderablesupplier" */
  updateOrderableSupplier?: Maybe<OrderableSupplierMutationResponse>;
  /** update single row of the table: "server_orderablesupplier" */
  updateOrderableSupplierByPk?: Maybe<OrderableSupplier>;
  /** update multiples rows of table: "server_orderablesupplier" */
  updateOrderableSupplierMany?: Maybe<Array<Maybe<OrderableSupplierMutationResponse>>>;
  /** update data of the table: "server_orderablesupplier_payers" */
  updateOrderableSupplierPayerConnections?: Maybe<OrderableSupplierPayerConnectionsMutationResponse>;
  /** update single row of the table: "server_orderablesupplier_payers" */
  updateOrderableSupplierPayerConnectionsByPk?: Maybe<OrderableSupplierPayerConnections>;
  /** update multiples rows of table: "server_orderablesupplier_payers" */
  updateOrderableSupplierPayerConnectionsMany?: Maybe<Array<Maybe<OrderableSupplierPayerConnectionsMutationResponse>>>;
  /** update data of the table: "server_orderaction" */
  updateOrderaction?: Maybe<OrderactionMutationResponse>;
  /** update single row of the table: "server_orderaction" */
  updateOrderactionByPk?: Maybe<Orderaction>;
  /** update multiples rows of table: "server_orderaction" */
  updateOrderactionMany?: Maybe<Array<Maybe<OrderactionMutationResponse>>>;
  /** update data of the table: "server_patient" */
  updatePatient?: Maybe<PatientMutationResponse>;
  /** update data of the table: "server_patientalias" */
  updatePatientAlias?: Maybe<PatientAliasMutationResponse>;
  /** update single row of the table: "server_patientalias" */
  updatePatientAliasByPk?: Maybe<PatientAlias>;
  /** update multiples rows of table: "server_patientalias" */
  updatePatientAliasMany?: Maybe<Array<Maybe<PatientAliasMutationResponse>>>;
  /** update single row of the table: "server_patient" */
  updatePatientByPk?: Maybe<Patient>;
  /** update data of the table: "server_patientcommunication" */
  updatePatientCommunication?: Maybe<PatientCommunicationMutationResponse>;
  /** update single row of the table: "server_patientcommunication" */
  updatePatientCommunicationByPk?: Maybe<PatientCommunication>;
  /** update multiples rows of table: "server_patientcommunication" */
  updatePatientCommunicationMany?: Maybe<Array<Maybe<PatientCommunicationMutationResponse>>>;
  /** update data of the table: "server_patientcontactperson" */
  updatePatientContactPerson?: Maybe<PatientContactPersonMutationResponse>;
  /** update single row of the table: "server_patientcontactperson" */
  updatePatientContactPersonByPk?: Maybe<PatientContactPerson>;
  /** update multiples rows of table: "server_patientcontactperson" */
  updatePatientContactPersonMany?: Maybe<Array<Maybe<PatientContactPersonMutationResponse>>>;
  /** update data of the table: "server_patienteligibilitycheck" */
  updatePatientEligibilityCheck?: Maybe<PatientEligibilityCheckMutationResponse>;
  /** update single row of the table: "server_patienteligibilitycheck" */
  updatePatientEligibilityCheckByPk?: Maybe<PatientEligibilityCheck>;
  /** update multiples rows of table: "server_patienteligibilitycheck" */
  updatePatientEligibilityCheckMany?: Maybe<Array<Maybe<PatientEligibilityCheckMutationResponse>>>;
  /** update data of the table: "server_patientintake" */
  updatePatientIntake?: Maybe<PatientIntakeMutationResponse>;
  /** update single row of the table: "server_patientintake" */
  updatePatientIntakeByPk?: Maybe<PatientIntake>;
  /** update multiples rows of table: "server_patientintake" */
  updatePatientIntakeMany?: Maybe<Array<Maybe<PatientIntakeMutationResponse>>>;
  /** update data of the table: "server_patientintake_records_locations" */
  updatePatientIntakeRecordsLocations?: Maybe<PatientIntakeRecordsLocationsMutationResponse>;
  /** update single row of the table: "server_patientintake_records_locations" */
  updatePatientIntakeRecordsLocationsByPk?: Maybe<PatientIntakeRecordsLocations>;
  /** update multiples rows of table: "server_patientintake_records_locations" */
  updatePatientIntakeRecordsLocationsMany?: Maybe<Array<Maybe<PatientIntakeRecordsLocationsMutationResponse>>>;
  /** update multiples rows of table: "server_patient" */
  updatePatientMany?: Maybe<Array<Maybe<PatientMutationResponse>>>;
  /** update data of the table: "server_patientphone" */
  updatePatientPhone?: Maybe<PatientPhoneMutationResponse>;
  /** update single row of the table: "server_patientphone" */
  updatePatientPhoneByPk?: Maybe<PatientPhone>;
  /** update multiples rows of table: "server_patientphone" */
  updatePatientPhoneMany?: Maybe<Array<Maybe<PatientPhoneMutationResponse>>>;
  /** update data of the table: "server_patientphoto" */
  updatePatientPhoto?: Maybe<PatientPhotoMutationResponse>;
  /** update single row of the table: "server_patientphoto" */
  updatePatientPhotoByPk?: Maybe<PatientPhoto>;
  /** update multiples rows of table: "server_patientphoto" */
  updatePatientPhotoMany?: Maybe<Array<Maybe<PatientPhotoMutationResponse>>>;
  /** update data of the table: "server_patientstatus" */
  updatePatientStatus?: Maybe<PatientStatusMutationResponse>;
  /** update data of the table: "server_patientstatusaction" */
  updatePatientStatusAction?: Maybe<PatientStatusActionMutationResponse>;
  /** update single row of the table: "server_patientstatusaction" */
  updatePatientStatusActionByPk?: Maybe<PatientStatusAction>;
  /** update multiples rows of table: "server_patientstatusaction" */
  updatePatientStatusActionMany?: Maybe<Array<Maybe<PatientStatusActionMutationResponse>>>;
  /** update single row of the table: "server_patientstatus" */
  updatePatientStatusByPk?: Maybe<PatientStatus>;
  /** update multiples rows of table: "server_patientstatus" */
  updatePatientStatusMany?: Maybe<Array<Maybe<PatientStatusMutationResponse>>>;
  /** update data of the table: "server_patientstatus_subsequent_statuses" */
  updatePatientStatusSubsequentStatuses?: Maybe<PatientStatusSubsequentStatusesMutationResponse>;
  /** update single row of the table: "server_patientstatus_subsequent_statuses" */
  updatePatientStatusSubsequentStatusesByPk?: Maybe<PatientStatusSubsequentStatuses>;
  /** update multiples rows of table: "server_patientstatus_subsequent_statuses" */
  updatePatientStatusSubsequentStatusesMany?: Maybe<Array<Maybe<PatientStatusSubsequentStatusesMutationResponse>>>;
  /** update data of the table: "server_payer" */
  updatePayer?: Maybe<PayerMutationResponse>;
  /** update single row of the table: "server_payer" */
  updatePayerByPk?: Maybe<Payer>;
  /** update multiples rows of table: "server_payer" */
  updatePayerMany?: Maybe<Array<Maybe<PayerMutationResponse>>>;
  /** update data of the table: "server_practicegroup" */
  updatePracticegroup?: Maybe<PracticegroupMutationResponse>;
  /** update single row of the table: "server_practicegroup" */
  updatePracticegroupByPk?: Maybe<Practicegroup>;
  /** update multiples rows of table: "server_practicegroup" */
  updatePracticegroupMany?: Maybe<Array<Maybe<PracticegroupMutationResponse>>>;
  /** update data of the table: "server_problem" */
  updateProblem?: Maybe<ProblemMutationResponse>;
  /** update single row of the table: "server_problem" */
  updateProblemByPk?: Maybe<Problem>;
  /** update multiples rows of table: "server_problem" */
  updateProblemMany?: Maybe<Array<Maybe<ProblemMutationResponse>>>;
  /** update data of the table: "server_problemplan" */
  updateProblemPlan?: Maybe<ProblemPlanMutationResponse>;
  /** update single row of the table: "server_problemplan" */
  updateProblemPlanByPk?: Maybe<ProblemPlan>;
  /** update multiples rows of table: "server_problemplan" */
  updateProblemPlanMany?: Maybe<Array<Maybe<ProblemPlanMutationResponse>>>;
  /** update data of the table: "server_procedure" */
  updateProcedure?: Maybe<ProcedureMutationResponse>;
  /** update single row of the table: "server_procedure" */
  updateProcedureByPk?: Maybe<Procedure>;
  /** update data of the table: "server_procedure_hcpcs" */
  updateProcedureHCPCS?: Maybe<ProcedureHcpcsMutationResponse>;
  /** update single row of the table: "server_procedure_hcpcs" */
  updateProcedureHCPCSByPk?: Maybe<ProcedureHcpcs>;
  /** update multiples rows of table: "server_procedure_hcpcs" */
  updateProcedureHCPCSMany?: Maybe<Array<Maybe<ProcedureHcpcsMutationResponse>>>;
  /** update multiples rows of table: "server_procedure" */
  updateProcedureMany?: Maybe<Array<Maybe<ProcedureMutationResponse>>>;
  /** update data of the table: "server_procedurerendered" */
  updateProcedureRendered?: Maybe<ProcedureRenderedMutationResponse>;
  /** update single row of the table: "server_procedurerendered" */
  updateProcedureRenderedByPk?: Maybe<ProcedureRendered>;
  /** update multiples rows of table: "server_procedurerendered" */
  updateProcedureRenderedMany?: Maybe<Array<Maybe<ProcedureRenderedMutationResponse>>>;
  /** update data of the table: "server_provider" */
  updateProvider?: Maybe<ProviderMutationResponse>;
  /** update single row of the table: "server_provider" */
  updateProviderByPk?: Maybe<Provider>;
  /** update data of the table: "server_providerclassification" */
  updateProviderClassification?: Maybe<ProviderClassificationMutationResponse>;
  /** update single row of the table: "server_providerclassification" */
  updateProviderClassificationByPk?: Maybe<ProviderClassification>;
  /** update multiples rows of table: "server_providerclassification" */
  updateProviderClassificationMany?: Maybe<Array<Maybe<ProviderClassificationMutationResponse>>>;
  /** update multiples rows of table: "server_provider" */
  updateProviderMany?: Maybe<Array<Maybe<ProviderMutationResponse>>>;
  /** update data of the table: "server_providerschedule" */
  updateProviderSchedule?: Maybe<ProviderScheduleMutationResponse>;
  /** update single row of the table: "server_providerschedule" */
  updateProviderScheduleByPk?: Maybe<ProviderSchedule>;
  /** update multiples rows of table: "server_providerschedule" */
  updateProviderScheduleMany?: Maybe<Array<Maybe<ProviderScheduleMutationResponse>>>;
  /** update data of the table: "server_recordsrequestdestination" */
  updateRecordsRequestDestination?: Maybe<RecordsRequestDestinationMutationResponse>;
  /** update single row of the table: "server_recordsrequestdestination" */
  updateRecordsRequestDestinationByPk?: Maybe<RecordsRequestDestination>;
  /** update multiples rows of table: "server_recordsrequestdestination" */
  updateRecordsRequestDestinationMany?: Maybe<Array<Maybe<RecordsRequestDestinationMutationResponse>>>;
  /** update data of the table: "server_referral" */
  updateReferral?: Maybe<ReferralMutationResponse>;
  /** update data of the table: "server_referral_attachments" */
  updateReferralAttachments?: Maybe<ReferralAttachmentsMutationResponse>;
  /** update single row of the table: "server_referral_attachments" */
  updateReferralAttachmentsByPk?: Maybe<ReferralAttachments>;
  /** update multiples rows of table: "server_referral_attachments" */
  updateReferralAttachmentsMany?: Maybe<Array<Maybe<ReferralAttachmentsMutationResponse>>>;
  /** update single row of the table: "server_referral" */
  updateReferralByPk?: Maybe<Referral>;
  /** update multiples rows of table: "server_referral" */
  updateReferralMany?: Maybe<Array<Maybe<ReferralMutationResponse>>>;
  /** update data of the table: "server_renderedassessment" */
  updateRenderedAssessment?: Maybe<RenderedAssessmentMutationResponse>;
  /** update single row of the table: "server_renderedassessment" */
  updateRenderedAssessmentByPk?: Maybe<RenderedAssessment>;
  /** update multiples rows of table: "server_renderedassessment" */
  updateRenderedAssessmentMany?: Maybe<Array<Maybe<RenderedAssessmentMutationResponse>>>;
  /** update data of the table: "server_schedulingaction" */
  updateSchedulingAction?: Maybe<SchedulingActionMutationResponse>;
  /** update single row of the table: "server_schedulingaction" */
  updateSchedulingActionByPk?: Maybe<SchedulingAction>;
  /** update multiples rows of table: "server_schedulingaction" */
  updateSchedulingActionMany?: Maybe<Array<Maybe<SchedulingActionMutationResponse>>>;
  /** update data of the table: "server_servicetype" */
  updateServiceType?: Maybe<ServiceTypeMutationResponse>;
  /** update single row of the table: "server_servicetype" */
  updateServiceTypeByPk?: Maybe<ServiceType>;
  /** update multiples rows of table: "server_servicetype" */
  updateServiceTypeMany?: Maybe<Array<Maybe<ServiceTypeMutationResponse>>>;
  /** update data of the table: "server_signedconsent" */
  updateSignedConsent?: Maybe<SignedConsentMutationResponse>;
  /** update single row of the table: "server_signedconsent" */
  updateSignedConsentByPk?: Maybe<SignedConsent>;
  /** update multiples rows of table: "server_signedconsent" */
  updateSignedConsentMany?: Maybe<Array<Maybe<SignedConsentMutationResponse>>>;
  /** update data of the table: "server_signoff" */
  updateSignoff?: Maybe<SignoffMutationResponse>;
  /** update single row of the table: "server_signoff" */
  updateSignoffByPk?: Maybe<Signoff>;
  /** update multiples rows of table: "server_signoff" */
  updateSignoffMany?: Maybe<Array<Maybe<SignoffMutationResponse>>>;
  /** update data of the table: "server_signofftype" */
  updateSignoffType?: Maybe<SignoffTypeMutationResponse>;
  /** update single row of the table: "server_signofftype" */
  updateSignoffTypeByPk?: Maybe<SignoffType>;
  /** update multiples rows of table: "server_signofftype" */
  updateSignoffTypeMany?: Maybe<Array<Maybe<SignoffTypeMutationResponse>>>;
  /** update data of the table: "server_telemedappointmentrequest" */
  updateTelemedAppointmentRequest?: Maybe<TelemedAppointmentRequestMutationResponse>;
  /** update data of the table: "server_telemedappointmentrequestaccesscode" */
  updateTelemedAppointmentRequestAccessCode?: Maybe<TelemedAppointmentRequestAccessCodeMutationResponse>;
  /** update single row of the table: "server_telemedappointmentrequestaccesscode" */
  updateTelemedAppointmentRequestAccessCodeByPk?: Maybe<TelemedAppointmentRequestAccessCode>;
  /** update multiples rows of table: "server_telemedappointmentrequestaccesscode" */
  updateTelemedAppointmentRequestAccessCodeMany?: Maybe<Array<Maybe<TelemedAppointmentRequestAccessCodeMutationResponse>>>;
  /** update single row of the table: "server_telemedappointmentrequest" */
  updateTelemedAppointmentRequestByPk?: Maybe<TelemedAppointmentRequest>;
  /** update multiples rows of table: "server_telemedappointmentrequest" */
  updateTelemedAppointmentRequestMany?: Maybe<Array<Maybe<TelemedAppointmentRequestMutationResponse>>>;
  /** update data of the table: "server_threadcategory" */
  updateThreadCategory?: Maybe<ThreadCategoryMutationResponse>;
  /** update single row of the table: "server_threadcategory" */
  updateThreadCategoryByPk?: Maybe<ThreadCategory>;
  /** update multiples rows of table: "server_threadcategory" */
  updateThreadCategoryMany?: Maybe<Array<Maybe<ThreadCategoryMutationResponse>>>;
  /** update data of the table: "server_threadcategorystatus" */
  updateThreadCategoryStatus?: Maybe<ThreadCategoryStatusMutationResponse>;
  /** update single row of the table: "server_threadcategorystatus" */
  updateThreadCategoryStatusByPk?: Maybe<ThreadCategoryStatus>;
  /** update multiples rows of table: "server_threadcategorystatus" */
  updateThreadCategoryStatusMany?: Maybe<Array<Maybe<ThreadCategoryStatusMutationResponse>>>;
  /** update data of the table: "server_threadfield" */
  updateThreadField?: Maybe<ThreadFieldMutationResponse>;
  /** update single row of the table: "server_threadfield" */
  updateThreadFieldByPk?: Maybe<ThreadField>;
  /** update multiples rows of table: "server_threadfield" */
  updateThreadFieldMany?: Maybe<Array<Maybe<ThreadFieldMutationResponse>>>;
  /** update data of the table: "server_threadfieldvalue" */
  updateThreadFieldValue?: Maybe<ThreadFieldValueMutationResponse>;
  /** update single row of the table: "server_threadfieldvalue" */
  updateThreadFieldValueByPk?: Maybe<ThreadFieldValue>;
  /** update multiples rows of table: "server_threadfieldvalue" */
  updateThreadFieldValueMany?: Maybe<Array<Maybe<ThreadFieldValueMutationResponse>>>;
  /** update data of the table: "server_ticketsubscription" */
  updateTicketSubscription?: Maybe<TicketSubscriptionMutationResponse>;
  /** update single row of the table: "server_ticketsubscription" */
  updateTicketSubscriptionByPk?: Maybe<TicketSubscription>;
  /** update multiples rows of table: "server_ticketsubscription" */
  updateTicketSubscriptionMany?: Maybe<Array<Maybe<TicketSubscriptionMutationResponse>>>;
  /** update data of the table: "server_ticketthread" */
  updateTicketThread?: Maybe<TicketThreadMutationResponse>;
  /** update single row of the table: "server_ticketthread" */
  updateTicketThreadByPk?: Maybe<TicketThread>;
  /** update multiples rows of table: "server_ticketthread" */
  updateTicketThreadMany?: Maybe<Array<Maybe<TicketThreadMutationResponse>>>;
  /** update data of the table: "server_treatmentplan" */
  updateTreatmentplan?: Maybe<TreatmentplanMutationResponse>;
  /** update data of the table: "server_treatmentplanassessment" */
  updateTreatmentplanAssessment?: Maybe<TreatmentplanAssessmentMutationResponse>;
  /** update single row of the table: "server_treatmentplanassessment" */
  updateTreatmentplanAssessmentByPk?: Maybe<TreatmentplanAssessment>;
  /** update multiples rows of table: "server_treatmentplanassessment" */
  updateTreatmentplanAssessmentMany?: Maybe<Array<Maybe<TreatmentplanAssessmentMutationResponse>>>;
  /** update single row of the table: "server_treatmentplan" */
  updateTreatmentplanByPk?: Maybe<Treatmentplan>;
  /** update multiples rows of table: "server_treatmentplan" */
  updateTreatmentplanMany?: Maybe<Array<Maybe<TreatmentplanMutationResponse>>>;
  /** update data of the table: "server_treatmentplannote" */
  updateTreatmentplanNote?: Maybe<TreatmentplanNoteMutationResponse>;
  /** update single row of the table: "server_treatmentplannote" */
  updateTreatmentplanNoteByPk?: Maybe<TreatmentplanNote>;
  /** update multiples rows of table: "server_treatmentplannote" */
  updateTreatmentplanNoteMany?: Maybe<Array<Maybe<TreatmentplanNoteMutationResponse>>>;
  /** update data of the table: "server_treatmentplan_problems" */
  updateTreatmentplanProblems?: Maybe<TreatmentplanProblemsMutationResponse>;
  /** update single row of the table: "server_treatmentplan_problems" */
  updateTreatmentplanProblemsByPk?: Maybe<TreatmentplanProblems>;
  /** update multiples rows of table: "server_treatmentplan_problems" */
  updateTreatmentplanProblemsMany?: Maybe<Array<Maybe<TreatmentplanProblemsMutationResponse>>>;
  /** update data of the table: "server_treatmentplantemplate" */
  updateTreatmentplanTemplate?: Maybe<TreatmentplanTemplateMutationResponse>;
  /** update data of the table: "server_treatmentplantemplate_assessments" */
  updateTreatmentplanTemplateAssessment?: Maybe<TreatmentplanTemplateAssessmentMutationResponse>;
  /** update single row of the table: "server_treatmentplantemplate_assessments" */
  updateTreatmentplanTemplateAssessmentByPk?: Maybe<TreatmentplanTemplateAssessment>;
  /** update multiples rows of table: "server_treatmentplantemplate_assessments" */
  updateTreatmentplanTemplateAssessmentMany?: Maybe<Array<Maybe<TreatmentplanTemplateAssessmentMutationResponse>>>;
  /** update single row of the table: "server_treatmentplantemplate" */
  updateTreatmentplanTemplateByPk?: Maybe<TreatmentplanTemplate>;
  /** update multiples rows of table: "server_treatmentplantemplate" */
  updateTreatmentplanTemplateMany?: Maybe<Array<Maybe<TreatmentplanTemplateMutationResponse>>>;
  /** update data of the table: "server_customuser" */
  updateUser?: Maybe<UserMutationResponse>;
  /** update single row of the table: "server_customuser" */
  updateUserByPk?: Maybe<User>;
  /** update data of the table: "server_customuser_groups" */
  updateUserGroups?: Maybe<UserGroupsMutationResponse>;
  /** update single row of the table: "server_customuser_groups" */
  updateUserGroupsByPk?: Maybe<UserGroups>;
  /** update multiples rows of table: "server_customuser_groups" */
  updateUserGroupsMany?: Maybe<Array<Maybe<UserGroupsMutationResponse>>>;
  /** update multiples rows of table: "server_customuser" */
  updateUserMany?: Maybe<Array<Maybe<UserMutationResponse>>>;
  /** update data of the table: "server_customuser_user_permissions" */
  updateUserPermissions?: Maybe<UserPermissionsMutationResponse>;
  /** update single row of the table: "server_customuser_user_permissions" */
  updateUserPermissionsByPk?: Maybe<UserPermissions>;
  /** update multiples rows of table: "server_customuser_user_permissions" */
  updateUserPermissionsMany?: Maybe<Array<Maybe<UserPermissionsMutationResponse>>>;
  /** update data of the table: "server_visitrebill" */
  updateVisitRebill?: Maybe<VisitRebillMutationResponse>;
  /** update single row of the table: "server_visitrebill" */
  updateVisitRebillByPk?: Maybe<VisitRebill>;
  /** update multiples rows of table: "server_visitrebill" */
  updateVisitRebillMany?: Maybe<Array<Maybe<VisitRebillMutationResponse>>>;
  /** update data of the table: "server_vitals" */
  updateVitals?: Maybe<VitalsMutationResponse>;
  /** update single row of the table: "server_vitals" */
  updateVitalsByPk?: Maybe<Vitals>;
  /** update multiples rows of table: "server_vitals" */
  updateVitalsMany?: Maybe<Array<Maybe<VitalsMutationResponse>>>;
};


/** mutation root */
export type Mutation_RootDeleteAddressArgs = {
  where: AddressBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAddressByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteAgencyArgs = {
  where: AgencyBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAgencyByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteAgencyLocationArgs = {
  where: AgencyLocationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAgencyLocationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteAgencyPayersArgs = {
  where: AgencyPayersBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAgencyPayersByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteAgencyServicesArgs = {
  where: AgencyServicesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAgencyServicesByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteAgencyconnectionArgs = {
  where: AgencyconnectionBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAgencyconnectionByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteAgencyjoinrequestArgs = {
  where: AgencyjoinrequestBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAgencyjoinrequestByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteAllergyArgs = {
  where: AllergyBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAllergyByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteAppointmentArgs = {
  where: AppointmentBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAppointmentByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteAssessmentArgs = {
  where: AssessmentBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAssessmentByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteAssessmentItemArgs = {
  where: AssessmentItemBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAssessmentItemByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteAssessmentItemValueArgs = {
  where: AssessmentItemValueBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAssessmentItemValueByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteBaseProtocolOrderArgs = {
  where: BaseProtocolOrderBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteBaseProtocolOrderByPkArgs = {
  dependantorderPtrId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteBaseorderArgs = {
  where: BaseorderBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteBaseorderByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCaretimeArgs = {
  where: CaretimeBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCaretimeByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCaretimeProblemsArgs = {
  where: CaretimeProblemsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCaretimeProblemsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteClaimArgs = {
  where: ClaimBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteClaimByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteClinicalNoteFutureReferralsArgs = {
  where: ClinicalNoteFutureReferralsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteClinicalNoteFutureReferralsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteClinicalnoteArgs = {
  where: ClinicalnoteBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteClinicalnoteByPkArgs = {
  appointmentId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteClinicalnoteFieldArgs = {
  where: ClinicalnoteFieldBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteClinicalnoteFieldByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteClinicalnoteFieldValueArgs = {
  where: ClinicalnoteFieldValueBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteClinicalnoteFieldValueByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteClinicalnoteTemplateArgs = {
  where: ClinicalnoteTemplateBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteClinicalnoteTemplateByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteClinicalnoteTemplateProviderClassificationsArgs = {
  where: ClinicalnoteTemplateProviderClassificationsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteClinicalnoteTemplateProviderClassificationsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCodesetArgs = {
  where: CodesetBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCodesetAddonArgs = {
  where: CodesetAddonBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCodesetAddonByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCodesetBaseArgs = {
  where: CodesetBaseBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCodesetBaseByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCodesetByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteConsentArgs = {
  where: ConsentBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteConsentByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCpoClaimArgs = {
  where: CpoClaimBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCpoClaimByPkArgs = {
  claimPtrId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteDependantOrderArgs = {
  where: DependantOrderBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteDependantOrderByPkArgs = {
  baseorderPtrId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteDiagnosisAbbreviationArgs = {
  where: DiagnosisAbbreviationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteDiagnosisAbbreviationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteDocumentArgs = {
  where: DocumentBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteDocumentByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteDocumentPendingReviewArgs = {
  where: DocumentPendingReviewBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteDocumentPendingReviewByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteExternalSignedOrderArgs = {
  where: ExternalSignedOrderBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteExternalSignedOrderByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteExternalStaffMyPatientsArgs = {
  where: ExternalStaffMyPatientsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteExternalStaffMyPatientsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteExternalstaffArgs = {
  where: ExternalstaffBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteExternalstaffByPkArgs = {
  userId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteFaxArgs = {
  where: FaxBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteFaxByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteFaxDocumentsArgs = {
  where: FaxDocumentsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteFaxDocumentsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteFreeTextOrderArgs = {
  where: FreeTextOrderBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteFreeTextOrderByPkArgs = {
  baseorderPtrId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteFrequencyOrderArgs = {
  where: FrequencyOrderBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteFrequencyOrderByPkArgs = {
  dependantorderPtrId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteFrequencyProtocolArgs = {
  where: FrequencyProtocolBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteFrequencyProtocolByPkArgs = {
  baseprotocolorderPtrId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteHcpcsArgs = {
  where: HcpcsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteHcpcsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteInsuranceplanArgs = {
  where: InsuranceplanBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteInsuranceplanByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteIntegrationArgs = {
  where: IntegrationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteIntegrationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteInternalstaffArgs = {
  where: InternalstaffBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteInternalstaffByPkArgs = {
  userId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteLegacyOrderArgs = {
  where: LegacyOrderBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteLegacyOrderByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteLegacyOrderFrequencyArgs = {
  where: LegacyOrderFrequencyBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteLegacyOrderFrequencyByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteLegacyOrderMedicationsArgs = {
  where: LegacyOrderMedicationsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteLegacyOrderMedicationsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteLegacyOrderOrderableFieldValueArgs = {
  where: LegacyOrderOrderableFieldValueBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteLegacyOrderOrderableFieldValueByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteLegacyOrderQualificationArgs = {
  where: LegacyOrderQualificationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteLegacyOrderQualificationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteLegacyTreatmentplanOrderArgs = {
  where: LegacyTreatmentplanOrderBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteLegacyTreatmentplanOrderByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteLineitemArgs = {
  where: LineitemBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteLineitemByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteMedicationArgs = {
  where: MedicationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteMedicationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteMedicationOrderArgs = {
  where: MedicationOrderBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteMedicationOrderByPkArgs = {
  baseorderPtrId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteMedicationorderMedicationsArgs = {
  where: MedicationorderMedicationsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteMedicationorderMedicationsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteMessageArgs = {
  where: MessageBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteMessageByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteOrderOrderableFieldArgs = {
  where: OrderOrderableFieldBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderOrderableFieldByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteOrderOrderableLegacyArgs = {
  where: OrderOrderableLegacyBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderOrderableLegacyByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteOrderableArgs = {
  where: OrderableBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderableByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteOrderableCategoryArgs = {
  where: OrderableCategoryBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderableCategoryByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteOrderableOrderArgs = {
  where: OrderableOrderBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderableOrderByPkArgs = {
  baseorderPtrId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteOrderableOrderQualificationArgs = {
  where: OrderableOrderQualificationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderableOrderQualificationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteOrderableQualificationArgs = {
  where: OrderableQualificationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderableQualificationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteOrderableSupplierArgs = {
  where: OrderableSupplierBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderableSupplierByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteOrderableSupplierPayerConnectionsArgs = {
  where: OrderableSupplierPayerConnectionsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderableSupplierPayerConnectionsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteOrderactionArgs = {
  where: OrderactionBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderactionByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePatientArgs = {
  where: PatientBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePatientAliasArgs = {
  where: PatientAliasBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePatientAliasByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePatientByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePatientCommunicationArgs = {
  where: PatientCommunicationBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePatientCommunicationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePatientContactPersonArgs = {
  where: PatientContactPersonBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePatientContactPersonByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePatientEligibilityCheckArgs = {
  where: PatientEligibilityCheckBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePatientEligibilityCheckByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePatientIntakeArgs = {
  where: PatientIntakeBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePatientIntakeByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePatientIntakeRecordsLocationsArgs = {
  where: PatientIntakeRecordsLocationsBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePatientIntakeRecordsLocationsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePatientPhoneArgs = {
  where: PatientPhoneBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePatientPhoneByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePatientPhotoArgs = {
  where: PatientPhotoBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePatientPhotoByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePatientStatusArgs = {
  where: PatientStatusBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePatientStatusActionArgs = {
  where: PatientStatusActionBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePatientStatusActionByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePatientStatusByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePatientStatusSubsequentStatusesArgs = {
  where: PatientStatusSubsequentStatusesBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePatientStatusSubsequentStatusesByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePayerArgs = {
  where: PayerBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePayerByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeletePracticegroupArgs = {
  where: PracticegroupBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePracticegroupByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteProblemArgs = {
  where: ProblemBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteProblemByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteProblemPlanArgs = {
  where: ProblemPlanBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteProblemPlanByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteProcedureArgs = {
  where: ProcedureBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteProcedureByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteProcedureHcpcsArgs = {
  where: ProcedureHcpcsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteProcedureHcpcsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteProcedureRenderedArgs = {
  where: ProcedureRenderedBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteProcedureRenderedByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteProviderArgs = {
  where: ProviderBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteProviderByPkArgs = {
  internalStaffId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteProviderClassificationArgs = {
  where: ProviderClassificationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteProviderClassificationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteProviderScheduleArgs = {
  where: ProviderScheduleBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteProviderScheduleByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteRecordsRequestDestinationArgs = {
  where: RecordsRequestDestinationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteRecordsRequestDestinationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteReferralArgs = {
  where: ReferralBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteReferralAttachmentsArgs = {
  where: ReferralAttachmentsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteReferralAttachmentsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteReferralByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteRenderedAssessmentArgs = {
  where: RenderedAssessmentBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteRenderedAssessmentByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteSchedulingActionArgs = {
  where: SchedulingActionBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteSchedulingActionByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteServiceTypeArgs = {
  where: ServiceTypeBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteServiceTypeByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteSignedConsentArgs = {
  where: SignedConsentBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteSignedConsentByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteSignoffArgs = {
  where: SignoffBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteSignoffByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteSignoffTypeArgs = {
  where: SignoffTypeBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteSignoffTypeByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteTelemedAppointmentRequestArgs = {
  where: TelemedAppointmentRequestBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTelemedAppointmentRequestAccessCodeArgs = {
  where: TelemedAppointmentRequestAccessCodeBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTelemedAppointmentRequestAccessCodeByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteTelemedAppointmentRequestByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteThreadCategoryArgs = {
  where: ThreadCategoryBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteThreadCategoryByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteThreadCategoryStatusArgs = {
  where: ThreadCategoryStatusBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteThreadCategoryStatusByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteThreadFieldArgs = {
  where: ThreadFieldBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteThreadFieldByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteThreadFieldValueArgs = {
  where: ThreadFieldValueBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteThreadFieldValueByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteTicketSubscriptionArgs = {
  where: TicketSubscriptionBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTicketSubscriptionByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteTicketThreadArgs = {
  where: TicketThreadBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTicketThreadByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteTreatmentplanArgs = {
  where: TreatmentplanBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTreatmentplanAssessmentArgs = {
  where: TreatmentplanAssessmentBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTreatmentplanAssessmentByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteTreatmentplanByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteTreatmentplanNoteArgs = {
  where: TreatmentplanNoteBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTreatmentplanNoteByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteTreatmentplanProblemsArgs = {
  where: TreatmentplanProblemsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTreatmentplanProblemsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteTreatmentplanTemplateArgs = {
  where: TreatmentplanTemplateBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTreatmentplanTemplateAssessmentArgs = {
  where: TreatmentplanTemplateAssessmentBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTreatmentplanTemplateAssessmentByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteTreatmentplanTemplateByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  where: UserBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteUserByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteUserGroupsArgs = {
  where: UserGroupsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteUserGroupsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteUserPermissionsArgs = {
  where: UserPermissionsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteUserPermissionsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteVisitRebillArgs = {
  where: VisitRebillBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteVisitRebillByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteVitalsArgs = {
  where: VitalsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteVitalsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsertAddressArgs = {
  objects: Array<AddressInsertInput>;
  onConflict?: InputMaybe<AddressOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAddressOneArgs = {
  object: AddressInsertInput;
  onConflict?: InputMaybe<AddressOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAgencyArgs = {
  objects: Array<AgencyInsertInput>;
  onConflict?: InputMaybe<AgencyOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAgencyLocationArgs = {
  objects: Array<AgencyLocationInsertInput>;
  onConflict?: InputMaybe<AgencyLocationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAgencyLocationOneArgs = {
  object: AgencyLocationInsertInput;
  onConflict?: InputMaybe<AgencyLocationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAgencyOneArgs = {
  object: AgencyInsertInput;
  onConflict?: InputMaybe<AgencyOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAgencyPayersArgs = {
  objects: Array<AgencyPayersInsertInput>;
  onConflict?: InputMaybe<AgencyPayersOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAgencyPayersOneArgs = {
  object: AgencyPayersInsertInput;
  onConflict?: InputMaybe<AgencyPayersOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAgencyServicesArgs = {
  objects: Array<AgencyServicesInsertInput>;
  onConflict?: InputMaybe<AgencyServicesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAgencyServicesOneArgs = {
  object: AgencyServicesInsertInput;
  onConflict?: InputMaybe<AgencyServicesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAgencyconnectionArgs = {
  objects: Array<AgencyconnectionInsertInput>;
  onConflict?: InputMaybe<AgencyconnectionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAgencyconnectionOneArgs = {
  object: AgencyconnectionInsertInput;
  onConflict?: InputMaybe<AgencyconnectionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAgencyjoinrequestArgs = {
  objects: Array<AgencyjoinrequestInsertInput>;
  onConflict?: InputMaybe<AgencyjoinrequestOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAgencyjoinrequestOneArgs = {
  object: AgencyjoinrequestInsertInput;
  onConflict?: InputMaybe<AgencyjoinrequestOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAllergyArgs = {
  objects: Array<AllergyInsertInput>;
  onConflict?: InputMaybe<AllergyOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAllergyOneArgs = {
  object: AllergyInsertInput;
  onConflict?: InputMaybe<AllergyOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAppointmentArgs = {
  objects: Array<AppointmentInsertInput>;
  onConflict?: InputMaybe<AppointmentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAppointmentOneArgs = {
  object: AppointmentInsertInput;
  onConflict?: InputMaybe<AppointmentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAssessmentArgs = {
  objects: Array<AssessmentInsertInput>;
  onConflict?: InputMaybe<AssessmentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAssessmentItemArgs = {
  objects: Array<AssessmentItemInsertInput>;
  onConflict?: InputMaybe<AssessmentItemOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAssessmentItemOneArgs = {
  object: AssessmentItemInsertInput;
  onConflict?: InputMaybe<AssessmentItemOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAssessmentItemValueArgs = {
  objects: Array<AssessmentItemValueInsertInput>;
  onConflict?: InputMaybe<AssessmentItemValueOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAssessmentItemValueOneArgs = {
  object: AssessmentItemValueInsertInput;
  onConflict?: InputMaybe<AssessmentItemValueOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAssessmentOneArgs = {
  object: AssessmentInsertInput;
  onConflict?: InputMaybe<AssessmentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertBaseProtocolOrderArgs = {
  objects: Array<BaseProtocolOrderInsertInput>;
  onConflict?: InputMaybe<BaseProtocolOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertBaseProtocolOrderOneArgs = {
  object: BaseProtocolOrderInsertInput;
  onConflict?: InputMaybe<BaseProtocolOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertBaseorderArgs = {
  objects: Array<BaseorderInsertInput>;
  onConflict?: InputMaybe<BaseorderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertBaseorderOneArgs = {
  object: BaseorderInsertInput;
  onConflict?: InputMaybe<BaseorderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCaretimeArgs = {
  objects: Array<CaretimeInsertInput>;
  onConflict?: InputMaybe<CaretimeOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCaretimeOneArgs = {
  object: CaretimeInsertInput;
  onConflict?: InputMaybe<CaretimeOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCaretimeProblemsArgs = {
  objects: Array<CaretimeProblemsInsertInput>;
  onConflict?: InputMaybe<CaretimeProblemsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCaretimeProblemsOneArgs = {
  object: CaretimeProblemsInsertInput;
  onConflict?: InputMaybe<CaretimeProblemsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClaimArgs = {
  objects: Array<ClaimInsertInput>;
  onConflict?: InputMaybe<ClaimOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClaimOneArgs = {
  object: ClaimInsertInput;
  onConflict?: InputMaybe<ClaimOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClinicalNoteFutureReferralsArgs = {
  objects: Array<ClinicalNoteFutureReferralsInsertInput>;
  onConflict?: InputMaybe<ClinicalNoteFutureReferralsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClinicalNoteFutureReferralsOneArgs = {
  object: ClinicalNoteFutureReferralsInsertInput;
  onConflict?: InputMaybe<ClinicalNoteFutureReferralsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClinicalnoteArgs = {
  objects: Array<ClinicalnoteInsertInput>;
  onConflict?: InputMaybe<ClinicalnoteOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClinicalnoteFieldArgs = {
  objects: Array<ClinicalnoteFieldInsertInput>;
  onConflict?: InputMaybe<ClinicalnoteFieldOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClinicalnoteFieldOneArgs = {
  object: ClinicalnoteFieldInsertInput;
  onConflict?: InputMaybe<ClinicalnoteFieldOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClinicalnoteFieldValueArgs = {
  objects: Array<ClinicalnoteFieldValueInsertInput>;
  onConflict?: InputMaybe<ClinicalnoteFieldValueOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClinicalnoteFieldValueOneArgs = {
  object: ClinicalnoteFieldValueInsertInput;
  onConflict?: InputMaybe<ClinicalnoteFieldValueOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClinicalnoteOneArgs = {
  object: ClinicalnoteInsertInput;
  onConflict?: InputMaybe<ClinicalnoteOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClinicalnoteTemplateArgs = {
  objects: Array<ClinicalnoteTemplateInsertInput>;
  onConflict?: InputMaybe<ClinicalnoteTemplateOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClinicalnoteTemplateOneArgs = {
  object: ClinicalnoteTemplateInsertInput;
  onConflict?: InputMaybe<ClinicalnoteTemplateOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClinicalnoteTemplateProviderClassificationsArgs = {
  objects: Array<ClinicalnoteTemplateProviderClassificationsInsertInput>;
  onConflict?: InputMaybe<ClinicalnoteTemplateProviderClassificationsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertClinicalnoteTemplateProviderClassificationsOneArgs = {
  object: ClinicalnoteTemplateProviderClassificationsInsertInput;
  onConflict?: InputMaybe<ClinicalnoteTemplateProviderClassificationsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCodesetArgs = {
  objects: Array<CodesetInsertInput>;
  onConflict?: InputMaybe<CodesetOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCodesetAddonArgs = {
  objects: Array<CodesetAddonInsertInput>;
  onConflict?: InputMaybe<CodesetAddonOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCodesetAddonOneArgs = {
  object: CodesetAddonInsertInput;
  onConflict?: InputMaybe<CodesetAddonOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCodesetBaseArgs = {
  objects: Array<CodesetBaseInsertInput>;
  onConflict?: InputMaybe<CodesetBaseOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCodesetBaseOneArgs = {
  object: CodesetBaseInsertInput;
  onConflict?: InputMaybe<CodesetBaseOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCodesetOneArgs = {
  object: CodesetInsertInput;
  onConflict?: InputMaybe<CodesetOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertConsentArgs = {
  objects: Array<ConsentInsertInput>;
  onConflict?: InputMaybe<ConsentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertConsentOneArgs = {
  object: ConsentInsertInput;
  onConflict?: InputMaybe<ConsentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCpoClaimArgs = {
  objects: Array<CpoClaimInsertInput>;
  onConflict?: InputMaybe<CpoClaimOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCpoClaimOneArgs = {
  object: CpoClaimInsertInput;
  onConflict?: InputMaybe<CpoClaimOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertDependantOrderArgs = {
  objects: Array<DependantOrderInsertInput>;
  onConflict?: InputMaybe<DependantOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertDependantOrderOneArgs = {
  object: DependantOrderInsertInput;
  onConflict?: InputMaybe<DependantOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertDiagnosisAbbreviationArgs = {
  objects: Array<DiagnosisAbbreviationInsertInput>;
  onConflict?: InputMaybe<DiagnosisAbbreviationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertDiagnosisAbbreviationOneArgs = {
  object: DiagnosisAbbreviationInsertInput;
  onConflict?: InputMaybe<DiagnosisAbbreviationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertDocumentArgs = {
  objects: Array<DocumentInsertInput>;
  onConflict?: InputMaybe<DocumentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertDocumentOneArgs = {
  object: DocumentInsertInput;
  onConflict?: InputMaybe<DocumentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertDocumentPendingReviewArgs = {
  objects: Array<DocumentPendingReviewInsertInput>;
  onConflict?: InputMaybe<DocumentPendingReviewOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertDocumentPendingReviewOneArgs = {
  object: DocumentPendingReviewInsertInput;
  onConflict?: InputMaybe<DocumentPendingReviewOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertExternalSignedOrderArgs = {
  objects: Array<ExternalSignedOrderInsertInput>;
  onConflict?: InputMaybe<ExternalSignedOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertExternalSignedOrderOneArgs = {
  object: ExternalSignedOrderInsertInput;
  onConflict?: InputMaybe<ExternalSignedOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertExternalStaffMyPatientsArgs = {
  objects: Array<ExternalStaffMyPatientsInsertInput>;
  onConflict?: InputMaybe<ExternalStaffMyPatientsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertExternalStaffMyPatientsOneArgs = {
  object: ExternalStaffMyPatientsInsertInput;
  onConflict?: InputMaybe<ExternalStaffMyPatientsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertExternalstaffArgs = {
  objects: Array<ExternalstaffInsertInput>;
  onConflict?: InputMaybe<ExternalstaffOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertExternalstaffOneArgs = {
  object: ExternalstaffInsertInput;
  onConflict?: InputMaybe<ExternalstaffOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertFaxArgs = {
  objects: Array<FaxInsertInput>;
  onConflict?: InputMaybe<FaxOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertFaxDocumentsArgs = {
  objects: Array<FaxDocumentsInsertInput>;
  onConflict?: InputMaybe<FaxDocumentsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertFaxDocumentsOneArgs = {
  object: FaxDocumentsInsertInput;
  onConflict?: InputMaybe<FaxDocumentsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertFaxOneArgs = {
  object: FaxInsertInput;
  onConflict?: InputMaybe<FaxOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertFreeTextOrderArgs = {
  objects: Array<FreeTextOrderInsertInput>;
  onConflict?: InputMaybe<FreeTextOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertFreeTextOrderOneArgs = {
  object: FreeTextOrderInsertInput;
  onConflict?: InputMaybe<FreeTextOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertFrequencyOrderArgs = {
  objects: Array<FrequencyOrderInsertInput>;
  onConflict?: InputMaybe<FrequencyOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertFrequencyOrderOneArgs = {
  object: FrequencyOrderInsertInput;
  onConflict?: InputMaybe<FrequencyOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertFrequencyProtocolArgs = {
  objects: Array<FrequencyProtocolInsertInput>;
  onConflict?: InputMaybe<FrequencyProtocolOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertFrequencyProtocolOneArgs = {
  object: FrequencyProtocolInsertInput;
  onConflict?: InputMaybe<FrequencyProtocolOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertHcpcsArgs = {
  objects: Array<HcpcsInsertInput>;
  onConflict?: InputMaybe<HcpcsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertHcpcsOneArgs = {
  object: HcpcsInsertInput;
  onConflict?: InputMaybe<HcpcsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertInsuranceplanArgs = {
  objects: Array<InsuranceplanInsertInput>;
  onConflict?: InputMaybe<InsuranceplanOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertInsuranceplanOneArgs = {
  object: InsuranceplanInsertInput;
  onConflict?: InputMaybe<InsuranceplanOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertIntegrationArgs = {
  objects: Array<IntegrationInsertInput>;
  onConflict?: InputMaybe<IntegrationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertIntegrationOneArgs = {
  object: IntegrationInsertInput;
  onConflict?: InputMaybe<IntegrationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertInternalstaffArgs = {
  objects: Array<InternalstaffInsertInput>;
  onConflict?: InputMaybe<InternalstaffOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertInternalstaffOneArgs = {
  object: InternalstaffInsertInput;
  onConflict?: InputMaybe<InternalstaffOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLegacyOrderArgs = {
  objects: Array<LegacyOrderInsertInput>;
  onConflict?: InputMaybe<LegacyOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLegacyOrderFrequencyArgs = {
  objects: Array<LegacyOrderFrequencyInsertInput>;
  onConflict?: InputMaybe<LegacyOrderFrequencyOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLegacyOrderFrequencyOneArgs = {
  object: LegacyOrderFrequencyInsertInput;
  onConflict?: InputMaybe<LegacyOrderFrequencyOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLegacyOrderMedicationsArgs = {
  objects: Array<LegacyOrderMedicationsInsertInput>;
  onConflict?: InputMaybe<LegacyOrderMedicationsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLegacyOrderMedicationsOneArgs = {
  object: LegacyOrderMedicationsInsertInput;
  onConflict?: InputMaybe<LegacyOrderMedicationsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLegacyOrderOneArgs = {
  object: LegacyOrderInsertInput;
  onConflict?: InputMaybe<LegacyOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLegacyOrderOrderableFieldValueArgs = {
  objects: Array<LegacyOrderOrderableFieldValueInsertInput>;
  onConflict?: InputMaybe<LegacyOrderOrderableFieldValueOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLegacyOrderOrderableFieldValueOneArgs = {
  object: LegacyOrderOrderableFieldValueInsertInput;
  onConflict?: InputMaybe<LegacyOrderOrderableFieldValueOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLegacyOrderQualificationArgs = {
  objects: Array<LegacyOrderQualificationInsertInput>;
  onConflict?: InputMaybe<LegacyOrderQualificationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLegacyOrderQualificationOneArgs = {
  object: LegacyOrderQualificationInsertInput;
  onConflict?: InputMaybe<LegacyOrderQualificationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLegacyTreatmentplanOrderArgs = {
  objects: Array<LegacyTreatmentplanOrderInsertInput>;
  onConflict?: InputMaybe<LegacyTreatmentplanOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLegacyTreatmentplanOrderOneArgs = {
  object: LegacyTreatmentplanOrderInsertInput;
  onConflict?: InputMaybe<LegacyTreatmentplanOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLineitemArgs = {
  objects: Array<LineitemInsertInput>;
  onConflict?: InputMaybe<LineitemOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLineitemOneArgs = {
  object: LineitemInsertInput;
  onConflict?: InputMaybe<LineitemOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMedicationArgs = {
  objects: Array<MedicationInsertInput>;
  onConflict?: InputMaybe<MedicationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMedicationOneArgs = {
  object: MedicationInsertInput;
  onConflict?: InputMaybe<MedicationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMedicationOrderArgs = {
  objects: Array<MedicationOrderInsertInput>;
  onConflict?: InputMaybe<MedicationOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMedicationOrderOneArgs = {
  object: MedicationOrderInsertInput;
  onConflict?: InputMaybe<MedicationOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMedicationorderMedicationsArgs = {
  objects: Array<MedicationorderMedicationsInsertInput>;
  onConflict?: InputMaybe<MedicationorderMedicationsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMedicationorderMedicationsOneArgs = {
  object: MedicationorderMedicationsInsertInput;
  onConflict?: InputMaybe<MedicationorderMedicationsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMessageArgs = {
  objects: Array<MessageInsertInput>;
  onConflict?: InputMaybe<MessageOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMessageOneArgs = {
  object: MessageInsertInput;
  onConflict?: InputMaybe<MessageOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderOrderableFieldArgs = {
  objects: Array<OrderOrderableFieldInsertInput>;
  onConflict?: InputMaybe<OrderOrderableFieldOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderOrderableFieldOneArgs = {
  object: OrderOrderableFieldInsertInput;
  onConflict?: InputMaybe<OrderOrderableFieldOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderOrderableLegacyArgs = {
  objects: Array<OrderOrderableLegacyInsertInput>;
  onConflict?: InputMaybe<OrderOrderableLegacyOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderOrderableLegacyOneArgs = {
  object: OrderOrderableLegacyInsertInput;
  onConflict?: InputMaybe<OrderOrderableLegacyOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableArgs = {
  objects: Array<OrderableInsertInput>;
  onConflict?: InputMaybe<OrderableOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableCategoryArgs = {
  objects: Array<OrderableCategoryInsertInput>;
  onConflict?: InputMaybe<OrderableCategoryOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableCategoryOneArgs = {
  object: OrderableCategoryInsertInput;
  onConflict?: InputMaybe<OrderableCategoryOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableOneArgs = {
  object: OrderableInsertInput;
  onConflict?: InputMaybe<OrderableOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableOrderArgs = {
  objects: Array<OrderableOrderInsertInput>;
  onConflict?: InputMaybe<OrderableOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableOrderOneArgs = {
  object: OrderableOrderInsertInput;
  onConflict?: InputMaybe<OrderableOrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableOrderQualificationArgs = {
  objects: Array<OrderableOrderQualificationInsertInput>;
  onConflict?: InputMaybe<OrderableOrderQualificationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableOrderQualificationOneArgs = {
  object: OrderableOrderQualificationInsertInput;
  onConflict?: InputMaybe<OrderableOrderQualificationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableQualificationArgs = {
  objects: Array<OrderableQualificationInsertInput>;
  onConflict?: InputMaybe<OrderableQualificationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableQualificationOneArgs = {
  object: OrderableQualificationInsertInput;
  onConflict?: InputMaybe<OrderableQualificationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableSupplierArgs = {
  objects: Array<OrderableSupplierInsertInput>;
  onConflict?: InputMaybe<OrderableSupplierOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableSupplierOneArgs = {
  object: OrderableSupplierInsertInput;
  onConflict?: InputMaybe<OrderableSupplierOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableSupplierPayerConnectionsArgs = {
  objects: Array<OrderableSupplierPayerConnectionsInsertInput>;
  onConflict?: InputMaybe<OrderableSupplierPayerConnectionsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderableSupplierPayerConnectionsOneArgs = {
  object: OrderableSupplierPayerConnectionsInsertInput;
  onConflict?: InputMaybe<OrderableSupplierPayerConnectionsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderactionArgs = {
  objects: Array<OrderactionInsertInput>;
  onConflict?: InputMaybe<OrderactionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderactionOneArgs = {
  object: OrderactionInsertInput;
  onConflict?: InputMaybe<OrderactionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientArgs = {
  objects: Array<PatientInsertInput>;
  onConflict?: InputMaybe<PatientOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientAliasArgs = {
  objects: Array<PatientAliasInsertInput>;
  onConflict?: InputMaybe<PatientAliasOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientAliasOneArgs = {
  object: PatientAliasInsertInput;
  onConflict?: InputMaybe<PatientAliasOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientCommunicationArgs = {
  objects: Array<PatientCommunicationInsertInput>;
  onConflict?: InputMaybe<PatientCommunicationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientCommunicationOneArgs = {
  object: PatientCommunicationInsertInput;
  onConflict?: InputMaybe<PatientCommunicationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientContactPersonArgs = {
  objects: Array<PatientContactPersonInsertInput>;
  onConflict?: InputMaybe<PatientContactPersonOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientContactPersonOneArgs = {
  object: PatientContactPersonInsertInput;
  onConflict?: InputMaybe<PatientContactPersonOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientEligibilityCheckArgs = {
  objects: Array<PatientEligibilityCheckInsertInput>;
  onConflict?: InputMaybe<PatientEligibilityCheckOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientEligibilityCheckOneArgs = {
  object: PatientEligibilityCheckInsertInput;
  onConflict?: InputMaybe<PatientEligibilityCheckOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientIntakeArgs = {
  objects: Array<PatientIntakeInsertInput>;
  onConflict?: InputMaybe<PatientIntakeOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientIntakeOneArgs = {
  object: PatientIntakeInsertInput;
  onConflict?: InputMaybe<PatientIntakeOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientIntakeRecordsLocationsArgs = {
  objects: Array<PatientIntakeRecordsLocationsInsertInput>;
  onConflict?: InputMaybe<PatientIntakeRecordsLocationsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientIntakeRecordsLocationsOneArgs = {
  object: PatientIntakeRecordsLocationsInsertInput;
  onConflict?: InputMaybe<PatientIntakeRecordsLocationsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientOneArgs = {
  object: PatientInsertInput;
  onConflict?: InputMaybe<PatientOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientPhoneArgs = {
  objects: Array<PatientPhoneInsertInput>;
  onConflict?: InputMaybe<PatientPhoneOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientPhoneOneArgs = {
  object: PatientPhoneInsertInput;
  onConflict?: InputMaybe<PatientPhoneOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientPhotoArgs = {
  objects: Array<PatientPhotoInsertInput>;
  onConflict?: InputMaybe<PatientPhotoOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientPhotoOneArgs = {
  object: PatientPhotoInsertInput;
  onConflict?: InputMaybe<PatientPhotoOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientStatusArgs = {
  objects: Array<PatientStatusInsertInput>;
  onConflict?: InputMaybe<PatientStatusOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientStatusActionArgs = {
  objects: Array<PatientStatusActionInsertInput>;
  onConflict?: InputMaybe<PatientStatusActionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientStatusActionOneArgs = {
  object: PatientStatusActionInsertInput;
  onConflict?: InputMaybe<PatientStatusActionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientStatusOneArgs = {
  object: PatientStatusInsertInput;
  onConflict?: InputMaybe<PatientStatusOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientStatusSubsequentStatusesArgs = {
  objects: Array<PatientStatusSubsequentStatusesInsertInput>;
  onConflict?: InputMaybe<PatientStatusSubsequentStatusesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPatientStatusSubsequentStatusesOneArgs = {
  object: PatientStatusSubsequentStatusesInsertInput;
  onConflict?: InputMaybe<PatientStatusSubsequentStatusesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPayerArgs = {
  objects: Array<PayerInsertInput>;
  onConflict?: InputMaybe<PayerOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPayerOneArgs = {
  object: PayerInsertInput;
  onConflict?: InputMaybe<PayerOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPracticegroupArgs = {
  objects: Array<PracticegroupInsertInput>;
  onConflict?: InputMaybe<PracticegroupOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPracticegroupOneArgs = {
  object: PracticegroupInsertInput;
  onConflict?: InputMaybe<PracticegroupOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProblemArgs = {
  objects: Array<ProblemInsertInput>;
  onConflict?: InputMaybe<ProblemOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProblemOneArgs = {
  object: ProblemInsertInput;
  onConflict?: InputMaybe<ProblemOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProblemPlanArgs = {
  objects: Array<ProblemPlanInsertInput>;
  onConflict?: InputMaybe<ProblemPlanOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProblemPlanOneArgs = {
  object: ProblemPlanInsertInput;
  onConflict?: InputMaybe<ProblemPlanOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProcedureArgs = {
  objects: Array<ProcedureInsertInput>;
  onConflict?: InputMaybe<ProcedureOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProcedureHcpcsArgs = {
  objects: Array<ProcedureHcpcsInsertInput>;
  onConflict?: InputMaybe<ProcedureHcpcsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProcedureHcpcsOneArgs = {
  object: ProcedureHcpcsInsertInput;
  onConflict?: InputMaybe<ProcedureHcpcsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProcedureOneArgs = {
  object: ProcedureInsertInput;
  onConflict?: InputMaybe<ProcedureOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProcedureRenderedArgs = {
  objects: Array<ProcedureRenderedInsertInput>;
  onConflict?: InputMaybe<ProcedureRenderedOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProcedureRenderedOneArgs = {
  object: ProcedureRenderedInsertInput;
  onConflict?: InputMaybe<ProcedureRenderedOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProviderArgs = {
  objects: Array<ProviderInsertInput>;
  onConflict?: InputMaybe<ProviderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProviderClassificationArgs = {
  objects: Array<ProviderClassificationInsertInput>;
  onConflict?: InputMaybe<ProviderClassificationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProviderClassificationOneArgs = {
  object: ProviderClassificationInsertInput;
  onConflict?: InputMaybe<ProviderClassificationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProviderOneArgs = {
  object: ProviderInsertInput;
  onConflict?: InputMaybe<ProviderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProviderScheduleArgs = {
  objects: Array<ProviderScheduleInsertInput>;
  onConflict?: InputMaybe<ProviderScheduleOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProviderScheduleOneArgs = {
  object: ProviderScheduleInsertInput;
  onConflict?: InputMaybe<ProviderScheduleOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertRecordsRequestDestinationArgs = {
  objects: Array<RecordsRequestDestinationInsertInput>;
  onConflict?: InputMaybe<RecordsRequestDestinationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertRecordsRequestDestinationOneArgs = {
  object: RecordsRequestDestinationInsertInput;
  onConflict?: InputMaybe<RecordsRequestDestinationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertReferralArgs = {
  objects: Array<ReferralInsertInput>;
  onConflict?: InputMaybe<ReferralOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertReferralAttachmentsArgs = {
  objects: Array<ReferralAttachmentsInsertInput>;
  onConflict?: InputMaybe<ReferralAttachmentsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertReferralAttachmentsOneArgs = {
  object: ReferralAttachmentsInsertInput;
  onConflict?: InputMaybe<ReferralAttachmentsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertReferralOneArgs = {
  object: ReferralInsertInput;
  onConflict?: InputMaybe<ReferralOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertRenderedAssessmentArgs = {
  objects: Array<RenderedAssessmentInsertInput>;
  onConflict?: InputMaybe<RenderedAssessmentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertRenderedAssessmentOneArgs = {
  object: RenderedAssessmentInsertInput;
  onConflict?: InputMaybe<RenderedAssessmentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSchedulingActionArgs = {
  objects: Array<SchedulingActionInsertInput>;
  onConflict?: InputMaybe<SchedulingActionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSchedulingActionOneArgs = {
  object: SchedulingActionInsertInput;
  onConflict?: InputMaybe<SchedulingActionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertServiceTypeArgs = {
  objects: Array<ServiceTypeInsertInput>;
  onConflict?: InputMaybe<ServiceTypeOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertServiceTypeOneArgs = {
  object: ServiceTypeInsertInput;
  onConflict?: InputMaybe<ServiceTypeOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSignedConsentArgs = {
  objects: Array<SignedConsentInsertInput>;
  onConflict?: InputMaybe<SignedConsentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSignedConsentOneArgs = {
  object: SignedConsentInsertInput;
  onConflict?: InputMaybe<SignedConsentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSignoffArgs = {
  objects: Array<SignoffInsertInput>;
  onConflict?: InputMaybe<SignoffOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSignoffOneArgs = {
  object: SignoffInsertInput;
  onConflict?: InputMaybe<SignoffOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSignoffTypeArgs = {
  objects: Array<SignoffTypeInsertInput>;
  onConflict?: InputMaybe<SignoffTypeOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSignoffTypeOneArgs = {
  object: SignoffTypeInsertInput;
  onConflict?: InputMaybe<SignoffTypeOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTelemedAppointmentRequestArgs = {
  objects: Array<TelemedAppointmentRequestInsertInput>;
  onConflict?: InputMaybe<TelemedAppointmentRequestOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTelemedAppointmentRequestAccessCodeArgs = {
  objects: Array<TelemedAppointmentRequestAccessCodeInsertInput>;
  onConflict?: InputMaybe<TelemedAppointmentRequestAccessCodeOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTelemedAppointmentRequestAccessCodeOneArgs = {
  object: TelemedAppointmentRequestAccessCodeInsertInput;
  onConflict?: InputMaybe<TelemedAppointmentRequestAccessCodeOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTelemedAppointmentRequestOneArgs = {
  object: TelemedAppointmentRequestInsertInput;
  onConflict?: InputMaybe<TelemedAppointmentRequestOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertThreadCategoryArgs = {
  objects: Array<ThreadCategoryInsertInput>;
  onConflict?: InputMaybe<ThreadCategoryOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertThreadCategoryOneArgs = {
  object: ThreadCategoryInsertInput;
  onConflict?: InputMaybe<ThreadCategoryOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertThreadCategoryStatusArgs = {
  objects: Array<ThreadCategoryStatusInsertInput>;
  onConflict?: InputMaybe<ThreadCategoryStatusOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertThreadCategoryStatusOneArgs = {
  object: ThreadCategoryStatusInsertInput;
  onConflict?: InputMaybe<ThreadCategoryStatusOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertThreadFieldArgs = {
  objects: Array<ThreadFieldInsertInput>;
  onConflict?: InputMaybe<ThreadFieldOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertThreadFieldOneArgs = {
  object: ThreadFieldInsertInput;
  onConflict?: InputMaybe<ThreadFieldOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertThreadFieldValueArgs = {
  objects: Array<ThreadFieldValueInsertInput>;
  onConflict?: InputMaybe<ThreadFieldValueOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertThreadFieldValueOneArgs = {
  object: ThreadFieldValueInsertInput;
  onConflict?: InputMaybe<ThreadFieldValueOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTicketSubscriptionArgs = {
  objects: Array<TicketSubscriptionInsertInput>;
  onConflict?: InputMaybe<TicketSubscriptionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTicketSubscriptionOneArgs = {
  object: TicketSubscriptionInsertInput;
  onConflict?: InputMaybe<TicketSubscriptionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTicketThreadArgs = {
  objects: Array<TicketThreadInsertInput>;
  onConflict?: InputMaybe<TicketThreadOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTicketThreadOneArgs = {
  object: TicketThreadInsertInput;
  onConflict?: InputMaybe<TicketThreadOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTreatmentplanArgs = {
  objects: Array<TreatmentplanInsertInput>;
  onConflict?: InputMaybe<TreatmentplanOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTreatmentplanAssessmentArgs = {
  objects: Array<TreatmentplanAssessmentInsertInput>;
  onConflict?: InputMaybe<TreatmentplanAssessmentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTreatmentplanAssessmentOneArgs = {
  object: TreatmentplanAssessmentInsertInput;
  onConflict?: InputMaybe<TreatmentplanAssessmentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTreatmentplanNoteArgs = {
  objects: Array<TreatmentplanNoteInsertInput>;
  onConflict?: InputMaybe<TreatmentplanNoteOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTreatmentplanNoteOneArgs = {
  object: TreatmentplanNoteInsertInput;
  onConflict?: InputMaybe<TreatmentplanNoteOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTreatmentplanOneArgs = {
  object: TreatmentplanInsertInput;
  onConflict?: InputMaybe<TreatmentplanOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTreatmentplanProblemsArgs = {
  objects: Array<TreatmentplanProblemsInsertInput>;
  onConflict?: InputMaybe<TreatmentplanProblemsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTreatmentplanProblemsOneArgs = {
  object: TreatmentplanProblemsInsertInput;
  onConflict?: InputMaybe<TreatmentplanProblemsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTreatmentplanTemplateArgs = {
  objects: Array<TreatmentplanTemplateInsertInput>;
  onConflict?: InputMaybe<TreatmentplanTemplateOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTreatmentplanTemplateAssessmentArgs = {
  objects: Array<TreatmentplanTemplateAssessmentInsertInput>;
  onConflict?: InputMaybe<TreatmentplanTemplateAssessmentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTreatmentplanTemplateAssessmentOneArgs = {
  object: TreatmentplanTemplateAssessmentInsertInput;
  onConflict?: InputMaybe<TreatmentplanTemplateAssessmentOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTreatmentplanTemplateOneArgs = {
  object: TreatmentplanTemplateInsertInput;
  onConflict?: InputMaybe<TreatmentplanTemplateOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUserArgs = {
  objects: Array<UserInsertInput>;
  onConflict?: InputMaybe<UserOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUserGroupsArgs = {
  objects: Array<UserGroupsInsertInput>;
  onConflict?: InputMaybe<UserGroupsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUserGroupsOneArgs = {
  object: UserGroupsInsertInput;
  onConflict?: InputMaybe<UserGroupsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUserOneArgs = {
  object: UserInsertInput;
  onConflict?: InputMaybe<UserOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUserPermissionsArgs = {
  objects: Array<UserPermissionsInsertInput>;
  onConflict?: InputMaybe<UserPermissionsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUserPermissionsOneArgs = {
  object: UserPermissionsInsertInput;
  onConflict?: InputMaybe<UserPermissionsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertVisitRebillArgs = {
  objects: Array<VisitRebillInsertInput>;
  onConflict?: InputMaybe<VisitRebillOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertVisitRebillOneArgs = {
  object: VisitRebillInsertInput;
  onConflict?: InputMaybe<VisitRebillOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertVitalsArgs = {
  objects: Array<VitalsInsertInput>;
  onConflict?: InputMaybe<VitalsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertVitalsOneArgs = {
  object: VitalsInsertInput;
  onConflict?: InputMaybe<VitalsOnConflict>;
};


/** mutation root */
export type Mutation_RootUpdateAddressArgs = {
  _inc?: InputMaybe<AddressIncInput>;
  _set?: InputMaybe<AddressSetInput>;
  where: AddressBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAddressByPkArgs = {
  _inc?: InputMaybe<AddressIncInput>;
  _set?: InputMaybe<AddressSetInput>;
  pk_columns: AddressPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAddressManyArgs = {
  updates: Array<AddressUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAgencyArgs = {
  _inc?: InputMaybe<AgencyIncInput>;
  _set?: InputMaybe<AgencySetInput>;
  where: AgencyBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAgencyByPkArgs = {
  _inc?: InputMaybe<AgencyIncInput>;
  _set?: InputMaybe<AgencySetInput>;
  pk_columns: AgencyPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAgencyLocationArgs = {
  _inc?: InputMaybe<AgencyLocationIncInput>;
  _set?: InputMaybe<AgencyLocationSetInput>;
  where: AgencyLocationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAgencyLocationByPkArgs = {
  _inc?: InputMaybe<AgencyLocationIncInput>;
  _set?: InputMaybe<AgencyLocationSetInput>;
  pk_columns: AgencyLocationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAgencyLocationManyArgs = {
  updates: Array<AgencyLocationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAgencyManyArgs = {
  updates: Array<AgencyUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAgencyPayersArgs = {
  _inc?: InputMaybe<AgencyPayersIncInput>;
  _set?: InputMaybe<AgencyPayersSetInput>;
  where: AgencyPayersBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAgencyPayersByPkArgs = {
  _inc?: InputMaybe<AgencyPayersIncInput>;
  _set?: InputMaybe<AgencyPayersSetInput>;
  pk_columns: AgencyPayersPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAgencyPayersManyArgs = {
  updates: Array<AgencyPayersUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAgencyServicesArgs = {
  _inc?: InputMaybe<AgencyServicesIncInput>;
  _set?: InputMaybe<AgencyServicesSetInput>;
  where: AgencyServicesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAgencyServicesByPkArgs = {
  _inc?: InputMaybe<AgencyServicesIncInput>;
  _set?: InputMaybe<AgencyServicesSetInput>;
  pk_columns: AgencyServicesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAgencyServicesManyArgs = {
  updates: Array<AgencyServicesUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAgencyconnectionArgs = {
  _inc?: InputMaybe<AgencyconnectionIncInput>;
  _set?: InputMaybe<AgencyconnectionSetInput>;
  where: AgencyconnectionBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAgencyconnectionByPkArgs = {
  _inc?: InputMaybe<AgencyconnectionIncInput>;
  _set?: InputMaybe<AgencyconnectionSetInput>;
  pk_columns: AgencyconnectionPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAgencyconnectionManyArgs = {
  updates: Array<AgencyconnectionUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAgencyjoinrequestArgs = {
  _inc?: InputMaybe<AgencyjoinrequestIncInput>;
  _set?: InputMaybe<AgencyjoinrequestSetInput>;
  where: AgencyjoinrequestBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAgencyjoinrequestByPkArgs = {
  _inc?: InputMaybe<AgencyjoinrequestIncInput>;
  _set?: InputMaybe<AgencyjoinrequestSetInput>;
  pk_columns: AgencyjoinrequestPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAgencyjoinrequestManyArgs = {
  updates: Array<AgencyjoinrequestUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAllergyArgs = {
  _append?: InputMaybe<AllergyAppendInput>;
  _deleteAtPath?: InputMaybe<AllergyDeleteAtPathInput>;
  _deleteElem?: InputMaybe<AllergyDeleteElemInput>;
  _deleteKey?: InputMaybe<AllergyDeleteKeyInput>;
  _inc?: InputMaybe<AllergyIncInput>;
  _prepend?: InputMaybe<AllergyPrependInput>;
  _set?: InputMaybe<AllergySetInput>;
  where: AllergyBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAllergyByPkArgs = {
  _append?: InputMaybe<AllergyAppendInput>;
  _deleteAtPath?: InputMaybe<AllergyDeleteAtPathInput>;
  _deleteElem?: InputMaybe<AllergyDeleteElemInput>;
  _deleteKey?: InputMaybe<AllergyDeleteKeyInput>;
  _inc?: InputMaybe<AllergyIncInput>;
  _prepend?: InputMaybe<AllergyPrependInput>;
  _set?: InputMaybe<AllergySetInput>;
  pk_columns: AllergyPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAllergyManyArgs = {
  updates: Array<AllergyUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAppointmentArgs = {
  _append?: InputMaybe<AppointmentAppendInput>;
  _deleteAtPath?: InputMaybe<AppointmentDeleteAtPathInput>;
  _deleteElem?: InputMaybe<AppointmentDeleteElemInput>;
  _deleteKey?: InputMaybe<AppointmentDeleteKeyInput>;
  _inc?: InputMaybe<AppointmentIncInput>;
  _prepend?: InputMaybe<AppointmentPrependInput>;
  _set?: InputMaybe<AppointmentSetInput>;
  where: AppointmentBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAppointmentByPkArgs = {
  _append?: InputMaybe<AppointmentAppendInput>;
  _deleteAtPath?: InputMaybe<AppointmentDeleteAtPathInput>;
  _deleteElem?: InputMaybe<AppointmentDeleteElemInput>;
  _deleteKey?: InputMaybe<AppointmentDeleteKeyInput>;
  _inc?: InputMaybe<AppointmentIncInput>;
  _prepend?: InputMaybe<AppointmentPrependInput>;
  _set?: InputMaybe<AppointmentSetInput>;
  pk_columns: AppointmentPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAppointmentManyArgs = {
  updates: Array<AppointmentUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAssessmentArgs = {
  _inc?: InputMaybe<AssessmentIncInput>;
  _set?: InputMaybe<AssessmentSetInput>;
  where: AssessmentBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAssessmentByPkArgs = {
  _inc?: InputMaybe<AssessmentIncInput>;
  _set?: InputMaybe<AssessmentSetInput>;
  pk_columns: AssessmentPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAssessmentItemArgs = {
  _append?: InputMaybe<AssessmentItemAppendInput>;
  _deleteAtPath?: InputMaybe<AssessmentItemDeleteAtPathInput>;
  _deleteElem?: InputMaybe<AssessmentItemDeleteElemInput>;
  _deleteKey?: InputMaybe<AssessmentItemDeleteKeyInput>;
  _inc?: InputMaybe<AssessmentItemIncInput>;
  _prepend?: InputMaybe<AssessmentItemPrependInput>;
  _set?: InputMaybe<AssessmentItemSetInput>;
  where: AssessmentItemBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAssessmentItemByPkArgs = {
  _append?: InputMaybe<AssessmentItemAppendInput>;
  _deleteAtPath?: InputMaybe<AssessmentItemDeleteAtPathInput>;
  _deleteElem?: InputMaybe<AssessmentItemDeleteElemInput>;
  _deleteKey?: InputMaybe<AssessmentItemDeleteKeyInput>;
  _inc?: InputMaybe<AssessmentItemIncInput>;
  _prepend?: InputMaybe<AssessmentItemPrependInput>;
  _set?: InputMaybe<AssessmentItemSetInput>;
  pk_columns: AssessmentItemPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAssessmentItemManyArgs = {
  updates: Array<AssessmentItemUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAssessmentItemValueArgs = {
  _inc?: InputMaybe<AssessmentItemValueIncInput>;
  _set?: InputMaybe<AssessmentItemValueSetInput>;
  where: AssessmentItemValueBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAssessmentItemValueByPkArgs = {
  _inc?: InputMaybe<AssessmentItemValueIncInput>;
  _set?: InputMaybe<AssessmentItemValueSetInput>;
  pk_columns: AssessmentItemValuePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAssessmentItemValueManyArgs = {
  updates: Array<AssessmentItemValueUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAssessmentManyArgs = {
  updates: Array<AssessmentUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateBaseProtocolOrderArgs = {
  _inc?: InputMaybe<BaseProtocolOrderIncInput>;
  _set?: InputMaybe<BaseProtocolOrderSetInput>;
  where: BaseProtocolOrderBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateBaseProtocolOrderByPkArgs = {
  _inc?: InputMaybe<BaseProtocolOrderIncInput>;
  _set?: InputMaybe<BaseProtocolOrderSetInput>;
  pk_columns: BaseProtocolOrderPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateBaseProtocolOrderManyArgs = {
  updates: Array<BaseProtocolOrderUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateBaseorderArgs = {
  _append?: InputMaybe<BaseorderAppendInput>;
  _deleteAtPath?: InputMaybe<BaseorderDeleteAtPathInput>;
  _deleteElem?: InputMaybe<BaseorderDeleteElemInput>;
  _deleteKey?: InputMaybe<BaseorderDeleteKeyInput>;
  _inc?: InputMaybe<BaseorderIncInput>;
  _prepend?: InputMaybe<BaseorderPrependInput>;
  _set?: InputMaybe<BaseorderSetInput>;
  where: BaseorderBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateBaseorderByPkArgs = {
  _append?: InputMaybe<BaseorderAppendInput>;
  _deleteAtPath?: InputMaybe<BaseorderDeleteAtPathInput>;
  _deleteElem?: InputMaybe<BaseorderDeleteElemInput>;
  _deleteKey?: InputMaybe<BaseorderDeleteKeyInput>;
  _inc?: InputMaybe<BaseorderIncInput>;
  _prepend?: InputMaybe<BaseorderPrependInput>;
  _set?: InputMaybe<BaseorderSetInput>;
  pk_columns: BaseorderPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateBaseorderManyArgs = {
  updates: Array<BaseorderUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCaretimeArgs = {
  _inc?: InputMaybe<CaretimeIncInput>;
  _set?: InputMaybe<CaretimeSetInput>;
  where: CaretimeBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCaretimeByPkArgs = {
  _inc?: InputMaybe<CaretimeIncInput>;
  _set?: InputMaybe<CaretimeSetInput>;
  pk_columns: CaretimePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCaretimeManyArgs = {
  updates: Array<CaretimeUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCaretimeProblemsArgs = {
  _inc?: InputMaybe<CaretimeProblemsIncInput>;
  _set?: InputMaybe<CaretimeProblemsSetInput>;
  where: CaretimeProblemsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCaretimeProblemsByPkArgs = {
  _inc?: InputMaybe<CaretimeProblemsIncInput>;
  _set?: InputMaybe<CaretimeProblemsSetInput>;
  pk_columns: CaretimeProblemsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCaretimeProblemsManyArgs = {
  updates: Array<CaretimeProblemsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateClaimArgs = {
  _inc?: InputMaybe<ClaimIncInput>;
  _set?: InputMaybe<ClaimSetInput>;
  where: ClaimBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateClaimByPkArgs = {
  _inc?: InputMaybe<ClaimIncInput>;
  _set?: InputMaybe<ClaimSetInput>;
  pk_columns: ClaimPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateClaimManyArgs = {
  updates: Array<ClaimUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateClinicalNoteFutureReferralsArgs = {
  _inc?: InputMaybe<ClinicalNoteFutureReferralsIncInput>;
  _set?: InputMaybe<ClinicalNoteFutureReferralsSetInput>;
  where: ClinicalNoteFutureReferralsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateClinicalNoteFutureReferralsByPkArgs = {
  _inc?: InputMaybe<ClinicalNoteFutureReferralsIncInput>;
  _set?: InputMaybe<ClinicalNoteFutureReferralsSetInput>;
  pk_columns: ClinicalNoteFutureReferralsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateClinicalNoteFutureReferralsManyArgs = {
  updates: Array<ClinicalNoteFutureReferralsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteArgs = {
  _inc?: InputMaybe<ClinicalnoteIncInput>;
  _set?: InputMaybe<ClinicalnoteSetInput>;
  where: ClinicalnoteBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteByPkArgs = {
  _inc?: InputMaybe<ClinicalnoteIncInput>;
  _set?: InputMaybe<ClinicalnoteSetInput>;
  pk_columns: ClinicalnotePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteFieldArgs = {
  _append?: InputMaybe<ClinicalnoteFieldAppendInput>;
  _deleteAtPath?: InputMaybe<ClinicalnoteFieldDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ClinicalnoteFieldDeleteElemInput>;
  _deleteKey?: InputMaybe<ClinicalnoteFieldDeleteKeyInput>;
  _inc?: InputMaybe<ClinicalnoteFieldIncInput>;
  _prepend?: InputMaybe<ClinicalnoteFieldPrependInput>;
  _set?: InputMaybe<ClinicalnoteFieldSetInput>;
  where: ClinicalnoteFieldBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteFieldByPkArgs = {
  _append?: InputMaybe<ClinicalnoteFieldAppendInput>;
  _deleteAtPath?: InputMaybe<ClinicalnoteFieldDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ClinicalnoteFieldDeleteElemInput>;
  _deleteKey?: InputMaybe<ClinicalnoteFieldDeleteKeyInput>;
  _inc?: InputMaybe<ClinicalnoteFieldIncInput>;
  _prepend?: InputMaybe<ClinicalnoteFieldPrependInput>;
  _set?: InputMaybe<ClinicalnoteFieldSetInput>;
  pk_columns: ClinicalnoteFieldPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteFieldManyArgs = {
  updates: Array<ClinicalnoteFieldUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteFieldValueArgs = {
  _inc?: InputMaybe<ClinicalnoteFieldValueIncInput>;
  _set?: InputMaybe<ClinicalnoteFieldValueSetInput>;
  where: ClinicalnoteFieldValueBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteFieldValueByPkArgs = {
  _inc?: InputMaybe<ClinicalnoteFieldValueIncInput>;
  _set?: InputMaybe<ClinicalnoteFieldValueSetInput>;
  pk_columns: ClinicalnoteFieldValuePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteFieldValueManyArgs = {
  updates: Array<ClinicalnoteFieldValueUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteManyArgs = {
  updates: Array<ClinicalnoteUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteTemplateArgs = {
  _inc?: InputMaybe<ClinicalnoteTemplateIncInput>;
  _set?: InputMaybe<ClinicalnoteTemplateSetInput>;
  where: ClinicalnoteTemplateBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteTemplateByPkArgs = {
  _inc?: InputMaybe<ClinicalnoteTemplateIncInput>;
  _set?: InputMaybe<ClinicalnoteTemplateSetInput>;
  pk_columns: ClinicalnoteTemplatePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteTemplateManyArgs = {
  updates: Array<ClinicalnoteTemplateUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteTemplateProviderClassificationsArgs = {
  _inc?: InputMaybe<ClinicalnoteTemplateProviderClassificationsIncInput>;
  _set?: InputMaybe<ClinicalnoteTemplateProviderClassificationsSetInput>;
  where: ClinicalnoteTemplateProviderClassificationsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteTemplateProviderClassificationsByPkArgs = {
  _inc?: InputMaybe<ClinicalnoteTemplateProviderClassificationsIncInput>;
  _set?: InputMaybe<ClinicalnoteTemplateProviderClassificationsSetInput>;
  pk_columns: ClinicalnoteTemplateProviderClassificationsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateClinicalnoteTemplateProviderClassificationsManyArgs = {
  updates: Array<ClinicalnoteTemplateProviderClassificationsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCodesetArgs = {
  _inc?: InputMaybe<CodesetIncInput>;
  _set?: InputMaybe<CodesetSetInput>;
  where: CodesetBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCodesetAddonArgs = {
  _inc?: InputMaybe<CodesetAddonIncInput>;
  _set?: InputMaybe<CodesetAddonSetInput>;
  where: CodesetAddonBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCodesetAddonByPkArgs = {
  _inc?: InputMaybe<CodesetAddonIncInput>;
  _set?: InputMaybe<CodesetAddonSetInput>;
  pk_columns: CodesetAddonPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCodesetAddonManyArgs = {
  updates: Array<CodesetAddonUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCodesetBaseArgs = {
  _inc?: InputMaybe<CodesetBaseIncInput>;
  _set?: InputMaybe<CodesetBaseSetInput>;
  where: CodesetBaseBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCodesetBaseByPkArgs = {
  _inc?: InputMaybe<CodesetBaseIncInput>;
  _set?: InputMaybe<CodesetBaseSetInput>;
  pk_columns: CodesetBasePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCodesetBaseManyArgs = {
  updates: Array<CodesetBaseUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCodesetByPkArgs = {
  _inc?: InputMaybe<CodesetIncInput>;
  _set?: InputMaybe<CodesetSetInput>;
  pk_columns: CodesetPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCodesetManyArgs = {
  updates: Array<CodesetUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateConsentArgs = {
  _inc?: InputMaybe<ConsentIncInput>;
  _set?: InputMaybe<ConsentSetInput>;
  where: ConsentBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateConsentByPkArgs = {
  _inc?: InputMaybe<ConsentIncInput>;
  _set?: InputMaybe<ConsentSetInput>;
  pk_columns: ConsentPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateConsentManyArgs = {
  updates: Array<ConsentUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateCpoClaimArgs = {
  _inc?: InputMaybe<CpoClaimIncInput>;
  _set?: InputMaybe<CpoClaimSetInput>;
  where: CpoClaimBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCpoClaimByPkArgs = {
  _inc?: InputMaybe<CpoClaimIncInput>;
  _set?: InputMaybe<CpoClaimSetInput>;
  pk_columns: CpoClaimPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCpoClaimManyArgs = {
  updates: Array<CpoClaimUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateDependantOrderArgs = {
  _inc?: InputMaybe<DependantOrderIncInput>;
  _set?: InputMaybe<DependantOrderSetInput>;
  where: DependantOrderBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateDependantOrderByPkArgs = {
  _inc?: InputMaybe<DependantOrderIncInput>;
  _set?: InputMaybe<DependantOrderSetInput>;
  pk_columns: DependantOrderPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateDependantOrderManyArgs = {
  updates: Array<DependantOrderUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateDiagnosisAbbreviationArgs = {
  _inc?: InputMaybe<DiagnosisAbbreviationIncInput>;
  _set?: InputMaybe<DiagnosisAbbreviationSetInput>;
  where: DiagnosisAbbreviationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateDiagnosisAbbreviationByPkArgs = {
  _inc?: InputMaybe<DiagnosisAbbreviationIncInput>;
  _set?: InputMaybe<DiagnosisAbbreviationSetInput>;
  pk_columns: DiagnosisAbbreviationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateDiagnosisAbbreviationManyArgs = {
  updates: Array<DiagnosisAbbreviationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateDocumentArgs = {
  _append?: InputMaybe<DocumentAppendInput>;
  _deleteAtPath?: InputMaybe<DocumentDeleteAtPathInput>;
  _deleteElem?: InputMaybe<DocumentDeleteElemInput>;
  _deleteKey?: InputMaybe<DocumentDeleteKeyInput>;
  _inc?: InputMaybe<DocumentIncInput>;
  _prepend?: InputMaybe<DocumentPrependInput>;
  _set?: InputMaybe<DocumentSetInput>;
  where: DocumentBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateDocumentByPkArgs = {
  _append?: InputMaybe<DocumentAppendInput>;
  _deleteAtPath?: InputMaybe<DocumentDeleteAtPathInput>;
  _deleteElem?: InputMaybe<DocumentDeleteElemInput>;
  _deleteKey?: InputMaybe<DocumentDeleteKeyInput>;
  _inc?: InputMaybe<DocumentIncInput>;
  _prepend?: InputMaybe<DocumentPrependInput>;
  _set?: InputMaybe<DocumentSetInput>;
  pk_columns: DocumentPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateDocumentManyArgs = {
  updates: Array<DocumentUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateDocumentPendingReviewArgs = {
  _append?: InputMaybe<DocumentPendingReviewAppendInput>;
  _deleteAtPath?: InputMaybe<DocumentPendingReviewDeleteAtPathInput>;
  _deleteElem?: InputMaybe<DocumentPendingReviewDeleteElemInput>;
  _deleteKey?: InputMaybe<DocumentPendingReviewDeleteKeyInput>;
  _inc?: InputMaybe<DocumentPendingReviewIncInput>;
  _prepend?: InputMaybe<DocumentPendingReviewPrependInput>;
  _set?: InputMaybe<DocumentPendingReviewSetInput>;
  where: DocumentPendingReviewBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateDocumentPendingReviewByPkArgs = {
  _append?: InputMaybe<DocumentPendingReviewAppendInput>;
  _deleteAtPath?: InputMaybe<DocumentPendingReviewDeleteAtPathInput>;
  _deleteElem?: InputMaybe<DocumentPendingReviewDeleteElemInput>;
  _deleteKey?: InputMaybe<DocumentPendingReviewDeleteKeyInput>;
  _inc?: InputMaybe<DocumentPendingReviewIncInput>;
  _prepend?: InputMaybe<DocumentPendingReviewPrependInput>;
  _set?: InputMaybe<DocumentPendingReviewSetInput>;
  pk_columns: DocumentPendingReviewPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateDocumentPendingReviewManyArgs = {
  updates: Array<DocumentPendingReviewUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateExternalSignedOrderArgs = {
  _inc?: InputMaybe<ExternalSignedOrderIncInput>;
  _set?: InputMaybe<ExternalSignedOrderSetInput>;
  where: ExternalSignedOrderBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateExternalSignedOrderByPkArgs = {
  _inc?: InputMaybe<ExternalSignedOrderIncInput>;
  _set?: InputMaybe<ExternalSignedOrderSetInput>;
  pk_columns: ExternalSignedOrderPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateExternalSignedOrderManyArgs = {
  updates: Array<ExternalSignedOrderUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateExternalStaffMyPatientsArgs = {
  _inc?: InputMaybe<ExternalStaffMyPatientsIncInput>;
  _set?: InputMaybe<ExternalStaffMyPatientsSetInput>;
  where: ExternalStaffMyPatientsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateExternalStaffMyPatientsByPkArgs = {
  _inc?: InputMaybe<ExternalStaffMyPatientsIncInput>;
  _set?: InputMaybe<ExternalStaffMyPatientsSetInput>;
  pk_columns: ExternalStaffMyPatientsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateExternalStaffMyPatientsManyArgs = {
  updates: Array<ExternalStaffMyPatientsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateExternalstaffArgs = {
  _inc?: InputMaybe<ExternalstaffIncInput>;
  _set?: InputMaybe<ExternalstaffSetInput>;
  where: ExternalstaffBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateExternalstaffByPkArgs = {
  _inc?: InputMaybe<ExternalstaffIncInput>;
  _set?: InputMaybe<ExternalstaffSetInput>;
  pk_columns: ExternalstaffPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateExternalstaffManyArgs = {
  updates: Array<ExternalstaffUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateFaxArgs = {
  _inc?: InputMaybe<FaxIncInput>;
  _set?: InputMaybe<FaxSetInput>;
  where: FaxBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateFaxByPkArgs = {
  _inc?: InputMaybe<FaxIncInput>;
  _set?: InputMaybe<FaxSetInput>;
  pk_columns: FaxPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateFaxDocumentsArgs = {
  _inc?: InputMaybe<FaxDocumentsIncInput>;
  _set?: InputMaybe<FaxDocumentsSetInput>;
  where: FaxDocumentsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateFaxDocumentsByPkArgs = {
  _inc?: InputMaybe<FaxDocumentsIncInput>;
  _set?: InputMaybe<FaxDocumentsSetInput>;
  pk_columns: FaxDocumentsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateFaxDocumentsManyArgs = {
  updates: Array<FaxDocumentsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateFaxManyArgs = {
  updates: Array<FaxUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateFreeTextOrderArgs = {
  _inc?: InputMaybe<FreeTextOrderIncInput>;
  _set?: InputMaybe<FreeTextOrderSetInput>;
  where: FreeTextOrderBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateFreeTextOrderByPkArgs = {
  _inc?: InputMaybe<FreeTextOrderIncInput>;
  _set?: InputMaybe<FreeTextOrderSetInput>;
  pk_columns: FreeTextOrderPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateFreeTextOrderManyArgs = {
  updates: Array<FreeTextOrderUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateFrequencyOrderArgs = {
  _inc?: InputMaybe<FrequencyOrderIncInput>;
  _set?: InputMaybe<FrequencyOrderSetInput>;
  where: FrequencyOrderBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateFrequencyOrderByPkArgs = {
  _inc?: InputMaybe<FrequencyOrderIncInput>;
  _set?: InputMaybe<FrequencyOrderSetInput>;
  pk_columns: FrequencyOrderPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateFrequencyOrderManyArgs = {
  updates: Array<FrequencyOrderUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateFrequencyProtocolArgs = {
  _inc?: InputMaybe<FrequencyProtocolIncInput>;
  _set?: InputMaybe<FrequencyProtocolSetInput>;
  where: FrequencyProtocolBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateFrequencyProtocolByPkArgs = {
  _inc?: InputMaybe<FrequencyProtocolIncInput>;
  _set?: InputMaybe<FrequencyProtocolSetInput>;
  pk_columns: FrequencyProtocolPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateFrequencyProtocolManyArgs = {
  updates: Array<FrequencyProtocolUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateHcpcsArgs = {
  _inc?: InputMaybe<HcpcsIncInput>;
  _set?: InputMaybe<HcpcsSetInput>;
  where: HcpcsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateHcpcsByPkArgs = {
  _inc?: InputMaybe<HcpcsIncInput>;
  _set?: InputMaybe<HcpcsSetInput>;
  pk_columns: HcpcsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateHcpcsManyArgs = {
  updates: Array<HcpcsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateInsuranceplanArgs = {
  _inc?: InputMaybe<InsuranceplanIncInput>;
  _set?: InputMaybe<InsuranceplanSetInput>;
  where: InsuranceplanBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateInsuranceplanByPkArgs = {
  _inc?: InputMaybe<InsuranceplanIncInput>;
  _set?: InputMaybe<InsuranceplanSetInput>;
  pk_columns: InsuranceplanPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateInsuranceplanManyArgs = {
  updates: Array<InsuranceplanUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateIntegrationArgs = {
  _append?: InputMaybe<IntegrationAppendInput>;
  _deleteAtPath?: InputMaybe<IntegrationDeleteAtPathInput>;
  _deleteElem?: InputMaybe<IntegrationDeleteElemInput>;
  _deleteKey?: InputMaybe<IntegrationDeleteKeyInput>;
  _inc?: InputMaybe<IntegrationIncInput>;
  _prepend?: InputMaybe<IntegrationPrependInput>;
  _set?: InputMaybe<IntegrationSetInput>;
  where: IntegrationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateIntegrationByPkArgs = {
  _append?: InputMaybe<IntegrationAppendInput>;
  _deleteAtPath?: InputMaybe<IntegrationDeleteAtPathInput>;
  _deleteElem?: InputMaybe<IntegrationDeleteElemInput>;
  _deleteKey?: InputMaybe<IntegrationDeleteKeyInput>;
  _inc?: InputMaybe<IntegrationIncInput>;
  _prepend?: InputMaybe<IntegrationPrependInput>;
  _set?: InputMaybe<IntegrationSetInput>;
  pk_columns: IntegrationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateIntegrationManyArgs = {
  updates: Array<IntegrationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateInternalstaffArgs = {
  _inc?: InputMaybe<InternalstaffIncInput>;
  _set?: InputMaybe<InternalstaffSetInput>;
  where: InternalstaffBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateInternalstaffByPkArgs = {
  _inc?: InputMaybe<InternalstaffIncInput>;
  _set?: InputMaybe<InternalstaffSetInput>;
  pk_columns: InternalstaffPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateInternalstaffManyArgs = {
  updates: Array<InternalstaffUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderArgs = {
  _append?: InputMaybe<LegacyOrderAppendInput>;
  _deleteAtPath?: InputMaybe<LegacyOrderDeleteAtPathInput>;
  _deleteElem?: InputMaybe<LegacyOrderDeleteElemInput>;
  _deleteKey?: InputMaybe<LegacyOrderDeleteKeyInput>;
  _inc?: InputMaybe<LegacyOrderIncInput>;
  _prepend?: InputMaybe<LegacyOrderPrependInput>;
  _set?: InputMaybe<LegacyOrderSetInput>;
  where: LegacyOrderBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderByPkArgs = {
  _append?: InputMaybe<LegacyOrderAppendInput>;
  _deleteAtPath?: InputMaybe<LegacyOrderDeleteAtPathInput>;
  _deleteElem?: InputMaybe<LegacyOrderDeleteElemInput>;
  _deleteKey?: InputMaybe<LegacyOrderDeleteKeyInput>;
  _inc?: InputMaybe<LegacyOrderIncInput>;
  _prepend?: InputMaybe<LegacyOrderPrependInput>;
  _set?: InputMaybe<LegacyOrderSetInput>;
  pk_columns: LegacyOrderPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderFrequencyArgs = {
  _inc?: InputMaybe<LegacyOrderFrequencyIncInput>;
  _set?: InputMaybe<LegacyOrderFrequencySetInput>;
  where: LegacyOrderFrequencyBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderFrequencyByPkArgs = {
  _inc?: InputMaybe<LegacyOrderFrequencyIncInput>;
  _set?: InputMaybe<LegacyOrderFrequencySetInput>;
  pk_columns: LegacyOrderFrequencyPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderFrequencyManyArgs = {
  updates: Array<LegacyOrderFrequencyUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderManyArgs = {
  updates: Array<LegacyOrderUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderMedicationsArgs = {
  _inc?: InputMaybe<LegacyOrderMedicationsIncInput>;
  _set?: InputMaybe<LegacyOrderMedicationsSetInput>;
  where: LegacyOrderMedicationsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderMedicationsByPkArgs = {
  _inc?: InputMaybe<LegacyOrderMedicationsIncInput>;
  _set?: InputMaybe<LegacyOrderMedicationsSetInput>;
  pk_columns: LegacyOrderMedicationsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderMedicationsManyArgs = {
  updates: Array<LegacyOrderMedicationsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderOrderableFieldValueArgs = {
  _inc?: InputMaybe<LegacyOrderOrderableFieldValueIncInput>;
  _set?: InputMaybe<LegacyOrderOrderableFieldValueSetInput>;
  where: LegacyOrderOrderableFieldValueBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderOrderableFieldValueByPkArgs = {
  _inc?: InputMaybe<LegacyOrderOrderableFieldValueIncInput>;
  _set?: InputMaybe<LegacyOrderOrderableFieldValueSetInput>;
  pk_columns: LegacyOrderOrderableFieldValuePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderOrderableFieldValueManyArgs = {
  updates: Array<LegacyOrderOrderableFieldValueUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderQualificationArgs = {
  _inc?: InputMaybe<LegacyOrderQualificationIncInput>;
  _set?: InputMaybe<LegacyOrderQualificationSetInput>;
  where: LegacyOrderQualificationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderQualificationByPkArgs = {
  _inc?: InputMaybe<LegacyOrderQualificationIncInput>;
  _set?: InputMaybe<LegacyOrderQualificationSetInput>;
  pk_columns: LegacyOrderQualificationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateLegacyOrderQualificationManyArgs = {
  updates: Array<LegacyOrderQualificationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateLegacyTreatmentplanOrderArgs = {
  _inc?: InputMaybe<LegacyTreatmentplanOrderIncInput>;
  _set?: InputMaybe<LegacyTreatmentplanOrderSetInput>;
  where: LegacyTreatmentplanOrderBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateLegacyTreatmentplanOrderByPkArgs = {
  _inc?: InputMaybe<LegacyTreatmentplanOrderIncInput>;
  _set?: InputMaybe<LegacyTreatmentplanOrderSetInput>;
  pk_columns: LegacyTreatmentplanOrderPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateLegacyTreatmentplanOrderManyArgs = {
  updates: Array<LegacyTreatmentplanOrderUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateLineitemArgs = {
  _append?: InputMaybe<LineitemAppendInput>;
  _deleteAtPath?: InputMaybe<LineitemDeleteAtPathInput>;
  _deleteElem?: InputMaybe<LineitemDeleteElemInput>;
  _deleteKey?: InputMaybe<LineitemDeleteKeyInput>;
  _inc?: InputMaybe<LineitemIncInput>;
  _prepend?: InputMaybe<LineitemPrependInput>;
  _set?: InputMaybe<LineitemSetInput>;
  where: LineitemBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateLineitemByPkArgs = {
  _append?: InputMaybe<LineitemAppendInput>;
  _deleteAtPath?: InputMaybe<LineitemDeleteAtPathInput>;
  _deleteElem?: InputMaybe<LineitemDeleteElemInput>;
  _deleteKey?: InputMaybe<LineitemDeleteKeyInput>;
  _inc?: InputMaybe<LineitemIncInput>;
  _prepend?: InputMaybe<LineitemPrependInput>;
  _set?: InputMaybe<LineitemSetInput>;
  pk_columns: LineitemPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateLineitemManyArgs = {
  updates: Array<LineitemUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateMedicationArgs = {
  _append?: InputMaybe<MedicationAppendInput>;
  _deleteAtPath?: InputMaybe<MedicationDeleteAtPathInput>;
  _deleteElem?: InputMaybe<MedicationDeleteElemInput>;
  _deleteKey?: InputMaybe<MedicationDeleteKeyInput>;
  _inc?: InputMaybe<MedicationIncInput>;
  _prepend?: InputMaybe<MedicationPrependInput>;
  _set?: InputMaybe<MedicationSetInput>;
  where: MedicationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateMedicationByPkArgs = {
  _append?: InputMaybe<MedicationAppendInput>;
  _deleteAtPath?: InputMaybe<MedicationDeleteAtPathInput>;
  _deleteElem?: InputMaybe<MedicationDeleteElemInput>;
  _deleteKey?: InputMaybe<MedicationDeleteKeyInput>;
  _inc?: InputMaybe<MedicationIncInput>;
  _prepend?: InputMaybe<MedicationPrependInput>;
  _set?: InputMaybe<MedicationSetInput>;
  pk_columns: MedicationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateMedicationManyArgs = {
  updates: Array<MedicationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateMedicationOrderArgs = {
  _inc?: InputMaybe<MedicationOrderIncInput>;
  _set?: InputMaybe<MedicationOrderSetInput>;
  where: MedicationOrderBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateMedicationOrderByPkArgs = {
  _inc?: InputMaybe<MedicationOrderIncInput>;
  _set?: InputMaybe<MedicationOrderSetInput>;
  pk_columns: MedicationOrderPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateMedicationOrderManyArgs = {
  updates: Array<MedicationOrderUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateMedicationorderMedicationsArgs = {
  _inc?: InputMaybe<MedicationorderMedicationsIncInput>;
  _set?: InputMaybe<MedicationorderMedicationsSetInput>;
  where: MedicationorderMedicationsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateMedicationorderMedicationsByPkArgs = {
  _inc?: InputMaybe<MedicationorderMedicationsIncInput>;
  _set?: InputMaybe<MedicationorderMedicationsSetInput>;
  pk_columns: MedicationorderMedicationsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateMedicationorderMedicationsManyArgs = {
  updates: Array<MedicationorderMedicationsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateMessageArgs = {
  _inc?: InputMaybe<MessageIncInput>;
  _set?: InputMaybe<MessageSetInput>;
  where: MessageBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateMessageByPkArgs = {
  _inc?: InputMaybe<MessageIncInput>;
  _set?: InputMaybe<MessageSetInput>;
  pk_columns: MessagePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateMessageManyArgs = {
  updates: Array<MessageUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateOrderOrderableFieldArgs = {
  _append?: InputMaybe<OrderOrderableFieldAppendInput>;
  _deleteAtPath?: InputMaybe<OrderOrderableFieldDeleteAtPathInput>;
  _deleteElem?: InputMaybe<OrderOrderableFieldDeleteElemInput>;
  _deleteKey?: InputMaybe<OrderOrderableFieldDeleteKeyInput>;
  _inc?: InputMaybe<OrderOrderableFieldIncInput>;
  _prepend?: InputMaybe<OrderOrderableFieldPrependInput>;
  _set?: InputMaybe<OrderOrderableFieldSetInput>;
  where: OrderOrderableFieldBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderOrderableFieldByPkArgs = {
  _append?: InputMaybe<OrderOrderableFieldAppendInput>;
  _deleteAtPath?: InputMaybe<OrderOrderableFieldDeleteAtPathInput>;
  _deleteElem?: InputMaybe<OrderOrderableFieldDeleteElemInput>;
  _deleteKey?: InputMaybe<OrderOrderableFieldDeleteKeyInput>;
  _inc?: InputMaybe<OrderOrderableFieldIncInput>;
  _prepend?: InputMaybe<OrderOrderableFieldPrependInput>;
  _set?: InputMaybe<OrderOrderableFieldSetInput>;
  pk_columns: OrderOrderableFieldPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderOrderableFieldManyArgs = {
  updates: Array<OrderOrderableFieldUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateOrderOrderableLegacyArgs = {
  _inc?: InputMaybe<OrderOrderableLegacyIncInput>;
  _set?: InputMaybe<OrderOrderableLegacySetInput>;
  where: OrderOrderableLegacyBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderOrderableLegacyByPkArgs = {
  _inc?: InputMaybe<OrderOrderableLegacyIncInput>;
  _set?: InputMaybe<OrderOrderableLegacySetInput>;
  pk_columns: OrderOrderableLegacyPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderOrderableLegacyManyArgs = {
  updates: Array<OrderOrderableLegacyUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateOrderableArgs = {
  _inc?: InputMaybe<OrderableIncInput>;
  _set?: InputMaybe<OrderableSetInput>;
  where: OrderableBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderableByPkArgs = {
  _inc?: InputMaybe<OrderableIncInput>;
  _set?: InputMaybe<OrderableSetInput>;
  pk_columns: OrderablePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderableCategoryArgs = {
  _inc?: InputMaybe<OrderableCategoryIncInput>;
  _set?: InputMaybe<OrderableCategorySetInput>;
  where: OrderableCategoryBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderableCategoryByPkArgs = {
  _inc?: InputMaybe<OrderableCategoryIncInput>;
  _set?: InputMaybe<OrderableCategorySetInput>;
  pk_columns: OrderableCategoryPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderableCategoryManyArgs = {
  updates: Array<OrderableCategoryUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateOrderableManyArgs = {
  updates: Array<OrderableUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateOrderableOrderArgs = {
  _inc?: InputMaybe<OrderableOrderIncInput>;
  _set?: InputMaybe<OrderableOrderSetInput>;
  where: OrderableOrderBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderableOrderByPkArgs = {
  _inc?: InputMaybe<OrderableOrderIncInput>;
  _set?: InputMaybe<OrderableOrderSetInput>;
  pk_columns: OrderableOrderPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderableOrderManyArgs = {
  updates: Array<OrderableOrderUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateOrderableOrderQualificationArgs = {
  _inc?: InputMaybe<OrderableOrderQualificationIncInput>;
  _set?: InputMaybe<OrderableOrderQualificationSetInput>;
  where: OrderableOrderQualificationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderableOrderQualificationByPkArgs = {
  _inc?: InputMaybe<OrderableOrderQualificationIncInput>;
  _set?: InputMaybe<OrderableOrderQualificationSetInput>;
  pk_columns: OrderableOrderQualificationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderableOrderQualificationManyArgs = {
  updates: Array<OrderableOrderQualificationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateOrderableQualificationArgs = {
  _append?: InputMaybe<OrderableQualificationAppendInput>;
  _deleteAtPath?: InputMaybe<OrderableQualificationDeleteAtPathInput>;
  _deleteElem?: InputMaybe<OrderableQualificationDeleteElemInput>;
  _deleteKey?: InputMaybe<OrderableQualificationDeleteKeyInput>;
  _inc?: InputMaybe<OrderableQualificationIncInput>;
  _prepend?: InputMaybe<OrderableQualificationPrependInput>;
  _set?: InputMaybe<OrderableQualificationSetInput>;
  where: OrderableQualificationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderableQualificationByPkArgs = {
  _append?: InputMaybe<OrderableQualificationAppendInput>;
  _deleteAtPath?: InputMaybe<OrderableQualificationDeleteAtPathInput>;
  _deleteElem?: InputMaybe<OrderableQualificationDeleteElemInput>;
  _deleteKey?: InputMaybe<OrderableQualificationDeleteKeyInput>;
  _inc?: InputMaybe<OrderableQualificationIncInput>;
  _prepend?: InputMaybe<OrderableQualificationPrependInput>;
  _set?: InputMaybe<OrderableQualificationSetInput>;
  pk_columns: OrderableQualificationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderableQualificationManyArgs = {
  updates: Array<OrderableQualificationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateOrderableSupplierArgs = {
  _inc?: InputMaybe<OrderableSupplierIncInput>;
  _set?: InputMaybe<OrderableSupplierSetInput>;
  where: OrderableSupplierBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderableSupplierByPkArgs = {
  _inc?: InputMaybe<OrderableSupplierIncInput>;
  _set?: InputMaybe<OrderableSupplierSetInput>;
  pk_columns: OrderableSupplierPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderableSupplierManyArgs = {
  updates: Array<OrderableSupplierUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateOrderableSupplierPayerConnectionsArgs = {
  _inc?: InputMaybe<OrderableSupplierPayerConnectionsIncInput>;
  _set?: InputMaybe<OrderableSupplierPayerConnectionsSetInput>;
  where: OrderableSupplierPayerConnectionsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderableSupplierPayerConnectionsByPkArgs = {
  _inc?: InputMaybe<OrderableSupplierPayerConnectionsIncInput>;
  _set?: InputMaybe<OrderableSupplierPayerConnectionsSetInput>;
  pk_columns: OrderableSupplierPayerConnectionsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderableSupplierPayerConnectionsManyArgs = {
  updates: Array<OrderableSupplierPayerConnectionsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateOrderactionArgs = {
  _inc?: InputMaybe<OrderactionIncInput>;
  _set?: InputMaybe<OrderactionSetInput>;
  where: OrderactionBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderactionByPkArgs = {
  _inc?: InputMaybe<OrderactionIncInput>;
  _set?: InputMaybe<OrderactionSetInput>;
  pk_columns: OrderactionPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderactionManyArgs = {
  updates: Array<OrderactionUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePatientArgs = {
  _append?: InputMaybe<PatientAppendInput>;
  _deleteAtPath?: InputMaybe<PatientDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PatientDeleteElemInput>;
  _deleteKey?: InputMaybe<PatientDeleteKeyInput>;
  _inc?: InputMaybe<PatientIncInput>;
  _prepend?: InputMaybe<PatientPrependInput>;
  _set?: InputMaybe<PatientSetInput>;
  where: PatientBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePatientAliasArgs = {
  _inc?: InputMaybe<PatientAliasIncInput>;
  _set?: InputMaybe<PatientAliasSetInput>;
  where: PatientAliasBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePatientAliasByPkArgs = {
  _inc?: InputMaybe<PatientAliasIncInput>;
  _set?: InputMaybe<PatientAliasSetInput>;
  pk_columns: PatientAliasPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientAliasManyArgs = {
  updates: Array<PatientAliasUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePatientByPkArgs = {
  _append?: InputMaybe<PatientAppendInput>;
  _deleteAtPath?: InputMaybe<PatientDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PatientDeleteElemInput>;
  _deleteKey?: InputMaybe<PatientDeleteKeyInput>;
  _inc?: InputMaybe<PatientIncInput>;
  _prepend?: InputMaybe<PatientPrependInput>;
  _set?: InputMaybe<PatientSetInput>;
  pk_columns: PatientPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientCommunicationArgs = {
  _inc?: InputMaybe<PatientCommunicationIncInput>;
  _set?: InputMaybe<PatientCommunicationSetInput>;
  where: PatientCommunicationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePatientCommunicationByPkArgs = {
  _inc?: InputMaybe<PatientCommunicationIncInput>;
  _set?: InputMaybe<PatientCommunicationSetInput>;
  pk_columns: PatientCommunicationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientCommunicationManyArgs = {
  updates: Array<PatientCommunicationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePatientContactPersonArgs = {
  _inc?: InputMaybe<PatientContactPersonIncInput>;
  _set?: InputMaybe<PatientContactPersonSetInput>;
  where: PatientContactPersonBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePatientContactPersonByPkArgs = {
  _inc?: InputMaybe<PatientContactPersonIncInput>;
  _set?: InputMaybe<PatientContactPersonSetInput>;
  pk_columns: PatientContactPersonPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientContactPersonManyArgs = {
  updates: Array<PatientContactPersonUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePatientEligibilityCheckArgs = {
  _append?: InputMaybe<PatientEligibilityCheckAppendInput>;
  _deleteAtPath?: InputMaybe<PatientEligibilityCheckDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PatientEligibilityCheckDeleteElemInput>;
  _deleteKey?: InputMaybe<PatientEligibilityCheckDeleteKeyInput>;
  _inc?: InputMaybe<PatientEligibilityCheckIncInput>;
  _prepend?: InputMaybe<PatientEligibilityCheckPrependInput>;
  _set?: InputMaybe<PatientEligibilityCheckSetInput>;
  where: PatientEligibilityCheckBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePatientEligibilityCheckByPkArgs = {
  _append?: InputMaybe<PatientEligibilityCheckAppendInput>;
  _deleteAtPath?: InputMaybe<PatientEligibilityCheckDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PatientEligibilityCheckDeleteElemInput>;
  _deleteKey?: InputMaybe<PatientEligibilityCheckDeleteKeyInput>;
  _inc?: InputMaybe<PatientEligibilityCheckIncInput>;
  _prepend?: InputMaybe<PatientEligibilityCheckPrependInput>;
  _set?: InputMaybe<PatientEligibilityCheckSetInput>;
  pk_columns: PatientEligibilityCheckPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientEligibilityCheckManyArgs = {
  updates: Array<PatientEligibilityCheckUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePatientIntakeArgs = {
  _inc?: InputMaybe<PatientIntakeIncInput>;
  _set?: InputMaybe<PatientIntakeSetInput>;
  where: PatientIntakeBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePatientIntakeByPkArgs = {
  _inc?: InputMaybe<PatientIntakeIncInput>;
  _set?: InputMaybe<PatientIntakeSetInput>;
  pk_columns: PatientIntakePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientIntakeManyArgs = {
  updates: Array<PatientIntakeUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePatientIntakeRecordsLocationsArgs = {
  _inc?: InputMaybe<PatientIntakeRecordsLocationsIncInput>;
  _set?: InputMaybe<PatientIntakeRecordsLocationsSetInput>;
  where: PatientIntakeRecordsLocationsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePatientIntakeRecordsLocationsByPkArgs = {
  _inc?: InputMaybe<PatientIntakeRecordsLocationsIncInput>;
  _set?: InputMaybe<PatientIntakeRecordsLocationsSetInput>;
  pk_columns: PatientIntakeRecordsLocationsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientIntakeRecordsLocationsManyArgs = {
  updates: Array<PatientIntakeRecordsLocationsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePatientManyArgs = {
  updates: Array<PatientUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePatientPhoneArgs = {
  _inc?: InputMaybe<PatientPhoneIncInput>;
  _set?: InputMaybe<PatientPhoneSetInput>;
  where: PatientPhoneBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePatientPhoneByPkArgs = {
  _inc?: InputMaybe<PatientPhoneIncInput>;
  _set?: InputMaybe<PatientPhoneSetInput>;
  pk_columns: PatientPhonePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientPhoneManyArgs = {
  updates: Array<PatientPhoneUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePatientPhotoArgs = {
  _inc?: InputMaybe<PatientPhotoIncInput>;
  _set?: InputMaybe<PatientPhotoSetInput>;
  where: PatientPhotoBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePatientPhotoByPkArgs = {
  _inc?: InputMaybe<PatientPhotoIncInput>;
  _set?: InputMaybe<PatientPhotoSetInput>;
  pk_columns: PatientPhotoPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientPhotoManyArgs = {
  updates: Array<PatientPhotoUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePatientStatusArgs = {
  _inc?: InputMaybe<PatientStatusIncInput>;
  _set?: InputMaybe<PatientStatusSetInput>;
  where: PatientStatusBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePatientStatusActionArgs = {
  _inc?: InputMaybe<PatientStatusActionIncInput>;
  _set?: InputMaybe<PatientStatusActionSetInput>;
  where: PatientStatusActionBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePatientStatusActionByPkArgs = {
  _inc?: InputMaybe<PatientStatusActionIncInput>;
  _set?: InputMaybe<PatientStatusActionSetInput>;
  pk_columns: PatientStatusActionPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientStatusActionManyArgs = {
  updates: Array<PatientStatusActionUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePatientStatusByPkArgs = {
  _inc?: InputMaybe<PatientStatusIncInput>;
  _set?: InputMaybe<PatientStatusSetInput>;
  pk_columns: PatientStatusPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientStatusManyArgs = {
  updates: Array<PatientStatusUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePatientStatusSubsequentStatusesArgs = {
  _inc?: InputMaybe<PatientStatusSubsequentStatusesIncInput>;
  _set?: InputMaybe<PatientStatusSubsequentStatusesSetInput>;
  where: PatientStatusSubsequentStatusesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePatientStatusSubsequentStatusesByPkArgs = {
  _inc?: InputMaybe<PatientStatusSubsequentStatusesIncInput>;
  _set?: InputMaybe<PatientStatusSubsequentStatusesSetInput>;
  pk_columns: PatientStatusSubsequentStatusesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePatientStatusSubsequentStatusesManyArgs = {
  updates: Array<PatientStatusSubsequentStatusesUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePayerArgs = {
  _inc?: InputMaybe<PayerIncInput>;
  _set?: InputMaybe<PayerSetInput>;
  where: PayerBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePayerByPkArgs = {
  _inc?: InputMaybe<PayerIncInput>;
  _set?: InputMaybe<PayerSetInput>;
  pk_columns: PayerPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePayerManyArgs = {
  updates: Array<PayerUpdates>;
};


/** mutation root */
export type Mutation_RootUpdatePracticegroupArgs = {
  _inc?: InputMaybe<PracticegroupIncInput>;
  _set?: InputMaybe<PracticegroupSetInput>;
  where: PracticegroupBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePracticegroupByPkArgs = {
  _inc?: InputMaybe<PracticegroupIncInput>;
  _set?: InputMaybe<PracticegroupSetInput>;
  pk_columns: PracticegroupPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePracticegroupManyArgs = {
  updates: Array<PracticegroupUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateProblemArgs = {
  _inc?: InputMaybe<ProblemIncInput>;
  _set?: InputMaybe<ProblemSetInput>;
  where: ProblemBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateProblemByPkArgs = {
  _inc?: InputMaybe<ProblemIncInput>;
  _set?: InputMaybe<ProblemSetInput>;
  pk_columns: ProblemPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateProblemManyArgs = {
  updates: Array<ProblemUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateProblemPlanArgs = {
  _inc?: InputMaybe<ProblemPlanIncInput>;
  _set?: InputMaybe<ProblemPlanSetInput>;
  where: ProblemPlanBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateProblemPlanByPkArgs = {
  _inc?: InputMaybe<ProblemPlanIncInput>;
  _set?: InputMaybe<ProblemPlanSetInput>;
  pk_columns: ProblemPlanPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateProblemPlanManyArgs = {
  updates: Array<ProblemPlanUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateProcedureArgs = {
  _inc?: InputMaybe<ProcedureIncInput>;
  _set?: InputMaybe<ProcedureSetInput>;
  where: ProcedureBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateProcedureByPkArgs = {
  _inc?: InputMaybe<ProcedureIncInput>;
  _set?: InputMaybe<ProcedureSetInput>;
  pk_columns: ProcedurePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateProcedureHcpcsArgs = {
  _inc?: InputMaybe<ProcedureHcpcsIncInput>;
  _set?: InputMaybe<ProcedureHcpcsSetInput>;
  where: ProcedureHcpcsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateProcedureHcpcsByPkArgs = {
  _inc?: InputMaybe<ProcedureHcpcsIncInput>;
  _set?: InputMaybe<ProcedureHcpcsSetInput>;
  pk_columns: ProcedureHcpcsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateProcedureHcpcsManyArgs = {
  updates: Array<ProcedureHcpcsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateProcedureManyArgs = {
  updates: Array<ProcedureUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateProcedureRenderedArgs = {
  _inc?: InputMaybe<ProcedureRenderedIncInput>;
  _set?: InputMaybe<ProcedureRenderedSetInput>;
  where: ProcedureRenderedBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateProcedureRenderedByPkArgs = {
  _inc?: InputMaybe<ProcedureRenderedIncInput>;
  _set?: InputMaybe<ProcedureRenderedSetInput>;
  pk_columns: ProcedureRenderedPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateProcedureRenderedManyArgs = {
  updates: Array<ProcedureRenderedUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateProviderArgs = {
  _inc?: InputMaybe<ProviderIncInput>;
  _set?: InputMaybe<ProviderSetInput>;
  where: ProviderBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateProviderByPkArgs = {
  _inc?: InputMaybe<ProviderIncInput>;
  _set?: InputMaybe<ProviderSetInput>;
  pk_columns: ProviderPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateProviderClassificationArgs = {
  _inc?: InputMaybe<ProviderClassificationIncInput>;
  _set?: InputMaybe<ProviderClassificationSetInput>;
  where: ProviderClassificationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateProviderClassificationByPkArgs = {
  _inc?: InputMaybe<ProviderClassificationIncInput>;
  _set?: InputMaybe<ProviderClassificationSetInput>;
  pk_columns: ProviderClassificationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateProviderClassificationManyArgs = {
  updates: Array<ProviderClassificationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateProviderManyArgs = {
  updates: Array<ProviderUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateProviderScheduleArgs = {
  _inc?: InputMaybe<ProviderScheduleIncInput>;
  _set?: InputMaybe<ProviderScheduleSetInput>;
  where: ProviderScheduleBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateProviderScheduleByPkArgs = {
  _inc?: InputMaybe<ProviderScheduleIncInput>;
  _set?: InputMaybe<ProviderScheduleSetInput>;
  pk_columns: ProviderSchedulePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateProviderScheduleManyArgs = {
  updates: Array<ProviderScheduleUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateRecordsRequestDestinationArgs = {
  _inc?: InputMaybe<RecordsRequestDestinationIncInput>;
  _set?: InputMaybe<RecordsRequestDestinationSetInput>;
  where: RecordsRequestDestinationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateRecordsRequestDestinationByPkArgs = {
  _inc?: InputMaybe<RecordsRequestDestinationIncInput>;
  _set?: InputMaybe<RecordsRequestDestinationSetInput>;
  pk_columns: RecordsRequestDestinationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateRecordsRequestDestinationManyArgs = {
  updates: Array<RecordsRequestDestinationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateReferralArgs = {
  _inc?: InputMaybe<ReferralIncInput>;
  _set?: InputMaybe<ReferralSetInput>;
  where: ReferralBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateReferralAttachmentsArgs = {
  _inc?: InputMaybe<ReferralAttachmentsIncInput>;
  _set?: InputMaybe<ReferralAttachmentsSetInput>;
  where: ReferralAttachmentsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateReferralAttachmentsByPkArgs = {
  _inc?: InputMaybe<ReferralAttachmentsIncInput>;
  _set?: InputMaybe<ReferralAttachmentsSetInput>;
  pk_columns: ReferralAttachmentsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateReferralAttachmentsManyArgs = {
  updates: Array<ReferralAttachmentsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateReferralByPkArgs = {
  _inc?: InputMaybe<ReferralIncInput>;
  _set?: InputMaybe<ReferralSetInput>;
  pk_columns: ReferralPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateReferralManyArgs = {
  updates: Array<ReferralUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateRenderedAssessmentArgs = {
  _inc?: InputMaybe<RenderedAssessmentIncInput>;
  _set?: InputMaybe<RenderedAssessmentSetInput>;
  where: RenderedAssessmentBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateRenderedAssessmentByPkArgs = {
  _inc?: InputMaybe<RenderedAssessmentIncInput>;
  _set?: InputMaybe<RenderedAssessmentSetInput>;
  pk_columns: RenderedAssessmentPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateRenderedAssessmentManyArgs = {
  updates: Array<RenderedAssessmentUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateSchedulingActionArgs = {
  _inc?: InputMaybe<SchedulingActionIncInput>;
  _set?: InputMaybe<SchedulingActionSetInput>;
  where: SchedulingActionBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateSchedulingActionByPkArgs = {
  _inc?: InputMaybe<SchedulingActionIncInput>;
  _set?: InputMaybe<SchedulingActionSetInput>;
  pk_columns: SchedulingActionPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateSchedulingActionManyArgs = {
  updates: Array<SchedulingActionUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateServiceTypeArgs = {
  _inc?: InputMaybe<ServiceTypeIncInput>;
  _set?: InputMaybe<ServiceTypeSetInput>;
  where: ServiceTypeBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateServiceTypeByPkArgs = {
  _inc?: InputMaybe<ServiceTypeIncInput>;
  _set?: InputMaybe<ServiceTypeSetInput>;
  pk_columns: ServiceTypePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateServiceTypeManyArgs = {
  updates: Array<ServiceTypeUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateSignedConsentArgs = {
  _inc?: InputMaybe<SignedConsentIncInput>;
  _set?: InputMaybe<SignedConsentSetInput>;
  where: SignedConsentBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateSignedConsentByPkArgs = {
  _inc?: InputMaybe<SignedConsentIncInput>;
  _set?: InputMaybe<SignedConsentSetInput>;
  pk_columns: SignedConsentPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateSignedConsentManyArgs = {
  updates: Array<SignedConsentUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateSignoffArgs = {
  _inc?: InputMaybe<SignoffIncInput>;
  _set?: InputMaybe<SignoffSetInput>;
  where: SignoffBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateSignoffByPkArgs = {
  _inc?: InputMaybe<SignoffIncInput>;
  _set?: InputMaybe<SignoffSetInput>;
  pk_columns: SignoffPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateSignoffManyArgs = {
  updates: Array<SignoffUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateSignoffTypeArgs = {
  _inc?: InputMaybe<SignoffTypeIncInput>;
  _set?: InputMaybe<SignoffTypeSetInput>;
  where: SignoffTypeBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateSignoffTypeByPkArgs = {
  _inc?: InputMaybe<SignoffTypeIncInput>;
  _set?: InputMaybe<SignoffTypeSetInput>;
  pk_columns: SignoffTypePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateSignoffTypeManyArgs = {
  updates: Array<SignoffTypeUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTelemedAppointmentRequestArgs = {
  _inc?: InputMaybe<TelemedAppointmentRequestIncInput>;
  _set?: InputMaybe<TelemedAppointmentRequestSetInput>;
  where: TelemedAppointmentRequestBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTelemedAppointmentRequestAccessCodeArgs = {
  _inc?: InputMaybe<TelemedAppointmentRequestAccessCodeIncInput>;
  _set?: InputMaybe<TelemedAppointmentRequestAccessCodeSetInput>;
  where: TelemedAppointmentRequestAccessCodeBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTelemedAppointmentRequestAccessCodeByPkArgs = {
  _inc?: InputMaybe<TelemedAppointmentRequestAccessCodeIncInput>;
  _set?: InputMaybe<TelemedAppointmentRequestAccessCodeSetInput>;
  pk_columns: TelemedAppointmentRequestAccessCodePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTelemedAppointmentRequestAccessCodeManyArgs = {
  updates: Array<TelemedAppointmentRequestAccessCodeUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTelemedAppointmentRequestByPkArgs = {
  _inc?: InputMaybe<TelemedAppointmentRequestIncInput>;
  _set?: InputMaybe<TelemedAppointmentRequestSetInput>;
  pk_columns: TelemedAppointmentRequestPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTelemedAppointmentRequestManyArgs = {
  updates: Array<TelemedAppointmentRequestUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateThreadCategoryArgs = {
  _inc?: InputMaybe<ThreadCategoryIncInput>;
  _set?: InputMaybe<ThreadCategorySetInput>;
  where: ThreadCategoryBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateThreadCategoryByPkArgs = {
  _inc?: InputMaybe<ThreadCategoryIncInput>;
  _set?: InputMaybe<ThreadCategorySetInput>;
  pk_columns: ThreadCategoryPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateThreadCategoryManyArgs = {
  updates: Array<ThreadCategoryUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateThreadCategoryStatusArgs = {
  _inc?: InputMaybe<ThreadCategoryStatusIncInput>;
  _set?: InputMaybe<ThreadCategoryStatusSetInput>;
  where: ThreadCategoryStatusBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateThreadCategoryStatusByPkArgs = {
  _inc?: InputMaybe<ThreadCategoryStatusIncInput>;
  _set?: InputMaybe<ThreadCategoryStatusSetInput>;
  pk_columns: ThreadCategoryStatusPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateThreadCategoryStatusManyArgs = {
  updates: Array<ThreadCategoryStatusUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateThreadFieldArgs = {
  _inc?: InputMaybe<ThreadFieldIncInput>;
  _set?: InputMaybe<ThreadFieldSetInput>;
  where: ThreadFieldBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateThreadFieldByPkArgs = {
  _inc?: InputMaybe<ThreadFieldIncInput>;
  _set?: InputMaybe<ThreadFieldSetInput>;
  pk_columns: ThreadFieldPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateThreadFieldManyArgs = {
  updates: Array<ThreadFieldUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateThreadFieldValueArgs = {
  _inc?: InputMaybe<ThreadFieldValueIncInput>;
  _set?: InputMaybe<ThreadFieldValueSetInput>;
  where: ThreadFieldValueBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateThreadFieldValueByPkArgs = {
  _inc?: InputMaybe<ThreadFieldValueIncInput>;
  _set?: InputMaybe<ThreadFieldValueSetInput>;
  pk_columns: ThreadFieldValuePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateThreadFieldValueManyArgs = {
  updates: Array<ThreadFieldValueUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTicketSubscriptionArgs = {
  _inc?: InputMaybe<TicketSubscriptionIncInput>;
  _set?: InputMaybe<TicketSubscriptionSetInput>;
  where: TicketSubscriptionBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTicketSubscriptionByPkArgs = {
  _inc?: InputMaybe<TicketSubscriptionIncInput>;
  _set?: InputMaybe<TicketSubscriptionSetInput>;
  pk_columns: TicketSubscriptionPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTicketSubscriptionManyArgs = {
  updates: Array<TicketSubscriptionUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTicketThreadArgs = {
  _append?: InputMaybe<TicketThreadAppendInput>;
  _deleteAtPath?: InputMaybe<TicketThreadDeleteAtPathInput>;
  _deleteElem?: InputMaybe<TicketThreadDeleteElemInput>;
  _deleteKey?: InputMaybe<TicketThreadDeleteKeyInput>;
  _inc?: InputMaybe<TicketThreadIncInput>;
  _prepend?: InputMaybe<TicketThreadPrependInput>;
  _set?: InputMaybe<TicketThreadSetInput>;
  where: TicketThreadBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTicketThreadByPkArgs = {
  _append?: InputMaybe<TicketThreadAppendInput>;
  _deleteAtPath?: InputMaybe<TicketThreadDeleteAtPathInput>;
  _deleteElem?: InputMaybe<TicketThreadDeleteElemInput>;
  _deleteKey?: InputMaybe<TicketThreadDeleteKeyInput>;
  _inc?: InputMaybe<TicketThreadIncInput>;
  _prepend?: InputMaybe<TicketThreadPrependInput>;
  _set?: InputMaybe<TicketThreadSetInput>;
  pk_columns: TicketThreadPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTicketThreadManyArgs = {
  updates: Array<TicketThreadUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanArgs = {
  _inc?: InputMaybe<TreatmentplanIncInput>;
  _set?: InputMaybe<TreatmentplanSetInput>;
  where: TreatmentplanBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanAssessmentArgs = {
  _inc?: InputMaybe<TreatmentplanAssessmentIncInput>;
  _set?: InputMaybe<TreatmentplanAssessmentSetInput>;
  where: TreatmentplanAssessmentBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanAssessmentByPkArgs = {
  _inc?: InputMaybe<TreatmentplanAssessmentIncInput>;
  _set?: InputMaybe<TreatmentplanAssessmentSetInput>;
  pk_columns: TreatmentplanAssessmentPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanAssessmentManyArgs = {
  updates: Array<TreatmentplanAssessmentUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanByPkArgs = {
  _inc?: InputMaybe<TreatmentplanIncInput>;
  _set?: InputMaybe<TreatmentplanSetInput>;
  pk_columns: TreatmentplanPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanManyArgs = {
  updates: Array<TreatmentplanUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanNoteArgs = {
  _inc?: InputMaybe<TreatmentplanNoteIncInput>;
  _set?: InputMaybe<TreatmentplanNoteSetInput>;
  where: TreatmentplanNoteBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanNoteByPkArgs = {
  _inc?: InputMaybe<TreatmentplanNoteIncInput>;
  _set?: InputMaybe<TreatmentplanNoteSetInput>;
  pk_columns: TreatmentplanNotePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanNoteManyArgs = {
  updates: Array<TreatmentplanNoteUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanProblemsArgs = {
  _inc?: InputMaybe<TreatmentplanProblemsIncInput>;
  _set?: InputMaybe<TreatmentplanProblemsSetInput>;
  where: TreatmentplanProblemsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanProblemsByPkArgs = {
  _inc?: InputMaybe<TreatmentplanProblemsIncInput>;
  _set?: InputMaybe<TreatmentplanProblemsSetInput>;
  pk_columns: TreatmentplanProblemsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanProblemsManyArgs = {
  updates: Array<TreatmentplanProblemsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanTemplateArgs = {
  _inc?: InputMaybe<TreatmentplanTemplateIncInput>;
  _set?: InputMaybe<TreatmentplanTemplateSetInput>;
  where: TreatmentplanTemplateBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanTemplateAssessmentArgs = {
  _inc?: InputMaybe<TreatmentplanTemplateAssessmentIncInput>;
  _set?: InputMaybe<TreatmentplanTemplateAssessmentSetInput>;
  where: TreatmentplanTemplateAssessmentBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanTemplateAssessmentByPkArgs = {
  _inc?: InputMaybe<TreatmentplanTemplateAssessmentIncInput>;
  _set?: InputMaybe<TreatmentplanTemplateAssessmentSetInput>;
  pk_columns: TreatmentplanTemplateAssessmentPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanTemplateAssessmentManyArgs = {
  updates: Array<TreatmentplanTemplateAssessmentUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanTemplateByPkArgs = {
  _inc?: InputMaybe<TreatmentplanTemplateIncInput>;
  _set?: InputMaybe<TreatmentplanTemplateSetInput>;
  pk_columns: TreatmentplanTemplatePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTreatmentplanTemplateManyArgs = {
  updates: Array<TreatmentplanTemplateUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _inc?: InputMaybe<UserIncInput>;
  _set?: InputMaybe<UserSetInput>;
  where: UserBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateUserByPkArgs = {
  _inc?: InputMaybe<UserIncInput>;
  _set?: InputMaybe<UserSetInput>;
  pk_columns: UserPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateUserGroupsArgs = {
  _inc?: InputMaybe<UserGroupsIncInput>;
  _set?: InputMaybe<UserGroupsSetInput>;
  where: UserGroupsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateUserGroupsByPkArgs = {
  _inc?: InputMaybe<UserGroupsIncInput>;
  _set?: InputMaybe<UserGroupsSetInput>;
  pk_columns: UserGroupsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateUserGroupsManyArgs = {
  updates: Array<UserGroupsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateUserManyArgs = {
  updates: Array<UserUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateUserPermissionsArgs = {
  _inc?: InputMaybe<UserPermissionsIncInput>;
  _set?: InputMaybe<UserPermissionsSetInput>;
  where: UserPermissionsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateUserPermissionsByPkArgs = {
  _inc?: InputMaybe<UserPermissionsIncInput>;
  _set?: InputMaybe<UserPermissionsSetInput>;
  pk_columns: UserPermissionsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateUserPermissionsManyArgs = {
  updates: Array<UserPermissionsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateVisitRebillArgs = {
  _inc?: InputMaybe<VisitRebillIncInput>;
  _set?: InputMaybe<VisitRebillSetInput>;
  where: VisitRebillBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateVisitRebillByPkArgs = {
  _inc?: InputMaybe<VisitRebillIncInput>;
  _set?: InputMaybe<VisitRebillSetInput>;
  pk_columns: VisitRebillPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateVisitRebillManyArgs = {
  updates: Array<VisitRebillUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateVitalsArgs = {
  _inc?: InputMaybe<VitalsIncInput>;
  _set?: InputMaybe<VitalsSetInput>;
  where: VitalsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateVitalsByPkArgs = {
  _inc?: InputMaybe<VitalsIncInput>;
  _set?: InputMaybe<VitalsSetInput>;
  pk_columns: VitalsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateVitalsManyArgs = {
  updates: Array<VitalsUpdates>;
};

/** columns and relationships of "server_orderorderablefield" */
export type OrderOrderableField = {
  __typename?: 'orderOrderableField';
  data?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  fieldValues: Array<LegacyOrderOrderableFieldValue>;
  /** An aggregate relationship */
  fieldValuesAggregate: LegacyOrderOrderableFieldValueAggregate;
  id: Scalars['Int'];
  insertText: Scalars['String'];
  name: Scalars['String'];
  orderWithinGroup: Scalars['smallint'];
  /** An object relationship */
  orderable: Orderable;
  orderableId: Scalars['Int'];
  patientQualification: Scalars['Boolean'];
  required: Scalars['Boolean'];
  type: Scalars['smallint'];
};


/** columns and relationships of "server_orderorderablefield" */
export type OrderOrderableFieldDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "server_orderorderablefield" */
export type OrderOrderableFieldFieldValuesArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderOrderableFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderableFieldValueOrderBy>>;
  where?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
};


/** columns and relationships of "server_orderorderablefield" */
export type OrderOrderableFieldFieldValuesAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderOrderableFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderableFieldValueOrderBy>>;
  where?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
};

/** aggregated selection of "server_orderorderablefield" */
export type OrderOrderableFieldAggregate = {
  __typename?: 'orderOrderableFieldAggregate';
  aggregate?: Maybe<OrderOrderableFieldAggregateFields>;
  nodes: Array<OrderOrderableField>;
};

/** aggregate fields of "server_orderorderablefield" */
export type OrderOrderableFieldAggregateFields = {
  __typename?: 'orderOrderableFieldAggregateFields';
  avg?: Maybe<OrderOrderableFieldAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderOrderableFieldMaxFields>;
  min?: Maybe<OrderOrderableFieldMinFields>;
  stddev?: Maybe<OrderOrderableFieldStddevFields>;
  stddevPop?: Maybe<OrderOrderableFieldStddev_PopFields>;
  stddevSamp?: Maybe<OrderOrderableFieldStddev_SampFields>;
  sum?: Maybe<OrderOrderableFieldSumFields>;
  varPop?: Maybe<OrderOrderableFieldVar_PopFields>;
  varSamp?: Maybe<OrderOrderableFieldVar_SampFields>;
  variance?: Maybe<OrderOrderableFieldVarianceFields>;
};


/** aggregate fields of "server_orderorderablefield" */
export type OrderOrderableFieldAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderOrderableFieldSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_orderorderablefield" */
export type OrderOrderableFieldAggregateOrderBy = {
  avg?: InputMaybe<OrderOrderableField_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrderOrderableField_Max_Order_By>;
  min?: InputMaybe<OrderOrderableField_Min_Order_By>;
  stddev?: InputMaybe<OrderOrderableField_Stddev_Order_By>;
  stddev_pop?: InputMaybe<OrderOrderableField_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<OrderOrderableField_Stddev_Samp_Order_By>;
  sum?: InputMaybe<OrderOrderableField_Sum_Order_By>;
  var_pop?: InputMaybe<OrderOrderableField_Var_Pop_Order_By>;
  var_samp?: InputMaybe<OrderOrderableField_Var_Samp_Order_By>;
  variance?: InputMaybe<OrderOrderableField_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type OrderOrderableFieldAppendInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_orderorderablefield" */
export type OrderOrderableFieldArrRelInsertInput = {
  data: Array<OrderOrderableFieldInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrderOrderableFieldOnConflict>;
};

/** aggregate avg on columns */
export type OrderOrderableFieldAvgFields = {
  __typename?: 'orderOrderableFieldAvgFields';
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderorderablefield". All fields are combined with a logical 'AND'. */
export type OrderOrderableFieldBoolExp = {
  _and?: InputMaybe<Array<OrderOrderableFieldBoolExp>>;
  _not?: InputMaybe<OrderOrderableFieldBoolExp>;
  _or?: InputMaybe<Array<OrderOrderableFieldBoolExp>>;
  data?: InputMaybe<JsonbComparisonExp>;
  fieldValues?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  insertText?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  orderWithinGroup?: InputMaybe<SmallintComparisonExp>;
  orderable?: InputMaybe<OrderableBoolExp>;
  orderableId?: InputMaybe<IntComparisonExp>;
  patientQualification?: InputMaybe<BooleanComparisonExp>;
  required?: InputMaybe<BooleanComparisonExp>;
  type?: InputMaybe<SmallintComparisonExp>;
};

/** unique or primary key constraints on table "server_orderorderablefield" */
export enum OrderOrderableFieldConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerOrderorderablefieldPkey = 'server_orderorderablefield_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type OrderOrderableFieldDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type OrderOrderableFieldDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type OrderOrderableFieldDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_orderorderablefield" */
export type OrderOrderableFieldIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderWithinGroup?: InputMaybe<Scalars['smallint']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_orderorderablefield" */
export type OrderOrderableFieldInsertInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  fieldValues?: InputMaybe<LegacyOrderOrderableFieldValueArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  insertText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderWithinGroup?: InputMaybe<Scalars['smallint']>;
  orderable?: InputMaybe<OrderableObjRelInsertInput>;
  orderableId?: InputMaybe<Scalars['Int']>;
  patientQualification?: InputMaybe<Scalars['Boolean']>;
  required?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type OrderOrderableFieldMaxFields = {
  __typename?: 'orderOrderableFieldMaxFields';
  id?: Maybe<Scalars['Int']>;
  insertText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderWithinGroup?: Maybe<Scalars['smallint']>;
  orderableId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type OrderOrderableFieldMinFields = {
  __typename?: 'orderOrderableFieldMinFields';
  id?: Maybe<Scalars['Int']>;
  insertText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderWithinGroup?: Maybe<Scalars['smallint']>;
  orderableId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_orderorderablefield" */
export type OrderOrderableFieldMutationResponse = {
  __typename?: 'orderOrderableFieldMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderOrderableField>;
};

/** input type for inserting object relation for remote table "server_orderorderablefield" */
export type OrderOrderableFieldObjRelInsertInput = {
  data: OrderOrderableFieldInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrderOrderableFieldOnConflict>;
};

/** on_conflict condition type for table "server_orderorderablefield" */
export type OrderOrderableFieldOnConflict = {
  constraint: OrderOrderableFieldConstraint;
  update_columns?: Array<OrderOrderableFieldUpdateColumn>;
  where?: InputMaybe<OrderOrderableFieldBoolExp>;
};

/** Ordering options when selecting data from "server_orderorderablefield". */
export type OrderOrderableFieldOrderBy = {
  data?: InputMaybe<OrderBy>;
  fieldValuesAggregate?: InputMaybe<LegacyOrderOrderableFieldValueAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  insertText?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  orderable?: InputMaybe<OrderableOrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  patientQualification?: InputMaybe<OrderBy>;
  required?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_orderorderablefield */
export type OrderOrderableFieldPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type OrderOrderableFieldPrependInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_orderorderablefield" */
export enum OrderOrderableFieldSelectColumn {
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  InsertText = 'insertText',
  /** column name */
  Name = 'name',
  /** column name */
  OrderWithinGroup = 'orderWithinGroup',
  /** column name */
  OrderableId = 'orderableId',
  /** column name */
  PatientQualification = 'patientQualification',
  /** column name */
  Required = 'required',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "server_orderorderablefield" */
export type OrderOrderableFieldSetInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  insertText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderWithinGroup?: InputMaybe<Scalars['smallint']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  patientQualification?: InputMaybe<Scalars['Boolean']>;
  required?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type OrderOrderableFieldStddevFields = {
  __typename?: 'orderOrderableFieldStddevFields';
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrderOrderableFieldStddev_PopFields = {
  __typename?: 'orderOrderableFieldStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrderOrderableFieldStddev_SampFields = {
  __typename?: 'orderOrderableFieldStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrderOrderableFieldSumFields = {
  __typename?: 'orderOrderableFieldSumFields';
  id?: Maybe<Scalars['Int']>;
  orderWithinGroup?: Maybe<Scalars['smallint']>;
  orderableId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_orderorderablefield" */
export enum OrderOrderableFieldUpdateColumn {
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  InsertText = 'insertText',
  /** column name */
  Name = 'name',
  /** column name */
  OrderWithinGroup = 'orderWithinGroup',
  /** column name */
  OrderableId = 'orderableId',
  /** column name */
  PatientQualification = 'patientQualification',
  /** column name */
  Required = 'required',
  /** column name */
  Type = 'type'
}

export type OrderOrderableFieldUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<OrderOrderableFieldAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<OrderOrderableFieldDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<OrderOrderableFieldDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<OrderOrderableFieldDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrderOrderableFieldIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<OrderOrderableFieldPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderOrderableFieldSetInput>;
  where: OrderOrderableFieldBoolExp;
};

/** aggregate var_pop on columns */
export type OrderOrderableFieldVar_PopFields = {
  __typename?: 'orderOrderableFieldVar_popFields';
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrderOrderableFieldVar_SampFields = {
  __typename?: 'orderOrderableFieldVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderOrderableFieldVarianceFields = {
  __typename?: 'orderOrderableFieldVarianceFields';
  id?: Maybe<Scalars['Float']>;
  orderWithinGroup?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_orderorderablefield" */
export type OrderOrderableField_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_orderorderablefield" */
export type OrderOrderableField_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  insertText?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_orderorderablefield" */
export type OrderOrderableField_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  insertText?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_orderorderablefield" */
export type OrderOrderableField_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_orderorderablefield" */
export type OrderOrderableField_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_orderorderablefield" */
export type OrderOrderableField_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "orderOrderableField" */
export type OrderOrderableField_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrderOrderableField_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrderOrderableField_StreamCursorValueInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  insertText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderWithinGroup?: InputMaybe<Scalars['smallint']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  patientQualification?: InputMaybe<Scalars['Boolean']>;
  required?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "server_orderorderablefield" */
export type OrderOrderableField_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_orderorderablefield" */
export type OrderOrderableField_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_orderorderablefield" */
export type OrderOrderableField_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_orderorderablefield" */
export type OrderOrderableField_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderWithinGroup?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_orderorderable" */
export type OrderOrderableLegacy = {
  __typename?: 'orderOrderableLegacy';
  id: Scalars['Int'];
  /** An object relationship */
  legacyOrder: LegacyOrder;
  orderId: Scalars['Int'];
  /** An object relationship */
  orderable: Orderable;
  orderableId: Scalars['Int'];
  quantity: Scalars['Int'];
};

/** aggregated selection of "server_orderorderable" */
export type OrderOrderableLegacyAggregate = {
  __typename?: 'orderOrderableLegacyAggregate';
  aggregate?: Maybe<OrderOrderableLegacyAggregateFields>;
  nodes: Array<OrderOrderableLegacy>;
};

/** aggregate fields of "server_orderorderable" */
export type OrderOrderableLegacyAggregateFields = {
  __typename?: 'orderOrderableLegacyAggregateFields';
  avg?: Maybe<OrderOrderableLegacyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderOrderableLegacyMaxFields>;
  min?: Maybe<OrderOrderableLegacyMinFields>;
  stddev?: Maybe<OrderOrderableLegacyStddevFields>;
  stddevPop?: Maybe<OrderOrderableLegacyStddev_PopFields>;
  stddevSamp?: Maybe<OrderOrderableLegacyStddev_SampFields>;
  sum?: Maybe<OrderOrderableLegacySumFields>;
  varPop?: Maybe<OrderOrderableLegacyVar_PopFields>;
  varSamp?: Maybe<OrderOrderableLegacyVar_SampFields>;
  variance?: Maybe<OrderOrderableLegacyVarianceFields>;
};


/** aggregate fields of "server_orderorderable" */
export type OrderOrderableLegacyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderOrderableLegacySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_orderorderable" */
export type OrderOrderableLegacyAggregateOrderBy = {
  avg?: InputMaybe<OrderOrderableLegacy_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrderOrderableLegacy_Max_Order_By>;
  min?: InputMaybe<OrderOrderableLegacy_Min_Order_By>;
  stddev?: InputMaybe<OrderOrderableLegacy_Stddev_Order_By>;
  stddev_pop?: InputMaybe<OrderOrderableLegacy_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<OrderOrderableLegacy_Stddev_Samp_Order_By>;
  sum?: InputMaybe<OrderOrderableLegacy_Sum_Order_By>;
  var_pop?: InputMaybe<OrderOrderableLegacy_Var_Pop_Order_By>;
  var_samp?: InputMaybe<OrderOrderableLegacy_Var_Samp_Order_By>;
  variance?: InputMaybe<OrderOrderableLegacy_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_orderorderable" */
export type OrderOrderableLegacyArrRelInsertInput = {
  data: Array<OrderOrderableLegacyInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrderOrderableLegacyOnConflict>;
};

/** aggregate avg on columns */
export type OrderOrderableLegacyAvgFields = {
  __typename?: 'orderOrderableLegacyAvgFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderorderable". All fields are combined with a logical 'AND'. */
export type OrderOrderableLegacyBoolExp = {
  _and?: InputMaybe<Array<OrderOrderableLegacyBoolExp>>;
  _not?: InputMaybe<OrderOrderableLegacyBoolExp>;
  _or?: InputMaybe<Array<OrderOrderableLegacyBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  legacyOrder?: InputMaybe<LegacyOrderBoolExp>;
  orderId?: InputMaybe<IntComparisonExp>;
  orderable?: InputMaybe<OrderableBoolExp>;
  orderableId?: InputMaybe<IntComparisonExp>;
  quantity?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_orderorderable" */
export enum OrderOrderableLegacyConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerOrderorderablePkey = 'server_orderorderable_pkey'
}

/** input type for incrementing numeric columns in table "server_orderorderable" */
export type OrderOrderableLegacyIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_orderorderable" */
export type OrderOrderableLegacyInsertInput = {
  id?: InputMaybe<Scalars['Int']>;
  legacyOrder?: InputMaybe<LegacyOrderObjRelInsertInput>;
  orderId?: InputMaybe<Scalars['Int']>;
  orderable?: InputMaybe<OrderableObjRelInsertInput>;
  orderableId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type OrderOrderableLegacyMaxFields = {
  __typename?: 'orderOrderableLegacyMaxFields';
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  orderableId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type OrderOrderableLegacyMinFields = {
  __typename?: 'orderOrderableLegacyMinFields';
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  orderableId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_orderorderable" */
export type OrderOrderableLegacyMutationResponse = {
  __typename?: 'orderOrderableLegacyMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderOrderableLegacy>;
};

/** on_conflict condition type for table "server_orderorderable" */
export type OrderOrderableLegacyOnConflict = {
  constraint: OrderOrderableLegacyConstraint;
  update_columns?: Array<OrderOrderableLegacyUpdateColumn>;
  where?: InputMaybe<OrderOrderableLegacyBoolExp>;
};

/** Ordering options when selecting data from "server_orderorderable". */
export type OrderOrderableLegacyOrderBy = {
  id?: InputMaybe<OrderBy>;
  legacyOrder?: InputMaybe<LegacyOrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderable?: InputMaybe<OrderableOrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_orderorderable */
export type OrderOrderableLegacyPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_orderorderable" */
export enum OrderOrderableLegacySelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  OrderableId = 'orderableId',
  /** column name */
  Quantity = 'quantity'
}

/** input type for updating data in table "server_orderorderable" */
export type OrderOrderableLegacySetInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type OrderOrderableLegacyStddevFields = {
  __typename?: 'orderOrderableLegacyStddevFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrderOrderableLegacyStddev_PopFields = {
  __typename?: 'orderOrderableLegacyStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrderOrderableLegacyStddev_SampFields = {
  __typename?: 'orderOrderableLegacyStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrderOrderableLegacySumFields = {
  __typename?: 'orderOrderableLegacySumFields';
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  orderableId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_orderorderable" */
export enum OrderOrderableLegacyUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  OrderableId = 'orderableId',
  /** column name */
  Quantity = 'quantity'
}

export type OrderOrderableLegacyUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrderOrderableLegacyIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderOrderableLegacySetInput>;
  where: OrderOrderableLegacyBoolExp;
};

/** aggregate var_pop on columns */
export type OrderOrderableLegacyVar_PopFields = {
  __typename?: 'orderOrderableLegacyVar_popFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrderOrderableLegacyVar_SampFields = {
  __typename?: 'orderOrderableLegacyVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderOrderableLegacyVarianceFields = {
  __typename?: 'orderOrderableLegacyVarianceFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_orderorderable" */
export type OrderOrderableLegacy_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_orderorderable" */
export type OrderOrderableLegacy_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_orderorderable" */
export type OrderOrderableLegacy_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_orderorderable" */
export type OrderOrderableLegacy_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_orderorderable" */
export type OrderOrderableLegacy_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_orderorderable" */
export type OrderOrderableLegacy_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "orderOrderableLegacy" */
export type OrderOrderableLegacy_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrderOrderableLegacy_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrderOrderableLegacy_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_orderorderable" */
export type OrderOrderableLegacy_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_orderorderable" */
export type OrderOrderableLegacy_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_orderorderable" */
export type OrderOrderableLegacy_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_orderorderable" */
export type OrderOrderableLegacy_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_orderable" */
export type Orderable = {
  __typename?: 'orderable';
  /** An object relationship */
  category: OrderableCategory;
  categoryId: Scalars['Int'];
  /** An array relationship */
  children: Array<Orderable>;
  /** An aggregate relationship */
  childrenAggregate: OrderableAggregate;
  description: Scalars['String'];
  id: Scalars['Int'];
  /** An array relationship */
  legacyOrders: Array<LegacyOrder>;
  /** An aggregate relationship */
  legacyOrdersAggregate: LegacyOrderAggregate;
  /** An array relationship */
  orderOrderableFields: Array<OrderOrderableField>;
  /** An aggregate relationship */
  orderOrderableFieldsAggregate: OrderOrderableFieldAggregate;
  /** An array relationship */
  orderOrderables: Array<OrderOrderableLegacy>;
  /** An aggregate relationship */
  orderOrderablesAggregate: OrderOrderableLegacyAggregate;
  /** An array relationship */
  orderableOrders: Array<OrderableOrder>;
  /** An aggregate relationship */
  orderableOrdersAggregate: OrderableOrderAggregate;
  /** An array relationship */
  orderableQualifications: Array<OrderableQualification>;
  /** An aggregate relationship */
  orderableQualificationsAggregate: OrderableQualificationAggregate;
  /** An object relationship */
  parent?: Maybe<Orderable>;
  parentId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  suppliers: Array<OrderableSupplier>;
  /** An aggregate relationship */
  suppliersAggregate: OrderableSupplierAggregate;
  title: Scalars['String'];
  validIcd10: Scalars['_varchar'];
};


/** columns and relationships of "server_orderable" */
export type OrderableChildrenArgs = {
  distinctOn?: InputMaybe<Array<OrderableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderBy>>;
  where?: InputMaybe<OrderableBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableChildrenAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderBy>>;
  where?: InputMaybe<OrderableBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableLegacyOrdersArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableLegacyOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableOrderOrderableFieldsArgs = {
  distinctOn?: InputMaybe<Array<OrderOrderableFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderableFieldOrderBy>>;
  where?: InputMaybe<OrderOrderableFieldBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableOrderOrderableFieldsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderOrderableFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderableFieldOrderBy>>;
  where?: InputMaybe<OrderOrderableFieldBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableOrderOrderablesArgs = {
  distinctOn?: InputMaybe<Array<OrderOrderableLegacySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderableLegacyOrderBy>>;
  where?: InputMaybe<OrderOrderableLegacyBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableOrderOrderablesAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderOrderableLegacySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderableLegacyOrderBy>>;
  where?: InputMaybe<OrderOrderableLegacyBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableOrderableOrdersArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderOrderBy>>;
  where?: InputMaybe<OrderableOrderBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableOrderableOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderOrderBy>>;
  where?: InputMaybe<OrderableOrderBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableOrderableQualificationsArgs = {
  distinctOn?: InputMaybe<Array<OrderableQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableQualificationOrderBy>>;
  where?: InputMaybe<OrderableQualificationBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableOrderableQualificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableQualificationOrderBy>>;
  where?: InputMaybe<OrderableQualificationBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableSuppliersArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierOrderBy>>;
  where?: InputMaybe<OrderableSupplierBoolExp>;
};


/** columns and relationships of "server_orderable" */
export type OrderableSuppliersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierOrderBy>>;
  where?: InputMaybe<OrderableSupplierBoolExp>;
};

/** aggregated selection of "server_orderable" */
export type OrderableAggregate = {
  __typename?: 'orderableAggregate';
  aggregate?: Maybe<OrderableAggregateFields>;
  nodes: Array<Orderable>;
};

/** aggregate fields of "server_orderable" */
export type OrderableAggregateFields = {
  __typename?: 'orderableAggregateFields';
  avg?: Maybe<OrderableAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderableMaxFields>;
  min?: Maybe<OrderableMinFields>;
  stddev?: Maybe<OrderableStddevFields>;
  stddevPop?: Maybe<OrderableStddev_PopFields>;
  stddevSamp?: Maybe<OrderableStddev_SampFields>;
  sum?: Maybe<OrderableSumFields>;
  varPop?: Maybe<OrderableVar_PopFields>;
  varSamp?: Maybe<OrderableVar_SampFields>;
  variance?: Maybe<OrderableVarianceFields>;
};


/** aggregate fields of "server_orderable" */
export type OrderableAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderableSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_orderable" */
export type OrderableAggregateOrderBy = {
  avg?: InputMaybe<Orderable_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Orderable_Max_Order_By>;
  min?: InputMaybe<Orderable_Min_Order_By>;
  stddev?: InputMaybe<Orderable_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Orderable_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Orderable_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Orderable_Sum_Order_By>;
  var_pop?: InputMaybe<Orderable_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Orderable_Var_Samp_Order_By>;
  variance?: InputMaybe<Orderable_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_orderable" */
export type OrderableArrRelInsertInput = {
  data: Array<OrderableInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrderableOnConflict>;
};

/** aggregate avg on columns */
export type OrderableAvgFields = {
  __typename?: 'orderableAvgFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderable". All fields are combined with a logical 'AND'. */
export type OrderableBoolExp = {
  _and?: InputMaybe<Array<OrderableBoolExp>>;
  _not?: InputMaybe<OrderableBoolExp>;
  _or?: InputMaybe<Array<OrderableBoolExp>>;
  category?: InputMaybe<OrderableCategoryBoolExp>;
  categoryId?: InputMaybe<IntComparisonExp>;
  children?: InputMaybe<OrderableBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  legacyOrders?: InputMaybe<LegacyOrderBoolExp>;
  orderOrderableFields?: InputMaybe<OrderOrderableFieldBoolExp>;
  orderOrderables?: InputMaybe<OrderOrderableLegacyBoolExp>;
  orderableOrders?: InputMaybe<OrderableOrderBoolExp>;
  orderableQualifications?: InputMaybe<OrderableQualificationBoolExp>;
  parent?: InputMaybe<OrderableBoolExp>;
  parentId?: InputMaybe<IntComparisonExp>;
  suppliers?: InputMaybe<OrderableSupplierBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  validIcd10?: InputMaybe<_VarcharComparisonExp>;
};

/** columns and relationships of "server_orderablecategory" */
export type OrderableCategory = {
  __typename?: 'orderableCategory';
  classification: Scalars['smallint'];
  description: Scalars['String'];
  id: Scalars['Int'];
  /** An array relationship */
  orderablesInCategory: Array<Orderable>;
  /** An aggregate relationship */
  orderablesInCategoryAggregate: OrderableAggregate;
  title: Scalars['String'];
};


/** columns and relationships of "server_orderablecategory" */
export type OrderableCategoryOrderablesInCategoryArgs = {
  distinctOn?: InputMaybe<Array<OrderableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderBy>>;
  where?: InputMaybe<OrderableBoolExp>;
};


/** columns and relationships of "server_orderablecategory" */
export type OrderableCategoryOrderablesInCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderBy>>;
  where?: InputMaybe<OrderableBoolExp>;
};

/** aggregated selection of "server_orderablecategory" */
export type OrderableCategoryAggregate = {
  __typename?: 'orderableCategoryAggregate';
  aggregate?: Maybe<OrderableCategoryAggregateFields>;
  nodes: Array<OrderableCategory>;
};

/** aggregate fields of "server_orderablecategory" */
export type OrderableCategoryAggregateFields = {
  __typename?: 'orderableCategoryAggregateFields';
  avg?: Maybe<OrderableCategoryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderableCategoryMaxFields>;
  min?: Maybe<OrderableCategoryMinFields>;
  stddev?: Maybe<OrderableCategoryStddevFields>;
  stddevPop?: Maybe<OrderableCategoryStddev_PopFields>;
  stddevSamp?: Maybe<OrderableCategoryStddev_SampFields>;
  sum?: Maybe<OrderableCategorySumFields>;
  varPop?: Maybe<OrderableCategoryVar_PopFields>;
  varSamp?: Maybe<OrderableCategoryVar_SampFields>;
  variance?: Maybe<OrderableCategoryVarianceFields>;
};


/** aggregate fields of "server_orderablecategory" */
export type OrderableCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderableCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type OrderableCategoryAvgFields = {
  __typename?: 'orderableCategoryAvgFields';
  classification?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderablecategory". All fields are combined with a logical 'AND'. */
export type OrderableCategoryBoolExp = {
  _and?: InputMaybe<Array<OrderableCategoryBoolExp>>;
  _not?: InputMaybe<OrderableCategoryBoolExp>;
  _or?: InputMaybe<Array<OrderableCategoryBoolExp>>;
  classification?: InputMaybe<SmallintComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  orderablesInCategory?: InputMaybe<OrderableBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_orderablecategory" */
export enum OrderableCategoryConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerOrderablecategoryPkey = 'server_orderablecategory_pkey'
}

/** input type for incrementing numeric columns in table "server_orderablecategory" */
export type OrderableCategoryIncInput = {
  classification?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_orderablecategory" */
export type OrderableCategoryInsertInput = {
  classification?: InputMaybe<Scalars['smallint']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  orderablesInCategory?: InputMaybe<OrderableArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrderableCategoryMaxFields = {
  __typename?: 'orderableCategoryMaxFields';
  classification?: Maybe<Scalars['smallint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrderableCategoryMinFields = {
  __typename?: 'orderableCategoryMinFields';
  classification?: Maybe<Scalars['smallint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_orderablecategory" */
export type OrderableCategoryMutationResponse = {
  __typename?: 'orderableCategoryMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderableCategory>;
};

/** input type for inserting object relation for remote table "server_orderablecategory" */
export type OrderableCategoryObjRelInsertInput = {
  data: OrderableCategoryInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrderableCategoryOnConflict>;
};

/** on_conflict condition type for table "server_orderablecategory" */
export type OrderableCategoryOnConflict = {
  constraint: OrderableCategoryConstraint;
  update_columns?: Array<OrderableCategoryUpdateColumn>;
  where?: InputMaybe<OrderableCategoryBoolExp>;
};

/** Ordering options when selecting data from "server_orderablecategory". */
export type OrderableCategoryOrderBy = {
  classification?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderablesInCategoryAggregate?: InputMaybe<OrderableAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_orderablecategory */
export type OrderableCategoryPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_orderablecategory" */
export enum OrderableCategorySelectColumn {
  /** column name */
  Classification = 'classification',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "server_orderablecategory" */
export type OrderableCategorySetInput = {
  classification?: InputMaybe<Scalars['smallint']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type OrderableCategoryStddevFields = {
  __typename?: 'orderableCategoryStddevFields';
  classification?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrderableCategoryStddev_PopFields = {
  __typename?: 'orderableCategoryStddev_popFields';
  classification?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrderableCategoryStddev_SampFields = {
  __typename?: 'orderableCategoryStddev_sampFields';
  classification?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrderableCategorySumFields = {
  __typename?: 'orderableCategorySumFields';
  classification?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_orderablecategory" */
export enum OrderableCategoryUpdateColumn {
  /** column name */
  Classification = 'classification',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

export type OrderableCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrderableCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderableCategorySetInput>;
  where: OrderableCategoryBoolExp;
};

/** aggregate var_pop on columns */
export type OrderableCategoryVar_PopFields = {
  __typename?: 'orderableCategoryVar_popFields';
  classification?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrderableCategoryVar_SampFields = {
  __typename?: 'orderableCategoryVar_sampFields';
  classification?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderableCategoryVarianceFields = {
  __typename?: 'orderableCategoryVarianceFields';
  classification?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "orderableCategory" */
export type OrderableCategory_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrderableCategory_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrderableCategory_StreamCursorValueInput = {
  classification?: InputMaybe<Scalars['smallint']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** unique or primary key constraints on table "server_orderable" */
export enum OrderableConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerOrderablePkey = 'server_orderable_pkey'
}

/** input type for incrementing numeric columns in table "server_orderable" */
export type OrderableIncInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_orderable" */
export type OrderableInsertInput = {
  category?: InputMaybe<OrderableCategoryObjRelInsertInput>;
  categoryId?: InputMaybe<Scalars['Int']>;
  children?: InputMaybe<OrderableArrRelInsertInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  legacyOrders?: InputMaybe<LegacyOrderArrRelInsertInput>;
  orderOrderableFields?: InputMaybe<OrderOrderableFieldArrRelInsertInput>;
  orderOrderables?: InputMaybe<OrderOrderableLegacyArrRelInsertInput>;
  orderableOrders?: InputMaybe<OrderableOrderArrRelInsertInput>;
  orderableQualifications?: InputMaybe<OrderableQualificationArrRelInsertInput>;
  parent?: InputMaybe<OrderableObjRelInsertInput>;
  parentId?: InputMaybe<Scalars['Int']>;
  suppliers?: InputMaybe<OrderableSupplierArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']>;
  validIcd10?: InputMaybe<Scalars['_varchar']>;
};

/** aggregate max on columns */
export type OrderableMaxFields = {
  __typename?: 'orderableMaxFields';
  categoryId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrderableMinFields = {
  __typename?: 'orderableMinFields';
  categoryId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_orderable" */
export type OrderableMutationResponse = {
  __typename?: 'orderableMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Orderable>;
};

/** input type for inserting object relation for remote table "server_orderable" */
export type OrderableObjRelInsertInput = {
  data: OrderableInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrderableOnConflict>;
};

/** on_conflict condition type for table "server_orderable" */
export type OrderableOnConflict = {
  constraint: OrderableConstraint;
  update_columns?: Array<OrderableUpdateColumn>;
  where?: InputMaybe<OrderableBoolExp>;
};

/** columns and relationships of "server_orderableorder" */
export type OrderableOrder = {
  __typename?: 'orderableOrder';
  /** An object relationship */
  baseorder: Baseorder;
  baseorderPtrId: Scalars['Int'];
  /** An array relationship */
  fieldValues: Array<LegacyOrderOrderableFieldValue>;
  /** An aggregate relationship */
  fieldValuesAggregate: LegacyOrderOrderableFieldValueAggregate;
  /** An object relationship */
  orderable?: Maybe<Orderable>;
  orderableId?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "server_orderableorder" */
export type OrderableOrderFieldValuesArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderOrderableFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderableFieldValueOrderBy>>;
  where?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
};


/** columns and relationships of "server_orderableorder" */
export type OrderableOrderFieldValuesAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderOrderableFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderableFieldValueOrderBy>>;
  where?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
};

/** aggregated selection of "server_orderableorder" */
export type OrderableOrderAggregate = {
  __typename?: 'orderableOrderAggregate';
  aggregate?: Maybe<OrderableOrderAggregateFields>;
  nodes: Array<OrderableOrder>;
};

/** aggregate fields of "server_orderableorder" */
export type OrderableOrderAggregateFields = {
  __typename?: 'orderableOrderAggregateFields';
  avg?: Maybe<OrderableOrderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderableOrderMaxFields>;
  min?: Maybe<OrderableOrderMinFields>;
  stddev?: Maybe<OrderableOrderStddevFields>;
  stddevPop?: Maybe<OrderableOrderStddev_PopFields>;
  stddevSamp?: Maybe<OrderableOrderStddev_SampFields>;
  sum?: Maybe<OrderableOrderSumFields>;
  varPop?: Maybe<OrderableOrderVar_PopFields>;
  varSamp?: Maybe<OrderableOrderVar_SampFields>;
  variance?: Maybe<OrderableOrderVarianceFields>;
};


/** aggregate fields of "server_orderableorder" */
export type OrderableOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderableOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_orderableorder" */
export type OrderableOrderAggregateOrderBy = {
  avg?: InputMaybe<OrderableOrder_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrderableOrder_Max_Order_By>;
  min?: InputMaybe<OrderableOrder_Min_Order_By>;
  stddev?: InputMaybe<OrderableOrder_Stddev_Order_By>;
  stddev_pop?: InputMaybe<OrderableOrder_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<OrderableOrder_Stddev_Samp_Order_By>;
  sum?: InputMaybe<OrderableOrder_Sum_Order_By>;
  var_pop?: InputMaybe<OrderableOrder_Var_Pop_Order_By>;
  var_samp?: InputMaybe<OrderableOrder_Var_Samp_Order_By>;
  variance?: InputMaybe<OrderableOrder_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_orderableorder" */
export type OrderableOrderArrRelInsertInput = {
  data: Array<OrderableOrderInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrderableOrderOnConflict>;
};

/** aggregate avg on columns */
export type OrderableOrderAvgFields = {
  __typename?: 'orderableOrderAvgFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderableorder". All fields are combined with a logical 'AND'. */
export type OrderableOrderBoolExp = {
  _and?: InputMaybe<Array<OrderableOrderBoolExp>>;
  _not?: InputMaybe<OrderableOrderBoolExp>;
  _or?: InputMaybe<Array<OrderableOrderBoolExp>>;
  baseorder?: InputMaybe<BaseorderBoolExp>;
  baseorderPtrId?: InputMaybe<IntComparisonExp>;
  fieldValues?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
  orderable?: InputMaybe<OrderableBoolExp>;
  orderableId?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "server_orderable". */
export type OrderableOrderBy = {
  category?: InputMaybe<OrderableCategoryOrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  childrenAggregate?: InputMaybe<OrderableAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legacyOrdersAggregate?: InputMaybe<LegacyOrderAggregateOrderBy>;
  orderOrderableFieldsAggregate?: InputMaybe<OrderOrderableFieldAggregateOrderBy>;
  orderOrderablesAggregate?: InputMaybe<OrderOrderableLegacyAggregateOrderBy>;
  orderableOrdersAggregate?: InputMaybe<OrderableOrderAggregateOrderBy>;
  orderableQualificationsAggregate?: InputMaybe<OrderableQualificationAggregateOrderBy>;
  parent?: InputMaybe<OrderableOrderBy>;
  parentId?: InputMaybe<OrderBy>;
  suppliersAggregate?: InputMaybe<OrderableSupplierAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
  validIcd10?: InputMaybe<OrderBy>;
};

/** unique or primary key constraints on table "server_orderableorder" */
export enum OrderableOrderConstraint {
  /** unique or primary key constraint on columns "baseorder_ptr_id" */
  ServerOrderableorderPkey = 'server_orderableorder_pkey'
}

/** input type for incrementing numeric columns in table "server_orderableorder" */
export type OrderableOrderIncInput = {
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
  orderableId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_orderableorder" */
export type OrderableOrderInsertInput = {
  baseorder?: InputMaybe<BaseorderObjRelInsertInput>;
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
  fieldValues?: InputMaybe<LegacyOrderOrderableFieldValueArrRelInsertInput>;
  orderable?: InputMaybe<OrderableObjRelInsertInput>;
  orderableId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type OrderableOrderMaxFields = {
  __typename?: 'orderableOrderMaxFields';
  baseorderPtrId?: Maybe<Scalars['Int']>;
  orderableId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type OrderableOrderMinFields = {
  __typename?: 'orderableOrderMinFields';
  baseorderPtrId?: Maybe<Scalars['Int']>;
  orderableId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_orderableorder" */
export type OrderableOrderMutationResponse = {
  __typename?: 'orderableOrderMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderableOrder>;
};

/** input type for inserting object relation for remote table "server_orderableorder" */
export type OrderableOrderObjRelInsertInput = {
  data: OrderableOrderInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrderableOrderOnConflict>;
};

/** on_conflict condition type for table "server_orderableorder" */
export type OrderableOrderOnConflict = {
  constraint: OrderableOrderConstraint;
  update_columns?: Array<OrderableOrderUpdateColumn>;
  where?: InputMaybe<OrderableOrderBoolExp>;
};

/** Ordering options when selecting data from "server_orderableorder". */
export type OrderableOrderOrderBy = {
  baseorder?: InputMaybe<BaseorderOrderBy>;
  baseorderPtrId?: InputMaybe<OrderBy>;
  fieldValuesAggregate?: InputMaybe<LegacyOrderOrderableFieldValueAggregateOrderBy>;
  orderable?: InputMaybe<OrderableOrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_orderableorder */
export type OrderableOrderPkColumnsInput = {
  baseorderPtrId: Scalars['Int'];
};

/** columns and relationships of "server_orderableorderqualification" */
export type OrderableOrderQualification = {
  __typename?: 'orderableOrderQualification';
  /** An object relationship */
  baseorder: Baseorder;
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  /** An object relationship */
  orderableQualification: OrderableQualification;
  qualificationId: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "server_orderableorderqualification" */
export type OrderableOrderQualificationAggregate = {
  __typename?: 'orderableOrderQualificationAggregate';
  aggregate?: Maybe<OrderableOrderQualificationAggregateFields>;
  nodes: Array<OrderableOrderQualification>;
};

/** aggregate fields of "server_orderableorderqualification" */
export type OrderableOrderQualificationAggregateFields = {
  __typename?: 'orderableOrderQualificationAggregateFields';
  avg?: Maybe<OrderableOrderQualificationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderableOrderQualificationMaxFields>;
  min?: Maybe<OrderableOrderQualificationMinFields>;
  stddev?: Maybe<OrderableOrderQualificationStddevFields>;
  stddevPop?: Maybe<OrderableOrderQualificationStddev_PopFields>;
  stddevSamp?: Maybe<OrderableOrderQualificationStddev_SampFields>;
  sum?: Maybe<OrderableOrderQualificationSumFields>;
  varPop?: Maybe<OrderableOrderQualificationVar_PopFields>;
  varSamp?: Maybe<OrderableOrderQualificationVar_SampFields>;
  variance?: Maybe<OrderableOrderQualificationVarianceFields>;
};


/** aggregate fields of "server_orderableorderqualification" */
export type OrderableOrderQualificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderableOrderQualificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_orderableorderqualification" */
export type OrderableOrderQualificationAggregateOrderBy = {
  avg?: InputMaybe<OrderableOrderQualification_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrderableOrderQualification_Max_Order_By>;
  min?: InputMaybe<OrderableOrderQualification_Min_Order_By>;
  stddev?: InputMaybe<OrderableOrderQualification_Stddev_Order_By>;
  stddev_pop?: InputMaybe<OrderableOrderQualification_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<OrderableOrderQualification_Stddev_Samp_Order_By>;
  sum?: InputMaybe<OrderableOrderQualification_Sum_Order_By>;
  var_pop?: InputMaybe<OrderableOrderQualification_Var_Pop_Order_By>;
  var_samp?: InputMaybe<OrderableOrderQualification_Var_Samp_Order_By>;
  variance?: InputMaybe<OrderableOrderQualification_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_orderableorderqualification" */
export type OrderableOrderQualificationArrRelInsertInput = {
  data: Array<OrderableOrderQualificationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrderableOrderQualificationOnConflict>;
};

/** aggregate avg on columns */
export type OrderableOrderQualificationAvgFields = {
  __typename?: 'orderableOrderQualificationAvgFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderableorderqualification". All fields are combined with a logical 'AND'. */
export type OrderableOrderQualificationBoolExp = {
  _and?: InputMaybe<Array<OrderableOrderQualificationBoolExp>>;
  _not?: InputMaybe<OrderableOrderQualificationBoolExp>;
  _or?: InputMaybe<Array<OrderableOrderQualificationBoolExp>>;
  baseorder?: InputMaybe<BaseorderBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  orderId?: InputMaybe<IntComparisonExp>;
  orderableQualification?: InputMaybe<OrderableQualificationBoolExp>;
  qualificationId?: InputMaybe<IntComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_orderableorderqualification" */
export enum OrderableOrderQualificationConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerOrderableorderqualificationPkey = 'server_orderableorderqualification_pkey'
}

/** input type for incrementing numeric columns in table "server_orderableorderqualification" */
export type OrderableOrderQualificationIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  qualificationId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_orderableorderqualification" */
export type OrderableOrderQualificationInsertInput = {
  baseorder?: InputMaybe<BaseorderObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  orderableQualification?: InputMaybe<OrderableQualificationObjRelInsertInput>;
  qualificationId?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrderableOrderQualificationMaxFields = {
  __typename?: 'orderableOrderQualificationMaxFields';
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  qualificationId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrderableOrderQualificationMinFields = {
  __typename?: 'orderableOrderQualificationMinFields';
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  qualificationId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_orderableorderqualification" */
export type OrderableOrderQualificationMutationResponse = {
  __typename?: 'orderableOrderQualificationMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderableOrderQualification>;
};

/** on_conflict condition type for table "server_orderableorderqualification" */
export type OrderableOrderQualificationOnConflict = {
  constraint: OrderableOrderQualificationConstraint;
  update_columns?: Array<OrderableOrderQualificationUpdateColumn>;
  where?: InputMaybe<OrderableOrderQualificationBoolExp>;
};

/** Ordering options when selecting data from "server_orderableorderqualification". */
export type OrderableOrderQualificationOrderBy = {
  baseorder?: InputMaybe<BaseorderOrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderableQualification?: InputMaybe<OrderableQualificationOrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_orderableorderqualification */
export type OrderableOrderQualificationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_orderableorderqualification" */
export enum OrderableOrderQualificationSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  QualificationId = 'qualificationId',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "server_orderableorderqualification" */
export type OrderableOrderQualificationSetInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  qualificationId?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type OrderableOrderQualificationStddevFields = {
  __typename?: 'orderableOrderQualificationStddevFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrderableOrderQualificationStddev_PopFields = {
  __typename?: 'orderableOrderQualificationStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrderableOrderQualificationStddev_SampFields = {
  __typename?: 'orderableOrderQualificationStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrderableOrderQualificationSumFields = {
  __typename?: 'orderableOrderQualificationSumFields';
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  qualificationId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_orderableorderqualification" */
export enum OrderableOrderQualificationUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  QualificationId = 'qualificationId',
  /** column name */
  Value = 'value'
}

export type OrderableOrderQualificationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrderableOrderQualificationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderableOrderQualificationSetInput>;
  where: OrderableOrderQualificationBoolExp;
};

/** aggregate var_pop on columns */
export type OrderableOrderQualificationVar_PopFields = {
  __typename?: 'orderableOrderQualificationVar_popFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrderableOrderQualificationVar_SampFields = {
  __typename?: 'orderableOrderQualificationVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderableOrderQualificationVarianceFields = {
  __typename?: 'orderableOrderQualificationVarianceFields';
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  qualificationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_orderableorderqualification" */
export type OrderableOrderQualification_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_orderableorderqualification" */
export type OrderableOrderQualification_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_orderableorderqualification" */
export type OrderableOrderQualification_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_orderableorderqualification" */
export type OrderableOrderQualification_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_orderableorderqualification" */
export type OrderableOrderQualification_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_orderableorderqualification" */
export type OrderableOrderQualification_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "orderableOrderQualification" */
export type OrderableOrderQualification_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrderableOrderQualification_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrderableOrderQualification_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  qualificationId?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_orderableorderqualification" */
export type OrderableOrderQualification_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_orderableorderqualification" */
export type OrderableOrderQualification_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_orderableorderqualification" */
export type OrderableOrderQualification_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_orderableorderqualification" */
export type OrderableOrderQualification_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  qualificationId?: InputMaybe<OrderBy>;
};

/** select columns of table "server_orderableorder" */
export enum OrderableOrderSelectColumn {
  /** column name */
  BaseorderPtrId = 'baseorderPtrId',
  /** column name */
  OrderableId = 'orderableId'
}

/** input type for updating data in table "server_orderableorder" */
export type OrderableOrderSetInput = {
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
  orderableId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type OrderableOrderStddevFields = {
  __typename?: 'orderableOrderStddevFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrderableOrderStddev_PopFields = {
  __typename?: 'orderableOrderStddev_popFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrderableOrderStddev_SampFields = {
  __typename?: 'orderableOrderStddev_sampFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrderableOrderSumFields = {
  __typename?: 'orderableOrderSumFields';
  baseorderPtrId?: Maybe<Scalars['Int']>;
  orderableId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_orderableorder" */
export enum OrderableOrderUpdateColumn {
  /** column name */
  BaseorderPtrId = 'baseorderPtrId',
  /** column name */
  OrderableId = 'orderableId'
}

export type OrderableOrderUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrderableOrderIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderableOrderSetInput>;
  where: OrderableOrderBoolExp;
};

/** aggregate var_pop on columns */
export type OrderableOrderVar_PopFields = {
  __typename?: 'orderableOrderVar_popFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrderableOrderVar_SampFields = {
  __typename?: 'orderableOrderVar_sampFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderableOrderVarianceFields = {
  __typename?: 'orderableOrderVarianceFields';
  baseorderPtrId?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_orderableorder" */
export type OrderableOrder_Avg_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_orderableorder" */
export type OrderableOrder_Max_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_orderableorder" */
export type OrderableOrder_Min_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_orderableorder" */
export type OrderableOrder_Stddev_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_orderableorder" */
export type OrderableOrder_Stddev_Pop_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_orderableorder" */
export type OrderableOrder_Stddev_Samp_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "orderableOrder" */
export type OrderableOrder_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrderableOrder_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrderableOrder_StreamCursorValueInput = {
  baseorderPtrId?: InputMaybe<Scalars['Int']>;
  orderableId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_orderableorder" */
export type OrderableOrder_Sum_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_orderableorder" */
export type OrderableOrder_Var_Pop_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_orderableorder" */
export type OrderableOrder_Var_Samp_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_orderableorder" */
export type OrderableOrder_Variance_Order_By = {
  baseorderPtrId?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_orderable */
export type OrderablePkColumnsInput = {
  id: Scalars['Int'];
};

/** columns and relationships of "server_orderablequalification" */
export type OrderableQualification = {
  __typename?: 'orderableQualification';
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  noteText?: Maybe<Scalars['String']>;
  /** An array relationship */
  orderQualificationValues: Array<OrderableOrderQualification>;
  /** An aggregate relationship */
  orderQualificationValuesAggregate: OrderableOrderQualificationAggregate;
  /** An array relationship */
  orderQualifications: Array<LegacyOrderQualification>;
  /** An aggregate relationship */
  orderQualificationsAggregate: LegacyOrderQualificationAggregate;
  /** An object relationship */
  orderable: Orderable;
  orderableId: Scalars['Int'];
  required: Scalars['Boolean'];
  type: Scalars['smallint'];
};


/** columns and relationships of "server_orderablequalification" */
export type OrderableQualificationDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "server_orderablequalification" */
export type OrderableQualificationOrderQualificationValuesArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderQualificationOrderBy>>;
  where?: InputMaybe<OrderableOrderQualificationBoolExp>;
};


/** columns and relationships of "server_orderablequalification" */
export type OrderableQualificationOrderQualificationValuesAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderQualificationOrderBy>>;
  where?: InputMaybe<OrderableOrderQualificationBoolExp>;
};


/** columns and relationships of "server_orderablequalification" */
export type OrderableQualificationOrderQualificationsArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderQualificationOrderBy>>;
  where?: InputMaybe<LegacyOrderQualificationBoolExp>;
};


/** columns and relationships of "server_orderablequalification" */
export type OrderableQualificationOrderQualificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderQualificationOrderBy>>;
  where?: InputMaybe<LegacyOrderQualificationBoolExp>;
};

/** aggregated selection of "server_orderablequalification" */
export type OrderableQualificationAggregate = {
  __typename?: 'orderableQualificationAggregate';
  aggregate?: Maybe<OrderableQualificationAggregateFields>;
  nodes: Array<OrderableQualification>;
};

/** aggregate fields of "server_orderablequalification" */
export type OrderableQualificationAggregateFields = {
  __typename?: 'orderableQualificationAggregateFields';
  avg?: Maybe<OrderableQualificationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderableQualificationMaxFields>;
  min?: Maybe<OrderableQualificationMinFields>;
  stddev?: Maybe<OrderableQualificationStddevFields>;
  stddevPop?: Maybe<OrderableQualificationStddev_PopFields>;
  stddevSamp?: Maybe<OrderableQualificationStddev_SampFields>;
  sum?: Maybe<OrderableQualificationSumFields>;
  varPop?: Maybe<OrderableQualificationVar_PopFields>;
  varSamp?: Maybe<OrderableQualificationVar_SampFields>;
  variance?: Maybe<OrderableQualificationVarianceFields>;
};


/** aggregate fields of "server_orderablequalification" */
export type OrderableQualificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderableQualificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_orderablequalification" */
export type OrderableQualificationAggregateOrderBy = {
  avg?: InputMaybe<OrderableQualification_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrderableQualification_Max_Order_By>;
  min?: InputMaybe<OrderableQualification_Min_Order_By>;
  stddev?: InputMaybe<OrderableQualification_Stddev_Order_By>;
  stddev_pop?: InputMaybe<OrderableQualification_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<OrderableQualification_Stddev_Samp_Order_By>;
  sum?: InputMaybe<OrderableQualification_Sum_Order_By>;
  var_pop?: InputMaybe<OrderableQualification_Var_Pop_Order_By>;
  var_samp?: InputMaybe<OrderableQualification_Var_Samp_Order_By>;
  variance?: InputMaybe<OrderableQualification_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type OrderableQualificationAppendInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_orderablequalification" */
export type OrderableQualificationArrRelInsertInput = {
  data: Array<OrderableQualificationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrderableQualificationOnConflict>;
};

/** aggregate avg on columns */
export type OrderableQualificationAvgFields = {
  __typename?: 'orderableQualificationAvgFields';
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderablequalification". All fields are combined with a logical 'AND'. */
export type OrderableQualificationBoolExp = {
  _and?: InputMaybe<Array<OrderableQualificationBoolExp>>;
  _not?: InputMaybe<OrderableQualificationBoolExp>;
  _or?: InputMaybe<Array<OrderableQualificationBoolExp>>;
  data?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  noteText?: InputMaybe<StringComparisonExp>;
  orderQualificationValues?: InputMaybe<OrderableOrderQualificationBoolExp>;
  orderQualifications?: InputMaybe<LegacyOrderQualificationBoolExp>;
  orderable?: InputMaybe<OrderableBoolExp>;
  orderableId?: InputMaybe<IntComparisonExp>;
  required?: InputMaybe<BooleanComparisonExp>;
  type?: InputMaybe<SmallintComparisonExp>;
};

/** unique or primary key constraints on table "server_orderablequalification" */
export enum OrderableQualificationConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerOrderablequalificationPkey = 'server_orderablequalification_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type OrderableQualificationDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type OrderableQualificationDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type OrderableQualificationDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_orderablequalification" */
export type OrderableQualificationIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_orderablequalification" */
export type OrderableQualificationInsertInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  noteText?: InputMaybe<Scalars['String']>;
  orderQualificationValues?: InputMaybe<OrderableOrderQualificationArrRelInsertInput>;
  orderQualifications?: InputMaybe<LegacyOrderQualificationArrRelInsertInput>;
  orderable?: InputMaybe<OrderableObjRelInsertInput>;
  orderableId?: InputMaybe<Scalars['Int']>;
  required?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type OrderableQualificationMaxFields = {
  __typename?: 'orderableQualificationMaxFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  noteText?: Maybe<Scalars['String']>;
  orderableId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type OrderableQualificationMinFields = {
  __typename?: 'orderableQualificationMinFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  noteText?: Maybe<Scalars['String']>;
  orderableId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_orderablequalification" */
export type OrderableQualificationMutationResponse = {
  __typename?: 'orderableQualificationMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderableQualification>;
};

/** input type for inserting object relation for remote table "server_orderablequalification" */
export type OrderableQualificationObjRelInsertInput = {
  data: OrderableQualificationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrderableQualificationOnConflict>;
};

/** on_conflict condition type for table "server_orderablequalification" */
export type OrderableQualificationOnConflict = {
  constraint: OrderableQualificationConstraint;
  update_columns?: Array<OrderableQualificationUpdateColumn>;
  where?: InputMaybe<OrderableQualificationBoolExp>;
};

/** Ordering options when selecting data from "server_orderablequalification". */
export type OrderableQualificationOrderBy = {
  data?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  noteText?: InputMaybe<OrderBy>;
  orderQualificationValuesAggregate?: InputMaybe<OrderableOrderQualificationAggregateOrderBy>;
  orderQualificationsAggregate?: InputMaybe<LegacyOrderQualificationAggregateOrderBy>;
  orderable?: InputMaybe<OrderableOrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  required?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_orderablequalification */
export type OrderableQualificationPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type OrderableQualificationPrependInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_orderablequalification" */
export enum OrderableQualificationSelectColumn {
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NoteText = 'noteText',
  /** column name */
  OrderableId = 'orderableId',
  /** column name */
  Required = 'required',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "server_orderablequalification" */
export type OrderableQualificationSetInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  noteText?: InputMaybe<Scalars['String']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  required?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type OrderableQualificationStddevFields = {
  __typename?: 'orderableQualificationStddevFields';
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrderableQualificationStddev_PopFields = {
  __typename?: 'orderableQualificationStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrderableQualificationStddev_SampFields = {
  __typename?: 'orderableQualificationStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrderableQualificationSumFields = {
  __typename?: 'orderableQualificationSumFields';
  id?: Maybe<Scalars['Int']>;
  orderableId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_orderablequalification" */
export enum OrderableQualificationUpdateColumn {
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NoteText = 'noteText',
  /** column name */
  OrderableId = 'orderableId',
  /** column name */
  Required = 'required',
  /** column name */
  Type = 'type'
}

export type OrderableQualificationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<OrderableQualificationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<OrderableQualificationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<OrderableQualificationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<OrderableQualificationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrderableQualificationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<OrderableQualificationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderableQualificationSetInput>;
  where: OrderableQualificationBoolExp;
};

/** aggregate var_pop on columns */
export type OrderableQualificationVar_PopFields = {
  __typename?: 'orderableQualificationVar_popFields';
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrderableQualificationVar_SampFields = {
  __typename?: 'orderableQualificationVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderableQualificationVarianceFields = {
  __typename?: 'orderableQualificationVarianceFields';
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_orderablequalification" */
export type OrderableQualification_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_orderablequalification" */
export type OrderableQualification_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  noteText?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_orderablequalification" */
export type OrderableQualification_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  noteText?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_orderablequalification" */
export type OrderableQualification_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_orderablequalification" */
export type OrderableQualification_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_orderablequalification" */
export type OrderableQualification_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "orderableQualification" */
export type OrderableQualification_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrderableQualification_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrderableQualification_StreamCursorValueInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  noteText?: InputMaybe<Scalars['String']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  required?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "server_orderablequalification" */
export type OrderableQualification_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_orderablequalification" */
export type OrderableQualification_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_orderablequalification" */
export type OrderableQualification_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_orderablequalification" */
export type OrderableQualification_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** select columns of table "server_orderable" */
export enum OrderableSelectColumn {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Title = 'title',
  /** column name */
  ValidIcd10 = 'validIcd10'
}

/** input type for updating data in table "server_orderable" */
export type OrderableSetInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  validIcd10?: InputMaybe<Scalars['_varchar']>;
};

/** aggregate stddev on columns */
export type OrderableStddevFields = {
  __typename?: 'orderableStddevFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrderableStddev_PopFields = {
  __typename?: 'orderableStddev_popFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrderableStddev_SampFields = {
  __typename?: 'orderableStddev_sampFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrderableSumFields = {
  __typename?: 'orderableSumFields';
  categoryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "server_orderablesupplier" */
export type OrderableSupplier = {
  __typename?: 'orderableSupplier';
  /** An array relationship */
  acceptedPayers: Array<OrderableSupplierPayerConnections>;
  /** An aggregate relationship */
  acceptedPayersAggregate: OrderableSupplierPayerConnectionsAggregate;
  /** An object relationship */
  agency?: Maybe<Agency>;
  agencyId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  orderable: Orderable;
  orderableId: Scalars['Int'];
};


/** columns and relationships of "server_orderablesupplier" */
export type OrderableSupplierAcceptedPayersArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierPayerConnectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierPayerConnectionsOrderBy>>;
  where?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
};


/** columns and relationships of "server_orderablesupplier" */
export type OrderableSupplierAcceptedPayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierPayerConnectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierPayerConnectionsOrderBy>>;
  where?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
};

/** aggregated selection of "server_orderablesupplier" */
export type OrderableSupplierAggregate = {
  __typename?: 'orderableSupplierAggregate';
  aggregate?: Maybe<OrderableSupplierAggregateFields>;
  nodes: Array<OrderableSupplier>;
};

/** aggregate fields of "server_orderablesupplier" */
export type OrderableSupplierAggregateFields = {
  __typename?: 'orderableSupplierAggregateFields';
  avg?: Maybe<OrderableSupplierAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderableSupplierMaxFields>;
  min?: Maybe<OrderableSupplierMinFields>;
  stddev?: Maybe<OrderableSupplierStddevFields>;
  stddevPop?: Maybe<OrderableSupplierStddev_PopFields>;
  stddevSamp?: Maybe<OrderableSupplierStddev_SampFields>;
  sum?: Maybe<OrderableSupplierSumFields>;
  varPop?: Maybe<OrderableSupplierVar_PopFields>;
  varSamp?: Maybe<OrderableSupplierVar_SampFields>;
  variance?: Maybe<OrderableSupplierVarianceFields>;
};


/** aggregate fields of "server_orderablesupplier" */
export type OrderableSupplierAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderableSupplierSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_orderablesupplier" */
export type OrderableSupplierAggregateOrderBy = {
  avg?: InputMaybe<OrderableSupplier_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrderableSupplier_Max_Order_By>;
  min?: InputMaybe<OrderableSupplier_Min_Order_By>;
  stddev?: InputMaybe<OrderableSupplier_Stddev_Order_By>;
  stddev_pop?: InputMaybe<OrderableSupplier_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<OrderableSupplier_Stddev_Samp_Order_By>;
  sum?: InputMaybe<OrderableSupplier_Sum_Order_By>;
  var_pop?: InputMaybe<OrderableSupplier_Var_Pop_Order_By>;
  var_samp?: InputMaybe<OrderableSupplier_Var_Samp_Order_By>;
  variance?: InputMaybe<OrderableSupplier_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_orderablesupplier" */
export type OrderableSupplierArrRelInsertInput = {
  data: Array<OrderableSupplierInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrderableSupplierOnConflict>;
};

/** aggregate avg on columns */
export type OrderableSupplierAvgFields = {
  __typename?: 'orderableSupplierAvgFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderablesupplier". All fields are combined with a logical 'AND'. */
export type OrderableSupplierBoolExp = {
  _and?: InputMaybe<Array<OrderableSupplierBoolExp>>;
  _not?: InputMaybe<OrderableSupplierBoolExp>;
  _or?: InputMaybe<Array<OrderableSupplierBoolExp>>;
  acceptedPayers?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
  agency?: InputMaybe<AgencyBoolExp>;
  agencyId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  orderable?: InputMaybe<OrderableBoolExp>;
  orderableId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_orderablesupplier" */
export enum OrderableSupplierConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerOrderablesupplierPkey = 'server_orderablesupplier_pkey'
}

/** input type for incrementing numeric columns in table "server_orderablesupplier" */
export type OrderableSupplierIncInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderableId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_orderablesupplier" */
export type OrderableSupplierInsertInput = {
  acceptedPayers?: InputMaybe<OrderableSupplierPayerConnectionsArrRelInsertInput>;
  agency?: InputMaybe<AgencyObjRelInsertInput>;
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderable?: InputMaybe<OrderableObjRelInsertInput>;
  orderableId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type OrderableSupplierMaxFields = {
  __typename?: 'orderableSupplierMaxFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderableId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type OrderableSupplierMinFields = {
  __typename?: 'orderableSupplierMinFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderableId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_orderablesupplier" */
export type OrderableSupplierMutationResponse = {
  __typename?: 'orderableSupplierMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderableSupplier>;
};

/** input type for inserting object relation for remote table "server_orderablesupplier" */
export type OrderableSupplierObjRelInsertInput = {
  data: OrderableSupplierInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrderableSupplierOnConflict>;
};

/** on_conflict condition type for table "server_orderablesupplier" */
export type OrderableSupplierOnConflict = {
  constraint: OrderableSupplierConstraint;
  update_columns?: Array<OrderableSupplierUpdateColumn>;
  where?: InputMaybe<OrderableSupplierBoolExp>;
};

/** Ordering options when selecting data from "server_orderablesupplier". */
export type OrderableSupplierOrderBy = {
  acceptedPayersAggregate?: InputMaybe<OrderableSupplierPayerConnectionsAggregateOrderBy>;
  agency?: InputMaybe<AgencyOrderBy>;
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderable?: InputMaybe<OrderableOrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnections = {
  __typename?: 'orderableSupplierPayerConnections';
  id: Scalars['Int'];
  orderablesupplierId: Scalars['Int'];
  /** An object relationship */
  payer: Payer;
  payerId: Scalars['Int'];
  /** An object relationship */
  supplier: OrderableSupplier;
};

/** aggregated selection of "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnectionsAggregate = {
  __typename?: 'orderableSupplierPayerConnectionsAggregate';
  aggregate?: Maybe<OrderableSupplierPayerConnectionsAggregateFields>;
  nodes: Array<OrderableSupplierPayerConnections>;
};

/** aggregate fields of "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnectionsAggregateFields = {
  __typename?: 'orderableSupplierPayerConnectionsAggregateFields';
  avg?: Maybe<OrderableSupplierPayerConnectionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderableSupplierPayerConnectionsMaxFields>;
  min?: Maybe<OrderableSupplierPayerConnectionsMinFields>;
  stddev?: Maybe<OrderableSupplierPayerConnectionsStddevFields>;
  stddevPop?: Maybe<OrderableSupplierPayerConnectionsStddev_PopFields>;
  stddevSamp?: Maybe<OrderableSupplierPayerConnectionsStddev_SampFields>;
  sum?: Maybe<OrderableSupplierPayerConnectionsSumFields>;
  varPop?: Maybe<OrderableSupplierPayerConnectionsVar_PopFields>;
  varSamp?: Maybe<OrderableSupplierPayerConnectionsVar_SampFields>;
  variance?: Maybe<OrderableSupplierPayerConnectionsVarianceFields>;
};


/** aggregate fields of "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnectionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderableSupplierPayerConnectionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnectionsAggregateOrderBy = {
  avg?: InputMaybe<OrderableSupplierPayerConnections_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrderableSupplierPayerConnections_Max_Order_By>;
  min?: InputMaybe<OrderableSupplierPayerConnections_Min_Order_By>;
  stddev?: InputMaybe<OrderableSupplierPayerConnections_Stddev_Order_By>;
  stddev_pop?: InputMaybe<OrderableSupplierPayerConnections_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<OrderableSupplierPayerConnections_Stddev_Samp_Order_By>;
  sum?: InputMaybe<OrderableSupplierPayerConnections_Sum_Order_By>;
  var_pop?: InputMaybe<OrderableSupplierPayerConnections_Var_Pop_Order_By>;
  var_samp?: InputMaybe<OrderableSupplierPayerConnections_Var_Samp_Order_By>;
  variance?: InputMaybe<OrderableSupplierPayerConnections_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnectionsArrRelInsertInput = {
  data: Array<OrderableSupplierPayerConnectionsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrderableSupplierPayerConnectionsOnConflict>;
};

/** aggregate avg on columns */
export type OrderableSupplierPayerConnectionsAvgFields = {
  __typename?: 'orderableSupplierPayerConnectionsAvgFields';
  id?: Maybe<Scalars['Float']>;
  orderablesupplierId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderablesupplier_payers". All fields are combined with a logical 'AND'. */
export type OrderableSupplierPayerConnectionsBoolExp = {
  _and?: InputMaybe<Array<OrderableSupplierPayerConnectionsBoolExp>>;
  _not?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
  _or?: InputMaybe<Array<OrderableSupplierPayerConnectionsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  orderablesupplierId?: InputMaybe<IntComparisonExp>;
  payer?: InputMaybe<PayerBoolExp>;
  payerId?: InputMaybe<IntComparisonExp>;
  supplier?: InputMaybe<OrderableSupplierBoolExp>;
};

/** unique or primary key constraints on table "server_orderablesupplier_payers" */
export enum OrderableSupplierPayerConnectionsConstraint {
  /** unique or primary key constraint on columns "payer_id", "orderablesupplier_id" */
  ServerOrderablesupplierOrderablesupplierIdPayF820b448Uniq = 'server_orderablesupplier_orderablesupplier_id_pay_f820b448_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerOrderablesupplierPayersPkey = 'server_orderablesupplier_payers_pkey'
}

/** input type for incrementing numeric columns in table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnectionsIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderablesupplierId?: InputMaybe<Scalars['Int']>;
  payerId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnectionsInsertInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderablesupplierId?: InputMaybe<Scalars['Int']>;
  payer?: InputMaybe<PayerObjRelInsertInput>;
  payerId?: InputMaybe<Scalars['Int']>;
  supplier?: InputMaybe<OrderableSupplierObjRelInsertInput>;
};

/** aggregate max on columns */
export type OrderableSupplierPayerConnectionsMaxFields = {
  __typename?: 'orderableSupplierPayerConnectionsMaxFields';
  id?: Maybe<Scalars['Int']>;
  orderablesupplierId?: Maybe<Scalars['Int']>;
  payerId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type OrderableSupplierPayerConnectionsMinFields = {
  __typename?: 'orderableSupplierPayerConnectionsMinFields';
  id?: Maybe<Scalars['Int']>;
  orderablesupplierId?: Maybe<Scalars['Int']>;
  payerId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnectionsMutationResponse = {
  __typename?: 'orderableSupplierPayerConnectionsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderableSupplierPayerConnections>;
};

/** on_conflict condition type for table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnectionsOnConflict = {
  constraint: OrderableSupplierPayerConnectionsConstraint;
  update_columns?: Array<OrderableSupplierPayerConnectionsUpdateColumn>;
  where?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
};

/** Ordering options when selecting data from "server_orderablesupplier_payers". */
export type OrderableSupplierPayerConnectionsOrderBy = {
  id?: InputMaybe<OrderBy>;
  orderablesupplierId?: InputMaybe<OrderBy>;
  payer?: InputMaybe<PayerOrderBy>;
  payerId?: InputMaybe<OrderBy>;
  supplier?: InputMaybe<OrderableSupplierOrderBy>;
};

/** primary key columns input for table: server_orderablesupplier_payers */
export type OrderableSupplierPayerConnectionsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_orderablesupplier_payers" */
export enum OrderableSupplierPayerConnectionsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrderablesupplierId = 'orderablesupplierId',
  /** column name */
  PayerId = 'payerId'
}

/** input type for updating data in table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnectionsSetInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderablesupplierId?: InputMaybe<Scalars['Int']>;
  payerId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type OrderableSupplierPayerConnectionsStddevFields = {
  __typename?: 'orderableSupplierPayerConnectionsStddevFields';
  id?: Maybe<Scalars['Float']>;
  orderablesupplierId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrderableSupplierPayerConnectionsStddev_PopFields = {
  __typename?: 'orderableSupplierPayerConnectionsStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  orderablesupplierId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrderableSupplierPayerConnectionsStddev_SampFields = {
  __typename?: 'orderableSupplierPayerConnectionsStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderablesupplierId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrderableSupplierPayerConnectionsSumFields = {
  __typename?: 'orderableSupplierPayerConnectionsSumFields';
  id?: Maybe<Scalars['Int']>;
  orderablesupplierId?: Maybe<Scalars['Int']>;
  payerId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_orderablesupplier_payers" */
export enum OrderableSupplierPayerConnectionsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrderablesupplierId = 'orderablesupplierId',
  /** column name */
  PayerId = 'payerId'
}

export type OrderableSupplierPayerConnectionsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrderableSupplierPayerConnectionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderableSupplierPayerConnectionsSetInput>;
  where: OrderableSupplierPayerConnectionsBoolExp;
};

/** aggregate var_pop on columns */
export type OrderableSupplierPayerConnectionsVar_PopFields = {
  __typename?: 'orderableSupplierPayerConnectionsVar_popFields';
  id?: Maybe<Scalars['Float']>;
  orderablesupplierId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrderableSupplierPayerConnectionsVar_SampFields = {
  __typename?: 'orderableSupplierPayerConnectionsVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  orderablesupplierId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderableSupplierPayerConnectionsVarianceFields = {
  __typename?: 'orderableSupplierPayerConnectionsVarianceFields';
  id?: Maybe<Scalars['Float']>;
  orderablesupplierId?: Maybe<Scalars['Float']>;
  payerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnections_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderablesupplierId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnections_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderablesupplierId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnections_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderablesupplierId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnections_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderablesupplierId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnections_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderablesupplierId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnections_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderablesupplierId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "orderableSupplierPayerConnections" */
export type OrderableSupplierPayerConnections_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrderableSupplierPayerConnections_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrderableSupplierPayerConnections_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderablesupplierId?: InputMaybe<Scalars['Int']>;
  payerId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnections_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderablesupplierId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnections_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderablesupplierId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnections_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderablesupplierId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_orderablesupplier_payers" */
export type OrderableSupplierPayerConnections_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  orderablesupplierId?: InputMaybe<OrderBy>;
  payerId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_orderablesupplier */
export type OrderableSupplierPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_orderablesupplier" */
export enum OrderableSupplierSelectColumn {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  Id = 'id',
  /** column name */
  OrderableId = 'orderableId'
}

/** input type for updating data in table "server_orderablesupplier" */
export type OrderableSupplierSetInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderableId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type OrderableSupplierStddevFields = {
  __typename?: 'orderableSupplierStddevFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrderableSupplierStddev_PopFields = {
  __typename?: 'orderableSupplierStddev_popFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrderableSupplierStddev_SampFields = {
  __typename?: 'orderableSupplierStddev_sampFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrderableSupplierSumFields = {
  __typename?: 'orderableSupplierSumFields';
  agencyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderableId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_orderablesupplier" */
export enum OrderableSupplierUpdateColumn {
  /** column name */
  AgencyId = 'agencyId',
  /** column name */
  Id = 'id',
  /** column name */
  OrderableId = 'orderableId'
}

export type OrderableSupplierUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrderableSupplierIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderableSupplierSetInput>;
  where: OrderableSupplierBoolExp;
};

/** aggregate var_pop on columns */
export type OrderableSupplierVar_PopFields = {
  __typename?: 'orderableSupplierVar_popFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrderableSupplierVar_SampFields = {
  __typename?: 'orderableSupplierVar_sampFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderableSupplierVarianceFields = {
  __typename?: 'orderableSupplierVarianceFields';
  agencyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderableId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_orderablesupplier" */
export type OrderableSupplier_Avg_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_orderablesupplier" */
export type OrderableSupplier_Max_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_orderablesupplier" */
export type OrderableSupplier_Min_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_orderablesupplier" */
export type OrderableSupplier_Stddev_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_orderablesupplier" */
export type OrderableSupplier_Stddev_Pop_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_orderablesupplier" */
export type OrderableSupplier_Stddev_Samp_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "orderableSupplier" */
export type OrderableSupplier_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrderableSupplier_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrderableSupplier_StreamCursorValueInput = {
  agencyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderableId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_orderablesupplier" */
export type OrderableSupplier_Sum_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_orderablesupplier" */
export type OrderableSupplier_Var_Pop_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_orderablesupplier" */
export type OrderableSupplier_Var_Samp_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_orderablesupplier" */
export type OrderableSupplier_Variance_Order_By = {
  agencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableId?: InputMaybe<OrderBy>;
};

/** update columns of table "server_orderable" */
export enum OrderableUpdateColumn {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Title = 'title',
  /** column name */
  ValidIcd10 = 'validIcd10'
}

export type OrderableUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrderableIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderableSetInput>;
  where: OrderableBoolExp;
};

/** aggregate var_pop on columns */
export type OrderableVar_PopFields = {
  __typename?: 'orderableVar_popFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrderableVar_SampFields = {
  __typename?: 'orderableVar_sampFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderableVarianceFields = {
  __typename?: 'orderableVarianceFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_orderable" */
export type Orderable_Avg_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_orderable" */
export type Orderable_Max_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_orderable" */
export type Orderable_Min_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_orderable" */
export type Orderable_Stddev_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_orderable" */
export type Orderable_Stddev_Pop_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_orderable" */
export type Orderable_Stddev_Samp_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "orderable" */
export type Orderable_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Orderable_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Orderable_StreamCursorValueInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  validIcd10?: InputMaybe<Scalars['_varchar']>;
};

/** order by sum() on columns of table "server_orderable" */
export type Orderable_Sum_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_orderable" */
export type Orderable_Var_Pop_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_orderable" */
export type Orderable_Var_Samp_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_orderable" */
export type Orderable_Variance_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_orderaction" */
export type Orderaction = {
  __typename?: 'orderaction';
  action: Scalars['smallint'];
  /** An object relationship */
  baseorder: Baseorder;
  datetimeEntered: Scalars['timestamptz'];
  /** An object relationship */
  enteringUser: User;
  enteringUserId: Scalars['Int'];
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  orderId: Scalars['Int'];
  /** An object relationship */
  responsibleProvider?: Maybe<Provider>;
  responsibleProviderId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "server_orderaction" */
export type OrderactionAggregate = {
  __typename?: 'orderactionAggregate';
  aggregate?: Maybe<OrderactionAggregateFields>;
  nodes: Array<Orderaction>;
};

/** aggregate fields of "server_orderaction" */
export type OrderactionAggregateFields = {
  __typename?: 'orderactionAggregateFields';
  avg?: Maybe<OrderactionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderactionMaxFields>;
  min?: Maybe<OrderactionMinFields>;
  stddev?: Maybe<OrderactionStddevFields>;
  stddevPop?: Maybe<OrderactionStddev_PopFields>;
  stddevSamp?: Maybe<OrderactionStddev_SampFields>;
  sum?: Maybe<OrderactionSumFields>;
  varPop?: Maybe<OrderactionVar_PopFields>;
  varSamp?: Maybe<OrderactionVar_SampFields>;
  variance?: Maybe<OrderactionVarianceFields>;
};


/** aggregate fields of "server_orderaction" */
export type OrderactionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderactionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_orderaction" */
export type OrderactionAggregateOrderBy = {
  avg?: InputMaybe<Orderaction_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Orderaction_Max_Order_By>;
  min?: InputMaybe<Orderaction_Min_Order_By>;
  stddev?: InputMaybe<Orderaction_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Orderaction_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Orderaction_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Orderaction_Sum_Order_By>;
  var_pop?: InputMaybe<Orderaction_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Orderaction_Var_Samp_Order_By>;
  variance?: InputMaybe<Orderaction_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_orderaction" */
export type OrderactionArrRelInsertInput = {
  data: Array<OrderactionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrderactionOnConflict>;
};

/** aggregate avg on columns */
export type OrderactionAvgFields = {
  __typename?: 'orderactionAvgFields';
  action?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  responsibleProviderId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_orderaction". All fields are combined with a logical 'AND'. */
export type OrderactionBoolExp = {
  _and?: InputMaybe<Array<OrderactionBoolExp>>;
  _not?: InputMaybe<OrderactionBoolExp>;
  _or?: InputMaybe<Array<OrderactionBoolExp>>;
  action?: InputMaybe<SmallintComparisonExp>;
  baseorder?: InputMaybe<BaseorderBoolExp>;
  datetimeEntered?: InputMaybe<TimestamptzComparisonExp>;
  enteringUser?: InputMaybe<UserBoolExp>;
  enteringUserId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  orderId?: InputMaybe<IntComparisonExp>;
  responsibleProvider?: InputMaybe<ProviderBoolExp>;
  responsibleProviderId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_orderaction" */
export enum OrderactionConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerOrderactionPkey = 'server_orderaction_pkey'
}

/** input type for incrementing numeric columns in table "server_orderaction" */
export type OrderactionIncInput = {
  action?: InputMaybe<Scalars['smallint']>;
  enteringUserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['Int']>;
  responsibleProviderId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_orderaction" */
export type OrderactionInsertInput = {
  action?: InputMaybe<Scalars['smallint']>;
  baseorder?: InputMaybe<BaseorderObjRelInsertInput>;
  datetimeEntered?: InputMaybe<Scalars['timestamptz']>;
  enteringUser?: InputMaybe<UserObjRelInsertInput>;
  enteringUserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['Int']>;
  responsibleProvider?: InputMaybe<ProviderObjRelInsertInput>;
  responsibleProviderId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type OrderactionMaxFields = {
  __typename?: 'orderactionMaxFields';
  action?: Maybe<Scalars['smallint']>;
  datetimeEntered?: Maybe<Scalars['timestamptz']>;
  enteringUserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Int']>;
  responsibleProviderId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type OrderactionMinFields = {
  __typename?: 'orderactionMinFields';
  action?: Maybe<Scalars['smallint']>;
  datetimeEntered?: Maybe<Scalars['timestamptz']>;
  enteringUserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Int']>;
  responsibleProviderId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_orderaction" */
export type OrderactionMutationResponse = {
  __typename?: 'orderactionMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Orderaction>;
};

/** on_conflict condition type for table "server_orderaction" */
export type OrderactionOnConflict = {
  constraint: OrderactionConstraint;
  update_columns?: Array<OrderactionUpdateColumn>;
  where?: InputMaybe<OrderactionBoolExp>;
};

/** Ordering options when selecting data from "server_orderaction". */
export type OrderactionOrderBy = {
  action?: InputMaybe<OrderBy>;
  baseorder?: InputMaybe<BaseorderOrderBy>;
  datetimeEntered?: InputMaybe<OrderBy>;
  enteringUser?: InputMaybe<UserOrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  responsibleProvider?: InputMaybe<ProviderOrderBy>;
  responsibleProviderId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_orderaction */
export type OrderactionPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_orderaction" */
export enum OrderactionSelectColumn {
  /** column name */
  Action = 'action',
  /** column name */
  DatetimeEntered = 'datetimeEntered',
  /** column name */
  EnteringUserId = 'enteringUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  ResponsibleProviderId = 'responsibleProviderId'
}

/** input type for updating data in table "server_orderaction" */
export type OrderactionSetInput = {
  action?: InputMaybe<Scalars['smallint']>;
  datetimeEntered?: InputMaybe<Scalars['timestamptz']>;
  enteringUserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['Int']>;
  responsibleProviderId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type OrderactionStddevFields = {
  __typename?: 'orderactionStddevFields';
  action?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  responsibleProviderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrderactionStddev_PopFields = {
  __typename?: 'orderactionStddev_popFields';
  action?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  responsibleProviderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrderactionStddev_SampFields = {
  __typename?: 'orderactionStddev_sampFields';
  action?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  responsibleProviderId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrderactionSumFields = {
  __typename?: 'orderactionSumFields';
  action?: Maybe<Scalars['smallint']>;
  enteringUserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  responsibleProviderId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_orderaction" */
export enum OrderactionUpdateColumn {
  /** column name */
  Action = 'action',
  /** column name */
  DatetimeEntered = 'datetimeEntered',
  /** column name */
  EnteringUserId = 'enteringUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  ResponsibleProviderId = 'responsibleProviderId'
}

export type OrderactionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrderactionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderactionSetInput>;
  where: OrderactionBoolExp;
};

/** aggregate var_pop on columns */
export type OrderactionVar_PopFields = {
  __typename?: 'orderactionVar_popFields';
  action?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  responsibleProviderId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrderactionVar_SampFields = {
  __typename?: 'orderactionVar_sampFields';
  action?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  responsibleProviderId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderactionVarianceFields = {
  __typename?: 'orderactionVarianceFields';
  action?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Float']>;
  responsibleProviderId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_orderaction" */
export type Orderaction_Avg_Order_By = {
  action?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  responsibleProviderId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_orderaction" */
export type Orderaction_Max_Order_By = {
  action?: InputMaybe<OrderBy>;
  datetimeEntered?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  responsibleProviderId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_orderaction" */
export type Orderaction_Min_Order_By = {
  action?: InputMaybe<OrderBy>;
  datetimeEntered?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  responsibleProviderId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_orderaction" */
export type Orderaction_Stddev_Order_By = {
  action?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  responsibleProviderId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_orderaction" */
export type Orderaction_Stddev_Pop_Order_By = {
  action?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  responsibleProviderId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_orderaction" */
export type Orderaction_Stddev_Samp_Order_By = {
  action?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  responsibleProviderId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "orderaction" */
export type Orderaction_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Orderaction_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Orderaction_StreamCursorValueInput = {
  action?: InputMaybe<Scalars['smallint']>;
  datetimeEntered?: InputMaybe<Scalars['timestamptz']>;
  enteringUserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['Int']>;
  responsibleProviderId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_orderaction" */
export type Orderaction_Sum_Order_By = {
  action?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  responsibleProviderId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_orderaction" */
export type Orderaction_Var_Pop_Order_By = {
  action?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  responsibleProviderId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_orderaction" */
export type Orderaction_Var_Samp_Order_By = {
  action?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  responsibleProviderId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_orderaction" */
export type Orderaction_Variance_Order_By = {
  action?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  responsibleProviderId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_patient" */
export type Patient = {
  __typename?: 'patient';
  address?: Maybe<Scalars['String']>;
  admittedStatus: Scalars['smallint'];
  /** An array relationship */
  agencyconnections: Array<Agencyconnection>;
  /** An aggregate relationship */
  agencyconnectionsAggregate: AgencyconnectionAggregate;
  /** An array relationship */
  allergies: Array<Allergy>;
  /** An aggregate relationship */
  allergiesAggregate: AllergyAggregate;
  /** An array relationship */
  appointments: Array<Appointment>;
  /** An aggregate relationship */
  appointmentsAggregate: AppointmentAggregate;
  /** An array relationship */
  baseorders: Array<Baseorder>;
  /** An aggregate relationship */
  baseordersAggregate: BaseorderAggregate;
  /** An array relationship */
  caretimes: Array<Caretime>;
  /** An aggregate relationship */
  caretimesAggregate: CaretimeAggregate;
  city?: Maybe<Scalars['String']>;
  /** An array relationship */
  claims: Array<Claim>;
  /** An aggregate relationship */
  claimsAggregate: ClaimAggregate;
  /** An array relationship */
  contactPersons: Array<PatientContactPerson>;
  /** An aggregate relationship */
  contactPersonsAggregate: PatientContactPersonAggregate;
  dateOfBirth?: Maybe<Scalars['date']>;
  /** An array relationship */
  documents: Array<Document>;
  /** An aggregate relationship */
  documentsAggregate: DocumentAggregate;
  /** An array relationship */
  documentsPendingReviewGuesses: Array<DocumentPendingReview>;
  /** An aggregate relationship */
  documentsPendingReviewGuessesAggregate: DocumentPendingReviewAggregate;
  email?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactPhone?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  externalJson?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  externalSignedOrders: Array<ExternalSignedOrder>;
  /** An aggregate relationship */
  externalSignedOrdersAggregate: ExternalSignedOrderAggregate;
  /** An array relationship */
  externalStaffClaimingMyPatient: Array<ExternalStaffMyPatients>;
  /** An aggregate relationship */
  externalStaffClaimingMyPatientAggregate: ExternalStaffMyPatientsAggregate;
  /** An array relationship */
  faxes: Array<Fax>;
  /** An aggregate relationship */
  faxesAggregate: FaxAggregate;
  firstName: Scalars['String'];
  followupMax?: Maybe<Scalars['date']>;
  followupMin?: Maybe<Scalars['date']>;
  gender: Scalars['smallint'];
  /** An object relationship */
  homeAddress?: Maybe<Address>;
  homeAddressId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An array relationship */
  insurancePlans: Array<Insuranceplan>;
  /** An aggregate relationship */
  insurancePlansAggregate: InsuranceplanAggregate;
  /** An array relationship */
  intakes: Array<PatientIntake>;
  /** An aggregate relationship */
  intakesAggregate: PatientIntakeAggregate;
  lastName: Scalars['String'];
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  medications: Array<Medication>;
  /** An aggregate relationship */
  medicationsAggregate: MedicationAggregate;
  middleName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  orders: Array<LegacyOrder>;
  /** An aggregate relationship */
  ordersAggregate: LegacyOrderAggregate;
  /** An array relationship */
  patientAliases: Array<PatientAlias>;
  /** An aggregate relationship */
  patientAliasesAggregate: PatientAliasAggregate;
  /** An array relationship */
  phones: Array<PatientPhone>;
  /** An aggregate relationship */
  phonesAggregate: PatientPhoneAggregate;
  practiceGroupId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  practicegroup?: Maybe<Practicegroup>;
  primaryPhone?: Maybe<Scalars['String']>;
  /** An array relationship */
  problems: Array<Problem>;
  /** An aggregate relationship */
  problemsAggregate: ProblemAggregate;
  /** An array relationship */
  referrals: Array<Referral>;
  /** An aggregate relationship */
  referralsAggregate: ReferralAggregate;
  /** An array relationship */
  renderedAssessments: Array<RenderedAssessment>;
  /** An aggregate relationship */
  renderedAssessmentsAggregate: RenderedAssessmentAggregate;
  secondaryPhone?: Maybe<Scalars['String']>;
  /** An array relationship */
  signedConsents: Array<SignedConsent>;
  /** An aggregate relationship */
  signedConsentsAggregate: SignedConsentAggregate;
  state?: Maybe<Scalars['String']>;
  /** An array relationship */
  statusActions: Array<PatientStatusAction>;
  /** An aggregate relationship */
  statusActionsAggregate: PatientStatusActionAggregate;
  /** An array relationship */
  telemedAppointmentRequests: Array<TelemedAppointmentRequest>;
  /** An aggregate relationship */
  telemedAppointmentRequestsAggregate: TelemedAppointmentRequestAggregate;
  /** An array relationship */
  ticketthreads: Array<TicketThread>;
  /** An aggregate relationship */
  ticketthreadsAggregate: TicketThreadAggregate;
  /** An array relationship */
  treatmentplans: Array<Treatmentplan>;
  /** An aggregate relationship */
  treatmentplansAggregate: TreatmentplanAggregate;
  /** A computed field, executes function "user_can_access_patient" */
  user_can_access?: Maybe<Scalars['Boolean']>;
  zipCode?: Maybe<Scalars['String']>;
};


/** columns and relationships of "server_patient" */
export type PatientAgencyconnectionsArgs = {
  distinctOn?: InputMaybe<Array<AgencyconnectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyconnectionOrderBy>>;
  where?: InputMaybe<AgencyconnectionBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientAgencyconnectionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyconnectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyconnectionOrderBy>>;
  where?: InputMaybe<AgencyconnectionBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientAllergiesArgs = {
  distinctOn?: InputMaybe<Array<AllergySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AllergyOrderBy>>;
  where?: InputMaybe<AllergyBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientAllergiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AllergySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AllergyOrderBy>>;
  where?: InputMaybe<AllergyBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientBaseordersArgs = {
  distinctOn?: InputMaybe<Array<BaseorderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseorderOrderBy>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientBaseordersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BaseorderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseorderOrderBy>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientCaretimesArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientCaretimesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientClaimsArgs = {
  distinctOn?: InputMaybe<Array<ClaimSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClaimOrderBy>>;
  where?: InputMaybe<ClaimBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientClaimsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClaimSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClaimOrderBy>>;
  where?: InputMaybe<ClaimBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientContactPersonsArgs = {
  distinctOn?: InputMaybe<Array<PatientContactPersonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientContactPersonOrderBy>>;
  where?: InputMaybe<PatientContactPersonBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientContactPersonsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientContactPersonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientContactPersonOrderBy>>;
  where?: InputMaybe<PatientContactPersonBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientDocumentsArgs = {
  distinctOn?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientDocumentsPendingReviewGuessesArgs = {
  distinctOn?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentPendingReviewOrderBy>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientDocumentsPendingReviewGuessesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentPendingReviewOrderBy>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientExternalJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "server_patient" */
export type PatientExternalSignedOrdersArgs = {
  distinctOn?: InputMaybe<Array<ExternalSignedOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalSignedOrderOrderBy>>;
  where?: InputMaybe<ExternalSignedOrderBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientExternalSignedOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExternalSignedOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalSignedOrderOrderBy>>;
  where?: InputMaybe<ExternalSignedOrderBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientExternalStaffClaimingMyPatientArgs = {
  distinctOn?: InputMaybe<Array<ExternalStaffMyPatientsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalStaffMyPatientsOrderBy>>;
  where?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientExternalStaffClaimingMyPatientAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExternalStaffMyPatientsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalStaffMyPatientsOrderBy>>;
  where?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientFaxesArgs = {
  distinctOn?: InputMaybe<Array<FaxSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxOrderBy>>;
  where?: InputMaybe<FaxBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientFaxesAggregateArgs = {
  distinctOn?: InputMaybe<Array<FaxSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxOrderBy>>;
  where?: InputMaybe<FaxBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientInsurancePlansArgs = {
  distinctOn?: InputMaybe<Array<InsuranceplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceplanOrderBy>>;
  where?: InputMaybe<InsuranceplanBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientInsurancePlansAggregateArgs = {
  distinctOn?: InputMaybe<Array<InsuranceplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceplanOrderBy>>;
  where?: InputMaybe<InsuranceplanBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientIntakesArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeOrderBy>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientIntakesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeOrderBy>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientMedicationsArgs = {
  distinctOn?: InputMaybe<Array<MedicationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationOrderBy>>;
  where?: InputMaybe<MedicationBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientMedicationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationOrderBy>>;
  where?: InputMaybe<MedicationBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientOrdersArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientPatientAliasesArgs = {
  distinctOn?: InputMaybe<Array<PatientAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientAliasOrderBy>>;
  where?: InputMaybe<PatientAliasBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientPatientAliasesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientAliasOrderBy>>;
  where?: InputMaybe<PatientAliasBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientPhonesArgs = {
  distinctOn?: InputMaybe<Array<PatientPhoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientPhoneOrderBy>>;
  where?: InputMaybe<PatientPhoneBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientPhonesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientPhoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientPhoneOrderBy>>;
  where?: InputMaybe<PatientPhoneBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientProblemsArgs = {
  distinctOn?: InputMaybe<Array<ProblemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemOrderBy>>;
  where?: InputMaybe<ProblemBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientProblemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProblemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemOrderBy>>;
  where?: InputMaybe<ProblemBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientReferralsArgs = {
  distinctOn?: InputMaybe<Array<ReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralOrderBy>>;
  where?: InputMaybe<ReferralBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientReferralsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralOrderBy>>;
  where?: InputMaybe<ReferralBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientRenderedAssessmentsArgs = {
  distinctOn?: InputMaybe<Array<RenderedAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RenderedAssessmentOrderBy>>;
  where?: InputMaybe<RenderedAssessmentBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientRenderedAssessmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<RenderedAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RenderedAssessmentOrderBy>>;
  where?: InputMaybe<RenderedAssessmentBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientSignedConsentsArgs = {
  distinctOn?: InputMaybe<Array<SignedConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignedConsentOrderBy>>;
  where?: InputMaybe<SignedConsentBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientSignedConsentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SignedConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignedConsentOrderBy>>;
  where?: InputMaybe<SignedConsentBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientStatusActionsArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusActionOrderBy>>;
  where?: InputMaybe<PatientStatusActionBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientStatusActionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusActionOrderBy>>;
  where?: InputMaybe<PatientStatusActionBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientTelemedAppointmentRequestsArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientTelemedAppointmentRequestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientTicketthreadsArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientTicketthreadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientTreatmentplansArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanOrderBy>>;
  where?: InputMaybe<TreatmentplanBoolExp>;
};


/** columns and relationships of "server_patient" */
export type PatientTreatmentplansAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanOrderBy>>;
  where?: InputMaybe<TreatmentplanBoolExp>;
};

/** aggregated selection of "server_patient" */
export type PatientAggregate = {
  __typename?: 'patientAggregate';
  aggregate?: Maybe<PatientAggregateFields>;
  nodes: Array<Patient>;
};

/** aggregate fields of "server_patient" */
export type PatientAggregateFields = {
  __typename?: 'patientAggregateFields';
  avg?: Maybe<PatientAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PatientMaxFields>;
  min?: Maybe<PatientMinFields>;
  stddev?: Maybe<PatientStddevFields>;
  stddevPop?: Maybe<PatientStddev_PopFields>;
  stddevSamp?: Maybe<PatientStddev_SampFields>;
  sum?: Maybe<PatientSumFields>;
  varPop?: Maybe<PatientVar_PopFields>;
  varSamp?: Maybe<PatientVar_SampFields>;
  variance?: Maybe<PatientVarianceFields>;
};


/** aggregate fields of "server_patient" */
export type PatientAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PatientSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_patient" */
export type PatientAggregateOrderBy = {
  avg?: InputMaybe<Patient_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Patient_Max_Order_By>;
  min?: InputMaybe<Patient_Min_Order_By>;
  stddev?: InputMaybe<Patient_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Patient_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Patient_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Patient_Sum_Order_By>;
  var_pop?: InputMaybe<Patient_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Patient_Var_Samp_Order_By>;
  variance?: InputMaybe<Patient_Variance_Order_By>;
};

/** columns and relationships of "server_patientalias" */
export type PatientAlias = {
  __typename?: 'patientAlias';
  alias: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
};

/** aggregated selection of "server_patientalias" */
export type PatientAliasAggregate = {
  __typename?: 'patientAliasAggregate';
  aggregate?: Maybe<PatientAliasAggregateFields>;
  nodes: Array<PatientAlias>;
};

/** aggregate fields of "server_patientalias" */
export type PatientAliasAggregateFields = {
  __typename?: 'patientAliasAggregateFields';
  avg?: Maybe<PatientAliasAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PatientAliasMaxFields>;
  min?: Maybe<PatientAliasMinFields>;
  stddev?: Maybe<PatientAliasStddevFields>;
  stddevPop?: Maybe<PatientAliasStddev_PopFields>;
  stddevSamp?: Maybe<PatientAliasStddev_SampFields>;
  sum?: Maybe<PatientAliasSumFields>;
  varPop?: Maybe<PatientAliasVar_PopFields>;
  varSamp?: Maybe<PatientAliasVar_SampFields>;
  variance?: Maybe<PatientAliasVarianceFields>;
};


/** aggregate fields of "server_patientalias" */
export type PatientAliasAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PatientAliasSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_patientalias" */
export type PatientAliasAggregateOrderBy = {
  avg?: InputMaybe<PatientAlias_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PatientAlias_Max_Order_By>;
  min?: InputMaybe<PatientAlias_Min_Order_By>;
  stddev?: InputMaybe<PatientAlias_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PatientAlias_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PatientAlias_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PatientAlias_Sum_Order_By>;
  var_pop?: InputMaybe<PatientAlias_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PatientAlias_Var_Samp_Order_By>;
  variance?: InputMaybe<PatientAlias_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_patientalias" */
export type PatientAliasArrRelInsertInput = {
  data: Array<PatientAliasInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PatientAliasOnConflict>;
};

/** aggregate avg on columns */
export type PatientAliasAvgFields = {
  __typename?: 'patientAliasAvgFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_patientalias". All fields are combined with a logical 'AND'. */
export type PatientAliasBoolExp = {
  _and?: InputMaybe<Array<PatientAliasBoolExp>>;
  _not?: InputMaybe<PatientAliasBoolExp>;
  _or?: InputMaybe<Array<PatientAliasBoolExp>>;
  alias?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_patientalias" */
export enum PatientAliasConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerPatientaliasPkey = 'server_patientalias_pkey'
}

/** input type for incrementing numeric columns in table "server_patientalias" */
export type PatientAliasIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_patientalias" */
export type PatientAliasInsertInput = {
  alias?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type PatientAliasMaxFields = {
  __typename?: 'patientAliasMaxFields';
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type PatientAliasMinFields = {
  __typename?: 'patientAliasMinFields';
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_patientalias" */
export type PatientAliasMutationResponse = {
  __typename?: 'patientAliasMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientAlias>;
};

/** on_conflict condition type for table "server_patientalias" */
export type PatientAliasOnConflict = {
  constraint: PatientAliasConstraint;
  update_columns?: Array<PatientAliasUpdateColumn>;
  where?: InputMaybe<PatientAliasBoolExp>;
};

/** Ordering options when selecting data from "server_patientalias". */
export type PatientAliasOrderBy = {
  alias?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_patientalias */
export type PatientAliasPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_patientalias" */
export enum PatientAliasSelectColumn {
  /** column name */
  Alias = 'alias',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId'
}

/** input type for updating data in table "server_patientalias" */
export type PatientAliasSetInput = {
  alias?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PatientAliasStddevFields = {
  __typename?: 'patientAliasStddevFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientAliasStddev_PopFields = {
  __typename?: 'patientAliasStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientAliasStddev_SampFields = {
  __typename?: 'patientAliasStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PatientAliasSumFields = {
  __typename?: 'patientAliasSumFields';
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_patientalias" */
export enum PatientAliasUpdateColumn {
  /** column name */
  Alias = 'alias',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId'
}

export type PatientAliasUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientAliasIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientAliasSetInput>;
  where: PatientAliasBoolExp;
};

/** aggregate var_pop on columns */
export type PatientAliasVar_PopFields = {
  __typename?: 'patientAliasVar_popFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientAliasVar_SampFields = {
  __typename?: 'patientAliasVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientAliasVarianceFields = {
  __typename?: 'patientAliasVarianceFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_patientalias" */
export type PatientAlias_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_patientalias" */
export type PatientAlias_Max_Order_By = {
  alias?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_patientalias" */
export type PatientAlias_Min_Order_By = {
  alias?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_patientalias" */
export type PatientAlias_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_patientalias" */
export type PatientAlias_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_patientalias" */
export type PatientAlias_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "patientAlias" */
export type PatientAlias_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PatientAlias_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientAlias_StreamCursorValueInput = {
  alias?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_patientalias" */
export type PatientAlias_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_patientalias" */
export type PatientAlias_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_patientalias" */
export type PatientAlias_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_patientalias" */
export type PatientAlias_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PatientAppendInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_patient" */
export type PatientArrRelInsertInput = {
  data: Array<PatientInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PatientOnConflict>;
};

/** aggregate avg on columns */
export type PatientAvgFields = {
  __typename?: 'patientAvgFields';
  admittedStatus?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  gender?: Maybe<Scalars['Float']>;
  homeAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_patient". All fields are combined with a logical 'AND'. */
export type PatientBoolExp = {
  _and?: InputMaybe<Array<PatientBoolExp>>;
  _not?: InputMaybe<PatientBoolExp>;
  _or?: InputMaybe<Array<PatientBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  admittedStatus?: InputMaybe<SmallintComparisonExp>;
  agencyconnections?: InputMaybe<AgencyconnectionBoolExp>;
  allergies?: InputMaybe<AllergyBoolExp>;
  appointments?: InputMaybe<AppointmentBoolExp>;
  baseorders?: InputMaybe<BaseorderBoolExp>;
  caretimes?: InputMaybe<CaretimeBoolExp>;
  city?: InputMaybe<StringComparisonExp>;
  claims?: InputMaybe<ClaimBoolExp>;
  contactPersons?: InputMaybe<PatientContactPersonBoolExp>;
  dateOfBirth?: InputMaybe<DateComparisonExp>;
  documents?: InputMaybe<DocumentBoolExp>;
  documentsPendingReviewGuesses?: InputMaybe<DocumentPendingReviewBoolExp>;
  email?: InputMaybe<StringComparisonExp>;
  emergencyContactName?: InputMaybe<StringComparisonExp>;
  emergencyContactPhone?: InputMaybe<StringComparisonExp>;
  externalId?: InputMaybe<IntComparisonExp>;
  externalJson?: InputMaybe<JsonbComparisonExp>;
  externalSignedOrders?: InputMaybe<ExternalSignedOrderBoolExp>;
  externalStaffClaimingMyPatient?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
  faxes?: InputMaybe<FaxBoolExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  followupMax?: InputMaybe<DateComparisonExp>;
  followupMin?: InputMaybe<DateComparisonExp>;
  gender?: InputMaybe<SmallintComparisonExp>;
  homeAddress?: InputMaybe<AddressBoolExp>;
  homeAddressId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  insurancePlans?: InputMaybe<InsuranceplanBoolExp>;
  intakes?: InputMaybe<PatientIntakeBoolExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  latitude?: InputMaybe<NumericComparisonExp>;
  longitude?: InputMaybe<NumericComparisonExp>;
  medications?: InputMaybe<MedicationBoolExp>;
  middleName?: InputMaybe<StringComparisonExp>;
  nickName?: InputMaybe<StringComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  orders?: InputMaybe<LegacyOrderBoolExp>;
  patientAliases?: InputMaybe<PatientAliasBoolExp>;
  phones?: InputMaybe<PatientPhoneBoolExp>;
  practiceGroupId?: InputMaybe<IntComparisonExp>;
  practicegroup?: InputMaybe<PracticegroupBoolExp>;
  primaryPhone?: InputMaybe<StringComparisonExp>;
  problems?: InputMaybe<ProblemBoolExp>;
  referrals?: InputMaybe<ReferralBoolExp>;
  renderedAssessments?: InputMaybe<RenderedAssessmentBoolExp>;
  secondaryPhone?: InputMaybe<StringComparisonExp>;
  signedConsents?: InputMaybe<SignedConsentBoolExp>;
  state?: InputMaybe<StringComparisonExp>;
  statusActions?: InputMaybe<PatientStatusActionBoolExp>;
  telemedAppointmentRequests?: InputMaybe<TelemedAppointmentRequestBoolExp>;
  ticketthreads?: InputMaybe<TicketThreadBoolExp>;
  treatmentplans?: InputMaybe<TreatmentplanBoolExp>;
  user_can_access?: InputMaybe<BooleanComparisonExp>;
  zipCode?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "server_patientcommunication" */
export type PatientCommunication = {
  __typename?: 'patientCommunication';
  /** An object relationship */
  caretime: Caretime;
  caretimeId: Scalars['Int'];
  datesigned?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  text: Scalars['String'];
  title: Scalars['String'];
};

/** aggregated selection of "server_patientcommunication" */
export type PatientCommunicationAggregate = {
  __typename?: 'patientCommunicationAggregate';
  aggregate?: Maybe<PatientCommunicationAggregateFields>;
  nodes: Array<PatientCommunication>;
};

/** aggregate fields of "server_patientcommunication" */
export type PatientCommunicationAggregateFields = {
  __typename?: 'patientCommunicationAggregateFields';
  avg?: Maybe<PatientCommunicationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PatientCommunicationMaxFields>;
  min?: Maybe<PatientCommunicationMinFields>;
  stddev?: Maybe<PatientCommunicationStddevFields>;
  stddevPop?: Maybe<PatientCommunicationStddev_PopFields>;
  stddevSamp?: Maybe<PatientCommunicationStddev_SampFields>;
  sum?: Maybe<PatientCommunicationSumFields>;
  varPop?: Maybe<PatientCommunicationVar_PopFields>;
  varSamp?: Maybe<PatientCommunicationVar_SampFields>;
  variance?: Maybe<PatientCommunicationVarianceFields>;
};


/** aggregate fields of "server_patientcommunication" */
export type PatientCommunicationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PatientCommunicationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_patientcommunication" */
export type PatientCommunicationAggregateOrderBy = {
  avg?: InputMaybe<PatientCommunication_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PatientCommunication_Max_Order_By>;
  min?: InputMaybe<PatientCommunication_Min_Order_By>;
  stddev?: InputMaybe<PatientCommunication_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PatientCommunication_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PatientCommunication_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PatientCommunication_Sum_Order_By>;
  var_pop?: InputMaybe<PatientCommunication_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PatientCommunication_Var_Samp_Order_By>;
  variance?: InputMaybe<PatientCommunication_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_patientcommunication" */
export type PatientCommunicationArrRelInsertInput = {
  data: Array<PatientCommunicationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PatientCommunicationOnConflict>;
};

/** aggregate avg on columns */
export type PatientCommunicationAvgFields = {
  __typename?: 'patientCommunicationAvgFields';
  caretimeId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_patientcommunication". All fields are combined with a logical 'AND'. */
export type PatientCommunicationBoolExp = {
  _and?: InputMaybe<Array<PatientCommunicationBoolExp>>;
  _not?: InputMaybe<PatientCommunicationBoolExp>;
  _or?: InputMaybe<Array<PatientCommunicationBoolExp>>;
  caretime?: InputMaybe<CaretimeBoolExp>;
  caretimeId?: InputMaybe<IntComparisonExp>;
  datesigned?: InputMaybe<TimestamptzComparisonExp>;
  externalId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_patientcommunication" */
export enum PatientCommunicationConstraint {
  /** unique or primary key constraint on columns "external_id" */
  ServerPatientcommunicationExternalIdKey = 'server_patientcommunication_external_id_key',
  /** unique or primary key constraint on columns "id" */
  ServerPatientcommunicationPkey = 'server_patientcommunication_pkey'
}

/** input type for incrementing numeric columns in table "server_patientcommunication" */
export type PatientCommunicationIncInput = {
  caretimeId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_patientcommunication" */
export type PatientCommunicationInsertInput = {
  caretime?: InputMaybe<CaretimeObjRelInsertInput>;
  caretimeId?: InputMaybe<Scalars['Int']>;
  datesigned?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PatientCommunicationMaxFields = {
  __typename?: 'patientCommunicationMaxFields';
  caretimeId?: Maybe<Scalars['Int']>;
  datesigned?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PatientCommunicationMinFields = {
  __typename?: 'patientCommunicationMinFields';
  caretimeId?: Maybe<Scalars['Int']>;
  datesigned?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_patientcommunication" */
export type PatientCommunicationMutationResponse = {
  __typename?: 'patientCommunicationMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientCommunication>;
};

/** on_conflict condition type for table "server_patientcommunication" */
export type PatientCommunicationOnConflict = {
  constraint: PatientCommunicationConstraint;
  update_columns?: Array<PatientCommunicationUpdateColumn>;
  where?: InputMaybe<PatientCommunicationBoolExp>;
};

/** Ordering options when selecting data from "server_patientcommunication". */
export type PatientCommunicationOrderBy = {
  caretime?: InputMaybe<CaretimeOrderBy>;
  caretimeId?: InputMaybe<OrderBy>;
  datesigned?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_patientcommunication */
export type PatientCommunicationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_patientcommunication" */
export enum PatientCommunicationSelectColumn {
  /** column name */
  CaretimeId = 'caretimeId',
  /** column name */
  Datesigned = 'datesigned',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "server_patientcommunication" */
export type PatientCommunicationSetInput = {
  caretimeId?: InputMaybe<Scalars['Int']>;
  datesigned?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type PatientCommunicationStddevFields = {
  __typename?: 'patientCommunicationStddevFields';
  caretimeId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientCommunicationStddev_PopFields = {
  __typename?: 'patientCommunicationStddev_popFields';
  caretimeId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientCommunicationStddev_SampFields = {
  __typename?: 'patientCommunicationStddev_sampFields';
  caretimeId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PatientCommunicationSumFields = {
  __typename?: 'patientCommunicationSumFields';
  caretimeId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_patientcommunication" */
export enum PatientCommunicationUpdateColumn {
  /** column name */
  CaretimeId = 'caretimeId',
  /** column name */
  Datesigned = 'datesigned',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title'
}

export type PatientCommunicationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientCommunicationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientCommunicationSetInput>;
  where: PatientCommunicationBoolExp;
};

/** aggregate var_pop on columns */
export type PatientCommunicationVar_PopFields = {
  __typename?: 'patientCommunicationVar_popFields';
  caretimeId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientCommunicationVar_SampFields = {
  __typename?: 'patientCommunicationVar_sampFields';
  caretimeId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientCommunicationVarianceFields = {
  __typename?: 'patientCommunicationVarianceFields';
  caretimeId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_patientcommunication" */
export type PatientCommunication_Avg_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_patientcommunication" */
export type PatientCommunication_Max_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  datesigned?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_patientcommunication" */
export type PatientCommunication_Min_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  datesigned?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_patientcommunication" */
export type PatientCommunication_Stddev_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_patientcommunication" */
export type PatientCommunication_Stddev_Pop_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_patientcommunication" */
export type PatientCommunication_Stddev_Samp_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "patientCommunication" */
export type PatientCommunication_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PatientCommunication_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientCommunication_StreamCursorValueInput = {
  caretimeId?: InputMaybe<Scalars['Int']>;
  datesigned?: InputMaybe<Scalars['timestamptz']>;
  externalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_patientcommunication" */
export type PatientCommunication_Sum_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_patientcommunication" */
export type PatientCommunication_Var_Pop_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_patientcommunication" */
export type PatientCommunication_Var_Samp_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_patientcommunication" */
export type PatientCommunication_Variance_Order_By = {
  caretimeId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** unique or primary key constraints on table "server_patient" */
export enum PatientConstraint {
  /** unique or primary key constraint on columns "external_id" */
  ServerPatientExternalIdKey = 'server_patient_external_id_key',
  /** unique or primary key constraint on columns "home_address_id" */
  ServerPatientHomeAddressIdKey = 'server_patient_home_address_id_key',
  /** unique or primary key constraint on columns "id" */
  ServerPatientPkey = 'server_patient_pkey'
}

/** columns and relationships of "server_patientcontactperson" */
export type PatientContactPerson = {
  __typename?: 'patientContactPerson';
  active: Scalars['Boolean'];
  emergencyContact: Scalars['Boolean'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  notes: Scalars['String'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  /** An array relationship */
  phones: Array<PatientPhone>;
  /** An aggregate relationship */
  phonesAggregate: PatientPhoneAggregate;
  rank: Scalars['smallint'];
  relationship: Scalars['String'];
  scheduling: Scalars['Boolean'];
};


/** columns and relationships of "server_patientcontactperson" */
export type PatientContactPersonPhonesArgs = {
  distinctOn?: InputMaybe<Array<PatientPhoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientPhoneOrderBy>>;
  where?: InputMaybe<PatientPhoneBoolExp>;
};


/** columns and relationships of "server_patientcontactperson" */
export type PatientContactPersonPhonesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientPhoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientPhoneOrderBy>>;
  where?: InputMaybe<PatientPhoneBoolExp>;
};

/** aggregated selection of "server_patientcontactperson" */
export type PatientContactPersonAggregate = {
  __typename?: 'patientContactPersonAggregate';
  aggregate?: Maybe<PatientContactPersonAggregateFields>;
  nodes: Array<PatientContactPerson>;
};

/** aggregate fields of "server_patientcontactperson" */
export type PatientContactPersonAggregateFields = {
  __typename?: 'patientContactPersonAggregateFields';
  avg?: Maybe<PatientContactPersonAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PatientContactPersonMaxFields>;
  min?: Maybe<PatientContactPersonMinFields>;
  stddev?: Maybe<PatientContactPersonStddevFields>;
  stddevPop?: Maybe<PatientContactPersonStddev_PopFields>;
  stddevSamp?: Maybe<PatientContactPersonStddev_SampFields>;
  sum?: Maybe<PatientContactPersonSumFields>;
  varPop?: Maybe<PatientContactPersonVar_PopFields>;
  varSamp?: Maybe<PatientContactPersonVar_SampFields>;
  variance?: Maybe<PatientContactPersonVarianceFields>;
};


/** aggregate fields of "server_patientcontactperson" */
export type PatientContactPersonAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PatientContactPersonSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_patientcontactperson" */
export type PatientContactPersonAggregateOrderBy = {
  avg?: InputMaybe<PatientContactPerson_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PatientContactPerson_Max_Order_By>;
  min?: InputMaybe<PatientContactPerson_Min_Order_By>;
  stddev?: InputMaybe<PatientContactPerson_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PatientContactPerson_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PatientContactPerson_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PatientContactPerson_Sum_Order_By>;
  var_pop?: InputMaybe<PatientContactPerson_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PatientContactPerson_Var_Samp_Order_By>;
  variance?: InputMaybe<PatientContactPerson_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_patientcontactperson" */
export type PatientContactPersonArrRelInsertInput = {
  data: Array<PatientContactPersonInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PatientContactPersonOnConflict>;
};

/** aggregate avg on columns */
export type PatientContactPersonAvgFields = {
  __typename?: 'patientContactPersonAvgFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_patientcontactperson". All fields are combined with a logical 'AND'. */
export type PatientContactPersonBoolExp = {
  _and?: InputMaybe<Array<PatientContactPersonBoolExp>>;
  _not?: InputMaybe<PatientContactPersonBoolExp>;
  _or?: InputMaybe<Array<PatientContactPersonBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  emergencyContact?: InputMaybe<BooleanComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  phones?: InputMaybe<PatientPhoneBoolExp>;
  rank?: InputMaybe<SmallintComparisonExp>;
  relationship?: InputMaybe<StringComparisonExp>;
  scheduling?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "server_patientcontactperson" */
export enum PatientContactPersonConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerPatientcontactpersonPkey = 'server_patientcontactperson_pkey'
}

/** input type for incrementing numeric columns in table "server_patientcontactperson" */
export type PatientContactPersonIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_patientcontactperson" */
export type PatientContactPersonInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  emergencyContact?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  phones?: InputMaybe<PatientPhoneArrRelInsertInput>;
  rank?: InputMaybe<Scalars['smallint']>;
  relationship?: InputMaybe<Scalars['String']>;
  scheduling?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type PatientContactPersonMaxFields = {
  __typename?: 'patientContactPersonMaxFields';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['smallint']>;
  relationship?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PatientContactPersonMinFields = {
  __typename?: 'patientContactPersonMinFields';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['smallint']>;
  relationship?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_patientcontactperson" */
export type PatientContactPersonMutationResponse = {
  __typename?: 'patientContactPersonMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientContactPerson>;
};

/** input type for inserting object relation for remote table "server_patientcontactperson" */
export type PatientContactPersonObjRelInsertInput = {
  data: PatientContactPersonInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PatientContactPersonOnConflict>;
};

/** on_conflict condition type for table "server_patientcontactperson" */
export type PatientContactPersonOnConflict = {
  constraint: PatientContactPersonConstraint;
  update_columns?: Array<PatientContactPersonUpdateColumn>;
  where?: InputMaybe<PatientContactPersonBoolExp>;
};

/** Ordering options when selecting data from "server_patientcontactperson". */
export type PatientContactPersonOrderBy = {
  active?: InputMaybe<OrderBy>;
  emergencyContact?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  phonesAggregate?: InputMaybe<PatientPhoneAggregateOrderBy>;
  rank?: InputMaybe<OrderBy>;
  relationship?: InputMaybe<OrderBy>;
  scheduling?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_patientcontactperson */
export type PatientContactPersonPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_patientcontactperson" */
export enum PatientContactPersonSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  EmergencyContact = 'emergencyContact',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Notes = 'notes',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Rank = 'rank',
  /** column name */
  Relationship = 'relationship',
  /** column name */
  Scheduling = 'scheduling'
}

/** input type for updating data in table "server_patientcontactperson" */
export type PatientContactPersonSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  emergencyContact?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['smallint']>;
  relationship?: InputMaybe<Scalars['String']>;
  scheduling?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type PatientContactPersonStddevFields = {
  __typename?: 'patientContactPersonStddevFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientContactPersonStddev_PopFields = {
  __typename?: 'patientContactPersonStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientContactPersonStddev_SampFields = {
  __typename?: 'patientContactPersonStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PatientContactPersonSumFields = {
  __typename?: 'patientContactPersonSumFields';
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_patientcontactperson" */
export enum PatientContactPersonUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  EmergencyContact = 'emergencyContact',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Notes = 'notes',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Rank = 'rank',
  /** column name */
  Relationship = 'relationship',
  /** column name */
  Scheduling = 'scheduling'
}

export type PatientContactPersonUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientContactPersonIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientContactPersonSetInput>;
  where: PatientContactPersonBoolExp;
};

/** aggregate var_pop on columns */
export type PatientContactPersonVar_PopFields = {
  __typename?: 'patientContactPersonVar_popFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientContactPersonVar_SampFields = {
  __typename?: 'patientContactPersonVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientContactPersonVarianceFields = {
  __typename?: 'patientContactPersonVarianceFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_patientcontactperson" */
export type PatientContactPerson_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_patientcontactperson" */
export type PatientContactPerson_Max_Order_By = {
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
  relationship?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_patientcontactperson" */
export type PatientContactPerson_Min_Order_By = {
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
  relationship?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_patientcontactperson" */
export type PatientContactPerson_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_patientcontactperson" */
export type PatientContactPerson_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_patientcontactperson" */
export type PatientContactPerson_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "patientContactPerson" */
export type PatientContactPerson_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PatientContactPerson_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientContactPerson_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  emergencyContact?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['smallint']>;
  relationship?: InputMaybe<Scalars['String']>;
  scheduling?: InputMaybe<Scalars['Boolean']>;
};

/** order by sum() on columns of table "server_patientcontactperson" */
export type PatientContactPerson_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_patientcontactperson" */
export type PatientContactPerson_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_patientcontactperson" */
export type PatientContactPerson_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_patientcontactperson" */
export type PatientContactPerson_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PatientDeleteAtPathInput = {
  externalJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PatientDeleteElemInput = {
  externalJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PatientDeleteKeyInput = {
  externalJson?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "server_patienteligibilitycheck" */
export type PatientEligibilityCheck = {
  __typename?: 'patientEligibilityCheck';
  datetime?: Maybe<Scalars['timestamptz']>;
  endDos: Scalars['date'];
  externalId?: Maybe<Scalars['Int']>;
  externalJson?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  insurancePlanId: Scalars['Int'];
  /** An object relationship */
  insuranceplan: Insuranceplan;
  startDos: Scalars['date'];
  status: Scalars['smallint'];
};


/** columns and relationships of "server_patienteligibilitycheck" */
export type PatientEligibilityCheckExternalJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "server_patienteligibilitycheck" */
export type PatientEligibilityCheckAggregate = {
  __typename?: 'patientEligibilityCheckAggregate';
  aggregate?: Maybe<PatientEligibilityCheckAggregateFields>;
  nodes: Array<PatientEligibilityCheck>;
};

/** aggregate fields of "server_patienteligibilitycheck" */
export type PatientEligibilityCheckAggregateFields = {
  __typename?: 'patientEligibilityCheckAggregateFields';
  avg?: Maybe<PatientEligibilityCheckAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PatientEligibilityCheckMaxFields>;
  min?: Maybe<PatientEligibilityCheckMinFields>;
  stddev?: Maybe<PatientEligibilityCheckStddevFields>;
  stddevPop?: Maybe<PatientEligibilityCheckStddev_PopFields>;
  stddevSamp?: Maybe<PatientEligibilityCheckStddev_SampFields>;
  sum?: Maybe<PatientEligibilityCheckSumFields>;
  varPop?: Maybe<PatientEligibilityCheckVar_PopFields>;
  varSamp?: Maybe<PatientEligibilityCheckVar_SampFields>;
  variance?: Maybe<PatientEligibilityCheckVarianceFields>;
};


/** aggregate fields of "server_patienteligibilitycheck" */
export type PatientEligibilityCheckAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PatientEligibilityCheckSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_patienteligibilitycheck" */
export type PatientEligibilityCheckAggregateOrderBy = {
  avg?: InputMaybe<PatientEligibilityCheck_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PatientEligibilityCheck_Max_Order_By>;
  min?: InputMaybe<PatientEligibilityCheck_Min_Order_By>;
  stddev?: InputMaybe<PatientEligibilityCheck_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PatientEligibilityCheck_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PatientEligibilityCheck_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PatientEligibilityCheck_Sum_Order_By>;
  var_pop?: InputMaybe<PatientEligibilityCheck_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PatientEligibilityCheck_Var_Samp_Order_By>;
  variance?: InputMaybe<PatientEligibilityCheck_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PatientEligibilityCheckAppendInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_patienteligibilitycheck" */
export type PatientEligibilityCheckArrRelInsertInput = {
  data: Array<PatientEligibilityCheckInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PatientEligibilityCheckOnConflict>;
};

/** aggregate avg on columns */
export type PatientEligibilityCheckAvgFields = {
  __typename?: 'patientEligibilityCheckAvgFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurancePlanId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_patienteligibilitycheck". All fields are combined with a logical 'AND'. */
export type PatientEligibilityCheckBoolExp = {
  _and?: InputMaybe<Array<PatientEligibilityCheckBoolExp>>;
  _not?: InputMaybe<PatientEligibilityCheckBoolExp>;
  _or?: InputMaybe<Array<PatientEligibilityCheckBoolExp>>;
  datetime?: InputMaybe<TimestamptzComparisonExp>;
  endDos?: InputMaybe<DateComparisonExp>;
  externalId?: InputMaybe<IntComparisonExp>;
  externalJson?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  insurancePlanId?: InputMaybe<IntComparisonExp>;
  insuranceplan?: InputMaybe<InsuranceplanBoolExp>;
  startDos?: InputMaybe<DateComparisonExp>;
  status?: InputMaybe<SmallintComparisonExp>;
};

/** unique or primary key constraints on table "server_patienteligibilitycheck" */
export enum PatientEligibilityCheckConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerPatienteligibilitycheckPkey = 'server_patienteligibilitycheck_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PatientEligibilityCheckDeleteAtPathInput = {
  externalJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PatientEligibilityCheckDeleteElemInput = {
  externalJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PatientEligibilityCheckDeleteKeyInput = {
  externalJson?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_patienteligibilitycheck" */
export type PatientEligibilityCheckIncInput = {
  externalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  insurancePlanId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_patienteligibilitycheck" */
export type PatientEligibilityCheckInsertInput = {
  datetime?: InputMaybe<Scalars['timestamptz']>;
  endDos?: InputMaybe<Scalars['date']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  insurancePlanId?: InputMaybe<Scalars['Int']>;
  insuranceplan?: InputMaybe<InsuranceplanObjRelInsertInput>;
  startDos?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type PatientEligibilityCheckMaxFields = {
  __typename?: 'patientEligibilityCheckMaxFields';
  datetime?: Maybe<Scalars['timestamptz']>;
  endDos?: Maybe<Scalars['date']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  insurancePlanId?: Maybe<Scalars['Int']>;
  startDos?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type PatientEligibilityCheckMinFields = {
  __typename?: 'patientEligibilityCheckMinFields';
  datetime?: Maybe<Scalars['timestamptz']>;
  endDos?: Maybe<Scalars['date']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  insurancePlanId?: Maybe<Scalars['Int']>;
  startDos?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_patienteligibilitycheck" */
export type PatientEligibilityCheckMutationResponse = {
  __typename?: 'patientEligibilityCheckMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientEligibilityCheck>;
};

/** on_conflict condition type for table "server_patienteligibilitycheck" */
export type PatientEligibilityCheckOnConflict = {
  constraint: PatientEligibilityCheckConstraint;
  update_columns?: Array<PatientEligibilityCheckUpdateColumn>;
  where?: InputMaybe<PatientEligibilityCheckBoolExp>;
};

/** Ordering options when selecting data from "server_patienteligibilitycheck". */
export type PatientEligibilityCheckOrderBy = {
  datetime?: InputMaybe<OrderBy>;
  endDos?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  externalJson?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlanId?: InputMaybe<OrderBy>;
  insuranceplan?: InputMaybe<InsuranceplanOrderBy>;
  startDos?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_patienteligibilitycheck */
export type PatientEligibilityCheckPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PatientEligibilityCheckPrependInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_patienteligibilitycheck" */
export enum PatientEligibilityCheckSelectColumn {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  EndDos = 'endDos',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  Id = 'id',
  /** column name */
  InsurancePlanId = 'insurancePlanId',
  /** column name */
  StartDos = 'startDos',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "server_patienteligibilitycheck" */
export type PatientEligibilityCheckSetInput = {
  datetime?: InputMaybe<Scalars['timestamptz']>;
  endDos?: InputMaybe<Scalars['date']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  insurancePlanId?: InputMaybe<Scalars['Int']>;
  startDos?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type PatientEligibilityCheckStddevFields = {
  __typename?: 'patientEligibilityCheckStddevFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurancePlanId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientEligibilityCheckStddev_PopFields = {
  __typename?: 'patientEligibilityCheckStddev_popFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurancePlanId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientEligibilityCheckStddev_SampFields = {
  __typename?: 'patientEligibilityCheckStddev_sampFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurancePlanId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PatientEligibilityCheckSumFields = {
  __typename?: 'patientEligibilityCheckSumFields';
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  insurancePlanId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_patienteligibilitycheck" */
export enum PatientEligibilityCheckUpdateColumn {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  EndDos = 'endDos',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  Id = 'id',
  /** column name */
  InsurancePlanId = 'insurancePlanId',
  /** column name */
  StartDos = 'startDos',
  /** column name */
  Status = 'status'
}

export type PatientEligibilityCheckUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PatientEligibilityCheckAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<PatientEligibilityCheckDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<PatientEligibilityCheckDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<PatientEligibilityCheckDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientEligibilityCheckIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PatientEligibilityCheckPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientEligibilityCheckSetInput>;
  where: PatientEligibilityCheckBoolExp;
};

/** aggregate var_pop on columns */
export type PatientEligibilityCheckVar_PopFields = {
  __typename?: 'patientEligibilityCheckVar_popFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurancePlanId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientEligibilityCheckVar_SampFields = {
  __typename?: 'patientEligibilityCheckVar_sampFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurancePlanId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientEligibilityCheckVarianceFields = {
  __typename?: 'patientEligibilityCheckVarianceFields';
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurancePlanId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_patienteligibilitycheck" */
export type PatientEligibilityCheck_Avg_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlanId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_patienteligibilitycheck" */
export type PatientEligibilityCheck_Max_Order_By = {
  datetime?: InputMaybe<OrderBy>;
  endDos?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlanId?: InputMaybe<OrderBy>;
  startDos?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_patienteligibilitycheck" */
export type PatientEligibilityCheck_Min_Order_By = {
  datetime?: InputMaybe<OrderBy>;
  endDos?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlanId?: InputMaybe<OrderBy>;
  startDos?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_patienteligibilitycheck" */
export type PatientEligibilityCheck_Stddev_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlanId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_patienteligibilitycheck" */
export type PatientEligibilityCheck_Stddev_Pop_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlanId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_patienteligibilitycheck" */
export type PatientEligibilityCheck_Stddev_Samp_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlanId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "patientEligibilityCheck" */
export type PatientEligibilityCheck_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PatientEligibilityCheck_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientEligibilityCheck_StreamCursorValueInput = {
  datetime?: InputMaybe<Scalars['timestamptz']>;
  endDos?: InputMaybe<Scalars['date']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  insurancePlanId?: InputMaybe<Scalars['Int']>;
  startDos?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "server_patienteligibilitycheck" */
export type PatientEligibilityCheck_Sum_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlanId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_patienteligibilitycheck" */
export type PatientEligibilityCheck_Var_Pop_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlanId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_patienteligibilitycheck" */
export type PatientEligibilityCheck_Var_Samp_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlanId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_patienteligibilitycheck" */
export type PatientEligibilityCheck_Variance_Order_By = {
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlanId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "server_patient" */
export type PatientIncInput = {
  admittedStatus?: InputMaybe<Scalars['smallint']>;
  externalId?: InputMaybe<Scalars['Int']>;
  gender?: InputMaybe<Scalars['smallint']>;
  homeAddressId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_patient" */
export type PatientInsertInput = {
  address?: InputMaybe<Scalars['String']>;
  admittedStatus?: InputMaybe<Scalars['smallint']>;
  agencyconnections?: InputMaybe<AgencyconnectionArrRelInsertInput>;
  allergies?: InputMaybe<AllergyArrRelInsertInput>;
  appointments?: InputMaybe<AppointmentArrRelInsertInput>;
  baseorders?: InputMaybe<BaseorderArrRelInsertInput>;
  caretimes?: InputMaybe<CaretimeArrRelInsertInput>;
  city?: InputMaybe<Scalars['String']>;
  claims?: InputMaybe<ClaimArrRelInsertInput>;
  contactPersons?: InputMaybe<PatientContactPersonArrRelInsertInput>;
  dateOfBirth?: InputMaybe<Scalars['date']>;
  documents?: InputMaybe<DocumentArrRelInsertInput>;
  documentsPendingReviewGuesses?: InputMaybe<DocumentPendingReviewArrRelInsertInput>;
  email?: InputMaybe<Scalars['String']>;
  emergencyContactName?: InputMaybe<Scalars['String']>;
  emergencyContactPhone?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  externalSignedOrders?: InputMaybe<ExternalSignedOrderArrRelInsertInput>;
  externalStaffClaimingMyPatient?: InputMaybe<ExternalStaffMyPatientsArrRelInsertInput>;
  faxes?: InputMaybe<FaxArrRelInsertInput>;
  firstName?: InputMaybe<Scalars['String']>;
  followupMax?: InputMaybe<Scalars['date']>;
  followupMin?: InputMaybe<Scalars['date']>;
  gender?: InputMaybe<Scalars['smallint']>;
  homeAddress?: InputMaybe<AddressObjRelInsertInput>;
  homeAddressId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  insurancePlans?: InputMaybe<InsuranceplanArrRelInsertInput>;
  intakes?: InputMaybe<PatientIntakeArrRelInsertInput>;
  lastName?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  medications?: InputMaybe<MedicationArrRelInsertInput>;
  middleName?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<LegacyOrderArrRelInsertInput>;
  patientAliases?: InputMaybe<PatientAliasArrRelInsertInput>;
  phones?: InputMaybe<PatientPhoneArrRelInsertInput>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  practicegroup?: InputMaybe<PracticegroupObjRelInsertInput>;
  primaryPhone?: InputMaybe<Scalars['String']>;
  problems?: InputMaybe<ProblemArrRelInsertInput>;
  referrals?: InputMaybe<ReferralArrRelInsertInput>;
  renderedAssessments?: InputMaybe<RenderedAssessmentArrRelInsertInput>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  signedConsents?: InputMaybe<SignedConsentArrRelInsertInput>;
  state?: InputMaybe<Scalars['String']>;
  statusActions?: InputMaybe<PatientStatusActionArrRelInsertInput>;
  telemedAppointmentRequests?: InputMaybe<TelemedAppointmentRequestArrRelInsertInput>;
  ticketthreads?: InputMaybe<TicketThreadArrRelInsertInput>;
  treatmentplans?: InputMaybe<TreatmentplanArrRelInsertInput>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "server_patientintake" */
export type PatientIntake = {
  __typename?: 'patientIntake';
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  insuranceId?: Maybe<Scalars['String']>;
  insuranceName?: Maybe<Scalars['String']>;
  lastFourSocial?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** An array relationship */
  messages: Array<Message>;
  /** An aggregate relationship */
  messagesAggregate: MessageAggregate;
  /** An object relationship */
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['Int']>;
  practiceGroupId: Scalars['Int'];
  /** An object relationship */
  practicegroup: Practicegroup;
  /** An array relationship */
  recordsLocations: Array<PatientIntakeRecordsLocations>;
  /** An aggregate relationship */
  recordsLocationsAggregate: PatientIntakeRecordsLocationsAggregate;
  status: Scalars['smallint'];
  /** An object relationship */
  submittedAgency: Agency;
  submittedAgencyId: Scalars['Int'];
  /** An object relationship */
  submittedUser: User;
  submittedUserId: Scalars['Int'];
  /** An array relationship */
  telemedAppointmentRequests: Array<TelemedAppointmentRequest>;
  /** An aggregate relationship */
  telemedAppointmentRequestsAggregate: TelemedAppointmentRequestAggregate;
  /** A computed field, executes function "user_can_access_patientintake" */
  user_can_access?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "server_patientintake" */
export type PatientIntakeMessagesArgs = {
  distinctOn?: InputMaybe<Array<MessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderBy>>;
  where?: InputMaybe<MessageBoolExp>;
};


/** columns and relationships of "server_patientintake" */
export type PatientIntakeMessagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderBy>>;
  where?: InputMaybe<MessageBoolExp>;
};


/** columns and relationships of "server_patientintake" */
export type PatientIntakeRecordsLocationsArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeRecordsLocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeRecordsLocationsOrderBy>>;
  where?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
};


/** columns and relationships of "server_patientintake" */
export type PatientIntakeRecordsLocationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeRecordsLocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeRecordsLocationsOrderBy>>;
  where?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
};


/** columns and relationships of "server_patientintake" */
export type PatientIntakeTelemedAppointmentRequestsArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};


/** columns and relationships of "server_patientintake" */
export type PatientIntakeTelemedAppointmentRequestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};

/** aggregated selection of "server_patientintake" */
export type PatientIntakeAggregate = {
  __typename?: 'patientIntakeAggregate';
  aggregate?: Maybe<PatientIntakeAggregateFields>;
  nodes: Array<PatientIntake>;
};

/** aggregate fields of "server_patientintake" */
export type PatientIntakeAggregateFields = {
  __typename?: 'patientIntakeAggregateFields';
  avg?: Maybe<PatientIntakeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PatientIntakeMaxFields>;
  min?: Maybe<PatientIntakeMinFields>;
  stddev?: Maybe<PatientIntakeStddevFields>;
  stddevPop?: Maybe<PatientIntakeStddev_PopFields>;
  stddevSamp?: Maybe<PatientIntakeStddev_SampFields>;
  sum?: Maybe<PatientIntakeSumFields>;
  varPop?: Maybe<PatientIntakeVar_PopFields>;
  varSamp?: Maybe<PatientIntakeVar_SampFields>;
  variance?: Maybe<PatientIntakeVarianceFields>;
};


/** aggregate fields of "server_patientintake" */
export type PatientIntakeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_patientintake" */
export type PatientIntakeAggregateOrderBy = {
  avg?: InputMaybe<PatientIntake_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PatientIntake_Max_Order_By>;
  min?: InputMaybe<PatientIntake_Min_Order_By>;
  stddev?: InputMaybe<PatientIntake_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PatientIntake_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PatientIntake_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PatientIntake_Sum_Order_By>;
  var_pop?: InputMaybe<PatientIntake_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PatientIntake_Var_Samp_Order_By>;
  variance?: InputMaybe<PatientIntake_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_patientintake" */
export type PatientIntakeArrRelInsertInput = {
  data: Array<PatientIntakeInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PatientIntakeOnConflict>;
};

/** aggregate avg on columns */
export type PatientIntakeAvgFields = {
  __typename?: 'patientIntakeAvgFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  submittedAgencyId?: Maybe<Scalars['Float']>;
  submittedUserId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_patientintake". All fields are combined with a logical 'AND'. */
export type PatientIntakeBoolExp = {
  _and?: InputMaybe<Array<PatientIntakeBoolExp>>;
  _not?: InputMaybe<PatientIntakeBoolExp>;
  _or?: InputMaybe<Array<PatientIntakeBoolExp>>;
  dateOfBirth?: InputMaybe<DateComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  insuranceId?: InputMaybe<StringComparisonExp>;
  insuranceName?: InputMaybe<StringComparisonExp>;
  lastFourSocial?: InputMaybe<StringComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  messages?: InputMaybe<MessageBoolExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  practiceGroupId?: InputMaybe<IntComparisonExp>;
  practicegroup?: InputMaybe<PracticegroupBoolExp>;
  recordsLocations?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
  status?: InputMaybe<SmallintComparisonExp>;
  submittedAgency?: InputMaybe<AgencyBoolExp>;
  submittedAgencyId?: InputMaybe<IntComparisonExp>;
  submittedUser?: InputMaybe<UserBoolExp>;
  submittedUserId?: InputMaybe<IntComparisonExp>;
  telemedAppointmentRequests?: InputMaybe<TelemedAppointmentRequestBoolExp>;
  user_can_access?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "server_patientintake" */
export enum PatientIntakeConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerPatientintakePkey = 'server_patientintake_pkey'
}

/** input type for incrementing numeric columns in table "server_patientintake" */
export type PatientIntakeIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
  submittedAgencyId?: InputMaybe<Scalars['Int']>;
  submittedUserId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_patientintake" */
export type PatientIntakeInsertInput = {
  dateOfBirth?: InputMaybe<Scalars['date']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  insuranceId?: InputMaybe<Scalars['String']>;
  insuranceName?: InputMaybe<Scalars['String']>;
  lastFourSocial?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  messages?: InputMaybe<MessageArrRelInsertInput>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  practicegroup?: InputMaybe<PracticegroupObjRelInsertInput>;
  recordsLocations?: InputMaybe<PatientIntakeRecordsLocationsArrRelInsertInput>;
  status?: InputMaybe<Scalars['smallint']>;
  submittedAgency?: InputMaybe<AgencyObjRelInsertInput>;
  submittedAgencyId?: InputMaybe<Scalars['Int']>;
  submittedUser?: InputMaybe<UserObjRelInsertInput>;
  submittedUserId?: InputMaybe<Scalars['Int']>;
  telemedAppointmentRequests?: InputMaybe<TelemedAppointmentRequestArrRelInsertInput>;
};

/** aggregate max on columns */
export type PatientIntakeMaxFields = {
  __typename?: 'patientIntakeMaxFields';
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  insuranceId?: Maybe<Scalars['String']>;
  insuranceName?: Maybe<Scalars['String']>;
  lastFourSocial?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
  submittedAgencyId?: Maybe<Scalars['Int']>;
  submittedUserId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type PatientIntakeMinFields = {
  __typename?: 'patientIntakeMinFields';
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  insuranceId?: Maybe<Scalars['String']>;
  insuranceName?: Maybe<Scalars['String']>;
  lastFourSocial?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
  submittedAgencyId?: Maybe<Scalars['Int']>;
  submittedUserId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_patientintake" */
export type PatientIntakeMutationResponse = {
  __typename?: 'patientIntakeMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientIntake>;
};

/** input type for inserting object relation for remote table "server_patientintake" */
export type PatientIntakeObjRelInsertInput = {
  data: PatientIntakeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PatientIntakeOnConflict>;
};

/** on_conflict condition type for table "server_patientintake" */
export type PatientIntakeOnConflict = {
  constraint: PatientIntakeConstraint;
  update_columns?: Array<PatientIntakeUpdateColumn>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};

/** Ordering options when selecting data from "server_patientintake". */
export type PatientIntakeOrderBy = {
  dateOfBirth?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insuranceId?: InputMaybe<OrderBy>;
  insuranceName?: InputMaybe<OrderBy>;
  lastFourSocial?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  messagesAggregate?: InputMaybe<MessageAggregateOrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  practicegroup?: InputMaybe<PracticegroupOrderBy>;
  recordsLocationsAggregate?: InputMaybe<PatientIntakeRecordsLocationsAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
  submittedAgency?: InputMaybe<AgencyOrderBy>;
  submittedAgencyId?: InputMaybe<OrderBy>;
  submittedUser?: InputMaybe<UserOrderBy>;
  submittedUserId?: InputMaybe<OrderBy>;
  telemedAppointmentRequestsAggregate?: InputMaybe<TelemedAppointmentRequestAggregateOrderBy>;
  user_can_access?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_patientintake */
export type PatientIntakePkColumnsInput = {
  id: Scalars['Int'];
};

/** columns and relationships of "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocations = {
  __typename?: 'patientIntakeRecordsLocations';
  id: Scalars['Int'];
  /** An object relationship */
  intake: PatientIntake;
  patientintakeId: Scalars['Int'];
  /** An object relationship */
  recordsLocation: RecordsRequestDestination;
  recordsrequestdestinationId: Scalars['Int'];
};

/** aggregated selection of "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocationsAggregate = {
  __typename?: 'patientIntakeRecordsLocationsAggregate';
  aggregate?: Maybe<PatientIntakeRecordsLocationsAggregateFields>;
  nodes: Array<PatientIntakeRecordsLocations>;
};

/** aggregate fields of "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocationsAggregateFields = {
  __typename?: 'patientIntakeRecordsLocationsAggregateFields';
  avg?: Maybe<PatientIntakeRecordsLocationsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PatientIntakeRecordsLocationsMaxFields>;
  min?: Maybe<PatientIntakeRecordsLocationsMinFields>;
  stddev?: Maybe<PatientIntakeRecordsLocationsStddevFields>;
  stddevPop?: Maybe<PatientIntakeRecordsLocationsStddev_PopFields>;
  stddevSamp?: Maybe<PatientIntakeRecordsLocationsStddev_SampFields>;
  sum?: Maybe<PatientIntakeRecordsLocationsSumFields>;
  varPop?: Maybe<PatientIntakeRecordsLocationsVar_PopFields>;
  varSamp?: Maybe<PatientIntakeRecordsLocationsVar_SampFields>;
  variance?: Maybe<PatientIntakeRecordsLocationsVarianceFields>;
};


/** aggregate fields of "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PatientIntakeRecordsLocationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocationsAggregateOrderBy = {
  avg?: InputMaybe<PatientIntakeRecordsLocations_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PatientIntakeRecordsLocations_Max_Order_By>;
  min?: InputMaybe<PatientIntakeRecordsLocations_Min_Order_By>;
  stddev?: InputMaybe<PatientIntakeRecordsLocations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PatientIntakeRecordsLocations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PatientIntakeRecordsLocations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PatientIntakeRecordsLocations_Sum_Order_By>;
  var_pop?: InputMaybe<PatientIntakeRecordsLocations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PatientIntakeRecordsLocations_Var_Samp_Order_By>;
  variance?: InputMaybe<PatientIntakeRecordsLocations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocationsArrRelInsertInput = {
  data: Array<PatientIntakeRecordsLocationsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PatientIntakeRecordsLocationsOnConflict>;
};

/** aggregate avg on columns */
export type PatientIntakeRecordsLocationsAvgFields = {
  __typename?: 'patientIntakeRecordsLocationsAvgFields';
  id?: Maybe<Scalars['Float']>;
  patientintakeId?: Maybe<Scalars['Float']>;
  recordsrequestdestinationId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_patientintake_records_locations". All fields are combined with a logical 'AND'. */
export type PatientIntakeRecordsLocationsBoolExp = {
  _and?: InputMaybe<Array<PatientIntakeRecordsLocationsBoolExp>>;
  _not?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
  _or?: InputMaybe<Array<PatientIntakeRecordsLocationsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  intake?: InputMaybe<PatientIntakeBoolExp>;
  patientintakeId?: InputMaybe<IntComparisonExp>;
  recordsLocation?: InputMaybe<RecordsRequestDestinationBoolExp>;
  recordsrequestdestinationId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_patientintake_records_locations" */
export enum PatientIntakeRecordsLocationsConstraint {
  /** unique or primary key constraint on columns "recordsrequestdestination_id", "patientintake_id" */
  ServerPatientintakeRecPatientintakeIdRecords_41f7278bUniq = 'server_patientintake_rec_patientintake_id_records_41f7278b_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerPatientintakeRecordsLocationsPkey = 'server_patientintake_records_locations_pkey'
}

/** input type for incrementing numeric columns in table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocationsIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  patientintakeId?: InputMaybe<Scalars['Int']>;
  recordsrequestdestinationId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocationsInsertInput = {
  id?: InputMaybe<Scalars['Int']>;
  intake?: InputMaybe<PatientIntakeObjRelInsertInput>;
  patientintakeId?: InputMaybe<Scalars['Int']>;
  recordsLocation?: InputMaybe<RecordsRequestDestinationObjRelInsertInput>;
  recordsrequestdestinationId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type PatientIntakeRecordsLocationsMaxFields = {
  __typename?: 'patientIntakeRecordsLocationsMaxFields';
  id?: Maybe<Scalars['Int']>;
  patientintakeId?: Maybe<Scalars['Int']>;
  recordsrequestdestinationId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type PatientIntakeRecordsLocationsMinFields = {
  __typename?: 'patientIntakeRecordsLocationsMinFields';
  id?: Maybe<Scalars['Int']>;
  patientintakeId?: Maybe<Scalars['Int']>;
  recordsrequestdestinationId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocationsMutationResponse = {
  __typename?: 'patientIntakeRecordsLocationsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientIntakeRecordsLocations>;
};

/** on_conflict condition type for table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocationsOnConflict = {
  constraint: PatientIntakeRecordsLocationsConstraint;
  update_columns?: Array<PatientIntakeRecordsLocationsUpdateColumn>;
  where?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
};

/** Ordering options when selecting data from "server_patientintake_records_locations". */
export type PatientIntakeRecordsLocationsOrderBy = {
  id?: InputMaybe<OrderBy>;
  intake?: InputMaybe<PatientIntakeOrderBy>;
  patientintakeId?: InputMaybe<OrderBy>;
  recordsLocation?: InputMaybe<RecordsRequestDestinationOrderBy>;
  recordsrequestdestinationId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_patientintake_records_locations */
export type PatientIntakeRecordsLocationsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_patientintake_records_locations" */
export enum PatientIntakeRecordsLocationsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  PatientintakeId = 'patientintakeId',
  /** column name */
  RecordsrequestdestinationId = 'recordsrequestdestinationId'
}

/** input type for updating data in table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocationsSetInput = {
  id?: InputMaybe<Scalars['Int']>;
  patientintakeId?: InputMaybe<Scalars['Int']>;
  recordsrequestdestinationId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PatientIntakeRecordsLocationsStddevFields = {
  __typename?: 'patientIntakeRecordsLocationsStddevFields';
  id?: Maybe<Scalars['Float']>;
  patientintakeId?: Maybe<Scalars['Float']>;
  recordsrequestdestinationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientIntakeRecordsLocationsStddev_PopFields = {
  __typename?: 'patientIntakeRecordsLocationsStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  patientintakeId?: Maybe<Scalars['Float']>;
  recordsrequestdestinationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientIntakeRecordsLocationsStddev_SampFields = {
  __typename?: 'patientIntakeRecordsLocationsStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  patientintakeId?: Maybe<Scalars['Float']>;
  recordsrequestdestinationId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PatientIntakeRecordsLocationsSumFields = {
  __typename?: 'patientIntakeRecordsLocationsSumFields';
  id?: Maybe<Scalars['Int']>;
  patientintakeId?: Maybe<Scalars['Int']>;
  recordsrequestdestinationId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_patientintake_records_locations" */
export enum PatientIntakeRecordsLocationsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  PatientintakeId = 'patientintakeId',
  /** column name */
  RecordsrequestdestinationId = 'recordsrequestdestinationId'
}

export type PatientIntakeRecordsLocationsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientIntakeRecordsLocationsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientIntakeRecordsLocationsSetInput>;
  where: PatientIntakeRecordsLocationsBoolExp;
};

/** aggregate var_pop on columns */
export type PatientIntakeRecordsLocationsVar_PopFields = {
  __typename?: 'patientIntakeRecordsLocationsVar_popFields';
  id?: Maybe<Scalars['Float']>;
  patientintakeId?: Maybe<Scalars['Float']>;
  recordsrequestdestinationId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientIntakeRecordsLocationsVar_SampFields = {
  __typename?: 'patientIntakeRecordsLocationsVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  patientintakeId?: Maybe<Scalars['Float']>;
  recordsrequestdestinationId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientIntakeRecordsLocationsVarianceFields = {
  __typename?: 'patientIntakeRecordsLocationsVarianceFields';
  id?: Maybe<Scalars['Float']>;
  patientintakeId?: Maybe<Scalars['Float']>;
  recordsrequestdestinationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocations_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientintakeId?: InputMaybe<OrderBy>;
  recordsrequestdestinationId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocations_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientintakeId?: InputMaybe<OrderBy>;
  recordsrequestdestinationId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocations_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientintakeId?: InputMaybe<OrderBy>;
  recordsrequestdestinationId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocations_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientintakeId?: InputMaybe<OrderBy>;
  recordsrequestdestinationId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocations_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientintakeId?: InputMaybe<OrderBy>;
  recordsrequestdestinationId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocations_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientintakeId?: InputMaybe<OrderBy>;
  recordsrequestdestinationId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "patientIntakeRecordsLocations" */
export type PatientIntakeRecordsLocations_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PatientIntakeRecordsLocations_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientIntakeRecordsLocations_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  patientintakeId?: InputMaybe<Scalars['Int']>;
  recordsrequestdestinationId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocations_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientintakeId?: InputMaybe<OrderBy>;
  recordsrequestdestinationId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocations_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientintakeId?: InputMaybe<OrderBy>;
  recordsrequestdestinationId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocations_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientintakeId?: InputMaybe<OrderBy>;
  recordsrequestdestinationId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_patientintake_records_locations" */
export type PatientIntakeRecordsLocations_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientintakeId?: InputMaybe<OrderBy>;
  recordsrequestdestinationId?: InputMaybe<OrderBy>;
};

/** select columns of table "server_patientintake" */
export enum PatientIntakeSelectColumn {
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceId = 'insuranceId',
  /** column name */
  InsuranceName = 'insuranceName',
  /** column name */
  LastFourSocial = 'lastFourSocial',
  /** column name */
  LastName = 'lastName',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PracticeGroupId = 'practiceGroupId',
  /** column name */
  Status = 'status',
  /** column name */
  SubmittedAgencyId = 'submittedAgencyId',
  /** column name */
  SubmittedUserId = 'submittedUserId'
}

/** input type for updating data in table "server_patientintake" */
export type PatientIntakeSetInput = {
  dateOfBirth?: InputMaybe<Scalars['date']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  insuranceId?: InputMaybe<Scalars['String']>;
  insuranceName?: InputMaybe<Scalars['String']>;
  lastFourSocial?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
  submittedAgencyId?: InputMaybe<Scalars['Int']>;
  submittedUserId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PatientIntakeStddevFields = {
  __typename?: 'patientIntakeStddevFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  submittedAgencyId?: Maybe<Scalars['Float']>;
  submittedUserId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientIntakeStddev_PopFields = {
  __typename?: 'patientIntakeStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  submittedAgencyId?: Maybe<Scalars['Float']>;
  submittedUserId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientIntakeStddev_SampFields = {
  __typename?: 'patientIntakeStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  submittedAgencyId?: Maybe<Scalars['Float']>;
  submittedUserId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PatientIntakeSumFields = {
  __typename?: 'patientIntakeSumFields';
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
  submittedAgencyId?: Maybe<Scalars['Int']>;
  submittedUserId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_patientintake" */
export enum PatientIntakeUpdateColumn {
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceId = 'insuranceId',
  /** column name */
  InsuranceName = 'insuranceName',
  /** column name */
  LastFourSocial = 'lastFourSocial',
  /** column name */
  LastName = 'lastName',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PracticeGroupId = 'practiceGroupId',
  /** column name */
  Status = 'status',
  /** column name */
  SubmittedAgencyId = 'submittedAgencyId',
  /** column name */
  SubmittedUserId = 'submittedUserId'
}

export type PatientIntakeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientIntakeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientIntakeSetInput>;
  where: PatientIntakeBoolExp;
};

/** aggregate var_pop on columns */
export type PatientIntakeVar_PopFields = {
  __typename?: 'patientIntakeVar_popFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  submittedAgencyId?: Maybe<Scalars['Float']>;
  submittedUserId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientIntakeVar_SampFields = {
  __typename?: 'patientIntakeVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  submittedAgencyId?: Maybe<Scalars['Float']>;
  submittedUserId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientIntakeVarianceFields = {
  __typename?: 'patientIntakeVarianceFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  submittedAgencyId?: Maybe<Scalars['Float']>;
  submittedUserId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_patientintake" */
export type PatientIntake_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  submittedAgencyId?: InputMaybe<OrderBy>;
  submittedUserId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_patientintake" */
export type PatientIntake_Max_Order_By = {
  dateOfBirth?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insuranceId?: InputMaybe<OrderBy>;
  insuranceName?: InputMaybe<OrderBy>;
  lastFourSocial?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  submittedAgencyId?: InputMaybe<OrderBy>;
  submittedUserId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_patientintake" */
export type PatientIntake_Min_Order_By = {
  dateOfBirth?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insuranceId?: InputMaybe<OrderBy>;
  insuranceName?: InputMaybe<OrderBy>;
  lastFourSocial?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  submittedAgencyId?: InputMaybe<OrderBy>;
  submittedUserId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_patientintake" */
export type PatientIntake_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  submittedAgencyId?: InputMaybe<OrderBy>;
  submittedUserId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_patientintake" */
export type PatientIntake_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  submittedAgencyId?: InputMaybe<OrderBy>;
  submittedUserId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_patientintake" */
export type PatientIntake_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  submittedAgencyId?: InputMaybe<OrderBy>;
  submittedUserId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "patientIntake" */
export type PatientIntake_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PatientIntake_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientIntake_StreamCursorValueInput = {
  dateOfBirth?: InputMaybe<Scalars['date']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  insuranceId?: InputMaybe<Scalars['String']>;
  insuranceName?: InputMaybe<Scalars['String']>;
  lastFourSocial?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
  submittedAgencyId?: InputMaybe<Scalars['Int']>;
  submittedUserId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_patientintake" */
export type PatientIntake_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  submittedAgencyId?: InputMaybe<OrderBy>;
  submittedUserId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_patientintake" */
export type PatientIntake_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  submittedAgencyId?: InputMaybe<OrderBy>;
  submittedUserId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_patientintake" */
export type PatientIntake_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  submittedAgencyId?: InputMaybe<OrderBy>;
  submittedUserId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_patientintake" */
export type PatientIntake_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  submittedAgencyId?: InputMaybe<OrderBy>;
  submittedUserId?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type PatientMaxFields = {
  __typename?: 'patientMaxFields';
  address?: Maybe<Scalars['String']>;
  admittedStatus?: Maybe<Scalars['smallint']>;
  city?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactPhone?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  followupMax?: Maybe<Scalars['date']>;
  followupMin?: Maybe<Scalars['date']>;
  gender?: Maybe<Scalars['smallint']>;
  homeAddressId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  middleName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
  primaryPhone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PatientMinFields = {
  __typename?: 'patientMinFields';
  address?: Maybe<Scalars['String']>;
  admittedStatus?: Maybe<Scalars['smallint']>;
  city?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactPhone?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  followupMax?: Maybe<Scalars['date']>;
  followupMin?: Maybe<Scalars['date']>;
  gender?: Maybe<Scalars['smallint']>;
  homeAddressId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  middleName?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
  primaryPhone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_patient" */
export type PatientMutationResponse = {
  __typename?: 'patientMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient>;
};

/** input type for inserting object relation for remote table "server_patient" */
export type PatientObjRelInsertInput = {
  data: PatientInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PatientOnConflict>;
};

/** on_conflict condition type for table "server_patient" */
export type PatientOnConflict = {
  constraint: PatientConstraint;
  update_columns?: Array<PatientUpdateColumn>;
  where?: InputMaybe<PatientBoolExp>;
};

/** Ordering options when selecting data from "server_patient". */
export type PatientOrderBy = {
  address?: InputMaybe<OrderBy>;
  admittedStatus?: InputMaybe<OrderBy>;
  agencyconnectionsAggregate?: InputMaybe<AgencyconnectionAggregateOrderBy>;
  allergiesAggregate?: InputMaybe<AllergyAggregateOrderBy>;
  appointmentsAggregate?: InputMaybe<AppointmentAggregateOrderBy>;
  baseordersAggregate?: InputMaybe<BaseorderAggregateOrderBy>;
  caretimesAggregate?: InputMaybe<CaretimeAggregateOrderBy>;
  city?: InputMaybe<OrderBy>;
  claimsAggregate?: InputMaybe<ClaimAggregateOrderBy>;
  contactPersonsAggregate?: InputMaybe<PatientContactPersonAggregateOrderBy>;
  dateOfBirth?: InputMaybe<OrderBy>;
  documentsAggregate?: InputMaybe<DocumentAggregateOrderBy>;
  documentsPendingReviewGuessesAggregate?: InputMaybe<DocumentPendingReviewAggregateOrderBy>;
  email?: InputMaybe<OrderBy>;
  emergencyContactName?: InputMaybe<OrderBy>;
  emergencyContactPhone?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  externalJson?: InputMaybe<OrderBy>;
  externalSignedOrdersAggregate?: InputMaybe<ExternalSignedOrderAggregateOrderBy>;
  externalStaffClaimingMyPatientAggregate?: InputMaybe<ExternalStaffMyPatientsAggregateOrderBy>;
  faxesAggregate?: InputMaybe<FaxAggregateOrderBy>;
  firstName?: InputMaybe<OrderBy>;
  followupMax?: InputMaybe<OrderBy>;
  followupMin?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  homeAddress?: InputMaybe<AddressOrderBy>;
  homeAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlansAggregate?: InputMaybe<InsuranceplanAggregateOrderBy>;
  intakesAggregate?: InputMaybe<PatientIntakeAggregateOrderBy>;
  lastName?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  medicationsAggregate?: InputMaybe<MedicationAggregateOrderBy>;
  middleName?: InputMaybe<OrderBy>;
  nickName?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  ordersAggregate?: InputMaybe<LegacyOrderAggregateOrderBy>;
  patientAliasesAggregate?: InputMaybe<PatientAliasAggregateOrderBy>;
  phonesAggregate?: InputMaybe<PatientPhoneAggregateOrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  practicegroup?: InputMaybe<PracticegroupOrderBy>;
  primaryPhone?: InputMaybe<OrderBy>;
  problemsAggregate?: InputMaybe<ProblemAggregateOrderBy>;
  referralsAggregate?: InputMaybe<ReferralAggregateOrderBy>;
  renderedAssessmentsAggregate?: InputMaybe<RenderedAssessmentAggregateOrderBy>;
  secondaryPhone?: InputMaybe<OrderBy>;
  signedConsentsAggregate?: InputMaybe<SignedConsentAggregateOrderBy>;
  state?: InputMaybe<OrderBy>;
  statusActionsAggregate?: InputMaybe<PatientStatusActionAggregateOrderBy>;
  telemedAppointmentRequestsAggregate?: InputMaybe<TelemedAppointmentRequestAggregateOrderBy>;
  ticketthreadsAggregate?: InputMaybe<TicketThreadAggregateOrderBy>;
  treatmentplansAggregate?: InputMaybe<TreatmentplanAggregateOrderBy>;
  user_can_access?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_patientphone" */
export type PatientPhone = {
  __typename?: 'patientPhone';
  active: Scalars['Boolean'];
  appointmentNotificationPreference?: Maybe<Scalars['smallint']>;
  /** An object relationship */
  contactPerson?: Maybe<PatientContactPerson>;
  contactPersonId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  leaveVoicemail: Scalars['Boolean'];
  notes: Scalars['String'];
  notifyPhysical: Scalars['Boolean'];
  notifyVirtual: Scalars['Boolean'];
  number: Scalars['String'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  rank: Scalars['smallint'];
  supportsSms: Scalars['Boolean'];
  typeDescription?: Maybe<Scalars['String']>;
};

/** aggregated selection of "server_patientphone" */
export type PatientPhoneAggregate = {
  __typename?: 'patientPhoneAggregate';
  aggregate?: Maybe<PatientPhoneAggregateFields>;
  nodes: Array<PatientPhone>;
};

/** aggregate fields of "server_patientphone" */
export type PatientPhoneAggregateFields = {
  __typename?: 'patientPhoneAggregateFields';
  avg?: Maybe<PatientPhoneAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PatientPhoneMaxFields>;
  min?: Maybe<PatientPhoneMinFields>;
  stddev?: Maybe<PatientPhoneStddevFields>;
  stddevPop?: Maybe<PatientPhoneStddev_PopFields>;
  stddevSamp?: Maybe<PatientPhoneStddev_SampFields>;
  sum?: Maybe<PatientPhoneSumFields>;
  varPop?: Maybe<PatientPhoneVar_PopFields>;
  varSamp?: Maybe<PatientPhoneVar_SampFields>;
  variance?: Maybe<PatientPhoneVarianceFields>;
};


/** aggregate fields of "server_patientphone" */
export type PatientPhoneAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PatientPhoneSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_patientphone" */
export type PatientPhoneAggregateOrderBy = {
  avg?: InputMaybe<PatientPhone_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PatientPhone_Max_Order_By>;
  min?: InputMaybe<PatientPhone_Min_Order_By>;
  stddev?: InputMaybe<PatientPhone_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PatientPhone_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PatientPhone_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PatientPhone_Sum_Order_By>;
  var_pop?: InputMaybe<PatientPhone_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PatientPhone_Var_Samp_Order_By>;
  variance?: InputMaybe<PatientPhone_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_patientphone" */
export type PatientPhoneArrRelInsertInput = {
  data: Array<PatientPhoneInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PatientPhoneOnConflict>;
};

/** aggregate avg on columns */
export type PatientPhoneAvgFields = {
  __typename?: 'patientPhoneAvgFields';
  appointmentNotificationPreference?: Maybe<Scalars['Float']>;
  contactPersonId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_patientphone". All fields are combined with a logical 'AND'. */
export type PatientPhoneBoolExp = {
  _and?: InputMaybe<Array<PatientPhoneBoolExp>>;
  _not?: InputMaybe<PatientPhoneBoolExp>;
  _or?: InputMaybe<Array<PatientPhoneBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  appointmentNotificationPreference?: InputMaybe<SmallintComparisonExp>;
  contactPerson?: InputMaybe<PatientContactPersonBoolExp>;
  contactPersonId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  leaveVoicemail?: InputMaybe<BooleanComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  notifyPhysical?: InputMaybe<BooleanComparisonExp>;
  notifyVirtual?: InputMaybe<BooleanComparisonExp>;
  number?: InputMaybe<StringComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  rank?: InputMaybe<SmallintComparisonExp>;
  supportsSms?: InputMaybe<BooleanComparisonExp>;
  typeDescription?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_patientphone" */
export enum PatientPhoneConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerPatientphonePkey = 'server_patientphone_pkey'
}

/** input type for incrementing numeric columns in table "server_patientphone" */
export type PatientPhoneIncInput = {
  appointmentNotificationPreference?: InputMaybe<Scalars['smallint']>;
  contactPersonId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_patientphone" */
export type PatientPhoneInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  appointmentNotificationPreference?: InputMaybe<Scalars['smallint']>;
  contactPerson?: InputMaybe<PatientContactPersonObjRelInsertInput>;
  contactPersonId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  leaveVoicemail?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  notifyPhysical?: InputMaybe<Scalars['Boolean']>;
  notifyVirtual?: InputMaybe<Scalars['Boolean']>;
  number?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['smallint']>;
  supportsSms?: InputMaybe<Scalars['Boolean']>;
  typeDescription?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PatientPhoneMaxFields = {
  __typename?: 'patientPhoneMaxFields';
  appointmentNotificationPreference?: Maybe<Scalars['smallint']>;
  contactPersonId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['smallint']>;
  typeDescription?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PatientPhoneMinFields = {
  __typename?: 'patientPhoneMinFields';
  appointmentNotificationPreference?: Maybe<Scalars['smallint']>;
  contactPersonId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['smallint']>;
  typeDescription?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_patientphone" */
export type PatientPhoneMutationResponse = {
  __typename?: 'patientPhoneMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientPhone>;
};

/** on_conflict condition type for table "server_patientphone" */
export type PatientPhoneOnConflict = {
  constraint: PatientPhoneConstraint;
  update_columns?: Array<PatientPhoneUpdateColumn>;
  where?: InputMaybe<PatientPhoneBoolExp>;
};

/** Ordering options when selecting data from "server_patientphone". */
export type PatientPhoneOrderBy = {
  active?: InputMaybe<OrderBy>;
  appointmentNotificationPreference?: InputMaybe<OrderBy>;
  contactPerson?: InputMaybe<PatientContactPersonOrderBy>;
  contactPersonId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  leaveVoicemail?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  notifyPhysical?: InputMaybe<OrderBy>;
  notifyVirtual?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
  supportsSms?: InputMaybe<OrderBy>;
  typeDescription?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_patientphone */
export type PatientPhonePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_patientphone" */
export enum PatientPhoneSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AppointmentNotificationPreference = 'appointmentNotificationPreference',
  /** column name */
  ContactPersonId = 'contactPersonId',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveVoicemail = 'leaveVoicemail',
  /** column name */
  Notes = 'notes',
  /** column name */
  NotifyPhysical = 'notifyPhysical',
  /** column name */
  NotifyVirtual = 'notifyVirtual',
  /** column name */
  Number = 'number',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Rank = 'rank',
  /** column name */
  SupportsSms = 'supportsSms',
  /** column name */
  TypeDescription = 'typeDescription'
}

/** input type for updating data in table "server_patientphone" */
export type PatientPhoneSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  appointmentNotificationPreference?: InputMaybe<Scalars['smallint']>;
  contactPersonId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  leaveVoicemail?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  notifyPhysical?: InputMaybe<Scalars['Boolean']>;
  notifyVirtual?: InputMaybe<Scalars['Boolean']>;
  number?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['smallint']>;
  supportsSms?: InputMaybe<Scalars['Boolean']>;
  typeDescription?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type PatientPhoneStddevFields = {
  __typename?: 'patientPhoneStddevFields';
  appointmentNotificationPreference?: Maybe<Scalars['Float']>;
  contactPersonId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientPhoneStddev_PopFields = {
  __typename?: 'patientPhoneStddev_popFields';
  appointmentNotificationPreference?: Maybe<Scalars['Float']>;
  contactPersonId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientPhoneStddev_SampFields = {
  __typename?: 'patientPhoneStddev_sampFields';
  appointmentNotificationPreference?: Maybe<Scalars['Float']>;
  contactPersonId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PatientPhoneSumFields = {
  __typename?: 'patientPhoneSumFields';
  appointmentNotificationPreference?: Maybe<Scalars['smallint']>;
  contactPersonId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_patientphone" */
export enum PatientPhoneUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AppointmentNotificationPreference = 'appointmentNotificationPreference',
  /** column name */
  ContactPersonId = 'contactPersonId',
  /** column name */
  Id = 'id',
  /** column name */
  LeaveVoicemail = 'leaveVoicemail',
  /** column name */
  Notes = 'notes',
  /** column name */
  NotifyPhysical = 'notifyPhysical',
  /** column name */
  NotifyVirtual = 'notifyVirtual',
  /** column name */
  Number = 'number',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Rank = 'rank',
  /** column name */
  SupportsSms = 'supportsSms',
  /** column name */
  TypeDescription = 'typeDescription'
}

export type PatientPhoneUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientPhoneIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientPhoneSetInput>;
  where: PatientPhoneBoolExp;
};

/** aggregate var_pop on columns */
export type PatientPhoneVar_PopFields = {
  __typename?: 'patientPhoneVar_popFields';
  appointmentNotificationPreference?: Maybe<Scalars['Float']>;
  contactPersonId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientPhoneVar_SampFields = {
  __typename?: 'patientPhoneVar_sampFields';
  appointmentNotificationPreference?: Maybe<Scalars['Float']>;
  contactPersonId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientPhoneVarianceFields = {
  __typename?: 'patientPhoneVarianceFields';
  appointmentNotificationPreference?: Maybe<Scalars['Float']>;
  contactPersonId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_patientphone" */
export type PatientPhone_Avg_Order_By = {
  appointmentNotificationPreference?: InputMaybe<OrderBy>;
  contactPersonId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_patientphone" */
export type PatientPhone_Max_Order_By = {
  appointmentNotificationPreference?: InputMaybe<OrderBy>;
  contactPersonId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
  typeDescription?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_patientphone" */
export type PatientPhone_Min_Order_By = {
  appointmentNotificationPreference?: InputMaybe<OrderBy>;
  contactPersonId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
  typeDescription?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_patientphone" */
export type PatientPhone_Stddev_Order_By = {
  appointmentNotificationPreference?: InputMaybe<OrderBy>;
  contactPersonId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_patientphone" */
export type PatientPhone_Stddev_Pop_Order_By = {
  appointmentNotificationPreference?: InputMaybe<OrderBy>;
  contactPersonId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_patientphone" */
export type PatientPhone_Stddev_Samp_Order_By = {
  appointmentNotificationPreference?: InputMaybe<OrderBy>;
  contactPersonId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "patientPhone" */
export type PatientPhone_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PatientPhone_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientPhone_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  appointmentNotificationPreference?: InputMaybe<Scalars['smallint']>;
  contactPersonId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  leaveVoicemail?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  notifyPhysical?: InputMaybe<Scalars['Boolean']>;
  notifyVirtual?: InputMaybe<Scalars['Boolean']>;
  number?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['smallint']>;
  supportsSms?: InputMaybe<Scalars['Boolean']>;
  typeDescription?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_patientphone" */
export type PatientPhone_Sum_Order_By = {
  appointmentNotificationPreference?: InputMaybe<OrderBy>;
  contactPersonId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_patientphone" */
export type PatientPhone_Var_Pop_Order_By = {
  appointmentNotificationPreference?: InputMaybe<OrderBy>;
  contactPersonId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_patientphone" */
export type PatientPhone_Var_Samp_Order_By = {
  appointmentNotificationPreference?: InputMaybe<OrderBy>;
  contactPersonId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_patientphone" */
export type PatientPhone_Variance_Order_By = {
  appointmentNotificationPreference?: InputMaybe<OrderBy>;
  contactPersonId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  rank?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_patientphoto" */
export type PatientPhoto = {
  __typename?: 'patientPhoto';
  active: Scalars['Boolean'];
  /** An object relationship */
  document: Document;
  documentId: Scalars['Int'];
  id: Scalars['Int'];
};

/** aggregated selection of "server_patientphoto" */
export type PatientPhotoAggregate = {
  __typename?: 'patientPhotoAggregate';
  aggregate?: Maybe<PatientPhotoAggregateFields>;
  nodes: Array<PatientPhoto>;
};

/** aggregate fields of "server_patientphoto" */
export type PatientPhotoAggregateFields = {
  __typename?: 'patientPhotoAggregateFields';
  avg?: Maybe<PatientPhotoAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PatientPhotoMaxFields>;
  min?: Maybe<PatientPhotoMinFields>;
  stddev?: Maybe<PatientPhotoStddevFields>;
  stddevPop?: Maybe<PatientPhotoStddev_PopFields>;
  stddevSamp?: Maybe<PatientPhotoStddev_SampFields>;
  sum?: Maybe<PatientPhotoSumFields>;
  varPop?: Maybe<PatientPhotoVar_PopFields>;
  varSamp?: Maybe<PatientPhotoVar_SampFields>;
  variance?: Maybe<PatientPhotoVarianceFields>;
};


/** aggregate fields of "server_patientphoto" */
export type PatientPhotoAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PatientPhotoSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PatientPhotoAvgFields = {
  __typename?: 'patientPhotoAvgFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_patientphoto". All fields are combined with a logical 'AND'. */
export type PatientPhotoBoolExp = {
  _and?: InputMaybe<Array<PatientPhotoBoolExp>>;
  _not?: InputMaybe<PatientPhotoBoolExp>;
  _or?: InputMaybe<Array<PatientPhotoBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  document?: InputMaybe<DocumentBoolExp>;
  documentId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_patientphoto" */
export enum PatientPhotoConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerPatientphotoPkey = 'server_patientphoto_pkey'
}

/** input type for incrementing numeric columns in table "server_patientphoto" */
export type PatientPhotoIncInput = {
  documentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_patientphoto" */
export type PatientPhotoInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  document?: InputMaybe<DocumentObjRelInsertInput>;
  documentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type PatientPhotoMaxFields = {
  __typename?: 'patientPhotoMaxFields';
  documentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type PatientPhotoMinFields = {
  __typename?: 'patientPhotoMinFields';
  documentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_patientphoto" */
export type PatientPhotoMutationResponse = {
  __typename?: 'patientPhotoMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientPhoto>;
};

/** on_conflict condition type for table "server_patientphoto" */
export type PatientPhotoOnConflict = {
  constraint: PatientPhotoConstraint;
  update_columns?: Array<PatientPhotoUpdateColumn>;
  where?: InputMaybe<PatientPhotoBoolExp>;
};

/** Ordering options when selecting data from "server_patientphoto". */
export type PatientPhotoOrderBy = {
  active?: InputMaybe<OrderBy>;
  document?: InputMaybe<DocumentOrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_patientphoto */
export type PatientPhotoPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_patientphoto" */
export enum PatientPhotoSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "server_patientphoto" */
export type PatientPhotoSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  documentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PatientPhotoStddevFields = {
  __typename?: 'patientPhotoStddevFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientPhotoStddev_PopFields = {
  __typename?: 'patientPhotoStddev_popFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientPhotoStddev_SampFields = {
  __typename?: 'patientPhotoStddev_sampFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PatientPhotoSumFields = {
  __typename?: 'patientPhotoSumFields';
  documentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_patientphoto" */
export enum PatientPhotoUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id'
}

export type PatientPhotoUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientPhotoIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientPhotoSetInput>;
  where: PatientPhotoBoolExp;
};

/** aggregate var_pop on columns */
export type PatientPhotoVar_PopFields = {
  __typename?: 'patientPhotoVar_popFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientPhotoVar_SampFields = {
  __typename?: 'patientPhotoVar_sampFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientPhotoVarianceFields = {
  __typename?: 'patientPhotoVarianceFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "patientPhoto" */
export type PatientPhoto_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PatientPhoto_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientPhoto_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  documentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** primary key columns input for table: server_patient */
export type PatientPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PatientPrependInput = {
  externalJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_patient" */
export enum PatientSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AdmittedStatus = 'admittedStatus',
  /** column name */
  City = 'city',
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  Email = 'email',
  /** column name */
  EmergencyContactName = 'emergencyContactName',
  /** column name */
  EmergencyContactPhone = 'emergencyContactPhone',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FollowupMax = 'followupMax',
  /** column name */
  FollowupMin = 'followupMin',
  /** column name */
  Gender = 'gender',
  /** column name */
  HomeAddressId = 'homeAddressId',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  NickName = 'nickName',
  /** column name */
  Notes = 'notes',
  /** column name */
  PracticeGroupId = 'practiceGroupId',
  /** column name */
  PrimaryPhone = 'primaryPhone',
  /** column name */
  SecondaryPhone = 'secondaryPhone',
  /** column name */
  State = 'state',
  /** column name */
  ZipCode = 'zipCode'
}

/** input type for updating data in table "server_patient" */
export type PatientSetInput = {
  address?: InputMaybe<Scalars['String']>;
  admittedStatus?: InputMaybe<Scalars['smallint']>;
  city?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  emergencyContactName?: InputMaybe<Scalars['String']>;
  emergencyContactPhone?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  firstName?: InputMaybe<Scalars['String']>;
  followupMax?: InputMaybe<Scalars['date']>;
  followupMin?: InputMaybe<Scalars['date']>;
  gender?: InputMaybe<Scalars['smallint']>;
  homeAddressId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  middleName?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  primaryPhone?: InputMaybe<Scalars['String']>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "server_patientstatus" */
export type PatientStatus = {
  __typename?: 'patientStatus';
  defaultDaysInEffect?: Maybe<Scalars['smallint']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  onExpiresId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  patientStatusActions: Array<PatientStatusAction>;
  /** An aggregate relationship */
  patientStatusActionsAggregate: PatientStatusActionAggregate;
  requiresProviderAuthorization: Scalars['Boolean'];
  /** An object relationship */
  statusOnExpires?: Maybe<PatientStatus>;
  underCare: Scalars['Boolean'];
};


/** columns and relationships of "server_patientstatus" */
export type PatientStatusPatientStatusActionsArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusActionOrderBy>>;
  where?: InputMaybe<PatientStatusActionBoolExp>;
};


/** columns and relationships of "server_patientstatus" */
export type PatientStatusPatientStatusActionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusActionOrderBy>>;
  where?: InputMaybe<PatientStatusActionBoolExp>;
};

/** columns and relationships of "server_patientstatusaction" */
export type PatientStatusAction = {
  __typename?: 'patientStatusAction';
  /** An object relationship */
  associatedAppointment?: Maybe<Appointment>;
  associatedAppointmentId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  authorizingProvider?: Maybe<Provider>;
  authorizingProviderId?: Maybe<Scalars['Int']>;
  datetimeEffective: Scalars['timestamptz'];
  datetimeEnd?: Maybe<Scalars['timestamptz']>;
  datetimeEntered: Scalars['timestamptz'];
  /** An object relationship */
  enteringUser: User;
  enteringUserId: Scalars['Int'];
  id: Scalars['Int'];
  notes: Scalars['String'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  /** An object relationship */
  status: PatientStatus;
  statusId: Scalars['Int'];
};

/** aggregated selection of "server_patientstatusaction" */
export type PatientStatusActionAggregate = {
  __typename?: 'patientStatusActionAggregate';
  aggregate?: Maybe<PatientStatusActionAggregateFields>;
  nodes: Array<PatientStatusAction>;
};

/** aggregate fields of "server_patientstatusaction" */
export type PatientStatusActionAggregateFields = {
  __typename?: 'patientStatusActionAggregateFields';
  avg?: Maybe<PatientStatusActionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PatientStatusActionMaxFields>;
  min?: Maybe<PatientStatusActionMinFields>;
  stddev?: Maybe<PatientStatusActionStddevFields>;
  stddevPop?: Maybe<PatientStatusActionStddev_PopFields>;
  stddevSamp?: Maybe<PatientStatusActionStddev_SampFields>;
  sum?: Maybe<PatientStatusActionSumFields>;
  varPop?: Maybe<PatientStatusActionVar_PopFields>;
  varSamp?: Maybe<PatientStatusActionVar_SampFields>;
  variance?: Maybe<PatientStatusActionVarianceFields>;
};


/** aggregate fields of "server_patientstatusaction" */
export type PatientStatusActionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PatientStatusActionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_patientstatusaction" */
export type PatientStatusActionAggregateOrderBy = {
  avg?: InputMaybe<PatientStatusAction_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PatientStatusAction_Max_Order_By>;
  min?: InputMaybe<PatientStatusAction_Min_Order_By>;
  stddev?: InputMaybe<PatientStatusAction_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PatientStatusAction_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PatientStatusAction_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PatientStatusAction_Sum_Order_By>;
  var_pop?: InputMaybe<PatientStatusAction_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PatientStatusAction_Var_Samp_Order_By>;
  variance?: InputMaybe<PatientStatusAction_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_patientstatusaction" */
export type PatientStatusActionArrRelInsertInput = {
  data: Array<PatientStatusActionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PatientStatusActionOnConflict>;
};

/** aggregate avg on columns */
export type PatientStatusActionAvgFields = {
  __typename?: 'patientStatusActionAvgFields';
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  authorizingProviderId?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_patientstatusaction". All fields are combined with a logical 'AND'. */
export type PatientStatusActionBoolExp = {
  _and?: InputMaybe<Array<PatientStatusActionBoolExp>>;
  _not?: InputMaybe<PatientStatusActionBoolExp>;
  _or?: InputMaybe<Array<PatientStatusActionBoolExp>>;
  associatedAppointment?: InputMaybe<AppointmentBoolExp>;
  associatedAppointmentId?: InputMaybe<IntComparisonExp>;
  authorizingProvider?: InputMaybe<ProviderBoolExp>;
  authorizingProviderId?: InputMaybe<IntComparisonExp>;
  datetimeEffective?: InputMaybe<TimestamptzComparisonExp>;
  datetimeEnd?: InputMaybe<TimestamptzComparisonExp>;
  datetimeEntered?: InputMaybe<TimestamptzComparisonExp>;
  enteringUser?: InputMaybe<UserBoolExp>;
  enteringUserId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<PatientStatusBoolExp>;
  statusId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_patientstatusaction" */
export enum PatientStatusActionConstraint {
  /** unique or primary key constraint on columns "associated_appointment_id" */
  ServerPatientstatusactiAssociatedAppointmentIB7b3ef3cUniq = 'server_patientstatusacti_associated_appointment_i_b7b3ef3c_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerPatientstatusactionPkey = 'server_patientstatusaction_pkey'
}

/** input type for incrementing numeric columns in table "server_patientstatusaction" */
export type PatientStatusActionIncInput = {
  associatedAppointmentId?: InputMaybe<Scalars['Int']>;
  authorizingProviderId?: InputMaybe<Scalars['Int']>;
  enteringUserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  statusId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_patientstatusaction" */
export type PatientStatusActionInsertInput = {
  associatedAppointment?: InputMaybe<AppointmentObjRelInsertInput>;
  associatedAppointmentId?: InputMaybe<Scalars['Int']>;
  authorizingProvider?: InputMaybe<ProviderObjRelInsertInput>;
  authorizingProviderId?: InputMaybe<Scalars['Int']>;
  datetimeEffective?: InputMaybe<Scalars['timestamptz']>;
  datetimeEnd?: InputMaybe<Scalars['timestamptz']>;
  datetimeEntered?: InputMaybe<Scalars['timestamptz']>;
  enteringUser?: InputMaybe<UserObjRelInsertInput>;
  enteringUserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<PatientStatusObjRelInsertInput>;
  statusId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type PatientStatusActionMaxFields = {
  __typename?: 'patientStatusActionMaxFields';
  associatedAppointmentId?: Maybe<Scalars['Int']>;
  authorizingProviderId?: Maybe<Scalars['Int']>;
  datetimeEffective?: Maybe<Scalars['timestamptz']>;
  datetimeEnd?: Maybe<Scalars['timestamptz']>;
  datetimeEntered?: Maybe<Scalars['timestamptz']>;
  enteringUserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  statusId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type PatientStatusActionMinFields = {
  __typename?: 'patientStatusActionMinFields';
  associatedAppointmentId?: Maybe<Scalars['Int']>;
  authorizingProviderId?: Maybe<Scalars['Int']>;
  datetimeEffective?: Maybe<Scalars['timestamptz']>;
  datetimeEnd?: Maybe<Scalars['timestamptz']>;
  datetimeEntered?: Maybe<Scalars['timestamptz']>;
  enteringUserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  statusId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_patientstatusaction" */
export type PatientStatusActionMutationResponse = {
  __typename?: 'patientStatusActionMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientStatusAction>;
};

/** input type for inserting object relation for remote table "server_patientstatusaction" */
export type PatientStatusActionObjRelInsertInput = {
  data: PatientStatusActionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PatientStatusActionOnConflict>;
};

/** on_conflict condition type for table "server_patientstatusaction" */
export type PatientStatusActionOnConflict = {
  constraint: PatientStatusActionConstraint;
  update_columns?: Array<PatientStatusActionUpdateColumn>;
  where?: InputMaybe<PatientStatusActionBoolExp>;
};

/** Ordering options when selecting data from "server_patientstatusaction". */
export type PatientStatusActionOrderBy = {
  associatedAppointment?: InputMaybe<AppointmentOrderBy>;
  associatedAppointmentId?: InputMaybe<OrderBy>;
  authorizingProvider?: InputMaybe<ProviderOrderBy>;
  authorizingProviderId?: InputMaybe<OrderBy>;
  datetimeEffective?: InputMaybe<OrderBy>;
  datetimeEnd?: InputMaybe<OrderBy>;
  datetimeEntered?: InputMaybe<OrderBy>;
  enteringUser?: InputMaybe<UserOrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  status?: InputMaybe<PatientStatusOrderBy>;
  statusId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_patientstatusaction */
export type PatientStatusActionPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_patientstatusaction" */
export enum PatientStatusActionSelectColumn {
  /** column name */
  AssociatedAppointmentId = 'associatedAppointmentId',
  /** column name */
  AuthorizingProviderId = 'authorizingProviderId',
  /** column name */
  DatetimeEffective = 'datetimeEffective',
  /** column name */
  DatetimeEnd = 'datetimeEnd',
  /** column name */
  DatetimeEntered = 'datetimeEntered',
  /** column name */
  EnteringUserId = 'enteringUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  StatusId = 'statusId'
}

/** input type for updating data in table "server_patientstatusaction" */
export type PatientStatusActionSetInput = {
  associatedAppointmentId?: InputMaybe<Scalars['Int']>;
  authorizingProviderId?: InputMaybe<Scalars['Int']>;
  datetimeEffective?: InputMaybe<Scalars['timestamptz']>;
  datetimeEnd?: InputMaybe<Scalars['timestamptz']>;
  datetimeEntered?: InputMaybe<Scalars['timestamptz']>;
  enteringUserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
  statusId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PatientStatusActionStddevFields = {
  __typename?: 'patientStatusActionStddevFields';
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  authorizingProviderId?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientStatusActionStddev_PopFields = {
  __typename?: 'patientStatusActionStddev_popFields';
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  authorizingProviderId?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientStatusActionStddev_SampFields = {
  __typename?: 'patientStatusActionStddev_sampFields';
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  authorizingProviderId?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PatientStatusActionSumFields = {
  __typename?: 'patientStatusActionSumFields';
  associatedAppointmentId?: Maybe<Scalars['Int']>;
  authorizingProviderId?: Maybe<Scalars['Int']>;
  enteringUserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  statusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_patientstatusaction" */
export enum PatientStatusActionUpdateColumn {
  /** column name */
  AssociatedAppointmentId = 'associatedAppointmentId',
  /** column name */
  AuthorizingProviderId = 'authorizingProviderId',
  /** column name */
  DatetimeEffective = 'datetimeEffective',
  /** column name */
  DatetimeEnd = 'datetimeEnd',
  /** column name */
  DatetimeEntered = 'datetimeEntered',
  /** column name */
  EnteringUserId = 'enteringUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  StatusId = 'statusId'
}

export type PatientStatusActionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientStatusActionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientStatusActionSetInput>;
  where: PatientStatusActionBoolExp;
};

/** aggregate var_pop on columns */
export type PatientStatusActionVar_PopFields = {
  __typename?: 'patientStatusActionVar_popFields';
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  authorizingProviderId?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientStatusActionVar_SampFields = {
  __typename?: 'patientStatusActionVar_sampFields';
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  authorizingProviderId?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientStatusActionVarianceFields = {
  __typename?: 'patientStatusActionVarianceFields';
  associatedAppointmentId?: Maybe<Scalars['Float']>;
  authorizingProviderId?: Maybe<Scalars['Float']>;
  enteringUserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_patientstatusaction" */
export type PatientStatusAction_Avg_Order_By = {
  associatedAppointmentId?: InputMaybe<OrderBy>;
  authorizingProviderId?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_patientstatusaction" */
export type PatientStatusAction_Max_Order_By = {
  associatedAppointmentId?: InputMaybe<OrderBy>;
  authorizingProviderId?: InputMaybe<OrderBy>;
  datetimeEffective?: InputMaybe<OrderBy>;
  datetimeEnd?: InputMaybe<OrderBy>;
  datetimeEntered?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_patientstatusaction" */
export type PatientStatusAction_Min_Order_By = {
  associatedAppointmentId?: InputMaybe<OrderBy>;
  authorizingProviderId?: InputMaybe<OrderBy>;
  datetimeEffective?: InputMaybe<OrderBy>;
  datetimeEnd?: InputMaybe<OrderBy>;
  datetimeEntered?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_patientstatusaction" */
export type PatientStatusAction_Stddev_Order_By = {
  associatedAppointmentId?: InputMaybe<OrderBy>;
  authorizingProviderId?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_patientstatusaction" */
export type PatientStatusAction_Stddev_Pop_Order_By = {
  associatedAppointmentId?: InputMaybe<OrderBy>;
  authorizingProviderId?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_patientstatusaction" */
export type PatientStatusAction_Stddev_Samp_Order_By = {
  associatedAppointmentId?: InputMaybe<OrderBy>;
  authorizingProviderId?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "patientStatusAction" */
export type PatientStatusAction_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PatientStatusAction_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientStatusAction_StreamCursorValueInput = {
  associatedAppointmentId?: InputMaybe<Scalars['Int']>;
  authorizingProviderId?: InputMaybe<Scalars['Int']>;
  datetimeEffective?: InputMaybe<Scalars['timestamptz']>;
  datetimeEnd?: InputMaybe<Scalars['timestamptz']>;
  datetimeEntered?: InputMaybe<Scalars['timestamptz']>;
  enteringUserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
  statusId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_patientstatusaction" */
export type PatientStatusAction_Sum_Order_By = {
  associatedAppointmentId?: InputMaybe<OrderBy>;
  authorizingProviderId?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_patientstatusaction" */
export type PatientStatusAction_Var_Pop_Order_By = {
  associatedAppointmentId?: InputMaybe<OrderBy>;
  authorizingProviderId?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_patientstatusaction" */
export type PatientStatusAction_Var_Samp_Order_By = {
  associatedAppointmentId?: InputMaybe<OrderBy>;
  authorizingProviderId?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_patientstatusaction" */
export type PatientStatusAction_Variance_Order_By = {
  associatedAppointmentId?: InputMaybe<OrderBy>;
  authorizingProviderId?: InputMaybe<OrderBy>;
  enteringUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
};

/** aggregated selection of "server_patientstatus" */
export type PatientStatusAggregate = {
  __typename?: 'patientStatusAggregate';
  aggregate?: Maybe<PatientStatusAggregateFields>;
  nodes: Array<PatientStatus>;
};

/** aggregate fields of "server_patientstatus" */
export type PatientStatusAggregateFields = {
  __typename?: 'patientStatusAggregateFields';
  avg?: Maybe<PatientStatusAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PatientStatusMaxFields>;
  min?: Maybe<PatientStatusMinFields>;
  stddev?: Maybe<PatientStatusStddevFields>;
  stddevPop?: Maybe<PatientStatusStddev_PopFields>;
  stddevSamp?: Maybe<PatientStatusStddev_SampFields>;
  sum?: Maybe<PatientStatusSumFields>;
  varPop?: Maybe<PatientStatusVar_PopFields>;
  varSamp?: Maybe<PatientStatusVar_SampFields>;
  variance?: Maybe<PatientStatusVarianceFields>;
};


/** aggregate fields of "server_patientstatus" */
export type PatientStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PatientStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PatientStatusAvgFields = {
  __typename?: 'patientStatusAvgFields';
  defaultDaysInEffect?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onExpiresId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_patientstatus". All fields are combined with a logical 'AND'. */
export type PatientStatusBoolExp = {
  _and?: InputMaybe<Array<PatientStatusBoolExp>>;
  _not?: InputMaybe<PatientStatusBoolExp>;
  _or?: InputMaybe<Array<PatientStatusBoolExp>>;
  defaultDaysInEffect?: InputMaybe<SmallintComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  onExpiresId?: InputMaybe<IntComparisonExp>;
  patientStatusActions?: InputMaybe<PatientStatusActionBoolExp>;
  requiresProviderAuthorization?: InputMaybe<BooleanComparisonExp>;
  statusOnExpires?: InputMaybe<PatientStatusBoolExp>;
  underCare?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "server_patientstatus" */
export enum PatientStatusConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerPatientstatusPkey = 'server_patientstatus_pkey'
}

/** input type for incrementing numeric columns in table "server_patientstatus" */
export type PatientStatusIncInput = {
  defaultDaysInEffect?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  onExpiresId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_patientstatus" */
export type PatientStatusInsertInput = {
  defaultDaysInEffect?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  onExpiresId?: InputMaybe<Scalars['Int']>;
  patientStatusActions?: InputMaybe<PatientStatusActionArrRelInsertInput>;
  requiresProviderAuthorization?: InputMaybe<Scalars['Boolean']>;
  statusOnExpires?: InputMaybe<PatientStatusObjRelInsertInput>;
  underCare?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type PatientStatusMaxFields = {
  __typename?: 'patientStatusMaxFields';
  defaultDaysInEffect?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  onExpiresId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type PatientStatusMinFields = {
  __typename?: 'patientStatusMinFields';
  defaultDaysInEffect?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  onExpiresId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_patientstatus" */
export type PatientStatusMutationResponse = {
  __typename?: 'patientStatusMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientStatus>;
};

/** input type for inserting object relation for remote table "server_patientstatus" */
export type PatientStatusObjRelInsertInput = {
  data: PatientStatusInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PatientStatusOnConflict>;
};

/** on_conflict condition type for table "server_patientstatus" */
export type PatientStatusOnConflict = {
  constraint: PatientStatusConstraint;
  update_columns?: Array<PatientStatusUpdateColumn>;
  where?: InputMaybe<PatientStatusBoolExp>;
};

/** Ordering options when selecting data from "server_patientstatus". */
export type PatientStatusOrderBy = {
  defaultDaysInEffect?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  onExpiresId?: InputMaybe<OrderBy>;
  patientStatusActionsAggregate?: InputMaybe<PatientStatusActionAggregateOrderBy>;
  requiresProviderAuthorization?: InputMaybe<OrderBy>;
  statusOnExpires?: InputMaybe<PatientStatusOrderBy>;
  underCare?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_patientstatus */
export type PatientStatusPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_patientstatus" */
export enum PatientStatusSelectColumn {
  /** column name */
  DefaultDaysInEffect = 'defaultDaysInEffect',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OnExpiresId = 'onExpiresId',
  /** column name */
  RequiresProviderAuthorization = 'requiresProviderAuthorization',
  /** column name */
  UnderCare = 'underCare'
}

/** input type for updating data in table "server_patientstatus" */
export type PatientStatusSetInput = {
  defaultDaysInEffect?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  onExpiresId?: InputMaybe<Scalars['Int']>;
  requiresProviderAuthorization?: InputMaybe<Scalars['Boolean']>;
  underCare?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type PatientStatusStddevFields = {
  __typename?: 'patientStatusStddevFields';
  defaultDaysInEffect?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onExpiresId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientStatusStddev_PopFields = {
  __typename?: 'patientStatusStddev_popFields';
  defaultDaysInEffect?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onExpiresId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientStatusStddev_SampFields = {
  __typename?: 'patientStatusStddev_sampFields';
  defaultDaysInEffect?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onExpiresId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "server_patientstatus_subsequent_statuses" */
export type PatientStatusSubsequentStatuses = {
  __typename?: 'patientStatusSubsequentStatuses';
  fromPatientstatusId: Scalars['Int'];
  /** An object relationship */
  fromStatus: PatientStatus;
  id: Scalars['Int'];
  toPatientstatusId: Scalars['Int'];
  /** An object relationship */
  toStatus: PatientStatus;
};

/** aggregated selection of "server_patientstatus_subsequent_statuses" */
export type PatientStatusSubsequentStatusesAggregate = {
  __typename?: 'patientStatusSubsequentStatusesAggregate';
  aggregate?: Maybe<PatientStatusSubsequentStatusesAggregateFields>;
  nodes: Array<PatientStatusSubsequentStatuses>;
};

/** aggregate fields of "server_patientstatus_subsequent_statuses" */
export type PatientStatusSubsequentStatusesAggregateFields = {
  __typename?: 'patientStatusSubsequentStatusesAggregateFields';
  avg?: Maybe<PatientStatusSubsequentStatusesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PatientStatusSubsequentStatusesMaxFields>;
  min?: Maybe<PatientStatusSubsequentStatusesMinFields>;
  stddev?: Maybe<PatientStatusSubsequentStatusesStddevFields>;
  stddevPop?: Maybe<PatientStatusSubsequentStatusesStddev_PopFields>;
  stddevSamp?: Maybe<PatientStatusSubsequentStatusesStddev_SampFields>;
  sum?: Maybe<PatientStatusSubsequentStatusesSumFields>;
  varPop?: Maybe<PatientStatusSubsequentStatusesVar_PopFields>;
  varSamp?: Maybe<PatientStatusSubsequentStatusesVar_SampFields>;
  variance?: Maybe<PatientStatusSubsequentStatusesVarianceFields>;
};


/** aggregate fields of "server_patientstatus_subsequent_statuses" */
export type PatientStatusSubsequentStatusesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PatientStatusSubsequentStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PatientStatusSubsequentStatusesAvgFields = {
  __typename?: 'patientStatusSubsequentStatusesAvgFields';
  fromPatientstatusId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  toPatientstatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_patientstatus_subsequent_statuses". All fields are combined with a logical 'AND'. */
export type PatientStatusSubsequentStatusesBoolExp = {
  _and?: InputMaybe<Array<PatientStatusSubsequentStatusesBoolExp>>;
  _not?: InputMaybe<PatientStatusSubsequentStatusesBoolExp>;
  _or?: InputMaybe<Array<PatientStatusSubsequentStatusesBoolExp>>;
  fromPatientstatusId?: InputMaybe<IntComparisonExp>;
  fromStatus?: InputMaybe<PatientStatusBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  toPatientstatusId?: InputMaybe<IntComparisonExp>;
  toStatus?: InputMaybe<PatientStatusBoolExp>;
};

/** unique or primary key constraints on table "server_patientstatus_subsequent_statuses" */
export enum PatientStatusSubsequentStatusesConstraint {
  /** unique or primary key constraint on columns "from_patientstatus_id", "to_patientstatus_id" */
  ServerPatientstatusSubFromPatientstatusIdToA083046bUniq = 'server_patientstatus_sub_from_patientstatus_id_to_a083046b_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerPatientstatusSubsequentStatusesPkey = 'server_patientstatus_subsequent_statuses_pkey'
}

/** input type for incrementing numeric columns in table "server_patientstatus_subsequent_statuses" */
export type PatientStatusSubsequentStatusesIncInput = {
  fromPatientstatusId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  toPatientstatusId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_patientstatus_subsequent_statuses" */
export type PatientStatusSubsequentStatusesInsertInput = {
  fromPatientstatusId?: InputMaybe<Scalars['Int']>;
  fromStatus?: InputMaybe<PatientStatusObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  toPatientstatusId?: InputMaybe<Scalars['Int']>;
  toStatus?: InputMaybe<PatientStatusObjRelInsertInput>;
};

/** aggregate max on columns */
export type PatientStatusSubsequentStatusesMaxFields = {
  __typename?: 'patientStatusSubsequentStatusesMaxFields';
  fromPatientstatusId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  toPatientstatusId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type PatientStatusSubsequentStatusesMinFields = {
  __typename?: 'patientStatusSubsequentStatusesMinFields';
  fromPatientstatusId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  toPatientstatusId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_patientstatus_subsequent_statuses" */
export type PatientStatusSubsequentStatusesMutationResponse = {
  __typename?: 'patientStatusSubsequentStatusesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PatientStatusSubsequentStatuses>;
};

/** on_conflict condition type for table "server_patientstatus_subsequent_statuses" */
export type PatientStatusSubsequentStatusesOnConflict = {
  constraint: PatientStatusSubsequentStatusesConstraint;
  update_columns?: Array<PatientStatusSubsequentStatusesUpdateColumn>;
  where?: InputMaybe<PatientStatusSubsequentStatusesBoolExp>;
};

/** Ordering options when selecting data from "server_patientstatus_subsequent_statuses". */
export type PatientStatusSubsequentStatusesOrderBy = {
  fromPatientstatusId?: InputMaybe<OrderBy>;
  fromStatus?: InputMaybe<PatientStatusOrderBy>;
  id?: InputMaybe<OrderBy>;
  toPatientstatusId?: InputMaybe<OrderBy>;
  toStatus?: InputMaybe<PatientStatusOrderBy>;
};

/** primary key columns input for table: server_patientstatus_subsequent_statuses */
export type PatientStatusSubsequentStatusesPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_patientstatus_subsequent_statuses" */
export enum PatientStatusSubsequentStatusesSelectColumn {
  /** column name */
  FromPatientstatusId = 'fromPatientstatusId',
  /** column name */
  Id = 'id',
  /** column name */
  ToPatientstatusId = 'toPatientstatusId'
}

/** input type for updating data in table "server_patientstatus_subsequent_statuses" */
export type PatientStatusSubsequentStatusesSetInput = {
  fromPatientstatusId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  toPatientstatusId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PatientStatusSubsequentStatusesStddevFields = {
  __typename?: 'patientStatusSubsequentStatusesStddevFields';
  fromPatientstatusId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  toPatientstatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientStatusSubsequentStatusesStddev_PopFields = {
  __typename?: 'patientStatusSubsequentStatusesStddev_popFields';
  fromPatientstatusId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  toPatientstatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientStatusSubsequentStatusesStddev_SampFields = {
  __typename?: 'patientStatusSubsequentStatusesStddev_sampFields';
  fromPatientstatusId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  toPatientstatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PatientStatusSubsequentStatusesSumFields = {
  __typename?: 'patientStatusSubsequentStatusesSumFields';
  fromPatientstatusId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  toPatientstatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_patientstatus_subsequent_statuses" */
export enum PatientStatusSubsequentStatusesUpdateColumn {
  /** column name */
  FromPatientstatusId = 'fromPatientstatusId',
  /** column name */
  Id = 'id',
  /** column name */
  ToPatientstatusId = 'toPatientstatusId'
}

export type PatientStatusSubsequentStatusesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientStatusSubsequentStatusesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientStatusSubsequentStatusesSetInput>;
  where: PatientStatusSubsequentStatusesBoolExp;
};

/** aggregate var_pop on columns */
export type PatientStatusSubsequentStatusesVar_PopFields = {
  __typename?: 'patientStatusSubsequentStatusesVar_popFields';
  fromPatientstatusId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  toPatientstatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientStatusSubsequentStatusesVar_SampFields = {
  __typename?: 'patientStatusSubsequentStatusesVar_sampFields';
  fromPatientstatusId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  toPatientstatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientStatusSubsequentStatusesVarianceFields = {
  __typename?: 'patientStatusSubsequentStatusesVarianceFields';
  fromPatientstatusId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  toPatientstatusId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "patientStatusSubsequentStatuses" */
export type PatientStatusSubsequentStatuses_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PatientStatusSubsequentStatuses_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientStatusSubsequentStatuses_StreamCursorValueInput = {
  fromPatientstatusId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  toPatientstatusId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type PatientStatusSumFields = {
  __typename?: 'patientStatusSumFields';
  defaultDaysInEffect?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
  onExpiresId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_patientstatus" */
export enum PatientStatusUpdateColumn {
  /** column name */
  DefaultDaysInEffect = 'defaultDaysInEffect',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OnExpiresId = 'onExpiresId',
  /** column name */
  RequiresProviderAuthorization = 'requiresProviderAuthorization',
  /** column name */
  UnderCare = 'underCare'
}

export type PatientStatusUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientStatusIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientStatusSetInput>;
  where: PatientStatusBoolExp;
};

/** aggregate var_pop on columns */
export type PatientStatusVar_PopFields = {
  __typename?: 'patientStatusVar_popFields';
  defaultDaysInEffect?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onExpiresId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientStatusVar_SampFields = {
  __typename?: 'patientStatusVar_sampFields';
  defaultDaysInEffect?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onExpiresId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientStatusVarianceFields = {
  __typename?: 'patientStatusVarianceFields';
  defaultDaysInEffect?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onExpiresId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "patientStatus" */
export type PatientStatus_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PatientStatus_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PatientStatus_StreamCursorValueInput = {
  defaultDaysInEffect?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  onExpiresId?: InputMaybe<Scalars['Int']>;
  requiresProviderAuthorization?: InputMaybe<Scalars['Boolean']>;
  underCare?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type PatientStddevFields = {
  __typename?: 'patientStddevFields';
  admittedStatus?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  gender?: Maybe<Scalars['Float']>;
  homeAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PatientStddev_PopFields = {
  __typename?: 'patientStddev_popFields';
  admittedStatus?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  gender?: Maybe<Scalars['Float']>;
  homeAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PatientStddev_SampFields = {
  __typename?: 'patientStddev_sampFields';
  admittedStatus?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  gender?: Maybe<Scalars['Float']>;
  homeAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PatientSumFields = {
  __typename?: 'patientSumFields';
  admittedStatus?: Maybe<Scalars['smallint']>;
  externalId?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['smallint']>;
  homeAddressId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['numeric']>;
  longitude?: Maybe<Scalars['numeric']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_patient" */
export enum PatientUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AdmittedStatus = 'admittedStatus',
  /** column name */
  City = 'city',
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  Email = 'email',
  /** column name */
  EmergencyContactName = 'emergencyContactName',
  /** column name */
  EmergencyContactPhone = 'emergencyContactPhone',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  ExternalJson = 'externalJson',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FollowupMax = 'followupMax',
  /** column name */
  FollowupMin = 'followupMin',
  /** column name */
  Gender = 'gender',
  /** column name */
  HomeAddressId = 'homeAddressId',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  NickName = 'nickName',
  /** column name */
  Notes = 'notes',
  /** column name */
  PracticeGroupId = 'practiceGroupId',
  /** column name */
  PrimaryPhone = 'primaryPhone',
  /** column name */
  SecondaryPhone = 'secondaryPhone',
  /** column name */
  State = 'state',
  /** column name */
  ZipCode = 'zipCode'
}

export type PatientUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PatientAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<PatientDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<PatientDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<PatientDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PatientIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PatientPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PatientSetInput>;
  where: PatientBoolExp;
};

/** aggregate var_pop on columns */
export type PatientVar_PopFields = {
  __typename?: 'patientVar_popFields';
  admittedStatus?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  gender?: Maybe<Scalars['Float']>;
  homeAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PatientVar_SampFields = {
  __typename?: 'patientVar_sampFields';
  admittedStatus?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  gender?: Maybe<Scalars['Float']>;
  homeAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PatientVarianceFields = {
  __typename?: 'patientVarianceFields';
  admittedStatus?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  gender?: Maybe<Scalars['Float']>;
  homeAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_patient" */
export type Patient_Avg_Order_By = {
  admittedStatus?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  homeAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_patient" */
export type Patient_Max_Order_By = {
  address?: InputMaybe<OrderBy>;
  admittedStatus?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  dateOfBirth?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  emergencyContactName?: InputMaybe<OrderBy>;
  emergencyContactPhone?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  followupMax?: InputMaybe<OrderBy>;
  followupMin?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  homeAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  middleName?: InputMaybe<OrderBy>;
  nickName?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  primaryPhone?: InputMaybe<OrderBy>;
  secondaryPhone?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_patient" */
export type Patient_Min_Order_By = {
  address?: InputMaybe<OrderBy>;
  admittedStatus?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  dateOfBirth?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  emergencyContactName?: InputMaybe<OrderBy>;
  emergencyContactPhone?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  followupMax?: InputMaybe<OrderBy>;
  followupMin?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  homeAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  middleName?: InputMaybe<OrderBy>;
  nickName?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  primaryPhone?: InputMaybe<OrderBy>;
  secondaryPhone?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_patient" */
export type Patient_Stddev_Order_By = {
  admittedStatus?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  homeAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_patient" */
export type Patient_Stddev_Pop_Order_By = {
  admittedStatus?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  homeAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_patient" */
export type Patient_Stddev_Samp_Order_By = {
  admittedStatus?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  homeAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "patient" */
export type Patient_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Patient_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_StreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']>;
  admittedStatus?: InputMaybe<Scalars['smallint']>;
  city?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  emergencyContactName?: InputMaybe<Scalars['String']>;
  emergencyContactPhone?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['Int']>;
  externalJson?: InputMaybe<Scalars['jsonb']>;
  firstName?: InputMaybe<Scalars['String']>;
  followupMax?: InputMaybe<Scalars['date']>;
  followupMin?: InputMaybe<Scalars['date']>;
  gender?: InputMaybe<Scalars['smallint']>;
  homeAddressId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  middleName?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  primaryPhone?: InputMaybe<Scalars['String']>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_patient" */
export type Patient_Sum_Order_By = {
  admittedStatus?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  homeAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_patient" */
export type Patient_Var_Pop_Order_By = {
  admittedStatus?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  homeAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_patient" */
export type Patient_Var_Samp_Order_By = {
  admittedStatus?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  homeAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_patient" */
export type Patient_Variance_Order_By = {
  admittedStatus?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  homeAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_payer" */
export type Payer = {
  __typename?: 'payer';
  /** An array relationship */
  agencies: Array<AgencyPayers>;
  /** An aggregate relationship */
  agenciesAggregate: AgencyPayersAggregate;
  drchronoId?: Maybe<Scalars['String']>;
  drchronoName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  insurancePlans: Array<Insuranceplan>;
  /** An aggregate relationship */
  insurancePlansAggregate: InsuranceplanAggregate;
  name: Scalars['String'];
  /** An array relationship */
  orderableSuppliers: Array<OrderableSupplierPayerConnections>;
  /** An aggregate relationship */
  orderableSuppliersAggregate: OrderableSupplierPayerConnectionsAggregate;
};


/** columns and relationships of "server_payer" */
export type PayerAgenciesArgs = {
  distinctOn?: InputMaybe<Array<AgencyPayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyPayersOrderBy>>;
  where?: InputMaybe<AgencyPayersBoolExp>;
};


/** columns and relationships of "server_payer" */
export type PayerAgenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyPayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyPayersOrderBy>>;
  where?: InputMaybe<AgencyPayersBoolExp>;
};


/** columns and relationships of "server_payer" */
export type PayerInsurancePlansArgs = {
  distinctOn?: InputMaybe<Array<InsuranceplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceplanOrderBy>>;
  where?: InputMaybe<InsuranceplanBoolExp>;
};


/** columns and relationships of "server_payer" */
export type PayerInsurancePlansAggregateArgs = {
  distinctOn?: InputMaybe<Array<InsuranceplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceplanOrderBy>>;
  where?: InputMaybe<InsuranceplanBoolExp>;
};


/** columns and relationships of "server_payer" */
export type PayerOrderableSuppliersArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierPayerConnectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierPayerConnectionsOrderBy>>;
  where?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
};


/** columns and relationships of "server_payer" */
export type PayerOrderableSuppliersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierPayerConnectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierPayerConnectionsOrderBy>>;
  where?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
};

/** aggregated selection of "server_payer" */
export type PayerAggregate = {
  __typename?: 'payerAggregate';
  aggregate?: Maybe<PayerAggregateFields>;
  nodes: Array<Payer>;
};

/** aggregate fields of "server_payer" */
export type PayerAggregateFields = {
  __typename?: 'payerAggregateFields';
  avg?: Maybe<PayerAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PayerMaxFields>;
  min?: Maybe<PayerMinFields>;
  stddev?: Maybe<PayerStddevFields>;
  stddevPop?: Maybe<PayerStddev_PopFields>;
  stddevSamp?: Maybe<PayerStddev_SampFields>;
  sum?: Maybe<PayerSumFields>;
  varPop?: Maybe<PayerVar_PopFields>;
  varSamp?: Maybe<PayerVar_SampFields>;
  variance?: Maybe<PayerVarianceFields>;
};


/** aggregate fields of "server_payer" */
export type PayerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PayerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PayerAvgFields = {
  __typename?: 'payerAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_payer". All fields are combined with a logical 'AND'. */
export type PayerBoolExp = {
  _and?: InputMaybe<Array<PayerBoolExp>>;
  _not?: InputMaybe<PayerBoolExp>;
  _or?: InputMaybe<Array<PayerBoolExp>>;
  agencies?: InputMaybe<AgencyPayersBoolExp>;
  drchronoId?: InputMaybe<StringComparisonExp>;
  drchronoName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  insurancePlans?: InputMaybe<InsuranceplanBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  orderableSuppliers?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
};

/** unique or primary key constraints on table "server_payer" */
export enum PayerConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerPayerPkey = 'server_payer_pkey'
}

/** input type for incrementing numeric columns in table "server_payer" */
export type PayerIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_payer" */
export type PayerInsertInput = {
  agencies?: InputMaybe<AgencyPayersArrRelInsertInput>;
  drchronoId?: InputMaybe<Scalars['String']>;
  drchronoName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  insurancePlans?: InputMaybe<InsuranceplanArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  orderableSuppliers?: InputMaybe<OrderableSupplierPayerConnectionsArrRelInsertInput>;
};

/** aggregate max on columns */
export type PayerMaxFields = {
  __typename?: 'payerMaxFields';
  drchronoId?: Maybe<Scalars['String']>;
  drchronoName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PayerMinFields = {
  __typename?: 'payerMinFields';
  drchronoId?: Maybe<Scalars['String']>;
  drchronoName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_payer" */
export type PayerMutationResponse = {
  __typename?: 'payerMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payer>;
};

/** input type for inserting object relation for remote table "server_payer" */
export type PayerObjRelInsertInput = {
  data: PayerInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PayerOnConflict>;
};

/** on_conflict condition type for table "server_payer" */
export type PayerOnConflict = {
  constraint: PayerConstraint;
  update_columns?: Array<PayerUpdateColumn>;
  where?: InputMaybe<PayerBoolExp>;
};

/** Ordering options when selecting data from "server_payer". */
export type PayerOrderBy = {
  agenciesAggregate?: InputMaybe<AgencyPayersAggregateOrderBy>;
  drchronoId?: InputMaybe<OrderBy>;
  drchronoName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePlansAggregate?: InputMaybe<InsuranceplanAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  orderableSuppliersAggregate?: InputMaybe<OrderableSupplierPayerConnectionsAggregateOrderBy>;
};

/** primary key columns input for table: server_payer */
export type PayerPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_payer" */
export enum PayerSelectColumn {
  /** column name */
  DrchronoId = 'drchronoId',
  /** column name */
  DrchronoName = 'drchronoName',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "server_payer" */
export type PayerSetInput = {
  drchronoId?: InputMaybe<Scalars['String']>;
  drchronoName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type PayerStddevFields = {
  __typename?: 'payerStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PayerStddev_PopFields = {
  __typename?: 'payerStddev_popFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PayerStddev_SampFields = {
  __typename?: 'payerStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PayerSumFields = {
  __typename?: 'payerSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_payer" */
export enum PayerUpdateColumn {
  /** column name */
  DrchronoId = 'drchronoId',
  /** column name */
  DrchronoName = 'drchronoName',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type PayerUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PayerIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PayerSetInput>;
  where: PayerBoolExp;
};

/** aggregate var_pop on columns */
export type PayerVar_PopFields = {
  __typename?: 'payerVar_popFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PayerVar_SampFields = {
  __typename?: 'payerVar_sampFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PayerVarianceFields = {
  __typename?: 'payerVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "payer" */
export type Payer_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Payer_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Payer_StreamCursorValueInput = {
  drchronoId?: InputMaybe<Scalars['String']>;
  drchronoName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "server_practicegroup" */
export type Practicegroup = {
  __typename?: 'practicegroup';
  address?: Maybe<Scalars['String']>;
  /** An array relationship */
  assessments: Array<Assessment>;
  /** An aggregate relationship */
  assessmentsAggregate: AssessmentAggregate;
  city?: Maybe<Scalars['String']>;
  /** An array relationship */
  clinicalNoteTemplate: Array<ClinicalnoteTemplate>;
  /** An aggregate relationship */
  clinicalNoteTemplateAggregate: ClinicalnoteTemplateAggregate;
  /** An array relationship */
  consents: Array<Consent>;
  /** An aggregate relationship */
  consentsAggregate: ConsentAggregate;
  /** An object relationship */
  defaultLabAgency?: Maybe<Agency>;
  defaultLabId?: Maybe<Scalars['Int']>;
  defaultPlaceofservice: Scalars['smallint'];
  /** An object relationship */
  defaultRadiologyAgency?: Maybe<Agency>;
  defaultRadiologyId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  documentsPendingReview: Array<DocumentPendingReview>;
  /** An aggregate relationship */
  documentsPendingReviewAggregate: DocumentPendingReviewAggregate;
  externalId?: Maybe<Scalars['Int']>;
  fax?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  integrations: Array<Integration>;
  /** An aggregate relationship */
  integrationsAggregate: IntegrationAggregate;
  /** An array relationship */
  internalStaff: Array<Internalstaff>;
  /** An aggregate relationship */
  internalStaffAggregate: InternalstaffAggregate;
  labAppend?: Maybe<Scalars['String']>;
  /** An object relationship */
  medicalDirector?: Maybe<User>;
  medicalDirectorId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  /** An array relationship */
  patientIntakes: Array<PatientIntake>;
  /** An aggregate relationship */
  patientIntakesAggregate: PatientIntakeAggregate;
  /** An array relationship */
  patients: Array<Patient>;
  /** An aggregate relationship */
  patientsAggregate: PatientAggregate;
  phone?: Maybe<Scalars['String']>;
  radiologyAppend?: Maybe<Scalars['String']>;
  /** An array relationship */
  recordsRequestDestinations: Array<RecordsRequestDestination>;
  /** An aggregate relationship */
  recordsRequestDestinationsAggregate: RecordsRequestDestinationAggregate;
  state?: Maybe<Scalars['String']>;
  /** An array relationship */
  threadCategories: Array<ThreadCategory>;
  /** An aggregate relationship */
  threadCategoriesAggregate: ThreadCategoryAggregate;
  /** An array relationship */
  ticketThreads: Array<TicketThread>;
  /** An aggregate relationship */
  ticketThreadsAggregate: TicketThreadAggregate;
  zipCode?: Maybe<Scalars['String']>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupAssessmentsArgs = {
  distinctOn?: InputMaybe<Array<AssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentOrderBy>>;
  where?: InputMaybe<AssessmentBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupAssessmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentOrderBy>>;
  where?: InputMaybe<AssessmentBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupClinicalNoteTemplateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupClinicalNoteTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupConsentsArgs = {
  distinctOn?: InputMaybe<Array<ConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConsentOrderBy>>;
  where?: InputMaybe<ConsentBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupConsentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConsentOrderBy>>;
  where?: InputMaybe<ConsentBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupDocumentsPendingReviewArgs = {
  distinctOn?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentPendingReviewOrderBy>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupDocumentsPendingReviewAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentPendingReviewOrderBy>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupIntegrationsArgs = {
  distinctOn?: InputMaybe<Array<IntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationOrderBy>>;
  where?: InputMaybe<IntegrationBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupIntegrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<IntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationOrderBy>>;
  where?: InputMaybe<IntegrationBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupInternalStaffArgs = {
  distinctOn?: InputMaybe<Array<InternalstaffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InternalstaffOrderBy>>;
  where?: InputMaybe<InternalstaffBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupInternalStaffAggregateArgs = {
  distinctOn?: InputMaybe<Array<InternalstaffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InternalstaffOrderBy>>;
  where?: InputMaybe<InternalstaffBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupPatientIntakesArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeOrderBy>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupPatientIntakesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeOrderBy>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupPatientsArgs = {
  distinctOn?: InputMaybe<Array<PatientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientOrderBy>>;
  where?: InputMaybe<PatientBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupPatientsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientOrderBy>>;
  where?: InputMaybe<PatientBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupRecordsRequestDestinationsArgs = {
  distinctOn?: InputMaybe<Array<RecordsRequestDestinationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordsRequestDestinationOrderBy>>;
  where?: InputMaybe<RecordsRequestDestinationBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupRecordsRequestDestinationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<RecordsRequestDestinationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordsRequestDestinationOrderBy>>;
  where?: InputMaybe<RecordsRequestDestinationBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupThreadCategoriesArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryOrderBy>>;
  where?: InputMaybe<ThreadCategoryBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupThreadCategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryOrderBy>>;
  where?: InputMaybe<ThreadCategoryBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupTicketThreadsArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};


/** columns and relationships of "server_practicegroup" */
export type PracticegroupTicketThreadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};

/** aggregated selection of "server_practicegroup" */
export type PracticegroupAggregate = {
  __typename?: 'practicegroupAggregate';
  aggregate?: Maybe<PracticegroupAggregateFields>;
  nodes: Array<Practicegroup>;
};

/** aggregate fields of "server_practicegroup" */
export type PracticegroupAggregateFields = {
  __typename?: 'practicegroupAggregateFields';
  avg?: Maybe<PracticegroupAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PracticegroupMaxFields>;
  min?: Maybe<PracticegroupMinFields>;
  stddev?: Maybe<PracticegroupStddevFields>;
  stddevPop?: Maybe<PracticegroupStddev_PopFields>;
  stddevSamp?: Maybe<PracticegroupStddev_SampFields>;
  sum?: Maybe<PracticegroupSumFields>;
  varPop?: Maybe<PracticegroupVar_PopFields>;
  varSamp?: Maybe<PracticegroupVar_SampFields>;
  variance?: Maybe<PracticegroupVarianceFields>;
};


/** aggregate fields of "server_practicegroup" */
export type PracticegroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PracticegroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_practicegroup" */
export type PracticegroupAggregateOrderBy = {
  avg?: InputMaybe<Practicegroup_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Practicegroup_Max_Order_By>;
  min?: InputMaybe<Practicegroup_Min_Order_By>;
  stddev?: InputMaybe<Practicegroup_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Practicegroup_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Practicegroup_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Practicegroup_Sum_Order_By>;
  var_pop?: InputMaybe<Practicegroup_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Practicegroup_Var_Samp_Order_By>;
  variance?: InputMaybe<Practicegroup_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_practicegroup" */
export type PracticegroupArrRelInsertInput = {
  data: Array<PracticegroupInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PracticegroupOnConflict>;
};

/** aggregate avg on columns */
export type PracticegroupAvgFields = {
  __typename?: 'practicegroupAvgFields';
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultPlaceofservice?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medicalDirectorId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_practicegroup". All fields are combined with a logical 'AND'. */
export type PracticegroupBoolExp = {
  _and?: InputMaybe<Array<PracticegroupBoolExp>>;
  _not?: InputMaybe<PracticegroupBoolExp>;
  _or?: InputMaybe<Array<PracticegroupBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  assessments?: InputMaybe<AssessmentBoolExp>;
  city?: InputMaybe<StringComparisonExp>;
  clinicalNoteTemplate?: InputMaybe<ClinicalnoteTemplateBoolExp>;
  consents?: InputMaybe<ConsentBoolExp>;
  defaultLabAgency?: InputMaybe<AgencyBoolExp>;
  defaultLabId?: InputMaybe<IntComparisonExp>;
  defaultPlaceofservice?: InputMaybe<SmallintComparisonExp>;
  defaultRadiologyAgency?: InputMaybe<AgencyBoolExp>;
  defaultRadiologyId?: InputMaybe<IntComparisonExp>;
  documentsPendingReview?: InputMaybe<DocumentPendingReviewBoolExp>;
  externalId?: InputMaybe<IntComparisonExp>;
  fax?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  integrations?: InputMaybe<IntegrationBoolExp>;
  internalStaff?: InputMaybe<InternalstaffBoolExp>;
  labAppend?: InputMaybe<StringComparisonExp>;
  medicalDirector?: InputMaybe<UserBoolExp>;
  medicalDirectorId?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  patientIntakes?: InputMaybe<PatientIntakeBoolExp>;
  patients?: InputMaybe<PatientBoolExp>;
  phone?: InputMaybe<StringComparisonExp>;
  radiologyAppend?: InputMaybe<StringComparisonExp>;
  recordsRequestDestinations?: InputMaybe<RecordsRequestDestinationBoolExp>;
  state?: InputMaybe<StringComparisonExp>;
  threadCategories?: InputMaybe<ThreadCategoryBoolExp>;
  ticketThreads?: InputMaybe<TicketThreadBoolExp>;
  zipCode?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_practicegroup" */
export enum PracticegroupConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerPracticegroupPkey = 'server_practicegroup_pkey'
}

/** input type for incrementing numeric columns in table "server_practicegroup" */
export type PracticegroupIncInput = {
  defaultLabId?: InputMaybe<Scalars['Int']>;
  defaultPlaceofservice?: InputMaybe<Scalars['smallint']>;
  defaultRadiologyId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  medicalDirectorId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_practicegroup" */
export type PracticegroupInsertInput = {
  address?: InputMaybe<Scalars['String']>;
  assessments?: InputMaybe<AssessmentArrRelInsertInput>;
  city?: InputMaybe<Scalars['String']>;
  clinicalNoteTemplate?: InputMaybe<ClinicalnoteTemplateArrRelInsertInput>;
  consents?: InputMaybe<ConsentArrRelInsertInput>;
  defaultLabAgency?: InputMaybe<AgencyObjRelInsertInput>;
  defaultLabId?: InputMaybe<Scalars['Int']>;
  defaultPlaceofservice?: InputMaybe<Scalars['smallint']>;
  defaultRadiologyAgency?: InputMaybe<AgencyObjRelInsertInput>;
  defaultRadiologyId?: InputMaybe<Scalars['Int']>;
  documentsPendingReview?: InputMaybe<DocumentPendingReviewArrRelInsertInput>;
  externalId?: InputMaybe<Scalars['Int']>;
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  integrations?: InputMaybe<IntegrationArrRelInsertInput>;
  internalStaff?: InputMaybe<InternalstaffArrRelInsertInput>;
  labAppend?: InputMaybe<Scalars['String']>;
  medicalDirector?: InputMaybe<UserObjRelInsertInput>;
  medicalDirectorId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  patientIntakes?: InputMaybe<PatientIntakeArrRelInsertInput>;
  patients?: InputMaybe<PatientArrRelInsertInput>;
  phone?: InputMaybe<Scalars['String']>;
  radiologyAppend?: InputMaybe<Scalars['String']>;
  recordsRequestDestinations?: InputMaybe<RecordsRequestDestinationArrRelInsertInput>;
  state?: InputMaybe<Scalars['String']>;
  threadCategories?: InputMaybe<ThreadCategoryArrRelInsertInput>;
  ticketThreads?: InputMaybe<TicketThreadArrRelInsertInput>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PracticegroupMaxFields = {
  __typename?: 'practicegroupMaxFields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  defaultLabId?: Maybe<Scalars['Int']>;
  defaultPlaceofservice?: Maybe<Scalars['smallint']>;
  defaultRadiologyId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  labAppend?: Maybe<Scalars['String']>;
  medicalDirectorId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  radiologyAppend?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PracticegroupMinFields = {
  __typename?: 'practicegroupMinFields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  defaultLabId?: Maybe<Scalars['Int']>;
  defaultPlaceofservice?: Maybe<Scalars['smallint']>;
  defaultRadiologyId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  labAppend?: Maybe<Scalars['String']>;
  medicalDirectorId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  radiologyAppend?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_practicegroup" */
export type PracticegroupMutationResponse = {
  __typename?: 'practicegroupMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Practicegroup>;
};

/** input type for inserting object relation for remote table "server_practicegroup" */
export type PracticegroupObjRelInsertInput = {
  data: PracticegroupInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PracticegroupOnConflict>;
};

/** on_conflict condition type for table "server_practicegroup" */
export type PracticegroupOnConflict = {
  constraint: PracticegroupConstraint;
  update_columns?: Array<PracticegroupUpdateColumn>;
  where?: InputMaybe<PracticegroupBoolExp>;
};

/** Ordering options when selecting data from "server_practicegroup". */
export type PracticegroupOrderBy = {
  address?: InputMaybe<OrderBy>;
  assessmentsAggregate?: InputMaybe<AssessmentAggregateOrderBy>;
  city?: InputMaybe<OrderBy>;
  clinicalNoteTemplateAggregate?: InputMaybe<ClinicalnoteTemplateAggregateOrderBy>;
  consentsAggregate?: InputMaybe<ConsentAggregateOrderBy>;
  defaultLabAgency?: InputMaybe<AgencyOrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultPlaceofservice?: InputMaybe<OrderBy>;
  defaultRadiologyAgency?: InputMaybe<AgencyOrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  documentsPendingReviewAggregate?: InputMaybe<DocumentPendingReviewAggregateOrderBy>;
  externalId?: InputMaybe<OrderBy>;
  fax?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  integrationsAggregate?: InputMaybe<IntegrationAggregateOrderBy>;
  internalStaffAggregate?: InputMaybe<InternalstaffAggregateOrderBy>;
  labAppend?: InputMaybe<OrderBy>;
  medicalDirector?: InputMaybe<UserOrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  patientIntakesAggregate?: InputMaybe<PatientIntakeAggregateOrderBy>;
  patientsAggregate?: InputMaybe<PatientAggregateOrderBy>;
  phone?: InputMaybe<OrderBy>;
  radiologyAppend?: InputMaybe<OrderBy>;
  recordsRequestDestinationsAggregate?: InputMaybe<RecordsRequestDestinationAggregateOrderBy>;
  state?: InputMaybe<OrderBy>;
  threadCategoriesAggregate?: InputMaybe<ThreadCategoryAggregateOrderBy>;
  ticketThreadsAggregate?: InputMaybe<TicketThreadAggregateOrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_practicegroup */
export type PracticegroupPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_practicegroup" */
export enum PracticegroupSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  DefaultLabId = 'defaultLabId',
  /** column name */
  DefaultPlaceofservice = 'defaultPlaceofservice',
  /** column name */
  DefaultRadiologyId = 'defaultRadiologyId',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Fax = 'fax',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  LabAppend = 'labAppend',
  /** column name */
  MedicalDirectorId = 'medicalDirectorId',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  RadiologyAppend = 'radiologyAppend',
  /** column name */
  State = 'state',
  /** column name */
  ZipCode = 'zipCode'
}

/** input type for updating data in table "server_practicegroup" */
export type PracticegroupSetInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  defaultLabId?: InputMaybe<Scalars['Int']>;
  defaultPlaceofservice?: InputMaybe<Scalars['smallint']>;
  defaultRadiologyId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['Int']>;
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  labAppend?: InputMaybe<Scalars['String']>;
  medicalDirectorId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  radiologyAppend?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type PracticegroupStddevFields = {
  __typename?: 'practicegroupStddevFields';
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultPlaceofservice?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medicalDirectorId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PracticegroupStddev_PopFields = {
  __typename?: 'practicegroupStddev_popFields';
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultPlaceofservice?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medicalDirectorId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PracticegroupStddev_SampFields = {
  __typename?: 'practicegroupStddev_sampFields';
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultPlaceofservice?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medicalDirectorId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PracticegroupSumFields = {
  __typename?: 'practicegroupSumFields';
  defaultLabId?: Maybe<Scalars['Int']>;
  defaultPlaceofservice?: Maybe<Scalars['smallint']>;
  defaultRadiologyId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  medicalDirectorId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_practicegroup" */
export enum PracticegroupUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  DefaultLabId = 'defaultLabId',
  /** column name */
  DefaultPlaceofservice = 'defaultPlaceofservice',
  /** column name */
  DefaultRadiologyId = 'defaultRadiologyId',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Fax = 'fax',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  LabAppend = 'labAppend',
  /** column name */
  MedicalDirectorId = 'medicalDirectorId',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  RadiologyAppend = 'radiologyAppend',
  /** column name */
  State = 'state',
  /** column name */
  ZipCode = 'zipCode'
}

export type PracticegroupUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PracticegroupIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PracticegroupSetInput>;
  where: PracticegroupBoolExp;
};

/** aggregate var_pop on columns */
export type PracticegroupVar_PopFields = {
  __typename?: 'practicegroupVar_popFields';
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultPlaceofservice?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medicalDirectorId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PracticegroupVar_SampFields = {
  __typename?: 'practicegroupVar_sampFields';
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultPlaceofservice?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medicalDirectorId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PracticegroupVarianceFields = {
  __typename?: 'practicegroupVarianceFields';
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultPlaceofservice?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  medicalDirectorId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_practicegroup" */
export type Practicegroup_Avg_Order_By = {
  defaultLabId?: InputMaybe<OrderBy>;
  defaultPlaceofservice?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_practicegroup" */
export type Practicegroup_Max_Order_By = {
  address?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultPlaceofservice?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  fax?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  labAppend?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  radiologyAppend?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_practicegroup" */
export type Practicegroup_Min_Order_By = {
  address?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultPlaceofservice?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  fax?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  labAppend?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  radiologyAppend?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_practicegroup" */
export type Practicegroup_Stddev_Order_By = {
  defaultLabId?: InputMaybe<OrderBy>;
  defaultPlaceofservice?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_practicegroup" */
export type Practicegroup_Stddev_Pop_Order_By = {
  defaultLabId?: InputMaybe<OrderBy>;
  defaultPlaceofservice?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_practicegroup" */
export type Practicegroup_Stddev_Samp_Order_By = {
  defaultLabId?: InputMaybe<OrderBy>;
  defaultPlaceofservice?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "practicegroup" */
export type Practicegroup_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Practicegroup_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Practicegroup_StreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  defaultLabId?: InputMaybe<Scalars['Int']>;
  defaultPlaceofservice?: InputMaybe<Scalars['smallint']>;
  defaultRadiologyId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['Int']>;
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  labAppend?: InputMaybe<Scalars['String']>;
  medicalDirectorId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  radiologyAppend?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_practicegroup" */
export type Practicegroup_Sum_Order_By = {
  defaultLabId?: InputMaybe<OrderBy>;
  defaultPlaceofservice?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_practicegroup" */
export type Practicegroup_Var_Pop_Order_By = {
  defaultLabId?: InputMaybe<OrderBy>;
  defaultPlaceofservice?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_practicegroup" */
export type Practicegroup_Var_Samp_Order_By = {
  defaultLabId?: InputMaybe<OrderBy>;
  defaultPlaceofservice?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_practicegroup" */
export type Practicegroup_Variance_Order_By = {
  defaultLabId?: InputMaybe<OrderBy>;
  defaultPlaceofservice?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_problem" */
export type Problem = {
  __typename?: 'problem';
  active: Scalars['Boolean'];
  /** An array relationship */
  caretimes: Array<CaretimeProblems>;
  /** An aggregate relationship */
  caretimesAggregate: CaretimeProblemsAggregate;
  dateOnset: Scalars['date'];
  dateResolved?: Maybe<Scalars['date']>;
  icd10: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  /** An array relationship */
  plans: Array<ProblemPlan>;
  /** An aggregate relationship */
  plansAggregate: ProblemPlanAggregate;
  title: Scalars['String'];
  /** An array relationship */
  treatmentPlans: Array<TreatmentplanProblems>;
  /** An aggregate relationship */
  treatmentPlansAggregate: TreatmentplanProblemsAggregate;
};


/** columns and relationships of "server_problem" */
export type ProblemCaretimesArgs = {
  distinctOn?: InputMaybe<Array<CaretimeProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeProblemsOrderBy>>;
  where?: InputMaybe<CaretimeProblemsBoolExp>;
};


/** columns and relationships of "server_problem" */
export type ProblemCaretimesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CaretimeProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeProblemsOrderBy>>;
  where?: InputMaybe<CaretimeProblemsBoolExp>;
};


/** columns and relationships of "server_problem" */
export type ProblemPlansArgs = {
  distinctOn?: InputMaybe<Array<ProblemPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemPlanOrderBy>>;
  where?: InputMaybe<ProblemPlanBoolExp>;
};


/** columns and relationships of "server_problem" */
export type ProblemPlansAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProblemPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemPlanOrderBy>>;
  where?: InputMaybe<ProblemPlanBoolExp>;
};


/** columns and relationships of "server_problem" */
export type ProblemTreatmentPlansArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanProblemsOrderBy>>;
  where?: InputMaybe<TreatmentplanProblemsBoolExp>;
};


/** columns and relationships of "server_problem" */
export type ProblemTreatmentPlansAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanProblemsOrderBy>>;
  where?: InputMaybe<TreatmentplanProblemsBoolExp>;
};

/** aggregated selection of "server_problem" */
export type ProblemAggregate = {
  __typename?: 'problemAggregate';
  aggregate?: Maybe<ProblemAggregateFields>;
  nodes: Array<Problem>;
};

/** aggregate fields of "server_problem" */
export type ProblemAggregateFields = {
  __typename?: 'problemAggregateFields';
  avg?: Maybe<ProblemAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProblemMaxFields>;
  min?: Maybe<ProblemMinFields>;
  stddev?: Maybe<ProblemStddevFields>;
  stddevPop?: Maybe<ProblemStddev_PopFields>;
  stddevSamp?: Maybe<ProblemStddev_SampFields>;
  sum?: Maybe<ProblemSumFields>;
  varPop?: Maybe<ProblemVar_PopFields>;
  varSamp?: Maybe<ProblemVar_SampFields>;
  variance?: Maybe<ProblemVarianceFields>;
};


/** aggregate fields of "server_problem" */
export type ProblemAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProblemSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_problem" */
export type ProblemAggregateOrderBy = {
  avg?: InputMaybe<Problem_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Problem_Max_Order_By>;
  min?: InputMaybe<Problem_Min_Order_By>;
  stddev?: InputMaybe<Problem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Problem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Problem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Problem_Sum_Order_By>;
  var_pop?: InputMaybe<Problem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Problem_Var_Samp_Order_By>;
  variance?: InputMaybe<Problem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_problem" */
export type ProblemArrRelInsertInput = {
  data: Array<ProblemInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProblemOnConflict>;
};

/** aggregate avg on columns */
export type ProblemAvgFields = {
  __typename?: 'problemAvgFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_problem". All fields are combined with a logical 'AND'. */
export type ProblemBoolExp = {
  _and?: InputMaybe<Array<ProblemBoolExp>>;
  _not?: InputMaybe<ProblemBoolExp>;
  _or?: InputMaybe<Array<ProblemBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  caretimes?: InputMaybe<CaretimeProblemsBoolExp>;
  dateOnset?: InputMaybe<DateComparisonExp>;
  dateResolved?: InputMaybe<DateComparisonExp>;
  icd10?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  plans?: InputMaybe<ProblemPlanBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  treatmentPlans?: InputMaybe<TreatmentplanProblemsBoolExp>;
};

/** unique or primary key constraints on table "server_problem" */
export enum ProblemConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerProblemPkey = 'server_problem_pkey'
}

/** input type for incrementing numeric columns in table "server_problem" */
export type ProblemIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_problem" */
export type ProblemInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  caretimes?: InputMaybe<CaretimeProblemsArrRelInsertInput>;
  dateOnset?: InputMaybe<Scalars['date']>;
  dateResolved?: InputMaybe<Scalars['date']>;
  icd10?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  plans?: InputMaybe<ProblemPlanArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']>;
  treatmentPlans?: InputMaybe<TreatmentplanProblemsArrRelInsertInput>;
};

/** aggregate max on columns */
export type ProblemMaxFields = {
  __typename?: 'problemMaxFields';
  dateOnset?: Maybe<Scalars['date']>;
  dateResolved?: Maybe<Scalars['date']>;
  icd10?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProblemMinFields = {
  __typename?: 'problemMinFields';
  dateOnset?: Maybe<Scalars['date']>;
  dateResolved?: Maybe<Scalars['date']>;
  icd10?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_problem" */
export type ProblemMutationResponse = {
  __typename?: 'problemMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Problem>;
};

/** input type for inserting object relation for remote table "server_problem" */
export type ProblemObjRelInsertInput = {
  data: ProblemInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProblemOnConflict>;
};

/** on_conflict condition type for table "server_problem" */
export type ProblemOnConflict = {
  constraint: ProblemConstraint;
  update_columns?: Array<ProblemUpdateColumn>;
  where?: InputMaybe<ProblemBoolExp>;
};

/** Ordering options when selecting data from "server_problem". */
export type ProblemOrderBy = {
  active?: InputMaybe<OrderBy>;
  caretimesAggregate?: InputMaybe<CaretimeProblemsAggregateOrderBy>;
  dateOnset?: InputMaybe<OrderBy>;
  dateResolved?: InputMaybe<OrderBy>;
  icd10?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  plansAggregate?: InputMaybe<ProblemPlanAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
  treatmentPlansAggregate?: InputMaybe<TreatmentplanProblemsAggregateOrderBy>;
};

/** primary key columns input for table: server_problem */
export type ProblemPkColumnsInput = {
  id: Scalars['Int'];
};

/** columns and relationships of "server_problemplan" */
export type ProblemPlan = {
  __typename?: 'problemPlan';
  clinicalNoteId: Scalars['Int'];
  /** An object relationship */
  clinicalnote: Clinicalnote;
  id: Scalars['Int'];
  plan: Scalars['String'];
  /** An object relationship */
  problem: Problem;
  problemId: Scalars['Int'];
};

/** aggregated selection of "server_problemplan" */
export type ProblemPlanAggregate = {
  __typename?: 'problemPlanAggregate';
  aggregate?: Maybe<ProblemPlanAggregateFields>;
  nodes: Array<ProblemPlan>;
};

/** aggregate fields of "server_problemplan" */
export type ProblemPlanAggregateFields = {
  __typename?: 'problemPlanAggregateFields';
  avg?: Maybe<ProblemPlanAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProblemPlanMaxFields>;
  min?: Maybe<ProblemPlanMinFields>;
  stddev?: Maybe<ProblemPlanStddevFields>;
  stddevPop?: Maybe<ProblemPlanStddev_PopFields>;
  stddevSamp?: Maybe<ProblemPlanStddev_SampFields>;
  sum?: Maybe<ProblemPlanSumFields>;
  varPop?: Maybe<ProblemPlanVar_PopFields>;
  varSamp?: Maybe<ProblemPlanVar_SampFields>;
  variance?: Maybe<ProblemPlanVarianceFields>;
};


/** aggregate fields of "server_problemplan" */
export type ProblemPlanAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProblemPlanSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_problemplan" */
export type ProblemPlanAggregateOrderBy = {
  avg?: InputMaybe<ProblemPlan_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProblemPlan_Max_Order_By>;
  min?: InputMaybe<ProblemPlan_Min_Order_By>;
  stddev?: InputMaybe<ProblemPlan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ProblemPlan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ProblemPlan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ProblemPlan_Sum_Order_By>;
  var_pop?: InputMaybe<ProblemPlan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ProblemPlan_Var_Samp_Order_By>;
  variance?: InputMaybe<ProblemPlan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_problemplan" */
export type ProblemPlanArrRelInsertInput = {
  data: Array<ProblemPlanInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProblemPlanOnConflict>;
};

/** aggregate avg on columns */
export type ProblemPlanAvgFields = {
  __typename?: 'problemPlanAvgFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_problemplan". All fields are combined with a logical 'AND'. */
export type ProblemPlanBoolExp = {
  _and?: InputMaybe<Array<ProblemPlanBoolExp>>;
  _not?: InputMaybe<ProblemPlanBoolExp>;
  _or?: InputMaybe<Array<ProblemPlanBoolExp>>;
  clinicalNoteId?: InputMaybe<IntComparisonExp>;
  clinicalnote?: InputMaybe<ClinicalnoteBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  plan?: InputMaybe<StringComparisonExp>;
  problem?: InputMaybe<ProblemBoolExp>;
  problemId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_problemplan" */
export enum ProblemPlanConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerProblemplanPkey = 'server_problemplan_pkey'
}

/** input type for incrementing numeric columns in table "server_problemplan" */
export type ProblemPlanIncInput = {
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  problemId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_problemplan" */
export type ProblemPlanInsertInput = {
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  clinicalnote?: InputMaybe<ClinicalnoteObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  plan?: InputMaybe<Scalars['String']>;
  problem?: InputMaybe<ProblemObjRelInsertInput>;
  problemId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProblemPlanMaxFields = {
  __typename?: 'problemPlanMaxFields';
  clinicalNoteId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  plan?: Maybe<Scalars['String']>;
  problemId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ProblemPlanMinFields = {
  __typename?: 'problemPlanMinFields';
  clinicalNoteId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  plan?: Maybe<Scalars['String']>;
  problemId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_problemplan" */
export type ProblemPlanMutationResponse = {
  __typename?: 'problemPlanMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProblemPlan>;
};

/** on_conflict condition type for table "server_problemplan" */
export type ProblemPlanOnConflict = {
  constraint: ProblemPlanConstraint;
  update_columns?: Array<ProblemPlanUpdateColumn>;
  where?: InputMaybe<ProblemPlanBoolExp>;
};

/** Ordering options when selecting data from "server_problemplan". */
export type ProblemPlanOrderBy = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  clinicalnote?: InputMaybe<ClinicalnoteOrderBy>;
  id?: InputMaybe<OrderBy>;
  plan?: InputMaybe<OrderBy>;
  problem?: InputMaybe<ProblemOrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_problemplan */
export type ProblemPlanPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_problemplan" */
export enum ProblemPlanSelectColumn {
  /** column name */
  ClinicalNoteId = 'clinicalNoteId',
  /** column name */
  Id = 'id',
  /** column name */
  Plan = 'plan',
  /** column name */
  ProblemId = 'problemId'
}

/** input type for updating data in table "server_problemplan" */
export type ProblemPlanSetInput = {
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  plan?: InputMaybe<Scalars['String']>;
  problemId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProblemPlanStddevFields = {
  __typename?: 'problemPlanStddevFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProblemPlanStddev_PopFields = {
  __typename?: 'problemPlanStddev_popFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProblemPlanStddev_SampFields = {
  __typename?: 'problemPlanStddev_sampFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProblemPlanSumFields = {
  __typename?: 'problemPlanSumFields';
  clinicalNoteId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  problemId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_problemplan" */
export enum ProblemPlanUpdateColumn {
  /** column name */
  ClinicalNoteId = 'clinicalNoteId',
  /** column name */
  Id = 'id',
  /** column name */
  Plan = 'plan',
  /** column name */
  ProblemId = 'problemId'
}

export type ProblemPlanUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProblemPlanIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProblemPlanSetInput>;
  where: ProblemPlanBoolExp;
};

/** aggregate var_pop on columns */
export type ProblemPlanVar_PopFields = {
  __typename?: 'problemPlanVar_popFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProblemPlanVar_SampFields = {
  __typename?: 'problemPlanVar_sampFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProblemPlanVarianceFields = {
  __typename?: 'problemPlanVarianceFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_problemplan" */
export type ProblemPlan_Avg_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_problemplan" */
export type ProblemPlan_Max_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  plan?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_problemplan" */
export type ProblemPlan_Min_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  plan?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_problemplan" */
export type ProblemPlan_Stddev_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_problemplan" */
export type ProblemPlan_Stddev_Pop_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_problemplan" */
export type ProblemPlan_Stddev_Samp_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "problemPlan" */
export type ProblemPlan_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProblemPlan_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProblemPlan_StreamCursorValueInput = {
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  plan?: InputMaybe<Scalars['String']>;
  problemId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_problemplan" */
export type ProblemPlan_Sum_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_problemplan" */
export type ProblemPlan_Var_Pop_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_problemplan" */
export type ProblemPlan_Var_Samp_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_problemplan" */
export type ProblemPlan_Variance_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
};

/** select columns of table "server_problem" */
export enum ProblemSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DateOnset = 'dateOnset',
  /** column name */
  DateResolved = 'dateResolved',
  /** column name */
  Icd10 = 'icd10',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "server_problem" */
export type ProblemSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  dateOnset?: InputMaybe<Scalars['date']>;
  dateResolved?: InputMaybe<Scalars['date']>;
  icd10?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProblemStddevFields = {
  __typename?: 'problemStddevFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProblemStddev_PopFields = {
  __typename?: 'problemStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProblemStddev_SampFields = {
  __typename?: 'problemStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProblemSumFields = {
  __typename?: 'problemSumFields';
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_problem" */
export enum ProblemUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  DateOnset = 'dateOnset',
  /** column name */
  DateResolved = 'dateResolved',
  /** column name */
  Icd10 = 'icd10',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Title = 'title'
}

export type ProblemUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProblemIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProblemSetInput>;
  where: ProblemBoolExp;
};

/** aggregate var_pop on columns */
export type ProblemVar_PopFields = {
  __typename?: 'problemVar_popFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProblemVar_SampFields = {
  __typename?: 'problemVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProblemVarianceFields = {
  __typename?: 'problemVarianceFields';
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_problem" */
export type Problem_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_problem" */
export type Problem_Max_Order_By = {
  dateOnset?: InputMaybe<OrderBy>;
  dateResolved?: InputMaybe<OrderBy>;
  icd10?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_problem" */
export type Problem_Min_Order_By = {
  dateOnset?: InputMaybe<OrderBy>;
  dateResolved?: InputMaybe<OrderBy>;
  icd10?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_problem" */
export type Problem_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_problem" */
export type Problem_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_problem" */
export type Problem_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "problem" */
export type Problem_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Problem_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Problem_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  dateOnset?: InputMaybe<Scalars['date']>;
  dateResolved?: InputMaybe<Scalars['date']>;
  icd10?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_problem" */
export type Problem_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_problem" */
export type Problem_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_problem" */
export type Problem_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_problem" */
export type Problem_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_procedure" */
export type Procedure = {
  __typename?: 'procedure';
  /** An array relationship */
  clinicalnoteTemplates: Array<ClinicalnoteTemplate>;
  /** An aggregate relationship */
  clinicalnoteTemplatesAggregate: ClinicalnoteTemplateAggregate;
  /** An object relationship */
  codeset?: Maybe<Codeset>;
  codesetId?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  /** An array relationship */
  hcpcs: Array<ProcedureHcpcs>;
  /** An aggregate relationship */
  hcpcsAggregate: ProcedureHcpcsAggregate;
  id: Scalars['Int'];
  /** An array relationship */
  renderedProcedures: Array<ProcedureRendered>;
  /** An aggregate relationship */
  renderedProceduresAggregate: ProcedureRenderedAggregate;
  title: Scalars['String'];
};


/** columns and relationships of "server_procedure" */
export type ProcedureClinicalnoteTemplatesArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateBoolExp>;
};


/** columns and relationships of "server_procedure" */
export type ProcedureClinicalnoteTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateBoolExp>;
};


/** columns and relationships of "server_procedure" */
export type ProcedureHcpcsArgs = {
  distinctOn?: InputMaybe<Array<ProcedureHcpcsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureHcpcsOrderBy>>;
  where?: InputMaybe<ProcedureHcpcsBoolExp>;
};


/** columns and relationships of "server_procedure" */
export type ProcedureHcpcsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcedureHcpcsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureHcpcsOrderBy>>;
  where?: InputMaybe<ProcedureHcpcsBoolExp>;
};


/** columns and relationships of "server_procedure" */
export type ProcedureRenderedProceduresArgs = {
  distinctOn?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureRenderedOrderBy>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};


/** columns and relationships of "server_procedure" */
export type ProcedureRenderedProceduresAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureRenderedOrderBy>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};

/** aggregated selection of "server_procedure" */
export type ProcedureAggregate = {
  __typename?: 'procedureAggregate';
  aggregate?: Maybe<ProcedureAggregateFields>;
  nodes: Array<Procedure>;
};

/** aggregate fields of "server_procedure" */
export type ProcedureAggregateFields = {
  __typename?: 'procedureAggregateFields';
  avg?: Maybe<ProcedureAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProcedureMaxFields>;
  min?: Maybe<ProcedureMinFields>;
  stddev?: Maybe<ProcedureStddevFields>;
  stddevPop?: Maybe<ProcedureStddev_PopFields>;
  stddevSamp?: Maybe<ProcedureStddev_SampFields>;
  sum?: Maybe<ProcedureSumFields>;
  varPop?: Maybe<ProcedureVar_PopFields>;
  varSamp?: Maybe<ProcedureVar_SampFields>;
  variance?: Maybe<ProcedureVarianceFields>;
};


/** aggregate fields of "server_procedure" */
export type ProcedureAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProcedureSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProcedureAvgFields = {
  __typename?: 'procedureAvgFields';
  codesetId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_procedure". All fields are combined with a logical 'AND'. */
export type ProcedureBoolExp = {
  _and?: InputMaybe<Array<ProcedureBoolExp>>;
  _not?: InputMaybe<ProcedureBoolExp>;
  _or?: InputMaybe<Array<ProcedureBoolExp>>;
  clinicalnoteTemplates?: InputMaybe<ClinicalnoteTemplateBoolExp>;
  codeset?: InputMaybe<CodesetBoolExp>;
  codesetId?: InputMaybe<IntComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  hcpcs?: InputMaybe<ProcedureHcpcsBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  renderedProcedures?: InputMaybe<ProcedureRenderedBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_procedure" */
export enum ProcedureConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerProcedurePkey = 'server_procedure_pkey'
}

/** columns and relationships of "server_procedure_hcpcs" */
export type ProcedureHcpcs = {
  __typename?: 'procedureHCPCS';
  /** An object relationship */
  hcpcs: Hcpcs;
  hcpcsId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  procedure: Procedure;
  procedureId: Scalars['Int'];
};

/** aggregated selection of "server_procedure_hcpcs" */
export type ProcedureHcpcsAggregate = {
  __typename?: 'procedureHCPCSAggregate';
  aggregate?: Maybe<ProcedureHcpcsAggregateFields>;
  nodes: Array<ProcedureHcpcs>;
};

/** aggregate fields of "server_procedure_hcpcs" */
export type ProcedureHcpcsAggregateFields = {
  __typename?: 'procedureHCPCSAggregateFields';
  avg?: Maybe<ProcedureHcpcsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProcedureHcpcsMaxFields>;
  min?: Maybe<ProcedureHcpcsMinFields>;
  stddev?: Maybe<ProcedureHcpcsStddevFields>;
  stddevPop?: Maybe<ProcedureHcpcsStddev_PopFields>;
  stddevSamp?: Maybe<ProcedureHcpcsStddev_SampFields>;
  sum?: Maybe<ProcedureHcpcsSumFields>;
  varPop?: Maybe<ProcedureHcpcsVar_PopFields>;
  varSamp?: Maybe<ProcedureHcpcsVar_SampFields>;
  variance?: Maybe<ProcedureHcpcsVarianceFields>;
};


/** aggregate fields of "server_procedure_hcpcs" */
export type ProcedureHcpcsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProcedureHcpcsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_procedure_hcpcs" */
export type ProcedureHcpcsAggregateOrderBy = {
  avg?: InputMaybe<ProcedureHcpcs_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProcedureHcpcs_Max_Order_By>;
  min?: InputMaybe<ProcedureHcpcs_Min_Order_By>;
  stddev?: InputMaybe<ProcedureHcpcs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ProcedureHcpcs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ProcedureHcpcs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ProcedureHcpcs_Sum_Order_By>;
  var_pop?: InputMaybe<ProcedureHcpcs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ProcedureHcpcs_Var_Samp_Order_By>;
  variance?: InputMaybe<ProcedureHcpcs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_procedure_hcpcs" */
export type ProcedureHcpcsArrRelInsertInput = {
  data: Array<ProcedureHcpcsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProcedureHcpcsOnConflict>;
};

/** aggregate avg on columns */
export type ProcedureHcpcsAvgFields = {
  __typename?: 'procedureHCPCSAvgFields';
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_procedure_hcpcs". All fields are combined with a logical 'AND'. */
export type ProcedureHcpcsBoolExp = {
  _and?: InputMaybe<Array<ProcedureHcpcsBoolExp>>;
  _not?: InputMaybe<ProcedureHcpcsBoolExp>;
  _or?: InputMaybe<Array<ProcedureHcpcsBoolExp>>;
  hcpcs?: InputMaybe<HcpcsBoolExp>;
  hcpcsId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  procedure?: InputMaybe<ProcedureBoolExp>;
  procedureId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_procedure_hcpcs" */
export enum ProcedureHcpcsConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerProcedureHcpcsPkey = 'server_procedure_hcpcs_pkey',
  /** unique or primary key constraint on columns "hcpcs_id", "procedure_id" */
  ServerProcedureHcpcsProcedureIdHcpcsIdEed947afUniq = 'server_procedure_hcpcs_procedure_id_hcpcs_id_eed947af_uniq'
}

/** input type for incrementing numeric columns in table "server_procedure_hcpcs" */
export type ProcedureHcpcsIncInput = {
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  procedureId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_procedure_hcpcs" */
export type ProcedureHcpcsInsertInput = {
  hcpcs?: InputMaybe<HcpcsObjRelInsertInput>;
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  procedure?: InputMaybe<ProcedureObjRelInsertInput>;
  procedureId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProcedureHcpcsMaxFields = {
  __typename?: 'procedureHCPCSMaxFields';
  hcpcsId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  procedureId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ProcedureHcpcsMinFields = {
  __typename?: 'procedureHCPCSMinFields';
  hcpcsId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  procedureId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_procedure_hcpcs" */
export type ProcedureHcpcsMutationResponse = {
  __typename?: 'procedureHCPCSMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProcedureHcpcs>;
};

/** on_conflict condition type for table "server_procedure_hcpcs" */
export type ProcedureHcpcsOnConflict = {
  constraint: ProcedureHcpcsConstraint;
  update_columns?: Array<ProcedureHcpcsUpdateColumn>;
  where?: InputMaybe<ProcedureHcpcsBoolExp>;
};

/** Ordering options when selecting data from "server_procedure_hcpcs". */
export type ProcedureHcpcsOrderBy = {
  hcpcs?: InputMaybe<HcpcsOrderBy>;
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  procedure?: InputMaybe<ProcedureOrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_procedure_hcpcs */
export type ProcedureHcpcsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_procedure_hcpcs" */
export enum ProcedureHcpcsSelectColumn {
  /** column name */
  HcpcsId = 'hcpcsId',
  /** column name */
  Id = 'id',
  /** column name */
  ProcedureId = 'procedureId'
}

/** input type for updating data in table "server_procedure_hcpcs" */
export type ProcedureHcpcsSetInput = {
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  procedureId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProcedureHcpcsStddevFields = {
  __typename?: 'procedureHCPCSStddevFields';
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProcedureHcpcsStddev_PopFields = {
  __typename?: 'procedureHCPCSStddev_popFields';
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProcedureHcpcsStddev_SampFields = {
  __typename?: 'procedureHCPCSStddev_sampFields';
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProcedureHcpcsSumFields = {
  __typename?: 'procedureHCPCSSumFields';
  hcpcsId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  procedureId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_procedure_hcpcs" */
export enum ProcedureHcpcsUpdateColumn {
  /** column name */
  HcpcsId = 'hcpcsId',
  /** column name */
  Id = 'id',
  /** column name */
  ProcedureId = 'procedureId'
}

export type ProcedureHcpcsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProcedureHcpcsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProcedureHcpcsSetInput>;
  where: ProcedureHcpcsBoolExp;
};

/** aggregate var_pop on columns */
export type ProcedureHcpcsVar_PopFields = {
  __typename?: 'procedureHCPCSVar_popFields';
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProcedureHcpcsVar_SampFields = {
  __typename?: 'procedureHCPCSVar_sampFields';
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProcedureHcpcsVarianceFields = {
  __typename?: 'procedureHCPCSVarianceFields';
  hcpcsId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_procedure_hcpcs" */
export type ProcedureHcpcs_Avg_Order_By = {
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_procedure_hcpcs" */
export type ProcedureHcpcs_Max_Order_By = {
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_procedure_hcpcs" */
export type ProcedureHcpcs_Min_Order_By = {
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_procedure_hcpcs" */
export type ProcedureHcpcs_Stddev_Order_By = {
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_procedure_hcpcs" */
export type ProcedureHcpcs_Stddev_Pop_Order_By = {
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_procedure_hcpcs" */
export type ProcedureHcpcs_Stddev_Samp_Order_By = {
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "procedureHCPCS" */
export type ProcedureHcpcs_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProcedureHcpcs_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProcedureHcpcs_StreamCursorValueInput = {
  hcpcsId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  procedureId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_procedure_hcpcs" */
export type ProcedureHcpcs_Sum_Order_By = {
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_procedure_hcpcs" */
export type ProcedureHcpcs_Var_Pop_Order_By = {
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_procedure_hcpcs" */
export type ProcedureHcpcs_Var_Samp_Order_By = {
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_procedure_hcpcs" */
export type ProcedureHcpcs_Variance_Order_By = {
  hcpcsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "server_procedure" */
export type ProcedureIncInput = {
  codesetId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_procedure" */
export type ProcedureInsertInput = {
  clinicalnoteTemplates?: InputMaybe<ClinicalnoteTemplateArrRelInsertInput>;
  codeset?: InputMaybe<CodesetObjRelInsertInput>;
  codesetId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  hcpcs?: InputMaybe<ProcedureHcpcsArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  renderedProcedures?: InputMaybe<ProcedureRenderedArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProcedureMaxFields = {
  __typename?: 'procedureMaxFields';
  codesetId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProcedureMinFields = {
  __typename?: 'procedureMinFields';
  codesetId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_procedure" */
export type ProcedureMutationResponse = {
  __typename?: 'procedureMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Procedure>;
};

/** input type for inserting object relation for remote table "server_procedure" */
export type ProcedureObjRelInsertInput = {
  data: ProcedureInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProcedureOnConflict>;
};

/** on_conflict condition type for table "server_procedure" */
export type ProcedureOnConflict = {
  constraint: ProcedureConstraint;
  update_columns?: Array<ProcedureUpdateColumn>;
  where?: InputMaybe<ProcedureBoolExp>;
};

/** Ordering options when selecting data from "server_procedure". */
export type ProcedureOrderBy = {
  clinicalnoteTemplatesAggregate?: InputMaybe<ClinicalnoteTemplateAggregateOrderBy>;
  codeset?: InputMaybe<CodesetOrderBy>;
  codesetId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  hcpcsAggregate?: InputMaybe<ProcedureHcpcsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedProceduresAggregate?: InputMaybe<ProcedureRenderedAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_procedure */
export type ProcedurePkColumnsInput = {
  id: Scalars['Int'];
};

/** columns and relationships of "server_procedurerendered" */
export type ProcedureRendered = {
  __typename?: 'procedureRendered';
  active: Scalars['Boolean'];
  /** An object relationship */
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['Int']>;
  datetime: Scalars['timestamptz'];
  id: Scalars['Int'];
  notes: Scalars['String'];
  /** An object relationship */
  performingUser: User;
  performingUserId: Scalars['Int'];
  /** An object relationship */
  procedure: Procedure;
  procedureId: Scalars['Int'];
  /** An object relationship */
  treatmentPlan?: Maybe<Treatmentplan>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "server_procedurerendered" */
export type ProcedureRenderedAggregate = {
  __typename?: 'procedureRenderedAggregate';
  aggregate?: Maybe<ProcedureRenderedAggregateFields>;
  nodes: Array<ProcedureRendered>;
};

/** aggregate fields of "server_procedurerendered" */
export type ProcedureRenderedAggregateFields = {
  __typename?: 'procedureRenderedAggregateFields';
  avg?: Maybe<ProcedureRenderedAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProcedureRenderedMaxFields>;
  min?: Maybe<ProcedureRenderedMinFields>;
  stddev?: Maybe<ProcedureRenderedStddevFields>;
  stddevPop?: Maybe<ProcedureRenderedStddev_PopFields>;
  stddevSamp?: Maybe<ProcedureRenderedStddev_SampFields>;
  sum?: Maybe<ProcedureRenderedSumFields>;
  varPop?: Maybe<ProcedureRenderedVar_PopFields>;
  varSamp?: Maybe<ProcedureRenderedVar_SampFields>;
  variance?: Maybe<ProcedureRenderedVarianceFields>;
};


/** aggregate fields of "server_procedurerendered" */
export type ProcedureRenderedAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_procedurerendered" */
export type ProcedureRenderedAggregateOrderBy = {
  avg?: InputMaybe<ProcedureRendered_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProcedureRendered_Max_Order_By>;
  min?: InputMaybe<ProcedureRendered_Min_Order_By>;
  stddev?: InputMaybe<ProcedureRendered_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ProcedureRendered_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ProcedureRendered_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ProcedureRendered_Sum_Order_By>;
  var_pop?: InputMaybe<ProcedureRendered_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ProcedureRendered_Var_Samp_Order_By>;
  variance?: InputMaybe<ProcedureRendered_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_procedurerendered" */
export type ProcedureRenderedArrRelInsertInput = {
  data: Array<ProcedureRenderedInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProcedureRenderedOnConflict>;
};

/** aggregate avg on columns */
export type ProcedureRenderedAvgFields = {
  __typename?: 'procedureRenderedAvgFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  performingUserId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_procedurerendered". All fields are combined with a logical 'AND'. */
export type ProcedureRenderedBoolExp = {
  _and?: InputMaybe<Array<ProcedureRenderedBoolExp>>;
  _not?: InputMaybe<ProcedureRenderedBoolExp>;
  _or?: InputMaybe<Array<ProcedureRenderedBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  appointment?: InputMaybe<AppointmentBoolExp>;
  appointmentId?: InputMaybe<IntComparisonExp>;
  datetime?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  performingUser?: InputMaybe<UserBoolExp>;
  performingUserId?: InputMaybe<IntComparisonExp>;
  procedure?: InputMaybe<ProcedureBoolExp>;
  procedureId?: InputMaybe<IntComparisonExp>;
  treatmentPlan?: InputMaybe<TreatmentplanBoolExp>;
  treatmentPlanId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_procedurerendered" */
export enum ProcedureRenderedConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerProcedurerenderedPkey = 'server_procedurerendered_pkey',
  /** unique or primary key constraint on columns "procedure_id", "appointment_id" */
  ServerProcedurerenderedProcedureIdAppointment_2ad0f195Uniq = 'server_procedurerendered_procedure_id_appointment_2ad0f195_uniq'
}

/** input type for incrementing numeric columns in table "server_procedurerendered" */
export type ProcedureRenderedIncInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  performingUserId?: InputMaybe<Scalars['Int']>;
  procedureId?: InputMaybe<Scalars['Int']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_procedurerendered" */
export type ProcedureRenderedInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  appointment?: InputMaybe<AppointmentObjRelInsertInput>;
  appointmentId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  performingUser?: InputMaybe<UserObjRelInsertInput>;
  performingUserId?: InputMaybe<Scalars['Int']>;
  procedure?: InputMaybe<ProcedureObjRelInsertInput>;
  procedureId?: InputMaybe<Scalars['Int']>;
  treatmentPlan?: InputMaybe<TreatmentplanObjRelInsertInput>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProcedureRenderedMaxFields = {
  __typename?: 'procedureRenderedMaxFields';
  appointmentId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  performingUserId?: Maybe<Scalars['Int']>;
  procedureId?: Maybe<Scalars['Int']>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ProcedureRenderedMinFields = {
  __typename?: 'procedureRenderedMinFields';
  appointmentId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  performingUserId?: Maybe<Scalars['Int']>;
  procedureId?: Maybe<Scalars['Int']>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_procedurerendered" */
export type ProcedureRenderedMutationResponse = {
  __typename?: 'procedureRenderedMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProcedureRendered>;
};

/** on_conflict condition type for table "server_procedurerendered" */
export type ProcedureRenderedOnConflict = {
  constraint: ProcedureRenderedConstraint;
  update_columns?: Array<ProcedureRenderedUpdateColumn>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};

/** Ordering options when selecting data from "server_procedurerendered". */
export type ProcedureRenderedOrderBy = {
  active?: InputMaybe<OrderBy>;
  appointment?: InputMaybe<AppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  performingUser?: InputMaybe<UserOrderBy>;
  performingUserId?: InputMaybe<OrderBy>;
  procedure?: InputMaybe<ProcedureOrderBy>;
  procedureId?: InputMaybe<OrderBy>;
  treatmentPlan?: InputMaybe<TreatmentplanOrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_procedurerendered */
export type ProcedureRenderedPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_procedurerendered" */
export enum ProcedureRenderedSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PerformingUserId = 'performingUserId',
  /** column name */
  ProcedureId = 'procedureId',
  /** column name */
  TreatmentPlanId = 'treatmentPlanId'
}

/** input type for updating data in table "server_procedurerendered" */
export type ProcedureRenderedSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  appointmentId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  performingUserId?: InputMaybe<Scalars['Int']>;
  procedureId?: InputMaybe<Scalars['Int']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProcedureRenderedStddevFields = {
  __typename?: 'procedureRenderedStddevFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  performingUserId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProcedureRenderedStddev_PopFields = {
  __typename?: 'procedureRenderedStddev_popFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  performingUserId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProcedureRenderedStddev_SampFields = {
  __typename?: 'procedureRenderedStddev_sampFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  performingUserId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProcedureRenderedSumFields = {
  __typename?: 'procedureRenderedSumFields';
  appointmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  performingUserId?: Maybe<Scalars['Int']>;
  procedureId?: Maybe<Scalars['Int']>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_procedurerendered" */
export enum ProcedureRenderedUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PerformingUserId = 'performingUserId',
  /** column name */
  ProcedureId = 'procedureId',
  /** column name */
  TreatmentPlanId = 'treatmentPlanId'
}

export type ProcedureRenderedUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProcedureRenderedIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProcedureRenderedSetInput>;
  where: ProcedureRenderedBoolExp;
};

/** aggregate var_pop on columns */
export type ProcedureRenderedVar_PopFields = {
  __typename?: 'procedureRenderedVar_popFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  performingUserId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProcedureRenderedVar_SampFields = {
  __typename?: 'procedureRenderedVar_sampFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  performingUserId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProcedureRenderedVarianceFields = {
  __typename?: 'procedureRenderedVarianceFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  performingUserId?: Maybe<Scalars['Float']>;
  procedureId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_procedurerendered" */
export type ProcedureRendered_Avg_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  performingUserId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_procedurerendered" */
export type ProcedureRendered_Max_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  performingUserId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_procedurerendered" */
export type ProcedureRendered_Min_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  performingUserId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_procedurerendered" */
export type ProcedureRendered_Stddev_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  performingUserId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_procedurerendered" */
export type ProcedureRendered_Stddev_Pop_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  performingUserId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_procedurerendered" */
export type ProcedureRendered_Stddev_Samp_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  performingUserId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "procedureRendered" */
export type ProcedureRendered_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProcedureRendered_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProcedureRendered_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  appointmentId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  performingUserId?: InputMaybe<Scalars['Int']>;
  procedureId?: InputMaybe<Scalars['Int']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_procedurerendered" */
export type ProcedureRendered_Sum_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  performingUserId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_procedurerendered" */
export type ProcedureRendered_Var_Pop_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  performingUserId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_procedurerendered" */
export type ProcedureRendered_Var_Samp_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  performingUserId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_procedurerendered" */
export type ProcedureRendered_Variance_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  performingUserId?: InputMaybe<OrderBy>;
  procedureId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** select columns of table "server_procedure" */
export enum ProcedureSelectColumn {
  /** column name */
  CodesetId = 'codesetId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "server_procedure" */
export type ProcedureSetInput = {
  codesetId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProcedureStddevFields = {
  __typename?: 'procedureStddevFields';
  codesetId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProcedureStddev_PopFields = {
  __typename?: 'procedureStddev_popFields';
  codesetId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProcedureStddev_SampFields = {
  __typename?: 'procedureStddev_sampFields';
  codesetId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProcedureSumFields = {
  __typename?: 'procedureSumFields';
  codesetId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_procedure" */
export enum ProcedureUpdateColumn {
  /** column name */
  CodesetId = 'codesetId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

export type ProcedureUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProcedureIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProcedureSetInput>;
  where: ProcedureBoolExp;
};

/** aggregate var_pop on columns */
export type ProcedureVar_PopFields = {
  __typename?: 'procedureVar_popFields';
  codesetId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProcedureVar_SampFields = {
  __typename?: 'procedureVar_sampFields';
  codesetId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProcedureVarianceFields = {
  __typename?: 'procedureVarianceFields';
  codesetId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "procedure" */
export type Procedure_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Procedure_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Procedure_StreamCursorValueInput = {
  codesetId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "server_provider" */
export type Provider = {
  __typename?: 'provider';
  /** An array relationship */
  appointments: Array<Appointment>;
  /** An aggregate relationship */
  appointmentsAggregate: AppointmentAggregate;
  /** An array relationship */
  claims: Array<Claim>;
  /** An aggregate relationship */
  claimsAggregate: ClaimAggregate;
  /** An object relationship */
  classification?: Maybe<ProviderClassification>;
  classificationId?: Maybe<Scalars['Int']>;
  conductsVirtualVisit: Scalars['Boolean'];
  /** An object relationship */
  defaultLabAgency?: Maybe<Agency>;
  defaultLabId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  defaultRadiologyAgency?: Maybe<Agency>;
  defaultRadiologyId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  internalStaffId: Scalars['Int'];
  /** An object relationship */
  internalstaff: Internalstaff;
  nPI: Scalars['String'];
  /** An array relationship */
  orderActionsResponsibleFor: Array<Orderaction>;
  /** An aggregate relationship */
  orderActionsResponsibleForAggregate: OrderactionAggregate;
  /** An array relationship */
  ordersApproved: Array<LegacyOrder>;
  /** An aggregate relationship */
  ordersApprovedAggregate: LegacyOrderAggregate;
  /** An array relationship */
  ordersApprovedAsSupervisor: Array<LegacyOrder>;
  /** An aggregate relationship */
  ordersApprovedAsSupervisorAggregate: LegacyOrderAggregate;
  /** An object relationship */
  provider?: Maybe<Provider>;
  /** An array relationship */
  providerSchedules: Array<ProviderSchedule>;
  /** An aggregate relationship */
  providerSchedulesAggregate: ProviderScheduleAggregate;
  scheduling: Scalars['Boolean'];
  supervisingProviderId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  supervisingProviders: Array<Provider>;
  /** An aggregate relationship */
  supervisingProvidersAggregate: ProviderAggregate;
};


/** columns and relationships of "server_provider" */
export type ProviderAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderClaimsArgs = {
  distinctOn?: InputMaybe<Array<ClaimSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClaimOrderBy>>;
  where?: InputMaybe<ClaimBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderClaimsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClaimSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClaimOrderBy>>;
  where?: InputMaybe<ClaimBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderOrderActionsResponsibleForArgs = {
  distinctOn?: InputMaybe<Array<OrderactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderactionOrderBy>>;
  where?: InputMaybe<OrderactionBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderOrderActionsResponsibleForAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderactionOrderBy>>;
  where?: InputMaybe<OrderactionBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderOrdersApprovedArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderOrdersApprovedAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderOrdersApprovedAsSupervisorArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderOrdersApprovedAsSupervisorAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderProviderSchedulesArgs = {
  distinctOn?: InputMaybe<Array<ProviderScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderScheduleOrderBy>>;
  where?: InputMaybe<ProviderScheduleBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderProviderSchedulesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderScheduleOrderBy>>;
  where?: InputMaybe<ProviderScheduleBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderSupervisingProvidersArgs = {
  distinctOn?: InputMaybe<Array<ProviderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderOrderBy>>;
  where?: InputMaybe<ProviderBoolExp>;
};


/** columns and relationships of "server_provider" */
export type ProviderSupervisingProvidersAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderOrderBy>>;
  where?: InputMaybe<ProviderBoolExp>;
};

/** aggregated selection of "server_provider" */
export type ProviderAggregate = {
  __typename?: 'providerAggregate';
  aggregate?: Maybe<ProviderAggregateFields>;
  nodes: Array<Provider>;
};

/** aggregate fields of "server_provider" */
export type ProviderAggregateFields = {
  __typename?: 'providerAggregateFields';
  avg?: Maybe<ProviderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProviderMaxFields>;
  min?: Maybe<ProviderMinFields>;
  stddev?: Maybe<ProviderStddevFields>;
  stddevPop?: Maybe<ProviderStddev_PopFields>;
  stddevSamp?: Maybe<ProviderStddev_SampFields>;
  sum?: Maybe<ProviderSumFields>;
  varPop?: Maybe<ProviderVar_PopFields>;
  varSamp?: Maybe<ProviderVar_SampFields>;
  variance?: Maybe<ProviderVarianceFields>;
};


/** aggregate fields of "server_provider" */
export type ProviderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProviderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_provider" */
export type ProviderAggregateOrderBy = {
  avg?: InputMaybe<Provider_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Provider_Max_Order_By>;
  min?: InputMaybe<Provider_Min_Order_By>;
  stddev?: InputMaybe<Provider_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Provider_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Provider_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Provider_Sum_Order_By>;
  var_pop?: InputMaybe<Provider_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Provider_Var_Samp_Order_By>;
  variance?: InputMaybe<Provider_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_provider" */
export type ProviderArrRelInsertInput = {
  data: Array<ProviderInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProviderOnConflict>;
};

/** aggregate avg on columns */
export type ProviderAvgFields = {
  __typename?: 'providerAvgFields';
  classificationId?: Maybe<Scalars['Float']>;
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  internalStaffId?: Maybe<Scalars['Float']>;
  supervisingProviderId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_provider". All fields are combined with a logical 'AND'. */
export type ProviderBoolExp = {
  _and?: InputMaybe<Array<ProviderBoolExp>>;
  _not?: InputMaybe<ProviderBoolExp>;
  _or?: InputMaybe<Array<ProviderBoolExp>>;
  appointments?: InputMaybe<AppointmentBoolExp>;
  claims?: InputMaybe<ClaimBoolExp>;
  classification?: InputMaybe<ProviderClassificationBoolExp>;
  classificationId?: InputMaybe<IntComparisonExp>;
  conductsVirtualVisit?: InputMaybe<BooleanComparisonExp>;
  defaultLabAgency?: InputMaybe<AgencyBoolExp>;
  defaultLabId?: InputMaybe<IntComparisonExp>;
  defaultRadiologyAgency?: InputMaybe<AgencyBoolExp>;
  defaultRadiologyId?: InputMaybe<IntComparisonExp>;
  externalId?: InputMaybe<IntComparisonExp>;
  internalStaffId?: InputMaybe<IntComparisonExp>;
  internalstaff?: InputMaybe<InternalstaffBoolExp>;
  nPI?: InputMaybe<StringComparisonExp>;
  orderActionsResponsibleFor?: InputMaybe<OrderactionBoolExp>;
  ordersApproved?: InputMaybe<LegacyOrderBoolExp>;
  ordersApprovedAsSupervisor?: InputMaybe<LegacyOrderBoolExp>;
  provider?: InputMaybe<ProviderBoolExp>;
  providerSchedules?: InputMaybe<ProviderScheduleBoolExp>;
  scheduling?: InputMaybe<BooleanComparisonExp>;
  supervisingProviderId?: InputMaybe<IntComparisonExp>;
  supervisingProviders?: InputMaybe<ProviderBoolExp>;
};

/** columns and relationships of "server_providerclassification" */
export type ProviderClassification = {
  __typename?: 'providerClassification';
  billingAdjustment: Scalars['numeric'];
  billsEm: Scalars['Boolean'];
  /** An array relationship */
  clinicalNoteTemplates: Array<ClinicalnoteTemplateProviderClassifications>;
  /** An aggregate relationship */
  clinicalNoteTemplatesAggregate: ClinicalnoteTemplateProviderClassificationsAggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  primaryCodeset?: Maybe<Codeset>;
  primaryCodesetId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  providers: Array<Provider>;
  /** An aggregate relationship */
  providersAggregate: ProviderAggregate;
};


/** columns and relationships of "server_providerclassification" */
export type ProviderClassificationClinicalNoteTemplatesArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
};


/** columns and relationships of "server_providerclassification" */
export type ProviderClassificationClinicalNoteTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
};


/** columns and relationships of "server_providerclassification" */
export type ProviderClassificationProvidersArgs = {
  distinctOn?: InputMaybe<Array<ProviderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderOrderBy>>;
  where?: InputMaybe<ProviderBoolExp>;
};


/** columns and relationships of "server_providerclassification" */
export type ProviderClassificationProvidersAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderOrderBy>>;
  where?: InputMaybe<ProviderBoolExp>;
};

/** aggregated selection of "server_providerclassification" */
export type ProviderClassificationAggregate = {
  __typename?: 'providerClassificationAggregate';
  aggregate?: Maybe<ProviderClassificationAggregateFields>;
  nodes: Array<ProviderClassification>;
};

/** aggregate fields of "server_providerclassification" */
export type ProviderClassificationAggregateFields = {
  __typename?: 'providerClassificationAggregateFields';
  avg?: Maybe<ProviderClassificationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProviderClassificationMaxFields>;
  min?: Maybe<ProviderClassificationMinFields>;
  stddev?: Maybe<ProviderClassificationStddevFields>;
  stddevPop?: Maybe<ProviderClassificationStddev_PopFields>;
  stddevSamp?: Maybe<ProviderClassificationStddev_SampFields>;
  sum?: Maybe<ProviderClassificationSumFields>;
  varPop?: Maybe<ProviderClassificationVar_PopFields>;
  varSamp?: Maybe<ProviderClassificationVar_SampFields>;
  variance?: Maybe<ProviderClassificationVarianceFields>;
};


/** aggregate fields of "server_providerclassification" */
export type ProviderClassificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProviderClassificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProviderClassificationAvgFields = {
  __typename?: 'providerClassificationAvgFields';
  billingAdjustment?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primaryCodesetId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_providerclassification". All fields are combined with a logical 'AND'. */
export type ProviderClassificationBoolExp = {
  _and?: InputMaybe<Array<ProviderClassificationBoolExp>>;
  _not?: InputMaybe<ProviderClassificationBoolExp>;
  _or?: InputMaybe<Array<ProviderClassificationBoolExp>>;
  billingAdjustment?: InputMaybe<NumericComparisonExp>;
  billsEm?: InputMaybe<BooleanComparisonExp>;
  clinicalNoteTemplates?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  primaryCodeset?: InputMaybe<CodesetBoolExp>;
  primaryCodesetId?: InputMaybe<IntComparisonExp>;
  providers?: InputMaybe<ProviderBoolExp>;
};

/** unique or primary key constraints on table "server_providerclassification" */
export enum ProviderClassificationConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerProviderclassificationPkey = 'server_providerclassification_pkey'
}

/** input type for incrementing numeric columns in table "server_providerclassification" */
export type ProviderClassificationIncInput = {
  billingAdjustment?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  primaryCodesetId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_providerclassification" */
export type ProviderClassificationInsertInput = {
  billingAdjustment?: InputMaybe<Scalars['numeric']>;
  billsEm?: InputMaybe<Scalars['Boolean']>;
  clinicalNoteTemplates?: InputMaybe<ClinicalnoteTemplateProviderClassificationsArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryCodeset?: InputMaybe<CodesetObjRelInsertInput>;
  primaryCodesetId?: InputMaybe<Scalars['Int']>;
  providers?: InputMaybe<ProviderArrRelInsertInput>;
};

/** aggregate max on columns */
export type ProviderClassificationMaxFields = {
  __typename?: 'providerClassificationMaxFields';
  billingAdjustment?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  primaryCodesetId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ProviderClassificationMinFields = {
  __typename?: 'providerClassificationMinFields';
  billingAdjustment?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  primaryCodesetId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_providerclassification" */
export type ProviderClassificationMutationResponse = {
  __typename?: 'providerClassificationMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProviderClassification>;
};

/** input type for inserting object relation for remote table "server_providerclassification" */
export type ProviderClassificationObjRelInsertInput = {
  data: ProviderClassificationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProviderClassificationOnConflict>;
};

/** on_conflict condition type for table "server_providerclassification" */
export type ProviderClassificationOnConflict = {
  constraint: ProviderClassificationConstraint;
  update_columns?: Array<ProviderClassificationUpdateColumn>;
  where?: InputMaybe<ProviderClassificationBoolExp>;
};

/** Ordering options when selecting data from "server_providerclassification". */
export type ProviderClassificationOrderBy = {
  billingAdjustment?: InputMaybe<OrderBy>;
  billsEm?: InputMaybe<OrderBy>;
  clinicalNoteTemplatesAggregate?: InputMaybe<ClinicalnoteTemplateProviderClassificationsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  primaryCodeset?: InputMaybe<CodesetOrderBy>;
  primaryCodesetId?: InputMaybe<OrderBy>;
  providersAggregate?: InputMaybe<ProviderAggregateOrderBy>;
};

/** primary key columns input for table: server_providerclassification */
export type ProviderClassificationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_providerclassification" */
export enum ProviderClassificationSelectColumn {
  /** column name */
  BillingAdjustment = 'billingAdjustment',
  /** column name */
  BillsEm = 'billsEm',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrimaryCodesetId = 'primaryCodesetId'
}

/** input type for updating data in table "server_providerclassification" */
export type ProviderClassificationSetInput = {
  billingAdjustment?: InputMaybe<Scalars['numeric']>;
  billsEm?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryCodesetId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProviderClassificationStddevFields = {
  __typename?: 'providerClassificationStddevFields';
  billingAdjustment?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primaryCodesetId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProviderClassificationStddev_PopFields = {
  __typename?: 'providerClassificationStddev_popFields';
  billingAdjustment?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primaryCodesetId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProviderClassificationStddev_SampFields = {
  __typename?: 'providerClassificationStddev_sampFields';
  billingAdjustment?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primaryCodesetId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProviderClassificationSumFields = {
  __typename?: 'providerClassificationSumFields';
  billingAdjustment?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  primaryCodesetId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_providerclassification" */
export enum ProviderClassificationUpdateColumn {
  /** column name */
  BillingAdjustment = 'billingAdjustment',
  /** column name */
  BillsEm = 'billsEm',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrimaryCodesetId = 'primaryCodesetId'
}

export type ProviderClassificationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProviderClassificationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProviderClassificationSetInput>;
  where: ProviderClassificationBoolExp;
};

/** aggregate var_pop on columns */
export type ProviderClassificationVar_PopFields = {
  __typename?: 'providerClassificationVar_popFields';
  billingAdjustment?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primaryCodesetId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProviderClassificationVar_SampFields = {
  __typename?: 'providerClassificationVar_sampFields';
  billingAdjustment?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primaryCodesetId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProviderClassificationVarianceFields = {
  __typename?: 'providerClassificationVarianceFields';
  billingAdjustment?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primaryCodesetId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "providerClassification" */
export type ProviderClassification_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProviderClassification_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProviderClassification_StreamCursorValueInput = {
  billingAdjustment?: InputMaybe<Scalars['numeric']>;
  billsEm?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryCodesetId?: InputMaybe<Scalars['Int']>;
};

/** unique or primary key constraints on table "server_provider" */
export enum ProviderConstraint {
  /** unique or primary key constraint on columns "internal_staff_id" */
  ServerProviderPkey = 'server_provider_pkey'
}

/** input type for incrementing numeric columns in table "server_provider" */
export type ProviderIncInput = {
  classificationId?: InputMaybe<Scalars['Int']>;
  defaultLabId?: InputMaybe<Scalars['Int']>;
  defaultRadiologyId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['Int']>;
  internalStaffId?: InputMaybe<Scalars['Int']>;
  supervisingProviderId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_provider" */
export type ProviderInsertInput = {
  appointments?: InputMaybe<AppointmentArrRelInsertInput>;
  claims?: InputMaybe<ClaimArrRelInsertInput>;
  classification?: InputMaybe<ProviderClassificationObjRelInsertInput>;
  classificationId?: InputMaybe<Scalars['Int']>;
  conductsVirtualVisit?: InputMaybe<Scalars['Boolean']>;
  defaultLabAgency?: InputMaybe<AgencyObjRelInsertInput>;
  defaultLabId?: InputMaybe<Scalars['Int']>;
  defaultRadiologyAgency?: InputMaybe<AgencyObjRelInsertInput>;
  defaultRadiologyId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['Int']>;
  internalStaffId?: InputMaybe<Scalars['Int']>;
  internalstaff?: InputMaybe<InternalstaffObjRelInsertInput>;
  nPI?: InputMaybe<Scalars['String']>;
  orderActionsResponsibleFor?: InputMaybe<OrderactionArrRelInsertInput>;
  ordersApproved?: InputMaybe<LegacyOrderArrRelInsertInput>;
  ordersApprovedAsSupervisor?: InputMaybe<LegacyOrderArrRelInsertInput>;
  provider?: InputMaybe<ProviderObjRelInsertInput>;
  providerSchedules?: InputMaybe<ProviderScheduleArrRelInsertInput>;
  scheduling?: InputMaybe<Scalars['Boolean']>;
  supervisingProviderId?: InputMaybe<Scalars['Int']>;
  supervisingProviders?: InputMaybe<ProviderArrRelInsertInput>;
};

/** aggregate max on columns */
export type ProviderMaxFields = {
  __typename?: 'providerMaxFields';
  classificationId?: Maybe<Scalars['Int']>;
  defaultLabId?: Maybe<Scalars['Int']>;
  defaultRadiologyId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  internalStaffId?: Maybe<Scalars['Int']>;
  nPI?: Maybe<Scalars['String']>;
  supervisingProviderId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ProviderMinFields = {
  __typename?: 'providerMinFields';
  classificationId?: Maybe<Scalars['Int']>;
  defaultLabId?: Maybe<Scalars['Int']>;
  defaultRadiologyId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  internalStaffId?: Maybe<Scalars['Int']>;
  nPI?: Maybe<Scalars['String']>;
  supervisingProviderId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_provider" */
export type ProviderMutationResponse = {
  __typename?: 'providerMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Provider>;
};

/** input type for inserting object relation for remote table "server_provider" */
export type ProviderObjRelInsertInput = {
  data: ProviderInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProviderOnConflict>;
};

/** on_conflict condition type for table "server_provider" */
export type ProviderOnConflict = {
  constraint: ProviderConstraint;
  update_columns?: Array<ProviderUpdateColumn>;
  where?: InputMaybe<ProviderBoolExp>;
};

/** Ordering options when selecting data from "server_provider". */
export type ProviderOrderBy = {
  appointmentsAggregate?: InputMaybe<AppointmentAggregateOrderBy>;
  claimsAggregate?: InputMaybe<ClaimAggregateOrderBy>;
  classification?: InputMaybe<ProviderClassificationOrderBy>;
  classificationId?: InputMaybe<OrderBy>;
  conductsVirtualVisit?: InputMaybe<OrderBy>;
  defaultLabAgency?: InputMaybe<AgencyOrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultRadiologyAgency?: InputMaybe<AgencyOrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  internalStaffId?: InputMaybe<OrderBy>;
  internalstaff?: InputMaybe<InternalstaffOrderBy>;
  nPI?: InputMaybe<OrderBy>;
  orderActionsResponsibleForAggregate?: InputMaybe<OrderactionAggregateOrderBy>;
  ordersApprovedAggregate?: InputMaybe<LegacyOrderAggregateOrderBy>;
  ordersApprovedAsSupervisorAggregate?: InputMaybe<LegacyOrderAggregateOrderBy>;
  provider?: InputMaybe<ProviderOrderBy>;
  providerSchedulesAggregate?: InputMaybe<ProviderScheduleAggregateOrderBy>;
  scheduling?: InputMaybe<OrderBy>;
  supervisingProviderId?: InputMaybe<OrderBy>;
  supervisingProvidersAggregate?: InputMaybe<ProviderAggregateOrderBy>;
};

/** primary key columns input for table: server_provider */
export type ProviderPkColumnsInput = {
  internalStaffId: Scalars['Int'];
};

/** columns and relationships of "server_providerschedule" */
export type ProviderSchedule = {
  __typename?: 'providerSchedule';
  /** An object relationship */
  endAddress?: Maybe<Address>;
  endAddressId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['date']>;
  endTime: Scalars['time'];
  id: Scalars['Int'];
  priority: Scalars['Int'];
  /** An object relationship */
  provider: Provider;
  providerId: Scalars['Int'];
  repetitionSchedule?: Maybe<Scalars['String']>;
  /** An object relationship */
  startAddress?: Maybe<Address>;
  startAddressId?: Maybe<Scalars['Int']>;
  startDate: Scalars['date'];
  startTime: Scalars['time'];
  visitEquivalents: Scalars['Int'];
};

/** aggregated selection of "server_providerschedule" */
export type ProviderScheduleAggregate = {
  __typename?: 'providerScheduleAggregate';
  aggregate?: Maybe<ProviderScheduleAggregateFields>;
  nodes: Array<ProviderSchedule>;
};

/** aggregate fields of "server_providerschedule" */
export type ProviderScheduleAggregateFields = {
  __typename?: 'providerScheduleAggregateFields';
  avg?: Maybe<ProviderScheduleAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProviderScheduleMaxFields>;
  min?: Maybe<ProviderScheduleMinFields>;
  stddev?: Maybe<ProviderScheduleStddevFields>;
  stddevPop?: Maybe<ProviderScheduleStddev_PopFields>;
  stddevSamp?: Maybe<ProviderScheduleStddev_SampFields>;
  sum?: Maybe<ProviderScheduleSumFields>;
  varPop?: Maybe<ProviderScheduleVar_PopFields>;
  varSamp?: Maybe<ProviderScheduleVar_SampFields>;
  variance?: Maybe<ProviderScheduleVarianceFields>;
};


/** aggregate fields of "server_providerschedule" */
export type ProviderScheduleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProviderScheduleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_providerschedule" */
export type ProviderScheduleAggregateOrderBy = {
  avg?: InputMaybe<ProviderSchedule_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProviderSchedule_Max_Order_By>;
  min?: InputMaybe<ProviderSchedule_Min_Order_By>;
  stddev?: InputMaybe<ProviderSchedule_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ProviderSchedule_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ProviderSchedule_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ProviderSchedule_Sum_Order_By>;
  var_pop?: InputMaybe<ProviderSchedule_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ProviderSchedule_Var_Samp_Order_By>;
  variance?: InputMaybe<ProviderSchedule_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_providerschedule" */
export type ProviderScheduleArrRelInsertInput = {
  data: Array<ProviderScheduleInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProviderScheduleOnConflict>;
};

/** aggregate avg on columns */
export type ProviderScheduleAvgFields = {
  __typename?: 'providerScheduleAvgFields';
  endAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  startAddressId?: Maybe<Scalars['Float']>;
  visitEquivalents?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_providerschedule". All fields are combined with a logical 'AND'. */
export type ProviderScheduleBoolExp = {
  _and?: InputMaybe<Array<ProviderScheduleBoolExp>>;
  _not?: InputMaybe<ProviderScheduleBoolExp>;
  _or?: InputMaybe<Array<ProviderScheduleBoolExp>>;
  endAddress?: InputMaybe<AddressBoolExp>;
  endAddressId?: InputMaybe<IntComparisonExp>;
  endDate?: InputMaybe<DateComparisonExp>;
  endTime?: InputMaybe<TimeComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  provider?: InputMaybe<ProviderBoolExp>;
  providerId?: InputMaybe<IntComparisonExp>;
  repetitionSchedule?: InputMaybe<StringComparisonExp>;
  startAddress?: InputMaybe<AddressBoolExp>;
  startAddressId?: InputMaybe<IntComparisonExp>;
  startDate?: InputMaybe<DateComparisonExp>;
  startTime?: InputMaybe<TimeComparisonExp>;
  visitEquivalents?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_providerschedule" */
export enum ProviderScheduleConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerProviderschedulePkey = 'server_providerschedule_pkey'
}

/** input type for incrementing numeric columns in table "server_providerschedule" */
export type ProviderScheduleIncInput = {
  endAddressId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
  startAddressId?: InputMaybe<Scalars['Int']>;
  visitEquivalents?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_providerschedule" */
export type ProviderScheduleInsertInput = {
  endAddress?: InputMaybe<AddressObjRelInsertInput>;
  endAddressId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['date']>;
  endTime?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<ProviderObjRelInsertInput>;
  providerId?: InputMaybe<Scalars['Int']>;
  repetitionSchedule?: InputMaybe<Scalars['String']>;
  startAddress?: InputMaybe<AddressObjRelInsertInput>;
  startAddressId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  startTime?: InputMaybe<Scalars['time']>;
  visitEquivalents?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProviderScheduleMaxFields = {
  __typename?: 'providerScheduleMaxFields';
  endAddressId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
  repetitionSchedule?: Maybe<Scalars['String']>;
  startAddressId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  visitEquivalents?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ProviderScheduleMinFields = {
  __typename?: 'providerScheduleMinFields';
  endAddressId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
  repetitionSchedule?: Maybe<Scalars['String']>;
  startAddressId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  visitEquivalents?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_providerschedule" */
export type ProviderScheduleMutationResponse = {
  __typename?: 'providerScheduleMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProviderSchedule>;
};

/** on_conflict condition type for table "server_providerschedule" */
export type ProviderScheduleOnConflict = {
  constraint: ProviderScheduleConstraint;
  update_columns?: Array<ProviderScheduleUpdateColumn>;
  where?: InputMaybe<ProviderScheduleBoolExp>;
};

/** Ordering options when selecting data from "server_providerschedule". */
export type ProviderScheduleOrderBy = {
  endAddress?: InputMaybe<AddressOrderBy>;
  endAddressId?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  provider?: InputMaybe<ProviderOrderBy>;
  providerId?: InputMaybe<OrderBy>;
  repetitionSchedule?: InputMaybe<OrderBy>;
  startAddress?: InputMaybe<AddressOrderBy>;
  startAddressId?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  visitEquivalents?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_providerschedule */
export type ProviderSchedulePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_providerschedule" */
export enum ProviderScheduleSelectColumn {
  /** column name */
  EndAddressId = 'endAddressId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  Id = 'id',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  RepetitionSchedule = 'repetitionSchedule',
  /** column name */
  StartAddressId = 'startAddressId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  VisitEquivalents = 'visitEquivalents'
}

/** input type for updating data in table "server_providerschedule" */
export type ProviderScheduleSetInput = {
  endAddressId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['date']>;
  endTime?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
  repetitionSchedule?: InputMaybe<Scalars['String']>;
  startAddressId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  startTime?: InputMaybe<Scalars['time']>;
  visitEquivalents?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProviderScheduleStddevFields = {
  __typename?: 'providerScheduleStddevFields';
  endAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  startAddressId?: Maybe<Scalars['Float']>;
  visitEquivalents?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProviderScheduleStddev_PopFields = {
  __typename?: 'providerScheduleStddev_popFields';
  endAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  startAddressId?: Maybe<Scalars['Float']>;
  visitEquivalents?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProviderScheduleStddev_SampFields = {
  __typename?: 'providerScheduleStddev_sampFields';
  endAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  startAddressId?: Maybe<Scalars['Float']>;
  visitEquivalents?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProviderScheduleSumFields = {
  __typename?: 'providerScheduleSumFields';
  endAddressId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
  startAddressId?: Maybe<Scalars['Int']>;
  visitEquivalents?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_providerschedule" */
export enum ProviderScheduleUpdateColumn {
  /** column name */
  EndAddressId = 'endAddressId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  Id = 'id',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  RepetitionSchedule = 'repetitionSchedule',
  /** column name */
  StartAddressId = 'startAddressId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  VisitEquivalents = 'visitEquivalents'
}

export type ProviderScheduleUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProviderScheduleIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProviderScheduleSetInput>;
  where: ProviderScheduleBoolExp;
};

/** aggregate var_pop on columns */
export type ProviderScheduleVar_PopFields = {
  __typename?: 'providerScheduleVar_popFields';
  endAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  startAddressId?: Maybe<Scalars['Float']>;
  visitEquivalents?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProviderScheduleVar_SampFields = {
  __typename?: 'providerScheduleVar_sampFields';
  endAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  startAddressId?: Maybe<Scalars['Float']>;
  visitEquivalents?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProviderScheduleVarianceFields = {
  __typename?: 'providerScheduleVarianceFields';
  endAddressId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Float']>;
  startAddressId?: Maybe<Scalars['Float']>;
  visitEquivalents?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_providerschedule" */
export type ProviderSchedule_Avg_Order_By = {
  endAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  startAddressId?: InputMaybe<OrderBy>;
  visitEquivalents?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_providerschedule" */
export type ProviderSchedule_Max_Order_By = {
  endAddressId?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  repetitionSchedule?: InputMaybe<OrderBy>;
  startAddressId?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  visitEquivalents?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_providerschedule" */
export type ProviderSchedule_Min_Order_By = {
  endAddressId?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  repetitionSchedule?: InputMaybe<OrderBy>;
  startAddressId?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  visitEquivalents?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_providerschedule" */
export type ProviderSchedule_Stddev_Order_By = {
  endAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  startAddressId?: InputMaybe<OrderBy>;
  visitEquivalents?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_providerschedule" */
export type ProviderSchedule_Stddev_Pop_Order_By = {
  endAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  startAddressId?: InputMaybe<OrderBy>;
  visitEquivalents?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_providerschedule" */
export type ProviderSchedule_Stddev_Samp_Order_By = {
  endAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  startAddressId?: InputMaybe<OrderBy>;
  visitEquivalents?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "providerSchedule" */
export type ProviderSchedule_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProviderSchedule_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProviderSchedule_StreamCursorValueInput = {
  endAddressId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['date']>;
  endTime?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
  repetitionSchedule?: InputMaybe<Scalars['String']>;
  startAddressId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  startTime?: InputMaybe<Scalars['time']>;
  visitEquivalents?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_providerschedule" */
export type ProviderSchedule_Sum_Order_By = {
  endAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  startAddressId?: InputMaybe<OrderBy>;
  visitEquivalents?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_providerschedule" */
export type ProviderSchedule_Var_Pop_Order_By = {
  endAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  startAddressId?: InputMaybe<OrderBy>;
  visitEquivalents?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_providerschedule" */
export type ProviderSchedule_Var_Samp_Order_By = {
  endAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  startAddressId?: InputMaybe<OrderBy>;
  visitEquivalents?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_providerschedule" */
export type ProviderSchedule_Variance_Order_By = {
  endAddressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  startAddressId?: InputMaybe<OrderBy>;
  visitEquivalents?: InputMaybe<OrderBy>;
};

/** select columns of table "server_provider" */
export enum ProviderSelectColumn {
  /** column name */
  ClassificationId = 'classificationId',
  /** column name */
  ConductsVirtualVisit = 'conductsVirtualVisit',
  /** column name */
  DefaultLabId = 'defaultLabId',
  /** column name */
  DefaultRadiologyId = 'defaultRadiologyId',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  InternalStaffId = 'internalStaffId',
  /** column name */
  NPi = 'nPI',
  /** column name */
  Scheduling = 'scheduling',
  /** column name */
  SupervisingProviderId = 'supervisingProviderId'
}

/** input type for updating data in table "server_provider" */
export type ProviderSetInput = {
  classificationId?: InputMaybe<Scalars['Int']>;
  conductsVirtualVisit?: InputMaybe<Scalars['Boolean']>;
  defaultLabId?: InputMaybe<Scalars['Int']>;
  defaultRadiologyId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['Int']>;
  internalStaffId?: InputMaybe<Scalars['Int']>;
  nPI?: InputMaybe<Scalars['String']>;
  scheduling?: InputMaybe<Scalars['Boolean']>;
  supervisingProviderId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProviderStddevFields = {
  __typename?: 'providerStddevFields';
  classificationId?: Maybe<Scalars['Float']>;
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  internalStaffId?: Maybe<Scalars['Float']>;
  supervisingProviderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProviderStddev_PopFields = {
  __typename?: 'providerStddev_popFields';
  classificationId?: Maybe<Scalars['Float']>;
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  internalStaffId?: Maybe<Scalars['Float']>;
  supervisingProviderId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProviderStddev_SampFields = {
  __typename?: 'providerStddev_sampFields';
  classificationId?: Maybe<Scalars['Float']>;
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  internalStaffId?: Maybe<Scalars['Float']>;
  supervisingProviderId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProviderSumFields = {
  __typename?: 'providerSumFields';
  classificationId?: Maybe<Scalars['Int']>;
  defaultLabId?: Maybe<Scalars['Int']>;
  defaultRadiologyId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  internalStaffId?: Maybe<Scalars['Int']>;
  supervisingProviderId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_provider" */
export enum ProviderUpdateColumn {
  /** column name */
  ClassificationId = 'classificationId',
  /** column name */
  ConductsVirtualVisit = 'conductsVirtualVisit',
  /** column name */
  DefaultLabId = 'defaultLabId',
  /** column name */
  DefaultRadiologyId = 'defaultRadiologyId',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  InternalStaffId = 'internalStaffId',
  /** column name */
  NPi = 'nPI',
  /** column name */
  Scheduling = 'scheduling',
  /** column name */
  SupervisingProviderId = 'supervisingProviderId'
}

export type ProviderUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProviderIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProviderSetInput>;
  where: ProviderBoolExp;
};

/** aggregate var_pop on columns */
export type ProviderVar_PopFields = {
  __typename?: 'providerVar_popFields';
  classificationId?: Maybe<Scalars['Float']>;
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  internalStaffId?: Maybe<Scalars['Float']>;
  supervisingProviderId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProviderVar_SampFields = {
  __typename?: 'providerVar_sampFields';
  classificationId?: Maybe<Scalars['Float']>;
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  internalStaffId?: Maybe<Scalars['Float']>;
  supervisingProviderId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProviderVarianceFields = {
  __typename?: 'providerVarianceFields';
  classificationId?: Maybe<Scalars['Float']>;
  defaultLabId?: Maybe<Scalars['Float']>;
  defaultRadiologyId?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  internalStaffId?: Maybe<Scalars['Float']>;
  supervisingProviderId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_provider" */
export type Provider_Avg_Order_By = {
  classificationId?: InputMaybe<OrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  internalStaffId?: InputMaybe<OrderBy>;
  supervisingProviderId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_provider" */
export type Provider_Max_Order_By = {
  classificationId?: InputMaybe<OrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  internalStaffId?: InputMaybe<OrderBy>;
  nPI?: InputMaybe<OrderBy>;
  supervisingProviderId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_provider" */
export type Provider_Min_Order_By = {
  classificationId?: InputMaybe<OrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  internalStaffId?: InputMaybe<OrderBy>;
  nPI?: InputMaybe<OrderBy>;
  supervisingProviderId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_provider" */
export type Provider_Stddev_Order_By = {
  classificationId?: InputMaybe<OrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  internalStaffId?: InputMaybe<OrderBy>;
  supervisingProviderId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_provider" */
export type Provider_Stddev_Pop_Order_By = {
  classificationId?: InputMaybe<OrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  internalStaffId?: InputMaybe<OrderBy>;
  supervisingProviderId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_provider" */
export type Provider_Stddev_Samp_Order_By = {
  classificationId?: InputMaybe<OrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  internalStaffId?: InputMaybe<OrderBy>;
  supervisingProviderId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "provider" */
export type Provider_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Provider_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Provider_StreamCursorValueInput = {
  classificationId?: InputMaybe<Scalars['Int']>;
  conductsVirtualVisit?: InputMaybe<Scalars['Boolean']>;
  defaultLabId?: InputMaybe<Scalars['Int']>;
  defaultRadiologyId?: InputMaybe<Scalars['Int']>;
  externalId?: InputMaybe<Scalars['Int']>;
  internalStaffId?: InputMaybe<Scalars['Int']>;
  nPI?: InputMaybe<Scalars['String']>;
  scheduling?: InputMaybe<Scalars['Boolean']>;
  supervisingProviderId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_provider" */
export type Provider_Sum_Order_By = {
  classificationId?: InputMaybe<OrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  internalStaffId?: InputMaybe<OrderBy>;
  supervisingProviderId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_provider" */
export type Provider_Var_Pop_Order_By = {
  classificationId?: InputMaybe<OrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  internalStaffId?: InputMaybe<OrderBy>;
  supervisingProviderId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_provider" */
export type Provider_Var_Samp_Order_By = {
  classificationId?: InputMaybe<OrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  internalStaffId?: InputMaybe<OrderBy>;
  supervisingProviderId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_provider" */
export type Provider_Variance_Order_By = {
  classificationId?: InputMaybe<OrderBy>;
  defaultLabId?: InputMaybe<OrderBy>;
  defaultRadiologyId?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  internalStaffId?: InputMaybe<OrderBy>;
  supervisingProviderId?: InputMaybe<OrderBy>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "server_address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "server_address" */
  addressAggregate: AddressAggregate;
  /** fetch data from the table: "server_address" using primary key columns */
  addressByPk?: Maybe<Address>;
  /** fetch data from the table: "server_agency" */
  agency: Array<Agency>;
  /** fetch aggregated fields from the table: "server_agency" */
  agencyAggregate: AgencyAggregate;
  /** fetch data from the table: "server_agency" using primary key columns */
  agencyByPk?: Maybe<Agency>;
  /** fetch data from the table: "server_agencylocation" */
  agencyLocation: Array<AgencyLocation>;
  /** fetch aggregated fields from the table: "server_agencylocation" */
  agencyLocationAggregate: AgencyLocationAggregate;
  /** fetch data from the table: "server_agencylocation" using primary key columns */
  agencyLocationByPk?: Maybe<AgencyLocation>;
  /** fetch data from the table: "server_agency_payers" */
  agencyPayers: Array<AgencyPayers>;
  /** fetch aggregated fields from the table: "server_agency_payers" */
  agencyPayersAggregate: AgencyPayersAggregate;
  /** fetch data from the table: "server_agency_payers" using primary key columns */
  agencyPayersByPk?: Maybe<AgencyPayers>;
  /** An array relationship */
  agencyServices: Array<AgencyServices>;
  /** An aggregate relationship */
  agencyServicesAggregate: AgencyServicesAggregate;
  /** fetch data from the table: "server_agency_services" using primary key columns */
  agencyServicesByPk?: Maybe<AgencyServices>;
  /** fetch data from the table: "server_agencyconnection" */
  agencyconnection: Array<Agencyconnection>;
  /** fetch aggregated fields from the table: "server_agencyconnection" */
  agencyconnectionAggregate: AgencyconnectionAggregate;
  /** fetch data from the table: "server_agencyconnection" using primary key columns */
  agencyconnectionByPk?: Maybe<Agencyconnection>;
  /** fetch data from the table: "server_agencyjoinrequest" */
  agencyjoinrequest: Array<Agencyjoinrequest>;
  /** fetch aggregated fields from the table: "server_agencyjoinrequest" */
  agencyjoinrequestAggregate: AgencyjoinrequestAggregate;
  /** fetch data from the table: "server_agencyjoinrequest" using primary key columns */
  agencyjoinrequestByPk?: Maybe<Agencyjoinrequest>;
  /** fetch data from the table: "server_allergy" */
  allergy: Array<Allergy>;
  /** fetch aggregated fields from the table: "server_allergy" */
  allergyAggregate: AllergyAggregate;
  /** fetch data from the table: "server_allergy" using primary key columns */
  allergyByPk?: Maybe<Allergy>;
  /** fetch data from the table: "server_appointment" */
  appointment: Array<Appointment>;
  /** fetch aggregated fields from the table: "server_appointment" */
  appointmentAggregate: AppointmentAggregate;
  /** fetch data from the table: "server_appointment" using primary key columns */
  appointmentByPk?: Maybe<Appointment>;
  /** fetch data from the table: "server_assessment" */
  assessment: Array<Assessment>;
  /** fetch aggregated fields from the table: "server_assessment" */
  assessmentAggregate: AssessmentAggregate;
  /** fetch data from the table: "server_assessment" using primary key columns */
  assessmentByPk?: Maybe<Assessment>;
  /** fetch data from the table: "server_assessmentitem" */
  assessmentItem: Array<AssessmentItem>;
  /** fetch aggregated fields from the table: "server_assessmentitem" */
  assessmentItemAggregate: AssessmentItemAggregate;
  /** fetch data from the table: "server_assessmentitem" using primary key columns */
  assessmentItemByPk?: Maybe<AssessmentItem>;
  /** fetch data from the table: "server_assessmentitemvalue" */
  assessmentItemValue: Array<AssessmentItemValue>;
  /** fetch aggregated fields from the table: "server_assessmentitemvalue" */
  assessmentItemValueAggregate: AssessmentItemValueAggregate;
  /** fetch data from the table: "server_assessmentitemvalue" using primary key columns */
  assessmentItemValueByPk?: Maybe<AssessmentItemValue>;
  /** fetch data from the table: "server_baseprotocolorder" */
  baseProtocolOrder: Array<BaseProtocolOrder>;
  /** fetch aggregated fields from the table: "server_baseprotocolorder" */
  baseProtocolOrderAggregate: BaseProtocolOrderAggregate;
  /** fetch data from the table: "server_baseprotocolorder" using primary key columns */
  baseProtocolOrderByPk?: Maybe<BaseProtocolOrder>;
  /** fetch data from the table: "server_baseorder" */
  baseorder: Array<Baseorder>;
  /** fetch aggregated fields from the table: "server_baseorder" */
  baseorderAggregate: BaseorderAggregate;
  /** fetch data from the table: "server_baseorder" using primary key columns */
  baseorderByPk?: Maybe<Baseorder>;
  /** fetch data from the table: "server_caretime" */
  caretime: Array<Caretime>;
  /** fetch aggregated fields from the table: "server_caretime" */
  caretimeAggregate: CaretimeAggregate;
  /** fetch data from the table: "server_caretime" using primary key columns */
  caretimeByPk?: Maybe<Caretime>;
  /** fetch data from the table: "server_caretime_problems" */
  caretimeProblems: Array<CaretimeProblems>;
  /** fetch aggregated fields from the table: "server_caretime_problems" */
  caretimeProblemsAggregate: CaretimeProblemsAggregate;
  /** fetch data from the table: "server_caretime_problems" using primary key columns */
  caretimeProblemsByPk?: Maybe<CaretimeProblems>;
  /** fetch data from the table: "server_claim" */
  claim: Array<Claim>;
  /** fetch aggregated fields from the table: "server_claim" */
  claimAggregate: ClaimAggregate;
  /** fetch data from the table: "server_claim" using primary key columns */
  claimByPk?: Maybe<Claim>;
  /** fetch data from the table: "server_futurereferralclinicalnote" */
  clinicalNoteFutureReferrals: Array<ClinicalNoteFutureReferrals>;
  /** fetch aggregated fields from the table: "server_futurereferralclinicalnote" */
  clinicalNoteFutureReferralsAggregate: ClinicalNoteFutureReferralsAggregate;
  /** fetch data from the table: "server_futurereferralclinicalnote" using primary key columns */
  clinicalNoteFutureReferralsByPk?: Maybe<ClinicalNoteFutureReferrals>;
  /** fetch data from the table: "server_clinicalnote" */
  clinicalnote: Array<Clinicalnote>;
  /** fetch aggregated fields from the table: "server_clinicalnote" */
  clinicalnoteAggregate: ClinicalnoteAggregate;
  /** fetch data from the table: "server_clinicalnote" using primary key columns */
  clinicalnoteByPk?: Maybe<Clinicalnote>;
  /** fetch data from the table: "server_clinicalnotefield" */
  clinicalnoteField: Array<ClinicalnoteField>;
  /** fetch aggregated fields from the table: "server_clinicalnotefield" */
  clinicalnoteFieldAggregate: ClinicalnoteFieldAggregate;
  /** fetch data from the table: "server_clinicalnotefield" using primary key columns */
  clinicalnoteFieldByPk?: Maybe<ClinicalnoteField>;
  /** fetch data from the table: "server_clinicalnotefieldvalue" */
  clinicalnoteFieldValue: Array<ClinicalnoteFieldValue>;
  /** fetch aggregated fields from the table: "server_clinicalnotefieldvalue" */
  clinicalnoteFieldValueAggregate: ClinicalnoteFieldValueAggregate;
  /** fetch data from the table: "server_clinicalnotefieldvalue" using primary key columns */
  clinicalnoteFieldValueByPk?: Maybe<ClinicalnoteFieldValue>;
  /** fetch data from the table: "server_clinicalnotetemplate" */
  clinicalnoteTemplate: Array<ClinicalnoteTemplate>;
  /** fetch aggregated fields from the table: "server_clinicalnotetemplate" */
  clinicalnoteTemplateAggregate: ClinicalnoteTemplateAggregate;
  /** fetch data from the table: "server_clinicalnotetemplate" using primary key columns */
  clinicalnoteTemplateByPk?: Maybe<ClinicalnoteTemplate>;
  /** fetch data from the table: "server_clinicalnotetemplate_provider_classifications" */
  clinicalnoteTemplateProviderClassifications: Array<ClinicalnoteTemplateProviderClassifications>;
  /** fetch aggregated fields from the table: "server_clinicalnotetemplate_provider_classifications" */
  clinicalnoteTemplateProviderClassificationsAggregate: ClinicalnoteTemplateProviderClassificationsAggregate;
  /** fetch data from the table: "server_clinicalnotetemplate_provider_classifications" using primary key columns */
  clinicalnoteTemplateProviderClassificationsByPk?: Maybe<ClinicalnoteTemplateProviderClassifications>;
  /** fetch data from the table: "server_codeset" */
  codeset: Array<Codeset>;
  /** fetch data from the table: "server_codeset_addon_codeset" */
  codesetAddon: Array<CodesetAddon>;
  /** fetch aggregated fields from the table: "server_codeset_addon_codeset" */
  codesetAddonAggregate: CodesetAddonAggregate;
  /** fetch data from the table: "server_codeset_addon_codeset" using primary key columns */
  codesetAddonByPk?: Maybe<CodesetAddon>;
  /** fetch aggregated fields from the table: "server_codeset" */
  codesetAggregate: CodesetAggregate;
  /** fetch data from the table: "server_codeset_base_codeset" */
  codesetBase: Array<CodesetBase>;
  /** fetch aggregated fields from the table: "server_codeset_base_codeset" */
  codesetBaseAggregate: CodesetBaseAggregate;
  /** fetch data from the table: "server_codeset_base_codeset" using primary key columns */
  codesetBaseByPk?: Maybe<CodesetBase>;
  /** fetch data from the table: "server_codeset" using primary key columns */
  codesetByPk?: Maybe<Codeset>;
  /** fetch data from the table: "server_consent" */
  consent: Array<Consent>;
  /** fetch aggregated fields from the table: "server_consent" */
  consentAggregate: ConsentAggregate;
  /** fetch data from the table: "server_consent" using primary key columns */
  consentByPk?: Maybe<Consent>;
  /** fetch data from the table: "server_cpoclaim" */
  cpoClaim: Array<CpoClaim>;
  /** fetch aggregated fields from the table: "server_cpoclaim" */
  cpoClaimAggregate: CpoClaimAggregate;
  /** fetch data from the table: "server_cpoclaim" using primary key columns */
  cpoClaimByPk?: Maybe<CpoClaim>;
  /** fetch data from the table: "server_dependantorder" */
  dependantOrder: Array<DependantOrder>;
  /** fetch aggregated fields from the table: "server_dependantorder" */
  dependantOrderAggregate: DependantOrderAggregate;
  /** fetch data from the table: "server_dependantorder" using primary key columns */
  dependantOrderByPk?: Maybe<DependantOrder>;
  /** fetch data from the table: "server_diagnosisabbreviation" */
  diagnosisAbbreviation: Array<DiagnosisAbbreviation>;
  /** fetch aggregated fields from the table: "server_diagnosisabbreviation" */
  diagnosisAbbreviationAggregate: DiagnosisAbbreviationAggregate;
  /** fetch data from the table: "server_diagnosisabbreviation" using primary key columns */
  diagnosisAbbreviationByPk?: Maybe<DiagnosisAbbreviation>;
  /** fetch data from the table: "server_document" */
  document: Array<Document>;
  /** fetch aggregated fields from the table: "server_document" */
  documentAggregate: DocumentAggregate;
  /** fetch data from the table: "server_document" using primary key columns */
  documentByPk?: Maybe<Document>;
  /** fetch data from the table: "server_documentpendingreview" */
  documentPendingReview: Array<DocumentPendingReview>;
  /** fetch aggregated fields from the table: "server_documentpendingreview" */
  documentPendingReviewAggregate: DocumentPendingReviewAggregate;
  /** fetch data from the table: "server_documentpendingreview" using primary key columns */
  documentPendingReviewByPk?: Maybe<DocumentPendingReview>;
  /** fetch data from the table: "server_externalsignedorder" */
  externalSignedOrder: Array<ExternalSignedOrder>;
  /** fetch aggregated fields from the table: "server_externalsignedorder" */
  externalSignedOrderAggregate: ExternalSignedOrderAggregate;
  /** fetch data from the table: "server_externalsignedorder" using primary key columns */
  externalSignedOrderByPk?: Maybe<ExternalSignedOrder>;
  /** fetch data from the table: "server_externalstaff_my_patients" */
  externalStaffMyPatients: Array<ExternalStaffMyPatients>;
  /** fetch aggregated fields from the table: "server_externalstaff_my_patients" */
  externalStaffMyPatientsAggregate: ExternalStaffMyPatientsAggregate;
  /** fetch data from the table: "server_externalstaff_my_patients" using primary key columns */
  externalStaffMyPatientsByPk?: Maybe<ExternalStaffMyPatients>;
  /** fetch data from the table: "server_externalstaff" */
  externalstaff: Array<Externalstaff>;
  /** fetch aggregated fields from the table: "server_externalstaff" */
  externalstaffAggregate: ExternalstaffAggregate;
  /** fetch data from the table: "server_externalstaff" using primary key columns */
  externalstaffByPk?: Maybe<Externalstaff>;
  /** fetch data from the table: "server_fax" */
  fax: Array<Fax>;
  /** fetch aggregated fields from the table: "server_fax" */
  faxAggregate: FaxAggregate;
  /** fetch data from the table: "server_fax" using primary key columns */
  faxByPk?: Maybe<Fax>;
  /** fetch data from the table: "server_fax_documents" */
  faxDocuments: Array<FaxDocuments>;
  /** fetch aggregated fields from the table: "server_fax_documents" */
  faxDocumentsAggregate: FaxDocumentsAggregate;
  /** fetch data from the table: "server_fax_documents" using primary key columns */
  faxDocumentsByPk?: Maybe<FaxDocuments>;
  /** fetch data from the table: "server_freetextorder" */
  freeTextOrder: Array<FreeTextOrder>;
  /** fetch aggregated fields from the table: "server_freetextorder" */
  freeTextOrderAggregate: FreeTextOrderAggregate;
  /** fetch data from the table: "server_freetextorder" using primary key columns */
  freeTextOrderByPk?: Maybe<FreeTextOrder>;
  /** fetch data from the table: "server_frequencyorder" */
  frequencyOrder: Array<FrequencyOrder>;
  /** fetch aggregated fields from the table: "server_frequencyorder" */
  frequencyOrderAggregate: FrequencyOrderAggregate;
  /** fetch data from the table: "server_frequencyorder" using primary key columns */
  frequencyOrderByPk?: Maybe<FrequencyOrder>;
  /** fetch data from the table: "server_frequencyprotocol" */
  frequencyProtocol: Array<FrequencyProtocol>;
  /** fetch aggregated fields from the table: "server_frequencyprotocol" */
  frequencyProtocolAggregate: FrequencyProtocolAggregate;
  /** fetch data from the table: "server_frequencyprotocol" using primary key columns */
  frequencyProtocolByPk?: Maybe<FrequencyProtocol>;
  /** fetch data from the table: "server_hcpcs" */
  hcpcs: Array<Hcpcs>;
  /** fetch aggregated fields from the table: "server_hcpcs" */
  hcpcsAggregate: HcpcsAggregate;
  /** fetch data from the table: "server_hcpcs" using primary key columns */
  hcpcsByPk?: Maybe<Hcpcs>;
  /** fetch data from the table: "server_insuranceplan" */
  insuranceplan: Array<Insuranceplan>;
  /** fetch aggregated fields from the table: "server_insuranceplan" */
  insuranceplanAggregate: InsuranceplanAggregate;
  /** fetch data from the table: "server_insuranceplan" using primary key columns */
  insuranceplanByPk?: Maybe<Insuranceplan>;
  /** fetch data from the table: "server_integration" */
  integration: Array<Integration>;
  /** fetch aggregated fields from the table: "server_integration" */
  integrationAggregate: IntegrationAggregate;
  /** fetch data from the table: "server_integration" using primary key columns */
  integrationByPk?: Maybe<Integration>;
  /** fetch data from the table: "server_internalstaff" */
  internalstaff: Array<Internalstaff>;
  /** fetch aggregated fields from the table: "server_internalstaff" */
  internalstaffAggregate: InternalstaffAggregate;
  /** fetch data from the table: "server_internalstaff" using primary key columns */
  internalstaffByPk?: Maybe<Internalstaff>;
  /** fetch data from the table: "server_order" */
  legacyOrder: Array<LegacyOrder>;
  /** fetch aggregated fields from the table: "server_order" */
  legacyOrderAggregate: LegacyOrderAggregate;
  /** fetch data from the table: "server_order" using primary key columns */
  legacyOrderByPk?: Maybe<LegacyOrder>;
  /** fetch data from the table: "server_orderfrequency" */
  legacyOrderFrequency: Array<LegacyOrderFrequency>;
  /** fetch aggregated fields from the table: "server_orderfrequency" */
  legacyOrderFrequencyAggregate: LegacyOrderFrequencyAggregate;
  /** fetch data from the table: "server_orderfrequency" using primary key columns */
  legacyOrderFrequencyByPk?: Maybe<LegacyOrderFrequency>;
  /** fetch data from the table: "server_order_medications" */
  legacyOrderMedications: Array<LegacyOrderMedications>;
  /** fetch aggregated fields from the table: "server_order_medications" */
  legacyOrderMedicationsAggregate: LegacyOrderMedicationsAggregate;
  /** fetch data from the table: "server_order_medications" using primary key columns */
  legacyOrderMedicationsByPk?: Maybe<LegacyOrderMedications>;
  /** fetch data from the table: "server_orderorderablefieldvalue" */
  legacyOrderOrderableFieldValue: Array<LegacyOrderOrderableFieldValue>;
  /** fetch aggregated fields from the table: "server_orderorderablefieldvalue" */
  legacyOrderOrderableFieldValueAggregate: LegacyOrderOrderableFieldValueAggregate;
  /** fetch data from the table: "server_orderorderablefieldvalue" using primary key columns */
  legacyOrderOrderableFieldValueByPk?: Maybe<LegacyOrderOrderableFieldValue>;
  /** fetch data from the table: "server_orderqualification" */
  legacyOrderQualification: Array<LegacyOrderQualification>;
  /** fetch aggregated fields from the table: "server_orderqualification" */
  legacyOrderQualificationAggregate: LegacyOrderQualificationAggregate;
  /** fetch data from the table: "server_orderqualification" using primary key columns */
  legacyOrderQualificationByPk?: Maybe<LegacyOrderQualification>;
  /** fetch data from the table: "server_treatmentplanorder" */
  legacyTreatmentplanOrder: Array<LegacyTreatmentplanOrder>;
  /** fetch aggregated fields from the table: "server_treatmentplanorder" */
  legacyTreatmentplanOrderAggregate: LegacyTreatmentplanOrderAggregate;
  /** fetch data from the table: "server_treatmentplanorder" using primary key columns */
  legacyTreatmentplanOrderByPk?: Maybe<LegacyTreatmentplanOrder>;
  /** fetch data from the table: "server_lineitem" */
  lineitem: Array<Lineitem>;
  /** fetch aggregated fields from the table: "server_lineitem" */
  lineitemAggregate: LineitemAggregate;
  /** fetch data from the table: "server_lineitem" using primary key columns */
  lineitemByPk?: Maybe<Lineitem>;
  /** fetch data from the table: "server_medication" */
  medication: Array<Medication>;
  /** fetch aggregated fields from the table: "server_medication" */
  medicationAggregate: MedicationAggregate;
  /** fetch data from the table: "server_medication" using primary key columns */
  medicationByPk?: Maybe<Medication>;
  /** fetch data from the table: "server_medicationorder" */
  medicationOrder: Array<MedicationOrder>;
  /** fetch aggregated fields from the table: "server_medicationorder" */
  medicationOrderAggregate: MedicationOrderAggregate;
  /** fetch data from the table: "server_medicationorder" using primary key columns */
  medicationOrderByPk?: Maybe<MedicationOrder>;
  /** fetch data from the table: "server_medicationorder_medications" */
  medicationorderMedications: Array<MedicationorderMedications>;
  /** fetch aggregated fields from the table: "server_medicationorder_medications" */
  medicationorderMedicationsAggregate: MedicationorderMedicationsAggregate;
  /** fetch data from the table: "server_medicationorder_medications" using primary key columns */
  medicationorderMedicationsByPk?: Maybe<MedicationorderMedications>;
  /** fetch data from the table: "server_message" */
  message: Array<Message>;
  /** fetch aggregated fields from the table: "server_message" */
  messageAggregate: MessageAggregate;
  /** fetch data from the table: "server_message" using primary key columns */
  messageByPk?: Maybe<Message>;
  /** fetch data from the table: "server_orderorderablefield" */
  orderOrderableField: Array<OrderOrderableField>;
  /** fetch aggregated fields from the table: "server_orderorderablefield" */
  orderOrderableFieldAggregate: OrderOrderableFieldAggregate;
  /** fetch data from the table: "server_orderorderablefield" using primary key columns */
  orderOrderableFieldByPk?: Maybe<OrderOrderableField>;
  /** fetch data from the table: "server_orderorderable" */
  orderOrderableLegacy: Array<OrderOrderableLegacy>;
  /** fetch aggregated fields from the table: "server_orderorderable" */
  orderOrderableLegacyAggregate: OrderOrderableLegacyAggregate;
  /** fetch data from the table: "server_orderorderable" using primary key columns */
  orderOrderableLegacyByPk?: Maybe<OrderOrderableLegacy>;
  /** fetch data from the table: "server_orderable" */
  orderable: Array<Orderable>;
  /** fetch aggregated fields from the table: "server_orderable" */
  orderableAggregate: OrderableAggregate;
  /** fetch data from the table: "server_orderable" using primary key columns */
  orderableByPk?: Maybe<Orderable>;
  /** fetch data from the table: "server_orderablecategory" */
  orderableCategory: Array<OrderableCategory>;
  /** fetch aggregated fields from the table: "server_orderablecategory" */
  orderableCategoryAggregate: OrderableCategoryAggregate;
  /** fetch data from the table: "server_orderablecategory" using primary key columns */
  orderableCategoryByPk?: Maybe<OrderableCategory>;
  /** fetch data from the table: "server_orderableorder" */
  orderableOrder: Array<OrderableOrder>;
  /** fetch aggregated fields from the table: "server_orderableorder" */
  orderableOrderAggregate: OrderableOrderAggregate;
  /** fetch data from the table: "server_orderableorder" using primary key columns */
  orderableOrderByPk?: Maybe<OrderableOrder>;
  /** fetch data from the table: "server_orderableorderqualification" */
  orderableOrderQualification: Array<OrderableOrderQualification>;
  /** fetch aggregated fields from the table: "server_orderableorderqualification" */
  orderableOrderQualificationAggregate: OrderableOrderQualificationAggregate;
  /** fetch data from the table: "server_orderableorderqualification" using primary key columns */
  orderableOrderQualificationByPk?: Maybe<OrderableOrderQualification>;
  /** fetch data from the table: "server_orderablequalification" */
  orderableQualification: Array<OrderableQualification>;
  /** fetch aggregated fields from the table: "server_orderablequalification" */
  orderableQualificationAggregate: OrderableQualificationAggregate;
  /** fetch data from the table: "server_orderablequalification" using primary key columns */
  orderableQualificationByPk?: Maybe<OrderableQualification>;
  /** fetch data from the table: "server_orderablesupplier" */
  orderableSupplier: Array<OrderableSupplier>;
  /** fetch aggregated fields from the table: "server_orderablesupplier" */
  orderableSupplierAggregate: OrderableSupplierAggregate;
  /** fetch data from the table: "server_orderablesupplier" using primary key columns */
  orderableSupplierByPk?: Maybe<OrderableSupplier>;
  /** fetch data from the table: "server_orderablesupplier_payers" */
  orderableSupplierPayerConnections: Array<OrderableSupplierPayerConnections>;
  /** fetch aggregated fields from the table: "server_orderablesupplier_payers" */
  orderableSupplierPayerConnectionsAggregate: OrderableSupplierPayerConnectionsAggregate;
  /** fetch data from the table: "server_orderablesupplier_payers" using primary key columns */
  orderableSupplierPayerConnectionsByPk?: Maybe<OrderableSupplierPayerConnections>;
  /** fetch data from the table: "server_orderaction" */
  orderaction: Array<Orderaction>;
  /** fetch aggregated fields from the table: "server_orderaction" */
  orderactionAggregate: OrderactionAggregate;
  /** fetch data from the table: "server_orderaction" using primary key columns */
  orderactionByPk?: Maybe<Orderaction>;
  /** fetch data from the table: "server_patient" */
  patient: Array<Patient>;
  /** fetch aggregated fields from the table: "server_patient" */
  patientAggregate: PatientAggregate;
  /** fetch data from the table: "server_patientalias" */
  patientAlias: Array<PatientAlias>;
  /** fetch aggregated fields from the table: "server_patientalias" */
  patientAliasAggregate: PatientAliasAggregate;
  /** fetch data from the table: "server_patientalias" using primary key columns */
  patientAliasByPk?: Maybe<PatientAlias>;
  /** fetch data from the table: "server_patient" using primary key columns */
  patientByPk?: Maybe<Patient>;
  /** fetch data from the table: "server_patientcommunication" */
  patientCommunication: Array<PatientCommunication>;
  /** fetch aggregated fields from the table: "server_patientcommunication" */
  patientCommunicationAggregate: PatientCommunicationAggregate;
  /** fetch data from the table: "server_patientcommunication" using primary key columns */
  patientCommunicationByPk?: Maybe<PatientCommunication>;
  /** fetch data from the table: "server_patientcontactperson" */
  patientContactPerson: Array<PatientContactPerson>;
  /** fetch aggregated fields from the table: "server_patientcontactperson" */
  patientContactPersonAggregate: PatientContactPersonAggregate;
  /** fetch data from the table: "server_patientcontactperson" using primary key columns */
  patientContactPersonByPk?: Maybe<PatientContactPerson>;
  /** fetch data from the table: "server_patienteligibilitycheck" */
  patientEligibilityCheck: Array<PatientEligibilityCheck>;
  /** fetch aggregated fields from the table: "server_patienteligibilitycheck" */
  patientEligibilityCheckAggregate: PatientEligibilityCheckAggregate;
  /** fetch data from the table: "server_patienteligibilitycheck" using primary key columns */
  patientEligibilityCheckByPk?: Maybe<PatientEligibilityCheck>;
  /** fetch data from the table: "server_patientintake" */
  patientIntake: Array<PatientIntake>;
  /** fetch aggregated fields from the table: "server_patientintake" */
  patientIntakeAggregate: PatientIntakeAggregate;
  /** fetch data from the table: "server_patientintake" using primary key columns */
  patientIntakeByPk?: Maybe<PatientIntake>;
  /** fetch data from the table: "server_patientintake_records_locations" */
  patientIntakeRecordsLocations: Array<PatientIntakeRecordsLocations>;
  /** fetch aggregated fields from the table: "server_patientintake_records_locations" */
  patientIntakeRecordsLocationsAggregate: PatientIntakeRecordsLocationsAggregate;
  /** fetch data from the table: "server_patientintake_records_locations" using primary key columns */
  patientIntakeRecordsLocationsByPk?: Maybe<PatientIntakeRecordsLocations>;
  /** fetch data from the table: "server_patientphone" */
  patientPhone: Array<PatientPhone>;
  /** fetch aggregated fields from the table: "server_patientphone" */
  patientPhoneAggregate: PatientPhoneAggregate;
  /** fetch data from the table: "server_patientphone" using primary key columns */
  patientPhoneByPk?: Maybe<PatientPhone>;
  /** fetch data from the table: "server_patientphoto" */
  patientPhoto: Array<PatientPhoto>;
  /** fetch aggregated fields from the table: "server_patientphoto" */
  patientPhotoAggregate: PatientPhotoAggregate;
  /** fetch data from the table: "server_patientphoto" using primary key columns */
  patientPhotoByPk?: Maybe<PatientPhoto>;
  /** fetch data from the table: "server_patientstatus" */
  patientStatus: Array<PatientStatus>;
  /** fetch data from the table: "server_patientstatusaction" */
  patientStatusAction: Array<PatientStatusAction>;
  /** fetch aggregated fields from the table: "server_patientstatusaction" */
  patientStatusActionAggregate: PatientStatusActionAggregate;
  /** fetch data from the table: "server_patientstatusaction" using primary key columns */
  patientStatusActionByPk?: Maybe<PatientStatusAction>;
  /** fetch aggregated fields from the table: "server_patientstatus" */
  patientStatusAggregate: PatientStatusAggregate;
  /** fetch data from the table: "server_patientstatus" using primary key columns */
  patientStatusByPk?: Maybe<PatientStatus>;
  /** fetch data from the table: "server_patientstatus_subsequent_statuses" */
  patientStatusSubsequentStatuses: Array<PatientStatusSubsequentStatuses>;
  /** fetch aggregated fields from the table: "server_patientstatus_subsequent_statuses" */
  patientStatusSubsequentStatusesAggregate: PatientStatusSubsequentStatusesAggregate;
  /** fetch data from the table: "server_patientstatus_subsequent_statuses" using primary key columns */
  patientStatusSubsequentStatusesByPk?: Maybe<PatientStatusSubsequentStatuses>;
  /** fetch data from the table: "server_payer" */
  payer: Array<Payer>;
  /** fetch aggregated fields from the table: "server_payer" */
  payerAggregate: PayerAggregate;
  /** fetch data from the table: "server_payer" using primary key columns */
  payerByPk?: Maybe<Payer>;
  /** fetch data from the table: "server_practicegroup" */
  practicegroup: Array<Practicegroup>;
  /** fetch aggregated fields from the table: "server_practicegroup" */
  practicegroupAggregate: PracticegroupAggregate;
  /** fetch data from the table: "server_practicegroup" using primary key columns */
  practicegroupByPk?: Maybe<Practicegroup>;
  /** fetch data from the table: "server_problem" */
  problem: Array<Problem>;
  /** fetch aggregated fields from the table: "server_problem" */
  problemAggregate: ProblemAggregate;
  /** fetch data from the table: "server_problem" using primary key columns */
  problemByPk?: Maybe<Problem>;
  /** fetch data from the table: "server_problemplan" */
  problemPlan: Array<ProblemPlan>;
  /** fetch aggregated fields from the table: "server_problemplan" */
  problemPlanAggregate: ProblemPlanAggregate;
  /** fetch data from the table: "server_problemplan" using primary key columns */
  problemPlanByPk?: Maybe<ProblemPlan>;
  /** fetch data from the table: "server_procedure" */
  procedure: Array<Procedure>;
  /** fetch aggregated fields from the table: "server_procedure" */
  procedureAggregate: ProcedureAggregate;
  /** fetch data from the table: "server_procedure" using primary key columns */
  procedureByPk?: Maybe<Procedure>;
  /** fetch data from the table: "server_procedure_hcpcs" */
  procedureHCPCS: Array<ProcedureHcpcs>;
  /** fetch aggregated fields from the table: "server_procedure_hcpcs" */
  procedureHCPCSAggregate: ProcedureHcpcsAggregate;
  /** fetch data from the table: "server_procedure_hcpcs" using primary key columns */
  procedureHCPCSByPk?: Maybe<ProcedureHcpcs>;
  /** fetch data from the table: "server_procedurerendered" */
  procedureRendered: Array<ProcedureRendered>;
  /** fetch aggregated fields from the table: "server_procedurerendered" */
  procedureRenderedAggregate: ProcedureRenderedAggregate;
  /** fetch data from the table: "server_procedurerendered" using primary key columns */
  procedureRenderedByPk?: Maybe<ProcedureRendered>;
  /** fetch data from the table: "server_provider" */
  provider: Array<Provider>;
  /** fetch aggregated fields from the table: "server_provider" */
  providerAggregate: ProviderAggregate;
  /** fetch data from the table: "server_provider" using primary key columns */
  providerByPk?: Maybe<Provider>;
  /** fetch data from the table: "server_providerclassification" */
  providerClassification: Array<ProviderClassification>;
  /** fetch aggregated fields from the table: "server_providerclassification" */
  providerClassificationAggregate: ProviderClassificationAggregate;
  /** fetch data from the table: "server_providerclassification" using primary key columns */
  providerClassificationByPk?: Maybe<ProviderClassification>;
  /** fetch data from the table: "server_providerschedule" */
  providerSchedule: Array<ProviderSchedule>;
  /** fetch aggregated fields from the table: "server_providerschedule" */
  providerScheduleAggregate: ProviderScheduleAggregate;
  /** fetch data from the table: "server_providerschedule" using primary key columns */
  providerScheduleByPk?: Maybe<ProviderSchedule>;
  /** fetch data from the table: "server_recordsrequestdestination" */
  recordsRequestDestination: Array<RecordsRequestDestination>;
  /** fetch aggregated fields from the table: "server_recordsrequestdestination" */
  recordsRequestDestinationAggregate: RecordsRequestDestinationAggregate;
  /** fetch data from the table: "server_recordsrequestdestination" using primary key columns */
  recordsRequestDestinationByPk?: Maybe<RecordsRequestDestination>;
  /** fetch data from the table: "server_referral" */
  referral: Array<Referral>;
  /** fetch aggregated fields from the table: "server_referral" */
  referralAggregate: ReferralAggregate;
  /** fetch data from the table: "server_referral_attachments" */
  referralAttachments: Array<ReferralAttachments>;
  /** fetch aggregated fields from the table: "server_referral_attachments" */
  referralAttachmentsAggregate: ReferralAttachmentsAggregate;
  /** fetch data from the table: "server_referral_attachments" using primary key columns */
  referralAttachmentsByPk?: Maybe<ReferralAttachments>;
  /** fetch data from the table: "server_referral" using primary key columns */
  referralByPk?: Maybe<Referral>;
  /** fetch data from the table: "server_renderedassessment" */
  renderedAssessment: Array<RenderedAssessment>;
  /** fetch aggregated fields from the table: "server_renderedassessment" */
  renderedAssessmentAggregate: RenderedAssessmentAggregate;
  /** fetch data from the table: "server_renderedassessment" using primary key columns */
  renderedAssessmentByPk?: Maybe<RenderedAssessment>;
  /** fetch data from the table: "server_schedulingaction" */
  schedulingAction: Array<SchedulingAction>;
  /** fetch aggregated fields from the table: "server_schedulingaction" */
  schedulingActionAggregate: SchedulingActionAggregate;
  /** fetch data from the table: "server_schedulingaction" using primary key columns */
  schedulingActionByPk?: Maybe<SchedulingAction>;
  /** fetch data from the table: "server_servicetype" */
  serviceType: Array<ServiceType>;
  /** fetch aggregated fields from the table: "server_servicetype" */
  serviceTypeAggregate: ServiceTypeAggregate;
  /** fetch data from the table: "server_servicetype" using primary key columns */
  serviceTypeByPk?: Maybe<ServiceType>;
  /** fetch data from the table: "server_signedconsent" */
  signedConsent: Array<SignedConsent>;
  /** fetch aggregated fields from the table: "server_signedconsent" */
  signedConsentAggregate: SignedConsentAggregate;
  /** fetch data from the table: "server_signedconsent" using primary key columns */
  signedConsentByPk?: Maybe<SignedConsent>;
  /** fetch data from the table: "server_signoff" */
  signoff: Array<Signoff>;
  /** fetch aggregated fields from the table: "server_signoff" */
  signoffAggregate: SignoffAggregate;
  /** fetch data from the table: "server_signoff" using primary key columns */
  signoffByPk?: Maybe<Signoff>;
  /** fetch data from the table: "server_signofftype" */
  signoffType: Array<SignoffType>;
  /** fetch aggregated fields from the table: "server_signofftype" */
  signoffTypeAggregate: SignoffTypeAggregate;
  /** fetch data from the table: "server_signofftype" using primary key columns */
  signoffTypeByPk?: Maybe<SignoffType>;
  /** fetch data from the table: "server_telemedappointmentrequest" */
  telemedAppointmentRequest: Array<TelemedAppointmentRequest>;
  /** fetch data from the table: "server_telemedappointmentrequestaccesscode" */
  telemedAppointmentRequestAccessCode: Array<TelemedAppointmentRequestAccessCode>;
  /** fetch aggregated fields from the table: "server_telemedappointmentrequestaccesscode" */
  telemedAppointmentRequestAccessCodeAggregate: TelemedAppointmentRequestAccessCodeAggregate;
  /** fetch data from the table: "server_telemedappointmentrequestaccesscode" using primary key columns */
  telemedAppointmentRequestAccessCodeByPk?: Maybe<TelemedAppointmentRequestAccessCode>;
  /** fetch aggregated fields from the table: "server_telemedappointmentrequest" */
  telemedAppointmentRequestAggregate: TelemedAppointmentRequestAggregate;
  /** fetch data from the table: "server_telemedappointmentrequest" using primary key columns */
  telemedAppointmentRequestByPk?: Maybe<TelemedAppointmentRequest>;
  /** fetch data from the table: "server_threadcategory" */
  threadCategory: Array<ThreadCategory>;
  /** fetch aggregated fields from the table: "server_threadcategory" */
  threadCategoryAggregate: ThreadCategoryAggregate;
  /** fetch data from the table: "server_threadcategory" using primary key columns */
  threadCategoryByPk?: Maybe<ThreadCategory>;
  /** fetch data from the table: "server_threadcategorystatus" */
  threadCategoryStatus: Array<ThreadCategoryStatus>;
  /** fetch aggregated fields from the table: "server_threadcategorystatus" */
  threadCategoryStatusAggregate: ThreadCategoryStatusAggregate;
  /** fetch data from the table: "server_threadcategorystatus" using primary key columns */
  threadCategoryStatusByPk?: Maybe<ThreadCategoryStatus>;
  /** fetch data from the table: "server_threadfield" */
  threadField: Array<ThreadField>;
  /** fetch aggregated fields from the table: "server_threadfield" */
  threadFieldAggregate: ThreadFieldAggregate;
  /** fetch data from the table: "server_threadfield" using primary key columns */
  threadFieldByPk?: Maybe<ThreadField>;
  /** fetch data from the table: "server_threadfieldvalue" */
  threadFieldValue: Array<ThreadFieldValue>;
  /** fetch aggregated fields from the table: "server_threadfieldvalue" */
  threadFieldValueAggregate: ThreadFieldValueAggregate;
  /** fetch data from the table: "server_threadfieldvalue" using primary key columns */
  threadFieldValueByPk?: Maybe<ThreadFieldValue>;
  /** fetch data from the table: "server_ticketsubscription" */
  ticketSubscription: Array<TicketSubscription>;
  /** fetch aggregated fields from the table: "server_ticketsubscription" */
  ticketSubscriptionAggregate: TicketSubscriptionAggregate;
  /** fetch data from the table: "server_ticketsubscription" using primary key columns */
  ticketSubscriptionByPk?: Maybe<TicketSubscription>;
  /** fetch data from the table: "server_ticketthread" */
  ticketThread: Array<TicketThread>;
  /** fetch aggregated fields from the table: "server_ticketthread" */
  ticketThreadAggregate: TicketThreadAggregate;
  /** fetch data from the table: "server_ticketthread" using primary key columns */
  ticketThreadByPk?: Maybe<TicketThread>;
  /** fetch data from the table: "server_treatmentplan" */
  treatmentplan: Array<Treatmentplan>;
  /** fetch aggregated fields from the table: "server_treatmentplan" */
  treatmentplanAggregate: TreatmentplanAggregate;
  /** fetch data from the table: "server_treatmentplanassessment" */
  treatmentplanAssessment: Array<TreatmentplanAssessment>;
  /** fetch aggregated fields from the table: "server_treatmentplanassessment" */
  treatmentplanAssessmentAggregate: TreatmentplanAssessmentAggregate;
  /** fetch data from the table: "server_treatmentplanassessment" using primary key columns */
  treatmentplanAssessmentByPk?: Maybe<TreatmentplanAssessment>;
  /** fetch data from the table: "server_treatmentplan" using primary key columns */
  treatmentplanByPk?: Maybe<Treatmentplan>;
  /** fetch data from the table: "server_treatmentplannote" */
  treatmentplanNote: Array<TreatmentplanNote>;
  /** fetch aggregated fields from the table: "server_treatmentplannote" */
  treatmentplanNoteAggregate: TreatmentplanNoteAggregate;
  /** fetch data from the table: "server_treatmentplannote" using primary key columns */
  treatmentplanNoteByPk?: Maybe<TreatmentplanNote>;
  /** fetch data from the table: "server_treatmentplan_problems" */
  treatmentplanProblems: Array<TreatmentplanProblems>;
  /** fetch aggregated fields from the table: "server_treatmentplan_problems" */
  treatmentplanProblemsAggregate: TreatmentplanProblemsAggregate;
  /** fetch data from the table: "server_treatmentplan_problems" using primary key columns */
  treatmentplanProblemsByPk?: Maybe<TreatmentplanProblems>;
  /** fetch data from the table: "server_treatmentplantemplate" */
  treatmentplanTemplate: Array<TreatmentplanTemplate>;
  /** fetch aggregated fields from the table: "server_treatmentplantemplate" */
  treatmentplanTemplateAggregate: TreatmentplanTemplateAggregate;
  /** fetch data from the table: "server_treatmentplantemplate_assessments" */
  treatmentplanTemplateAssessment: Array<TreatmentplanTemplateAssessment>;
  /** fetch aggregated fields from the table: "server_treatmentplantemplate_assessments" */
  treatmentplanTemplateAssessmentAggregate: TreatmentplanTemplateAssessmentAggregate;
  /** fetch data from the table: "server_treatmentplantemplate_assessments" using primary key columns */
  treatmentplanTemplateAssessmentByPk?: Maybe<TreatmentplanTemplateAssessment>;
  /** fetch data from the table: "server_treatmentplantemplate" using primary key columns */
  treatmentplanTemplateByPk?: Maybe<TreatmentplanTemplate>;
  /** fetch data from the table: "server_customuser" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "server_customuser" */
  userAggregate: UserAggregate;
  /** fetch data from the table: "server_customuser" using primary key columns */
  userByPk?: Maybe<User>;
  /** fetch data from the table: "server_customuser_groups" */
  userGroups: Array<UserGroups>;
  /** fetch aggregated fields from the table: "server_customuser_groups" */
  userGroupsAggregate: UserGroupsAggregate;
  /** fetch data from the table: "server_customuser_groups" using primary key columns */
  userGroupsByPk?: Maybe<UserGroups>;
  /** fetch data from the table: "server_customuser_user_permissions" */
  userPermissions: Array<UserPermissions>;
  /** fetch aggregated fields from the table: "server_customuser_user_permissions" */
  userPermissionsAggregate: UserPermissionsAggregate;
  /** fetch data from the table: "server_customuser_user_permissions" using primary key columns */
  userPermissionsByPk?: Maybe<UserPermissions>;
  /** fetch data from the table: "server_visitrebill" */
  visitRebill: Array<VisitRebill>;
  /** fetch aggregated fields from the table: "server_visitrebill" */
  visitRebillAggregate: VisitRebillAggregate;
  /** fetch data from the table: "server_visitrebill" using primary key columns */
  visitRebillByPk?: Maybe<VisitRebill>;
  /** fetch data from the table: "server_vitals" */
  vitals: Array<Vitals>;
  /** fetch aggregated fields from the table: "server_vitals" */
  vitalsAggregate: VitalsAggregate;
  /** fetch data from the table: "server_vitals" using primary key columns */
  vitalsByPk?: Maybe<Vitals>;
};


export type Query_RootAddressArgs = {
  distinctOn?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type Query_RootAddressAggregateArgs = {
  distinctOn?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type Query_RootAddressByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAgencyArgs = {
  distinctOn?: InputMaybe<Array<AgencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyOrderBy>>;
  where?: InputMaybe<AgencyBoolExp>;
};


export type Query_RootAgencyAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyOrderBy>>;
  where?: InputMaybe<AgencyBoolExp>;
};


export type Query_RootAgencyByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAgencyLocationArgs = {
  distinctOn?: InputMaybe<Array<AgencyLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyLocationOrderBy>>;
  where?: InputMaybe<AgencyLocationBoolExp>;
};


export type Query_RootAgencyLocationAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyLocationOrderBy>>;
  where?: InputMaybe<AgencyLocationBoolExp>;
};


export type Query_RootAgencyLocationByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAgencyPayersArgs = {
  distinctOn?: InputMaybe<Array<AgencyPayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyPayersOrderBy>>;
  where?: InputMaybe<AgencyPayersBoolExp>;
};


export type Query_RootAgencyPayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyPayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyPayersOrderBy>>;
  where?: InputMaybe<AgencyPayersBoolExp>;
};


export type Query_RootAgencyPayersByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAgencyServicesArgs = {
  distinctOn?: InputMaybe<Array<AgencyServicesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyServicesOrderBy>>;
  where?: InputMaybe<AgencyServicesBoolExp>;
};


export type Query_RootAgencyServicesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyServicesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyServicesOrderBy>>;
  where?: InputMaybe<AgencyServicesBoolExp>;
};


export type Query_RootAgencyServicesByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAgencyconnectionArgs = {
  distinctOn?: InputMaybe<Array<AgencyconnectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyconnectionOrderBy>>;
  where?: InputMaybe<AgencyconnectionBoolExp>;
};


export type Query_RootAgencyconnectionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyconnectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyconnectionOrderBy>>;
  where?: InputMaybe<AgencyconnectionBoolExp>;
};


export type Query_RootAgencyconnectionByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAgencyjoinrequestArgs = {
  distinctOn?: InputMaybe<Array<AgencyjoinrequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyjoinrequestOrderBy>>;
  where?: InputMaybe<AgencyjoinrequestBoolExp>;
};


export type Query_RootAgencyjoinrequestAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyjoinrequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyjoinrequestOrderBy>>;
  where?: InputMaybe<AgencyjoinrequestBoolExp>;
};


export type Query_RootAgencyjoinrequestByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAllergyArgs = {
  distinctOn?: InputMaybe<Array<AllergySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AllergyOrderBy>>;
  where?: InputMaybe<AllergyBoolExp>;
};


export type Query_RootAllergyAggregateArgs = {
  distinctOn?: InputMaybe<Array<AllergySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AllergyOrderBy>>;
  where?: InputMaybe<AllergyBoolExp>;
};


export type Query_RootAllergyByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAppointmentArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


export type Query_RootAppointmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


export type Query_RootAppointmentByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAssessmentArgs = {
  distinctOn?: InputMaybe<Array<AssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentOrderBy>>;
  where?: InputMaybe<AssessmentBoolExp>;
};


export type Query_RootAssessmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentOrderBy>>;
  where?: InputMaybe<AssessmentBoolExp>;
};


export type Query_RootAssessmentByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAssessmentItemArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemOrderBy>>;
  where?: InputMaybe<AssessmentItemBoolExp>;
};


export type Query_RootAssessmentItemAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemOrderBy>>;
  where?: InputMaybe<AssessmentItemBoolExp>;
};


export type Query_RootAssessmentItemByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAssessmentItemValueArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemValueOrderBy>>;
  where?: InputMaybe<AssessmentItemValueBoolExp>;
};


export type Query_RootAssessmentItemValueAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemValueOrderBy>>;
  where?: InputMaybe<AssessmentItemValueBoolExp>;
};


export type Query_RootAssessmentItemValueByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBaseProtocolOrderArgs = {
  distinctOn?: InputMaybe<Array<BaseProtocolOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseProtocolOrderOrderBy>>;
  where?: InputMaybe<BaseProtocolOrderBoolExp>;
};


export type Query_RootBaseProtocolOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<BaseProtocolOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseProtocolOrderOrderBy>>;
  where?: InputMaybe<BaseProtocolOrderBoolExp>;
};


export type Query_RootBaseProtocolOrderByPkArgs = {
  dependantorderPtrId: Scalars['Int'];
};


export type Query_RootBaseorderArgs = {
  distinctOn?: InputMaybe<Array<BaseorderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseorderOrderBy>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


export type Query_RootBaseorderAggregateArgs = {
  distinctOn?: InputMaybe<Array<BaseorderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseorderOrderBy>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


export type Query_RootBaseorderByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCaretimeArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


export type Query_RootCaretimeAggregateArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


export type Query_RootCaretimeByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCaretimeProblemsArgs = {
  distinctOn?: InputMaybe<Array<CaretimeProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeProblemsOrderBy>>;
  where?: InputMaybe<CaretimeProblemsBoolExp>;
};


export type Query_RootCaretimeProblemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CaretimeProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeProblemsOrderBy>>;
  where?: InputMaybe<CaretimeProblemsBoolExp>;
};


export type Query_RootCaretimeProblemsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClaimArgs = {
  distinctOn?: InputMaybe<Array<ClaimSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClaimOrderBy>>;
  where?: InputMaybe<ClaimBoolExp>;
};


export type Query_RootClaimAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClaimSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClaimOrderBy>>;
  where?: InputMaybe<ClaimBoolExp>;
};


export type Query_RootClaimByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClinicalNoteFutureReferralsArgs = {
  distinctOn?: InputMaybe<Array<ClinicalNoteFutureReferralsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalNoteFutureReferralsOrderBy>>;
  where?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
};


export type Query_RootClinicalNoteFutureReferralsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalNoteFutureReferralsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalNoteFutureReferralsOrderBy>>;
  where?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
};


export type Query_RootClinicalNoteFutureReferralsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClinicalnoteArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteOrderBy>>;
  where?: InputMaybe<ClinicalnoteBoolExp>;
};


export type Query_RootClinicalnoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteOrderBy>>;
  where?: InputMaybe<ClinicalnoteBoolExp>;
};


export type Query_RootClinicalnoteByPkArgs = {
  appointmentId: Scalars['Int'];
};


export type Query_RootClinicalnoteFieldArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldBoolExp>;
};


export type Query_RootClinicalnoteFieldAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldBoolExp>;
};


export type Query_RootClinicalnoteFieldByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClinicalnoteFieldValueArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldValueOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
};


export type Query_RootClinicalnoteFieldValueAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldValueOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
};


export type Query_RootClinicalnoteFieldValueByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClinicalnoteTemplateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateBoolExp>;
};


export type Query_RootClinicalnoteTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateBoolExp>;
};


export type Query_RootClinicalnoteTemplateByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClinicalnoteTemplateProviderClassificationsArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
};


export type Query_RootClinicalnoteTemplateProviderClassificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
};


export type Query_RootClinicalnoteTemplateProviderClassificationsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCodesetArgs = {
  distinctOn?: InputMaybe<Array<CodesetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetOrderBy>>;
  where?: InputMaybe<CodesetBoolExp>;
};


export type Query_RootCodesetAddonArgs = {
  distinctOn?: InputMaybe<Array<CodesetAddonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetAddonOrderBy>>;
  where?: InputMaybe<CodesetAddonBoolExp>;
};


export type Query_RootCodesetAddonAggregateArgs = {
  distinctOn?: InputMaybe<Array<CodesetAddonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetAddonOrderBy>>;
  where?: InputMaybe<CodesetAddonBoolExp>;
};


export type Query_RootCodesetAddonByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCodesetAggregateArgs = {
  distinctOn?: InputMaybe<Array<CodesetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetOrderBy>>;
  where?: InputMaybe<CodesetBoolExp>;
};


export type Query_RootCodesetBaseArgs = {
  distinctOn?: InputMaybe<Array<CodesetBaseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetBaseOrderBy>>;
  where?: InputMaybe<CodesetBaseBoolExp>;
};


export type Query_RootCodesetBaseAggregateArgs = {
  distinctOn?: InputMaybe<Array<CodesetBaseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetBaseOrderBy>>;
  where?: InputMaybe<CodesetBaseBoolExp>;
};


export type Query_RootCodesetBaseByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCodesetByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootConsentArgs = {
  distinctOn?: InputMaybe<Array<ConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConsentOrderBy>>;
  where?: InputMaybe<ConsentBoolExp>;
};


export type Query_RootConsentAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConsentOrderBy>>;
  where?: InputMaybe<ConsentBoolExp>;
};


export type Query_RootConsentByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCpoClaimArgs = {
  distinctOn?: InputMaybe<Array<CpoClaimSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CpoClaimOrderBy>>;
  where?: InputMaybe<CpoClaimBoolExp>;
};


export type Query_RootCpoClaimAggregateArgs = {
  distinctOn?: InputMaybe<Array<CpoClaimSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CpoClaimOrderBy>>;
  where?: InputMaybe<CpoClaimBoolExp>;
};


export type Query_RootCpoClaimByPkArgs = {
  claimPtrId: Scalars['Int'];
};


export type Query_RootDependantOrderArgs = {
  distinctOn?: InputMaybe<Array<DependantOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DependantOrderOrderBy>>;
  where?: InputMaybe<DependantOrderBoolExp>;
};


export type Query_RootDependantOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<DependantOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DependantOrderOrderBy>>;
  where?: InputMaybe<DependantOrderBoolExp>;
};


export type Query_RootDependantOrderByPkArgs = {
  baseorderPtrId: Scalars['Int'];
};


export type Query_RootDiagnosisAbbreviationArgs = {
  distinctOn?: InputMaybe<Array<DiagnosisAbbreviationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DiagnosisAbbreviationOrderBy>>;
  where?: InputMaybe<DiagnosisAbbreviationBoolExp>;
};


export type Query_RootDiagnosisAbbreviationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiagnosisAbbreviationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DiagnosisAbbreviationOrderBy>>;
  where?: InputMaybe<DiagnosisAbbreviationBoolExp>;
};


export type Query_RootDiagnosisAbbreviationByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDocumentArgs = {
  distinctOn?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


export type Query_RootDocumentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


export type Query_RootDocumentByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDocumentPendingReviewArgs = {
  distinctOn?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentPendingReviewOrderBy>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};


export type Query_RootDocumentPendingReviewAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentPendingReviewOrderBy>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};


export type Query_RootDocumentPendingReviewByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootExternalSignedOrderArgs = {
  distinctOn?: InputMaybe<Array<ExternalSignedOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalSignedOrderOrderBy>>;
  where?: InputMaybe<ExternalSignedOrderBoolExp>;
};


export type Query_RootExternalSignedOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExternalSignedOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalSignedOrderOrderBy>>;
  where?: InputMaybe<ExternalSignedOrderBoolExp>;
};


export type Query_RootExternalSignedOrderByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootExternalStaffMyPatientsArgs = {
  distinctOn?: InputMaybe<Array<ExternalStaffMyPatientsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalStaffMyPatientsOrderBy>>;
  where?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
};


export type Query_RootExternalStaffMyPatientsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExternalStaffMyPatientsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalStaffMyPatientsOrderBy>>;
  where?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
};


export type Query_RootExternalStaffMyPatientsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootExternalstaffArgs = {
  distinctOn?: InputMaybe<Array<ExternalstaffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalstaffOrderBy>>;
  where?: InputMaybe<ExternalstaffBoolExp>;
};


export type Query_RootExternalstaffAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExternalstaffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalstaffOrderBy>>;
  where?: InputMaybe<ExternalstaffBoolExp>;
};


export type Query_RootExternalstaffByPkArgs = {
  userId: Scalars['Int'];
};


export type Query_RootFaxArgs = {
  distinctOn?: InputMaybe<Array<FaxSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxOrderBy>>;
  where?: InputMaybe<FaxBoolExp>;
};


export type Query_RootFaxAggregateArgs = {
  distinctOn?: InputMaybe<Array<FaxSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxOrderBy>>;
  where?: InputMaybe<FaxBoolExp>;
};


export type Query_RootFaxByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFaxDocumentsArgs = {
  distinctOn?: InputMaybe<Array<FaxDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxDocumentsOrderBy>>;
  where?: InputMaybe<FaxDocumentsBoolExp>;
};


export type Query_RootFaxDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FaxDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxDocumentsOrderBy>>;
  where?: InputMaybe<FaxDocumentsBoolExp>;
};


export type Query_RootFaxDocumentsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFreeTextOrderArgs = {
  distinctOn?: InputMaybe<Array<FreeTextOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FreeTextOrderOrderBy>>;
  where?: InputMaybe<FreeTextOrderBoolExp>;
};


export type Query_RootFreeTextOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<FreeTextOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FreeTextOrderOrderBy>>;
  where?: InputMaybe<FreeTextOrderBoolExp>;
};


export type Query_RootFreeTextOrderByPkArgs = {
  baseorderPtrId: Scalars['Int'];
};


export type Query_RootFrequencyOrderArgs = {
  distinctOn?: InputMaybe<Array<FrequencyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FrequencyOrderOrderBy>>;
  where?: InputMaybe<FrequencyOrderBoolExp>;
};


export type Query_RootFrequencyOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<FrequencyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FrequencyOrderOrderBy>>;
  where?: InputMaybe<FrequencyOrderBoolExp>;
};


export type Query_RootFrequencyOrderByPkArgs = {
  dependantorderPtrId: Scalars['Int'];
};


export type Query_RootFrequencyProtocolArgs = {
  distinctOn?: InputMaybe<Array<FrequencyProtocolSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FrequencyProtocolOrderBy>>;
  where?: InputMaybe<FrequencyProtocolBoolExp>;
};


export type Query_RootFrequencyProtocolAggregateArgs = {
  distinctOn?: InputMaybe<Array<FrequencyProtocolSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FrequencyProtocolOrderBy>>;
  where?: InputMaybe<FrequencyProtocolBoolExp>;
};


export type Query_RootFrequencyProtocolByPkArgs = {
  baseprotocolorderPtrId: Scalars['Int'];
};


export type Query_RootHcpcsArgs = {
  distinctOn?: InputMaybe<Array<HcpcsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<HcpcsOrderBy>>;
  where?: InputMaybe<HcpcsBoolExp>;
};


export type Query_RootHcpcsAggregateArgs = {
  distinctOn?: InputMaybe<Array<HcpcsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<HcpcsOrderBy>>;
  where?: InputMaybe<HcpcsBoolExp>;
};


export type Query_RootHcpcsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInsuranceplanArgs = {
  distinctOn?: InputMaybe<Array<InsuranceplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceplanOrderBy>>;
  where?: InputMaybe<InsuranceplanBoolExp>;
};


export type Query_RootInsuranceplanAggregateArgs = {
  distinctOn?: InputMaybe<Array<InsuranceplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceplanOrderBy>>;
  where?: InputMaybe<InsuranceplanBoolExp>;
};


export type Query_RootInsuranceplanByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIntegrationArgs = {
  distinctOn?: InputMaybe<Array<IntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationOrderBy>>;
  where?: InputMaybe<IntegrationBoolExp>;
};


export type Query_RootIntegrationAggregateArgs = {
  distinctOn?: InputMaybe<Array<IntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationOrderBy>>;
  where?: InputMaybe<IntegrationBoolExp>;
};


export type Query_RootIntegrationByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInternalstaffArgs = {
  distinctOn?: InputMaybe<Array<InternalstaffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InternalstaffOrderBy>>;
  where?: InputMaybe<InternalstaffBoolExp>;
};


export type Query_RootInternalstaffAggregateArgs = {
  distinctOn?: InputMaybe<Array<InternalstaffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InternalstaffOrderBy>>;
  where?: InputMaybe<InternalstaffBoolExp>;
};


export type Query_RootInternalstaffByPkArgs = {
  userId: Scalars['Int'];
};


export type Query_RootLegacyOrderArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


export type Query_RootLegacyOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


export type Query_RootLegacyOrderByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLegacyOrderFrequencyArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderFrequencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderFrequencyOrderBy>>;
  where?: InputMaybe<LegacyOrderFrequencyBoolExp>;
};


export type Query_RootLegacyOrderFrequencyAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderFrequencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderFrequencyOrderBy>>;
  where?: InputMaybe<LegacyOrderFrequencyBoolExp>;
};


export type Query_RootLegacyOrderFrequencyByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLegacyOrderMedicationsArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderMedicationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderMedicationsOrderBy>>;
  where?: InputMaybe<LegacyOrderMedicationsBoolExp>;
};


export type Query_RootLegacyOrderMedicationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderMedicationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderMedicationsOrderBy>>;
  where?: InputMaybe<LegacyOrderMedicationsBoolExp>;
};


export type Query_RootLegacyOrderMedicationsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLegacyOrderOrderableFieldValueArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderOrderableFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderableFieldValueOrderBy>>;
  where?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
};


export type Query_RootLegacyOrderOrderableFieldValueAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderOrderableFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderableFieldValueOrderBy>>;
  where?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
};


export type Query_RootLegacyOrderOrderableFieldValueByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLegacyOrderQualificationArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderQualificationOrderBy>>;
  where?: InputMaybe<LegacyOrderQualificationBoolExp>;
};


export type Query_RootLegacyOrderQualificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderQualificationOrderBy>>;
  where?: InputMaybe<LegacyOrderQualificationBoolExp>;
};


export type Query_RootLegacyOrderQualificationByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLegacyTreatmentplanOrderArgs = {
  distinctOn?: InputMaybe<Array<LegacyTreatmentplanOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyTreatmentplanOrderOrderBy>>;
  where?: InputMaybe<LegacyTreatmentplanOrderBoolExp>;
};


export type Query_RootLegacyTreatmentplanOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyTreatmentplanOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyTreatmentplanOrderOrderBy>>;
  where?: InputMaybe<LegacyTreatmentplanOrderBoolExp>;
};


export type Query_RootLegacyTreatmentplanOrderByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLineitemArgs = {
  distinctOn?: InputMaybe<Array<LineitemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LineitemOrderBy>>;
  where?: InputMaybe<LineitemBoolExp>;
};


export type Query_RootLineitemAggregateArgs = {
  distinctOn?: InputMaybe<Array<LineitemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LineitemOrderBy>>;
  where?: InputMaybe<LineitemBoolExp>;
};


export type Query_RootLineitemByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMedicationArgs = {
  distinctOn?: InputMaybe<Array<MedicationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationOrderBy>>;
  where?: InputMaybe<MedicationBoolExp>;
};


export type Query_RootMedicationAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationOrderBy>>;
  where?: InputMaybe<MedicationBoolExp>;
};


export type Query_RootMedicationByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMedicationOrderArgs = {
  distinctOn?: InputMaybe<Array<MedicationOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationOrderOrderBy>>;
  where?: InputMaybe<MedicationOrderBoolExp>;
};


export type Query_RootMedicationOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicationOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationOrderOrderBy>>;
  where?: InputMaybe<MedicationOrderBoolExp>;
};


export type Query_RootMedicationOrderByPkArgs = {
  baseorderPtrId: Scalars['Int'];
};


export type Query_RootMedicationorderMedicationsArgs = {
  distinctOn?: InputMaybe<Array<MedicationorderMedicationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationorderMedicationsOrderBy>>;
  where?: InputMaybe<MedicationorderMedicationsBoolExp>;
};


export type Query_RootMedicationorderMedicationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicationorderMedicationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationorderMedicationsOrderBy>>;
  where?: InputMaybe<MedicationorderMedicationsBoolExp>;
};


export type Query_RootMedicationorderMedicationsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMessageArgs = {
  distinctOn?: InputMaybe<Array<MessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderBy>>;
  where?: InputMaybe<MessageBoolExp>;
};


export type Query_RootMessageAggregateArgs = {
  distinctOn?: InputMaybe<Array<MessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderBy>>;
  where?: InputMaybe<MessageBoolExp>;
};


export type Query_RootMessageByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrderOrderableFieldArgs = {
  distinctOn?: InputMaybe<Array<OrderOrderableFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderableFieldOrderBy>>;
  where?: InputMaybe<OrderOrderableFieldBoolExp>;
};


export type Query_RootOrderOrderableFieldAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderOrderableFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderableFieldOrderBy>>;
  where?: InputMaybe<OrderOrderableFieldBoolExp>;
};


export type Query_RootOrderOrderableFieldByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrderOrderableLegacyArgs = {
  distinctOn?: InputMaybe<Array<OrderOrderableLegacySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderableLegacyOrderBy>>;
  where?: InputMaybe<OrderOrderableLegacyBoolExp>;
};


export type Query_RootOrderOrderableLegacyAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderOrderableLegacySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderableLegacyOrderBy>>;
  where?: InputMaybe<OrderOrderableLegacyBoolExp>;
};


export type Query_RootOrderOrderableLegacyByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrderableArgs = {
  distinctOn?: InputMaybe<Array<OrderableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderBy>>;
  where?: InputMaybe<OrderableBoolExp>;
};


export type Query_RootOrderableAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderBy>>;
  where?: InputMaybe<OrderableBoolExp>;
};


export type Query_RootOrderableByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrderableCategoryArgs = {
  distinctOn?: InputMaybe<Array<OrderableCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableCategoryOrderBy>>;
  where?: InputMaybe<OrderableCategoryBoolExp>;
};


export type Query_RootOrderableCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableCategoryOrderBy>>;
  where?: InputMaybe<OrderableCategoryBoolExp>;
};


export type Query_RootOrderableCategoryByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrderableOrderArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderOrderBy>>;
  where?: InputMaybe<OrderableOrderBoolExp>;
};


export type Query_RootOrderableOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderOrderBy>>;
  where?: InputMaybe<OrderableOrderBoolExp>;
};


export type Query_RootOrderableOrderByPkArgs = {
  baseorderPtrId: Scalars['Int'];
};


export type Query_RootOrderableOrderQualificationArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderQualificationOrderBy>>;
  where?: InputMaybe<OrderableOrderQualificationBoolExp>;
};


export type Query_RootOrderableOrderQualificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderQualificationOrderBy>>;
  where?: InputMaybe<OrderableOrderQualificationBoolExp>;
};


export type Query_RootOrderableOrderQualificationByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrderableQualificationArgs = {
  distinctOn?: InputMaybe<Array<OrderableQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableQualificationOrderBy>>;
  where?: InputMaybe<OrderableQualificationBoolExp>;
};


export type Query_RootOrderableQualificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableQualificationOrderBy>>;
  where?: InputMaybe<OrderableQualificationBoolExp>;
};


export type Query_RootOrderableQualificationByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrderableSupplierArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierOrderBy>>;
  where?: InputMaybe<OrderableSupplierBoolExp>;
};


export type Query_RootOrderableSupplierAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierOrderBy>>;
  where?: InputMaybe<OrderableSupplierBoolExp>;
};


export type Query_RootOrderableSupplierByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrderableSupplierPayerConnectionsArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierPayerConnectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierPayerConnectionsOrderBy>>;
  where?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
};


export type Query_RootOrderableSupplierPayerConnectionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierPayerConnectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierPayerConnectionsOrderBy>>;
  where?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
};


export type Query_RootOrderableSupplierPayerConnectionsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrderactionArgs = {
  distinctOn?: InputMaybe<Array<OrderactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderactionOrderBy>>;
  where?: InputMaybe<OrderactionBoolExp>;
};


export type Query_RootOrderactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderactionOrderBy>>;
  where?: InputMaybe<OrderactionBoolExp>;
};


export type Query_RootOrderactionByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPatientArgs = {
  distinctOn?: InputMaybe<Array<PatientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientOrderBy>>;
  where?: InputMaybe<PatientBoolExp>;
};


export type Query_RootPatientAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientOrderBy>>;
  where?: InputMaybe<PatientBoolExp>;
};


export type Query_RootPatientAliasArgs = {
  distinctOn?: InputMaybe<Array<PatientAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientAliasOrderBy>>;
  where?: InputMaybe<PatientAliasBoolExp>;
};


export type Query_RootPatientAliasAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientAliasOrderBy>>;
  where?: InputMaybe<PatientAliasBoolExp>;
};


export type Query_RootPatientAliasByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPatientByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPatientCommunicationArgs = {
  distinctOn?: InputMaybe<Array<PatientCommunicationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientCommunicationOrderBy>>;
  where?: InputMaybe<PatientCommunicationBoolExp>;
};


export type Query_RootPatientCommunicationAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientCommunicationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientCommunicationOrderBy>>;
  where?: InputMaybe<PatientCommunicationBoolExp>;
};


export type Query_RootPatientCommunicationByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPatientContactPersonArgs = {
  distinctOn?: InputMaybe<Array<PatientContactPersonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientContactPersonOrderBy>>;
  where?: InputMaybe<PatientContactPersonBoolExp>;
};


export type Query_RootPatientContactPersonAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientContactPersonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientContactPersonOrderBy>>;
  where?: InputMaybe<PatientContactPersonBoolExp>;
};


export type Query_RootPatientContactPersonByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPatientEligibilityCheckArgs = {
  distinctOn?: InputMaybe<Array<PatientEligibilityCheckSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientEligibilityCheckOrderBy>>;
  where?: InputMaybe<PatientEligibilityCheckBoolExp>;
};


export type Query_RootPatientEligibilityCheckAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientEligibilityCheckSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientEligibilityCheckOrderBy>>;
  where?: InputMaybe<PatientEligibilityCheckBoolExp>;
};


export type Query_RootPatientEligibilityCheckByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPatientIntakeArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeOrderBy>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


export type Query_RootPatientIntakeAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeOrderBy>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


export type Query_RootPatientIntakeByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPatientIntakeRecordsLocationsArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeRecordsLocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeRecordsLocationsOrderBy>>;
  where?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
};


export type Query_RootPatientIntakeRecordsLocationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeRecordsLocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeRecordsLocationsOrderBy>>;
  where?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
};


export type Query_RootPatientIntakeRecordsLocationsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPatientPhoneArgs = {
  distinctOn?: InputMaybe<Array<PatientPhoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientPhoneOrderBy>>;
  where?: InputMaybe<PatientPhoneBoolExp>;
};


export type Query_RootPatientPhoneAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientPhoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientPhoneOrderBy>>;
  where?: InputMaybe<PatientPhoneBoolExp>;
};


export type Query_RootPatientPhoneByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPatientPhotoArgs = {
  distinctOn?: InputMaybe<Array<PatientPhotoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientPhotoOrderBy>>;
  where?: InputMaybe<PatientPhotoBoolExp>;
};


export type Query_RootPatientPhotoAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientPhotoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientPhotoOrderBy>>;
  where?: InputMaybe<PatientPhotoBoolExp>;
};


export type Query_RootPatientPhotoByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPatientStatusArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusOrderBy>>;
  where?: InputMaybe<PatientStatusBoolExp>;
};


export type Query_RootPatientStatusActionArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusActionOrderBy>>;
  where?: InputMaybe<PatientStatusActionBoolExp>;
};


export type Query_RootPatientStatusActionAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusActionOrderBy>>;
  where?: InputMaybe<PatientStatusActionBoolExp>;
};


export type Query_RootPatientStatusActionByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPatientStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusOrderBy>>;
  where?: InputMaybe<PatientStatusBoolExp>;
};


export type Query_RootPatientStatusByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPatientStatusSubsequentStatusesArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusSubsequentStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusSubsequentStatusesOrderBy>>;
  where?: InputMaybe<PatientStatusSubsequentStatusesBoolExp>;
};


export type Query_RootPatientStatusSubsequentStatusesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusSubsequentStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusSubsequentStatusesOrderBy>>;
  where?: InputMaybe<PatientStatusSubsequentStatusesBoolExp>;
};


export type Query_RootPatientStatusSubsequentStatusesByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPayerArgs = {
  distinctOn?: InputMaybe<Array<PayerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PayerOrderBy>>;
  where?: InputMaybe<PayerBoolExp>;
};


export type Query_RootPayerAggregateArgs = {
  distinctOn?: InputMaybe<Array<PayerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PayerOrderBy>>;
  where?: InputMaybe<PayerBoolExp>;
};


export type Query_RootPayerByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPracticegroupArgs = {
  distinctOn?: InputMaybe<Array<PracticegroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PracticegroupOrderBy>>;
  where?: InputMaybe<PracticegroupBoolExp>;
};


export type Query_RootPracticegroupAggregateArgs = {
  distinctOn?: InputMaybe<Array<PracticegroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PracticegroupOrderBy>>;
  where?: InputMaybe<PracticegroupBoolExp>;
};


export type Query_RootPracticegroupByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProblemArgs = {
  distinctOn?: InputMaybe<Array<ProblemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemOrderBy>>;
  where?: InputMaybe<ProblemBoolExp>;
};


export type Query_RootProblemAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProblemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemOrderBy>>;
  where?: InputMaybe<ProblemBoolExp>;
};


export type Query_RootProblemByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProblemPlanArgs = {
  distinctOn?: InputMaybe<Array<ProblemPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemPlanOrderBy>>;
  where?: InputMaybe<ProblemPlanBoolExp>;
};


export type Query_RootProblemPlanAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProblemPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemPlanOrderBy>>;
  where?: InputMaybe<ProblemPlanBoolExp>;
};


export type Query_RootProblemPlanByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProcedureArgs = {
  distinctOn?: InputMaybe<Array<ProcedureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureOrderBy>>;
  where?: InputMaybe<ProcedureBoolExp>;
};


export type Query_RootProcedureAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcedureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureOrderBy>>;
  where?: InputMaybe<ProcedureBoolExp>;
};


export type Query_RootProcedureByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProcedureHcpcsArgs = {
  distinctOn?: InputMaybe<Array<ProcedureHcpcsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureHcpcsOrderBy>>;
  where?: InputMaybe<ProcedureHcpcsBoolExp>;
};


export type Query_RootProcedureHcpcsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcedureHcpcsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureHcpcsOrderBy>>;
  where?: InputMaybe<ProcedureHcpcsBoolExp>;
};


export type Query_RootProcedureHcpcsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProcedureRenderedArgs = {
  distinctOn?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureRenderedOrderBy>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};


export type Query_RootProcedureRenderedAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureRenderedOrderBy>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};


export type Query_RootProcedureRenderedByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProviderArgs = {
  distinctOn?: InputMaybe<Array<ProviderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderOrderBy>>;
  where?: InputMaybe<ProviderBoolExp>;
};


export type Query_RootProviderAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderOrderBy>>;
  where?: InputMaybe<ProviderBoolExp>;
};


export type Query_RootProviderByPkArgs = {
  internalStaffId: Scalars['Int'];
};


export type Query_RootProviderClassificationArgs = {
  distinctOn?: InputMaybe<Array<ProviderClassificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderClassificationOrderBy>>;
  where?: InputMaybe<ProviderClassificationBoolExp>;
};


export type Query_RootProviderClassificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderClassificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderClassificationOrderBy>>;
  where?: InputMaybe<ProviderClassificationBoolExp>;
};


export type Query_RootProviderClassificationByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProviderScheduleArgs = {
  distinctOn?: InputMaybe<Array<ProviderScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderScheduleOrderBy>>;
  where?: InputMaybe<ProviderScheduleBoolExp>;
};


export type Query_RootProviderScheduleAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderScheduleOrderBy>>;
  where?: InputMaybe<ProviderScheduleBoolExp>;
};


export type Query_RootProviderScheduleByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRecordsRequestDestinationArgs = {
  distinctOn?: InputMaybe<Array<RecordsRequestDestinationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordsRequestDestinationOrderBy>>;
  where?: InputMaybe<RecordsRequestDestinationBoolExp>;
};


export type Query_RootRecordsRequestDestinationAggregateArgs = {
  distinctOn?: InputMaybe<Array<RecordsRequestDestinationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordsRequestDestinationOrderBy>>;
  where?: InputMaybe<RecordsRequestDestinationBoolExp>;
};


export type Query_RootRecordsRequestDestinationByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootReferralArgs = {
  distinctOn?: InputMaybe<Array<ReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralOrderBy>>;
  where?: InputMaybe<ReferralBoolExp>;
};


export type Query_RootReferralAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralOrderBy>>;
  where?: InputMaybe<ReferralBoolExp>;
};


export type Query_RootReferralAttachmentsArgs = {
  distinctOn?: InputMaybe<Array<ReferralAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralAttachmentsOrderBy>>;
  where?: InputMaybe<ReferralAttachmentsBoolExp>;
};


export type Query_RootReferralAttachmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReferralAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralAttachmentsOrderBy>>;
  where?: InputMaybe<ReferralAttachmentsBoolExp>;
};


export type Query_RootReferralAttachmentsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootReferralByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRenderedAssessmentArgs = {
  distinctOn?: InputMaybe<Array<RenderedAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RenderedAssessmentOrderBy>>;
  where?: InputMaybe<RenderedAssessmentBoolExp>;
};


export type Query_RootRenderedAssessmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<RenderedAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RenderedAssessmentOrderBy>>;
  where?: InputMaybe<RenderedAssessmentBoolExp>;
};


export type Query_RootRenderedAssessmentByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSchedulingActionArgs = {
  distinctOn?: InputMaybe<Array<SchedulingActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchedulingActionOrderBy>>;
  where?: InputMaybe<SchedulingActionBoolExp>;
};


export type Query_RootSchedulingActionAggregateArgs = {
  distinctOn?: InputMaybe<Array<SchedulingActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchedulingActionOrderBy>>;
  where?: InputMaybe<SchedulingActionBoolExp>;
};


export type Query_RootSchedulingActionByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootServiceTypeArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ServiceTypeOrderBy>>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};


export type Query_RootServiceTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ServiceTypeOrderBy>>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};


export type Query_RootServiceTypeByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSignedConsentArgs = {
  distinctOn?: InputMaybe<Array<SignedConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignedConsentOrderBy>>;
  where?: InputMaybe<SignedConsentBoolExp>;
};


export type Query_RootSignedConsentAggregateArgs = {
  distinctOn?: InputMaybe<Array<SignedConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignedConsentOrderBy>>;
  where?: InputMaybe<SignedConsentBoolExp>;
};


export type Query_RootSignedConsentByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSignoffArgs = {
  distinctOn?: InputMaybe<Array<SignoffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignoffOrderBy>>;
  where?: InputMaybe<SignoffBoolExp>;
};


export type Query_RootSignoffAggregateArgs = {
  distinctOn?: InputMaybe<Array<SignoffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignoffOrderBy>>;
  where?: InputMaybe<SignoffBoolExp>;
};


export type Query_RootSignoffByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSignoffTypeArgs = {
  distinctOn?: InputMaybe<Array<SignoffTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignoffTypeOrderBy>>;
  where?: InputMaybe<SignoffTypeBoolExp>;
};


export type Query_RootSignoffTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<SignoffTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignoffTypeOrderBy>>;
  where?: InputMaybe<SignoffTypeBoolExp>;
};


export type Query_RootSignoffTypeByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTelemedAppointmentRequestArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};


export type Query_RootTelemedAppointmentRequestAccessCodeArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestAccessCodeBoolExp>;
};


export type Query_RootTelemedAppointmentRequestAccessCodeAggregateArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestAccessCodeBoolExp>;
};


export type Query_RootTelemedAppointmentRequestAccessCodeByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTelemedAppointmentRequestAggregateArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};


export type Query_RootTelemedAppointmentRequestByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootThreadCategoryArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryOrderBy>>;
  where?: InputMaybe<ThreadCategoryBoolExp>;
};


export type Query_RootThreadCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryOrderBy>>;
  where?: InputMaybe<ThreadCategoryBoolExp>;
};


export type Query_RootThreadCategoryByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootThreadCategoryStatusArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategoryStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryStatusOrderBy>>;
  where?: InputMaybe<ThreadCategoryStatusBoolExp>;
};


export type Query_RootThreadCategoryStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategoryStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryStatusOrderBy>>;
  where?: InputMaybe<ThreadCategoryStatusBoolExp>;
};


export type Query_RootThreadCategoryStatusByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootThreadFieldArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldOrderBy>>;
  where?: InputMaybe<ThreadFieldBoolExp>;
};


export type Query_RootThreadFieldAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldOrderBy>>;
  where?: InputMaybe<ThreadFieldBoolExp>;
};


export type Query_RootThreadFieldByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootThreadFieldValueArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldValueOrderBy>>;
  where?: InputMaybe<ThreadFieldValueBoolExp>;
};


export type Query_RootThreadFieldValueAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldValueOrderBy>>;
  where?: InputMaybe<ThreadFieldValueBoolExp>;
};


export type Query_RootThreadFieldValueByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTicketSubscriptionArgs = {
  distinctOn?: InputMaybe<Array<TicketSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketSubscriptionOrderBy>>;
  where?: InputMaybe<TicketSubscriptionBoolExp>;
};


export type Query_RootTicketSubscriptionAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketSubscriptionOrderBy>>;
  where?: InputMaybe<TicketSubscriptionBoolExp>;
};


export type Query_RootTicketSubscriptionByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTicketThreadArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};


export type Query_RootTicketThreadAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};


export type Query_RootTicketThreadByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTreatmentplanArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanOrderBy>>;
  where?: InputMaybe<TreatmentplanBoolExp>;
};


export type Query_RootTreatmentplanAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanOrderBy>>;
  where?: InputMaybe<TreatmentplanBoolExp>;
};


export type Query_RootTreatmentplanAssessmentArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanAssessmentBoolExp>;
};


export type Query_RootTreatmentplanAssessmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanAssessmentBoolExp>;
};


export type Query_RootTreatmentplanAssessmentByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTreatmentplanByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTreatmentplanNoteArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanNoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanNoteOrderBy>>;
  where?: InputMaybe<TreatmentplanNoteBoolExp>;
};


export type Query_RootTreatmentplanNoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanNoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanNoteOrderBy>>;
  where?: InputMaybe<TreatmentplanNoteBoolExp>;
};


export type Query_RootTreatmentplanNoteByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTreatmentplanProblemsArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanProblemsOrderBy>>;
  where?: InputMaybe<TreatmentplanProblemsBoolExp>;
};


export type Query_RootTreatmentplanProblemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanProblemsOrderBy>>;
  where?: InputMaybe<TreatmentplanProblemsBoolExp>;
};


export type Query_RootTreatmentplanProblemsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTreatmentplanTemplateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanTemplateOrderBy>>;
  where?: InputMaybe<TreatmentplanTemplateBoolExp>;
};


export type Query_RootTreatmentplanTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanTemplateOrderBy>>;
  where?: InputMaybe<TreatmentplanTemplateBoolExp>;
};


export type Query_RootTreatmentplanTemplateAssessmentArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanTemplateAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanTemplateAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
};


export type Query_RootTreatmentplanTemplateAssessmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanTemplateAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanTemplateAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
};


export type Query_RootTreatmentplanTemplateAssessmentByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTreatmentplanTemplateByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUserArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type Query_RootUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type Query_RootUserByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUserGroupsArgs = {
  distinctOn?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


export type Query_RootUserGroupsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


export type Query_RootUserGroupsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUserPermissionsArgs = {
  distinctOn?: InputMaybe<Array<UserPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
  where?: InputMaybe<UserPermissionsBoolExp>;
};


export type Query_RootUserPermissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
  where?: InputMaybe<UserPermissionsBoolExp>;
};


export type Query_RootUserPermissionsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVisitRebillArgs = {
  distinctOn?: InputMaybe<Array<VisitRebillSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VisitRebillOrderBy>>;
  where?: InputMaybe<VisitRebillBoolExp>;
};


export type Query_RootVisitRebillAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitRebillSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VisitRebillOrderBy>>;
  where?: InputMaybe<VisitRebillBoolExp>;
};


export type Query_RootVisitRebillByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVitalsArgs = {
  distinctOn?: InputMaybe<Array<VitalsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VitalsOrderBy>>;
  where?: InputMaybe<VitalsBoolExp>;
};


export type Query_RootVitalsAggregateArgs = {
  distinctOn?: InputMaybe<Array<VitalsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VitalsOrderBy>>;
  where?: InputMaybe<VitalsBoolExp>;
};


export type Query_RootVitalsByPkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "server_recordsrequestdestination" */
export type RecordsRequestDestination = {
  __typename?: 'recordsRequestDestination';
  fax: Scalars['String'];
  id: Scalars['Int'];
  /** An array relationship */
  intakesRequestingFromDestination: Array<PatientIntakeRecordsLocations>;
  /** An aggregate relationship */
  intakesRequestingFromDestinationAggregate: PatientIntakeRecordsLocationsAggregate;
  name: Scalars['String'];
  practiceGroupId: Scalars['Int'];
  /** An object relationship */
  practicegroup: Practicegroup;
  type: Scalars['smallint'];
};


/** columns and relationships of "server_recordsrequestdestination" */
export type RecordsRequestDestinationIntakesRequestingFromDestinationArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeRecordsLocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeRecordsLocationsOrderBy>>;
  where?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
};


/** columns and relationships of "server_recordsrequestdestination" */
export type RecordsRequestDestinationIntakesRequestingFromDestinationAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeRecordsLocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeRecordsLocationsOrderBy>>;
  where?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
};

/** aggregated selection of "server_recordsrequestdestination" */
export type RecordsRequestDestinationAggregate = {
  __typename?: 'recordsRequestDestinationAggregate';
  aggregate?: Maybe<RecordsRequestDestinationAggregateFields>;
  nodes: Array<RecordsRequestDestination>;
};

/** aggregate fields of "server_recordsrequestdestination" */
export type RecordsRequestDestinationAggregateFields = {
  __typename?: 'recordsRequestDestinationAggregateFields';
  avg?: Maybe<RecordsRequestDestinationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<RecordsRequestDestinationMaxFields>;
  min?: Maybe<RecordsRequestDestinationMinFields>;
  stddev?: Maybe<RecordsRequestDestinationStddevFields>;
  stddevPop?: Maybe<RecordsRequestDestinationStddev_PopFields>;
  stddevSamp?: Maybe<RecordsRequestDestinationStddev_SampFields>;
  sum?: Maybe<RecordsRequestDestinationSumFields>;
  varPop?: Maybe<RecordsRequestDestinationVar_PopFields>;
  varSamp?: Maybe<RecordsRequestDestinationVar_SampFields>;
  variance?: Maybe<RecordsRequestDestinationVarianceFields>;
};


/** aggregate fields of "server_recordsrequestdestination" */
export type RecordsRequestDestinationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RecordsRequestDestinationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_recordsrequestdestination" */
export type RecordsRequestDestinationAggregateOrderBy = {
  avg?: InputMaybe<RecordsRequestDestination_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RecordsRequestDestination_Max_Order_By>;
  min?: InputMaybe<RecordsRequestDestination_Min_Order_By>;
  stddev?: InputMaybe<RecordsRequestDestination_Stddev_Order_By>;
  stddev_pop?: InputMaybe<RecordsRequestDestination_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<RecordsRequestDestination_Stddev_Samp_Order_By>;
  sum?: InputMaybe<RecordsRequestDestination_Sum_Order_By>;
  var_pop?: InputMaybe<RecordsRequestDestination_Var_Pop_Order_By>;
  var_samp?: InputMaybe<RecordsRequestDestination_Var_Samp_Order_By>;
  variance?: InputMaybe<RecordsRequestDestination_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_recordsrequestdestination" */
export type RecordsRequestDestinationArrRelInsertInput = {
  data: Array<RecordsRequestDestinationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<RecordsRequestDestinationOnConflict>;
};

/** aggregate avg on columns */
export type RecordsRequestDestinationAvgFields = {
  __typename?: 'recordsRequestDestinationAvgFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_recordsrequestdestination". All fields are combined with a logical 'AND'. */
export type RecordsRequestDestinationBoolExp = {
  _and?: InputMaybe<Array<RecordsRequestDestinationBoolExp>>;
  _not?: InputMaybe<RecordsRequestDestinationBoolExp>;
  _or?: InputMaybe<Array<RecordsRequestDestinationBoolExp>>;
  fax?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  intakesRequestingFromDestination?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  practiceGroupId?: InputMaybe<IntComparisonExp>;
  practicegroup?: InputMaybe<PracticegroupBoolExp>;
  type?: InputMaybe<SmallintComparisonExp>;
};

/** unique or primary key constraints on table "server_recordsrequestdestination" */
export enum RecordsRequestDestinationConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerRecordsrequestdestinationPkey = 'server_recordsrequestdestination_pkey'
}

/** input type for incrementing numeric columns in table "server_recordsrequestdestination" */
export type RecordsRequestDestinationIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_recordsrequestdestination" */
export type RecordsRequestDestinationInsertInput = {
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  intakesRequestingFromDestination?: InputMaybe<PatientIntakeRecordsLocationsArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  practicegroup?: InputMaybe<PracticegroupObjRelInsertInput>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type RecordsRequestDestinationMaxFields = {
  __typename?: 'recordsRequestDestinationMaxFields';
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type RecordsRequestDestinationMinFields = {
  __typename?: 'recordsRequestDestinationMinFields';
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_recordsrequestdestination" */
export type RecordsRequestDestinationMutationResponse = {
  __typename?: 'recordsRequestDestinationMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RecordsRequestDestination>;
};

/** input type for inserting object relation for remote table "server_recordsrequestdestination" */
export type RecordsRequestDestinationObjRelInsertInput = {
  data: RecordsRequestDestinationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<RecordsRequestDestinationOnConflict>;
};

/** on_conflict condition type for table "server_recordsrequestdestination" */
export type RecordsRequestDestinationOnConflict = {
  constraint: RecordsRequestDestinationConstraint;
  update_columns?: Array<RecordsRequestDestinationUpdateColumn>;
  where?: InputMaybe<RecordsRequestDestinationBoolExp>;
};

/** Ordering options when selecting data from "server_recordsrequestdestination". */
export type RecordsRequestDestinationOrderBy = {
  fax?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  intakesRequestingFromDestinationAggregate?: InputMaybe<PatientIntakeRecordsLocationsAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  practicegroup?: InputMaybe<PracticegroupOrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_recordsrequestdestination */
export type RecordsRequestDestinationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_recordsrequestdestination" */
export enum RecordsRequestDestinationSelectColumn {
  /** column name */
  Fax = 'fax',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PracticeGroupId = 'practiceGroupId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "server_recordsrequestdestination" */
export type RecordsRequestDestinationSetInput = {
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type RecordsRequestDestinationStddevFields = {
  __typename?: 'recordsRequestDestinationStddevFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RecordsRequestDestinationStddev_PopFields = {
  __typename?: 'recordsRequestDestinationStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RecordsRequestDestinationStddev_SampFields = {
  __typename?: 'recordsRequestDestinationStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type RecordsRequestDestinationSumFields = {
  __typename?: 'recordsRequestDestinationSumFields';
  id?: Maybe<Scalars['Int']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_recordsrequestdestination" */
export enum RecordsRequestDestinationUpdateColumn {
  /** column name */
  Fax = 'fax',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PracticeGroupId = 'practiceGroupId',
  /** column name */
  Type = 'type'
}

export type RecordsRequestDestinationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RecordsRequestDestinationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RecordsRequestDestinationSetInput>;
  where: RecordsRequestDestinationBoolExp;
};

/** aggregate var_pop on columns */
export type RecordsRequestDestinationVar_PopFields = {
  __typename?: 'recordsRequestDestinationVar_popFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RecordsRequestDestinationVar_SampFields = {
  __typename?: 'recordsRequestDestinationVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RecordsRequestDestinationVarianceFields = {
  __typename?: 'recordsRequestDestinationVarianceFields';
  id?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_recordsrequestdestination" */
export type RecordsRequestDestination_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_recordsrequestdestination" */
export type RecordsRequestDestination_Max_Order_By = {
  fax?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_recordsrequestdestination" */
export type RecordsRequestDestination_Min_Order_By = {
  fax?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_recordsrequestdestination" */
export type RecordsRequestDestination_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_recordsrequestdestination" */
export type RecordsRequestDestination_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_recordsrequestdestination" */
export type RecordsRequestDestination_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "recordsRequestDestination" */
export type RecordsRequestDestination_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: RecordsRequestDestination_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RecordsRequestDestination_StreamCursorValueInput = {
  fax?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "server_recordsrequestdestination" */
export type RecordsRequestDestination_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_recordsrequestdestination" */
export type RecordsRequestDestination_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_recordsrequestdestination" */
export type RecordsRequestDestination_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_recordsrequestdestination" */
export type RecordsRequestDestination_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_referral" */
export type Referral = {
  __typename?: 'referral';
  active: Scalars['Boolean'];
  /** An array relationship */
  attachments: Array<ReferralAttachments>;
  /** An aggregate relationship */
  attachmentsAggregate: ReferralAttachmentsAggregate;
  datetime: Scalars['timestamptz'];
  /** An object relationship */
  destination: AgencyLocation;
  destinationId: Scalars['Int'];
  /** An array relationship */
  faxes: Array<Fax>;
  /** An aggregate relationship */
  faxesAggregate: FaxAggregate;
  /** An array relationship */
  futureReferralsFromClinicalNotes: Array<ClinicalNoteFutureReferrals>;
  /** An aggregate relationship */
  futureReferralsFromClinicalNotesAggregate: ClinicalNoteFutureReferralsAggregate;
  id: Scalars['Int'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  sendOngoing: Scalars['Boolean'];
};


/** columns and relationships of "server_referral" */
export type ReferralAttachmentsArgs = {
  distinctOn?: InputMaybe<Array<ReferralAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralAttachmentsOrderBy>>;
  where?: InputMaybe<ReferralAttachmentsBoolExp>;
};


/** columns and relationships of "server_referral" */
export type ReferralAttachmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReferralAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralAttachmentsOrderBy>>;
  where?: InputMaybe<ReferralAttachmentsBoolExp>;
};


/** columns and relationships of "server_referral" */
export type ReferralFaxesArgs = {
  distinctOn?: InputMaybe<Array<FaxSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxOrderBy>>;
  where?: InputMaybe<FaxBoolExp>;
};


/** columns and relationships of "server_referral" */
export type ReferralFaxesAggregateArgs = {
  distinctOn?: InputMaybe<Array<FaxSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxOrderBy>>;
  where?: InputMaybe<FaxBoolExp>;
};


/** columns and relationships of "server_referral" */
export type ReferralFutureReferralsFromClinicalNotesArgs = {
  distinctOn?: InputMaybe<Array<ClinicalNoteFutureReferralsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalNoteFutureReferralsOrderBy>>;
  where?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
};


/** columns and relationships of "server_referral" */
export type ReferralFutureReferralsFromClinicalNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalNoteFutureReferralsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalNoteFutureReferralsOrderBy>>;
  where?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
};

/** aggregated selection of "server_referral" */
export type ReferralAggregate = {
  __typename?: 'referralAggregate';
  aggregate?: Maybe<ReferralAggregateFields>;
  nodes: Array<Referral>;
};

/** aggregate fields of "server_referral" */
export type ReferralAggregateFields = {
  __typename?: 'referralAggregateFields';
  avg?: Maybe<ReferralAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ReferralMaxFields>;
  min?: Maybe<ReferralMinFields>;
  stddev?: Maybe<ReferralStddevFields>;
  stddevPop?: Maybe<ReferralStddev_PopFields>;
  stddevSamp?: Maybe<ReferralStddev_SampFields>;
  sum?: Maybe<ReferralSumFields>;
  varPop?: Maybe<ReferralVar_PopFields>;
  varSamp?: Maybe<ReferralVar_SampFields>;
  variance?: Maybe<ReferralVarianceFields>;
};


/** aggregate fields of "server_referral" */
export type ReferralAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ReferralSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_referral" */
export type ReferralAggregateOrderBy = {
  avg?: InputMaybe<Referral_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Referral_Max_Order_By>;
  min?: InputMaybe<Referral_Min_Order_By>;
  stddev?: InputMaybe<Referral_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Referral_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Referral_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Referral_Sum_Order_By>;
  var_pop?: InputMaybe<Referral_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Referral_Var_Samp_Order_By>;
  variance?: InputMaybe<Referral_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_referral" */
export type ReferralArrRelInsertInput = {
  data: Array<ReferralInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ReferralOnConflict>;
};

/** columns and relationships of "server_referral_attachments" */
export type ReferralAttachments = {
  __typename?: 'referralAttachments';
  /** An object relationship */
  document: Document;
  documentId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  referral: Referral;
  referralId: Scalars['Int'];
};

/** aggregated selection of "server_referral_attachments" */
export type ReferralAttachmentsAggregate = {
  __typename?: 'referralAttachmentsAggregate';
  aggregate?: Maybe<ReferralAttachmentsAggregateFields>;
  nodes: Array<ReferralAttachments>;
};

/** aggregate fields of "server_referral_attachments" */
export type ReferralAttachmentsAggregateFields = {
  __typename?: 'referralAttachmentsAggregateFields';
  avg?: Maybe<ReferralAttachmentsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ReferralAttachmentsMaxFields>;
  min?: Maybe<ReferralAttachmentsMinFields>;
  stddev?: Maybe<ReferralAttachmentsStddevFields>;
  stddevPop?: Maybe<ReferralAttachmentsStddev_PopFields>;
  stddevSamp?: Maybe<ReferralAttachmentsStddev_SampFields>;
  sum?: Maybe<ReferralAttachmentsSumFields>;
  varPop?: Maybe<ReferralAttachmentsVar_PopFields>;
  varSamp?: Maybe<ReferralAttachmentsVar_SampFields>;
  variance?: Maybe<ReferralAttachmentsVarianceFields>;
};


/** aggregate fields of "server_referral_attachments" */
export type ReferralAttachmentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ReferralAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_referral_attachments" */
export type ReferralAttachmentsAggregateOrderBy = {
  avg?: InputMaybe<ReferralAttachments_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReferralAttachments_Max_Order_By>;
  min?: InputMaybe<ReferralAttachments_Min_Order_By>;
  stddev?: InputMaybe<ReferralAttachments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ReferralAttachments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ReferralAttachments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ReferralAttachments_Sum_Order_By>;
  var_pop?: InputMaybe<ReferralAttachments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ReferralAttachments_Var_Samp_Order_By>;
  variance?: InputMaybe<ReferralAttachments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_referral_attachments" */
export type ReferralAttachmentsArrRelInsertInput = {
  data: Array<ReferralAttachmentsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ReferralAttachmentsOnConflict>;
};

/** aggregate avg on columns */
export type ReferralAttachmentsAvgFields = {
  __typename?: 'referralAttachmentsAvgFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_referral_attachments". All fields are combined with a logical 'AND'. */
export type ReferralAttachmentsBoolExp = {
  _and?: InputMaybe<Array<ReferralAttachmentsBoolExp>>;
  _not?: InputMaybe<ReferralAttachmentsBoolExp>;
  _or?: InputMaybe<Array<ReferralAttachmentsBoolExp>>;
  document?: InputMaybe<DocumentBoolExp>;
  documentId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  referral?: InputMaybe<ReferralBoolExp>;
  referralId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_referral_attachments" */
export enum ReferralAttachmentsConstraint {
  /** unique or primary key constraint on columns "document_id", "referral_id" */
  ServerReferralAttachmeReferralIdDocumentIdDd2bdee1Uniq = 'server_referral_attachme_referral_id_document_id_dd2bdee1_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerReferralAttachmentsPkey = 'server_referral_attachments_pkey'
}

/** input type for incrementing numeric columns in table "server_referral_attachments" */
export type ReferralAttachmentsIncInput = {
  documentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  referralId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_referral_attachments" */
export type ReferralAttachmentsInsertInput = {
  document?: InputMaybe<DocumentObjRelInsertInput>;
  documentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  referral?: InputMaybe<ReferralObjRelInsertInput>;
  referralId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ReferralAttachmentsMaxFields = {
  __typename?: 'referralAttachmentsMaxFields';
  documentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  referralId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ReferralAttachmentsMinFields = {
  __typename?: 'referralAttachmentsMinFields';
  documentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  referralId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_referral_attachments" */
export type ReferralAttachmentsMutationResponse = {
  __typename?: 'referralAttachmentsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReferralAttachments>;
};

/** on_conflict condition type for table "server_referral_attachments" */
export type ReferralAttachmentsOnConflict = {
  constraint: ReferralAttachmentsConstraint;
  update_columns?: Array<ReferralAttachmentsUpdateColumn>;
  where?: InputMaybe<ReferralAttachmentsBoolExp>;
};

/** Ordering options when selecting data from "server_referral_attachments". */
export type ReferralAttachmentsOrderBy = {
  document?: InputMaybe<DocumentOrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referral?: InputMaybe<ReferralOrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_referral_attachments */
export type ReferralAttachmentsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_referral_attachments" */
export enum ReferralAttachmentsSelectColumn {
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  ReferralId = 'referralId'
}

/** input type for updating data in table "server_referral_attachments" */
export type ReferralAttachmentsSetInput = {
  documentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  referralId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ReferralAttachmentsStddevFields = {
  __typename?: 'referralAttachmentsStddevFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ReferralAttachmentsStddev_PopFields = {
  __typename?: 'referralAttachmentsStddev_popFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ReferralAttachmentsStddev_SampFields = {
  __typename?: 'referralAttachmentsStddev_sampFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ReferralAttachmentsSumFields = {
  __typename?: 'referralAttachmentsSumFields';
  documentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  referralId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_referral_attachments" */
export enum ReferralAttachmentsUpdateColumn {
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  ReferralId = 'referralId'
}

export type ReferralAttachmentsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReferralAttachmentsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReferralAttachmentsSetInput>;
  where: ReferralAttachmentsBoolExp;
};

/** aggregate var_pop on columns */
export type ReferralAttachmentsVar_PopFields = {
  __typename?: 'referralAttachmentsVar_popFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ReferralAttachmentsVar_SampFields = {
  __typename?: 'referralAttachmentsVar_sampFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ReferralAttachmentsVarianceFields = {
  __typename?: 'referralAttachmentsVarianceFields';
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  referralId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_referral_attachments" */
export type ReferralAttachments_Avg_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_referral_attachments" */
export type ReferralAttachments_Max_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_referral_attachments" */
export type ReferralAttachments_Min_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_referral_attachments" */
export type ReferralAttachments_Stddev_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_referral_attachments" */
export type ReferralAttachments_Stddev_Pop_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_referral_attachments" */
export type ReferralAttachments_Stddev_Samp_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "referralAttachments" */
export type ReferralAttachments_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ReferralAttachments_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ReferralAttachments_StreamCursorValueInput = {
  documentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  referralId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_referral_attachments" */
export type ReferralAttachments_Sum_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_referral_attachments" */
export type ReferralAttachments_Var_Pop_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_referral_attachments" */
export type ReferralAttachments_Var_Samp_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_referral_attachments" */
export type ReferralAttachments_Variance_Order_By = {
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** aggregate avg on columns */
export type ReferralAvgFields = {
  __typename?: 'referralAvgFields';
  destinationId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_referral". All fields are combined with a logical 'AND'. */
export type ReferralBoolExp = {
  _and?: InputMaybe<Array<ReferralBoolExp>>;
  _not?: InputMaybe<ReferralBoolExp>;
  _or?: InputMaybe<Array<ReferralBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  attachments?: InputMaybe<ReferralAttachmentsBoolExp>;
  datetime?: InputMaybe<TimestamptzComparisonExp>;
  destination?: InputMaybe<AgencyLocationBoolExp>;
  destinationId?: InputMaybe<IntComparisonExp>;
  faxes?: InputMaybe<FaxBoolExp>;
  futureReferralsFromClinicalNotes?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  sendOngoing?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "server_referral" */
export enum ReferralConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerReferralPkey = 'server_referral_pkey'
}

/** input type for incrementing numeric columns in table "server_referral" */
export type ReferralIncInput = {
  destinationId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_referral" */
export type ReferralInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  attachments?: InputMaybe<ReferralAttachmentsArrRelInsertInput>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  destination?: InputMaybe<AgencyLocationObjRelInsertInput>;
  destinationId?: InputMaybe<Scalars['Int']>;
  faxes?: InputMaybe<FaxArrRelInsertInput>;
  futureReferralsFromClinicalNotes?: InputMaybe<ClinicalNoteFutureReferralsArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  sendOngoing?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type ReferralMaxFields = {
  __typename?: 'referralMaxFields';
  datetime?: Maybe<Scalars['timestamptz']>;
  destinationId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ReferralMinFields = {
  __typename?: 'referralMinFields';
  datetime?: Maybe<Scalars['timestamptz']>;
  destinationId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_referral" */
export type ReferralMutationResponse = {
  __typename?: 'referralMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Referral>;
};

/** input type for inserting object relation for remote table "server_referral" */
export type ReferralObjRelInsertInput = {
  data: ReferralInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ReferralOnConflict>;
};

/** on_conflict condition type for table "server_referral" */
export type ReferralOnConflict = {
  constraint: ReferralConstraint;
  update_columns?: Array<ReferralUpdateColumn>;
  where?: InputMaybe<ReferralBoolExp>;
};

/** Ordering options when selecting data from "server_referral". */
export type ReferralOrderBy = {
  active?: InputMaybe<OrderBy>;
  attachmentsAggregate?: InputMaybe<ReferralAttachmentsAggregateOrderBy>;
  datetime?: InputMaybe<OrderBy>;
  destination?: InputMaybe<AgencyLocationOrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  faxesAggregate?: InputMaybe<FaxAggregateOrderBy>;
  futureReferralsFromClinicalNotesAggregate?: InputMaybe<ClinicalNoteFutureReferralsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  sendOngoing?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_referral */
export type ReferralPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_referral" */
export enum ReferralSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DestinationId = 'destinationId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  SendOngoing = 'sendOngoing'
}

/** input type for updating data in table "server_referral" */
export type ReferralSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  destinationId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  sendOngoing?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type ReferralStddevFields = {
  __typename?: 'referralStddevFields';
  destinationId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ReferralStddev_PopFields = {
  __typename?: 'referralStddev_popFields';
  destinationId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ReferralStddev_SampFields = {
  __typename?: 'referralStddev_sampFields';
  destinationId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ReferralSumFields = {
  __typename?: 'referralSumFields';
  destinationId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_referral" */
export enum ReferralUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DestinationId = 'destinationId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  SendOngoing = 'sendOngoing'
}

export type ReferralUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReferralIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReferralSetInput>;
  where: ReferralBoolExp;
};

/** aggregate var_pop on columns */
export type ReferralVar_PopFields = {
  __typename?: 'referralVar_popFields';
  destinationId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ReferralVar_SampFields = {
  __typename?: 'referralVar_sampFields';
  destinationId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ReferralVarianceFields = {
  __typename?: 'referralVarianceFields';
  destinationId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_referral" */
export type Referral_Avg_Order_By = {
  destinationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_referral" */
export type Referral_Max_Order_By = {
  datetime?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_referral" */
export type Referral_Min_Order_By = {
  datetime?: InputMaybe<OrderBy>;
  destinationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_referral" */
export type Referral_Stddev_Order_By = {
  destinationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_referral" */
export type Referral_Stddev_Pop_Order_By = {
  destinationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_referral" */
export type Referral_Stddev_Samp_Order_By = {
  destinationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "referral" */
export type Referral_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Referral_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Referral_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  destinationId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  sendOngoing?: InputMaybe<Scalars['Boolean']>;
};

/** order by sum() on columns of table "server_referral" */
export type Referral_Sum_Order_By = {
  destinationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_referral" */
export type Referral_Var_Pop_Order_By = {
  destinationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_referral" */
export type Referral_Var_Samp_Order_By = {
  destinationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_referral" */
export type Referral_Variance_Order_By = {
  destinationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_renderedassessment" */
export type RenderedAssessment = {
  __typename?: 'renderedAssessment';
  /** An object relationship */
  assessment: Assessment;
  assessmentId: Scalars['Int'];
  /** An array relationship */
  assessmentItemValues: Array<AssessmentItemValue>;
  /** An aggregate relationship */
  assessmentItemValuesAggregate: AssessmentItemValueAggregate;
  datetime: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  /** An object relationship */
  renderingUser: User;
  text: Scalars['String'];
  /** An object relationship */
  treatmentPlanAssessment?: Maybe<TreatmentplanAssessment>;
  userId: Scalars['Int'];
};


/** columns and relationships of "server_renderedassessment" */
export type RenderedAssessmentAssessmentItemValuesArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemValueOrderBy>>;
  where?: InputMaybe<AssessmentItemValueBoolExp>;
};


/** columns and relationships of "server_renderedassessment" */
export type RenderedAssessmentAssessmentItemValuesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemValueOrderBy>>;
  where?: InputMaybe<AssessmentItemValueBoolExp>;
};

/** aggregated selection of "server_renderedassessment" */
export type RenderedAssessmentAggregate = {
  __typename?: 'renderedAssessmentAggregate';
  aggregate?: Maybe<RenderedAssessmentAggregateFields>;
  nodes: Array<RenderedAssessment>;
};

/** aggregate fields of "server_renderedassessment" */
export type RenderedAssessmentAggregateFields = {
  __typename?: 'renderedAssessmentAggregateFields';
  avg?: Maybe<RenderedAssessmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<RenderedAssessmentMaxFields>;
  min?: Maybe<RenderedAssessmentMinFields>;
  stddev?: Maybe<RenderedAssessmentStddevFields>;
  stddevPop?: Maybe<RenderedAssessmentStddev_PopFields>;
  stddevSamp?: Maybe<RenderedAssessmentStddev_SampFields>;
  sum?: Maybe<RenderedAssessmentSumFields>;
  varPop?: Maybe<RenderedAssessmentVar_PopFields>;
  varSamp?: Maybe<RenderedAssessmentVar_SampFields>;
  variance?: Maybe<RenderedAssessmentVarianceFields>;
};


/** aggregate fields of "server_renderedassessment" */
export type RenderedAssessmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RenderedAssessmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_renderedassessment" */
export type RenderedAssessmentAggregateOrderBy = {
  avg?: InputMaybe<RenderedAssessment_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RenderedAssessment_Max_Order_By>;
  min?: InputMaybe<RenderedAssessment_Min_Order_By>;
  stddev?: InputMaybe<RenderedAssessment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<RenderedAssessment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<RenderedAssessment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<RenderedAssessment_Sum_Order_By>;
  var_pop?: InputMaybe<RenderedAssessment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<RenderedAssessment_Var_Samp_Order_By>;
  variance?: InputMaybe<RenderedAssessment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_renderedassessment" */
export type RenderedAssessmentArrRelInsertInput = {
  data: Array<RenderedAssessmentInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<RenderedAssessmentOnConflict>;
};

/** aggregate avg on columns */
export type RenderedAssessmentAvgFields = {
  __typename?: 'renderedAssessmentAvgFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_renderedassessment". All fields are combined with a logical 'AND'. */
export type RenderedAssessmentBoolExp = {
  _and?: InputMaybe<Array<RenderedAssessmentBoolExp>>;
  _not?: InputMaybe<RenderedAssessmentBoolExp>;
  _or?: InputMaybe<Array<RenderedAssessmentBoolExp>>;
  assessment?: InputMaybe<AssessmentBoolExp>;
  assessmentId?: InputMaybe<IntComparisonExp>;
  assessmentItemValues?: InputMaybe<AssessmentItemValueBoolExp>;
  datetime?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  renderingUser?: InputMaybe<UserBoolExp>;
  text?: InputMaybe<StringComparisonExp>;
  treatmentPlanAssessment?: InputMaybe<TreatmentplanAssessmentBoolExp>;
  userId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_renderedassessment" */
export enum RenderedAssessmentConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerRenderedassessmentPkey = 'server_renderedassessment_pkey'
}

/** input type for incrementing numeric columns in table "server_renderedassessment" */
export type RenderedAssessmentIncInput = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_renderedassessment" */
export type RenderedAssessmentInsertInput = {
  assessment?: InputMaybe<AssessmentObjRelInsertInput>;
  assessmentId?: InputMaybe<Scalars['Int']>;
  assessmentItemValues?: InputMaybe<AssessmentItemValueArrRelInsertInput>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  renderingUser?: InputMaybe<UserObjRelInsertInput>;
  text?: InputMaybe<Scalars['String']>;
  treatmentPlanAssessment?: InputMaybe<TreatmentplanAssessmentObjRelInsertInput>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type RenderedAssessmentMaxFields = {
  __typename?: 'renderedAssessmentMaxFields';
  assessmentId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type RenderedAssessmentMinFields = {
  __typename?: 'renderedAssessmentMinFields';
  assessmentId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_renderedassessment" */
export type RenderedAssessmentMutationResponse = {
  __typename?: 'renderedAssessmentMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RenderedAssessment>;
};

/** input type for inserting object relation for remote table "server_renderedassessment" */
export type RenderedAssessmentObjRelInsertInput = {
  data: RenderedAssessmentInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<RenderedAssessmentOnConflict>;
};

/** on_conflict condition type for table "server_renderedassessment" */
export type RenderedAssessmentOnConflict = {
  constraint: RenderedAssessmentConstraint;
  update_columns?: Array<RenderedAssessmentUpdateColumn>;
  where?: InputMaybe<RenderedAssessmentBoolExp>;
};

/** Ordering options when selecting data from "server_renderedassessment". */
export type RenderedAssessmentOrderBy = {
  assessment?: InputMaybe<AssessmentOrderBy>;
  assessmentId?: InputMaybe<OrderBy>;
  assessmentItemValuesAggregate?: InputMaybe<AssessmentItemValueAggregateOrderBy>;
  datetime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  renderingUser?: InputMaybe<UserOrderBy>;
  text?: InputMaybe<OrderBy>;
  treatmentPlanAssessment?: InputMaybe<TreatmentplanAssessmentOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_renderedassessment */
export type RenderedAssessmentPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_renderedassessment" */
export enum RenderedAssessmentSelectColumn {
  /** column name */
  AssessmentId = 'assessmentId',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Text = 'text',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "server_renderedassessment" */
export type RenderedAssessmentSetInput = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type RenderedAssessmentStddevFields = {
  __typename?: 'renderedAssessmentStddevFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RenderedAssessmentStddev_PopFields = {
  __typename?: 'renderedAssessmentStddev_popFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RenderedAssessmentStddev_SampFields = {
  __typename?: 'renderedAssessmentStddev_sampFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type RenderedAssessmentSumFields = {
  __typename?: 'renderedAssessmentSumFields';
  assessmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_renderedassessment" */
export enum RenderedAssessmentUpdateColumn {
  /** column name */
  AssessmentId = 'assessmentId',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  Text = 'text',
  /** column name */
  UserId = 'userId'
}

export type RenderedAssessmentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RenderedAssessmentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RenderedAssessmentSetInput>;
  where: RenderedAssessmentBoolExp;
};

/** aggregate var_pop on columns */
export type RenderedAssessmentVar_PopFields = {
  __typename?: 'renderedAssessmentVar_popFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RenderedAssessmentVar_SampFields = {
  __typename?: 'renderedAssessmentVar_sampFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RenderedAssessmentVarianceFields = {
  __typename?: 'renderedAssessmentVarianceFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_renderedassessment" */
export type RenderedAssessment_Avg_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_renderedassessment" */
export type RenderedAssessment_Max_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_renderedassessment" */
export type RenderedAssessment_Min_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_renderedassessment" */
export type RenderedAssessment_Stddev_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_renderedassessment" */
export type RenderedAssessment_Stddev_Pop_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_renderedassessment" */
export type RenderedAssessment_Stddev_Samp_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "renderedAssessment" */
export type RenderedAssessment_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: RenderedAssessment_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RenderedAssessment_StreamCursorValueInput = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_renderedassessment" */
export type RenderedAssessment_Sum_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_renderedassessment" */
export type RenderedAssessment_Var_Pop_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_renderedassessment" */
export type RenderedAssessment_Var_Samp_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_renderedassessment" */
export type RenderedAssessment_Variance_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_schedulingaction" */
export type SchedulingAction = {
  __typename?: 'schedulingAction';
  /** An object relationship */
  appointment: Appointment;
  appointmentId: Scalars['Int'];
  datetime: Scalars['timestamptz'];
  id: Scalars['Int'];
  notes: Scalars['String'];
  reachable?: Maybe<Scalars['Boolean']>;
  refusedVisit?: Maybe<Scalars['Boolean']>;
  statusAction: Scalars['smallint'];
  voicemailLeft?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "server_schedulingaction" */
export type SchedulingActionAggregate = {
  __typename?: 'schedulingActionAggregate';
  aggregate?: Maybe<SchedulingActionAggregateFields>;
  nodes: Array<SchedulingAction>;
};

/** aggregate fields of "server_schedulingaction" */
export type SchedulingActionAggregateFields = {
  __typename?: 'schedulingActionAggregateFields';
  avg?: Maybe<SchedulingActionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SchedulingActionMaxFields>;
  min?: Maybe<SchedulingActionMinFields>;
  stddev?: Maybe<SchedulingActionStddevFields>;
  stddevPop?: Maybe<SchedulingActionStddev_PopFields>;
  stddevSamp?: Maybe<SchedulingActionStddev_SampFields>;
  sum?: Maybe<SchedulingActionSumFields>;
  varPop?: Maybe<SchedulingActionVar_PopFields>;
  varSamp?: Maybe<SchedulingActionVar_SampFields>;
  variance?: Maybe<SchedulingActionVarianceFields>;
};


/** aggregate fields of "server_schedulingaction" */
export type SchedulingActionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SchedulingActionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_schedulingaction" */
export type SchedulingActionAggregateOrderBy = {
  avg?: InputMaybe<SchedulingAction_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SchedulingAction_Max_Order_By>;
  min?: InputMaybe<SchedulingAction_Min_Order_By>;
  stddev?: InputMaybe<SchedulingAction_Stddev_Order_By>;
  stddev_pop?: InputMaybe<SchedulingAction_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<SchedulingAction_Stddev_Samp_Order_By>;
  sum?: InputMaybe<SchedulingAction_Sum_Order_By>;
  var_pop?: InputMaybe<SchedulingAction_Var_Pop_Order_By>;
  var_samp?: InputMaybe<SchedulingAction_Var_Samp_Order_By>;
  variance?: InputMaybe<SchedulingAction_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_schedulingaction" */
export type SchedulingActionArrRelInsertInput = {
  data: Array<SchedulingActionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<SchedulingActionOnConflict>;
};

/** aggregate avg on columns */
export type SchedulingActionAvgFields = {
  __typename?: 'schedulingActionAvgFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statusAction?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_schedulingaction". All fields are combined with a logical 'AND'. */
export type SchedulingActionBoolExp = {
  _and?: InputMaybe<Array<SchedulingActionBoolExp>>;
  _not?: InputMaybe<SchedulingActionBoolExp>;
  _or?: InputMaybe<Array<SchedulingActionBoolExp>>;
  appointment?: InputMaybe<AppointmentBoolExp>;
  appointmentId?: InputMaybe<IntComparisonExp>;
  datetime?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  reachable?: InputMaybe<BooleanComparisonExp>;
  refusedVisit?: InputMaybe<BooleanComparisonExp>;
  statusAction?: InputMaybe<SmallintComparisonExp>;
  voicemailLeft?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "server_schedulingaction" */
export enum SchedulingActionConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerSchedulingactionPkey = 'server_schedulingaction_pkey'
}

/** input type for incrementing numeric columns in table "server_schedulingaction" */
export type SchedulingActionIncInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  statusAction?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_schedulingaction" */
export type SchedulingActionInsertInput = {
  appointment?: InputMaybe<AppointmentObjRelInsertInput>;
  appointmentId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  reachable?: InputMaybe<Scalars['Boolean']>;
  refusedVisit?: InputMaybe<Scalars['Boolean']>;
  statusAction?: InputMaybe<Scalars['smallint']>;
  voicemailLeft?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type SchedulingActionMaxFields = {
  __typename?: 'schedulingActionMaxFields';
  appointmentId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  statusAction?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type SchedulingActionMinFields = {
  __typename?: 'schedulingActionMinFields';
  appointmentId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  statusAction?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_schedulingaction" */
export type SchedulingActionMutationResponse = {
  __typename?: 'schedulingActionMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SchedulingAction>;
};

/** on_conflict condition type for table "server_schedulingaction" */
export type SchedulingActionOnConflict = {
  constraint: SchedulingActionConstraint;
  update_columns?: Array<SchedulingActionUpdateColumn>;
  where?: InputMaybe<SchedulingActionBoolExp>;
};

/** Ordering options when selecting data from "server_schedulingaction". */
export type SchedulingActionOrderBy = {
  appointment?: InputMaybe<AppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  reachable?: InputMaybe<OrderBy>;
  refusedVisit?: InputMaybe<OrderBy>;
  statusAction?: InputMaybe<OrderBy>;
  voicemailLeft?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_schedulingaction */
export type SchedulingActionPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_schedulingaction" */
export enum SchedulingActionSelectColumn {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Reachable = 'reachable',
  /** column name */
  RefusedVisit = 'refusedVisit',
  /** column name */
  StatusAction = 'statusAction',
  /** column name */
  VoicemailLeft = 'voicemailLeft'
}

/** input type for updating data in table "server_schedulingaction" */
export type SchedulingActionSetInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  reachable?: InputMaybe<Scalars['Boolean']>;
  refusedVisit?: InputMaybe<Scalars['Boolean']>;
  statusAction?: InputMaybe<Scalars['smallint']>;
  voicemailLeft?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type SchedulingActionStddevFields = {
  __typename?: 'schedulingActionStddevFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statusAction?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SchedulingActionStddev_PopFields = {
  __typename?: 'schedulingActionStddev_popFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statusAction?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SchedulingActionStddev_SampFields = {
  __typename?: 'schedulingActionStddev_sampFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statusAction?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SchedulingActionSumFields = {
  __typename?: 'schedulingActionSumFields';
  appointmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  statusAction?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_schedulingaction" */
export enum SchedulingActionUpdateColumn {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Reachable = 'reachable',
  /** column name */
  RefusedVisit = 'refusedVisit',
  /** column name */
  StatusAction = 'statusAction',
  /** column name */
  VoicemailLeft = 'voicemailLeft'
}

export type SchedulingActionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SchedulingActionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SchedulingActionSetInput>;
  where: SchedulingActionBoolExp;
};

/** aggregate var_pop on columns */
export type SchedulingActionVar_PopFields = {
  __typename?: 'schedulingActionVar_popFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statusAction?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SchedulingActionVar_SampFields = {
  __typename?: 'schedulingActionVar_sampFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statusAction?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SchedulingActionVarianceFields = {
  __typename?: 'schedulingActionVarianceFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  statusAction?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_schedulingaction" */
export type SchedulingAction_Avg_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  statusAction?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_schedulingaction" */
export type SchedulingAction_Max_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  statusAction?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_schedulingaction" */
export type SchedulingAction_Min_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  statusAction?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_schedulingaction" */
export type SchedulingAction_Stddev_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  statusAction?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_schedulingaction" */
export type SchedulingAction_Stddev_Pop_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  statusAction?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_schedulingaction" */
export type SchedulingAction_Stddev_Samp_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  statusAction?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "schedulingAction" */
export type SchedulingAction_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SchedulingAction_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SchedulingAction_StreamCursorValueInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  reachable?: InputMaybe<Scalars['Boolean']>;
  refusedVisit?: InputMaybe<Scalars['Boolean']>;
  statusAction?: InputMaybe<Scalars['smallint']>;
  voicemailLeft?: InputMaybe<Scalars['Boolean']>;
};

/** order by sum() on columns of table "server_schedulingaction" */
export type SchedulingAction_Sum_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  statusAction?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_schedulingaction" */
export type SchedulingAction_Var_Pop_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  statusAction?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_schedulingaction" */
export type SchedulingAction_Var_Samp_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  statusAction?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_schedulingaction" */
export type SchedulingAction_Variance_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  statusAction?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_servicetype" */
export type ServiceType = {
  __typename?: 'serviceType';
  /** An array relationship */
  agencyServices: Array<AgencyServices>;
  /** An aggregate relationship */
  agencyServicesAggregate: AgencyServicesAggregate;
  category: Scalars['smallint'];
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};


/** columns and relationships of "server_servicetype" */
export type ServiceTypeAgencyServicesArgs = {
  distinctOn?: InputMaybe<Array<AgencyServicesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyServicesOrderBy>>;
  where?: InputMaybe<AgencyServicesBoolExp>;
};


/** columns and relationships of "server_servicetype" */
export type ServiceTypeAgencyServicesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyServicesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyServicesOrderBy>>;
  where?: InputMaybe<AgencyServicesBoolExp>;
};

/** aggregated selection of "server_servicetype" */
export type ServiceTypeAggregate = {
  __typename?: 'serviceTypeAggregate';
  aggregate?: Maybe<ServiceTypeAggregateFields>;
  nodes: Array<ServiceType>;
};

/** aggregate fields of "server_servicetype" */
export type ServiceTypeAggregateFields = {
  __typename?: 'serviceTypeAggregateFields';
  avg?: Maybe<ServiceTypeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ServiceTypeMaxFields>;
  min?: Maybe<ServiceTypeMinFields>;
  stddev?: Maybe<ServiceTypeStddevFields>;
  stddevPop?: Maybe<ServiceTypeStddev_PopFields>;
  stddevSamp?: Maybe<ServiceTypeStddev_SampFields>;
  sum?: Maybe<ServiceTypeSumFields>;
  varPop?: Maybe<ServiceTypeVar_PopFields>;
  varSamp?: Maybe<ServiceTypeVar_SampFields>;
  variance?: Maybe<ServiceTypeVarianceFields>;
};


/** aggregate fields of "server_servicetype" */
export type ServiceTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ServiceTypeAvgFields = {
  __typename?: 'serviceTypeAvgFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_servicetype". All fields are combined with a logical 'AND'. */
export type ServiceTypeBoolExp = {
  _and?: InputMaybe<Array<ServiceTypeBoolExp>>;
  _not?: InputMaybe<ServiceTypeBoolExp>;
  _or?: InputMaybe<Array<ServiceTypeBoolExp>>;
  agencyServices?: InputMaybe<AgencyServicesBoolExp>;
  category?: InputMaybe<SmallintComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_servicetype" */
export enum ServiceTypeConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerServicetypePkey = 'server_servicetype_pkey'
}

/** input type for incrementing numeric columns in table "server_servicetype" */
export type ServiceTypeIncInput = {
  category?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_servicetype" */
export type ServiceTypeInsertInput = {
  agencyServices?: InputMaybe<AgencyServicesArrRelInsertInput>;
  category?: InputMaybe<Scalars['smallint']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ServiceTypeMaxFields = {
  __typename?: 'serviceTypeMaxFields';
  category?: Maybe<Scalars['smallint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ServiceTypeMinFields = {
  __typename?: 'serviceTypeMinFields';
  category?: Maybe<Scalars['smallint']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_servicetype" */
export type ServiceTypeMutationResponse = {
  __typename?: 'serviceTypeMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceType>;
};

/** input type for inserting object relation for remote table "server_servicetype" */
export type ServiceTypeObjRelInsertInput = {
  data: ServiceTypeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceTypeOnConflict>;
};

/** on_conflict condition type for table "server_servicetype" */
export type ServiceTypeOnConflict = {
  constraint: ServiceTypeConstraint;
  update_columns?: Array<ServiceTypeUpdateColumn>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};

/** Ordering options when selecting data from "server_servicetype". */
export type ServiceTypeOrderBy = {
  agencyServicesAggregate?: InputMaybe<AgencyServicesAggregateOrderBy>;
  category?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_servicetype */
export type ServiceTypePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_servicetype" */
export enum ServiceTypeSelectColumn {
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "server_servicetype" */
export type ServiceTypeSetInput = {
  category?: InputMaybe<Scalars['smallint']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ServiceTypeStddevFields = {
  __typename?: 'serviceTypeStddevFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ServiceTypeStddev_PopFields = {
  __typename?: 'serviceTypeStddev_popFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ServiceTypeStddev_SampFields = {
  __typename?: 'serviceTypeStddev_sampFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ServiceTypeSumFields = {
  __typename?: 'serviceTypeSumFields';
  category?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_servicetype" */
export enum ServiceTypeUpdateColumn {
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type ServiceTypeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceTypeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceTypeSetInput>;
  where: ServiceTypeBoolExp;
};

/** aggregate var_pop on columns */
export type ServiceTypeVar_PopFields = {
  __typename?: 'serviceTypeVar_popFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ServiceTypeVar_SampFields = {
  __typename?: 'serviceTypeVar_sampFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ServiceTypeVarianceFields = {
  __typename?: 'serviceTypeVarianceFields';
  category?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "serviceType" */
export type ServiceType_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceType_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceType_StreamCursorValueInput = {
  category?: InputMaybe<Scalars['smallint']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "server_signedconsent" */
export type SignedConsent = {
  __typename?: 'signedConsent';
  /** An object relationship */
  consent?: Maybe<Consent>;
  consentId?: Maybe<Scalars['Int']>;
  datetime: Scalars['timestamptz'];
  /** An object relationship */
  document: Document;
  documentId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  signatureImage?: Maybe<Scalars['String']>;
};

/** aggregated selection of "server_signedconsent" */
export type SignedConsentAggregate = {
  __typename?: 'signedConsentAggregate';
  aggregate?: Maybe<SignedConsentAggregateFields>;
  nodes: Array<SignedConsent>;
};

/** aggregate fields of "server_signedconsent" */
export type SignedConsentAggregateFields = {
  __typename?: 'signedConsentAggregateFields';
  avg?: Maybe<SignedConsentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SignedConsentMaxFields>;
  min?: Maybe<SignedConsentMinFields>;
  stddev?: Maybe<SignedConsentStddevFields>;
  stddevPop?: Maybe<SignedConsentStddev_PopFields>;
  stddevSamp?: Maybe<SignedConsentStddev_SampFields>;
  sum?: Maybe<SignedConsentSumFields>;
  varPop?: Maybe<SignedConsentVar_PopFields>;
  varSamp?: Maybe<SignedConsentVar_SampFields>;
  variance?: Maybe<SignedConsentVarianceFields>;
};


/** aggregate fields of "server_signedconsent" */
export type SignedConsentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SignedConsentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_signedconsent" */
export type SignedConsentAggregateOrderBy = {
  avg?: InputMaybe<SignedConsent_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SignedConsent_Max_Order_By>;
  min?: InputMaybe<SignedConsent_Min_Order_By>;
  stddev?: InputMaybe<SignedConsent_Stddev_Order_By>;
  stddev_pop?: InputMaybe<SignedConsent_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<SignedConsent_Stddev_Samp_Order_By>;
  sum?: InputMaybe<SignedConsent_Sum_Order_By>;
  var_pop?: InputMaybe<SignedConsent_Var_Pop_Order_By>;
  var_samp?: InputMaybe<SignedConsent_Var_Samp_Order_By>;
  variance?: InputMaybe<SignedConsent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_signedconsent" */
export type SignedConsentArrRelInsertInput = {
  data: Array<SignedConsentInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<SignedConsentOnConflict>;
};

/** aggregate avg on columns */
export type SignedConsentAvgFields = {
  __typename?: 'signedConsentAvgFields';
  consentId?: Maybe<Scalars['Float']>;
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_signedconsent". All fields are combined with a logical 'AND'. */
export type SignedConsentBoolExp = {
  _and?: InputMaybe<Array<SignedConsentBoolExp>>;
  _not?: InputMaybe<SignedConsentBoolExp>;
  _or?: InputMaybe<Array<SignedConsentBoolExp>>;
  consent?: InputMaybe<ConsentBoolExp>;
  consentId?: InputMaybe<IntComparisonExp>;
  datetime?: InputMaybe<TimestamptzComparisonExp>;
  document?: InputMaybe<DocumentBoolExp>;
  documentId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  signatureImage?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_signedconsent" */
export enum SignedConsentConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerSignedconsentPkey = 'server_signedconsent_pkey'
}

/** input type for incrementing numeric columns in table "server_signedconsent" */
export type SignedConsentIncInput = {
  consentId?: InputMaybe<Scalars['Int']>;
  documentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_signedconsent" */
export type SignedConsentInsertInput = {
  consent?: InputMaybe<ConsentObjRelInsertInput>;
  consentId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  document?: InputMaybe<DocumentObjRelInsertInput>;
  documentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  signatureImage?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SignedConsentMaxFields = {
  __typename?: 'signedConsentMaxFields';
  consentId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  documentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  signatureImage?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SignedConsentMinFields = {
  __typename?: 'signedConsentMinFields';
  consentId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['timestamptz']>;
  documentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  signatureImage?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_signedconsent" */
export type SignedConsentMutationResponse = {
  __typename?: 'signedConsentMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SignedConsent>;
};

/** on_conflict condition type for table "server_signedconsent" */
export type SignedConsentOnConflict = {
  constraint: SignedConsentConstraint;
  update_columns?: Array<SignedConsentUpdateColumn>;
  where?: InputMaybe<SignedConsentBoolExp>;
};

/** Ordering options when selecting data from "server_signedconsent". */
export type SignedConsentOrderBy = {
  consent?: InputMaybe<ConsentOrderBy>;
  consentId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  document?: InputMaybe<DocumentOrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  signatureImage?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_signedconsent */
export type SignedConsentPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_signedconsent" */
export enum SignedConsentSelectColumn {
  /** column name */
  ConsentId = 'consentId',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  SignatureImage = 'signatureImage'
}

/** input type for updating data in table "server_signedconsent" */
export type SignedConsentSetInput = {
  consentId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  documentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  signatureImage?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type SignedConsentStddevFields = {
  __typename?: 'signedConsentStddevFields';
  consentId?: Maybe<Scalars['Float']>;
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SignedConsentStddev_PopFields = {
  __typename?: 'signedConsentStddev_popFields';
  consentId?: Maybe<Scalars['Float']>;
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SignedConsentStddev_SampFields = {
  __typename?: 'signedConsentStddev_sampFields';
  consentId?: Maybe<Scalars['Float']>;
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SignedConsentSumFields = {
  __typename?: 'signedConsentSumFields';
  consentId?: Maybe<Scalars['Int']>;
  documentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_signedconsent" */
export enum SignedConsentUpdateColumn {
  /** column name */
  ConsentId = 'consentId',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DocumentId = 'documentId',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  SignatureImage = 'signatureImage'
}

export type SignedConsentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SignedConsentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SignedConsentSetInput>;
  where: SignedConsentBoolExp;
};

/** aggregate var_pop on columns */
export type SignedConsentVar_PopFields = {
  __typename?: 'signedConsentVar_popFields';
  consentId?: Maybe<Scalars['Float']>;
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SignedConsentVar_SampFields = {
  __typename?: 'signedConsentVar_sampFields';
  consentId?: Maybe<Scalars['Float']>;
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SignedConsentVarianceFields = {
  __typename?: 'signedConsentVarianceFields';
  consentId?: Maybe<Scalars['Float']>;
  documentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_signedconsent" */
export type SignedConsent_Avg_Order_By = {
  consentId?: InputMaybe<OrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_signedconsent" */
export type SignedConsent_Max_Order_By = {
  consentId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  signatureImage?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_signedconsent" */
export type SignedConsent_Min_Order_By = {
  consentId?: InputMaybe<OrderBy>;
  datetime?: InputMaybe<OrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  signatureImage?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_signedconsent" */
export type SignedConsent_Stddev_Order_By = {
  consentId?: InputMaybe<OrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_signedconsent" */
export type SignedConsent_Stddev_Pop_Order_By = {
  consentId?: InputMaybe<OrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_signedconsent" */
export type SignedConsent_Stddev_Samp_Order_By = {
  consentId?: InputMaybe<OrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "signedConsent" */
export type SignedConsent_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SignedConsent_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SignedConsent_StreamCursorValueInput = {
  consentId?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamptz']>;
  documentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  signatureImage?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_signedconsent" */
export type SignedConsent_Sum_Order_By = {
  consentId?: InputMaybe<OrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_signedconsent" */
export type SignedConsent_Var_Pop_Order_By = {
  consentId?: InputMaybe<OrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_signedconsent" */
export type SignedConsent_Var_Samp_Order_By = {
  consentId?: InputMaybe<OrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_signedconsent" */
export type SignedConsent_Variance_Order_By = {
  consentId?: InputMaybe<OrderBy>;
  documentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_signoff" */
export type Signoff = {
  __typename?: 'signoff';
  active: Scalars['Boolean'];
  clinicalNoteId: Scalars['Int'];
  /** An object relationship */
  clinicalnote: Clinicalnote;
  date: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  signoffType?: Maybe<SignoffType>;
  /** An object relationship */
  signoffUser: User;
  typeId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
};

/** aggregated selection of "server_signoff" */
export type SignoffAggregate = {
  __typename?: 'signoffAggregate';
  aggregate?: Maybe<SignoffAggregateFields>;
  nodes: Array<Signoff>;
};

/** aggregate fields of "server_signoff" */
export type SignoffAggregateFields = {
  __typename?: 'signoffAggregateFields';
  avg?: Maybe<SignoffAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SignoffMaxFields>;
  min?: Maybe<SignoffMinFields>;
  stddev?: Maybe<SignoffStddevFields>;
  stddevPop?: Maybe<SignoffStddev_PopFields>;
  stddevSamp?: Maybe<SignoffStddev_SampFields>;
  sum?: Maybe<SignoffSumFields>;
  varPop?: Maybe<SignoffVar_PopFields>;
  varSamp?: Maybe<SignoffVar_SampFields>;
  variance?: Maybe<SignoffVarianceFields>;
};


/** aggregate fields of "server_signoff" */
export type SignoffAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SignoffSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_signoff" */
export type SignoffAggregateOrderBy = {
  avg?: InputMaybe<Signoff_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Signoff_Max_Order_By>;
  min?: InputMaybe<Signoff_Min_Order_By>;
  stddev?: InputMaybe<Signoff_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Signoff_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Signoff_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Signoff_Sum_Order_By>;
  var_pop?: InputMaybe<Signoff_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Signoff_Var_Samp_Order_By>;
  variance?: InputMaybe<Signoff_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_signoff" */
export type SignoffArrRelInsertInput = {
  data: Array<SignoffInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<SignoffOnConflict>;
};

/** aggregate avg on columns */
export type SignoffAvgFields = {
  __typename?: 'signoffAvgFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_signoff". All fields are combined with a logical 'AND'. */
export type SignoffBoolExp = {
  _and?: InputMaybe<Array<SignoffBoolExp>>;
  _not?: InputMaybe<SignoffBoolExp>;
  _or?: InputMaybe<Array<SignoffBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  clinicalNoteId?: InputMaybe<IntComparisonExp>;
  clinicalnote?: InputMaybe<ClinicalnoteBoolExp>;
  date?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  signoffType?: InputMaybe<SignoffTypeBoolExp>;
  signoffUser?: InputMaybe<UserBoolExp>;
  typeId?: InputMaybe<IntComparisonExp>;
  userId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_signoff" */
export enum SignoffConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerSignoffPkey = 'server_signoff_pkey'
}

/** input type for incrementing numeric columns in table "server_signoff" */
export type SignoffIncInput = {
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_signoff" */
export type SignoffInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  clinicalnote?: InputMaybe<ClinicalnoteObjRelInsertInput>;
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  signoffType?: InputMaybe<SignoffTypeObjRelInsertInput>;
  signoffUser?: InputMaybe<UserObjRelInsertInput>;
  typeId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type SignoffMaxFields = {
  __typename?: 'signoffMaxFields';
  clinicalNoteId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  typeId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type SignoffMinFields = {
  __typename?: 'signoffMinFields';
  clinicalNoteId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  typeId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_signoff" */
export type SignoffMutationResponse = {
  __typename?: 'signoffMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Signoff>;
};

/** on_conflict condition type for table "server_signoff" */
export type SignoffOnConflict = {
  constraint: SignoffConstraint;
  update_columns?: Array<SignoffUpdateColumn>;
  where?: InputMaybe<SignoffBoolExp>;
};

/** Ordering options when selecting data from "server_signoff". */
export type SignoffOrderBy = {
  active?: InputMaybe<OrderBy>;
  clinicalNoteId?: InputMaybe<OrderBy>;
  clinicalnote?: InputMaybe<ClinicalnoteOrderBy>;
  date?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  signoffType?: InputMaybe<SignoffTypeOrderBy>;
  signoffUser?: InputMaybe<UserOrderBy>;
  typeId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_signoff */
export type SignoffPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_signoff" */
export enum SignoffSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  ClinicalNoteId = 'clinicalNoteId',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  TypeId = 'typeId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "server_signoff" */
export type SignoffSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type SignoffStddevFields = {
  __typename?: 'signoffStddevFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SignoffStddev_PopFields = {
  __typename?: 'signoffStddev_popFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SignoffStddev_SampFields = {
  __typename?: 'signoffStddev_sampFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SignoffSumFields = {
  __typename?: 'signoffSumFields';
  clinicalNoteId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  typeId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "server_signofftype" */
export type SignoffType = {
  __typename?: 'signoffType';
  id: Scalars['Int'];
  name: Scalars['String'];
  noteStatement?: Maybe<Scalars['String']>;
  /** An array relationship */
  signoffs: Array<Signoff>;
  /** An aggregate relationship */
  signoffsAggregate: SignoffAggregate;
};


/** columns and relationships of "server_signofftype" */
export type SignoffTypeSignoffsArgs = {
  distinctOn?: InputMaybe<Array<SignoffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignoffOrderBy>>;
  where?: InputMaybe<SignoffBoolExp>;
};


/** columns and relationships of "server_signofftype" */
export type SignoffTypeSignoffsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SignoffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignoffOrderBy>>;
  where?: InputMaybe<SignoffBoolExp>;
};

/** aggregated selection of "server_signofftype" */
export type SignoffTypeAggregate = {
  __typename?: 'signoffTypeAggregate';
  aggregate?: Maybe<SignoffTypeAggregateFields>;
  nodes: Array<SignoffType>;
};

/** aggregate fields of "server_signofftype" */
export type SignoffTypeAggregateFields = {
  __typename?: 'signoffTypeAggregateFields';
  avg?: Maybe<SignoffTypeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SignoffTypeMaxFields>;
  min?: Maybe<SignoffTypeMinFields>;
  stddev?: Maybe<SignoffTypeStddevFields>;
  stddevPop?: Maybe<SignoffTypeStddev_PopFields>;
  stddevSamp?: Maybe<SignoffTypeStddev_SampFields>;
  sum?: Maybe<SignoffTypeSumFields>;
  varPop?: Maybe<SignoffTypeVar_PopFields>;
  varSamp?: Maybe<SignoffTypeVar_SampFields>;
  variance?: Maybe<SignoffTypeVarianceFields>;
};


/** aggregate fields of "server_signofftype" */
export type SignoffTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SignoffTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SignoffTypeAvgFields = {
  __typename?: 'signoffTypeAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_signofftype". All fields are combined with a logical 'AND'. */
export type SignoffTypeBoolExp = {
  _and?: InputMaybe<Array<SignoffTypeBoolExp>>;
  _not?: InputMaybe<SignoffTypeBoolExp>;
  _or?: InputMaybe<Array<SignoffTypeBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  noteStatement?: InputMaybe<StringComparisonExp>;
  signoffs?: InputMaybe<SignoffBoolExp>;
};

/** unique or primary key constraints on table "server_signofftype" */
export enum SignoffTypeConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerSignofftypePkey = 'server_signofftype_pkey'
}

/** input type for incrementing numeric columns in table "server_signofftype" */
export type SignoffTypeIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_signofftype" */
export type SignoffTypeInsertInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  noteStatement?: InputMaybe<Scalars['String']>;
  signoffs?: InputMaybe<SignoffArrRelInsertInput>;
};

/** aggregate max on columns */
export type SignoffTypeMaxFields = {
  __typename?: 'signoffTypeMaxFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  noteStatement?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SignoffTypeMinFields = {
  __typename?: 'signoffTypeMinFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  noteStatement?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_signofftype" */
export type SignoffTypeMutationResponse = {
  __typename?: 'signoffTypeMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SignoffType>;
};

/** input type for inserting object relation for remote table "server_signofftype" */
export type SignoffTypeObjRelInsertInput = {
  data: SignoffTypeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SignoffTypeOnConflict>;
};

/** on_conflict condition type for table "server_signofftype" */
export type SignoffTypeOnConflict = {
  constraint: SignoffTypeConstraint;
  update_columns?: Array<SignoffTypeUpdateColumn>;
  where?: InputMaybe<SignoffTypeBoolExp>;
};

/** Ordering options when selecting data from "server_signofftype". */
export type SignoffTypeOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  noteStatement?: InputMaybe<OrderBy>;
  signoffsAggregate?: InputMaybe<SignoffAggregateOrderBy>;
};

/** primary key columns input for table: server_signofftype */
export type SignoffTypePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_signofftype" */
export enum SignoffTypeSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NoteStatement = 'noteStatement'
}

/** input type for updating data in table "server_signofftype" */
export type SignoffTypeSetInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  noteStatement?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type SignoffTypeStddevFields = {
  __typename?: 'signoffTypeStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SignoffTypeStddev_PopFields = {
  __typename?: 'signoffTypeStddev_popFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SignoffTypeStddev_SampFields = {
  __typename?: 'signoffTypeStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type SignoffTypeSumFields = {
  __typename?: 'signoffTypeSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_signofftype" */
export enum SignoffTypeUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NoteStatement = 'noteStatement'
}

export type SignoffTypeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SignoffTypeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SignoffTypeSetInput>;
  where: SignoffTypeBoolExp;
};

/** aggregate var_pop on columns */
export type SignoffTypeVar_PopFields = {
  __typename?: 'signoffTypeVar_popFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SignoffTypeVar_SampFields = {
  __typename?: 'signoffTypeVar_sampFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SignoffTypeVarianceFields = {
  __typename?: 'signoffTypeVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "signoffType" */
export type SignoffType_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SignoffType_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SignoffType_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  noteStatement?: InputMaybe<Scalars['String']>;
};

/** update columns of table "server_signoff" */
export enum SignoffUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  ClinicalNoteId = 'clinicalNoteId',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  TypeId = 'typeId',
  /** column name */
  UserId = 'userId'
}

export type SignoffUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SignoffIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SignoffSetInput>;
  where: SignoffBoolExp;
};

/** aggregate var_pop on columns */
export type SignoffVar_PopFields = {
  __typename?: 'signoffVar_popFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SignoffVar_SampFields = {
  __typename?: 'signoffVar_sampFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SignoffVarianceFields = {
  __typename?: 'signoffVarianceFields';
  clinicalNoteId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_signoff" */
export type Signoff_Avg_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  typeId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_signoff" */
export type Signoff_Max_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  typeId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_signoff" */
export type Signoff_Min_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  typeId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_signoff" */
export type Signoff_Stddev_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  typeId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_signoff" */
export type Signoff_Stddev_Pop_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  typeId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_signoff" */
export type Signoff_Stddev_Samp_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  typeId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "signoff" */
export type Signoff_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Signoff_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Signoff_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  clinicalNoteId?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_signoff" */
export type Signoff_Sum_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  typeId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_signoff" */
export type Signoff_Var_Pop_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  typeId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_signoff" */
export type Signoff_Var_Samp_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  typeId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_signoff" */
export type Signoff_Variance_Order_By = {
  clinicalNoteId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  typeId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "server_address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "server_address" */
  addressAggregate: AddressAggregate;
  /** fetch data from the table: "server_address" using primary key columns */
  addressByPk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner : "server_address" */
  addressStream: Array<Address>;
  /** fetch data from the table: "server_agency" */
  agency: Array<Agency>;
  /** fetch aggregated fields from the table: "server_agency" */
  agencyAggregate: AgencyAggregate;
  /** fetch data from the table: "server_agency" using primary key columns */
  agencyByPk?: Maybe<Agency>;
  /** fetch data from the table: "server_agencylocation" */
  agencyLocation: Array<AgencyLocation>;
  /** fetch aggregated fields from the table: "server_agencylocation" */
  agencyLocationAggregate: AgencyLocationAggregate;
  /** fetch data from the table: "server_agencylocation" using primary key columns */
  agencyLocationByPk?: Maybe<AgencyLocation>;
  /** fetch data from the table in a streaming manner : "server_agencylocation" */
  agencyLocationStream: Array<AgencyLocation>;
  /** fetch data from the table: "server_agency_payers" */
  agencyPayers: Array<AgencyPayers>;
  /** fetch aggregated fields from the table: "server_agency_payers" */
  agencyPayersAggregate: AgencyPayersAggregate;
  /** fetch data from the table: "server_agency_payers" using primary key columns */
  agencyPayersByPk?: Maybe<AgencyPayers>;
  /** fetch data from the table in a streaming manner : "server_agency_payers" */
  agencyPayersStream: Array<AgencyPayers>;
  /** An array relationship */
  agencyServices: Array<AgencyServices>;
  /** An aggregate relationship */
  agencyServicesAggregate: AgencyServicesAggregate;
  /** fetch data from the table: "server_agency_services" using primary key columns */
  agencyServicesByPk?: Maybe<AgencyServices>;
  /** fetch data from the table in a streaming manner : "server_agency_services" */
  agencyServicesStream: Array<AgencyServices>;
  /** fetch data from the table in a streaming manner : "server_agency" */
  agencyStream: Array<Agency>;
  /** fetch data from the table: "server_agencyconnection" */
  agencyconnection: Array<Agencyconnection>;
  /** fetch aggregated fields from the table: "server_agencyconnection" */
  agencyconnectionAggregate: AgencyconnectionAggregate;
  /** fetch data from the table: "server_agencyconnection" using primary key columns */
  agencyconnectionByPk?: Maybe<Agencyconnection>;
  /** fetch data from the table in a streaming manner : "server_agencyconnection" */
  agencyconnectionStream: Array<Agencyconnection>;
  /** fetch data from the table: "server_agencyjoinrequest" */
  agencyjoinrequest: Array<Agencyjoinrequest>;
  /** fetch aggregated fields from the table: "server_agencyjoinrequest" */
  agencyjoinrequestAggregate: AgencyjoinrequestAggregate;
  /** fetch data from the table: "server_agencyjoinrequest" using primary key columns */
  agencyjoinrequestByPk?: Maybe<Agencyjoinrequest>;
  /** fetch data from the table in a streaming manner : "server_agencyjoinrequest" */
  agencyjoinrequestStream: Array<Agencyjoinrequest>;
  /** fetch data from the table: "server_allergy" */
  allergy: Array<Allergy>;
  /** fetch aggregated fields from the table: "server_allergy" */
  allergyAggregate: AllergyAggregate;
  /** fetch data from the table: "server_allergy" using primary key columns */
  allergyByPk?: Maybe<Allergy>;
  /** fetch data from the table in a streaming manner : "server_allergy" */
  allergyStream: Array<Allergy>;
  /** fetch data from the table: "server_appointment" */
  appointment: Array<Appointment>;
  /** fetch aggregated fields from the table: "server_appointment" */
  appointmentAggregate: AppointmentAggregate;
  /** fetch data from the table: "server_appointment" using primary key columns */
  appointmentByPk?: Maybe<Appointment>;
  /** fetch data from the table in a streaming manner : "server_appointment" */
  appointmentStream: Array<Appointment>;
  /** fetch data from the table: "server_assessment" */
  assessment: Array<Assessment>;
  /** fetch aggregated fields from the table: "server_assessment" */
  assessmentAggregate: AssessmentAggregate;
  /** fetch data from the table: "server_assessment" using primary key columns */
  assessmentByPk?: Maybe<Assessment>;
  /** fetch data from the table: "server_assessmentitem" */
  assessmentItem: Array<AssessmentItem>;
  /** fetch aggregated fields from the table: "server_assessmentitem" */
  assessmentItemAggregate: AssessmentItemAggregate;
  /** fetch data from the table: "server_assessmentitem" using primary key columns */
  assessmentItemByPk?: Maybe<AssessmentItem>;
  /** fetch data from the table in a streaming manner : "server_assessmentitem" */
  assessmentItemStream: Array<AssessmentItem>;
  /** fetch data from the table: "server_assessmentitemvalue" */
  assessmentItemValue: Array<AssessmentItemValue>;
  /** fetch aggregated fields from the table: "server_assessmentitemvalue" */
  assessmentItemValueAggregate: AssessmentItemValueAggregate;
  /** fetch data from the table: "server_assessmentitemvalue" using primary key columns */
  assessmentItemValueByPk?: Maybe<AssessmentItemValue>;
  /** fetch data from the table in a streaming manner : "server_assessmentitemvalue" */
  assessmentItemValueStream: Array<AssessmentItemValue>;
  /** fetch data from the table in a streaming manner : "server_assessment" */
  assessmentStream: Array<Assessment>;
  /** fetch data from the table: "server_baseprotocolorder" */
  baseProtocolOrder: Array<BaseProtocolOrder>;
  /** fetch aggregated fields from the table: "server_baseprotocolorder" */
  baseProtocolOrderAggregate: BaseProtocolOrderAggregate;
  /** fetch data from the table: "server_baseprotocolorder" using primary key columns */
  baseProtocolOrderByPk?: Maybe<BaseProtocolOrder>;
  /** fetch data from the table in a streaming manner : "server_baseprotocolorder" */
  baseProtocolOrderStream: Array<BaseProtocolOrder>;
  /** fetch data from the table: "server_baseorder" */
  baseorder: Array<Baseorder>;
  /** fetch aggregated fields from the table: "server_baseorder" */
  baseorderAggregate: BaseorderAggregate;
  /** fetch data from the table: "server_baseorder" using primary key columns */
  baseorderByPk?: Maybe<Baseorder>;
  /** fetch data from the table in a streaming manner : "server_baseorder" */
  baseorderStream: Array<Baseorder>;
  /** fetch data from the table: "server_caretime" */
  caretime: Array<Caretime>;
  /** fetch aggregated fields from the table: "server_caretime" */
  caretimeAggregate: CaretimeAggregate;
  /** fetch data from the table: "server_caretime" using primary key columns */
  caretimeByPk?: Maybe<Caretime>;
  /** fetch data from the table: "server_caretime_problems" */
  caretimeProblems: Array<CaretimeProblems>;
  /** fetch aggregated fields from the table: "server_caretime_problems" */
  caretimeProblemsAggregate: CaretimeProblemsAggregate;
  /** fetch data from the table: "server_caretime_problems" using primary key columns */
  caretimeProblemsByPk?: Maybe<CaretimeProblems>;
  /** fetch data from the table in a streaming manner : "server_caretime_problems" */
  caretimeProblemsStream: Array<CaretimeProblems>;
  /** fetch data from the table in a streaming manner : "server_caretime" */
  caretimeStream: Array<Caretime>;
  /** fetch data from the table: "server_claim" */
  claim: Array<Claim>;
  /** fetch aggregated fields from the table: "server_claim" */
  claimAggregate: ClaimAggregate;
  /** fetch data from the table: "server_claim" using primary key columns */
  claimByPk?: Maybe<Claim>;
  /** fetch data from the table in a streaming manner : "server_claim" */
  claimStream: Array<Claim>;
  /** fetch data from the table: "server_futurereferralclinicalnote" */
  clinicalNoteFutureReferrals: Array<ClinicalNoteFutureReferrals>;
  /** fetch aggregated fields from the table: "server_futurereferralclinicalnote" */
  clinicalNoteFutureReferralsAggregate: ClinicalNoteFutureReferralsAggregate;
  /** fetch data from the table: "server_futurereferralclinicalnote" using primary key columns */
  clinicalNoteFutureReferralsByPk?: Maybe<ClinicalNoteFutureReferrals>;
  /** fetch data from the table in a streaming manner : "server_futurereferralclinicalnote" */
  clinicalNoteFutureReferralsStream: Array<ClinicalNoteFutureReferrals>;
  /** fetch data from the table: "server_clinicalnote" */
  clinicalnote: Array<Clinicalnote>;
  /** fetch aggregated fields from the table: "server_clinicalnote" */
  clinicalnoteAggregate: ClinicalnoteAggregate;
  /** fetch data from the table: "server_clinicalnote" using primary key columns */
  clinicalnoteByPk?: Maybe<Clinicalnote>;
  /** fetch data from the table: "server_clinicalnotefield" */
  clinicalnoteField: Array<ClinicalnoteField>;
  /** fetch aggregated fields from the table: "server_clinicalnotefield" */
  clinicalnoteFieldAggregate: ClinicalnoteFieldAggregate;
  /** fetch data from the table: "server_clinicalnotefield" using primary key columns */
  clinicalnoteFieldByPk?: Maybe<ClinicalnoteField>;
  /** fetch data from the table in a streaming manner : "server_clinicalnotefield" */
  clinicalnoteFieldStream: Array<ClinicalnoteField>;
  /** fetch data from the table: "server_clinicalnotefieldvalue" */
  clinicalnoteFieldValue: Array<ClinicalnoteFieldValue>;
  /** fetch aggregated fields from the table: "server_clinicalnotefieldvalue" */
  clinicalnoteFieldValueAggregate: ClinicalnoteFieldValueAggregate;
  /** fetch data from the table: "server_clinicalnotefieldvalue" using primary key columns */
  clinicalnoteFieldValueByPk?: Maybe<ClinicalnoteFieldValue>;
  /** fetch data from the table in a streaming manner : "server_clinicalnotefieldvalue" */
  clinicalnoteFieldValueStream: Array<ClinicalnoteFieldValue>;
  /** fetch data from the table in a streaming manner : "server_clinicalnote" */
  clinicalnoteStream: Array<Clinicalnote>;
  /** fetch data from the table: "server_clinicalnotetemplate" */
  clinicalnoteTemplate: Array<ClinicalnoteTemplate>;
  /** fetch aggregated fields from the table: "server_clinicalnotetemplate" */
  clinicalnoteTemplateAggregate: ClinicalnoteTemplateAggregate;
  /** fetch data from the table: "server_clinicalnotetemplate" using primary key columns */
  clinicalnoteTemplateByPk?: Maybe<ClinicalnoteTemplate>;
  /** fetch data from the table: "server_clinicalnotetemplate_provider_classifications" */
  clinicalnoteTemplateProviderClassifications: Array<ClinicalnoteTemplateProviderClassifications>;
  /** fetch aggregated fields from the table: "server_clinicalnotetemplate_provider_classifications" */
  clinicalnoteTemplateProviderClassificationsAggregate: ClinicalnoteTemplateProviderClassificationsAggregate;
  /** fetch data from the table: "server_clinicalnotetemplate_provider_classifications" using primary key columns */
  clinicalnoteTemplateProviderClassificationsByPk?: Maybe<ClinicalnoteTemplateProviderClassifications>;
  /** fetch data from the table in a streaming manner : "server_clinicalnotetemplate_provider_classifications" */
  clinicalnoteTemplateProviderClassificationsStream: Array<ClinicalnoteTemplateProviderClassifications>;
  /** fetch data from the table in a streaming manner : "server_clinicalnotetemplate" */
  clinicalnoteTemplateStream: Array<ClinicalnoteTemplate>;
  /** fetch data from the table: "server_codeset" */
  codeset: Array<Codeset>;
  /** fetch data from the table: "server_codeset_addon_codeset" */
  codesetAddon: Array<CodesetAddon>;
  /** fetch aggregated fields from the table: "server_codeset_addon_codeset" */
  codesetAddonAggregate: CodesetAddonAggregate;
  /** fetch data from the table: "server_codeset_addon_codeset" using primary key columns */
  codesetAddonByPk?: Maybe<CodesetAddon>;
  /** fetch data from the table in a streaming manner : "server_codeset_addon_codeset" */
  codesetAddonStream: Array<CodesetAddon>;
  /** fetch aggregated fields from the table: "server_codeset" */
  codesetAggregate: CodesetAggregate;
  /** fetch data from the table: "server_codeset_base_codeset" */
  codesetBase: Array<CodesetBase>;
  /** fetch aggregated fields from the table: "server_codeset_base_codeset" */
  codesetBaseAggregate: CodesetBaseAggregate;
  /** fetch data from the table: "server_codeset_base_codeset" using primary key columns */
  codesetBaseByPk?: Maybe<CodesetBase>;
  /** fetch data from the table in a streaming manner : "server_codeset_base_codeset" */
  codesetBaseStream: Array<CodesetBase>;
  /** fetch data from the table: "server_codeset" using primary key columns */
  codesetByPk?: Maybe<Codeset>;
  /** fetch data from the table in a streaming manner : "server_codeset" */
  codesetStream: Array<Codeset>;
  /** fetch data from the table: "server_consent" */
  consent: Array<Consent>;
  /** fetch aggregated fields from the table: "server_consent" */
  consentAggregate: ConsentAggregate;
  /** fetch data from the table: "server_consent" using primary key columns */
  consentByPk?: Maybe<Consent>;
  /** fetch data from the table in a streaming manner : "server_consent" */
  consentStream: Array<Consent>;
  /** fetch data from the table: "server_cpoclaim" */
  cpoClaim: Array<CpoClaim>;
  /** fetch aggregated fields from the table: "server_cpoclaim" */
  cpoClaimAggregate: CpoClaimAggregate;
  /** fetch data from the table: "server_cpoclaim" using primary key columns */
  cpoClaimByPk?: Maybe<CpoClaim>;
  /** fetch data from the table in a streaming manner : "server_cpoclaim" */
  cpoClaimStream: Array<CpoClaim>;
  /** fetch data from the table: "server_dependantorder" */
  dependantOrder: Array<DependantOrder>;
  /** fetch aggregated fields from the table: "server_dependantorder" */
  dependantOrderAggregate: DependantOrderAggregate;
  /** fetch data from the table: "server_dependantorder" using primary key columns */
  dependantOrderByPk?: Maybe<DependantOrder>;
  /** fetch data from the table in a streaming manner : "server_dependantorder" */
  dependantOrderStream: Array<DependantOrder>;
  /** fetch data from the table: "server_diagnosisabbreviation" */
  diagnosisAbbreviation: Array<DiagnosisAbbreviation>;
  /** fetch aggregated fields from the table: "server_diagnosisabbreviation" */
  diagnosisAbbreviationAggregate: DiagnosisAbbreviationAggregate;
  /** fetch data from the table: "server_diagnosisabbreviation" using primary key columns */
  diagnosisAbbreviationByPk?: Maybe<DiagnosisAbbreviation>;
  /** fetch data from the table in a streaming manner : "server_diagnosisabbreviation" */
  diagnosisAbbreviationStream: Array<DiagnosisAbbreviation>;
  /** fetch data from the table: "server_document" */
  document: Array<Document>;
  /** fetch aggregated fields from the table: "server_document" */
  documentAggregate: DocumentAggregate;
  /** fetch data from the table: "server_document" using primary key columns */
  documentByPk?: Maybe<Document>;
  /** fetch data from the table: "server_documentpendingreview" */
  documentPendingReview: Array<DocumentPendingReview>;
  /** fetch aggregated fields from the table: "server_documentpendingreview" */
  documentPendingReviewAggregate: DocumentPendingReviewAggregate;
  /** fetch data from the table: "server_documentpendingreview" using primary key columns */
  documentPendingReviewByPk?: Maybe<DocumentPendingReview>;
  /** fetch data from the table in a streaming manner : "server_documentpendingreview" */
  documentPendingReviewStream: Array<DocumentPendingReview>;
  /** fetch data from the table in a streaming manner : "server_document" */
  documentStream: Array<Document>;
  /** fetch data from the table: "server_externalsignedorder" */
  externalSignedOrder: Array<ExternalSignedOrder>;
  /** fetch aggregated fields from the table: "server_externalsignedorder" */
  externalSignedOrderAggregate: ExternalSignedOrderAggregate;
  /** fetch data from the table: "server_externalsignedorder" using primary key columns */
  externalSignedOrderByPk?: Maybe<ExternalSignedOrder>;
  /** fetch data from the table in a streaming manner : "server_externalsignedorder" */
  externalSignedOrderStream: Array<ExternalSignedOrder>;
  /** fetch data from the table: "server_externalstaff_my_patients" */
  externalStaffMyPatients: Array<ExternalStaffMyPatients>;
  /** fetch aggregated fields from the table: "server_externalstaff_my_patients" */
  externalStaffMyPatientsAggregate: ExternalStaffMyPatientsAggregate;
  /** fetch data from the table: "server_externalstaff_my_patients" using primary key columns */
  externalStaffMyPatientsByPk?: Maybe<ExternalStaffMyPatients>;
  /** fetch data from the table in a streaming manner : "server_externalstaff_my_patients" */
  externalStaffMyPatientsStream: Array<ExternalStaffMyPatients>;
  /** fetch data from the table: "server_externalstaff" */
  externalstaff: Array<Externalstaff>;
  /** fetch aggregated fields from the table: "server_externalstaff" */
  externalstaffAggregate: ExternalstaffAggregate;
  /** fetch data from the table: "server_externalstaff" using primary key columns */
  externalstaffByPk?: Maybe<Externalstaff>;
  /** fetch data from the table in a streaming manner : "server_externalstaff" */
  externalstaffStream: Array<Externalstaff>;
  /** fetch data from the table: "server_fax" */
  fax: Array<Fax>;
  /** fetch aggregated fields from the table: "server_fax" */
  faxAggregate: FaxAggregate;
  /** fetch data from the table: "server_fax" using primary key columns */
  faxByPk?: Maybe<Fax>;
  /** fetch data from the table: "server_fax_documents" */
  faxDocuments: Array<FaxDocuments>;
  /** fetch aggregated fields from the table: "server_fax_documents" */
  faxDocumentsAggregate: FaxDocumentsAggregate;
  /** fetch data from the table: "server_fax_documents" using primary key columns */
  faxDocumentsByPk?: Maybe<FaxDocuments>;
  /** fetch data from the table in a streaming manner : "server_fax_documents" */
  faxDocumentsStream: Array<FaxDocuments>;
  /** fetch data from the table in a streaming manner : "server_fax" */
  faxStream: Array<Fax>;
  /** fetch data from the table: "server_freetextorder" */
  freeTextOrder: Array<FreeTextOrder>;
  /** fetch aggregated fields from the table: "server_freetextorder" */
  freeTextOrderAggregate: FreeTextOrderAggregate;
  /** fetch data from the table: "server_freetextorder" using primary key columns */
  freeTextOrderByPk?: Maybe<FreeTextOrder>;
  /** fetch data from the table in a streaming manner : "server_freetextorder" */
  freeTextOrderStream: Array<FreeTextOrder>;
  /** fetch data from the table: "server_frequencyorder" */
  frequencyOrder: Array<FrequencyOrder>;
  /** fetch aggregated fields from the table: "server_frequencyorder" */
  frequencyOrderAggregate: FrequencyOrderAggregate;
  /** fetch data from the table: "server_frequencyorder" using primary key columns */
  frequencyOrderByPk?: Maybe<FrequencyOrder>;
  /** fetch data from the table in a streaming manner : "server_frequencyorder" */
  frequencyOrderStream: Array<FrequencyOrder>;
  /** fetch data from the table: "server_frequencyprotocol" */
  frequencyProtocol: Array<FrequencyProtocol>;
  /** fetch aggregated fields from the table: "server_frequencyprotocol" */
  frequencyProtocolAggregate: FrequencyProtocolAggregate;
  /** fetch data from the table: "server_frequencyprotocol" using primary key columns */
  frequencyProtocolByPk?: Maybe<FrequencyProtocol>;
  /** fetch data from the table in a streaming manner : "server_frequencyprotocol" */
  frequencyProtocolStream: Array<FrequencyProtocol>;
  /** fetch data from the table: "server_hcpcs" */
  hcpcs: Array<Hcpcs>;
  /** fetch aggregated fields from the table: "server_hcpcs" */
  hcpcsAggregate: HcpcsAggregate;
  /** fetch data from the table: "server_hcpcs" using primary key columns */
  hcpcsByPk?: Maybe<Hcpcs>;
  /** fetch data from the table in a streaming manner : "server_hcpcs" */
  hcpcsStream: Array<Hcpcs>;
  /** fetch data from the table: "server_insuranceplan" */
  insuranceplan: Array<Insuranceplan>;
  /** fetch aggregated fields from the table: "server_insuranceplan" */
  insuranceplanAggregate: InsuranceplanAggregate;
  /** fetch data from the table: "server_insuranceplan" using primary key columns */
  insuranceplanByPk?: Maybe<Insuranceplan>;
  /** fetch data from the table in a streaming manner : "server_insuranceplan" */
  insuranceplanStream: Array<Insuranceplan>;
  /** fetch data from the table: "server_integration" */
  integration: Array<Integration>;
  /** fetch aggregated fields from the table: "server_integration" */
  integrationAggregate: IntegrationAggregate;
  /** fetch data from the table: "server_integration" using primary key columns */
  integrationByPk?: Maybe<Integration>;
  /** fetch data from the table in a streaming manner : "server_integration" */
  integrationStream: Array<Integration>;
  /** fetch data from the table: "server_internalstaff" */
  internalstaff: Array<Internalstaff>;
  /** fetch aggregated fields from the table: "server_internalstaff" */
  internalstaffAggregate: InternalstaffAggregate;
  /** fetch data from the table: "server_internalstaff" using primary key columns */
  internalstaffByPk?: Maybe<Internalstaff>;
  /** fetch data from the table in a streaming manner : "server_internalstaff" */
  internalstaffStream: Array<Internalstaff>;
  /** fetch data from the table: "server_order" */
  legacyOrder: Array<LegacyOrder>;
  /** fetch aggregated fields from the table: "server_order" */
  legacyOrderAggregate: LegacyOrderAggregate;
  /** fetch data from the table: "server_order" using primary key columns */
  legacyOrderByPk?: Maybe<LegacyOrder>;
  /** fetch data from the table: "server_orderfrequency" */
  legacyOrderFrequency: Array<LegacyOrderFrequency>;
  /** fetch aggregated fields from the table: "server_orderfrequency" */
  legacyOrderFrequencyAggregate: LegacyOrderFrequencyAggregate;
  /** fetch data from the table: "server_orderfrequency" using primary key columns */
  legacyOrderFrequencyByPk?: Maybe<LegacyOrderFrequency>;
  /** fetch data from the table in a streaming manner : "server_orderfrequency" */
  legacyOrderFrequencyStream: Array<LegacyOrderFrequency>;
  /** fetch data from the table: "server_order_medications" */
  legacyOrderMedications: Array<LegacyOrderMedications>;
  /** fetch aggregated fields from the table: "server_order_medications" */
  legacyOrderMedicationsAggregate: LegacyOrderMedicationsAggregate;
  /** fetch data from the table: "server_order_medications" using primary key columns */
  legacyOrderMedicationsByPk?: Maybe<LegacyOrderMedications>;
  /** fetch data from the table in a streaming manner : "server_order_medications" */
  legacyOrderMedicationsStream: Array<LegacyOrderMedications>;
  /** fetch data from the table: "server_orderorderablefieldvalue" */
  legacyOrderOrderableFieldValue: Array<LegacyOrderOrderableFieldValue>;
  /** fetch aggregated fields from the table: "server_orderorderablefieldvalue" */
  legacyOrderOrderableFieldValueAggregate: LegacyOrderOrderableFieldValueAggregate;
  /** fetch data from the table: "server_orderorderablefieldvalue" using primary key columns */
  legacyOrderOrderableFieldValueByPk?: Maybe<LegacyOrderOrderableFieldValue>;
  /** fetch data from the table in a streaming manner : "server_orderorderablefieldvalue" */
  legacyOrderOrderableFieldValueStream: Array<LegacyOrderOrderableFieldValue>;
  /** fetch data from the table: "server_orderqualification" */
  legacyOrderQualification: Array<LegacyOrderQualification>;
  /** fetch aggregated fields from the table: "server_orderqualification" */
  legacyOrderQualificationAggregate: LegacyOrderQualificationAggregate;
  /** fetch data from the table: "server_orderqualification" using primary key columns */
  legacyOrderQualificationByPk?: Maybe<LegacyOrderQualification>;
  /** fetch data from the table in a streaming manner : "server_orderqualification" */
  legacyOrderQualificationStream: Array<LegacyOrderQualification>;
  /** fetch data from the table in a streaming manner : "server_order" */
  legacyOrderStream: Array<LegacyOrder>;
  /** fetch data from the table: "server_treatmentplanorder" */
  legacyTreatmentplanOrder: Array<LegacyTreatmentplanOrder>;
  /** fetch aggregated fields from the table: "server_treatmentplanorder" */
  legacyTreatmentplanOrderAggregate: LegacyTreatmentplanOrderAggregate;
  /** fetch data from the table: "server_treatmentplanorder" using primary key columns */
  legacyTreatmentplanOrderByPk?: Maybe<LegacyTreatmentplanOrder>;
  /** fetch data from the table in a streaming manner : "server_treatmentplanorder" */
  legacyTreatmentplanOrderStream: Array<LegacyTreatmentplanOrder>;
  /** fetch data from the table: "server_lineitem" */
  lineitem: Array<Lineitem>;
  /** fetch aggregated fields from the table: "server_lineitem" */
  lineitemAggregate: LineitemAggregate;
  /** fetch data from the table: "server_lineitem" using primary key columns */
  lineitemByPk?: Maybe<Lineitem>;
  /** fetch data from the table in a streaming manner : "server_lineitem" */
  lineitemStream: Array<Lineitem>;
  /** fetch data from the table: "server_medication" */
  medication: Array<Medication>;
  /** fetch aggregated fields from the table: "server_medication" */
  medicationAggregate: MedicationAggregate;
  /** fetch data from the table: "server_medication" using primary key columns */
  medicationByPk?: Maybe<Medication>;
  /** fetch data from the table: "server_medicationorder" */
  medicationOrder: Array<MedicationOrder>;
  /** fetch aggregated fields from the table: "server_medicationorder" */
  medicationOrderAggregate: MedicationOrderAggregate;
  /** fetch data from the table: "server_medicationorder" using primary key columns */
  medicationOrderByPk?: Maybe<MedicationOrder>;
  /** fetch data from the table in a streaming manner : "server_medicationorder" */
  medicationOrderStream: Array<MedicationOrder>;
  /** fetch data from the table in a streaming manner : "server_medication" */
  medicationStream: Array<Medication>;
  /** fetch data from the table: "server_medicationorder_medications" */
  medicationorderMedications: Array<MedicationorderMedications>;
  /** fetch aggregated fields from the table: "server_medicationorder_medications" */
  medicationorderMedicationsAggregate: MedicationorderMedicationsAggregate;
  /** fetch data from the table: "server_medicationorder_medications" using primary key columns */
  medicationorderMedicationsByPk?: Maybe<MedicationorderMedications>;
  /** fetch data from the table in a streaming manner : "server_medicationorder_medications" */
  medicationorderMedicationsStream: Array<MedicationorderMedications>;
  /** fetch data from the table: "server_message" */
  message: Array<Message>;
  /** fetch aggregated fields from the table: "server_message" */
  messageAggregate: MessageAggregate;
  /** fetch data from the table: "server_message" using primary key columns */
  messageByPk?: Maybe<Message>;
  /** fetch data from the table in a streaming manner : "server_message" */
  messageStream: Array<Message>;
  /** fetch data from the table: "server_orderorderablefield" */
  orderOrderableField: Array<OrderOrderableField>;
  /** fetch aggregated fields from the table: "server_orderorderablefield" */
  orderOrderableFieldAggregate: OrderOrderableFieldAggregate;
  /** fetch data from the table: "server_orderorderablefield" using primary key columns */
  orderOrderableFieldByPk?: Maybe<OrderOrderableField>;
  /** fetch data from the table in a streaming manner : "server_orderorderablefield" */
  orderOrderableFieldStream: Array<OrderOrderableField>;
  /** fetch data from the table: "server_orderorderable" */
  orderOrderableLegacy: Array<OrderOrderableLegacy>;
  /** fetch aggregated fields from the table: "server_orderorderable" */
  orderOrderableLegacyAggregate: OrderOrderableLegacyAggregate;
  /** fetch data from the table: "server_orderorderable" using primary key columns */
  orderOrderableLegacyByPk?: Maybe<OrderOrderableLegacy>;
  /** fetch data from the table in a streaming manner : "server_orderorderable" */
  orderOrderableLegacyStream: Array<OrderOrderableLegacy>;
  /** fetch data from the table: "server_orderable" */
  orderable: Array<Orderable>;
  /** fetch aggregated fields from the table: "server_orderable" */
  orderableAggregate: OrderableAggregate;
  /** fetch data from the table: "server_orderable" using primary key columns */
  orderableByPk?: Maybe<Orderable>;
  /** fetch data from the table: "server_orderablecategory" */
  orderableCategory: Array<OrderableCategory>;
  /** fetch aggregated fields from the table: "server_orderablecategory" */
  orderableCategoryAggregate: OrderableCategoryAggregate;
  /** fetch data from the table: "server_orderablecategory" using primary key columns */
  orderableCategoryByPk?: Maybe<OrderableCategory>;
  /** fetch data from the table in a streaming manner : "server_orderablecategory" */
  orderableCategoryStream: Array<OrderableCategory>;
  /** fetch data from the table: "server_orderableorder" */
  orderableOrder: Array<OrderableOrder>;
  /** fetch aggregated fields from the table: "server_orderableorder" */
  orderableOrderAggregate: OrderableOrderAggregate;
  /** fetch data from the table: "server_orderableorder" using primary key columns */
  orderableOrderByPk?: Maybe<OrderableOrder>;
  /** fetch data from the table: "server_orderableorderqualification" */
  orderableOrderQualification: Array<OrderableOrderQualification>;
  /** fetch aggregated fields from the table: "server_orderableorderqualification" */
  orderableOrderQualificationAggregate: OrderableOrderQualificationAggregate;
  /** fetch data from the table: "server_orderableorderqualification" using primary key columns */
  orderableOrderQualificationByPk?: Maybe<OrderableOrderQualification>;
  /** fetch data from the table in a streaming manner : "server_orderableorderqualification" */
  orderableOrderQualificationStream: Array<OrderableOrderQualification>;
  /** fetch data from the table in a streaming manner : "server_orderableorder" */
  orderableOrderStream: Array<OrderableOrder>;
  /** fetch data from the table: "server_orderablequalification" */
  orderableQualification: Array<OrderableQualification>;
  /** fetch aggregated fields from the table: "server_orderablequalification" */
  orderableQualificationAggregate: OrderableQualificationAggregate;
  /** fetch data from the table: "server_orderablequalification" using primary key columns */
  orderableQualificationByPk?: Maybe<OrderableQualification>;
  /** fetch data from the table in a streaming manner : "server_orderablequalification" */
  orderableQualificationStream: Array<OrderableQualification>;
  /** fetch data from the table in a streaming manner : "server_orderable" */
  orderableStream: Array<Orderable>;
  /** fetch data from the table: "server_orderablesupplier" */
  orderableSupplier: Array<OrderableSupplier>;
  /** fetch aggregated fields from the table: "server_orderablesupplier" */
  orderableSupplierAggregate: OrderableSupplierAggregate;
  /** fetch data from the table: "server_orderablesupplier" using primary key columns */
  orderableSupplierByPk?: Maybe<OrderableSupplier>;
  /** fetch data from the table: "server_orderablesupplier_payers" */
  orderableSupplierPayerConnections: Array<OrderableSupplierPayerConnections>;
  /** fetch aggregated fields from the table: "server_orderablesupplier_payers" */
  orderableSupplierPayerConnectionsAggregate: OrderableSupplierPayerConnectionsAggregate;
  /** fetch data from the table: "server_orderablesupplier_payers" using primary key columns */
  orderableSupplierPayerConnectionsByPk?: Maybe<OrderableSupplierPayerConnections>;
  /** fetch data from the table in a streaming manner : "server_orderablesupplier_payers" */
  orderableSupplierPayerConnectionsStream: Array<OrderableSupplierPayerConnections>;
  /** fetch data from the table in a streaming manner : "server_orderablesupplier" */
  orderableSupplierStream: Array<OrderableSupplier>;
  /** fetch data from the table: "server_orderaction" */
  orderaction: Array<Orderaction>;
  /** fetch aggregated fields from the table: "server_orderaction" */
  orderactionAggregate: OrderactionAggregate;
  /** fetch data from the table: "server_orderaction" using primary key columns */
  orderactionByPk?: Maybe<Orderaction>;
  /** fetch data from the table in a streaming manner : "server_orderaction" */
  orderactionStream: Array<Orderaction>;
  /** fetch data from the table: "server_patient" */
  patient: Array<Patient>;
  /** fetch aggregated fields from the table: "server_patient" */
  patientAggregate: PatientAggregate;
  /** fetch data from the table: "server_patientalias" */
  patientAlias: Array<PatientAlias>;
  /** fetch aggregated fields from the table: "server_patientalias" */
  patientAliasAggregate: PatientAliasAggregate;
  /** fetch data from the table: "server_patientalias" using primary key columns */
  patientAliasByPk?: Maybe<PatientAlias>;
  /** fetch data from the table in a streaming manner : "server_patientalias" */
  patientAliasStream: Array<PatientAlias>;
  /** fetch data from the table: "server_patient" using primary key columns */
  patientByPk?: Maybe<Patient>;
  /** fetch data from the table: "server_patientcommunication" */
  patientCommunication: Array<PatientCommunication>;
  /** fetch aggregated fields from the table: "server_patientcommunication" */
  patientCommunicationAggregate: PatientCommunicationAggregate;
  /** fetch data from the table: "server_patientcommunication" using primary key columns */
  patientCommunicationByPk?: Maybe<PatientCommunication>;
  /** fetch data from the table in a streaming manner : "server_patientcommunication" */
  patientCommunicationStream: Array<PatientCommunication>;
  /** fetch data from the table: "server_patientcontactperson" */
  patientContactPerson: Array<PatientContactPerson>;
  /** fetch aggregated fields from the table: "server_patientcontactperson" */
  patientContactPersonAggregate: PatientContactPersonAggregate;
  /** fetch data from the table: "server_patientcontactperson" using primary key columns */
  patientContactPersonByPk?: Maybe<PatientContactPerson>;
  /** fetch data from the table in a streaming manner : "server_patientcontactperson" */
  patientContactPersonStream: Array<PatientContactPerson>;
  /** fetch data from the table: "server_patienteligibilitycheck" */
  patientEligibilityCheck: Array<PatientEligibilityCheck>;
  /** fetch aggregated fields from the table: "server_patienteligibilitycheck" */
  patientEligibilityCheckAggregate: PatientEligibilityCheckAggregate;
  /** fetch data from the table: "server_patienteligibilitycheck" using primary key columns */
  patientEligibilityCheckByPk?: Maybe<PatientEligibilityCheck>;
  /** fetch data from the table in a streaming manner : "server_patienteligibilitycheck" */
  patientEligibilityCheckStream: Array<PatientEligibilityCheck>;
  /** fetch data from the table: "server_patientintake" */
  patientIntake: Array<PatientIntake>;
  /** fetch aggregated fields from the table: "server_patientintake" */
  patientIntakeAggregate: PatientIntakeAggregate;
  /** fetch data from the table: "server_patientintake" using primary key columns */
  patientIntakeByPk?: Maybe<PatientIntake>;
  /** fetch data from the table: "server_patientintake_records_locations" */
  patientIntakeRecordsLocations: Array<PatientIntakeRecordsLocations>;
  /** fetch aggregated fields from the table: "server_patientintake_records_locations" */
  patientIntakeRecordsLocationsAggregate: PatientIntakeRecordsLocationsAggregate;
  /** fetch data from the table: "server_patientintake_records_locations" using primary key columns */
  patientIntakeRecordsLocationsByPk?: Maybe<PatientIntakeRecordsLocations>;
  /** fetch data from the table in a streaming manner : "server_patientintake_records_locations" */
  patientIntakeRecordsLocationsStream: Array<PatientIntakeRecordsLocations>;
  /** fetch data from the table in a streaming manner : "server_patientintake" */
  patientIntakeStream: Array<PatientIntake>;
  /** fetch data from the table: "server_patientphone" */
  patientPhone: Array<PatientPhone>;
  /** fetch aggregated fields from the table: "server_patientphone" */
  patientPhoneAggregate: PatientPhoneAggregate;
  /** fetch data from the table: "server_patientphone" using primary key columns */
  patientPhoneByPk?: Maybe<PatientPhone>;
  /** fetch data from the table in a streaming manner : "server_patientphone" */
  patientPhoneStream: Array<PatientPhone>;
  /** fetch data from the table: "server_patientphoto" */
  patientPhoto: Array<PatientPhoto>;
  /** fetch aggregated fields from the table: "server_patientphoto" */
  patientPhotoAggregate: PatientPhotoAggregate;
  /** fetch data from the table: "server_patientphoto" using primary key columns */
  patientPhotoByPk?: Maybe<PatientPhoto>;
  /** fetch data from the table in a streaming manner : "server_patientphoto" */
  patientPhotoStream: Array<PatientPhoto>;
  /** fetch data from the table: "server_patientstatus" */
  patientStatus: Array<PatientStatus>;
  /** fetch data from the table: "server_patientstatusaction" */
  patientStatusAction: Array<PatientStatusAction>;
  /** fetch aggregated fields from the table: "server_patientstatusaction" */
  patientStatusActionAggregate: PatientStatusActionAggregate;
  /** fetch data from the table: "server_patientstatusaction" using primary key columns */
  patientStatusActionByPk?: Maybe<PatientStatusAction>;
  /** fetch data from the table in a streaming manner : "server_patientstatusaction" */
  patientStatusActionStream: Array<PatientStatusAction>;
  /** fetch aggregated fields from the table: "server_patientstatus" */
  patientStatusAggregate: PatientStatusAggregate;
  /** fetch data from the table: "server_patientstatus" using primary key columns */
  patientStatusByPk?: Maybe<PatientStatus>;
  /** fetch data from the table in a streaming manner : "server_patientstatus" */
  patientStatusStream: Array<PatientStatus>;
  /** fetch data from the table: "server_patientstatus_subsequent_statuses" */
  patientStatusSubsequentStatuses: Array<PatientStatusSubsequentStatuses>;
  /** fetch aggregated fields from the table: "server_patientstatus_subsequent_statuses" */
  patientStatusSubsequentStatusesAggregate: PatientStatusSubsequentStatusesAggregate;
  /** fetch data from the table: "server_patientstatus_subsequent_statuses" using primary key columns */
  patientStatusSubsequentStatusesByPk?: Maybe<PatientStatusSubsequentStatuses>;
  /** fetch data from the table in a streaming manner : "server_patientstatus_subsequent_statuses" */
  patientStatusSubsequentStatusesStream: Array<PatientStatusSubsequentStatuses>;
  /** fetch data from the table in a streaming manner : "server_patient" */
  patientStream: Array<Patient>;
  /** fetch data from the table: "server_payer" */
  payer: Array<Payer>;
  /** fetch aggregated fields from the table: "server_payer" */
  payerAggregate: PayerAggregate;
  /** fetch data from the table: "server_payer" using primary key columns */
  payerByPk?: Maybe<Payer>;
  /** fetch data from the table in a streaming manner : "server_payer" */
  payerStream: Array<Payer>;
  /** fetch data from the table: "server_practicegroup" */
  practicegroup: Array<Practicegroup>;
  /** fetch aggregated fields from the table: "server_practicegroup" */
  practicegroupAggregate: PracticegroupAggregate;
  /** fetch data from the table: "server_practicegroup" using primary key columns */
  practicegroupByPk?: Maybe<Practicegroup>;
  /** fetch data from the table in a streaming manner : "server_practicegroup" */
  practicegroupStream: Array<Practicegroup>;
  /** fetch data from the table: "server_problem" */
  problem: Array<Problem>;
  /** fetch aggregated fields from the table: "server_problem" */
  problemAggregate: ProblemAggregate;
  /** fetch data from the table: "server_problem" using primary key columns */
  problemByPk?: Maybe<Problem>;
  /** fetch data from the table: "server_problemplan" */
  problemPlan: Array<ProblemPlan>;
  /** fetch aggregated fields from the table: "server_problemplan" */
  problemPlanAggregate: ProblemPlanAggregate;
  /** fetch data from the table: "server_problemplan" using primary key columns */
  problemPlanByPk?: Maybe<ProblemPlan>;
  /** fetch data from the table in a streaming manner : "server_problemplan" */
  problemPlanStream: Array<ProblemPlan>;
  /** fetch data from the table in a streaming manner : "server_problem" */
  problemStream: Array<Problem>;
  /** fetch data from the table: "server_procedure" */
  procedure: Array<Procedure>;
  /** fetch aggregated fields from the table: "server_procedure" */
  procedureAggregate: ProcedureAggregate;
  /** fetch data from the table: "server_procedure" using primary key columns */
  procedureByPk?: Maybe<Procedure>;
  /** fetch data from the table: "server_procedure_hcpcs" */
  procedureHCPCS: Array<ProcedureHcpcs>;
  /** fetch aggregated fields from the table: "server_procedure_hcpcs" */
  procedureHCPCSAggregate: ProcedureHcpcsAggregate;
  /** fetch data from the table: "server_procedure_hcpcs" using primary key columns */
  procedureHCPCSByPk?: Maybe<ProcedureHcpcs>;
  /** fetch data from the table in a streaming manner : "server_procedure_hcpcs" */
  procedureHCPCSStream: Array<ProcedureHcpcs>;
  /** fetch data from the table: "server_procedurerendered" */
  procedureRendered: Array<ProcedureRendered>;
  /** fetch aggregated fields from the table: "server_procedurerendered" */
  procedureRenderedAggregate: ProcedureRenderedAggregate;
  /** fetch data from the table: "server_procedurerendered" using primary key columns */
  procedureRenderedByPk?: Maybe<ProcedureRendered>;
  /** fetch data from the table in a streaming manner : "server_procedurerendered" */
  procedureRenderedStream: Array<ProcedureRendered>;
  /** fetch data from the table in a streaming manner : "server_procedure" */
  procedureStream: Array<Procedure>;
  /** fetch data from the table: "server_provider" */
  provider: Array<Provider>;
  /** fetch aggregated fields from the table: "server_provider" */
  providerAggregate: ProviderAggregate;
  /** fetch data from the table: "server_provider" using primary key columns */
  providerByPk?: Maybe<Provider>;
  /** fetch data from the table: "server_providerclassification" */
  providerClassification: Array<ProviderClassification>;
  /** fetch aggregated fields from the table: "server_providerclassification" */
  providerClassificationAggregate: ProviderClassificationAggregate;
  /** fetch data from the table: "server_providerclassification" using primary key columns */
  providerClassificationByPk?: Maybe<ProviderClassification>;
  /** fetch data from the table in a streaming manner : "server_providerclassification" */
  providerClassificationStream: Array<ProviderClassification>;
  /** fetch data from the table: "server_providerschedule" */
  providerSchedule: Array<ProviderSchedule>;
  /** fetch aggregated fields from the table: "server_providerschedule" */
  providerScheduleAggregate: ProviderScheduleAggregate;
  /** fetch data from the table: "server_providerschedule" using primary key columns */
  providerScheduleByPk?: Maybe<ProviderSchedule>;
  /** fetch data from the table in a streaming manner : "server_providerschedule" */
  providerScheduleStream: Array<ProviderSchedule>;
  /** fetch data from the table in a streaming manner : "server_provider" */
  providerStream: Array<Provider>;
  /** fetch data from the table: "server_recordsrequestdestination" */
  recordsRequestDestination: Array<RecordsRequestDestination>;
  /** fetch aggregated fields from the table: "server_recordsrequestdestination" */
  recordsRequestDestinationAggregate: RecordsRequestDestinationAggregate;
  /** fetch data from the table: "server_recordsrequestdestination" using primary key columns */
  recordsRequestDestinationByPk?: Maybe<RecordsRequestDestination>;
  /** fetch data from the table in a streaming manner : "server_recordsrequestdestination" */
  recordsRequestDestinationStream: Array<RecordsRequestDestination>;
  /** fetch data from the table: "server_referral" */
  referral: Array<Referral>;
  /** fetch aggregated fields from the table: "server_referral" */
  referralAggregate: ReferralAggregate;
  /** fetch data from the table: "server_referral_attachments" */
  referralAttachments: Array<ReferralAttachments>;
  /** fetch aggregated fields from the table: "server_referral_attachments" */
  referralAttachmentsAggregate: ReferralAttachmentsAggregate;
  /** fetch data from the table: "server_referral_attachments" using primary key columns */
  referralAttachmentsByPk?: Maybe<ReferralAttachments>;
  /** fetch data from the table in a streaming manner : "server_referral_attachments" */
  referralAttachmentsStream: Array<ReferralAttachments>;
  /** fetch data from the table: "server_referral" using primary key columns */
  referralByPk?: Maybe<Referral>;
  /** fetch data from the table in a streaming manner : "server_referral" */
  referralStream: Array<Referral>;
  /** fetch data from the table: "server_renderedassessment" */
  renderedAssessment: Array<RenderedAssessment>;
  /** fetch aggregated fields from the table: "server_renderedassessment" */
  renderedAssessmentAggregate: RenderedAssessmentAggregate;
  /** fetch data from the table: "server_renderedassessment" using primary key columns */
  renderedAssessmentByPk?: Maybe<RenderedAssessment>;
  /** fetch data from the table in a streaming manner : "server_renderedassessment" */
  renderedAssessmentStream: Array<RenderedAssessment>;
  /** fetch data from the table: "server_schedulingaction" */
  schedulingAction: Array<SchedulingAction>;
  /** fetch aggregated fields from the table: "server_schedulingaction" */
  schedulingActionAggregate: SchedulingActionAggregate;
  /** fetch data from the table: "server_schedulingaction" using primary key columns */
  schedulingActionByPk?: Maybe<SchedulingAction>;
  /** fetch data from the table in a streaming manner : "server_schedulingaction" */
  schedulingActionStream: Array<SchedulingAction>;
  /** fetch data from the table: "server_servicetype" */
  serviceType: Array<ServiceType>;
  /** fetch aggregated fields from the table: "server_servicetype" */
  serviceTypeAggregate: ServiceTypeAggregate;
  /** fetch data from the table: "server_servicetype" using primary key columns */
  serviceTypeByPk?: Maybe<ServiceType>;
  /** fetch data from the table in a streaming manner : "server_servicetype" */
  serviceTypeStream: Array<ServiceType>;
  /** fetch data from the table: "server_signedconsent" */
  signedConsent: Array<SignedConsent>;
  /** fetch aggregated fields from the table: "server_signedconsent" */
  signedConsentAggregate: SignedConsentAggregate;
  /** fetch data from the table: "server_signedconsent" using primary key columns */
  signedConsentByPk?: Maybe<SignedConsent>;
  /** fetch data from the table in a streaming manner : "server_signedconsent" */
  signedConsentStream: Array<SignedConsent>;
  /** fetch data from the table: "server_signoff" */
  signoff: Array<Signoff>;
  /** fetch aggregated fields from the table: "server_signoff" */
  signoffAggregate: SignoffAggregate;
  /** fetch data from the table: "server_signoff" using primary key columns */
  signoffByPk?: Maybe<Signoff>;
  /** fetch data from the table in a streaming manner : "server_signoff" */
  signoffStream: Array<Signoff>;
  /** fetch data from the table: "server_signofftype" */
  signoffType: Array<SignoffType>;
  /** fetch aggregated fields from the table: "server_signofftype" */
  signoffTypeAggregate: SignoffTypeAggregate;
  /** fetch data from the table: "server_signofftype" using primary key columns */
  signoffTypeByPk?: Maybe<SignoffType>;
  /** fetch data from the table in a streaming manner : "server_signofftype" */
  signoffTypeStream: Array<SignoffType>;
  /** fetch data from the table: "server_telemedappointmentrequest" */
  telemedAppointmentRequest: Array<TelemedAppointmentRequest>;
  /** fetch data from the table: "server_telemedappointmentrequestaccesscode" */
  telemedAppointmentRequestAccessCode: Array<TelemedAppointmentRequestAccessCode>;
  /** fetch aggregated fields from the table: "server_telemedappointmentrequestaccesscode" */
  telemedAppointmentRequestAccessCodeAggregate: TelemedAppointmentRequestAccessCodeAggregate;
  /** fetch data from the table: "server_telemedappointmentrequestaccesscode" using primary key columns */
  telemedAppointmentRequestAccessCodeByPk?: Maybe<TelemedAppointmentRequestAccessCode>;
  /** fetch data from the table in a streaming manner : "server_telemedappointmentrequestaccesscode" */
  telemedAppointmentRequestAccessCodeStream: Array<TelemedAppointmentRequestAccessCode>;
  /** fetch aggregated fields from the table: "server_telemedappointmentrequest" */
  telemedAppointmentRequestAggregate: TelemedAppointmentRequestAggregate;
  /** fetch data from the table: "server_telemedappointmentrequest" using primary key columns */
  telemedAppointmentRequestByPk?: Maybe<TelemedAppointmentRequest>;
  /** fetch data from the table in a streaming manner : "server_telemedappointmentrequest" */
  telemedAppointmentRequestStream: Array<TelemedAppointmentRequest>;
  /** fetch data from the table: "server_threadcategory" */
  threadCategory: Array<ThreadCategory>;
  /** fetch aggregated fields from the table: "server_threadcategory" */
  threadCategoryAggregate: ThreadCategoryAggregate;
  /** fetch data from the table: "server_threadcategory" using primary key columns */
  threadCategoryByPk?: Maybe<ThreadCategory>;
  /** fetch data from the table: "server_threadcategorystatus" */
  threadCategoryStatus: Array<ThreadCategoryStatus>;
  /** fetch aggregated fields from the table: "server_threadcategorystatus" */
  threadCategoryStatusAggregate: ThreadCategoryStatusAggregate;
  /** fetch data from the table: "server_threadcategorystatus" using primary key columns */
  threadCategoryStatusByPk?: Maybe<ThreadCategoryStatus>;
  /** fetch data from the table in a streaming manner : "server_threadcategorystatus" */
  threadCategoryStatusStream: Array<ThreadCategoryStatus>;
  /** fetch data from the table in a streaming manner : "server_threadcategory" */
  threadCategoryStream: Array<ThreadCategory>;
  /** fetch data from the table: "server_threadfield" */
  threadField: Array<ThreadField>;
  /** fetch aggregated fields from the table: "server_threadfield" */
  threadFieldAggregate: ThreadFieldAggregate;
  /** fetch data from the table: "server_threadfield" using primary key columns */
  threadFieldByPk?: Maybe<ThreadField>;
  /** fetch data from the table in a streaming manner : "server_threadfield" */
  threadFieldStream: Array<ThreadField>;
  /** fetch data from the table: "server_threadfieldvalue" */
  threadFieldValue: Array<ThreadFieldValue>;
  /** fetch aggregated fields from the table: "server_threadfieldvalue" */
  threadFieldValueAggregate: ThreadFieldValueAggregate;
  /** fetch data from the table: "server_threadfieldvalue" using primary key columns */
  threadFieldValueByPk?: Maybe<ThreadFieldValue>;
  /** fetch data from the table in a streaming manner : "server_threadfieldvalue" */
  threadFieldValueStream: Array<ThreadFieldValue>;
  /** fetch data from the table: "server_ticketsubscription" */
  ticketSubscription: Array<TicketSubscription>;
  /** fetch aggregated fields from the table: "server_ticketsubscription" */
  ticketSubscriptionAggregate: TicketSubscriptionAggregate;
  /** fetch data from the table: "server_ticketsubscription" using primary key columns */
  ticketSubscriptionByPk?: Maybe<TicketSubscription>;
  /** fetch data from the table in a streaming manner : "server_ticketsubscription" */
  ticketSubscriptionStream: Array<TicketSubscription>;
  /** fetch data from the table: "server_ticketthread" */
  ticketThread: Array<TicketThread>;
  /** fetch aggregated fields from the table: "server_ticketthread" */
  ticketThreadAggregate: TicketThreadAggregate;
  /** fetch data from the table: "server_ticketthread" using primary key columns */
  ticketThreadByPk?: Maybe<TicketThread>;
  /** fetch data from the table in a streaming manner : "server_ticketthread" */
  ticketThreadStream: Array<TicketThread>;
  /** fetch data from the table: "server_treatmentplan" */
  treatmentplan: Array<Treatmentplan>;
  /** fetch aggregated fields from the table: "server_treatmentplan" */
  treatmentplanAggregate: TreatmentplanAggregate;
  /** fetch data from the table: "server_treatmentplanassessment" */
  treatmentplanAssessment: Array<TreatmentplanAssessment>;
  /** fetch aggregated fields from the table: "server_treatmentplanassessment" */
  treatmentplanAssessmentAggregate: TreatmentplanAssessmentAggregate;
  /** fetch data from the table: "server_treatmentplanassessment" using primary key columns */
  treatmentplanAssessmentByPk?: Maybe<TreatmentplanAssessment>;
  /** fetch data from the table in a streaming manner : "server_treatmentplanassessment" */
  treatmentplanAssessmentStream: Array<TreatmentplanAssessment>;
  /** fetch data from the table: "server_treatmentplan" using primary key columns */
  treatmentplanByPk?: Maybe<Treatmentplan>;
  /** fetch data from the table: "server_treatmentplannote" */
  treatmentplanNote: Array<TreatmentplanNote>;
  /** fetch aggregated fields from the table: "server_treatmentplannote" */
  treatmentplanNoteAggregate: TreatmentplanNoteAggregate;
  /** fetch data from the table: "server_treatmentplannote" using primary key columns */
  treatmentplanNoteByPk?: Maybe<TreatmentplanNote>;
  /** fetch data from the table in a streaming manner : "server_treatmentplannote" */
  treatmentplanNoteStream: Array<TreatmentplanNote>;
  /** fetch data from the table: "server_treatmentplan_problems" */
  treatmentplanProblems: Array<TreatmentplanProblems>;
  /** fetch aggregated fields from the table: "server_treatmentplan_problems" */
  treatmentplanProblemsAggregate: TreatmentplanProblemsAggregate;
  /** fetch data from the table: "server_treatmentplan_problems" using primary key columns */
  treatmentplanProblemsByPk?: Maybe<TreatmentplanProblems>;
  /** fetch data from the table in a streaming manner : "server_treatmentplan_problems" */
  treatmentplanProblemsStream: Array<TreatmentplanProblems>;
  /** fetch data from the table in a streaming manner : "server_treatmentplan" */
  treatmentplanStream: Array<Treatmentplan>;
  /** fetch data from the table: "server_treatmentplantemplate" */
  treatmentplanTemplate: Array<TreatmentplanTemplate>;
  /** fetch aggregated fields from the table: "server_treatmentplantemplate" */
  treatmentplanTemplateAggregate: TreatmentplanTemplateAggregate;
  /** fetch data from the table: "server_treatmentplantemplate_assessments" */
  treatmentplanTemplateAssessment: Array<TreatmentplanTemplateAssessment>;
  /** fetch aggregated fields from the table: "server_treatmentplantemplate_assessments" */
  treatmentplanTemplateAssessmentAggregate: TreatmentplanTemplateAssessmentAggregate;
  /** fetch data from the table: "server_treatmentplantemplate_assessments" using primary key columns */
  treatmentplanTemplateAssessmentByPk?: Maybe<TreatmentplanTemplateAssessment>;
  /** fetch data from the table in a streaming manner : "server_treatmentplantemplate_assessments" */
  treatmentplanTemplateAssessmentStream: Array<TreatmentplanTemplateAssessment>;
  /** fetch data from the table: "server_treatmentplantemplate" using primary key columns */
  treatmentplanTemplateByPk?: Maybe<TreatmentplanTemplate>;
  /** fetch data from the table in a streaming manner : "server_treatmentplantemplate" */
  treatmentplanTemplateStream: Array<TreatmentplanTemplate>;
  /** fetch data from the table: "server_customuser" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "server_customuser" */
  userAggregate: UserAggregate;
  /** fetch data from the table: "server_customuser" using primary key columns */
  userByPk?: Maybe<User>;
  /** fetch data from the table: "server_customuser_groups" */
  userGroups: Array<UserGroups>;
  /** fetch aggregated fields from the table: "server_customuser_groups" */
  userGroupsAggregate: UserGroupsAggregate;
  /** fetch data from the table: "server_customuser_groups" using primary key columns */
  userGroupsByPk?: Maybe<UserGroups>;
  /** fetch data from the table in a streaming manner : "server_customuser_groups" */
  userGroupsStream: Array<UserGroups>;
  /** fetch data from the table: "server_customuser_user_permissions" */
  userPermissions: Array<UserPermissions>;
  /** fetch aggregated fields from the table: "server_customuser_user_permissions" */
  userPermissionsAggregate: UserPermissionsAggregate;
  /** fetch data from the table: "server_customuser_user_permissions" using primary key columns */
  userPermissionsByPk?: Maybe<UserPermissions>;
  /** fetch data from the table in a streaming manner : "server_customuser_user_permissions" */
  userPermissionsStream: Array<UserPermissions>;
  /** fetch data from the table in a streaming manner : "server_customuser" */
  userStream: Array<User>;
  /** fetch data from the table: "server_visitrebill" */
  visitRebill: Array<VisitRebill>;
  /** fetch aggregated fields from the table: "server_visitrebill" */
  visitRebillAggregate: VisitRebillAggregate;
  /** fetch data from the table: "server_visitrebill" using primary key columns */
  visitRebillByPk?: Maybe<VisitRebill>;
  /** fetch data from the table in a streaming manner : "server_visitrebill" */
  visitRebillStream: Array<VisitRebill>;
  /** fetch data from the table: "server_vitals" */
  vitals: Array<Vitals>;
  /** fetch aggregated fields from the table: "server_vitals" */
  vitalsAggregate: VitalsAggregate;
  /** fetch data from the table: "server_vitals" using primary key columns */
  vitalsByPk?: Maybe<Vitals>;
  /** fetch data from the table in a streaming manner : "server_vitals" */
  vitalsStream: Array<Vitals>;
};


export type Subscription_RootAddressArgs = {
  distinctOn?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type Subscription_RootAddressAggregateArgs = {
  distinctOn?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type Subscription_RootAddressByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAddressStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Address_StreamCursorInput>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type Subscription_RootAgencyArgs = {
  distinctOn?: InputMaybe<Array<AgencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyOrderBy>>;
  where?: InputMaybe<AgencyBoolExp>;
};


export type Subscription_RootAgencyAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyOrderBy>>;
  where?: InputMaybe<AgencyBoolExp>;
};


export type Subscription_RootAgencyByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAgencyLocationArgs = {
  distinctOn?: InputMaybe<Array<AgencyLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyLocationOrderBy>>;
  where?: InputMaybe<AgencyLocationBoolExp>;
};


export type Subscription_RootAgencyLocationAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyLocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyLocationOrderBy>>;
  where?: InputMaybe<AgencyLocationBoolExp>;
};


export type Subscription_RootAgencyLocationByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAgencyLocationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AgencyLocation_StreamCursorInput>>;
  where?: InputMaybe<AgencyLocationBoolExp>;
};


export type Subscription_RootAgencyPayersArgs = {
  distinctOn?: InputMaybe<Array<AgencyPayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyPayersOrderBy>>;
  where?: InputMaybe<AgencyPayersBoolExp>;
};


export type Subscription_RootAgencyPayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyPayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyPayersOrderBy>>;
  where?: InputMaybe<AgencyPayersBoolExp>;
};


export type Subscription_RootAgencyPayersByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAgencyPayersStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AgencyPayers_StreamCursorInput>>;
  where?: InputMaybe<AgencyPayersBoolExp>;
};


export type Subscription_RootAgencyServicesArgs = {
  distinctOn?: InputMaybe<Array<AgencyServicesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyServicesOrderBy>>;
  where?: InputMaybe<AgencyServicesBoolExp>;
};


export type Subscription_RootAgencyServicesAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyServicesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyServicesOrderBy>>;
  where?: InputMaybe<AgencyServicesBoolExp>;
};


export type Subscription_RootAgencyServicesByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAgencyServicesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AgencyServices_StreamCursorInput>>;
  where?: InputMaybe<AgencyServicesBoolExp>;
};


export type Subscription_RootAgencyStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Agency_StreamCursorInput>>;
  where?: InputMaybe<AgencyBoolExp>;
};


export type Subscription_RootAgencyconnectionArgs = {
  distinctOn?: InputMaybe<Array<AgencyconnectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyconnectionOrderBy>>;
  where?: InputMaybe<AgencyconnectionBoolExp>;
};


export type Subscription_RootAgencyconnectionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyconnectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyconnectionOrderBy>>;
  where?: InputMaybe<AgencyconnectionBoolExp>;
};


export type Subscription_RootAgencyconnectionByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAgencyconnectionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Agencyconnection_StreamCursorInput>>;
  where?: InputMaybe<AgencyconnectionBoolExp>;
};


export type Subscription_RootAgencyjoinrequestArgs = {
  distinctOn?: InputMaybe<Array<AgencyjoinrequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyjoinrequestOrderBy>>;
  where?: InputMaybe<AgencyjoinrequestBoolExp>;
};


export type Subscription_RootAgencyjoinrequestAggregateArgs = {
  distinctOn?: InputMaybe<Array<AgencyjoinrequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AgencyjoinrequestOrderBy>>;
  where?: InputMaybe<AgencyjoinrequestBoolExp>;
};


export type Subscription_RootAgencyjoinrequestByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAgencyjoinrequestStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Agencyjoinrequest_StreamCursorInput>>;
  where?: InputMaybe<AgencyjoinrequestBoolExp>;
};


export type Subscription_RootAllergyArgs = {
  distinctOn?: InputMaybe<Array<AllergySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AllergyOrderBy>>;
  where?: InputMaybe<AllergyBoolExp>;
};


export type Subscription_RootAllergyAggregateArgs = {
  distinctOn?: InputMaybe<Array<AllergySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AllergyOrderBy>>;
  where?: InputMaybe<AllergyBoolExp>;
};


export type Subscription_RootAllergyByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAllergyStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Allergy_StreamCursorInput>>;
  where?: InputMaybe<AllergyBoolExp>;
};


export type Subscription_RootAppointmentArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


export type Subscription_RootAppointmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<AppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AppointmentOrderBy>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


export type Subscription_RootAppointmentByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAppointmentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Appointment_StreamCursorInput>>;
  where?: InputMaybe<AppointmentBoolExp>;
};


export type Subscription_RootAssessmentArgs = {
  distinctOn?: InputMaybe<Array<AssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentOrderBy>>;
  where?: InputMaybe<AssessmentBoolExp>;
};


export type Subscription_RootAssessmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentOrderBy>>;
  where?: InputMaybe<AssessmentBoolExp>;
};


export type Subscription_RootAssessmentByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAssessmentItemArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemOrderBy>>;
  where?: InputMaybe<AssessmentItemBoolExp>;
};


export type Subscription_RootAssessmentItemAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemOrderBy>>;
  where?: InputMaybe<AssessmentItemBoolExp>;
};


export type Subscription_RootAssessmentItemByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAssessmentItemStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AssessmentItem_StreamCursorInput>>;
  where?: InputMaybe<AssessmentItemBoolExp>;
};


export type Subscription_RootAssessmentItemValueArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemValueOrderBy>>;
  where?: InputMaybe<AssessmentItemValueBoolExp>;
};


export type Subscription_RootAssessmentItemValueAggregateArgs = {
  distinctOn?: InputMaybe<Array<AssessmentItemValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AssessmentItemValueOrderBy>>;
  where?: InputMaybe<AssessmentItemValueBoolExp>;
};


export type Subscription_RootAssessmentItemValueByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAssessmentItemValueStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AssessmentItemValue_StreamCursorInput>>;
  where?: InputMaybe<AssessmentItemValueBoolExp>;
};


export type Subscription_RootAssessmentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Assessment_StreamCursorInput>>;
  where?: InputMaybe<AssessmentBoolExp>;
};


export type Subscription_RootBaseProtocolOrderArgs = {
  distinctOn?: InputMaybe<Array<BaseProtocolOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseProtocolOrderOrderBy>>;
  where?: InputMaybe<BaseProtocolOrderBoolExp>;
};


export type Subscription_RootBaseProtocolOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<BaseProtocolOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseProtocolOrderOrderBy>>;
  where?: InputMaybe<BaseProtocolOrderBoolExp>;
};


export type Subscription_RootBaseProtocolOrderByPkArgs = {
  dependantorderPtrId: Scalars['Int'];
};


export type Subscription_RootBaseProtocolOrderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<BaseProtocolOrder_StreamCursorInput>>;
  where?: InputMaybe<BaseProtocolOrderBoolExp>;
};


export type Subscription_RootBaseorderArgs = {
  distinctOn?: InputMaybe<Array<BaseorderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseorderOrderBy>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


export type Subscription_RootBaseorderAggregateArgs = {
  distinctOn?: InputMaybe<Array<BaseorderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseorderOrderBy>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


export type Subscription_RootBaseorderByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBaseorderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Baseorder_StreamCursorInput>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


export type Subscription_RootCaretimeArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


export type Subscription_RootCaretimeAggregateArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


export type Subscription_RootCaretimeByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCaretimeProblemsArgs = {
  distinctOn?: InputMaybe<Array<CaretimeProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeProblemsOrderBy>>;
  where?: InputMaybe<CaretimeProblemsBoolExp>;
};


export type Subscription_RootCaretimeProblemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CaretimeProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeProblemsOrderBy>>;
  where?: InputMaybe<CaretimeProblemsBoolExp>;
};


export type Subscription_RootCaretimeProblemsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCaretimeProblemsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CaretimeProblems_StreamCursorInput>>;
  where?: InputMaybe<CaretimeProblemsBoolExp>;
};


export type Subscription_RootCaretimeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Caretime_StreamCursorInput>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


export type Subscription_RootClaimArgs = {
  distinctOn?: InputMaybe<Array<ClaimSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClaimOrderBy>>;
  where?: InputMaybe<ClaimBoolExp>;
};


export type Subscription_RootClaimAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClaimSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClaimOrderBy>>;
  where?: InputMaybe<ClaimBoolExp>;
};


export type Subscription_RootClaimByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClaimStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Claim_StreamCursorInput>>;
  where?: InputMaybe<ClaimBoolExp>;
};


export type Subscription_RootClinicalNoteFutureReferralsArgs = {
  distinctOn?: InputMaybe<Array<ClinicalNoteFutureReferralsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalNoteFutureReferralsOrderBy>>;
  where?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
};


export type Subscription_RootClinicalNoteFutureReferralsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalNoteFutureReferralsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalNoteFutureReferralsOrderBy>>;
  where?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
};


export type Subscription_RootClinicalNoteFutureReferralsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClinicalNoteFutureReferralsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClinicalNoteFutureReferrals_StreamCursorInput>>;
  where?: InputMaybe<ClinicalNoteFutureReferralsBoolExp>;
};


export type Subscription_RootClinicalnoteArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteOrderBy>>;
  where?: InputMaybe<ClinicalnoteBoolExp>;
};


export type Subscription_RootClinicalnoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteOrderBy>>;
  where?: InputMaybe<ClinicalnoteBoolExp>;
};


export type Subscription_RootClinicalnoteByPkArgs = {
  appointmentId: Scalars['Int'];
};


export type Subscription_RootClinicalnoteFieldArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldBoolExp>;
};


export type Subscription_RootClinicalnoteFieldAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldBoolExp>;
};


export type Subscription_RootClinicalnoteFieldByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClinicalnoteFieldStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClinicalnoteField_StreamCursorInput>>;
  where?: InputMaybe<ClinicalnoteFieldBoolExp>;
};


export type Subscription_RootClinicalnoteFieldValueArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldValueOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
};


export type Subscription_RootClinicalnoteFieldValueAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteFieldValueOrderBy>>;
  where?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
};


export type Subscription_RootClinicalnoteFieldValueByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClinicalnoteFieldValueStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClinicalnoteFieldValue_StreamCursorInput>>;
  where?: InputMaybe<ClinicalnoteFieldValueBoolExp>;
};


export type Subscription_RootClinicalnoteStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Clinicalnote_StreamCursorInput>>;
  where?: InputMaybe<ClinicalnoteBoolExp>;
};


export type Subscription_RootClinicalnoteTemplateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateBoolExp>;
};


export type Subscription_RootClinicalnoteTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateBoolExp>;
};


export type Subscription_RootClinicalnoteTemplateByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClinicalnoteTemplateProviderClassificationsArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
};


export type Subscription_RootClinicalnoteTemplateProviderClassificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClinicalnoteTemplateProviderClassificationsOrderBy>>;
  where?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
};


export type Subscription_RootClinicalnoteTemplateProviderClassificationsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClinicalnoteTemplateProviderClassificationsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClinicalnoteTemplateProviderClassifications_StreamCursorInput>>;
  where?: InputMaybe<ClinicalnoteTemplateProviderClassificationsBoolExp>;
};


export type Subscription_RootClinicalnoteTemplateStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClinicalnoteTemplate_StreamCursorInput>>;
  where?: InputMaybe<ClinicalnoteTemplateBoolExp>;
};


export type Subscription_RootCodesetArgs = {
  distinctOn?: InputMaybe<Array<CodesetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetOrderBy>>;
  where?: InputMaybe<CodesetBoolExp>;
};


export type Subscription_RootCodesetAddonArgs = {
  distinctOn?: InputMaybe<Array<CodesetAddonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetAddonOrderBy>>;
  where?: InputMaybe<CodesetAddonBoolExp>;
};


export type Subscription_RootCodesetAddonAggregateArgs = {
  distinctOn?: InputMaybe<Array<CodesetAddonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetAddonOrderBy>>;
  where?: InputMaybe<CodesetAddonBoolExp>;
};


export type Subscription_RootCodesetAddonByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCodesetAddonStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CodesetAddon_StreamCursorInput>>;
  where?: InputMaybe<CodesetAddonBoolExp>;
};


export type Subscription_RootCodesetAggregateArgs = {
  distinctOn?: InputMaybe<Array<CodesetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetOrderBy>>;
  where?: InputMaybe<CodesetBoolExp>;
};


export type Subscription_RootCodesetBaseArgs = {
  distinctOn?: InputMaybe<Array<CodesetBaseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetBaseOrderBy>>;
  where?: InputMaybe<CodesetBaseBoolExp>;
};


export type Subscription_RootCodesetBaseAggregateArgs = {
  distinctOn?: InputMaybe<Array<CodesetBaseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CodesetBaseOrderBy>>;
  where?: InputMaybe<CodesetBaseBoolExp>;
};


export type Subscription_RootCodesetBaseByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCodesetBaseStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CodesetBase_StreamCursorInput>>;
  where?: InputMaybe<CodesetBaseBoolExp>;
};


export type Subscription_RootCodesetByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCodesetStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Codeset_StreamCursorInput>>;
  where?: InputMaybe<CodesetBoolExp>;
};


export type Subscription_RootConsentArgs = {
  distinctOn?: InputMaybe<Array<ConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConsentOrderBy>>;
  where?: InputMaybe<ConsentBoolExp>;
};


export type Subscription_RootConsentAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConsentOrderBy>>;
  where?: InputMaybe<ConsentBoolExp>;
};


export type Subscription_RootConsentByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootConsentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Consent_StreamCursorInput>>;
  where?: InputMaybe<ConsentBoolExp>;
};


export type Subscription_RootCpoClaimArgs = {
  distinctOn?: InputMaybe<Array<CpoClaimSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CpoClaimOrderBy>>;
  where?: InputMaybe<CpoClaimBoolExp>;
};


export type Subscription_RootCpoClaimAggregateArgs = {
  distinctOn?: InputMaybe<Array<CpoClaimSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CpoClaimOrderBy>>;
  where?: InputMaybe<CpoClaimBoolExp>;
};


export type Subscription_RootCpoClaimByPkArgs = {
  claimPtrId: Scalars['Int'];
};


export type Subscription_RootCpoClaimStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CpoClaim_StreamCursorInput>>;
  where?: InputMaybe<CpoClaimBoolExp>;
};


export type Subscription_RootDependantOrderArgs = {
  distinctOn?: InputMaybe<Array<DependantOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DependantOrderOrderBy>>;
  where?: InputMaybe<DependantOrderBoolExp>;
};


export type Subscription_RootDependantOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<DependantOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DependantOrderOrderBy>>;
  where?: InputMaybe<DependantOrderBoolExp>;
};


export type Subscription_RootDependantOrderByPkArgs = {
  baseorderPtrId: Scalars['Int'];
};


export type Subscription_RootDependantOrderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DependantOrder_StreamCursorInput>>;
  where?: InputMaybe<DependantOrderBoolExp>;
};


export type Subscription_RootDiagnosisAbbreviationArgs = {
  distinctOn?: InputMaybe<Array<DiagnosisAbbreviationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DiagnosisAbbreviationOrderBy>>;
  where?: InputMaybe<DiagnosisAbbreviationBoolExp>;
};


export type Subscription_RootDiagnosisAbbreviationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiagnosisAbbreviationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DiagnosisAbbreviationOrderBy>>;
  where?: InputMaybe<DiagnosisAbbreviationBoolExp>;
};


export type Subscription_RootDiagnosisAbbreviationByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDiagnosisAbbreviationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DiagnosisAbbreviation_StreamCursorInput>>;
  where?: InputMaybe<DiagnosisAbbreviationBoolExp>;
};


export type Subscription_RootDocumentArgs = {
  distinctOn?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


export type Subscription_RootDocumentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


export type Subscription_RootDocumentByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDocumentPendingReviewArgs = {
  distinctOn?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentPendingReviewOrderBy>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};


export type Subscription_RootDocumentPendingReviewAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentPendingReviewOrderBy>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};


export type Subscription_RootDocumentPendingReviewByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDocumentPendingReviewStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DocumentPendingReview_StreamCursorInput>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};


export type Subscription_RootDocumentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Document_StreamCursorInput>>;
  where?: InputMaybe<DocumentBoolExp>;
};


export type Subscription_RootExternalSignedOrderArgs = {
  distinctOn?: InputMaybe<Array<ExternalSignedOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalSignedOrderOrderBy>>;
  where?: InputMaybe<ExternalSignedOrderBoolExp>;
};


export type Subscription_RootExternalSignedOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExternalSignedOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalSignedOrderOrderBy>>;
  where?: InputMaybe<ExternalSignedOrderBoolExp>;
};


export type Subscription_RootExternalSignedOrderByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootExternalSignedOrderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalSignedOrder_StreamCursorInput>>;
  where?: InputMaybe<ExternalSignedOrderBoolExp>;
};


export type Subscription_RootExternalStaffMyPatientsArgs = {
  distinctOn?: InputMaybe<Array<ExternalStaffMyPatientsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalStaffMyPatientsOrderBy>>;
  where?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
};


export type Subscription_RootExternalStaffMyPatientsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExternalStaffMyPatientsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalStaffMyPatientsOrderBy>>;
  where?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
};


export type Subscription_RootExternalStaffMyPatientsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootExternalStaffMyPatientsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalStaffMyPatients_StreamCursorInput>>;
  where?: InputMaybe<ExternalStaffMyPatientsBoolExp>;
};


export type Subscription_RootExternalstaffArgs = {
  distinctOn?: InputMaybe<Array<ExternalstaffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalstaffOrderBy>>;
  where?: InputMaybe<ExternalstaffBoolExp>;
};


export type Subscription_RootExternalstaffAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExternalstaffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalstaffOrderBy>>;
  where?: InputMaybe<ExternalstaffBoolExp>;
};


export type Subscription_RootExternalstaffByPkArgs = {
  userId: Scalars['Int'];
};


export type Subscription_RootExternalstaffStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Externalstaff_StreamCursorInput>>;
  where?: InputMaybe<ExternalstaffBoolExp>;
};


export type Subscription_RootFaxArgs = {
  distinctOn?: InputMaybe<Array<FaxSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxOrderBy>>;
  where?: InputMaybe<FaxBoolExp>;
};


export type Subscription_RootFaxAggregateArgs = {
  distinctOn?: InputMaybe<Array<FaxSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxOrderBy>>;
  where?: InputMaybe<FaxBoolExp>;
};


export type Subscription_RootFaxByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFaxDocumentsArgs = {
  distinctOn?: InputMaybe<Array<FaxDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxDocumentsOrderBy>>;
  where?: InputMaybe<FaxDocumentsBoolExp>;
};


export type Subscription_RootFaxDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FaxDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FaxDocumentsOrderBy>>;
  where?: InputMaybe<FaxDocumentsBoolExp>;
};


export type Subscription_RootFaxDocumentsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFaxDocumentsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<FaxDocuments_StreamCursorInput>>;
  where?: InputMaybe<FaxDocumentsBoolExp>;
};


export type Subscription_RootFaxStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Fax_StreamCursorInput>>;
  where?: InputMaybe<FaxBoolExp>;
};


export type Subscription_RootFreeTextOrderArgs = {
  distinctOn?: InputMaybe<Array<FreeTextOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FreeTextOrderOrderBy>>;
  where?: InputMaybe<FreeTextOrderBoolExp>;
};


export type Subscription_RootFreeTextOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<FreeTextOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FreeTextOrderOrderBy>>;
  where?: InputMaybe<FreeTextOrderBoolExp>;
};


export type Subscription_RootFreeTextOrderByPkArgs = {
  baseorderPtrId: Scalars['Int'];
};


export type Subscription_RootFreeTextOrderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<FreeTextOrder_StreamCursorInput>>;
  where?: InputMaybe<FreeTextOrderBoolExp>;
};


export type Subscription_RootFrequencyOrderArgs = {
  distinctOn?: InputMaybe<Array<FrequencyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FrequencyOrderOrderBy>>;
  where?: InputMaybe<FrequencyOrderBoolExp>;
};


export type Subscription_RootFrequencyOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<FrequencyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FrequencyOrderOrderBy>>;
  where?: InputMaybe<FrequencyOrderBoolExp>;
};


export type Subscription_RootFrequencyOrderByPkArgs = {
  dependantorderPtrId: Scalars['Int'];
};


export type Subscription_RootFrequencyOrderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<FrequencyOrder_StreamCursorInput>>;
  where?: InputMaybe<FrequencyOrderBoolExp>;
};


export type Subscription_RootFrequencyProtocolArgs = {
  distinctOn?: InputMaybe<Array<FrequencyProtocolSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FrequencyProtocolOrderBy>>;
  where?: InputMaybe<FrequencyProtocolBoolExp>;
};


export type Subscription_RootFrequencyProtocolAggregateArgs = {
  distinctOn?: InputMaybe<Array<FrequencyProtocolSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FrequencyProtocolOrderBy>>;
  where?: InputMaybe<FrequencyProtocolBoolExp>;
};


export type Subscription_RootFrequencyProtocolByPkArgs = {
  baseprotocolorderPtrId: Scalars['Int'];
};


export type Subscription_RootFrequencyProtocolStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<FrequencyProtocol_StreamCursorInput>>;
  where?: InputMaybe<FrequencyProtocolBoolExp>;
};


export type Subscription_RootHcpcsArgs = {
  distinctOn?: InputMaybe<Array<HcpcsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<HcpcsOrderBy>>;
  where?: InputMaybe<HcpcsBoolExp>;
};


export type Subscription_RootHcpcsAggregateArgs = {
  distinctOn?: InputMaybe<Array<HcpcsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<HcpcsOrderBy>>;
  where?: InputMaybe<HcpcsBoolExp>;
};


export type Subscription_RootHcpcsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootHcpcsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Hcpcs_StreamCursorInput>>;
  where?: InputMaybe<HcpcsBoolExp>;
};


export type Subscription_RootInsuranceplanArgs = {
  distinctOn?: InputMaybe<Array<InsuranceplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceplanOrderBy>>;
  where?: InputMaybe<InsuranceplanBoolExp>;
};


export type Subscription_RootInsuranceplanAggregateArgs = {
  distinctOn?: InputMaybe<Array<InsuranceplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsuranceplanOrderBy>>;
  where?: InputMaybe<InsuranceplanBoolExp>;
};


export type Subscription_RootInsuranceplanByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInsuranceplanStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Insuranceplan_StreamCursorInput>>;
  where?: InputMaybe<InsuranceplanBoolExp>;
};


export type Subscription_RootIntegrationArgs = {
  distinctOn?: InputMaybe<Array<IntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationOrderBy>>;
  where?: InputMaybe<IntegrationBoolExp>;
};


export type Subscription_RootIntegrationAggregateArgs = {
  distinctOn?: InputMaybe<Array<IntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationOrderBy>>;
  where?: InputMaybe<IntegrationBoolExp>;
};


export type Subscription_RootIntegrationByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootIntegrationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Integration_StreamCursorInput>>;
  where?: InputMaybe<IntegrationBoolExp>;
};


export type Subscription_RootInternalstaffArgs = {
  distinctOn?: InputMaybe<Array<InternalstaffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InternalstaffOrderBy>>;
  where?: InputMaybe<InternalstaffBoolExp>;
};


export type Subscription_RootInternalstaffAggregateArgs = {
  distinctOn?: InputMaybe<Array<InternalstaffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InternalstaffOrderBy>>;
  where?: InputMaybe<InternalstaffBoolExp>;
};


export type Subscription_RootInternalstaffByPkArgs = {
  userId: Scalars['Int'];
};


export type Subscription_RootInternalstaffStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Internalstaff_StreamCursorInput>>;
  where?: InputMaybe<InternalstaffBoolExp>;
};


export type Subscription_RootLegacyOrderArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


export type Subscription_RootLegacyOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


export type Subscription_RootLegacyOrderByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLegacyOrderFrequencyArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderFrequencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderFrequencyOrderBy>>;
  where?: InputMaybe<LegacyOrderFrequencyBoolExp>;
};


export type Subscription_RootLegacyOrderFrequencyAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderFrequencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderFrequencyOrderBy>>;
  where?: InputMaybe<LegacyOrderFrequencyBoolExp>;
};


export type Subscription_RootLegacyOrderFrequencyByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLegacyOrderFrequencyStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LegacyOrderFrequency_StreamCursorInput>>;
  where?: InputMaybe<LegacyOrderFrequencyBoolExp>;
};


export type Subscription_RootLegacyOrderMedicationsArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderMedicationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderMedicationsOrderBy>>;
  where?: InputMaybe<LegacyOrderMedicationsBoolExp>;
};


export type Subscription_RootLegacyOrderMedicationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderMedicationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderMedicationsOrderBy>>;
  where?: InputMaybe<LegacyOrderMedicationsBoolExp>;
};


export type Subscription_RootLegacyOrderMedicationsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLegacyOrderMedicationsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LegacyOrderMedications_StreamCursorInput>>;
  where?: InputMaybe<LegacyOrderMedicationsBoolExp>;
};


export type Subscription_RootLegacyOrderOrderableFieldValueArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderOrderableFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderableFieldValueOrderBy>>;
  where?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
};


export type Subscription_RootLegacyOrderOrderableFieldValueAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderOrderableFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderableFieldValueOrderBy>>;
  where?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
};


export type Subscription_RootLegacyOrderOrderableFieldValueByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLegacyOrderOrderableFieldValueStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LegacyOrderOrderableFieldValue_StreamCursorInput>>;
  where?: InputMaybe<LegacyOrderOrderableFieldValueBoolExp>;
};


export type Subscription_RootLegacyOrderQualificationArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderQualificationOrderBy>>;
  where?: InputMaybe<LegacyOrderQualificationBoolExp>;
};


export type Subscription_RootLegacyOrderQualificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderQualificationOrderBy>>;
  where?: InputMaybe<LegacyOrderQualificationBoolExp>;
};


export type Subscription_RootLegacyOrderQualificationByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLegacyOrderQualificationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LegacyOrderQualification_StreamCursorInput>>;
  where?: InputMaybe<LegacyOrderQualificationBoolExp>;
};


export type Subscription_RootLegacyOrderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LegacyOrder_StreamCursorInput>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


export type Subscription_RootLegacyTreatmentplanOrderArgs = {
  distinctOn?: InputMaybe<Array<LegacyTreatmentplanOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyTreatmentplanOrderOrderBy>>;
  where?: InputMaybe<LegacyTreatmentplanOrderBoolExp>;
};


export type Subscription_RootLegacyTreatmentplanOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyTreatmentplanOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyTreatmentplanOrderOrderBy>>;
  where?: InputMaybe<LegacyTreatmentplanOrderBoolExp>;
};


export type Subscription_RootLegacyTreatmentplanOrderByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLegacyTreatmentplanOrderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LegacyTreatmentplanOrder_StreamCursorInput>>;
  where?: InputMaybe<LegacyTreatmentplanOrderBoolExp>;
};


export type Subscription_RootLineitemArgs = {
  distinctOn?: InputMaybe<Array<LineitemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LineitemOrderBy>>;
  where?: InputMaybe<LineitemBoolExp>;
};


export type Subscription_RootLineitemAggregateArgs = {
  distinctOn?: InputMaybe<Array<LineitemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LineitemOrderBy>>;
  where?: InputMaybe<LineitemBoolExp>;
};


export type Subscription_RootLineitemByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLineitemStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Lineitem_StreamCursorInput>>;
  where?: InputMaybe<LineitemBoolExp>;
};


export type Subscription_RootMedicationArgs = {
  distinctOn?: InputMaybe<Array<MedicationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationOrderBy>>;
  where?: InputMaybe<MedicationBoolExp>;
};


export type Subscription_RootMedicationAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationOrderBy>>;
  where?: InputMaybe<MedicationBoolExp>;
};


export type Subscription_RootMedicationByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMedicationOrderArgs = {
  distinctOn?: InputMaybe<Array<MedicationOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationOrderOrderBy>>;
  where?: InputMaybe<MedicationOrderBoolExp>;
};


export type Subscription_RootMedicationOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicationOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationOrderOrderBy>>;
  where?: InputMaybe<MedicationOrderBoolExp>;
};


export type Subscription_RootMedicationOrderByPkArgs = {
  baseorderPtrId: Scalars['Int'];
};


export type Subscription_RootMedicationOrderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<MedicationOrder_StreamCursorInput>>;
  where?: InputMaybe<MedicationOrderBoolExp>;
};


export type Subscription_RootMedicationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Medication_StreamCursorInput>>;
  where?: InputMaybe<MedicationBoolExp>;
};


export type Subscription_RootMedicationorderMedicationsArgs = {
  distinctOn?: InputMaybe<Array<MedicationorderMedicationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationorderMedicationsOrderBy>>;
  where?: InputMaybe<MedicationorderMedicationsBoolExp>;
};


export type Subscription_RootMedicationorderMedicationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicationorderMedicationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedicationorderMedicationsOrderBy>>;
  where?: InputMaybe<MedicationorderMedicationsBoolExp>;
};


export type Subscription_RootMedicationorderMedicationsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMedicationorderMedicationsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<MedicationorderMedications_StreamCursorInput>>;
  where?: InputMaybe<MedicationorderMedicationsBoolExp>;
};


export type Subscription_RootMessageArgs = {
  distinctOn?: InputMaybe<Array<MessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderBy>>;
  where?: InputMaybe<MessageBoolExp>;
};


export type Subscription_RootMessageAggregateArgs = {
  distinctOn?: InputMaybe<Array<MessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderBy>>;
  where?: InputMaybe<MessageBoolExp>;
};


export type Subscription_RootMessageByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMessageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Message_StreamCursorInput>>;
  where?: InputMaybe<MessageBoolExp>;
};


export type Subscription_RootOrderOrderableFieldArgs = {
  distinctOn?: InputMaybe<Array<OrderOrderableFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderableFieldOrderBy>>;
  where?: InputMaybe<OrderOrderableFieldBoolExp>;
};


export type Subscription_RootOrderOrderableFieldAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderOrderableFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderableFieldOrderBy>>;
  where?: InputMaybe<OrderOrderableFieldBoolExp>;
};


export type Subscription_RootOrderOrderableFieldByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrderOrderableFieldStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrderOrderableField_StreamCursorInput>>;
  where?: InputMaybe<OrderOrderableFieldBoolExp>;
};


export type Subscription_RootOrderOrderableLegacyArgs = {
  distinctOn?: InputMaybe<Array<OrderOrderableLegacySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderableLegacyOrderBy>>;
  where?: InputMaybe<OrderOrderableLegacyBoolExp>;
};


export type Subscription_RootOrderOrderableLegacyAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderOrderableLegacySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderableLegacyOrderBy>>;
  where?: InputMaybe<OrderOrderableLegacyBoolExp>;
};


export type Subscription_RootOrderOrderableLegacyByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrderOrderableLegacyStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrderOrderableLegacy_StreamCursorInput>>;
  where?: InputMaybe<OrderOrderableLegacyBoolExp>;
};


export type Subscription_RootOrderableArgs = {
  distinctOn?: InputMaybe<Array<OrderableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderBy>>;
  where?: InputMaybe<OrderableBoolExp>;
};


export type Subscription_RootOrderableAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderBy>>;
  where?: InputMaybe<OrderableBoolExp>;
};


export type Subscription_RootOrderableByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrderableCategoryArgs = {
  distinctOn?: InputMaybe<Array<OrderableCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableCategoryOrderBy>>;
  where?: InputMaybe<OrderableCategoryBoolExp>;
};


export type Subscription_RootOrderableCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableCategoryOrderBy>>;
  where?: InputMaybe<OrderableCategoryBoolExp>;
};


export type Subscription_RootOrderableCategoryByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrderableCategoryStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrderableCategory_StreamCursorInput>>;
  where?: InputMaybe<OrderableCategoryBoolExp>;
};


export type Subscription_RootOrderableOrderArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderOrderBy>>;
  where?: InputMaybe<OrderableOrderBoolExp>;
};


export type Subscription_RootOrderableOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderOrderBy>>;
  where?: InputMaybe<OrderableOrderBoolExp>;
};


export type Subscription_RootOrderableOrderByPkArgs = {
  baseorderPtrId: Scalars['Int'];
};


export type Subscription_RootOrderableOrderQualificationArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderQualificationOrderBy>>;
  where?: InputMaybe<OrderableOrderQualificationBoolExp>;
};


export type Subscription_RootOrderableOrderQualificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableOrderQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableOrderQualificationOrderBy>>;
  where?: InputMaybe<OrderableOrderQualificationBoolExp>;
};


export type Subscription_RootOrderableOrderQualificationByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrderableOrderQualificationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrderableOrderQualification_StreamCursorInput>>;
  where?: InputMaybe<OrderableOrderQualificationBoolExp>;
};


export type Subscription_RootOrderableOrderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrderableOrder_StreamCursorInput>>;
  where?: InputMaybe<OrderableOrderBoolExp>;
};


export type Subscription_RootOrderableQualificationArgs = {
  distinctOn?: InputMaybe<Array<OrderableQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableQualificationOrderBy>>;
  where?: InputMaybe<OrderableQualificationBoolExp>;
};


export type Subscription_RootOrderableQualificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableQualificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableQualificationOrderBy>>;
  where?: InputMaybe<OrderableQualificationBoolExp>;
};


export type Subscription_RootOrderableQualificationByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrderableQualificationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrderableQualification_StreamCursorInput>>;
  where?: InputMaybe<OrderableQualificationBoolExp>;
};


export type Subscription_RootOrderableStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Orderable_StreamCursorInput>>;
  where?: InputMaybe<OrderableBoolExp>;
};


export type Subscription_RootOrderableSupplierArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierOrderBy>>;
  where?: InputMaybe<OrderableSupplierBoolExp>;
};


export type Subscription_RootOrderableSupplierAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierOrderBy>>;
  where?: InputMaybe<OrderableSupplierBoolExp>;
};


export type Subscription_RootOrderableSupplierByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrderableSupplierPayerConnectionsArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierPayerConnectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierPayerConnectionsOrderBy>>;
  where?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
};


export type Subscription_RootOrderableSupplierPayerConnectionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderableSupplierPayerConnectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderableSupplierPayerConnectionsOrderBy>>;
  where?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
};


export type Subscription_RootOrderableSupplierPayerConnectionsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrderableSupplierPayerConnectionsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrderableSupplierPayerConnections_StreamCursorInput>>;
  where?: InputMaybe<OrderableSupplierPayerConnectionsBoolExp>;
};


export type Subscription_RootOrderableSupplierStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrderableSupplier_StreamCursorInput>>;
  where?: InputMaybe<OrderableSupplierBoolExp>;
};


export type Subscription_RootOrderactionArgs = {
  distinctOn?: InputMaybe<Array<OrderactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderactionOrderBy>>;
  where?: InputMaybe<OrderactionBoolExp>;
};


export type Subscription_RootOrderactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderactionOrderBy>>;
  where?: InputMaybe<OrderactionBoolExp>;
};


export type Subscription_RootOrderactionByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrderactionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Orderaction_StreamCursorInput>>;
  where?: InputMaybe<OrderactionBoolExp>;
};


export type Subscription_RootPatientArgs = {
  distinctOn?: InputMaybe<Array<PatientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientOrderBy>>;
  where?: InputMaybe<PatientBoolExp>;
};


export type Subscription_RootPatientAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientOrderBy>>;
  where?: InputMaybe<PatientBoolExp>;
};


export type Subscription_RootPatientAliasArgs = {
  distinctOn?: InputMaybe<Array<PatientAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientAliasOrderBy>>;
  where?: InputMaybe<PatientAliasBoolExp>;
};


export type Subscription_RootPatientAliasAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientAliasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientAliasOrderBy>>;
  where?: InputMaybe<PatientAliasBoolExp>;
};


export type Subscription_RootPatientAliasByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPatientAliasStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PatientAlias_StreamCursorInput>>;
  where?: InputMaybe<PatientAliasBoolExp>;
};


export type Subscription_RootPatientByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPatientCommunicationArgs = {
  distinctOn?: InputMaybe<Array<PatientCommunicationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientCommunicationOrderBy>>;
  where?: InputMaybe<PatientCommunicationBoolExp>;
};


export type Subscription_RootPatientCommunicationAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientCommunicationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientCommunicationOrderBy>>;
  where?: InputMaybe<PatientCommunicationBoolExp>;
};


export type Subscription_RootPatientCommunicationByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPatientCommunicationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PatientCommunication_StreamCursorInput>>;
  where?: InputMaybe<PatientCommunicationBoolExp>;
};


export type Subscription_RootPatientContactPersonArgs = {
  distinctOn?: InputMaybe<Array<PatientContactPersonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientContactPersonOrderBy>>;
  where?: InputMaybe<PatientContactPersonBoolExp>;
};


export type Subscription_RootPatientContactPersonAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientContactPersonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientContactPersonOrderBy>>;
  where?: InputMaybe<PatientContactPersonBoolExp>;
};


export type Subscription_RootPatientContactPersonByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPatientContactPersonStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PatientContactPerson_StreamCursorInput>>;
  where?: InputMaybe<PatientContactPersonBoolExp>;
};


export type Subscription_RootPatientEligibilityCheckArgs = {
  distinctOn?: InputMaybe<Array<PatientEligibilityCheckSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientEligibilityCheckOrderBy>>;
  where?: InputMaybe<PatientEligibilityCheckBoolExp>;
};


export type Subscription_RootPatientEligibilityCheckAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientEligibilityCheckSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientEligibilityCheckOrderBy>>;
  where?: InputMaybe<PatientEligibilityCheckBoolExp>;
};


export type Subscription_RootPatientEligibilityCheckByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPatientEligibilityCheckStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PatientEligibilityCheck_StreamCursorInput>>;
  where?: InputMaybe<PatientEligibilityCheckBoolExp>;
};


export type Subscription_RootPatientIntakeArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeOrderBy>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


export type Subscription_RootPatientIntakeAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeOrderBy>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


export type Subscription_RootPatientIntakeByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPatientIntakeRecordsLocationsArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeRecordsLocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeRecordsLocationsOrderBy>>;
  where?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
};


export type Subscription_RootPatientIntakeRecordsLocationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeRecordsLocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeRecordsLocationsOrderBy>>;
  where?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
};


export type Subscription_RootPatientIntakeRecordsLocationsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPatientIntakeRecordsLocationsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PatientIntakeRecordsLocations_StreamCursorInput>>;
  where?: InputMaybe<PatientIntakeRecordsLocationsBoolExp>;
};


export type Subscription_RootPatientIntakeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PatientIntake_StreamCursorInput>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


export type Subscription_RootPatientPhoneArgs = {
  distinctOn?: InputMaybe<Array<PatientPhoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientPhoneOrderBy>>;
  where?: InputMaybe<PatientPhoneBoolExp>;
};


export type Subscription_RootPatientPhoneAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientPhoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientPhoneOrderBy>>;
  where?: InputMaybe<PatientPhoneBoolExp>;
};


export type Subscription_RootPatientPhoneByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPatientPhoneStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PatientPhone_StreamCursorInput>>;
  where?: InputMaybe<PatientPhoneBoolExp>;
};


export type Subscription_RootPatientPhotoArgs = {
  distinctOn?: InputMaybe<Array<PatientPhotoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientPhotoOrderBy>>;
  where?: InputMaybe<PatientPhotoBoolExp>;
};


export type Subscription_RootPatientPhotoAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientPhotoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientPhotoOrderBy>>;
  where?: InputMaybe<PatientPhotoBoolExp>;
};


export type Subscription_RootPatientPhotoByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPatientPhotoStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PatientPhoto_StreamCursorInput>>;
  where?: InputMaybe<PatientPhotoBoolExp>;
};


export type Subscription_RootPatientStatusArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusOrderBy>>;
  where?: InputMaybe<PatientStatusBoolExp>;
};


export type Subscription_RootPatientStatusActionArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusActionOrderBy>>;
  where?: InputMaybe<PatientStatusActionBoolExp>;
};


export type Subscription_RootPatientStatusActionAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusActionOrderBy>>;
  where?: InputMaybe<PatientStatusActionBoolExp>;
};


export type Subscription_RootPatientStatusActionByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPatientStatusActionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PatientStatusAction_StreamCursorInput>>;
  where?: InputMaybe<PatientStatusActionBoolExp>;
};


export type Subscription_RootPatientStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusOrderBy>>;
  where?: InputMaybe<PatientStatusBoolExp>;
};


export type Subscription_RootPatientStatusByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPatientStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PatientStatus_StreamCursorInput>>;
  where?: InputMaybe<PatientStatusBoolExp>;
};


export type Subscription_RootPatientStatusSubsequentStatusesArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusSubsequentStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusSubsequentStatusesOrderBy>>;
  where?: InputMaybe<PatientStatusSubsequentStatusesBoolExp>;
};


export type Subscription_RootPatientStatusSubsequentStatusesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusSubsequentStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusSubsequentStatusesOrderBy>>;
  where?: InputMaybe<PatientStatusSubsequentStatusesBoolExp>;
};


export type Subscription_RootPatientStatusSubsequentStatusesByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPatientStatusSubsequentStatusesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PatientStatusSubsequentStatuses_StreamCursorInput>>;
  where?: InputMaybe<PatientStatusSubsequentStatusesBoolExp>;
};


export type Subscription_RootPatientStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_StreamCursorInput>>;
  where?: InputMaybe<PatientBoolExp>;
};


export type Subscription_RootPayerArgs = {
  distinctOn?: InputMaybe<Array<PayerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PayerOrderBy>>;
  where?: InputMaybe<PayerBoolExp>;
};


export type Subscription_RootPayerAggregateArgs = {
  distinctOn?: InputMaybe<Array<PayerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PayerOrderBy>>;
  where?: InputMaybe<PayerBoolExp>;
};


export type Subscription_RootPayerByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPayerStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Payer_StreamCursorInput>>;
  where?: InputMaybe<PayerBoolExp>;
};


export type Subscription_RootPracticegroupArgs = {
  distinctOn?: InputMaybe<Array<PracticegroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PracticegroupOrderBy>>;
  where?: InputMaybe<PracticegroupBoolExp>;
};


export type Subscription_RootPracticegroupAggregateArgs = {
  distinctOn?: InputMaybe<Array<PracticegroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PracticegroupOrderBy>>;
  where?: InputMaybe<PracticegroupBoolExp>;
};


export type Subscription_RootPracticegroupByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPracticegroupStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Practicegroup_StreamCursorInput>>;
  where?: InputMaybe<PracticegroupBoolExp>;
};


export type Subscription_RootProblemArgs = {
  distinctOn?: InputMaybe<Array<ProblemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemOrderBy>>;
  where?: InputMaybe<ProblemBoolExp>;
};


export type Subscription_RootProblemAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProblemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemOrderBy>>;
  where?: InputMaybe<ProblemBoolExp>;
};


export type Subscription_RootProblemByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProblemPlanArgs = {
  distinctOn?: InputMaybe<Array<ProblemPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemPlanOrderBy>>;
  where?: InputMaybe<ProblemPlanBoolExp>;
};


export type Subscription_RootProblemPlanAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProblemPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProblemPlanOrderBy>>;
  where?: InputMaybe<ProblemPlanBoolExp>;
};


export type Subscription_RootProblemPlanByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProblemPlanStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProblemPlan_StreamCursorInput>>;
  where?: InputMaybe<ProblemPlanBoolExp>;
};


export type Subscription_RootProblemStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Problem_StreamCursorInput>>;
  where?: InputMaybe<ProblemBoolExp>;
};


export type Subscription_RootProcedureArgs = {
  distinctOn?: InputMaybe<Array<ProcedureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureOrderBy>>;
  where?: InputMaybe<ProcedureBoolExp>;
};


export type Subscription_RootProcedureAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcedureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureOrderBy>>;
  where?: InputMaybe<ProcedureBoolExp>;
};


export type Subscription_RootProcedureByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProcedureHcpcsArgs = {
  distinctOn?: InputMaybe<Array<ProcedureHcpcsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureHcpcsOrderBy>>;
  where?: InputMaybe<ProcedureHcpcsBoolExp>;
};


export type Subscription_RootProcedureHcpcsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcedureHcpcsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureHcpcsOrderBy>>;
  where?: InputMaybe<ProcedureHcpcsBoolExp>;
};


export type Subscription_RootProcedureHcpcsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProcedureHcpcsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProcedureHcpcs_StreamCursorInput>>;
  where?: InputMaybe<ProcedureHcpcsBoolExp>;
};


export type Subscription_RootProcedureRenderedArgs = {
  distinctOn?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureRenderedOrderBy>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};


export type Subscription_RootProcedureRenderedAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureRenderedOrderBy>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};


export type Subscription_RootProcedureRenderedByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProcedureRenderedStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProcedureRendered_StreamCursorInput>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};


export type Subscription_RootProcedureStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Procedure_StreamCursorInput>>;
  where?: InputMaybe<ProcedureBoolExp>;
};


export type Subscription_RootProviderArgs = {
  distinctOn?: InputMaybe<Array<ProviderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderOrderBy>>;
  where?: InputMaybe<ProviderBoolExp>;
};


export type Subscription_RootProviderAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderOrderBy>>;
  where?: InputMaybe<ProviderBoolExp>;
};


export type Subscription_RootProviderByPkArgs = {
  internalStaffId: Scalars['Int'];
};


export type Subscription_RootProviderClassificationArgs = {
  distinctOn?: InputMaybe<Array<ProviderClassificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderClassificationOrderBy>>;
  where?: InputMaybe<ProviderClassificationBoolExp>;
};


export type Subscription_RootProviderClassificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderClassificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderClassificationOrderBy>>;
  where?: InputMaybe<ProviderClassificationBoolExp>;
};


export type Subscription_RootProviderClassificationByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProviderClassificationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProviderClassification_StreamCursorInput>>;
  where?: InputMaybe<ProviderClassificationBoolExp>;
};


export type Subscription_RootProviderScheduleArgs = {
  distinctOn?: InputMaybe<Array<ProviderScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderScheduleOrderBy>>;
  where?: InputMaybe<ProviderScheduleBoolExp>;
};


export type Subscription_RootProviderScheduleAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderScheduleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProviderScheduleOrderBy>>;
  where?: InputMaybe<ProviderScheduleBoolExp>;
};


export type Subscription_RootProviderScheduleByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProviderScheduleStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProviderSchedule_StreamCursorInput>>;
  where?: InputMaybe<ProviderScheduleBoolExp>;
};


export type Subscription_RootProviderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Provider_StreamCursorInput>>;
  where?: InputMaybe<ProviderBoolExp>;
};


export type Subscription_RootRecordsRequestDestinationArgs = {
  distinctOn?: InputMaybe<Array<RecordsRequestDestinationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordsRequestDestinationOrderBy>>;
  where?: InputMaybe<RecordsRequestDestinationBoolExp>;
};


export type Subscription_RootRecordsRequestDestinationAggregateArgs = {
  distinctOn?: InputMaybe<Array<RecordsRequestDestinationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordsRequestDestinationOrderBy>>;
  where?: InputMaybe<RecordsRequestDestinationBoolExp>;
};


export type Subscription_RootRecordsRequestDestinationByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRecordsRequestDestinationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<RecordsRequestDestination_StreamCursorInput>>;
  where?: InputMaybe<RecordsRequestDestinationBoolExp>;
};


export type Subscription_RootReferralArgs = {
  distinctOn?: InputMaybe<Array<ReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralOrderBy>>;
  where?: InputMaybe<ReferralBoolExp>;
};


export type Subscription_RootReferralAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralOrderBy>>;
  where?: InputMaybe<ReferralBoolExp>;
};


export type Subscription_RootReferralAttachmentsArgs = {
  distinctOn?: InputMaybe<Array<ReferralAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralAttachmentsOrderBy>>;
  where?: InputMaybe<ReferralAttachmentsBoolExp>;
};


export type Subscription_RootReferralAttachmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReferralAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralAttachmentsOrderBy>>;
  where?: InputMaybe<ReferralAttachmentsBoolExp>;
};


export type Subscription_RootReferralAttachmentsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootReferralAttachmentsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ReferralAttachments_StreamCursorInput>>;
  where?: InputMaybe<ReferralAttachmentsBoolExp>;
};


export type Subscription_RootReferralByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootReferralStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Referral_StreamCursorInput>>;
  where?: InputMaybe<ReferralBoolExp>;
};


export type Subscription_RootRenderedAssessmentArgs = {
  distinctOn?: InputMaybe<Array<RenderedAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RenderedAssessmentOrderBy>>;
  where?: InputMaybe<RenderedAssessmentBoolExp>;
};


export type Subscription_RootRenderedAssessmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<RenderedAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RenderedAssessmentOrderBy>>;
  where?: InputMaybe<RenderedAssessmentBoolExp>;
};


export type Subscription_RootRenderedAssessmentByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRenderedAssessmentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<RenderedAssessment_StreamCursorInput>>;
  where?: InputMaybe<RenderedAssessmentBoolExp>;
};


export type Subscription_RootSchedulingActionArgs = {
  distinctOn?: InputMaybe<Array<SchedulingActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchedulingActionOrderBy>>;
  where?: InputMaybe<SchedulingActionBoolExp>;
};


export type Subscription_RootSchedulingActionAggregateArgs = {
  distinctOn?: InputMaybe<Array<SchedulingActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchedulingActionOrderBy>>;
  where?: InputMaybe<SchedulingActionBoolExp>;
};


export type Subscription_RootSchedulingActionByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSchedulingActionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SchedulingAction_StreamCursorInput>>;
  where?: InputMaybe<SchedulingActionBoolExp>;
};


export type Subscription_RootServiceTypeArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ServiceTypeOrderBy>>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};


export type Subscription_RootServiceTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ServiceTypeOrderBy>>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};


export type Subscription_RootServiceTypeByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootServiceTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceType_StreamCursorInput>>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};


export type Subscription_RootSignedConsentArgs = {
  distinctOn?: InputMaybe<Array<SignedConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignedConsentOrderBy>>;
  where?: InputMaybe<SignedConsentBoolExp>;
};


export type Subscription_RootSignedConsentAggregateArgs = {
  distinctOn?: InputMaybe<Array<SignedConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignedConsentOrderBy>>;
  where?: InputMaybe<SignedConsentBoolExp>;
};


export type Subscription_RootSignedConsentByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSignedConsentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SignedConsent_StreamCursorInput>>;
  where?: InputMaybe<SignedConsentBoolExp>;
};


export type Subscription_RootSignoffArgs = {
  distinctOn?: InputMaybe<Array<SignoffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignoffOrderBy>>;
  where?: InputMaybe<SignoffBoolExp>;
};


export type Subscription_RootSignoffAggregateArgs = {
  distinctOn?: InputMaybe<Array<SignoffSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignoffOrderBy>>;
  where?: InputMaybe<SignoffBoolExp>;
};


export type Subscription_RootSignoffByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSignoffStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Signoff_StreamCursorInput>>;
  where?: InputMaybe<SignoffBoolExp>;
};


export type Subscription_RootSignoffTypeArgs = {
  distinctOn?: InputMaybe<Array<SignoffTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignoffTypeOrderBy>>;
  where?: InputMaybe<SignoffTypeBoolExp>;
};


export type Subscription_RootSignoffTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<SignoffTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignoffTypeOrderBy>>;
  where?: InputMaybe<SignoffTypeBoolExp>;
};


export type Subscription_RootSignoffTypeByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSignoffTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SignoffType_StreamCursorInput>>;
  where?: InputMaybe<SignoffTypeBoolExp>;
};


export type Subscription_RootTelemedAppointmentRequestArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};


export type Subscription_RootTelemedAppointmentRequestAccessCodeArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestAccessCodeBoolExp>;
};


export type Subscription_RootTelemedAppointmentRequestAccessCodeAggregateArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestAccessCodeBoolExp>;
};


export type Subscription_RootTelemedAppointmentRequestAccessCodeByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTelemedAppointmentRequestAccessCodeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TelemedAppointmentRequestAccessCode_StreamCursorInput>>;
  where?: InputMaybe<TelemedAppointmentRequestAccessCodeBoolExp>;
};


export type Subscription_RootTelemedAppointmentRequestAggregateArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};


export type Subscription_RootTelemedAppointmentRequestByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTelemedAppointmentRequestStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TelemedAppointmentRequest_StreamCursorInput>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};


export type Subscription_RootThreadCategoryArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryOrderBy>>;
  where?: InputMaybe<ThreadCategoryBoolExp>;
};


export type Subscription_RootThreadCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryOrderBy>>;
  where?: InputMaybe<ThreadCategoryBoolExp>;
};


export type Subscription_RootThreadCategoryByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootThreadCategoryStatusArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategoryStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryStatusOrderBy>>;
  where?: InputMaybe<ThreadCategoryStatusBoolExp>;
};


export type Subscription_RootThreadCategoryStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategoryStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryStatusOrderBy>>;
  where?: InputMaybe<ThreadCategoryStatusBoolExp>;
};


export type Subscription_RootThreadCategoryStatusByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootThreadCategoryStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ThreadCategoryStatus_StreamCursorInput>>;
  where?: InputMaybe<ThreadCategoryStatusBoolExp>;
};


export type Subscription_RootThreadCategoryStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ThreadCategory_StreamCursorInput>>;
  where?: InputMaybe<ThreadCategoryBoolExp>;
};


export type Subscription_RootThreadFieldArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldOrderBy>>;
  where?: InputMaybe<ThreadFieldBoolExp>;
};


export type Subscription_RootThreadFieldAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldOrderBy>>;
  where?: InputMaybe<ThreadFieldBoolExp>;
};


export type Subscription_RootThreadFieldByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootThreadFieldStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ThreadField_StreamCursorInput>>;
  where?: InputMaybe<ThreadFieldBoolExp>;
};


export type Subscription_RootThreadFieldValueArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldValueOrderBy>>;
  where?: InputMaybe<ThreadFieldValueBoolExp>;
};


export type Subscription_RootThreadFieldValueAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldValueOrderBy>>;
  where?: InputMaybe<ThreadFieldValueBoolExp>;
};


export type Subscription_RootThreadFieldValueByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootThreadFieldValueStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ThreadFieldValue_StreamCursorInput>>;
  where?: InputMaybe<ThreadFieldValueBoolExp>;
};


export type Subscription_RootTicketSubscriptionArgs = {
  distinctOn?: InputMaybe<Array<TicketSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketSubscriptionOrderBy>>;
  where?: InputMaybe<TicketSubscriptionBoolExp>;
};


export type Subscription_RootTicketSubscriptionAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketSubscriptionOrderBy>>;
  where?: InputMaybe<TicketSubscriptionBoolExp>;
};


export type Subscription_RootTicketSubscriptionByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTicketSubscriptionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TicketSubscription_StreamCursorInput>>;
  where?: InputMaybe<TicketSubscriptionBoolExp>;
};


export type Subscription_RootTicketThreadArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};


export type Subscription_RootTicketThreadAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};


export type Subscription_RootTicketThreadByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTicketThreadStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TicketThread_StreamCursorInput>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};


export type Subscription_RootTreatmentplanArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanOrderBy>>;
  where?: InputMaybe<TreatmentplanBoolExp>;
};


export type Subscription_RootTreatmentplanAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanOrderBy>>;
  where?: InputMaybe<TreatmentplanBoolExp>;
};


export type Subscription_RootTreatmentplanAssessmentArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanAssessmentBoolExp>;
};


export type Subscription_RootTreatmentplanAssessmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanAssessmentBoolExp>;
};


export type Subscription_RootTreatmentplanAssessmentByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTreatmentplanAssessmentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TreatmentplanAssessment_StreamCursorInput>>;
  where?: InputMaybe<TreatmentplanAssessmentBoolExp>;
};


export type Subscription_RootTreatmentplanByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTreatmentplanNoteArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanNoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanNoteOrderBy>>;
  where?: InputMaybe<TreatmentplanNoteBoolExp>;
};


export type Subscription_RootTreatmentplanNoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanNoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanNoteOrderBy>>;
  where?: InputMaybe<TreatmentplanNoteBoolExp>;
};


export type Subscription_RootTreatmentplanNoteByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTreatmentplanNoteStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TreatmentplanNote_StreamCursorInput>>;
  where?: InputMaybe<TreatmentplanNoteBoolExp>;
};


export type Subscription_RootTreatmentplanProblemsArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanProblemsOrderBy>>;
  where?: InputMaybe<TreatmentplanProblemsBoolExp>;
};


export type Subscription_RootTreatmentplanProblemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanProblemsOrderBy>>;
  where?: InputMaybe<TreatmentplanProblemsBoolExp>;
};


export type Subscription_RootTreatmentplanProblemsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTreatmentplanProblemsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TreatmentplanProblems_StreamCursorInput>>;
  where?: InputMaybe<TreatmentplanProblemsBoolExp>;
};


export type Subscription_RootTreatmentplanStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Treatmentplan_StreamCursorInput>>;
  where?: InputMaybe<TreatmentplanBoolExp>;
};


export type Subscription_RootTreatmentplanTemplateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanTemplateOrderBy>>;
  where?: InputMaybe<TreatmentplanTemplateBoolExp>;
};


export type Subscription_RootTreatmentplanTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanTemplateOrderBy>>;
  where?: InputMaybe<TreatmentplanTemplateBoolExp>;
};


export type Subscription_RootTreatmentplanTemplateAssessmentArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanTemplateAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanTemplateAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
};


export type Subscription_RootTreatmentplanTemplateAssessmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanTemplateAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanTemplateAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
};


export type Subscription_RootTreatmentplanTemplateAssessmentByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTreatmentplanTemplateAssessmentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TreatmentplanTemplateAssessment_StreamCursorInput>>;
  where?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
};


export type Subscription_RootTreatmentplanTemplateByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTreatmentplanTemplateStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TreatmentplanTemplate_StreamCursorInput>>;
  where?: InputMaybe<TreatmentplanTemplateBoolExp>;
};


export type Subscription_RootUserArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type Subscription_RootUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type Subscription_RootUserByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUserGroupsArgs = {
  distinctOn?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


export type Subscription_RootUserGroupsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserGroupsOrderBy>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


export type Subscription_RootUserGroupsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUserGroupsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UserGroups_StreamCursorInput>>;
  where?: InputMaybe<UserGroupsBoolExp>;
};


export type Subscription_RootUserPermissionsArgs = {
  distinctOn?: InputMaybe<Array<UserPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
  where?: InputMaybe<UserPermissionsBoolExp>;
};


export type Subscription_RootUserPermissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
  where?: InputMaybe<UserPermissionsBoolExp>;
};


export type Subscription_RootUserPermissionsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUserPermissionsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UserPermissions_StreamCursorInput>>;
  where?: InputMaybe<UserPermissionsBoolExp>;
};


export type Subscription_RootUserStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<User_StreamCursorInput>>;
  where?: InputMaybe<UserBoolExp>;
};


export type Subscription_RootVisitRebillArgs = {
  distinctOn?: InputMaybe<Array<VisitRebillSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VisitRebillOrderBy>>;
  where?: InputMaybe<VisitRebillBoolExp>;
};


export type Subscription_RootVisitRebillAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitRebillSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VisitRebillOrderBy>>;
  where?: InputMaybe<VisitRebillBoolExp>;
};


export type Subscription_RootVisitRebillByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVisitRebillStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<VisitRebill_StreamCursorInput>>;
  where?: InputMaybe<VisitRebillBoolExp>;
};


export type Subscription_RootVitalsArgs = {
  distinctOn?: InputMaybe<Array<VitalsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VitalsOrderBy>>;
  where?: InputMaybe<VitalsBoolExp>;
};


export type Subscription_RootVitalsAggregateArgs = {
  distinctOn?: InputMaybe<Array<VitalsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VitalsOrderBy>>;
  where?: InputMaybe<VitalsBoolExp>;
};


export type Subscription_RootVitalsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVitalsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<Vitals_StreamCursorInput>>;
  where?: InputMaybe<VitalsBoolExp>;
};

/** columns and relationships of "server_telemedappointmentrequest" */
export type TelemedAppointmentRequest = {
  __typename?: 'telemedAppointmentRequest';
  /** An array relationship */
  accessCodes: Array<TelemedAppointmentRequestAccessCode>;
  /** An aggregate relationship */
  accessCodesAggregate: TelemedAppointmentRequestAccessCodeAggregate;
  active: Scalars['Boolean'];
  /** An object relationship */
  appointment?: Maybe<Appointment>;
  /** An object relationship */
  approvalUser?: Maybe<User>;
  approvalUserId?: Maybe<Scalars['Int']>;
  apptId?: Maybe<Scalars['Int']>;
  availableUntil?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An array relationship */
  messages: Array<Message>;
  /** An aggregate relationship */
  messagesAggregate: MessageAggregate;
  /** An object relationship */
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  patientIntake?: Maybe<PatientIntake>;
  patientIntakeId?: Maybe<Scalars['Int']>;
  providerEta?: Maybe<Scalars['timestamptz']>;
  requestTime: Scalars['timestamptz'];
  /** An object relationship */
  requestingUser?: Maybe<User>;
  requestingUserId?: Maybe<Scalars['Int']>;
  status: Scalars['smallint'];
  timeAvailable: Scalars['timestamptz'];
  /** A computed field, executes function "user_can_access_telemedappointmentrequest" */
  user_can_access?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestAccessCodesArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestAccessCodeBoolExp>;
};


/** columns and relationships of "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestAccessCodesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestAccessCodeBoolExp>;
};


/** columns and relationships of "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestMessagesArgs = {
  distinctOn?: InputMaybe<Array<MessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderBy>>;
  where?: InputMaybe<MessageBoolExp>;
};


/** columns and relationships of "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestMessagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderBy>>;
  where?: InputMaybe<MessageBoolExp>;
};

/** columns and relationships of "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCode = {
  __typename?: 'telemedAppointmentRequestAccessCode';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  created: Scalars['timestamptz'];
  expiresIn: Scalars['interval'];
  id: Scalars['Int'];
  /** An object relationship */
  telemedAppointmentRequest: TelemedAppointmentRequest;
  used?: Maybe<Scalars['timestamptz']>;
  visitRequestId: Scalars['Int'];
};

/** aggregated selection of "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCodeAggregate = {
  __typename?: 'telemedAppointmentRequestAccessCodeAggregate';
  aggregate?: Maybe<TelemedAppointmentRequestAccessCodeAggregateFields>;
  nodes: Array<TelemedAppointmentRequestAccessCode>;
};

/** aggregate fields of "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCodeAggregateFields = {
  __typename?: 'telemedAppointmentRequestAccessCodeAggregateFields';
  avg?: Maybe<TelemedAppointmentRequestAccessCodeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TelemedAppointmentRequestAccessCodeMaxFields>;
  min?: Maybe<TelemedAppointmentRequestAccessCodeMinFields>;
  stddev?: Maybe<TelemedAppointmentRequestAccessCodeStddevFields>;
  stddevPop?: Maybe<TelemedAppointmentRequestAccessCodeStddev_PopFields>;
  stddevSamp?: Maybe<TelemedAppointmentRequestAccessCodeStddev_SampFields>;
  sum?: Maybe<TelemedAppointmentRequestAccessCodeSumFields>;
  varPop?: Maybe<TelemedAppointmentRequestAccessCodeVar_PopFields>;
  varSamp?: Maybe<TelemedAppointmentRequestAccessCodeVar_SampFields>;
  variance?: Maybe<TelemedAppointmentRequestAccessCodeVarianceFields>;
};


/** aggregate fields of "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCodeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCodeAggregateOrderBy = {
  avg?: InputMaybe<TelemedAppointmentRequestAccessCode_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TelemedAppointmentRequestAccessCode_Max_Order_By>;
  min?: InputMaybe<TelemedAppointmentRequestAccessCode_Min_Order_By>;
  stddev?: InputMaybe<TelemedAppointmentRequestAccessCode_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TelemedAppointmentRequestAccessCode_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TelemedAppointmentRequestAccessCode_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TelemedAppointmentRequestAccessCode_Sum_Order_By>;
  var_pop?: InputMaybe<TelemedAppointmentRequestAccessCode_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TelemedAppointmentRequestAccessCode_Var_Samp_Order_By>;
  variance?: InputMaybe<TelemedAppointmentRequestAccessCode_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCodeArrRelInsertInput = {
  data: Array<TelemedAppointmentRequestAccessCodeInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TelemedAppointmentRequestAccessCodeOnConflict>;
};

/** aggregate avg on columns */
export type TelemedAppointmentRequestAccessCodeAvgFields = {
  __typename?: 'telemedAppointmentRequestAccessCodeAvgFields';
  id?: Maybe<Scalars['Float']>;
  visitRequestId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_telemedappointmentrequestaccesscode". All fields are combined with a logical 'AND'. */
export type TelemedAppointmentRequestAccessCodeBoolExp = {
  _and?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeBoolExp>>;
  _not?: InputMaybe<TelemedAppointmentRequestAccessCodeBoolExp>;
  _or?: InputMaybe<Array<TelemedAppointmentRequestAccessCodeBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  code?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  expiresIn?: InputMaybe<IntervalComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  telemedAppointmentRequest?: InputMaybe<TelemedAppointmentRequestBoolExp>;
  used?: InputMaybe<TimestamptzComparisonExp>;
  visitRequestId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_telemedappointmentrequestaccesscode" */
export enum TelemedAppointmentRequestAccessCodeConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerTelemedappointmentrequestaccesscodePkey = 'server_telemedappointmentrequestaccesscode_pkey'
}

/** input type for incrementing numeric columns in table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCodeIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  visitRequestId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCodeInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  expiresIn?: InputMaybe<Scalars['interval']>;
  id?: InputMaybe<Scalars['Int']>;
  telemedAppointmentRequest?: InputMaybe<TelemedAppointmentRequestObjRelInsertInput>;
  used?: InputMaybe<Scalars['timestamptz']>;
  visitRequestId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TelemedAppointmentRequestAccessCodeMaxFields = {
  __typename?: 'telemedAppointmentRequestAccessCodeMaxFields';
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['timestamptz']>;
  visitRequestId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TelemedAppointmentRequestAccessCodeMinFields = {
  __typename?: 'telemedAppointmentRequestAccessCodeMinFields';
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['timestamptz']>;
  visitRequestId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCodeMutationResponse = {
  __typename?: 'telemedAppointmentRequestAccessCodeMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TelemedAppointmentRequestAccessCode>;
};

/** on_conflict condition type for table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCodeOnConflict = {
  constraint: TelemedAppointmentRequestAccessCodeConstraint;
  update_columns?: Array<TelemedAppointmentRequestAccessCodeUpdateColumn>;
  where?: InputMaybe<TelemedAppointmentRequestAccessCodeBoolExp>;
};

/** Ordering options when selecting data from "server_telemedappointmentrequestaccesscode". */
export type TelemedAppointmentRequestAccessCodeOrderBy = {
  active?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  expiresIn?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  telemedAppointmentRequest?: InputMaybe<TelemedAppointmentRequestOrderBy>;
  used?: InputMaybe<OrderBy>;
  visitRequestId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_telemedappointmentrequestaccesscode */
export type TelemedAppointmentRequestAccessCodePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_telemedappointmentrequestaccesscode" */
export enum TelemedAppointmentRequestAccessCodeSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Code = 'code',
  /** column name */
  Created = 'created',
  /** column name */
  ExpiresIn = 'expiresIn',
  /** column name */
  Id = 'id',
  /** column name */
  Used = 'used',
  /** column name */
  VisitRequestId = 'visitRequestId'
}

/** input type for updating data in table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCodeSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  expiresIn?: InputMaybe<Scalars['interval']>;
  id?: InputMaybe<Scalars['Int']>;
  used?: InputMaybe<Scalars['timestamptz']>;
  visitRequestId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TelemedAppointmentRequestAccessCodeStddevFields = {
  __typename?: 'telemedAppointmentRequestAccessCodeStddevFields';
  id?: Maybe<Scalars['Float']>;
  visitRequestId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TelemedAppointmentRequestAccessCodeStddev_PopFields = {
  __typename?: 'telemedAppointmentRequestAccessCodeStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  visitRequestId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TelemedAppointmentRequestAccessCodeStddev_SampFields = {
  __typename?: 'telemedAppointmentRequestAccessCodeStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  visitRequestId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TelemedAppointmentRequestAccessCodeSumFields = {
  __typename?: 'telemedAppointmentRequestAccessCodeSumFields';
  id?: Maybe<Scalars['Int']>;
  visitRequestId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_telemedappointmentrequestaccesscode" */
export enum TelemedAppointmentRequestAccessCodeUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Code = 'code',
  /** column name */
  Created = 'created',
  /** column name */
  ExpiresIn = 'expiresIn',
  /** column name */
  Id = 'id',
  /** column name */
  Used = 'used',
  /** column name */
  VisitRequestId = 'visitRequestId'
}

export type TelemedAppointmentRequestAccessCodeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TelemedAppointmentRequestAccessCodeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TelemedAppointmentRequestAccessCodeSetInput>;
  where: TelemedAppointmentRequestAccessCodeBoolExp;
};

/** aggregate var_pop on columns */
export type TelemedAppointmentRequestAccessCodeVar_PopFields = {
  __typename?: 'telemedAppointmentRequestAccessCodeVar_popFields';
  id?: Maybe<Scalars['Float']>;
  visitRequestId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TelemedAppointmentRequestAccessCodeVar_SampFields = {
  __typename?: 'telemedAppointmentRequestAccessCodeVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  visitRequestId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TelemedAppointmentRequestAccessCodeVarianceFields = {
  __typename?: 'telemedAppointmentRequestAccessCodeVarianceFields';
  id?: Maybe<Scalars['Float']>;
  visitRequestId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCode_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  visitRequestId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCode_Max_Order_By = {
  code?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  used?: InputMaybe<OrderBy>;
  visitRequestId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCode_Min_Order_By = {
  code?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  used?: InputMaybe<OrderBy>;
  visitRequestId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCode_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  visitRequestId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCode_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  visitRequestId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCode_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  visitRequestId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "telemedAppointmentRequestAccessCode" */
export type TelemedAppointmentRequestAccessCode_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TelemedAppointmentRequestAccessCode_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TelemedAppointmentRequestAccessCode_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  expiresIn?: InputMaybe<Scalars['interval']>;
  id?: InputMaybe<Scalars['Int']>;
  used?: InputMaybe<Scalars['timestamptz']>;
  visitRequestId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCode_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  visitRequestId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCode_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  visitRequestId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCode_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  visitRequestId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_telemedappointmentrequestaccesscode" */
export type TelemedAppointmentRequestAccessCode_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  visitRequestId?: InputMaybe<OrderBy>;
};

/** aggregated selection of "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestAggregate = {
  __typename?: 'telemedAppointmentRequestAggregate';
  aggregate?: Maybe<TelemedAppointmentRequestAggregateFields>;
  nodes: Array<TelemedAppointmentRequest>;
};

/** aggregate fields of "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestAggregateFields = {
  __typename?: 'telemedAppointmentRequestAggregateFields';
  avg?: Maybe<TelemedAppointmentRequestAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TelemedAppointmentRequestMaxFields>;
  min?: Maybe<TelemedAppointmentRequestMinFields>;
  stddev?: Maybe<TelemedAppointmentRequestStddevFields>;
  stddevPop?: Maybe<TelemedAppointmentRequestStddev_PopFields>;
  stddevSamp?: Maybe<TelemedAppointmentRequestStddev_SampFields>;
  sum?: Maybe<TelemedAppointmentRequestSumFields>;
  varPop?: Maybe<TelemedAppointmentRequestVar_PopFields>;
  varSamp?: Maybe<TelemedAppointmentRequestVar_SampFields>;
  variance?: Maybe<TelemedAppointmentRequestVarianceFields>;
};


/** aggregate fields of "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestAggregateOrderBy = {
  avg?: InputMaybe<TelemedAppointmentRequest_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TelemedAppointmentRequest_Max_Order_By>;
  min?: InputMaybe<TelemedAppointmentRequest_Min_Order_By>;
  stddev?: InputMaybe<TelemedAppointmentRequest_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TelemedAppointmentRequest_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TelemedAppointmentRequest_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TelemedAppointmentRequest_Sum_Order_By>;
  var_pop?: InputMaybe<TelemedAppointmentRequest_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TelemedAppointmentRequest_Var_Samp_Order_By>;
  variance?: InputMaybe<TelemedAppointmentRequest_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestArrRelInsertInput = {
  data: Array<TelemedAppointmentRequestInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TelemedAppointmentRequestOnConflict>;
};

/** aggregate avg on columns */
export type TelemedAppointmentRequestAvgFields = {
  __typename?: 'telemedAppointmentRequestAvgFields';
  approvalUserId?: Maybe<Scalars['Float']>;
  apptId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  patientIntakeId?: Maybe<Scalars['Float']>;
  requestingUserId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_telemedappointmentrequest". All fields are combined with a logical 'AND'. */
export type TelemedAppointmentRequestBoolExp = {
  _and?: InputMaybe<Array<TelemedAppointmentRequestBoolExp>>;
  _not?: InputMaybe<TelemedAppointmentRequestBoolExp>;
  _or?: InputMaybe<Array<TelemedAppointmentRequestBoolExp>>;
  accessCodes?: InputMaybe<TelemedAppointmentRequestAccessCodeBoolExp>;
  active?: InputMaybe<BooleanComparisonExp>;
  appointment?: InputMaybe<AppointmentBoolExp>;
  approvalUser?: InputMaybe<UserBoolExp>;
  approvalUserId?: InputMaybe<IntComparisonExp>;
  apptId?: InputMaybe<IntComparisonExp>;
  availableUntil?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  messages?: InputMaybe<MessageBoolExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  patientIntake?: InputMaybe<PatientIntakeBoolExp>;
  patientIntakeId?: InputMaybe<IntComparisonExp>;
  providerEta?: InputMaybe<TimestamptzComparisonExp>;
  requestTime?: InputMaybe<TimestamptzComparisonExp>;
  requestingUser?: InputMaybe<UserBoolExp>;
  requestingUserId?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<SmallintComparisonExp>;
  timeAvailable?: InputMaybe<TimestamptzComparisonExp>;
  user_can_access?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "server_telemedappointmentrequest" */
export enum TelemedAppointmentRequestConstraint {
  /** unique or primary key constraint on columns "appt_id" */
  ServerTelemedappointmentrequestApptIdKey = 'server_telemedappointmentrequest_appt_id_key',
  /** unique or primary key constraint on columns "id" */
  ServerTelemedappointmentrequestPkey = 'server_telemedappointmentrequest_pkey'
}

/** input type for incrementing numeric columns in table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestIncInput = {
  approvalUserId?: InputMaybe<Scalars['Int']>;
  apptId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  patientIntakeId?: InputMaybe<Scalars['Int']>;
  requestingUserId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestInsertInput = {
  accessCodes?: InputMaybe<TelemedAppointmentRequestAccessCodeArrRelInsertInput>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointment?: InputMaybe<AppointmentObjRelInsertInput>;
  approvalUser?: InputMaybe<UserObjRelInsertInput>;
  approvalUserId?: InputMaybe<Scalars['Int']>;
  apptId?: InputMaybe<Scalars['Int']>;
  availableUntil?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  messages?: InputMaybe<MessageArrRelInsertInput>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  patientIntake?: InputMaybe<PatientIntakeObjRelInsertInput>;
  patientIntakeId?: InputMaybe<Scalars['Int']>;
  providerEta?: InputMaybe<Scalars['timestamptz']>;
  requestTime?: InputMaybe<Scalars['timestamptz']>;
  requestingUser?: InputMaybe<UserObjRelInsertInput>;
  requestingUserId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
  timeAvailable?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TelemedAppointmentRequestMaxFields = {
  __typename?: 'telemedAppointmentRequestMaxFields';
  approvalUserId?: Maybe<Scalars['Int']>;
  apptId?: Maybe<Scalars['Int']>;
  availableUntil?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  patientIntakeId?: Maybe<Scalars['Int']>;
  providerEta?: Maybe<Scalars['timestamptz']>;
  requestTime?: Maybe<Scalars['timestamptz']>;
  requestingUserId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
  timeAvailable?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type TelemedAppointmentRequestMinFields = {
  __typename?: 'telemedAppointmentRequestMinFields';
  approvalUserId?: Maybe<Scalars['Int']>;
  apptId?: Maybe<Scalars['Int']>;
  availableUntil?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  patientIntakeId?: Maybe<Scalars['Int']>;
  providerEta?: Maybe<Scalars['timestamptz']>;
  requestTime?: Maybe<Scalars['timestamptz']>;
  requestingUserId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
  timeAvailable?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestMutationResponse = {
  __typename?: 'telemedAppointmentRequestMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TelemedAppointmentRequest>;
};

/** input type for inserting object relation for remote table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestObjRelInsertInput = {
  data: TelemedAppointmentRequestInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TelemedAppointmentRequestOnConflict>;
};

/** on_conflict condition type for table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestOnConflict = {
  constraint: TelemedAppointmentRequestConstraint;
  update_columns?: Array<TelemedAppointmentRequestUpdateColumn>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};

/** Ordering options when selecting data from "server_telemedappointmentrequest". */
export type TelemedAppointmentRequestOrderBy = {
  accessCodesAggregate?: InputMaybe<TelemedAppointmentRequestAccessCodeAggregateOrderBy>;
  active?: InputMaybe<OrderBy>;
  appointment?: InputMaybe<AppointmentOrderBy>;
  approvalUser?: InputMaybe<UserOrderBy>;
  approvalUserId?: InputMaybe<OrderBy>;
  apptId?: InputMaybe<OrderBy>;
  availableUntil?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messagesAggregate?: InputMaybe<MessageAggregateOrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  patientIntake?: InputMaybe<PatientIntakeOrderBy>;
  patientIntakeId?: InputMaybe<OrderBy>;
  providerEta?: InputMaybe<OrderBy>;
  requestTime?: InputMaybe<OrderBy>;
  requestingUser?: InputMaybe<UserOrderBy>;
  requestingUserId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  timeAvailable?: InputMaybe<OrderBy>;
  user_can_access?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_telemedappointmentrequest */
export type TelemedAppointmentRequestPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_telemedappointmentrequest" */
export enum TelemedAppointmentRequestSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  ApprovalUserId = 'approvalUserId',
  /** column name */
  ApptId = 'apptId',
  /** column name */
  AvailableUntil = 'availableUntil',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientIntakeId = 'patientIntakeId',
  /** column name */
  ProviderEta = 'providerEta',
  /** column name */
  RequestTime = 'requestTime',
  /** column name */
  RequestingUserId = 'requestingUserId',
  /** column name */
  Status = 'status',
  /** column name */
  TimeAvailable = 'timeAvailable'
}

/** input type for updating data in table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequestSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  approvalUserId?: InputMaybe<Scalars['Int']>;
  apptId?: InputMaybe<Scalars['Int']>;
  availableUntil?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  patientIntakeId?: InputMaybe<Scalars['Int']>;
  providerEta?: InputMaybe<Scalars['timestamptz']>;
  requestTime?: InputMaybe<Scalars['timestamptz']>;
  requestingUserId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
  timeAvailable?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TelemedAppointmentRequestStddevFields = {
  __typename?: 'telemedAppointmentRequestStddevFields';
  approvalUserId?: Maybe<Scalars['Float']>;
  apptId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  patientIntakeId?: Maybe<Scalars['Float']>;
  requestingUserId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TelemedAppointmentRequestStddev_PopFields = {
  __typename?: 'telemedAppointmentRequestStddev_popFields';
  approvalUserId?: Maybe<Scalars['Float']>;
  apptId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  patientIntakeId?: Maybe<Scalars['Float']>;
  requestingUserId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TelemedAppointmentRequestStddev_SampFields = {
  __typename?: 'telemedAppointmentRequestStddev_sampFields';
  approvalUserId?: Maybe<Scalars['Float']>;
  apptId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  patientIntakeId?: Maybe<Scalars['Float']>;
  requestingUserId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TelemedAppointmentRequestSumFields = {
  __typename?: 'telemedAppointmentRequestSumFields';
  approvalUserId?: Maybe<Scalars['Int']>;
  apptId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  patientIntakeId?: Maybe<Scalars['Int']>;
  requestingUserId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_telemedappointmentrequest" */
export enum TelemedAppointmentRequestUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  ApprovalUserId = 'approvalUserId',
  /** column name */
  ApptId = 'apptId',
  /** column name */
  AvailableUntil = 'availableUntil',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PatientIntakeId = 'patientIntakeId',
  /** column name */
  ProviderEta = 'providerEta',
  /** column name */
  RequestTime = 'requestTime',
  /** column name */
  RequestingUserId = 'requestingUserId',
  /** column name */
  Status = 'status',
  /** column name */
  TimeAvailable = 'timeAvailable'
}

export type TelemedAppointmentRequestUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TelemedAppointmentRequestIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TelemedAppointmentRequestSetInput>;
  where: TelemedAppointmentRequestBoolExp;
};

/** aggregate var_pop on columns */
export type TelemedAppointmentRequestVar_PopFields = {
  __typename?: 'telemedAppointmentRequestVar_popFields';
  approvalUserId?: Maybe<Scalars['Float']>;
  apptId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  patientIntakeId?: Maybe<Scalars['Float']>;
  requestingUserId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TelemedAppointmentRequestVar_SampFields = {
  __typename?: 'telemedAppointmentRequestVar_sampFields';
  approvalUserId?: Maybe<Scalars['Float']>;
  apptId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  patientIntakeId?: Maybe<Scalars['Float']>;
  requestingUserId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TelemedAppointmentRequestVarianceFields = {
  __typename?: 'telemedAppointmentRequestVarianceFields';
  approvalUserId?: Maybe<Scalars['Float']>;
  apptId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  patientIntakeId?: Maybe<Scalars['Float']>;
  requestingUserId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequest_Avg_Order_By = {
  approvalUserId?: InputMaybe<OrderBy>;
  apptId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  patientIntakeId?: InputMaybe<OrderBy>;
  requestingUserId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequest_Max_Order_By = {
  approvalUserId?: InputMaybe<OrderBy>;
  apptId?: InputMaybe<OrderBy>;
  availableUntil?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  patientIntakeId?: InputMaybe<OrderBy>;
  providerEta?: InputMaybe<OrderBy>;
  requestTime?: InputMaybe<OrderBy>;
  requestingUserId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  timeAvailable?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequest_Min_Order_By = {
  approvalUserId?: InputMaybe<OrderBy>;
  apptId?: InputMaybe<OrderBy>;
  availableUntil?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  patientIntakeId?: InputMaybe<OrderBy>;
  providerEta?: InputMaybe<OrderBy>;
  requestTime?: InputMaybe<OrderBy>;
  requestingUserId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  timeAvailable?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequest_Stddev_Order_By = {
  approvalUserId?: InputMaybe<OrderBy>;
  apptId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  patientIntakeId?: InputMaybe<OrderBy>;
  requestingUserId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequest_Stddev_Pop_Order_By = {
  approvalUserId?: InputMaybe<OrderBy>;
  apptId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  patientIntakeId?: InputMaybe<OrderBy>;
  requestingUserId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequest_Stddev_Samp_Order_By = {
  approvalUserId?: InputMaybe<OrderBy>;
  apptId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  patientIntakeId?: InputMaybe<OrderBy>;
  requestingUserId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "telemedAppointmentRequest" */
export type TelemedAppointmentRequest_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TelemedAppointmentRequest_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TelemedAppointmentRequest_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  approvalUserId?: InputMaybe<Scalars['Int']>;
  apptId?: InputMaybe<Scalars['Int']>;
  availableUntil?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  patientIntakeId?: InputMaybe<Scalars['Int']>;
  providerEta?: InputMaybe<Scalars['timestamptz']>;
  requestTime?: InputMaybe<Scalars['timestamptz']>;
  requestingUserId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['smallint']>;
  timeAvailable?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequest_Sum_Order_By = {
  approvalUserId?: InputMaybe<OrderBy>;
  apptId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  patientIntakeId?: InputMaybe<OrderBy>;
  requestingUserId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequest_Var_Pop_Order_By = {
  approvalUserId?: InputMaybe<OrderBy>;
  apptId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  patientIntakeId?: InputMaybe<OrderBy>;
  requestingUserId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequest_Var_Samp_Order_By = {
  approvalUserId?: InputMaybe<OrderBy>;
  apptId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  patientIntakeId?: InputMaybe<OrderBy>;
  requestingUserId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_telemedappointmentrequest" */
export type TelemedAppointmentRequest_Variance_Order_By = {
  approvalUserId?: InputMaybe<OrderBy>;
  apptId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  patientIntakeId?: InputMaybe<OrderBy>;
  requestingUserId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_threadcategory" */
export type ThreadCategory = {
  __typename?: 'threadCategory';
  /** An array relationship */
  childThreadCategories: Array<ThreadCategory>;
  /** An aggregate relationship */
  childThreadCategoriesAggregate: ThreadCategoryAggregate;
  /** An array relationship */
  fields: Array<ThreadField>;
  /** An aggregate relationship */
  fieldsAggregate: ThreadFieldAggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  parentThreadCategory?: Maybe<ThreadCategory>;
  practiceGroupId: Scalars['Int'];
  /** An object relationship */
  practicegroup: Practicegroup;
  /** An array relationship */
  statuses: Array<ThreadCategoryStatus>;
  /** An aggregate relationship */
  statusesAggregate: ThreadCategoryStatusAggregate;
  /** An array relationship */
  ticketthreads: Array<TicketThread>;
  /** An aggregate relationship */
  ticketthreadsAggregate: TicketThreadAggregate;
};


/** columns and relationships of "server_threadcategory" */
export type ThreadCategoryChildThreadCategoriesArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryOrderBy>>;
  where?: InputMaybe<ThreadCategoryBoolExp>;
};


/** columns and relationships of "server_threadcategory" */
export type ThreadCategoryChildThreadCategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryOrderBy>>;
  where?: InputMaybe<ThreadCategoryBoolExp>;
};


/** columns and relationships of "server_threadcategory" */
export type ThreadCategoryFieldsArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldOrderBy>>;
  where?: InputMaybe<ThreadFieldBoolExp>;
};


/** columns and relationships of "server_threadcategory" */
export type ThreadCategoryFieldsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldOrderBy>>;
  where?: InputMaybe<ThreadFieldBoolExp>;
};


/** columns and relationships of "server_threadcategory" */
export type ThreadCategoryStatusesArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategoryStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryStatusOrderBy>>;
  where?: InputMaybe<ThreadCategoryStatusBoolExp>;
};


/** columns and relationships of "server_threadcategory" */
export type ThreadCategoryStatusesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategoryStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryStatusOrderBy>>;
  where?: InputMaybe<ThreadCategoryStatusBoolExp>;
};


/** columns and relationships of "server_threadcategory" */
export type ThreadCategoryTicketthreadsArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};


/** columns and relationships of "server_threadcategory" */
export type ThreadCategoryTicketthreadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};

/** aggregated selection of "server_threadcategory" */
export type ThreadCategoryAggregate = {
  __typename?: 'threadCategoryAggregate';
  aggregate?: Maybe<ThreadCategoryAggregateFields>;
  nodes: Array<ThreadCategory>;
};

/** aggregate fields of "server_threadcategory" */
export type ThreadCategoryAggregateFields = {
  __typename?: 'threadCategoryAggregateFields';
  avg?: Maybe<ThreadCategoryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ThreadCategoryMaxFields>;
  min?: Maybe<ThreadCategoryMinFields>;
  stddev?: Maybe<ThreadCategoryStddevFields>;
  stddevPop?: Maybe<ThreadCategoryStddev_PopFields>;
  stddevSamp?: Maybe<ThreadCategoryStddev_SampFields>;
  sum?: Maybe<ThreadCategorySumFields>;
  varPop?: Maybe<ThreadCategoryVar_PopFields>;
  varSamp?: Maybe<ThreadCategoryVar_SampFields>;
  variance?: Maybe<ThreadCategoryVarianceFields>;
};


/** aggregate fields of "server_threadcategory" */
export type ThreadCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ThreadCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_threadcategory" */
export type ThreadCategoryAggregateOrderBy = {
  avg?: InputMaybe<ThreadCategory_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ThreadCategory_Max_Order_By>;
  min?: InputMaybe<ThreadCategory_Min_Order_By>;
  stddev?: InputMaybe<ThreadCategory_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ThreadCategory_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ThreadCategory_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ThreadCategory_Sum_Order_By>;
  var_pop?: InputMaybe<ThreadCategory_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ThreadCategory_Var_Samp_Order_By>;
  variance?: InputMaybe<ThreadCategory_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_threadcategory" */
export type ThreadCategoryArrRelInsertInput = {
  data: Array<ThreadCategoryInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ThreadCategoryOnConflict>;
};

/** aggregate avg on columns */
export type ThreadCategoryAvgFields = {
  __typename?: 'threadCategoryAvgFields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_threadcategory". All fields are combined with a logical 'AND'. */
export type ThreadCategoryBoolExp = {
  _and?: InputMaybe<Array<ThreadCategoryBoolExp>>;
  _not?: InputMaybe<ThreadCategoryBoolExp>;
  _or?: InputMaybe<Array<ThreadCategoryBoolExp>>;
  childThreadCategories?: InputMaybe<ThreadCategoryBoolExp>;
  fields?: InputMaybe<ThreadFieldBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  parentId?: InputMaybe<IntComparisonExp>;
  parentThreadCategory?: InputMaybe<ThreadCategoryBoolExp>;
  practiceGroupId?: InputMaybe<IntComparisonExp>;
  practicegroup?: InputMaybe<PracticegroupBoolExp>;
  statuses?: InputMaybe<ThreadCategoryStatusBoolExp>;
  ticketthreads?: InputMaybe<TicketThreadBoolExp>;
};

/** unique or primary key constraints on table "server_threadcategory" */
export enum ThreadCategoryConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerMessagecategoryPkey = 'server_messagecategory_pkey'
}

/** input type for incrementing numeric columns in table "server_threadcategory" */
export type ThreadCategoryIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['Int']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_threadcategory" */
export type ThreadCategoryInsertInput = {
  childThreadCategories?: InputMaybe<ThreadCategoryArrRelInsertInput>;
  fields?: InputMaybe<ThreadFieldArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['Int']>;
  parentThreadCategory?: InputMaybe<ThreadCategoryObjRelInsertInput>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
  practicegroup?: InputMaybe<PracticegroupObjRelInsertInput>;
  statuses?: InputMaybe<ThreadCategoryStatusArrRelInsertInput>;
  ticketthreads?: InputMaybe<TicketThreadArrRelInsertInput>;
};

/** aggregate max on columns */
export type ThreadCategoryMaxFields = {
  __typename?: 'threadCategoryMaxFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ThreadCategoryMinFields = {
  __typename?: 'threadCategoryMinFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_threadcategory" */
export type ThreadCategoryMutationResponse = {
  __typename?: 'threadCategoryMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ThreadCategory>;
};

/** input type for inserting object relation for remote table "server_threadcategory" */
export type ThreadCategoryObjRelInsertInput = {
  data: ThreadCategoryInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ThreadCategoryOnConflict>;
};

/** on_conflict condition type for table "server_threadcategory" */
export type ThreadCategoryOnConflict = {
  constraint: ThreadCategoryConstraint;
  update_columns?: Array<ThreadCategoryUpdateColumn>;
  where?: InputMaybe<ThreadCategoryBoolExp>;
};

/** Ordering options when selecting data from "server_threadcategory". */
export type ThreadCategoryOrderBy = {
  childThreadCategoriesAggregate?: InputMaybe<ThreadCategoryAggregateOrderBy>;
  fieldsAggregate?: InputMaybe<ThreadFieldAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  parentThreadCategory?: InputMaybe<ThreadCategoryOrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
  practicegroup?: InputMaybe<PracticegroupOrderBy>;
  statusesAggregate?: InputMaybe<ThreadCategoryStatusAggregateOrderBy>;
  ticketthreadsAggregate?: InputMaybe<TicketThreadAggregateOrderBy>;
};

/** primary key columns input for table: server_threadcategory */
export type ThreadCategoryPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_threadcategory" */
export enum ThreadCategorySelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  PracticeGroupId = 'practiceGroupId'
}

/** input type for updating data in table "server_threadcategory" */
export type ThreadCategorySetInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['Int']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "server_threadcategorystatus" */
export type ThreadCategoryStatus = {
  __typename?: 'threadCategoryStatus';
  categoryId: Scalars['Int'];
  endStatus: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  previousStatus?: Maybe<ThreadCategoryStatus>;
  previousStatusId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  subsequentStatuses: Array<ThreadCategoryStatus>;
  /** An aggregate relationship */
  subsequentStatusesAggregate: ThreadCategoryStatusAggregate;
  /** An object relationship */
  threadCategory: ThreadCategory;
  /** An array relationship */
  ticketthreads: Array<TicketThread>;
  /** An aggregate relationship */
  ticketthreadsAggregate: TicketThreadAggregate;
};


/** columns and relationships of "server_threadcategorystatus" */
export type ThreadCategoryStatusSubsequentStatusesArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategoryStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryStatusOrderBy>>;
  where?: InputMaybe<ThreadCategoryStatusBoolExp>;
};


/** columns and relationships of "server_threadcategorystatus" */
export type ThreadCategoryStatusSubsequentStatusesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadCategoryStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadCategoryStatusOrderBy>>;
  where?: InputMaybe<ThreadCategoryStatusBoolExp>;
};


/** columns and relationships of "server_threadcategorystatus" */
export type ThreadCategoryStatusTicketthreadsArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};


/** columns and relationships of "server_threadcategorystatus" */
export type ThreadCategoryStatusTicketthreadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};

/** aggregated selection of "server_threadcategorystatus" */
export type ThreadCategoryStatusAggregate = {
  __typename?: 'threadCategoryStatusAggregate';
  aggregate?: Maybe<ThreadCategoryStatusAggregateFields>;
  nodes: Array<ThreadCategoryStatus>;
};

/** aggregate fields of "server_threadcategorystatus" */
export type ThreadCategoryStatusAggregateFields = {
  __typename?: 'threadCategoryStatusAggregateFields';
  avg?: Maybe<ThreadCategoryStatusAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ThreadCategoryStatusMaxFields>;
  min?: Maybe<ThreadCategoryStatusMinFields>;
  stddev?: Maybe<ThreadCategoryStatusStddevFields>;
  stddevPop?: Maybe<ThreadCategoryStatusStddev_PopFields>;
  stddevSamp?: Maybe<ThreadCategoryStatusStddev_SampFields>;
  sum?: Maybe<ThreadCategoryStatusSumFields>;
  varPop?: Maybe<ThreadCategoryStatusVar_PopFields>;
  varSamp?: Maybe<ThreadCategoryStatusVar_SampFields>;
  variance?: Maybe<ThreadCategoryStatusVarianceFields>;
};


/** aggregate fields of "server_threadcategorystatus" */
export type ThreadCategoryStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ThreadCategoryStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_threadcategorystatus" */
export type ThreadCategoryStatusAggregateOrderBy = {
  avg?: InputMaybe<ThreadCategoryStatus_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ThreadCategoryStatus_Max_Order_By>;
  min?: InputMaybe<ThreadCategoryStatus_Min_Order_By>;
  stddev?: InputMaybe<ThreadCategoryStatus_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ThreadCategoryStatus_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ThreadCategoryStatus_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ThreadCategoryStatus_Sum_Order_By>;
  var_pop?: InputMaybe<ThreadCategoryStatus_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ThreadCategoryStatus_Var_Samp_Order_By>;
  variance?: InputMaybe<ThreadCategoryStatus_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_threadcategorystatus" */
export type ThreadCategoryStatusArrRelInsertInput = {
  data: Array<ThreadCategoryStatusInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ThreadCategoryStatusOnConflict>;
};

/** aggregate avg on columns */
export type ThreadCategoryStatusAvgFields = {
  __typename?: 'threadCategoryStatusAvgFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  previousStatusId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_threadcategorystatus". All fields are combined with a logical 'AND'. */
export type ThreadCategoryStatusBoolExp = {
  _and?: InputMaybe<Array<ThreadCategoryStatusBoolExp>>;
  _not?: InputMaybe<ThreadCategoryStatusBoolExp>;
  _or?: InputMaybe<Array<ThreadCategoryStatusBoolExp>>;
  categoryId?: InputMaybe<IntComparisonExp>;
  endStatus?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  previousStatus?: InputMaybe<ThreadCategoryStatusBoolExp>;
  previousStatusId?: InputMaybe<IntComparisonExp>;
  subsequentStatuses?: InputMaybe<ThreadCategoryStatusBoolExp>;
  threadCategory?: InputMaybe<ThreadCategoryBoolExp>;
  ticketthreads?: InputMaybe<TicketThreadBoolExp>;
};

/** unique or primary key constraints on table "server_threadcategorystatus" */
export enum ThreadCategoryStatusConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerThreadcategorystatusPkey = 'server_threadcategorystatus_pkey'
}

/** input type for incrementing numeric columns in table "server_threadcategorystatus" */
export type ThreadCategoryStatusIncInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  previousStatusId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_threadcategorystatus" */
export type ThreadCategoryStatusInsertInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  endStatus?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  previousStatus?: InputMaybe<ThreadCategoryStatusObjRelInsertInput>;
  previousStatusId?: InputMaybe<Scalars['Int']>;
  subsequentStatuses?: InputMaybe<ThreadCategoryStatusArrRelInsertInput>;
  threadCategory?: InputMaybe<ThreadCategoryObjRelInsertInput>;
  ticketthreads?: InputMaybe<TicketThreadArrRelInsertInput>;
};

/** aggregate max on columns */
export type ThreadCategoryStatusMaxFields = {
  __typename?: 'threadCategoryStatusMaxFields';
  categoryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  previousStatusId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ThreadCategoryStatusMinFields = {
  __typename?: 'threadCategoryStatusMinFields';
  categoryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  previousStatusId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_threadcategorystatus" */
export type ThreadCategoryStatusMutationResponse = {
  __typename?: 'threadCategoryStatusMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ThreadCategoryStatus>;
};

/** input type for inserting object relation for remote table "server_threadcategorystatus" */
export type ThreadCategoryStatusObjRelInsertInput = {
  data: ThreadCategoryStatusInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ThreadCategoryStatusOnConflict>;
};

/** on_conflict condition type for table "server_threadcategorystatus" */
export type ThreadCategoryStatusOnConflict = {
  constraint: ThreadCategoryStatusConstraint;
  update_columns?: Array<ThreadCategoryStatusUpdateColumn>;
  where?: InputMaybe<ThreadCategoryStatusBoolExp>;
};

/** Ordering options when selecting data from "server_threadcategorystatus". */
export type ThreadCategoryStatusOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  endStatus?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  previousStatus?: InputMaybe<ThreadCategoryStatusOrderBy>;
  previousStatusId?: InputMaybe<OrderBy>;
  subsequentStatusesAggregate?: InputMaybe<ThreadCategoryStatusAggregateOrderBy>;
  threadCategory?: InputMaybe<ThreadCategoryOrderBy>;
  ticketthreadsAggregate?: InputMaybe<TicketThreadAggregateOrderBy>;
};

/** primary key columns input for table: server_threadcategorystatus */
export type ThreadCategoryStatusPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_threadcategorystatus" */
export enum ThreadCategoryStatusSelectColumn {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  EndStatus = 'endStatus',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PreviousStatusId = 'previousStatusId'
}

/** input type for updating data in table "server_threadcategorystatus" */
export type ThreadCategoryStatusSetInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  endStatus?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  previousStatusId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ThreadCategoryStatusStddevFields = {
  __typename?: 'threadCategoryStatusStddevFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  previousStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ThreadCategoryStatusStddev_PopFields = {
  __typename?: 'threadCategoryStatusStddev_popFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  previousStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ThreadCategoryStatusStddev_SampFields = {
  __typename?: 'threadCategoryStatusStddev_sampFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  previousStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ThreadCategoryStatusSumFields = {
  __typename?: 'threadCategoryStatusSumFields';
  categoryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  previousStatusId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_threadcategorystatus" */
export enum ThreadCategoryStatusUpdateColumn {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  EndStatus = 'endStatus',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PreviousStatusId = 'previousStatusId'
}

export type ThreadCategoryStatusUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ThreadCategoryStatusIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ThreadCategoryStatusSetInput>;
  where: ThreadCategoryStatusBoolExp;
};

/** aggregate var_pop on columns */
export type ThreadCategoryStatusVar_PopFields = {
  __typename?: 'threadCategoryStatusVar_popFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  previousStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ThreadCategoryStatusVar_SampFields = {
  __typename?: 'threadCategoryStatusVar_sampFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  previousStatusId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ThreadCategoryStatusVarianceFields = {
  __typename?: 'threadCategoryStatusVarianceFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  previousStatusId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_threadcategorystatus" */
export type ThreadCategoryStatus_Avg_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  previousStatusId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_threadcategorystatus" */
export type ThreadCategoryStatus_Max_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  previousStatusId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_threadcategorystatus" */
export type ThreadCategoryStatus_Min_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  previousStatusId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_threadcategorystatus" */
export type ThreadCategoryStatus_Stddev_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  previousStatusId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_threadcategorystatus" */
export type ThreadCategoryStatus_Stddev_Pop_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  previousStatusId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_threadcategorystatus" */
export type ThreadCategoryStatus_Stddev_Samp_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  previousStatusId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "threadCategoryStatus" */
export type ThreadCategoryStatus_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ThreadCategoryStatus_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ThreadCategoryStatus_StreamCursorValueInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  endStatus?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  previousStatusId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_threadcategorystatus" */
export type ThreadCategoryStatus_Sum_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  previousStatusId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_threadcategorystatus" */
export type ThreadCategoryStatus_Var_Pop_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  previousStatusId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_threadcategorystatus" */
export type ThreadCategoryStatus_Var_Samp_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  previousStatusId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_threadcategorystatus" */
export type ThreadCategoryStatus_Variance_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  previousStatusId?: InputMaybe<OrderBy>;
};

/** aggregate stddev on columns */
export type ThreadCategoryStddevFields = {
  __typename?: 'threadCategoryStddevFields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ThreadCategoryStddev_PopFields = {
  __typename?: 'threadCategoryStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ThreadCategoryStddev_SampFields = {
  __typename?: 'threadCategoryStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ThreadCategorySumFields = {
  __typename?: 'threadCategorySumFields';
  id?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  practiceGroupId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_threadcategory" */
export enum ThreadCategoryUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  PracticeGroupId = 'practiceGroupId'
}

export type ThreadCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ThreadCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ThreadCategorySetInput>;
  where: ThreadCategoryBoolExp;
};

/** aggregate var_pop on columns */
export type ThreadCategoryVar_PopFields = {
  __typename?: 'threadCategoryVar_popFields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ThreadCategoryVar_SampFields = {
  __typename?: 'threadCategoryVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ThreadCategoryVarianceFields = {
  __typename?: 'threadCategoryVarianceFields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  practiceGroupId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_threadcategory" */
export type ThreadCategory_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_threadcategory" */
export type ThreadCategory_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_threadcategory" */
export type ThreadCategory_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_threadcategory" */
export type ThreadCategory_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_threadcategory" */
export type ThreadCategory_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_threadcategory" */
export type ThreadCategory_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "threadCategory" */
export type ThreadCategory_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ThreadCategory_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ThreadCategory_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['Int']>;
  practiceGroupId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_threadcategory" */
export type ThreadCategory_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_threadcategory" */
export type ThreadCategory_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_threadcategory" */
export type ThreadCategory_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_threadcategory" */
export type ThreadCategory_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  practiceGroupId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_threadfield" */
export type ThreadField = {
  __typename?: 'threadField';
  active: Scalars['Boolean'];
  categoryId: Scalars['Int'];
  /** An array relationship */
  fieldValues: Array<ThreadFieldValue>;
  /** An aggregate relationship */
  fieldValuesAggregate: ThreadFieldValueAggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  threadCategory: ThreadCategory;
};


/** columns and relationships of "server_threadfield" */
export type ThreadFieldFieldValuesArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldValueOrderBy>>;
  where?: InputMaybe<ThreadFieldValueBoolExp>;
};


/** columns and relationships of "server_threadfield" */
export type ThreadFieldFieldValuesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldValueOrderBy>>;
  where?: InputMaybe<ThreadFieldValueBoolExp>;
};

/** aggregated selection of "server_threadfield" */
export type ThreadFieldAggregate = {
  __typename?: 'threadFieldAggregate';
  aggregate?: Maybe<ThreadFieldAggregateFields>;
  nodes: Array<ThreadField>;
};

/** aggregate fields of "server_threadfield" */
export type ThreadFieldAggregateFields = {
  __typename?: 'threadFieldAggregateFields';
  avg?: Maybe<ThreadFieldAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ThreadFieldMaxFields>;
  min?: Maybe<ThreadFieldMinFields>;
  stddev?: Maybe<ThreadFieldStddevFields>;
  stddevPop?: Maybe<ThreadFieldStddev_PopFields>;
  stddevSamp?: Maybe<ThreadFieldStddev_SampFields>;
  sum?: Maybe<ThreadFieldSumFields>;
  varPop?: Maybe<ThreadFieldVar_PopFields>;
  varSamp?: Maybe<ThreadFieldVar_SampFields>;
  variance?: Maybe<ThreadFieldVarianceFields>;
};


/** aggregate fields of "server_threadfield" */
export type ThreadFieldAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ThreadFieldSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_threadfield" */
export type ThreadFieldAggregateOrderBy = {
  avg?: InputMaybe<ThreadField_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ThreadField_Max_Order_By>;
  min?: InputMaybe<ThreadField_Min_Order_By>;
  stddev?: InputMaybe<ThreadField_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ThreadField_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ThreadField_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ThreadField_Sum_Order_By>;
  var_pop?: InputMaybe<ThreadField_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ThreadField_Var_Samp_Order_By>;
  variance?: InputMaybe<ThreadField_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_threadfield" */
export type ThreadFieldArrRelInsertInput = {
  data: Array<ThreadFieldInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ThreadFieldOnConflict>;
};

/** aggregate avg on columns */
export type ThreadFieldAvgFields = {
  __typename?: 'threadFieldAvgFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_threadfield". All fields are combined with a logical 'AND'. */
export type ThreadFieldBoolExp = {
  _and?: InputMaybe<Array<ThreadFieldBoolExp>>;
  _not?: InputMaybe<ThreadFieldBoolExp>;
  _or?: InputMaybe<Array<ThreadFieldBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  categoryId?: InputMaybe<IntComparisonExp>;
  fieldValues?: InputMaybe<ThreadFieldValueBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  threadCategory?: InputMaybe<ThreadCategoryBoolExp>;
};

/** unique or primary key constraints on table "server_threadfield" */
export enum ThreadFieldConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerThreadfieldPkey = 'server_threadfield_pkey'
}

/** input type for incrementing numeric columns in table "server_threadfield" */
export type ThreadFieldIncInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_threadfield" */
export type ThreadFieldInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  fieldValues?: InputMaybe<ThreadFieldValueArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  threadCategory?: InputMaybe<ThreadCategoryObjRelInsertInput>;
};

/** aggregate max on columns */
export type ThreadFieldMaxFields = {
  __typename?: 'threadFieldMaxFields';
  categoryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ThreadFieldMinFields = {
  __typename?: 'threadFieldMinFields';
  categoryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_threadfield" */
export type ThreadFieldMutationResponse = {
  __typename?: 'threadFieldMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ThreadField>;
};

/** input type for inserting object relation for remote table "server_threadfield" */
export type ThreadFieldObjRelInsertInput = {
  data: ThreadFieldInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ThreadFieldOnConflict>;
};

/** on_conflict condition type for table "server_threadfield" */
export type ThreadFieldOnConflict = {
  constraint: ThreadFieldConstraint;
  update_columns?: Array<ThreadFieldUpdateColumn>;
  where?: InputMaybe<ThreadFieldBoolExp>;
};

/** Ordering options when selecting data from "server_threadfield". */
export type ThreadFieldOrderBy = {
  active?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  fieldValuesAggregate?: InputMaybe<ThreadFieldValueAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  threadCategory?: InputMaybe<ThreadCategoryOrderBy>;
};

/** primary key columns input for table: server_threadfield */
export type ThreadFieldPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_threadfield" */
export enum ThreadFieldSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "server_threadfield" */
export type ThreadFieldSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ThreadFieldStddevFields = {
  __typename?: 'threadFieldStddevFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ThreadFieldStddev_PopFields = {
  __typename?: 'threadFieldStddev_popFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ThreadFieldStddev_SampFields = {
  __typename?: 'threadFieldStddev_sampFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ThreadFieldSumFields = {
  __typename?: 'threadFieldSumFields';
  categoryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_threadfield" */
export enum ThreadFieldUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type ThreadFieldUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ThreadFieldIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ThreadFieldSetInput>;
  where: ThreadFieldBoolExp;
};

/** columns and relationships of "server_threadfieldvalue" */
export type ThreadFieldValue = {
  __typename?: 'threadFieldValue';
  id: Scalars['Int'];
  threadFieldId: Scalars['Int'];
  threadId: Scalars['Int'];
  /** An object relationship */
  threadfield: ThreadField;
  /** An object relationship */
  ticketthread: TicketThread;
  value: Scalars['String'];
};

/** aggregated selection of "server_threadfieldvalue" */
export type ThreadFieldValueAggregate = {
  __typename?: 'threadFieldValueAggregate';
  aggregate?: Maybe<ThreadFieldValueAggregateFields>;
  nodes: Array<ThreadFieldValue>;
};

/** aggregate fields of "server_threadfieldvalue" */
export type ThreadFieldValueAggregateFields = {
  __typename?: 'threadFieldValueAggregateFields';
  avg?: Maybe<ThreadFieldValueAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ThreadFieldValueMaxFields>;
  min?: Maybe<ThreadFieldValueMinFields>;
  stddev?: Maybe<ThreadFieldValueStddevFields>;
  stddevPop?: Maybe<ThreadFieldValueStddev_PopFields>;
  stddevSamp?: Maybe<ThreadFieldValueStddev_SampFields>;
  sum?: Maybe<ThreadFieldValueSumFields>;
  varPop?: Maybe<ThreadFieldValueVar_PopFields>;
  varSamp?: Maybe<ThreadFieldValueVar_SampFields>;
  variance?: Maybe<ThreadFieldValueVarianceFields>;
};


/** aggregate fields of "server_threadfieldvalue" */
export type ThreadFieldValueAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ThreadFieldValueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_threadfieldvalue" */
export type ThreadFieldValueAggregateOrderBy = {
  avg?: InputMaybe<ThreadFieldValue_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ThreadFieldValue_Max_Order_By>;
  min?: InputMaybe<ThreadFieldValue_Min_Order_By>;
  stddev?: InputMaybe<ThreadFieldValue_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ThreadFieldValue_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ThreadFieldValue_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ThreadFieldValue_Sum_Order_By>;
  var_pop?: InputMaybe<ThreadFieldValue_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ThreadFieldValue_Var_Samp_Order_By>;
  variance?: InputMaybe<ThreadFieldValue_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_threadfieldvalue" */
export type ThreadFieldValueArrRelInsertInput = {
  data: Array<ThreadFieldValueInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ThreadFieldValueOnConflict>;
};

/** aggregate avg on columns */
export type ThreadFieldValueAvgFields = {
  __typename?: 'threadFieldValueAvgFields';
  id?: Maybe<Scalars['Float']>;
  threadFieldId?: Maybe<Scalars['Float']>;
  threadId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_threadfieldvalue". All fields are combined with a logical 'AND'. */
export type ThreadFieldValueBoolExp = {
  _and?: InputMaybe<Array<ThreadFieldValueBoolExp>>;
  _not?: InputMaybe<ThreadFieldValueBoolExp>;
  _or?: InputMaybe<Array<ThreadFieldValueBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  threadFieldId?: InputMaybe<IntComparisonExp>;
  threadId?: InputMaybe<IntComparisonExp>;
  threadfield?: InputMaybe<ThreadFieldBoolExp>;
  ticketthread?: InputMaybe<TicketThreadBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_threadfieldvalue" */
export enum ThreadFieldValueConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerThreadfieldvaluePkey = 'server_threadfieldvalue_pkey'
}

/** input type for incrementing numeric columns in table "server_threadfieldvalue" */
export type ThreadFieldValueIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  threadFieldId?: InputMaybe<Scalars['Int']>;
  threadId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_threadfieldvalue" */
export type ThreadFieldValueInsertInput = {
  id?: InputMaybe<Scalars['Int']>;
  threadFieldId?: InputMaybe<Scalars['Int']>;
  threadId?: InputMaybe<Scalars['Int']>;
  threadfield?: InputMaybe<ThreadFieldObjRelInsertInput>;
  ticketthread?: InputMaybe<TicketThreadObjRelInsertInput>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ThreadFieldValueMaxFields = {
  __typename?: 'threadFieldValueMaxFields';
  id?: Maybe<Scalars['Int']>;
  threadFieldId?: Maybe<Scalars['Int']>;
  threadId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ThreadFieldValueMinFields = {
  __typename?: 'threadFieldValueMinFields';
  id?: Maybe<Scalars['Int']>;
  threadFieldId?: Maybe<Scalars['Int']>;
  threadId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_threadfieldvalue" */
export type ThreadFieldValueMutationResponse = {
  __typename?: 'threadFieldValueMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ThreadFieldValue>;
};

/** on_conflict condition type for table "server_threadfieldvalue" */
export type ThreadFieldValueOnConflict = {
  constraint: ThreadFieldValueConstraint;
  update_columns?: Array<ThreadFieldValueUpdateColumn>;
  where?: InputMaybe<ThreadFieldValueBoolExp>;
};

/** Ordering options when selecting data from "server_threadfieldvalue". */
export type ThreadFieldValueOrderBy = {
  id?: InputMaybe<OrderBy>;
  threadFieldId?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
  threadfield?: InputMaybe<ThreadFieldOrderBy>;
  ticketthread?: InputMaybe<TicketThreadOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_threadfieldvalue */
export type ThreadFieldValuePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_threadfieldvalue" */
export enum ThreadFieldValueSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ThreadFieldId = 'threadFieldId',
  /** column name */
  ThreadId = 'threadId',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "server_threadfieldvalue" */
export type ThreadFieldValueSetInput = {
  id?: InputMaybe<Scalars['Int']>;
  threadFieldId?: InputMaybe<Scalars['Int']>;
  threadId?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ThreadFieldValueStddevFields = {
  __typename?: 'threadFieldValueStddevFields';
  id?: Maybe<Scalars['Float']>;
  threadFieldId?: Maybe<Scalars['Float']>;
  threadId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ThreadFieldValueStddev_PopFields = {
  __typename?: 'threadFieldValueStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  threadFieldId?: Maybe<Scalars['Float']>;
  threadId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ThreadFieldValueStddev_SampFields = {
  __typename?: 'threadFieldValueStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  threadFieldId?: Maybe<Scalars['Float']>;
  threadId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ThreadFieldValueSumFields = {
  __typename?: 'threadFieldValueSumFields';
  id?: Maybe<Scalars['Int']>;
  threadFieldId?: Maybe<Scalars['Int']>;
  threadId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_threadfieldvalue" */
export enum ThreadFieldValueUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ThreadFieldId = 'threadFieldId',
  /** column name */
  ThreadId = 'threadId',
  /** column name */
  Value = 'value'
}

export type ThreadFieldValueUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ThreadFieldValueIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ThreadFieldValueSetInput>;
  where: ThreadFieldValueBoolExp;
};

/** aggregate var_pop on columns */
export type ThreadFieldValueVar_PopFields = {
  __typename?: 'threadFieldValueVar_popFields';
  id?: Maybe<Scalars['Float']>;
  threadFieldId?: Maybe<Scalars['Float']>;
  threadId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ThreadFieldValueVar_SampFields = {
  __typename?: 'threadFieldValueVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  threadFieldId?: Maybe<Scalars['Float']>;
  threadId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ThreadFieldValueVarianceFields = {
  __typename?: 'threadFieldValueVarianceFields';
  id?: Maybe<Scalars['Float']>;
  threadFieldId?: Maybe<Scalars['Float']>;
  threadId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_threadfieldvalue" */
export type ThreadFieldValue_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  threadFieldId?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_threadfieldvalue" */
export type ThreadFieldValue_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  threadFieldId?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_threadfieldvalue" */
export type ThreadFieldValue_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  threadFieldId?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_threadfieldvalue" */
export type ThreadFieldValue_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  threadFieldId?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_threadfieldvalue" */
export type ThreadFieldValue_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  threadFieldId?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_threadfieldvalue" */
export type ThreadFieldValue_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  threadFieldId?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "threadFieldValue" */
export type ThreadFieldValue_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ThreadFieldValue_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ThreadFieldValue_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  threadFieldId?: InputMaybe<Scalars['Int']>;
  threadId?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_threadfieldvalue" */
export type ThreadFieldValue_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  threadFieldId?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_threadfieldvalue" */
export type ThreadFieldValue_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  threadFieldId?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_threadfieldvalue" */
export type ThreadFieldValue_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  threadFieldId?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_threadfieldvalue" */
export type ThreadFieldValue_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  threadFieldId?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ThreadFieldVar_PopFields = {
  __typename?: 'threadFieldVar_popFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ThreadFieldVar_SampFields = {
  __typename?: 'threadFieldVar_sampFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ThreadFieldVarianceFields = {
  __typename?: 'threadFieldVarianceFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_threadfield" */
export type ThreadField_Avg_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_threadfield" */
export type ThreadField_Max_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_threadfield" */
export type ThreadField_Min_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_threadfield" */
export type ThreadField_Stddev_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_threadfield" */
export type ThreadField_Stddev_Pop_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_threadfield" */
export type ThreadField_Stddev_Samp_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "threadField" */
export type ThreadField_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ThreadField_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ThreadField_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "server_threadfield" */
export type ThreadField_Sum_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_threadfield" */
export type ThreadField_Var_Pop_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_threadfield" */
export type ThreadField_Var_Samp_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_threadfield" */
export type ThreadField_Variance_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_ticketsubscription" */
export type TicketSubscription = {
  __typename?: 'ticketSubscription';
  createdById: Scalars['Int'];
  /** An object relationship */
  createdUser: User;
  dateSubscribed: Scalars['timestamptz'];
  dateUnsubscribed?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  subscribedUser: User;
  ticketId: Scalars['Int'];
  /** An object relationship */
  ticketthread: TicketThread;
  userId: Scalars['Int'];
};

/** aggregated selection of "server_ticketsubscription" */
export type TicketSubscriptionAggregate = {
  __typename?: 'ticketSubscriptionAggregate';
  aggregate?: Maybe<TicketSubscriptionAggregateFields>;
  nodes: Array<TicketSubscription>;
};

/** aggregate fields of "server_ticketsubscription" */
export type TicketSubscriptionAggregateFields = {
  __typename?: 'ticketSubscriptionAggregateFields';
  avg?: Maybe<TicketSubscriptionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TicketSubscriptionMaxFields>;
  min?: Maybe<TicketSubscriptionMinFields>;
  stddev?: Maybe<TicketSubscriptionStddevFields>;
  stddevPop?: Maybe<TicketSubscriptionStddev_PopFields>;
  stddevSamp?: Maybe<TicketSubscriptionStddev_SampFields>;
  sum?: Maybe<TicketSubscriptionSumFields>;
  varPop?: Maybe<TicketSubscriptionVar_PopFields>;
  varSamp?: Maybe<TicketSubscriptionVar_SampFields>;
  variance?: Maybe<TicketSubscriptionVarianceFields>;
};


/** aggregate fields of "server_ticketsubscription" */
export type TicketSubscriptionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TicketSubscriptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_ticketsubscription" */
export type TicketSubscriptionAggregateOrderBy = {
  avg?: InputMaybe<TicketSubscription_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TicketSubscription_Max_Order_By>;
  min?: InputMaybe<TicketSubscription_Min_Order_By>;
  stddev?: InputMaybe<TicketSubscription_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TicketSubscription_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TicketSubscription_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TicketSubscription_Sum_Order_By>;
  var_pop?: InputMaybe<TicketSubscription_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TicketSubscription_Var_Samp_Order_By>;
  variance?: InputMaybe<TicketSubscription_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_ticketsubscription" */
export type TicketSubscriptionArrRelInsertInput = {
  data: Array<TicketSubscriptionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TicketSubscriptionOnConflict>;
};

/** aggregate avg on columns */
export type TicketSubscriptionAvgFields = {
  __typename?: 'ticketSubscriptionAvgFields';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_ticketsubscription". All fields are combined with a logical 'AND'. */
export type TicketSubscriptionBoolExp = {
  _and?: InputMaybe<Array<TicketSubscriptionBoolExp>>;
  _not?: InputMaybe<TicketSubscriptionBoolExp>;
  _or?: InputMaybe<Array<TicketSubscriptionBoolExp>>;
  createdById?: InputMaybe<IntComparisonExp>;
  createdUser?: InputMaybe<UserBoolExp>;
  dateSubscribed?: InputMaybe<TimestamptzComparisonExp>;
  dateUnsubscribed?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  subscribedUser?: InputMaybe<UserBoolExp>;
  ticketId?: InputMaybe<IntComparisonExp>;
  ticketthread?: InputMaybe<TicketThreadBoolExp>;
  userId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_ticketsubscription" */
export enum TicketSubscriptionConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerTicketsubscriptionPkey = 'server_ticketsubscription_pkey'
}

/** input type for incrementing numeric columns in table "server_ticketsubscription" */
export type TicketSubscriptionIncInput = {
  createdById?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_ticketsubscription" */
export type TicketSubscriptionInsertInput = {
  createdById?: InputMaybe<Scalars['Int']>;
  createdUser?: InputMaybe<UserObjRelInsertInput>;
  dateSubscribed?: InputMaybe<Scalars['timestamptz']>;
  dateUnsubscribed?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  subscribedUser?: InputMaybe<UserObjRelInsertInput>;
  ticketId?: InputMaybe<Scalars['Int']>;
  ticketthread?: InputMaybe<TicketThreadObjRelInsertInput>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TicketSubscriptionMaxFields = {
  __typename?: 'ticketSubscriptionMaxFields';
  createdById?: Maybe<Scalars['Int']>;
  dateSubscribed?: Maybe<Scalars['timestamptz']>;
  dateUnsubscribed?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TicketSubscriptionMinFields = {
  __typename?: 'ticketSubscriptionMinFields';
  createdById?: Maybe<Scalars['Int']>;
  dateSubscribed?: Maybe<Scalars['timestamptz']>;
  dateUnsubscribed?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_ticketsubscription" */
export type TicketSubscriptionMutationResponse = {
  __typename?: 'ticketSubscriptionMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TicketSubscription>;
};

/** on_conflict condition type for table "server_ticketsubscription" */
export type TicketSubscriptionOnConflict = {
  constraint: TicketSubscriptionConstraint;
  update_columns?: Array<TicketSubscriptionUpdateColumn>;
  where?: InputMaybe<TicketSubscriptionBoolExp>;
};

/** Ordering options when selecting data from "server_ticketsubscription". */
export type TicketSubscriptionOrderBy = {
  createdById?: InputMaybe<OrderBy>;
  createdUser?: InputMaybe<UserOrderBy>;
  dateSubscribed?: InputMaybe<OrderBy>;
  dateUnsubscribed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  subscribedUser?: InputMaybe<UserOrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  ticketthread?: InputMaybe<TicketThreadOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_ticketsubscription */
export type TicketSubscriptionPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_ticketsubscription" */
export enum TicketSubscriptionSelectColumn {
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  DateSubscribed = 'dateSubscribed',
  /** column name */
  DateUnsubscribed = 'dateUnsubscribed',
  /** column name */
  Id = 'id',
  /** column name */
  TicketId = 'ticketId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "server_ticketsubscription" */
export type TicketSubscriptionSetInput = {
  createdById?: InputMaybe<Scalars['Int']>;
  dateSubscribed?: InputMaybe<Scalars['timestamptz']>;
  dateUnsubscribed?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TicketSubscriptionStddevFields = {
  __typename?: 'ticketSubscriptionStddevFields';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TicketSubscriptionStddev_PopFields = {
  __typename?: 'ticketSubscriptionStddev_popFields';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TicketSubscriptionStddev_SampFields = {
  __typename?: 'ticketSubscriptionStddev_sampFields';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TicketSubscriptionSumFields = {
  __typename?: 'ticketSubscriptionSumFields';
  createdById?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_ticketsubscription" */
export enum TicketSubscriptionUpdateColumn {
  /** column name */
  CreatedById = 'createdById',
  /** column name */
  DateSubscribed = 'dateSubscribed',
  /** column name */
  DateUnsubscribed = 'dateUnsubscribed',
  /** column name */
  Id = 'id',
  /** column name */
  TicketId = 'ticketId',
  /** column name */
  UserId = 'userId'
}

export type TicketSubscriptionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TicketSubscriptionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TicketSubscriptionSetInput>;
  where: TicketSubscriptionBoolExp;
};

/** aggregate var_pop on columns */
export type TicketSubscriptionVar_PopFields = {
  __typename?: 'ticketSubscriptionVar_popFields';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TicketSubscriptionVar_SampFields = {
  __typename?: 'ticketSubscriptionVar_sampFields';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TicketSubscriptionVarianceFields = {
  __typename?: 'ticketSubscriptionVarianceFields';
  createdById?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_ticketsubscription" */
export type TicketSubscription_Avg_Order_By = {
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_ticketsubscription" */
export type TicketSubscription_Max_Order_By = {
  createdById?: InputMaybe<OrderBy>;
  dateSubscribed?: InputMaybe<OrderBy>;
  dateUnsubscribed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_ticketsubscription" */
export type TicketSubscription_Min_Order_By = {
  createdById?: InputMaybe<OrderBy>;
  dateSubscribed?: InputMaybe<OrderBy>;
  dateUnsubscribed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_ticketsubscription" */
export type TicketSubscription_Stddev_Order_By = {
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_ticketsubscription" */
export type TicketSubscription_Stddev_Pop_Order_By = {
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_ticketsubscription" */
export type TicketSubscription_Stddev_Samp_Order_By = {
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "ticketSubscription" */
export type TicketSubscription_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TicketSubscription_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TicketSubscription_StreamCursorValueInput = {
  createdById?: InputMaybe<Scalars['Int']>;
  dateSubscribed?: InputMaybe<Scalars['timestamptz']>;
  dateUnsubscribed?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  ticketId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_ticketsubscription" */
export type TicketSubscription_Sum_Order_By = {
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_ticketsubscription" */
export type TicketSubscription_Var_Pop_Order_By = {
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_ticketsubscription" */
export type TicketSubscription_Var_Samp_Order_By = {
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_ticketsubscription" */
export type TicketSubscription_Variance_Order_By = {
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_ticketthread" */
export type TicketThread = {
  __typename?: 'ticketThread';
  /** An array relationship */
  baseorders: Array<Baseorder>;
  /** An aggregate relationship */
  baseordersAggregate: BaseorderAggregate;
  /** An array relationship */
  caretimes: Array<Caretime>;
  /** An aggregate relationship */
  caretimesAggregate: CaretimeAggregate;
  /** An object relationship */
  category?: Maybe<ThreadCategory>;
  categoryId?: Maybe<Scalars['Int']>;
  dateCreated: Scalars['timestamptz'];
  /** An array relationship */
  documents: Array<Document>;
  /** An aggregate relationship */
  documentsAggregate: DocumentAggregate;
  emailSubmitted?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  jsonData?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  legacyOrders: Array<LegacyOrder>;
  /** An aggregate relationship */
  legacyOrdersAggregate: LegacyOrderAggregate;
  /** An array relationship */
  messages: Array<Message>;
  /** An aggregate relationship */
  messagesAggregate: MessageAggregate;
  /** An object relationship */
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  practicegroup: Practicegroup;
  practicegroupId: Scalars['Int'];
  priority: Scalars['smallint'];
  /** An object relationship */
  status?: Maybe<ThreadCategoryStatus>;
  statusId?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
  /** An array relationship */
  threadFieldValues: Array<ThreadFieldValue>;
  /** An aggregate relationship */
  threadFieldValuesAggregate: ThreadFieldValueAggregate;
  title: Scalars['String'];
  updatedTimestamp: Scalars['timestamptz'];
  /** An object relationship */
  userCreated?: Maybe<User>;
  userCreatedId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  userSubscriptions: Array<TicketSubscription>;
  /** An aggregate relationship */
  userSubscriptionsAggregate: TicketSubscriptionAggregate;
  /** A computed field, executes function "user_can_access_ticketthread" */
  user_can_access?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadBaseordersArgs = {
  distinctOn?: InputMaybe<Array<BaseorderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseorderOrderBy>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadBaseordersAggregateArgs = {
  distinctOn?: InputMaybe<Array<BaseorderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BaseorderOrderBy>>;
  where?: InputMaybe<BaseorderBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadCaretimesArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadCaretimesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadDocumentsArgs = {
  distinctOn?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadJsonDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadLegacyOrdersArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadLegacyOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadMessagesArgs = {
  distinctOn?: InputMaybe<Array<MessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderBy>>;
  where?: InputMaybe<MessageBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadMessagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderBy>>;
  where?: InputMaybe<MessageBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadThreadFieldValuesArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldValueOrderBy>>;
  where?: InputMaybe<ThreadFieldValueBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadThreadFieldValuesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ThreadFieldValueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ThreadFieldValueOrderBy>>;
  where?: InputMaybe<ThreadFieldValueBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadUserSubscriptionsArgs = {
  distinctOn?: InputMaybe<Array<TicketSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketSubscriptionOrderBy>>;
  where?: InputMaybe<TicketSubscriptionBoolExp>;
};


/** columns and relationships of "server_ticketthread" */
export type TicketThreadUserSubscriptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketSubscriptionOrderBy>>;
  where?: InputMaybe<TicketSubscriptionBoolExp>;
};

/** aggregated selection of "server_ticketthread" */
export type TicketThreadAggregate = {
  __typename?: 'ticketThreadAggregate';
  aggregate?: Maybe<TicketThreadAggregateFields>;
  nodes: Array<TicketThread>;
};

/** aggregate fields of "server_ticketthread" */
export type TicketThreadAggregateFields = {
  __typename?: 'ticketThreadAggregateFields';
  avg?: Maybe<TicketThreadAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TicketThreadMaxFields>;
  min?: Maybe<TicketThreadMinFields>;
  stddev?: Maybe<TicketThreadStddevFields>;
  stddevPop?: Maybe<TicketThreadStddev_PopFields>;
  stddevSamp?: Maybe<TicketThreadStddev_SampFields>;
  sum?: Maybe<TicketThreadSumFields>;
  varPop?: Maybe<TicketThreadVar_PopFields>;
  varSamp?: Maybe<TicketThreadVar_SampFields>;
  variance?: Maybe<TicketThreadVarianceFields>;
};


/** aggregate fields of "server_ticketthread" */
export type TicketThreadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TicketThreadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_ticketthread" */
export type TicketThreadAggregateOrderBy = {
  avg?: InputMaybe<TicketThread_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TicketThread_Max_Order_By>;
  min?: InputMaybe<TicketThread_Min_Order_By>;
  stddev?: InputMaybe<TicketThread_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TicketThread_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TicketThread_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TicketThread_Sum_Order_By>;
  var_pop?: InputMaybe<TicketThread_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TicketThread_Var_Samp_Order_By>;
  variance?: InputMaybe<TicketThread_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TicketThreadAppendInput = {
  jsonData?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "server_ticketthread" */
export type TicketThreadArrRelInsertInput = {
  data: Array<TicketThreadInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TicketThreadOnConflict>;
};

/** aggregate avg on columns */
export type TicketThreadAvgFields = {
  __typename?: 'ticketThreadAvgFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
  userCreatedId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_ticketthread". All fields are combined with a logical 'AND'. */
export type TicketThreadBoolExp = {
  _and?: InputMaybe<Array<TicketThreadBoolExp>>;
  _not?: InputMaybe<TicketThreadBoolExp>;
  _or?: InputMaybe<Array<TicketThreadBoolExp>>;
  baseorders?: InputMaybe<BaseorderBoolExp>;
  caretimes?: InputMaybe<CaretimeBoolExp>;
  category?: InputMaybe<ThreadCategoryBoolExp>;
  categoryId?: InputMaybe<IntComparisonExp>;
  dateCreated?: InputMaybe<TimestamptzComparisonExp>;
  documents?: InputMaybe<DocumentBoolExp>;
  emailSubmitted?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  jsonData?: InputMaybe<JsonbComparisonExp>;
  legacyOrders?: InputMaybe<LegacyOrderBoolExp>;
  messages?: InputMaybe<MessageBoolExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  practicegroup?: InputMaybe<PracticegroupBoolExp>;
  practicegroupId?: InputMaybe<IntComparisonExp>;
  priority?: InputMaybe<SmallintComparisonExp>;
  status?: InputMaybe<ThreadCategoryStatusBoolExp>;
  statusId?: InputMaybe<IntComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  threadFieldValues?: InputMaybe<ThreadFieldValueBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  userCreated?: InputMaybe<UserBoolExp>;
  userCreatedId?: InputMaybe<IntComparisonExp>;
  userSubscriptions?: InputMaybe<TicketSubscriptionBoolExp>;
  user_can_access?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "server_ticketthread" */
export enum TicketThreadConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerTicketthreadPkey = 'server_ticketthread_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TicketThreadDeleteAtPathInput = {
  jsonData?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TicketThreadDeleteElemInput = {
  jsonData?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TicketThreadDeleteKeyInput = {
  jsonData?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "server_ticketthread" */
export type TicketThreadIncInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
  practicegroupId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['smallint']>;
  statusId?: InputMaybe<Scalars['Int']>;
  userCreatedId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_ticketthread" */
export type TicketThreadInsertInput = {
  baseorders?: InputMaybe<BaseorderArrRelInsertInput>;
  caretimes?: InputMaybe<CaretimeArrRelInsertInput>;
  category?: InputMaybe<ThreadCategoryObjRelInsertInput>;
  categoryId?: InputMaybe<Scalars['Int']>;
  dateCreated?: InputMaybe<Scalars['timestamptz']>;
  documents?: InputMaybe<DocumentArrRelInsertInput>;
  emailSubmitted?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  jsonData?: InputMaybe<Scalars['jsonb']>;
  legacyOrders?: InputMaybe<LegacyOrderArrRelInsertInput>;
  messages?: InputMaybe<MessageArrRelInsertInput>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  practicegroup?: InputMaybe<PracticegroupObjRelInsertInput>;
  practicegroupId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['smallint']>;
  status?: InputMaybe<ThreadCategoryStatusObjRelInsertInput>;
  statusId?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  threadFieldValues?: InputMaybe<ThreadFieldValueArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']>;
  updatedTimestamp?: InputMaybe<Scalars['timestamptz']>;
  userCreated?: InputMaybe<UserObjRelInsertInput>;
  userCreatedId?: InputMaybe<Scalars['Int']>;
  userSubscriptions?: InputMaybe<TicketSubscriptionArrRelInsertInput>;
};

/** aggregate max on columns */
export type TicketThreadMaxFields = {
  __typename?: 'ticketThreadMaxFields';
  categoryId?: Maybe<Scalars['Int']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  emailSubmitted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  practicegroupId?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['smallint']>;
  statusId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedTimestamp?: Maybe<Scalars['timestamptz']>;
  userCreatedId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TicketThreadMinFields = {
  __typename?: 'ticketThreadMinFields';
  categoryId?: Maybe<Scalars['Int']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  emailSubmitted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  practicegroupId?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['smallint']>;
  statusId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedTimestamp?: Maybe<Scalars['timestamptz']>;
  userCreatedId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_ticketthread" */
export type TicketThreadMutationResponse = {
  __typename?: 'ticketThreadMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TicketThread>;
};

/** input type for inserting object relation for remote table "server_ticketthread" */
export type TicketThreadObjRelInsertInput = {
  data: TicketThreadInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TicketThreadOnConflict>;
};

/** on_conflict condition type for table "server_ticketthread" */
export type TicketThreadOnConflict = {
  constraint: TicketThreadConstraint;
  update_columns?: Array<TicketThreadUpdateColumn>;
  where?: InputMaybe<TicketThreadBoolExp>;
};

/** Ordering options when selecting data from "server_ticketthread". */
export type TicketThreadOrderBy = {
  baseordersAggregate?: InputMaybe<BaseorderAggregateOrderBy>;
  caretimesAggregate?: InputMaybe<CaretimeAggregateOrderBy>;
  category?: InputMaybe<ThreadCategoryOrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  dateCreated?: InputMaybe<OrderBy>;
  documentsAggregate?: InputMaybe<DocumentAggregateOrderBy>;
  emailSubmitted?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  jsonData?: InputMaybe<OrderBy>;
  legacyOrdersAggregate?: InputMaybe<LegacyOrderAggregateOrderBy>;
  messagesAggregate?: InputMaybe<MessageAggregateOrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practicegroup?: InputMaybe<PracticegroupOrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  status?: InputMaybe<ThreadCategoryStatusOrderBy>;
  statusId?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  threadFieldValuesAggregate?: InputMaybe<ThreadFieldValueAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedTimestamp?: InputMaybe<OrderBy>;
  userCreated?: InputMaybe<UserOrderBy>;
  userCreatedId?: InputMaybe<OrderBy>;
  userSubscriptionsAggregate?: InputMaybe<TicketSubscriptionAggregateOrderBy>;
  user_can_access?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_ticketthread */
export type TicketThreadPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TicketThreadPrependInput = {
  jsonData?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "server_ticketthread" */
export enum TicketThreadSelectColumn {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  EmailSubmitted = 'emailSubmitted',
  /** column name */
  Id = 'id',
  /** column name */
  JsonData = 'jsonData',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PracticegroupId = 'practicegroupId',
  /** column name */
  Priority = 'priority',
  /** column name */
  StatusId = 'statusId',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedTimestamp = 'updatedTimestamp',
  /** column name */
  UserCreatedId = 'userCreatedId'
}

/** input type for updating data in table "server_ticketthread" */
export type TicketThreadSetInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  dateCreated?: InputMaybe<Scalars['timestamptz']>;
  emailSubmitted?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  jsonData?: InputMaybe<Scalars['jsonb']>;
  patientId?: InputMaybe<Scalars['Int']>;
  practicegroupId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['smallint']>;
  statusId?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedTimestamp?: InputMaybe<Scalars['timestamptz']>;
  userCreatedId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TicketThreadStddevFields = {
  __typename?: 'ticketThreadStddevFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
  userCreatedId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TicketThreadStddev_PopFields = {
  __typename?: 'ticketThreadStddev_popFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
  userCreatedId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TicketThreadStddev_SampFields = {
  __typename?: 'ticketThreadStddev_sampFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
  userCreatedId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TicketThreadSumFields = {
  __typename?: 'ticketThreadSumFields';
  categoryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  practicegroupId?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['smallint']>;
  statusId?: Maybe<Scalars['Int']>;
  userCreatedId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_ticketthread" */
export enum TicketThreadUpdateColumn {
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  EmailSubmitted = 'emailSubmitted',
  /** column name */
  Id = 'id',
  /** column name */
  JsonData = 'jsonData',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  PracticegroupId = 'practicegroupId',
  /** column name */
  Priority = 'priority',
  /** column name */
  StatusId = 'statusId',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedTimestamp = 'updatedTimestamp',
  /** column name */
  UserCreatedId = 'userCreatedId'
}

export type TicketThreadUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TicketThreadAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<TicketThreadDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<TicketThreadDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<TicketThreadDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TicketThreadIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TicketThreadPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TicketThreadSetInput>;
  where: TicketThreadBoolExp;
};

/** aggregate var_pop on columns */
export type TicketThreadVar_PopFields = {
  __typename?: 'ticketThreadVar_popFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
  userCreatedId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TicketThreadVar_SampFields = {
  __typename?: 'ticketThreadVar_sampFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
  userCreatedId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TicketThreadVarianceFields = {
  __typename?: 'ticketThreadVarianceFields';
  categoryId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
  practicegroupId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
  userCreatedId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_ticketthread" */
export type TicketThread_Avg_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  userCreatedId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_ticketthread" */
export type TicketThread_Max_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  dateCreated?: InputMaybe<OrderBy>;
  emailSubmitted?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedTimestamp?: InputMaybe<OrderBy>;
  userCreatedId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_ticketthread" */
export type TicketThread_Min_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  dateCreated?: InputMaybe<OrderBy>;
  emailSubmitted?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedTimestamp?: InputMaybe<OrderBy>;
  userCreatedId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_ticketthread" */
export type TicketThread_Stddev_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  userCreatedId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_ticketthread" */
export type TicketThread_Stddev_Pop_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  userCreatedId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_ticketthread" */
export type TicketThread_Stddev_Samp_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  userCreatedId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "ticketThread" */
export type TicketThread_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TicketThread_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TicketThread_StreamCursorValueInput = {
  categoryId?: InputMaybe<Scalars['Int']>;
  dateCreated?: InputMaybe<Scalars['timestamptz']>;
  emailSubmitted?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  jsonData?: InputMaybe<Scalars['jsonb']>;
  patientId?: InputMaybe<Scalars['Int']>;
  practicegroupId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['smallint']>;
  statusId?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedTimestamp?: InputMaybe<Scalars['timestamptz']>;
  userCreatedId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_ticketthread" */
export type TicketThread_Sum_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  userCreatedId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_ticketthread" */
export type TicketThread_Var_Pop_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  userCreatedId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_ticketthread" */
export type TicketThread_Var_Samp_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  userCreatedId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_ticketthread" */
export type TicketThread_Variance_Order_By = {
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
  practicegroupId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  statusId?: InputMaybe<OrderBy>;
  userCreatedId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_treatmentplan" */
export type Treatmentplan = {
  __typename?: 'treatmentplan';
  active: Scalars['Boolean'];
  /** An array relationship */
  assessments: Array<TreatmentplanAssessment>;
  /** An aggregate relationship */
  assessmentsAggregate: TreatmentplanAssessmentAggregate;
  associatedTemplateId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  associatedTreatmentPlanTemplate?: Maybe<TreatmentplanTemplate>;
  dateEnd?: Maybe<Scalars['date']>;
  dateStart: Scalars['date'];
  id: Scalars['Int'];
  name: Scalars['String'];
  notes: Scalars['String'];
  /** An array relationship */
  orders: Array<LegacyTreatmentplanOrder>;
  /** An aggregate relationship */
  ordersAggregate: LegacyTreatmentplanOrderAggregate;
  /** An object relationship */
  patient: Patient;
  patientId: Scalars['Int'];
  /** An array relationship */
  proceduresRendered: Array<ProcedureRendered>;
  /** An aggregate relationship */
  proceduresRenderedAggregate: ProcedureRenderedAggregate;
  /** An array relationship */
  treatmentPlanProblems: Array<TreatmentplanProblems>;
  /** An aggregate relationship */
  treatmentPlanProblemsAggregate: TreatmentplanProblemsAggregate;
  /** An array relationship */
  treatmentPlanProgressNotes: Array<TreatmentplanNote>;
  /** An aggregate relationship */
  treatmentPlanProgressNotesAggregate: TreatmentplanNoteAggregate;
};


/** columns and relationships of "server_treatmentplan" */
export type TreatmentplanAssessmentsArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanAssessmentBoolExp>;
};


/** columns and relationships of "server_treatmentplan" */
export type TreatmentplanAssessmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanAssessmentBoolExp>;
};


/** columns and relationships of "server_treatmentplan" */
export type TreatmentplanOrdersArgs = {
  distinctOn?: InputMaybe<Array<LegacyTreatmentplanOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyTreatmentplanOrderOrderBy>>;
  where?: InputMaybe<LegacyTreatmentplanOrderBoolExp>;
};


/** columns and relationships of "server_treatmentplan" */
export type TreatmentplanOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyTreatmentplanOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyTreatmentplanOrderOrderBy>>;
  where?: InputMaybe<LegacyTreatmentplanOrderBoolExp>;
};


/** columns and relationships of "server_treatmentplan" */
export type TreatmentplanProceduresRenderedArgs = {
  distinctOn?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureRenderedOrderBy>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};


/** columns and relationships of "server_treatmentplan" */
export type TreatmentplanProceduresRenderedAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureRenderedOrderBy>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};


/** columns and relationships of "server_treatmentplan" */
export type TreatmentplanTreatmentPlanProblemsArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanProblemsOrderBy>>;
  where?: InputMaybe<TreatmentplanProblemsBoolExp>;
};


/** columns and relationships of "server_treatmentplan" */
export type TreatmentplanTreatmentPlanProblemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanProblemsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanProblemsOrderBy>>;
  where?: InputMaybe<TreatmentplanProblemsBoolExp>;
};


/** columns and relationships of "server_treatmentplan" */
export type TreatmentplanTreatmentPlanProgressNotesArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanNoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanNoteOrderBy>>;
  where?: InputMaybe<TreatmentplanNoteBoolExp>;
};


/** columns and relationships of "server_treatmentplan" */
export type TreatmentplanTreatmentPlanProgressNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanNoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanNoteOrderBy>>;
  where?: InputMaybe<TreatmentplanNoteBoolExp>;
};

/** aggregated selection of "server_treatmentplan" */
export type TreatmentplanAggregate = {
  __typename?: 'treatmentplanAggregate';
  aggregate?: Maybe<TreatmentplanAggregateFields>;
  nodes: Array<Treatmentplan>;
};

/** aggregate fields of "server_treatmentplan" */
export type TreatmentplanAggregateFields = {
  __typename?: 'treatmentplanAggregateFields';
  avg?: Maybe<TreatmentplanAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TreatmentplanMaxFields>;
  min?: Maybe<TreatmentplanMinFields>;
  stddev?: Maybe<TreatmentplanStddevFields>;
  stddevPop?: Maybe<TreatmentplanStddev_PopFields>;
  stddevSamp?: Maybe<TreatmentplanStddev_SampFields>;
  sum?: Maybe<TreatmentplanSumFields>;
  varPop?: Maybe<TreatmentplanVar_PopFields>;
  varSamp?: Maybe<TreatmentplanVar_SampFields>;
  variance?: Maybe<TreatmentplanVarianceFields>;
};


/** aggregate fields of "server_treatmentplan" */
export type TreatmentplanAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentplanSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_treatmentplan" */
export type TreatmentplanAggregateOrderBy = {
  avg?: InputMaybe<Treatmentplan_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Treatmentplan_Max_Order_By>;
  min?: InputMaybe<Treatmentplan_Min_Order_By>;
  stddev?: InputMaybe<Treatmentplan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Treatmentplan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Treatmentplan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Treatmentplan_Sum_Order_By>;
  var_pop?: InputMaybe<Treatmentplan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Treatmentplan_Var_Samp_Order_By>;
  variance?: InputMaybe<Treatmentplan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_treatmentplan" */
export type TreatmentplanArrRelInsertInput = {
  data: Array<TreatmentplanInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TreatmentplanOnConflict>;
};

/** columns and relationships of "server_treatmentplanassessment" */
export type TreatmentplanAssessment = {
  __typename?: 'treatmentplanAssessment';
  /** An object relationship */
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  renderedAssessment: RenderedAssessment;
  renderedAssessmentId: Scalars['Int'];
  treatmentPlanId: Scalars['Int'];
  /** An object relationship */
  treatmentplan: Treatmentplan;
};

/** aggregated selection of "server_treatmentplanassessment" */
export type TreatmentplanAssessmentAggregate = {
  __typename?: 'treatmentplanAssessmentAggregate';
  aggregate?: Maybe<TreatmentplanAssessmentAggregateFields>;
  nodes: Array<TreatmentplanAssessment>;
};

/** aggregate fields of "server_treatmentplanassessment" */
export type TreatmentplanAssessmentAggregateFields = {
  __typename?: 'treatmentplanAssessmentAggregateFields';
  avg?: Maybe<TreatmentplanAssessmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TreatmentplanAssessmentMaxFields>;
  min?: Maybe<TreatmentplanAssessmentMinFields>;
  stddev?: Maybe<TreatmentplanAssessmentStddevFields>;
  stddevPop?: Maybe<TreatmentplanAssessmentStddev_PopFields>;
  stddevSamp?: Maybe<TreatmentplanAssessmentStddev_SampFields>;
  sum?: Maybe<TreatmentplanAssessmentSumFields>;
  varPop?: Maybe<TreatmentplanAssessmentVar_PopFields>;
  varSamp?: Maybe<TreatmentplanAssessmentVar_SampFields>;
  variance?: Maybe<TreatmentplanAssessmentVarianceFields>;
};


/** aggregate fields of "server_treatmentplanassessment" */
export type TreatmentplanAssessmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentplanAssessmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_treatmentplanassessment" */
export type TreatmentplanAssessmentAggregateOrderBy = {
  avg?: InputMaybe<TreatmentplanAssessment_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TreatmentplanAssessment_Max_Order_By>;
  min?: InputMaybe<TreatmentplanAssessment_Min_Order_By>;
  stddev?: InputMaybe<TreatmentplanAssessment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TreatmentplanAssessment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TreatmentplanAssessment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TreatmentplanAssessment_Sum_Order_By>;
  var_pop?: InputMaybe<TreatmentplanAssessment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TreatmentplanAssessment_Var_Samp_Order_By>;
  variance?: InputMaybe<TreatmentplanAssessment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_treatmentplanassessment" */
export type TreatmentplanAssessmentArrRelInsertInput = {
  data: Array<TreatmentplanAssessmentInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TreatmentplanAssessmentOnConflict>;
};

/** aggregate avg on columns */
export type TreatmentplanAssessmentAvgFields = {
  __typename?: 'treatmentplanAssessmentAvgFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_treatmentplanassessment". All fields are combined with a logical 'AND'. */
export type TreatmentplanAssessmentBoolExp = {
  _and?: InputMaybe<Array<TreatmentplanAssessmentBoolExp>>;
  _not?: InputMaybe<TreatmentplanAssessmentBoolExp>;
  _or?: InputMaybe<Array<TreatmentplanAssessmentBoolExp>>;
  appointment?: InputMaybe<AppointmentBoolExp>;
  appointmentId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  renderedAssessment?: InputMaybe<RenderedAssessmentBoolExp>;
  renderedAssessmentId?: InputMaybe<IntComparisonExp>;
  treatmentPlanId?: InputMaybe<IntComparisonExp>;
  treatmentplan?: InputMaybe<TreatmentplanBoolExp>;
};

/** unique or primary key constraints on table "server_treatmentplanassessment" */
export enum TreatmentplanAssessmentConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerTreatmentplanassessmentPkey = 'server_treatmentplanassessment_pkey',
  /** unique or primary key constraint on columns "rendered_assessment_id" */
  ServerTreatmentplanassessmentRenderedAssessmentIdKey = 'server_treatmentplanassessment_rendered_assessment_id_key'
}

/** input type for incrementing numeric columns in table "server_treatmentplanassessment" */
export type TreatmentplanAssessmentIncInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  renderedAssessmentId?: InputMaybe<Scalars['Int']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_treatmentplanassessment" */
export type TreatmentplanAssessmentInsertInput = {
  appointment?: InputMaybe<AppointmentObjRelInsertInput>;
  appointmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  renderedAssessment?: InputMaybe<RenderedAssessmentObjRelInsertInput>;
  renderedAssessmentId?: InputMaybe<Scalars['Int']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
  treatmentplan?: InputMaybe<TreatmentplanObjRelInsertInput>;
};

/** aggregate max on columns */
export type TreatmentplanAssessmentMaxFields = {
  __typename?: 'treatmentplanAssessmentMaxFields';
  appointmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  renderedAssessmentId?: Maybe<Scalars['Int']>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TreatmentplanAssessmentMinFields = {
  __typename?: 'treatmentplanAssessmentMinFields';
  appointmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  renderedAssessmentId?: Maybe<Scalars['Int']>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_treatmentplanassessment" */
export type TreatmentplanAssessmentMutationResponse = {
  __typename?: 'treatmentplanAssessmentMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TreatmentplanAssessment>;
};

/** input type for inserting object relation for remote table "server_treatmentplanassessment" */
export type TreatmentplanAssessmentObjRelInsertInput = {
  data: TreatmentplanAssessmentInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TreatmentplanAssessmentOnConflict>;
};

/** on_conflict condition type for table "server_treatmentplanassessment" */
export type TreatmentplanAssessmentOnConflict = {
  constraint: TreatmentplanAssessmentConstraint;
  update_columns?: Array<TreatmentplanAssessmentUpdateColumn>;
  where?: InputMaybe<TreatmentplanAssessmentBoolExp>;
};

/** Ordering options when selecting data from "server_treatmentplanassessment". */
export type TreatmentplanAssessmentOrderBy = {
  appointment?: InputMaybe<AppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessment?: InputMaybe<RenderedAssessmentOrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  treatmentplan?: InputMaybe<TreatmentplanOrderBy>;
};

/** primary key columns input for table: server_treatmentplanassessment */
export type TreatmentplanAssessmentPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_treatmentplanassessment" */
export enum TreatmentplanAssessmentSelectColumn {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Id = 'id',
  /** column name */
  RenderedAssessmentId = 'renderedAssessmentId',
  /** column name */
  TreatmentPlanId = 'treatmentPlanId'
}

/** input type for updating data in table "server_treatmentplanassessment" */
export type TreatmentplanAssessmentSetInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  renderedAssessmentId?: InputMaybe<Scalars['Int']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TreatmentplanAssessmentStddevFields = {
  __typename?: 'treatmentplanAssessmentStddevFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TreatmentplanAssessmentStddev_PopFields = {
  __typename?: 'treatmentplanAssessmentStddev_popFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TreatmentplanAssessmentStddev_SampFields = {
  __typename?: 'treatmentplanAssessmentStddev_sampFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TreatmentplanAssessmentSumFields = {
  __typename?: 'treatmentplanAssessmentSumFields';
  appointmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  renderedAssessmentId?: Maybe<Scalars['Int']>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_treatmentplanassessment" */
export enum TreatmentplanAssessmentUpdateColumn {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Id = 'id',
  /** column name */
  RenderedAssessmentId = 'renderedAssessmentId',
  /** column name */
  TreatmentPlanId = 'treatmentPlanId'
}

export type TreatmentplanAssessmentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TreatmentplanAssessmentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentplanAssessmentSetInput>;
  where: TreatmentplanAssessmentBoolExp;
};

/** aggregate var_pop on columns */
export type TreatmentplanAssessmentVar_PopFields = {
  __typename?: 'treatmentplanAssessmentVar_popFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TreatmentplanAssessmentVar_SampFields = {
  __typename?: 'treatmentplanAssessmentVar_sampFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TreatmentplanAssessmentVarianceFields = {
  __typename?: 'treatmentplanAssessmentVarianceFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  renderedAssessmentId?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_treatmentplanassessment" */
export type TreatmentplanAssessment_Avg_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_treatmentplanassessment" */
export type TreatmentplanAssessment_Max_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_treatmentplanassessment" */
export type TreatmentplanAssessment_Min_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_treatmentplanassessment" */
export type TreatmentplanAssessment_Stddev_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_treatmentplanassessment" */
export type TreatmentplanAssessment_Stddev_Pop_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_treatmentplanassessment" */
export type TreatmentplanAssessment_Stddev_Samp_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "treatmentplanAssessment" */
export type TreatmentplanAssessment_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TreatmentplanAssessment_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TreatmentplanAssessment_StreamCursorValueInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  renderedAssessmentId?: InputMaybe<Scalars['Int']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_treatmentplanassessment" */
export type TreatmentplanAssessment_Sum_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_treatmentplanassessment" */
export type TreatmentplanAssessment_Var_Pop_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_treatmentplanassessment" */
export type TreatmentplanAssessment_Var_Samp_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_treatmentplanassessment" */
export type TreatmentplanAssessment_Variance_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  renderedAssessmentId?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
};

/** aggregate avg on columns */
export type TreatmentplanAvgFields = {
  __typename?: 'treatmentplanAvgFields';
  associatedTemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_treatmentplan". All fields are combined with a logical 'AND'. */
export type TreatmentplanBoolExp = {
  _and?: InputMaybe<Array<TreatmentplanBoolExp>>;
  _not?: InputMaybe<TreatmentplanBoolExp>;
  _or?: InputMaybe<Array<TreatmentplanBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  assessments?: InputMaybe<TreatmentplanAssessmentBoolExp>;
  associatedTemplateId?: InputMaybe<IntComparisonExp>;
  associatedTreatmentPlanTemplate?: InputMaybe<TreatmentplanTemplateBoolExp>;
  dateEnd?: InputMaybe<DateComparisonExp>;
  dateStart?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  orders?: InputMaybe<LegacyTreatmentplanOrderBoolExp>;
  patient?: InputMaybe<PatientBoolExp>;
  patientId?: InputMaybe<IntComparisonExp>;
  proceduresRendered?: InputMaybe<ProcedureRenderedBoolExp>;
  treatmentPlanProblems?: InputMaybe<TreatmentplanProblemsBoolExp>;
  treatmentPlanProgressNotes?: InputMaybe<TreatmentplanNoteBoolExp>;
};

/** unique or primary key constraints on table "server_treatmentplan" */
export enum TreatmentplanConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerTreatmentplanPkey = 'server_treatmentplan_pkey'
}

/** input type for incrementing numeric columns in table "server_treatmentplan" */
export type TreatmentplanIncInput = {
  associatedTemplateId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_treatmentplan" */
export type TreatmentplanInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  assessments?: InputMaybe<TreatmentplanAssessmentArrRelInsertInput>;
  associatedTemplateId?: InputMaybe<Scalars['Int']>;
  associatedTreatmentPlanTemplate?: InputMaybe<TreatmentplanTemplateObjRelInsertInput>;
  dateEnd?: InputMaybe<Scalars['date']>;
  dateStart?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<LegacyTreatmentplanOrderArrRelInsertInput>;
  patient?: InputMaybe<PatientObjRelInsertInput>;
  patientId?: InputMaybe<Scalars['Int']>;
  proceduresRendered?: InputMaybe<ProcedureRenderedArrRelInsertInput>;
  treatmentPlanProblems?: InputMaybe<TreatmentplanProblemsArrRelInsertInput>;
  treatmentPlanProgressNotes?: InputMaybe<TreatmentplanNoteArrRelInsertInput>;
};

/** aggregate max on columns */
export type TreatmentplanMaxFields = {
  __typename?: 'treatmentplanMaxFields';
  associatedTemplateId?: Maybe<Scalars['Int']>;
  dateEnd?: Maybe<Scalars['date']>;
  dateStart?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TreatmentplanMinFields = {
  __typename?: 'treatmentplanMinFields';
  associatedTemplateId?: Maybe<Scalars['Int']>;
  dateEnd?: Maybe<Scalars['date']>;
  dateStart?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_treatmentplan" */
export type TreatmentplanMutationResponse = {
  __typename?: 'treatmentplanMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Treatmentplan>;
};

/** columns and relationships of "server_treatmentplannote" */
export type TreatmentplanNote = {
  __typename?: 'treatmentplanNote';
  datetime: Scalars['timestamptz'];
  /** An object relationship */
  enteringUser: User;
  id: Scalars['Int'];
  note: Scalars['String'];
  treatmentPlanId: Scalars['Int'];
  /** An object relationship */
  treatmentplan: Treatmentplan;
  userId: Scalars['Int'];
};

/** aggregated selection of "server_treatmentplannote" */
export type TreatmentplanNoteAggregate = {
  __typename?: 'treatmentplanNoteAggregate';
  aggregate?: Maybe<TreatmentplanNoteAggregateFields>;
  nodes: Array<TreatmentplanNote>;
};

/** aggregate fields of "server_treatmentplannote" */
export type TreatmentplanNoteAggregateFields = {
  __typename?: 'treatmentplanNoteAggregateFields';
  avg?: Maybe<TreatmentplanNoteAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TreatmentplanNoteMaxFields>;
  min?: Maybe<TreatmentplanNoteMinFields>;
  stddev?: Maybe<TreatmentplanNoteStddevFields>;
  stddevPop?: Maybe<TreatmentplanNoteStddev_PopFields>;
  stddevSamp?: Maybe<TreatmentplanNoteStddev_SampFields>;
  sum?: Maybe<TreatmentplanNoteSumFields>;
  varPop?: Maybe<TreatmentplanNoteVar_PopFields>;
  varSamp?: Maybe<TreatmentplanNoteVar_SampFields>;
  variance?: Maybe<TreatmentplanNoteVarianceFields>;
};


/** aggregate fields of "server_treatmentplannote" */
export type TreatmentplanNoteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentplanNoteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_treatmentplannote" */
export type TreatmentplanNoteAggregateOrderBy = {
  avg?: InputMaybe<TreatmentplanNote_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TreatmentplanNote_Max_Order_By>;
  min?: InputMaybe<TreatmentplanNote_Min_Order_By>;
  stddev?: InputMaybe<TreatmentplanNote_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TreatmentplanNote_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TreatmentplanNote_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TreatmentplanNote_Sum_Order_By>;
  var_pop?: InputMaybe<TreatmentplanNote_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TreatmentplanNote_Var_Samp_Order_By>;
  variance?: InputMaybe<TreatmentplanNote_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_treatmentplannote" */
export type TreatmentplanNoteArrRelInsertInput = {
  data: Array<TreatmentplanNoteInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TreatmentplanNoteOnConflict>;
};

/** aggregate avg on columns */
export type TreatmentplanNoteAvgFields = {
  __typename?: 'treatmentplanNoteAvgFields';
  id?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_treatmentplannote". All fields are combined with a logical 'AND'. */
export type TreatmentplanNoteBoolExp = {
  _and?: InputMaybe<Array<TreatmentplanNoteBoolExp>>;
  _not?: InputMaybe<TreatmentplanNoteBoolExp>;
  _or?: InputMaybe<Array<TreatmentplanNoteBoolExp>>;
  datetime?: InputMaybe<TimestamptzComparisonExp>;
  enteringUser?: InputMaybe<UserBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  treatmentPlanId?: InputMaybe<IntComparisonExp>;
  treatmentplan?: InputMaybe<TreatmentplanBoolExp>;
  userId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_treatmentplannote" */
export enum TreatmentplanNoteConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerTreatmentplannotePkey = 'server_treatmentplannote_pkey'
}

/** input type for incrementing numeric columns in table "server_treatmentplannote" */
export type TreatmentplanNoteIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_treatmentplannote" */
export type TreatmentplanNoteInsertInput = {
  datetime?: InputMaybe<Scalars['timestamptz']>;
  enteringUser?: InputMaybe<UserObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
  treatmentplan?: InputMaybe<TreatmentplanObjRelInsertInput>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TreatmentplanNoteMaxFields = {
  __typename?: 'treatmentplanNoteMaxFields';
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TreatmentplanNoteMinFields = {
  __typename?: 'treatmentplanNoteMinFields';
  datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_treatmentplannote" */
export type TreatmentplanNoteMutationResponse = {
  __typename?: 'treatmentplanNoteMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TreatmentplanNote>;
};

/** on_conflict condition type for table "server_treatmentplannote" */
export type TreatmentplanNoteOnConflict = {
  constraint: TreatmentplanNoteConstraint;
  update_columns?: Array<TreatmentplanNoteUpdateColumn>;
  where?: InputMaybe<TreatmentplanNoteBoolExp>;
};

/** Ordering options when selecting data from "server_treatmentplannote". */
export type TreatmentplanNoteOrderBy = {
  datetime?: InputMaybe<OrderBy>;
  enteringUser?: InputMaybe<UserOrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  treatmentplan?: InputMaybe<TreatmentplanOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_treatmentplannote */
export type TreatmentplanNotePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_treatmentplannote" */
export enum TreatmentplanNoteSelectColumn {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  TreatmentPlanId = 'treatmentPlanId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "server_treatmentplannote" */
export type TreatmentplanNoteSetInput = {
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TreatmentplanNoteStddevFields = {
  __typename?: 'treatmentplanNoteStddevFields';
  id?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TreatmentplanNoteStddev_PopFields = {
  __typename?: 'treatmentplanNoteStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TreatmentplanNoteStddev_SampFields = {
  __typename?: 'treatmentplanNoteStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TreatmentplanNoteSumFields = {
  __typename?: 'treatmentplanNoteSumFields';
  id?: Maybe<Scalars['Int']>;
  treatmentPlanId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_treatmentplannote" */
export enum TreatmentplanNoteUpdateColumn {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  TreatmentPlanId = 'treatmentPlanId',
  /** column name */
  UserId = 'userId'
}

export type TreatmentplanNoteUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TreatmentplanNoteIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentplanNoteSetInput>;
  where: TreatmentplanNoteBoolExp;
};

/** aggregate var_pop on columns */
export type TreatmentplanNoteVar_PopFields = {
  __typename?: 'treatmentplanNoteVar_popFields';
  id?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TreatmentplanNoteVar_SampFields = {
  __typename?: 'treatmentplanNoteVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TreatmentplanNoteVarianceFields = {
  __typename?: 'treatmentplanNoteVarianceFields';
  id?: Maybe<Scalars['Float']>;
  treatmentPlanId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_treatmentplannote" */
export type TreatmentplanNote_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_treatmentplannote" */
export type TreatmentplanNote_Max_Order_By = {
  datetime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_treatmentplannote" */
export type TreatmentplanNote_Min_Order_By = {
  datetime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_treatmentplannote" */
export type TreatmentplanNote_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_treatmentplannote" */
export type TreatmentplanNote_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_treatmentplannote" */
export type TreatmentplanNote_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "treatmentplanNote" */
export type TreatmentplanNote_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TreatmentplanNote_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TreatmentplanNote_StreamCursorValueInput = {
  datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  treatmentPlanId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_treatmentplannote" */
export type TreatmentplanNote_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_treatmentplannote" */
export type TreatmentplanNote_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_treatmentplannote" */
export type TreatmentplanNote_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_treatmentplannote" */
export type TreatmentplanNote_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  treatmentPlanId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** input type for inserting object relation for remote table "server_treatmentplan" */
export type TreatmentplanObjRelInsertInput = {
  data: TreatmentplanInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TreatmentplanOnConflict>;
};

/** on_conflict condition type for table "server_treatmentplan" */
export type TreatmentplanOnConflict = {
  constraint: TreatmentplanConstraint;
  update_columns?: Array<TreatmentplanUpdateColumn>;
  where?: InputMaybe<TreatmentplanBoolExp>;
};

/** Ordering options when selecting data from "server_treatmentplan". */
export type TreatmentplanOrderBy = {
  active?: InputMaybe<OrderBy>;
  assessmentsAggregate?: InputMaybe<TreatmentplanAssessmentAggregateOrderBy>;
  associatedTemplateId?: InputMaybe<OrderBy>;
  associatedTreatmentPlanTemplate?: InputMaybe<TreatmentplanTemplateOrderBy>;
  dateEnd?: InputMaybe<OrderBy>;
  dateStart?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  ordersAggregate?: InputMaybe<LegacyTreatmentplanOrderAggregateOrderBy>;
  patient?: InputMaybe<PatientOrderBy>;
  patientId?: InputMaybe<OrderBy>;
  proceduresRenderedAggregate?: InputMaybe<ProcedureRenderedAggregateOrderBy>;
  treatmentPlanProblemsAggregate?: InputMaybe<TreatmentplanProblemsAggregateOrderBy>;
  treatmentPlanProgressNotesAggregate?: InputMaybe<TreatmentplanNoteAggregateOrderBy>;
};

/** primary key columns input for table: server_treatmentplan */
export type TreatmentplanPkColumnsInput = {
  id: Scalars['Int'];
};

/** columns and relationships of "server_treatmentplan_problems" */
export type TreatmentplanProblems = {
  __typename?: 'treatmentplanProblems';
  id: Scalars['Int'];
  /** An object relationship */
  problem: Problem;
  problemId: Scalars['Int'];
  /** An object relationship */
  treatmentplan: Treatmentplan;
  treatmentplanId: Scalars['Int'];
};

/** aggregated selection of "server_treatmentplan_problems" */
export type TreatmentplanProblemsAggregate = {
  __typename?: 'treatmentplanProblemsAggregate';
  aggregate?: Maybe<TreatmentplanProblemsAggregateFields>;
  nodes: Array<TreatmentplanProblems>;
};

/** aggregate fields of "server_treatmentplan_problems" */
export type TreatmentplanProblemsAggregateFields = {
  __typename?: 'treatmentplanProblemsAggregateFields';
  avg?: Maybe<TreatmentplanProblemsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TreatmentplanProblemsMaxFields>;
  min?: Maybe<TreatmentplanProblemsMinFields>;
  stddev?: Maybe<TreatmentplanProblemsStddevFields>;
  stddevPop?: Maybe<TreatmentplanProblemsStddev_PopFields>;
  stddevSamp?: Maybe<TreatmentplanProblemsStddev_SampFields>;
  sum?: Maybe<TreatmentplanProblemsSumFields>;
  varPop?: Maybe<TreatmentplanProblemsVar_PopFields>;
  varSamp?: Maybe<TreatmentplanProblemsVar_SampFields>;
  variance?: Maybe<TreatmentplanProblemsVarianceFields>;
};


/** aggregate fields of "server_treatmentplan_problems" */
export type TreatmentplanProblemsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentplanProblemsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_treatmentplan_problems" */
export type TreatmentplanProblemsAggregateOrderBy = {
  avg?: InputMaybe<TreatmentplanProblems_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TreatmentplanProblems_Max_Order_By>;
  min?: InputMaybe<TreatmentplanProblems_Min_Order_By>;
  stddev?: InputMaybe<TreatmentplanProblems_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TreatmentplanProblems_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TreatmentplanProblems_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TreatmentplanProblems_Sum_Order_By>;
  var_pop?: InputMaybe<TreatmentplanProblems_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TreatmentplanProblems_Var_Samp_Order_By>;
  variance?: InputMaybe<TreatmentplanProblems_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_treatmentplan_problems" */
export type TreatmentplanProblemsArrRelInsertInput = {
  data: Array<TreatmentplanProblemsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TreatmentplanProblemsOnConflict>;
};

/** aggregate avg on columns */
export type TreatmentplanProblemsAvgFields = {
  __typename?: 'treatmentplanProblemsAvgFields';
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
  treatmentplanId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_treatmentplan_problems". All fields are combined with a logical 'AND'. */
export type TreatmentplanProblemsBoolExp = {
  _and?: InputMaybe<Array<TreatmentplanProblemsBoolExp>>;
  _not?: InputMaybe<TreatmentplanProblemsBoolExp>;
  _or?: InputMaybe<Array<TreatmentplanProblemsBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  problem?: InputMaybe<ProblemBoolExp>;
  problemId?: InputMaybe<IntComparisonExp>;
  treatmentplan?: InputMaybe<TreatmentplanBoolExp>;
  treatmentplanId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_treatmentplan_problems" */
export enum TreatmentplanProblemsConstraint {
  /** unique or primary key constraint on columns "problem_id", "treatmentplan_id" */
  ServerTreatmentplanProTreatmentplanIdProblem_84b71b89Uniq = 'server_treatmentplan_pro_treatmentplan_id_problem_84b71b89_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerTreatmentplanProblemsPkey = 'server_treatmentplan_problems_pkey'
}

/** input type for incrementing numeric columns in table "server_treatmentplan_problems" */
export type TreatmentplanProblemsIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  problemId?: InputMaybe<Scalars['Int']>;
  treatmentplanId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_treatmentplan_problems" */
export type TreatmentplanProblemsInsertInput = {
  id?: InputMaybe<Scalars['Int']>;
  problem?: InputMaybe<ProblemObjRelInsertInput>;
  problemId?: InputMaybe<Scalars['Int']>;
  treatmentplan?: InputMaybe<TreatmentplanObjRelInsertInput>;
  treatmentplanId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TreatmentplanProblemsMaxFields = {
  __typename?: 'treatmentplanProblemsMaxFields';
  id?: Maybe<Scalars['Int']>;
  problemId?: Maybe<Scalars['Int']>;
  treatmentplanId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TreatmentplanProblemsMinFields = {
  __typename?: 'treatmentplanProblemsMinFields';
  id?: Maybe<Scalars['Int']>;
  problemId?: Maybe<Scalars['Int']>;
  treatmentplanId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_treatmentplan_problems" */
export type TreatmentplanProblemsMutationResponse = {
  __typename?: 'treatmentplanProblemsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TreatmentplanProblems>;
};

/** on_conflict condition type for table "server_treatmentplan_problems" */
export type TreatmentplanProblemsOnConflict = {
  constraint: TreatmentplanProblemsConstraint;
  update_columns?: Array<TreatmentplanProblemsUpdateColumn>;
  where?: InputMaybe<TreatmentplanProblemsBoolExp>;
};

/** Ordering options when selecting data from "server_treatmentplan_problems". */
export type TreatmentplanProblemsOrderBy = {
  id?: InputMaybe<OrderBy>;
  problem?: InputMaybe<ProblemOrderBy>;
  problemId?: InputMaybe<OrderBy>;
  treatmentplan?: InputMaybe<TreatmentplanOrderBy>;
  treatmentplanId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_treatmentplan_problems */
export type TreatmentplanProblemsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_treatmentplan_problems" */
export enum TreatmentplanProblemsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ProblemId = 'problemId',
  /** column name */
  TreatmentplanId = 'treatmentplanId'
}

/** input type for updating data in table "server_treatmentplan_problems" */
export type TreatmentplanProblemsSetInput = {
  id?: InputMaybe<Scalars['Int']>;
  problemId?: InputMaybe<Scalars['Int']>;
  treatmentplanId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TreatmentplanProblemsStddevFields = {
  __typename?: 'treatmentplanProblemsStddevFields';
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
  treatmentplanId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TreatmentplanProblemsStddev_PopFields = {
  __typename?: 'treatmentplanProblemsStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
  treatmentplanId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TreatmentplanProblemsStddev_SampFields = {
  __typename?: 'treatmentplanProblemsStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
  treatmentplanId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TreatmentplanProblemsSumFields = {
  __typename?: 'treatmentplanProblemsSumFields';
  id?: Maybe<Scalars['Int']>;
  problemId?: Maybe<Scalars['Int']>;
  treatmentplanId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_treatmentplan_problems" */
export enum TreatmentplanProblemsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ProblemId = 'problemId',
  /** column name */
  TreatmentplanId = 'treatmentplanId'
}

export type TreatmentplanProblemsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TreatmentplanProblemsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentplanProblemsSetInput>;
  where: TreatmentplanProblemsBoolExp;
};

/** aggregate var_pop on columns */
export type TreatmentplanProblemsVar_PopFields = {
  __typename?: 'treatmentplanProblemsVar_popFields';
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
  treatmentplanId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TreatmentplanProblemsVar_SampFields = {
  __typename?: 'treatmentplanProblemsVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
  treatmentplanId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TreatmentplanProblemsVarianceFields = {
  __typename?: 'treatmentplanProblemsVarianceFields';
  id?: Maybe<Scalars['Float']>;
  problemId?: Maybe<Scalars['Float']>;
  treatmentplanId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_treatmentplan_problems" */
export type TreatmentplanProblems_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
  treatmentplanId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_treatmentplan_problems" */
export type TreatmentplanProblems_Max_Order_By = {
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
  treatmentplanId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_treatmentplan_problems" */
export type TreatmentplanProblems_Min_Order_By = {
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
  treatmentplanId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_treatmentplan_problems" */
export type TreatmentplanProblems_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
  treatmentplanId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_treatmentplan_problems" */
export type TreatmentplanProblems_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
  treatmentplanId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_treatmentplan_problems" */
export type TreatmentplanProblems_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
  treatmentplanId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "treatmentplanProblems" */
export type TreatmentplanProblems_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TreatmentplanProblems_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TreatmentplanProblems_StreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']>;
  problemId?: InputMaybe<Scalars['Int']>;
  treatmentplanId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_treatmentplan_problems" */
export type TreatmentplanProblems_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
  treatmentplanId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_treatmentplan_problems" */
export type TreatmentplanProblems_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
  treatmentplanId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_treatmentplan_problems" */
export type TreatmentplanProblems_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
  treatmentplanId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_treatmentplan_problems" */
export type TreatmentplanProblems_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  problemId?: InputMaybe<OrderBy>;
  treatmentplanId?: InputMaybe<OrderBy>;
};

/** select columns of table "server_treatmentplan" */
export enum TreatmentplanSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AssociatedTemplateId = 'associatedTemplateId',
  /** column name */
  DateEnd = 'dateEnd',
  /** column name */
  DateStart = 'dateStart',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PatientId = 'patientId'
}

/** input type for updating data in table "server_treatmentplan" */
export type TreatmentplanSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  associatedTemplateId?: InputMaybe<Scalars['Int']>;
  dateEnd?: InputMaybe<Scalars['date']>;
  dateStart?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TreatmentplanStddevFields = {
  __typename?: 'treatmentplanStddevFields';
  associatedTemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TreatmentplanStddev_PopFields = {
  __typename?: 'treatmentplanStddev_popFields';
  associatedTemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TreatmentplanStddev_SampFields = {
  __typename?: 'treatmentplanStddev_sampFields';
  associatedTemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TreatmentplanSumFields = {
  __typename?: 'treatmentplanSumFields';
  associatedTemplateId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "server_treatmentplantemplate" */
export type TreatmentplanTemplate = {
  __typename?: 'treatmentplanTemplate';
  /** An array relationship */
  assessmentsUsingTemplate: Array<TreatmentplanTemplateAssessment>;
  /** An aggregate relationship */
  assessmentsUsingTemplateAggregate: TreatmentplanTemplateAssessmentAggregate;
  icdOptions?: Maybe<Scalars['_varchar']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  treatmentplans: Array<Treatmentplan>;
  /** An aggregate relationship */
  treatmentplansAggregate: TreatmentplanAggregate;
};


/** columns and relationships of "server_treatmentplantemplate" */
export type TreatmentplanTemplateAssessmentsUsingTemplateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanTemplateAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanTemplateAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
};


/** columns and relationships of "server_treatmentplantemplate" */
export type TreatmentplanTemplateAssessmentsUsingTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanTemplateAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanTemplateAssessmentOrderBy>>;
  where?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
};


/** columns and relationships of "server_treatmentplantemplate" */
export type TreatmentplanTemplateTreatmentplansArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanOrderBy>>;
  where?: InputMaybe<TreatmentplanBoolExp>;
};


/** columns and relationships of "server_treatmentplantemplate" */
export type TreatmentplanTemplateTreatmentplansAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanOrderBy>>;
  where?: InputMaybe<TreatmentplanBoolExp>;
};

/** aggregated selection of "server_treatmentplantemplate" */
export type TreatmentplanTemplateAggregate = {
  __typename?: 'treatmentplanTemplateAggregate';
  aggregate?: Maybe<TreatmentplanTemplateAggregateFields>;
  nodes: Array<TreatmentplanTemplate>;
};

/** aggregate fields of "server_treatmentplantemplate" */
export type TreatmentplanTemplateAggregateFields = {
  __typename?: 'treatmentplanTemplateAggregateFields';
  avg?: Maybe<TreatmentplanTemplateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TreatmentplanTemplateMaxFields>;
  min?: Maybe<TreatmentplanTemplateMinFields>;
  stddev?: Maybe<TreatmentplanTemplateStddevFields>;
  stddevPop?: Maybe<TreatmentplanTemplateStddev_PopFields>;
  stddevSamp?: Maybe<TreatmentplanTemplateStddev_SampFields>;
  sum?: Maybe<TreatmentplanTemplateSumFields>;
  varPop?: Maybe<TreatmentplanTemplateVar_PopFields>;
  varSamp?: Maybe<TreatmentplanTemplateVar_SampFields>;
  variance?: Maybe<TreatmentplanTemplateVarianceFields>;
};


/** aggregate fields of "server_treatmentplantemplate" */
export type TreatmentplanTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentplanTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessment = {
  __typename?: 'treatmentplanTemplateAssessment';
  /** An object relationship */
  assessment: Assessment;
  assessmentId: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  treatmentplanTemplate: TreatmentplanTemplate;
  treatmentplantemplateId: Scalars['Int'];
};

/** aggregated selection of "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessmentAggregate = {
  __typename?: 'treatmentplanTemplateAssessmentAggregate';
  aggregate?: Maybe<TreatmentplanTemplateAssessmentAggregateFields>;
  nodes: Array<TreatmentplanTemplateAssessment>;
};

/** aggregate fields of "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessmentAggregateFields = {
  __typename?: 'treatmentplanTemplateAssessmentAggregateFields';
  avg?: Maybe<TreatmentplanTemplateAssessmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TreatmentplanTemplateAssessmentMaxFields>;
  min?: Maybe<TreatmentplanTemplateAssessmentMinFields>;
  stddev?: Maybe<TreatmentplanTemplateAssessmentStddevFields>;
  stddevPop?: Maybe<TreatmentplanTemplateAssessmentStddev_PopFields>;
  stddevSamp?: Maybe<TreatmentplanTemplateAssessmentStddev_SampFields>;
  sum?: Maybe<TreatmentplanTemplateAssessmentSumFields>;
  varPop?: Maybe<TreatmentplanTemplateAssessmentVar_PopFields>;
  varSamp?: Maybe<TreatmentplanTemplateAssessmentVar_SampFields>;
  variance?: Maybe<TreatmentplanTemplateAssessmentVarianceFields>;
};


/** aggregate fields of "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TreatmentplanTemplateAssessmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessmentAggregateOrderBy = {
  avg?: InputMaybe<TreatmentplanTemplateAssessment_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TreatmentplanTemplateAssessment_Max_Order_By>;
  min?: InputMaybe<TreatmentplanTemplateAssessment_Min_Order_By>;
  stddev?: InputMaybe<TreatmentplanTemplateAssessment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TreatmentplanTemplateAssessment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TreatmentplanTemplateAssessment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TreatmentplanTemplateAssessment_Sum_Order_By>;
  var_pop?: InputMaybe<TreatmentplanTemplateAssessment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TreatmentplanTemplateAssessment_Var_Samp_Order_By>;
  variance?: InputMaybe<TreatmentplanTemplateAssessment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessmentArrRelInsertInput = {
  data: Array<TreatmentplanTemplateAssessmentInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TreatmentplanTemplateAssessmentOnConflict>;
};

/** aggregate avg on columns */
export type TreatmentplanTemplateAssessmentAvgFields = {
  __typename?: 'treatmentplanTemplateAssessmentAvgFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  treatmentplantemplateId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_treatmentplantemplate_assessments". All fields are combined with a logical 'AND'. */
export type TreatmentplanTemplateAssessmentBoolExp = {
  _and?: InputMaybe<Array<TreatmentplanTemplateAssessmentBoolExp>>;
  _not?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
  _or?: InputMaybe<Array<TreatmentplanTemplateAssessmentBoolExp>>;
  assessment?: InputMaybe<AssessmentBoolExp>;
  assessmentId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  treatmentplanTemplate?: InputMaybe<TreatmentplanTemplateBoolExp>;
  treatmentplantemplateId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_treatmentplantemplate_assessments" */
export enum TreatmentplanTemplateAssessmentConstraint {
  /** unique or primary key constraint on columns "treatmentplantemplate_id", "assessment_id" */
  ServerTreatmentplantempTreatmentplantemplateId_7dd46927Uniq = 'server_treatmentplantemp_treatmentplantemplate_id_7dd46927_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerTreatmentplantemplateAssessmentsPkey = 'server_treatmentplantemplate_assessments_pkey'
}

/** input type for incrementing numeric columns in table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessmentIncInput = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  treatmentplantemplateId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessmentInsertInput = {
  assessment?: InputMaybe<AssessmentObjRelInsertInput>;
  assessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  treatmentplanTemplate?: InputMaybe<TreatmentplanTemplateObjRelInsertInput>;
  treatmentplantemplateId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TreatmentplanTemplateAssessmentMaxFields = {
  __typename?: 'treatmentplanTemplateAssessmentMaxFields';
  assessmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  treatmentplantemplateId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TreatmentplanTemplateAssessmentMinFields = {
  __typename?: 'treatmentplanTemplateAssessmentMinFields';
  assessmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  treatmentplantemplateId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessmentMutationResponse = {
  __typename?: 'treatmentplanTemplateAssessmentMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TreatmentplanTemplateAssessment>;
};

/** on_conflict condition type for table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessmentOnConflict = {
  constraint: TreatmentplanTemplateAssessmentConstraint;
  update_columns?: Array<TreatmentplanTemplateAssessmentUpdateColumn>;
  where?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
};

/** Ordering options when selecting data from "server_treatmentplantemplate_assessments". */
export type TreatmentplanTemplateAssessmentOrderBy = {
  assessment?: InputMaybe<AssessmentOrderBy>;
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  treatmentplanTemplate?: InputMaybe<TreatmentplanTemplateOrderBy>;
  treatmentplantemplateId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_treatmentplantemplate_assessments */
export type TreatmentplanTemplateAssessmentPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_treatmentplantemplate_assessments" */
export enum TreatmentplanTemplateAssessmentSelectColumn {
  /** column name */
  AssessmentId = 'assessmentId',
  /** column name */
  Id = 'id',
  /** column name */
  TreatmentplantemplateId = 'treatmentplantemplateId'
}

/** input type for updating data in table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessmentSetInput = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  treatmentplantemplateId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TreatmentplanTemplateAssessmentStddevFields = {
  __typename?: 'treatmentplanTemplateAssessmentStddevFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  treatmentplantemplateId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TreatmentplanTemplateAssessmentStddev_PopFields = {
  __typename?: 'treatmentplanTemplateAssessmentStddev_popFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  treatmentplantemplateId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TreatmentplanTemplateAssessmentStddev_SampFields = {
  __typename?: 'treatmentplanTemplateAssessmentStddev_sampFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  treatmentplantemplateId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TreatmentplanTemplateAssessmentSumFields = {
  __typename?: 'treatmentplanTemplateAssessmentSumFields';
  assessmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  treatmentplantemplateId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_treatmentplantemplate_assessments" */
export enum TreatmentplanTemplateAssessmentUpdateColumn {
  /** column name */
  AssessmentId = 'assessmentId',
  /** column name */
  Id = 'id',
  /** column name */
  TreatmentplantemplateId = 'treatmentplantemplateId'
}

export type TreatmentplanTemplateAssessmentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TreatmentplanTemplateAssessmentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentplanTemplateAssessmentSetInput>;
  where: TreatmentplanTemplateAssessmentBoolExp;
};

/** aggregate var_pop on columns */
export type TreatmentplanTemplateAssessmentVar_PopFields = {
  __typename?: 'treatmentplanTemplateAssessmentVar_popFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  treatmentplantemplateId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TreatmentplanTemplateAssessmentVar_SampFields = {
  __typename?: 'treatmentplanTemplateAssessmentVar_sampFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  treatmentplantemplateId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TreatmentplanTemplateAssessmentVarianceFields = {
  __typename?: 'treatmentplanTemplateAssessmentVarianceFields';
  assessmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  treatmentplantemplateId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessment_Avg_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  treatmentplantemplateId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessment_Max_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  treatmentplantemplateId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessment_Min_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  treatmentplantemplateId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessment_Stddev_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  treatmentplantemplateId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessment_Stddev_Pop_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  treatmentplantemplateId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessment_Stddev_Samp_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  treatmentplantemplateId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "treatmentplanTemplateAssessment" */
export type TreatmentplanTemplateAssessment_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TreatmentplanTemplateAssessment_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TreatmentplanTemplateAssessment_StreamCursorValueInput = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  treatmentplantemplateId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessment_Sum_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  treatmentplantemplateId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessment_Var_Pop_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  treatmentplantemplateId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessment_Var_Samp_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  treatmentplantemplateId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_treatmentplantemplate_assessments" */
export type TreatmentplanTemplateAssessment_Variance_Order_By = {
  assessmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  treatmentplantemplateId?: InputMaybe<OrderBy>;
};

/** aggregate avg on columns */
export type TreatmentplanTemplateAvgFields = {
  __typename?: 'treatmentplanTemplateAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_treatmentplantemplate". All fields are combined with a logical 'AND'. */
export type TreatmentplanTemplateBoolExp = {
  _and?: InputMaybe<Array<TreatmentplanTemplateBoolExp>>;
  _not?: InputMaybe<TreatmentplanTemplateBoolExp>;
  _or?: InputMaybe<Array<TreatmentplanTemplateBoolExp>>;
  assessmentsUsingTemplate?: InputMaybe<TreatmentplanTemplateAssessmentBoolExp>;
  icdOptions?: InputMaybe<_VarcharComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  treatmentplans?: InputMaybe<TreatmentplanBoolExp>;
};

/** unique or primary key constraints on table "server_treatmentplantemplate" */
export enum TreatmentplanTemplateConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerTreatmentplantemplatePkey = 'server_treatmentplantemplate_pkey'
}

/** input type for incrementing numeric columns in table "server_treatmentplantemplate" */
export type TreatmentplanTemplateIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_treatmentplantemplate" */
export type TreatmentplanTemplateInsertInput = {
  assessmentsUsingTemplate?: InputMaybe<TreatmentplanTemplateAssessmentArrRelInsertInput>;
  icdOptions?: InputMaybe<Scalars['_varchar']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  treatmentplans?: InputMaybe<TreatmentplanArrRelInsertInput>;
};

/** aggregate max on columns */
export type TreatmentplanTemplateMaxFields = {
  __typename?: 'treatmentplanTemplateMaxFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TreatmentplanTemplateMinFields = {
  __typename?: 'treatmentplanTemplateMinFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_treatmentplantemplate" */
export type TreatmentplanTemplateMutationResponse = {
  __typename?: 'treatmentplanTemplateMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TreatmentplanTemplate>;
};

/** input type for inserting object relation for remote table "server_treatmentplantemplate" */
export type TreatmentplanTemplateObjRelInsertInput = {
  data: TreatmentplanTemplateInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TreatmentplanTemplateOnConflict>;
};

/** on_conflict condition type for table "server_treatmentplantemplate" */
export type TreatmentplanTemplateOnConflict = {
  constraint: TreatmentplanTemplateConstraint;
  update_columns?: Array<TreatmentplanTemplateUpdateColumn>;
  where?: InputMaybe<TreatmentplanTemplateBoolExp>;
};

/** Ordering options when selecting data from "server_treatmentplantemplate". */
export type TreatmentplanTemplateOrderBy = {
  assessmentsUsingTemplateAggregate?: InputMaybe<TreatmentplanTemplateAssessmentAggregateOrderBy>;
  icdOptions?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  treatmentplansAggregate?: InputMaybe<TreatmentplanAggregateOrderBy>;
};

/** primary key columns input for table: server_treatmentplantemplate */
export type TreatmentplanTemplatePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_treatmentplantemplate" */
export enum TreatmentplanTemplateSelectColumn {
  /** column name */
  IcdOptions = 'icdOptions',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "server_treatmentplantemplate" */
export type TreatmentplanTemplateSetInput = {
  icdOptions?: InputMaybe<Scalars['_varchar']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type TreatmentplanTemplateStddevFields = {
  __typename?: 'treatmentplanTemplateStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TreatmentplanTemplateStddev_PopFields = {
  __typename?: 'treatmentplanTemplateStddev_popFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TreatmentplanTemplateStddev_SampFields = {
  __typename?: 'treatmentplanTemplateStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TreatmentplanTemplateSumFields = {
  __typename?: 'treatmentplanTemplateSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_treatmentplantemplate" */
export enum TreatmentplanTemplateUpdateColumn {
  /** column name */
  IcdOptions = 'icdOptions',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type TreatmentplanTemplateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TreatmentplanTemplateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentplanTemplateSetInput>;
  where: TreatmentplanTemplateBoolExp;
};

/** aggregate var_pop on columns */
export type TreatmentplanTemplateVar_PopFields = {
  __typename?: 'treatmentplanTemplateVar_popFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TreatmentplanTemplateVar_SampFields = {
  __typename?: 'treatmentplanTemplateVar_sampFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TreatmentplanTemplateVarianceFields = {
  __typename?: 'treatmentplanTemplateVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "treatmentplanTemplate" */
export type TreatmentplanTemplate_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TreatmentplanTemplate_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TreatmentplanTemplate_StreamCursorValueInput = {
  icdOptions?: InputMaybe<Scalars['_varchar']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "server_treatmentplan" */
export enum TreatmentplanUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AssociatedTemplateId = 'associatedTemplateId',
  /** column name */
  DateEnd = 'dateEnd',
  /** column name */
  DateStart = 'dateStart',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PatientId = 'patientId'
}

export type TreatmentplanUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TreatmentplanIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TreatmentplanSetInput>;
  where: TreatmentplanBoolExp;
};

/** aggregate var_pop on columns */
export type TreatmentplanVar_PopFields = {
  __typename?: 'treatmentplanVar_popFields';
  associatedTemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TreatmentplanVar_SampFields = {
  __typename?: 'treatmentplanVar_sampFields';
  associatedTemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TreatmentplanVarianceFields = {
  __typename?: 'treatmentplanVarianceFields';
  associatedTemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patientId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_treatmentplan" */
export type Treatmentplan_Avg_Order_By = {
  associatedTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_treatmentplan" */
export type Treatmentplan_Max_Order_By = {
  associatedTemplateId?: InputMaybe<OrderBy>;
  dateEnd?: InputMaybe<OrderBy>;
  dateStart?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_treatmentplan" */
export type Treatmentplan_Min_Order_By = {
  associatedTemplateId?: InputMaybe<OrderBy>;
  dateEnd?: InputMaybe<OrderBy>;
  dateStart?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_treatmentplan" */
export type Treatmentplan_Stddev_Order_By = {
  associatedTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_treatmentplan" */
export type Treatmentplan_Stddev_Pop_Order_By = {
  associatedTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_treatmentplan" */
export type Treatmentplan_Stddev_Samp_Order_By = {
  associatedTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "treatmentplan" */
export type Treatmentplan_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Treatmentplan_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Treatmentplan_StreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  associatedTemplateId?: InputMaybe<Scalars['Int']>;
  dateEnd?: InputMaybe<Scalars['date']>;
  dateStart?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_treatmentplan" */
export type Treatmentplan_Sum_Order_By = {
  associatedTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_treatmentplan" */
export type Treatmentplan_Var_Pop_Order_By = {
  associatedTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_treatmentplan" */
export type Treatmentplan_Var_Samp_Order_By = {
  associatedTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_treatmentplan" */
export type Treatmentplan_Variance_Order_By = {
  associatedTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  patientId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_customuser" */
export type User = {
  __typename?: 'user';
  /** An array relationship */
  assessmentsRendered: Array<RenderedAssessment>;
  /** An aggregate relationship */
  assessmentsRenderedAggregate: RenderedAssessmentAggregate;
  /** An array relationship */
  assignedDocumentsPendingReview: Array<DocumentPendingReview>;
  /** An aggregate relationship */
  assignedDocumentsPendingReviewAggregate: DocumentPendingReviewAggregate;
  /** An array relationship */
  caretimes: Array<Caretime>;
  /** An aggregate relationship */
  caretimesAggregate: CaretimeAggregate;
  dateJoined: Scalars['timestamptz'];
  email: Scalars['String'];
  /** An object relationship */
  externalstaff?: Maybe<Externalstaff>;
  firstName: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  internalstaff?: Maybe<Internalstaff>;
  isActive: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  isSuperuser: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['timestamptz']>;
  lastName: Scalars['String'];
  /** An array relationship */
  messages: Array<Message>;
  /** An aggregate relationship */
  messagesAggregate: MessageAggregate;
  /** An array relationship */
  orderactionsEntered: Array<Orderaction>;
  /** An aggregate relationship */
  orderactionsEnteredAggregate: OrderactionAggregate;
  /** An array relationship */
  ordersApprovedAsSupervisor: Array<LegacyOrder>;
  /** An aggregate relationship */
  ordersApprovedAsSupervisorAggregate: LegacyOrderAggregate;
  /** An array relationship */
  ordersFinalized: Array<LegacyOrder>;
  /** An aggregate relationship */
  ordersFinalizedAggregate: LegacyOrderAggregate;
  /** An array relationship */
  ordersRequested: Array<LegacyOrder>;
  /** An aggregate relationship */
  ordersRequestedAggregate: LegacyOrderAggregate;
  password: Scalars['String'];
  /** An array relationship */
  patientIntakesSubmitted: Array<PatientIntake>;
  /** An aggregate relationship */
  patientIntakesSubmittedAggregate: PatientIntakeAggregate;
  /** An array relationship */
  patientStatusActionsEntered: Array<PatientStatusAction>;
  /** An aggregate relationship */
  patientStatusActionsEnteredAggregate: PatientStatusActionAggregate;
  phoneNumber: Scalars['String'];
  /** An array relationship */
  practiceGroupsMedicalDirected: Array<Practicegroup>;
  /** An aggregate relationship */
  practiceGroupsMedicalDirectedAggregate: PracticegroupAggregate;
  /** An array relationship */
  proceduresPerformed: Array<ProcedureRendered>;
  /** An aggregate relationship */
  proceduresPerformedAggregate: ProcedureRenderedAggregate;
  /** An array relationship */
  telemedAppointmentRequestsApproved: Array<TelemedAppointmentRequest>;
  /** An aggregate relationship */
  telemedAppointmentRequestsApprovedAggregate: TelemedAppointmentRequestAggregate;
  /** An array relationship */
  telemedAppointmentRequestsRequested: Array<TelemedAppointmentRequest>;
  /** An aggregate relationship */
  telemedAppointmentRequestsRequestedAggregate: TelemedAppointmentRequestAggregate;
  /** An array relationship */
  ticketsCreated: Array<TicketThread>;
  /** An aggregate relationship */
  ticketsCreatedAggregate: TicketThreadAggregate;
  /** An array relationship */
  ticketsSubscribed: Array<TicketSubscription>;
  /** An aggregate relationship */
  ticketsSubscribedAggregate: TicketSubscriptionAggregate;
  title?: Maybe<Scalars['String']>;
  /** An array relationship */
  treatmentPlanNotesEntered: Array<TreatmentplanNote>;
  /** An aggregate relationship */
  treatmentPlanNotesEnteredAggregate: TreatmentplanNoteAggregate;
  userType: Scalars['smallint'];
  username: Scalars['String'];
};


/** columns and relationships of "server_customuser" */
export type UserAssessmentsRenderedArgs = {
  distinctOn?: InputMaybe<Array<RenderedAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RenderedAssessmentOrderBy>>;
  where?: InputMaybe<RenderedAssessmentBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserAssessmentsRenderedAggregateArgs = {
  distinctOn?: InputMaybe<Array<RenderedAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RenderedAssessmentOrderBy>>;
  where?: InputMaybe<RenderedAssessmentBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserAssignedDocumentsPendingReviewArgs = {
  distinctOn?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentPendingReviewOrderBy>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserAssignedDocumentsPendingReviewAggregateArgs = {
  distinctOn?: InputMaybe<Array<DocumentPendingReviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentPendingReviewOrderBy>>;
  where?: InputMaybe<DocumentPendingReviewBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserCaretimesArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserCaretimesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CaretimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CaretimeOrderBy>>;
  where?: InputMaybe<CaretimeBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserMessagesArgs = {
  distinctOn?: InputMaybe<Array<MessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderBy>>;
  where?: InputMaybe<MessageBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserMessagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderBy>>;
  where?: InputMaybe<MessageBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserOrderactionsEnteredArgs = {
  distinctOn?: InputMaybe<Array<OrderactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderactionOrderBy>>;
  where?: InputMaybe<OrderactionBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserOrderactionsEnteredAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderactionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderactionOrderBy>>;
  where?: InputMaybe<OrderactionBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserOrdersApprovedAsSupervisorArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserOrdersApprovedAsSupervisorAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserOrdersFinalizedArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserOrdersFinalizedAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserOrdersRequestedArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserOrdersRequestedAggregateArgs = {
  distinctOn?: InputMaybe<Array<LegacyOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LegacyOrderOrderBy>>;
  where?: InputMaybe<LegacyOrderBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserPatientIntakesSubmittedArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeOrderBy>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserPatientIntakesSubmittedAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientIntakeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientIntakeOrderBy>>;
  where?: InputMaybe<PatientIntakeBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserPatientStatusActionsEnteredArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusActionOrderBy>>;
  where?: InputMaybe<PatientStatusActionBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserPatientStatusActionsEnteredAggregateArgs = {
  distinctOn?: InputMaybe<Array<PatientStatusActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PatientStatusActionOrderBy>>;
  where?: InputMaybe<PatientStatusActionBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserPracticeGroupsMedicalDirectedArgs = {
  distinctOn?: InputMaybe<Array<PracticegroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PracticegroupOrderBy>>;
  where?: InputMaybe<PracticegroupBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserPracticeGroupsMedicalDirectedAggregateArgs = {
  distinctOn?: InputMaybe<Array<PracticegroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PracticegroupOrderBy>>;
  where?: InputMaybe<PracticegroupBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserProceduresPerformedArgs = {
  distinctOn?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureRenderedOrderBy>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserProceduresPerformedAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcedureRenderedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcedureRenderedOrderBy>>;
  where?: InputMaybe<ProcedureRenderedBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserTelemedAppointmentRequestsApprovedArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserTelemedAppointmentRequestsApprovedAggregateArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserTelemedAppointmentRequestsRequestedArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserTelemedAppointmentRequestsRequestedAggregateArgs = {
  distinctOn?: InputMaybe<Array<TelemedAppointmentRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TelemedAppointmentRequestOrderBy>>;
  where?: InputMaybe<TelemedAppointmentRequestBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserTicketsCreatedArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserTicketsCreatedAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketThreadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketThreadOrderBy>>;
  where?: InputMaybe<TicketThreadBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserTicketsSubscribedArgs = {
  distinctOn?: InputMaybe<Array<TicketSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketSubscriptionOrderBy>>;
  where?: InputMaybe<TicketSubscriptionBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserTicketsSubscribedAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TicketSubscriptionOrderBy>>;
  where?: InputMaybe<TicketSubscriptionBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserTreatmentPlanNotesEnteredArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanNoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanNoteOrderBy>>;
  where?: InputMaybe<TreatmentplanNoteBoolExp>;
};


/** columns and relationships of "server_customuser" */
export type UserTreatmentPlanNotesEnteredAggregateArgs = {
  distinctOn?: InputMaybe<Array<TreatmentplanNoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TreatmentplanNoteOrderBy>>;
  where?: InputMaybe<TreatmentplanNoteBoolExp>;
};

/** aggregated selection of "server_customuser" */
export type UserAggregate = {
  __typename?: 'userAggregate';
  aggregate?: Maybe<UserAggregateFields>;
  nodes: Array<User>;
};

/** aggregate fields of "server_customuser" */
export type UserAggregateFields = {
  __typename?: 'userAggregateFields';
  avg?: Maybe<UserAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserMaxFields>;
  min?: Maybe<UserMinFields>;
  stddev?: Maybe<UserStddevFields>;
  stddevPop?: Maybe<UserStddev_PopFields>;
  stddevSamp?: Maybe<UserStddev_SampFields>;
  sum?: Maybe<UserSumFields>;
  varPop?: Maybe<UserVar_PopFields>;
  varSamp?: Maybe<UserVar_SampFields>;
  variance?: Maybe<UserVarianceFields>;
};


/** aggregate fields of "server_customuser" */
export type UserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UserAvgFields = {
  __typename?: 'userAvgFields';
  id?: Maybe<Scalars['Float']>;
  userType?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_customuser". All fields are combined with a logical 'AND'. */
export type UserBoolExp = {
  _and?: InputMaybe<Array<UserBoolExp>>;
  _not?: InputMaybe<UserBoolExp>;
  _or?: InputMaybe<Array<UserBoolExp>>;
  assessmentsRendered?: InputMaybe<RenderedAssessmentBoolExp>;
  assignedDocumentsPendingReview?: InputMaybe<DocumentPendingReviewBoolExp>;
  caretimes?: InputMaybe<CaretimeBoolExp>;
  dateJoined?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  externalstaff?: InputMaybe<ExternalstaffBoolExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  internalstaff?: InputMaybe<InternalstaffBoolExp>;
  isActive?: InputMaybe<BooleanComparisonExp>;
  isStaff?: InputMaybe<BooleanComparisonExp>;
  isSuperuser?: InputMaybe<BooleanComparisonExp>;
  lastLogin?: InputMaybe<TimestamptzComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  messages?: InputMaybe<MessageBoolExp>;
  orderactionsEntered?: InputMaybe<OrderactionBoolExp>;
  ordersApprovedAsSupervisor?: InputMaybe<LegacyOrderBoolExp>;
  ordersFinalized?: InputMaybe<LegacyOrderBoolExp>;
  ordersRequested?: InputMaybe<LegacyOrderBoolExp>;
  password?: InputMaybe<StringComparisonExp>;
  patientIntakesSubmitted?: InputMaybe<PatientIntakeBoolExp>;
  patientStatusActionsEntered?: InputMaybe<PatientStatusActionBoolExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  practiceGroupsMedicalDirected?: InputMaybe<PracticegroupBoolExp>;
  proceduresPerformed?: InputMaybe<ProcedureRenderedBoolExp>;
  telemedAppointmentRequestsApproved?: InputMaybe<TelemedAppointmentRequestBoolExp>;
  telemedAppointmentRequestsRequested?: InputMaybe<TelemedAppointmentRequestBoolExp>;
  ticketsCreated?: InputMaybe<TicketThreadBoolExp>;
  ticketsSubscribed?: InputMaybe<TicketSubscriptionBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  treatmentPlanNotesEntered?: InputMaybe<TreatmentplanNoteBoolExp>;
  userType?: InputMaybe<SmallintComparisonExp>;
  username?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "server_customuser" */
export enum UserConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerCustomuserPkey = 'server_customuser_pkey',
  /** unique or primary key constraint on columns "username" */
  ServerCustomuserUsernameKey = 'server_customuser_username_key'
}

/** columns and relationships of "server_customuser_groups" */
export type UserGroups = {
  __typename?: 'userGroups';
  customuserId: Scalars['Int'];
  groupId: Scalars['Int'];
  id: Scalars['Int'];
};

/** aggregated selection of "server_customuser_groups" */
export type UserGroupsAggregate = {
  __typename?: 'userGroupsAggregate';
  aggregate?: Maybe<UserGroupsAggregateFields>;
  nodes: Array<UserGroups>;
};

/** aggregate fields of "server_customuser_groups" */
export type UserGroupsAggregateFields = {
  __typename?: 'userGroupsAggregateFields';
  avg?: Maybe<UserGroupsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserGroupsMaxFields>;
  min?: Maybe<UserGroupsMinFields>;
  stddev?: Maybe<UserGroupsStddevFields>;
  stddevPop?: Maybe<UserGroupsStddev_PopFields>;
  stddevSamp?: Maybe<UserGroupsStddev_SampFields>;
  sum?: Maybe<UserGroupsSumFields>;
  varPop?: Maybe<UserGroupsVar_PopFields>;
  varSamp?: Maybe<UserGroupsVar_SampFields>;
  variance?: Maybe<UserGroupsVarianceFields>;
};


/** aggregate fields of "server_customuser_groups" */
export type UserGroupsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UserGroupsAvgFields = {
  __typename?: 'userGroupsAvgFields';
  customuserId?: Maybe<Scalars['Float']>;
  groupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_customuser_groups". All fields are combined with a logical 'AND'. */
export type UserGroupsBoolExp = {
  _and?: InputMaybe<Array<UserGroupsBoolExp>>;
  _not?: InputMaybe<UserGroupsBoolExp>;
  _or?: InputMaybe<Array<UserGroupsBoolExp>>;
  customuserId?: InputMaybe<IntComparisonExp>;
  groupId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_customuser_groups" */
export enum UserGroupsConstraint {
  /** unique or primary key constraint on columns "group_id", "customuser_id" */
  ServerCustomuserGroupsCustomuserIdGroupId_640ae33aUniq = 'server_customuser_groups_customuser_id_group_id_640ae33a_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerCustomuserGroupsPkey = 'server_customuser_groups_pkey'
}

/** input type for incrementing numeric columns in table "server_customuser_groups" */
export type UserGroupsIncInput = {
  customuserId?: InputMaybe<Scalars['Int']>;
  groupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_customuser_groups" */
export type UserGroupsInsertInput = {
  customuserId?: InputMaybe<Scalars['Int']>;
  groupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type UserGroupsMaxFields = {
  __typename?: 'userGroupsMaxFields';
  customuserId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type UserGroupsMinFields = {
  __typename?: 'userGroupsMinFields';
  customuserId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_customuser_groups" */
export type UserGroupsMutationResponse = {
  __typename?: 'userGroupsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserGroups>;
};

/** on_conflict condition type for table "server_customuser_groups" */
export type UserGroupsOnConflict = {
  constraint: UserGroupsConstraint;
  update_columns?: Array<UserGroupsUpdateColumn>;
  where?: InputMaybe<UserGroupsBoolExp>;
};

/** Ordering options when selecting data from "server_customuser_groups". */
export type UserGroupsOrderBy = {
  customuserId?: InputMaybe<OrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_customuser_groups */
export type UserGroupsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_customuser_groups" */
export enum UserGroupsSelectColumn {
  /** column name */
  CustomuserId = 'customuserId',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "server_customuser_groups" */
export type UserGroupsSetInput = {
  customuserId?: InputMaybe<Scalars['Int']>;
  groupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type UserGroupsStddevFields = {
  __typename?: 'userGroupsStddevFields';
  customuserId?: Maybe<Scalars['Float']>;
  groupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type UserGroupsStddev_PopFields = {
  __typename?: 'userGroupsStddev_popFields';
  customuserId?: Maybe<Scalars['Float']>;
  groupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type UserGroupsStddev_SampFields = {
  __typename?: 'userGroupsStddev_sampFields';
  customuserId?: Maybe<Scalars['Float']>;
  groupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type UserGroupsSumFields = {
  __typename?: 'userGroupsSumFields';
  customuserId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_customuser_groups" */
export enum UserGroupsUpdateColumn {
  /** column name */
  CustomuserId = 'customuserId',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id'
}

export type UserGroupsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserGroupsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserGroupsSetInput>;
  where: UserGroupsBoolExp;
};

/** aggregate var_pop on columns */
export type UserGroupsVar_PopFields = {
  __typename?: 'userGroupsVar_popFields';
  customuserId?: Maybe<Scalars['Float']>;
  groupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type UserGroupsVar_SampFields = {
  __typename?: 'userGroupsVar_sampFields';
  customuserId?: Maybe<Scalars['Float']>;
  groupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserGroupsVarianceFields = {
  __typename?: 'userGroupsVarianceFields';
  customuserId?: Maybe<Scalars['Float']>;
  groupId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "userGroups" */
export type UserGroups_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserGroups_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserGroups_StreamCursorValueInput = {
  customuserId?: InputMaybe<Scalars['Int']>;
  groupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for incrementing numeric columns in table "server_customuser" */
export type UserIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  userType?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_customuser" */
export type UserInsertInput = {
  assessmentsRendered?: InputMaybe<RenderedAssessmentArrRelInsertInput>;
  assignedDocumentsPendingReview?: InputMaybe<DocumentPendingReviewArrRelInsertInput>;
  caretimes?: InputMaybe<CaretimeArrRelInsertInput>;
  dateJoined?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  externalstaff?: InputMaybe<ExternalstaffObjRelInsertInput>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  internalstaff?: InputMaybe<InternalstaffObjRelInsertInput>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
  isSuperuser?: InputMaybe<Scalars['Boolean']>;
  lastLogin?: InputMaybe<Scalars['timestamptz']>;
  lastName?: InputMaybe<Scalars['String']>;
  messages?: InputMaybe<MessageArrRelInsertInput>;
  orderactionsEntered?: InputMaybe<OrderactionArrRelInsertInput>;
  ordersApprovedAsSupervisor?: InputMaybe<LegacyOrderArrRelInsertInput>;
  ordersFinalized?: InputMaybe<LegacyOrderArrRelInsertInput>;
  ordersRequested?: InputMaybe<LegacyOrderArrRelInsertInput>;
  password?: InputMaybe<Scalars['String']>;
  patientIntakesSubmitted?: InputMaybe<PatientIntakeArrRelInsertInput>;
  patientStatusActionsEntered?: InputMaybe<PatientStatusActionArrRelInsertInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  practiceGroupsMedicalDirected?: InputMaybe<PracticegroupArrRelInsertInput>;
  proceduresPerformed?: InputMaybe<ProcedureRenderedArrRelInsertInput>;
  telemedAppointmentRequestsApproved?: InputMaybe<TelemedAppointmentRequestArrRelInsertInput>;
  telemedAppointmentRequestsRequested?: InputMaybe<TelemedAppointmentRequestArrRelInsertInput>;
  ticketsCreated?: InputMaybe<TicketThreadArrRelInsertInput>;
  ticketsSubscribed?: InputMaybe<TicketSubscriptionArrRelInsertInput>;
  title?: InputMaybe<Scalars['String']>;
  treatmentPlanNotesEntered?: InputMaybe<TreatmentplanNoteArrRelInsertInput>;
  userType?: InputMaybe<Scalars['smallint']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserMaxFields = {
  __typename?: 'userMaxFields';
  dateJoined?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastLogin?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['smallint']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type UserMinFields = {
  __typename?: 'userMinFields';
  dateJoined?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastLogin?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['smallint']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "server_customuser" */
export type UserMutationResponse = {
  __typename?: 'userMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "server_customuser" */
export type UserObjRelInsertInput = {
  data: UserInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UserOnConflict>;
};

/** on_conflict condition type for table "server_customuser" */
export type UserOnConflict = {
  constraint: UserConstraint;
  update_columns?: Array<UserUpdateColumn>;
  where?: InputMaybe<UserBoolExp>;
};

/** Ordering options when selecting data from "server_customuser". */
export type UserOrderBy = {
  assessmentsRenderedAggregate?: InputMaybe<RenderedAssessmentAggregateOrderBy>;
  assignedDocumentsPendingReviewAggregate?: InputMaybe<DocumentPendingReviewAggregateOrderBy>;
  caretimesAggregate?: InputMaybe<CaretimeAggregateOrderBy>;
  dateJoined?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  externalstaff?: InputMaybe<ExternalstaffOrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  internalstaff?: InputMaybe<InternalstaffOrderBy>;
  isActive?: InputMaybe<OrderBy>;
  isStaff?: InputMaybe<OrderBy>;
  isSuperuser?: InputMaybe<OrderBy>;
  lastLogin?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  messagesAggregate?: InputMaybe<MessageAggregateOrderBy>;
  orderactionsEnteredAggregate?: InputMaybe<OrderactionAggregateOrderBy>;
  ordersApprovedAsSupervisorAggregate?: InputMaybe<LegacyOrderAggregateOrderBy>;
  ordersFinalizedAggregate?: InputMaybe<LegacyOrderAggregateOrderBy>;
  ordersRequestedAggregate?: InputMaybe<LegacyOrderAggregateOrderBy>;
  password?: InputMaybe<OrderBy>;
  patientIntakesSubmittedAggregate?: InputMaybe<PatientIntakeAggregateOrderBy>;
  patientStatusActionsEnteredAggregate?: InputMaybe<PatientStatusActionAggregateOrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  practiceGroupsMedicalDirectedAggregate?: InputMaybe<PracticegroupAggregateOrderBy>;
  proceduresPerformedAggregate?: InputMaybe<ProcedureRenderedAggregateOrderBy>;
  telemedAppointmentRequestsApprovedAggregate?: InputMaybe<TelemedAppointmentRequestAggregateOrderBy>;
  telemedAppointmentRequestsRequestedAggregate?: InputMaybe<TelemedAppointmentRequestAggregateOrderBy>;
  ticketsCreatedAggregate?: InputMaybe<TicketThreadAggregateOrderBy>;
  ticketsSubscribedAggregate?: InputMaybe<TicketSubscriptionAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
  treatmentPlanNotesEnteredAggregate?: InputMaybe<TreatmentplanNoteAggregateOrderBy>;
  userType?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_customuser_user_permissions" */
export type UserPermissions = {
  __typename?: 'userPermissions';
  customuserId: Scalars['Int'];
  id: Scalars['Int'];
  permissionId: Scalars['Int'];
};

/** aggregated selection of "server_customuser_user_permissions" */
export type UserPermissionsAggregate = {
  __typename?: 'userPermissionsAggregate';
  aggregate?: Maybe<UserPermissionsAggregateFields>;
  nodes: Array<UserPermissions>;
};

/** aggregate fields of "server_customuser_user_permissions" */
export type UserPermissionsAggregateFields = {
  __typename?: 'userPermissionsAggregateFields';
  avg?: Maybe<UserPermissionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserPermissionsMaxFields>;
  min?: Maybe<UserPermissionsMinFields>;
  stddev?: Maybe<UserPermissionsStddevFields>;
  stddevPop?: Maybe<UserPermissionsStddev_PopFields>;
  stddevSamp?: Maybe<UserPermissionsStddev_SampFields>;
  sum?: Maybe<UserPermissionsSumFields>;
  varPop?: Maybe<UserPermissionsVar_PopFields>;
  varSamp?: Maybe<UserPermissionsVar_SampFields>;
  variance?: Maybe<UserPermissionsVarianceFields>;
};


/** aggregate fields of "server_customuser_user_permissions" */
export type UserPermissionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserPermissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UserPermissionsAvgFields = {
  __typename?: 'userPermissionsAvgFields';
  customuserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permissionId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_customuser_user_permissions". All fields are combined with a logical 'AND'. */
export type UserPermissionsBoolExp = {
  _and?: InputMaybe<Array<UserPermissionsBoolExp>>;
  _not?: InputMaybe<UserPermissionsBoolExp>;
  _or?: InputMaybe<Array<UserPermissionsBoolExp>>;
  customuserId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  permissionId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_customuser_user_permissions" */
export enum UserPermissionsConstraint {
  /** unique or primary key constraint on columns "customuser_id", "permission_id" */
  ServerCustomuserUserPCustomuserIdPermission_24c5b4a1Uniq = 'server_customuser_user_p_customuser_id_permission_24c5b4a1_uniq',
  /** unique or primary key constraint on columns "id" */
  ServerCustomuserUserPermissionsPkey = 'server_customuser_user_permissions_pkey'
}

/** input type for incrementing numeric columns in table "server_customuser_user_permissions" */
export type UserPermissionsIncInput = {
  customuserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  permissionId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_customuser_user_permissions" */
export type UserPermissionsInsertInput = {
  customuserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  permissionId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type UserPermissionsMaxFields = {
  __typename?: 'userPermissionsMaxFields';
  customuserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  permissionId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type UserPermissionsMinFields = {
  __typename?: 'userPermissionsMinFields';
  customuserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  permissionId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_customuser_user_permissions" */
export type UserPermissionsMutationResponse = {
  __typename?: 'userPermissionsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserPermissions>;
};

/** on_conflict condition type for table "server_customuser_user_permissions" */
export type UserPermissionsOnConflict = {
  constraint: UserPermissionsConstraint;
  update_columns?: Array<UserPermissionsUpdateColumn>;
  where?: InputMaybe<UserPermissionsBoolExp>;
};

/** Ordering options when selecting data from "server_customuser_user_permissions". */
export type UserPermissionsOrderBy = {
  customuserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  permissionId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_customuser_user_permissions */
export type UserPermissionsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_customuser_user_permissions" */
export enum UserPermissionsSelectColumn {
  /** column name */
  CustomuserId = 'customuserId',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permissionId'
}

/** input type for updating data in table "server_customuser_user_permissions" */
export type UserPermissionsSetInput = {
  customuserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  permissionId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type UserPermissionsStddevFields = {
  __typename?: 'userPermissionsStddevFields';
  customuserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permissionId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type UserPermissionsStddev_PopFields = {
  __typename?: 'userPermissionsStddev_popFields';
  customuserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permissionId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type UserPermissionsStddev_SampFields = {
  __typename?: 'userPermissionsStddev_sampFields';
  customuserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permissionId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type UserPermissionsSumFields = {
  __typename?: 'userPermissionsSumFields';
  customuserId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  permissionId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_customuser_user_permissions" */
export enum UserPermissionsUpdateColumn {
  /** column name */
  CustomuserId = 'customuserId',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permissionId'
}

export type UserPermissionsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserPermissionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserPermissionsSetInput>;
  where: UserPermissionsBoolExp;
};

/** aggregate var_pop on columns */
export type UserPermissionsVar_PopFields = {
  __typename?: 'userPermissionsVar_popFields';
  customuserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permissionId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type UserPermissionsVar_SampFields = {
  __typename?: 'userPermissionsVar_sampFields';
  customuserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permissionId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserPermissionsVarianceFields = {
  __typename?: 'userPermissionsVarianceFields';
  customuserId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permissionId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "userPermissions" */
export type UserPermissions_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserPermissions_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserPermissions_StreamCursorValueInput = {
  customuserId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  permissionId?: InputMaybe<Scalars['Int']>;
};

/** primary key columns input for table: server_customuser */
export type UserPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_customuser" */
export enum UserSelectColumn {
  /** column name */
  DateJoined = 'dateJoined',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsStaff = 'isStaff',
  /** column name */
  IsSuperuser = 'isSuperuser',
  /** column name */
  LastLogin = 'lastLogin',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Title = 'title',
  /** column name */
  UserType = 'userType',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "server_customuser" */
export type UserSetInput = {
  dateJoined?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
  isSuperuser?: InputMaybe<Scalars['Boolean']>;
  lastLogin?: InputMaybe<Scalars['timestamptz']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['smallint']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UserStddevFields = {
  __typename?: 'userStddevFields';
  id?: Maybe<Scalars['Float']>;
  userType?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type UserStddev_PopFields = {
  __typename?: 'userStddev_popFields';
  id?: Maybe<Scalars['Float']>;
  userType?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type UserStddev_SampFields = {
  __typename?: 'userStddev_sampFields';
  id?: Maybe<Scalars['Float']>;
  userType?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type UserSumFields = {
  __typename?: 'userSumFields';
  id?: Maybe<Scalars['Int']>;
  userType?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_customuser" */
export enum UserUpdateColumn {
  /** column name */
  DateJoined = 'dateJoined',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsStaff = 'isStaff',
  /** column name */
  IsSuperuser = 'isSuperuser',
  /** column name */
  LastLogin = 'lastLogin',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Title = 'title',
  /** column name */
  UserType = 'userType',
  /** column name */
  Username = 'username'
}

export type UserUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSetInput>;
  where: UserBoolExp;
};

/** aggregate var_pop on columns */
export type UserVar_PopFields = {
  __typename?: 'userVar_popFields';
  id?: Maybe<Scalars['Float']>;
  userType?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type UserVar_SampFields = {
  __typename?: 'userVar_sampFields';
  id?: Maybe<Scalars['Float']>;
  userType?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserVarianceFields = {
  __typename?: 'userVarianceFields';
  id?: Maybe<Scalars['Float']>;
  userType?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user" */
export type User_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: User_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type User_StreamCursorValueInput = {
  dateJoined?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
  isSuperuser?: InputMaybe<Scalars['Boolean']>;
  lastLogin?: InputMaybe<Scalars['timestamptz']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['smallint']>;
  username?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "server_visitrebill" */
export type VisitRebill = {
  __typename?: 'visitRebill';
  /** An object relationship */
  appointment: Appointment;
  appointmentId: Scalars['Int'];
  id: Scalars['Int'];
  rebillDatetime: Scalars['timestamptz'];
  /** An object relationship */
  resultingAppointment?: Maybe<Appointment>;
  resultingAppointmentId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  resultingCaretime?: Maybe<Caretime>;
  resultingCaretimeId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "server_visitrebill" */
export type VisitRebillAggregate = {
  __typename?: 'visitRebillAggregate';
  aggregate?: Maybe<VisitRebillAggregateFields>;
  nodes: Array<VisitRebill>;
};

/** aggregate fields of "server_visitrebill" */
export type VisitRebillAggregateFields = {
  __typename?: 'visitRebillAggregateFields';
  avg?: Maybe<VisitRebillAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<VisitRebillMaxFields>;
  min?: Maybe<VisitRebillMinFields>;
  stddev?: Maybe<VisitRebillStddevFields>;
  stddevPop?: Maybe<VisitRebillStddev_PopFields>;
  stddevSamp?: Maybe<VisitRebillStddev_SampFields>;
  sum?: Maybe<VisitRebillSumFields>;
  varPop?: Maybe<VisitRebillVar_PopFields>;
  varSamp?: Maybe<VisitRebillVar_SampFields>;
  variance?: Maybe<VisitRebillVarianceFields>;
};


/** aggregate fields of "server_visitrebill" */
export type VisitRebillAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VisitRebillSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "server_visitrebill" */
export type VisitRebillAggregateOrderBy = {
  avg?: InputMaybe<VisitRebill_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<VisitRebill_Max_Order_By>;
  min?: InputMaybe<VisitRebill_Min_Order_By>;
  stddev?: InputMaybe<VisitRebill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<VisitRebill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<VisitRebill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<VisitRebill_Sum_Order_By>;
  var_pop?: InputMaybe<VisitRebill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<VisitRebill_Var_Samp_Order_By>;
  variance?: InputMaybe<VisitRebill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "server_visitrebill" */
export type VisitRebillArrRelInsertInput = {
  data: Array<VisitRebillInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<VisitRebillOnConflict>;
};

/** aggregate avg on columns */
export type VisitRebillAvgFields = {
  __typename?: 'visitRebillAvgFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  resultingAppointmentId?: Maybe<Scalars['Float']>;
  resultingCaretimeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_visitrebill". All fields are combined with a logical 'AND'. */
export type VisitRebillBoolExp = {
  _and?: InputMaybe<Array<VisitRebillBoolExp>>;
  _not?: InputMaybe<VisitRebillBoolExp>;
  _or?: InputMaybe<Array<VisitRebillBoolExp>>;
  appointment?: InputMaybe<AppointmentBoolExp>;
  appointmentId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  rebillDatetime?: InputMaybe<TimestamptzComparisonExp>;
  resultingAppointment?: InputMaybe<AppointmentBoolExp>;
  resultingAppointmentId?: InputMaybe<IntComparisonExp>;
  resultingCaretime?: InputMaybe<CaretimeBoolExp>;
  resultingCaretimeId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "server_visitrebill" */
export enum VisitRebillConstraint {
  /** unique or primary key constraint on columns "id" */
  ServerVisitrebillPkey = 'server_visitrebill_pkey'
}

/** input type for incrementing numeric columns in table "server_visitrebill" */
export type VisitRebillIncInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  resultingAppointmentId?: InputMaybe<Scalars['Int']>;
  resultingCaretimeId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "server_visitrebill" */
export type VisitRebillInsertInput = {
  appointment?: InputMaybe<AppointmentObjRelInsertInput>;
  appointmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  rebillDatetime?: InputMaybe<Scalars['timestamptz']>;
  resultingAppointment?: InputMaybe<AppointmentObjRelInsertInput>;
  resultingAppointmentId?: InputMaybe<Scalars['Int']>;
  resultingCaretime?: InputMaybe<CaretimeObjRelInsertInput>;
  resultingCaretimeId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type VisitRebillMaxFields = {
  __typename?: 'visitRebillMaxFields';
  appointmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  rebillDatetime?: Maybe<Scalars['timestamptz']>;
  resultingAppointmentId?: Maybe<Scalars['Int']>;
  resultingCaretimeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type VisitRebillMinFields = {
  __typename?: 'visitRebillMinFields';
  appointmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  rebillDatetime?: Maybe<Scalars['timestamptz']>;
  resultingAppointmentId?: Maybe<Scalars['Int']>;
  resultingCaretimeId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "server_visitrebill" */
export type VisitRebillMutationResponse = {
  __typename?: 'visitRebillMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VisitRebill>;
};

/** on_conflict condition type for table "server_visitrebill" */
export type VisitRebillOnConflict = {
  constraint: VisitRebillConstraint;
  update_columns?: Array<VisitRebillUpdateColumn>;
  where?: InputMaybe<VisitRebillBoolExp>;
};

/** Ordering options when selecting data from "server_visitrebill". */
export type VisitRebillOrderBy = {
  appointment?: InputMaybe<AppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rebillDatetime?: InputMaybe<OrderBy>;
  resultingAppointment?: InputMaybe<AppointmentOrderBy>;
  resultingAppointmentId?: InputMaybe<OrderBy>;
  resultingCaretime?: InputMaybe<CaretimeOrderBy>;
  resultingCaretimeId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_visitrebill */
export type VisitRebillPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_visitrebill" */
export enum VisitRebillSelectColumn {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Id = 'id',
  /** column name */
  RebillDatetime = 'rebillDatetime',
  /** column name */
  ResultingAppointmentId = 'resultingAppointmentId',
  /** column name */
  ResultingCaretimeId = 'resultingCaretimeId'
}

/** input type for updating data in table "server_visitrebill" */
export type VisitRebillSetInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  rebillDatetime?: InputMaybe<Scalars['timestamptz']>;
  resultingAppointmentId?: InputMaybe<Scalars['Int']>;
  resultingCaretimeId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VisitRebillStddevFields = {
  __typename?: 'visitRebillStddevFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  resultingAppointmentId?: Maybe<Scalars['Float']>;
  resultingCaretimeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type VisitRebillStddev_PopFields = {
  __typename?: 'visitRebillStddev_popFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  resultingAppointmentId?: Maybe<Scalars['Float']>;
  resultingCaretimeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type VisitRebillStddev_SampFields = {
  __typename?: 'visitRebillStddev_sampFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  resultingAppointmentId?: Maybe<Scalars['Float']>;
  resultingCaretimeId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type VisitRebillSumFields = {
  __typename?: 'visitRebillSumFields';
  appointmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  resultingAppointmentId?: Maybe<Scalars['Int']>;
  resultingCaretimeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "server_visitrebill" */
export enum VisitRebillUpdateColumn {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Id = 'id',
  /** column name */
  RebillDatetime = 'rebillDatetime',
  /** column name */
  ResultingAppointmentId = 'resultingAppointmentId',
  /** column name */
  ResultingCaretimeId = 'resultingCaretimeId'
}

export type VisitRebillUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VisitRebillIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitRebillSetInput>;
  where: VisitRebillBoolExp;
};

/** aggregate var_pop on columns */
export type VisitRebillVar_PopFields = {
  __typename?: 'visitRebillVar_popFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  resultingAppointmentId?: Maybe<Scalars['Float']>;
  resultingCaretimeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type VisitRebillVar_SampFields = {
  __typename?: 'visitRebillVar_sampFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  resultingAppointmentId?: Maybe<Scalars['Float']>;
  resultingCaretimeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type VisitRebillVarianceFields = {
  __typename?: 'visitRebillVarianceFields';
  appointmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  resultingAppointmentId?: Maybe<Scalars['Float']>;
  resultingCaretimeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "server_visitrebill" */
export type VisitRebill_Avg_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  resultingAppointmentId?: InputMaybe<OrderBy>;
  resultingCaretimeId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "server_visitrebill" */
export type VisitRebill_Max_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rebillDatetime?: InputMaybe<OrderBy>;
  resultingAppointmentId?: InputMaybe<OrderBy>;
  resultingCaretimeId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "server_visitrebill" */
export type VisitRebill_Min_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rebillDatetime?: InputMaybe<OrderBy>;
  resultingAppointmentId?: InputMaybe<OrderBy>;
  resultingCaretimeId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "server_visitrebill" */
export type VisitRebill_Stddev_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  resultingAppointmentId?: InputMaybe<OrderBy>;
  resultingCaretimeId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "server_visitrebill" */
export type VisitRebill_Stddev_Pop_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  resultingAppointmentId?: InputMaybe<OrderBy>;
  resultingCaretimeId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "server_visitrebill" */
export type VisitRebill_Stddev_Samp_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  resultingAppointmentId?: InputMaybe<OrderBy>;
  resultingCaretimeId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "visitRebill" */
export type VisitRebill_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VisitRebill_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VisitRebill_StreamCursorValueInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  rebillDatetime?: InputMaybe<Scalars['timestamptz']>;
  resultingAppointmentId?: InputMaybe<Scalars['Int']>;
  resultingCaretimeId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "server_visitrebill" */
export type VisitRebill_Sum_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  resultingAppointmentId?: InputMaybe<OrderBy>;
  resultingCaretimeId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "server_visitrebill" */
export type VisitRebill_Var_Pop_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  resultingAppointmentId?: InputMaybe<OrderBy>;
  resultingCaretimeId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "server_visitrebill" */
export type VisitRebill_Var_Samp_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  resultingAppointmentId?: InputMaybe<OrderBy>;
  resultingCaretimeId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "server_visitrebill" */
export type VisitRebill_Variance_Order_By = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  resultingAppointmentId?: InputMaybe<OrderBy>;
  resultingCaretimeId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_vitals" */
export type Vitals = {
  __typename?: 'vitals';
  /** An object relationship */
  appointment: Appointment;
  appointmentId: Scalars['Int'];
  bmi?: Maybe<Scalars['numeric']>;
  bpdiastolic?: Maybe<Scalars['smallint']>;
  bpsystolic?: Maybe<Scalars['smallint']>;
  height?: Maybe<Scalars['numeric']>;
  id: Scalars['Int'];
  pulse?: Maybe<Scalars['smallint']>;
  respiratoryrate?: Maybe<Scalars['smallint']>;
  smokingStatus?: Maybe<Scalars['smallint']>;
  spo2?: Maybe<Scalars['smallint']>;
  temperature?: Maybe<Scalars['numeric']>;
  weight?: Maybe<Scalars['numeric']>;
  weightMethod?: Maybe<Scalars['smallint']>;
};

/** aggregated selection of "server_vitals" */
export type VitalsAggregate = {
  __typename?: 'vitalsAggregate';
  aggregate?: Maybe<VitalsAggregateFields>;
  nodes: Array<Vitals>;
};

/** aggregate fields of "server_vitals" */
export type VitalsAggregateFields = {
  __typename?: 'vitalsAggregateFields';
  avg?: Maybe<VitalsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<VitalsMaxFields>;
  min?: Maybe<VitalsMinFields>;
  stddev?: Maybe<VitalsStddevFields>;
  stddevPop?: Maybe<VitalsStddev_PopFields>;
  stddevSamp?: Maybe<VitalsStddev_SampFields>;
  sum?: Maybe<VitalsSumFields>;
  varPop?: Maybe<VitalsVar_PopFields>;
  varSamp?: Maybe<VitalsVar_SampFields>;
  variance?: Maybe<VitalsVarianceFields>;
};


/** aggregate fields of "server_vitals" */
export type VitalsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VitalsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type VitalsAvgFields = {
  __typename?: 'vitalsAvgFields';
  appointmentId?: Maybe<Scalars['Float']>;
  bmi?: Maybe<Scalars['Float']>;
  bpdiastolic?: Maybe<Scalars['Float']>;
  bpsystolic?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pulse?: Maybe<Scalars['Float']>;
  respiratoryrate?: Maybe<Scalars['Float']>;
  smokingStatus?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  weightMethod?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "server_vitals". All fields are combined with a logical 'AND'. */
export type VitalsBoolExp = {
  _and?: InputMaybe<Array<VitalsBoolExp>>;
  _not?: InputMaybe<VitalsBoolExp>;
  _or?: InputMaybe<Array<VitalsBoolExp>>;
  appointment?: InputMaybe<AppointmentBoolExp>;
  appointmentId?: InputMaybe<IntComparisonExp>;
  bmi?: InputMaybe<NumericComparisonExp>;
  bpdiastolic?: InputMaybe<SmallintComparisonExp>;
  bpsystolic?: InputMaybe<SmallintComparisonExp>;
  height?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  pulse?: InputMaybe<SmallintComparisonExp>;
  respiratoryrate?: InputMaybe<SmallintComparisonExp>;
  smokingStatus?: InputMaybe<SmallintComparisonExp>;
  spo2?: InputMaybe<SmallintComparisonExp>;
  temperature?: InputMaybe<NumericComparisonExp>;
  weight?: InputMaybe<NumericComparisonExp>;
  weightMethod?: InputMaybe<SmallintComparisonExp>;
};

/** unique or primary key constraints on table "server_vitals" */
export enum VitalsConstraint {
  /** unique or primary key constraint on columns "appointment_id" */
  ServerVitalsAppointmentIdKey = 'server_vitals_appointment_id_key',
  /** unique or primary key constraint on columns "id" */
  ServerVitalsPkey = 'server_vitals_pkey'
}

/** input type for incrementing numeric columns in table "server_vitals" */
export type VitalsIncInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  bmi?: InputMaybe<Scalars['numeric']>;
  bpdiastolic?: InputMaybe<Scalars['smallint']>;
  bpsystolic?: InputMaybe<Scalars['smallint']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  pulse?: InputMaybe<Scalars['smallint']>;
  respiratoryrate?: InputMaybe<Scalars['smallint']>;
  smokingStatus?: InputMaybe<Scalars['smallint']>;
  spo2?: InputMaybe<Scalars['smallint']>;
  temperature?: InputMaybe<Scalars['numeric']>;
  weight?: InputMaybe<Scalars['numeric']>;
  weightMethod?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "server_vitals" */
export type VitalsInsertInput = {
  appointment?: InputMaybe<AppointmentObjRelInsertInput>;
  appointmentId?: InputMaybe<Scalars['Int']>;
  bmi?: InputMaybe<Scalars['numeric']>;
  bpdiastolic?: InputMaybe<Scalars['smallint']>;
  bpsystolic?: InputMaybe<Scalars['smallint']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  pulse?: InputMaybe<Scalars['smallint']>;
  respiratoryrate?: InputMaybe<Scalars['smallint']>;
  smokingStatus?: InputMaybe<Scalars['smallint']>;
  spo2?: InputMaybe<Scalars['smallint']>;
  temperature?: InputMaybe<Scalars['numeric']>;
  weight?: InputMaybe<Scalars['numeric']>;
  weightMethod?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type VitalsMaxFields = {
  __typename?: 'vitalsMaxFields';
  appointmentId?: Maybe<Scalars['Int']>;
  bmi?: Maybe<Scalars['numeric']>;
  bpdiastolic?: Maybe<Scalars['smallint']>;
  bpsystolic?: Maybe<Scalars['smallint']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  pulse?: Maybe<Scalars['smallint']>;
  respiratoryrate?: Maybe<Scalars['smallint']>;
  smokingStatus?: Maybe<Scalars['smallint']>;
  spo2?: Maybe<Scalars['smallint']>;
  temperature?: Maybe<Scalars['numeric']>;
  weight?: Maybe<Scalars['numeric']>;
  weightMethod?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type VitalsMinFields = {
  __typename?: 'vitalsMinFields';
  appointmentId?: Maybe<Scalars['Int']>;
  bmi?: Maybe<Scalars['numeric']>;
  bpdiastolic?: Maybe<Scalars['smallint']>;
  bpsystolic?: Maybe<Scalars['smallint']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  pulse?: Maybe<Scalars['smallint']>;
  respiratoryrate?: Maybe<Scalars['smallint']>;
  smokingStatus?: Maybe<Scalars['smallint']>;
  spo2?: Maybe<Scalars['smallint']>;
  temperature?: Maybe<Scalars['numeric']>;
  weight?: Maybe<Scalars['numeric']>;
  weightMethod?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "server_vitals" */
export type VitalsMutationResponse = {
  __typename?: 'vitalsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vitals>;
};

/** input type for inserting object relation for remote table "server_vitals" */
export type VitalsObjRelInsertInput = {
  data: VitalsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<VitalsOnConflict>;
};

/** on_conflict condition type for table "server_vitals" */
export type VitalsOnConflict = {
  constraint: VitalsConstraint;
  update_columns?: Array<VitalsUpdateColumn>;
  where?: InputMaybe<VitalsBoolExp>;
};

/** Ordering options when selecting data from "server_vitals". */
export type VitalsOrderBy = {
  appointment?: InputMaybe<AppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
  bmi?: InputMaybe<OrderBy>;
  bpdiastolic?: InputMaybe<OrderBy>;
  bpsystolic?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  pulse?: InputMaybe<OrderBy>;
  respiratoryrate?: InputMaybe<OrderBy>;
  smokingStatus?: InputMaybe<OrderBy>;
  spo2?: InputMaybe<OrderBy>;
  temperature?: InputMaybe<OrderBy>;
  weight?: InputMaybe<OrderBy>;
  weightMethod?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: server_vitals */
export type VitalsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "server_vitals" */
export enum VitalsSelectColumn {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Bmi = 'bmi',
  /** column name */
  Bpdiastolic = 'bpdiastolic',
  /** column name */
  Bpsystolic = 'bpsystolic',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  Pulse = 'pulse',
  /** column name */
  Respiratoryrate = 'respiratoryrate',
  /** column name */
  SmokingStatus = 'smokingStatus',
  /** column name */
  Spo2 = 'spo2',
  /** column name */
  Temperature = 'temperature',
  /** column name */
  Weight = 'weight',
  /** column name */
  WeightMethod = 'weightMethod'
}

/** input type for updating data in table "server_vitals" */
export type VitalsSetInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  bmi?: InputMaybe<Scalars['numeric']>;
  bpdiastolic?: InputMaybe<Scalars['smallint']>;
  bpsystolic?: InputMaybe<Scalars['smallint']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  pulse?: InputMaybe<Scalars['smallint']>;
  respiratoryrate?: InputMaybe<Scalars['smallint']>;
  smokingStatus?: InputMaybe<Scalars['smallint']>;
  spo2?: InputMaybe<Scalars['smallint']>;
  temperature?: InputMaybe<Scalars['numeric']>;
  weight?: InputMaybe<Scalars['numeric']>;
  weightMethod?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type VitalsStddevFields = {
  __typename?: 'vitalsStddevFields';
  appointmentId?: Maybe<Scalars['Float']>;
  bmi?: Maybe<Scalars['Float']>;
  bpdiastolic?: Maybe<Scalars['Float']>;
  bpsystolic?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pulse?: Maybe<Scalars['Float']>;
  respiratoryrate?: Maybe<Scalars['Float']>;
  smokingStatus?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  weightMethod?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type VitalsStddev_PopFields = {
  __typename?: 'vitalsStddev_popFields';
  appointmentId?: Maybe<Scalars['Float']>;
  bmi?: Maybe<Scalars['Float']>;
  bpdiastolic?: Maybe<Scalars['Float']>;
  bpsystolic?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pulse?: Maybe<Scalars['Float']>;
  respiratoryrate?: Maybe<Scalars['Float']>;
  smokingStatus?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  weightMethod?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type VitalsStddev_SampFields = {
  __typename?: 'vitalsStddev_sampFields';
  appointmentId?: Maybe<Scalars['Float']>;
  bmi?: Maybe<Scalars['Float']>;
  bpdiastolic?: Maybe<Scalars['Float']>;
  bpsystolic?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pulse?: Maybe<Scalars['Float']>;
  respiratoryrate?: Maybe<Scalars['Float']>;
  smokingStatus?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  weightMethod?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type VitalsSumFields = {
  __typename?: 'vitalsSumFields';
  appointmentId?: Maybe<Scalars['Int']>;
  bmi?: Maybe<Scalars['numeric']>;
  bpdiastolic?: Maybe<Scalars['smallint']>;
  bpsystolic?: Maybe<Scalars['smallint']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  pulse?: Maybe<Scalars['smallint']>;
  respiratoryrate?: Maybe<Scalars['smallint']>;
  smokingStatus?: Maybe<Scalars['smallint']>;
  spo2?: Maybe<Scalars['smallint']>;
  temperature?: Maybe<Scalars['numeric']>;
  weight?: Maybe<Scalars['numeric']>;
  weightMethod?: Maybe<Scalars['smallint']>;
};

/** update columns of table "server_vitals" */
export enum VitalsUpdateColumn {
  /** column name */
  AppointmentId = 'appointmentId',
  /** column name */
  Bmi = 'bmi',
  /** column name */
  Bpdiastolic = 'bpdiastolic',
  /** column name */
  Bpsystolic = 'bpsystolic',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  Pulse = 'pulse',
  /** column name */
  Respiratoryrate = 'respiratoryrate',
  /** column name */
  SmokingStatus = 'smokingStatus',
  /** column name */
  Spo2 = 'spo2',
  /** column name */
  Temperature = 'temperature',
  /** column name */
  Weight = 'weight',
  /** column name */
  WeightMethod = 'weightMethod'
}

export type VitalsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VitalsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VitalsSetInput>;
  where: VitalsBoolExp;
};

/** aggregate var_pop on columns */
export type VitalsVar_PopFields = {
  __typename?: 'vitalsVar_popFields';
  appointmentId?: Maybe<Scalars['Float']>;
  bmi?: Maybe<Scalars['Float']>;
  bpdiastolic?: Maybe<Scalars['Float']>;
  bpsystolic?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pulse?: Maybe<Scalars['Float']>;
  respiratoryrate?: Maybe<Scalars['Float']>;
  smokingStatus?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  weightMethod?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type VitalsVar_SampFields = {
  __typename?: 'vitalsVar_sampFields';
  appointmentId?: Maybe<Scalars['Float']>;
  bmi?: Maybe<Scalars['Float']>;
  bpdiastolic?: Maybe<Scalars['Float']>;
  bpsystolic?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pulse?: Maybe<Scalars['Float']>;
  respiratoryrate?: Maybe<Scalars['Float']>;
  smokingStatus?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  weightMethod?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type VitalsVarianceFields = {
  __typename?: 'vitalsVarianceFields';
  appointmentId?: Maybe<Scalars['Float']>;
  bmi?: Maybe<Scalars['Float']>;
  bpdiastolic?: Maybe<Scalars['Float']>;
  bpsystolic?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pulse?: Maybe<Scalars['Float']>;
  respiratoryrate?: Maybe<Scalars['Float']>;
  smokingStatus?: Maybe<Scalars['Float']>;
  spo2?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  weightMethod?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "vitals" */
export type Vitals_StreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: Vitals_StreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type Vitals_StreamCursorValueInput = {
  appointmentId?: InputMaybe<Scalars['Int']>;
  bmi?: InputMaybe<Scalars['numeric']>;
  bpdiastolic?: InputMaybe<Scalars['smallint']>;
  bpsystolic?: InputMaybe<Scalars['smallint']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  pulse?: InputMaybe<Scalars['smallint']>;
  respiratoryrate?: InputMaybe<Scalars['smallint']>;
  smokingStatus?: InputMaybe<Scalars['smallint']>;
  spo2?: InputMaybe<Scalars['smallint']>;
  temperature?: InputMaybe<Scalars['numeric']>;
  weight?: InputMaybe<Scalars['numeric']>;
  weightMethod?: InputMaybe<Scalars['smallint']>;
};

export type AppointmentDetailsFragment = { __typename?: 'appointment', id: number, appointmentDatetime?: any | null, externalJson?: any | null, virtualVisit: boolean, status: any, placeOfService: any, notes: string, clinicalnote?: { __typename?: 'clinicalnote', locked: boolean } | null, patient: { __typename?: 'patient', id: number, firstName: string, lastName: string, dateOfBirth?: any | null }, provider: { __typename?: 'provider', nPI: string, internalstaff: { __typename?: 'internalstaff', userId: number, user: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } } } };

export type AppointmentListBasicsFragment = { __typename?: 'appointment', id: number, appointmentDatetime?: any | null, status: any, placeOfService: any, provider: { __typename?: 'provider', internalstaff: { __typename?: 'internalstaff', user: { __typename?: 'user', id: number, firstName: string, lastLogin?: any | null, title?: string | null } } } };

export type InfoFragment = { __typename?: 'appointment', id: number, appointmentDatetime?: any | null, externalJson?: any | null, virtualVisit: boolean, status: any, placeOfService: any, notes: string, clinicalnote?: { __typename?: 'clinicalnote', locked: boolean } | null, patient: { __typename?: 'patient', id: number, firstName: string, lastName: string, dateOfBirth?: any | null }, provider: { __typename?: 'provider', nPI: string, internalstaff: { __typename?: 'internalstaff', userId: number, user: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } } } };

export type DocumentBasicsFragment = { __typename?: 'document', id: number, category: any, datetime: any, description?: string | null, location?: string | null, contentType?: string | null };

export type DprBasicsFragment = { __typename?: 'documentPendingReview', id: number, requiresFurtherAttention: boolean, read: boolean, document: { __typename?: 'document', id: number, category: any, datetime: any, description?: string | null, location?: string | null, contentType?: string | null } };

export type MedicationBasicsFragment = { __typename?: 'medication', id: number, name?: string | null, drugName?: string | null, active: boolean, sig?: string | null };

export type OrderableBasicsFragment = { __typename?: 'orderable', id: number, title: string, description: string, category: { __typename?: 'orderableCategory', id: number, description: string } };

export type OrderableFieldDetailsFragment = { __typename?: 'orderOrderableField', id: number, name: string, insertText: string, orderWithinGroup: any, patientQualification: boolean, type: any, data?: any | null };

export type OrderableFieldsFragment = { __typename?: 'orderable', orderOrderableFields: Array<{ __typename?: 'orderOrderableField', id: number, name: string, insertText: string, orderWithinGroup: any, patientQualification: boolean, type: any, data?: any | null }> };

export type OrderablesForCategoryQueryVariables = Exact<{
  category?: InputMaybe<Scalars['Int']>;
}>;


export type OrderablesForCategoryQuery = { __typename?: 'query_root', orderable: Array<{ __typename?: 'orderable', id: number, description: string, title: string, orderOrderableFields: Array<{ __typename?: 'orderOrderableField', id: number, name: string, insertText: string, orderWithinGroup: any, patientQualification: boolean, type: any, data?: any | null }> }> };

export type OrderableDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type OrderableDetailsQuery = { __typename?: 'query_root', orderableByPk?: { __typename?: 'orderable', id: number, title: string, description: string, category: { __typename?: 'orderableCategory', id: number, description: string }, orderOrderableFields: Array<{ __typename?: 'orderOrderableField', id: number, name: string, insertText: string, orderWithinGroup: any, patientQualification: boolean, type: any, data?: any | null }> } | null };

export type OrderActionBasicsFragment = { __typename?: 'orderaction', id: number, datetimeEntered: any, action: any };

export type OrderBasicsFragment = { __typename?: 'baseorder', id: number, dateEffective?: any | null, dateEnd?: any | null, approved?: boolean | null, expired?: boolean | null, cancelled?: boolean | null, orderActions: Array<{ __typename?: 'orderaction', id: number, datetimeEntered: any, action: any }> };

export type MedicationOrderBasicsFragment = { __typename?: 'medicationOrder', baseorder: { __typename?: 'baseorder', id: number, dateEffective?: any | null, dateEnd?: any | null, approved?: boolean | null, expired?: boolean | null, cancelled?: boolean | null, orderActions: Array<{ __typename?: 'orderaction', id: number, datetimeEntered: any, action: any }> }, medicationOrderSpecifics: Array<{ __typename?: 'medicationorderMedications', medication: { __typename?: 'medication', id: number, name?: string | null, drugName?: string | null, active: boolean, sig?: string | null } }> };

export type OrderableOrderBasicsFragment = { __typename?: 'orderableOrder', baseorder: { __typename?: 'baseorder', id: number, dateEffective?: any | null, dateEnd?: any | null, approved?: boolean | null, expired?: boolean | null, cancelled?: boolean | null, orderActions: Array<{ __typename?: 'orderaction', id: number, datetimeEntered: any, action: any }> }, orderable?: { __typename?: 'orderable', id: number, title: string, description: string, category: { __typename?: 'orderableCategory', id: number, description: string } } | null };

export type FreeTextOrderBasicsFragment = { __typename?: 'freeTextOrder', orderText?: string | null, baseorder: { __typename?: 'baseorder', id: number, dateEffective?: any | null, dateEnd?: any | null, approved?: boolean | null, expired?: boolean | null, cancelled?: boolean | null, orderActions: Array<{ __typename?: 'orderaction', id: number, datetimeEntered: any, action: any }> } };

export type OrderActionDetailsFragment = { __typename?: 'orderaction', responsibleProvider?: { __typename?: 'provider', nPI: string, internalstaff: { __typename?: 'internalstaff', userId: number, user: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } } } | null, enteringUser: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } };

export type OrderDetailsFragment = { __typename?: 'baseorder', orderActions: Array<{ __typename?: 'orderaction', responsibleProvider?: { __typename?: 'provider', nPI: string, internalstaff: { __typename?: 'internalstaff', userId: number, user: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } } } | null, enteringUser: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } }> };

export type OrderDispositionDetailsFragment = { __typename?: 'baseorder', active?: boolean | null, approved?: boolean | null, cancelled?: boolean | null, expired?: boolean | null, expirationDate?: any | null, calculatedEffectiveDate?: boolean | null };

export type OrderableOrderFieldValuesFragment = { __typename?: 'orderableOrder', fieldValues: Array<{ __typename?: 'legacyOrderOrderableFieldValue', id: number, value: string, field: { __typename?: 'orderOrderableField', id: number, name: string, insertText: string, orderWithinGroup: any, patientQualification: boolean, type: any, data?: any | null } }> };

export type MyOrdersListQueryVariables = Exact<{ [key: string]: never; }>;


export type MyOrdersListQuery = { __typename?: 'query_root', baseorder: Array<{ __typename?: 'baseorder', id: number, patient: { __typename?: 'patient', id: number, firstName: string, lastName: string, dateOfBirth?: any | null } }> };

export type AllPatientOrdersQueryVariables = Exact<{
  patientId?: InputMaybe<Scalars['Int']>;
  details: Scalars['Boolean'];
}>;


export type AllPatientOrdersQuery = { __typename?: 'query_root', orderableOrder: Array<{ __typename?: 'orderableOrder', baseorder: { __typename?: 'baseorder', id: number, dateEffective?: any | null, dateEnd?: any | null, approved?: boolean | null, expired?: boolean | null, cancelled?: boolean | null, orderActions: Array<{ __typename?: 'orderaction', id: number, datetimeEntered: any, action: any, responsibleProvider?: { __typename?: 'provider', nPI: string, internalstaff: { __typename?: 'internalstaff', userId: number, user: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } } } | null, enteringUser: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } }> }, orderable?: { __typename?: 'orderable', id: number, title: string, description: string, category: { __typename?: 'orderableCategory', id: number, description: string } } | null }>, medicationOrder: Array<{ __typename?: 'medicationOrder', baseorder: { __typename?: 'baseorder', id: number, dateEffective?: any | null, dateEnd?: any | null, approved?: boolean | null, expired?: boolean | null, cancelled?: boolean | null, orderActions: Array<{ __typename?: 'orderaction', id: number, datetimeEntered: any, action: any }> }, medicationOrderSpecifics: Array<{ __typename?: 'medicationorderMedications', medication: { __typename?: 'medication', id: number, name?: string | null, drugName?: string | null, active: boolean, sig?: string | null } }> }>, freeTextOrder: Array<{ __typename?: 'freeTextOrder', orderText?: string | null, baseorder: { __typename?: 'baseorder', id: number, dateEffective?: any | null, dateEnd?: any | null, approved?: boolean | null, expired?: boolean | null, cancelled?: boolean | null, orderActions: Array<{ __typename?: 'orderaction', id: number, datetimeEntered: any, action: any }> } }> };

export type OrderableOrdersForPatientQueryVariables = Exact<{
  patientId: Scalars['Int'];
  category?: InputMaybe<Scalars['Int']>;
  orderableId?: InputMaybe<Scalars['Int']>;
  active?: InputMaybe<Scalars['Boolean']>;
}>;


export type OrderableOrdersForPatientQuery = { __typename?: 'query_root', orderableOrder: Array<{ __typename?: 'orderableOrder', baseorder: { __typename?: 'baseorder', active?: boolean | null, approved?: boolean | null, cancelled?: boolean | null, expired?: boolean | null, expirationDate?: any | null, calculatedEffectiveDate?: boolean | null, id: number, dateEffective?: any | null, dateEnd?: any | null, orderActions: Array<{ __typename?: 'orderaction', id: number, datetimeEntered: any, action: any, responsibleProvider?: { __typename?: 'provider', nPI: string, internalstaff: { __typename?: 'internalstaff', userId: number, user: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } } } | null, enteringUser: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } }> }, orderable?: { __typename?: 'orderable', id: number, title: string, description: string, category: { __typename?: 'orderableCategory', id: number, description: string } } | null, fieldValues: Array<{ __typename?: 'legacyOrderOrderableFieldValue', id: number, value: string, field: { __typename?: 'orderOrderableField', id: number, name: string, insertText: string, orderWithinGroup: any, patientQualification: boolean, type: any, data?: any | null } }> }> };

export type CommunicationDetailsFragment = { __typename?: 'patientCommunication', id: number, text: string, datesigned?: any | null, caretime: { __typename?: 'caretime', id: number, datetime: any, duration: any, user?: { __typename?: 'user', firstName: string, lastName: string, title?: string | null } | null } };

export type DemographicsFragment = { __typename?: 'patient', address?: string | null, city?: string | null, state?: string | null, zipCode?: string | null, gender: any, primaryPhone?: string | null, secondaryPhone?: string | null, admittedStatus: any, insurancePlans: Array<{ __typename?: 'insuranceplan', id: number, subscriberIdNumber?: string | null, rank: any, active: boolean, payer: { __typename?: 'payer', name: string, id: number } }> };

export type BasicsFragment = { __typename?: 'patient', id: number, firstName: string, lastName: string, dateOfBirth?: any | null };

export type StatusBasicsFragment = { __typename?: 'patientStatus', id: number, name: string, underCare: boolean };

export type StatusActionBasicsFragment = { __typename?: 'patientStatusAction', id: number, datetimeEffective: any, datetimeEntered: any, datetimeEnd?: any | null, notes: string, status: { __typename?: 'patientStatus', id: number, name: string, underCare: boolean } };

export type GetPatientQueryVariables = Exact<{
  id: Scalars['Int'];
  details: Scalars['Boolean'];
}>;


export type GetPatientQuery = { __typename?: 'query_root', patientByPk?: { __typename?: 'patient', id: number, firstName: string, lastName: string, dateOfBirth?: any | null, address?: string | null, city?: string | null, state?: string | null, zipCode?: string | null, gender: any, primaryPhone?: string | null, secondaryPhone?: string | null, admittedStatus: any, insurancePlans: Array<{ __typename?: 'insuranceplan', id: number, subscriberIdNumber?: string | null, rank: any, active: boolean, payer: { __typename?: 'payer', name: string, id: number } }> } | null };

export type GetPatientHistoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPatientHistoryQuery = { __typename?: 'query_root', patientByPk?: { __typename?: 'patient', id: number, firstName: string, lastName: string, dateOfBirth?: any | null, documents: Array<{ __typename?: 'document', id: number, category: any, datetime: any, description?: string | null, location?: string | null, contentType?: string | null }>, documentsPendingReviewGuesses: Array<{ __typename?: 'documentPendingReview', id: number, requiresFurtherAttention: boolean, read: boolean, document: { __typename?: 'document', id: number, category: any, datetime: any, description?: string | null, location?: string | null, contentType?: string | null } }>, ticketthreads: Array<{ __typename?: 'ticketThread', id: number, title: string, dateCreated: any, emailSubmitted?: string | null, userCreated?: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } | null }> } | null };

export type GetPatientCommunicationsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPatientCommunicationsQuery = { __typename?: 'query_root', patientCommunication: Array<{ __typename?: 'patientCommunication', id: number, text: string, datesigned?: any | null, caretime: { __typename?: 'caretime', id: number, datetime: any, duration: any, user?: { __typename?: 'user', firstName: string, lastName: string, title?: string | null } | null } }> };

export type MyPatientListQueryVariables = Exact<{ [key: string]: never; }>;


export type MyPatientListQuery = { __typename?: 'query_root', patient: Array<{ __typename?: 'patient', id: number, firstName: string, lastName: string, dateOfBirth?: any | null }> };

export type PatientStatusQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PatientStatusQuery = { __typename?: 'query_root', patientByPk?: { __typename?: 'patient', id: number, statusActions: Array<{ __typename?: 'patientStatusAction', id: number, datetimeEffective: any, datetimeEntered: any, datetimeEnd?: any | null, notes: string, status: { __typename?: 'patientStatus', id: number, name: string, underCare: boolean } }> } | null };

export type GetPatientOrdersQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPatientOrdersQuery = { __typename?: 'query_root', patientByPk?: { __typename?: 'patient', id: number, orders: Array<{ __typename?: 'legacyOrder', id: number }> } | null };

export type GetPatientAppointmentsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPatientAppointmentsQuery = { __typename?: 'query_root', patientByPk?: { __typename?: 'patient', id: number, appointments: Array<{ __typename?: 'appointment', id: number, appointmentDatetime?: any | null, status: any, placeOfService: any, provider: { __typename?: 'provider', internalstaff: { __typename?: 'internalstaff', user: { __typename?: 'user', id: number, firstName: string, lastLogin?: any | null, title?: string | null } } } }> } | null };

export type TicketThreadBasicsFragment = { __typename?: 'ticketThread', id: number, title: string, dateCreated: any, emailSubmitted?: string | null, userCreated?: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } | null };

export type NameFragment = { __typename?: 'user', firstName: string, lastName: string, title?: string | null };

export type UserBasicsFragment = { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null };

export type ProviderDetailsFragment = { __typename?: 'provider', nPI: string, internalstaff: { __typename?: 'internalstaff', userId: number, user: { __typename?: 'user', id: number, firstName: string, lastName: string, title?: string | null } } };

export const AppointmentListBasicsFragmentDoc = gql`
    fragment AppointmentListBasics on appointment {
  id
  appointmentDatetime
  status
  placeOfService
  provider {
    internalstaff {
      user {
        id
        firstName
        lastLogin
        title
      }
    }
  }
}
    `;
export const BasicsFragmentDoc = gql`
    fragment Basics on patient {
  id
  firstName
  lastName
  dateOfBirth
}
    `;
export const NameFragmentDoc = gql`
    fragment Name on user {
  firstName
  lastName
  title
}
    `;
export const UserBasicsFragmentDoc = gql`
    fragment UserBasics on user {
  ...Name
  id
}
    ${NameFragmentDoc}`;
export const ProviderDetailsFragmentDoc = gql`
    fragment ProviderDetails on provider {
  internalstaff {
    userId
    user {
      ...UserBasics
    }
  }
  nPI
}
    ${UserBasicsFragmentDoc}`;
export const AppointmentDetailsFragmentDoc = gql`
    fragment AppointmentDetails on appointment {
  id
  appointmentDatetime
  externalJson
  virtualVisit
  status
  clinicalnote {
    locked
  }
  patient {
    ...Basics
  }
  provider {
    ...ProviderDetails
  }
  placeOfService
  notes
}
    ${BasicsFragmentDoc}
${ProviderDetailsFragmentDoc}`;
export const InfoFragmentDoc = gql`
    fragment info on appointment {
  ...AppointmentDetails
}
    ${AppointmentDetailsFragmentDoc}`;
export const DocumentBasicsFragmentDoc = gql`
    fragment DocumentBasics on document {
  id
  category
  datetime
  description
  location
  contentType
}
    `;
export const DprBasicsFragmentDoc = gql`
    fragment DPRBasics on documentPendingReview {
  id
  document {
    ...DocumentBasics
  }
  requiresFurtherAttention
  read
}
    ${DocumentBasicsFragmentDoc}`;
export const OrderableFieldDetailsFragmentDoc = gql`
    fragment OrderableFieldDetails on orderOrderableField {
  id
  name
  insertText
  orderWithinGroup
  patientQualification
  type
  data
}
    `;
export const OrderableFieldsFragmentDoc = gql`
    fragment OrderableFields on orderable {
  orderOrderableFields {
    ...OrderableFieldDetails
  }
}
    ${OrderableFieldDetailsFragmentDoc}`;
export const OrderActionBasicsFragmentDoc = gql`
    fragment OrderActionBasics on orderaction {
  id
  datetimeEntered
  action
}
    `;
export const OrderBasicsFragmentDoc = gql`
    fragment OrderBasics on baseorder {
  id
  dateEffective
  dateEnd
  orderActions {
    ...OrderActionBasics
  }
  approved
  expired
  cancelled
}
    ${OrderActionBasicsFragmentDoc}`;
export const MedicationBasicsFragmentDoc = gql`
    fragment MedicationBasics on medication {
  id
  name
  drugName
  active
  sig
}
    `;
export const MedicationOrderBasicsFragmentDoc = gql`
    fragment MedicationOrderBasics on medicationOrder {
  baseorder {
    ...OrderBasics
  }
  medicationOrderSpecifics {
    medication {
      ...MedicationBasics
    }
  }
}
    ${OrderBasicsFragmentDoc}
${MedicationBasicsFragmentDoc}`;
export const OrderableBasicsFragmentDoc = gql`
    fragment OrderableBasics on orderable {
  id
  title
  description
  category {
    id
    description
  }
}
    `;
export const OrderableOrderBasicsFragmentDoc = gql`
    fragment OrderableOrderBasics on orderableOrder {
  baseorder {
    ...OrderBasics
  }
  orderable {
    ...OrderableBasics
  }
}
    ${OrderBasicsFragmentDoc}
${OrderableBasicsFragmentDoc}`;
export const FreeTextOrderBasicsFragmentDoc = gql`
    fragment FreeTextOrderBasics on freeTextOrder {
  baseorder {
    ...OrderBasics
  }
  orderText
}
    ${OrderBasicsFragmentDoc}`;
export const OrderActionDetailsFragmentDoc = gql`
    fragment OrderActionDetails on orderaction {
  responsibleProvider {
    ...ProviderDetails
  }
  enteringUser {
    ...UserBasics
  }
}
    ${ProviderDetailsFragmentDoc}
${UserBasicsFragmentDoc}`;
export const OrderDetailsFragmentDoc = gql`
    fragment OrderDetails on baseorder {
  orderActions {
    ...OrderActionDetails
  }
}
    ${OrderActionDetailsFragmentDoc}`;
export const OrderDispositionDetailsFragmentDoc = gql`
    fragment OrderDispositionDetails on baseorder {
  active
  approved
  cancelled
  expired
  expirationDate
  calculatedEffectiveDate
}
    `;
export const OrderableOrderFieldValuesFragmentDoc = gql`
    fragment OrderableOrderFieldValues on orderableOrder {
  fieldValues {
    id
    value
    field {
      ...OrderableFieldDetails
    }
  }
}
    ${OrderableFieldDetailsFragmentDoc}`;
export const CommunicationDetailsFragmentDoc = gql`
    fragment CommunicationDetails on patientCommunication {
  id
  caretime {
    id
    datetime
    duration
    user {
      firstName
      lastName
      title
    }
  }
  text
  datesigned
}
    `;
export const DemographicsFragmentDoc = gql`
    fragment Demographics on patient {
  address
  city
  state
  zipCode
  gender
  primaryPhone
  secondaryPhone
  insurancePlans {
    id
    payer {
      name
      id
    }
    subscriberIdNumber
    rank
    active
  }
  admittedStatus
}
    `;
export const StatusBasicsFragmentDoc = gql`
    fragment StatusBasics on patientStatus {
  id
  name
  underCare
}
    `;
export const StatusActionBasicsFragmentDoc = gql`
    fragment StatusActionBasics on patientStatusAction {
  id
  status {
    ...StatusBasics
  }
  datetimeEffective
  datetimeEntered
  datetimeEnd
  notes
}
    ${StatusBasicsFragmentDoc}`;
export const TicketThreadBasicsFragmentDoc = gql`
    fragment TicketThreadBasics on ticketThread {
  id
  title
  userCreated {
    id
    firstName
    lastName
    title
  }
  dateCreated
  emailSubmitted
}
    `;
export const OrderablesForCategoryDocument = gql`
    query OrderablesForCategory($category: Int) {
  orderable(where: {categoryId: {_eq: $category}}) {
    id
    description
    title
    ...OrderableFields
  }
}
    ${OrderableFieldsFragmentDoc}`;

export function useOrderablesForCategoryQuery(options?: Omit<Urql.UseQueryArgs<OrderablesForCategoryQueryVariables>, 'query'>) {
  return Urql.useQuery<OrderablesForCategoryQuery, OrderablesForCategoryQueryVariables>({ query: OrderablesForCategoryDocument, ...options });
};
export const OrderableDetailsDocument = gql`
    query OrderableDetails($id: Int!) {
  orderableByPk(id: $id) {
    ...OrderableBasics
    ...OrderableFields
  }
}
    ${OrderableBasicsFragmentDoc}
${OrderableFieldsFragmentDoc}`;

export function useOrderableDetailsQuery(options: Omit<Urql.UseQueryArgs<OrderableDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<OrderableDetailsQuery, OrderableDetailsQueryVariables>({ query: OrderableDetailsDocument, ...options });
};
export const MyOrdersListDocument = gql`
    query myOrdersList {
  baseorder {
    id
    patient {
      id
      firstName
      lastName
      dateOfBirth
    }
  }
}
    `;

export function useMyOrdersListQuery(options?: Omit<Urql.UseQueryArgs<MyOrdersListQueryVariables>, 'query'>) {
  return Urql.useQuery<MyOrdersListQuery, MyOrdersListQueryVariables>({ query: MyOrdersListDocument, ...options });
};
export const AllPatientOrdersDocument = gql`
    query allPatientOrders($patientId: Int, $details: Boolean!) {
  orderableOrder(where: {baseorder: {patientId: {_eq: $patientId}}}) {
    ...OrderableOrderBasics
    baseorder {
      ...OrderDetails @include(if: $details)
    }
  }
  medicationOrder(where: {baseorder: {patientId: {_eq: $patientId}}}) {
    ...MedicationOrderBasics
  }
  freeTextOrder(where: {baseorder: {patientId: {_eq: $patientId}}}) {
    ...FreeTextOrderBasics
  }
}
    ${OrderableOrderBasicsFragmentDoc}
${OrderDetailsFragmentDoc}
${MedicationOrderBasicsFragmentDoc}
${FreeTextOrderBasicsFragmentDoc}`;

export function useAllPatientOrdersQuery(options: Omit<Urql.UseQueryArgs<AllPatientOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<AllPatientOrdersQuery, AllPatientOrdersQueryVariables>({ query: AllPatientOrdersDocument, ...options });
};
export const OrderableOrdersForPatientDocument = gql`
    query orderableOrdersForPatient($patientId: Int!, $category: Int, $orderableId: Int, $active: Boolean) {
  orderableOrder(
    where: {baseorder: {patientId: {_eq: $patientId}, active: {_eq: $active}}, orderable: {categoryId: {_eq: $category}}, orderableId: {_eq: $orderableId}}
  ) {
    ...OrderableOrderBasics
    baseorder {
      ...OrderDetails
      ...OrderDispositionDetails
    }
    ...OrderableOrderFieldValues
  }
}
    ${OrderableOrderBasicsFragmentDoc}
${OrderDetailsFragmentDoc}
${OrderDispositionDetailsFragmentDoc}
${OrderableOrderFieldValuesFragmentDoc}`;

export function useOrderableOrdersForPatientQuery(options: Omit<Urql.UseQueryArgs<OrderableOrdersForPatientQueryVariables>, 'query'>) {
  return Urql.useQuery<OrderableOrdersForPatientQuery, OrderableOrdersForPatientQueryVariables>({ query: OrderableOrdersForPatientDocument, ...options });
};
export const GetPatientDocument = gql`
    query getPatient($id: Int!, $details: Boolean!) {
  patientByPk(id: $id) {
    ...Basics
    ...Demographics @include(if: $details)
  }
}
    ${BasicsFragmentDoc}
${DemographicsFragmentDoc}`;

export function useGetPatientQuery(options: Omit<Urql.UseQueryArgs<GetPatientQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPatientQuery, GetPatientQueryVariables>({ query: GetPatientDocument, ...options });
};
export const GetPatientHistoryDocument = gql`
    query getPatientHistory($id: Int!) {
  patientByPk(id: $id) {
    ...Basics
    documents {
      ...DocumentBasics
    }
    documentsPendingReviewGuesses {
      ...DPRBasics
    }
    ticketthreads {
      ...TicketThreadBasics
    }
  }
}
    ${BasicsFragmentDoc}
${DocumentBasicsFragmentDoc}
${DprBasicsFragmentDoc}
${TicketThreadBasicsFragmentDoc}`;

export function useGetPatientHistoryQuery(options: Omit<Urql.UseQueryArgs<GetPatientHistoryQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPatientHistoryQuery, GetPatientHistoryQueryVariables>({ query: GetPatientHistoryDocument, ...options });
};
export const GetPatientCommunicationsDocument = gql`
    query getPatientCommunications($id: Int!) {
  patientCommunication(where: {caretime: {patientId: {_eq: $id}}}) {
    ...CommunicationDetails
  }
}
    ${CommunicationDetailsFragmentDoc}`;

export function useGetPatientCommunicationsQuery(options: Omit<Urql.UseQueryArgs<GetPatientCommunicationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPatientCommunicationsQuery, GetPatientCommunicationsQueryVariables>({ query: GetPatientCommunicationsDocument, ...options });
};
export const MyPatientListDocument = gql`
    query myPatientList {
  patient {
    id
    firstName
    lastName
    dateOfBirth
  }
}
    `;

export function useMyPatientListQuery(options?: Omit<Urql.UseQueryArgs<MyPatientListQueryVariables>, 'query'>) {
  return Urql.useQuery<MyPatientListQuery, MyPatientListQueryVariables>({ query: MyPatientListDocument, ...options });
};
export const PatientStatusDocument = gql`
    query patientStatus($id: Int!) {
  patientByPk(id: $id) {
    id
    statusActions {
      ...StatusActionBasics
    }
  }
}
    ${StatusActionBasicsFragmentDoc}`;

export function usePatientStatusQuery(options: Omit<Urql.UseQueryArgs<PatientStatusQueryVariables>, 'query'>) {
  return Urql.useQuery<PatientStatusQuery, PatientStatusQueryVariables>({ query: PatientStatusDocument, ...options });
};
export const GetPatientOrdersDocument = gql`
    query getPatientOrders($id: Int!) {
  patientByPk(id: $id) {
    id
    orders {
      id
    }
  }
}
    `;

export function useGetPatientOrdersQuery(options: Omit<Urql.UseQueryArgs<GetPatientOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPatientOrdersQuery, GetPatientOrdersQueryVariables>({ query: GetPatientOrdersDocument, ...options });
};
export const GetPatientAppointmentsDocument = gql`
    query getPatientAppointments($id: Int!) {
  patientByPk(id: $id) {
    id
    appointments {
      ...AppointmentListBasics
    }
  }
}
    ${AppointmentListBasicsFragmentDoc}`;

export function useGetPatientAppointmentsQuery(options: Omit<Urql.UseQueryArgs<GetPatientAppointmentsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPatientAppointmentsQuery, GetPatientAppointmentsQueryVariables>({ query: GetPatientAppointmentsDocument, ...options });
};