import { ComponentProps, forwardRef } from 'react';
import { Text as NativeText, Platform, Linking, TextStyle } from 'react-native';
import { styled, StyledProps } from 'nativewind';

export const Text = styled(NativeText, 'font-normal');

export const HeaderText = styled(NativeText, 'font-bold text-2xl');

/**
 * You can use this pattern to create components with default styles
 */
export const P = styled(NativeText, 'text-base text-black my-4 font-regular');
