import React from 'react';
import {
  StyleSheet,
  View,
  SafeAreaView,
  TouchableOpacity,
  ScrollView,
  ViewProps,
  Pressable,
} from 'react-native';
import * as RN from 'react-native';
import * as Colors from '../../design/colors';
import { StyledButton } from '../../design/buttons';
import { Patient, useMyPatientListQuery } from '../../generated/graphql-urql-reacthooks';
import { useLinkProps } from '@react-navigation/native';
import { ViewPressable } from '../../design/generics/ViewPressable';
import HomescreenList from '../home/HomescreenList';
import { Ionicons } from '@expo/vector-icons';
import { Text } from '../../design/typography';
import ScrollableList from '../../design/generics/list/ScrollableList';
import { SplitTitleButtonHeader } from '../../design/generics/headers/SplitTitleButtonHeader';

const MyPatientsList = (props: ViewProps) => {
  const [result, reexecuteQuery] = useMyPatientListQuery({ requestPolicy: 'network-only' });
  const { data, error } = result;
  const patients = data ? (data.patient as Patient[]) : ([] as Patient[]);

  if (error) {
    return (
      <View>
        <Text>{error.message}</Text>
      </View>
    );
  }

  return (
    <HomescreenList<Patient>
      title={'My Patients'}
      buttonText={'All Patients'}
      buttonStyle={'bg-patient'}
      itemStyle={'bg-patient-light'}
      items={patients}
      icon={(item) => (
        <Ionicons
          style={{ flex: 0, marginLeft: 20, marginRight: 20 }}
          name="person-circle-outline"
          size={40}
          color="green"
        />
      )}
      headingText={(item) => `${item.firstName ?? ''} ${item.lastName ?? ''}`}
      subheadingText={(item) => `${item.dateOfBirth?.toLocaleDateString() ?? ''}`}
      getKey={(item) => item.id ?? 0}
      to={(item) => ({ screen: 'Patient', params: { patientId: item.id } })}
    />
  );
};

export default MyPatientsList;

const styles = StyleSheet.create({
  container: {
    maxHeight: 200,
  },
  header: {
    margin: 2,
  },
});
