import * as Firebase from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { useAtom } from 'jotai';
import { authInitializingAtom, userAtom } from '../../state';
import React from 'react';
import { onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBJ8H4TkI-iPf-NIpGE_u2lsRzrUgy49HA',
  authDomain: 'mobilehrserver.firebaseapp.com',
  projectId: 'mobilehrserver',
  storageBucket: 'mobilehrserver.appspot.com',
  messagingSenderId: '729217793174',
  appId: '1:729217793174:web:bc7b9ea56ab2865c0a56b7',
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export function getAuth() {
  return Firebase.getAuth(app);
}

export function getUser() {
  return getAuth().currentUser;
}

export function loginWithEmailPassword(email: string, password: string) {
  return Firebase.signInWithEmailAndPassword(getAuth(), email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      // ...
      console.log('User account signed in!');
      console.log(user);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
      console.log(error);
    });
}

export function logout() {
  return Firebase.signOut(getAuth())
    .then(() => {
      // Sign-out successful.
      console.log('User account signed out!');
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getToken() {
  const user = getUser();
  if (user == null) {
    return null;
  }
  return Firebase.getIdToken(user).catch((error) => {
    console.log(error);
  });
}

// Initialize Firebase Authentication and get a reference to the service

export default function AuthProvider({ children }) {
  const [user, setUser] = useAtom(userAtom);
  const [initializing, setInitializing] = useAtom(authInitializingAtom);

  React.useEffect(() => {
    const unsubscribeFromAuthStatuChanged = onAuthStateChanged(getAuth(), (userC) => {
      if (initializing) {
        setInitializing(false);
      }
      if (userC) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(userC);
      } else {
        // User is signed out
        setUser(null);
      }
    });
    return unsubscribeFromAuthStatuChanged;
  }, []);
  return <>{children}</>;
}
