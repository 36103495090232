import React from 'react';
import { PatientType, useGetPatientQuery } from '../../../generated/graphql-urql-reacthooks';
import PatientDemoLine from './PatientDemoLine';
import { ScrollView, View, Text } from 'react-native';
import { HeaderText } from '../../../design/typography';
import { BackgroundView, CenterViewSpinner } from '../../../design/view';

const PatientDemoScreen = ({ patientId }: { patientId: number }): JSX.Element => {
  const [result, reexecuteQuery] = useGetPatientQuery({
    variables: { id: 177 },
    requestPolicy: 'network-only',
  });
  const { data, error, fetching } = result;
  const patient = data ? (data.patient as PatientType) : {};
  if (error) {
    return <Text>{JSON.stringify(error)}</Text>;
  }

  if (fetching) {
    return <CenterViewSpinner />;
  }

  return (
    <BackgroundView className={'flex-1'}>
      <ScrollView>
        <HeaderText>Basics</HeaderText>
        <PatientDemoLine title={'First Name'} value={patient.firstName}></PatientDemoLine>
        <PatientDemoLine title={'Last Name'} value={patient.lastName}></PatientDemoLine>
        <PatientDemoLine title={'Primary Phone'} value={patient.primaryPhone}></PatientDemoLine>
        <PatientDemoLine title={'Secondary Phone'} value={patient.secondaryPhone}></PatientDemoLine>
        <HeaderText>Address</HeaderText>
        <PatientDemoLine title={'Address'} value={patient.address}></PatientDemoLine>
        <PatientDemoLine title={'City'} value={patient.city}></PatientDemoLine>
        <PatientDemoLine title={'State'} value={patient.state}></PatientDemoLine>
        <PatientDemoLine title={'Zipcode'} value={patient.zipCode}></PatientDemoLine>
      </ScrollView>
    </BackgroundView>
  );
};

export default PatientDemoScreen;
