import React from 'react';
import { HeaderText } from '../../../design/typography';
import {
  DateInputStepperFormStep,
  TextDisplayStepperFormStep,
  TextInputStepperFormStep,
} from '../../../design/generics/StepperForm/GenericSteps';
import { StepperForm } from '../../../design/generics/StepperForm/StepperForm';
import { BackgroundView } from '../../../design/view';

const MiscelaneousOrderRequestScreen = (props) => {
  const patientId = props.route.params.patientId;
  const [startDate, setStartDate] = React.useState(new Date());
  const [details, setDetails] = React.useState('');

  return (
    <BackgroundView className={'flex-1'}>
      <HeaderText style={{ flexGrow: 0 }}>Miscelaneous Order</HeaderText>
      <StepperForm
        style={{ flexGrow: 1, flexShrink: 1 }}
        steps={[
          DateInputStepperFormStep({
            title: 'Start Date',
            value: startDate,
            onChange: setStartDate,
          }),
          TextInputStepperFormStep({
            title: 'Order Details',
            value: details,
            onChange: setDetails,
            error: () => {
              return details.length === 0 ? 'Please enter details' : null;
            },
          }),
          TextDisplayStepperFormStep({
            title: 'Order Confirmation',
            value: `Start Date: ${startDate.toLocaleDateString()}\nOrder:\n` + details,
            onChange: () => {},
          }),
        ]}
        onFinish={() => {}}
      />
    </BackgroundView>
  );
};

export default MiscelaneousOrderRequestScreen;
