import { ScrollView, View, ViewProps } from 'react-native';
import React, { ReactElement } from 'react';
import renderedItems from './RenderedItems';

interface ScrollableListProps<ItemType> extends ViewProps {
  header?: ReactElement;
  renderItem: (item: ItemType) => ReactElement;
  items: ItemType[];
}

const ScrollableList = <ItemType extends object>({
  header,
  renderItem,
  items,
  style,
  children,
  ...rest
}: ScrollableListProps<ItemType>) => (
  <View {...rest} className={'flex-1 flex-col'} style={style}>
    {header}
    <ScrollView className={'flex-grow'}>
      {renderedItems<ItemType>({ items, renderItem })}
    </ScrollView>
  </View>
);

export default ScrollableList;
